<script setup>
import { onMounted, ref, computed } from "vue";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { useRouter } from "vue-router/composables";
import HandleModal from "@/components/modals/HandleModal.vue";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import CompGoFlexRequestDetail from "@/components/goFlex/CompGoFlexRequestDetail.vue";

const goFlexStore = useGoFlexStore();
const router = useRouter();
const isLoading = ref(false);

const requestsToShow = computed(() => {
  return [...goFlexStore.requests]
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .slice(0, 3);
});

const colorStatus = (status) => {
  const map = new Map([
    ["PENDING", "tw-bg-yellow-200"],
    ["APPROVED", "tw-bg-green-450"],
    ["REJECTED", "tw-bg-red-400"],
    ["CANCELLED", "tw-bg-red-400"],
    ["COMPLETED", "tw-bg-brown-500"],
  ]);
  return map.get(status);
};

const statusName = (name) => {
  const map = new Map([
    ["PENDING", "Pendiente"],
    ["APPROVED", "Aprobada"],
    ["REJECTED", "Rechazada"],
    ["CANCELLED", "Cancelada"],
    ["COMPLETED", "Finalizada"],
  ]);
  return map.get(name) || "Pendiente";
};

const toggleModal = () => {
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Tus solicitudes",
    event_label: "Opciones - tus solicitudes",
    component_name: "Click",
  });
};

const seeRequests = () => {
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Tus solicitudes",
    event_label: "Ver tus solicitudes",
    component_name: "Click",
  });
  router.push({ name: "goFlexRequestsHistory" });
};

onMounted(async () => {
  isLoading.value = true;
  await goFlexStore.actGetGoFlexRequests().then(() => {
    isLoading.value = false;
  });
});
</script>
<template>
  <div v-if="!isLoading">
    <handle-modal
      modal-name="goFlexStates"
      icon="icon-reloj-go"
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
      @toggle="toggleModal"
    >
      <div class="tw-grid tw-auto-rows-auto tw-gap-3">
        <!-- Title -->
        <div class="tw-flex tw-justify-center">
          <p
            class="tw-font-robotoBold tw-text-lg lg:tw-text-xl tw-text-red-350"
          >
            Tus solicitudes
          </p>
        </div>
        <!-- No data -->
        <div
          v-if="goFlexStore.requests.length === 0"
          class="tw-flex tw-flex-col tw-gap-6"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center">
            No has realizado ninguna solicitud
          </p>
          <div class="tw-aspect-w-10 tw-aspect-h-8">
            <img
              src="@/assets/img/order-status/order-no-pending-order.svg"
              alt=""
            />
          </div>
        </div>
        <!-- Accordion -->
        <comp-accordion-parent open-first always-open-one v-else>
          <comp-accordion-item
            v-for="(request, idx) in requestsToShow"
            open-first
            :id="'req_' + idx"
            :key="idx"
          >
            <template v-slot:mainInfo>
              <div class="tw-grid tw-grid-cols-col2_fit_last tw-w-full">
                <div class="tw-grid tw-grid-rows-2">
                  <p class="tw-font-robotoBold tw-text-sm tw-m-0">
                    {{ request.option_label }}
                  </p>
                  <p class="tw-font-roboto tw-text-sm tw-m-0 tw-text-gray-300">
                    {{ formatDateYYYYMMDDLong(request.requested_date) }}
                  </p>
                </div>
                <div class="tw-h-full tw-flex tw-items-center">
                  <div
                    :class="colorStatus(request.status)"
                    class="tw-status-bubble"
                  >
                    <p class="tw-m-0 tw-text-white">
                      {{ statusName(request.status) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:secondaryInfo>
              <comp-go-flex-request-detail :request="request" />
            </template>
          </comp-accordion-item>
        </comp-accordion-parent>
        <!-- Buttons -->
        <div
          v-if="goFlexStore.requests.length > 0"
          class="tw-btn-primary-red tw-text-center"
          @click="seeRequests()"
        >
          <p class="tw-my-1">Ver tus solicitudes</p>
        </div>
      </div>
    </handle-modal>
  </div>
</template>
