<script setup>
import { useRouter } from "vue-router/composables";
import AtomText from "@/components/atoms/AtomText.vue";

const router = useRouter();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

const goBack = () => {
  return router.go(-1);
};
</script>
<template>
  <header
    class="tw-w-full tw-text-gray-600 tw-bg-white tw-block tw-shadow-md md:tw-hidden"
  >
    <div class="tw-px-0 tw-py-3 tw-flex tw-items-center tw-justify-between">
      <div class="tw-flex tw-items-center tw-pl-2 tw-gap-2" @click="goBack">
        <i class="icon-flecha-izq tw-text-xl tw-text-red-350"></i>
        <atom-text
          tag="h1"
          font="robotoBold"
          textSizeMobile="2xl"
          textSizeDesktop="3xl"
        >
          {{ props.title }}
        </atom-text>
      </div>
      <div class="tw-flex tw-items-center">
        <i class="icon-cebada-full tw-text-2xl tw-mr-3 tw-text-red-350"></i>
      </div>
    </div>
  </header>
</template>
