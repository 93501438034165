
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID || '';
const URL_AUTORIZE = process.env.VUE_APP_URL_AUTORIZE || '';
const SCOPE = process.env.VUE_APP_SCOPE || '';
const URL_CALLBACK = process.env.VUE_APP_URL_CALLBACK || '';

export default {
    CLIENT_ID,
    URL_AUTORIZE,
    SCOPE,
    URL_CALLBACK
}