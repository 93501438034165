<script setup>
import { computed, ref } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useGoFlexStore } from "@/stores/goFlexStore";

const goFlexStore = useGoFlexStore();
const cancel = ref(false);

const props = defineProps({
  request: {
    type: Object,
    default: () => {},
  },
  showNumber: {
    type: Boolean,
    default: false,
  },
});

const requestDetailText = computed(() => {
  return props.request?.requested_time
    ? `Tu solicitud inicia a las ${props.request?.requested_time}`
    : `Tu solicitud es para el día ${props.request?.requested_date}`;
});

const cancelRequest = () => {
  if (!cancel.value) {
    cancel.value = true;
    pushDataLayer({
      event_category: "Go Flex",
      event_action: "Tus solicitudes",
      event_label: "Cancelar solicitud",
      component_name: "Click",
      element_category: props.request.option_label,
    });
  } else {
    pushDataLayer({
      event_category: "Go Flex",
      event_action: "Tus solicitudes",
      event_label: "Cancelar",
      component_name: "Click",
      element_category: props.request.option_label,
    });
    goFlexStore
      .actPostCancelGoFlexEmployeeRequest(props.request.id)
      .then(() => {
        goFlexStore.actGetGoFlexRequests().then(() => {
          cancel.value = false;
        });
      });
  }
};

const goBackCancel = () => {
  cancel.value = false;
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Tus solicitudes",
    event_label: "Volver",
    component_name: "Click",
    element_category: props.request.option_label,
  });
};

const statusText = (name) => {
  const map = new Map([
    ["PENDING", "Tu solicitud la está revisando tu Line Manager."],
    [
      "APPROVED",
      "Tu solicitud fue aprobada por tu Line Manager, disfruta de este tiempo para tí.",
    ],
    [
      "REJECTED",
      "Tu solicitud no fue aprobada por tu Line Manager, comunícate directamente con él.",
    ],
    ["CANCELLED", "Cancelaste tu solicitud."],
    ["COMPLETED", "Esperamos que lograras disfrutar este tiempo para tí."],
  ]);
  return map.get(name);
};
</script>
<template>
  <div>
    <div v-if="!cancel">
      <div class="tw-flex tw-flex-col tw-gap-5 tw-mt-4">
        <div class="tw-flex tw-flex-col tw-gap-3">
          <div>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              Detalle de tu solicitud
            </p>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              {{ requestDetailText }}
            </p>
          </div>
          <p
            class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0 tw-text-gray-400"
          >
            {{ statusText(request.status) }}
          </p>
        </div>

        <div
          v-if="props.request.status === 'PENDING'"
          class="tw-btn-secondary-white"
          @click="cancelRequest"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
            Cancelar solicitud
          </p>
        </div>
      </div>
    </div>
    <div v-else class="tw-flex tw-flex-col tw-gap-5">
      <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-3">
        <div class="tw-col-span-1">
          <div class="tw-flex tw-justify-center tw-w-full tw-h-full">
            <img
              src="@/assets/img/ecommerce/compra-rechazada.svg"
              class="tw-object-contain tw-w-full tw-h-full"
              alt=""
            />
          </div>
        </div>
        <div class="tw-col-span-2 tw-flex tw-items-center">
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-center"
          >
            ¿Estás seguro de cancelar tu solicitud?
          </p>
        </div>
      </div>
      <div class="tw-flex tw-justify-between tw-items-center tw-gap-3">
        <div class="tw-btn-secondary-white" @click="goBackCancel()">
          <p class="tw-m-0">Volver</p>
        </div>
        <div class="tw-btn-primary-red" @click="cancelRequest()">
          <p class="tw-m-0">Cancelar</p>
        </div>
      </div>
    </div>
  </div>
</template>
