<template>
  <div id="page-participants">
    <div class="ml-0 md:tw-ml-20 ml-xl-0">
      <div class="container p-0 mb-5 mb-md-0">
        <div class="row align-items-center">
          <div class="col-md-7 py-0">
            <SecondaryNavbar
              :title="competition.name"
              :breadcrumb="breadcrumb"
            />
          </div>
          <!-- .col -->
          <div class="col-md-5 py-0">
            <ul
              class="navbar-nav flex-row align-items-end justify-content-center"
            >
              <li class="col px-0 px-md-1 px-lg-3 text-center">
                <router-link
                  class="nav-link"
                  :to="'/competition/competition/' + competition.id"
                  >Concurso</router-link
                >
              </li>
              <li class="col px-0 px-md-1 px-lg-3 text-center">
                <router-link
                  class="nav-link active"
                  to="/competition/participants"
                  >Resultados</router-link
                >
              </li>
            </ul>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
        <div
          class="row mt-4"
          v-if="
            competition.ranking.length > 0 &&
            competition.end <= new Date().toISOString().slice(0, 10)
          "
        >
          <div class="col-12 col-md-6 px-4">
            <div class="row px-0">
              <div class="col-12">
                <div
                  v-if="participant.post.length !== 0"
                  class="cont-actual p-3 d-flex align-items-center justify-content-between"
                >
                  <p
                    class="name ml-3 mb-0 text-bold text-capitalize text-white"
                  >
                    <!--{{ getFirstName(participant.post[0].name) }}-->
                  </p>
                  <p
                    v-if="participant.post[0].position == null"
                    class="desc mx-3 mb-0 text-white h5"
                  >
                    esta vez no ganaste pero puedes seguir intentándolo en otros
                    concursos.
                  </p>
                  <p
                    v-if="participant.post[0].position !== null"
                    class="desc mx-3 mb-0 text-white h5"
                  >
                    ¡Ganaste!, tu posición en el concurso es:
                  </p>
                  <div
                    v-if="participant.post[0].position !== null"
                    class="cont-img position-relative"
                  >
                    <img
                      src="@/assets/img/demo/avatar-empty.png"
                      alt
                      class="rounded-circle tw-w-full tw-h-full"
                    />
                    <img
                      src="@/assets/img/icons/laureles.svg"
                      class="img-laureles"
                      alt="laureles"
                    />
                    <div
                      v-if="participant.post[0].position < 10"
                      class="number-one d-flex justify-content-center align-items-center position-absolute"
                    >
                      <p class="text-bold mb-2">
                        {{ participant.post[0].position }}
                      </p>
                    </div>
                    <div
                      v-if="
                        participant.post[0].position > 10 &&
                        participant.post[0].position < 100
                      "
                      class="number-one-t d-flex justify-content-center align-items-center position-absolute"
                    >
                      <p class="text-bold mb-2">
                        {{ participant.post[0].position }}
                      </p>
                    </div>
                    <div
                      v-if="
                        participant.post[0].position > 99 &&
                        participant.post[0].position < 1000
                      "
                      class="number-one-d d-flex justify-content-center align-items-center position-absolute"
                    >
                      <p class="text-bold mb-2">
                        {{ participant.post[0].position }}
                      </p>
                    </div>
                    <div
                      v-if="
                        participant.post[0].position > 999 &&
                        participant.post[0].position < 5000
                      "
                      class="number-one-c d-flex justify-content-center align-items-center position-absolute"
                    >
                      <p class="text-bold mb-2">
                        {{ participant.post[0].position }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- .cont-actual -->
              </div>
              <!-- .col -->
            </div>

            <div class="row d-flex justify-content-center mt-4">
              <div class="cont-rank-user bar-1 bar-silver">
                <div class="cont-img position-relative">
                  <img
                    src="@/assets/img/demo/avatar-empty.png"
                    class="rounded-circle tw-w-full"
                    alt=""
                  />
                  <img
                    src="@/assets/img/icons/laureles.svg"
                    class="img-laureles"
                    alt="laureles"
                  />
                  <div
                    class="number d-flex justify-content-center align-items-center position-absolute"
                  >
                    <p class="mb-0 text-bold">2</p>
                  </div>
                </div>
                <div class="cont-bar">
                  <div
                    class="d-flex flex-column h-100 justify-content-center align-items-center"
                  >
                    <svg
                      class="mb-3"
                      width="40"
                      height="40"
                      viewBox="0 -10 511.98685 511"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                        fill="#8f98a6"
                      />
                    </svg>
                    <p
                      class="name mb-2 text-capitalize"
                      v-if="competition.ranking[1] !== undefined"
                    >
                      {{ getFirstName(competition.ranking[1].name) }}
                    </p>
                    <p class="points mb-0"></p>
                  </div>
                </div>
              </div>

              <div class="cont-rank-user bar-2 bar-gold">
                <div class="cont-img position-relative">
                  <img
                    src="@/assets/img/demo/avatar-empty.png"
                    class="rounded-circle tw-w-full|"
                    alt
                  />
                  <img
                    src="@/assets/img/icons/laureles.svg"
                    class="img-laureles"
                    alt="laureles"
                  />
                  <div
                    class="number d-flex justify-content-center align-items-center position-absolute"
                  >
                    <p class="mb-0 text-bold">1</p>
                  </div>
                </div>
                <div class="cont-bar">
                  <div
                    class="d-flex flex-column h-100 justify-content-center align-items-center"
                  >
                    <svg
                      class="mb-3 mt-5"
                      width="40"
                      height="40"
                      viewBox="0 -10 511.98685 511"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                        fill="#f8a82e"
                      />
                    </svg>
                    <p
                      class="name mb-2 text-capitalize"
                      v-if="competition.ranking[0] !== undefined"
                    >
                      {{ getFirstName(competition.ranking[0].name) }}
                    </p>
                    <p class="points mb-0"></p>
                  </div>
                </div>
              </div>

              <div class="cont-rank-user bar-1 bar-bronze">
                <div class="cont-img position-relative">
                  <img
                    src="@/assets/img/demo/avatar-empty.png"
                    class="rounded-circle tw-w-full"
                    alt
                  />
                  <img
                    src="@/assets/img/icons/laureles.svg"
                    class="img-laureles"
                    alt="laureles"
                  />
                  <div
                    class="number d-flex justify-content-center align-items-center position-absolute"
                  >
                    <p class="mb-0 text-bold">3</p>
                  </div>
                </div>
                <div class="cont-bar">
                  <div
                    class="d-flex flex-column h-100 justify-content-center align-items-center"
                  >
                    <svg
                      class="mb-3"
                      width="40"
                      height="40"
                      viewBox="0 -10 511.98685 511"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                        fill="#c47632"
                      />
                    </svg>
                    <p
                      class="name mb-2 text-capitalize"
                      v-if="competition.ranking[2] !== undefined"
                    >
                      {{ getFirstName(competition.ranking[2].name) }}
                    </p>
                    <p class="points mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="cont-bar-title mt-4 d-none d-md-block">
              <h2
                class="text-bold text-uppercase text-center tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"
              >
                podio de ganadores
              </h2>
            </div>
          </div>

          <!-- .col -->
          <div class="col-12 col-md-5 px-md-0 mx-md-auto font-box">
            <div class="cont-position-table">
              <div class="cont-title mt-5 mt-md-0">
                <h2
                  class="text-center text-bold mb-0 py-2 text-uppercase tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"
                >
                  ganadores
                </h2>
              </div>
              <div
                class="scroll-participant pt-3"
                v-if="participant.post.length !== 0"
              >
                <div>
                  <div
                    v-show="participant.post[0].position !== null"
                    :class="
                      item.position === participant.post[0].position
                        ? 'participantWinner'
                        : 'participant'
                    "
                    class="card-user p-2 px-md-5 py-md-2 d-flex align-items-center justify-content-between mb-3"
                    data-spy="scroll"
                    v-for="item in competition.ranking"
                    v-bind:key="item.position"
                  >
                    <p
                      class="my-auto"
                      :class="
                        item.position === participant.post[0].position
                          ? 'text-white'
                          : 'text-dark'
                      "
                    >
                      {{ item.position }}
                    </p>
                    <img
                      src="@/assets/img/demo/avatar-empty.png"
                      alt="user"
                      class="rounded-circle img mx-2 tw-w-[62px] tw-h-[62px]"
                    />
                    <p
                      :class="
                        item.position === participant.post[0].position
                          ? 'text-white'
                          : 'text-dark'
                      "
                      class="name mb-0 text-capitalize"
                    >
                      {{ getName(item.name) }}
                    </p>
                    <p
                      v-if="competition.comp_competition_type_id === 1"
                      class="points mb-0 text-bold mx-2"
                    >
                      100pt
                    </p>
                    <svg
                      class
                      width="20"
                      height="20"
                      viewBox="0 -10 511.98685 511"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                        fill="#f8a82e"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="scroll-participant-Two pt-3" v-else>
                <div
                  class="card-user p-2 px-md-5 py-md-2 d-flex align-items-center justify-content-between mb-3 participant"
                  data-spy="scroll"
                  v-for="item in competition.ranking"
                  v-bind:key="item.position"
                >
                  <p class="text-dark my-auto">
                    {{ item.position }}
                  </p>
                  <img
                    src="@/assets/img/demo/avatar-empty.png"
                    alt="user"
                    class="rounded-circle img mx-2 tw-w-[62px] tw-h-[62px]"
                  />
                  <p class="name mb-0 text-capitalize text-dark">
                    {{ getName(item.name) }}
                  </p>
                  <p
                    v-if="competition.comp_competition_type_id === 1"
                    class="points mb-0 text-bold mx-2"
                  >
                    100pt
                  </p>
                  <svg
                    class
                    width="20"
                    height="20"
                    viewBox="0 -10 511.98685 511"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                      fill="#f8a82e"
                    />
                  </svg>
                </div>
              </div>

              <!-- .card-user -->
              <!-- .card-user -->
            </div>
            <!-- .cont-position-table -->
          </div>
        </div>
        <div
          v-if="
            competition.ranking.length !== 0 &&
            competition.end <= new Date().toISOString().slice(0, 10)
          "
        >
          <hr class="my-5" />
        </div>
        <div
          class="row cont-no-participants text-center mt-4 mt-md-4"
          v-show="competition.posts.length === 0"
        >
          <div class="col-sm-12 col-10 mx-auto">
            <p class="title">Aún no hay participantes</p>
            <p class="content-p mx-auto mt-4 d-none d-md-block">
              ¿Qué estas esperando? Aún tienes oportunidad de participar,
              aprovecha e ingresa a nuestro concurso y juega ya.
            </p>
            <p class="h6 mt-4 d-block d-md-none" style="color: #999999">
              ¿Qué estas esperando? Aún tienes oportunidad de participar,
              aprovecha e ingresa a nuestro concurso y juega ya.
            </p>
          </div>
          <div class="col-10 col-md-4 col-lg-3 mx-auto">
            <div class="text-center">
              <img
                src="@/assets/img/no-participantes.png"
                alt="no users"
                class="rounded-circle mt-3 mt-md-5 tw-w-full"
              />
              <div class="row mt-2 mb-5 mx-2 mx-md-0 mt-5">
                <div class="col-sm-12 col-4 mx-4 mx-md-0 px-0">
                  <div
                    v-if="
                      this.competition.posts.length === 0 ||
                      this.competition.comp_competition_type_id === 1
                    "
                  >
                    <a
                      v-if="
                        competition.end >=
                          new Date().toISOString().slice(0, 10) &&
                        this.participant.post.length === 0
                      "
                      v-b-modal.modal-sell
                      href="#"
                      class="btn btn-block color mb-1 mx-2 text-bold d-flex justify-content-center align-items-center"
                      >¡Quiero participar!</a
                    >
                    <a
                      v-else
                      href="#"
                      class="btn btn-block color mb-1 mx-2 text-bold d-flex justify-content-center align-items-center disabled"
                      >¡Quiero participar!</a
                    >
                  </div>
                </div>
                <!-- .col -->
              </div>
            </div>
            <b-modal
              id="modal-sell"
              centered
              hide-footer
              hide-header
              class="text-center"
            >
              <img
                :src="url + '/' + competition.icon"
                alt="img 1"
                width="100%"
              />
              <div class="row px-3 px-md-0 mt-2">
                <div class="col-12 px-5">
                  <div
                    class="cont-text d-flex h-100 flex-column justify-content-center text-center"
                  >
                    <p class="text-1 mb-3">{{ competition.desc }}</p>
                    <p class="text-2">
                      Al participar en este concurso, estás aceptando los
                      <a
                        :href="url + '/' + competition.tyc"
                        class="text-red text-bold"
                        target="_blank"
                        >términos y condiciones</a
                      >.
                    </p>
                    <router-link
                      v-if="this.competition.comp_competition_type_id === 1"
                      to="/competition/question"
                      class="btn btn-block btn-outline-danger text-bold mt-2"
                      >Empezar</router-link
                    >
                    <router-link
                      v-if="this.competition.comp_competition_type_id === 2"
                      :to="'/competition/' + competition.id"
                      class="btn btn-block btn-outline-danger text-bold mt-2"
                      >Empezar</router-link
                    >
                    <router-link
                      v-if="this.competition.comp_competition_type_id === 3"
                      :to="'/competition/' + competition.id"
                      class="btn btn-block btn-outline-danger text-bold mt-2"
                      >Empezar</router-link
                    >
                  </div>
                  <!-- .cont-text-1 -->
                </div>
                <!-- .col -->
              </div>
            </b-modal>
            <!-- .cont-no-participants -->
          </div>
        </div>
        <!-- .row -->
        <p
          class="h4 font-weight-bold mt-3 ml-2 ml-md-0"
          v-if="competition.posts.length > 0"
        >
          Todos los participantes
        </p>
        <div class="row mt-3" :v-show="competition.posts.length > 0">
          <div
            class="col-12 col-md-6 col-lg-4"
            v-for="participant in competition.posts"
            :key="participant.id"
          >
            <div class="participant-card mb-4">
              <div
                class="cont-img"
                :style="cssProps"
                v-on:click="participantItem(participant)"
              >
                <img
                  v-if="participant.type === 'I'"
                  :src="setImageUrl(participant.file)"
                  width="100%"
                  alt="no users"
                  class="mb-3"
                />
                <video
                  v-if="participant.type === 'V'"
                  width="100%"
                  height="auto"
                  ref="participantVideo"
                >
                  <source
                    :src="setImageUrl(participant.file)"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <!-- .cont-img -->
              <p class="name">{{ participant.core_employee_name }}</p>
              <p class="title">{{ participant.name }}</p>
              <p class="desc mb-0">{{ participant.description }}</p>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </div>
    <!-- .layout-content -->
    <b-modal ref="modal-participant" size="lg" hide-footer>
      <template v-slot:modal-title></template>
      <div class="d-block text-center">
        <div class="cont-video position-relative" :style="cssProps">
          <!--<source :src="setImageUrl(participant.file)" type="image/*" />-->
          <img
            v-if="dataParticipant.type === 'I'"
            :src="setImageUrl(dataParticipant.file)"
            width="100%"
            alt="no users"
            class="mb-3"
          />
          <video
            v-if="dataParticipant.type === 'V'"
            width="100%"
            height="auto"
            ref="participantVideo"
            controls
          >
            <source :src="setImageUrl(dataParticipant.file)" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <!--<img
            src="@/assets/img/btn-play.png"
            width="54"
            height="54"
            alt="play"
            class="position-absolute"
            @click="play"
            v-show="!isPlaying"
          />-->
        </div>
        <div class="text-left mt-1">
          <!--<p class="user">Nombre del participante</p>-->
          <p class="name">{{ dataParticipant.core_employee_name }}</p>
          <p class="title mb-1">{{ dataParticipant.name }}</p>
          <p class="desc mb-0">{{ dataParticipant.description }}</p>
        </div>
      </div>
    </b-modal>
  </div>
  <!-- #page-participants -->
</template>

<script>
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import { mapActions, mapState } from "vuex";
import config from "@/services/config";

export default {
  name: "ParticipantsView",
  components: {
    SecondaryNavbar,
  },
  data() {
    return {
      url: config.beerOfficeURL,
      dataParticipant: {},
      perPage: 3,
      currentPage: 1,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Universo de marcas",
          link: "/beer-universe",
        },
        {
          id: 3,
          name: "Mundo de marcas",
          link: "/brands",
        },
        {
          id: 4,
          name: "Concursos",
          link: "/competitions",
          active: true,
        },
      ],
      participants: [
        {
          id: 1,
          imgUrl: "https://picsum.photos/id/1/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: false,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 2,
          imgUrl: "https://picsum.photos/id/2/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: false,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 3,
          imgUrl: "https://picsum.photos/id/3/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: false,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 4,
          imgUrl: "https://picsum.photos/id/4/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: 100,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 5,
          imgUrl: "https://picsum.photos/id/5/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: 100,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 6,
          imgUrl: "https://picsum.photos/id/6/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: 100,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 7,
          imgUrl: "https://picsum.photos/id/6/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: 100,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
        {
          id: 8,
          imgUrl: "https://picsum.photos/id/6/600/300",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          username: "Nombre del participante",
          title: "Nombre de la publicación",
          votes: 100,
          desc: "Descripción de la publicación. 140 caracteres. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        },
      ],
      cssProps: {
        background: `url(${require("@/assets/img/icons/loading.gif")}) no-repeat center center`,
      },
      isPlaying: false,
    };
  },
  created() {},
  beforeMount() {
    this.idCompetition = this.$route.params.id;
  },
  mounted() {
    this.actGetCompetition(this.idCompetition);
  },
  computed: {
    ...mapState("modCompetitions", [
      "competition",
      "competitionInfo",
      "participant",
    ]),
    //...mapState("modCore", ["user"]),
    rows() {
      return this.participants.length;
    },
  },
  methods: {
    ...mapActions("modCompetitions", [
      "actGetCompetition",
      "actGetCompetitionByIdUser",
    ]),
    setImageUrl(imagePath) {
      return this.url + "/" + imagePath;
    },
    play() {
      this.$refs.participantVideo.play();
      this.isPlaying = true;
    },
    // videoEnd() {
    //   this.isPlaying = false;
    // },
    participantItem(item) {
      this.dataParticipant = item;
      this.$refs["modal-participant"].show();
    },
    getFirstName(string) {
      string = string + "";
      let name = string.split(" ");
      return name[0].toLowerCase();
    },
    getName(string) {
      string = string + "";
      let name = string.split(" ");
      return name[0].toLowerCase() + " " + name[2].toLowerCase();
    },
  },
};
</script>

<style lang="css" scoped>
.color {
  border-color: #d82b2b;
  color: #d82b2b;
  width: 200px;
}
.color:hover {
  background-color: #d82b2b;
  color: #ffffff;
  border-color: #d82b2b;
}
.content-p {
  width: 400px;
  font-size: 18px;
  color: #999999;
}
.scroll-participant {
  height: 530px;
  width: 100%;
  border: 1px solid #ddd;
  background: #f1f1f1;
  overflow-y: scroll;
}
.scroll-participant-Two {
  height: 410px;
  width: 100%;
  border: 1px solid #ddd;
  background: #f1f1f1;
  overflow-y: scroll;
}
.participantWinner {
  background: #00bf79;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  box-shadow: 0 3px 6px #0000001a;
  opacity: 1;
}
.participant {
  background: #fff;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  box-shadow: 0 3px 6px #0000001a;
  opacity: 1;
}
.cont-bar-title p {
  font-size: 30px;
  color: #bababa;
}
.bar-silver {
  color: #bababa;
}

.bar-silver .cont-bar {
  background-color: #ebebeb;
}
.bar-2 .cont-bar {
  height: 280px;
  width: 100px;
}
.bar-2 {
  max-width: 120px;
}
.bar-gold {
  color: #ffc041;
}

.bar-gold .cont-bar {
  background-color: #ffc04157;
  margin: 30px auto 0 auto;
}

.bar-gold .cont-bar .icon {
  color: #f9b233;
  background-color: #ffcc00;
  border: solid 1px #fdc00f;
  display: block;
  border-radius: 50%;
  padding: 10px;
  width: 65px;
  height: 65px;
}
.bar-bronze {
  color: #ca742a;
}
.bar-bronze .cont-bar {
  background-color: #fee4cd;
}
.bar-1 .cont-img {
  margin-top: 70px;
}
.bar-1 .cont-bar {
  height: 230px;
  width: 100px;
}
.bar-1 {
  max-width: 100px;
}
.cont-rank-user .img-laureles {
  position: absolute;
  top: 46px;
  left: 0;
}
.img-laureles {
  position: absolute;
  top: 46px;
  left: 0;
}
.cont-actual {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 18px;
  background: #00bf79;
  color: #ffffff;
}
.cont-rank-user .cont-img .number {
  background: #ffffff;
  box-shadow: 0 3px 6px #00000029;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: -8px;
  left: calc(50% - 20px);
}
.cont-rank-user .cont-img .number p {
  font-size: 22px;
}
.number-one p {
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(350%, -50%);
  color: #ffc041;
}
.number-one-t p {
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(150%, -50%);
  color: #ffc041;
}
.number-one-d p {
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(85%, -50%);
  color: #ffc041;
}
.number-one-c p {
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  color: #ffc041;
}
.cont-bar {
  width: 100%;
  border-radius: 20px;
  margin-top: 30px;
}
.bar-1 .cont-img {
  margin-top: 70px;
}
/**NAVIGATION */
.navbar-nav {
  background-color: #f7f7f7;
  height: 40px;
}
.nav-link {
  color: #999999;
  text-transform: uppercase;
}
.nav-link.active {
  color: #d82b2b;
  border-bottom: 3px solid #d82b2b;
}

@media (max-width: 768px) {
  .scroll-participant {
    height: 330px;
    width: 100%;
    border: 1px solid #ddd;
    background: #f1f1f1;
    overflow-y: scroll;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    background-color: transparent;
  }

  .nav-link {
    color: #999999;
    border-bottom: 3px solid #999999;
    text-transform: initial;
  }
}
.cont-no-participants .title {
  color: #d82b2b;
  font-size: 24px;
  font-family: "RobotoBold", sans-serif;
}
/**Card */
.participant-card {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
  padding: 15px;
}
.participant-card .cont-img {
  min-height: 150px;
}
.participant-card .cont-img img {
  border-radius: 8px;
}
.cont-actual .name {
  font-size: 26px;
}
.cont-bar .name {
  font-size: 22px;
  font-family: RobotoLight, sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding: 0 5px;
  text-align: center;
}
.participant-card .name {
  color: #454545;
  font-size: 12px;
  font-family: "RobotoBold", sans-serif;
}
.cont-position-table .card-user .name {
  color: #707070;
  font-size: 12px;
  flex: 0 0 110px;
}
.cont-position-table .card-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.cont-position-table .card-active .name,
.participant-card .title {
  color: #343434;
  font-size: 20px;
  font-family: "RobotoBold", sans-serif;
}
.cont-position-table .card-user .position {
  color: #707070;
  flex: 0 0 20px;
}
.font-box {
  background: #ebebeb;
  border-radius: 50px;
}
.cont-position-table .card-user p,
.cont-position-table .card-user span {
  font-size: 14px;
}
.cont-position-table .card-user .points {
  color: #ffc041;
}

.cont-position-table .card-user .icon {
  color: #ffc041;
  width: 30px;
  height: 30px;
}
.cont-position-table .card-active {
  background-color: #ffc041;
}
.participant-card .desc {
  color: #353333;
  font-size: 14px;
}
@media (min-width: 992px) {
  .cont-position-table .card-user .name {
    flex: 0 0 250px;
  }
}

@media (min-width: 768px) {
  .participant-card .cont-img {
    min-height: 160px;
  }
  .cont-position-table .card-user .name {
    font-size: 16px;
    flex: 0 0 180px;
  }
  .cont-position-table .cont-title p {
    font-size: 30px;
  }

  .cont-position-table .card-user p,
  .cont-position-table .card-user span {
    font-size: 18px;
  }
}

/**Votes */
.cont-votes {
  color: #00bf79;
}

.cont-winners {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 16px;
  padding-top: 15px;
}
</style>

<style lang="css">
/**Modal */
#modal-participant header {
  border-bottom: 0 none;
}

.name {
  color: #454545;
  font-size: 12px;
  font-family: "RobotoBold", sans-serif;
}

.title {
  color: #343434;
  font-size: 20px;
  font-family: "RobotoBold", sans-serif;
}

.desc {
  color: #808080;
  font-size: 14px;
}

#modal-participant .cont-video {
  min-height: 420px;
  cursor: pointer;
}

#modal-participant .cont-video img {
  top: calc(50% - 27px);
  left: calc(50% - 27px);
}

.modal-content button.close {
  opacity: 1;
}

/**Carousel */
#page-participants .carousel-cell {
  width: 50%;
  padding: 0 15px;
  min-height: 320px;
  height: auto;
}

#page-participants .carousel-cell .title-number {
  color: #d82b2b;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
}

#page-participants .carousel-cell .name {
  margin-top: 10px;
  color: #454545;
  font-size: 10px;
  font-family: "RobotoBold", sans-serif;
}

#page-participants .carousel-cell .title {
  color: #343434;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
}

#page-participants .carousel-cell .cont-numb {
  color: #00bf79;
}

@media (min-width: 768px) {
  #page-participants .carousel-cell {
    width: 25%;
    min-height: 350px;
  }

  #page-participants .carousel-cell .name {
    font-size: 12px;
  }

  #page-participants .carousel-cell .title {
    font-size: 20px;
  }
}
</style>
