<script setup>
import { ref, onBeforeMount, onMounted, watch } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import GeneralSplash from "@/components/general/GeneralSplash";
import CardProductFeatured from "@/components/ecommerce/CardProductFeatured";
import GeneralProducts from "@/components/ecommerce/GeneralProducts";
import GeneralModal from "@/components/modals/GeneralModal";
import CompClaimModal from "@/components/ecommerce/CompClaimModal";
import ShoppingCart from "@/components/ecommerce/ShoppingCart";
import { storeToRefs } from "pinia";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { actGetUserById } = userStore;
const isLoading = ref(false);
const isSplash = ref(true);
const isShowClaimModal = ref(false);
const max_day_gift = ref(null);

const showClaimModal = () => {
  isShowClaimModal.value = true;
};

const hideClaimModal = () => {
  isShowClaimModal.value = false;
};

const pushDataLayer = () => {
  let productsToDataLayer = [];
  // Adicionando la lista de productos de compra al array
  ecommerceStore.products[0].ecomm_product.forEach((product) => {
    productsToDataLayer.push({
      name: product.name,
      id: product.id,
      price: product.price_public,
      brand: product.ecomm_brand.name,
      category: product.ecomm_product_category?.name,
      list: "Productos",
    });
  });
  // Adicionando la lista de productos obsequio al array
  ecommerceStore.products[1].ecomm_product.forEach((product) => {
    if (product.ecomm_product_category == null) {
      product.ecomm_product_category = {
        name: "Sin categoría",
      };
    }
    productsToDataLayer.push({
      name: product.name,
      id: product.id,
      price: product.price_public,
      brand: product.ecomm_brand.name,
      category: product.ecomm_product_category?.name,
      list: "Productos obsequio",
    });
  });
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "productImpression",
    ecommerce: {
      currencyCode: "COP",
      impressions: productsToDataLayer,
    },
  });
};

watch(
  () => userStore.user.ecomm_delivery_site_id,
  (newSite, oldSite) => {
    if (newSite !== oldSite) {
      ecommerceStore.actGetProducts();
    }
  }
);

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetUserById(user.value.id);
  await ecommerceStore
    .actGetProducts()
    .then(() => {
      ecommerceStore.actGetLocations();
      max_day_gift.value = ecommerceStore.products.filter(
        (product) => product.name === "OBSEQUIO"
      )[0].max_day;
    })
    .then(() => {
      pushDataLayer();
      isLoading.value = false;
    });
});

onMounted(() => {
  if (userStore.user.ecomm_delivery_site_id == null) {
    showClaimModal();
  }
});
</script>
<template>
  <div>
    <general-modal
      :hide-button-support="true"
      v-if="isShowClaimModal"
      modal-name="claimModal"
      :is-show-modal="isShowClaimModal"
      :closable="userStore.user.ecomm_delivery_site_id != null"
      :modalWidth="{ md: 500, lg: 600 }"
      :maxHeight="{ base: 700, md: 700, lg: 800 }"
      @close="hideClaimModal"
    >
      <comp-claim-modal @close="hideClaimModal" />
    </general-modal>
    <div v-if="isSplash">
      <GeneralSplash
        title="Tienda"
        icon="icon-inside.svg"
        illustration="illustration-ecommerce.svg"
        @loaded="isSplash = false"
      >
        Descubre todos los productos que tenemos disponibles para ti por ser
        Embajador y pide tu producto del mes del día 1 al 10 de cada mes.
      </GeneralSplash>
    </div>
    <div v-else-if="!isSplash && !isLoading">
      <shopping-cart />
      <div class="tw-container-general">
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-col2_fit_last">
          <div
            class="tw-flex tw-gap-x-6 tw-items-center tw-my-5 tw-justify-between md:tw-justify-start"
          >
            <p
              class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-4xl tw-font-robotoBold"
            >
              Tienda
            </p>
            <img
              src="@/assets/img/icons/icon-3.png"
              alt="icon 3"
              class="tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24"
            />
          </div>
          <div class="tw-flex tw-items-center tw-my-5">
            <div
              class="tw-btn-secondary-white tw-flex tw-gap-x-3 tw-items-center tw-justify-center tw-px-4"
              @click="showClaimModal"
            >
              <i class="icon-pin tw-text-base lg:tw-text-lg"></i>
              <p class="tw-m-0 tw-text-xs lg:tw-text-base">
                {{
                  userStore.user.ecomm_delivery_site &&
                  userStore.user.ecomm_delivery_site.name
                    ? userStore.user.ecomm_delivery_site.name
                    : "No hay sitio seleccionado"
                }}
              </p>
            </div>
          </div>
        </div>
        <CardProductFeatured :max_day_gift="max_day_gift" />
        <hr
          class="tw-my-4 md:tw-my-6 lg:tw-my-10"
          v-if="user.core_society.access_to_ecommerce === 1"
        />
        <GeneralProducts v-if="user.core_society.access_to_ecommerce === 1" />
      </div>
    </div>
  </div>
</template>
