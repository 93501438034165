<template>
  <div id="app">
    <CompNotifications v-if="showNotifications" />
    <AppLayout>
      <router-view />
    </AppLayout>
    <Modal
      v-if="isModalVisible"
      @close="closeModal"
      widthModal="tw-max-w-screen-sm"
    >
      <template v-slot:body>
        <div class="tw-p-4 tw-text-center">
          <img :src="imageUrl" class="tw-mb-4 tw-inline-block" alt="support" />
          <p class="tw-text-3xl tw-text-red-400 tw-font-roboto tw-mb-4">
            ¡Uy! Parece que no tienes conexión a internet
          </p>
          <p class="tw-text-base tw-text-gray-600 tw-font-roboto">
            Mientras recuperas tu conexión a internet, puedes seguir consultando
            toda la información de Embajadores Beer Office.
          </p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppLayout from "@/layouts/AppLayout";
import Modal from "@/components/modals/Modal";
import { loadScript } from "vue-plugin-load-script";
import nps from "./services/nps";
import CompNotifications from "@/components/notifications/CompNotifications.vue";

/*let recaptchaScript = document.createElement("script");
recaptchaScript.setAttribute("src", process.env.VUE_APP_TAPIT_SSO_URL);
document.body.append(recaptchaScript);*/

const script = document.createElement("script");
script.type = "text/javascript";
script.innerHTML = `
 (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "k8dqlyx6du");
`;
document.head.appendChild(script);

export default {
  components: {
    CompNotifications,
    AppLayout,
    Modal,
  },
  beforeMount() {
    this.imageUrl = require("@/assets/img/icons/Sinconexion1x.png");
  },
  mounted() {
    window.addEventListener("appinstalled", function () {
      window.dataLayer.push({
        event: "eventoGA",
        eventCat: "pwa",
        eventAct: "added to home screen",
      });
    });
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.loadScriptNPS();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  computed: {
    ...mapState("modNotifications", ["showNotifications"]),
  },
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: true,
      isModalVisible: false,
      imageUrl: require("@/assets/img/icons/Sinconexion1x.png"),
    };
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
      if (this.onLine === false) {
        this.showModal();
      } else {
        this.closeModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    loadScriptNPS() {
      if (nps.NPS_URL !== "") {
        loadScript(nps.NPS_URL)
          .then(() => {
            //Script execution and import
          })
          .catch(() => {
            // Failed to fetch script
          });
      }
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
  },
};
</script>
