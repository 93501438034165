const slidelinesMixin = {
  data() {
    return {
      sidelines: [
        "I",
        "I-A",
        "I-B",
        "II",
        "II-A",
        "II-B",
        "III",
        "III-A",
        "III-B",
        "IV",
        "IV-A",
        "IV-B",
        "V",
        "V-A",
        "V-B",
        "VI",
        "VI-A",
        "VI-B",
        "VII",
        "VII-A",
        "VII-B",
        "VIII",
        "VIII-A",
        "VIII-B",
        "IX-A",
        "IX-B",
        "X-A",
        "X-B",
        "XI-A",
        "XI-B",
      ],
    };
  },
  methods: {
    selectPrevSidelines(sideLine, amount) {
      let findSideLine = this.sidelines.indexOf(sideLine);
      let filterSlices = [];
      if (findSideLine <= 2) {
        switch (findSideLine) {
          case 0:
            filterSlices = ["I"];
            break;
          case 1:
            filterSlices = ["I", "I-A"];
            break;
          case 2:
            filterSlices = ["I", "I-A", "I-B"];
            break;

          default:
            filterSlices = [];
            break;
        }
      }
      else {
        filterSlices = this.sidelines.splice(findSideLine - amount, amount + 1);
      }
      return filterSlices;
    },
  },
};

export default slidelinesMixin;
