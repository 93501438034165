<script setup>
import config from "@/services/config";
import { useRouter } from "vue-router/composables";
import { formatDateDDMMYYYY, formatTimeToAMPM } from "@/helpers/datesHelpers";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const uriResources = config.beerOfficeURL;
const router = useRouter();

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4 tw-p-3 tw-border tw-border-gray-300 tw-rounded-2.5xl"
  >
    <p class="tw-m-0 tw-font-robotoBold tw-text-lg">{{ props.event.name }}</p>
    <div class="tw-relative">
      <img
        :src="`${uriResources}/${props.event.resource}`"
        alt=""
        class="tw-aspect-[16/9] tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
      />
      <div
        class="tw-absolute tw-top-5 tw-right-0 tw-z-10 tw-w-fit tw-bg-green-450 tw-rounded-l-2xl tw-p-2"
        v-if="props.event.registered_user"
      >
        <p
          class="tw-m-0 tw-text-xs lg:tw-text-sm tw-font-robotoBold tw-text-white tw-text-center"
        >
          ¡Ya estás inscrito!
        </p>
      </div>
    </div>
    <p
      v-if="props.event.active === 1"
      class="tw-m-0 tw-font-robotoBold tw-text-sm tw-text-red-350 tw-text-center"
    >
      Evento disponible
    </p>
    <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
      {{ props.event.desc }}
    </p>
    <div class="tw-flex tw-justify-between tw-items-center">
      <p class="tw-m-0 tw-font-roboto tw-text-sm">
        <span class="tw-font-robotoBold">Fecha: </span>
        {{ formatDateDDMMYYYY(props.event.date, "-") }}
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-sm">
        <span class="tw-font-robotoBold">Hora: </span>
        {{ formatTimeToAMPM(props.event.start_time) }}
      </p>
    </div>
    <p class="tw-m-0 tw-font-roboto tw-text-sm tw-text-red-350">
      Cupos disponibles: {{ props.event.places_available }}
    </p>
    <comp-button-primary-red
      title="Conoce más"
      @click.native="router.push(`/events/${props.event.id}`)"
    />
  </div>
</template>

<style scoped></style>
