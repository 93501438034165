<template>
  <div class="py-3 tw-bg-white tw-shadow-md tw-rounded-lg tabs-2">
    <p class="text-center tw-text-lg font-weight-bold mb-4">
      Conoce tu progreso de este mes
    </p>
    <b-tabs
      active-nav-item-class="tw-text-red-200"
      active-tab-class="tw-text-gray-600"
      content-class="mt-n0"
      fill
    >
      <b-tab
        :title="infoTab.variable_group"
        v-for="(infoTab, index) in user.remuneration.variables"
        :key="'tab-' + index"
      >
        <div class="container mt-4">
          <div class="row mx-2 position-relative tw-z-1 overflow-auto">
            <div class="d-flex">
              <div
                v-for="(metric, index) in infoTab.metrics"
                :key="'metric-' + index"
              >
                <CompInnerTableProgress
                  :title="infoTab.variable_group"
                  :metric="metric"
                  :rates="infoTab.rates"
                  :salaryBase="variableRemuneration"
                />
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CompInnerTableProgress from "@/components/show-me-the-money/variable-compensation/CompInnerTableProgress";

export default {
  name: "CompTableProgressMonth",
  components: {
    CompInnerTableProgress,
  },
  computed: {
    ...mapState("modCore", ["user"]),
    variableRemuneration() {
      return this.user.variable_salary / 12;
    },
  },
};
</script>
