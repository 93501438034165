import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";
import { useUserStore } from "@/stores/userStore";

const uri = {
  validateTokenUri: "/identification/validateToken",
  identificationAccessUri: "/identification/access",
  identificationsActivateUri: "/identification/activate",
  identificationAccountUri: "/identification/account",
  accessSSOUri: "/identification/accessSSO",
};

export const useAuthStore = defineStore("auth", () => {
  const token = sessionStorage.getItem("token") || "";
  const tokenIsValid = ref(false);
  const userStore = useUserStore();
  const setPassword = ref(0);

  async function actValidateToken() {
    const response = await AxiosBeerOffice.get(uri.validateTokenUri, true, {});
    if (response.error) return response.error;
    const { data: tokenData } = response;
    tokenIsValid.value = tokenData;
  }

  async function actAuthLogin(data) {
    const response = await AxiosBeerOffice.post(
      uri.identificationAccessUri,
      false,
      data
    );
    if (response.error) return response.error;
    userStore.setUser(response.user);
    sessionStorage.setItem("token", response.api_token);
    return true;
  }

  async function actAuthLoginSSO(data) {
    const response = await AxiosBeerOffice.post(uri.accessSSOUri, false, data);
    if (response.error) return response.error;
    userStore.setUser(response.data?.user);
    sessionStorage.setItem("token", response.data?.api_token);
    return true;
  }

  async function actAuthLogout() {
    tokenIsValid.value = false;
    localStorage.removeItem("user");
    sessionStorage.clear();
  }

  async function actAuthAccount(data) {
    const response = await AxiosBeerOffice.patch(
      uri.identificationAccountUri,
      false,
      data
    );
    if (response.error) return false;
    userStore.setUser(response.data?.user);
    sessionStorage.setItem("token", response.data?.api_token);
    return response;
  }

  async function actAuthActivate(data) {
    data.id = userStore.user.id;
    if (data.setPassword !== 1) {
      data.cc = userStore.user.cc;
    }
    const response = await AxiosBeerOffice.patch(
      uri.identificationsActivateUri,
      true,
      data
    );
    if (response.error) return response.error;
    return response !== false;
  }

  return {
    token,
    tokenIsValid,
    actValidateToken,
    actAuthLogin,
    actAuthLoginSSO,
    actAuthLogout,
    actAuthAccount,
    actAuthActivate,
    setPassword,
  };
});
