<script setup>
import { formatDateShortDDMMM } from "@/helpers/datesHelpers";
import { computed } from "vue";

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
});

const viewsFormatted = computed(() => {
  let formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(props.article.views);
});
</script>
<template>
  <div>
    <div class="tw-flex tw-gap-x-2 md:tw-gap-x-5 tw-justify-start">
      <div
        class="tw-flex tw-gap-x-1 tw-text-black tw-items-center tw-cursor-pointer"
      >
        <i
          class="icon-calendar-round tw-text-sm md:tw-text-base lg:tw-text-lg"
        ></i>

        <span class="tw-font-roboto tw-text-xs md:tw-text-xs lg:tw-text-sm">{{
          formatDateShortDDMMM(props.article.created_at)
        }}</span>
      </div>
      <div
        class="tw-flex tw-gap-x-1 tw-text-black tw-items-center tw-cursor-pointer"
      >
        <i class="icon-reloj tw-text-sm md:tw-text-base lg:tw-text-lg"></i>
        <span class="tw-font-roboto tw-text-xs md:tw-text-xs lg:tw-text-sm">{{
          props.article.reading_time + " min"
        }}</span>
      </div>
      <div
        class="tw-flex tw-gap-x-1 tw-text-black tw-items-center tw-cursor-pointer"
      >
        <i
          class="icon-articleviews tw-text-sm md:tw-text-base lg:tw-text-lg"
        ></i>
        <span class="tw-font-roboto tw-text-xs md:tw-text-xs lg:tw-text-sm">{{
          viewsFormatted
        }}</span>
      </div>
    </div>
  </div>
</template>
