<script setup>
import { onMounted } from "vue";
import ProgressBar from "@/components/general/ProgressBar.vue";
import $eventHub from "@/components/eventHub";
import { useUserStore } from "@/stores/userStore";
import { useProfileStore } from "@/stores/profileStore";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router/composables";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { getBand, getAge } from "@/helpers/userHelpers";

const userStore = useUserStore();
const profileStore = useProfileStore();
const authStore = useAuthStore();
const router = useRouter();

function getSearchParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

onMounted(async () => {
  $eventHub.$emit("asyncComponentLoading"); // Start progress bar
  try {
    const params = getSearchParams();
    const state = sessionStorage.getItem("state");

    if (params.state === state) {
      const authResponse = await authStore.actAuthLoginSSO({
        code: params?.code,
      });
      const userResponse = await userStore.actGetUserById(userStore.user?.id);

      if (authResponse !== true) {
        await router.push({
          name: "Login",
          params: { errorMessage: authResponse },
        });
        return;
      }

      await profileStore.actGetProfileOnboarding();
      await profileStore.actGetProfileQuestions();

      if (!userResponse.id || !userStore.user?.prof_profile_id) {
        if (profileStore.profileOnboardingSlides.length > 0) {
          await router.push({ path: "onBoard" });
        } else if (profileStore.profileQuestions.length > 0) {
          await router.push({ path: "questions" });
        }
      }
    }

    const redirect = sessionStorage.getItem("redirect");
    sessionStorage.removeItem("redirect");

    await pushDataLayer({
      event_category: "Login",
      userID: userStore.user?.cc,
      Banda: getBand(userStore.user),
      Cargo: userStore.user?.core_position?.name,
      Localizacion: userStore.user?.core_organizational_unit?.name,
      Convenio: userStore.user?.ben_agreement?.name,
      Tipodecontrato: userStore.user?.ben_contract.acronym,
      Area: userStore.user?.core_vp.name,
      Regional: userStore.user?.core_site.core_regional?.name,
      Genero: userStore.user?.gender,
      Edad: getAge(userStore.user?.birthdate),
      Sociedad: userStore.user?.core_site?.name,
    });

    await router.replace(redirect || "/home");
  } catch (error) {
    await router.push({ path: "login" });
  }
});
</script>
<template>
  <div class="container-fluid">
    <progress-bar :duration="10000"></progress-bar>
    <div class="splash row">
      <div class="col-9 col-md-4 col-md-lg-3 mx-auto">
        <div
          class="d-flex flex-column h-100 justify-content-center align-items-center"
        >
          <img
            alt="Embajadores BeerOffice"
            class="main-logo tw-w-full"
            src="@/assets/img/logo-embajadores.png"
          />
          <p class="mt-4 text-center">Versión 1.0</p>
          <div class="cont-logos mt-5 pt-4 w-100 d-flex justify-content-center">
            <img
              alt="Bavaria"
              class="logo-bavaria"
              src="@/assets/img/logo-bavaria.svg"
            />
            <img
              alt="Bavaria"
              class="logo-dp d-none"
              src="@/assets/img/logo-DPxB.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.splash {
  color: #d32e24;
  height: 100vh;
}

.main-logo {
  width: 100%;
  max-width: 400px;
  height: auto;
  text-align: center;
}

.logo-bavaria {
  width: 70px;
}

.logo-dp {
  width: 100px;
}

@media (min-width: 992px) {
  .logo-bavaria {
    width: 100px;
  }

  .logo-dp {
    width: 150px;
  }
}
</style>
