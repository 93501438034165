<template>
  <div class="card w-100 pb-3">
    <div class="mt-3 font-weight-bold">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3 text-left d-block d-md-none mb-2">
            <img class="icon-event" :src="imgIconEvents" alt />
          </div>
          <div class="col-12 col-md-9">
            <h5 class="text-uppercase textColorPrimary tw-font-robotoBold tw-text-sm md:tw-text-lg">Tus<br />eventos</h5>
          </div>
          <div class="col-md-3 text-right d-none d-md-block pl-1">
            <img class="icon-event" :src="imgIconEvents" alt />
          </div>
        </div>
      </div>
      <div class="col-12 mt-3" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="@/assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div class="col-12 mt-3" v-else-if="lastEvents.length === 0">
        <div class="alert alert-warning" role="alert">
          No hay eventos disponibles.
        </div>
      </div>
      <div v-else class="col-12 mt-3">
        <div
          v-for="event in lastEvents"
          :key="'event-' + event.id"
          class="cont-1 mb-3 d-flex"
        >
          <img
            src="@/assets/img/icons/green-circle.png"
            alt="circulo"
            class="icon mr-2 tw-w-3"
          />
          <div class="cont-txt">
            <p class="date mb-0">
              <span class="text-capitalize">{{
                getMonthName(event.month)
              }}</span>
              {{ event.day }} - {{ convertFrom24To12Format(event.start_time) }}
            </p>
            <p class="desc mb-0">{{ event.name }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <span class="link" @click="toggleViewCalendar()">Detalle</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import datesMixin from "@/mixins/general/dates";

export default {
  name: "CompEvents",
  mixins: [datesMixin],
  data() {
    return {
      isLoading: false,
      lastEvents: [],
      imgIconEvents: require("@/assets/img/icons/circulo-esquinas.png"),
    };
  },
  created() {
    this.isLoading = true;

    let currentYear = new Date().getFullYear();
    this.actGetCalendarData({ year: currentYear })
      .then(() => {
        this.lastEvents = this.events.splice(0, 3);
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCalendar", ["events"]),
  },
  methods: {
    ...mapActions("modCalendar", [
      "actGetCalendarData",
      "actGetEventCategories",
    ]),
    ...mapMutations("modCalendar", ["toggleViewCalendar"]),
  },
};
</script>

<style scoped>
.cont-1 .icon {
  flex: 0 0 14px;
  height: 14px;
}

.cont-1 .cont-txt .date {
  color: #00bf79;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
  line-height: 14px;
}

.cont-1 .cont-txt .desc {
  color: #717171;
  font-size: 12px;
}

.link {
  color: #222;
  font-size: 12px;
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
}
.link:hover {
  font-weight: bold;
}
</style>
