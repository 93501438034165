<template>
  <div>
    <div class="mt-md-4 px-0 px-md-3 px-lg-5">
      <!-- Título Desktop y leyenda -->
      <div
        class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
      >
        <div class="col-12 col-md-6 px-0">
          <div class="d-none d-md-flex">
            <p
              class="tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
            >
              La Maltería
            </p>
          </div>
          <div class="d-none d-md-flex">
            <div class="px-3 p-md-0">
              <p
                class="tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"
              >
                Completa misiones, gana puntos y redime premios
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container gamification">
        <div class="row align-items-center">
          <div class="col-12 d-flex categories px-0 py-3 py-md-2">
            <div class="mx-3 d-flex align-items-center">
              <label class="check-group textPlane">
                En curso ({{ contProgress }})
                <input
                  type="checkbox"
                  value="1"
                  v-model="state"
                  v-on:change="filterMissionsData()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="mx-3 d-flex align-items-center">
              <label class="check-group textPlane">
                Completadas ({{ contCompleted }})
                <input
                  type="checkbox"
                  value="2"
                  v-model="state"
                  v-on:change="filterMissionsData()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="row pt-0 pt-md-3 pb-5" v-if="isLoading">
          <div class="col">
            <div class="text-center mt-3">
              <img src="../../assets/img/icons/loading.gif" alt="loading" />
            </div>
          </div>
        </div>

        <div class="row mt-4 mb-2 mb-md-4">
          <div
            class="col-12 col-md-6 col-lg-4 mb-3"
            v-for="mision of filterMissions"
            v-bind:key="mision.id"
          >
            <div
              class="mission-card card h-100"
              :style="{
                background: mision.category.bg_color_2,
                color: mision.category.font_color,
              }"
            >
              <img
                class="mission-background-image"
                :src="uriResources + '/img/' + mision.category.bg_image"
                alt=""
              />
              <div class="mission-content row m-0 h-100">
                <div class="col-7 pr-0 pt-3 pb-2">
                  <div class="d-flex flex-column justify-content-between h-100">
                    <div>
                      <p
                        class="mission-title text-uppercase mb-1"
                        :style="{ color: mision.category.font_color }"
                      >
                        {{ mision.name }}
                      </p>
                      <p
                        class="mission-desc mb-2"
                        :style="{ color: mision.category.font_color }"
                      >
                        {{ mision.description }}
                      </p>
                    </div>
                    <p
                      class="mission-advance m-0"
                      :style="{ color: mision.category.font_color }"
                    >
                      <span>Avance:</span> {{ advance(mision) }}
                    </p>
                  </div>
                </div>
                <div class="col-5 pr-0">
                  <div
                    class="counter d-flex flex-column align-items-center justify-content-center h-100 py-2"
                    :style="{ background: mision.category.bg_color }"
                  >
                    <img
                      v-if="
                        mision.status != null &&
                        mision.status.progress >= mision.status.required
                      "
                      :src="uriResources + '/storage/images/' + mision.image"
                      alt="cover png"
                    />
                    <img
                      v-else
                      :src="
                        uriResources + '/img/' + mision.category.cover_image
                      "
                      alt="cover png"
                    />
                    <div class="text-center mt-2">
                      <p class="points text-uppercase" style="">
                        Puntos: {{ mision.points }}
                      </p>
                      <p class="malts text-uppercase" style="">
                        Maltas: {{ mision.malts }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
      </div>
      <!-- .container -->
    </div>
    <!-- .layout-content -->
    <ScrollToTop />
  </div>
</template>
<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";
import ScrollToTop from "@/components/general/ScrollToTop";

export default {
  name: "gamificationMissions",
  components: {
    ScrollToTop,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      state: [],
      filterMissions: [],
      missionNames: [],
      contProgress: 0,
      contCompleted: 0,
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    this.actGamiMissions()
      .then(() => {
        this.filterMissions = this.missions;
        this.filterMissions.filter((mission) => {
          if (mission.status != null) {
            if (mission.status.progress >= mission.status.required) {
              this.contCompleted++;
            }
            if (mission.status.progress < mission.status.required) {
              this.contProgress++;
            }
          } else {
            this.contProgress += 0;
            this.contCompleted += 0;
            this.getMissionsName();
            this.pushDataLayer(this.contProgress, this.contCompleted);
          }
        });
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["missions"]),
  },
  methods: {
    ...mapActions("modGamification", ["actGamiMissions"]),
    filterMissionsData() {
      if (this.state.includes("1") && this.state.includes("2")) {
        this.filterMissions = this.missions.filter((mission) => {
          return mission.status != null;
        });
      } else if (this.state.includes("1")) {
        this.filterMissions = this.missions.filter((mission) => {
          if (mission.status != null) {
            return mission.status.progress < mission.status.required;
          }
        });
      } else if (this.state.includes("2")) {
        this.filterMissions = this.missions.filter((mission) => {
          if (mission.status != null) {
            return mission.status.progress >= mission.status.required;
          }
        });
      } else {
        this.filterMissions = this.missions;
      }
    },
    advance(item) {
      if (item.status != null) {
        if (item.status.progress >= item.status.required) {
          return "Finalizado";
        } else {
          return (
            item.status.progress + " de " + item.status.required + " veces"
          );
        }
      } else {
        return "Sin progreso";
      }
    },
    getMissionsName() {
      for (let i in this.missions) {
        this.missionNames.push(this.missions[i].name);
      }
    },
    pushDataLayer(contProg, contComp) {
      window.dataLayer.push({
        event: "TrackEvent-Gamification",
        category: "Gamification",
        SubCategory: "Misiones",
        InProgress: contProg,
        Completed: contComp,
        missionType: this.missionNames,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.congg {
  height: 180px;
  width: 100%;
  background: #3ac891 no-repeat;
}

.check-group {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 4px #00000042;
  border-radius: 2px;
  opacity: 1;
}

/* When the checkbox is checked, add a blue background */
.check-group input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-group input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-group .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #d82b2b;
  border-width: 0 2px 2px 0;
  /* -webkit-transform: rotate(45deg); */
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/**Cards */

.mission-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: #ffffff;
  min-height: 160px;
}

.mission-background-image {
  opacity: 0.2;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.mission-content {
  z-index: 3;
}

.mission-content .mission-title {
  height: 40px;
  font-size: 28px;
  line-height: 20px;
  font-family: TolyerBold1, sans-serif;
}

.mission-content .mission-desc {
  font-size: 12px;
  line-height: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mission-content .mission-advance {
  font-size: 14px;
}

.mission-content .counter {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.mission-content .counter img {
  width: 80px;
  height: 80px;
}
.mission-content .counter .points {
  font-family: TolyerBold1, sans-serif;
  font-size: 16px;
  line-height: 15px;
  margin: 0;
}
.mission-content .counter .malts {
  font-family: TolyerBold1, sans-serif;
  font-size: 16px;
  line-height: 15px;
  margin: 0;
}
</style>
