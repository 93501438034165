<template>
  <div class="container tw-mb-4 md:tw-mb-5 mt-md-n4 mt-n3">
    <div class="row align-items-center">
      <div class="col-12 col-lg-7 mb-4 mb-lg-0">
        <div
          class="tw-text-lg mb-0 d-none d-md-block"
          @click="resetFilter"
          role="button"
        >
          <svg
            width="23"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-3"
          >
            <path
              d="M20.823 7.945a1.584 1.584 0 00-.274-.02H4.919l.341-.159a3.17 3.17 0 00.896-.634l4.383-4.383a1.64 1.64 0 00.23-2.1A1.585 1.585 0 008.39.443L.465 8.369a1.585 1.585 0 00-.001 2.241v.002l7.927 7.925a1.585 1.585 0 002.377-.158 1.64 1.64 0 00-.23-2.1l-4.374-4.391a3.167 3.167 0 00-.793-.579l-.476-.214h15.567a1.64 1.64 0 001.672-1.332 1.585 1.585 0 00-1.31-1.818z"
              fill="#717171"
            />
          </svg>
          <span class="tw-text-gray-600 hover:tw-text-gray-600">Summary</span>
        </div>
      </div>
      <div class="col-lg-5 col">
        <div class="container">
          <div class="row justify-content-center justify-content-md-end">
            <div
              v-for="button in psi_buttons"
              :key="button.id"
              class="px-2 col-4"
            >
              <router-link
                :to="button.link"
                class="
                   tw-text-xs  fs-md-14
                  hover:tw-text-gray-600
                  tw-border tw-border-solid tw-border-gray-600
                  py-2
                  tw-rounded-md
                  tw-bg-white
                  tw-text-gray-600
                  hover:tw-bg-gray-200
                  d-block
                  text-center
                "
                @click.native="pushDataLayer(button.name)"
                :class="button.active ? 'tw-bg-gray-200' : ''"
              >
                {{ button.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import psi from "@/mixins/general/psi";

export default {
  name: "PsiBreadcrumbButtons",
  mixins: [psi],
  props: {
    path: {
      type: String,
    },
  },
  computed: {
    ...mapState("modPsi", ["kpi", "vp", "psah", "month"]),
  },
  methods: {
    ...mapActions("modPsi", ["resetFilterData", "getSummaryPsi"]),
    resetFilter() {
      this.$emit("buttonClicked");
      this.resetFilterData();
      this.getSummaryPsi(
        JSON.stringify({
          kpi: this.kpi,
          vp: this.vp,
          psah: this.psah,
          month: this.month,
        })
      );
      this.$router.push({ path: "/indicators/psi/" });
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-TBO",
        category: "Somos Embajadores",
        SubCategory: "Indicadores",
        Action: "Clics",
        Label: label,
      });
    },
  },
  mounted() {
    for (let i = 0; i < this.psi_buttons.length; i++) {
      this.psi_buttons[i].active = this.psi_buttons[i].link === this.path;
    }
  },
};
</script>