<script setup>
import { onBeforeMount, ref } from "vue";
import { useCoreStore } from "@/stores/coreStore";
import Mailbox from "@/components/support/CompMailbox.vue";
import TitleBar from "@/components/general/TitleBar";

const coreStore = useCoreStore();
const isLoading = ref(false);

const getCategories = async () => {
  if (coreStore.suggestionsCategories.length > 0) return;
  isLoading.value = true;
  await coreStore
    .actGetSuggestionsCategories()
    .catch((err) => {
      console.error("Error: ", err);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onBeforeMount(() => {
  getCategories();
});
</script>
<template>
  <div>
    <title-bar title="Buzón de Sugerencias" icon />
    <div class="tw-container-general tw-my-5" v-if="!isLoading">
      <mailbox />
    </div>
  </div>
</template>
