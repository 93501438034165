<template>
  <div class="container">
    <div class="row justify-content-center justify-content-md-end">
      <div
        v-for="item in peopleCycleButtons"
        :key="item.id"
        class="col-6 col-md mb-3 mb-lg-0"
      >
        <router-link
          :to="item.link"
          class="
             tw-text-xs  fs-md-12
            hover:tw-text-gray-600
            tw-border tw-border-solid tw-border-gray-600
            py-2
            px-1
            tw-rounded-md
            tw-bg-white
            tw-text-gray-600
            hover:tw-bg-gray-200
            d-block
            text-center
          "
          @click.native="pushDataLayer(item.name)"
          :class="item.active ? 'tw-bg-gray-200' : ''"
        >
          {{ item.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import cvMixin from "@/mixins/user/cv";

  export default {
    name: "ButtonsPeopleCycle",
    mixins: [cvMixin],
    methods: {
      pushDataLayer(label) {
        window.dataLayer.push({
          event: "TrackEvent-TBO",
          category: "Somos Embajadores",
          SubCategory: "People Cycling",
          Action: "Clics",
          Label: label,
        });
      },
    },
  };
</script>