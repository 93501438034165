import AxiosBeerOffice from "./axiosBeerOffice";
/*
import Axios from "axios";
import config from "./config";
*/

/* all URI to API */
const uri = {
  //Core
  core_employee: "/coreEmployees",
  //Core authentication
  identification_accountUri: "/identification/account",
  identification_activateUri: "/identification/activate",
  identification_accessUri: "/identification/access",
  accessSSO: "/identification/accessSSO",
  validateTokenUri: "/identification/validateToken",
  //Home
  shortcutSectionsUri: "/shortcutSections",
  shortcutPillarsUri: "/shortcutsPillars",
  //Contents
  content_documentsUri: "/contentDocuments",
  sharedResourcesUri: "/sharedResources",
  //Profilling
  profilling_questionsUri: "/profQuestions",
  profilling_onboardingUri: "/profOnboardSlides",
  profilling_onboardEmployeeUri: "/profOnboardEmployees",
  profilling_childrens: "/profChildrens",
  profilling_families: "/profFamilies",
  profilling_pets: "/profPets",
  profilling_hobbies: "/profHobbies",
  //Care and benefits
  benefitsUri: "/benBenefits",
  agreementCategoriesUri: "/benEmpAgreementCategories",
  agreementsUri: "/benEmpAgreements",
  addAgreementUri: "/proposedAgreements",
  //Contribute Country
  sectionsUri: "/contentSections",
  sectionDashCardsUri: "/contentDashCards",
  //Old Articles
  sectionsArticlesUri: "/contentSections/categories",
  articlesUri: "/contentSections/categories/item",
  sections_articlesUri: "/contentSections/categories",
  //New Article
  articlesNewUri: "/articles",
  articleCategoriesUri: "/articleCategories",
  suggestedArticleUri: "/suggestedArticles",
  //Brands
  sections_brandsUri: "/contentBrands",
  sections_itemsBrandsUri: "/contentBrands/subBrand",
  //E-commerce
  ecommerce_products: "/ecommProductTypes",
  locationsUri: "/ecommDeliverSites",
  productDetailUrl: "/ecommProducts",
  orders: "/ecommOrders",
  //Banners
  bannersUri: "/contentBanners",
  //Competitions
  competitionsUri: "/compCompetitions",
  //Variable Compensation
  variableGeneralRanking: "/rankings/general",
  variableGeneralVariableRanking: "/rankings/general/variables",
  variableDirectorRanking: "/rankings/director",
  remunerationUri: "/remunerations",
  variableMoreDetails: "/remunerations/contents",
  regionsFilter: "/remunerations/getRegions",
  managersFilter: "/remunerations/getManagers",
  supervisorsFilter: "/remunerations/getSupervisors",
  bdrFilter: "/remunerations/getBdrs",
  remunerationHistory: "/remunerations/history",
  remunerationHistoryDetailUri: "/remunerations/historyDetail",
  //PQR
  pqrUri: "/pqrs",
  compPostUri: "/compPosts",
  //Notifications
  notificationsUri: "/notifications",
  //Learning
  coursesUri: "/learnCourses",
  contactsUri: "/profFavorites",
  //Calendar
  calendarUri: "/calendarEvents",
  eventCategoriesUri: "/calendarEventCategories",
  //Gamification
  gamificationHelpUri: "/gamiHelps",
  gamificationAvatarElements: "/gamiAvatarElements",
  gamiMissionsUri: "/gamiMissions",
  gamiRewardsUri: "/gamiRewards",
  gamiRewardsHistoryUri: "/gamiRewards/history",
  gamiFirstEntryUri: "/coreEmployees/firstEntryGamification",
  gamiUserRanksUri: "/gamiRanks",
  gamiPredefinedAvatarsUri: "/gamiPredefinedAvatars",
  //Gamification Form
  gamiFormDepartmentsUri: "/departments",
  gamiFormCitiesUri: "/cities",
  gamiFormContactInfoUri: "/gamiRewards/contactInfo",
  //Gamification Malts - Tapit
  tapitMaltsExchangeUri: "/tapitIntegration/exchange",
  tapitTransactionsUri: "/tapitIntegration/transactions",
  tapitVerifyIdNumberUri: "/tapitIntegration/verifyIdNumber",
  //Rating
  ratingUserUri: "/coreReviews",
  ratingsUri: "/coreReviews?category",
  //Graphs
  graphs: "/graphs",
  //Documents
  documentsUri: "/remuBaucher",
  //Certificates
  certificatesUri: "/chatbotIntegration/payrollVoucher",
  //Support
  supportCategories: "/supportCategories",
  supportFaqs: "/supportFAQs",
  supportTickets: "/supportTickets",
  //People
  platformsUri: "/peoplePlatforms",
  //Extra hours
  overtimeItemsUri: "/overtimeItems",
  //CV
  peopleResumeUri: "/peopleResume",
  termsUri: "/coreVps",
  //PSI
  psiTabFilter: "/indicatorDataEngCalcules/getTabFilter",
  psiLeaders: "/indicatorDataEngCalcules/getLeaders",
  psiCategories: "/indicatorDataEngCalcules/getCategories",
  psiGeneralFilter: "/indicatorDataEngCalcules/filter",
  psiTurnOver: "/indicatorDataEngCalcules/filterTo",
  psiPersonalitiesTabFilter: "/indicatorDataEngCalcules/getCoreVps",
  psiPersonalitiesAreaFilter:
    "/indicatorDataEngCalcules/calculatePersonalities",
  psiCompareLeaders: "/indicatorDataEngCalcules/comparisonLeader",
  psiYearsAndMonths: "/indicatorDataEngCalcules/getYearsAndMonths",
  //Vacants
  vacantsUri: "/peopleVacantPositions",
  applyVacantUri: "/peopleVacantPositionEmployees",
  //Places
  placesUri: "/coreSites",
  sitesForCasinoUri: "/coreSitesForCasino",
  //Sidelines
  sideLinesUri: "/corePaSidelines",
  //PSI
  psiUri: "/indicatorDataPsiCalcules",
  //People Cycle
  peopleCycleUri: "/peopleCycle",
  //Movements
  movementsUri: "/peopleMovements",
  //Search
  searchUri: "/contentSearch",
  searchV2Uri: "/contentSearchV2",
  //Trivia
  triviaUri: "/contentTrivia",
  //Volunteers
  volunteerUri: "/volunteers",
  volunteerJourneysUri: "/volunteerJourneys",
  //Gointegro
  goIntegroCardsUri: "/genericCards/GO_INTEGRO/byType",
  //Recomendaciones
  recommendationsCardUri: "/genericCards/HUELLA_ECOLOGICA/byType",
  //Seguimiento
  rankingsUri: "/rankings",
  //Huella ecológica
  ecologicalFootprintUri: "/contentSections",
  //Menus
  menuFoodUri: "/benMenus/byDate",
  //Birthday Gift
  birthdayGiftUri: "/coreEmployees/validateBirthday",
  //Rank People
  rankPeopleUri: "/rankPeople",
  //Areas
  areasUri: "/ourAreas",
  //Advertising
  advertisingUri: "/contentPublicities",
  //News
  newsPostsUri: "/posts",
  //Comments
  commentsUri: "/comments",
  //Share
  shareUri: "/shares",
  //Reactions
  reactionsUri: "/reactions",
  //Reservations
  bookingPlaceCategoriesUri: "/bookingPlaceCategories",
  bookingPlacesUri: "/bookingPlaces",
  bookingsUri: "/bookings",
  bookingSeasonsUri: "/bookingSeasonCategories",
  //Contextual Helpers
  contextualHelpersUri: "/contextualSupports",
  //Suggestions Mailbox
  suggestionsMailboxUri: "/suggestions",
  suggestionsCategoriesUri: "/suggestionCategories",
  //Questions and Contacts
  questionsUri: "/contentQuestions",
  contactsNewUri: "/contentContacts",
  //GoFlex
  goFlexUri: "/goFlex",
};

//all services of beerOffice
export default {
  /*
   * CORE
   */
  validateToken: async () => {
    //validate if the user exists
    return await AxiosBeerOffice.get(uri.validateTokenUri, true, {});
  },
  core_employee: async (data) => {
    //validate if the user exists
    return await AxiosBeerOffice.get(
      uri.core_employee + "/" + data.id,
      true,
      {}
    );
  },
  core_employee_avatar: async (data, id) => {
    return await AxiosBeerOffice.post(
      uri.core_employee + "/" + id + "/profPicture",
      true,
      data,
      true
    );
  },
  /*
   * CORE AUTENTHICATION
   */
  identification_account: async (data) => {
    //validate if the user exists
    return await AxiosBeerOffice.patch(
      uri.identification_accountUri,
      false,
      data
    );
  },
  identification_access: async (data) => {
    //validate if the user exists
    return await AxiosBeerOffice.post(
      uri.identification_accessUri,
      false,
      data
    );
  },
  accessSSO: async (data) => {
    //Access platform by SSO
    return await AxiosBeerOffice.post(uri.accessSSO, false, data);
  },
  identification_activate: async (data) => {
    return await AxiosBeerOffice.patch(
      uri.identification_activateUri,
      true,
      data
    );
  },
  content_documents: async () => {
    return await AxiosBeerOffice.get(uri.content_documentsUri, false, {});
  },

  /** HOME */
  getSharedResources: async () => {
    return await AxiosBeerOffice.get(uri.sharedResourcesUri, true, {});
  },
  getShortcutSections: async () => {
    return await AxiosBeerOffice.get(uri.shortcutSectionsUri, true, {});
  },
  getShortcutPillars: async () => {
    return await AxiosBeerOffice.get(uri.shortcutPillarsUri, true, {});
  },

  /*
   * PROFILLING
   */
  profilling_questions: async () => {
    return await AxiosBeerOffice.get(uri.profilling_questionsUri, true, {});
  },
  profilling_onboarding: async () => {
    return await AxiosBeerOffice.get(uri.profilling_onboardingUri, true, {});
  },
  profilling_onboardEmployee: async (data) => {
    return await AxiosBeerOffice.post(
      uri.profilling_onboardEmployeeUri,
      true,
      data
    );
  },
  profilling_questions_answer: async (data) => {
    return await AxiosBeerOffice.post(uri.profilling_questionsUri, true, data);
  },
  profilling_about_me_data: async (data) => {
    return await AxiosBeerOffice.patch(
      uri.core_employee + "/" + data.id,
      true,
      data
    );
  },
  profilling_childrens: async (id) => {
    return await AxiosBeerOffice.get(
      uri.profilling_childrens + "/" + id,
      true,
      {}
    );
  },
  profilling_childrensUpdate: async (data) => {
    return await AxiosBeerOffice.post(uri.profilling_childrens, true, data);
  },
  profilling_families: async () => {
    return await AxiosBeerOffice.get(uri.profilling_families, true, {});
  },
  profilling_familiesById: async (id) => {
    return await AxiosBeerOffice.get(
      uri.profilling_families + "/" + id,
      true,
      {}
    );
  },
  profilling_familiesUpdate: async (data) => {
    return await AxiosBeerOffice.post(uri.profilling_families, true, data);
  },
  profilling_pets: async () => {
    return await AxiosBeerOffice.get(uri.profilling_pets, true, {});
  },
  profilling_petsById: async (id) => {
    return await AxiosBeerOffice.get(uri.profilling_pets + "/" + id, true, {});
  },
  profilling_petsUpdate: async (data) => {
    return await AxiosBeerOffice.post(uri.profilling_pets, true, data);
  },
  profilling_hobbies: async () => {
    return await AxiosBeerOffice.get(uri.profilling_hobbies, true, {});
  },
  profilling_hobbiesById: async (id) => {
    return await AxiosBeerOffice.get(
      uri.profilling_hobbies + "/" + id,
      true,
      {}
    );
  },
  profilling_hobbiesUpdate: async (data) => {
    return await AxiosBeerOffice.post(uri.profilling_hobbies, true, data);
  },
  /*
   * DIVERSITY AND INCLUSION
   */
  getCategoriesSocialInclusion: async () => {
    return await AxiosBeerOffice.get(uri.sectionsUri + "/1", true, {});
  },

  getCategoryArticles: async (categoryId) => {
    return await AxiosBeerOffice.get(
      uri.sectionsArticlesUri + "/" + categoryId,
      true,
      {}
    );
  },

  getArticle: async (categoryId) => {
    return await AxiosBeerOffice.get(
      uri.articlesUri + "/" + categoryId,
      true,
      {}
    );
  },

  /*
   QUESTIONS AND CONTACTS 
  */

  getQuestions: async (data) => {
    return await AxiosBeerOffice.get(
      uri.questionsUri +
        `?filter={"content_category_key":"${data.content_category_key}"}`,
      true,
      {}
    );
  },

  getContactsNew: async (data) => {
    return await AxiosBeerOffice.get(
      uri.contactsNewUri +
        `?filter={"content_category_key":"${data.content_category_key}"}`,
      true,
      {}
    );
  },
  /*
  getArticleId: async (id) => {
    return await AxiosBeerOffice.get(uri.articlesUri + "/" + id, true, {});
  },*/

  /*
  FAVORITES
  */

  set_favorites: async (data) => {
    let custom_home_object = "[]";
    if (data.favorites.length > 0) {
      custom_home_object = data.favorites;
    }

    return await AxiosBeerOffice.patch(
      uri.core_employee + "/" + data.userId + "/home",
      true,
      { custom_home_object: custom_home_object }
    );
  },
  set_tapitAccount: async (data) => {
    return await AxiosBeerOffice.patch(
      uri.core_employee + "/" + data.userId + "/setTapitId",
      true,
      {
        tapit_id: data.tapit_id,
        tapit_email: data.tapit_email,
        tapit_identity: data.tapit_identity,
        custom_token: data.custom_token,
      }
    );
  },

  /**
   * BRANDS UNIVERSE
   */

  getCategoriesBrands: async () => {
    return await AxiosBeerOffice.get(uri.sections_brandsUri, true, {});
  },
  getArticleBrand: async (id) => {
    return await AxiosBeerOffice.get(
      uri.sections_brandsUri + "/" + id,
      true,
      {}
    );
  },
  getArticlesBrands: async (id) => {
    return await AxiosBeerOffice.get(
      uri.sections_itemsBrandsUri + "/" + id,
      true,
      {}
    );
  },

  /**
   * E-Commerce
   */

  ordersHistory: async (id) => {
    return await AxiosBeerOffice.get(uri.orders + "/history/" + id, true, id);
  },
  ordersPending: async (id) => {
    return await AxiosBeerOffice.get(uri.orders + "/pending/" + id, true, id);
  },

  orderCancel: async (idOrder) => {
    return await AxiosBeerOffice.post(
      uri.orders + "/cancel/" + idOrder,
      true,
      {}
    );
  },

  /*
   * BANNERS
   */

  getBanners: async () => {
    return await AxiosBeerOffice.get(uri.bannersUri, true, {});
  },

  getBannerById: async (id) => {
    return await AxiosBeerOffice.get(uri.bannersUri + "/" + id, true, {});
  },

  /*
   * COMPETITIONS
   */

  getCompetitions: async () => {
    return await AxiosBeerOffice.get(uri.competitionsUri, true, {});
  },

  getCompetitionById: async (id) => {
    return await AxiosBeerOffice.get(uri.competitionsUri + "/" + id, true, {});
  },

  competition_questions_answer: async (data) => {
    return await AxiosBeerOffice.post(uri.competitionsUri, true, data);
  },

  getCompetitionBy_Id_User: async (id, idUser) => {
    return await AxiosBeerOffice.get(
      uri.competitionsUri + "/" + id + "/" + idUser,
      true,
      {}
    );
  },

  postCompetition: async (data) => {
    return await AxiosBeerOffice.post(
      uri.competitionsUri + "/" + data.comp_competition_id + "/compPosts",
      true,
      data,
      true
    );
  },

  compPost: async (data) => {
    return await AxiosBeerOffice.post(uri.compPostUri, true, data, true);
  },

  /**
   * RATING
   */

  getRating: async (data) => {
    return await AxiosBeerOffice.get(
      uri.ratingsUri + "=" + data,
      true,
      data,
      true
    );
  },

  /*
   * VARIABLE COMPENSATION
   */
  getGeneralRankingData: async () => {
    return await AxiosBeerOffice.get(uri.variableGeneralRanking, true, {});
  },

  getGeneralVariableRankingData: async () => {
    return await AxiosBeerOffice.get(
      uri.variableGeneralVariableRanking,
      true,
      {}
    );
  },

  getDirectorRankingData: async (dateType) => {
    return await AxiosBeerOffice.get(
      uri.variableDirectorRanking + "?period=" + dateType,
      true,
      {}
    );
  },

  getVariableMoreDetails: async () => {
    return await AxiosBeerOffice.get(uri.variableMoreDetails, true, {});
  },

  getRegionsFilter: async () => {
    return await AxiosBeerOffice.get(uri.regionsFilter, true, {});
  },

  getManagersFilter: async (regional) => {
    return await AxiosBeerOffice.get(
      uri.managersFilter + "?regional=" + regional,
      true,
      {}
    );
  },

  getSupervisorsFilter: async (regional, manager) => {
    if (manager === undefined || manager === null) {
      return await AxiosBeerOffice.get(
        uri.supervisorsFilter + "?regional=" + regional,
        true,
        {}
      );
    } else {
      return await AxiosBeerOffice.get(
        uri.supervisorsFilter + "?manager=" + manager,
        true,
        {}
      );
    }
  },

  getBdrFilter: async (regional, manager, supervisor) => {
    if (supervisor === undefined || supervisor === null) {
      if (manager === undefined || manager === null) {
        return await AxiosBeerOffice.get(
          uri.bdrFilter + "?regional=" + regional,
          true,
          {}
        );
      } else {
        return await AxiosBeerOffice.get(
          uri.bdrFilter + "?manager=" + manager,
          true,
          {}
        );
      }
    } else {
      return await AxiosBeerOffice.get(
        uri.bdrFilter + "?supervisor=" + supervisor,
        true,
        {}
      );
    }
  },

  getRemunerationHistory: async (
    year,
    month,
    regional,
    manager,
    supervisor,
    bdr
  ) => {
    if (manager === undefined || manager === null) {
      if (supervisor === undefined || supervisor === null) {
        // ;
        return await AxiosBeerOffice.get(
          uri.remunerationHistory +
            "?year=" +
            year +
            "&" +
            "month=" +
            month +
            "&" +
            "regional=" +
            regional +
            "&" +
            "bdr=" +
            bdr,
          true,
          {}
        );
      } else {
        // ;
        return await AxiosBeerOffice.get(
          uri.remunerationHistory +
            "?year=" +
            year +
            "&" +
            "month=" +
            month +
            "&" +
            "regional=" +
            regional +
            "&" +
            "supervisor=" +
            supervisor +
            "&" +
            "bdr=" +
            bdr,
          true,
          {}
        );
      }
    } else {
      return await AxiosBeerOffice.get(
        uri.remunerationHistory +
          "?year=" +
          year +
          "&" +
          "month=" +
          month +
          "&" +
          "regional=" +
          regional +
          "&" +
          "manager=" +
          manager +
          "&" +
          "supervisor=" +
          supervisor +
          "&" +
          "bdr=" +
          bdr,
        true,
        {}
      );
    }
  },

  /*
   * FORM
   */
  postPqr: async (data) => {
    return await AxiosBeerOffice.post(uri.pqrUri, true, data);
  },

  /**
   * Notifications
   */

  getNotifications: async () => {
    return await AxiosBeerOffice.get(uri.notificationsUri, true, {});
  },

  getSections: async () => {
    return await AxiosBeerOffice.get(
      uri.notificationsUri + "/sections",
      true,
      {}
    );
  },

  markAllNotificationsAsRead: async (data) => {
    return await AxiosBeerOffice.post(
      uri.notificationsUri + "/updateBulk",
      true,
      data
    );
  },

  /**
   * Courses
   */

  getContacts: async () => {
    return await AxiosBeerOffice.get(uri.contactsUri, true, {});
  },

  /**
   * Contact Users
   */

  findContactUsers: async (name) => {
    return await AxiosBeerOffice.post(uri.contactsUri + "/find", true, {
      name: name,
    });
  },

  addContactToUser: async (id) => {
    return await AxiosBeerOffice.post(uri.contactsUri, true, { id: id });
  },

  deleteContactToUser: async (id) => {
    return await AxiosBeerOffice.post(uri.contactsUri + "/" + id, true, {});
  },

  /**
   * Calendar
   */

  getCalendarData: async (data) => {
    return await AxiosBeerOffice.post(uri.calendarUri, true, data, true);
  },

  getEventCategories: async () => {
    return await AxiosBeerOffice.get(uri.eventCategoriesUri, true, {});
  },
  /**
   * People
   */

  getPeoplePlatforms: async () => {
    return await AxiosBeerOffice.get(uri.platformsUri, true, {});
  },

  /**
   * Gamification
   */

  updateGamificationUserState: async () => {
    return await AxiosBeerOffice.get(
      uri.core_employee + "/firstEntryGamification",
      true,
      {}
    );
  },

  getGamificationHowItWorkSlides: async () => {
    return await AxiosBeerOffice.get(uri.gamificationHelpUri, true, {});
  },

  getGamificationAvatarElements: async () => {
    return await AxiosBeerOffice.get(uri.gamificationAvatarElements, true, {});
  },

  getGamiPredefinedAvatars: async () => {
    return await AxiosBeerOffice.get(uri.gamiPredefinedAvatarsUri, true, {});
  },

  updateCoreEmployeesAvatar: async (id, data) => {
    return await AxiosBeerOffice.post(
      uri.core_employee + "/" + id + "/avatar",
      true,
      data
    );
  },

  getGamiMissions: async () => {
    return await AxiosBeerOffice.get(uri.gamiMissionsUri, true, {});
  },

  getGamiRewards: async () => {
    return await AxiosBeerOffice.get(uri.gamiRewardsUri, true, {});
  },

  postGamiRewards: async (data) => {
    return await AxiosBeerOffice.post(uri.gamiRewardsUri, true, data);
  },

  getGamiRewardsHistory: async () => {
    return await AxiosBeerOffice.get(uri.gamiRewardsHistoryUri, true, {});
  },

  getGamiUserRanks: async (id) => {
    return await AxiosBeerOffice.get(
      uri.gamiUserRanksUri + "?profile_id=" + id,
      true,
      id
    );
  },

  getGamiRewardsNew: async (id) => {
    return await AxiosBeerOffice.get(uri.gamiRewardsUri + "/" + id, true, {});
  },

  getGamiRewardsStock: async (data) => {
    if (Object.keys(data).length > 1) {
      let uripet =
        uri.gamiRewardsUri +
        "/" +
        data.id +
        "/validateStock?combination=" +
        "[" +
        data.color +
        "," +
        data.talla +
        "]";
      return await AxiosBeerOffice.get(uripet, true, {});
    } else {
      let uripet = uri.gamiRewardsUri + "/" + data.id;
      return await AxiosBeerOffice.get(uripet, true, {});
    }
  },

  getGamiFormDepartments: async () => {
    return await AxiosBeerOffice.get(uri.gamiFormDepartmentsUri, true, {});
  },

  getGamiFormCities: async (id) => {
    return await AxiosBeerOffice.get(
      uri.gamiFormCitiesUri + "?department_id=" + id,
      true,
      {}
    );
  },
  getGamiFormContactInfo: async () => {
    return await AxiosBeerOffice.get(uri.gamiFormContactInfoUri, true, {});
  },

  /**
   * Gamification Malts - Tapit Integration
   */

  postMaltsTapitExchange: async (malts) => {
    return await AxiosBeerOffice.post(
      uri.tapitMaltsExchangeUri + "?malts=" + malts,
      true,
      {}
    );
  },

  getTapitTransactions: async () => {
    return await AxiosBeerOffice.get(uri.tapitTransactionsUri, true, {});
  },
  getTapitVerifyIdNumber: async (accessToken) => {
    return await AxiosBeerOffice.get(
      uri.tapitVerifyIdNumberUri + "?accessToken=" + accessToken,
      true,
      {}
    );
  },

  /**
   * Documents
   */
  postDocuments: async (data) => {
    return await AxiosBeerOffice.post(uri.documentsUri, true, data);
  },

  /**
   * Extra hours
   */
  getOverTimeItmes: async () => {
    return await AxiosBeerOffice.get(uri.overtimeItemsUri, true, {});
  },

  /* User Delivery Site */
  core_employee_delivery: async (id, data) => {
    return await AxiosBeerOffice.patch(
      uri.core_employee + "/" + id + "/ecommDeliverySite",
      true,
      data
    );
  },
  /**
   * CV
   */
  getTerms: async () => {
    return await AxiosBeerOffice.get(uri.termsUri, true, {});
  },
  getPeopleResume: async () => {
    return await AxiosBeerOffice.get(uri.peopleResumeUri, true, {});
  },
  saveProfile: async (data) => {
    return await AxiosBeerOffice.post(
      uri.peopleResumeUri + "/profile",
      true,
      data
    );
  },
  saveEducation: async (data) => {
    return await AxiosBeerOffice.post(
      uri.peopleResumeUri + "/education",
      true,
      data
    );
  },
  saveExperience: async (data) => {
    return await AxiosBeerOffice.post(
      uri.peopleResumeUri + "/workExperience",
      true,
      data
    );
  },
  saveCourse: async (data) => {
    return await AxiosBeerOffice.post(
      uri.peopleResumeUri + "/courses",
      true,
      data
    );
  },
  saveLanguages: async (data) => {
    return await AxiosBeerOffice.post(
      uri.peopleResumeUri + "/languages",
      true,
      data
    );
  },
  /**
   * PSI
   */
  getTabFilter: async () => {
    return await AxiosBeerOffice.get(uri.psiTabFilter, true, {});
  },
  getLeaders: async (data) => {
    return await AxiosBeerOffice.get(
      uri.psiLeaders + "/" + JSON.stringify(data),
      true,
      {}
    );
  },
  getPersonalitiesAreas: async () => {
    return await AxiosBeerOffice.get(uri.psiPersonalitiesTabFilter, true, {});
  },
  getCategoriesPsi: async (data) => {
    return await AxiosBeerOffice.get(
      uri.psiCategories + "/" + JSON.stringify(data),
      true,
      {}
    );
  },
  getYearsAndMonths: async () => {
    return await AxiosBeerOffice.get(uri.psiYearsAndMonths, true, {});
  },

  /*
   * Vacants
   */
  getVacants: async () => {
    return await AxiosBeerOffice.get(uri.vacantsUri, true, {});
  },
  getPlaces: async () => {
    return await AxiosBeerOffice.get(uri.placesUri, true, {});
  },

  getSideLines: async () => {
    return await AxiosBeerOffice.get(uri.sideLinesUri, true, {});
  },
  getVacantById: async (id) => {
    return await AxiosBeerOffice.get(uri.vacantsUri + "/" + id, true, {});
  },
  getSearchVacants: async (data) => {
    return await AxiosBeerOffice.get(
      uri.vacantsUri +
        "?vp=" +
        data.area +
        "&site=" +
        data.site +
        "&sideline=" +
        data.sideline +
        "&text=" +
        data.text,
      true,
      {}
    );
  },
  getGeneralPsiInfo: async (data) => {
    return await AxiosBeerOffice.get(
      uri.psiGeneralFilter + "/" + JSON.stringify(data),
      true,
      {}
    );
  },
  getTurnOverInfo: async (data) => {
    return await AxiosBeerOffice.get(
      uri.psiTurnOver + "/" + JSON.stringify(data),
      true,
      {}
    );
  },
  applyVacant: async (data) => {
    return await AxiosBeerOffice.post(uri.applyVacantUri, true, data);
  },
  getVacantRequests: async () => {
    return await AxiosBeerOffice.get(uri.vacantsUri + "/applied", true, {});
  },
  getVacantRequestsByLineManager: async () => {
    return await AxiosBeerOffice.get(
      uri.vacantsUri + "/byLineManager",
      true,
      {}
    );
  },
  getPersonalitiesData: async (id) => {
    return await AxiosBeerOffice.get(
      uri.psiPersonalitiesAreaFilter + "/" + id.id,
      true,
      {}
    );
  },
  getLeadersInfo: async (data) => {
    return await AxiosBeerOffice.get(
      uri.psiCompareLeaders + "/" + JSON.stringify(data),
      true,
      {}
    );
  },
  changeVacantState: async (data, id) => {
    return await AxiosBeerOffice.post(
      uri.applyVacantUri + "/" + id + "/approved",
      true,
      data
    );
  },
  /**
   * PSI
   */

  getSelectFilters: async () => {
    return await AxiosBeerOffice.get(uri.psiUri + "/getTabFilter", true, {});
  },
  getSummaryPsi: async (data) => {
    return await AxiosBeerOffice.get(uri.psiUri + `?filter=${data}`, true, {});
  },
  /**
   * People Cycle
   */
  getPeopleCycleTabFilter: async () => {
    return await AxiosBeerOffice.get(
      uri.peopleCycleUri + "/getTabFilter",
      true,
      {}
    );
  },
  getPeopleCycleItem: async (id) => {
    return await AxiosBeerOffice.get(
      uri.peopleCycleUri + "/" + id.id,
      true,
      {}
    );
  },
  /**
   * Movements
   */
  getMovements: async (vp) => {
    return await AxiosBeerOffice.get(uri.movementsUri + `?vp=${vp}`, true, {});
  },

  /**
   * Trivia
   */
  getTriviaResult: async (id) => {
    return await AxiosBeerOffice.get(
      uri.triviaUri + `/${id}/getResult`,
      true,
      {}
    );
  },
  answerTrivia: async (data, id) => {
    return await AxiosBeerOffice.post(
      uri.triviaUri + "/" + id + "/answer",
      true,
      data
    );
  },

  /**
   * Go integro
   */

  getFollowMeData: async () => {
    return await AxiosBeerOffice.get(uri.rankingsUri + "/followUp", true, {});
  },
  getRankingTabData: async () => {
    return await AxiosBeerOffice.get(uri.rankingsUri + "/general", true, {});
  },

  /**
   * Menu foods
   */
  /* User Delivery Site */
  food_menu_delivery: async (id, data) => {
    return await AxiosBeerOffice.patch(
      uri.core_employee + "/" + id + "/benMenuSite",
      true,
      data
    );
  },

  /**
   * Rank People
   */
  getRankPeopleFilters: async () => {
    return await AxiosBeerOffice.get(
      uri.rankPeopleUri + "/getFilters",
      true,
      {}
    );
  },
  getRankPeopleResult: async (
    type_filter,
    rank_category,
    rank_people,
    month,
    rank_stage
  ) => {
    if (type_filter === 1) {
      return await AxiosBeerOffice.get(
        uri.rankPeopleUri +
          `/result?filter={"type_filter":${type_filter},"rank_category":${rank_category},"rank_people":${rank_people},"month":${month}}`,
        true,
        {}
      );
    } else {
      return await AxiosBeerOffice.get(
        uri.rankPeopleUri +
          `/result?filter={"type_filter":${type_filter},"rank_category":${rank_category},"rank_people":${rank_people},"rank_stage":${rank_stage}}`,
        true,
        {}
      );
    }
  },
  getRankPeopleByUser: async (filter) => {
    return await AxiosBeerOffice.get(
      uri.rankPeopleUri + "/getInformationSiteByMonth" + filter,
      true,
      {}
    );
  },

  /**
   * Comments
   */

  getComments: async (data) => {
    return await AxiosBeerOffice.get(
      uri.commentsUri +
        "?post_category=" +
        data.post_category +
        "&post_id=" +
        data.post_id,
      true,
      {}
    );
  },
  postComments: async (data) => {
    return await AxiosBeerOffice.post(uri.commentsUri, true, data);
  },
  deleteComments: async (id) => {
    return await AxiosBeerOffice.delete(uri.commentsUri + "/" + id, true, {});
  },
  /**
   * Share
   */

  shareContent: async (data) => {
    return await AxiosBeerOffice.post(uri.shareUri, true, data);
  },
  getEmployeesForShare: async (data) => {
    return await AxiosBeerOffice.get(
      uri.shareUri +
        "/getEmployeesForShare" +
        "?post_category=" +
        data.post_category +
        "&post_id=" +
        data.post_id +
        "&name=" +
        data.name,
      true,
      {}
    );
  },

  /**
   * Reservations
   */

  getBookingPlacesList: async (id) => {
    return await AxiosBeerOffice.get(
      uri.bookingPlaceCategoriesUri + "/" + id + "/bookingPlaces",
      true,
      {}
    );
  },

  getBookingAvailableHours: async (data) => {
    return await AxiosBeerOffice.get(
      uri.bookingsUri +
        "/availableHours?booking_place_id=" +
        data.booking_place_id +
        "&booking_place_option_id=" +
        data.booking_place_option_id +
        "&date=" +
        data.date +
        "&full_place=" +
        data.full_place,
      true,
      {}
    );
  },
  getBookings: async () => {
    return await AxiosBeerOffice.get(uri.bookingsUri, true);
  },

  /**
   * Certificates
   */
  postRequestWorkCertificate: async (data) => {
    return await AxiosBeerOffice.post(uri.certificatesUri, true, data);
  },
  /**
   * Contextual Helpers
   */
  getContextualHelpers: async (route) => {
    return await AxiosBeerOffice.get(
      uri.contextualHelpersUri + "?route=" + route,
      true
    );
  },
  postSeenContextualHelper: async (data) => {
    return await AxiosBeerOffice.post(
      uri.contextualHelpersUri + "/seeRoute",
      true,
      data
    );
  },
  getSeenContextualHelper: async (route) => {
    return await AxiosBeerOffice.get(
      uri.contextualHelpersUri + "/hasSeenRoute?route=" + route,
      true
    );
  },
  /**
   * Suggestions Mailbox
   */
  postSuggestions: async (data) => {
    return await AxiosBeerOffice.post(uri.suggestionsMailboxUri, true, data);
  },
  getSuggestionsCategories: async () => {
    return await AxiosBeerOffice.get(uri.suggestionsCategoriesUri, true, {});
  },
};
