<script setup>
import config from "@/services/config";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";

const store = useStore();
const uriResources = config.beerOfficeURL;
const imgAvatarEmpty = require("@/assets/img/demo/avatar-empty.png");
const { user } = storeToRefs(useUserStore());

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <div class="tw-shadow-md tw-rounded-lg tw-bg-white">
    <div class="tw-bg-gray-200 tw-rounded-lg py-3 px-1 px-md-3">
      <div class="container">
        <div class="row row-cols-2">
          <div class="col">
            <router-link to="/profile/personalData">
              <img
                :src="
                  user.prof_picture !== null
                    ? uriResources + '/' + user.prof_picture
                    : imgAvatarEmpty
                "
                class="wi-120 he-120 tw-rounded-full tw-object-cover"
                alt="img user"
              />
            </router-link>
          </div>
          <div class="col">
            <p
              class="tw-text-gray-600 tw-text-xs text-uppercase font-weight-bold line-height-1 mb-2"
            >
              {{ user.name }}
            </p>
            <p class="tw-text-xs tw-text-gray-600 mb-1">
              Id: <span class="tw-text-gray-300">{{ user.id_sharp }}</span>
            </p>
            <p class="tw-text-xs tw-text-gray-600 mb-1">
              Usuario:
              <span class="tw-text-gray-300">
                {{ user.prof_profile.name || "-" }}
                +</span
              >
            </p>
            <svg
              width="111"
              height="25"
              viewBox="0 0 111 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mt-2"
            >
              <path
                d="M87.2793 23.4615H110.164C110.164 10.6403 99.9552 0.221719 87.2793 0V23.4615Z"
                fill="#D82B2B"
              />
              <path
                d="M29.541 24.4533H42.7153C42.7153 17.07 36.8392 11.0723 29.541 10.9453V24.4533Z"
                fill="#D82B2B"
              />
              <path
                d="M52.4749 24.4522V0.9375H29.541C42.2066 0.9375 52.4749 11.4658 52.4749 24.4522Z"
                fill="#D82B2B"
              />
              <path
                d="M67.2752 15.7787C70.4554 19.0395 70.2807 24.5021 70.2807 24.5021C70.2807 24.5021 64.9509 24.6789 61.7729 21.4204C58.5926 18.1597 58.7673 12.6971 58.7673 12.6971C58.7673 12.6971 64.095 12.5179 67.2752 15.7787Z"
                fill="#FFBF35"
              />
              <path
                d="M73.2768 15.7787C70.0965 19.0395 70.2712 24.5021 70.2712 24.5021C70.2712 24.5021 75.6011 24.6789 78.7791 21.4204C81.9593 18.1597 81.7846 12.6971 81.7846 12.6971C81.7846 12.6971 76.457 12.5179 73.2768 15.7787Z"
                fill="#FFBF35"
              />
              <path
                d="M67.2752 4.02089C70.4554 7.28165 70.2807 12.7442 70.2807 12.7442C70.2807 12.7442 64.9509 12.9211 61.7729 9.66261C58.5926 6.40184 58.7673 0.939256 58.7673 0.939256C58.7673 0.939256 64.095 0.760117 67.2752 4.02089Z"
                fill="#FFBF35"
              />
              <path
                d="M73.2768 4.02089C70.0965 7.28165 70.2712 12.7442 70.2712 12.7442C70.2712 12.7442 75.6011 12.9211 78.7791 9.66261C81.9593 6.40184 81.7846 0.939256 81.7846 0.939256C81.7846 0.939256 76.457 0.760117 73.2768 4.02089Z"
                fill="#FFBF35"
              />
              <path
                d="M0.316406 24.4546H9.30863L4.81252 19.8424L0.316406 15.2324V24.4546Z"
                fill="#00BF79"
              />
              <path
                d="M23.2503 0.9375V10.1597L18.7542 5.54974L14.2559 0.9375H23.2503Z"
                fill="#00BF79"
              />
              <path
                d="M0.316406 0.9375V10.1597L4.81252 5.54974L9.30863 0.9375H0.316406Z"
                fill="#00BF79"
              />
              <path
                d="M23.2503 24.4546H14.2559L18.7542 19.8424L23.2503 15.2324V24.4546Z"
                fill="#00BF79"
              />
              <path
                d="M11.7826 19.3407C15.3614 19.3407 18.2625 16.3661 18.2625 12.6967C18.2625 9.02735 15.3614 6.05273 11.7826 6.05273C8.20387 6.05273 5.30273 9.02735 5.30273 12.6967C5.30273 16.3661 8.20387 19.3407 11.7826 19.3407Z"
                fill="#00BF79"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 pb-3 px-3 position-relative">
      <div class="position-absolute tw-top-2 tw-right-5">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.27494 15.7417C12.7208 19.2009 12.5315 24.9959 12.5315 24.9959C12.5315 24.9959 6.75648 25.1836 3.31306 21.7268C-0.132762 18.2675 0.0565428 12.4725 0.0565428 12.4725C0.0565428 12.4725 5.82913 12.2849 9.27494 15.7417Z"
            fill="#FFBF35"
          />
          <path
            d="M15.7779 15.7417C12.3321 19.2009 12.5214 24.9959 12.5214 24.9959C12.5214 24.9959 18.2964 25.1836 21.7398 21.7268C25.1856 18.2675 24.9963 12.4725 24.9963 12.4725C24.9963 12.4725 19.2213 12.2849 15.7779 15.7417Z"
            fill="#FFBF35"
          />
          <path
            d="M9.27494 3.27098C12.7208 6.7302 12.5315 12.5252 12.5315 12.5252C12.5315 12.5252 6.75648 12.7129 3.31306 9.25606C-0.132762 5.79684 0.0565428 0.00179944 0.0565428 0.00179944C0.0565428 0.00179944 5.82913 -0.185836 9.27494 3.27098Z"
            fill="#FFBF35"
          />
          <path
            d="M15.7779 3.27098C12.3321 6.7302 12.5214 12.5252 12.5214 12.5252C12.5214 12.5252 18.2964 12.7129 21.7398 9.25606C25.1856 5.79684 24.9963 0.00179944 24.9963 0.00179944C24.9963 0.00179944 19.2213 -0.185836 15.7779 3.27098Z"
            fill="#FFBF35"
          />
        </svg>
      </div>
      <div class="mb-4">
        <p class="fs-tw-text-gray-600 font-weight-bold tw-text-sm">
          Acerca de mi
        </p>
        <p class="tw-text-sm tw-text-gray-300">
          {{ store.state.modCv.formData.profile.summary || "-" }}
        </p>
      </div>
      <div class="mb-4">
        <p class="fs-tw-text-gray-600 font-weight-bold tw-text-sm">
          Correo corporativo
        </p>
        <p class="tw-text-sm tw-text-gray-300">
          {{ store.state.modCv.formData.profile.email || "-" }}
        </p>
      </div>
      <div class="mb-4">
        <p class="fs-tw-text-gray-600 font-weight-bold tw-text-sm">
          Correo personal
        </p>
        <p class="tw-text-sm tw-text-gray-300">
          {{ store.state.modCv.formData.profile.email_personal || "-" }}
        </p>
      </div>
      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="mr-1">
            <p class="fs-tw-text-gray-600 font-weight-bold tw-text-sm">
              Fecha de nacimiento
            </p>
            <p class="tw-text-sm tw-text-gray-300">
              {{ store.state.modCv.formData.profile.birthdate || "-" }}
            </p>
          </div>
          <div class="ml-1">
            <p class="fs-tw-text-gray-600 font-weight-bold tw-text-sm">
              Teléfono
            </p>
            <p class="tw-text-sm tw-text-gray-300">
              {{ store.state.modCv.formData.profile.cellphone || "-" }}
            </p>
          </div>
        </div>
      </div>
      <router-link
        v-if="store.state.modCv.is_complete"
        :to="props.url"
        class="d-block tw-bg-red-400 hover:tw-bg-red-600 py-3 px-2 tw-text-white tw-rounded-lg"
      >
        <div
          class="d-flex tw-text-white justify-content-center align-items-center"
        >
          <svg
            width="23"
            height="30"
            viewBox="0 0 23 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-3"
          >
            <path
              d="M20.1978 0H2.8022C1.25734 0 0 1.27747 0 2.84708V24.2984L6.11368 30H20.1978C21.7427 30 23 28.7225 23 27.1529V2.84708C23 1.27747 21.7427 0 20.1978 0ZM5.86919 28.2034L2.02896 24.6219H5.81884C5.84726 24.6219 5.87001 24.645 5.87001 24.6739V28.2034H5.86919ZM21.8629 27.1529C21.8629 28.0854 21.1156 28.8447 20.1978 28.8447H7.00632V24.6739C7.00632 24.0079 6.4735 23.4666 5.81802 23.4666H1.13713V2.84708C1.13713 1.91456 1.88438 1.15534 2.8022 1.15534H20.1978C21.1156 1.15534 21.8629 1.91456 21.8629 2.84708V27.1529Z"
              fill="white"
            />
            <path
              d="M17.2725 19.8223H4.8088C4.49446 19.8223 4.24023 20.0806 4.24023 20.3999C4.24023 20.7193 4.49446 20.9776 4.8088 20.9776H17.2725C17.5868 20.9776 17.8411 20.7193 17.8411 20.3999C17.8411 20.0806 17.586 19.8223 17.2725 19.8223Z"
              fill="white"
            />
            <path
              d="M17.2728 22.1934H10.6223C10.3079 22.1934 10.0537 22.4517 10.0537 22.771C10.0537 23.0904 10.3079 23.3487 10.6223 23.3487H17.2728C17.5872 23.3487 17.8414 23.0904 17.8414 22.771C17.8414 22.4517 17.5864 22.1934 17.2728 22.1934Z"
              fill="white"
            />
            <path
              d="M17.2728 24.5625H10.6223C10.3079 24.5625 10.0537 24.8208 10.0537 25.1402C10.0537 25.4595 10.3079 25.7178 10.6223 25.7178H17.2728C17.5872 25.7178 17.8414 25.4595 17.8414 25.1402C17.8414 24.8208 17.5864 24.5625 17.2728 24.5625Z"
              fill="white"
            />
            <path
              d="M11.6103 9.4816C13.2128 9.4816 14.5164 8.15709 14.5164 6.52889C14.5164 4.90068 13.2128 3.57617 11.6103 3.57617C10.0077 3.57617 8.7041 4.90068 8.7041 6.52889C8.7041 8.15709 10.0077 9.4816 11.6103 9.4816ZM11.6103 4.73151C12.5858 4.73151 13.3793 5.53777 13.3793 6.52889C13.3793 7.52 12.5858 8.32626 11.6103 8.32626C10.6348 8.32626 9.84123 7.52 9.84123 6.52889C9.84042 5.53777 10.6348 4.73151 11.6103 4.73151Z"
              fill="white"
            />
            <path
              d="M6.43787 16.6994H16.5656C16.8799 16.6994 17.1342 16.4411 17.1342 16.1217V15.4722C17.1342 12.3165 14.6073 9.75 11.5021 9.75C8.39698 9.75 5.87012 12.3173 5.87012 15.4722V16.1217C5.86931 16.4411 6.12435 16.6994 6.43787 16.6994ZM7.00643 15.4722C7.00643 12.9536 9.02321 10.9053 11.5013 10.9053C13.9795 10.9053 15.9962 12.9544 15.9962 15.4722V15.544H7.00643V15.4722Z"
              fill="white"
            />
          </svg>
          <span class="tw-text-white tw-text-sm font-weight-bold">{{
            props.buttonText
          }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
