<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useReservationsStore } from "@/stores/reservationsStore";
import { useRouter } from "vue-router/composables";
import GeneralSplash from "@/components/general/GeneralSplash";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import CompCardReservation from "@/components/reservations/CompCardReservation.vue";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompGeneralTopCardDropdown from "@/components/cards/CompGeneralTopCardDropdown.vue";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import { storeToRefs } from "pinia";

const router = useRouter();
const reservationsStore = useReservationsStore();
const isLoading = ref(false);
const isSplash = ref(true);
const optionSelected = ref(null);
const { bookingStep, bookingActive, bookingPlaceDetail } =
  storeToRefs(reservationsStore);
const { openBookingModal } = reservationsStore;

const bookingPlaceCategoryName = (id) => {
  const category = reservationsStore.bookingPlaceCategories.find(
    (category) => category.id === id
  );
  return category ? category.name : "";
};

async function changeOption(option) {
  optionSelected.value = option.id;
  await reservationsStore.actGetBookingPlacesList(option.id);
}

const hasReservation = computed(() => {
  return (
    bookingActive.value &&
    bookingPlaceDetail.value.booking_place_category_id === 1
  );
});

onBeforeMount(async () => {
  isLoading.value = true;
  await reservationsStore.actGetBookingPlaceCategories().then((res) => {
    reservationsStore.actGetBookingPlacesList(res[0].id);
  });
  optionSelected.value = reservationsStore.bookingPlaceCategories[0].id;
  await reservationsStore.actGetBookingBlockedUser();
  bookingStep.value = 1;
  isLoading.value = false;
});
</script>

<template>
  <div>
    <div v-if="isSplash && isLoading">
      <GeneralSplash
        illustration="reservations_home.png"
        title="Reserva de Espacios"
        icon="icon-inside.svg"
        @loaded="isSplash = false"
      >
        Conoce los diferentes espacios que tenemos disponibles para tus
        vacaciones y reuniones.
      </GeneralSplash>
    </div>
    <main v-else>
      <mobile-bar title="Reserva de Espacios" />
      <title-bar title="Reserva de Espacios" />
      <section class="tw-container-general tw-my-0 tw-py-0">
        <comp-general-top-card-dropdown
          title="¿Qué quieres ver?"
          image="assets/img/splash/reservations_cel.png"
          dropdown
        >
          <template #description>
            Conoce los diferentes espacios que tenemos disponibles para tus
            vacaciones y reuniones.
          </template>
          <template #dropdown>
            <CompGeneralDropdown
              @changeSelectedOption="changeOption"
              :isLabel="false"
              :fitted="false"
              :options="reservationsStore.bookingPlaceCategories"
              icon-visible
            />
          </template>
          <template #dropdownMobile>
            <CompGeneralDropdown
              @changeSelectedOption="changeOption"
              :isLabel="false"
              :fitted="false"
              :options="reservationsStore.bookingPlaceCategories"
              :embeddedMobile="true"
              icon-visible
            />
          </template>
        </comp-general-top-card-dropdown>
        <hr class="tw-hidden md:tw-block tw-mb-0" />
      </section>
      <aside
        id="blockedUserBookingDisclaimer"
        class="tw-bg-pink-100 -tw-mt-1"
        v-if="reservationsStore.bookingBlockedUser"
      >
        <div class="tw-container-general">
          <div class="tw-flex tw-items-center tw-gap-x-5 tw-py-3 md:tw-py-5">
            <i class="icon-lot tw-text-red-350 tw-text-4xl md:tw-text-6xl"></i>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base tw-text-red-350"
            >
              Te encuentras bloqueado por no asistir a tu última reserva, debes
              esperar 6 meses para realizar una.
            </p>
          </div>
        </div>
      </aside>
      <section class="tw-container-general">
        <div
          class="tw-bg-pink-100 tw-px-5 tw-py-4 tw-flex tw-justify-between tw-items-center tw-gap-5 lg:tw-gap-10"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg">
            {{
              hasReservation.value
                ? "Tienes una reserva activa, no puedes realizar otra hasta que canceles o se complete tu reserva"
                : "¿Quieres reservar alguno de estos espacios?"
            }}
          </p>
          <comp-button-primary-red
            :title="
              hasReservation.value ? 'Ver historial de reservas' : 'Reserva'
            "
            class="tw-w-28 lg:tw-w-36"
            @click.native="
              hasReservation.value
                ? router.push({ name: 'BookingHistory' })
                : openBookingModal()
            "
          />
        </div>
        <div
          class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3 md:tw-gap-10 tw-z-1 tw-mt-5 tw-mb-10"
        >
          <div
            v-for="(place, idx) in reservationsStore.bookingPlacesList"
            :key="idx"
          >
            <comp-card-reservation
              :place="place"
              :blocked="reservationsStore.bookingBlockedUser"
              @click.native="
                pushDataLayer({
                  event_category: 'Reservas de espacios',
                  event_action: bookingPlaceCategoryName(
                    place.booking_place_category_id
                  ),
                  event_label: place.name,
                  component_name: 'Click',
                })
              "
            />
            <hr class="tw-my-7 tw-block md:tw-hidden" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
