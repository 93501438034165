<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useTimeBenefitsStore } from "@/stores/timeBenefitsStore";
import { useProfileStore } from "@/stores/profileStore";
import { useUserStore } from "@/stores/userStore";
import { useRoute, useRouter } from "vue-router/composables";
import { formatDateYYYYMMDD } from "@/helpers/datesHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import SkeletonTimeBenefitsDetail from "@/components/timeBenefits/skeletons/SkeletonTimeBenefitsDetail.vue";

const profileStore = useProfileStore();
const timeBenefitsStore = useTimeBenefitsStore();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const selectedDay = ref(0);
const isLoading = ref(false);
const isModalOpen = ref(false);
const successfulRequest = ref(false);
const requestID = ref("");

const getUserFirstName = () => {
  const userName = userStore.user?.name;
  return userName?.split(" ")[0];
};

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const sendRequest = async () => {
  const date = new Date();
  const today = formatDateYYYYMMDD(date, "-");
  const tomorrow = formatDateYYYYMMDD(
    new Date(date.setDate(date.getDate() + 1)),
    "-"
  );
  const selectedDate = selectedDay.value === 0 ? today : tomorrow;
  await timeBenefitsStore
    .actPostTimeBenefitRequest(route.params.id, selectedDate)
    .then((res) => {
      successfulRequest.value = !!res.data;
      if (res.data) requestID.value = res.data.id;
    });

  openModal();
};

const hasPets = computed(() => profileStore.userPets.length > 0);

const hasPerroOrGato = computed(() => {
  const petTypes = profileStore.userPets?.map((pet) =>
    pet?.type?.toLowerCase()
  );
  return (
    hasPets.value && (petTypes.includes("perro") || petTypes.includes("gato"))
  );
});

const isLastRequestCompleted = computed(() => {
  const requests = timeBenefitsStore.userTimeBenefitsRequests;

  // Si no hay solicitudes, retorna true ya que no hay ninguna solicitud.
  if (requests.length === 0) {
    return true;
  }

  // Retorna true si la última solicitud está en estado "COMPLETED" o "APPROVED".
  return ["COMPLETED", "APPROVED"].includes(requests[0].status);
});

const noRestrictions = computed(
  () => hasPerroOrGato.value && isLastRequestCompleted.value
);

onBeforeMount(async () => {
  isLoading.value = true;
  await Promise.all([
    timeBenefitsStore.actGetTimeBenefitsRequests().then(() => {
      if (!noRestrictions.value) {
        router.push({
          name: "timeBenefits",
        });
      }
    }),
    timeBenefitsStore.actGetTimeBenefitById(route.params.id),
  ]);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <portal to="all-modals">
      <general-modal
        :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
        :modalWidth="{ md: 450, lg: 500, xl: 550 }"
        modal-name="avatar_saved"
        v-if="isModalOpen"
        :isShowModal="isModalOpen"
        @close="closeModal"
      >
        <div
          class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
          v-if="successfulRequest"
        >
          <div
            class="tw-flex tw-gap-10 tw-items-center tw-w-full tw-justify-center"
          >
            <img
              src="@/assets/img/modal/modal-success.svg"
              class="tw-max-h-20"
              alt=""
            />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-green-450"
            >
              LISTO
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            <span class="tw-capitalize">{{
              getUserFirstName().toLowerCase()
            }}</span
            >, tu solicitud de tiempo ha sido creada con éxito y le
            notificaremos a tu Line Manager.
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            Recuerda que podrás subir el documento de defunción en los
            siguientes 5 días.
          </p>

          <div class="tw-w-full tw-flex tw-gap-2">
            <div
              class="tw-btn-primary-red"
              @click="
                router.push({
                  name: 'timeBenefitsUploadVoucher',
                  params: { id: requestID },
                })
              "
            >
              <p class="tw-m-0">Subir un comprobante</p>
            </div>
            <div
              class="tw-btn-secondary-white"
              @click="
                router.push({
                  name: 'timeBenefitsHistory',
                })
              "
            >
              <p class="tw-m-0">Hacerlo después</p>
            </div>
          </div>
        </div>
        <div
          class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
          v-else
        >
          <div
            class="tw-flex tw-gap-10 tw-items-center tw-w-full tw-justify-center"
          >
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
            >
              ¡Lo sentimos!
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            Algo salió mal
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            Cierra sesión e inténtalo nuevamente más tarde.
          </p>

          <div class="tw-w-full tw-flex tw-gap-2">
            <div class="tw-btn-primary-red" @click="closeModal">
              <p class="tw-m-0">Cerrar</p>
            </div>
          </div>
        </div>
      </general-modal>
    </portal>
    <mobile-bar title="Beneficio Familias Felices" />
    <title-bar title="Beneficio Familias Felices" />
    <main class="tw-container-general">
      <section
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-col2_fit_first tw-gap-4 lg:tw-gap-6 tw-p-2 lg:tw-p-4 tw-border tw-border-blue-700 tw-rounded-2xl lg:tw-rounded-3xl"
        v-if="!isLoading"
      >
        <div class="div-img">
          <img
            src="@/assets/img/timeBenefits/time-benefit-detail-img.png"
            alt=""
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4 tw-justify-evenly">
          <h4 class="tw-font-black tw-font-robotoMedium">
            {{ timeBenefitsStore.timeBenefitDetail?.name }}
          </h4>
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm lg:tw-pr-10">
            {{ timeBenefitsStore.timeBenefitDetail?.description }}
          </p>
          <div
            class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-6"
          >
            <div
              class="lg:tw-col-span-3 tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-4"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                Fecha
              </p>
              <div class="tw-flex tw-items-center tw-gap-4 tw-w-full">
                <comp-button-custom
                  text="Hoy"
                  color="white"
                  textColor="blue-700"
                  borderColor="blue-700"
                  class="tw-w-full lg:tw-w-[100px]"
                  :active="selectedDay === 0"
                  @click.native="selectedDay = 0"
                />
                <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                  ó
                </p>
                <comp-button-custom
                  text="Mañana"
                  color="white"
                  textColor="blue-700"
                  borderColor="blue-700"
                  class="tw-w-full lg:tw-w-[100px]"
                  :active="selectedDay === 1"
                  @click.native="selectedDay = 1"
                />
              </div>
            </div>
            <div>
              <comp-button-custom
                text="Siguiente"
                color="blue-700"
                textColor="white"
                borderColor="blue-700"
                class="tw-w-full"
                @click.native.once="sendRequest"
              />
            </div>
          </div>
        </div>
      </section>
      <skeleton-time-benefits-detail v-else />
    </main>
  </div>
</template>

<style scoped lang="postcss">
.div-img {
  @apply tw-flex tw-items-center tw-justify-center tw-h-full;
}

.div-img img {
  @apply tw-max-h-[auto] tw-max-w-[auto] tw-rounded-2xl lg:tw-rounded-3xl;
}
</style>
