<script setup>
import { useRouter } from "vue-router/composables";
import { todayIsBetweenDates, formatDateDDMMMM } from "@/helpers/datesHelpers";
import AtomText from "@/components/atoms/AtomText.vue";
import MoleculeCardHeaderBodyFooter from "@/components/molecules/MoleculeCardHeaderBodyFooter.vue";
import AtomMedia from "@/components/atoms/AtomMedia.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";

const router = useRouter();

const props = defineProps({
  stages: {
    type: Array,
    required: true,
  },
});

const goToActivities = (id) => {
  router.push(`/vamosPaLaCalle/actividades/${id}`);
};
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-5">
    <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-6">
      <molecule-card-header-body-footer
        v-for="(stage, idx) in props.stages"
        class="tw-w-full tw-place-self-center tw-h-full"
        :key="idx"
        header-bg-color="red-350"
        border
        rounded
        rounded-size="2.5xl"
      >
        <template #header>
          <atom-text
            tag="h3"
            font="robotoBold"
            text-size-mobile="xl"
            text-size-desktop="xl"
            text-color="white"
            text-alignment="center"
          >
            {{ stage.name }}
          </atom-text>
        </template>
        <template #body>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
            <atom-media type="image" :src="stage.image" custom-width="w-9/12" />
            <div
              class="tw-flex tw-justify-between tw-items-center tw-gap-2"
              v-if="todayIsBetweenDates(stage.start_date, stage.end_date)"
            >
              <div class="tw-flex tw-flex-col tw-items-center tw-gap-2">
                <atom-text
                  tag="p"
                  font="robotoBold"
                  text-size-mobile="xl"
                  text-size-desktop="3xl"
                >
                  10
                </atom-text>
                <atom-text
                  tag="p"
                  font="roboto"
                  text-size-mobile="xs"
                  text-size-desktop="base"
                  text-alignment="center"
                >
                  Horas completadas
                </atom-text>
              </div>
              <div class="tw-flex tw-flex-col tw-items-center tw-gap-2">
                <atom-text
                  tag="p"
                  font="robotoBold"
                  text-size-mobile="xl"
                  text-size-desktop="3xl"
                >
                  5
                </atom-text>
                <atom-text
                  tag="p"
                  font="roboto"
                  text-size-mobile="xs"
                  text-size-desktop="base"
                  text-alignment="center"
                >
                  Actividades completadas
                </atom-text>
              </div>
            </div>
          </div>
          <div
            v-if="!todayIsBetweenDates(stage.start_date, stage.end_date)"
            class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-5 tw-mt-3 lg:tw-mt-4"
          >
            <atom-text
              tag="p"
              font="robotoBold"
              text-size-mobile="lg"
              text-size-desktop="2xl"
              text-alignment="center"
            >
              ¡Próximamente!
            </atom-text>
            <atom-text
              tag="p"
              font="roboto"
              text-size-mobile="xs"
              text-size-desktop="base"
              text-alignment="center"
            >
              Entre el {{ formatDateDDMMMM(stage.start_date) }} y el
              {{ formatDateDDMMMM(stage.end_date) }}
            </atom-text>
          </div>
        </template>
        <template
          #footer
          v-if="todayIsBetweenDates(stage.start_date, stage.end_date)"
        >
          <atom-button
            bg-color="red-350"
            rounded
            class="tw-w-full"
            @button-click="goToActivities(stage.id)"
          >
            <atom-text
              tag="span"
              font="roboto"
              text-size-mobile="sm"
              text-size-desktop="base"
              text-color="white"
              text-alignment="center"
              class="tw-place-self-center"
            >
              Conocer más
            </atom-text>
          </atom-button>
        </template>
      </molecule-card-header-body-footer>
    </div>
  </div>
</template>

<style scoped></style>
