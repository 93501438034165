<template>
  <div class="layout-content mt-0 mt-md-4 px-0 px-md-3 px-lg-5">
    <GamificationMainMenu />
    <div
      class="container-fluid gamification"
      :style="{
        backgroundImage: `url('${imgBgGamification}')`,
      }"
    ></div>
    <b-modal
      ref="modal-item"
      id="modal-sell"
      centered
      hide-header
      hide-footer
      size="lg"
      no-close-on-esc
      role="dialog"
      class="cont-modal"
    >
      <div class="row cont-card p-1 p-md-4">
        <div class="col-12 col-md-6 text-center">
          <img
            class="img-reward"
            :src="uriResources + '/storage/images/' + historyId.reward_image"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-end cont-img-symbol">
            <img class="d-none d-md-block" :src="imgSymbol" alt="" />
          </div>
          <p class="title">{{ historyId.reward_name }}</p>
          <p class="description">{{ historyId.reward_description }}</p>
          <div class="d-flex justify-content-center mt-5">
            <img
              class="d-block d-md-none"
              :src="imgSymbolHor"
              alt=""
              style="width: 90px"
            />
          </div>
        </div>
        <router-link
          class="d-block d-md-none"
          to="/gamification/rewardsHistory"
        >
          <img class="img-close-out-modal-mobile" :src="imgX" alt="" />
        </router-link>
      </div>
      <router-link class="d-none d-md-block" to="/gamification/rewardsHistory">
        <img class="img-close-out-modal" :src="imgX" alt="" />
      </router-link>
    </b-modal>
  </div>
</template>
<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";

import GamificationMainMenu from "@/components/gamification/CompGamificationMainMenu";

export default {
  name: "gamificationRedeemHistory",
  components: {
    GamificationMainMenu,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgBgGamification: require("@/assets/img/gamification/background.png"),
      imgSymbol: require("@/assets/img/gamification/symbol-1Vertical.png"),
      imgSymbolHor: require("@/assets/img/gamification/symbol-1.png"),
      imgX: require("@/assets/img/icons/GrupoX.png"),
      historyId: {},
      viewHistoryId: 0,
      resultDiff: 0,
    };
  },
  mounted() {
    this.actGamiRewardsHistory();
    this.itemRewardHistory(this.$route.params.id);
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["missions", "rewards", "history"]),
  },
  methods: {
    ...mapActions("modGamification", [
      "actGamiMissions",
      "actGamiRewards",
      "actGamiRewardsHistory",
    ]),
    itemRewardHistory(itemRewardHistoryId) {
      setTimeout(() => {
        let find = this.history.findIndex(
          (element) => element.id === itemRewardHistoryId
        );
        if (find >= 0) {
          this.historyId = this.history[find];
          this.viewHistoryId = 1;
          this.$refs["modal-item"].show();
        } else {
          this.historyId = {};
        }
      }, 800);
    },
    // formatMoney(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    // },
    // getFirstName(string) {
    //   string = string + "";
    //   let name = string.split(" ");
    //   return name[0].toLowerCase();
    // },
  },
};
</script>
<style scoped>
.gamification {
  background-size: cover;
  padding: 80px 20px 20px 20px;
  height: 80vh;
}
.cont-card {
  padding: 24px;
}
.cont-card .img-reward {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}
.cont-card .cont-img-Symbol img {
  width: 100%;
  height: 136px;
}
.cont-card .title {
  text-align: left;
  font: normal normal bold 32px Roboto;
  letter-spacing: 0;
  color: #222;
}
.cont-card .description {
  text-align: left;
  font: normal normal normal 19px Roboto;
  letter-spacing: 0;
  color: #171717;
}
.img-close-out-modal {
  position: fixed;
  top: 25px;
  right: 24px;
  border-radius: 30px;
  box-shadow: 0 3px 6px #00000029;
  background: #ffffff;
}
@media (max-width: 768px) {
  .cont-card .img-reward {
    width: 175px;
    height: 175px;
    border-radius: 30px;
    object-fit: cover;
  }
  .cont-card .title {
    text-align: center;
    font: normal normal bold 24px Roboto;
    letter-spacing: 0;
    color: #222;
    margin-top: 24px;
  }
  .cont-card .description {
    text-align: center;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #171717;
  }
  .cont-card .img-close-out-modal-mobile {
    position: absolute;
    top: -20px;
    right: -7px;
    border-radius: 30px;
    box-shadow: 0 3px 6px #00000029;
    background: #ffffff;
  }
}
</style>