<script setup>
import { ref, onMounted } from "vue";
import config from "@/services/config";
import { pushLink } from "@/helpers/linkHelpers";
import { useRoute } from "vue-router/composables";
import { useCoreStore } from "@/stores/coreStore";
import { useNavbarStore } from "@/stores/navbarStore";
import Modal from "@/components/modals/Modal";
import { storeToRefs } from "pinia";

const coreStore = useCoreStore();
const { actGetActiveAdvertising, actPostSeenAdvertising } = useCoreStore();
const { advertisingData } = storeToRefs(coreStore);
const { showSupportButton, hideSupportButton } = useNavbarStore();
const route = useRoute();
const uriResources = config.beerOfficeURL;

const isModalVisible = ref(false);

const showModal = () => {
  isModalVisible.value = true;
};

const closeModal = () => {
  isModalVisible.value = false;
  showSupportButton();
  seeAdvertising();
};

const getActiveAdvertising = async () => {
  await actGetActiveAdvertising(route.path.replace("/", ""));
  if (advertisingData.value) {
    hideSupportButton();
    showModal();
  }
};

const seeAdvertising = () => {
  actPostSeenAdvertising({
    content_publicity_id: advertisingData.value.id,
  });
};

onMounted(() => {
  getActiveAdvertising();
});
</script>
<template>
  <div v-if="advertisingData != null">
    <Modal
      v-if="isModalVisible"
      @close="closeModal"
      :timeToViewCloseButton="advertisingData.wait_time"
      :z-index-total="true"
      widthModal="tw-max-w-screen-md"
    >
      <template v-slot:header>
        <div class="tw-p-4">
          <p
            class="tx-text-md md:tw-text-xl tw-text-gray-600 tw-mb-0 tw-font-bold"
          >
            {{ advertisingData.name }}
          </p>
        </div>
      </template>
      <template v-slot:body>
        <div v-if="advertisingData.type === 'IMAGE'">
          <img
            @click="pushLink(advertisingData.link)"
            :src="uriResources + '/' + advertisingData.resource"
            :alt="advertisingData.name"
            class="tw-w-full tw-rounded-lg"
          />
        </div>
        <div v-if="advertisingData.type === 'VIDEO'">
          <video
            :src="uriResources + '/' + advertisingData.resource"
            class="tw-w-full"
            controls
            autoplay
          ></video>
        </div>
      </template>
    </Modal>
  </div>
</template>
