<template>
  <div
    :class="{
      'loading-container tw-fixed tw-top-0 tw-left-0 tw-h-1 tw-w-full tw-hidden tw-transition-opacity tw-duration-200 tw-opacity-0 tw-z-100': true,
      loading: isLoading,
      visible: isVisible,
    }"
  >
    <div
      v-if="overlay"
      class="tw-fixed tw-top-0 tw-left-0 tw-z-40 tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-40"
    ></div>

    <div
      class="loader tw-z-50 tw-relative tw-inline-block tw-bg-red-400 tw-h-full tw-w-1/2 tw-overflow-hidden tw-rounded-b-md tw-transition-all"
      :style="{ width: progress + '%' }"
    >
      <div
        class="light tw-float-right tw-h-full tw-w-1/5 tw-bg-gradient-to-r tw-from-red-400 tw-via-red-300 tw-to-red-400"
      ></div>
    </div>
  </div>
</template>
<script>
import random from "lodash.random";
import $eventHub from "@/components/eventHub";
// Assume that loading will complete under this amount of time.
const defaultDuration = 4000;
// How frequently to update
const defaultInterval = 1000;
// 0 - 1. Add some variation to how much the bar will grow at each interval
const variation = 0.5;
// 0 - 100. Where the progress bar should start from.
const startingPoint = 0;
// Limiting how far the progress bar will get to before loading is complete
const endingPoint = 90;

export default {
  name: "ProgressBar",
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: defaultDuration,
    },
  },
  data: () => ({
    isLoading: true, // Once loading is done, start fading away
    isVisible: false, // Once animate finish, set display: none
    progress: startingPoint,
    timeoutId: undefined,
  }),
  mounted() {
    $eventHub.$on("asyncComponentLoading", this.start);
    $eventHub.$on("asyncComponentLoaded", this.stop);
  },
  methods: {
    start() {
      this.isLoading = true;
      this.isVisible = true;
      this.progress = startingPoint;
      this.loop();
    },
    loop() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (this.progress >= endingPoint) {
        return;
      }
      const size =
        (endingPoint - startingPoint) / (this.duration / defaultInterval);
      const p = Math.round(
        this.progress + random(size * (1 - variation), size * (1 + variation))
      );
      this.progress = Math.min(p, endingPoint);
      this.timeoutId = setTimeout(
        this.loop,
        random(
          defaultInterval * (1 - variation),
          defaultInterval * (1 + variation)
        )
      );
    },
    stop() {
      this.isLoading = false;
      this.progress = 100;
      clearTimeout(this.timeoutId);
      const self = this;
      setTimeout(() => {
        if (!self.isLoading) {
          self.isVisible = false;
        }
      }, 200);
    },
  },
};
</script>
<style scoped lang="postcss">
.loading-container {
  font-size: 0; /* remove space */
}
.loading-container.visible {
  @apply tw-block;
}
.loading-container.loading {
  @apply tw-opacity-100;
}
.loader > .light {
  animation: loading-animation 2s ease-in infinite;
}
@keyframes loading-animation {
  0% {
    margin-right: 100%;
  }
  50% {
    margin-right: 100%;
  }
  100% {
    margin-right: -10%;
  }
}
</style>
