<template>
  <div class="mt-4 mt-md-0">
    <div class="mt-4 pb-3">
      <div
        class="d-flex flex-row tw-overflow-x-scroll invisible-scrollbar people-cycle-carousel"
      >
        <div class="col col-md-4 px-3" v-for="video in videos" :key="video.id">
          <div
            class="d-flex tw-shadow tw-rounded-lg mt-2 overflow-hidden he-270 he-md-400 wi-240 mw-100 wi-md-360 justify-content-center align-items-center position-relative"
          >
            <div class="tw-z-1 position-absolute">
              <img
                :src="uriResources + '/' + video.image"
                loading="lazy"
                width="100%"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="z-index-12 position-absolute icon-pointer text-center wi-60 wi-md-90"
              @click="showModal(video)"
            >
              <img src="../../assets/img/icons/btn-play.png" class="img-fluid" alt="" />
            </div>
          </div>
          <p
            class="tw-font-roboto tw-text-lg line-height-24 text-left tw-text-black mt-3 mb-2"
          >
            {{ video.description }}
          </p>
        </div>
      </div>
      <div
        class="row justify-content-between w-100 position-absolute pl-1 pl-md-0 pr-1 pr-md-0 tw-top-2/4 bg-transparent no-mobile"
      >
        <div
          role="button"
          class="bg-white wi-50 he-50 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
          @click="previous()"
        >
          <i class="icon-flecha-izq fs-50 tw-text-red-400"></i>
        </div>
        <div
          role="button"
          class="bg-white wi-50 he-50 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
          @click="next()"
        >
          <i class="icon-flecha-der fs-50 tw-text-red-400"></i>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      ref="modal-video-player"
      size="xl"
      centered
      title="qualification-video-people-cycle"
      hide-footer
      hide-header
    >
      <button class="close-modal" @click="hideModal">
        <span class="icon-cerrar-simple" @click="hideModal"></span>
      </button>
      <div class="container-video">
        <CompQualificationVideo
          :idVideo="selectedVideo.id"
          :urlVideo="uriResources + '/' + selectedVideo.video"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import config from "@/services/config";
import CompQualificationVideo from "@/components/video/CompVideoQualification.vue";

export default {
  name: "CompCarouselPeopleCycle",
  components: {
    CompQualificationVideo,
  },
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      selectedVideo: {},
    };
  },
  methods: {
    next() {
      document.querySelector(".people-cycle-carousel").scrollLeft += 170;
    },
    previous() {
      document.querySelector(".people-cycle-carousel").scrollLeft -= 170;
    },
    showModal(video) {
      this.selectedVideo = video;
      this.$refs["modal-video-player"].show();
    },
    hideModal() {
      this.$refs["modal-video-player"].hide();
    },
  },
};
</script>

<style scoped>
.container-video {
  width: 100%;
}

.container-video video {
  width: 100%;
}

.close-modal {
  background-color: transparent;
  border: 0;
  font-size: 24px;
  color: #d82b2b;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1;
}
</style>
