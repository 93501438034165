<script setup>
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import OrganismButtonsRow from "@/components/organisms/OrganismButtonsRow.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import OrganismVamosPaLaCalleActividadesGrid from "@/components/organisms/OrganismVamosPaLaCalleActividadesGrid.vue";
import { useEventsStore } from "@/stores/eventsStore";

const { actGetStreetEventStages } = useEventsStore();
const { streetEventStages } = storeToRefs(useEventsStore());
const isLoading = ref(false);

const buttons = ref([
  {
    id: 0,
    icon: "icon-home",
    text: "Conoce la iniciativa",
  },
  {
    id: 1,
    icon: "icon-eventos",
    text: "Actividades",
  },
  {
    id: 2,
    icon: "icon-galeria",
    text: "Galería",
  },
  {
    id: 3,
    icon: "icon-ranking-eventos",
    text: "Ranking",
  },
]);

onBeforeMount(async () => {
  isLoading.value = true;
  const currentYear = new Date().getFullYear();
  await actGetStreetEventStages(currentYear);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Actividades" />
    <title-bar title="Actividades" />
    <main class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
      <organism-buttons-row :buttons="buttons" />
      <atom-text
        tag="h1"
        font="robotoMedium"
        text-size-mobile="xl"
        text-size-desktop="3xl"
      >
        Actividades Eventos 2025
      </atom-text>
      <section
        class="tw-flex tw-justify-between tw-items-center tw-gap-2 lg:tw-gap-4 tw-border tw-border-y tw-border-x-0 tw-py-3 lg:tw-py-5"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl">
          Tu progreso general
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Ranking:</span>
          Supply
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Horas acumuladas:</span>
          15
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Puesto:</span>
          30°
        </p>
      </section>
      <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
        Lorem ipsum dolor sit amet consectetur. Magna nisl sollicitudin dolor
        est ultrices eu. Semper quisque facilisis erat eget ornare egestas
        bibendum sit ac. Eget elementum vitae sem rhoncus. Pellentesque sodales
        dictumst sit et.Lorem ipsum dolor sit amet consectetur. Magna nisl
        sollicitudin dolor est ultrices eu. Semper quisque facilisis erat eget
        ornare egestas bibendum sit ac. Eget elementum vitae sem rhoncus.
        Pellentesque sodales dictumst sit et.
      </p>
      <organism-vamos-pa-la-calle-actividades-grid
        v-if="!isLoading"
        :stages="streetEventStages"
      />
    </main>
  </div>
</template>

<style scoped></style>
