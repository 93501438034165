<template>
  <div class="row pt-3">
    <div class="col">
      <p
        class="m-0 tw-font-robotoBold tw-text-lg fs-md-22 line-height-md-30 tw-text-yellow-300 d-md-inline-block"
      >
        Paso 1.
      </p>
      <p
        class="ml-md-2 my-0 tw-font-robotoBold tw-text-lg fs-md-22 line-height-md-30 d-md-inline-block"
      >
        Simula tu Achievement Score
      </p>
    </div>
    <div
      v-b-modal.modal-achievement
      class="col-1 btn-modal-info d-flex align-items-center justify-content-end"
    >
      <i class="icon-mas-informacion tw-text-lg fs-md-22 tw-text-red-400"></i>
    </div>

    <b-modal
      class="showme d-flex justify-content-center"
      :id="'modal-achievement'"
      centered
      hide-footer
      :modal-title="false"
      size="lg"
    >
      <div class="container text-center px-3 px-md-4">
        <p class="modal-title strong">Ten en cuenta</p>
        <p>Así se calcula el Score</p>
        <img
          class="desc-image d-none d-md-block m-auto"
          src="@/assets/img/show-me/achievement-score.png"
          alt=""
        />
        <img
          class="desc-image d-block d-md-none m-auto"
          src="@/assets/img/show-me/achievement-score-mobile.png"
          alt=""
        />
        <p class="disclaimer mt-3 mt-md-5">
          • El Job Band Multiplier corresponde al % de acuerdo a tu Banda.
          <br />
          <br />• El simulador es una herramienta para calcular valores
          estimados del bono.
          <br />
          <br />• El valor a pagar es el que esté acorde a las políticas de
          compensación de la compañía la cual se reserva el derecho a
          modificarlas.
        </p>
      </div>
    </b-modal>

    <div
      class="col-12 pt-2 tw-font-roboto tw-text-sm line-height-18 tw-text-gray-600"
    >
      Para simular tu bono primero debes tener el valor del Achievement Score
      llenando los siguientes campos
    </div>
    <div class="col-12 pt-2">
      <form>
        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
        >
          Size of pie
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-sizeofpie text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="number"
            class="form-control text-right"
            id="pie-size"
            :value="bonusPropsRange0to6.sizeOfPie"
            @input="checkSizeOfPie($event, bonusPropsRange0to6)"
          />
          <div class="input-group-append">
            <span class="input-group-text bg-white tw-rounded-lg">%</span>
          </div>
          <small class="error" v-if="this.error.sizeOfPie.value">{{
            this.error.sizeOfPie.message
          }}</small>
        </div>

        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
        >
          Entity Performance
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-entityperformance text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="number"
            class="form-control text-right"
            id="entity-performance"
            :value="bonusPropsRange0to6.entityPerformance"
            @input="checkEntityPerformance($event, bonusPropsRange0to6)"
          />
          <div class="input-group-append">
            <span class="input-group-text bg-white tw-rounded-lg">%</span>
          </div>
          <small class="error" v-if="this.error.entityPerformance.value">{{
            this.error.entityPerformance.message
          }}</small>
        </div>

        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
        >
          Individual Performance
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-individualperformance text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="number"
            class="form-control text-right"
            id="individual-performance"
            :value="bonusPropsRange0to6.individualPerformance"
            @input="
              checkIndividualPerformance(
                $event,
                bonusPropsRange0to6,
                employeeCorePosition
              )
            "
          />
          <div class="input-group-append">
            <span class="input-group-text bg-white tw-rounded-lg">%</span>
          </div>
          <small class="error" v-if="this.error.individualPerformance.value">{{
            this.error.individualPerformance.message
          }}</small>
        </div>

        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
        >
          Discretionary Incentive
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-discincentive text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="number"
            class="form-control text-right"
            id="discretionary-incentive"
            :value="bonusPropsRange0to6.discretionaryIncentive"
            @input="
              checkDiscretionaryPerformance(
                $event,
                bonusPropsRange0to6,
                employeeCorePosition
              )
            "
          />
          <div class="input-group-append">
            <span class="input-group-text bg-white tw-rounded-lg">%</span>
          </div>
          <small class="error" v-if="this.error.discretionaryIncentive.value">{{
            this.error.discretionaryIncentive.message
          }}</small>
        </div>
      </form>
    </div>
    <div class="col-12 pt-2 pb-2">
      <div
        class="calculator-total-value text-center text-light d-flex justify-content-between align-items-center pt-2 pb-2 tw-border-b tw-border-gray-300"
      >
        <span class="pr-2 tw-font-robotoBold tw-text-base tw-text-gray-600"
          >Achievement Score</span
        >
        <span class="tw-font-roboto fs-24 line-height-32 tw-text-green-500"
          >{{ calculateAchievementScore }} %</span
        >
      </div>
    </div>
    <div class="col-12 pt-2 pb-2">
      <div class="calculator-total-value pt-1 pb-2">
        <div
          role="button"
          class="tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
          @click="changeStep()"
        >
          Siguiente paso
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  checkSizeOfPie,
  checkEntityPerformance,
  checkIndividualPerformance,
  checkDiscretionaryPerformance,
} from "@/helpers/bonusHelpers";

export default {
  name: "AchievementScoreRange0to6",
  props: {
    error: {
      type: Object,
    },
    bonusPropsRange0to6: {
      type: Object,
    },
    calculateAchievementScore: {
      type: Number,
    },
    calculateAbiValueRange0to6: {
      type: Number,
    },
    employeeCorePosition: {
      type: String,
    },
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  watch: {
    bonusPropsRange0to6() {},
  },
  methods: {
    checkSizeOfPie,
    checkEntityPerformance,
    checkIndividualPerformance,
    checkDiscretionaryPerformance,
    changeStep() {
      this.$emit("changeStep");
      this.pushDataLayer(
        this.bonusPropsRange0to6.sizeOfPie,
        this.bonusPropsRange0to6.entityPerformance,
        this.bonusPropsRange0to6.individualPerformance,
        this.bonusPropsRange0to6.discretionaryIncentive
      );
    },
    pushDataLayer(sizeofpie, entyper, indper, discinc) {
      window.dataLayer.push({
        event: "TrackEvent-Compensacion",
        Category: "Remuneración - Bono ABI",
        Action: "paso 1 - Bono ABI",
        Label: "Simula tu Achievement Score",
        SizeOfPie: sizeofpie,
        EntityPerformance: entyper,
        IndividualPerformance: indper,
        DiscretionaryIncentive: discinc,
      });
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-modal-info,
.btn-modal-info:focus {
  outline: none;
}

small.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.modal-title {
  color: #d82b2b;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 1;
  margin-bottom: 10px;
}

.disclaimer {
  text-align: left;
  font: normal normal normal 12px/13px Roboto;
  letter-spacing: 0;
  color: #343434 !important;
  opacity: 1;
}

.desc-image {
  max-width: 100%;
  height: auto;
}
</style>
