import { mapActions } from "vuex";
import utilitiesComponents from "@/mixins/general/utilitiesComponents";

const vacantMixin = {
  mixins: [utilitiesComponents],
  data() {
    return {
      requestSelected: {},
      isLoadingApply: false,
    };
  },
  computed: {
    classStateVacant() {
      let classStateVacant = {};
      switch (this.request.people_vacant_position_state_id) {
        case "WAITING_LM":
          classStateVacant.icon = "icon-reloj";
          classStateVacant.color = "tw-text-yellow-400";
          break;

        case "APPROVED_LM":
          classStateVacant.icon = "icon-check";
          classStateVacant.color = "tw-text-green-400";
          break;

        case "REJECTED_LM":
          classStateVacant.icon = "icon-circulo-tachado";
          classStateVacant.color = "tw-text-red-400";
          break;

        default:
          classStateVacant.icon = "icon-reloj";
          classStateVacant.color = "tw-text-yellow-400";
          break;
      }

      return classStateVacant;
    },
  },
  methods: {
    ...mapActions("modVacants", ["changeVacantState"]),
    async updateState(state) {
      this.isLoadingApply = true;
      let data = {
        is_approved: state,
        id: this.requestSelected.people_vacant_position_employee_id,
      };

      let response = await this.changeVacantState(data);
      if (response.error) {
        this.showToast(
          "Error",
          "No se ha podido hacer la acción requerida.",
          "danger",
          "b-toaster-bottom-right"
        );
        this.isLoadingApply = false;
      } else {
        this.isLoadingApply = false;
        if (state === 1) {
          this.requestSelected.people_vacant_position_state_id = "APPROVED_LM";
          this.requestSelected.people_vacant_position_state_name = "Aprobado";
        } else {
          this.requestSelected.people_vacant_position_state_id = "REJECTED_LM";
          this.requestSelected.people_vacant_position_state_name = "Rechazado";
        }
      }
    },
  },
};

export default vacantMixin;
