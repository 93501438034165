<template>
  <div class="container mt-4 tabPeople">
    <b-tabs
      nav-class="nav-1"
      active-nav-item-class="tw-text-gray-600"
      active-tab-class="tw-text-gray-600"
      content-class="mt-n0"
      fill
    >
      <b-tab
        v-for="item in items"
        :key="'people-calendar-' + item.id"
        :active="item.active"
        :title="item.name"
      >
        <CardPeople1
          v-if="item.people_cycle_calendar != null"
          :title="item.name"
          :description="item.people_cycle_calendar.description"
          :icon="item.people_cycle_calendar.icon"
          :months="item.people_cycle_calendar.months"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import CardPeople1 from "@/components/people/cards/CardPeople1";

export default {
  name: "TabPeople",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardPeople1,
  },
};
</script>

<style scoped>
.tabPeople .nav-1 {
  background-color: white;
}
.tabPeople .nav-1 li {
  border: #000 solid 1px;
  border-radius: 10px;
  margin: 0 5px 15px;
}
.tabPeople .nav-1 .nav-link.active,
.tabPeople .nav-1 .nav-item.show .nav-link {
  border-radius: 10px;
  border-bottom: none;
  color: #000;
}

.tabPeople .nav-1 .nav-link:hover,
.tabPeople .nav-1 .nav-link:focus {
  border-radius: 10px;
  border-bottom: none;
}
</style>
