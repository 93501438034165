<script setup>
import { computed, onBeforeMount, ref } from "vue";
import config from "@/services/config";
import { useUserStore } from "@/stores/userStore";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { useRoute, useRouter } from "vue-router/composables";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import GoFlexTopButtons from "@/components/goFlex/CompGoFlexTopButtons.vue";
import DateField from "@/components/general/DateField.vue";
import HourField from "@/components/general/HourField.vue";
// import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  points: {
    type: Number,
  },
});

const userStore = useUserStore();
const goFlexStore = useGoFlexStore();
const route = useRoute();
const router = useRouter();
const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const date = ref(null);
const hour = ref(null);
// const shift = ref(null);
const datevalidated = ref(false);
const hourvalidated = ref(false);
const isModalOpen = ref(false);
const error = ref(null);
const errorMessage = ref(null);
const isDateInPast = ref(false);
const isDateToday = ref(false);
const isNotCurrentMonth = ref(false);
const isSendingRequest = ref(false);

const getItem = computed(() => {
  return props.item == null ? goFlexStore.optionDetail : props.item;
});

const getPoints = computed(() => {
  return props.points == null
    ? goFlexStore.optionDetail.employee_points_balance
    : props.points;
});

const enoughPoints = computed(() => {
  return props.points == null
    ? goFlexStore.optionDetail.employee_points_balance >=
        goFlexStore.optionDetail.points
    : props.points >= getItem.value.points;
});

const validateData = computed(() => {
  return getItem.value.unit === "hour"
    ? hour.value != null && datevalidated.value && hourvalidated.value
    : datevalidated.value;
});

const checkDate = computed(() => {
  return goFlexStore.requests.some(
    (request) =>
      request.requested_date === date.value &&
      (request.status === "PENDING" || request.status === "APPROVED")
  );
});

const hasLineManager = computed(() => {
  return (
    userStore.user.line_manager_id_sharp != null &&
    userStore.user.line_manager_id_sharp !== "" &&
    userStore.user.line_manager_id_sharp !== "0"
  );
});

const isInCatalog = computed(() => {
  return goFlexStore.catalog?.options?.length > 0
    ? goFlexStore.catalog?.options?.some((item) => item.id === route.params.id)
    : false;
});

const disclaimerText = computed(() => {
  if (isDateInPast.value)
    return "* No puedes reservar días antes de la fecha de hoy";
  if (isDateToday.value)
    return "* Recuerda que estas pidiendo el día de hoy, y debe ser aprobado primero por tu LM para que puedas usar el beneficio.";
  if (isNotCurrentMonth.value)
    return "* Solo se pueden solicitar días dentro del mes actual";
  if (checkDate.value) return "* Ya tienes una solicitud para esta fecha";
  return "* Recuerda que puedes hacer tu solicitud solo dentro del mes actual.";
});

const openModal = (err) => {
  isModalOpen.value = true;
  error.value = err;
};

const closeModal = () => {
  isModalOpen.value = false;
  error.value = null;
  router.push({ name: "goFlex" });
};

/*const setShift = (option) => {
  shift.value = option;
};*/

const postGoFlexEmployeeRequest = async (e) => {
  isSendingRequest.value = true;
  pushDataLayer({
    event_category: "Go Flex",
    event_action: getItem.value === "flex" ? "Flex Work" : "Tiempo + Off",
    event_label: getItem.value.name,
    component_name: "Click",
    element_category: "Solicitar",
    element_points: getItem.value.points + " puntos",
    element_date: date.value,
  });
  await goFlexStore
    .actPostGoFlexEmployeeRequest({
      id: getItem.value.id,
      data: {
        requested_date: date.value,
        requested_time: hour.value,
      },
    })
    .then((response) => {
      if (response.status === 400) {
        error.value = true;
        errorMessage.value = response.data.message
        openModal(true);
      } else {
        error.value = false;
        openModal(false);
        e.target.disabled = false;
      }
    });
  isSendingRequest.value = false;
};

const dateInPast = () => {
  isDateInPast.value = true;
  isDateToday.value = false;
  isNotCurrentMonth.value = false;
};

const dateToday = () => {
  isDateToday.value = true;
  isDateInPast.value = false;
  isNotCurrentMonth.value = false;
};

const notCurrentMonth = () => {
  isNotCurrentMonth.value = true;
  isDateToday.value = false;
  isDateInPast.value = false;
};

const handleDateValidated = (validated, dateToValidate) => {
  datevalidated.value = validated;
  date.value = dateToValidate;
  if (validated) {
    isDateInPast.value = false;
    isNotCurrentMonth.value = false;
  }
};

const handleHourValidated = (validated, time) => {
  hourvalidated.value = validated;
  hour.value = time;
};

onBeforeMount(async () => {
  isLoading.value = true;
  if (goFlexStore.catalog.length === 0) {
    await goFlexStore.actGetGoFlexCatalog(getItem.value.catalog);
  }

  if (!hasLineManager.value || !isInCatalog.value) {
    await router.push({ name: "goFlex" });
  }

  if (getItem.value == null) {
    await goFlexStore.actGetGoFlexOptionDetail(route.params.id);
  }

  if (goFlexStore.requests.length === 0) {
    await goFlexStore.actGetGoFlexRequests();
  }
  isLoading.value = false;
});
</script>
<template>
  <div>
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 500, xl: 600 }"
      modal-name="goFlexDetail"
      v-if="isModalOpen"
      :isShowModal="isModalOpen"
      @close="closeModal"
    >
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
        v-if="!error"
      >
        <img src="@/assets/img/modal/modal-success.svg" alt="" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
        >
          GENIAL
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-w-8/12 tw-text-center"
        >
          <span class="tw-capitalize tw-font-robotoBold">{{
            userStore.user.name.split(" ")[0].toLowerCase()
          }}</span>
          tu solicitud ha sido creada, pronto te avisaremos si fue aprobada o
          no.
        </p>
      </div>
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
        v-else
      >
        <img src="@/assets/img/modal/modal-error.svg" alt="" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
        >
          Lo sentimos
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-w-8/12 tw-text-center"
        >
          {{ errorMessage }}
        </p>
      </div>
    </general-modal>
    <mobile-bar title="Beneficios de Tiempo" />
    <title-bar title="Beneficios de Tiempo" />
    <div class="tw-container-general" v-if="!isLoading">
      <go-flex-top-buttons />
      <div class="tw-flex tw-justify-center lg:tw-justify-end">
        <div class="tw-w-max tw-self-center">
          <div
            class="tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-flex tw-items-center tw-justify-between tw-gap-3 tw-py-2 lg:tw-py-3"
          >
            <p class="tw-m-0 tw-font-robotoMedium tw-text-lg lg:tw-text-xl">
              Tienes {{ getPoints }} puntos
            </p>
            <div
              class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-1"
            >
              <i class="icon-star tw-golden-black-border-icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-my-5 lg:tw-my-10">
        <div
          class="tw-w-full lg:tw-rounded-3xl tw-border tw-p-6 lg:tw-px-5 lg:tw-py-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-8"
          :class="
            getItem.catalog === 'off'
              ? 'tw-border-orange-350'
              : 'tw-border-green-500'
          "
        >
          <div class="lg:tw-col-span-1 tw-flex tw-flex-col tw-gap-3">
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-2xl lg:tw-text-xl tw-block lg:tw-hidden"
              :class="
                getItem.catalog === 'off'
                  ? 'tw-text-orange-350'
                  : 'tw-text-green-500'
              "
            >
              {{ getItem.name }}
            </p>
            <div class="tw-aspect-h-1 tw-aspect-w-1 tw-max-h-32">
              <img
                :src="uriResources + '/' + getItem.image"
                alt=""
                class="tw-w-full tw-rounded-2xl lg:tw-rounded-2.5xl tw-object-cover"
              />
            </div>
          </div>
          <div
            class="lg:tw-col-span-3 tw-flex tw-flex-col tw-justify-between tw-gap-4"
          >
            <div class="tw-flex tw-justify-center lg:tw-justify-between">
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-hidden lg:tw-block"
                :class="
                  getItem.catalog === 'off'
                    ? 'tw-text-orange-350'
                    : 'tw-text-green-500'
                "
              >
                {{ getItem.name }}
              </p>
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-lg tw-text-center"
                :class="
                  getItem.catalog === 'off'
                    ? 'tw-text-orange-350'
                    : 'tw-text-green-500'
                "
              >
                {{ getItem.points + " Puntos" }}
              </p>
            </div>
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              {{ getItem.description }}
            </p>
            <div
              class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-end tw-gap-4"
            >
              <div
                class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-3 tw-w-full"
              >
                <div
                  class="tw-grid tw-grid-cols-col2_fit_first lg:tw-grid-cols-1 tw-gap-6 lg:tw-gap-3 tw-w-full lg:tw-w-auto lg:tw-grid-rows-1 tw-grid-rows-rows_2_fit_first"
                >
                  <p class="tw-m-0 tw-font-roboto tw-text-xl">Fecha</p>
                  <date-field
                    @validated="handleDateValidated"
                    border-color="black"
                    @dateInPast="dateInPast"
                    @dateToday="dateToday"
                    @notCurrentMonth="notCurrentMonth"
                  />
                </div>
                <div
                  class="tw-grid tw-grid-cols-col2_fit_first lg:tw-grid-cols-1 tw-gap-6 lg:tw-gap-3 tw-w-full lg:tw-w-auto"
                  v-if="getItem.unit === 'hour'"
                >
                  <p class="tw-m-0 tw-font-roboto tw-text-xl">Hora inicial</p>
                  <hour-field
                    @validated="handleHourValidated"
                    border-color="black"
                  />
                </div>
                <!--                <div
                  class="tw-grid tw-grid-cols-col2_fit_first lg:tw-grid-cols-1 tw-gap-6 lg:tw-gap-3 tw-w-full lg:tw-w-auto tw-min-h-0"
                  v-if="this.getItem.shift"
                >
                  <p class="tw-m-0 tw-font-roboto tw-text-xl">Jornada</p>
                  <div>
                    <CompGeneralDropdown
                      :fitted="false"
                      :isLabel="false"
                      :options="[
                        {
                          id: 0,
                          name: 'Mañana',
                        },
                        {
                          id: 1,
                          name: 'Tarde',
                        },
                      ]"
                      default-text-visible
                      default-text="Elige uno"
                      border-color="black"
                      centered-text
                      @changeSelectedOption="setShift"
                      @chargeOption="setShift"
                    />
                  </div>
                </div>-->
              </div>
              <button
                :class="
                  validateData && enoughPoints
                    ? getItem.catalog === 'off'
                      ? 'tw-bg-orange-350'
                      : 'tw-bg-green-500'
                    : 'tw-bg-gray-300'
                "
                class="tw-btn-general-shape tw-border-none tw-text-white tw-w-full lg:tw-w-3/12"
                @click.once="
                  validateData && enoughPoints
                    ? postGoFlexEmployeeRequest($event)
                    : ''
                "
              >
                <span class="tw-m-0 tw-text-sm" v-if="!isSendingRequest">
                  Solicitar
                </span>
                <img
                  v-else
                  src="@/assets/img/icons/loading.gif"
                  class="tw-h-6"
                  alt="loading"
                />
              </button>
            </div>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm"
              :class="
                isDateInPast || isDateToday || isNotCurrentMonth
                  ? 'tw-text-red-500'
                  : ''
              "
            >
              {{ disclaimerText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
