import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    showNotifications: false,
    hasNotificationsRead: true,
    notifications: [],
    notificationsRead: [],
    notificationsUnread: [],
    sections: [],
  },
  mutations: {
    toggleShowNotifications: (state) => {
      state.showNotifications = !state.showNotifications;
    },
    hideNotifications: (state) => {
      state.showNotifications = false;
    },
    mutShowNotifications: (state) => {
      state.showNotifications = true;
    },
    setNotifications: (state, notifications) => {
      state.notifications = notifications;
    },
    setNotificationsRead: (state, notificationRead) => {
      state.notificationsRead = notificationRead;
    },
    setNotificationsUnRead: (state, notifications) => {
      state.notificationsUnread = notifications;
    },
    setSections: (state, sections) => {
      state.sections = sections;
    },
    setHasNotificationsRead: (state) => {
      let stateHasNotificationRead = state.notifications.find(
        (notification) => notification.read_at != null
      );
      state.hasNotificationsRead = stateHasNotificationRead === undefined;
    },
  },
  actions: {
    actGetNotifications: async ({ commit, state }) => {
      let response = await ApiBeerOffice.getNotifications();
      if (response.error) {
        return response.error;
      }
      commit("setNotifications", response.data);
      commit("setHasNotificationsRead");

      let stateHasNotificationRead = state.notifications.filter(
        (notification) => notification.read_at != null
      );
      commit("setNotificationsRead", stateHasNotificationRead);

      let stateHasNotificationUnRead = state.notifications.filter(
        (notification) => notification.read_at == null
      );
      commit("setNotificationsUnRead", stateHasNotificationUnRead);
      return true;
    },

    actGetSections: async ({ commit }) => {
      let response = await ApiBeerOffice.getSections();
      if (response.error) {
        return response.error;
      }
      commit("setSections", response.data);
      return true;
    },

    actMarkNotificationsAsRead: async ({ commit, state }, id) => {
      let data = [];
      /*state.notifications.forEach((notification) => {
        if (notification.read_at == null) {
          data.push(notification.id);
        }
      });*/

      data.push(id);

      let response = await ApiBeerOffice.markAllNotificationsAsRead({
        notifications: data,
      });

      if (response === "ok") {
        let newNotifications = state.notifications.map((notification) => {
          notification.read_at = new Date();
          return notification;
        });
        commit("setNotifications", newNotifications);
      } else {
        return response.error;
      }

      commit("setHasNotificationsRead");

      return true;
    },
  },
};
