<template>
  <div>
    <div
      role="button"
      v-b-toggle="'accordion-orderStatus-' + item.id"
      class="
        d-flex
        justify-content-between
        tw-cursor-pointer
        outline-none
        head-tab
      "
      @click="updateActualOrder(index)"
    >
      <p class="tw-text-gray-600 mb-0 tw-text-sm fs-md-16 font-weight-bold">
        {{ formatDate(item.created_at) }}
      </p>
      <p class="tw-text-gray-300 mb-0 tw-text-sm">No. {{ item.id }}</p>
    </div>
    <b-collapse
      :id="'accordion-orderStatus-' + item.id"
      accordion="my-orderStatus-accordion"
      v-model="collapses[index]"
    >
      <div class="d-flex align-items-center justify-content-between pt-3 pb-4">
       
        <div class="position-relative he-90 d-flex align-items-center">
          <div
            :class="
              item.ecomm_order_state.id === 1
                ? 'wi-50 wi-md-70 he-50 he-md-70 tw-bg-red-400 text-white fs-30 fs-md-40'
                : item.ecomm_order_state.id > 1
                ? item.ecomm_order_state.id === 4
                  ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                  : item.ecomm_order_state.id === 5
                  ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white border-sm-red-100 tw-text-xl fs-md-30'
                  : 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-red-100 text-white border-sm-red-100 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'
            "
            class="
              tw-rounded-full
              d-flex
              justify-content-center
              align-items-center
              tw-font-roboto
            "
          >
            <i class="icon-factura"></i>
            <p
              :class="
                item.ecomm_order_state.id === 1
                  ? 'tw-text-red-400 font-weight-bold'
                  : item.ecomm_order_state.id > 1
                  ? item.ecomm_order_state.id === 4
                    ? 'tw-text-green-400'
                    : item.ecomm_order_state.id === 5
                    ? 'tw-text-gray-300'
                    : ' tw-text-red-100'
                  : 'tw-text-gray-400'
              "
              class="
                tw--bottom-5 md:tw--bottom-10
                text-center
                 tw-text-xs  fs-md-14
                position-absolute
              "
            >
              Realizado
            </p>
          </div>
        </div>
        <div
          :class="
            item.ecomm_order_state.id === 4 ? 'tw-bg-green-400' : item.ecomm_order_state.id === 2 || item.ecomm_order_state.id === 3 ? 'tw-bg-red-100' : 'tw-bg-gray-300'
          "
          class="w-100 he-4 mt-1"
        ></div>
        <div class="position-relative he-90 d-flex align-items-center">
          <div
            :class="
              item.ecomm_order_state.id === 2
                ? 'wi-50 wi-md-70 he-50 he-md-70 tw-bg-red-400 text-white fs-30 fs-md-40'
                : item.ecomm_order_state.id > 2
                ? item.ecomm_order_state.id === 4
                  ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                  : item.ecomm_order_state.id === 5
                  ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white border-sm-red-100 tw-text-xl fs-md-30'
                  : 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-red-100 text-white border-sm-red-100 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'
            "
            class="
              mt-2
              tw-rounded-full
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <i class="icon-botellas"></i>
            <p
              :class="
                item.ecomm_order_state.id === 2
                  ? 'tw-text-red-400 font-weight-bold'
                  : item.ecomm_order_state.id > 2
                  ? item.ecomm_order_state.id === 4
                    ? 'tw-text-green-400'
                    : item.ecomm_order_state.id === 5
                    ? 'tw-text-gray-300'
                    : ' tw-text-red-100'
                  : 'tw-text-gray-400'
              "
              class="
                tw--bottom-5 md:tw--bottom-10
                text-center
                 tw-text-xs  fs-md-14
                position-absolute
              "
            >
              Alistando
            </p>
          </div>
        </div>
        <div
          :class="
            item.ecomm_order_state.id === 4 ? 'tw-bg-green-400' : item.ecomm_order_state.id === 3 ? 'tw-bg-red-100' : 'tw-bg-gray-300'
          "
          class="w-100 he-4 mt-1"
        ></div>
        <div class="position-relative he-90 d-flex align-items-center">
          <div
            :class="
              item.ecomm_order_state.id === 3
                ? 'wi-50 wi-md-70 he-50 he-md-70 tw-bg-red-400 text-white fs-30 fs-md-40'
                : item.ecomm_order_state.id > 3
                ? item.ecomm_order_state.id === 4
                  ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                  : item.ecomm_order_state.id === 5
                  ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white border-sm-red-100 tw-text-xl fs-md-30'
                  : 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-red-100 text-white border-sm-red-100 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'
            "
            class="
              mt-2
              tw-rounded-full
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <i class="icon-mobile-tienda"></i>
            <p
              :class="
                item.ecomm_order_state.id === 3
                  ? 'tw-text-red-400 wi-50 wi-md-70 font-weight-bold'
                  : item.ecomm_order_state.id > 3
                  ? item.ecomm_order_state.id === 4
                    ? 'tw-text-green-400 wi-50 wi-md-50'
                    : item.ecomm_order_state.id === 5
                    ? 'tw-text-gray-300 wi-50 wi-md-50'
                    : ' tw-text-red-100 wi-50 wi-md-50'
                  : 'tw-text-gray-400 wi-50 wi-md-50'
              "
              class="
                tw--bottom-5 md:tw--bottom-10
                text-center
                 tw-text-xs  fs-md-14
                position-absolute
                ml-n2 ml-md-n4
              "
            >
              ¡Reclámalo!
            </p>
          </div>
        </div>
        <div
          :class="
            item.ecomm_order_state.id === 4 ? 'tw-bg-green-400' : 'tw-bg-gray-300'
          "
          class="w-100 he-4 mt-1"
        ></div>
        <div class="position-relative he-90 d-flex align-items-center">
          <div
            :class="
              item.ecomm_order_state.id === 4
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                : item.ecomm_order_state.id === 5 ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'
            "
            class="
              mt-2
              tw-rounded-full
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <i class="icon-circulo-check"></i>
            <p
              v-if="item.ecomm_order_state.id === 5"
              :class="
                item.ecomm_order_state.id === 5
                  ? 'tw-text-gray-300 font-weight-bold wi-50 wi-md-50'
                  : 'tw-text-gray-400 wi-50 wi-md-50 '
              "
              class="
                tw--bottom-5 md:tw--bottom-10
                text-center
                 tw-text-xs  fs-md-14
                position-absolute
                ml-n2 ml-md-n4
              "
            >
              Cancelado
            </p>
            <p
              v-else
              :class="
                item.ecomm_order_state.id === 4
                  ? 'tw-text-green-400 font-weight-bold wi-50 wi-md-50'
                  : 'tw-text-gray-400 wi-50 wi-md-50'
              "
              class="
                tw--bottom-5 md:tw--bottom-10
                text-center
                 tw-text-xs  fs-md-14
                position-absolute
                ml-n2 ml-md-n4
              "
            >
              Entregado
            </p>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import datesMixin from "@/mixins/general/dates";

export default {
  name: "CompDetailStatusOrderProduct",
  props: ["item", "index", "countOrders"],
  mixins: [datesMixin],
  data() {
    return {
      collapses: [],
    };
  },
  created() {
    this.collapses = Array(this.countOrders).fill(true);
  },
  methods: {
    updateActualOrder() {
      this.$emit("clickOrder", this.index);
    },
  },
};
</script>