<template>
  <div>
    <div v-if="show === 1">
      <div class="mt-4 mx-1 row d-md-none">
        <div class="p-0 tw-w-full tw-container">
          <div class="p-0">
            <div
              class="d-md-none psi shadow-box-4 bg-white tw-rounded-md mx-auto my-3 p-0"
              :class="
                indicator.id === 3 || indicator.id === 4 || indicator.id === 5
                  ? 'd-none'
                  : indicator.classification === 1
                  ? 'd-flex psi-1'
                  : indicator.classification === 2
                  ? 'd-flex psi-2'
                  : indicator.classification === 3
                  ? 'd-flex psi-3'
                  : 'd-flex psi-4'
              "
              v-for="indicator in categoriesListData"
              :key="indicator.id"
              cols="5"
              v-on="
                indicator.id === 2
                  ? {
                      click: () => {
                        filterIndicatorbyId(indicator.id, 2);
                        pushDataLayer(indicator.name);
                        changeShow(2);
                      },
                    }
                  : {
                      click: () => {
                        filterIndicatorbyId(indicator.id, 1);
                        pushDataLayer(indicator.name);
                        changeShow(1);
                      },
                    }
              "
            >
              <div class="psi-left"></div>
              <div
                class="psi-t d-flex align-items-center pl-2 justify-content-between pr-3"
                :class="id === indicator.id ? 'psi-trans' : ''"
              >
                <span class="tw-font-robotoBold tw-text-xxs text-capitalize">{{
                  indicator.name.toLowerCase()
                }}</span>
                <span
                  class="tw-font-robotoBold tw-text-lg value"
                  v-if="indicator.average"
                  >{{
                    Math.round(
                      parseFloat(
                        indicator.average.substring(0, 5).replace(",", ".")
                      )
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row d-none d-md-flex">
        <div class="col-6 col-md-4">
          <div v-for="indicator in filterIndicators(1)" :key="indicator.id">
            <div
              class="d-flex psi psi-1 shadow-box-4 bg-white tw-rounded-lg my-3"
              @click="
                filterIndicatorbyId(indicator.id, 1);
                pushDataLayer(indicator.name);
                changeShow(1);
              "
            >
              <div class="psi-left"></div>
              <div
                class="psi-t d-flex align-items-center pl-2 justify-content-between pr-3"
                :class="id === indicator.id ? 'psi-trans' : ''"
              >
                <span class="tw-font-robotoBold tw-text-sm text-capitalize">{{
                  indicator.name.toLowerCase()
                }}</span>
                <span class="tw-font-robotoBold tw-text-sm value">{{
                  Math.round(
                    parseFloat(
                      indicator.average.substring(0, 5).replace(",", ".")
                    )
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div v-for="indicator in filterIndicators(2)" :key="indicator.id">
            <div
              class="d-flex psi psi-2 shadow-box-4 bg-white tw-rounded-lg my-3"
              v-on="
                indicator.id === 3 || indicator.id === 4 || indicator.id === 5
                  ? {
                      click: () => {
                        filterIndicatorbyId(indicator.id, 0);
                        pushDataLayer(indicator.name);
                        changeShow(0);
                      },
                    }
                  : {
                      click: () => {
                        filterIndicatorbyId(indicator.id, 1);
                        pushDataLayer(indicator.name);
                        changeShow(1);
                      },
                    }
              "
            >
              <div class="psi-left"></div>
              <div
                class="psi-t d-flex align-items-center pl-2 justify-content-between pr-3"
                :class="id === indicator.id ? 'psi-trans' : ''"
              >
                <span class="tw-font-robotoBold tw-text-sm text-capitalize">{{
                  indicator.name.toLowerCase()
                }}</span>
                <span v-if="indicator.average === ''"></span>
                <span class="tw-font-robotoBold tw-text-sm value" v-else>{{
                  Math.round(
                    parseFloat(
                      indicator.average.substring(0, 5).replace(",", ".")
                    )
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div v-for="indicator in filterIndicators(3)" :key="indicator.id">
            <div
              class="d-flex psi shadow-box-4 bg-white tw-rounded-lg my-3"
              :class="indicator.classification === 3 ? 'psi-3' : 'psi-4'"
              v-on="
                indicator.id === 2
                  ? {
                      click: () => {
                        filterIndicatorbyId(indicator.id, 2);
                        pushDataLayer(indicator.name);
                        changeShow(2);
                      },
                    }
                  : {
                      click: () => {
                        filterIndicatorbyId(indicator.id, 1);
                        pushDataLayer(indicator.name);
                        changeShow(1);
                      },
                    }
              "
            >
              <div class="psi-left"></div>
              <div
                class="psi-t d-flex align-items-center pl-2 justify-content-between pr-3"
                :class="id === indicator.id ? 'psi-trans' : ''"
              >
                <span class="tw-font-robotoBold tw-text-sm text-capitalize">{{
                  indicator.name.toLowerCase()
                }}</span>
                <span
                  class="tw-font-robotoBold tw-text-sm value"
                  v-if="indicator.average"
                  >{{
                    parseFloat(
                      indicator.average.substring(0, 5).replace(",", ".")
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TurnOver view -->
    <div v-else-if="show === 0">
      <div class="mt-4 row">
        <div
          class="col-md-3"
          v-for="indicator in filteredCategories"
          :key="indicator.id"
        >
          <div
            class="d-flex psi shadow-box-4 bg-white tw-rounded-lg my-3"
            :class="
              indicator.classification === 1
                ? 'psi-1'
                : indicator.classification === 2
                ? 'psi-2'
                : indicator.classification === 3
                ? 'psi-3'
                : indicator.classification === 4
                ? 'psi-4'
                : ''
            "
            @click="
              filterIndicatorbyId(indicator.id, 0);
              pushDataLayer(indicator.name);
              changeShow(0);
            "
          >
            <div class="psi-left"></div>
            <div
              class="psi-t d-flex align-items-center pl-2 justify-content-between pr-3"
              :class="id === indicator.id ? 'psi-trans' : ''"
            >
              <span class="tw-font-robotoBold tw-text-sm text-capitalize">{{
                indicator.name.toLowerCase()
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div
            class="d-flex psi psi-2 shadow-box-4 bg-white tw-rounded-lg my-3"
            @click="
              filterIndicatorbyId(1, 1);
              changeShow(1);
            "
          >
            <div class="psi-left"></div>
            <div
              class="psi-t d-flex align-items-center pl-2 justify-content-between pr-3"
            >
              <span class="tw-font-robotoBold tw-text-sm text-capitalize"
                >Todos los Indicadores</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicatorsButtons",
  props: {
    categoriesListData: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Number,
      required: true,
    },
  },
  computed: {
    filteredCategories() {
      return this.categoriesListData.filter(
          (e) => e.id === 3 || e.id === 4 || e.id === 5
      );
    },
  },
  methods: {
    changeShow(number) {
      this.$parent.changeShow(number);
    },
    filterIndicatorbyId(id, show) {
      let filteredIndicator = this.categoriesListData.filter(
        (e) => e.id === id
      );
      this.$parent.changeClassification(
        filteredIndicator[0].classification,
        filteredIndicator[0].id,
        filteredIndicator[0].name,
        filteredIndicator[0].description,
        show
      );
    },
    filterIndicators(classification) {
      return classification >= 3
          ? this.categoriesListData.filter(
              (e) => e.classification >= classification
          )
          : this.categoriesListData.filter(
              (e) => e.classification === classification
          );
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-TBO",
        category: "Somos Embajadores",
        SubCategory: "Indicadores Ranking",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>

<style scoped></style>
