<template>
  <div>
    <label class="switch-check-new" @click="changeStage">
      <input type="checkbox" v-model="checked" />
      <span class="slider-check round-check">
        <span class="on w-50 tw-font-robotoBold  tw-text-xs  tw-top-2 md:tw-top-1"> Mes </span>
        <span class="off w-50 tw-font-robotoBold  tw-text-xs  tw-top-2 md:tw-top-1">
          Etapa
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "SwitchPeopleRanking",
  data() {
    return {
      checked: false,
    };
  },
  created() {
    this.changeSwitchStage(1);
  },
  computed: {
    ...mapState("modRankingPeople", ["switchStage"]),
  },
  methods: {
    ...mapActions("modRankingPeople", ["changeSwitchStage"]),
    ...mapMutations("modRankingPeople", ["setDataUserRankSelected", "setMonthSelected", "setRankStageSelected"]),
    changeStage() {
      this.setDataUserRankSelected([]);
      if (this.checked) {
        this.changeSwitchStage(1);
      } else {
        this.changeSwitchStage(2);
      }
      this.changeMonth();
    },
    changeMonth() {
      let targetId = document.querySelector(".select-ranking-type").value;
      
      this.setMonthSelected(targetId);
      this.setRankStageSelected(targetId);
    },
  },
};
</script>

<style scoped>
.switch-check-new {
  position: relative;
  display: inline-block;
  width: 250px;
  height: 34px;
}

.switch-check-new input {
  display: none;
}

.switch-check-new .slider-check {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-check-new .slider-check:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 125px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-check-new input:checked + .slider-check {
  background-color: #f2f2f2;
}

.switch-check-new input:checked + .slider-check:before {
  transform: translateX(115px);
}

.switch-check-new .on {
  color: black;
  position: relative;
  display: block;
  text-align: center;
  top: 8px;
}

.switch-check-new .off {
  color: black;
  position: relative;
  left: calc(50%);
  display: block;
  text-align: center;
  top: -8px;
}

.switch-check-new input:checked + .slider-check .on {
  color: #bababa;
}

.switch-check-new input + .slider-check .off {
  color: #bababa;
}

.switch-check-new input:checked + .slider-check .off {
  color: black;
}

/*--------- END --------*/

/* Rounded slider-checks */
.switch-check-new .slider-check.round-check {
  border-radius: 15px;
}

.switch-check-new .slider-check.round-check:before {
  border-radius: 15px;
}
</style>