<template>
  <div class="tw-container-general">
    <ButtonsPeopleCycle
      class="py-3 mb-2 mb-md-4"
      :peopleCycleButtonsActive="4"
    />
    <PCNavigate :pc_item="pc_item" />

    <!-- ALL CONTENT -->
    <div class="container-md pb-5" id="general-wrapper">
      <div class="mb-5" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="../../../assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div v-else>
        <div class="tw-shadow-lg tw-rounded-lg d-flex flex-column p-0">
          <!-- People Cycle Title and Description-->
          <div
            class="tw-shadow-lg tw-rounded-lg he-200"
            :style="'background-color: ' + peopleCycleItem.color"
          >
            <div class="container h-100 d-flex align-items-md-center">
              <div class="row align-items-center px-3 px-md">
                <div class="col-3 col-md-2 text-center order-2 order-md-1">
                  <img
                    :src="uriResources + '/' + peopleCycleItem.icon"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  class="col-9 col-md-4 text-uppercase tw-font-tolyerBold text-white p-0 order-1 order-md-2"
                >
                  <p
                    class="fs-44 fs-md-70 line-height-40 line-height-md-60 p-0 m-0"
                  >
                    {{ peopleCycleItem.title }}
                  </p>
                  <p
                    v-if="peopleCycleItem.acronym != null || ''"
                    class="tw-text-xs fs-md-30 p-0 m-0 d-none d-md-block"
                  >
                    ({{ peopleCycleItem.acronym }})
                  </p>
                </div>

                <div class="col-12 col-md-5 order-3 p-0">
                  <p
                    class="text-white tw-font-robotoLight tw-text-xs fs-md-20 line-height-18 line-height-md-24 text-md-right pl-md-4"
                  >
                    {{ peopleCycleItem.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- People Cycle Main Image-->
          <div class="px-3 px-md-5 py-5">
            <div class="text-center position-relative">
              <img
                :src="uriResources + '/' + peopleCycleItem.image"
                class="img-fluid"
                alt=""
              />
              <img
                src="@/assets/img/peopleCycle/cc_icon_opr.png"
                class="h-10 h-md-100 tag"
                alt=""
              />
            </div>
          </div>
          <!-- People Cycle Sub Title and Sub Description-->
          <div
            class="px-3 px-md-5 pb-3 py-md-5"
            v-if="
              peopleCycleItem.sub_title !== null ||
              peopleCycleItem.sub_description !== null
            "
          >
            <div class="row justify-content-center">
              <div class="col-md-8 text-center position-relative px-3 px-md-5">
                <p class="tw-font-robotoBold text-uppercase fs-22 fs-md-28">
                  {{ peopleCycleItem.sub_title }}
                </p>
              </div>

              <div class="col-md-8 text-center position-relative px-3 px-md-5">
                <p class="tw-font-roboto tw-text-sm fs-md-20 line-height-24">
                  {{ peopleCycleItem.sub_description }}
                </p>
              </div>
            </div>
          </div>
          <!-- People Cycle Sub Items-->
          <div
            class="px-3 px-md-5"
            v-if="peopleCycleItem.people_cycle_sub_item.length > 0"
          >
            <div
              class="row justify-content-around justify-content-md-center px-3 px-md-5 py-3 pt-md-3"
            >
              <div
                class="col-3 col-md m-1 m-md-3 tw-rounded-lg shadow-box-6 bg-white p-1 p-md-3"
                v-for="item in peopleCycleItem.people_cycle_sub_item"
                :key="item.id"
              >
                <div class="overflow-hidden text-center">
                  <img
                    :src="uriResources + '/' + item.icon"
                    class="img-fluid p-2"
                    alt=""
                  />
                  <p
                    class="tw-font-roboto tw-text-xxs fs-md-16 tw-font-bold py-2 m-0"
                  >
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- STEPS -->
        <div class="container mt-md-5" v-if="peopleCycleItem.steps.length > 0">
          <div class="row">
            <div class="tw-rounded-lg col-12 col-md-12 py-4">
              <div class="text-center px-2 px-md-3 pt-3">
                <p class="tw-font-tolyerBold fs-34 fs-md-50">
                  {{ peopleCycleItem.steps[0].title }}
                </p>
              </div>
              <div class="container">
                <div class="row justify-content-center">
                  <div
                    class="col-6 col-sm px-md-2 px-1 py-md py-1"
                    v-for="item in peopleCycleItem.steps[0]
                      .people_cycle_step_card"
                    :key="item.id"
                  >
                    <div
                      class="h-100 tw-rounded-lg d-flex flex-column text-center overflow-hidden pb-md-4"
                      :style="
                        'background-color: ' +
                        item.color_background +
                        '; border: 2px solid ' +
                        item.color_content
                      "
                    >
                      <div
                        class="overflow-hidden text-center px-2 pt-1 pb-3 p-md-4"
                      >
                        <p
                          v-if="item.number_item_active"
                          class="tw-font-tolyerBold tw-text-xxxs 0 fs-md-90 tw-font-bold m-0"
                          :style="'color: ' + item.color_content"
                        >
                          {{ item.number_item }}
                        </p>
                        <div
                          v-if="item.icon_active"
                          class="text-center w-40 mx-auto pt-3 pb-2"
                        >
                          <img
                            :src="uriResources + '/' + item.icon"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <p
                          v-if="item.title_active"
                          class="tw-font-tolyerBold fs-28 fs-md-28 tw-font-bold m-0"
                          :style="'color: ' + item.color_content"
                        >
                          {{ item.title }}
                        </p>
                        <p
                          v-if="item.sub_title_active"
                          class="tw-font-roboto tw-text-sm fs-md-14 tw-font-medium m-0"
                          :style="'color: ' + item.color_content"
                        >
                          {{ item.sub_title }}
                        </p>
                        <p
                          v-if="item.description_active"
                          class="tw-font-roboto tw-text-xs fs-md-12 tw-font-normal pt-5 m-0"
                          :style="'color: ' + item.color_content"
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row justify-content-end mt-3 mb-5 my-md-3 mb-2"
                >
                  <div class="col-md-2 col px-0">
                    <div
                      @click="pushLink(peopleCycleItem.steps[0].url)"
                      class="tw-text-sm fs-md-14 line-height-16 tw-font-roboto hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-3 w-100 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center tw-cursor-pointer"
                    >
                      {{ peopleCycleItem.steps[0].url_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabPeople
          v-if="peopleCycleItem.indicator"
          :items="peopleCycleItem.indicator"
        />
        <CardPeople2
          v-if="peopleCycleItem.califications"
          :califications="peopleCycleItem.califications"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsPeopleCycle from "@/components/general/ButtonsPeopleCycle";
// import ButtonsOPR from "@/components/general/ButtonsOPR";
import PCNavigate from "@/components/general/PCNavigate";
import TabPeople from "@/components/people/tabs/TabPeople";
import CardPeople2 from "@/components/people/cards/CardPeople2";
import linksMixin from "@/mixins/general/links";

import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import cvMixin from "@/mixins/user/cv";
import people from "@/mixins/user/people";

export default {
  name: "OPR",
  mixins: [cvMixin, people, linksMixin],
  components: {
    ButtonsPeopleCycle,
    PCNavigate,
    TabPeople,
    CardPeople2,
  },
  data() {
    return {
      isLoading: true,
      uriResources: config.beerOfficeURL,
      pc_item: 6,
      OPRCalendar: 2,
      OPRCalendarIndex: 0,
      pcButtons: [],
      oprButtons: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.actGetPeopleCycleItem({
      id: 6,
    })
      .then(() => {
        this.pcButtons = this.peopleCycleButtons;
        this.oprButtons = this.pcButtons;
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        /*       this.getCalendar;*/
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modPeopleCycle", ["peopleCycleItem"]),
    // getCalendar() {
    //   let filteredCalendar = this.peopleCycleItem.indicator.filter(
    //     (e) => e.id === this.OPRCalendar
    //   );
    //
    //   if (filteredCalendar[0].people_cycle_calendar.length > 0) {
    //     return filteredCalendar[0].people_cycle_calendar[0];
    //   }
    //
    //   return null;
    // },
  },
  methods: {
    ...mapActions("modPeopleCycle", ["actGetPeopleCycleItem"]),
    changeOPR(number, index) {
      this.OPRCalendar = number;
      this.OPRCalendarIndex = index;
    },
  },
};
</script>

<style scoped>
.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>
