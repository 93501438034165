var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.bookingStep === 3),expression:"bookingStep === 3"}],staticClass:"tw-flex tw-flex-col tw-gap-5"},[_c('div',{attrs:{"id":"guestsTitle"}},[_c('p',{staticClass:"tw-font-robotoBold tw-text-base md:tw-text-2xl"},[_vm._v("Huéspedes")]),_c('p',{staticClass:"tw-font-roboto tw-text-xs md:tw-text-lg"},[_vm._v(" Este alojamiento tiene una capacidad máxima de "+_vm._s(_setup.bookingPlaceSelected.capacity)+" huéspedes, los niños menores de 3 años pueden ingresar sin ser contados en la capacidad del alojamiento ")])]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4 md:tw-gap-8",attrs:{"id":"guestsDetails"}},[_c(_setup.CompGuestsNumber,{attrs:{"guestsNumber":_vm.reservationData.guestsNumber.adults,"item":{
        idName: 'adultGuests',
        label: 'Adultos',
        description: 'Más de 13 años',
      },"overCrowded":_vm.overCrowded},on:{"decrementGuests":_vm.decrementAdultGuests,"incrementGuests":_vm.incrementAdultGuests}}),_c(_setup.CompGuestsNumber,{attrs:{"guestsNumber":_vm.reservationData.guestsNumber.children,"item":{
        idName: 'childrenGuests',
        label: 'Niños',
        description: 'De 3 a 12 años',
      },"overCrowded":_vm.overCrowded},on:{"decrementGuests":_vm.decrementChildrenGuests,"incrementGuests":_vm.incrementChildrenGuests}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }