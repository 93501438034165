<template>
  <div id="modal-notifications">
    <div class="cont-notifications animated fadeInLeft">
      <div
        class="cont-title d-flex justify-content-between align-items-center px-3 pt-2"
      >
        <h2
          class="text-uppercase mb-0 textColorPrimary tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"
        >
          Notificaciones
        </h2>
        <span
          class="btn-close icon-cerrar-simple"
          @click="hideNotifications()"
        ></span>
      </div>
      <!-- .cont-title -->
      <div class="cont-list-notifications" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="../../assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div v-else class="cont-list-notifications">
        <div
          class="notification position-relative d-flex align-items-center mb-2"
          :class="{ 'notification-read': notification.read_at }"
          v-for="notification in notifications"
          :key="notification.id"
          @click="
            actNotificationAndRedirect(
              notification.id,
              notification.url,
              notification
            )
          "
        >
          <span class="d-none">{{
            (color = selectOpcNotification(notification.section.id).color)
          }}</span>
          <div
            class="border-left position-absolute"
            :style="'background: ' + color + ';'"
          ></div>
          <div
            class="cont-icon d-flex justify-content-center align-items-center mr-2"
            :style="'background: ' + color + ';'"
          >
            <div class="position-relative">
              <span
                class="red-notification"
                v-show="notification.read_at == null"
              ></span>
              <span
                :class="
                  'icon-' + selectOpcNotification(notification.section.id).icon
                "
              ></span>
            </div>
          </div>
          <div class="cont-txt py-2 pr-2">
            <p class="title">{{ notification.title }}</p>
            <p class="desc">{{ notification.message }}</p>
            <p class="date mb-0 text-capitalize">
              {{ notification.created_at | moment("from") }}
            </p>
          </div>
          <span v-if="notification.url" class="icon-flecha-der"></span>
        </div>
        <!-- .notification -->
        <!-- <div
          class="notification position-relative d-flex align-items-center mb-2"
          v-for="notification in notificationsRead"
          :key="notification[0].id"
          @click="
            actNotificationAndRedirect(notification[0].id, notification[0].url, notification[0])
          "
        >
          <span class="d-none">{{
            (color = selectOpcNotification(notification[0].section.id).color)
          }}</span>
          <div class="border-left position-absolute" :style="'background: ' + color + ';'"></div>
          <div
            class="cont-icon d-flex justify-content-center align-items-center mr-2"
            :style="'background: ' + color + ';'"
          >
            <div class="position-relative">
              <span
                :class="'icon-' + selectOpcNotification(notification[0].section.id).icon"
              ></span>
            </div>
          </div>
          <div class="cont-txt py-2">
            <p class="title">{{ notification[0].title }}</p>
            <p class="desc">{{ notification[0].message }}</p>
            <p class="date mb-0 text-capitalize">
              {{ notification[0].created_at | moment("from") }}
            </p>
          </div>
          <span class="icon-flecha-der"></span>
        </div> -->
        <!-- .notification -->
      </div>
      <!-- .cont-list-notifications -->
    </div>
    <!-- .cont-notifications -->
  </div>
  <!-- #modal-notifications -->
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "CompNotifications",
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    this.actGetNotifications()
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapMutations("modNotifications", [
      "toggleShowNotifications",
      "hideNotifications",
    ]),
    ...mapActions("modNotifications", [
      "actMarkNotificationsAsRead",
      "actGetNotifications",
    ]),
    selectOpcNotification(sectionId) {
      let opcNotifications = {};
      switch (sectionId) {
        case 109:
        case 101:
          opcNotifications = {
            color: "#40140F",
            icon: "ecommerce",
          };
          break;

        case 111:
        case 118:
          opcNotifications = {
            color: "#F94747",
            icon: "cuidados-y-beneficios",
          };
          break;

        case 105:
          opcNotifications = {
            color: "#FAB23A",
            icon: "universo-cervecero",
          };
          break;

        case 116:
          opcNotifications = {
            color: "#D82B2B",
            icon: "caja-de-cerveza",
          };
          break;

        case 107:
          opcNotifications = {
            color: "#D82B2B",
            icon: "calendario",
          };
          break;

        default:
          opcNotifications = {
            color: "#D82B2B",
            icon: "caja-de-cerveza",
          };
          break;
      }
      return opcNotifications;
    },
    actNotificationAndRedirect(id, url, notification) {
      this.pushDataLayer(notification.section.name, notification.title);
      if (url) {
        this.$router.push(url);
      }
      this.toggleShowNotifications();
      this.actMarkNotificationsAsRead(id);
    },
    pushDataLayer: function (type_not, type_name) {
      window.dataLayer.push({
        event: "TrackEvent-Notification",
        category: "Notification",
        Type: type_not,
        Notificacion: type_name,
        Action: "Clics",
      });
    },
  },
  computed: {
    ...mapState("modNotifications", ["notifications", "notificationsRead"]),
  },
  watch: {
    $route() {
      this.hideNotifications();
    },
  },
};
</script>

<style scoped>
#modal-notifications {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
}

@media (min-width: 768px) {
  #modal-notifications {
    top: 72px;
    left: 60px;
  }
}

.cont-notifications {
  max-width: 360px;
  background: #f2f2f2;
}

.cont-notifications .cont-list-notifications {
  overflow: scroll;
  height: calc(100vh - 110px);
  padding: 12px;
}

.cont-notifications .cont-title {
  background: #ffffff;
}

.cont-notifications .cont-title h2 {
  font-size: 24px;
}

.cont-notifications .cont-title span {
  color: #d82b2b;
  font-size: 14px;
  cursor: pointer;
}

.cont-notifications .notification {
  background: #ffffff;
  border-radius: 10px;
  padding: 0 0 0 20px;
  cursor: pointer;
}

.cont-notifications .notification-read {
  opacity: 0.5;
}

.cont-notifications .notification .border-left {
  border-radius: 10px 0 0 10px;
  width: 10px;
  left: 0;
  height: 100%;
}

.cont-notifications .notification .cont-icon {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  border-radius: 50%;
}

.cont-notifications .notification .cont-icon span {
  color: #ffffff;
  font-size: 18px;
}

.cont-notifications .notification .cont-icon .red-notification {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #d82b2b;
  border-radius: 50%;
  top: -4px;
  right: -8px;
}

.cont-notifications .notification .title {
  color: #222222;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
  margin-bottom: 8px;
}

.cont-notifications .notification .desc {
  color: #171717;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}

.cont-notifications .notification .date {
  color: #717171;
  font-size: 10px;
}

.cont-notifications .notification .icon-flecha-der {
  color: #d82b2b;
  font-size: 26px;
}

/**Animation */
.animated {
  animation-duration: 0.5s;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}
</style>
