<template>
  <div class="tw-container-general">
    <ButtonsMenu class="py-3 mb-2 mb-md-4" :buttonsMenuItemActive="3" />
    <div class="container mb-3">
      <div class="row">
        <div class="col-12">
          <p
            class="fs-30 fs-md-40 text-uppercase tw-text-gray-600 font-weight-bold tw-font-tolyerBold mb-3"
          >
            Mis solicitudes
          </p>
        </div>
      </div>
    </div>
    <div class="container mb-4">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="d-flex mb-3">
            <div
              role="button"
              class="d-flex align-items-center py-2 px-3 tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 hover:tw-shadow-md mr-4"
              :class="
                activeRequestComponent === 'Request' ? 'tw-shadow-md' : ''
              "
              @click="changeTabApprovalRequest('Request')"
            >
              <p
                class="tw-text-xs fs-md-14 tw-text-gray-600 mb-0 font-weight-bold"
              >
                Mis solicitudes
              </p>
              <div class="wi-1 he-20 tw-bg-gray-300 d-block mx-2"></div>
              <div
                class="tw-bg-red-400 d-flex justify-content-center align-items-center tw-rounded-full wi-20 he-20"
              >
                <p class="mb-0 tw-text-white tw-text-xs">{{ requestsCount }}</p>
              </div>
            </div>
            <div
              v-if="requestsByLineManagerCount > 0"
              role="button"
              class="d-flex align-items-center py-2 px-3 tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 hover:tw-shadow-md"
              :class="
                activeRequestComponent === 'Approval' ? 'tw-shadow-md' : ''
              "
              @click="changeTabApprovalRequest('Approval')"
            >
              <p
                class="tw-text-xs fs-md-14 tw-text-gray-600 mb-0 font-weight-bold"
              >
                Aprobaciones
              </p>
              <div class="wi-1 he-20 tw-bg-gray-300 d-block mx-2"></div>
              <div
                class="tw-bg-green-400 d-flex justify-content-center align-items-center tw-rounded-full wi-20 he-20"
              >
                <p class="mb-0 tw-text-white tw-text-xs">
                  {{ requestsByLineManagerCount }}
                </p>
              </div>
            </div>
          </div>
          <div class="mb-4 he-md-480 tw-overflow-y-scroll px-2 pt-2">
            <div class="text-center mt-3" v-if="isLoading">
              <img src="@/assets/img/icons/loading.gif" alt="loading" />
            </div>
            <div v-else-if="activeRequestComponent === 'Request'">
              <div v-if="requestsCount > 0">
                <Request
                  v-for="(request, index) in vacantRequests"
                  :key="request.id"
                  :request="request"
                  @click.native="selectOpportunity($event, index)"
                />
              </div>
              <div v-else class="alert alert-warning mt-3" role="alert">
                No existen solicitudes.
              </div>
            </div>
            <div v-else-if="activeRequestComponent === 'Approval'">
              <Approval
                v-for="(requestByLm, index) in vacantRequestsByLineManager"
                :key="requestByLm.id"
                :request="requestByLm"
                @selectRequest="(id) => selectRequest(index, id)"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="text-center mt-3" v-if="isLoading">
            <img src="@/assets/img/icons/loading.gif" alt="loading" />
          </div>
          <div v-else>
            <RequestDetail
              v-if="Object.keys(requestSelected).length > 0"
              :request="requestSelected"
              :requestsByLineManagerCount="requestsByLineManagerCount"
              @updateState="(state, id) => updateState(state, id)"
              class="d-none d-lg-block"
            />
            <div v-else class="alert alert-warning mt-3" role="alert">
              No existen resultados.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ButtonsMenu from "@/components/general/ButtonsMenu";
import RequestDetail from "@/components/people/requests/RequestDetail";
import vacant from "@/mixins/user/vacant";

export default {
  name: "RequestsView",
  mixins: [vacant],
  components: {
    ButtonsMenu,
    RequestDetail,
    Request: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "requests" */ "@/components/people/requests/Request"
      ),
    Approval: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "requests" */ "@/components/people/requests/Approval"
      ),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    this.getVacantRequests()
      .then(() => {
        if (this.$route.params.id === "all") {
          if (this.vacantRequests.length > 0) {
            this.requestSelected = this.vacantRequests[0];
            setTimeout(() => {
              this.changeTabApprovalRequest("Request");
            }, 300);
          } else {
            this.requestSelected = {};
          }
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.getVacantRequestsByLineManager().then(() => {
      if (this.vacantRequestsByLineManager.length > 0) {
        this.requestSelected =
          this.vacantRequestsByLineManager[0].people_vacant_positions[0];
        setTimeout(() => {
          this.changeTabApprovalRequest("Approval");
        }, 200);
      } else {
        this.requestSelected = {};
      }
    });
  },
  computed: {
    ...mapState("modVacants", [
      "vacantRequests",
      "vacantRequestsByLineManager",
      "activeRequestComponent",
    ]),
    requestsCount() {
      return this.vacantRequests.length;
    },
    requestsByLineManagerCount() {
      return this.vacantRequestsByLineManager.length;
    },
  },
  methods: {
    ...mapActions("modVacants", [
      "getVacantRequests",
      "getVacantRequestsByLineManager",
      "changeRequestMainComponent",
      "changeVacantState",
    ]),
    changeTabApprovalRequest(component) {
      if (component === "Request") {
        if (this.vacantRequests.length > 0) {
          this.requestSelected = this.vacantRequests[0];
        } else {
          this.requestSelected = {};
        }
      } else {
        if (this.vacantRequestsByLineManager.length > 0) {
          this.requestSelected =
            this.vacantRequestsByLineManager[0].people_vacant_positions[0];
        } else {
          this.requestSelected = {};
        }
      }
      this.changeRequestMainComponent(component);
    },
    selectRequest(position, id) {
      this.requestSelected =
        this.vacantRequestsByLineManager[position].people_vacant_positions[id];
    },
    selectOpportunity(event, id) {
      this.requestSelected = this.vacantRequests[id];
      let vacantCard = event.srcElement.offsetParent;

      let opportunityCards =
        event.srcElement.offsetParent.parentElement.parentElement.querySelectorAll(
          "div .opportunity-card"
        );
      opportunityCards.forEach((i) => {
        i.classList.add("tw-shadow-sm");
        i.classList.remove("tw-shadow-md");
        i.classList.add("tw-bg-white");
        i.classList.remove("tw-bg-gray-200");
      });
      vacantCard.classList.remove("tw-shadow-sm");
      vacantCard.classList.add("tw-shadow-md");
      vacantCard.classList.remove("tw-bg-white");
      vacantCard.classList.add("tw-bg-gray-200");
    },
  },
};
</script>
