import { render, staticRenderFns } from "./CompResult.vue?vue&type=template&id=10c362b2&scoped=true"
import script from "./CompResult.vue?vue&type=script&setup=true&lang=js"
export * from "./CompResult.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CompResult.vue?vue&type=style&index=0&id=10c362b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c362b2",
  null
  
)

export default component.exports