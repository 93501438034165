<template>
  <div>
    <div class="row ml-n2 m-md-auto">
      <div class="col col-md-11">
        <p
          class="m-0 tw-font-robotoBold tw-text-lg line-height-20 fs-md-24 line-height-md-30"
        >
          Simula tu Bono aquí
        </p>
      </div>
      <div
        v-b-modal.modal-sti-administrative
        class="col-1 d-flex align-items-center justify-content-end"
      >
        <i class="icon-mas-informacion tw-text-lg fs-md-24 tw-text-red-400"></i>
      </div>
      <b-modal
        cclass="showme d-flex justify-content-center"
        :id="'modal-sti-administrative'"
        centered
        hide-footer
        :modal-title="false"
        size="lg"
      >
        <div class="container text-center">
          <p class="modal-title">Ten en cuenta</p>
          <p>Así es como se calcula el Achievement Score</p>
          <img
            class="desc-image d-none d-md-block"
            src="@/assets/img/show-me/achievement-score.png"
            alt=""
          />
          <img
            class="desc-image d-block d-md-none"
            src="@/assets/img/show-me/achievement-score-mobile.png"
            alt=""
          />
          <p class="disclaimer pt-2">
            *
            <strong> Size of Pie: </strong>Es el valor disponible para el pago
            de bonos y está determinado por el crecimiento orgánico del EBITDA a
            nivel global.
            <br />
            ** <strong> Entity Performance: </strong>Refleja la contribución de
            la BU Colombia a los resultados de ABI (EBITDA-Cash flow and Net
            revenue).
            <br />
            ***<strong> Individual Performance: </strong>Cumplimiento de metas
            del director de tu área.
            <br />
            **** El simulador es una herramienta para calcular valores estimados
            del bono. El valor a pagar es el que esté acorde a las políticas de
            la compañía la cual se reserva el derecho a modificarlas.
          </p>
        </div>
      </b-modal>
      <div
        class="col-12 pt-2 tw-font-roboto tw-text-sm line-height-18 tw-text-gray-600"
      >
        Para simular el valor de tu bono STI introduce los valores
        correspondientes en cada campo.
      </div>
      <div class="col-12 pt-3">
        <form>
          <p
            class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
          >
            Achievement Score
          </p>
          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-individualperformance text-white tw-text-xl"></i>
              </div>
            </div>
            <input
              type="number"
              class="form-control text-right"
              id="tdr-value"
              placeholder="Ingresa tu costo"
              :value="bonusProps.achievementScore"
              @input="checkScore($event.target.value)"
            />
            <div class="input-group-append">
              <span class="input-group-text bg-white tw-rounded-lg">%</span>
            </div>

            <small class="error" v-if="error.achievementScore.value">{{
              error.achievementScore.message
            }}</small>
          </div>

          <p
            class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
          >
            Bonus Target
          </p>
          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-percentage text-white tw-text-xl"></i>
              </div>
            </div>
            <input
              type="number"
              min="40"
              max="160"
              class="form-control text-right"
              id="tdr-value"
              placeholder="Ingresa tu costo"
              :value="stiTarget"
              readonly
            />
            <div class="input-group-append">
              <span class="input-group-text tw-rounded-lg">%</span>
            </div>
          </div>

          <p
            class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
            v-if="employeeCorePosition === 'VII-VIII'"
          >
            Salario Anual
          </p>
          <p
            class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
            v-if="employeeCorePosition === 'VIII+'"
          >
            Salario Anual
          </p>

          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-beneficios-dinero text-white tw-text-xl"></i>
              </div>
            </div>
            <input
              type="text"
              class="form-control text-right tw-rounded-lg"
              id="anual-salary"
              placeholder="Ingresa tu costo"
              :value="formatCurrency(salaryCalculate)"
              readonly
            />
          </div>

          <p
            class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
          >
            Dedicación de tiempo
          </p>
          <p
            v-if="bonusProps.tdrTime !== 0"
            class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
          >
            - Valor: {{ (bonusProps.tdrTime * 100).toFixed(0) }} %
          </p>
          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-calendario2 text-white tw-text-xl"></i>
              </div>
            </div>
            <select
              class="form-control tw-rounded-lg"
              id="tdr-value"
              v-model="bonusProps.tdrTime"
            >
              <option value="0" selected>Tiempo laborado</option>

              <option value="0.5">6 meses</option>
              <option value="0.58">7 meses</option>
              <option value="0.66">8 meses</option>
              <option value="0.75">9 meses</option>
              <option value="0.83">10 meses</option>
              <option value="0.91">11 meses</option>
              <option value="1">12 meses</option>
            </select>
          </div>
        </form>
        <div
          class="calculator-total-value text-center text-light d-flex justify-content-between align-items-center pt-3 pb-2 tw-border-b tw-border-gray-300"
        >
          <span class="pr-2 tw-font-robotoBold tw-text-base tw-text-gray-600"
            >Valor del bono STI</span
          >
          <span
            class="tw-font-roboto tw-text-xl fs-md-24 line-height-32 tw-text-green-500"
          >
            {{ formatCurrency(calculateBonus) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import numbersMixin from "@/mixins/general/numbers";
import { error } from "@/helpers/bonusHelpers";

export default {
  name: "CalculatorAdministrative",
  mixins: [numbersMixin],
  data() {
    return {
      error: error,
      bonusProps: {
        achievementScore: 0,
        tdrTime: 0,
      },
    };
  },
  components: {},
  props: {
    employeeCorePosition: {
      type: String,
    },
  },
  computed: {
    ...mapState("modCore", ["user"]),
    calculateBonus() {
      let bonus =
        this.salaryCalculate *
        (this.bonusProps.achievementScore / 100) *
        this.stiTarget / 100 *
        this.bonusProps.tdrTime;

      if (bonus > 0) {
        this.pushDataLayer();
      }
      return bonus;
    },
    stiTarget() {
      let target = Number(this.user.target.replace(",", "."));
      let result = (target * 100).toFixed(2);
      return Number(result) === parseInt(result)
        ? parseInt(result)
        : Number(result);
    },
    salaryCalculate() {
      return this.employeeCorePosition === "VII-VIII"
        ? Number(this.user.anual_salary) +
            Number(this.user.legal_premium) +
            Number(this.user.extra_legal_premium)
        : Number(this.user.total_salary) * 13;
    },
  },
  methods: {
    checkScore(value) {
      if (parseFloat(value) <= 160) {
        error.achievementScore.value = false;
        this.bonusProps.achievementScore = value;
      } else {
        error.achievementScore.value = true;
        error.achievementScore.message = "Ingrese un número entre 0 y 160";
        this.bonusProps.achievementScore = 0;
      }
    },
    pushDataLayer() {
      window.dataLayer.push({
        event: "TrackEvent-compensacion",
        category: "Remuneración",
        SubCategory: "Bono STI",
        Action: "Simulador",
      });
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

small.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.input-group-append span {
  background: #e9ecef;
}

.modal-title {
  color: #d82b2b;
  font: bold 16px/19px Roboto;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 1;
  margin-bottom: 10px;
}

.disclaimer {
  text-align: left;
  font: normal normal normal 12px/13px Roboto;
  letter-spacing: 0;
  color: #343434 !important;
  opacity: 1;
}

.desc-image {
  max-width: 100%;
  height: auto;
}
</style>
