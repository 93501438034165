const numbersMixin = {
  methods: {
    formatMoney(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    formatNumber(number, numberOfDigits = 2) {
      try {
        return new Intl.NumberFormat("en-US").format(
          parseFloat(number).toFixed(numberOfDigits)
        );
      } catch (error) {
        return 0;
      }
    },
    formatCurrency(number, numberOfDigits = 0) {
      try {
        return new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          maximumFractionDigits: numberOfDigits,
        }).format(number);
      } catch (error) {
        return 0;
      }
    },
  },
};

export default numbersMixin;
