<script setup>
import { onBeforeMount } from "vue";
import { useEventsStore } from "@/stores/eventsStore";
import { storeToRefs } from "pinia";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompEventsGridCard from "@/components/events/CompEventsGridCard.vue";

const eventsStore = useEventsStore();
const { actGetEvents } = eventsStore;
const { events } = storeToRefs(eventsStore);

onBeforeMount(async () => {
  await actGetEvents();
  console.log(events.value);
});
</script>

<template>
  <div>
    <mobile-bar title="Horas de vuelo" />
    <title-bar title="Horas de vuelo" />
    <main class="tw-container-general">
      <div class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6">
        <h4 class="tw-font-robotoBold">Eventos</h4>
        <p class="tw-m-0 tw-text-xs lg:tw-text-base">
          Lorem ipsum dolor sit amet consectetur. Magna nisl sollicitudin dolor
          est ultrices eu. Semper quisque facilisis erat eget ornare egestas
          bibendum sit ac. Eget elementum vitae sem rhoncus. Pellentesque
          sodales dictumst sit et.
        </p>

        <section
          id="events-grid"
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-3 lg:tw-gap-6"
        >
          <comp-events-grid-card
            v-for="(event, idx) in events"
            :key="idx"
            :event="event"
          />
        </section>
      </div>
    </main>
  </div>
</template>

<style scoped></style>
