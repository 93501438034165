<script setup>
import { ref, computed, onBeforeMount, getCurrentInstance } from "vue";
import config from "@/services/config";
import GeneralSplash from "@/components/general/GeneralSplash.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCardDropdown from "@/components/cards/CompGeneralTopCardDropdown.vue";
import CompGeneralDropdownIcon from "@/components/general/CompGeneralDropdownIcon.vue";
import CompGeneralFeaturedCard from "@/components/general/CompGeneralFeaturedCard.vue";
import CompGeneralOrderFilter from "@/components/general/CompGeneralOrderFilter.vue";
import CompGeneralGridCard from "@/components/general/CompGeneralGridCard.vue";
import CompGeneralShowMore from "@/components/general/CompGeneralShowMore.vue";
import { sortArrayByCriteria } from "@/helpers/arrayHelpers";
import { useBenefitsAgreementsStore } from "@/stores/benefitsAgreementsStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import CompCarouselArrows from "@/components/carousels/CompCarouselArrows.vue";
import { storeToRefs } from "pinia";

const uriResources = config.beerOfficeURL;
const isSplash = ref(true);
const isLoading = ref(false);
const featuredAgreement = ref(null);
const otherAgreements = ref([]);
const agreementsVisible = ref(6);
const { $isMobile: mobile } = getCurrentInstance().proxy;
const { actGetAgreements, actGetAgreementCategories } =
  useBenefitsAgreementsStore();
const { agreementSelected, agreementCategories, agreements } = storeToRefs(
  useBenefitsAgreementsStore()
);

const showMore = computed(() => {
  return agreementsVisible.value < otherAgreements.value.length;
});

function orderAgreements(value) {
  otherAgreements.value = sortArrayByCriteria(otherAgreements.value, value.id);
}

async function changeAgreement(val, source, charge) {
  agreementSelected.value = val;
  await getAgreements();
  if (source === "card") {
    window.scrollTo(0, 0);
    pushDataLayer({
      event_category: "Convenios",
      event_action: "Descubre más convenios",
      event_label: val.name,
      component_name: "Click",
    });
  } else {
    if (!charge) {
      pushDataLayer({
        event_category: "Convenios",
        event_action: "¿Qué convenios quieres ver?",
        event_label: val.name,
        component_name: "Click",
      });
    }
  }
}

const agreementSelectedImage = computed(() => {
  return uriResources + "/" + agreementSelected.value?.image;
});

async function getAgreements() {
  await actGetAgreements(agreementSelected.value.id).then(() => {
    featuredAgreement.value = agreements.value.find((b) => b.featured === 1);
    otherAgreements.value = agreements.value.filter((b) => b.featured === 0);
  });
  orderAgreements({ id: 0 });
}

const showMoreContent = () => {
  if (agreementsVisible.value < otherAgreements.value.length) {
    agreementsVisible.value += 6;
  } else {
    agreementsVisible.value = 6;
  }
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetAgreementCategories().then(() => {
    if (!agreementSelected.value) {
      agreementSelected.value = agreementCategories.value[0];
    }
  });
  isLoading.value = false;
});
</script>

<template>
  <div>
    <div v-if="isSplash && isLoading">
      <general-splash
        title="Convenios"
        icon="icon-inside.svg"
        illustration="illustration-beneficios.svg"
        @loaded="isSplash = false"
      >
        Descubre y disfruta los beneficios y todas las ventajas exclusivas que
        hemos preparado especialmente para ti.
      </general-splash>
    </div>
    <div v-else-if="!isLoading">
      <mobile-bar title="Convenios" />
      <title-bar title="Convenios" />
      <main class="tw-container-general tw-mt-0 tw-py-0">
        <comp-general-top-card-dropdown
          title="¿Qué convenios quieres ver?"
          :image="agreementSelectedImage"
          bg-color="FCF2EB"
          dropdown
        >
          <template #description>
            Descubre y disfruta los beneficios y todas las ventajas exclusivas
            que hemos preparado especialmente para ti.
          </template>
          <template #dropdown>
            <comp-general-dropdown-icon
              :options="agreementCategories"
              :is-label="false"
              :fitted="false"
              :default-option="agreementSelected.id"
              icon-visible
              @changeOption="changeAgreement"
            />
          </template>
          <template #dropdownMobile>
            <comp-general-dropdown-icon
              :options="agreementCategories"
              :is-label="false"
              :fitted="false"
              :default-option="agreementSelected.id"
              embedded-mobile
              icon-visible
              @changeOption="changeAgreement"
            />
          </template>
        </comp-general-top-card-dropdown>
        <hr class="tw-w-full lg:tw-my-10" />
        <comp-general-featured-card
          v-if="featuredAgreement"
          :item="featuredAgreement"
          :category="featuredAgreement.ben_emp_agreement_category?.category"
          :title="featuredAgreement.name"
          :description="featuredAgreement.description"
          :link="`/agreements/${featuredAgreement?.id}`"
          :image="featuredAgreement.main_image"
          category-for-social="AGREEMENTS"
          category-for-datalayer="Convenios"
          datalayer-active
          @updatedComment="getAgreements"
        />
        <hr class="tw-w-full lg:tw-my-10" v-if="featuredAgreement" />
        <section v-if="otherAgreements.length > 0">
          <div
            class="tw-flex tw-justify-between tw-items-start tw-mt-10 tw-mb-5 tw-h-auto tw-max-h-10"
          >
            <div class="tw-flex tw-gap-x-6 tw-items-center tw-justify-start">
              <p
                class="tw-mb-0 tw-text-black tw-text-xl md:tw-text-3xl tw-font-robotoBold"
              >
                Más convenios
              </p>
            </div>
            <comp-general-order-filter
              @changeFilter="orderAgreements"
              category-for-datalayer="Convenios"
              not-views
            />
          </div>
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8"
          >
            <div
              class="tw-flex tw-flex-col"
              v-for="(item, idx) in otherAgreements.slice(0, agreementsVisible)"
              :key="idx"
            >
              <comp-general-grid-card
                :id="item.id"
                :item="item"
                :category="item.ben_emp_agreement_category?.category"
                category-for-social="AGREEMENTS"
                :title="item.name"
                :description="item.description"
                :link="`/agreements/${item?.id}`"
                :image="mobile() ? item.banner_image : item.main_image"
                category-for-datalayer="Convenios"
                action-for-datalayer="Más convenios"
                @updatedComment="getAgreements"
                @updatedReaction="getAgreements"
              />
              <hr class="tw-w-full md:tw-hidden" />
            </div>
          </div>
          <comp-general-show-more
            v-if="otherAgreements.length > 6"
            :show-more="showMore"
            @showMoreContent="showMoreContent"
            datalayer-active
            category-for-datalayer="Convenios"
            action-for-datalayer="Más convenios"
          />
          <hr class="tw-w-full lg:tw-my-10" v-else />
        </section>
        <aside class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
          <div
            class="tw-flex tw-gap-x-6 tw-items-center tw-justify-start lg:tw-ml-10"
          >
            <p
              class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-3xl tw-font-robotoBold"
            >
              Descubre más convenios
            </p>
          </div>
          <comp-carousel-arrows>
            <div
              class="tw-grid tw-grid-cols-4 lg:tw-grid-flow-col tw-gap-1 lg:tw-gap-2 lg:tw-w-max"
            >
              <div
                class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-xl tw-border tw-border-black tw-gap-2 tw-w-full lg:tw-w-[150px] tw-max-h-24 lg:tw-max-h-full tw-p-1 lg:tw-p-2 tw-cursor-pointer"
                v-for="(item, idx) in agreementCategories"
                :key="idx"
                @click="changeAgreement(item, 'card')"
              >
                <img
                  :src="uriResources + '/' + item.image"
                  class="tw-object-contain tw-w-7/12"
                  alt=""
                />
                <p class="tw-m-0 tw-font-roboto tw-text-xxs">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </comp-carousel-arrows>
        </aside>
      </main>
    </div>
  </div>
</template>

<style scoped></style>
