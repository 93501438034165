<script setup>
import { computed, ref, onBeforeMount, getCurrentInstance } from "vue";
import { useRouter } from "vue-router/composables";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGoFlexTopButtons from "@/components/goFlex/CompGoFlexTopButtons.vue";
import SelectCategoriesButtons from "@/components/general/SelectCategoriesButtons.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompGoFlexRequestsTableDesk from "@/components/goFlex/CompGoFlexRequestsTableDesk.vue";
import CompGoFlexRequestsTableMobile from "@/components/goFlex/CompGoFlexRequestsTableMobile.vue";

const { $isMobile } = getCurrentInstance().proxy;
const router = useRouter();
const goFlexStore = useGoFlexStore();
const isLoading = ref(false);
const catalog = ref(null);
const requestFilter = ref("");
const isApprovalModal = ref(false);
const approve = ref(null);
const categories = ref([
  {
    id: 0,
    name: "Pendientes por aprobación",
    key: "PENDING",
  },
  {
    id: 1,
    name: "Tramitadas",
    key: "APPROVED",
  },
]);
const requestsToApprove = ref([]);
const requestsToReject = ref([]);

const filteredTeamRequests = computed(() => {
  return goFlexStore.teamRequests.filter((request) => {
    return requestFilter.value === ""
      ? request.status !== "CANCELLED"
      : requestFilter.value === "APPROVED"
      ? request.status !== "PENDING" && request.status !== "CANCELLED"
      : request.status === "PENDING";
  });
});

const changeFilter = (filter) => {
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Solicitudes de tu equipo",
    event_label: filter.name ? filter.name : "Todas",
    component_name: "Click",
  });
  filter === ""
    ? (requestFilter.value = "")
    : (requestFilter.value = filter.key);
};

const sendAnalyticsData = (label) => {
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Solicitudes de tu equipo",
    event_label: label,
    component_name: "Click",
    element_category: "Team Requests",
    element_option:
      requestFilter.value === "APPROVED"
        ? "Tramitadas"
        : requestFilter.value === "PENDING"
        ? "Pendientes por aprobación"
        : "Todas",
  });
};

const showApprovalModal = () => {
  isApprovalModal.value = true;
};

const hideApprovalModal = () => {
  isApprovalModal.value = false;
};

const approveRequests = (requests) => {
  requestsToApprove.value = requests;
  sendAnalyticsData("Aprobar");
  approve.value = true;
  showApprovalModal();
};

const rejectRequests = (requests) => {
  requestsToReject.value = requests;
  sendAnalyticsData("Rechazar");
  approve.value = false;
  showApprovalModal();
};

const approveRequestFinal = () => {
  goFlexStore
    .actPostApproveGoFlexEmployeeRequest(requestsToApprove.value)
    .then(() => {
      goFlexStore.actGetGoFlexTeamRequests();
    })
    .then(() => {
      requestsToApprove.value = [];
      requestsToReject.value = [];
      hideApprovalModal();
    });
};

const rejectRequestFinal = () => {
  goFlexStore
    .actPostRejectGoFlexEmployeeRequest(requestsToReject.value)
    .then(() => {
      goFlexStore.actGetGoFlexTeamRequests();
    })
    .then(() => {
      requestsToApprove.value = [];
      requestsToReject.value = [];
      hideApprovalModal();
    });
};

const cancelRequest = () => {
  sendAnalyticsData("Cancelar");
  hideApprovalModal();
};

onBeforeMount(async () => {
  isLoading.value = true;
  await goFlexStore.actGetGoFlexTeamRequests();
  if (goFlexStore.teamRequests.length === 0) {
    await router.push({ name: "goFlex" });
  }
  isLoading.value = false;
});
</script>
<template>
  <div>
    <general-modal
      :hide-button-support="true"
      v-if="isApprovalModal"
      modalName="approval-modal"
      @close="hideApprovalModal"
      :isShowModal="isApprovalModal"
      :modalWidth="{ md: 500, lg: 700, xl: 800 }"
      :maxHeight="{ base: 500, md: 500, lg: 600, xl: 700 }"
    >
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-6"
      >
        <div
          class="lg:tw-col-span-2 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6 tw-justify-center tw-items-center tw-w-full"
        >
          <span
            class="tw-font-robotoMedium tw-text-xs lg:tw-text-xl tw-text-center lg:tw-w-9/12"
            >¿Estas seguro de que quieres
            <span :class="approve ? 'tw-text-green-450' : 'tw-text-red-350'">{{
              approve ? "aprobar" : "rechazar"
            }}</span>
            estas solicitudes?
          </span>
          <div
            class="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-w-full"
          >
            <div class="tw-btn-secondary-white" @click="cancelRequest">
              <p class="tw-m-0">Cancelar</p>
            </div>
            <div
              class="tw-btn-general-shape tw-text-white tw-border"
              @click="approve ? approveRequestFinal() : rejectRequestFinal()"
              :class="
                approve
                  ? 'tw-bg-green-450 tw-border-green-450'
                  : 'tw-bg-red-350 tw-border-red-350'
              "
            >
              <p class="tw-m-0">{{ approve ? "Aprobar" : "Rechazar" }}</p>
            </div>
          </div>
        </div>
      </div>
    </general-modal>
    <mobile-bar title="Beneficios de Tiempo" />
    <title-bar title="Beneficios de Tiempo" />
    <div class="tw-container-general" v-if="!isLoading">
      <div>
        <comp-go-flex-top-buttons :catalog="catalog" />
        <select-categories-buttons
          :categories="categories"
          @changeFilter="changeFilter"
        />
      </div>
      <div class="tw-my-3">
        <comp-go-flex-requests-table-desk
          :team-requests="filteredTeamRequests"
          v-if="!$isMobile()"
          @approve="approveRequests"
          @reject="rejectRequests"
        />
        <comp-go-flex-requests-table-mobile
          :team-requests="filteredTeamRequests"
          v-else
          @approve="approveRequests"
          @reject="rejectRequests"
        />
      </div>
    </div>
  </div>
</template>
