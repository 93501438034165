<template>
  <header class="tw-w-full tw-text-gray-600 tw-bg-white tw-shadow-md md:tw-shadow-none tw-pt-2">
    <div class=" tw-flex tw-px-0 tw-justify-center md:tw-justify-start tw-py-1">
      <div class=" md:tw-hidden tw-ml-3">
        <span @click="goBack" class="icon-flecha-izq tw-text-3xl tw-text-red-400"></span>
      </div>
      <div class="tw-mx-auto md:tw-mx-0 tw-text-center md:tw-text-left">
        <h2 class="tw-m-0 tw-text-3xl md:tw-text-4xl tw-font-tolyerBold">{{ title }}</h2>
        <BreadCrumb :crumbs="breadcrumb" />
        <p class="tw-hidden md:tw-block tw-font-roboto  tw-text-xs  md:tw-text-sm tw-mb-0">
          {{ legend }}
        </p>
      </div>
    </div>
  </header>
</template>

<script>
import BreadCrumb from "@/components/general/BreadCrumb";

export default {
  name: "SecondaryNavbar",
  components: {
    BreadCrumb,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    legend: {
      type: String,
    },
    breadcrumb: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>
