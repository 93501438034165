<template>
  <div>
    <mobile-bar title="Diversidad, Equidad e Inclusión" />
    <title-bar title="Diversidad, Equidad e Inclusión" />
    <div class="tw-container-general tw-mb-0 tw-pb-0">
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-mb-5 lg:tw-my-5 lg:tw-mt-10 lg:tw-gap-6"
      >
        <div
          class="tw-order-2 lg:tw-order-1 tw-flex tw-flex-col tw-justify-center lg:tw-col-span-4 tw-gap-4 tw-mt-5 lg:tw-m-auto"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-hidden lg:tw-block"
          >
            ¿Cómo podemos ayudarnos?
          </p>
          <p class="tw-m-0 tw-text-sm lg:tw-text-base tw-hidden lg:tw-block">
            Fomentamos un entorno inclusivo y equitativo para todos. Encuentra
            recursos, solicita ayuda personalizada y conoce nuestras lineas de
            atención para la diversidad, equidad e inclusión. Estamos aquí para
            escucharte y apoyarte. ¡Contáctanos!
          </p>
        </div>
        <div class="tw-order-1 lg:tw-order-2 lg:tw-col-span-8 hijo">
          <div
            class="tw-rounded-br-3xl tw-rounded-bl-3xl tw-rounded-tr-none tw-rounded-tl-none lg:tw-rounded-3xl lg:tw-aspect-h-6 lg:tw-aspect-w-14 tw-overflow-hidden"
            :class="isVideo && !$isMobile() ? '' : 'tw-bg-green-550'"
          >
            <div
              class="tw-w-full tw-h-full tw-p-8 tw-flex tw-flex-col tw-gap-2"
              :class="isVideo ? 'lg:tw-p-0' : 'lg:tw-p-10'"
            >
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xl tw-text-white lg:tw-hidden tw-text-center"
              >
                ¿Cómo podemos ayudarnos?
              </p>
              <div
                class="tw-rounded-3xl tw-w-full tw-h-full tw-overflow-hidden"
                :class="
                  isVideo
                    ? 'lg:tw-rounded-none'
                    : 'lg:tw-rounded-bl-3xl lg:tw-rounded-tl-3xl lg:tw-rounded-br-none lg:tw-rounded-tr-none'
                "
              >
                <div class="tw-w-auto tw-h-full tw-flex" v-if="isVideo">
                  <video
                    ref="videoRef"
                    class="tw-w-full tw-object-cover"
                    controls
                    autoplay
                  >
                    <source :src="videoPrueba" type="video/mp4" />
                    Su navegador no soporta videos.
                  </video>
                </div>
                <div class="tw-w-full tw-h-full" v-else>
                  <img
                    src="@/assets/img/diversity/helplines.svg"
                    class="tw-object-contain tw-w-full tw-h-full"
                    alt=""
                  />
                </div>
              </div>
              <p
                class="tw-m-0 tw-text-sm lg:tw-text-base tw-text-white tw-block lg:tw-hidden"
              >
                Fomentamos un entorno inclusivo y equitativo para todos.
                Encuentra recursos, solicita ayuda personalizada y conoce
                nuestras lineas de atención para la diversidad, equidad e
                inclusión. Estamos aquí para escucharte y apoyarte.
                ¡Contáctanos!
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-6 lg:tw-gap-10 tw-my-5"
      >
        <div class="tw-flex tw-flex-col tw-gap-4">
          <div
            class="tw-w-full tw-bg-pink-100 tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-6 hijo"
          >
            <img
              src="@/assets/img/diversity/icons/midcircle.svg"
              class="tw-h-2/4 tw-transform tw-rotate-180"
              alt=""
            />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"
            >
              ¿Cómo actuar?
            </p>
            <img
              src="@/assets/img/diversity/icons/midcircle.svg"
              class="tw-h-2/4"
              alt=""
            />
          </div>
          <div>
            <comp-accordion-parent>
              <comp-accordion-item
                v-for="(item, index) in questions"
                :id="'item_' + index"
                :key="index"
                :index-of-item="index"
                :array-length="questions.length"
              >
                <template v-slot:mainInfo>
                  <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg">
                    {{ item.question_content.title }}
                  </p>
                </template>
                <template v-slot:secondaryInfo>
                  <div class="tw-flex tw-flex-col tw-gap-4">
                    <p
                      class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg"
                    >
                      {{ item.question_content.title }}
                    </p>
                    <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
                      {{ item.question_content.description }}
                    </p>
                    <div
                      v-if="
                        item.question_content.steps &&
                        item.question_content.steps.length > 0
                      "
                    >
                      <p
                        class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg"
                      >
                        Pasos
                      </p>
                      <ol class="tw-m-0">
                        <li
                          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"
                          v-for="(step, index) in item.steps"
                          :key="index"
                        >
                          {{ step }}
                        </li>
                      </ol>
                    </div>
                    <div class="tw-flex tw-items-center tw-cursor-pointer">
                      <p
                        class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
                      >
                        Saber más
                      </p>
                      <i
                        class="icon-flecha-izq tw-text-red-350 tw-ml-1 tw-text-xxs lg:tw-text-xs"
                      ></i>
                    </div>
                    <div
                      class="tw-flex tw-justify-between tw-items-center tw-gap-3"
                      v-if="item.question_content.buttons.length > 0"
                    >
                      <div
                        class="tw-btn-secondary-white tw-p-3"
                        @click="pushLink(item.question_content.buttons[0].url)"
                      >
                        <p class="tw-m-0">
                          {{ item.question_content.buttons[0].label }}
                        </p>
                      </div>
                      <div
                        class="tw-btn-primary-red tw-p-3"
                        @click="pushLink(item.question_content.buttons[1].url)"
                      >
                        <p class="tw-m-0">
                          {{ item.question_content.buttons[1].label }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </comp-accordion-item>
            </comp-accordion-parent>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <div
            class="tw-w-full tw-bg-red-350 tw-justify-between tw-rounded-2xl tw-py-4 tw-px-6 tw-hidden lg:tw-flex"
          >
            <p class="tw-m-0 tw-font-robotoBold tw-text-2xl tw-text-white">
              Líneas de atención
            </p>
            <img src="@/assets/img/icons/icon-5.png" class="tw-h-full" alt="" />
          </div>
          <div
            class="tw-w-full tw-bg-pink-100 tw-items-center tw-justify-between tw-py-4 tw-px-6 hijo tw-flex lg:tw-hidden"
          >
            <img
              src="@/assets/img/diversity/icons/midcircle.svg"
              class="tw-h-2/4 tw-transform tw-rotate-180"
              alt=""
            />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"
            >
              Líneas de atención
            </p>
            <img
              src="@/assets/img/diversity/icons/midcircle.svg"
              class="tw-h-2/4"
              alt=""
            />
          </div>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-py-5 tw-rounded-3xl lg:tw-border lg:tw-border-gray-300"
            v-for="(item, idx) in contacts"
            :key="idx"
          >
            <help-lines-cards
              :title="item.contact_content.title"
              :description="item.contact_content.description"
              :icon="`diversity/icons/${item.contact_content.icon}.svg`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import HelpLinesCards from "@/components/diversity/CompDiversityHelpLinesCards.vue";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import linksMixin from "@/mixins/general/links";

export default {
  name: "HelpLinesDI",
  components: {
    CompAccordionParent,
    CompAccordionItem,
    HelpLinesCards,
    TitleBar,
    MobileBar,
  },
  mixins: [linksMixin],
  data() {
    return {
      isVideo: false,
      isLoading: false,
      videoPrueba: "https://www.w3schools.com/html/mov_bbb.mp4",
    };
  },
  created() {
    this.isLoading = true;
    this.getContacts();
    this.getQuestions();
  },
  computed: {
    ...mapState("modGeneral", ["contacts", "questions"]),
  },
  methods: {
    ...mapActions("modGeneral", ["actGetContacts", "actGetQuestions"]),
    getContacts() {
      this.actGetContacts({
        content_category_key: "diversidad_e_inclusion",
      }).then(() => {
        this.isLoading = false;
      });
    },
    getQuestions() {
      this.actGetQuestions({
        content_category_key: "diversidad_e_inclusion",
      }).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .hijo {
    position: relative;
    width: 100vw;
    left: calc(50% - 50vw);
  }
}
</style>
