import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  actions: {
    actPostDocuments: async (_, data) => {
      let response = await ApiBeerOffice.postDocuments(data);
      if (response.error) {
        return false;
      } else {
        return response;
      }
    },
    actPostDocumentsTest: async (_, data) => {
      let response = await new Promise(resolve =>
        setTimeout(() => {
          resolve({ data: data, error: false });
        }, 2000)
      );
      if (response.error) {
        return response.error;
      } else {
        return response;
      }
    },
    actPostRequestWorkCertificate: async (_, data) => {

      let response = await ApiBeerOffice.postRequestWorkCertificate(data);
      if (response.error) {
        return false;
      } else {
        return response;
      }
    }
  }
};
