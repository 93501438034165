var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_setup.cancel)?_c('div',[(_setup.props.showNumber)?_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-gray-300 tw-m-0 tw-underline"},[_vm._v(" "+_vm._s("No. " + _setup.props.booking.id)+" ")]):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-5 tw-mt-4"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-3"},[_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0"},[_vm._v(" "+_vm._s(`Reserva del día ${_setup.convertDate( _setup.props.booking.start_date )} al ${_setup.convertDate(_setup.props.booking.end_date)}${ _setup.props.booking.status === "PENDING_PAYMENT" ? ". Estamos pendientes de tu pago" : "" }.`)+" ")])]),(_setup.props.booking.status === 'CONFIRMED')?_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-3"},[_c(_setup.CompButtonCustom,{attrs:{"text":"Desc. credencial"},nativeOn:{"click":function($event){return _setup.downloadFile(_setup.props.booking.confirmation_voucher)}}}),_c(_setup.CompButtonCustom,{attrs:{"text":"Desc. reglamento"},nativeOn:{"click":function($event){return _setup.downloadFile('REGLAMENTO_SEDES_VACACIONALES.pdf', '/documents')}}})],1):_vm._e(),(_setup.reservationButton(_setup.props.booking.status))?_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-4"},[(
            _setup.props.booking.status !== 'PENDING' ||
            _setup.props.booking.status !== 'PENDING_WON_RAFFLE'
          )?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer tw-w-full tw-btn-secondary-white",on:{"click":function($event){return _setup.buttonCallToAction(_setup.props.booking.status)}}},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm"},[_vm._v(" "+_vm._s(_setup.reservationStatusText(_setup.props.booking.status))+" ")])]):_vm._e(),(
            _setup.props.booking.status === 'PENDING_PAYMENT' ||
            _setup.props.booking.status === 'WON_RAFFLE' ||
            _setup.props.booking.status === 'PENDING' ||
            _setup.props.booking.status === 'PENDING_WON_RAFFLE'
          )?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer tw-w-full tw-btn-primary-black",on:{"click":function($event){return _setup.cancelReservation()}}},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm"},[_vm._v(" Cancelar reserva ")])]):_vm._e()]):_c('div',[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm",class:_setup.reservationColorText(_setup.props.booking.status)},[_vm._v(" "+_vm._s(_setup.reservationStatusText(_setup.props.booking.status))+" ")])])])]):_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-5"},[_vm._m(0),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-gap-3"},[_c('div',{staticClass:"tw-btn-secondary-white",on:{"click":function($event){_setup.cancel = false}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v("Volver")])]),_c('div',{staticClass:"tw-btn-primary-red",on:{"click":function($event){return _setup.cancelReservation()}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v("Cancelar reserva")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-3"},[_c('div',{staticClass:"tw-col-span-1"},[_c('div',{staticClass:"tw-flex tw-justify-center tw-w-full tw-h-full"},[_c('img',{staticClass:"tw-object-contain tw-w-full tw-h-full",attrs:{"src":require("@/assets/img/ecommerce/compra-rechazada.svg"),"alt":""}})])]),_c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-center"},[_vm._v(" ¿Estás seguro de cancelar la reserva?, si quieres reservar de nuevo puedes correr el riesgo de perder los días y estarán sujetos a disponibilidad. ")])])])
}]

export { render, staticRenderFns }