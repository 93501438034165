<script setup>
import { ref, computed, onBeforeMount, watch } from "vue";
import { capitalizeParagraphFirstLetter } from "@/helpers/textHelpers";

const openSelect = ref(false);
const optionSelectedId = ref(null);

const emit = defineEmits(["change", "chargeOption", "changeSelectedOption"]);

const props = defineProps({
  isLabel: {
    type: Boolean,
    default: false,
    required: true,
  },
  defaultTextVisible: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "Selecciona una opción: ",
  },
  fitted: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: String,
    default: "icon-cebada-full",
  },
  iconVisible: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  allOptionAvailable: {
    type: Boolean,
    default: false,
  },
  allOptionText: {
    type: String,
    default: "Todo",
  },
  embeddedMobile: {
    type: Boolean,
    default: false,
  },
  embeddedDesktop: {
    type: Boolean,
    default: false,
  },
  extendsContainer: {
    type: Boolean,
    default: false,
  },
  defaultOption: {
    type: Number,
    default: null,
  },
  defaultText: {
    type: String,
    default: "Selecciona una opción",
  },
  borderColor: {
    type: String,
    default: "gray-250",
  },
  singleArrayMode: {
    type: Boolean,
    default: false,
  },
});

const allOption = {
  id: 0,
  name: props.allOptionText,
  key: "all",
};

const allOptions = computed(() => {
  return props.allOptionAvailable
    ? [allOption, ...props.options]
    : props.options;
});

watch(optionSelectedId, (newVal) => {
  emit("change", newVal);
});

/*const optionSelected = computed(() => {
  return props.options.find(
    (option) => option.id === Number(optionSelectedId.value)
  );
});*/

const optionSelected = computed(() => {
  return allOptions.value.filter(
    (option) => option.id === Number(optionSelectedId.value)
  )[0];
});

function toggleSelectFilter(e) {
  if (e.target.closest(".main") && openSelect.value) {
    openSelect.value = false;
  } else {
    openSelect.value = !openSelect.value;
  }
}

function changeSelectFilter(opt) {
  if (props.singleArrayMode) {
    optionSelectedId.value = opt;
    emit("changeSelectedOption", opt, true);
  } else if (!props.singleArrayMode && opt.id !== optionSelectedId.value) {
    optionSelectedId.value = opt.id;
    emit("changeSelectedOption", optionSelected.value, true);
    openSelect.value = false;
  }
}

onBeforeMount(() => {
  if (props.singleArrayMode) {
    optionSelectedId.value = allOptions.value[0];
    emit("chargeOption", optionSelectedId.value, false);
  } else {
    optionSelectedId.value = props.defaultOption
      ? props.defaultOption
      : allOptions.value[0].id;
    emit("chargeOption", optionSelected.value, false);
  }
});
</script>

<template>
  <div
    class="tw-flex tw-gap-x-2 tw-items-start tw-justify-end tw-z-2"
    :class="props.extendsContainer ? '' : 'tw-max-h-8 lg:tw-max-h-14'"
  >
    <!--  <div
    class="tw-flex tw-gap-x-2 tw-items-start tw-justify-end tw-z-2"
    :class="
      !extendsContainer && !openSelect
        ? 'tw-max-h-10 lg:tw-max-h-16'
        : !extendsContainer && openSelect
        ? 'tw-max-h-full'
        : ''
    "
  >-->
    <p
      v-if="props.isLabel"
      class="tw-mt-2 tw-font-roboto tw-text-xs lg:tw-text-lg"
    >
      {{ props.label }}
    </p>
    <div
      class="tw-h-full tw-border-solid tw-border tw-bg-white tw-overflow-hidden tw-z-2 tw-p-1 lg:tw-p-2"
      :class="[
        props.embeddedMobile === true
          ? 'tw-border-transparent'
          : `tw-border-${props.borderColor}`,
        props.embeddedDesktop
          ? 'lg:tw-border-transparent'
          : `lg:tw-border-${props.borderColor}`,
        openSelect ? 'tw-rounded-2xl' : 'tw-rounded-2xl',
        fitted ? 'tw-w-max' : 'tw-w-full',
      ]"
    >
      <div
        class="main tw-grid tw-items-center tw-gap-x-3 tw-cursor-pointer tw-px-3 tw-py-2 lg:tw-px-3 lg:tw-py-2"
        :class="
          iconVisible
            ? 'tw-grid-cols-col3_fit_first_and_last'
            : 'tw-grid-cols-col2_fit_last'
        "
        @click="toggleSelectFilter"
      >
        <div
          class="tw-flex"
          v-if="iconVisible || (optionSelected && optionSelected.icon)"
        >
          <i
            class="tw-text-red-350"
            :class="optionSelected && `icon-${optionSelected.icon}`"
            v-if="
              iconVisible &&
              !openSelect &&
              optionSelected &&
              optionSelected.icon
            "
          ></i>
          <i class="tw-text-red-350 lg:tw-text-xl" :class="icon" v-else-if="iconVisible"></i>
        </div>
        <div class="tw-flex">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base tw-text-red-350 tw-lowercase"
            v-if="openSelect && defaultTextVisible"
          >
            {{ capitalizeParagraphFirstLetter(defaultText.toLowerCase()) }}
          </p>
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base"
            v-else
          >
            {{
              props.singleArrayMode
                ? optionSelectedId
                : capitalizeParagraphFirstLetter(
                    optionSelected?.name.toLowerCase()
                  )
            }}
          </p>
        </div>
        <i
          class="tw-text-xs tw-text-red-350"
          :class="openSelect ? 'icon-flecha-arriba' : 'icon-flecha-abajo'"
        ></i>
      </div>
      <div
        class="tw-bg-white tw-w-full tw-max-h-32 lg:tw-max-h-40 tw-overflow-scroll"
        :class="openSelect ? 'tw-h-auto tw-visible' : 'tw-h-0 tw-invisible'"
      >
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 lg:tw-px-3 lg:tw-py-2 tw-grid tw-grid-cols-col2_fit_first tw-items-center tw-gap-x-3"
          v-for="(opt, idx) in allOptions"
          :key="idx"
          @click="changeSelectFilter(opt)"
        >
          <i
            :class="[
              opt.icon ? `icon-${opt.icon}` : icon,
              opt.icon ? '' : 'tw-text-red-350',
            ]"
            v-if="props.iconVisible"
          ></i>
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            {{
              props.singleArrayMode
                ? opt
                : capitalizeParagraphFirstLetter(opt?.name.toLowerCase())
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
