import config from "@/services/config";
import router from "@/router";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

export function pushLink(link, datalayer) {
  const protocolRegex = /^(http?|https?|tel):\/\//i;

  if (link) {
    if (protocolRegex.test(link)) {
      window.open(link);
    } else if (link.startsWith("storage")) {
      window.open(`${config.beerOfficeURL}/${link}`);
    } else {
      router.push(link);
    }
  }

  if (datalayer) {
    pushDataLayer(datalayer);
  }
}

export function downloadFile(documentName, path) {
  const fileUrl = path
    ? `${config.beerOfficeURL}${path}/${documentName}`
    : `${config.beerOfficeURL}/${documentName}`;
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", documentName);
  link.target = "_blank";
  link.rel = "noopener noreferrer";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
}
