<template>
  <div id="parent" v-if="ranking.length > 0" class="fixed-bottom z-index-4">
    <div
      class="tw-shadow-lg bg-white tw-rounded-lg he-750 he-md-750"
      :class="
        classification === 1
          ? 'ranking-back1'
          : classification === 2
          ? 'ranking-back2'
          : classification === 3
          ? 'ranking-back3'
          : 'ranking-back4'
      "
    >
      <div class="container-fluid">
        <div class="mx-2 mt-4 text-center">
          <p
            class="tw-font-robotoBold fs-24 text-white text-capitalize line-height-30"
          >
            {{
              Array.from(new Set((title + this.actualIndicator).split(" ")))
                .join(" ")
                .toLowerCase()
            }}
          </p>
        </div>
        <div class="row mx-2 mt-3 tw-flex tw-items-end">
          <div class="col mx-auto">
            <div
              v-if="ranking[0].site.prof_picture == null"
              class="img-container"
            >
              <img
                src="@/assets/img/Engagement.png"
                alt=""
                class="shadow-box-1 bg-white tw-rounded-lg"
              />
            </div>
            <div v-else class="img-container">
              <img
                :src="uriResources + '/' + ranking[0].site.prof_picture"
                alt=""
                class="shadow-box-1 bg-white tw-rounded-lg"
              />
            </div>
            <div class="tw-grid tw-grid-cols-col2_fit_first tw-mt-2">
              <div class="tw-flex tw-items-center tw-gap-2">
                <div class="image-container text-center">
                  <p
                    class="tw-m-0 tw-mix-blend-screen tw-text-black tw-text-base tw-font-robotoBold rounded-circle tw-bg-white tw-h-full tw-leading-7"
                  >
                    1
                  </p>
                </div>
                <p class="tw-m-0 tw-text-white tw-font-robotoMedium tw-text-xl">
                  Puesto
                </p>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <p
                  class="tw-m-0 tw-font-robotoMedium tw-text-white tw-text-xl tw-leading-6 tw-capitalize tw-text-right"
                >
                  {{ ranking[0].site.name.toLowerCase() }}
                </p>
                <p
                  class="tw-m-0 tw-font-roboto tw-text-white tw-text-xs tw-capitalize tw-leading-3 tw-text-right"
                >
                  Equipo
                  {{
                    ranking[0].name
                      .toLowerCase()
                      .split(" ")
                      .slice(0, 1)
                      .join(" ")
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src="../../assets/img/icons/cornerRight.png" alt="" class="tag-2" />
      </div>
    </div>

    <div
      class="he-460 position-absolute z-index-5 bg-white container-fluid tw-rounded-tr-3xl tw-rounded-tl-3xl tw-rounded-br-lg tw-rounded-bl-lg fixed-bottom"
    >
      <div class="position-relative h-100">
        <div class="flex-wrapper h-100 pt-4">
          <div class="overflow-auto px-2" style="height: 80%">
            <div
              v-for="(persona, index) in ranking"
              :key="'persona' + index"
              class="persona-container"
            >
              <div v-if="index >= 1">
                <div
                  class="d-flex align-items-center justify-content-between px-2 my-3 person-box"
                  :class="
                    persona.id_sharp === user_idSharp
                      ? 'person-back2'
                      : 'person-back1'
                  "
                  :id="
                    persona.id_sharp === user_idSharp ? 'active' : 'inactive'
                  "
                >
                  <div class="col-1 text-center">
                    <p
                      class="tw-font-roboto tw-text-base my-auto tw-text-gray-400"
                    >
                      {{ index + 1 }}
                    </p>
                  </div>
                  <div class="col-2 pl-2">
                    <div v-if="persona.prof_picture == null">
                      <img
                        src="@/assets/img/Engagement.png"
                        alt=""
                        class="avatar-3 rounded-circle shadow-box"
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="uriResources + '/' + persona.prof_picture"
                        alt=""
                        class="avatar-3 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />
                    </div>
                  </div>
                  <div class="col overflow-hidden p-3">
                    <div>
                      <p
                        class="tw-font-robotoBold tw-text-sm text-capitalize text-truncate my-1"
                      >
                        {{ persona.site.name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-roboto tw-text-sm text-capitalize text-truncate my-1"
                        :class="
                          persona.id_sharp === user_idSharp
                            ? 'tw-text-gray-400'
                            : 'tw-text-gray-300'
                        "
                      >
                        {{ persona.name.toLowerCase() }}
                      </p>
                    </div>
                  </div>
                  <div class="col-2">
                    <div
                      class="shadow-box-2 bg-white tw-rounded-md text-center"
                      style="width: 38px; height: 38px"
                    >
                      <p
                        class="tw-font-robotoBold tw-text-base"
                        style="line-height: 38px"
                      >
                        {{
                          Math.round(
                            parseFloat(
                              persona.average.substring(0, 5).replace(",", ".")
                            )
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tag-3 image-container-2 text-center"
            @click="scroll('active')"
          >
            <i
              class="icon-flecha-abajo tw-text-white tw-bg-orange-450 rounded-circle tw-text-sm tw-p-2"
            ></i>
          </div>
          <div class="position-absolute w-100 tw-bottom-0 bg-white mb-4">
            <button
              @click="
                $router.push({
                  name: 'CompareLeaders',
                })
              "
              type="button"
              class="btn btn-primary btn-block"
            >
              Comparar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";

export default {
  name: "LeaderRanking",
  props: {
    classification: {
      type: Number,
      required: true,
    },
    ranking: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    actualIndicator: {
      type: String,
      required: true,
    },
    user_idSharp: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      title: "Ranking ",
    };
  },
  methods: {
    scroll(id) {
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    },
  },
};
</script>

<style scoped>
.ranking-back1 {
  background: linear-gradient(180deg, #f1ce38 0%, #f05e22 37.01%);
}

.ranking-back2 {
  background: linear-gradient(180deg, #4cc3c9 0%, #5ea1f7 63.54%);
}

.ranking-back3 {
  background: linear-gradient(180deg, #ecb2d0 0%, #c61a75 100%);
}

.ranking-back4 {
  background: linear-gradient(180deg, #e93c3e 0%, #641119 100%);
}

.avatar {
  width: 100px;
  height: 100px;
}

.avatar-2 {
  width: 60px;
  height: 60px;
}

.avatar-3 {
  width: 45px;
  height: 45px;
}

.tag-2 {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.tag-3 {
  left: 45%;
  bottom: 70px;
  position: absolute;
  z-index: 50;
}

.img-container {
  height: 100%;
  max-height: 140px;
}

.img-container img {
  display: block;
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.image-container {
  width: 31px;
  height: 31px; /* Some height */
}

.image-container-2 {
  width: 28px;
  height: 28px; /* Some height */
}

.text {
  color: black;
  mix-blend-mode: screen; /* This makes the cutout text possible */
}
</style>
