<script setup>
import { computed } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";

const ecommerceStore = useEcommerceStore();
const uriResources = config.beerOfficeURL;

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  history: {
    type: Boolean,
    default: false,
  },
});

const getProductQty = computed(() => {
  let qty = 0;
  if (ecommerceStore.shoppingCartArray.length > 0) {
    ecommerceStore.shoppingCartArray.forEach((item) => {
      if (item.id === props.product.id) {
        qty = item.qty;
      }
    });
  }
  return qty;
});

const incrementNumber = () => {
  ecommerceStore.addProductToShoppingCart(props.product);
};

const decrementNumber = () => {
  if (props.product.qty >= 1) {
    ecommerceStore.subsProductToShoppingCart(props.product);
  }
  if (props.product.qty === 0) {
    ecommerceStore.delProductToShoppingCart(props.product);
  }
};
</script>
<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-h-full">
      <div
        class="tw-flex tw-relative"
        :class="history ? 'tw-col-span-3' : 'tw-col-span-4'"
      >
        <div
          class="tw-flex-auto tw-cursor-pointer tw-h-full tw-aspect-w-1 tw-aspect-h-1"
        >
          <img
            :src="uriResources + '/' + props.product.img"
            alt="image"
            class="tw-rounded-2xl tw-object-cover tw-w-full tw-h-full"
            loading="lazy"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-justify-between"
        :class="history ? 'tw-col-span-9' : 'tw-col-span-8'"
      >
        <div>
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-base tw-capitalize lg:tw-text-lg tw-line-clamp-1"
          >
            {{ props.product.name.toLowerCase() }}
          </p>
        </div>
        <p
          class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-xs tw-text-gray-300"
        >
          {{
            props.product.description.charAt(0).toUpperCase() +
            props.product.description.slice(1).toLowerCase()
          }}
        </p>
        <div class="tw-flex tw-justify-between">
          <p class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-base">
            {{
              Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                maximumFractionDigits: 0,
              }).format(props.product.price_public)
            }}
          </p>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-rounded-3xl tw-font-roboto tw-shadow-none tw-border tw-border-gray-320 tw-cursor-pointer tw-px-3"
            v-if="history"
          >
            <p class="tw-m-0 tw-font-roboto tw-text-sm tw-text-gray-320">
              {{ `Cantidad: ${props.product.qty}` }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-end" v-if="!history">
      <div class="tw-w-1/3">
        <div
          class="tw-rounded-3xl tw-font-roboto tw-w-full tw-shadow-none tw-border tw-border-red-350 tw-cursor-pointer tw-overflow-hidden"
        >
          <div
            class="tw-grid tw-grid-cols-col3_fit_first_and_last tw-w-full tw-h-full tw-text-black"
          >
            <div
              class="tw-flex tw-justify-center tw-items-center tw-bg-red-350 tw-cursor-pointer tw-px-1"
              @click="decrementNumber()"
            >
              <i
                class="icon-remover-signo tw-text-white tw-text-lg md:tw-text-xl"
              ></i>
            </div>
            <div class="tw-flex tw-justify-center tw-items-center">
              <p class="tw-font-roboto tw-font-semibold tw-m-0 tw-text-xs">
                {{ getProductQty }}
              </p>
            </div>
            <div
              class="tw-flex tw-justify-center tw-items-center tw-bg-red-350 tw-cursor-pointer tw-px-1"
              @click="incrementNumber()"
            >
              <i
                class="icon-signo-mas tw-text-lg md:tw-text-xl tw-text-white"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
