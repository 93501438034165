import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    categoriesDiversityInclusion: [],
    categoryArticles: {},
    setArticle: {},
  },
  mutations: {
    setCategoriesDiversityInclusion: (state, categoriesDiversityInclusion) => {
      state.categoriesDiversityInclusion = categoriesDiversityInclusion;
    },
    setCategoryArticles: (state, categoryArticles) => {
      state.categoryArticles = categoryArticles;
    },
    setArticle: (state, setArticle) => {
      state.setArticle = setArticle;
    },
  },
  actions: {
    actGetCategoriesDiversityInclusion: async ({ commit }) => {
      let response = await ApiBeerOffice.getCategoriesSocialInclusion();
      if (response.error) {
        return response.error;
      }
      commit(
        "setCategoriesDiversityInclusion",
        response.data.section.categories
      );

      return true;
    },
    actGetCategoryArticles: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getCategoryArticles(data);
      if (response.error) {
        return response.error;
      }
      commit("setCategoryArticles", response.data.category);
      return true;
    },
    actGetArticle: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getArticle(data);
      if (response.error) {
        return response.error;
      }
      commit("setArticle", response.data.item);
      return true;
    },
  },
};
