<template>
  <div class="showMe">
    <div class="layout-content">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-8 p-0">
            <SecondaryNavbar title="Aprende más" :breadcrumb="breadcrumb" />
          </div>
          <div class="col-12 col-md-4 py-0 d-none d-md-block">
            <CompNavigationOptions selected="" />
          </div>
        </div>
        <div class="row pt-0 pt-md-3 pb-5">
          <div
            v-for="(item, index) in variableDetails"
            :key="index"
            @click="showItem(item.resource)"
            class="col-md-6 col-lg-4"
          >
            <div class="card px-2 px-md-0 py-1 py-sm-2 flex-row flex-md-column">
              <div class="ml-3 ml-md-0 img-cont">
                <img
                  class="item-img"
                  :src="uriResources + '/storage/images/' + item.image"
                  alt
                />
              </div>
              <div class="card-body w-100 px-2 px-md-0 py-2 py-md-3">
                <div class="module line-clamp">
                  <p v-html="item.description"></p>
                </div>
                <div class="final-path float-md-right float-sm-left">
                  Ver más
                </div>
              </div>
              <div class="d-block d-md-none">
                <span class="icon-flecha-der icon-sm icon-primary"></span>
              </div>
            </div>
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="my-modal" ref="my-modal" centered hide-footer hide-header>
      <b-button class="mb-2 close" @click="$bvModal.hide('my-modal')"
        >×</b-button
      >
      <div class="d-block text-center">
        <p class="modal-title-desc text-center"></p>
        <div v-if="resourceVideo !== null" class="mt-2 w-100">
          <video
            class="w-100"
            :src="resourceVideo"
            poster="@/assets/videos/placeholder.png"
            controls
          >
            <code></code>
          </video>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompNavigationOptions from "@/components/show-me-the-money/variable-compensation/CompNavigationOptions";

export default {
  name: "LearnMore",
  components: {
    SecondaryNavbar,
    CompNavigationOptions,
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modVariableCompensation", ["variableDetails"]),
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      resourceVideo: null,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración variable",
          link: "/showMeTheMoney/remuneracion-variable",
        },
        {
          id: 5,
          name: "Aprende más",
          link: "/showMeTheMoney/learn-more",
          active: true,
        },
      ],
      data: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions("modVariableCompensation", ["actGetVariableMoreDetails"]),
    getData() {
      this.actGetVariableMoreDetails();
    },
    showItem(resource) {
      const fileType = resource.split(".").pop();
      const mediaFiles = ["mp4", "mp3"];
      if (mediaFiles.includes(fileType)) {
        this.resourceVideo =
          this.uriResources + "/storage/documents/" + resource;
        this.$refs["my-modal"].show();
      } else {
        window.open(
          this.uriResources + "/storage/documents/" + resource,
          "_blank"
        );
      }
    },
  },
};
</script>
<style scoped>
.divider {
  border-bottom: 1px solid #bababa;
}

.item-img {
  height: 80px;
  width: 80px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  object-fit: cover;
}

.card {
  border: 0;
  align-items: center;
}

.module {
  margin: 0 0 1em 0;
  overflow: hidden;
}
.module p {
  margin: 0;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.final-path {
  font: bold 14px/24px Roboto;
  letter-spacing: 0 ;
  color: #0f0e0e;
  opacity: 1;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .img-cont {
    width: 100%;
  }
  .item-img {
    height: 235px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 3px 6px #00000029;
  }
  .divider {
    display: none;
  }
}
</style>