<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { storeToRefs } from "pinia";
import { useProfileStore } from "@/stores/profileStore";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";
import ButtonOutlineRed from "@/components/buttons/CompButtonOutlineRed.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompFormTextInput from "@/components/form/CompFormTextInput.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const props = defineProps({
  data: Object,
});

const emit = defineEmits(["update"]);
const isPetsModalOpen = ref(false);
const petsSaved = ref(false);
const { pets } = storeToRefs(useProfileStore());
const pet = reactive({
  type: null,
  name: null,
  breed: null,
  years: null,
  color: null,
});
const petsData = ref([]);

const updatePetsData = (value, isDelete = false) => {
  const index = petsData.value.findIndex((pet) => pet.name === value.name);
  if (isDelete) {
    if (index > -1) petsData.value.splice(index, 1);
    petsSaved.value = false;
  } else {
    const newPet = { ...value };
    if (index > -1) petsData.value[index] = newPet;
    else petsData.value.push(newPet);
    petsSaved.value = true;
  }
  emit("update", { ...props.data, pets: petsData.value });

  if (petsData.value.length > 0) {
    emit("update", { ...props.data, has_pets: "yes" });
  }
};

const closePetsModal = () => {
  isPetsModalOpen.value = false;
  petsSaved.value = false;
};

const openPetsModal = () => {
  // Create a new reactive object for each pet
  Object.assign(pet, {
    type: null,
    name: null,
    breed: null,
    years: null,
    color: null,
    icon: null,
  });

  isPetsModalOpen.value = true;
};

const validateFields = computed(() => {
  return pet.type && pet.name && pet.breed && pet.years && pet.color;
});

const emptyPets = () => {
  petsData.value = [];
  emit("update", { ...props.data, has_pets: "no", pets: [] });
};

const assignPetType = (value) => {
  pet.type = value.name;
  pet.icon = value.icon;
};

onMounted(() => {
  if (props.data.pets?.length > 0) {
    petsData.value = [...props.data.pets];
    petsData.value = petsData.value.filter((pet) => pet.name);
  } else {
    emit("update", { ...props.data, has_pets: "no", pets: [] });
  }
});
</script>

<template>
  <div>
    <portal to="profileHome">
      <general-modal
        :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
        :modalWidth="{ md: 500, lg: 700, xl: 700 }"
        modal-name="PetsModal"
        v-if="isPetsModalOpen"
        :isShowModal="isPetsModalOpen"
        @close="closePetsModal"
      >
        <div
          class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4 tw-mb-2"
          v-if="!petsSaved"
        >
          <h3 class="tw-text-center tw-font-robotoBold">
            Inscribe a tu mascota
          </h3>
          <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
            ¿Cuál es la especie de tu mascota?
          </p>
          <div class="tw-flex tw-justify-between tw-gap-2">
            <comp-button-custom-color
              class="tw-w-full"
              rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
              v-for="(item, idx) in pets"
              :key="idx"
              :text="item.name"
              :active="pet.type === item.name"
              active-color="green-500"
              text-active-color="white"
              @click.native="assignPetType(item)"
            />
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-2">
            <div class="tw-col-span-2">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                ¿Cuál es su nombre?
              </p>
            </div>
            <div class="tw-col-span-1">
              <comp-form-text-input
                :modelValue="pet.name"
                @update="(value) => (pet.name = value)"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-2">
            <div class="tw-col-span-2">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                ¿Cuál es su tipo o raza?
              </p>
            </div>
            <div class="tw-col-span-1">
              <comp-form-text-input
                :modelValue="pet.breed"
                @update="(value) => (pet.breed = value)"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-2">
            <div class="tw-col-span-2">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                ¿Cuántos años tiene?
              </p>
            </div>
            <div class="tw-col-span-1">
              <comp-form-text-input
                :modelValue="pet.years"
                input-type="number"
                @update="(value) => (pet.years = value)"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-2">
            <div class="tw-col-span-2">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                ¿De qué color es tu mascota?
              </p>
            </div>
            <div class="tw-col-span-1">
              <comp-form-text-input
                :modelValue="pet.color"
                @update="(value) => (pet.color = value)"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-2">
            <comp-button-custom
              class="tw-w-full"
              rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
              text="Cerrar"
              @click.native="closePetsModal"
            />
            <button-primary-red
              :disabled="!validateFields"
              class="tw-w-full"
              rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
              title="Guardar"
              @click.native="updatePetsData(pet)"
            />
          </div>
        </div>
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3"
          v-else
        >
          <div class="tw-flex tw-w-6/12 tw-justify-between tw-items-center">
            <img src="@/assets/img/modal/modal-success.svg" alt="" />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-green-450"
            >
              LISTO
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            {{ pet.name }}
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            ha sido registrado/a correctamente
          </p>
          <button-primary-red
            rounded-size="tw-rounded-lg lg:tw-rounded-xl"
            @click.native="closePetsModal"
            title="Cerrar"
            class="tw-w-full"
          />
        </div>
      </general-modal>
    </portal>
    <section
      class="tw-rounded-xl lg:tw-rounded-2xl tw-border tw-border-gray-350 tw-p-4 tw-flex tw-flex-col tw-gap-2"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
          ¿Tienes mascotas?*
        </p>
        <p class="tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs">
          *Puedes agregar varios
        </p>
      </div>
      <p class="tw-m-0 tw-font-robotoLight tw-text-sm lg:tw-text-base">
        *Inscribe a tu perro y/o gato para activar los beneficios de familias
        felices. (Máximo 2 mascotas)
      </p>
      <div class="tw-flex tw-items-center tw-gap-4 tw-w-full lg:tw-w-1/2">
        <comp-button-custom-color
          class="tw-w-full"
          rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
          text="Si"
          :color="props.data.has_pets === 'yes' ? 'green-500' : 'white'"
          active-color="green-500"
          :text-color="props.data.has_pets === 'yes' ? 'white' : 'gray-700'"
          text-active-color="white"
          @click.native="emit('update', { ...props.data, has_pets: 'yes' })"
        />
        <comp-button-custom-color
          class="tw-w-full"
          rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
          text="No"
          :color="props.data.has_pets === 'no' ? 'green-500' : 'white'"
          active-color="green-500"
          :text-color="props.data.has_pets === 'no' ? 'white' : 'gray-700'"
          text-active-color="white"
          @click.native="emptyPets()"
        />
      </div>
      <p
        v-if="petsData?.length === 0 && props.data.has_pets === 'yes'"
        class="tw-m-0 tw-font-robotoLight tw-text-xs lg:tw-text-sm tw-text-red-350"
      >
        *Debes inscribir al menos una mascota para poder continuar
      </p>
      <div
        class="tw-grid tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-2"
        v-if="petsData.length > 0"
      >
        <div v-for="(pet_, idx) in petsData" :key="idx" class="tw-self-center">
          <comp-button-custom-color
            class="tw-w-full"
            rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
            :text="pet_.name"
            color="green-500"
            text-color="white"
            icon
            icon-name="icon-remover-signo"
            @iconClick="updatePetsData(pet_, true)"
          />
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-3 tw-gap-2">
        <div class="tw-col-span-3 lg:tw-col-span-2">
          <button-outline-red
            class="tw-w-full"
            rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
            title="Inscribe una mascota"
            @click.native="openPetsModal"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped></style>
