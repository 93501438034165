<template>
  <div class="tw-container-general">
    <div id="general-wrapper" class="container-md">
      <!-- Filter Switch and Buttons -->

      <FilterSwitch
        :categoriesListData="categoriesListData"
        :description="description"
        :path="this.$route.path"
        :show="show"
        :title="title"
      />

      <!-- Personalities -->
      <div
        v-if="show === 2"
        class="col-1 d-md-none"
        role="button"
        @click="
          filterIndicatorbyId(1, 1);
          changeShow(1);
        "
      >
        <svg
          class="mr-3"
          fill="none"
          height="19"
          width="23"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.823 7.945a1.584 1.584 0 00-.274-.02H4.919l.341-.159a3.17 3.17 0 00.896-.634l4.383-4.383a1.64 1.64 0 00.23-2.1A1.585 1.585 0 008.39.443L.465 8.369a1.585 1.585 0 00-.001 2.241v.002l7.927 7.925a1.585 1.585 0 002.377-.158 1.64 1.64 0 00-.23-2.1l-4.374-4.391a3.167 3.167 0 00-.793-.579l-.476-.214h15.567a1.64 1.64 0 001.672-1.332 1.585 1.585 0 00-1.31-1.818z"
            fill="#717171"
          />
        </svg>
      </div>
      <div v-if="show === 2" class="row pt-md pt-4">
        <div class="d-table-cell col-md-8">
          <div class="tw-shadow-lg bg-white tw-rounded-lg w-100 h-100">
            <!-- Filter Select for desktop -->
            <div class="container">
              <div class="row m-1 pt-2">
                <div class="col-md-6">
                  <div class="container-fluid">
                    <div class="row pt-md pt-2">
                      <div class="col-2 col-md w-auto m-auto">
                        <span
                          class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                          >Site</span
                        >
                      </div>
                      <div class="col-10 col-md-10">
                        <select
                          v-model="area_personalities"
                          class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                        >
                          <option :value="null" disabled>Seleccionar...</option>

                          <option
                            v-for="vp in this.personalitiesAreaList"
                            v-bind:key="vp.id"
                            :selected="vp.id === area_personalities"
                            v-bind:value="vp.id"
                          >
                            {{ vp.name.toLowerCase() }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="container-fluid">
                    <div class="row pt-md pt-3">
                      <div class="col col-md-3 ml-auto">
                        <button
                          class="tw-bg-red-400 tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 tw-rounded-md float-right w-100 p-0"
                          style="height: 40px"
                          type="button"
                          @click="filterPersonalities()"
                        >
                          Filtrar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Graph Personalities-->
            <div class="d-block">
              <div v-if="isLoading" class="mb-5">
                <div class="text-center mt-3">
                  <img alt="loading" src="../../assets/img/icons/loading.gif" />
                </div>
              </div>
              <div v-if="is_data_fetched" class="m-md-2 py-3 overflow-hidden">
                <div class="row m-auto px-md-4">
                  <div class="col-md-6 m-auto p-4">
                    <DoughnutChart2 :chartData="datacollection" />
                  </div>
                  <div class="col-md-6 p-md-2 px-4 py-2">
                    <div
                      class="tw-shadow-sm tw-rounded-lg overflow-hidden h-100"
                    >
                      <div class="text-center my-3">
                        <span class="tw-font-tolyerBold fs-26"
                          >PERSONALIDADES {{ nameVp }}</span
                        >
                      </div>
                      <table class="table table-borderless table-striped">
                        <tbody>
                          <template v-for="brand in brandData">
                            <tr :key="brand.profile.id">
                              <th scope="row"></th>
                              <td>
                                <p
                                  :style="
                                    'cursor:pointer; color:' + brand.color
                                  "
                                  class="tw-font-robotoBold tw-text-xs m-0 p-0 text-underline"
                                  @click="changeBrand(brand.profile.id)"
                                >
                                  {{ brand.profile.name.toUpperCase() }}
                                </p>
                              </td>
                              <td
                                :style="'color:' + brand.color"
                                class="tw-font-robotoBold tw-text-base line-height-16"
                                @click="changeBrand(brand.profile.id)"
                              >
                                {{ Math.round(brand.average) + "%" }}
                              </td>

                              <td
                                :style="'color:' + brand.color"
                                class="tw-font-roboto tw-text-xxs line-height-12"
                                @click="changeBrand(brand.profile.id)"
                              >
                                {{ "(" + brand.amount + " " + "PERSONAS)" }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-table-cell col-md-4 personalities-cell">
          <div
            class="tw-shadow-lg bg-white tw-rounded-lg overflow-hidden h-100"
          >
            <div class="img-container w-80 m-auto pt-4">
              <img
                :src="uriResources + '/' + brand.image"
                alt=""
                class="tw-rounded-lg"
              />
            </div>
            <div class="w-80 m-auto pt-4">
              <p
                class="tw-font-roboto tw-text-xs line-height-16 mt-4 tw-font-normal"
              >
                {{ '"' + brand.description + '"' }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Graph Buttons and Ranking -->
      <div
        v-else-if="show === 1 || show === 0"
        id="graph-buttons-ranking-wrapper"
        class="row"
      >
        <!-- Filter Select for Mobile -->
        <div class="container d-block d-md-none order-1">
          <div class="row m-1 pt-md-2 pt-4">
            <div v-if="show === 1" class="col-md">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-2 col-md-3 w-auto m-auto">
                    <span
                      class="tw-font-robotoBold tw-text-base font-weight-light pr-1"
                      >Site</span
                    >
                  </div>
                  <div class="col-10 col-md-9">
                    <select
                      v-if="show === 1"
                      v-model="area_filter"
                      class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="site in tabFilter.sites"
                        v-bind:key="site.id"
                        :selected="site.id === area_filter"
                        v-bind:value="site.id"
                      >
                        {{ site.name.toLowerCase() }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="show === 1 || show === 0"
              :class="show === 0 ? 'col-md-3' : 'col-md'"
            >
              <div class="container-fluid">
                <div class="row pt-md pt-2">
                  <div class="col-2 col-md-3 w-auto m-auto">
                    <span
                      class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                      >Año</span
                    >
                  </div>
                  <div class="col-10 col-md-9">
                    <select
                      v-model="year"
                      class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="(data, idx) in yearsAndMonths"
                        :key="idx"
                        :selected="data.year === 2022"
                        :value="data.year"
                      >
                        {{ data.year }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="show === 1 || show === 0"
              :class="show === 0 ? 'col-md-3' : 'col-md'"
            >
              <div class="container-fluid">
                <div class="row pt-md pt-2">
                  <div class="col-2 col-md-3 w-auto m-auto">
                    <span
                      class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                      >Mes</span
                    >
                  </div>
                  <div class="col-10 col-md-9">
                    <select
                      v-model="month"
                      class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="(month, idx) in filteredMonths"
                        :key="idx"
                        :selected="month === filteredMonths.slice(-1)[0]"
                        v-bind:value="month"
                      >
                        {{ months_name[month] }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div :class="show === 0 ? 'col-md-6' : 'col-md-2'">
              <div class="container">
                <div class="row pt-md py-3">
                  <div
                    :class="show === 0 ? 'col-md-3' : ''"
                    class="col ml-auto"
                  >
                    <button
                      class="tw-bg-red-400 tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 tw-rounded-md w-100 float-right p-0"
                      style="height: 40px"
                      type="button"
                      @click="filterAgain()"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Graph and indicators buttons -->
        <div
          id="graph-buttons"
          :class="show === 0 ? 'col-lg-12 d-md-block d-none' : 'col-lg-8'"
          class="order-3 order-sm-1"
        >
          <div
            class="pl-1 mb-3 tw-font-roboto tw-text-sm he-md-40 d-md-block d-none"
          >
            {{ actualIndicatorDesc }}
          </div>

          <!-- Graph (only in desktop) -->
          <div class="d-md-block d-none">
            <div
              class="tw-shadow-lg tw-bg-white tw-rounded-lg tw-h-full tw-grid tw-grid-rows-row2_fit_first tw-gap-3 tw-p-4"
            >
              <div>
                <div class="container-fluid">
                  <div class="row m-1 pt-2">
                    <div v-if="show === 1" class="col-md">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-3 w-auto m-auto">
                            <span
                              class="tw-font-robotoBold tw-text-base font-weight-light pr-1"
                              >Site</span
                            >
                          </div>
                          <div class="col-md-9">
                            <select
                              v-if="show === 1"
                              v-model="area_filter"
                              class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                            >
                              <option :value="null" disabled>
                                Seleccionar...
                              </option>

                              <option
                                v-for="site in this.tabFilter.sites"
                                v-bind:key="site.id"
                                :selected="site.id === area_filter"
                                v-bind:value="site.id"
                              >
                                {{ site.name.toLowerCase() }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="show === 1 || show === 0"
                      :class="show === 0 ? 'col-md-3' : 'col-md'"
                    >
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-3 w-auto m-auto">
                            <span
                              class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                              >Año</span
                            >
                          </div>
                          <div class="col-md-9">
                            <select
                              v-model="year"
                              class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                            >
                              <option :value="null" disabled>
                                Seleccionar...
                              </option>

                              <option
                                v-for="(data, idx) in yearsAndMonths"
                                :key="idx"
                                :selected="data.year === 2022"
                                :value="data.year"
                              >
                                {{ data.year }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="show === 1 || show === 0"
                      :class="show === 0 ? 'col-md-3' : 'col-md'"
                    >
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-3 w-auto m-auto">
                            <span
                              class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                              >Mes</span
                            >
                          </div>
                          <div class="col-md-9">
                            <select
                              v-model="month"
                              class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1"
                            >
                              <option :value="null" disabled>
                                Seleccionar...
                              </option>

                              <option
                                v-for="(month, idx) in filteredMonths"
                                :key="idx"
                                :selected="
                                  month === filteredMonths.slice(-1)[0]
                                "
                                v-bind:value="month"
                              >
                                {{ months_name[month] }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="show === 0 ? 'col-md-6' : 'col-md-2'">
                      <div class="container-fluid">
                        <div class="row">
                          <div
                            :class="show === 0 ? 'col-md-3' : ''"
                            class="col ml-auto"
                          >
                            <button
                              v-if="show === 1"
                              class="tw-bg-red-400 tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 tw-rounded-md w-100 float-right p-0"
                              style="height: 40px"
                              type="button"
                              @click="filterAgain()"
                            >
                              Filtrar
                            </button>
                            <button
                              v-else-if="show === 0"
                              class="tw-bg-red-400 tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 tw-rounded-md w-100 float-right p-0"
                              style="height: 40px"
                              type="button"
                              @click="filterTurn()"
                            >
                              Filtrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <BarChart2 :chartData="barData" class="tw-w-full tw-h-full" />
              </div>
            </div>
          </div>
          <!-- Indicators Buttons -->
          <div class="px-2 order-3 order-md-2">
            <IndicatorsButtons
              :id="id"
              :categoriesListData="categoriesListData"
              :show="show"
            />
          </div>
        </div>
        <!-- Leaders Ranking -->
        <div
          v-if="show === 1 || show === 2"
          id="leaders-ranking"
          class="col-lg-4 order-2 order-sm-2 p-3"
        >
          <div v-if="isLoading">
            <div class="text-center mt-3">
              <img alt="loading" src="../../assets/img/icons/loading.gif" />
            </div>
          </div>

          <div v-else>
            <div class="d-md-none d-block py-3">
              <p class="tw-font-robotoBold fs-22 text-capitalize">
                {{ title }}
              </p>
              <p class="tw-font-roboto tw-text-sm line-height-16">
                {{ actualIndicatorDesc }}
              </p>
            </div>
            <LeaderRanking
              :actualIndicator="actualIndicator"
              :classification="classification"
              :isLoading="isLoading"
              :ranking="ranking"
              :user_idSharp="this.user.id_sharp"
            />
          </div>
        </div>
      </div>

      <!-- Como Mejorar tu Ranking -->
      <div id="mejorar-ranking">
        <div v-if="isLoading" class="mb-5">
          <div class="text-center mt-3">
            <img alt="loading" src="../../assets/img/icons/loading.gif" />
          </div>
        </div>
        <div v-else class="my-5">
          <div v-if="is_data_fetched === true">
            <div class="row">
              <div v-if="show === 1" class="col-md tw-font-roboto">
                <h4 class="tw-font-robotoBold tw-text-xl md:tw-text-2xl">
                  Como mejorar tu ranking
                </h4>
              </div>
              <div v-else-if="show === 2" class="col-md tw-font-roboto">
                <h4 class="tw-font-robotoBold tw-text-xl md:tw-text-2xl">
                  Personalidades similares
                </h4>
              </div>
            </div>
            <div
              class="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-2 md:tw-gap-4 lg:tw-gap-6"
              v-if="categoryInfoLoaded === true"
            >
              <div v-for="item in categoryInfo[0].content" :key="item.id">
                <div
                  class="tw-grid tw-grid-cols-3 tw-shadow-lg tw-bg-white tw-rounded-2xl tw-overflow-hidden tw-h-40 md:tw-h-44 lg:tw-h-48 tw-relative"
                >
                  <div class="tw-overflow-hidden">
                    <img
                      :src="uriResources + '/' + item.image"
                      class="tw-w-full tw-h-full tw-object-cover"
                      alt=""
                    />
                  </div>
                  <div
                    class="tw-col-span-2 tw-overflow-hidden tw-p-4 tw-grid tw-grid-rows-3 tw-gap-2"
                  >
                    <div
                      class="tw-row-span-1 tw-grid tw-grid-cols-col2_fit_last tw-gap-2"
                    >
                      <p
                        class="tw-m-0 tw-font-tolyerBold tw-text-xl md:tw-text-2xl lg:tw-text-2.5xl tw-leading-5 md:tw-leading-6 lg:tw-leading-6 tw-line-clamp-2"
                      >
                        {{ item.title }}
                      </p>
                      <img
                        :src="uriResources + '/' + item.icon"
                        alt=""
                        class="tw-w-6 tw-h-6 md:tw-w-7 md:tw-h-7 lg:tw-w-8 lg:tw-h-8"
                      />
                    </div>
                    <div class="tw-row-span-2">
                      <p
                        class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-5"
                      >
                        {{ item.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart2 from "@/components/graphs/BarChart2";
import IndicatorsButtons from "@/components/engagement/IndicatorsButtons";
import LeaderRanking from "@/components/engagement/LeaderRanking";
import DoughnutChart2 from "@/components/graphs/DoughnutChart.vue";
import FilterSwitch from "@/components/engagement/FilterSwitch";

import config from "@/services/config";

import psi from "@/mixins/general/psi";
import { mapActions, mapState } from "vuex";

export default {
  name: "EngagementView",
  mixins: [psi],
  components: {
    IndicatorsButtons,
    LeaderRanking,
    DoughnutChart2,
    FilterSwitch,
    BarChart2,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      isLoading: true,
      is_data_fetched: false,
      area: null,
      area_filter: 32,
      area_personalities: 1,
      year: 2021,
      month: null,
      filter: 1,
      classification: 1,
      actualIndicator: "",
      id: 1,
      actualIndicatorDesc: "",
      category: [1],
      categoryInfoLoaded: false,
      categoryInfo: [],
      ranking: [],
      barData: {
        labels: [],
        data: [],
      },
      donutData: {
        name: null,
        labels: [],
        data: [],
        colors: [],
      },
      brandData: [],
      barThick: 15,
      site: "",
      show: 1,
      brand: {
        id: 0,
        name: null,
        description: null,
        image: null,
      },
      nameVp: "",
    };
  },

  async created() {
    this.isLoading = true;
    this.area = this.user.core_vp_id;
    await this.actGetYearsAndMonths()
      .then(() => {
        this.year = this.yearsAndMonths.slice(-1)[0].year;
        this.month = this.filteredMonths.slice(-1)[0];
      })
      .then(() => {
        this.actGetTabFilter();
        this.actGetLeaders()
          .then(() => {})
          .catch((err) => {
            console.error("Error: ", err);
          });
      })
      .then(() => {
        this.actGetPersonalitiesAreas()
          .then(() => {})
          .catch((err) => {
            console.error("Error: ", err);
          })
          .finally(() => {});
      });
    /*    await this.actGetTabFilter()
            .then(() => {
            
            })
            .catch((err) => {
              console.error("Error: ", err);
            })
            .finally(() => {
              this.month = this.tabFilter.months.slice(-1)[0];
              this.year = this.tabFilter.years[0];


            });*/

    this.getCategoriesInfo();

    //Get Ranking, Chart and Improve Ranking Data
    this.getGeneralFilterData(
      this.filter,
      this.category,
      this.year,
      this.month
    );

    this.getPersonalitiesData(this.area_personalities);

    this.actGetLeadersInfo({
      leader: [[30000423, 25]],
      year: 2021,
      month: 3,
    })
      .then(() => {})
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {});
    this.isLoading = false;
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modIndicators", [
      "tabFilter",
      "leadersList",
      "personalitiesAreaList",
      "categoriesListData",
      "generalFilterData",
      "turnOverData",
      "personalitiesData",
      "leadersInfo",
      "yearsAndMonths",
    ]),
    title() {
      if (this.show === 2) {
        return "Personalidades de tu equipo";
      }
      return "Ranking Líderes - " + this.actualIndicator.toLowerCase();
    },
    datacollection() {
      return this.fillData();
    },
    description() {
      return this.actualIndicator.description;
    },
    filteredMonths() {
      return this.yearsAndMonths
        .filter((year) => {
          return year.year === this.year;
        })
        .map((year) => {
          return year.months;
        })
        .flat();
    },
  },
  methods: {
    ...mapActions("modIndicators", [
      "actGetTabFilter",
      "actGetLeaders",
      "actGetPersonalitiesAreas",
      "actGetCategoriesInfo",
      "actGetGeneralFilterData",
      "actGetTurnOverData",
      "actGetPersonalitiesData",
      "actGetLeadersInfo",
      "actGetYearsAndMonths",
    ]),
    fillData() {
      return {
        labels: this.donutData.labels,
        datasets: [
          {
            backgroundColor: this.donutData.colors,
            data: this.donutData.data,
          },
        ],
      };
    },
    filterIndicatorbyId(id, show) {
      let filteredIndicator = this.categoriesListData.filter(
        (e) => e.id === id
      );
      this.changeClassification(
        filteredIndicator[0].classification,
        filteredIndicator[0].id,
        filteredIndicator[0].name,
        filteredIndicator[0].description,
        show
      );
    },
    changeClassification(classification, id, name, description, show) {
      this.classification = classification;
      this.category = [id];
      this.id = id;
      this.actualIndicator = name;
      this.actualIndicatorDesc = description;
      if (show === 1) {
        this.getGeneralFilterData(
          this.filter,
          this.category,
          this.year,
          this.month
        );
      } else if (show === 0) {
        this.getTurnOverData(this.year, this.month);
      }
      // this.updateRanking(id);
      this.updateImproveRanking(id);
    },
    changeShow(number) {
      this.show = number;
    },
    changeFilter() {
      if (this.filter === 1) {
        this.filter = 2;
        this.barThick = 20;
      } else {
        this.filter = 1;
        this.barThick = 15;
      }
      this.filterAgain();
    },
    filterAgain() {
      this.getCategoriesInfo();
      this.getGeneralFilterData(
        this.filter,
        this.category,
        this.year,
        this.month
      );
    },
    filterTurn() {
      this.getCategoriesInfo();
      this.getTurnOverData(this.year, this.month);
    },
    filterPersonalities() {
      this.getPersonalitiesData(this.area_personalities);
    },
    // updateRanking(category_id) {
    //   this.ranking = Object.values(
    //     this.generalFilterData.filter((category) => {
    //       return category.id === category_id;
    //     })[0].ranking
    //   );
    //   this.ranking.sort(
    //     (a, b) =>
    //       parseFloat(b.average) - parseFloat(a.average) && b.ranking - a.ranking
    //   );
    // },
    updateImproveRanking(category_id) {
      this.categoryInfo = this.categoriesListData.filter((category) => {
        return category.id === category_id;
      });
    },
    getGeneralFilterData(filter, category, year, month) {
      // This filter brings the information for the bar chart
      this.actGetGeneralFilterData({
        filter: filter,
        category: category,
        year: year,
        month: month,
      })
        .then(() => {
          this.is_data_fetched = false;
          this.actualIndicator = this.generalFilterData[0].name;
          this.actualIndicatorDesc = this.generalFilterData[0].description;
          this.ranking = Object.values(
            this.generalFilterData.filter((category) => {
              return category.id === this.id;
            })[0].ranking
          ).sort((a, b) => parseFloat(b.average) - parseFloat(a.average));

          let fetchedData = Object.values(
            this.generalFilterData.filter((category) => {
              return category.id === this.id;
            })[0].calculate
          );
          this.getChartData(fetchedData, this.filter);
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.is_data_fetched = true;
        });
    },
    getTurnOverData(year, month) {
      this.actGetTurnOverData({
        year: year,
        month: month,
      })
        .then(() => {
          this.is_data_fetched = false;
          this.actualIndicator = this.turnOverData.name;
          this.actualIndicatorDesc = this.turnOverData.description;

          let fetchedData = Object.values(this.turnOverData.calculate);

          this.getChartData(fetchedData, this.filter);
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.is_data_fetched = true;
        });
    },
    getCategoriesInfo() {
      this.actGetCategoriesInfo({
        area: this.area_filter,
        year: this.year,
        month: this.month,
      })
        .then(() => {
          this.categoryInfo = this.categoriesListData.filter((category) => {
            return category.id === this.id;
          });
          this.categoriesListData.sort(
            (a, b) => a.classification - b.classification
          );
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.categoryInfoLoaded = true;
        });
    },
    getChartData(data, filter) {
      this.barData.labels.length = 0;
      this.barData.datasets = [];
      this.barData.datasets.push({
        backgroundColor: "#bababa",
        borderRadius: 20,
        data: [],
      });
      if (filter === 2) {
        for (let i = 0; i < data.length; i++) {
          this.barData.labels.push(data[i].month);
          this.barData.datasets[0].data.push(parseFloat(data[i].average));
        }
      } else {
        if (this.show === 0) {
          data.sort((a, b) => parseFloat(a.average) - parseFloat(b.average));
        } else {
          data.sort((a, b) => parseFloat(b.average) - parseFloat(a.average));
        }

        for (let i = 0; i < data.length; i++) {
          let siteName = data[i].name.split(" ");
          for (let i = 0; i < siteName.length; i++) {
            siteName[i] =
              siteName[i].charAt(0) + siteName[i].slice(1).toLowerCase();
          }
          this.barData.labels.push(siteName.join(" "));
          this.barData.datasets[0].data.push(parseFloat(data[i].average));
        }
      }
    },
    getPersonalitiesData(id) {
      this.actGetPersonalitiesData({
        id: id,
      })
        .then(() => {
          this.is_data_fetched = false;
          this.getBrandData();
          this.brand.id = this.personalitiesData.calculate[0].profile.id;
          this.brand.name = this.personalitiesData.calculate[0].profile.name;
          this.brand.description =
            this.personalitiesData.calculate[0].profile.tip_description;
          this.brand.image =
            this.personalitiesData.calculate[0].profile.tip_image;
          this.changeNameVp();
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.is_data_fetched = true;
        });
    },
    getDonutData() {
      this.donutData.name = null;
      this.donutData.labels.length = 0;
      this.donutData.data.length = 0;
      this.donutData.colors.length = 0;
      this.donutData.name = this.personalitiesData.vp.name;
      this.brandData.sort(
        (a, b) => parseFloat(b.average) - parseFloat(a.average)
      );
      for (let i = 0; i < this.brandData.length; i++) {
        this.donutData.labels.push(this.brandData[i].profile.name);
        this.donutData.data.push(
          Math.round(parseFloat(this.brandData[i].average))
        );
        this.donutData.colors.push(this.brandData[i].color);
      }
    },
    changeBrand(id) {
      this.brand.id = id;
      this.filterBrand();
    },
    filterBrand() {
      let filteredBrand = this.personalitiesData.calculate.filter(
        (e) => e.profile.id === this.brand.id
      );
      this.brand.name = filteredBrand[0].profile.name;
      this.brand.description = filteredBrand[0].profile.tip_description;
      this.brand.image = filteredBrand[0].profile.tip_image;
      return filteredBrand;
    },
    getBrandData() {
      this.brandData = [];
      for (let i = 0; i < this.personalitiesData.calculate.length; i++) {
        this.personalitiesData.calculate[i].color = this.brandColors[i];
        this.brandData.push(this.personalitiesData.calculate[i]);
      }
      //
      this.getDonutData();
      //
    },
    changeNameVp() {
      let name = this.personalitiesAreaList.filter((filtro) => {
        return filtro.id === this.area_personalities;
      })[0];

      this.nameVp = name.name;
    },
  },
};
</script>

<style scoped>
.fill-text {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.card-img {
  position: relative;
  overflow: hidden;
}

.card-img img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: absolute;
}

#parent {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* [class*="col"] {
  padding: 1%;
} */

.button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: 0;
  width: 100%;
}

.psi {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 264px;
  height: 55px;
  overflow: hidden;
}

[class*="psi-"]:hover {
  color: white;
}

[class*="psi-"]:hover .value {
  color: white;
}

.psi-1 {
  background: linear-gradient(270deg, #f1ce38 0%, #f05e22);
  color: #f05e22;
}

.psi-2 {
  background: linear-gradient(90deg, #4cc3c9 5.33%, #5ea0f8 92.75%);
  color: #4cc3c9;
}

.psi-3 {
  background: linear-gradient(270deg, #eaa8ca 4.33%, #bf0d63 95.42%);
  color: #bf0d63;
}

.psi-4 {
  background: linear-gradient(270deg, #e83c3e 39.02%, #c02f33 95.64%);
  color: #c02f33;
}

.psi-left {
  background: transparent;
  width: 10%;
  float: left;
  z-index: 1;
}

.psi-t {
  width: 100%;
  z-index: 1;
  float: right;
  background-color: white;
}

.psi-trans {
  width: 100%;
  z-index: 1;
  float: right;
  background-color: transparent;
  color: white;
}

.psi-trans .value {
  color: white;
}

.psi:hover .psi-t {
  background-color: transparent;
}

.value {
  color: #717171;
}

.person-box {
  width: 100%;
  height: 72px;
  border-radius: 10px;
}

.person-back1 {
  background: #f9f9f9;
}

.person-back2 {
  background: rgba(241, 184, 52, 0.45);
}

.dropdown {
  background: white;
  max-width: 140px;
}

.dropdown-2 {
  background: white;
  line-height: 40px;
}

.img-container {
  height: 100%;
  max-height: 250px;
}

.img-container img {
  display: block;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .graph {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .psi {
    height: 45px;
  }

  .personalities-cell {
    padding-top: 30px;
  }
}
</style>
