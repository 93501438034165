<script setup>
import { useRouter } from "vue-router/composables";

const router = useRouter();
const props = defineProps({
  crumbs: {
    type: Array,
    required: true,
  },
});

const isLast = (index) => index === props.crumbs.length - 1;

const selected = (crumb) => {
  if (crumb.link === "#") return;
  router.push({ path: crumb.link });
};
</script>
<template>
  <nav>
    <ol class="tw-bg-white tw-m-0 tw-p-0 tw-hidden md:tw-flex tw-items-center">
      <li
        v-for="(crumb, ci) in props.crumbs"
        :key="ci"
        class="breadcrumb-item tw-flex tw-items-center tw-px-0"
      >
        <p
          class="tw-text-xs tw-px-0 tw-font-robotoLight tw-text-gray-400 hover:tw-text-gray-600 tw-outline-none tw-shadow-none tw-border-0 tw-bg-transparent tw-m-0"
          :class="
            isLast(ci) ? 'disabled tw-cursor-default' : 'tw-cursor-pointer'
          "
          @click="crumb.link === '#' ? null : selected(crumb)"
        >
          {{ crumb.name }}
        </p>
      </li>
    </ol>
  </nav>
</template>

<style scoped lang="postcss">
.breadcrumb-item + .breadcrumb-item::before {
  @apply tw-text-red-400 tw-font-robotoLight  tw-text-xs  tw-px-0 tw-mr-2;
  content: ">";
}
.disabled {
  @apply tw-font-roboto tw-text-gray-900 tw-font-bold tw-pointer-events-none;
}
</style>
