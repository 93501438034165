function getWeekNumber(d) {
  let firstDayOfYear = new Date(d.getFullYear(), 0, 1);
  let pastDayOfYear = (d - firstDayOfYear) / 86400000;
  return Math.ceil((pastDayOfYear + firstDayOfYear.getDay() + 1) / 7);
}

function isLeapYear(year) {
  if (year % 100 === 0) {
    return year % 400 === 0;
  } else {
    return year % 4 === 0;
  }
}

function isWorkDay(d) {
  let day = d.getDay();
  return day !== 0 && day !== 6;
}

class Day {
  constructor(date = null, lang = "default") {
    date = date ?? new Date();
    this.Date = date;
    this.date = date.getDate();
    this.day = date.toLocaleString(lang, { weekday: "long" });
    this.dayNumber = date.getDay(); // 0-6, the sunday is 0
    this.dayShort = date.toLocaleString(lang, { weekday: "short" });
    this.year = date.getFullYear();
    this.yearShort = Number(date.toLocaleString(lang, { year: "2-digit" }));
    this.month = date.toLocaleString(lang, { month: "long" });
    this.monthShort = date.toLocaleString(lang, { month: "short" });
    this.monthNumber = Number(date.toLocaleString(lang, { month: "2-digit" }));
    this.timeStamp = date.getTime();
    this.week = getWeekNumber(date);
    this.workday = isWorkDay(date);
    this.Dateymd = `${this.year}-${String(this.monthNumber).padStart(
      2,
      "0"
    )}-${String(this.date).padStart(2, "0")}`;
  }

  get isToday() {
    return this.isEqualTo(new Date());
  }

  isEqualTo(date) {
    date = date instanceof Day ? date.Date : date;

    return (
      date.getDate() === this.date &&
      date.getMonth() === this.monthNumber - 1 &&
      date.getFullYear() === this.year
    );
  }

  format(formatStr) {
    return formatStr
      .replace(/\bYYYY\b/, this.year)
      .replace(/\bYY\b/, this.yearShort)
      .replace(/\bWW\b/, this.week.toString().padStart(2, "0"))
      .replace(/\bW\b/, this.week)
      .replace(/\bMMMM\b/, this.month)
      .replace(/\bMMM\b/, this.monthShort)
      .replace(/\bMM\b/, this.monthNumber.toString().padStart(2, "0"))
      .replace(/\bM\b/, this.monthNumber)
      .replace(/\bDDDD\b/, this.day)
      .replace(/\bDDD\b/, this.dayShort)
      .replace(/\bDD\b/, this.date.toString().padStart(2, "0"))
      .replace(/\bD\b/, this.date);
  }
}

class Month {
  constructor(date = null, lang = "default") {
    const self = this;
    const day = new Day(date, lang);
    const monthsSize = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    this.lang = lang;

    this.name = day.month;
    this.number = day.monthNumber;
    this.year = day.year;
    this.numberOfDays = monthsSize[this.number - 1];
    this.first_date = this.getDay(1).Date;
    this.last_date = this.getDay(this.numberOfDays).Date;
    this.start_date = new Date(this.year, this.number - 1, 1)
      .toISOString()
      .split("T")[0];
    this.end_date = new Date(this.year, this.number - 1, this.numberOfDays)
      .toISOString()
      .split("T")[0];
    this.next_month_first_date = new Date(this.year, this.number, 1)
      .toISOString()
      .split("T")[0];

    if (this.number === 2) {
      this.numberOfDays += isLeapYear(day.year) ? 1 : 0;
    }

    // this[Symbol.iterator] = function* () {
    //   let date = new Date(this.year, this.number - 1, 1);
    //   let day = new Day(date, this.lang);

    //   while (day.monthNumber === this.number) {
    //     yield day;
    //     date.setDate(date.getDate() + 1);
    //     day = new Day(date, this.lang);
    //   }
    // };

    this[Symbol.iterator] = function* () {
      let number = 1;
      yield self.getDay(number);
      while (number < this.numberOfDays) {
        number++;
        yield self.getDay(number);
      }
    };
  }

  getDay(date) {
    // we subtract 1 from the month number because the month number starts at 0
    return new Day(new Date(this.year, this.number - 1, date), this.lang);
  }
}

class Calendar {
  weekDaysLong = Array.from({ length: 7 });
  weekDaysShort = Array.from({ length: 7 });
  weekDaysWork = Array.from({ length: 5 });

  constructor(year = null, monthNumber = null, lang = "default") {
    const self = this;
    // this.today = new Day(null, lang);
    this.today = new Day(new Date(), lang);
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.today.date + 1);
    this.tomorrow.setHours(0, 0, 0, 0);
    const todayYear = this.today.Date.getFullYear();
    const todayMonth = String(this.today.Date.getMonth() + 1).padStart(2, "0");
    const todayDay = String(this.today.Date.getDate()).padStart(2, "0");
    this.todayymd = `${todayYear}-${todayMonth}-${todayDay}`;
    this.todayDayAndMonth = this.today.format("D de MMMM");

    this.year = year ?? this.today.year;
    this.month = new Month(
      new Date(this.year, (monthNumber || this.today.monthNumber) - 1),
      lang
    );
    this.lang = lang;

    this[Symbol.iterator] = function* () {
      let number = 1;
      yield self.getMonth(number);
      while (number < 12) {
        number++;
        yield self.getMonth(number);
      }
    };

    // these functions are made to get the weekdays in the correct order and in the navigator language
    this.weekDaysLong.forEach((_, idx) => {
      // this starts at index 0
      // as the month doesn't have a day 0, it will return the last day of the previous month
      const day = this.month.getDay(idx);
      // check if the array weekDaysLong doesn't include the day returned by the month.getDay()
      if (!this.weekDaysLong.includes(day.day)) {
        // if it doesn't, add it to the array
        this.weekDaysLong[day.dayNumber] = day.day;
      }
    });

    this.weekDaysShort.forEach((_, idx) => {
      const day = this.month.getDay(idx);
      if (!this.weekDaysShort.includes(day.dayShort)) {
        this.weekDaysShort[day.dayNumber] = {
          day: day.dayShort,
          number: day.dayNumber,
        };
      }
    });

    this.weekDaysWork.forEach((_, idx) => {
      const day = this.month.getDay(idx + 1);
      if (!this.weekDaysWork.includes(day.dayShort)) {
        this.weekDaysWork[day.dayNumber - 1] = {
          day: day.dayShort,
          number: day.dayNumber,
        };
      }
    });

    // this.monthDaysList = Array.from({
    //   length:
    //     this.month.numberOfDays +
    //     this.month.getDay(1).dayNumber +
    //     7 -
    //     [...this.month].slice(-1)[0].dayNumber -
    //     1,
    // });

    this.monthDaysList = [];

    this.getCurrentMonthDays();
  }

  get isLeapYear() {
    return isLeapYear(this.year);
  }

  getMonth(number) {
    return new Month(new Date(this.year, number - 1), this.lang);
  }

  getPreviousMonth() {
    if (this.month.number === 1) {
      return new Month(new Date(this.year - 1, 11), this.lang);
    }
    return new Month(new Date(this.year, this.month.number - 2), this.lang);
  }

  getNextMonth() {
    if (this.month.number === 12) {
      return new Month(new Date(this.year + 1, 0), this.lang);
    }

    return new Month(new Date(this.year, this.month.number), this.lang);
  }

  goToDate(monthNumber, year) {
    this.month = new Month(new Date(year, monthNumber - 1), this.lang);
    this.year = year;
  }

  goToNextYear() {
    this.year++;
    this.month = new Month(new Date(this.year, 0), this.lang);
    this.getCurrentMonthDays();
  }

  goToPreviousYear() {
    this.year--;
    this.month = new Month(new Date(this.year, 11), this.lang);
    this.getCurrentMonthDays();
  }

  goToNextMonth() {
    if (this.month.number === 12) {
      return this.goToNextYear();
    }

    this.month = new Month(new Date(this.year, this.month.number), this.lang);
    this.getCurrentMonthDays();
  }

  goToPreviousMonth() {
    if (this.month.number === 1) {
      return this.goToPreviousYear();
    }

    this.month = new Month(
      new Date(this.year, this.month.number - 2),
      this.lang
    );
    this.getCurrentMonthDays();
  }

  goToCurrentMonth() {
    this.year = this.today.year;
    this.month = new Month(new Date(), this.lang);
    this.getCurrentMonthDays();
  }

  goToRangeMonth(startDate) {
    let start_date = new Date(Date.parse(startDate));
    this.year = start_date.getFullYear();
    let month = start_date.getMonth();
    this.month = new Month(new Date(this.year, month), this.lang);
    this.getCurrentMonthDays();
  }

  getFirstDayOfMonth() {
    return this.month.getDay(1);
  }

  getMonthDaysGrid() {
    const totalLastMonthDays = this.getFirstDayOfMonth().dayNumber;
    const totalDays = this.month.numberOfDays + totalLastMonthDays;
    const monthList = Array.from({ length: totalDays });

    for (let i = totalLastMonthDays; i < totalDays; i++) {
      monthList[i] = {
        date: i - totalLastMonthDays + 1,
        month: this.month.name,
        year: this.month.year,
      };
    }

    return monthList;
  }

  getCurrentMonthDays() {
    this.emptyMonthDaysList();

    const lastDaysOfPreviousMonth = [...this.getPreviousMonth()].slice(
      -this.month.getDay(1).dayNumber
    );

    lastDaysOfPreviousMonth.forEach((day, idx) => {
      this.monthDaysList[idx] = day;
    });

    const lastDayOfCurrentMonth = [...this.month].slice(-1)[0];

    [...this.month].forEach((day) => {
      this.monthDaysList[day.date + this.month.getDay(1).dayNumber - 1] = day;
    });

    const firstDaysOfNextMonth = [...this.getNextMonth()].slice(
      0,
      7 - lastDayOfCurrentMonth.dayNumber - 1
    );

    firstDaysOfNextMonth.forEach((day) => {
      this.monthDaysList.push(day);
    });
  }

  emptyMonthDaysList() {
    this.monthDaysList = [];
  }

  getSurroundingDays(date) {
    const surroundingDays = [];
    const dateToCompare = new Date(date);
    const dateToCompareDay = dateToCompare.getDate();
    const dateToCompareMonth = dateToCompare.getMonth();
    const dateToCompareYear = dateToCompare.getFullYear();

    for (let i = -2; i <= 2; i++) {
      const surroundingDate = new Date(
        dateToCompareYear,
        dateToCompareMonth,
        dateToCompareDay + i
      );
      const surroundingDay = new Day(surroundingDate); // Supongamos que Day es la clase que hemos definido
      surroundingDays.push(surroundingDay);
    }

    return surroundingDays;
  }

  getFirstVisibleDay() {
    return this.monthDaysList[0];
  }

  getLastVisibleDay() {
    return this.monthDaysList.slice(-1)[0];
  }
}

// const day = new Day(new Date());
//const day = new Day(null, 'es'); para usar el idioma español
// console.log(day);
// console.log("--day", day.format("DDDD DD/MM/YYYY WW"));

// const month = new Month(new Date());
// console.log(month);

// console.log(month, month.getDay(3))

// for (day of month) {
//   console.log('day', day.date);
// }

// const cal = new Calendar(null, null, "pt");
// console.log(cal);

// for (month of cal) {
//   console.log(month);
// }

export default Calendar;
