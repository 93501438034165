<!--suppress ALL -->
<script setup>
import { ref } from "vue";
import config from "@/services/config";
import GeneralModal from "@/components/modals/GeneralModal";
import ImageSlider from "@/components/sliders/CompImageSlider.vue";

const uriResources = config.beerOfficeURL;
const isShowModalImages = ref(false);
const selected = ref(0);

const props = defineProps({
  content: {
    type: Array,
  },
});

const toggleModalImages = (idx) => {
  selected.value = idx;
  isShowModalImages.value = !isShowModalImages.value;
};

const linkImg = (img) => {
  if (img.includes("http://") || img.includes("https://")) {
    return img;
  } else {
    return uriResources + "/" + img;
  }
};
</script>
<template>
  <div>
    <portal to="all-modals">
      <general-modal
        :hide-button-support="true"
        v-if="isShowModalImages"
        modalName="images-article"
        @close="toggleModalImages"
        :isShowModal="isShowModalImages"
        :modalWidth="{ md: 500, lg: 600 }"
        :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      >
        <div
          class="tw-pt-6 tw-pb-4 tw-flex tw-overflow-hidden tw-justify-center"
        >
          <ImageSlider
            :content="props.content"
            :sel="selected"
            :inner="false"
            :side="'center'"
          />
        </div>
      </general-modal>
    </portal>
    <div class="grid-gallery">
      <div
        v-for="(img, idx) in content"
        :key="idx"
        class="grid-gallery__item tw-cursor-pointer"
        @click="toggleModalImages(idx)"
      >
        <img class="grid-gallery__image" :src="linkImg(img.image)" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped>
img {
  max-width: 100%;
  display: block;
}

.grid-gallery {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, 100px);
  grid-auto-rows: 100px;
  grid-auto-flow: row;
}

.grid-gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.grid-gallery__item:nth-child(3n + 1) {
  grid-column: span 1;
  grid-row: span 2;
}

.grid-gallery__item:nth-child(3n + 2) {
  grid-column: span 1;
  grid-row: span 1;
}

.grid-gallery__item:nth-child(3n + 3) {
  grid-column: span 1;
  grid-row: span 1;
}
</style>
