<template>
  <div>
    <!-- Título Desktop y leyenda -->
    <div
      class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0">
        <div class="d-none d-md-flex">
          <p
            class="tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
          >
            Recompensa
          </p>
        </div>
        <div class="d-none d-md-flex">
          <div class="px-3 p-md-0">
            <p
              class="tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"
            >
              Acumula maltas para redimir premios.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-3 px-md-3 mt-2 mb-4 my-md-4">
      <div class="row">
        <div
          class="col-12 d-flex justify-content-center justify-content-md-start"
        >
          <div
            class="d-flex justify-content-around justify-content-md-start align-items-center wi-md-auto wi-100p tw-rounded-2xl tw-shadow-md md:tw-shadow-none he-md-auto he-70"
          >
            <i
              class="icon-maltas fs-40 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
            ></i>
            <div>
              <p
                class="tw-font-robotoBold tw-text-lg fs-md-26 line-height-30 m-0"
              >
                Tienes
                {{
                  Number(user.gamification_maltas)
                    .toLocaleString()
                    .replace(",", ".")
                }}
                maltas
              </p>
              <p
                class="m-0 tw-font-roboto tw-text-xs tw-text-red-400 d-none d-md-block"
                v-if="user.gamification_maltas < reward.malts"
              >
                No tienes suficientes maltas para redimir este producto
              </p>
              <p
                class="m-0 tw-font-roboto tw-text-xs tw-text-red-400 d-block d-md-none"
                v-if="user.gamification_maltas < reward.malts"
              >
                No tienes suficientes maltas
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="!isLoading">
        <div class="col-12 col-md-7">
          <div class="carousel pb-2 tw-overflow-x-scroll">
            <div class="d-flex align-items-center mt-3 mt-md-auto">
              <div
                v-for="(item, index) in reward.images"
                :key="index"
                class="d-flex mr-4"
              >
                <div
                  class="mb-2 carousel-card wi-300 he-380 overflow-hidden d-flex tw-rounded-3xl"
                >
                  <img
                    class="h-100"
                    :src="uriResources + '/storage/images/' + item.name"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <p
              class="tw-font-robotoBold tw-text-xs fs-md-18 line-height-20 d-inline-block"
            >
              Ingresa a
              <i
                class="icon icon-gamimisiones tw-text-lg fs-md-22 tw-text-red-400 ml-1 my-0"
              ></i>
              <u
                class="tw-text-red-400"
                @click="$router.push('/gamification/missions')"
              >
                misiones</u
              >
              y gana suficientes maltas para redimir tus recompensas
            </p>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="d-flex flex-column h-100">
            <div>
              <div id="reward-description">
                <p class="tw-font-roboto fs-22 fs-md-36 m-auto">
                  {{ reward.name }}
                </p>
                <p
                  class="tw-font-roboto tw-text-gray-600 tw-text-sm line-height-20 my-2"
                >
                  {{ reward.description }}
                </p>
              </div>
              <div class="g-wrapper my-4 wi-md-auto">
                <div class="box a d-flex">
                  <i class="icon-reward fs-50 fs-md-70 tw-text-orange-300"></i>
                </div>
                <div class="box b d-flex align-items-center">
                  <p
                    class="m-0 tw-font-robotoBold tw-text-lg fs-md-26 line-height-20 line-height-md-30"
                  >
                    Premio
                  </p>
                </div>
                <div class="box c d-flex align-items-center">
                  <i
                    class="icon-maltas fs-22 fs-md-30 tw-text-orange-300 mr-2"
                  ></i>
                  <p
                    class="m-0 tw-font-roboto tw-text-base line-height-20 tw-text-gray-500"
                  >
                    {{ reward.malts.toLocaleString().replace(",", ".") }} maltas
                  </p>
                </div>
              </div>
              <div id="features" v-if="reward.gami_reward_property.length > 0">
                <div class="d-flex flex-column">
                  <p
                    class="m-0 tw-font-robotoBold tw-text-base line-height-18 tw-text-gray-600 py-2"
                  >
                    Selecciona tu talla
                  </p>
                  <div class="buttons-grid py-2">
                    <div v-for="item in filterSize" :key="item.id">
                      <div
                        class="wi-100 he-60 tw-rounded-lg tw-border tw-border-solid tw-border-gray-320 d-flex justify-content-center align-items-center tw-cursor-pointer"
                        :class="{
                          active: item.isActive,
                          '': Object.keys(selectedSize).length === 0,
                        }"
                        @click="toggleSizeActive(item.id)"
                      >
                        <p
                          class="m-0 tw-font-roboto tw-text-base line-height-20 tw-text-gray-300"
                        >
                          {{ item.value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="Object.keys(selectedSize).length === 0">
                    <p
                      class="tw-font-roboto tw-text-sm tw-text-red-400 mt-2 mb-0"
                    >
                      * Ups, selecciona alguna de las opciones para continuar.
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    class="m-0 tw-font-robotoBold tw-text-base line-height-18 tw-text-gray-600 pt-4 pb-2"
                  >
                    Selecciona el color
                  </p>
                  <div class="buttons-grid py-2">
                    <div v-for="item in filterColor" :key="item.id">
                      <div
                        class="wi-100 he-60 tw-rounded-lg d-flex justify-content-center align-items-center tw-cursor-pointer"
                        :class="{
                          active: item.isActive,
                          '': Object.keys(selectedColor).length === 0,
                        }"
                        :style="'background-color:' + item.value"
                        @click="toggleColorActive(item.id)"
                      ></div>
                    </div>
                  </div>
                  <div v-if="Object.keys(selectedColor).length === 0">
                    <p
                      class="tw-font-roboto tw-text-sm tw-text-red-400 mt-2 mb-0"
                    >
                      * Ups, selecciona alguna de las opciones para continuar.
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  Object.keys(selectedColor).length > 0 &&
                  Object.keys(selectedSize).length > 0 &&
                  rewardsStock.is_available === false
                "
              >
                <p class="tw-font-roboto tw-text-sm tw-text-red-400 mt-2 mb-0">
                  Por el momento esta combinación de producto no se encuentra en
                  stock.
                </p>
              </div>
            </div>
            <div id="redimir" class="mt-4">
              <div
                class="tw-rounded-lg he-50 w-100 d-flex justify-content-center align-items-center"
                :class="
                  rewardsStock.is_available ||
                  (reward.gami_reward_property.length === 0 &&
                    user.gamification_maltas > reward.malts &&
                    reward.gami_level_id ===
                      user.current_rank.current.gami_level_id)
                    ? 'cursor-pointer tw-bg-red-400'
                    : 'tw-bg-gray-500'
                "
                @click="
                  rewardsStock.is_available &&
                  user.gamification_maltas > reward.malts &&
                  reward.gami_level_id ===
                    user.current_rank.current.gami_level_id
                    ? $router.push({
                        name: 'gamificationForm',
                        params: {
                          reward_id: reward.id,
                          size: selectedSize,
                          color: selectedColor,
                        },
                        query: {
                          reward_id: reward.id,
                        },
                      })
                    : reward.gami_reward_property.length === 0 &&
                      user.gamification_maltas > reward.malts &&
                      reward.gami_level_id ===
                        user.current_rank.current.gami_level_id
                    ? $router.push({
                        name: 'gamificationForm',
                        params: {
                          reward_id: reward.id,
                        },
                        query: {
                          reward_id: reward.id,
                        },
                      })
                    : ''
                "
              >
                <p
                  class="tw-font-roboto tw-font-black tw-text-base line-height-20 m-0 text-white"
                >
                  Redimir
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/services/config";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "gamificationRedeem",
  data() {
    return {
      uriResources: config.beerOfficeURL,
      isLoading: false,
      activeReward: [],
      filterColor: [],
      filterSize: [],
      selectedColor: {},
      selectedSize: {},
      combArray: [],
      reward_id: null,
    };
  },
  created() {
    this.isLoading = true;
    this.reward_id = this.$route.params.id;
    this.itemReward(this.$route.params.id);
    this.actGamiRewardsNew(this.reward_id)
      .then(() => {
        if (this.reward.gami_reward_property.length > 0) {
          this.filterColor = this.reward.gami_reward_property.filter((i) => {
            return i.property === "COLOR";
          })[0].gami_reward_property_value;
          this.filterColor.map((color) => {
            color.isActive = false;
          });
          this.filterSize = this.reward.gami_reward_property.filter((i) => {
            return i.property === "TALLA";
          })[0].gami_reward_property_value;
          this.filterSize.map((talla) => {
            talla.isActive = false;
          });
        } else {
          this.actGamiRewardsStock({ id: this.reward_id });
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", [
      "missions",
      "reward",
      "rewards",
      "redeem",
      "userRanks",
      "rewardsStock",
    ]),
    colorAndsize() {
      return [this.selectedColor.id, this.selectedSize.id];
    },
  },
  mounted() {
    this.emptyRewardsStock();
  },
  watch: {
    colorAndsize: {
      handler: function () {
        this.actGamiRewardsStock({
          id: this.reward_id,
          color: this.selectedColor.id,
          talla: this.selectedSize.id,
        })
          .then(() => {})
          .catch((err) => {
            console.error("Error: ", err);
          })
          .finally(() => {});
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("modGamification", [
      "actGamiMissions",
      "actGamiRewardsNew",
      "actGamiRewardsStock",
    ]),
    ...mapMutations("modGamification", ["emptyRewardsStock"]),
    itemReward(itemRewardId) {
      let find = this.rewards.findIndex(
        (element) => element.id === itemRewardId
      );
      if (find >= 0) {
        this.activeReward = this.rewards[find];
      } else {
        this.activeReward = [];
      }
    },
    // getFirstName(string) {
    //   string = string + "";
    //   let name = string.split(" ");
    //   return name[0].toLowerCase();
    // },
    toggleColorActive(color_id) {
      this.filterColor = this.filterColor.map((color) => {
        if (color.id === color_id) {
          color.isActive = true;
          this.selectedColor = color;
        }
        if (color.id !== color_id) {
          color.isActive = false;
        }
        return color;
      });
    },
    toggleSizeActive(size_id) {
      this.filterSize = this.filterSize.map((size) => {
        if (size.id === size_id) {
          size.isActive = true;
          this.selectedSize = size;
        }
        if (size.id !== size_id) {
          size.isActive = false;
        }
        return size;
      });
    },
    pushDataLayer: function (rank, product_name) {
      let result = this.userRanks.filter(
        (urank) => urank.gami_level_id === rank
      );

      window.dataLayer.push({
        event: "TrackEvent-Gamification",
        category: "Gamification",
        SubCategory: "Recompensas",
        Action: "Redimir",
        Rank: result[0].name,
        product: product_name,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.gamification {
  background-size: cover;
  padding: 20px 20px 70px 20px;
  height: auto;
}

.g-wrapper {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 60px 1fr 1fr;
}

.a {
  grid-column: 1 / 2;
  grid-row: 1 / span 2;
}

.b {
  grid-column: 2 / 4;
  grid-row: 1;
}

.c {
  grid-column: 2 / 4;
  grid-row: 2;
}

.active {
  border: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.buttons-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 768px) {
  .buttons-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
  }
  .gamification {
    padding: 80px 50px 30px 50px;
    height: 100vh;
  }

  .g-wrapper {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: 80px 1fr 1fr;
  }
}
</style>
