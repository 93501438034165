<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="d-flex align-items-center">
            <p class="mb-0 tw-text-gray-600 mr-2 font-weight-bold">Año</p>
            <select
              name="rankPeople"
              class="
                form-control
                he-30
                tw-shadow-sm tw-border-0 tw-text-sm tw-text-gray-400
              "
              @change="changeRankPeople"
            >
              <option
                v-for="year in rankPeople"
                :key="'rank-people-' + year.id"
                :value="year.id"
              >
                {{ year.year }} - {{ year.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="d-flex align-items-center" v-if="this.switchStage === 1">
            <p class="mb-0 tw-text-gray-600 mr-2 font-weight-bold">Mes</p>
            <select
              v-if="monthsByCategory.length > 0"
              name="month"
              class="
                form-control
                he-30
                tw-shadow-sm tw-border-0 tw-text-sm tw-text-gray-400
                select-ranking-type
              "
              @change="changeMonth"
            >
              <option
                v-for="month in monthsByCategory"
                :value="month.month"
                :key="'month-ranking-' + month.month"
              >
                {{ month.name }}
              </option>
            </select>
          </div>
          <div class="d-flex align-items-center" v-else>
            <p class="mb-0 tw-text-gray-600 mr-2 font-weight-bold">Etapa</p>
            <select
              name="stage"
              class="
                form-control
                he-30
                tw-shadow-sm tw-border-0 tw-text-sm tw-text-gray-400
                select-ranking-type
              "
              @change="changeRankStage"
            >
              <option
                v-for="stage in stagesByCategory"
                :value="stage.id"
                :key="'stage-ranking-' + stage.id"
              >
                {{ stage.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <button
            class="
              tw-bg-red-400 tw-text-white
              w-100
              tw-border-0 tw-rounded-lg
              he-30
              hover:tw-bg-red-600
            "
            @click="getResultFilter"
          >
            Filtrar
          </button>
        </div>
      </div>
      <div
        id="div-to-pdf"
        class="row tw-shadow-md tw-rounded-lg mt-4"
        v-if="dataUserRankSelected.length > 0"
      >
        <div class="col-12 col-lg-3 tw-rounded-lg">
          <div class="d-flex flex-column py-3">
            <div class="position-relative text-center">
              <img
                v-if="userSelected.position <= 3"
                :src="imgMedal(userSelected.position)"
                width="35"
                height="auto"
                alt="medalla"
                class="position-absolute"
              />
              <img
                :src="
                  userSelected.business_partner.prof_picture
                    ? uriResources +
                      '/' +
                      userSelected.business_partner.prof_picture
                    : imgAvatar
                "
                width="130"
                height="auto"
                alt="usuario"
                class="tw-rounded-full"
                :class="
                  userSelected.position === 1
                    ? 'tw-border-4 tw-border-solid tw-border-yellow-400 '
                    : userSelected.position === 2
                    ? 'tw-border-4 tw-border-solid tw-border-gray-400 '
                    : userSelected.position === 3
                    ? 'tw-border-4 tw-border-solid tw-border-brown-400 '
                    : ''
                "
              />
            </div>
            <div class="mt-3 text-center">
              <p class="mb-2 tw-text-gray-600 font-weight-bold tw-text-sm">
                {{ userSelected.business_partner.name }}
              </p>
              <p class="mb-0 tw-text-gray-600 tw-text-xxs">
                {{ userSelected.name }}
              </p>
            </div>
            <div class="text-center mt-3">
              <img
                src="@/assets/img/ranking/patern.png"
                alt="patrin bavaria"
                class="tw-w-20"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-9 py-3 tw-bg-white">
          <div class="d-flex w-100 justify-content-end">
            <div
              role="button"
              class="d-flex tw-text-red-400 align-items-center"
              @click="downloadPdf()"
            >
              <i class="icon-descargar mr-2"></i>
              <ins class="tw-text-sm mb-0 text-underline">Descargar</ins>
            </div>
          </div>
          <div class="container mt-3" v-if="switchStage === 1">
            <DataCategory1
              v-if="rankCategoryType === 1"
              :dataUserRankSelected="dataUserRankSelected"
              :calulationName="calulationName"
            />
            <DataCategory2
              v-if="rankCategoryType === 2"
              :dataUserRankSelected="dataUserRankSelected"
              :calulationName="calulationName"
            />
          </div>
          <div class="container mt-3" v-if="switchStage === 2">
            <DataCategory3
              v-if="switchStage === 2"
              :dataUserRankSelected="dataUserRankSelected"
              calulationName="Posición"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4" v-else>
        <div class="col-12">
          <div class="alert alert-warning" role="alert">
            No hay un usuario seleccionado.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import config from "@/services/config";

/* webpackPrefetch: true *//*webpackChunkName:"html2Pdf"*/
import html2pdf from 'html2pdf.js'


import DataCategory1 from "@/components/people/ranking/DataCategory1";
import DataCategory2 from "@/components/people/ranking/DataCategory2";
import DataCategory3 from "@/components/people/ranking/DataCategory3";

export default {
  name: "InfoPeopleRankingFilter",
  components: {
    DataCategory1,
    DataCategory2,
    DataCategory3,
  },
  data() {
    return {
      calulationName: "Enero",
      uriResources: config.beerOfficeURL,
      imgAvatar: require("@/assets/img/contacts/avatar-empty.png"),
    };
  },
  created() {
    this.getFilters().then(() => {
      this.getResultFilter();
      this.calulationName = this.months[0].name;
    });
  },
  computed: {
    ...mapState("modRankingPeople", [
      "months",
      "rankCategories",
      "rankPeople",
      "rankStages",
      "switchStage",
      "dataUserRankSelected",
      "userSelected",
      "monthsByCategory",
      "stagesByCategory",
      "rankCategoryType",
    ]),
  },
  methods: {
    ...mapActions("modRankingPeople", ["getFilters", "getResultFilter"]),
    ...mapMutations("modRankingPeople", [
      "setMonthSelected",
      "setRankPeopleSelected",
      "setRankStageSelected",
      "setDataUserRankSelected",
    ]),
    changeRankPeople(evt) {
      this.setRankPeopleSelected(evt.target.value);
    },
    changeMonth(evt) {
      this.setMonthSelected(evt.target.value);
      this.setDataUserRankSelected([]);
      this.calulationName = evt.target.selectedOptions[0].text;
    },
    changeRankStage(evt) {
      this.setRankStageSelected(evt.target.value);
      this.setDataUserRankSelected([]);
      this.calulationName = evt.target.selectedOptions[0].text;
    },
    imgMedal(position) {
      let img = "";
      switch (position) {
        case 1:
          img = require("@/assets/img/ranking/gold.png");
          break;
        case 2:
          img = require("@/assets/img/ranking/silver.png");
          break;
        case 3:
          img = require("@/assets/img/ranking/bronze.png");
          break;

        default:
          img = null;
          break;
      }
      return img;
    },
    async downloadPdf() {
      const element = document.getElementById("div-to-pdf");
      html2pdf(element, {
        margin: 1,
        filename: "ranking-business-partner.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    },
  },
};
</script>

