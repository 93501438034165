<script setup>
import AtomMedia from "@/components/atoms/AtomMedia.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import { computed } from "vue";

const props = defineProps({
  headingText: {
    type: String,
    required: true,
  },
  headingColor: {
    type: String,
    default: "black",
  },
  paragraph: {
    type: String,
    required: true,
  },
  mediaSrc: {
    type: String,
    required: true,
  },
  mediaType: {
    type: String,
    required: true, // 'image' or 'video'
  },
  mediaLocation: {
    type: String,
    default: "left", // 'left' or 'right'
  },
  textLocation: {
    type: String,
    default: "center",
  },
});

const textLocationClass = computed(() => {
  return props.textLocation === "center"
    ? "tw-justify-center"
    : props.textLocation === "start"
    ? "tw-justify-start"
    : "tw-justify-end";
});
</script>

<template>
  <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-3 lg:tw-gap-4">
    <div class="tw-flex tw-flex-col tw-gap-2" :class="textLocationClass">
      <atom-text
        tag="h2"
        font="robotoBold" 
        :text-color="props.headingColor"
        textSizeMobile="lg"
        textSizeDesktop="xl"
      >
        {{ props.headingText }}
      </atom-text>
      <atom-text
        tag="p"
        font="roboto"
        textSizeMobile="sm"
        textSizeDesktop="base"
      >
        {{ props.paragraph }}
      </atom-text>
    </div>
    <atom-media
      :src="props.mediaSrc"
      :type="props.mediaType"
      rounded
      class="tw-w-full tw-h-full tw-aspect-w-16 tw-aspect-h-9 tw-order-first"
      :class="
        props.mediaLocation === 'right'
          ? 'lg:tw-order-last'
          : 'lg:tw-order-first'
      "
    />
  </div>
</template>
