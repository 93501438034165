<script setup>
import { useRouter } from "vue-router/composables";
import CompServiceCard from "@/components/cards/CompServiceCard.vue";

const router = useRouter();

const props = defineProps({
  pendingRequests: {
    type: Array,
    default: () => [],
  },
  userRequests: {
    type: Array,
    default: () => [],
  },
  noRestrictions: {
    type: Boolean,
    default: false,
  },
  hasPerroOrGato: {
    type: Boolean,
    default: false,
  },
  timeBenefits: {
    type: Array,
    default: () => [],
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
    <!-- Title and top buttons -->
    <div
      id="catalog"
      class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-[1fr,auto,auto] tw-gap-4"
    >
      <div class="tw-col-span-1 lg:tw-col-auto tw-order-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl">
          Catálogo
        </p>
      </div>
      <div
        class="tw-col-span-2 lg:tw-col-auto tw-flex tw-items-stretch tw-gap-6 tw-order-3 lg:tw-order-2"
      >
        <div
          class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-btn-general-shape lg:!tw-w-fit tw-border tw-border-purple-200"
          v-if="props.pendingRequests.length > 0"
          @click="router.push({ name: 'timeBenefitsHistory' })"
        >
          <div
            class="tw-w-8 tw-h-8 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-purple-200"
          >
            <span class="tw-text-white">
              {{ props.pendingRequests.length }}
            </span>
          </div>
          <p class="tw-m-0 lg:tw-whitespace-nowrap">
            Tienes comprobantes pendientes por subir
          </p>
        </div>
      </div>
      <div
        class="tw-col-span-1 lg:tw-col-auto tw-flex tw-gap-6 tw-items-stretch tw-order-2 lg:tw-order-3"
      >
        <div
          class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-btn-general-shape lg:!tw-w-fit tw-border tw-border-purple-200 tw-h-full"
          v-if="props.userRequests.length > 0"
          @click="router.push({ name: 'timeBenefitsHistory' })"
        >
          <p class="tw-my-0 tw-mx-2 lg:w-whitespace-nowrap">Tus solicitudes</p>
        </div>
      </div>
    </div>
    <!-- Catalog -->
    <div class="tw-p-6 tw-bg-pink-400 child" v-if="!props.noRestrictions">
      <div class="tw-container-general tw-py-0 tw-my-0">
        <p
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-text-blue-700"
          v-if="!props.hasPerroOrGato"
        >
          Para solicitar este beneficio, primero debes completar la información
          sobre tus mascotas en tu perfil. Te invitamos a solicitar un día off o
          a completar tu perfil, recuerda que este beneficio solo aplica para
          gatos y perros.
        </p>
        <p
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-text-blue-700"
          v-else-if="userRequests[0]?.status === 'PENDING'"
        >
          No puedes solicitar un día porque tienes un comprobante pendiente por
          subir.
        </p>
        <p
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-text-blue-700"
          v-else-if="userRequests[0]?.status !== 'COMPLETED'"
        >
          Ya realizaste una solicitud, cuando esta finalice podrás solicitar
          otra nuevamente.
        </p>
      </div>
    </div>
    <div
      class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-px-10 lg:tw-px-0 tw-gap-4 lg:tw-gap-6"
    >
      <comp-service-card
        v-for="(item, idx) in props.timeBenefits"
        :key="idx"
        :item="item"
        border-color="tw-border-purple-200"
        label-text-color="tw-text-purple-200"
        desc-text-color="tw-text-black"
        @click="
          noRestrictions
            ? router.push({
                name: 'timeBenefitsDetail',
                params: { id: item.id },
              })
            : null
        "
        :active="noRestrictions"
      />
    </div>
  </div>
</template>

<style scoped>
.child {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}
</style>
