<template>
  <Bar
      :chart-data="chartData"
      :chart-id="chartId"
      :chart-options="chartOptions"
      :css-classes="cssClasses"
      :dataset-id-key="datasetIdKey"
      :height="height"
      :plugins="plugins"
      :styles="styles"
  />
</template>

<script>
import {Bar} from 'vue-chartjs/legacy'
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {Bar},
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    chartData: {
      type: Object,
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      /*chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
      },*/
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              fontColor: "black",
            },
            grid: {
              display: false,
            },
          },
          x: {
            ticks: {
              fontSize: 12,
              fontColor: "black",
              fontFamily: "Roboto",
              maxRotation: 90,
              minRotation: 90,
            },
            grid: {
              display: false,
            },
          },
        },
      }
    }
  }
}
</script>