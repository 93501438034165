<template>
  <div class="content-card col-md-12" id="comp-contact">
    <div class="card w-100">
      <div class="card-body">
        <div class="row pl-3 pr-3 font-weight-bold mb-3 mb-md-0">
          <div class="col-6 p-0">
            <h4
              class="textColorPrimary tw-font-robotoBold tw-text-xl md:tw-text-2xl"
            >
              Tus contactos
            </h4>
          </div>
          <div class="col-6 p-0 text-right">
            <router-link to="/my-contacts" class="text-red"
              >Ver todos</router-link
            >
          </div>
        </div>

        <div class="profOverflow">
          <div
            @click="$bvModal.show('bv-modal-favorite-contacts')"
            class="carousel-contacts d-flex"
            :style="{
              'background-image': 'url(' + backgroundContacts + ')',
              'background-repeat': 'repeat-x',
            }"
          >
            <div class="cell">
              <div class="onboard-slide">
                <div
                  id="btn-add-user"
                  @click="$bvModal.show('bv-modal-favorite-contacts')"
                >
                  <img
                    class="rounded-circle contactAdd"
                    src="@/assets/img/icons/contactAdd.png"
                    alt=""
                  />
                  <p class="mb-0">Agregar</p>
                </div>
              </div>
            </div>
            <div
              class="cell"
              v-for="(contact, index) in contacts.businessPartners"
              :key="`bp${index}`"
            >
              <div class="onboard-slide">
                <div @click="loadAndShowDataInModal(contact.id, 'main')">
                  <img
                    class="rounded-circle"
                    :src="
                      contact.prof_picture == null
                        ? imgAvatar
                        : uriResources + '/' + contact.prof_picture
                    "
                    alt=""
                  />
                  <p class="mb-0 text-capitalize">
                    {{ contact.name.toLowerCase() }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="cell"
              v-for="(contact, index) in contacts.wellnessContacts"
              :key="`wc${index}`"
            >
              <div class="onboard-slide">
                <div @click="loadAndShowDataInModal(contact.id, 'main')">
                  <img
                    class="rounded-circle"
                    :src="
                      contact.prof_picture == null
                        ? imgAvatar
                        : uriResources + '/' + contact.prof_picture
                    "
                    alt=""
                  />
                  <p class="mb-0 text-capitalize">
                    {{ contact.name.toLowerCase() }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="cell"
              v-for="(contact, index) in contacts.favorites.slice(0, 8)"
              :key="`fav${index}`"
            >
              <div class="onboard-slide" v-if="index <= 4">
                <div @click="loadAndShowDataInModal(contact.id, 'favorite')">
                  <img
                    class="rounded-circle"
                    :src="
                      contact.core_employee.prof_picture == null
                        ? imgAvatar
                        : uriResources +
                          '/' +
                          contact.core_employee.prof_picture
                    "
                    alt=""
                  />
                  <p class="mb-0 text-capitalize">
                    {{ contact.core_employee.name.toLowerCase() }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CompModalContact
      :modal-contact="modalContact"
      :actual-type-contact="actualTypeContact"
    />
    <CompModalContacts />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "@/services/config";

import CompModalContact from "@/components/contacts/CompContactsModalContact.vue";
import CompModalContacts from "@/components/contacts/CompContactsModalContacts.vue";

export default {
  name: "CompContact",
  components: {
    CompModalContacts,
    CompModalContact,
  },
  created() {
    this.actGetContacts();
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      backgroundContacts: require("@/assets/img/contacts/background-input.png"),
      imgAvatar: require("@/assets/img/contacts/avatar-empty.png"),
      modalContact: {
        id: null,
        core_regional: {
          name: "",
        },
      },
      actualTypeContact: "main",
    };
  },
  computed: {
    ...mapState("modContacts", ["contacts"]),
    mainContactsJoined() {
      return this.contacts.businessPartners.concat(
        this.contacts.wellnessContacts
      );
    },
  },
  methods: {
    ...mapActions("modContacts", ["actGetContacts"]),
    loadAndShowDataInModal(id, type) {
      if (type === "main") {
        this.actualTypeContact = "main";
        this.modalContact = this.mainContactsJoined.find(
          (contact) => contact.id === id
        );
      } else {
        this.actualTypeContact = "favorite";
        this.modalContact = this.contacts.favorites.find(
          (contact) => contact.id === id
        );
      }
      setTimeout(() => {
        this.$bvModal.hide("bv-modal-favorite-contacts");
      }, 2);
      this.$bvModal.show("bv-modal-contact");
    },
  },
};
</script>

<style scoped>
a {
  color: #333333;
  text-decoration: underline;
}
a:hover {
  color: #333333;
}
.content-card {
  padding-top: 25px;
}
.content-card .card {
  border-radius: 8px;
}
.content-card .card .card-header {
  background-color: transparent;
}
.contactAdd {
  border: 2px solid #d32e24;
}

.carousel-contacts {
  overflow-y: hidden;
  overflow-x: scroll;
  height: 110px;
  background-attachment: local;
  cursor: pointer;
}

.cell {
  height: 100px;
}
.onboard-slide {
  width: 90px;
  padding: 0 10px;
  height: 90px;
  text-align: center;
}
.onboard-slide p {
  font-size: 12px;
  margin-top: 5px;
  background: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #171717;
}
.onboard-slide img {
  width: 65px;
  height: 65px;
  background: #ffffff;
  cursor: pointer;
  object-fit: cover;
}
.profOverflow {
  overflow: hidden;
}

.text-red {
  color: #171717;
  font-weight: normal;
}
.text-red:hover {
  color: #171717;
  font-weight: bold;
}

/**Icon */
.icon {
  color: #d82b2b;
  font-size: 26px;
  height: 20px;
  width: 20px;
  margin-right: 16px;
}

#btn-add-user {
  cursor: pointer;
}
</style>
