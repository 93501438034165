<script setup>
import { ref, onBeforeMount } from "vue";
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import { useRouter } from "vue-router/composables";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { useNavbarStore } from "@/stores/navbarStore";
import GeneralModal from "@/components/modals/GeneralModal";
import CompEcommerceQR from "@/components/ecommerce/CompEcommerceQR.vue";
import HandleModal from "@/components/modals/HandleModal.vue";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import CompOrderState from "@/components/ecommerce/CompOrderState.vue";
import CompEcommerceCouponDetail from "@/components/ecommerce/CompEcommerceCouponDetail.vue";

const router = useRouter();
const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const navbarStore = useNavbarStore();
const isQrModal = ref(false);
const qrOrder = ref({
  id: "",
  date: "",
  name: "",
});
const nameModal = ref("");
const isLoadingOrders = ref(false);
const firstOrders = ref([]);
const isFetchingQR = ref(false);

const showQrModal = (order) => {
  qrOrder.value = {
    id: order.id,
    date: formatDateDDMMYYYY(order.created_at),
    name: order.ecomm_order_details[0].ecomm_product.ecomm_product_type.name.toLowerCase(),
  };
  navbarStore.hideOrderModal();
  isQrModal.value = true;
};

const hideQrModal = () => {
  isQrModal.value = false;
};

async function downloadQr(order) {
  isFetchingQR.value = true;
  const pdf = await ecommerceStore.actGetOrderPdf(order.id);
  let a = document.createElement("a");
  a.href = "data:application/pdf;base64," + pdf;
  a.download = order.id + ".pdf";
  a.click();
  isFetchingQR.value = false;
}

const pushDataLayer = () => {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Interacciones laterales",
    event_label: "Mi pedido",
    interaction: "True",
    component_name: "Click",
  });
};

const goToShoppingHistory = () => {
  router.push({ name: "ShoppingHistory" });
};

onBeforeMount(async () => {
  isLoadingOrders.value = true;
  try {
    await ecommerceStore.actGetOrdersPending(userStore.user.id);
    firstOrders.value = ecommerceStore.ordersPending.slice(0, 3);
    nameModal.value =
      firstOrders.value[0]?.ecomm_order_state?.name || "Sin órdenes";
  } catch (err) {
    console.error(err);
  } finally {
    isLoadingOrders.value = false;
  }
});
</script>
<template>
  <div v-if="!isLoadingOrders">
    <portal to="all-modals">
      <general-modal
        :hide-button-support="true"
        v-if="isQrModal"
        modalName="qrModal"
        @close="hideQrModal"
        :isShowModal="isQrModal"
        :modalWidth="{ md: 500, lg: 600 }"
        :maxHeight="{ base: 500, md: 500, lg: 600, xl: 600 }"
      >
        <CompEcommerceQR :qr-order="qrOrder" />
      </general-modal>
    </portal>
    <handle-modal
      :max-height="{ base: 400, md: 500, lg: 600, xl: 600 }"
      :modal-width="{ md: 400, lg: 400, xl: 400 }"
      modal-name="shopping-cart"
      icon="icon-order-process"
      ref="handleModalRef"
      @open="pushDataLayer"
    >
      <div class="tw-grid tw-grid-rows-rows_3_fit_first_and_last tw-h-full">
        <div id="texts" class="tw-flex tw-flex-initial">
          <div class="tw-flex tw-flex-col tw-w-full tw-items-center">
            <p
              class="tw-font-robotoBold tw-text-xl tw-font-black tw-text-red-350 m-0 line-height-28"
            >
              Estado del pedido
            </p>
            <p
              class="tw-font-roboto tw-text-xs line-height-18 m-0"
              v-if="userStore.user.ecomm_order_request === false"
            >
              No has realizado ningún pedido
            </p>
            <p
              class="tw-font-roboto tw-text-xs line-height-18 m-0"
              v-else-if="
                userStore.user.ecomm_order_request === true &&
                ecommerceStore.ordersPending.length === 0
              "
            >
              No tienes ningún pedido pendiente
            </p>
            <p
              v-else
              class="tw-font-roboto tw-text-xs line-height-18 m-0 tw-capitalize"
            >
              {{ nameModal }}
            </p>
          </div>
        </div>
        <div>
          <div id="images" v-if="ecommerceStore.ordersPending.length === 0">
            <div
              class="tw-flex tw-justify-center tw-items-center tw-min-h-0 tw-h-full"
              v-if="ecommerceStore.ordersPending.length === 0"
            >
              <img
                v-if="userStore.user.ecomm_order_request === false"
                src="@/assets/img/order-status/order-never-order.svg"
                class="tw-max-w-full tw-max-h-60 lg:tw-max-h-64 tw-object-contain"
                alt=""
              />
              <img
                v-else-if="
                  userStore.user.ecomm_order_request === true &&
                  ecommerceStore.ordersPending.length === 0
                "
                src="@/assets/img/order-status/order-no-pending-order.svg"
                class="tw-max-w-full tw-max-h-60 lg:tw-max-h-64 tw-object-contain"
                alt=""
              />
            </div>
          </div>
          <div v-if="ecommerceStore.ordersPending.length > 0">
            <comp-accordion-parent open-first always-open-one>
              <comp-accordion-item
                v-for="(order, idx) in firstOrders"
                :id="'order_' + idx"
                :key="idx"
                division
              >
                <template #mainInfo>
                  <div
                    class="tw-flex tw-justify-between tw-items-center tw-w-full tw-cursor-pointer"
                  >
                    <div
                      class="tw-flex tw-items-center"
                      :class="order.ecomm_order_change_state == null ? '' : ''"
                    >
                      <i
                        v-if="order.ecomm_order_change_state == null"
                        class="icon-circle tw-text-xxxs tw-leading-none tw-text-red-350 tw-mr-2"
                      ></i>
                      <p
                        class="tw-font-robotoBold tw-text-xxs line-height-12 tw-gray-600 m-0"
                      >
                        {{ formatDateDDMMYYYY(order.created_at) }}
                      </p>
                    </div>
                    <div class="tw-flex tw-items-center">
                      <p
                        class="tw-font-roboto tw-text-xxs line-height-10 tw-gray-600 m-0"
                      >
                        {{
                          order.type_order === "TADA_CODE"
                            ? "Cupón TaDa"
                            : "No. " + order.id
                        }}
                      </p>
                      <i
                        class="icono-flechas tw-text-xl tw-leading-none tw-text-red-350 tw-ml-2"
                      ></i>
                    </div>
                  </div>
                </template>
                <template #secondaryInfo>
                  <div
                    v-if="order.type_order === 'ECOMM_PRODUCT'"
                    class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-relative"
                  >
                    <img
                      v-if="order.ecomm_order_state?.id === 1"
                      src="@/assets/img/order-status/order-made.svg"
                      alt=""
                      class="tw-object-contain tw-py-1"
                    />
                    <img
                      v-if="order.ecomm_order_state?.id === 2"
                      src="@/assets/img/order-status/order-in-process.svg"
                      alt=""
                      class="tw-object-contain tw-py-1"
                    />
                    <img
                      v-if="order.ecomm_order_state?.id === 3"
                      src="@/assets/img/order-status/order-pick.svg"
                      alt=""
                      class="tw-object-contain tw-py-1 tw-max-h-36"
                    />
                    <img
                      v-if="order.ecomm_order_state?.id === 4"
                      src="@/assets/img/order-status/order-delivered.svg"
                      alt=""
                      class="tw-object-contain tw-py-1"
                    />
                    <button
                      v-if="order.ecomm_order_state?.id === 3"
                      @click="showQrModal(order)"
                      class="tw-btn-secondary-red tw-mb-2 lg:tw-mb-3 tw-block md:tw-hidden"
                      name="btn-prod-mes"
                    >
                      <span class="tw-m-0">Ver código QR</span>
                    </button>
                    <button
                      v-if="order.ecomm_order_state?.id === 3"
                      :disabled="isFetchingQR"
                      @click="downloadQr(order)"
                      class="tw-btn-secondary-red tw-hidden md:tw-block tw-mb-2 lg:tw-mb-3"
                      :class="isFetchingQR ? 'tw-opacity-50' : 'tw-opacity-100'"
                      name="btn-prod-mes"
                    >
                      <span class="tw-m-0" v-if="!isFetchingQR"
                        >Descargar código QR</span
                      >
                      <span
                        v-else
                        class="tw-flex tw-items-center tw-justify-center tw-gap-2"
                      >
                        <i
                          class="icon-spinner tw-animate-spin tw-text-white tw-text-xl"
                        ></i>
                        <span class="tw-m-0">Descargando QR...</span>
                      </span>
                    </button>
                  </div>
                  <comp-order-state
                    v-if="order.type_order === 'ECOMM_PRODUCT'"
                    :active-order-state="order?.ecomm_order_state?.id"
                  />
                  <comp-ecommerce-coupon-detail
                    class="tw-mt-2"
                    minimal
                    :order="order"
                    v-if="order.type_order === 'TADA_CODE'"
                  />
                </template>
              </comp-accordion-item>
            </comp-accordion-parent>
          </div>
        </div>
        <div id="buttons">
          <div class="tw-h-full">
            <button
              v-if="ecommerceStore.ordersPending.length === 0"
              @click="$router.push({ name: 'ECommerce' })"
              class="tw-btn-primary-red tw-block tw-mb-2 lg:tw-mb-3"
              name="btn-prod-mes"
            >
              Ir a tienda
            </button>
            <button
              v-if="userStore.user.ecomm_order_request === true"
              @click="goToShoppingHistory()"
              class="tw-btn-primary-white tw-mt-0 tw-block"
              name="btn-prod-mes"
            >
              Ver pedidos anteriores
            </button>
          </div>
        </div>
      </div>
    </handle-modal>
  </div>
</template>
