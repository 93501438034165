<script setup>
import { computed, ref, watch } from "vue";
import CompStar from "@/components/rating/CompStar";
import { useRatingStore } from "@/stores/ratingStore";

const ratingStore = useRatingStore();
const voteStar = ref(0);
const averageStar = ref("0.0");
const userRating = ref({
  acm: 0,
  n: 0,
  score: 0,
  me: 0,
});
const isLoading = ref(false);

const emit = defineEmits(["childToParent"]);

const props = defineProps({
  idContent: {
    type: Number,
    default: 0,
  },
  category: {
    type: String,
    default: "",
  },
  showStars: {
    type: Boolean,
    default: true,
  },
  ratingData: {
    type: Object,
  },
  agreement: {
    type: Object,
  },
  agreement_name: {
    type: Object,
  },
});

const canVote = computed(() => {
  if (userRating.value === undefined) {
    return true;
  } else return !userRating.value.me;
});

watch(
  () => userRating.value,
  (newVal) => {
    if (newVal) {
      averageStar.value = newVal.score.toString().slice(0, 3);
    } else {
      averageStar.value = "0.0";
    }
  }
);

async function voteRating() {
  isLoading.value = true;
  let response = await ratingStore.actPostRating({
    category: props.category,
    reviewable_id: props.idContent,
    score: voteStar.value,
  });

  if (response) {
    userRating.value.me = voteStar.value;
    userRating.value.score = response;
    averageStar.value = response.toString().slice(0, 3);
    emit("childToParent", voteStar.value);
    isLoading.value = false;
  }
}

watch(
  () => props.ratingData,
  (data) => {
    userRating.value = data;
  }
);
</script>
<template>
  <div class="mb-3" :id="'stars-' + props.idContent">
    <template v-if="isLoading">
      <div class="text-center">
        <img src="../../assets/img/icons/loading.gif" class="tw-w-8" alt="loading" />
      </div>
    </template>
    <template v-else>
      <template v-if="canVote">
        <div v-if="showStars">
          <div class="tw-w-full tw-flex tw-items-center">
            <div
              class="rate tw-float-left tw-relative tw-ml-0 tw-mr-0 tw-flex tw-flex-row-reverse tw-items-center"
              style="height: 36px"
            >
              <input
                type="radio"
                :id="'star5' + props.idContent"
                name="rate"
                v-model="voteStar"
                value="5"
                v-on:change="voteRating()"
              />
              <label :for="'star5' + props.idContent"
                ><i class="icon-star"></i
              ></label>
              <input
                type="radio"
                :id="'star4' + props.idContent"
                name="rate"
                v-model="voteStar"
                value="4"
                v-on:change="voteRating()"
              />
              <label :for="'star4' + props.idContent"
                ><i class="icon-star"></i
              ></label>
              <input
                type="radio"
                :id="'star3' + props.idContent"
                name="rate"
                v-model="voteStar"
                value="3"
                v-on:change="voteRating()"
              />
              <label :for="'star3' + props.idContent"
                ><i class="icon-star"></i
              ></label>
              <input
                type="radio"
                :id="'star2' + props.idContent"
                name="rate"
                v-model="voteStar"
                value="2"
                v-on:change="voteRating()"
              />
              <label :for="'star2' + props.idContent"
                ><i class="icon-star"></i
              ></label>
              <input
                type="radio"
                :id="'star1' + props.idContent"
                name="rate"
                v-model="voteStar"
                value="1"
                v-on:change="voteRating()"
              />
              <label :for="'star1' + props.idContent"
                ><i class="icon-star"></i
              ></label>
            </div>
            <div class="rate-number tw-flex tw-items-center">
              <p class="tw-mb-0 tw-ml-2 tw-font-roboto tw-text-lg">0/5</p>
            </div>
          </div>
          <p class="tw-font-roboto tw-text-sm tw-text-gray-400">
            Califica este contenido
          </p>
        </div>
        <div class="tw-w-full" v-else>
          <CompStar :state="false" :stars="averageStar" />
        </div>
      </template>
      <template v-else>
        <CompStar :state="true" :stars="averageStar" />
      </template>
    </template>
  </div>
</template>
<style lang="css" scoped>
.rate:not(:checked) > input {
  position: absolute;
  left: -9999px;
}

.rate:not(:checked) > label {
  float: left;
  width: 25px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 22px;
  color: #ccc;
  position: relative;
  margin-bottom: 0;
}

.rate > input:checked ~ label {
  color: #ffc141;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #ffc141;
}
/*
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #ffc141;
} */
</style>
