<script setup>
import useHover from "@/composables/general/useHover";

const { isHovering, mouseEnter, mouseLeave } = useHover();
</script>

<template>
  <section id="card" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-1 tw-gap-2 lg:tw-gap-3">
      <slot name="image" />
      <div class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
        <slot name="category" />
        <slot name="titleAndDescription" />
        <slot name="callToAction" :hover="isHovering" />
      </div>
    </div>
  </section>
</template>

<style scoped></style>
