import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";
import config from "@/services/config";

const uri = {
  contentDocumentsUri: "/contentDocuments",
  contextualHelpersUri: "/contextualSupports",
  suggestionsMailboxUri: "/suggestions",
  suggestionsCategoriesUri: "/suggestionCategories",
  advertisingUri: "/contentPublicities",
  configurationsUri: "/configurations",
};

export const useCoreStore = defineStore("core", () => {
  const legalDocs = ref([]);
  const contextualHelpers = ref([]);
  const suggestionsCategories = ref([]);
  const advertisingData = ref({});
  const configurations = ref([]);
  const showNoAccessModal = ref(false);

  async function actGetConfigurations() {
    const response = await AxiosBeerOffice.get(uri.configurationsUri, true, {});
    if (response.error) return response.error;
    configurations.value = response.data;
    return true;
  }

  async function actGetContentDocuments() {
    const response = await AxiosBeerOffice.get(
      uri.contentDocumentsUri,
      false,
      {}
    );
    if (response.error) return response.error;
    legalDocs.value = response.data;
    return true;
  }

  function getURLResourceByKey(key) {
    const resource = configurations.value.find((resource) => {
      return resource?.key === key;
    });
    return resource
      ? `${config.beerOfficeURL}/storage/documents/${resource?.filename}`
      : null;
  }

  async function actGetContextualHelpers(route) {
    let response = await AxiosBeerOffice.get(
      `${uri.contextualHelpersUri}?route=${route}`,
      true
    );
    if (response.error) {
      return response.error;
    }
    contextualHelpers.value = response.data;
    return response.data;
  }

  async function actPostSeenContextualHelper(route) {
    let response = await AxiosBeerOffice.post(
      `${uri.contextualHelpersUri}/seeRoute`,
      true,
      { route: route }
    );
    if (response.error) {
      return response.error;
    }
    return response;
  }

  async function actGetSeenContextualHelper(route) {
    let response = await AxiosBeerOffice.get(
      `${uri.contextualHelpersUri}/hasSeenRoute?route=${route}`,
      true
    );
    if (response.error) {
      return response.error;
    }
    return response.data;
  }

  async function actGetSuggestionsCategories() {
    let response = await AxiosBeerOffice.get(
      uri.suggestionsCategoriesUri,
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    suggestionsCategories.value = response.data.categories;
    return response.data.categories;
  }

  async function actPostSuggestions(data) {
    let response = await AxiosBeerOffice.post(
      uri.suggestionsMailboxUri,
      true,
      data
    );
    if (response.error) {
      return response.error;
    }
    return response;
  }

  async function actGetActiveAdvertising(route) {
    let response = await AxiosBeerOffice.get(
      `${uri.advertisingUri}/active?route=${route}`,
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    const { data } = response;
    advertisingData.value = data;
    return true;
  }

  async function actPostSeenAdvertising(data) {
    return AxiosBeerOffice.post(`${uri.advertisingUri}/see`, true, data);
  }

  return {
    advertisingData,
    legalDocs,
    contextualHelpers,
    suggestionsCategories,
    configurations,
    showNoAccessModal,
    actGetConfigurations,
    actGetContentDocuments,
    getURLResourceByKey,
    actGetContextualHelpers,
    actPostSeenContextualHelper,
    actGetSeenContextualHelper,
    actGetSuggestionsCategories,
    actPostSuggestions,
    actGetActiveAdvertising,
    actPostSeenAdvertising,
  };
});
