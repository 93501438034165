<template>
  <div>
    <div class="row ml-n2 m-md-auto">
      <div class="col-10">
        <p class="m-0 tw-font-robotoBold fs-24 line-height-md-30">
          Simula tu Bono aquí
        </p>
      </div>
      <div v-b-modal.modal-sti-nonadministrative class="col-2">
        <i class="icon-mas-informacion fs-24 tw-text-red-400"></i>
      </div>
      <b-modal
        class="showme"
        :id="'modal-sti-nonadministrative'"
        centered
        hide-footer
        :modal-title="false"
      >
        <p class="modal-title">Ten en cuenta</p>
        <p class="disclaimer pt-2">
          * El simulador es una herramienta para calcular valores estimados del
          bono. El valor a pagar es el que esté acorde a las políticas de la
          compañía la cual se reserva el derecho a modificarlas.
        </p>
      </b-modal>
      <div
        class="col-12 pt-2 tw-font-roboto tw-text-sm line-height-18 tw-text-gray-600"
      >
        Para simular el valor de tu bono STI introduce los valores
        correspondientes en cada campo.
      </div>
      <div class="col-12 pt-2">
        <form>
          <small>Bonus Target - Valor: {{ bonusProps.plantRank }} días</small>
          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-percentage text-white tw-text-xl"></i>
              </div>
            </div>
            <select
              class="form-control tw-rounded-lg"
              id="company-ranking"
              v-model="bonusProps.plantRank"
            >
              <slot>
                <option value="0" selected>Selecciona tu planta</option>
                <option value="24" selected>Planta 1</option>
                <option value="19" selected>Planta 2</option>
                <option value="16" selected>Planta 3</option>
                <option value="14" selected>Planta 4</option>
                <option value="10" selected>Planta 5</option>
              </slot>
            </select>
          </div>

          <small
            >Dedicación de tiempo - Valor:
            {{ (bonusProps.tdrTime * 100).toFixed(0) }} %</small
          >
          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-anualsal text-white tw-text-xl"></i>
              </div>
            </div>
            <select
              class="form-control tw-rounded-lg"
              id="tdr-value"
              v-model="bonusProps.tdrTime"
            >
              <option value="0" selected>Tiempo laborado</option>
              <option value="0.5">6 meses</option>
              <option value="0.58">7 meses</option>
              <option value="0.66">8 meses</option>
              <option value="0.75">9 meses</option>
              <option value="0.83">10 meses</option>
              <option value="0.91">11 meses</option>
              <option value="1" selected>12 meses</option>
            </select>
          </div>
          <small
            v-if="
              employeeCorePosition === 'VII-VIII' ||
              employeeCorePosition === 'VIII+'
            "
            >Salario anual</small
          >
          <div class="input-group mb-2 mr-sm-2 pt-1">
            <div class="input-group-prepend">
              <div class="input-group-text tw-bg-gray-320 tw-rounded-lg">
                <i class="icon-beneficios-dinero text-white tw-text-xl"></i>
              </div>
            </div>
            <input
              type="text"
              class="form-control text-right tw-rounded-lg"
              id="anual-salary"
              :value="
                employeeCorePosition === 'VII-VIII'
                  ? formatCurrency(
                      Number(this.user.anual_salary) +
                        Number(this.user.legal_premium) +
                        Number(this.user.extra_legal_premium)
                    )
                  : formatCurrency(user.total_salary * 13)
              "
              readonly
            />
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pt-2">
        <div
          class="calculator-total-value text-center text-light d-flex justify-content-between align-items-center tw-border-b tw-border-gray-300"
        >
          <span class="pr-2 tw-font-robotoBold tw-text-base tw-text-gray-600"
            >Valor del bono STI</span
          >
          <span class="tw-font-roboto fs-24 line-height-32 tw-text-green-500">
            {{ formatCurrency(calculateBonus) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import numbersMixin from "@/mixins/general/numbers";

export default {
  name: "CalculatorNonAdministrative",
  mixins: [numbersMixin],
  data() {
    return {
      bonusProps: {
        tdrTime: 0,
        plantRank: 0,
      },
    };
  },
  props: {
    employeeCorePosition: {
      type: String,
    },
  },
  computed: {
    ...mapState("modCore", ["user"]),
    calculateBonus() {
      return (
        ((this.bonusProps.tdrTime * this.bonusProps.plantRank) / 360) *
        this.salaryCalculate
      );
    },
    salaryCalculate() {
      return this.employeeCorePosition === "VII-VIII"
        ? Number(this.user.anual_salary) +
            Number(this.user.legal_premium) +
            Number(this.user.extra_legal_premium)
        : Number(this.user.total_salary) * 13;
    },
  },
};
</script>
<style scoped>
/*
.value-description {
  color: #000000;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
}
*/

.modal-title {
  color: #d82b2b;
  font: bold 16px/19px Roboto;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 1;
  margin-bottom: 10px;
}

.disclaimer {
  text-align: left;
  font: normal normal normal 12px/13px Roboto;
  letter-spacing: 0;
  color: #343434 !important;
  opacity: 1;
}
</style>
