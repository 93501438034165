<template>
  <div class="tw-bg-white tw-rounded-lg tw-shadow-md p-3 mb-4" v-if="followData">
    <div class="d-flex justify-content-between mb-2">
      <p class="mb-0 tw-text-gray-600 tw-text-lg font-weight-bold">
        Seguimiento Diario
      </p>
      <div class="d-flex flex-column align-items-end">
        <p class="tw-text-gray-300  tw-text-xs  mb-1">Fecha de corte:</p>
        <p
          class="tw-text-gray-400  tw-text-xs "
          v-if="followData.donutChartInfo && followData.donutChartInfo.date"
        >
          {{ followData.donutChartInfo.date }}
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex" v-if="followData.sections[0]">
        <div class="mr-2">
          <p class="tw-text-red-400  tw-text-xxs  mb-1">
            {{ followData.sections[0].label }}
          </p>
          <p class="tw-text-gray-600  tw-text-xs  mb-1 font-weight-bold">
            $ {{ formatNumber(followData.sections[0].value, 0) }}
          </p>
        </div>
        <div class="tw-bg-red-400 p-2 text-center tw-rounded-md">
          <p class="mb-0 tw-text-sm tw-text-white">
            {{ followData.sections[0].valuePercent }}%
          </p>
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <p class="tw-text-brown-400  tw-text-xxs  mb-1">
            {{ followData.sections[1].label }}
          </p>
          <p class="tw-text-brown-600  tw-text-xs  mb-1 font-weight-bold">
            $ {{ formatNumber(followData.sections[1].value, 0) }}
          </p>
        </div>
        <div class="tw-bg-green-400 p-2 text-center tw-rounded-md">
          <p class="mb-0 tw-text-sm tw-text-white">
            {{ followData.sections[1].valuePercent }}%
          </p>
        </div>
      </div>
    </div>
    <b-progress max="100" class="mt-3">
      <b-progress-bar
        :value="followData.donutChartInfo.proyectPaymentPercent"
        variant="success"
      ></b-progress-bar>
    </b-progress>
    <div class="d-flex justify-content-between mt-4">
      <p class="mb-0 tw-text-gray-600 tw-text-base font-weight-bold">Tu salario base</p>
      <p class="mb-0 tw-text-green-400 tw-text-base font-weight-bold">
        ${{ formatNumber(followData.donutChartInfo.salary_base) }}
      </p>
    </div>
  </div>
</template>

<script>
import numbersMixin from "@/mixins/general/numbers";

export default {
  name: "CompFollow",
  props: {
    followData: {
      required: true,
    },
  },
  mixins: [numbersMixin],
};
</script>
