<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";

const tooltip = ref(false);
const referenceRef = ref(null);
const popperRef = ref(null);

const props = defineProps({
  tooltipText: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "black",
  },
});

const showTooltip = () => {
  tooltip.value = true;
  calculateTooltipPosition();
};

const hideTooltip = () => {
  tooltip.value = false;
};

const calculateTooltipPosition = () => {
  if (!tooltip.value) {
    return;
  }

  requestAnimationFrame(() => {
    const referenceRect = referenceRef.value.getBoundingClientRect();
    const popperElement = popperRef.value;

    const positions = [
      "tooltip-to-left",
      "tooltip-to-right",
      "tooltip-centered",
      "tooltip-to-top",
    ];

    // Remover todas las clases de posición existentes
    popperElement.classList.remove(...positions);

    // Lógica para determinar y agregar la clase de posición correspondiente
    if (referenceRect.right + popperElement.offsetWidth > window.innerWidth) {
      popperElement.classList.add("tooltip-to-left");
    } else if (referenceRect.left - popperElement.offsetWidth < 0) {
      popperElement.classList.add("tooltip-to-right");
    } else {
      popperElement.classList.add("tooltip-centered");
    }

    if (
      referenceRect.bottom + popperElement.offsetHeight >
      window.innerHeight
    ) {
      popperElement.classList.add("tooltip-to-top");
    } else {
      popperElement.classList.add("tooltip-to-bottom");
    }
  });
};

const computedColor = computed(() => {
  return props.color;
});

onMounted(() => {
  window.addEventListener("resize", calculateTooltipPosition);
});

onUnmounted(() => {
  window.removeEventListener("resize", calculateTooltipPosition);
});
</script>

<template>
  <div class="tooltip-box">
    <i
      class="icon-mas-informacion"
      ref="referenceRef"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
    />
    <div ref="popperRef" class="tooltip-content" v-if="tooltip">
      <span class="tooltip-triangle" />
      <p class="tooltip-text tw-m-0 tw-text-xs tw-font-roboto">
        {{ props.tooltipText }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.tooltip-box {
  position: relative;
}

.tooltip-content {
  position: absolute;
  width: 200px;
  background: #fff;
  color: #000;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  border: 1px solid v-bind(computedColor);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.tooltip-to-left {
  right: -100%;
}

.tooltip-to-right {
  left: -100%;
}

.tooltip-centered {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-to-top {
  bottom: calc(100% + 10px);
}

.tooltip-to-bottom {
  top: calc(100% + 10px);
}

.tooltip-triangle {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip-to-left.tooltip-to-top .tooltip-triangle {
  border-width: 10px 10px 10px 10px;
  border-color: v-bind(computedColor) transparent transparent transparent;
  right: 10px;
  top: 100%;
}

.tooltip-to-left.tooltip-to-bottom .tooltip-triangle {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent v-bind(computedColor) transparent;
  right: 10px;
  top: -10px;
}

.tooltip-to-right.tooltip-to-top .tooltip-triangle {
  border-width: 10px 10px 10px 10px;
  border-color: v-bind(computedColor) transparent transparent transparent;
  left: 10px;
  top: 100%;
}

.tooltip-to-right.tooltip-to-bottom .tooltip-triangle {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent v-bind(computedColor) transparent;
  left: 10px;
  top: -10px;
}

.tooltip-centered.tooltip-to-top .tooltip-triangle {
  border-width: 10px 10px 10px 10px;
  border-color: v-bind(computedColor) transparent transparent transparent;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-centered.tooltip-to-bottom .tooltip-triangle {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent v-bind(computedColor) transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
