<template>
  <div class="tw-overflow-x-scroll tw-overflow-y-auto invisible-scrollbar">
    <div
      class="tw-grid tw-grid-flow-col tw-gap-2 tw-w-max"
      id="scroll-container"
    >
      <div
        class="tw-rounded-full tw-w-max tw-px-3 md:tw-px-5 tw-py-2 tw-cursor-pointer"
        :class="
          filter === ''
            ? 'tw-bg-red-350 tw-text-white hover:tw-bg-red-350 hover:tw-text-white'
            : 'tw-bg-pink-100 hover:tw-bg-pink-200'
        "
        @click="changeFilter('')"
      >
        <p
          class="tw-m-0 tw-p-0 tw-font-roboto tw-text-xs md:tw-text-base md:tw-leading-5"
        >
          Todas
        </p>
      </div>
      <div
        class="tw-rounded-full tw-w-max tw-px-3 md:tw-px-5 tw-py-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
        :class="
          filter === category
            ? 'tw-bg-red-350 tw-text-white hover:tw-bg-red-350 hover:tw-text-white'
            : 'tw-bg-pink-100 hover:tw-bg-pink-200'
        "
        v-for="(category, idx) in categories"
        :key="idx"
        @click="changeFilter(category)"
      >
        <p
          class="tw-m-0 tw-p-0 tw-font-roboto tw-text-xs md:tw-text-base md:tw-leading-5"
        >
          {{ category.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectCategoriesButtons",
  data() {
    return {
      filter: "",
    };
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changeFilter(filter) {
      this.filter = filter;
      this.$emit("changeFilter", filter);
    },
  },
};
</script>
