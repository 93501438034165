<script setup>
import config from "@/services/config";
import ArticleStats from "@/components/articles/CompArticlesStats.vue";
import { pushLink } from "@/helpers/linkHelpers";
import { formatDateMMMDD } from "@/helpers/datesHelpers";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
  change: {
    type: Boolean,
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
});

const goToArticle = (article, label) => {
  let dataLayer = {
    event: "GAEvent",
    event_category: "Artículos",
    event_action: props.category,
    event_label: label,
    interaction: "True",
    component_name: "Click",
    element_category: props.article.article_category.name,
    element_text: props.article.title,
    element_date: formatDateMMMDD(props.article.created_at),
    element_time: `${props.article.reading_time} min`,
    element_view: props.article.views,
  };
  pushLink(`/articles/${article.id}`, dataLayer);
};
</script>
<template>
  <div class="lg:tw-h-full">
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-2 lg:tw-h-full lg:tw-grid-rows-articleCard"
    >
      <div
        class="md:tw-col-span-12 tw-flex"
        :class="props.change === true ? 'tw-col-span-12' : 'tw-col-span-5'"
      >
        <div
          @click="goToArticle(props.article, 'Imagen')"
          class="tw-flex-auto tw-cursor-pointer tw-h-full tw-aspect-w-5 tw-aspect-h-5 md:tw-aspect-w-16 md:tw-aspect-h-9"
        >
          <img
            :src="uriResources + '/' + props.article?.resource"
            loading="lazy"
            alt="image"
            class="tw-rounded-2xl tw-object-cover tw-w-full tw-h-full"
          />
        </div>
      </div>
      <div
        class="md:tw-col-span-12"
        :class="props.change === true ? 'tw-col-span-12' : 'tw-col-span-7'"
      >
        <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
          <div class="tw-flex tw-flex-col tw-flex-initial">
            <div class="tw-flex tw-justify-between tw-items-center">
              <p
                class="tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-5 tw-underline tw-my-1"
              >
                {{ props.article?.article_category?.name }}
              </p>
              <p
                v-if="props.article?.new === 1"
                class="tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-red-350 tw-px-4 tw-bg-pink-100 tw-rounded-xl tw-mb-0"
              >
                ¡Nuevo!
              </p>
            </div>
            <p
              class="tw-font-robotoBold tw-text-base tw-leading-5 lg:tw-text-2xl lg:tw-leading-7 md:tw-my-2"
              :class="props.change === true ? 'tw-my-2' : 'tw-mb-0'"
            >
              {{ props.article?.title }}
            </p>
          </div>
          <div class="tw-flex-auto">
            <p
              class="tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-4 lg:tw-leading-5 tw-line-clamp-3 md:tw-line-clamp-2 md:tw-my-2 tw-h-12 md:tw-h-10"
              :class="change === true ? 'tw-mb-2' : 'tw-mb-0'"
            >
              {{ props.article?.description }}
            </p>
          </div>
          <div
            class="tw-flex md:tw-flex-row md:tw-items-center tw-justify-end md:tw-justify-between"
            :class="
              change === true
                ? 'tw-flex-row-reverse tw-items-center tw-mt-2 md:tw-mt-3'
                : 'tw-flex-col tw-mt-3'
            "
          >
            <ArticleStats class="" :article="props.article" />
            <div
              class="tw-flex tw-items-center tw-cursor-pointer"
              :class="props.change === true ? '' : 'tw-mt-2 md:tw-mt-0'"
              @click="goToArticle(props.article, 'Saber más')"
            >
              <p
                class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
              >
                Saber más
              </p>
              <i
                class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-xl"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
