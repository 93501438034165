<template>
  <div class="tw-container-general">
    <PsiBreadcrumbButtons />
    <div class="row tw-bg-white tw-shadow-sm tw-rounded-lg pb-4">
      <div class="col-12">
        <p
          class="text-uppercase tw-text-gray-600 text-center font-weight-bold my-3 tw-text-xl"
        >
          {{ tableTitle }}
        </p>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-md-0">
            <p
              class="text-uppercase tw-text-gray-600 font-weight-bold tw-text-sm"
            >
              VICEPRESIDENCIA
            </p>
            <select
              class="w-100 tw-text-gray-400 tw-text-sm py-2 px-1 border-0 tw-shadow-md tw-rounded-md"
              v-model="vice0"
              @change="onChangeVice0($event)"
            >
              <option value="0">Seleccione</option>
              <option
                v-for="vice in this.filterVices"
                :key="'vice0-' + vice.id"
                :value="vice.id"
              >
                {{ vice.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-md-0">
            <p
              class="text-uppercase tw-text-gray-600 font-weight-bold tw-text-sm"
            >
              SCOPE
            </p>
            <select
              class="w-100 tw-text-gray-400 tw-text-sm py-2 px-1 border-0 tw-shadow-md tw-rounded-md"
              v-model="scope0"
              @change="onChangeScope0($event)"
            >
              <option value="0">Seleccione</option>
              <option
                v-for="scope in this.scopesByVice0"
                :key="'scope0-' + scope.id"
                :value="scope.id"
              >
                {{ scope.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row pb-4">
          <div class="col-12 col-lg-6 mb-3 mb-md-0">
            <p
              class="text-uppercase tw-text-gray-600 font-weight-bold tw-text-sm"
            >
              VICEPRESIDENCIA
            </p>
            <select
              class="w-100 tw-text-gray-400 tw-text-sm py-2 px-1 border-0 tw-shadow-md tw-rounded-md"
              v-model="vice1"
              @change="onChangeVice1($event)"
            >
              <option value="0">Seleccione</option>
              <option
                v-for="vice in this.filterVices"
                :key="'vice1-' + vice.id"
                :value="vice.id"
              >
                {{ vice.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-md-0">
            <p
              class="text-uppercase tw-text-gray-600 font-weight-bold tw-text-sm"
            >
              SCOPE
            </p>
            <select
              class="w-100 tw-text-gray-400 tw-text-sm py-2 px-1 border-0 tw-shadow-md tw-rounded-md"
              v-model="scope1"
              @change="onChangeScope1($event)"
            >
              <option value="0">Seleccione</option>
              <option
                v-for="scope in this.scopesByVice1"
                :key="'scope1-' + scope.id"
                :value="scope.id"
              >
                {{ scope.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 px-md-4">
        <div class="row tw-bg-gray-200 tw-shadow-md tw-rounded-md py-4">
          <div class="col-6">
            <p class="tw-text-sm fs-md-20 tw-text-gray-600 font-weight-bold">
              {{ dataCompare[0].name }}
            </p>
            <div class="tw-bg-white p-3 tw-rounded-lg tw-shadow-sm">
              <div class="row">
                <div class="col-12 col-lg-4 mb-3 mb-md-0">
                  <div
                    class="p-3 tw-rounded-lg"
                    :class="
                      Object.values(dataCompare[0].indicator_month_color).pop()
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      {{ getMonthNameBy0(month) }}
                    </p>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        Object.values(
                          dataCompare[0].indicator_month_color
                        ).pop()
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataCompare[0].indicator_month[month] }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-md-0">
                  <div
                    class="p-3 tw-rounded-lg"
                    :class="
                      dataCompare[0].year_to_date_color
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      Year TD
                    </p>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        dataCompare[0].year_to_date_color
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataCompare[0].year_to_date }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-md-0">
                  <div
                    class="p-3 tw-rounded-lg"
                    :class="
                      dataCompare[0].le_color
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      LE
                    </p>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        dataCompare[0].le_color
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataCompare[0].le }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 py-2 py-md-3"></div>
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div
                    class="tw-bg-gray-200 tw-rounded-lg px-3 py-3 py-md-4 d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="mb-0 text-uppercase tw-text-xxs fs-md-12 tw-text-gray-400"
                    >
                      Last Year
                    </p>
                    <p
                      class="mb-0 tw-text-xs fs-md-18 tw-text-gray-400 font-weight-bold"
                    >
                      {{
                        dataCompare[0].last_year == null
                          ? "- "
                          : dataCompare[0].last_year
                      }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div
                    class="tw-bg-gray-200 tw-rounded-lg px-3 py-3 py-md-4 d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="mb-0 text-uppercase tw-text-xxs fs-md-12 tw-text-gray-400"
                    >
                      Budget
                    </p>
                    <p
                      class="mb-0 tw-text-xs fs-md-18 tw-text-gray-400 font-weight-bold"
                    >
                      {{
                        dataCompare[0].budget == null
                          ? "- "
                          : dataCompare[0].budget
                      }}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <p class="tw-text-sm fs-md-20 tw-text-gray-600 font-weight-bold">
              {{ dataCompare[1].name }}
            </p>
            <div class="tw-bg-white p-3 tw-rounded-lg tw-shadow-sm">
              <div class="row">
                <div class="col-12 col-lg-4 mb-3 mb-md-0">
                  <div
                    class="p-3 tw-rounded-lg"
                    :class="
                      Object.values(dataCompare[1].indicator_month_color).pop()
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      {{ getMonthNameBy0(month) }}
                    </p>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        Object.values(
                          dataCompare[1].indicator_month_color
                        ).pop()
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataCompare[1].indicator_month[month] }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-md-0">
                  <div
                    class="p-3 tw-rounded-lg"
                    :class="
                      dataCompare[1].year_to_date_color
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      Year TD
                    </p>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        dataCompare[1].year_to_date_color
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataCompare[1].year_to_date }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-md-0">
                  <div
                    class="p-3 tw-rounded-lg"
                    :class="
                      dataCompare[1].le_color
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      LE
                    </p>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        dataCompare[1].le_color
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataCompare[1].le }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 py-2 py-md-3"></div>
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div
                    class="tw-bg-gray-200 tw-rounded-lg px-3 py-3 py-md-4 d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="mb-0 text-uppercase tw-text-xxs fs-md-12 tw-text-gray-400"
                    >
                      Last Year
                    </p>
                    <p
                      class="mb-0 tw-text-xs fs-md-18 tw-text-gray-400 font-weight-bold"
                    >
                      {{
                        dataCompare[1].last_year == null
                          ? "- "
                          : dataCompare[1].last_year
                      }}%
                    </p>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div
                    class="tw-bg-gray-200 tw-rounded-lg px-3 py-3 py-md-4 d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="mb-0 text-uppercase tw-text-xxs fs-md-12 tw-text-gray-400"
                    >
                      Budget
                    </p>
                    <p
                      class="mb-0 tw-text-xs fs-md-18 tw-text-gray-400 font-weight-bold"
                    >
                      {{
                        dataCompare[1].budget == null
                          ? "- "
                          : dataCompare[1].budget
                      }}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 py-3 py-md-4"></div>
          <div class="col-6">
            <div class="row">
              <div
                class="col-6 col-lg-3 mb-3"
                v-for="(month, index) in dataCompare[0].indicator_month"
                :key="'month0-' + index"
              >
                <p
                  class="text-center tw-text-xs fs-md-14 tw-text-gray-400 mb-2"
                >
                  {{ getMonthNameBy0(index) }}
                </p>
                <div
                  class="bg-white px-1 px-md-3 py-2 tw-rounded-md text-center"
                  :class="
                    dataCompare[0].indicator_month_color[index]
                      ? 'tw-border-l-10 tw-border-solid tw-border-green-400 '
                      : 'tw-border-l-10 tw-border-solid tw-border-red-400 '
                  "
                >
                  <p
                    class="mb-0 tw-text-xxs fs-md-16"
                    :class="
                      dataCompare[0].indicator_month_color[index]
                        ? 'tw-text-green-400'
                        : 'tw-text-red-400'
                    "
                  >
                    {{ month }}%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div
                class="col-6 col-lg-3 mb-3"
                v-for="(month, index) in dataCompare[1].indicator_month"
                :key="'month1-' + index"
              >
                <p
                  class="text-center tw-text-xs fs-md-14 tw-text-gray-400 mb-2"
                >
                  {{ getMonthNameBy0(index) }}
                </p>
                <div
                  class="bg-white px-1 px-md-3 py-2 tw-rounded-md text-center"
                  :class="
                    dataCompare[1].indicator_month_color[index]
                      ? 'tw-border-l-10 tw-border-solid tw-border-green-400 '
                      : 'tw-border-l-10 tw-border-solid tw-border-red-400 '
                  "
                >
                  <p
                    class="mb-0 tw-text-xxs fs-md-16"
                    :class="
                      dataCompare[1].indicator_month_color[index]
                        ? 'tw-text-green-400'
                        : 'tw-text-red-400'
                    "
                  >
                    {{ month }}%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div
              class="tw-bg-white tw-rounded-lg tw-shadow-sm p-3 position-relative"
            >
              <Line1
                :propChartdata="propChartdata"
                :key="lineKey"
                class="he-320"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import datesMixin from "@/mixins/general/dates";
import PsiBreadcrumbButtons from "@/components/psi/PsiBreadcrumbButtons";
import Line1 from "@/components/graphs/line/line-1";

export default {
  name: "PsiCompare",
  mixins: [datesMixin],
  components: {
    PsiBreadcrumbButtons,
    Line1,
  },
  computed: {
    ...mapState("modPsi", [
      "dataFilterSummary",
      "dataCompare",
      "month",
      "tableTitle",
      "filterVices",
    ]),
  },
  data() {
    return {
      lineKey: 0,
      vpFilter0: {},
      vpFilter1: {},
      scopeFilter0: {},
      scopeFilter1: {},
      vice0: 0,
      vice1: 0,
      scope0: 0,
      scope1: 0,
      scopesByVice0: [],
      scopesByVice1: [],
      propChartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "",
            borderColor: "#D82B2B",
            fill: false,
            lineTension: 0,
          },
          {
            data: [],
            label: "",
            borderColor: "#00BF79",
            fill: false,
            lineTension: 0,
          },
        ],
      },
    };
  },
  created() {
    this.fillGraphWithData();
  },
  methods: {
    ...mapActions("modPsi", ["updateDataCompare0", "updateDataCompare1"]),
    fillGraphWithData() {
      this.propChartdata.labels = this.monthsShort.slice(
        0,
        this.dataCompare[0].indicator_month.length
      );
      this.propChartdata.datasets[0].label = this.dataCompare[0].name;
      this.propChartdata.datasets[1].label = this.dataCompare[1].name;
      this.propChartdata.datasets[0].data = Object.values(
        this.dataCompare[0].indicator_month
      );
      this.propChartdata.datasets[1].data = Object.values(
        this.dataCompare[1].indicator_month
      );
      this.lineKey += 1;
    },
    onChangeVice0() {
      this.scope0 = 0;
      this.scopesByVice0 = this.filterVices.filter(
        (scope) => scope.id === this.vice0
      )[0].psah;
      this.vpFilter0 = this.dataFilterSummary[0].vp.filter(
        (vp) => vp.id === this.vice0
      )[0];
      this.updateDataCompare0(this.vpFilter0);
      this.fillGraphWithData();
    },
    onChangeVice1() {
      this.scope1 = 0;
      this.scopesByVice1 = this.filterVices.filter(
        (scope) => scope.id === this.vice1
      )[0].psah;
      this.vpFilter1 = this.dataFilterSummary[0].vp.filter(
        (vp) => vp.id === this.vice1
      )[0];
      this.updateDataCompare1(this.vpFilter1);
      this.fillGraphWithData();
    },
    onChangeScope0() {
      this.scopeFilter0 = this.vpFilter0.psah.filter(
        (scope) => scope.id === this.scope0
      )[0];
      this.updateDataCompare0(this.scopeFilter0);
      this.fillGraphWithData();
    },
    onChangeScope1() {
      this.scopeFilter1 = this.vpFilter1.psah.filter(
        (scope) => scope.id === this.scope1
      )[0];
      this.updateDataCompare1(this.scopeFilter1);
      this.fillGraphWithData();
    },
  },
};
</script>
