var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between tw-border tw-border-black tw-rounded-2.5xl tw-overflow-hidden"},[_vm._m(0),_c('div',{staticClass:"tw-p-2 tw-flex tw-flex-col tw-gap-3 tw-h-[400px] tw-overflow-y-auto hidden-scrollbar"},_vm._l((_setup.props.teamRequests),function(item,idx){return _c('div',{key:idx,staticClass:"tw-w-full tw-rounded-2.5xl tw-border tw-border-gray-300 tw-py-2 tw-px-4 tw-grid tw-grid-cols-col3_fit_first_and_last tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('input',{staticClass:"tw-w-6 tw-h-6 tw-border tw-border-solid tw-border-gray-300 tw-rounded-md focus:tw-ring-transparent tw-shadow-sm",class:item.status === 'APPROVED' || item.status === 'REJECTED'
                ? 'tw-cursor-not-allowed tw-text-gray-400'
                : 'tw-cursor-pointer tw-text-green-450',attrs:{"disabled":item.status === 'APPROVED' || item.status === 'REJECTED',"required":"","type":"checkbox"},domProps:{"checked":item.status === 'APPROVED' ||
              item.status === 'REJECTED' ||
              _setup.selectedItems.includes(item.request_id)},on:{"click":function($event){return _setup.toggleSelectItem(item.request_id)}}}),_c('img',{staticClass:"tw-object-cover tw-w-12 tw-h-12 tw-rounded-full tw-border-2 tw-border-gray-300",attrs:{"src":_setup.avatarImage(item),"alt":""}})]),_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center"},[_c('span',{staticClass:"tw-line-clamp-2 tw-font-robotoMedium tw-text-xs"},[_vm._v(_vm._s(_setup.capitalizeEachWord(item.core_employee_name)))]),_c('span',{staticClass:"tw-text-xs tw-text-gray-400"},[_vm._v(_vm._s(_setup.catalogOption[item.option_catalog]))]),_c('span',{staticClass:"tw-text-xs tw-text-gray-400"},[_vm._v(" "+_vm._s(item.option_name)+" ")]),_c('span',{staticClass:"tw-text-xs tw-text-gray-400"},[_vm._v(_vm._s(_setup.formatDateDDMMYYYY(item.requested_date, "/", true)))])]),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{class:'tw-h-1/2 tw-aspect-1 tw-rounded-full tw-bg-' +
              _setup.statusColor(item.status)})])])}),0)]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2 tw-w-full tw-border tw-border-black tw-rounded-2.5xl tw-p-3 tw-mt-3"},[_c('p',{staticClass:"tw-m-0 tw-text-end tw-text-xs"},[_vm._v(" "+_vm._s(`has seleccionado (${_setup.selectedItems.length}) solicitudes`)+" ")]),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-gap-2"},[_c('div',[_c(_setup.CompButtonCustom,{staticClass:"tw-w-full",attrs:{"text":_setup.selectedItems.length === _setup.pendingRequestsLength
              ? 'Deseleccionar todo'
              : 'Seleccionar todo',"color":"white"},nativeOn:{"click":function($event){return _setup.toggleSelectAllItems.apply(null, arguments)}}})],1),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c(_setup.CompButtonCustomColor,{staticClass:"tw-w-max",attrs:{"disabled":_setup.selectedItems.length === 0,"text":"Aprobar","color":"green-450","text-color":"white"},nativeOn:{"click":function($event){return _setup.emit('approve', _setup.selectedItems)}}}),_c(_setup.CompButtonCustomColor,{staticClass:"tw-w-max",attrs:{"disabled":_setup.selectedItems.length === 0,"text":"Rechazar","color":"red-350","text-color":"white"},nativeOn:{"click":function($event){return _setup.emit('reject', _setup.selectedItems)}}})],1)])]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-bg-red-350 tw-py-1"},[_c('p',{staticClass:"tw-m-0 tw-text-center tw-text-white"},[_vm._v("Solicitudes")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2 tw-mt-4"},[_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('div',{staticClass:"tw-h-6 tw-aspect-1 tw-rounded-full tw-bg-yellow-300"}),_c('span',{staticClass:"tw-font-roboto tw-text-sm"},[_vm._v("Pendientes")])]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('div',{staticClass:"tw-h-6 tw-aspect-1 tw-rounded-full tw-bg-green-450"}),_c('span',{staticClass:"tw-font-roboto tw-text-sm"},[_vm._v("Aprobadas")])]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('div',{staticClass:"tw-h-6 tw-aspect-1 tw-rounded-full tw-bg-red-350"}),_c('span',{staticClass:"tw-font-roboto tw-text-sm"},[_vm._v("Rechazadas")])])])
}]

export { render, staticRenderFns }