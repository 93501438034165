<script setup>
import { computed } from "vue";
import { pushLink } from "@/helpers/linkHelpers";

const cases = {
  1: {
    title: "No tienes tu cédula actualizada",
    description:
      "No tienes registrada tu cédula en Tap It, actualiza tus datos para poder realizar la transferencia.",
    image: require("@/assets/img/gamification/modalsImg/updateid.svg"),
    button: "Actualizar mi cédula en Tap It",
    button_link: "",
  },
  2: {
    title: "Tu cédula no coincide",
    description:
      "Tu cédula no coincide con la registraste en Tap It, contacta nuestro soporte para actualizar tus datos.",
    image: require("@/assets/img/gamification/modalsImg/fail.svg"),
    button: "Contactar Soporte",
    button_link: "",
  },
  3: {
    title: "Tu cédula está en actualización",
    description:
      "Tu cédula está siendo registrada en Tap It, verifica tus datos para poder realizar la transferencia.",
    image: require("@/assets/img/gamification/modalsImg/updateid.svg"),
    button: "Ver el estado de mi cédula",
    button_link: "",
  },
};

const emit = defineEmits(["close"]);

const props = defineProps({
  modalState: {
    type: Number,
    required: true,
  },
  tapit_url: {
    type: String,
  },
  customToken: {
    type: String,
  },
});

const actualCase = computed(() => cases[props.modalState]);

const hideModal = () => {
  emit("close");
};

const goToActualCase = () => {
  pushLink(actualCase.value.button_link);
  hideModal();
};
</script>
<template>
  <div>
    <div
      class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-hidden"
    >
      <div class="tw-flex tw-justify-center tw-text-center">
        <div class="tw-py-2 tw-px-4 lg:tw-py-6 lg:tw-px-14">
          <p
            class="tw-mb-3 lg:tw-mb-4 tw-font-tolyerBold tw-text-3xl lg:tw-text-5xl tw-text-red-400 tw-uppercase"
          >
            {{ actualCase.title }}
          </p>
          <img
            :src="actualCase.image"
            class="tw-w-32 lg:tw-w-44 tw-my-5"
            alt=""
          />
          <p
            class="lg:tw-my-5 tw-font-roboto tw-text-xs lg:tw-text-lg tw-text-gray-500"
          >
            {{ actualCase.description }}
          </p>
          <button
            @click="goToActualCase()"
            class="tw-text-sm tw-font-medium hover:tw-text-white tw-border-0 tw-py-2 lg:tw-py-3 tw-mt-3 lg:tw-mt-6 tw-rounded-md tw-bg-red-350 tw-text-white hover:tw-bg-red-500 tw-block tw-text-center tw-w-full"
          >
            {{ actualCase.button }}
          </button>
          <button
            @click="hideModal()"
            class="tw-text-sm tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-black tw-py-2 lg:tw-py-3 px-md-3 tw-mt-3 lg:tw-mt-3 tw-rounded-md tw-bg-white tw-text-black hover:tw-bg-gray-200 tw-block tw-text-center tw-w-full"
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
