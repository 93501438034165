<template>
  <div class="tw-container-general">
    <PsiBreadcrumbButtons />
    <div class="container">
      <div class="tw-shadow-sm px-3 tw-rounded-lg">
        <div class="row">
          <div class="col-12 pt-4">
            <p
              class="tw-text-gray-600 font-weight-bold tw-text-sm fs-md-20 text-uppercase text-center"
            >
              {{ tableTitle }}
            </p>
          </div>
          <div class="col-12 mt-4">
            <p
              class="tw-text-gray-600 font-weight-bold tw-text-xl text-uppercase d-none d-md-block"
            >
              {{ vpName }} {{ scopeName === "" ? "" : "-" }} {{ scopeName }}
            </p>
          </div>
        </div>
        <div class="row tw-bg-gray-200 pt-3 pt-md-5 pb-3 px-0">
          <div class="col-12 col-lg-6">
            <div
              class="py-5 px-3 tw-shadow-sm tw-rounded-lg tw-bg-white he-md-350"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <div
                    class="px-3 py-3 tw-rounded-lg position-relative hover-trigger"
                    :class="
                      Object.values(dataDetail.indicator_month_color).pop()
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      {{
                        this.getMonthNameBy0(
                          Object.keys(dataDetail.indicator_month).pop()
                        )
                      }}
                    </p>
                    <div class="position-absolute tw--top-20 hover-target">
                      <svg
                        width="120"
                        height="88"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="position-relative"
                      >
                        <path
                          d="M.5 10A9.5 9.5 0 0110 .5h99a9.5 9.5 0 019.5 9.5v47.272a9.5 9.5 0 01-5.92 8.8L63.548 86.017a9.5 9.5 0 01-7.108.02L6.472 66.05A9.5 9.5 0 01.5 57.23V10z"
                          fill="#fff"
                          stroke="#000"
                        />
                      </svg>
                      <p
                        class="mb-0 tw-text-black text-center tw-text-base mt-3 position-absolute tw-top-0 text-center w-100 pt-3"
                      >
                        {{
                          Object.values(
                            dataDetail.indicator_month_tooltip
                          ).pop()
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        Object.values(dataDetail.indicator_month_color).pop()
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ Object.values(dataDetail.indicator_month).pop()
                      }}{{ dataDetail.end }}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    class="px-3 py-3 tw-rounded-lg position-relative hover-trigger"
                    :class="
                      dataDetail.year_to_date_color
                        ? 'tw-bg-green-400'
                        : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      Year TO
                    </p>
                    <div class="position-absolute tw--top-20 hover-target">
                      <svg
                        width="120"
                        height="88"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="position-relative"
                      >
                        <path
                          d="M.5 10A9.5 9.5 0 0110 .5h99a9.5 9.5 0 019.5 9.5v47.272a9.5 9.5 0 01-5.92 8.8L63.548 86.017a9.5 9.5 0 01-7.108.02L6.472 66.05A9.5 9.5 0 01.5 57.23V10z"
                          fill="#fff"
                          stroke="#000"
                        />
                      </svg>
                      <p
                        class="mb-0 tw-text-black text-center tw-text-base mt-3 position-absolute tw-top-0 text-center w-100 pt-3"
                      >
                        {{ dataDetail.year_to_date_tooltip }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        dataDetail.year_to_date_color
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataDetail.year_to_date }}{{ dataDetail.end }}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    class="px-3 py-3 tw-rounded-lg position-relative hover-trigger"
                    :class="
                      dataDetail.le_color ? 'tw-bg-green-400' : 'tw-bg-red-400'
                    "
                  >
                    <p class="mb-0 tw-text-xxs fs-md-14 text-white text-center">
                      LE
                    </p>
                    <div class="position-absolute tw--top-20 hover-target">
                      <svg
                        width="120"
                        height="88"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="position-relative"
                      >
                        <path
                          d="M.5 10A9.5 9.5 0 0110 .5h99a9.5 9.5 0 019.5 9.5v47.272a9.5 9.5 0 01-5.92 8.8L63.548 86.017a9.5 9.5 0 01-7.108.02L6.472 66.05A9.5 9.5 0 01.5 57.23V10z"
                          fill="#fff"
                          stroke="#000"
                        />
                      </svg>
                      <p
                        class="mb-0 tw-text-black text-center tw-text-base mt-3 position-absolute tw-top-0 text-center w-100 pt-3"
                      >
                        {{ dataDetail.le_tooltip }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="tw-bg-gray-200 px-3 py-3 py-md-4 mx-2 tw-rounded-br-lg tw-rounded-bl-lg"
                  >
                    <p
                      class="mb-0 text-center tw-text-xxs fs-md-25 font-weight-bold"
                      :class="
                        dataDetail.le_color
                          ? 'tw-text-green-400'
                          : 'tw-text-red-400'
                      "
                    >
                      {{ dataDetail.le }}{{ dataDetail.end }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mt-lg-5">
                <div class="col-6">
                  <div
                    class="tw-bg-gray-200 tw-rounded-lg px-3 py-3 py-md-4 d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="mb-0 text-uppercase tw-text-xxs fs-md-12 tw-text-gray-400"
                    >
                      Last Year
                    </p>
                    <p
                      class="mb-0 tw-text-xs fs-md-18 tw-text-gray-400 font-weight-bold"
                    >
                      {{
                        dataDetail.last_year == null
                          ? "-"
                          : dataDetail.last_year
                      }}{{ dataDetail.end }}
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="tw-bg-gray-200 tw-rounded-lg px-3 py-3 py-md-4 d-flex justify-content-between align-items-center"
                  >
                    <p
                      class="mb-0 text-uppercase tw-text-xxs fs-md-12 tw-text-gray-400"
                    >
                      Budget
                    </p>
                    <p
                      class="mb-0 tw-text-xs fs-md-18 tw-text-gray-400 font-weight-bold"
                    >
                      {{ dataDetail.budget == null ? "-" : dataDetail.budget
                      }}{{ dataDetail.end }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="tw-bg-white p-3 tw-rounded-lg mt-4 mt-md-0">
              <Line1 :propChartdata="propChartdata" class="he-320" />
            </div>
          </div>
        </div>
        <div class="row tw-bg-gray-200 pt-3 pb-5 px-0">
          <div
            class="col-4 col-md-3 col-lg-2 mt-3"
            v-for="(monthData, index) in dataDetail.indicator_month"
            :key="'monthDetail-' + index"
          >
            <p class="text-center tw-text-sm fs-md-16 tw-text-gray-400 mb-2">
              {{ getMonthNameBy0(index) }}
            </p>
            <div
              class="bg-white px-3 py-3 tw-rounded-md text-center"
              :class="
                dataDetail.indicator_month_color[index]
                  ? 'tw-border-l-10 tw-border-solid tw-border-green-400 '
                  : 'tw-border-l-10 tw-border-solid tw-border-red-400 '
              "
            >
              <p
                class="mb-0 tw-text-xs fs-md-16"
                :class="
                  dataDetail.indicator_month_color[index]
                    ? 'tw-text-green-400'
                    : 'tw-text-red-400'
                "
              >
                {{ monthData }}{{ dataDetail.end }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import datesMixin from "@/mixins/general/dates";
import PsiBreadcrumbButtons from "@/components/psi/PsiBreadcrumbButtons";
import Line1 from "@/components/graphs/line/line-1";

export default {
  name: "PSiDeatil",
  mixins: [datesMixin],
  components: {
    PsiBreadcrumbButtons,
    Line1,
  },
  created() {
    this.propChartdata.labels = this.monthsShort.slice(
      0,
      this.dataDetail.indicator_month.length
    );

    this.propChartdata.datasets[0].data = Object.values(
      this.dataDetail.indicator_last
    );
    this.propChartdata.datasets[1].data = Object.values(
      this.dataDetail.indicator_month
    );
  },
  data() {
    return {
      propChartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Año 2020",
            borderColor: "#D82B2B",
            fill: false,
            lineTension: 0,
          },
          {
            data: [],
            label: "Año 2021",
            borderColor: "#00BF79",
            fill: false,
            lineTension: 0,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("modPsi", ["tableTitle", "dataDetail", "vpName", "scopeName"]),
  },
};
</script>
