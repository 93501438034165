<template>
  <div>
    <!--    <input
      type="text"
      v-model="time"
      @keydown="validateTime"
      maxlength="5"
      class="tw-w-full tw-border tw-border-gray-300 tw-rounded-3xl tw-px-3 focus:tw-outline-none focus:tw-border-gray-300 focus:tw-ring-transparent tw-text-center tw-text-base"
      placeholder="00:00"
    />-->
    <input
      type="time"
      v-model="time"
      @input="validateTime"
      class="tw-w-full tw-h-full tw-border tw-rounded-2xl tw-px-3 focus:tw-outline-none focus:tw-border-gray-300 focus:tw-ring-transparent tw-text-center tw-text-base"
      :class="`tw-border-${borderColor}`"
    />
  </div>
</template>

<script>
export default {
  name: "HourField",
  props: {
    borderColor: {
      type: String,
      default: "gray-300",
    },
  },
  data() {
    return {
      time: null,
      validatedHour: false,
    };
  },
  methods: {
    validateTime() {
      let regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
      let minTime = "08:00";
      let maxTime = "18:00";

      /* if (this.time.length > 2 && !this.time.includes(":")) {
        this.time = this.time.slice(0, 2) + ":" + this.time.slice(2);
      }

      if (
        e.inputType === "deleteContentBackward" &&
        this.time[this.time.length - 1] === ":"
      ) {
        this.time = this.time.slice(0, -1);
      }*/

      this.validatedHour =
        regex.test(this.time) && this.time >= minTime && this.time <= maxTime;
      this.$emit("validated", this.validatedHour, this.time);
    },
  },
};
</script>

<style scoped></style>
