<script setup>
import { ref, onBeforeMount } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import CompDiversityGeneralContent from "@/components/diversity/CompDiversityGeneralContent.vue";
import CompGeneralTopCardDropdownVideo from "@/components/cards/CompGeneralTopCardDropdownVideo.vue";

const articlesStore = useArticlesStore();
const isLoading = ref(false);
const optionSelected = ref(null);
const options = ref([
  {
    id: 0,
    name: "Nuestros programas",
    icon: "diversity-programs",
    op_id: "nuestros_programas_diversidad_e_inclusion",
  },
  {
    id: 1,
    name: "Iniciativas de D&I",
    icon: "diversity-initiative",
    op_id: "iniciativas_diversidad_e_inclusion",
  },
  {
    id: 2,
    name: "Eventos participativos",
    icon: "diversity-events",
    op_id: "eventos_diversidad_e_inclusion",
  },
  {
    id: 3,
    name: "Educación en D&I",
    icon: "diversity-education",
    op_id: "educacion_diversidad_e_inclusion",
  },
  {
    id: 4,
    name: "Lo tenemos para tí",
    icon: "diversity-foryou",
    op_id: "lo_tenemos_para_ti_diversidad_e_inclusion",
  },
]);

function changeOption(option) {
  pushDataLayer({
    event_category: "Diversidad e Inclusión",
    event_action: "Diversidad e Inclusión en Embajadores",
    event_label: option.name,
    component_name: "Click",
    element_category: "Elige uno",
  });
  optionSelected.value = option;
  getArticles();
}

function getArticles() {
  console.log("getArticles");
  articlesStore
    .actGetArticlesByCategory(optionSelected.value.op_id)
    .then(() => {
      isLoading.value = false;
    });
}

onBeforeMount(() => {
  isLoading.value = true;
  optionSelected.value = options.value[0];
});
</script>

<template>
  <div>
    <portal-target name="modal-general" />
    <mobile-bar title="Diversidad, Equidad e Inclusión" />
    <title-bar title="Diversidad, Equidad e Inclusión" />
    <main class="tw-container-general tw-mt-0 tw-py-0">
      <section>
        <comp-general-top-card-dropdown-video
          bg-color="AEA0FF"
          title="Diversidad, equidad e inclusión en Embajadores"
          title-mobile-up
          description-mobile-inside
          image="assets/img/diversity/ambassadorsDI.png"
          video="assets/img/diversity/videoPrueba.mp4"
          dropdown
        >
          <template #description>
            Esta sección busca afianzar nuestro compromiso como compañía,
            promoviendo y manteniendo un ambiente de trabajo justo, equitativo y
            diverso para todas las personas, sin importar su origen, raza,
            género, orientación sexual, religión u otras características
            personales. Aquí abordaremos las iniciativas más destacadas y
            relacionadas a la Diversidad, Equidad e inclusión.
          </template>
          <template #dropdown>
            <CompGeneralDropdown
              @changeSelectedOption="changeOption"
              @chargeOption="changeOption"
              :isLabel="false"
              :fitted="false"
              icon-visible
              default-text-visible
              :options="options"
              default-text="Elige uno"
            />
          </template>
          <template #dropdownMobile>
            <CompGeneralDropdown
              @changeSelectedOption="changeOption"
              :isLabel="false"
              :fitted="false"
              icon-visible
              embedded-mobile
              default-text-visible
              :options="options"
              default-text="Elige uno"
            />
          </template>
        </comp-general-top-card-dropdown-video>
      </section>
      <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
      <div v-if="!isLoading && articlesStore.articles.length > 0">
        <comp-diversity-general-content
          :featured-article="articlesStore.getArticlesFeatured[0]"
          :articles="articlesStore.getArticlesGeneral"
          :article-category="optionSelected?.name"
          @updatedComment="getArticles"
          @updatedReaction="getArticles"
        />
      </div>
    </main>
  </div>
</template>
