<script setup>
import config from "@/services/config";
import { ref } from "vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import { useSocialNetworkStore } from "@/stores/socialNetworkStore";
import CompSocialNetworkReactions from "@/components/social-network/CompSocialNetworkReactions.vue";
import CompSocialNetworkComments from "@/components/social-network/CompSocialNetworkComments.vue";
import CompSocialNetworkShare from "@/components/social-network/CompSocialNetworkShare.vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const isShowModalComments = ref(false);
const isShowModalShare = ref(false);
const isShowModalReactions = ref(false);
const reactions = ref(null);
const store = useSocialNetworkStore();

const emit = defineEmits([
  "updatedReaction",
  "updatedComment",
  "emitDataLayer",
]);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  amount_reactions: {
    type: Number,
    required: true,
  },
  amount_comments: {
    type: Number,
    required: true,
  },
  user_reaction: {
    validator(value) {
      return value == null || typeof value === "object";
    },
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
  categoryForDatalayer: {
    type: String,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  minimalIconsDesk: {
    type: Boolean,
    default: false,
  },
});

function toggleModalComments() {
  isShowModalComments.value = !isShowModalComments.value;
  if (isShowModalComments.value) {
    interactionsDatalayer("Comentar");
  }
}

function toggleModalShare() {
  isShowModalShare.value = !isShowModalShare.value;
  if (isShowModalShare.value) {
    interactionsDatalayer("Compartir");
  }
}

const toggleModalReactions = () => {
  const { x, y } = reactions.value.getBoundingClientRect();
  if (!isShowModalReactions.value) {
    store.setCoordinates({ x, y });
  }
  isShowModalReactions.value = !isShowModalReactions.value;
};

const manageReactions = ($event) => {
  emit("updatedReaction", $event);
  interactionsDatalayer($event?.reaction?.name);
};

function interactionsDatalayer(label) {
  pushDataLayer({
    event_category: props.categoryForDatalayer,
    event_action: props.title,
    event_label: label,
    component_name: "Click",
  });
}
</script>

<template>
  <div>
    <portal to="all-modals">
      <general-modal
        v-if="isShowModalComments"
        modalName="comments"
        @close="toggleModalComments"
        :isShowModal="isShowModalComments"
        :modalWidth="{ md: 500, lg: 600, xl: 600 }"
        :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
        hide-button-support
      >
        <comp-social-network-comments
          :id="props.id"
          :category="props.category"
          :item="props.item"
          :is-show-modal="isShowModalComments"
          class="tw-h-full"
          @updatedComment="emit('updatedComment', $event)"
        />
      </general-modal>
    </portal>
    <portal to="all-modals">
      <general-modal
        v-if="isShowModalShare"
        modalName="share"
        @close="toggleModalShare"
        :isShowModal="isShowModalShare"
        :modalWidth="{ md: 500, lg: 600, xl: 600 }"
        :maxHeight="{ base: 400, md: 550, lg: 550, xl: 600 }"
        hide-button-support
      >
        <comp-social-network-share
          :id="props.id"
          :category="props.category"
          :item="props.item"
          :title="props.title"
          :image="props.image"
          :description="props.description"
          class="tw-h-full"
        />
      </general-modal>
    </portal>
    <portal to="all-modals">
      <general-modal
        v-if="isShowModalReactions"
        modalName="reactions"
        @close="toggleModalReactions"
        :isShowModal="isShowModalReactions"
        :modalWidth="{ base: 320, md: 320, lg: 320, xl: 320 }"
        :maxHeight="{ base: 60, md: 60, lg: 60, xl: 60 }"
        :closable="false"
        :backdrop="false"
        :tiny="true"
        :coordinates="store.coordinates"
        :padding-x="false"
        :padding-y="false"
      >
        <comp-social-network-reactions
          :reaction="props.user_reaction"
          :category="props.category"
          :id="props.id"
          :item="props.item"
          @closeModal="toggleModalReactions"
          @updateReaction="manageReactions"
        />
      </general-modal>
    </portal>
    <div class="tw-flex tw-gap-x-4 md:tw-gap-x-10" v-if="!isLoading">
      <div
        class="tw-flex tw-gap-x-1 tw-text-black tw-items-center tw-cursor-pointer"
        @click="toggleModalReactions"
      >
        <div
          v-if="props.user_reaction == null"
          ref="reactions"
          class="tw-flex tw-justify-center tw-items-center tw-relative"
        >
          <div
            v-if="props.hover"
            class="tw-rounded-full tw-h-12 tw-w-12 tw-z-2 tw-absolute tw-bg-yellow-300 tw-opacity-20 tw-animate-pulse"
          ></div>
          <div
            v-if="props.hover"
            class="tw-rounded-full tw-w-8 tw-h-8 tw-z-3 tw-absolute tw-bg-yellow-300 tw-opacity-70 tw-animate-pulse"
          ></div>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-relative tw-z-4"
          >
            <i
              class="icon-like tw-text-sm md:tw-text-base"
              :class="
                props.minimalIconsDesk ? 'lg:tw-text-base' : 'lg:tw-text-xl'
              "
            ></i>
          </div>
        </div>

        <img
          v-else
          ref="reactions"
          :src="uriResources + '/' + props.user_reaction?.reaction?.icon"
          alt="image"
          class="tw-h-5/6 tw-max-h-6"
        />
        <span
          class="tw-font-roboto tw-text-sm md:tw-text-base lg:tw-text-xl tw-z-10"
          >{{ props.amount_reactions }}</span
        >
      </div>
      <div
        class="tw-flex tw-gap-x-1 tw-text-black tw-items-center tw-cursor-pointer"
        @click="toggleModalComments"
      >
        <i
          class="icon-comment tiw-text-sm md:tw-text-base"
          :class="props.minimalIconsDesk ? 'lg:tw-text-base' : 'lg:tw-text-xl'"
        ></i>
        <span class="tw-font-roboto tw-text-sm md:tw-text-base lg:tw-text-xl">{{
          props.amount_comments
        }}</span>
      </div>
      <div
        class="tw-flex tw-gap-x-1 tw-text-black tw-items-center tw-cursor-pointer"
        @click="toggleModalShare"
      >
        <i
          class="icon-share tw-text-sm md:tw-text-base"
          :class="props.minimalIconsDesk ? 'lg:tw-text-base' : 'lg:tw-text-xl'"
        ></i>
      </div>
    </div>
  </div>
</template>
