<script setup>
import { returnMediaURL } from "@/helpers/imageHelpers";
import { ref } from "vue";
import AtomPlayButtonOverlay from "@/components/atoms/AtomPlayButtonOverlay.vue";

defineProps({
  src: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true, // 'image' or 'video'
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  fill: {
    type: Boolean,
    default: false,
  },
  rectangular: {
    type: Boolean,
    default: false,
  },
  customWidth: {
    type: String,
    default: "",
  },
  customHeight: {
    type: String,
    default: "",
  },
  alignment: {
    type: String,
    default: "center",
  },
});

const playing = ref(false);
const videoRef = ref(null);

const playVideo = () => {
  if (videoRef.value) {
    videoRef.value.play();
    playing.value = true;
  }
};

const handleFullscreenChange = () => {
  if (document.fullscreenElement) {
    videoRef.value.style.width = "100%";
  }
};
</script>

<template>
  <div
    :class="
      [
        `tw-flex tw-justify-${alignment} tw-items-center`,
        rounded ? 'tw-rounded-2.5xl tw-overflow-hidden' : '',
        fill ? 'tw-w-full' : 'tw-w-fit',
        rectangular ? 'tw-aspect-[16/10]' : '',
      ].filter(Boolean)
    "
  >
    <img
      v-if="type === 'image'"
      :src="returnMediaURL(src)"
      alt="media"
      :class="
        [
          'tw-place-self-center tw-self-center tw-content-center tw-justify-self-center',
          rounded ? 'tw-rounded-2xl lg:tw-rounded-3xl' : '',
          fill ? ' tw-h-full tw-object-cover' : 'tw-max-w-full tw-max-h-full',
          customWidth ? `tw-${customWidth}` : 'tw-w-full',
        ].filter(Boolean)
      "
    />
    <video
      ref="videoRef"
      v-else
      :src="`${returnMediaURL(src)}#t=0.1`"
      controls
      playsinline
      preload="metadata"
      :class="{ 'tw-rounded-2xl lg:tw-rounded-3xl': rounded }"
      class="tw-w-full tw-h-auto"
      @play="playing = true"
      @pause="playing = false"
      @fullscreenchange="handleFullscreenChange"
    />
    <AtomPlayButtonOverlay
      v-if="!playing && type === 'video'"
      @play="playVideo"
    />
  </div>
</template>
