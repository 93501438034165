<script setup>
import config from "@/services/config";
import { useProfileStore } from "@/stores/profileStore";
import CompResult from "@/components/profilling/CompResult.vue";

const profileStore = useProfileStore();
const uriResources = config.beerOfficeURL;
</script>
<template>
  <div class="container-fluid">
    <img
      src="../../assets/img/icons/iconRightResponse.png"
      alt=""
      class="imgtr d-none d-md-block"
    />
    <div class="row">
      <div class="col-md-6 p-0 m-0">
        <div
          :style="{
            'background-image':
              'url(' +
              uriResources +
              '/' +
              profileStore.profileType.image +
              ')',
          }"
          class="h-100 account-background text-center d-none d-md-block pt-4"
        >
          <h1 class="w-100 tw-text-4xl md:tw-text-7xl tw-font-tolyerBold">
            EMBAJADORES
          </h1>
          <div class="account-logo">
            <img src="@/assets/img/LogoBavariaBL.png" class="tw-w-2.5" alt="" />
          </div>
        </div>
        <div
          :style="{
            'background-image':
              'url(' +
              uriResources +
              '/' +
              profileStore.profileType.image +
              ')',
          }"
          class="height35 account-background account-background-xs text-center d-xl-none d-md-none pt-4"
        >
          <h1 class="w-100">EMBAJADORES</h1>
        </div>
      </div>
      <div
        class="height100 col-md-6 p-0 pl-1 pr-1 d-flex justify-content-center profQuestion"
      >
        <div
          class="height100 mt-md-2 mt-sm-4 align-items-center text-center h100 profContent"
        >
          <CompResult
            class="content-sm mr-md-3 ml-md-3 d-none d-md-block d-none d-sm-block"
          />
          <CompResult
            class="content-sm mr-md-3 ml-md-3 d-block d-sm-none text-left"
          />
        </div>
      </div>
    </div>
    <img
      src="../../assets/img/icons/onboardingBL.png"
      alt=""
      class="imgbr d-none d-md-block"
    />
  </div>
</template>

<style scoped>
.account-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  color: #ffffff;
  border-radius: 0 40px 40px 0;
}

.account-logo {
  position: absolute;
  width: 100%;
  bottom: 5%;
}
.account-form a {
  text-decoration: underline;
}

.imgtr {
  position: absolute;
  top: 0;
  right: 0;
  width: 5%;
}

.imgbr {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
}
/* SMALL DISPLAY */
@media (max-width: 600px) {
  .height100 {
    min-height: auto !important;
    display: block;
  }
  .account-background {
    border-radius: 0 0 40px 40px;
    -webkit-box-shadow: 0 4px 8px #00000029;
    -moz-box-shadow: 0 4px 8px #00000029;
    box-shadow: 10px 10px 67px -18px rgba(0, 0, 0, 0.75);
  }
  .profContent {
    margin-top: 2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
</style>
