<script setup>
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});

const imageUrl = require(`@/assets/img/${props.icon}`);

const replaceWithBr = (text) => text.replace(/\n/g, "<br />");
</script>
<template>
  <div
    class="tw-w-full lg:tw-w-9/12 tw-border tw-border-gray-300 tw-rounded-3xl tw-shadow-lg tw-flex tw-gap-3 tw-p-4"
  >
    <div class="tw-h-full tw-flex tw-items-center">
      <div
        class="tw-rounded-full tw-p-3 tw-flex tw-items-center tw-justify-center tw-bg-pink-300 tw-max-h-14 tw-max-w-14 lg:tw-w-14"
      >
        <img :src="imageUrl" alt="" />
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-gap-3">
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-red-350"
      >
        {{ title }}
      </p>
      <p
        class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-xs"
        v-html="replaceWithBr(description)"
      ></p>
    </div>
  </div>
</template>
