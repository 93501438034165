<template>
  <div class="w-100">
    <div class="top-up">
      <div class="container p-0 profile">
        <SecondaryNavbar title="ACERCA DE TI" />
        <div class="row pt-0 pt-md-3 pb-5" v-if="isSending">
          <div class="col">
            <div class="text-center mt-3">
              <img src="../../assets/img/icons/loading.gif" alt="loading" />
            </div>
          </div>
        </div>
        <div v-else class="row w-100 mb-1 pb-3 mx-auto justify-content-center">
          <div class="col-md-6 p-0 pr-md-5">
            <div class="content-card pt-0 content-card-avatar">
              <div class="card overflow-hidden box-sha">
                <div class="card-body p-0">
                  <div
                    class="profAvatar w-100 p-3"
                    :style="{
                      'background-image':
                        '-webkit-gradient(linear, left 5, left bottom, from(rgba(0, 0, 0, 0.76)), to(rgba(0, 0, 0, 0.1))),url(' +
                        imgCardFont +
                        ')',
                    }"
                  >
                    <div class="w-50 mx-auto mt-3 text-center">
                      <img
                        v-if="user.prof_picture !== null"
                        class="rounded-circle my-auto mb-2"
                        :src="uriResources + '/' + user.prof_picture"
                        style="width: 150px; height: 150px"
                        alt=""
                      />

                      <img
                        v-else
                        class="rounded-circle my-auto mb-2"
                        :src="imgVideo"
                        style="width: 150px; height: 150px"
                        alt=""
                      />

                      <div
                        class="img-up"
                        @click="
                          pushDataLayerProfile({
                            category: 'Profile',
                            subcategory: '',
                            label: 'Foto de Perfil',
                          })
                        "
                      >
                        <label for="file-input">
                          <img
                            class="img-camera rounded-circle"
                            style="width: 38px; height: 38px"
                            src="@/assets/img/icons/camara-icons-d.png"
                            alt=""
                          />
                        </label>

                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          @change="change()"
                        />
                      </div>
                      <!--<div class="img-up">
                        <label for="file-input">
                          <img
                            class="img-camera rounded-circle"
                            style="width: 38px; height: 38px"
                            src="@/assets/img/icons/camara-icons.png"
                            alt=""
                            srcset=""
                          />
                        </label>
                        <input id="archivo" type="file" accept="image/*" />
                      </div>-->
                      <small
                        v-show="error.flag"
                        style="
                          position: absolute;
                          top: 27%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        "
                        class="alert-danger text-center mx-auto"
                        >{{ error.messageFileImg }}</small
                      >
                    </div>
                  </div>
                  <!--Desk-->
                  <div
                    class="row px-2 px-md-1 pt-3 pt-md-0 mx-3 mx-md-0 d-none d-md-block"
                  >
                    <div class="text-center pt-3 mb-md-2 mx-md-3 mx-4 mt-2">
                      <div class="row mt-2">
                        <div class="col-3 d-flex justify-content-center pr-0">
                          <img
                            width="65"
                            height="210"
                            :src="imgComplementary"
                            alt
                          />
                        </div>
                        <div class="col-9">
                          <div class="row pl-4 mb-1">
                            <div class="col-9 text-left pl-0">
                              <h6
                                class="text-capitalize font-weight-bold textColorPrimary"
                              >
                                nombre
                              </h6>
                              <h6 class="text-color-baba text-capitalize">
                                {{ user.name }}
                              </h6>
                              <img src alt />
                            </div>
                            <div class="col-3 text-right">
                              <img src="../../assets/img/icons/cebada-roja.png" alt />
                            </div>
                          </div>
                          <div class="row pl-4 mb-3">
                            <div class="col text-left pl-0">
                              <h6
                                class="text-capitalize font-weight-bold textColorPrimary"
                              >
                                cédula
                              </h6>
                              <h6 class="text-color-baba">{{ user.cc }}</h6>
                            </div>
                          </div>
                          <div class="row pl-4 mb-3">
                            <div class="col text-left pl-0">
                              <h6
                                class="text-capitalize font-weight-bold textColorPrimary"
                              >
                                correo
                              </h6>
                              <h6 class="text-color-baba">
                                {{ user.email || "-" }}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-1 mt-3 justify-content-between">
                        <h6
                          class="text-capitalize font-weight-bold textColorPrimary"
                        >
                          Tipo de usuario
                        </h6>
                        <h6 class="text-brand">{{ user.prof_profile.name }}</h6>
                      </div>
                      <p class="text-left text-color-baba mt-2">
                        "{{ user.prof_profile.description }}"
                      </p>
                      <!--{{user}}-->
                    </div>
                  </div>
                  <!--Mobile-->
                  <div class="px-2 px-md-1 pt-3 mx-2 mt-4 d-block d-md-none">
                    <div class="form-group position-relative">
                      <p class="text-font mb-1">Nombre</p>
                      <p class="cont-type-mobile p-3 text-box-mobile">
                        {{ user.name }}
                      </p>
                    </div>
                    <div class="form-group position-relative">
                      <p class="text-font mb-1">Cédula</p>
                      <p class="cont-type-mobile p-3 text-box-mobile">
                        {{ user.cc || "-" }}
                      </p>
                    </div>
                    <div class="form-group position-relative">
                      <p class="text-font mb-1">Correo</p>
                      <p class="cont-type-mobile p-3 text-box-mobile">
                        {{ user.email || "-" }}
                      </p>
                    </div>
                    <div class="form-group position-relative">
                      <p class="text-font mb-1">Tipo de Usuario</p>
                      <p class="cont-type-mobile p-3 text-box-mobile">
                        {{ user.prof_profile.name || "-" }}
                      </p>

                      <div class="cont-type-mobile mt-3">
                        <p
                          class="text-left text-color-baba mb-0 p-3 text-box-mobile"
                        >
                          "{{ user.prof_profile.description }}"
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-3 mt-md-0">
            <div class="cont-form mb-3">
              <div class="d-flex justify-content-between mb-1">
                <div>
                  <span class="font-weight-bold text-bar"
                    >Nivel de perfil:</span
                  >
                  <span v-if="this.bar <= 33" class="text-bar ml-1"
                    >basico</span
                  >
                  <span
                    v-if="this.bar > 33 && this.bar <= 66"
                    class="text-bar ml-1"
                    >intermedio</span
                  >
                  <span v-if="this.bar > 66" class="text-bar ml-1"
                    >avanzado</span
                  >
                </div>
                <div>
                  <span :class="colorNumber" class="font-weight-bold h6"
                    >{{ this.bar }}%</span
                  >

                  <img
                    v-if="this.bar <= 33"
                    :src="imgBored"
                    alt
                    class="imgHeight ml-1 mb-1"
                  />
                  <img
                    v-if="this.bar > 33 && this.bar <= 66"
                    :src="imgGood"
                    alt
                    class="imgHeight ml-1 mb-1"
                  />
                  <img
                    v-if="this.bar > 66"
                    :src="imgHappy"
                    alt
                    class="imgHeight ml-1 mb-1"
                  />
                </div>
              </div>
              <div class="progress">
                <div
                  :class="color"
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: this.bar + '%' }"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="form-group position-relative">
              <p class="text-font mb-1">Celular</p>
              <input
                v-model="updateData.cellphone"
                type="number"
                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
                class="form-control form-control-lg text-btns"
                placeholder="1234567890"
                @keyup.stop="barProgress()"
                @keyup.delete="barProgress()"
              />
              <small
                v-show="errCell.flag"
                class="alert-danger text-center mx-auto"
                >{{ errCell.messageCell }}</small
              >
            </div>
            <div class="form-group position-relative">
              <p class="text-font mb-1">Correo personal</p>
              <input
                v-model="updateData.email_personal"
                type="email"
                class="form-control form-control-lg text-btns"
                placeholder="abc@email.com"
                @keyup.stop="barProgress()"
                @keyup.delete="barProgre()"
              />
              <small
                v-show="errMail.flag"
                class="alert-danger text-center mx-auto"
                >{{ errMail.messageMail }}</small
              >
            </div>
            <div class="form-group position-relative">
              <div class="d-flex justify-content-between">
                <p class="mb-1 text-font">Género</p>
                <small class="text-color-baba mb-1"
                  >*Puedes seleccionar solo una opción.</small
                >
              </div>
              <div class="row cont-row">
                <div
                  class="col-4 px-1"
                  v-for="(_, key) in genderOptions"
                  v-bind:key="key"
                >
                  <button
                    :class="key === gender ? 'btn-active' : 'btn-default'"
                    class="btn text-btns px-0"
                    @click="
                      btnAddGender(key),
                        pushDataLayerProfile({
                          category: 'Perfil - Genero',
                          subcategory: 'Detalles de Perfil',
                          label: key,
                        })
                    "
                  >
                    {{ key }}
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group position-relative">
              <div class="d-flex justify-content-between">
                <p class="mb-1 text-font">Vive con</p>
                <small class="text-color-baba mb-1"
                  >*Puedes seleccionar varios.</small
                >
              </div>
              <div class="row cont-row">
                <div
                  class="col-3 mb-2 px-1"
                  v-for="item in familiesDefault"
                  v-bind:key="item.id"
                >
                  <div
                    @click="
                      btnAddFamilyActive(item),
                        pushDataLayerProfile({
                          category: 'Perfil - Vive con',
                          subcategory: 'Detalles de Perfil',
                          label: item.name,
                        })
                    "
                  >
                    <button
                      :class="item.active === 1 ? 'btn-active' : 'btn-default'"
                      class="btn text-btns px-0"
                    >
                      {{ item.name }}
                    </button>
                  </div>
                </div>

                <div class="col-3 px-1">
                  <button
                    :class="
                      familiesOthers.length > 0
                        ? 'btn-active-with'
                        : 'btn-life-with'
                    "
                    class="p-auto"
                    @click="btnAddFamily('')"
                  >
                    Agregar
                  </button>
                </div>
              </div>
              <div class="row cont-row">
                <div
                  class="input-group col-4 px-1 mt-3"
                  v-for="(item, index) in familiesOthers"
                  v-bind:key="index"
                >
                  <div class="input-group">
                    <input
                      v-model="familiesOthers[index]"
                      type="text"
                      class="form-control form-control-lg text-left btn-selected text-btns px-2 px-md-3"
                      min="0"
                      max="50"
                      @change="
                        pushDataLayerProfile({
                          category: 'Perfil - Vive con',
                          subcategory: 'Detalles del perfil',
                          label: item,
                        })
                      "
                    />
                  </div>
                  <button
                    class="btn btn-quit-d p-0"
                    @click="btnSubsFamily(index)"
                    type="button"
                  >
                    <img
                      class="icon-quit"
                      src="@/assets/img/icons/ic-actions-close.png"
                      alt
                    />
                  </button>
                </div>
              </div>
            </div>
            <p class="text-font mb-1 mt-4">¿Tienes hijos?</p>
            <div class="content-childs mb-4">
              <div class="row mt-2">
                <span
                  class="col-2 col-md-1 d-flex align-items-center mr-2 mr-md-3 text-font"
                  >Hijos:</span
                >
                <div
                  class="d-flex btn-child d-flex align-items-center justify-content-center mr-0 px-2"
                >
                  <div class="mx-auto mx-md-0 tamN col-6">
                    {{ childrens.length }}
                  </div>
                  <div class="mx-auto mx-md-0" @click="btnAddChildren()">
                    <button class="col-6">
                      <img
                        class="icon-quit"
                        src="@/assets/img/icons/ic-actions-add.png"
                        alt
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <p class="text-font">Fecha de nacimiento de tu(s) hijo(s):</p>
                <div class="row mb-2 mx-auto w-100">
                  <div
                    class="col-4 p-1"
                    v-for="(item, index) in childrens"
                    v-bind:key="item.id"
                  >
                    <div class="input-group">
                      <input
                        v-model="item.birthdate"
                        type="text"
                        class="form-control text-left form-control-lg px-0 px-md-2 btn-selected text-btns"
                        min="0"
                        max="50"
                        placeholder="AAAA-MM-DD"
                      />
                    </div>
                    <button
                      class="btn btn-quit p-0"
                      @click="btnSubsChild(index)"
                      type="button"
                    >
                      <img
                        class="icon-quit"
                        src="@/assets/img/icons/ic-actions-close.png"
                        alt
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group position-relative">
              <div class="d-flex justify-content-between">
                <p class="mb-1 text-font">Mascotas</p>
                <small class="text-color-baba mb-1"
                  >*Puedes seleccionar varios.</small
                >
              </div>
              <div class="row cont-row">
                <div
                  class="col-3 mb-2 px-1"
                  v-for="item in petsDefault"
                  v-bind:key="item.id"
                >
                  <div
                    @click="
                      btnAddPetActive(item),
                        pushDataLayerProfile({
                          category: 'Perfil - Mascotas',
                          subcategory: 'Detalles del perfil',
                          label: item.name,
                        })
                    "
                  >
                    <button
                      :class="item.active === 1 ? 'btn-active' : 'btn-default'"
                      class="btn text-btns px-0"
                    >
                      {{ item.name }}
                    </button>
                  </div>
                </div>
                <div class="col-3 px-1">
                  <button
                    :class="
                      petsOthers.length > 0
                        ? 'btn-active-with'
                        : 'btn-life-with'
                    "
                    class="p-auto"
                    @click="btnAddPet('')"
                  >
                    Agregar
                  </button>
                </div>
              </div>
              <div class="row cont-row">
                <div
                  class="input-group col-4 px-1 mt-3"
                  v-for="(item, index) in petsOthers"
                  v-bind:key="index"
                >
                  <div class="input-group">
                    <input
                      v-model="petsOthers[index]"
                      type="text"
                      class="form-control form-control-lg text-left btn-selected text-btns px-2 px-md-3"
                      min="0"
                      max="50"
                      @change="
                        pushDataLayerProfile({
                          category: 'Perfil - Mascotas',
                          subcategory: 'Detalles del perfil',
                          label: item,
                        })
                      "
                    />
                  </div>
                  <button
                    class="btn btn-quit-d p-0"
                    @click="btnSubsPet(index)"
                    type="button"
                  >
                    <img
                      class="icon-quit"
                      src="@/assets/img/icons/ic-actions-close.png"
                      alt
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group position-relative">
              <div class="d-flex justify-content-between">
                <p class="mb-1 text-font">Hobbies</p>
                <small class="text-color-baba mb-1"
                  >*Puedes seleccionar varios.</small
                >
              </div>
              <div class="row cont-row">
                <div
                  class="col-3 mb-2 px-1"
                  v-for="item in hobbiesDefault"
                  v-bind:key="item.id"
                >
                  <div
                    @click="
                      btnAddHobbieActive(item),
                        pushDataLayerProfile({
                          category: 'Perfil - Hobbies',
                          subcategory: 'Detalles del perfil',
                          label: item.name,
                        })
                    "
                  >
                    <button
                      :class="item.active === 1 ? 'btn-active' : 'btn-default'"
                      class="btn text-btns px-0"
                    >
                      {{ item.name }}
                    </button>
                  </div>
                </div>
                <div class="col-3 px-1">
                  <button
                    :class="
                      hobbiesOthers.length > 0
                        ? 'btn-active-with'
                        : 'btn-life-with'
                    "
                    class="p-auto"
                    @click="btnAddHobbie('')"
                  >
                    Agregar
                  </button>
                </div>
              </div>
              <div class="row cont-row">
                <div
                  class="input-group col-4 px-1 mt-3"
                  v-for="(item, index) in hobbiesOthers"
                  v-bind:key="index"
                >
                  <div class="input-group">
                    <input
                      v-model="hobbiesOthers[index]"
                      type="text"
                      class="form-control form-control-lg text-left btn-selected text-btns px-2 px-md-3"
                      min="0"
                      max="50"
                      @change="
                        pushDataLayerProfile({
                          category: 'Perfil - Hobbies',
                          subcategory: 'Detalles del perfil',
                          label: item,
                        })
                      "
                    />
                  </div>
                  <button
                    class="btn btn-quit-d p-0"
                    @click="btnSubsHobbie(index)"
                    type="button"
                  >
                    <img
                      class="icon-quit"
                      src="@/assets/img/icons/ic-actions-close.png"
                      alt
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-100 cont-save d-flex justify-content-end pt-5 px-3 align-self-end text-center"
          >
            <button
              class="btn-save px-4 py-3 d-none d-md-block"
              id="myBtn"
              @click="btnSave()"
            >
              Guardar cambios
            </button>
            <button
              class="btn-save px-4 py-3 d-block d-md-none mx-auto"
              id="myBtn"
              @click="btnSave()"
            >
              Guardar cambios
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        ref="modal-sell"
        centered
        hide-header
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        class="cont-modal"
      >
        <img
          src="@/assets/img/loading-beer-office.gif"
          class="tw-w-full"
          alt=""
          srcset=""
        />
        <div class="row px-3 px-md-0 mt-2">
          <div class="col-12 px-5">
            <div
              class="cont-text d-flex h-100 flex-column justify-content-center text-center"
            >
              <p class="mx-3 mb-5">Estamos cargando la imagen de tu perfil.</p>
            </div>
            <!-- .cont-text-1 -->
          </div>
          <!-- .col -->
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "personalData",
  data() {
    return {
      imgVideo: require("@/assets/img/demo/avatar-empty.png"),
      imgCardFont: require("@/assets/img/fondo-user-mobile.png"),
      imgComplementary: require("@/assets/img/icons/grafica complementaria.png"),
      imgBarley: require("@/assets/img/icons/cebada-roja.png"),
      imgBored: require("@/assets/img/icons/emoji-basico.svg"),
      imgGood: require("@/assets/img/icons/emoji-intermedio.svg"),
      imgHappy: require("@/assets/img/icons/emoji-avanzado.svg"),
      imgCheck: require("@/assets/img/icons/ic-actions-check.svg"),
      uriResources: config.beerOfficeURL,
      iconQuit: "@/assets/img/icons/ic-actions-close.png",
      textSave: "Tus cambios han sido guardados.",
      isSending: false,
      updateData: {
        email_personal: "",
        cellphone: "",
        gender: "",
        family: [],
      },
      genderOptions: {
        "No Binario": "OTHER",
        Femenino: "FEMALE",
        Masculino: "MALE",
      },
      gender: null,
      bar: 0,
      v: 0,
      h: 0,
      p: 0,
      file: null,
      pic: false,
      error: {
        flag: false,
        messageFileImg: "*Error al subir la imagen de perfil.",
      },
      errCell: {
        flag: false,
        messageCell: "Número celular inválido",
      },
      errGender: {
        flag: false,
        messageGender: "Debes seleccionar un campo",
      },
      errMail: {
        flag: false,
        messageMail: "Correo inválido",
      },
      validateMail: 0,
      validateCell: 0,
    };
  },
  components: {
    SecondaryNavbar,
  },
  async created() {
    await this.actGetFamiliesById();
    await this.actGetFamilies();
    await this.actGetChildrens();
    await this.actGetHobbiesById();
    await this.actGetPetsById();
    this.actGetPets();
    this.actGetHobbies();
    this.genderDefault();
  },
  mounted() {
    this.updateData.email_personal = this.user.email_personal;
    this.updateData.cellphone = this.user.cellphone;
    this.updateData.gender = this.user.gender;

    this.sFamily(this.familiesOthers);
    this.sPet(this.petsOthers);
    this.sHobbie(this.hobbiesOthers);

    window.dataLayer.push({
      event: "trackEvent",
      eventCategory: "Tipo de usuario",
      eventAction: "clic",
      eventLabel: this.user.prof_profile.name,
      eventValue: 5000,
    });
  },
  beforeUpdate() {
    this.barProgress();
  },
  computed: {
    ...mapState("modCore", [
      "user",
      "userDefault",
      "childrens",
      "families",
      "familiesDefault",
      "familiesOthers",
      "pets",
      "petsDefault",
      "petsOthers",
      "hobbies",
      "hobbiesDefault",
      "hobbiesOthers",
      "avatar",
    ]),
    color() {
      return {
        "bg-red": this.bar < 33,
        "bg-yellow": this.bar >= 33 && this.bar < 66,
        "bg-green": this.bar >= 66,
      };
    },
    colorNumber() {
      return {
        "text-red": this.bar < 33,
        "text-yellow": this.bar >= 33 && this.bar < 66,
        "text-green": this.bar >= 66,
      };
    },
  },
  methods: {
    ...mapActions("modCore", [
      "actUserUpdate",
      "actGetChildrens",
      "actUpdateChildrens",
      "actGetFamilies",
      "actGetFamiliesById",
      "actUpdateFamilies",
      "actGetPets",
      "actGetPetsById",
      "actUpdatePets",
      "actGetHobbies",
      "actGetHobbiesById",
      "actUpdateHobbies",
      "actUpdateImageProfile",
    ]),
    ...mapMutations("modCore", [
      "addFamily",
      "subsFamily",
      "sFamily",
      "addChildren",
      "subsChild",
      "addPet",
      "sPet",
      "subsPet",
      "subsHobbie",
      "addHobbie",
      "sHobbie",
    ]),
    genderDefault() {
      if (this.user.gender != null) {
        if (this.user.gender === "MALE") {
          this.gender = "Masculino";
        } else if (this.user.gender === "FEMALE") {
          this.gender = "Femenino";
        } else if (this.user.gender === "OTHER") {
          this.gender = "No Binario";
        }
      } else {
        this.gender = "";
      }
    },
    // assignDefaultBarProgress() {
    //   setTimeout(() => {}, 1000);
    // },
    btnAddChildren() {
      if (this.childrens.length < 15) {
        this.addChildren();
      }
      this.pushDataLayerProfile({
        category: "Perfil - Tiene hijos",
        subcategory: "Detalles del perfil",
        label: this.childrens.length,
      });
    },
    //Finish
    btnAddFamilyActive(family) {
      if (family.active === 1) {
        family.active = 0;
      } else {
        family.active = 1;
      }
      if (family.name === "Nadie") {
        this.familiesDefault.forEach((e) => {
          if (e.id !== family.id) {
            e.active = 0;
          }
        });
        this.sFamily([]);
      } else {
        this.familiesDefault.forEach((e) => {
          if (e.name === "Nadie") {
            e.active = 0;
          }
        });
      }
      //Event required to active btn Family
      this.btnAddGender(this.gender);
    },
    btnAddFamily(family) {
      let value = 0;
      this.familiesOthers.forEach((e) => {
        if (e === family) {
          value = 1;
        }
      });
      if (value !== 1) {
        this.familiesOthers.push(family);
      }
      this.updateData.family = this.updateData.family.concat(
        this.familiesOthers
      );
      this.barProgress();
    },
    btnAddGender(key) {
      this.gender = key;
      this.updateData.gender = this.genderOptions[key];
      this.barProgress();
    },
    btnAddPetActive(pet) {
      if (pet.active === 1) {
        pet.active = 0;
      } else {
        pet.active = 1;
      }
      if (pet.name === "Ninguno") {
        this.petsDefault.forEach((e) => {
          if (e.id !== pet.id) {
            e.active = 0;
          }
        });
        this.sPet([]);
      } else {
        this.petsDefault.forEach((e) => {
          if (e.name === "Ninguno") {
            e.active = 0;
          }
        });
      }
      //Event required to active btn Family
      this.btnAddGender(this.gender);
    },
    btnAddPet(pet) {
      let value = 0;
      this.petsDefault.forEach((e) => {
        if (e.name === "Ninguno") {
          e.active = 0;
        }
      });
      this.petsOthers.forEach((e) => {
        if (e === pet) {
          value = 1;
        }
      });
      if (value !== 1) {
        this.petsOthers.push(pet);
      }

      this.updateData.pets = this.updateData.pets.concat(this.petsOthers);
      this.barProgress();
    },
    btnAddHobbie(hobbie) {
      let value = 0;
      this.hobbiesDefault.forEach((e) => {
        if (e.name === "Ninguno") {
          e.active = 0;
        }
      });

      this.hobbiesOthers.forEach((e) => {
        if (e === hobbie) {
          value = 1;
        }
      });
      if (value !== 1) {
        this.hobbiesOthers.push(hobbie);
      }

      this.updateData.pets = this.updateData.hobbies.concat(this.hobbiesOthers);
      this.barProgress();
    },
    btnAddHobbieActive(hobbie) {
      if (hobbie.active === 1) {
        hobbie.active = 0;
      } else {
        hobbie.active = 1;
      }
      if (hobbie.name === "Ninguno") {
        this.hobbiesDefault.forEach((e) => {
          if (e.id !== hobbie.id) {
            e.active = 0;
          }
        });
        this.sHobbie([]);
      } else {
        this.hobbiesDefault.forEach((e) => {
          if (e.name === "Ninguno") {
            e.active = 0;
          }
        });
      }
      //Event required to active btn Family
      this.btnAddGender(this.gender);
    },
    btnSubsFamily(index) {
      this.familiesOthers.splice(index, 1);
      this.barProgress();
    },
    btnSubsPet(index) {
      this.petsOthers.splice(index, 1);
      this.barProgress();
    },
    btnSubsHobbie(index) {
      this.hobbiesOthers.splice(index, 1);
      this.barProgress();
    },
    btnSubsChild(index) {
      this.subsChild(index);
      this.barProgress();
    },
    validateData(value) {
      let emailRegex =
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      this.validateMail = 0;
      this.validateCell = 0;

      if (this.updateData.email_personal != null) {
        if (emailRegex.test(value.email_personal)) {
          this.errMail.flag = false;
        } else {
          this.errMail.flag = true;
          this.validateMail++;
        }
      }

      //Validate Cellphone
      if (
        this.user.cellphone != null ||
        (this.updateData.cellphone != null && this.updateData.cellphone !== "")
      ) {
        if (value.cellphone.length === 10) {
          this.errCell.flag = false;
        } else {
          this.errCell.flag = true;
          this.validateCell++;
        }
      }
    },
    makeToast(append = false) {
      this.$bvToast.toast(this.textSave, {
        variant: "success",
        src: this.imgCheck,
        solid: true,
        autoHideDelay: 1000,
        appendToast: append,
      });
    },
    btnSave() {
      this.validateMail = 0;
      this.validateCell = 0;
      this.errMail.flag = false;
      this.errCell.flag = false;
      this.updateData.family = [];
      this.updateData.pets = [];
      this.updateData.hobbies = [];
      this.updateData.children = this.childrens;

      //Families
      this.familiesDefault.forEach((e) => {
        if (e.active === 1) {
          this.updateData.family.push(e.name);
        }
      });
      this.updateData.family = this.updateData.family.concat(
        this.familiesOthers
      );
      //Pets
      this.petsDefault.forEach((e) => {
        if (e.active === 1) {
          this.updateData.pets.push(e.name);
        }
      });
      this.updateData.pets = this.updateData.pets.concat(this.petsOthers);
      //Hobbies
      this.hobbiesDefault.forEach((e) => {
        if (e.active === 1) {
          this.updateData.hobbies.push(e.name);
        }
      });
      this.updateData.hobbies = this.updateData.hobbies.concat(
        this.hobbiesOthers
      );

      if (this.updateData.cellphone || this.updateData.email_personal) {
        this.validateData(this.updateData);
        if (
          this.updateData.cellphone == null &&
          this.updateData.cellphone === ""
        ) {
          delete this.updateData.cellphone;
        }
        if (
          this.updateData.email_personal == null &&
          this.updateData.email_personal === ""
        ) {
          delete this.updateData.email_personal;
        }
        if (this.validateMail === 0 && this.validateCell === 0) {
          this.actUserUpdate(this.updateData);
          this.makeToast();
          this.sFamily(this.familiesOthers);
          this.sPet(this.petsOthers);
          this.sHobbie(this.hobbiesOthers);
        }
      } else {
        if (
          this.user.cellphone != null ||
          (this.updateData.cellphone != null &&
            this.updateData.cellphone !== "")
        ) {
          this.updateData.cellphone = this.user.cellphone;
        } else {
          delete this.updateData.cellphone;
        }
        if (this.updateData.email_personal != null) {
          this.updateData.email_personal = this.user.email_personal;
        } else {
          delete this.updateData.email_personal;
        }

        this.actUserUpdate(this.updateData);
        this.makeToast();
        this.sFamily(this.familiesOthers);
        this.sPet(this.petsOthers);
        this.sHobbie(this.hobbiesOthers);
      }

      if (this.user.prof_picture !== null) {
        this.pic = true;
      }

      this.pushDataLayer();
      setTimeout(() => this.$router.push({ name: "Profile" }), 1000);
    },
    pushDataLayer() {
      window.dataLayer.push({
        event: "TrackEvent-Profile",
        Category: "Profile",
        Action: "Guardar",
        "Nivel del perfil": "Basico",
        Genero: this.gender,
        Vive: this.updateData.family,
        Foto: this.pic,
        Hijos: this.childrens.length,
        Hobbies: this.updateData.hobbies,
        Mascotas: this.updateData.pets,
      });
    },
    pushDataLayerProfile(params) {
      window.dataLayer.push({
        event: "TrackEvent-Profile",
        Category: params.category,
        SubCategory: params.subcategory,
        Action: "Clic",
        Label: params.label,
      });
    },
    async change() {
      let formData = new FormData();
      let contentFile = document.querySelector("#file-input");

      formData.append("prof_picture", contentFile.files[0]);
      this.$refs["modal-sell"].show();

      try {
        const dataResult = await this.actUpdateImageProfile(formData);
        this.$refs["modal-sell"].hide();
        if (dataResult.data) {
          this.user.prof_picture = dataResult.data.prof_picture;
        } else {
          this.error.flag = true;
        }
      } catch (error) {
        this.$refs["modal-sell"].hide();
        this.error.flag = true;
      }
    },
    barProgress() {
      this.bar = 0;
      this.validateMail = 0;
      this.validateCell = 0;
      this.updateData.family = [];
      this.updateData.pets = [];
      this.updateData.hobbies = [];
      this.validateData(this.updateData);

      //Families
      if (this.familiesDefault.length !== 0) {
        this.familiesDefault.forEach((e) => {
          if (e.active === 1) {
            this.updateData.family.push(e.name);
          }
        });
      }
      //Pets
      if (this.petsDefault.length !== 0) {
        this.petsDefault.forEach((e) => {
          if (e.active === 1) {
            this.updateData.pets.push(e.name);
          }
        });
      }
      //Hobbies
      if (this.hobbiesDefault.length !== 0) {
        this.hobbiesDefault.forEach((e) => {
          if (e.active === 1) {
            this.updateData.hobbies.push(e.name);
          }
        });
      }

      if (this.updateData.cellphone != null && this.validateCell === 0) {
        this.bar += 14;
      }
      if (this.updateData.email_personal !== null && this.validateMail === 0) {
        this.bar += 14;
      }
      if (this.user.gender !== null || this.updateData.gender !== "") {
        this.bar += 14;
      }
      if (
        this.familiesOthers.length !== 0 ||
        this.updateData.family.length !== 0
      ) {
        this.bar += 14;
      }
      if (this.childrens.length >= 0) {
        this.bar += 14;
      }
      if (this.petsOthers.length !== 0 || this.updateData.pets.length !== 0) {
        this.bar += 14;
      }
      if (
        this.hobbiesOthers.length !== 0 ||
        this.updateData.hobbies.length !== 0
      ) {
        this.bar += 16;
      }
    },
  },
};
</script>

<style scoped>
.text-green {
  color: #52c49d;
}
.text-yellow {
  color: #ffc141;
}
.text-red {
  color: #d82b2b;
}
.bg-green {
  background: #52c49d;
}
.bg-yellow {
  background: #ffc141;
}
.bg-red {
  background: #d82b2b;
}
.img-up > input {
  display: none;
}

.img-up > input {
  display: none;
}
.img-camera {
  width: 25px;
  height: 25px;
}
.img-up {
  position: absolute;
  top: 11%;
  left: 69%;
}
.img-up {
  position: absolute;
  top: 42px;
  left: 62%;
}
@media (min-width: 325px) {
  .img-up {
    position: absolute;
    top: 45px;
    left: 60%;
  }
}
@media (min-width: 520px) {
  .img-up {
    position: absolute;
    top: 45px;
    left: 57%;
  }
}

@media (min-width: 600px) {
  .img-up {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -130px;
    left: 66%;
  }
}
@media (min-width: 700px) {
  .img-up {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -130px;
    left: 65%;
  }
}
@media (min-width: 760px) {
  .img-up {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -130px;
    left: 85%;
  }
}
@media (min-width: 900px) {
  .img-up {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -135px;
    left: 77%;
  }
}
@media (min-width: 1000px) {
  .img-up {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -135px;
    left: 67%;
  }
}
.toast-edit {
  background: #bababa;
}
.cont-row {
  padding-left: 12px;
  padding-right: 10px;
}
.text-brand {
  color: #d82b2b;
}
.text-btns {
  font: normal normal 15px Roboto;
  color: #bababa;
}
.text-box-mobile {
  font: normal normal normal 16px/19px Roboto;
  letter-spacing: 0;
  color: #34343459;
  opacity: 1;
}
.cont-fam {
  width: 50px;
}
.profile .icon {
  height: 20px;
  margin-left: 20px;
  margin-top: -0px;
}
.profile .icon-edit {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 32px;
  top: 16px;
}
.content-card {
  padding-top: 25px;
}
.content-card .card {
  border-radius: 8px;
}
.content-card .card .card-header {
  background-color: transparent;
}
.profAvatar {
  background-position: center;
  background-size: cover;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  min-height: 200px;
  width: 100%;
}
.profAvatar div img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.profAvatar .icon {
  width: 20px;
  height: 20px;
}
.profAvatar p {
  color: #000000;
}
.profBtn {
  border: 0 solid #cccccc;
}
.router {
  color: #333333;
  text-decoration: none;
}
.text-color-baba {
  color: #717171;
}
.shadow {
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 20px;
  opacity: 1;
}
.box-cont {
  background: #f4f4f4 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.cont-form {
  border-radius: 0.25rem;
  background: #fff;
  padding: 0.375rem 0.75rem;
  width: 100%;
  height: 60px;
  color: #ced4da;
  box-shadow: 0 3px 6px #00000029;
}
.cont-type {
  border: 1px solid #ced4da;
  border-radius: 6px;
  background: #fff;
  width: 100%;
  height: 100%;
  color: #ced4da;
}
.cont-type-mobile {
  background: #bababa40 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.btn-life {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
  padding: 0.375rem 0.75rem;
  width: 100%;
  height: 40px;
  color: #ced4da;
}
.btn-life:hover {
  background: #d82b2b;
  color: #fff;
}
.btn-life:focus {
  background: #d82b2b;
  color: #fff;
}
.btn-life-with:hover {
  background: #d82b2b;
  color: #fff;
}
.btn-default:hover {
  box-shadow: 0 3px 6px #00000040;
}
.btn-default:focus {
  box-shadow: none;
}
.btn-quit-d {
  position: absolute;
  top: 48%;
  left: 82%;
  transform: translate(-50%, -50%);
}
.btn-quit {
  position: absolute;
  top: 48%;
  left: 88%;
  transform: translate(-50%, -50%);
}
.btn-life-with {
  border: 1px solid #d82b2b;
  font: normal normal 14px/17px Roboto;
  border-radius: 0.25rem;
  background: #fff;
  width: 100%;
  height: 38px;
  color: #d82b2b;
}
.btn-active-with {
  border: 1px solid #d82b2b;
  font: normal normal 14px/17px Roboto;
  border-radius: 0.25rem;
  background: #d82b2b;
  width: 100%;
  height: 38px;
  color: #fff;
}
.btn-selected {
  border: 1px solid #bababa;
  border-radius: 0.25rem;
  background: #fff;
  width: 100%;
  height: 38px;
  color: #bababa;
}
.btn-default {
  border: 1px solid #bababa;
  border-radius: 0.25rem;
  background: #fff;
  width: 100%;
  height: 38px;
  color: #bababa;
}
.btn-active {
  border: 1px solid #999999;
  border-radius: 0.25rem;
  background: #52c49d;
  width: 100%;
  height: 38px;
  color: #ffffff;
}
.btn-active:active {
  border: 1px solid #999999;
  border-radius: 0.25rem;
  background: #d82b2b;
  width: 100%;
  height: 38px;
  color: #ffffff;
}
.btn-child {
  border: 1px solid #bababa;
  border-radius: 0.25rem;
  background: #fff;
  padding: 0.4rem 0.9rem;
  width: 13%;
  height: 40px;
  color: #343434;
}
.content-childs {
  border-radius: 0.3rem;
  background: #fff;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 3px 6px #00000029;
}

.tamN {
  font-size: 18px;
  color: #bababa;
}

.cont-save {
  margin-bottom: 120px;
}
.cont-save .btn-save {
  border: 1px solid #d82b2b;
  border-radius: 8px;
  background: #d82b2b;
  color: #fff;
}
.cont-save .btn-save:hover {
  background-color: #8a0000 !important;
}
.cont-save .btn-save {
  outline: none;
}

.group-check {
  border: 1px solid #bababa;
  border-radius: 30px;
  background: #fff;
  color: #bababa;
}
.text-font {
  font: normal normal bold 14px/17px Roboto;
  color: #222;
}
.text-bar {
  color: #222;
}
.tw-text-black {
  color: #0f0e0e;
}
.text-brand {
  font: normal normal bold 18px/22px Roboto;
  color: #d82b2b;
}
.imgHeight {
  width: 20px;
}

.box-text-save-mobile {
  position: fixed;
  right: 100px;
  top: 100px;
  border-radius: 8px;
  opacity: 1;
}
button {
  border: 0;
  background: #fff;
  color: #d82b2b;
}
button:focus {
  outline: none;
}
.icon-quit {
  width: 16px;
}
.form-group {
  margin-top: 18px;
}
@media (min-width: 600px) {
  .box-sha {
    box-shadow: 0 3px 6px #00000029;
  }
}
/* SMALL DISPLAY */
@media (max-width: 600px) {
  .box-text-save-mobile {
    position: fixed;
    right: 60px;
    left: 60px;
    top: 100px;
    border-radius: 8px;
    opacity: 1;
  }
  .desk-header {
    display: none;
  }
  .content-card {
    padding: 8px;
  }
  .top-up {
    margin-top: -10px;
  }
  .content-card-avatar {
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0;
  }
  .content-card-avatar .card {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .content-card-avatar .profAvatar {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -20px;
  }
  .profBtn {
    background-color: #ffffff;
  }
  .btn-child {
    width: 18%;
  }
}
</style>
