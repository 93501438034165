var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-rounded-2.5xl lg:tw-rounded-3xl tw-border tw-border-gray-300 tw-px-3 tw-py-2 lg:tw-p-4 tw-flex tw-flex-col tw-justify-between tw-gap-2 lg:tw-gap-3"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2 lg:tw-gap-3",attrs:{"id":"name_img"}},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-center"},[_vm._v(" "+_vm._s(_setup.props.product.name)+" ")]),_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-center"},[_vm._v(" "+_vm._s(_setup.props.product?.people_tour_stage?.name)+" ")]),_c('div',{staticClass:"tw-py-2 tw-px-4"},[_c('div',{staticClass:"tw-aspect-h-1 tw-aspect-w-1"},[_c('img',{staticClass:"tw-w-full tw-rounded-2xl lg:tw-rounded-2.5xl tw-object-cover",attrs:{"src":_setup.uriResources + '/' + _setup.props.product.image,"alt":""}})])])]),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-gap-2"},[_c('img',{staticClass:"tw-h-5 tw-w-5 lg:tw-h-7 lg:tw-w-7",attrs:{"src":require("@/assets/img/peopleTour/icons/icono-toin.png"),"alt":""}}),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm"},[_vm._v(" "+_vm._s(_setup.props.product.toins + " Toins")+" ")])]),(_vm.quantityVisible)?_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm tw-text-red-350 tw-text-center"},[_vm._v(" "+_vm._s(_setup.props.product.qty_balance <= 0 ? "Agotado" : "Cant. disponible: " + _setup.props.product?.qty_balance)+" ")]):_vm._e(),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm tw-line-clamp-5"},[_vm._v(" "+_vm._s(_setup.props.product.description)+" ")]),(_vm.redeemButton && _setup.props.product?.qty_balance > 0)?_c('div',{class:_setup.isReedemable && _vm.isActive
        ? 'tw-btn-primary-red'
        : 'tw-btn-primary-disabled',on:{"click":function($event){_setup.isReedemable && _vm.isActive
        ? _setup.router.push({
            name: 'peopleTourRewardsProduct',
            params: { id: _setup.props.product.id },
          })
        : null}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v("Redimir")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }