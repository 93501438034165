var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-social-network mt-3 mb-3 text-center text-md-left"},[_c('div',{staticClass:"row m-auto"},[_c('div',{on:{"click":function($event){return _vm.pushDataLayer(
          'facebook',
          _vm.category,
          _vm.subcategory,
          _vm.brand,
          _vm.variant,
          _vm.contribute,
          _vm.farming,
          _vm.videoname
        )}}},[_c('facebook',{staticClass:"mx-1",attrs:{"url":_vm.url,"scale":"2"}})],1),_c('div',{on:{"click":function($event){return _vm.pushDataLayer(
          'twitter',
          _vm.category,
          _vm.subcategory,
          _vm.brand,
          _vm.variant,
          _vm.contribute,
          _vm.farming,
          _vm.videoname
        )}}},[_c('twitter',{staticClass:"mx-1",attrs:{"url":_vm.url,"title":_vm.title,"scale":"2"}})],1),_c('div',{on:{"click":function($event){return _vm.pushDataLayer(
          'whatsapp',
          _vm.category,
          _vm.subcategory,
          _vm.brand,
          _vm.variant,
          _vm.contribute,
          _vm.farming,
          _vm.videoname
        )}}},[_c('whats-app',{staticClass:"mx-1",attrs:{"url":_vm.url,"title":_vm.title,"scale":"2"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }