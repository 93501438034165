<script setup>
import { onMounted } from "vue";
import TheFooter from "@/components/general/TheFooter.vue";
import MainNavbar from "@/components/general/MainNavbar";
import MainSidebar from "@/components/general/MainSidebar";
import ProgressBar from "@/components/general/ProgressBar.vue";
import SupportButton from "@/components/general/SupportButton.vue";
import CompGeneralAdvertising from "@/components/general/CompGeneralAdvertising.vue";
import CompBookingStates from "@/components/reservations/CompBookingStates.vue";
import CompBookingModal from "@/components/reservations/CompBookingModal.vue";
import nps from "../services/nps";
import CompGoFlexStates from "@/components/goFlex/CompGoFlexStates.vue";
import NpsModal from "@/components/nps/NpsModal.vue";
import { useUserStore } from "@/stores/userStore";
import { useNavbarStore } from "@/stores/navbarStore";
import { useRoute } from "vue-router/composables";
import { storeToRefs } from "pinia";

const { showModalNpsPoll } = nps;
const { user } = storeToRefs(useUserStore());
const navbarStore = useNavbarStore();
const route = useRoute();

const loadModalNps = () => {
  if (nps.NPS_URL !== "" && nps.NPS_APP !== "") {
    try {
      showModalNpsPoll(
        user.value.id_sharp,
        nps.NPS_APP,
        getEnvironment(),
        getStyle()
      );
    } catch (error) {
      //
    }
  }
};

const getEnvironment = () => {
  const dom = window.location.hostname;
  let env;
  if (dom.includes("dev") || dom.includes("localhost")) {
    env = "dev";
  } else if (dom.includes("stage")) {
    env = "stage";
  } else {
    env = "prod";
  }
  return env;
};

const getStyle = () => {
  return {
    titleNps: {
      style: {
        color: "",
      },
      classList: ["tw-text-red-400"],
    },
    btnYes: {
      style: {
        background: "",
        borderRadius: "",
        color: "",
        padding: "",
        border: "",
        cursor: "",
        fontSize: "",
      },
      classList: [
        "tw-mt-5tw-text-center",
        "tw-text-sm",
        "md:tw-text-base",
        "tw-cursor-pointer",
        "tw-text-white",
        "tw-border",
        "tw-border-red-400",
        "tw-p-2",
        "tw-rounded-2xl",
        "tw-bg-red-400",
        "hover:tw-bg-red-600",
        "hover:tw-text-white",
        "hover:tw-border-red-600",
      ],
    },
    btnNo: {
      style: {
        background: "",
        borderRadius: "",
        color: "",
        padding: "",
        border: "",
        cursor: "",
        fontSize: "",
      },
      classList: [
        "tw-mt-5tw-text-center",
        "tw-text-sm",
        "md:tw-text-base",
        "tw-cursor-pointer",
        "tw-text-red-400",
        "tw-border",
        "tw-border-red-400",
        "tw-p-2",
        "tw-rounded-2xl",
        "tw-bg-transparent",
        "hover:tw-border-red-600",
        "hover:tw-text-red-600",
      ],
    },
  };
};
onMounted(async () => {
  loadModalNps();
});
</script>
<template>
  <div>
    <main-navbar />
    <main-sidebar />
    <portal-target name="modal-thanks-suggestion" />
    <portal-target name="modal-suggestions" />
    <portal-target name="modal-change-password" />
    <portal-target name="outer-modals" />
    <div
      id="content-body"
      class="tw-pt-16 md:tw-pt-11 md:tw-pl-20 lg:tw-pt-[100px] lg:tw-pl-[70px]"
    >
      <portal-target name="all-modals" />
      <nps-modal />
      <div class="modal-nps-poll"></div>
      <comp-general-advertising />
      <progress-bar overlay></progress-bar>
      <div>
        <slot />
      </div>
      <comp-go-flex-states
        v-if="
          route.path.includes('goflex') &&
          route.name !== 'goFlexRequestsHistory'
        "
      />
      <support-button
        v-if="
          route.name !== 'NewRequest' ||
          (route.name !== 'Login' && navbarStore.isSupportButtonOpen)
        "
      />
      <comp-booking-states
        v-if="
          route.path.includes('reservations') && route.name !== 'BookingHistory'
        "
      />
      <comp-booking-modal v-if="route.path.includes('reservations')" />
    </div>
    <TheFooter />
  </div>
</template>
