<script setup>
import { ref, onBeforeMount } from "vue";
import config from "@/services/config";
import { useSocialNetworkStore } from "@/stores/socialNetworkStore";

const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const store = useSocialNetworkStore();

const emit = defineEmits(["updateReaction", "closeModal", "reactionDatalayer"]);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
  reaction: {
    validator(value) {
      return typeof value === "object" || value == null;
    },
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
});

const updateReaction = (reaction, action) => {
  emit("updateReaction", reaction, action);
  emit("closeModal");
};

function sendReaction(reaction_id) {
  if (props.reaction == null || props.reaction.reaction.id !== reaction_id) {
    store
      .actPostReactions({
        post_id: props.id,
        post_category: props.category,
        reaction_id: reaction_id,
      })
      .then((response) => {
        updateReaction(response, "post");
      });
  } else if (
    props.reaction.reaction &&
    reaction_id === props.reaction.reaction.id
  ) {
    store.actDeleteReactions(props.reaction.id).then((response) => {
      updateReaction(response, "delete");
    });
  }
}

onBeforeMount(() => {
  isLoading.value = true;
  if (store.reactions.length === 0) {
    store.actGetReactions().finally(() => {
      isLoading.value = false;
    });
  } else {
    isLoading.value = false;
  }
});
</script>
<template>
  <div class="tw-h-full tw-my-2">
    <div class="text-center" v-if="isLoading">
      <img src="@/assets/img/icons/loading.gif" class="tw-w-7" alt="loading" />
    </div>
    <div
      v-else
      class="tw-flex tw-justify-between tw-items-start tw-text-center tw-gap-x-2"
    >
      <div
        v-for="(reaction, index) in store.reactions"
        :key="index"
        class="tw-flex-1 icons"
        @click="sendReaction(reaction.id, reaction.name)"
      >
        <img
          :src="uriResources + '/' + reaction.icon"
          alt="image"
          class="tw-h-5 lg:tw-h-7"
        />
        <p
          class="tw-font-robotoBold tw-text-xxxs md:tw-text-xxs tw-mt-0.5 tw-mb-0 tw-whitespace-nowrap"
        >
          {{ reaction.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icons p {
  display: none;
}

.icons:hover p {
  display: block;
}
</style>
