<template>
  <div class="container my-md-3">
    <div class="row py-md-2 align-items-center">
      <div class="col-12 col-md-7">
        <SecondaryNavbar title="Reporta una novedad" :breadcrumb="breadcrumb" />
      </div>
      <div class="col-12 col-md-5 d-none d-md-block">
        <CompNavigationOptions selected="Reportar" />
      </div>
    </div>
    <div class="container mt-4 mb-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <form>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <b-dropdown
                    id="dropdown-1"
                    v-model="pqr.rv_date"
                    :text="
                      pqr.rv_date === null ? 'Mes' : formatDate(pqr.rv_date)
                    "
                    block
                    split
                    split-variant="outline-primary"
                    class="w-100"
                  >
                    <slot v-if="user.payments.length === 0">
                      <b-dropdown-item
                        href="#"
                        @click="setDate(getCurrentDate)"
                        class="w-100 text-uppercase"
                        :value="getCurrentDate"
                      >
                        {{ getCurrentDate | moment("MMMM") }}
                      </b-dropdown-item>
                    </slot>
                    <slot v-else>
                      <b-dropdown-item
                        href="#"
                        v-for="(item, index) in user.payments"
                        :key="index"
                        :value="item.date"
                        @click="setDate(item.date)"
                        class="w-100 text-uppercase"
                      >
                        {{ item.date | moment("MMMM") }}
                      </b-dropdown-item>
                    </slot>
                  </b-dropdown>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label
                    for="exampleFormControlTextarea1"
                    class="name-label w-100"
                  >
                    <textarea
                      v-model="pqr.message"
                      class="form-control"
                      placeholder="Ingresa el texto acá"
                    >
                    </textarea>
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-center col-12 pt-md-2 pt-sm-1">
                <button
                  @click="sendPqr('b-toaster-top-right')"
                  type="button"
                  class="btn btn-see-more d-none d-md-block"
                >
                  Enviar
                </button>
                <button
                  @click="sendPqr('b-toaster-top-center')"
                  type="button"
                  class="btn btn-see-more d-block d-sm-block d-md-none"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="cont-img">
            <img
              src="@/assets/img/show-me/report.jpg"
              alt="show payments"
              class="bgimg w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

import SecondaryNavbar from '@/components/general/SecondaryNavbar'
import CompNavigationOptions from '@/components/show-me-the-money/variable-compensation/CompNavigationOptions'

export default {
  name: "VariableCompensationTickets",
  components: {
    SecondaryNavbar,
    CompNavigationOptions,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración variable",
          link: "/showMeTheMoney/remuneracion-variable",
        },
        {
          id: 5,
          name: "Reporta una novedad",
          link: "/showMeTheMoney/remuneracion-reportes",
          active: true,
        },
      ],
      pqr: {
        section: "RV_NOVEDAD",
        message: null,
        rv_date: null,
      },
      toastCount: 0,
    };
  },
  mounted() {
    window.dataLayer.push({
      event: "Reportar Compensación Variable",
      Area: this.user.core_vp.name,
      Banda: this.user.core_pa_sideline.name,
      Cargo: this.user.core_position.name,
      Regional: this.user.core_site.core_regional.name,
      Gerencia: this.user.core_organizational_unit.name,
    });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    getCurrentDate() {
      return new Date();
    },
  },
  methods: {
    ...mapActions("modGeneral", ["actPostPqr"]),
    setDate(date) {
      this.pqr.rv_date = date;
    },
    formatDate(date) {
      return moment(date).format("MMMM").toUpperCase();
    },
    async sendPqr(toastPosition) {
      if (this.pqr.rv_date !== null && this.pqr.message !== null) {
        this.pqr.rv_date = moment(this.pqr.rv_date).format("YYYY/MM");

        let response = await this.actPostPqr(this.pqr);
        if (response === true) {
          this.$bvToast.toast("Tu solicitud fue enviada con éxito", {
            autoHideDelay: 5000,
            toastClass: "successmessage",
            solid: true,
            noCloseButton: true,
            toaster: toastPosition,
          });
        } else {
          this.$bvToast.toast("Hubo un error enviando el mensaje", {
            autoHideDelay: 5000,
            toastClass: "wrongmessage",
            solid: true,
            noCloseButton: true,
            toaster: toastPosition,
          });
        }
      } else {
        this.$bvToast.toast("Uno o más campos estan sin completar", {
          autoHideDelay: 5000,
          toastClass: "wrongmessage",
          solid: true,
          noCloseButton: true,
          toaster: toastPosition,
        });
      }
      this.pqr.rv_date = null;
      this.pqr.message = null;
    },
  },
};
</script>
<style>
.successmessage {
  margin-top: 10%;
  background: #00bf79 !important;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  height: 50px;
}

.wrongmessage {
  margin-top: 10%;
  background: #d82b2b !important;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  height: 50px;
}

#dropdown-1__BV_toggle_ {
  background-color: #ffffff !important;
  color: #000000 !important;
  border-color: #ced4da !important;
  /*border-left: none;*/
}

.btn-outline-primary {
  color: #000 !important;
  border: 1px solid #ced4da !important;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #ffffff !important;
  border: 1px solid #ced4da !important;
}

.dropdown-menu.show {
  display: block;
  width: 100%;
  text-align: center;
}
.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #212529;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding: 0.5rem !important;
  text-align: center;
}
</style>
<style scoped>
.icon {
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

#year {
  color: #999999 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent url("data:image/svg+xml;utf8,<svg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-chevron-down' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") no-repeat;
  background-position-x: 99%;
  background-position-y: 10px;
  border: 1px solid #dfdfdf;
  margin-right: 2rem;
  padding-right: 2rem;
  border-radius: 8px;

  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0 ;
  color: #999999 !important;
  opacity: 1;
}

textarea.form-control {
  height: auto;
  border-radius: 8px;
  text-align: justify;
}

textarea {
  resize: none;
  font: normal normal normal 16px/21px Roboto !important;
  color: #000000 !important;
  height: calc(40vh) !important;
  width: 100%;
}

.bgimg {
  border-radius: 16px 0 0 16px;
  width: 95% !important;
  height: calc(50vh);
}

.btn-see-more {
  color: #fff;
  background-color: #d82b2b;
  border-color: #d82b2b;
  width: 70%;
}

.btn-see-more:hover {
  color: #fff;
  background-color: #d82b2b;
  border-color: #d82b2b;
  width: 70%;
}

@media (max-width: 768px) {
  .btn-see-more {
    color: #fff;
    background-color: #d82b2b;
    border-color: #d82b2b;
    width: 100%;
  }
  .btn-see-more:hover {
    color: #fff;
    background-color: #d82b2b;
    border-color: #d82b2b;
    width: 100%;
  }
}
</style>
