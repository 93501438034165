<template>
  <div class="w-100 py-md-5 py-3 px-3 d-flex justify-content-center">
    <div
      class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 text-center w-100 max-wi-md-320"
      role="button"
      @click="pushDataLayer()"
      v-b-modal.modalStatusProblems
    >
      <p class="mb-0 f-18 font-weight-bold">¿Tienes problemas con tu pedido?</p>
    </div>
    <b-modal id="modalStatusProblems" hide-footer>
      <div>
        <p class="mt-3 mt-md-0 tw-text-base tw-text-gray-600">
          Si tienes algún inconveniente con tu producto obsequio o con tus
          compras en Mundo Embajada, envía un correo a:
        </p>
        <ul class="tw-text-gray-400">
          <li>santiago.magallanesHerrera@ab-inbev.com</li>
          <li>josei.gomezd@ab-inbev.com</li>
        </ul>
        <p class="tw-text-base tw-text-gray-600">
          Recuerda incluir la siguiente información:
        </p>
        <ul class="tw-text-gray-400">
          <li>Tu documento de identidad</li>
          <li>Tu nombre completo</li>
          <li>El detalle de tu pedido (producto/cantidad/Obsequio o compra)</li>
          <li>El sitio de entrega</li>
          <li>
            Una imagen que confirme tu pedido a través de Mundo Embajada
            <router-link
              class="tw-text-gray-400 hover:tw-text-gray-600 tw-underline"
              to="/e-commerce/cart/history"
            >
              (Consúltalo en el historial)
            </router-link>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CompFooterEcommerceModal",
  methods: {
    pushDataLayer() {
      window.dataLayer.push({
        event: 'TrackEvent-Tienda',
        Category: 'Tienda',
        Action: 'Click',
        Name: 'Tienes problemas con tu pedido'
      })
    }
  },
};
</script>