import { render, staticRenderFns } from "./TimeBenefitsHistory.vue?vue&type=template&id=a5d98bea&scoped=true"
import script from "./TimeBenefitsHistory.vue?vue&type=script&setup=true&lang=js"
export * from "./TimeBenefitsHistory.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5d98bea",
  null
  
)

export default component.exports