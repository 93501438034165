<template>
  <div class="tw-container-general">
    <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
      <div
        class="tw-w-6/12 lg:tw-w-3/12 tw-h-6 lg:tw-h-8 tw-bg-gray-300 tw-rounded-full tw-animate-pulse tw-place-self-center lg:tw-place-self-end"
      ></div>
      <div
        class="tw-w-full tw-rounded-3xl tw-border tw-border-gray-300 tw-p-6 lg:tw-px-5 lg:tw-py-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-8"
      >
        <div class="lg:tw-col-span-1 tw-flex tw-flex-col tw-gap-3">
          <div
            class="tw-w-2/3 tw-h-6 lg:tw-h-8 tw-bg-gray-300 tw-rounded-full tw-animate-pulse lg:tw-hidden"
          ></div>
          <div class="tw-aspect-h-1 tw-aspect-w-1 tw-max-h-32">
            <div
              class="tw-rounded-2xl tw-bg-gray-300 tw-w-full tw-h-full tw-animate-pulse"
            ></div>
          </div>
        </div>
        <div
          class="lg:tw-col-span-3 tw-flex tw-flex-col tw-justify-start tw-gap-4"
        >
          <div
            class="tw-flex tw-items-center tw-justify-center lg:tw-justify-between"
          >
            <div
              class="tw-w-1/3 tw-h-6 lg:tw-h-8 tw-bg-gray-300 tw-rounded-full tw-animate-pulse tw-hidden lg:tw-block"
            ></div>
            <div class="tw-flex tw-gap-2 tw-items-center">
              <img
                src="../../../assets/img/peopleTour/icons/icono-toin.png"
                class="tw-h-5 tw-w-5 lg:tw-h-7 lg:tw-w-7"
                alt=""
              />
              <div
                class="tw-w-24 tw-h-6 lg:tw-h-8 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
              ></div>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div
              class="tw-w-full tw-h-2 lg:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
            ></div>
            <div
              class="tw-w-full tw-h-2 lg:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
            ></div>
            <div
              class="tw-w-full tw-h-2 lg:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
            ></div>
            <div
              class="tw-w-full tw-h-2 lg:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
            ></div>
          </div>
          <div
            class="tw-w-full lg:tw-w-3/12 tw-h-6 lg:tw-h-8 tw-bg-gray-300 tw-rounded-full tw-animate-pulse tw-place-self-end"
          ></div>
        </div>
      </div>
      <div
        class="tw-w-full tw-h-2 lg:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
      ></div>
      <div
        class="tw-w-full tw-h-2 lg:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-animate-pulse"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonPeopleTourRewardsProduct",
};
</script>

<style scoped></style>
