<template>
  <div class="mt-0 mt-md-4">
    <div
      class="container-fluid gamification h-100 p-5"
      :style="{
        backgroundImage: `url('${imgBgGamification}')`,
      }"
    >
      <div class="row">
        <div class="col-12" v-if="isLoading">
          <div class="alert text-center">
            <img src="../../assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
        <div v-else class="col-12 col-md-10 col-lg-8 mx-auto position-relative">
          <swiper
            :slides-per-view="swiperOptions.slidesPerView"
            :space-between="swiperOptions.spaceBetween"
            :loop="swiperOptions.loop"
            :pagination="swiperOptions.pagination"
            :navigation="swiperOptions.navigation"
            class="bg-white tw-rounded-3xl tw-z-1"
          >
            <swiper-slide
              v-for="(slide, index) in howItWorkSlides"
              :key="slide.id"
              class="pt-4 pr-4 pl-4 pb-5"
            >
              <router-link
                v-if="slideLen - 1 === index"
                to="/gamification"
                class="icon-cerrar-simple btn-close position-absolute tw-text-red-400 fs-30 tw-right-5 tw-top-5"
              >
              </router-link>
              <p
                class="tw-font-robotoBold tw-text-xl fs-md-40 mt-5 mt-md tw-text-red-400 text-center mb-4"
              >
                {{
                  slide.title.substr(0, slide.title.indexOf(" ")) +
                  " " +
                  slide.title
                    .substr(slide.title.indexOf(" ") + 1)
                    .charAt(0)
                    .toUpperCase() +
                  slide.title
                    .substr(slide.title.indexOf(" ") + 1)
                    .toLowerCase()
                    .slice(1)
                }}
              </p>
              <div class="text-center">
                <img
                  :src="uriResources + '/' + slide.image"
                  alt="slide 1"
                  class="my-4 max-wi-400 max-he-250 wi-md-auto wi-100p h-auto"
                />
              </div>
              <p class="tw-font-roboto tw-text-sm fs-md-18 text-center">
                {{ slide.text }}
              </p>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div
            class="d-flex justify-content-center align-items-center arrows tw-left-0 wi-25 he-25 wi-md-40 he-md-40 bg-white tw-rounded-full position-absolute tw-top-2/4 tw-shadow-md btn-prev z-index-2"
          >
            <span
              class="icon icon-flecha-izq tw-text-red-400 fs-24 fs-md-30 line-height-34"
            ></span>
          </div>
          <div
            class="d-flex justify-content-center align-items-center arrows tw-right-0 wi-25 he-25 wi-md-40 he-md-40 bg-white tw-rounded-full position-absolute tw-top-2/4 tw-shadow-md btn-next z-index-2"
          >
            <span
              class="icon icon-flecha-der tw-text-red-400 fs-24 fs-md-30 line-height-34"
            ></span>
          </div>
        </div>
        <!-- .col -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";
// Import Swiper
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);

export default {
  name: "gamificationHowItWork",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      isLoading: false,
      imgBgGamification: require("@/assets/img/gamification/background.png"),
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 15,
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".btn-next",
          prevEl: ".btn-prev",
        },
      },
      slideLen: 0,
      nextSlide: 0,
      prevSlide: 0,
    };
  },
  created() {
    this.isLoading = true;
    this.assignDefaultGamificationHelpValue();
    this.actGetGamificationHelp()
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  mounted() {
    this.nextSlide = 0;
    //this.slideLen = this.howItWorkSlides.length;
    this.nextSlide = this.slideLen;
  },
  computed: {
    ...mapState("modGamification", ["howItWorkSlides"]),
  },
  methods: {
    ...mapActions("modGamification", ["actGetGamificationHelp"]),
    assignDefaultGamificationHelpValue() {
      setTimeout(() => {
        this.slideLen = this.howItWorkSlides.length;
      }, 2000);
    },
  },
};
</script>

<style lang="css" scoped>
.gamification {
  background-size: cover;
}
</style>

<style scoped lang="css">
.gamification .carousel-cell-1 {
  min-height: 500px !important;
}

.arrows {
  top: calc(50% - 20px);
}

@media (max-width: 768px) {
  .arrows {
    top: calc(50% - 13px);
  }
}
</style>
