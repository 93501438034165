var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-mb-4 md:tw-mb-5"},[_c('div',{staticClass:"row mt-4"},_vm._l((_setup.props.content),function(item,idx){return _c('div',{key:idx,staticClass:"mb-4 col-12 col-md-6 he-300",class:item.columns_class},[_c('router-link',{attrs:{"to":{
          name: 'Article Categories',
          params: {
            id: item.id,
            Category: _setup.props.category,
            SubCategory: item.name,
          },
        }}},[_c('div',{staticClass:"p-2 text-center h-100 tw-bg-cover tw-bg-center",style:({
            'background-image':
              '-webkit-gradient(linear, left 20, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.6))), url(' +
              _setup.uriResources +
              '/' +
              item.image +
              ')',
          }),on:{"click":function($event){return _setup.pushDataLayer(item.name)}}},[_c('div',{staticClass:"w-100 h-100 d-flex justify-content-center align-items-center"},[_c('p',{staticClass:"tw-text-white tw-font-tolyerBold fs-40"},[_vm._v(" "+_vm._s(item.name)+" ")])])])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }