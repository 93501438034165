const utilitiesComponents = {
  methods: {
    showToast(
      title = null,
      message = null,
      variant = "info",
      toaster = "b-toaster-top-right",
      append = false
    ) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: toaster,
        appendToast: append,
        solid: true,
      });
    },
  },
};

export default utilitiesComponents;
