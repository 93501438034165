<script setup>
import { onBeforeMount, ref } from "vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useCoreStore } from "@/stores/coreStore";
import { useRouter } from "vue-router/composables";
import { pushLink } from "@/helpers/linkHelpers";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";

const router = useRouter();
const isLoading = ref(false);
const ecommerceStore = useEcommerceStore();
const coreStore = useCoreStore();
const max_day_gift = ref(null);
const currentDay = ref(new Date().getDate());
const showModal = ref(false);
const taDaCoupon = ref(null);
const due_date = ref(null);
const isErrorModalOpen = ref(false);

const requestTaDa = async () => {
  try {
    const response = await ecommerceStore.actPostTaDaRequest();
    if (response) {
      taDaCoupon.value = response.code;
      due_date.value = response.due_date;
      showModal.value = true;
    } else {
      isErrorModalOpen.value = true;
    }
  } catch (error) {
    console.error("Error requesting TaDa:", error);
  }
  isErrorModalOpen.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const closeErrorModal = () => {
  isErrorModalOpen.value = false;
};

const downloadGuestFile = (url) => {
  let downloadURL = coreStore.getURLResourceByKey(url);
  let a = document.createElement("a");
  a.href = downloadURL;
  a.click();
};

onBeforeMount(async () => {
  isLoading.value = true;
  await ecommerceStore.actGetTaDaStatus();
  await ecommerceStore.actGetProducts();
  max_day_gift.value = ecommerceStore.products.filter(
    (product) => product.name === "OBSEQUIO"
  )[0].max_day;

  if (
    !ecommerceStore.taDaDisplayable ||
    currentDay.value > max_day_gift.value
  ) {
    await router.push("/e-commerce");
  }

  await coreStore.actGetSharedResources();
  isLoading.value = false;
});
</script>

<template>
  <div>
    <!-- modal de cupón exitoso -->
    <general-modal
      :hide-button-support="true"
      v-if="showModal"
      :is-show-modal="showModal"
      :modalWidth="{ md: 600, lg: 700, xl: 700 }"
      :maxHeight="{ base: 500, md: 500, lg: 400, xl: 400 }"
      modal-name="TaDa"
      @close="closeModal"
    >
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-3 lg:tw-gap-6"
      >
        <div class="tw-col-span-1 tw-flex tw-items-center">
          <img
            src="@/assets/img/ecommerce/compra-exitosa.png"
            class="tw-object-contain tw-w-full"
            alt=""
          />
        </div>
        <div
          class="tw-col-span-1 lg:tw-col-span-2 tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6"
        >
          <p class="tw-m-0 tw-text-center tw-font-robotoBold tw-text-xl">
            Disfruta de tu producto
          </p>
          <p
            class="tw-m-0 tw-text-center tw-font-roboto tw-text-xs lg:tw-text-base"
          >
            Tu código de TaDa es:
          </p>
          <p
            class="tw-m-0 tw-text-center tw-font-robotoBold tw-text-xl lg:tw-text-2xl"
          >
            {{ taDaCoupon }}
          </p>
          <p
            class="tw-m-0 tw-text-center tw-font-roboto tw-text-xs lg:tw-text-base"
          >
            Puedes usarlo una sola vez a partir de este momento y hasta el día
            {{ formatDateYYYYMMDDLong(due_date) }}. Consúltalo cuando quieras
            dentro de 'Mis Pedidos'.
          </p>
          <div class="tw-flex tw-gap-3 lg:tw-gap-6 tw-w-full">
            <comp-button-custom
              class="tw-w-full"
              text="Volver a la tienda"
              @click.native="router.push('/e-commerce')"
            />
            <button-primary-red
              title="Ver mis pedidos"
              class="tw-w-full tw-my-2 lg:tw-my-4"
              @click.native="router.push('/e-commerce/shoppingHistory')"
            />
          </div>
        </div>
      </div>
    </general-modal>
    <!-- modal de error -->
    <general-modal
      v-if="isErrorModalOpen"
      :isShowModal="isErrorModalOpen"
      :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
      modal-name="couponError"
      @close="closeErrorModal"
    >
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2"
      >
        <img alt="" src="@/assets/img/modal/modal-error.svg" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-red-350"
        >
          Lo sentimos
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
        >
          ¡Algo ha salido mal! En este momento no hemos podido realizar tu
          solicitud, inténtalo de nuevo mas tarde.
        </p>
      </div>
    </general-modal>
    <mobile-bar title="Tienda" />
    <title-bar title="Tienda" />
    <main class="tw-container-general" v-if="!isLoading">
      <p class="tw-font-robotoBold tw-text-base lg:tw-text-2xl">
        CARRITO DE COMPRA
      </p>
      <p class="tw-text-center tw-font-robotoBold tw-text-xs lg:tw-text-lg">
        ¿Estás seguro de que deseas elegir este obsequio para este mes?
      </p>
      <section id="couponDetail">
        <div
          class="tw-border tw-rounded-xl lg:tw-rounded-2xl tw-px-2 tw-py-3 lg:tw-px-4 lg:tw-py-5 tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-4"
        >
          <div
            class="tw-col-span-1 lg:tw-col-span-2 tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2 lg:tw-gap-4 tw-px-4"
          >
            <img
              src="@/assets/img/ecommerce/logo-tada.png"
              alt="Cupón TaDa"
              class="tw-w-1/2 lg:tw-w-full lg:tw-max-w-[210px] tw-aspect-1 tw-object-cover tw-rounded-xl lg:tw-rounded-2xl"
            />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl lg:tw-hidden"
            >
              Cupón de TaDa
            </p>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              <span class="tw-font-robotoBold">Precio:</span> ¡OBSEQUIO!
            </p>
            <div class="tw-gap-3 lg:tw-gap-6 tw-w-full tw-hidden lg:tw-flex">
              <comp-button-custom
                class="tw-w-full"
                text="Cambiar"
                @click.native="router.push('/e-commerce/monthProduct')"
              />
              <button-primary-red
                :disabled="!ecommerceStore.taDaAvailable"
                title="Reclamar"
                class="tw-w-full tw-my-2 lg:tw-my-4"
                @click.native="requestTaDa"
              />
            </div>
          </div>
          <div
            class="tw-col-span-1 lg:tw-col-span-4 tw-flex tw-flex-col tw-items-center tw-justify-evenly tw-gap-3"
          >
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-hidden lg:tw-block"
            >
              Cupón de TaDa
            </p>
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              El producto que entregamos corresponde a un código que solo puede
              ser redimido en la aplicación de TaDa, el cual es válido por un
              pack de 24 productos de la compañía de las marcas seleccionadas.
              Tiene vigencia hasta el décimo (10) día del mes. Este código te
              será asignado una vez confirmes esta opción y será equivalente al
              producto del mes habitual, por lo cual, solo podrás elegir 1 de
              estas 2 opciones. Es importante destacar que el código es de
              carácter personal e intransferible, lo que significa que solo tú
              puedes tener acceso a este código. Además, necesitas contar con
              una cuenta en TaDa para poder redimir el cupón. TaDa ofrece
              cobertura en más de 100 puntos de venta en las 8 ciudades
              colombianas, incluyendo Bogotá, Medellín, Barranquilla, Cali,
              Bucaramanga, Cúcuta, Santa Marta y Cartagena. Si tu lugar de
              residencia esta fuera de la cobertura de TaDa y te encuentras
              dentro del edificio de Bavaria Calle 127, puedes dejar en la
              aplicación la dirección de este site: Cra 53 A #127-35.
            </p>
            <div
              class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-gap-2 tw-w-full"
            >
              <p
                class="tw-m-0 tw-italic tw-font-roboto tw-text-xs lg:tw-text-base"
              >
                Conoce los terminos y condiciones de estos cupones
              </p>
              <comp-button-custom
                class="tw-w-fit"
                text="Términos y condiciones"
                @click.native="downloadGuestFile('TERMINOSYCONDICIONESTADA')"
              />
            </div>
            <div class="tw-gap-3 lg:tw-gap-6 tw-w-full tw-flex lg:tw-hidden">
              <comp-button-custom
                class="tw-w-full"
                text="Cambiar"
                @click.native="router.push('/e-commerce/monthProduct')"
              />
              <button-primary-red
                :disabled="!ecommerceStore.taDaAvailable"
                title="Reclamar"
                class="tw-w-full tw-my-2 lg:tw-my-4"
                @click.native="requestTaDa"
              />
            </div>
          </div>
        </div>
        <p class="tw-font-roboto tw-m-0 tw-italic tw-text-center tw-my-5">
          *Recuerda que solo puedes pedir tu obsequio antes del día 10 de cada
          mes
        </p>
        <div
          class="tw-border tw-rounded-xl lg:tw-rounded-2xl tw-px-2 tw-py-3 lg:tw-px-4 lg:tw-py-5 tw-flex tw-flex-col tw-items-center tw-gap-3"
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl">
            Descarga la app de TaDa
          </p>
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            Usala para conocer la cobertura antes de reclamar el premio.
          </p>
          <div class="tw-gap-3 lg:tw-gap-6 tw-flex">
            <div
              @click="
                pushLink(
                  'https://play.google.com/store/apps/details?id=co.com.tiendasya&hl=es_CO&gl=US'
                )
              "
            >
              <img src="@/assets/img/google-play-button.png" alt="" />
            </div>
            <div
              @click="
                pushLink(
                  'https://play.google.com/store/apps/details?id=co.com.tiendasya&hl=es_CO&gl=US'
                )
              "
            >
              <img src="@/assets/img/app-store-button.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
