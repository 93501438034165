<script setup>
import { removeHTMLTagsFromText } from "@/helpers/textHelpers";
import { getCurrentInstance } from "vue";

const { $isMobile: mobile } = getCurrentInstance().proxy;

const props = defineProps({
  description: {
    type: String,
    required: true,
  },
  lineClampDesktop: {
    type: Number,
    default: 3,
  },
  lineClampMobile: {
    type: Number,
    default: 2,
  },
});
</script>

<template>
  <p
    class="tw-mb-1 tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-4 lg:tw-leading-5"
    :class="[
      mobile()
        ? 'tw-line-clamp-' + props.lineClampMobile
        : 'tw-line-clamp-' + props.lineClampDesktop,
    ]"
  >
    {{ removeHTMLTagsFromText(props.description) }}
  </p>
</template>

<style scoped></style>
