<template>
  <div class="tw-px-0 lg:tw-px-4 tw-mb-4">
    <router-link :to="pillar.url">
      <div
        class="
          pillar-box
          tw-bg-cover tw-transition-all tw-z-10
          align-items-end
          tw-flex tw-mb-4
          lg:tw-mb-0
          tw-relative tw-w-full tw-h-80 tw-items-end
        "
        :style="{
          'background-image':
            '-webkit-gradient(linear, left 20, left bottom, from(rgba(0, 0, 0, 0.0)), to(rgba(0, 0, 0, 0.4))), url(' +
            pillar.img +
            ')',
        }"
      >
        <div
          class="
            pillar-text
            tw-absolute tw-bottom-0 tw-text-white tw-mb-6 tw-ml-6 tw-z-30
          "
        >
          <h6 class="tw-uppercase tw-font-bold">{{ pillar.title }}</h6>
          <p
            class="pillar-description tw-font-normal tw-pt-1 tw-mr-4 tw-hidden"
          >
            {{ pillar.desc }}
          </p>
        </div>

        <div
          class="
            pillar-back
            tw-absolute tw-w-full tw-h-80 tw-bg-black tw-bg-opacity-0 tw-z-20
          "
        ></div>
      </div>
    </router-link>
    <div class="tw-text-center tw-mt-4">
      <svg
        v-if="viewIcon"
        width="96"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.165 20.248h19.8348C95.9998 9.40877 87.1516.600775 76.165.41333V20.248zM25.5205 21.1574h11.5047c0-6.2531-5.1314-11.33287-11.5047-11.44042V21.1574zM45.548 21.1569V1.24127H25.5205c11.0605 0 20.0275 8.91693 20.0275 19.91563z"
          fill="#D82B2B"
        />
        <path
          d="M58.4722 13.811c2.7772 2.7617 2.6246 7.3882 2.6246 7.3882s-4.6544.1498-7.4297-2.61c-2.7772-2.7617-2.6246-7.3882-2.6246-7.3882s4.6525-.1517 7.4297 2.61z"
          fill="#FFBF35"
        />
        <path
          d="M63.714 13.8109c-2.7772 2.7617-2.6246 7.3882-2.6246 7.3882s4.6544.1498 7.4297-2.61c2.7772-2.7617 2.6246-7.3882 2.6246-7.3882s-4.6525-.1517-7.4297 2.61zM58.4732 3.85278c2.7771 2.76169 2.6246 7.38822 2.6246 7.38822s-4.6544.1498-7.4297-2.61c-2.7772-2.76169-2.6246-7.38818-2.6246-7.38818s4.6525-.15172 7.4297 2.60996z"
          fill="#FFBF35"
        />
        <path
          d="M63.715 3.85259c-2.7772 2.76168-2.6246 7.38821-2.6246 7.38821s4.6544.1498 7.4296-2.61c2.7772-2.76168 2.6246-7.38816 2.6246-7.38816s-4.6524-.15172-7.4296 2.60995z"
          fill="#FFBF35"
        />
        <path
          d="M0 21.1599h7.85264l-3.92632-3.9063L0 13.3492v7.8107zM20.0284 1.24152V9.0522l-3.9263-3.90438-3.9283-3.9063h7.8546zM0 1.24164v7.81069l3.92632-3.90439 3.92632-3.9063H0zM20.0284 21.1599h-7.8546l3.9283-3.9063 3.9263-3.9044v7.8107zM10.0141 16.8287c3.1252 0 5.6587-2.5194 5.6587-5.6271 0-3.10775-2.5335-5.62708-5.6587-5.62708-3.12516 0-5.65863 2.51933-5.65863 5.62708 0 3.1077 2.53347 5.6271 5.65863 5.6271z"
          fill="#00BF79"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardPillar",
  props: {
    pillar: {
      type: Object,
      required: true,
    },
    viewIcon: {
      default: true,
    },
  },
};
</script>

<style scoped lang="postcss">
.pillar-box {
  border-radius: 12px 60px 12px 12px;
}

.pillar-box:hover .pillar-back,
.pillar-box.hover .pillar-back {
  @apply tw-transition-all tw-bg-black tw-bg-opacity-30;
}

.pillar-back {
  border-radius: inherit;
}

.pillar-box:hover > .pillar-text p {
  @apply tw-flex;
}

.pillar-box:hover > .pillar-text {
  transform: translateY(-15%);
  @apply tw-transition-all;
}
</style>
