<script setup>
import config from "@/services/config";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router/composables";
import { useContestsStore } from "@/stores/contestsStore";
import { useUserStore } from "@/stores/userStore";
import { useImageUploader } from "@/composables/general/useImageUploader";
import GeneralModal from "@/components/modals/GeneralModal.vue";

const router = useRouter();
const uriResources = config.beerOfficeURL;
const { competition } = storeToRefs(useContestsStore());
const { user } = storeToRefs(useUserStore());
const postName = ref(null);
const comment = ref(null);
const isShowUploadModal = ref(false);
const { uploadFile, fileType, fileUploaded, formData } = useImageUploader();
const { actPostCompetition } = useContestsStore();

const closeUploadModal = () => {
  isShowUploadModal.value = false;
};

async function handleUploadImage() {
  isShowUploadModal.value = true;
  fileUploaded.value = false;
  fileType.value =
    competition.comp_competition_type_id === 3 ? "image" : "video";
  try {
    const { success, fileName } = await uploadFile(fileType.value);
    if (success) {
      console.log("Imagen cargada con éxito:", fileName);
      fileUploaded.value = true;
    }
  } catch (error) {
    console.error("Error al cargar la imagen:", error.message);
    fileUploaded.value = false;
    isShowUploadModal.value = false;
  }
}

async function submitImage() {
  formData.append("core_employee_id", user.value.id);
  formData.append("comp_competition_id", competition.value.id);
  formData.append("name", competition.value.name);
  formData.append("description", competition.value.desc);

  const response = await actPostCompetition({
    comp_competition_id: competition.value.id,
    data: formData,
  });

  if (response) {
    console.log("Imagen enviada con éxito");
    await router.push({ path: `/contests/${competition.value.id}/finish` });
  } else {
    console.error("Error al enviar la imagen");
  }
}
</script>

<template>
  <main>
    <portal to="all-modals">
      <general-modal
        :hide-button-support="true"
        v-if="isShowUploadModal"
        modalName="upload-image-modal"
        @close="closeUploadModal"
        :isShowModal="isShowUploadModal"
        :modalWidth="{ md: 500, lg: 500, xl: 500 }"
        :maxHeight="{ base: 400, md: 500, lg: 500, xl: 500 }"
        closable
      >
        <img
          src="@/assets/img/icons/upload.png"
          class="tw-max-w-20 tw-place-self-center"
          alt=""
        />
        <p class="tw-font-robotoBold tw-text-red-350 tw-text-xl tw-text-center">
          {{ !fileUploaded ? "Estamos cargando tu imagen" : "Listo" }}
        </p>
        <p class="tw-font-roboto tw-text-center tw-text-xl">
          {{
            !fileUploaded
              ? "Cuando esté lista podrás continuar"
              : "Tu documento se ha cargado correctamente"
          }}
        </p>
        <comp-button-primary-red
          :disabled="!fileUploaded"
          title="Continuar"
          class="tw-w-2/3 tw-place-self-center tw-py-3"
          @click.native="closeUploadModal"
        />
      </general-modal>
    </portal>
    <p class="tw-font-robotoBold tw-text-lg lg:tw-text-2xl">
      {{ competition.name }}
    </p>
    <p class="tw-font-roboto tw-text-base lg:tw-text-base">
      {{ competition.desc }}
    </p>
    <section
      class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 lg:tw-grid-rows-rows_2_fit_last tw-gap-3 lg:tw-gap-x-6 lg:tw-gap-y-3"
    >
      <div class="lg:tw-col-span-1 tw-flex tw-justify-center tw-items-start">
        <img
          :src="`${uriResources}/${competition.icon}`"
          alt=""
          class="tw-rounded-2.5xl tw-w-60 lg:tw-w-full lg:tw-h-full tw-object-cover tw-object-center"
        />
      </div>
      <div class="lg:tw-col-span-2 tw-row-span-1 tw-flex tw-flex-col tw-gap-2">
        <!-- input radio -->
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-base">
          Nombre de la publicación
        </p>
        <input
          type="text"
          class="tw-w-full tw-p-3 tw-rounded-2.5xl tw-border tw-border-gray-300"
          placeholder="Escribe tu respuesta aquí..."
          v-model="postName"
        />
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-base">
          Agrega un comentario
        </p>
        <section class="tw-h-32 lg:tw-flex-1 lg:tw-h-full">
          <textarea
            class="tw-flex-auto tw-w-full tw-h-full tw-p-3 lg:tw-p-4 tw-rounded-2.5xl tw-border tw-border-gray-300 tw-resize-none"
            v-model="comment"
            placeholder="Escribe tu respuesta aquí..."
          />
        </section>
        <div
          class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 tw-items-center tw-justify-center∏"
        >
          <comp-button-primary-red
            :title="
              competition.comp_competition_type_id === 3
                ? 'Subir una imagen'
                : 'Subir un video'
            "
            class="tw-w-full lg:tw-w-1/3 tw-h-full tw-py-3"
            @click.native="handleUploadImage"
          />
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
            {{
              competition.comp_competition_type_id === 3
                ? "La imagen debe ser en formato jpg o png y no debe superar los 5 mb de peso"
                : "El video debe ser en formato mp4 y no debe superar los 10 mb de peso"
            }}
          </p>
        </div>
      </div>
    </section>
    <div class="tw-flex tw-justify-end tw-mt-4">
      <comp-button-primary-red
        :disabled="!fileUploaded || !postName || !comment"
        title="Enviar"
        class="tw-w-full lg:tw-w-1/5 tw-h-full tw-py-3"
        @click.native="submitImage"
      />
    </div>
  </main>
</template>

<style scoped></style>
