import { render, staticRenderFns } from "./personalData.vue?vue&type=template&id=d97b32cc&scoped=true"
import script from "./personalData.vue?vue&type=script&lang=js"
export * from "./personalData.vue?vue&type=script&lang=js"
import style0 from "./personalData.vue?vue&type=style&index=0&id=d97b32cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d97b32cc",
  null
  
)

export default component.exports