<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router/composables";
import config from "@/services/config";

const uriResources = config.beerOfficeURL;
const router = useRouter();

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  redeemButton: {
    type: Boolean,
    default: false,
  },
  quantityVisible: {
    type: Boolean,
    default: false,
  },
  toinsBalance: {
    type: Number,
    default: 0,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const isReedemable = computed(() => {
  return props.toinsBalance >= props.product.toins;
});
</script>

<template>
  <div
    class="tw-rounded-2.5xl lg:tw-rounded-3xl tw-border tw-border-gray-300 tw-px-3 tw-py-2 lg:tw-p-4 tw-flex tw-flex-col tw-justify-between tw-gap-2 lg:tw-gap-3"
  >
    <div id="name_img" class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-3">
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-center"
      >
        {{ props.product.name }}
      </p>
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-center"
      >
        {{ props.product?.people_tour_stage?.name }}
      </p>
      <div class="tw-py-2 tw-px-4">
        <div class="tw-aspect-h-1 tw-aspect-w-1">
          <img
            :src="uriResources + '/' + props.product.image"
            alt=""
            class="tw-w-full tw-rounded-2xl lg:tw-rounded-2.5xl tw-object-cover"
          />
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-center tw-items-center tw-gap-2">
      <img
        src="@/assets/img/peopleTour/icons/icono-toin.png"
        class="tw-h-5 tw-w-5 lg:tw-h-7 lg:tw-w-7"
        alt=""
      />
      <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm">
        {{ props.product.toins + " Toins" }}
      </p>
    </div>
    <p
      v-if="quantityVisible"
      class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm tw-text-red-350 tw-text-center"
    >
      {{
        props.product.qty_balance <= 0
          ? "Agotado"
          : "Cant. disponible: " + props.product?.qty_balance
      }}
    </p>
    <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm tw-line-clamp-5">
      {{ props.product.description }}
    </p>
    <div
      :class="
        isReedemable && isActive
          ? 'tw-btn-primary-red'
          : 'tw-btn-primary-disabled'
      "
      v-if="redeemButton && props.product?.qty_balance > 0"
      @click="
        isReedemable && isActive
          ? router.push({
              name: 'peopleTourRewardsProduct',
              params: { id: props.product.id },
            })
          : null
      "
    >
      <p class="tw-m-0">Redimir</p>
    </div>
  </div>
</template>
