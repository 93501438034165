export function getBand(user) {
  return user.core_om_sideline?.name
    ? user.core_om_sideline?.name
    : user.core_pa_sideline?.name;
}

export function getAge(birthdate) {
  let diff_ms = Date.now() - new Date(birthdate.replace("-", "/")).getTime();
  return Math.abs(new Date(diff_ms).getUTCFullYear() - 1970);
}
