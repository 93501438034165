<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0">
    <div class="mx-auto" v-if="isLoading">
      <div class="container px-0 px-md-3 mt-md-4 he-500">
        <div
          class="d-flex align-items-center justify-content-center my-3 h-100"
        >
          <img src="../../assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
    </div>
    <div class="mx-auto" v-if="!isLoading">
      <div class="container px-0 px-md-3 mt-md-4">
        <BreadCrumb :crumbs="breadcrumb" />
      </div>
      <MobileBar :title="competition.name" />
      <!-- Título Desktop y leyenda -->
      <div
        class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
      >
        <div class="col-12 col-md-6 px-0 order-md-1 order-last my-0 py-0">
          <div class="d-none d-md-flex flex-md-column py-0">
            <p class="tw-font-tolyerBold fs-md-40 text-uppercase my-0">
              {{ competition.name }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 order-md-2 order-first">
          <div
            class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end py-0"
          >
            <div
              class="col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 mr-md-2 order-last order-md-1"
            >
              <router-link
                :to="'/competition/competition/' + this.$route.params.id"
                class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
              >
                Regresar al concurso
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="container px-3 px-md-3 mb-md-5 mb-0 mt-3 mt-md-2">
        <!-- .row -->
        <div class="row mt-md-5 mb-0">
          <div class="col-12 col-md-5 px-3 px-md-3 py-3 py-md-0">
            <div
              class="tw-shadow-lg tw-bg-purple-400 tw-rounded-lg bor-ranking-xs-1 he-500 he-md-500 principal-ranking d-flex flex-column justify-content-between"
            >
              <div class="container">
                <div class="row d-flex justify-content-center px-4 my-4">
                  <div class="col">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <img src="../../assets/img/icons/icon-golden-trophy.svg" class="he-50" alt="" />
                      <p
                        class="tw-font-robotoBold fs-22 fs-md-26 line-height-30 text-white m-0"
                      >
                        Ranking de Ganadores
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="row px-4 my-4 d-flex justify-content-center align-items-end"
                >
                  <div
                    class="col-4 avatares d-flex flex-column justify-content-center align-items-center"
                  >
                    <div
                      class="rounded-circle d-flex justify-content-center"
                      style="border: 5px solid #cccccc"
                    >
                      <img
                        v-if="competition.ranking[1].prof_picture === null"
                        src="@/assets/img/demo/avatar-empty.png"
                        alt=""
                        class="wi-110 he-110 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />

                      <img
                        v-else
                        :src="
                          uriResources +
                          '/' +
                          competition.ranking[1].prof_picture
                        "
                        alt=""
                        class="wi-110 he-110 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />
                      <div
                        class="tag-left rounded-circle wi-30 he-30 d-flex justify-content-center align-items-center"
                        style="background: #cccccc"
                      >
                        <p class="m-0 tw-font-robotoBold tw-text-base">2</p>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center align-items-center mt-3 texto text-center text-capitalize"
                      id="Numero 2"
                    >
                      <p
                        class="tw-font-roboto tw-text-sm fs-md-16 text-white mx-1 my-0 texto"
                      >
                        {{ competition.ranking[1].name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-roboto tw-text-sm line-height-16 text-white mt-1 mb-0"
                      >
                        {{ competition.ranking[1].vp_name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-robotoBold tw-text-lg line-height-20 mt-1"
                        style="color: #cccccc"
                      >
                        {{ competition.ranking[1].score }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-4 avatares d-flex flex-column justify-content-center align-items-center"
                  >
                    <div
                      class="rounded-circle d-flex justify-content-center"
                      style="border: 5px solid #ffce39"
                    >
                      <img
                        v-if="competition.ranking[0].prof_picture == null"
                        src="@/assets/img/demo/avatar-empty.png"
                        alt=""
                        class="wi-130 he-130 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />

                      <img
                        v-else
                        :src="
                          uriResources +
                          '/' +
                          competition.ranking[0].prof_picture
                        "
                        alt=""
                        class="wi-130 he-130 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />
                      <div
                        class="tag-center rounded-circle wi-40 he-40 d-flex justify-content-center align-items-center"
                        style="background: #ffce39"
                      >
                        <p class="m-0 tw-font-robotoBold tw-text-base">1</p>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center align-items-center mt-3 texto text-center text-capitalize"
                      id="Numero 1"
                    >
                      <p
                        class="tw-font-roboto tw-text-sm fs-md-16 text-white mx-1 my-0 texto"
                      >
                        {{ competition.ranking[0].name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-roboto tw-text-sm line-height-16 text-white mt-1 mb-0"
                      >
                        {{ competition.ranking[0].vp_name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-robotoBold tw-text-lg line-height-20 mt-1"
                        style="color: #ffce39"
                      >
                        {{ competition.ranking[0].score }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-4 avatares d-flex flex-column justify-content-center align-items-center"
                  >
                    <div
                      class="rounded-circle d-flex justify-content-center"
                      style="border: 5px solid #ff9955"
                    >
                      <img
                        v-if="competition.ranking[2].prof_picture == null"
                        src="@/assets/img/demo/avatar-empty.png"
                        alt=""
                        class="wi-110 he-110 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />

                      <img
                        v-else
                        :src="
                          uriResources +
                          '/' +
                          competition.ranking[2].prof_picture
                        "
                        alt=""
                        class="wi-110 he-110 rounded-circle shadow-box-1 bg-white tw-object-cover"
                      />

                      <div
                        class="tag-right rounded-circle wi-30 he-30 d-flex justify-content-center align-items-center"
                        style="background: #ff9955"
                      >
                        <p class="m-0 tw-font-robotoBold tw-text-base">3</p>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center align-items-center mt-3 texto text-center text-capitalize"
                      id="Numero 3"
                    >
                      <p
                        class="tw-font-roboto tw-text-sm fs-md-16 text-white mx-1 my-0 texto"
                      >
                        {{ competition.ranking[2].name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-roboto tw-text-sm line-height-16 text-white mt-1 mb-0"
                      >
                        {{ competition.ranking[2].vp_name.toLowerCase() }}
                      </p>
                      <p
                        class="tw-font-robotoBold tw-text-lg line-height-20 mt-1"
                        style="color: #ff9955"
                      >
                        {{ competition.ranking[2].score }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row my-1 d-none d-md-flex justify-content-center px-4"
              >
                <div class="col-12">
                  <p
                    class="tw-font-robotoBold tw-text-base line-height-20 text-white m-0"
                  >
                    Tu posición en el Ranking es:
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between px-1 px-md-3 my-3 w-100 tw-rounded-lg person-back2"
                  >
                    <div class="col-1 d-flex text-center">
                      <p
                        class="tw-font-roboto tw-text-base my-auto tw-text-gray-400"
                      >
                        {{ getMyPosition().myPosition }}
                      </p>
                    </div>
                    <div
                      class="col-2 ml-1 ml-md-0 d-flex justify-content-center"
                    >
                      <div>
                        <img
                          v-if="user.prof_picture == null"
                          src="@/assets/img/demo/avatar-empty.png"
                          alt=""
                          class="avatar-3 rounded-circle shadow-box-1 bg-white tw-object-cover"
                        />
                        <img
                          v-else
                          :src="uriResources + '/' + user.prof_picture"
                          alt=""
                          class="avatar-3 rounded-circle shadow-box-1 bg-white tw-object-cover"
                        />
                      </div>
                    </div>
                    <div class="col overflow-hidden p-3">
                      <div>
                        <p
                          class="tw-font-robotoBold tw-text-sm text-capitalize text-truncate my-1"
                        >
                          {{ user.name.toLowerCase() }}
                        </p>
                        <p
                          class="tw-font-roboto tw-text-sm text-capitalize text-truncate my-1 tw-text-gray-400"
                        >
                          {{ user.core_vp.name.toLowerCase() }}
                        </p>
                      </div>
                    </div>
                    <div class="col-2 d-flex justify-content-center">
                      <div
                        class="shadow-box-2 bg-white tw-rounded-md text-center wi-40 he-40"
                      >
                        <p
                          class="tw-font-robotoBold tw-text-base line-height-40"
                        >
                          {{ getMyPosition().score }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- TABLA DE POSICIONES -->
          <div class="col-12 col-md-7 px-3 px-md-3 py-0 py-md-0 overlap">
            <div
              class="tw-shadow-lg bg-white tw-rounded-lg bor-ranking-xs-1 he-md-500"
            >
              <div class="d-flex justify-content-center align-items-center">
                <p
                  class="tw-font-robotoBold tw-text-gray-600 fs-22 fs-md-26 my-4"
                >
                  Tabla de Posiciones
                </p>
              </div>
              <div class="d-none d-md-flex border-bottom"></div>
              <div class="position-relative h-100">
                <div class="flex-wrapper h-100 pb-2">
                  <div class="overflow-auto px-3 px-md-3 mb-0 h-75p h-80p-md">
                    <div
                      class="d-md-flex d-none align-items-center justify-content-between px-3 my-3 w-100 tw-rounded-lg bor-ranking-xs-1"
                    >
                      <div class="col-2 d-flex justify-content-center">
                        <div>
                          <p
                            class="tw-font-robotoBold tw-text-sm tw-text-gray-600 m-0"
                          >
                            Embajador
                          </p>
                        </div>
                      </div>
                      <div class="col overflow-hidden"></div>
                      <div class="col-2 d-flex justify-content-center px-0">
                        <div class="bg-white text-center">
                          <p
                            class="tw-font-robotoBold tw-text-sm tw-text-gray-600 m-0"
                          >
                            Posición
                          </p>
                        </div>
                      </div>
                      <div class="col-2 d-flex justify-content-center px-0">
                        <div class="bg-white text-center">
                          <p
                            class="tw-font-robotoBold tw-text-sm tw-text-gray-600 m-0"
                          >
                            Total Puntos
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-none d-md-block"
                      v-for="(persona, index) in competition.ranking"
                      :key="index"
                    >
                      <div>
                        <div
                          class="d-flex align-items-center justify-content-between px-1 px-md-3 my-3 w-100 tw-rounded-lg"
                          :class="
                            persona.id_sharp === user.id_sharp
                              ? 'person-back2'
                              : 'person-back1'
                          "
                          :id="
                            persona.id_sharp === user.id_sharp
                              ? 'active'
                              : 'inactive'
                          "
                        >
                          <div class="col-1 d-flex d-md-none text-center">
                            <p
                              class="tw-font-roboto tw-text-base my-auto tw-text-gray-400"
                            >
                              {{ persona.position }}
                            </p>
                          </div>
                          <div
                            class="col-2 ml-1 ml-md-0 d-flex justify-content-center"
                          >
                            <div>
                              <img
                                src="@/assets/img/demo/avatar-empty.png"
                                alt=""
                                class="avatar-3 rounded-circle shadow-box-1 bg-white tw-object-cover"
                              />
                            </div>
                          </div>
                          <div class="col overflow-hidden p-3">
                            <div>
                              <p
                                class="tw-font-robotoBold tw-text-sm text-capitalize text-truncate my-1"
                              >
                                {{ persona.name.toLowerCase() }}
                              </p>
                              <p
                                class="tw-font-roboto tw-text-sm text-capitalize text-truncate my-1"
                                :class="
                                  persona.id_sharp === user.id_sharp
                                    ? 'tw-text-gray-400'
                                    : 'tw-text-gray-300'
                                "
                              >
                                {{ persona.vp_name.toLowerCase() }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-2 d-none d-md-flex justify-content-center"
                          >
                            <div
                              class="shadow-box-2 bg-white tw-rounded-md text-center"
                              style="width: 38px; height: 38px"
                            >
                              <p
                                class="tw-font-robotoBold tw-text-base"
                                style="line-height: 38px"
                              >
                                {{ index + 1 }}
                              </p>
                            </div>
                          </div>
                          <div class="col-2 d-flex justify-content-center">
                            <div
                              class="shadow-box-2 bg-white tw-rounded-md text-center"
                              style="width: 38px; height: 38px"
                            >
                              <p
                                class="tw-font-robotoBold tw-text-base"
                                style="line-height: 38px"
                              >
                                {{ persona.score }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-block d-md-none"
                      v-for="(persona, index) in getRanking()"
                      :key="'A' + index"
                    >
                      <div>
                        <div
                          class="d-flex align-items-center justify-content-between px-1 px-md-3 my-3 w-100 tw-rounded-lg"
                          :class="
                            persona.id_sharp === user.id_sharp
                              ? 'person-back2'
                              : 'person-back1'
                          "
                          :id="
                            persona.id_sharp === user.id_sharp
                              ? 'active'
                              : 'inactive'
                          "
                        >
                          <div class="col-1 d-flex d-md-none text-center">
                            <p
                              class="tw-font-roboto tw-text-xs my-auto tw-text-gray-400"
                            >
                              {{ persona.position }}
                            </p>
                          </div>
                          <div
                            class="col-2 ml-2 ml-md-0 d-flex justify-content-center"
                          >
                            <div>
                              <img
                                src="@/assets/img/demo/avatar-empty.png"
                                alt=""
                                class="avatar-3 rounded-circle shadow-box-1 bg-white tw-object-cover"
                              />
                            </div>
                          </div>
                          <div class="col overflow-hidden p-3">
                            <div>
                              <p
                                class="tw-font-robotoBold tw-text-sm text-capitalize text-truncate my-1"
                              >
                                {{ persona.name.toLowerCase() }}
                              </p>
                              <p
                                class="tw-font-roboto tw-text-sm text-capitalize text-truncate my-1"
                                :class="
                                  persona.id_sharp === user.id_sharp
                                    ? 'tw-text-gray-400'
                                    : 'tw-text-gray-300'
                                "
                              >
                                {{ persona.vp_name.toLowerCase() }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-2 d-none d-md-flex justify-content-center"
                          >
                            <div
                              class="shadow-box-2 bg-white tw-rounded-md text-center"
                              style="width: 38px; height: 38px"
                            >
                              <p
                                class="tw-font-robotoBold tw-text-base"
                                style="line-height: 38px"
                              >
                                {{ index + 1 }}
                              </p>
                            </div>
                          </div>
                          <div class="col-2 d-flex justify-content-center">
                            <div
                              class="shadow-box-2 bg-white tw-rounded-md text-center"
                              style="width: 38px; height: 38px"
                            >
                              <p
                                class="tw-font-robotoBold tw-text-base"
                                style="line-height: 38px"
                              >
                                {{ persona.score }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tag-3 image-container-2 text-center"
                    @click="scroll('active')"
                    v-if="getMyPosition().myPosition !== 0"
                  >
                    <i
                      class="icon-flecha-abajo tw-text-white tw-bg-red-400 rounded-circle fs-30"
                    ></i>
                  </div>
                  <div class="d-block d-md-none bg-white my-3 w-100 px-3">
                    <button
                      @click="vermas()"
                      type="button"
                      class="btn btn-primary btn-block"
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "@/services/config";
import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";

export default {
  name: "CompetitionRanking",
  components: {
    BreadCrumb,
    MobileBar,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      numofpositions: 3,
      isLoading: true,
      backgroundLoading: {
        background: `url(${require("@/assets/img/icons/loading.gif")}) no-repeat center center`,
      },
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Universo de marcas",
          link: "/beer-universe",
        },
        {
          id: 3,
          name: "Mundo de marcas",
          link: "/brands",
        },
        {
          id: 4,
          name: "Concursos",
          link: "/competitions",
          active: true,
        },
      ],
    };
  },
  created() {
    this.actGetCompetition(this.$route.params.id)
      .then(() => {
        this.isLoading = true;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  mounted() {
    window.dataLayer.push({
      event: "'trackEvent",
      target: "Sub-secciones",
      action: "concursos-ranking",
      "target-properties": "clic",
      value: 5000,
    });
  },
  computed: {
    ...mapState("modCompetitions", ["competitions", "competition"]),
    ...mapState("modCore", ["user"]),
  },
  methods: {
    ...mapActions("modCompetitions", [
      "actGetCompetitions",
      "actGetCompetition",
    ]),
    ...mapActions("modCore", ["actUser"]),
    // getFirstName(string) {
    //   string = string + "";
    //   let name = string.split(" ");
    //   return name[0].toLowerCase();
    // },
    scroll(id) {
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    },
    getMyPosition() {
      let myData = this.competition.ranking.filter(
        (e) => e.id_sharp === this.user.id_sharp
      );

      if (myData.length > 0) {
        return myData;
      } else {
        return {
          score: 0,
          myPosition: 0,
        };
      }
    },
    getRanking() {
      let myData = this.competition.ranking.filter(
        (e) => e.id_sharp === this.user.id_sharp
      );

      if (myData.length > 0 && myData[0].position !== 0) {
        if (
          this.competition.ranking.slice(0, this.numofpositions).at(-1)
            .position > myData[0].position
        ) {
          return this.competition.ranking.slice(0, this.numofpositions);
        } else {
          let rankingData = this.competition.ranking.slice(
            0,
            this.numofpositions
          );
          rankingData.pop();
          rankingData.push(myData[0]);
          return rankingData;
        }
      } else {
        return this.competition.ranking.slice(0, this.numofpositions);
      }
    },
    vermas() {
      if (this.numofpositions < this.competition.ranking.length) {
        this.numofpositions = this.numofpositions + 20;
      } else {
        this.numofpositions = this.competition.ranking.length;
      }
      this.getRanking();
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .bor-ranking-xs-1 {
    border-radius: 26px 26px 8px 8px;
  }
}
.person-back1 {
  background: #f9f9f9;
}

.person-back2 {
  background: #ffecb8;
}

.ranking-back1 {
  background: linear-gradient(180deg, #f1ce38 0%, #f05e22 37.01%);
}

.ranking-back2 {
  background: linear-gradient(180deg, #4cc3c9 0%, #5ea1f7 63.54%);
}

.ranking-back3 {
  background: linear-gradient(180deg, #ecb2d0 0%, #c61a75 100%);
}

.ranking-back4 {
  background: linear-gradient(180deg, #e93c3e 0%, #641119 100%);
}

.avatar {
  width: 100px;
  height: 100px;
}

.avatar-2 {
  width: 60px;
  height: 60px;
}

.avatar-3 {
  width: 45px;
  height: 45px;
}

.tag-center {
  top: -15px;
  position: absolute;
  z-index: 50;
}

.tag-left {
  left: 0;
  position: absolute;
  z-index: 50;
}

.tag-right {
  right: 0;
  position: absolute;
  z-index: 50;
}

.tag-3 {
  left: 48%;
  bottom: 130px;
  position: absolute;
  z-index: 50;
}

@media (min-width: 768px) {
  .tag-3 {
    left: 48%;
    bottom: 70px;
    position: absolute;
    z-index: 50;
  }
}

.img-container {
  height: 100%;
  max-height: 140px;
}

.img-container img {
  display: block;
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.image-container {
  width: 31px;
  height: 31px; /* Some height */
}

.image-container-2 {
  width: 28px;
  height: 28px; /* Some height */
}

.text {
  color: black;
  mix-blend-mode: screen; /* This makes the cutout text possible */
}

.text-bold {
  font-family: "RobotoBold", sans-serif;
}

.text-red {
  color: #d82b2b;
}

.principal-ranking {
  background-image: url("../../assets/img/corner-contest.svg");
  background-repeat: no-repeat;
  background-position: top right;
}

@media (max-width: 769px) {
  .overlap {
    top: -160px;
  }
}

.avatares {
  position: relative;
}

.avatares:nth-child(1),
.avatares:nth-child(3) {
  z-index: 1;
}

.avatares:nth-child(2) {
  margin: 0 -20px 0 -20px;
  z-index: 2;
}

@media (min-width: 768px) {
  .avatares:nth-child(2) {
    margin: 0 -25px 0 -25px;
    z-index: 2;
  }
}

.texto {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
