<template>
  <div>
    <div
      class="cont-inner-banner"
      :style="backgroundLoading"
      v-if="!banner"
    ></div>
    <div class="component-banner bg-white tw-shadow-md bor-banner-1" v-else>
      <b-carousel
        ref="myCarousel"
        id="carousel-banner"
        :interval="5000"
        indicators
        background="transparent"
      >
        <!-- Slides with img slot -->
        <b-carousel-slide v-for="slide in banner.slides" :key="slide.id">
          <template v-slot:img>
            <div
              class="cont-inner-banner"
              :style="backgroundLoading"
              @click="
                redirectToLinkWithDataLayer(
                  event,
                  slide.url,
                  category,
                  slide.label_datalayer
                )
              "
            >
              <img
                loading="lazy"
                class="slide-img"
                :class="{
                  'd-block': !slide.img_mobile,
                  'd-none': slide.img_mobile,
                  'd-md-block': slide.img_mobile,
                  'slide-rounded-corner': roundedCorner,
                }"
                :src="uriResources + '/' + slide.img"
                alt="Diapositiva"
              />

              <img
                loading="lazy"
                class="slide-img"
                :class="{
                  'd-none': !slide.img_mobile,
                  'd-block': slide.img_mobile,
                  'd-md-none': slide.img_mobile,
                  'slide-rounded-corner': roundedCorner,
                }"
                :src="uriResources + '/' + slide.img_mobile"
                alt="Diapositiva"
              />

              <div
                class="slide-tag"
                :class="{
                  'd-none': legendMobileOutside,
                  'd-md-block': legendMobileOutside,
                  'd-block': !legendMobileOutside,
                }"
              >
                <h6>
                  <span class="badge tw-bg-red-350 tw-text-white">{{
                    slide.tag
                  }}</span>
                </h6>
              </div>
              <div
                v-if="slide.description"
                class="slide-legend"
                :class="{ 'legend-outside': legendMobileOutside }"
              >
                <h4 class="tw-font-robotoBold tw-text-2xl md:tw-text-4xl">
                  {{ slide.title }}
                </h4>
                <p class="tw-mb-0 tw-text-sm">{{ slide.description }}</p>
              </div>
            </div>

            <div
              class="slide-play"
              v-if="
                slide.content_type_slide_id === 2 ||
                slide.content_type_slide_id === 4
              "
            >
              <b-button size="sm" @click="showModal(slide)">
                <span class="icon-play icon-pointer icon-xl icon-white"></span>
              </b-button>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <!-- Modal -->
      <b-modal
        ref="modal-video-player"
        size="xl"
        centered
        title="qualification-video-banner"
        hide-footer
        hide-header
      >
        <button
          class="close-modal tw-text-2xl tw-border-0 tw-text-red-400 tw-absolute tw-bg-transparent tw-z-1"
          @click="hideModal"
        >
          <span class="icon-cerrar-simple" @click="hideModal"></span>
        </button>
        <div class="container-video tw-w-full">
          <CompQualificationVideo
            :idVideo="selectedSlide.id"
            :urlVideo="uriResources + '/' + selectedSlide.video"
            :title="selectedSlide.title"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import config from "@/services/config";

import CompQualificationVideo from "@/components/video/CompVideoQualification.vue";
import linksMixin from "@/mixins/general/links";

export default {
  name: "ComponentBanner",
  components: {
    CompQualificationVideo,
  },
  props: {
    bannerId: {
      type: Number,
      required: true,
    },
    category: {
      type: String,
    },
    event: {
      type: String,
    },
    legendMobileOutside: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: String,
      default: "none",
    },
    roundedCorner: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [linksMixin],
  data() {
    return {
      uriResources: config.beerOfficeURL,
      selectedSlide: {},
      backgroundLoading: {
        background: `url(${require("@/assets/img/icons/loading.gif")}) no-repeat center center`,
      },
      banner: null,
    };
  },
  async created() {
    const response = await this.actGetBannerById(this.bannerId);
    if (response.data) {
      this.banner = response.data.banner;
    }
  },
  computed: {
    ...mapState("modGeneral", ["banners"]),
    ...mapGetters("modGeneral", ["getBannerById"]),
    /*banner() {
      return this.getBannerById(this.bannerId);
    },*/
  },
  methods: {
    ...mapActions("modGeneral", ["actGetBannerById"]),
    showModal(slide) {
      this.selectedSlide = slide;
      this.$refs["modal-video-player"].show();
      this.dataLayerEvent(
        slide.label_datalayer,
        this.banner.name,
        "clic",
        5000
      );
    },
    hideModal() {
      this.$refs["modal-video-player"].hide();
    },
    prev() {
      this.$refs["myCarousel"].prev();
    },
    next() {
      this.$refs["myCarousel"].next();
    },
  },
};
</script>
<style scoped>
.bor-banner-1 {
  border-radius: 150px 10px 10px 10px;
}

.component-banner {
  min-height: 150px;
}
.close-modal text {
  top: 5%;
  right: 5%;
}

.cont-inner-banner {
  min-height: 200px;
}

a.router-card:link,
a.router-card:visited,
a.router-card:active {
  color: #0f0e0e;
  text-decoration: none;
}

.component-banner .slide-img {
  height: 240px;
  width: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 10px 10px 10px 10px;
}

.component-banner .slide-rounded-corner {
  border-top-left-radius: 100px;
}

.component-banner .slide-play {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}

.component-banner .slide-play .btn-secondary {
  background-color: transparent;
  border-color: transparent;
  top: calc(50% - 43px);
  position: relative;
}

.component-banner .slide-tag {
  text-align: right;
  position: absolute;
  top: 6%;
  width: 100%;
  padding: 0 6%;
}

.component-banner .slide-legend {
  color: #ffffff;
  text-align: center;
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding: 2% 6% 0 6%;
}

.component-banner .slide-legend p {
  margin: 0;
}

.component-banner .slide-legend.legend-outside {
  color: #000000;
  position: relative;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .component-banner .slide-img {
    height: 300px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .component-banner h4 {
    font-size: 44px;
  }

  .component-banner p {
    font-size: 16px;
  }
  .component-banner .slide-img {
    height: 350px;
  }

  .component-banner .slide-rounded-corner {
    border-top-left-radius: 150px;
  }

  .component-banner .slide-ecommerce {
    height: 480px;
    border-radius: 20px;
  }

  .component-banner .slide-legend,
  .component-banner .slide-legend.legend-outside {
    width: 50%;
    color: #ffffff;
    position: absolute;
    bottom: 10%;
    right: 0;
    text-align: right;
  }
}
</style>
