<script setup>
import config from "@/services/config";
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router/composables";
import { useContestsStore } from "@/stores/contestsStore";
import { storeToRefs } from "pinia";

const competitionStore = useContestsStore();
const uriResources = config.beerOfficeURL;
const route = useRoute();
const gift = ref(null);
const { competition } = storeToRefs(competitionStore);
const { actGetCompetition } = competitionStore;
const modalGift = ref(null);

const showModalGift = (data) => {
  gift.value = data;
  modalGift.value.show();
};

const closeModal = () => {
  modalGift.value.hide();
};

onBeforeMount(() => {
  actGetCompetition(route.params.id);
});
</script>
<template>
  <div>
    <div class="col-12 m-0 p-0">
      <div class="scrolling-wrapper float-lg-right">
        <div class="d-flex align-items-center">
          <div
            v-for="(data, idx) in competition.comp_awar"
            :key="idx"
            class="carousel-cell"
          >
            <div class="onboard-slide">
              <div>
                <router-link to="#">
                  <img
                    class="rounded-circle contactAdd"
                    :src="uriResources + '/' + data.icon"
                    alt="awards"
                    @click="showModalGift(data)"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        ref="modal-gift"
        size="md"
        id="modal-gift"
        hide-footer
        hide-header
        centered
      >
        <!--<source :src="setImageUrl(participant.file)" type="image/*" />-->
        <div class="modal-header justify-content-center py-2" v-if="gift">
          <div class="modal-title px-0">
            <h5 class="modal-title tw-font-robotoBold tw-text-sm md:tw-text-lg">
              {{ gift.name }}
            </h5>
            <button
              type="button"
              class="close text-right p-0"
              @click="closeModal()"
              style="position: absolute; top: 35px; right: 36px"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="content-img">
          <img
            v-if="gift"
            class="mt-2"
            :src="uriResources + '/' + gift.icon"
            alt="no users"
          />
          <p v-if="gift" class="mt-4 text-desc text-center">
            {{ gift.desc }}
          </p>
          <!--<p class="user">Nombre del participante</p>-->
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
#modal-gift .img-content {
  border-radius: 25px;
}
.content-img img {
  border-radius: 20px;
  width: 100%;
  box-shadow: 0 2px 4px 2px #0000001a;
  opacity: 1;
}
.scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: inherit;
  height: 80px;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.scrolling-wrapper::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrolling-wrapper.carousel-cell {
  display: inline;
}
.carousel-cell {
  display: inline-block;
}

.onboard-slide {
  width: 90px;
  padding: 0 10px;
  height: 100px;
  text-align: center;
}
.onboard-slide p {
  font-size: 12px;
  margin-top: 5px;
}
.onboard-slide img {
  width: 65px;
  height: 65px;
  border: 1px solid white;
  padding: 1px;
}

.text-desc {
  font: normal 14px/12px Roboto;
  color: #454545;
  opacity: 1;
}
@media (max-width: 600px) {
  .onboard-slide {
    width: 70px;
    padding: 0 0;
    height: 90px;
    text-align: center;
  }

  .carousel-cell {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
</style>
