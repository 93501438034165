<template>
  <div>
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 450, xl: 500 }"
      modal-name="avatar_saved"
      v-if="isModalOpen"
      :isShowModal="isModalOpen"
      @close="closeModal"
    >
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
      >
        <img src="@/assets/img/modal/modal-success.svg" alt="" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
        >
          GENIAL
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-w-8/12 tw-text-center"
        >
          Tu avatar ha sido guardado con éxito
        </p>

        <div class="tw-w-full tw-flex tw-gap-2">
          <div class="tw-btn-primary-red" @click="goToBrewery">
            <p class="tw-m-0">Ir a la maltería</p>
          </div>
          <div class="tw-btn-secondary-white" @click="closeModal">
            <p class="tw-m-0">Cerrar</p>
          </div>
        </div>
      </div>
    </general-modal>
    <div class="tw-container-general tw-mb-0 tw-pb-0">
      <div
        class="tw-z-2 tw-fixed tw-bottom-28 lg:tw-bottom-10 tw-py-4 tw-px-6 tw-rounded-2xl tw-shadow-lg tw-left-1/2 tw-transform tw--translate-x-1/2 tw-bg-pink-100 tw-w-max tw-flex tw-gap-2 tw-items-center tw-justify-center"
      >
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-xl tw-text-red-350"
          >
            ¿Deseas guardar tu avatar?
          </p>
          <p class="tw-m-0 tw-font-robotoLight tw-text-xs lg:tw-text-xl">
            Avatar seleccionado:
            {{
              +custom_avatar === 1
                ? " Avatar personalizado"
                : predefined_avatar && predefined_avatar.name
            }}
          </p>
        </div>
        <div class="tw-relative tw-text-center tw-w-min">
          <div
            :class="
              validateSave ? 'tw-btn-primary-red' : 'tw-btn-primary-disabled'
            "
            @click="saveAvatar()"
          >
            <p class="tw-m-1">Guardar</p>
          </div>
        </div>
      </div>
      <div class="gamificationAvatar tw-z-1">
        <div
          class="avatar-head"
          :style="{
            backgroundImage: `url('${imgBgGamification}')`,
          }"
        >
          <div class="bg-success">
            <b-toast
              id="my-toast"
              variant="success"
              autoHideDelay="3000"
              class
              solid
            >
              <div
                class="d-flex flex-grow-1 text-center align-items-baseline px-4"
              >
                <img :src="imgCheck" alt class="my-auto" />
                <p class="mr-auto ml-2 my-auto">
                  Tu avatar se ha guardado con éxito
                </p>
              </div>
            </b-toast>
          </div>
          <table class="tw-mx-auto">
            <tr>
              <td id="gamificationBuildAvatar">
                <div v-for="(image, index) in avatar.final" :key="index">
                  <img
                    v-if="image != null"
                    :src="uriResources + '/' + image"
                    width="100%"
                    style="
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                    "
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="avatar-nav" v-if="avatar_elements_loaded">
          <div class="avatar-carousel">
            <table class="tw-mx-auto">
              <tr>
                <td
                  v-for="element in avatarElements"
                  :key="element.id"
                  class="pl-3 pr-3"
                >
                  <img
                    v-if="active === element.id"
                    :src="uriResources + '/' + element.icon2"
                    @click="showObject(element.id, element.name, '')"
                    class="image"
                    alt=""
                  />
                  <img
                    v-else
                    :src="uriResources + '/' + element.icon"
                    @click="showObject(element.id, element.name, '')"
                    class="image"
                    alt=""
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="avatar-elements">
          <div
            :class="'object object-' + element.id"
            v-for="(element, idx) in avatarElements"
            :key="idx"
          >
            <div class="text-center text-element">
              <h2
                class="mb-0 mb-md-1 tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"
              >
                {{ element.name }}
              </h2>
            </div>
            <div class="carrousel-gamification">
              <table class="tw-mx-auto tw-border-0">
                <tr>
                  <td
                    v-for="obj in element['gami_avatar_image']"
                    :key="obj.id"
                    @click="addElementAvatar(element, obj, obj.id)"
                  >
                    <div>
                      <!-- <img
                        v-if="element.z_index !== 10"
                        :src="uriResources + '/' + avatar.cara.image"
                        width="150"
                        :style="{
                          zIndex: avatar.cara.zIndex,
                        }"
                      /> -->
                      <img
                        :src="uriResources + '/' + obj.image"
                        width="150"
                        :style="{
                          zIndex: element.z_index,
                        }"
                        alt=""
                      />
                      <img
                        :src="uriResources + '/' + obj.image2"
                        width="150"
                        :style="{
                          zIndex: element.z_index2,
                        }"
                        v-if="obj.image2 != null"
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="tw-flex tw-justify-center tw-gap-2 tw-mb-5">
              <div class="tw-relative tw-text-center tw-w-max">
                <div
                  class="tw-btn-secondary-white tw-flex tw-items-center tw-gap-2"
                  @click="selectCustomAvatar()"
                >
                  <input type="hidden" name="custom_avatar" value="0" />
                  <input
                    id="custom_avatar"
                    value="1"
                    v-model="custom_avatar"
                    name="custom_avatar"
                    class="tw-text-red-350 tw-border tw-border-black focus:tw-ring-transparent tw-w-4 tw-h-4 lg:tw-w-5 lg:tw-h-5 tw-rounded-full"
                    type="checkbox"
                    @click="$event.stopPropagation()"
                  />
                  <p class="tw-m-1">Seleccionar mi avatar personalizado</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-container-general">
        <div class="tw-my-5">
          <div class="tw-flex tw-gap-2 tw-items-center">
            <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
              Avatares Predefinidos
            </p>
            <img src="@/assets/img/icons/icon-3.png" alt="icon 3" />
          </div>
        </div>
        <div
          v-for="(category, idx) in predefinedAvatars"
          :key="idx"
          class="tw-flex tw-flex-col tw-gap-3"
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
            {{ category.name }}
          </p>
          <div class="tw-grid tw-grid-cols-3 lg:tw-grid-cols-6 tw-gap-3">
            <div
              v-for="(avatar, idx) in category.avatars"
              :key="idx"
              class="tw-grid tw-grid-rows-rows_2_fit_last tw-h-full tw-rounded-2.5xl tw-shadow-lg tw-border tw-border-gray-320 tw-overflow-hidden"
            >
              <div class="tw-relative">
                <input
                  :id="avatar.id"
                  :value="avatar"
                  v-model="predefined_avatar"
                  name="predefined_avatar"
                  class="tw-text-red-350 tw-border tw-border-black focus:tw-ring-transparent tw-w-4 tw-h-4 lg:tw-w-5 lg:tw-h-5 tw-rounded-full tw-absolute tw-top-2 tw-right-2"
                  type="radio"
                />
                <img
                  :src="uriResources + '/' + avatar.image"
                  class="tw-object-cover tw-w-full tw-h-full"
                  alt=""
                />
              </div>
              <div class="tw-py-3 tw-text-center">
                <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-lg">
                  {{ avatar.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";
import GeneralModal from "@/components/modals/GeneralModal.vue";

export default {
  name: "gamificationAvatar",
  components: { GeneralModal },
  async mounted() {
    await this.actAvatarElements()
      .then(() => {
        this.avatar_elements_loaded = true;
      })
      .then(() => {
        this.showObject(this.avatarElements[0].id);
        if (
          this.user.custom_avatar_object != null &&
          this.user.custom_avatar_object.length > 0
        ) {
          this.avatar.final = JSON.parse(this.user.custom_avatar_object);
        }
      })
      .then(() => {
        this.actGamiPredefinedAvatars();
      });
  },
  data() {
    return {
      active: 0,
      imgCheck: require("@/assets/img/icons/ic-actions-check.svg"),
      custom_avatar: 0,
      isModalOpen: false,
      isPredefinedAvatar: 0,
      predefined_avatar: null,
      predefined_avatar_id: null,
      element_clicked: "",
      avatar_elements_loaded: false,
      subelementssaved: [],
      avatar: {
        final: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        cara: {
          image: "storage/images/5faaef3093df6.png", // this image isn't found.
          zIndex: 10,
        },
      },
      uriResources: config.beerOfficeURL,
      imgBgGamification: require("@/assets/img/gamification/background.png"),
    };
  },
  watch: {
    predefined_avatar: function (val) {
      if (val != null) {
        this.custom_avatar = 0;
        this.isPredefinedAvatar = 1;
      }
    },
    custom_avatar: function (val) {
      if (+val === 1) {
        this.predefined_avatar = null;
        this.isPredefinedAvatar = 0;
      }
    },
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["avatarElements", "predefinedAvatars"]),
    validateSave() {
      if (+this.custom_avatar === 1) {
        return true;
      } else return this.predefined_avatar != null;
    },
  },
  methods: {
    ...mapActions("modGamification", [
      "actAvatarElements",
      "actGamiPredefinedAvatars",
    ]),
    ...mapActions("modCore", ["actUpdateAvatar", "actUser"]),
    showObject: function (id, element_name) {
      if (element_name === null) {
        element_name = "Cara";
      }
      this.element_clicked = element_name;
      this.active = id;
      let objs = document.querySelectorAll(".object");
      objs.forEach((element) => {
        element.style.display = "none";
      });
      document.querySelector(".object-" + id).style.display = "block";
      this.pushDataLayer(this.element_clicked);
    },
    addElementAvatar: function (element, obj, subelement_id) {
      let final = this.avatar.final.slice();

      final[element.z_index2] = null;

      if (this.avatar.final[element.z_index] !== obj.image + "") {
        this.subelementssaved.push(obj.id);
        final[element.z_index] = obj.image;
        if (obj.image2 != null) {
          final[element.z_index2] = obj.image2;
        }
      } else {
        final[element.z_index] = null;
      }
      this.$set(this.avatar, "final", final);
      this.pushDataLayer(this.element_clicked, subelement_id);
    },
    saveAvatar: function () {
      let dataToSend = {
        is_predefined_avatar: this.isPredefinedAvatar,
        ...(this.isPredefinedAvatar && {
          predefined_avatar_id: this.predefined_avatar.id,
        }),
        ...(!this.isPredefinedAvatar && { avatar: this.avatar.final }),
      };

      this.actUpdateAvatar(dataToSend).then((response) => {
        if (response) {
          this.pushDataLayer("Avatar", this.subelementssaved, "Guardar");
          this.openModal();
        }
      });
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Juega",
        event_action: "Cambiar Avatar",
        event_label: "Submit",
        interaction: "True",
        component_name: "Boton",
        element_text: "Guardar",
      });
    },
    selectCustomAvatar() {
      if (+this.custom_avatar === 1) {
        this.custom_avatar = 0;
      } else {
        this.custom_avatar = 1;
      }
    },
    openModal() {
      this.isModalOpen = true;
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Juega",
        event_action: "Cambiar Avatar",
        event_label: "Success",
        interaction: "True",
        component_name: "Popup",
        element_text: "Guardar",
        avatar_type: this.isPredefinedAvatar ? "Predefinido" : "Personalizado",
        avatar_name: this.isPredefinedAvatar
          ? this.predefined_avatar.name
          : null,
      });
    },
    closeModal() {
      this.isModalOpen = false;
    },
    goToBrewery() {
      this.$router.push({ path: "/gamification" });
    },
    pushDataLayer: function (element_name, subelement_id, action) {
      window.dataLayer.push({
        event: "TrackEvent-Gamification",
        category: "Gamification",
        SubCategory: "Avatar",
        Element: element_name,
        "Sub-element": subelement_id,
        Action: action,
      });
    },
  },
};
</script>

<style scoped>
h2 {
  color: #d82b2b;
}

.avatar-carousel {
  overflow: hidden;
  overflow-x: auto;
}

.gamificationAvatar {
  background-color: #fbf7f1;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 100px 30px 0 90px;
  overflow-y: auto;
}

.gamificationAvatar .avatar-head {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

.gamificationAvatar .avatar-nav {
  width: 100%;
  height: 52px;
  background-color: #ffffff;
  padding: 0 0 0 0;
}

.gamificationAvatar .object {
  padding: 10px 0 0 0;
  width: 100%;
  display: none;
  text-align: center;
  overflow: auto;
}

.gamificationAvatar .object table tr td div {
  width: 150px;
  height: 150px;
}

.gamificationAvatar .object table tr td div {
  width: 150px;
  height: 150px;
  position: relative;
}

.gamificationAvatar .object table tr td img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#gamificationBuildAvatar {
  width: 280px;
  height: 280px;
  position: relative;
}

.carrousel-gamification {
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: -10px;
  height: 155px;
}

@media (max-width: 768px) {
  .gamificationAvatar {
    background-color: #fbf7f1;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 60px 0 0 0;
  }

  .gamificationAvatar .avatar-head {
    width: 100%;
    height: 210px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    padding-top: 15px;
  }

  #gamificationBuildAvatar {
    width: 180px;
    height: 180px;
    position: relative;
  }

  .gamificationAvatar .object table tr td div {
    width: 130px;
    height: 130px;
    position: relative;
  }

  .gamificationAvatar .object table tr td img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .gamificationAvatar .avatar-nav {
    width: 100%;
    height: 45px;
    background-color: #ffffff;
    padding: 0 0 0 0;
  }

  .gamificationAvatar .avatar-nav .avatar-carousel table tr td img {
    height: 39px;
  }

  .carrousel-gamification {
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: -10px;
    height: 165px;
  }
}

@media (max-height: 600px) {
  .gamificationAvatar {
    background-color: #fbf7f1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 60px 0 0 0;
  }

  .gamificationAvatar .avatar-head {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    padding-top: 0;
  }

  .gamificationAvatar .avatar-head .text-element h2 {
    height: 34px;
  }

  #gamificationBuildAvatar {
    width: 155px;
    height: 155px;
    position: relative;
  }

  .carrousel-gamification {
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: -10px;
    height: 120px;
  }

  .gamificationAvatar .object table tr td div {
    width: 115px;
    height: 115px;
    position: relative;
  }

  .gamificationAvatar .object table tr td img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .gamificationAvatar .avatar-nav {
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding: 0 0 0 0;
  }

  .gamificationAvatar .avatar-nav .avatar-carousel table tr td img {
    height: 32px;
  }

  .carrousel-gamification {
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: -10px;
    height: 130px;
  }
}
</style>
