<template>
  <div class="tw-container-general" v-if="contextualHelpers.length > 0">
    <div
      class="tw-rounded-3xl tw-shadow-lg tw-p-6 lg:tw-p-10 tw-my-5 tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-3xl tw-text-red-350"
        >
          ¿Cómo usar Embajadores?
        </p>
        <div
          class="tw-flex tw-items-center tw-cursor-pointer"
          @click="$router.push({ path: '/' + $route.query.route })"
        >
          <i
            class="icon-cerrar-simple tw-text-3xl lg:tw-text-5xl tw-text-red-350"
          ></i>
        </div>
      </div>
      <div
        class="tw-overflow-hidden tw-rounded-3xl tw-aspect-w-3 tw-aspect-h-4 lg:tw-aspect-w-16 lg:tw-aspect-h-7"
      >
        <img
          v-if="contextualHelpers.length > 0"
          :src="uriResources + '/' + helper.image"
          class="tw-hidden lg:tw-block tw-object-cover tw-w-full"
          alt=""
        />
        <img
          :src="uriResources + '/' + helper.image_mobile"
          class="tw-block lg:tw-hidden tw-object-cover tw-w-full"
          alt=""
        />
      </div>
      <div class="tw-flex tw-flex-col tw-gap-2 tw-text-center">
        <p
          class="tw-m-0 tw-text-red-350 tw-text-lg lg:tw-text-3xl tw-font-robotoBold"
        >
          {{ helper.name }}
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-xl">
          {{ helper.description }}
        </p>
      </div>
      <div class="tw-grid tw-grid-cols-3">
        <div class="tw-flex tw-items-center tw-justify-end">
          <i
            v-if="helperId !== firstHelper"
            class="icon-flecha-izq tw-text-lg lg:tw-text-3xl tw-text-red-350 tw-cursor-pointer"
            @click="goToPreviousHelper()"
          ></i>
        </div>
        <div
          class="tw-text-center tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-text-red-350"
        >
          {{ helperId + 1 + "/" + helpersLength }}
        </div>
        <div class="tw-flex tw-items-center tw-justify-start">
          <i
            v-if="helperId !== lastHelper"
            class="icon-flecha-der tw-text-lg lg:tw-text-3xl tw-text-red-350 tw-cursor-pointer"
            @click="goToNextHelper()"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import config from "@/services/config";

export default {
  name: "ContextualRoutes",
  data() {
    return {
      isLoading: false,
      uriResources: config.beerOfficeURL,
      helperId: 0,
      route: this.$route.query.route,
      helpersLength: 0,
      firstHelper: 0,
      lastHelper: 0,
      contextualHelpers: [],
    };
  },
  async created() {
    this.isLoading = true;
    await this.actGetContextualHelpers(this.$route.query.route)
      .then((response) => {
        this.contextualHelpers = response;
        this.$route.query.helper !== "all"
          ? (this.helperId = Number(this.$route.query.helper))
          : (this.helperId = 0);
      })
      .finally(() => {
        this.helpersLength = this.contextualHelpers.length;
        this.lastHelper = this.helpersLength - 1;
        this.isLoading = false;
        if (this.helperId === this.lastHelper) {
          this.postSeenRoute({
            route: this.$route.query.route,
          });
        }
      });
  },
  mounted() {},
  computed: {
    helper() {
      return this.contextualHelpers[this.helperId];
    },
  },
  methods: {
    ...mapActions("modContextualHelpers", [
      "actGetContextualHelpers",
      "actPostSeenContextualHelper",
    ]),
    goToNextHelper() {
      this.pushDataLayer();
      this.helperId++;
      this.helperId === this.lastHelper
        ? this.postSeenRoute({
            route: this.$route.query.route,
          })
        : null;
    },
    goToPreviousHelper() {
      this.pushDataLayer();
      this.helperId--;
    },
    async postSeenRoute(route) {
      await this.actPostSeenContextualHelper(route);
    },
    pushDataLayer() {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Soporte",
        event_action: "¿Cómo usar embajadores?",
        event_label: this.helper.name,
        interaction: "True",
        component_name: "PageView",
        element_category: "Soporte",
        page: this.$route.query.originRoute,
      });
    },
  },
};
</script>

<style scoped></style>
