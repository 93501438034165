<template>
  <div class="tw-container-general">
    <ButtonsMenu class="py-3 mb-2 mb-md-4" :buttonsMenuItemActive="2" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p
            class="fs-30 fs-md-40 text-uppercase tw-text-gray-600 font-weight-bold tw-font-tolyerBold mb-3"
          >
            Mi hoja de vida
          </p>
        </div>
      </div>
    </div>
    <div class="container mb-4 pb-3">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
          <AboutUser
            url="/ambassadors/people/cv/view"
            buttonText="Ver hoja de vida"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-8 pt-1">
          <div class="tw-shadow-md tw-rounded-lg tw-bg-white px-3 px-md-4 py-5">
            <MenuCv />
            <keep-alive>
              <component :is="activeCvComponent" />
            </keep-alive>
          </div>
          <p
            class="tw-text-gray-300 tw-text-xs mt-3 text-center text-lg-right font-italic"
          >
            Los cambios realizados en tu hoja de vida se verán reflejados en tu
            perfil de Embajadores.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ButtonsMenu from "@/components/general/ButtonsMenu";
import AboutUser from "@/components/user/CompUserAboutUser.vue";
import MenuCv from "@/components/user/cv/MenuCv";

export default {
  name: "CurriculumVitae",
  components: {
    ButtonsMenu,
    AboutUser,
    MenuCv,
    CvProfile: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "curriculumvitae" */ "@/components/user/cv/CvProfile"
      ),
    CvEducation: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "curriculumvitae" */ "@/components/user/cv/CvEducation"
      ),
    CvExperience: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "curriculumvitae" */ "@/components/user/cv/CvExperience"
      ),
    CvCourse: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "curriculumvitae" */ "@/components/user/cv/CvCourse"
      ),
    CvLanguage: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "curriculumvitae" */ "@/components/user/cv/CvLanguage"
      ),
  },
  created() {
    this.getPeopleResume();
  },
  computed: {
    ...mapState("modCv", ["activeCvComponent"]),
  },
  methods: {
    ...mapActions("modCv", ["getPeopleResume"]),
  },
};
</script>
