const datesMixin = {
  data() {
    return {
      today: new Date(),
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthsShort: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      days: [
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ],
    };
  },
  computed: {
    getTodayYYYYmmdd() {
      let dd = this.today.getDate();
      let mm = this.today.getMonth() + 1; //January is 0!
      let yyyy = this.today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      return yyyy + "-" + mm + "-" + dd;
    },
  },
  methods: {
    getMonthName(monthId) {
      let currentId = monthId;

      if (monthId > 0) {
        currentId = currentId - 1;
      } else {
        currentId = 0;
      }
      return this.months[currentId];
    },
    getMonthNameBy0(monthId) {
      return this.months[monthId];
    },
    convertFrom24To12Format(time24) {
      const [sHours, minutes] = time24
        .match(/([0-9]{1,2}):([0-9]{2})/)
        .slice(1);
      const period = +sHours < 12 ? "AM" : "PM";
      const hours = +sHours % 12 || 12;

      return `${hours}:${minutes} ${period}`;
    },
    formatDate(value) {
      if (!value) return "";
      let date = value.substring(0, 10);
      let fragDate = date.split("-");
      return (
        this.getMonthName(fragDate[1]) +
        " " +
        fragDate[2] +
        " de " +
        fragDate[0]
      );
    },
  },
};

export default datesMixin;
