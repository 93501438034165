var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.MobileBar,{attrs:{"title":"Recompensas"}}),_c(_setup.TitleBar,{attrs:{"title":"Recompensas"}}),(!_setup.isLoading)?_c('main',{staticClass:"tw-container-general"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8"},[_vm._m(0),_c('section',{staticClass:"tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4"},[_c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-2"},[_c('div',{staticClass:"tw-grid tw-grid-cols-2 lg:tw-grid-cols-col2_fit_first tw-gap-4 md:tw-gap-8 tw-items-center"},[_vm._m(1),_c('div',{staticClass:"tw-h-12 lg:tw-h-14 tw-w-full lg:tw-w-max"},[_c(_setup.CompGeneralDropdown,{attrs:{"fitted":false,"isLabel":false,"options":_setup.stagesByDueDateDesc,"default-option":_setup.optionSelected?.id,"name":"tour_dropdown","border-color":"black"},on:{"changeSelectedOption":(option) => _setup.changeTour(option),"chargeOption":(option) => _setup.changeTour(option)}})],1)]),_c('div',{staticClass:"tw-w-full lg:tw-w-max tw-justify-self-end"},[_c('div',{staticClass:"tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-flex tw-items-centert tw-justify-center lg:tw-justify-between tw-gap-3 tw-py-2 lg:tw-py-3"},[_c('p',{class:{
                  'tw-m-0': true,
                  'tw-font-robotoMedium': true,
                  'tw-text-lg': true,
                  'lg:tw-text-xl': true,
                }},[_vm._v(" "+_vm._s(_setup.isPastDate(_setup.optionSelected?.due_date) ? "Esta etapa ha finalizado" : "Tus Toins de esta etapa ")+" "),(!_setup.isPastDate(_setup.optionSelected?.due_date))?_c('span',{staticClass:"tw-text-yellow-400"},[_vm._v(" "+_vm._s(_setup.peopleTourStore.employeeToinsBalance)+" ")]):_vm._e()]),_vm._m(2)])])]),_c('div',{staticClass:"tw-grid tw-grid-cols-2 lg:tw-grid-cols-1 tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-justify-start"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-2xl"},[_vm._v(" Premios - "+_vm._s(_setup.optionSelected?.name)+" ")])]),_c('div',{staticClass:"tw-flex tw-justify-end lg:tw-justify-start"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base tw-text-right"},[_vm._v(" Disponible desde el "),_c('span',{staticClass:"tw-font-robotoBold"},[_vm._v(_vm._s(_setup.formatDateYYYYMMDDLong(_setup.optionSelected?.start_date)))]),_vm._v(" hasta el "),_c('span',{staticClass:"tw-font-robotoBold"},[_vm._v(_vm._s(_setup.formatDateYYYYMMDDLong(_setup.optionSelected?.due_date)))])])])])]),(!_setup.isLoading)?_c('section',{staticClass:"grid-rewards tw-grid tw-grid-cols-2 lg:tw-grid-cols-5 tw-gap-3 lg:tw-gap-6"},_vm._l((_setup.rewardsByStageDueDate),function(product,idx){return _c(_setup.CompPeopleTourRewardCard,{key:idx,attrs:{"product":product,"toinsBalance":_setup.peopleTourStore.employeeToinsBalance,"isActive":_setup.todayIsBetweenDates(
              _setup.optionSelected?.start_date,
              _setup.optionSelected?.due_date
            ),"quantity-visible":"","redeem-button":!product.people_tour_stage?.has_redeemed}})}),1):_vm._e(),_c('aside',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-end tw-gap-2"},[_c('p',{staticClass:"tw-m-0 tw-text-xs lg:tw-text-sm tw-font-roboto tw-italic"},[_vm._v(" Conoce los términos y condiciones para tus premios de Tour de Gente ")]),_c('button',{staticClass:"tw-btn-primary-white tw-flex tw-gap-1 tw-w-full lg:tw-w-max tw-p-2 lg:tw-p-3",on:{"click":function($event){return _setup.pushLink(
              'https://api.embajadores.com.co/storage/documents/1720207785.pdf'
            )}}},[_c('i',{staticClass:"icon-archivo tw-text-red-350 tw-text-base"}),_c('span',{},[_vm._v("Términos y condiciones")])])])])]):_c(_setup.SkeletonPeopleTourRewards)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v(" Para tener acceso a estas recompensas, debes completar "),_c('span',{staticClass:"tw-font-robotoBold tw-text-red-350"},[_vm._v("actividades en el Tour de Gente.")]),_vm._v(" Cada vez que finalices una etapa, obtendrás una cantidad de puntos que podrás canjear en dicha etapa. Sin embargo, es importante recordar que estas recompensas "),_c('span',{staticClass:"tw-font-robotoBold tw-text-red-350"},[_vm._v("dejarán de estar disponibles al finalizar la etapa.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl"},[_vm._v(" Etapa ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-1"},[_c('i',{staticClass:"icon-star tw-golden-black-border-icon"})])
}]

export { render, staticRenderFns }