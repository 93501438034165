<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useContestsStore } from "@/stores/contestsStore";
import { storeToRefs } from "pinia";
import { orderArrayByDateDesc } from "@/helpers/datesHelpers";
import { isStillTime } from "@/helpers/datesHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompContestsActiveContestCard from "@/components/contests/CompContestsActiveContestCard.vue";
import CompSelectCategories from "@/components/general/CompSelectCategories.vue";
import CompContestsGridContainer from "@/components/contests/CompContestsGridContainer.vue";

const isLoading = ref(false);
const contestsStore = useContestsStore();
const { competitions } = storeToRefs(contestsStore);
const { actGetCompetitions } = contestsStore;
const filter = ref("");

const changeFilter = (opt) => {
  filter.value = opt;
};

const filteredContests = computed(() => {
  if (filter.value === "" || filter.value === "ALL") {
    return competitions.value;
  }
  return competitions.value.filter(
    (competition) => competition.status.name === filter.value
  );
});

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetCompetitions("ALL");
  competitions.value = orderArrayByDateDesc(competitions.value, "end");
  competitions.value = competitions.value.map((competition) => {
    return {
      ...competition,
      status: {
        id: isStillTime(competition.end) ? 1 : 2, // 1: En curso, 2: Finalizado
        name: isStillTime(competition.end) ? "En curso" : "Finalizado",
      },
    };
  });
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Concursos" />
    <title-bar title="Concursos" />
    <main class="tw-container-general" v-if="!isLoading">
      <comp-contests-active-contest-card :contest="competitions[0]" />
      <div
        class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 tw-justify-between tw-my-5 lg:tw-my-10"
      >
        <p class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0">
          Otros concursos
        </p>
        <comp-select-categories
          :items="competitions"
          categoryToFilter="status"
          @changeFilter="changeFilter"
        />
      </div>
      <comp-contests-grid-container :contests="filteredContests" />
    </main>
  </div>
</template>

<style scoped></style>
