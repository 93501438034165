<script setup>
import { onBeforeMount, onMounted, ref } from "vue";
import sso from "@/services/sso";
import { useRoute } from "vue-router/composables";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const route = useRoute();
const error = ref({ flag: false, class: "", message: "" });
const errorMessage = ref({ active: false, message: "" });

const errorMessageSSO = () => {
  const routeError = route.params.errorMessage;
  if (routeError) {
    errorMessage.value = {
      active: true,
      message: routeError.inactive
        ? routeError.message
        : "¡Error al acceder, intenta nuevamente!",
    };
  }
};

const automaticRedirectSSO = () => {
  if (route.name === "LoginSSO") {
    redirectSSO();
  }
};

const generateState = () => {
  const randomState = Array(40)
    .fill("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789")
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join("");
  sessionStorage.setItem("state", randomState);
  return randomState;
};

const redirectSSO = () => {
  pushDataLayer({
    event_category: "Registro",
    event_action: "Click",
    event_label: "Iniciar sesión",
    component_name: "Inicia la experiencia",
  });
  const parameters = new URLSearchParams({
    response_type: "code",
    client_id: sso.CLIENT_ID,
    redirect_uri: sso.URL_CALLBACK,
    state: generateState(),
    scope: sso.SCOPE,
  });
  window.location.href = sso.URL_AUTORIZE + parameters.toString();
};

onBeforeMount(() => {
  error.value.flag = false;
  error.value.class = "";
});

onMounted(() => {
  errorMessageSSO();
  automaticRedirectSSO();
});
</script>
<template>
  <div class="pt-2 px-2 px-md-4">
    <h2 class="text-center tw-text-3xl md:tw-text-4xl tw-font-tolyerBold">
      ¡Inicia la experiencia!
    </h2>
    <p
      class="tw-text-sm md:tw-text-base tw-mb-4 tw-text-gray-600 tw-font-roboto"
    >
      Ingresa tus datos e inicia sesión para empezar a vivir una experiencia
      única con BeerOffice.
    </p>
    <div v-if="errorMessage.active">
      <h6 class="text-danger text-center">{{ errorMessage.message }}</h6>
    </div>
    <div class="text-center pt-3">
      <a
        @click="redirectSSO"
        class="btn btn-primary rounded-pill px-5 background-primary mb-2"
      >
        Iniciar sesión
      </a>
    </div>
    <br />
    <br />
  </div>
</template>

<style scoped>
label,
input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input:placeholder-shown + label {
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 0) scale(1);
  font-size: 16px;
  color: #ced4da;
  position: absolute;
  top: 16px;
  left: 10px;
  opacity: 0;
}

::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

input:focus::-webkit-input-placeholder {
  opacity: 1;
}

input:not(:placeholder-shown) + label,
input:focus + label {
  transform: translate(0, -15px) scale(1);
  cursor: pointer;
  opacity: 1;
  position: absolute;
  top: 18px;
  left: 10px;
  font-size: 10px;
  color: #ced4da;
}
</style>
