<script setup>
import { useProfileStore } from "@/stores/profileStore";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router/composables";

const { profileType } = storeToRefs(useProfileStore());
const { user } = storeToRefs(useUserStore());
const router = useRouter();

const sendHome = () => {
  window.dataLayer.push({
    event: "'trackEvent",
    target: "Tienes_Espíritu",
    action: user.value.id + "",
    "target-properties": profileType.value.name,
    value: 5000,
  });

  router.push({ path: "/home" });
};

const getFirstName = (name) => {
  return name.substring(0, name.indexOf(" "));
};
</script>
<template>
  <div class>
    <div class="my-auto mx-auto p-1">
      <div class="result-text title">
        <span class="text-capitalize">{{
          getFirstName(user.name).toLowerCase()
        }}</span
        >, tienes espíritu:
      </div>
      <h1 class="mt-3 tw-text-4xl md:tw-text-7xl tw-font-tolyerBold">
        {{ profileType.name }}
      </h1>
      <div class="result-text content-description">
        {{ profileType.description }}
      </div>
      <div class="mt-4 text-center">
        <button
          type="button"
          href="javascript:void(0)"
          @click="sendHome()"
          class="btn btn-primary btn-principal px-5 background-primary"
        >
          ¡Vive la experiencia!
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.btn-primary {
  font: 14px/17px Roboto;
}

.content-description {
  max-width: 312px;
}

.result-text {
  color: #0f0e0e;
  font: 20px/21px Roboto;
  letter-spacing: 0;
  opacity: 1;
}

@media (max-width: 600px) {
  .result-text {
    color: #0f0e0e;
    font: 16px/18px Roboto;
    letter-spacing: 0;
    opacity: 1;
  }
  .result-text.title {
    font: bold 16px/18px Roboto;
  }
}
</style>
