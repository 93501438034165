<script setup>
import { ref, computed, onMounted } from "vue";
import { pushLink } from "@/helpers/linkHelpers";
import { useRouter, useRoute } from "vue-router/composables";
import { useReservationsStore } from "@/stores/reservationsStore";
import { useCoreStore } from "@/stores/coreStore";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import GeneralModal from "@/components/modals/GeneralModal.vue";

const router = useRouter();
const route = useRoute();
const reservationsStore = useReservationsStore();
const coreStore = useCoreStore();
const optionPayment = ref(1);
const isUploading = ref(false);
const isErrorModalOpen = ref(false);
let formData = new FormData();

function selectOption(event) {
  optionPayment.value = Number(event.target.value);
}

function uploadPayment() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute(
    "accept",
    "image/png, image/jpeg, image/jpg, application/pdf"
  );
  input.click();
  input.onchange = () => {
    const file = input.files[0];
    if (file.size <= 2000000) {
      formData.append("booking_payment_method_id", optionPayment.value);
      formData.append("proof_payment", file);
      postBooking(formData);
    } else {
      alert("El archivo no puede ser mayor a 2MB");
    }
  };
}

async function postBooking(formData) {
  isUploading.value = true;
  try {
    await reservationsStore
      .actPostBookingPayment({
        id: route.params.bookingId,
        data: formData,
      })
      .then((response) => {
        if (response.message === "Payment updated") {
          router.push(`/reservations/space/${route.params.id}/book/thanks`);
        } else {
          showErrorModal();
        }
      });
  } catch (error) {
    console.error(error);
  } finally {
    isUploading.value = false;
  }
}

function downloadSharedFile(url) {
  let downloadURL = coreStore.getURLResourceByKey(url);
  let a = document.createElement("a");
  a.href = downloadURL;
  a.click();
}

function showErrorModal() {
  isErrorModalOpen.value = true;
}

function closeErrorModal() {
  isErrorModalOpen.value = false;
  router.push(`/reservations/bookingHistory`);
}

const paymentOptions = {
  1: "Realizar pago por PSE en el siguiente botón y sigue los pasos:",
  2: "Realizar pago en Baloto con los siguientes datos:",
  3: "Realizar pago en Efecty con los siguientes datos:",
  4: "Realizar pago en Bancolombia con los siguientes datos:",
};

const paymentText = computed(() => {
  return paymentOptions[optionPayment.value] || "hola";
});

const agreementNumberText = computed(() => {
  if (optionPayment.value === 3) {
    return "No disponible";
  } else if (optionPayment.value === 4) {
    return "Nº 28834";
  } else {
    return true;
  }
});

const debtorCodes = {
  3: "No disponible",
  4: "SAP 100070",
};

const debtorCodeText = computed(() => {
  return debtorCodes[optionPayment.value] || true;
});

onMounted(async () => {
  await coreStore.actGetSharedResources();
});
</script>
<template>
  <div class="tw-relative">
    <general-modal
      :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
      modal-name="uploadActivityFile"
      v-if="isErrorModalOpen"
      :isShowModal="isErrorModalOpen"
      @close="closeErrorModal"
    >
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2"
      >
        <img src="@/assets/img/modal/modal-error.svg" alt="" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-red-350"
        >
          Lo sentimos
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
        >
          ¡Algo ha salido mal! Inténtalo de nuevo mas adelante.
        </p>
      </div>
    </general-modal>
    <mobile-bar title="Reserva" />
    <title-bar title="Reserva" />
    <main class="tw-container-general">
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-x-14 tw-my-4 md:tw-my-10"
      >
        <div class="tw-flex tw-flex-col tw-gap-5 md:tw-gap-6">
          <div class="tw-flex tw-items-center tw-justify-center">
            <p
              class="tw-m-0 tw-font-robotoMedium tw-text-red-350 tw-text-xl lg:tw-text-2xl"
            >
              ¡Hemos guardado tu reserva!
            </p>
          </div>
          <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl tw-m-0">
            Pago
          </p>
          <p class="tw-font-roboto tw-text-xs md:tw-text-lg tw-m-0">
            Puedes realizar el pago de tu reserva a través de estos medios,
            recuerda que debes hacer el pago <strong>5 días</strong> antes de tu
            reversa
          </p>
          <div
            class="tw-rounded-2xl tw-border tw-border-gray-300 tw-w-full tw-grid tw-grid-flow-col tw-auto-cols-fr tw-overflow-hidden"
          >
            <div
              class="tw-border tw-border-l-0 tw-border-r tw-border-b-0 tw-border-t-0 tw-border-gray-300"
            >
              <label
                class="tw-font-roboto tw-text-xs md:tw-text-base tw-flex tw-flex-col tw-items-center tw-gap-3 tw-py-3"
                for="radio-1"
              >
                <img
                  class="tw-object-contain tw-h-10 md:tw-h-16"
                  src="@/assets/img/reservations/paymentlogos/pse.png"
                  alt=""
                />
                <input
                  id="radio-1"
                  checked
                  class="tw-text-red-350"
                  name="radio"
                  type="radio"
                  value="1"
                  @change="selectOption($event)"
                />
                PSE
              </label>
            </div>
            <!--            <div
                class="tw-border tw-border-l-0 tw-border-r tw-border-b-0 tw-border-t-0 tw-border-gray-300">
              <label
                  class="tw-font-roboto tw-text-xs md:tw-text-base tw-flex tw-flex-col tw-items-center tw-gap-3 tw-py-3"
                  for="radio-2">
                <img class="tw-object-contain tw-h-10 md:tw-h-16"
                     src="@/assets/img/reservations/paymentlogos/baloto.svg">
                <input id="radio-2" class="tw-text-red-350" name="radio" type="radio" value="2"
                       @change="selectOption($event)">
                Baloto</label>
            </div>-->
            <div
              class="tw-border tw-border-l-0 tw-border-r tw-border-b-0 tw-border-t-0 tw-border-gray-300"
            >
              <label
                class="tw-font-roboto tw-text-xs md:tw-text-base tw-flex tw-flex-col tw-items-center tw-gap-3 tw-py-3"
                for="radio-3"
              >
                <img
                  class="tw-object-contain tw-h-10 md:tw-h-16"
                  src="@/assets/img/reservations/paymentlogos/efecty.svg"
                  alt=""
                />
                <input
                  id="radio-3"
                  class="tw-text-red-350"
                  name="radio"
                  type="radio"
                  value="3"
                  @change="selectOption($event)"
                />
                Efecty</label
              >
            </div>
            <div
              class="tw-border tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-t-0 tw-border-gray-300"
            >
              <label
                class="tw-font-roboto tw-text-xs md:tw-text-base tw-flex tw-flex-col tw-items-center tw-gap-3 tw-py-3"
                for="radio-4"
              >
                <img
                  class="tw-object-contain tw-h-10 md:tw-h-16"
                  src="@/assets/img/reservations/paymentlogos/bancolombia.svg"
                  alt=""
                />
                <input
                  id="radio-4"
                  class="tw-text-red-350"
                  name="radio"
                  type="radio"
                  value="4"
                  @change="selectOption($event)"
                />
                Bancolombia</label
              >
            </div>
          </div>
          <p class="tw-font-roboto tw-text-xs md:tw-text-base tw-m-0">
            {{ paymentText }}
          </p>
          <div
            class="tw-rounded-3xl tw-border tw-border-gray-300 tw-p-4 md:tw-p-8 tw-flex tw-flex-col"
          >
            <div class="tw-flex tw-justify-between" v-if="optionPayment !== 1">
              <p class="tw-font-roboto tw-m-0 tw-text-xs md:tw-text-sm">
                <strong>Convenio: </strong>{{ agreementNumberText }}
              </p>
              <p class="tw-font-roboto tw-m-0 tw-text-xs md:tw-text-sm">
                <strong>Código de deudor: </strong>{{ debtorCodeText }}
              </p>
            </div>
            <hr class="tw-w-full" v-if="optionPayment !== 1" />
            <div class="tw-flex tw-justify-between">
              <p class="tw-font-robotoBold tw-m-0 tw-text-lg md:tw-text-3xl">
                Valor a pagar:
              </p>
              <p class="tw-font-roboto tw-m-0 tw-text-lg md:tw-text-3xl">
                <span class="tw-text-red-350">$</span>
                {{
                  Intl.NumberFormat("es-CO", {
                    currency: "COP",
                    maximumFractionDigits: 0,
                  }).format(route.params.totalPrice)
                }}
              </p>
            </div>
          </div>
          <div
            class="tw-flex tw-justify-between tw-gap-4"
            v-if="optionPayment === 1"
          >
            <div
              class="tw-btn-primary-red tw-flex tw-justify-center tw-py-3 tw-mt-3 md:tw-mt-5"
              @click="
                pushLink(
                  'https://www.bavaria.co/clientes/pagos-en-linea-bavaria'
                )
              "
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
                Pago por PSE
              </p>
            </div>
            <div
              class="tw-btn-secondary-white tw-flex tw-justify-center tw-py-3 tw-mt-3 md:tw-mt-5"
              @click="downloadSharedFile('PAGO_RESERVA_PSE')"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
                Ver instructivo
              </p>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6">
          <hr class="tw-w-full md:tw-hidden" />
          <div
            class="tw-w-full md:tw-rounded-3xl md:tw-border md:tw-border-gray-300 md:tw-py-8 md:tw-px-12"
          >
            <div v-if="!isUploading">
              <p class="tw-font-robotoBold tw-text-sm md:tw-text-xl tw-m-0">
                Sube tu comprobante de pago
              </p>
              <div
                class="tw-my-5 md:tw-mt-10 md:tw-mb-6 tw-grid tw-grid-cols-3 md:tw-grid-cols-col2_fit_first tw-gap-6 md:tw-gap-8"
              >
                <div
                  class="tw-rounded-2xl tw-border tw-border-gray-300 tw-p-4 md:tw-rounded-none md:tw-border-none md:tw-p-0"
                >
                  <img
                    class="tw-w-full tw-object-contain"
                    src="@/assets/img/reservations/receipt.svg"
                    alt=""
                  />
                </div>
                <div
                  class="tw-col-span-2 md:tw-col-span-1 tw-flex tw-flex-col tw-gap-3"
                >
                  <p
                    class="tw-font-roboto tw-text-xs md:tw-text-lg tw-text-gray-500 tw-m-0"
                  >
                    Si cancelas tu reserva, este dinero no será reembolsado,
                    pero podrás usarlo en la siguiente reserva
                  </p>
                  <div
                    class="tw-btn-primary-red tw-flex tw-justify-center tw-py-3"
                    @click="uploadPayment()"
                  >
                    <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
                      Subir
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3 lg:tw-gap-6"
            >
              <img
                src="@/assets/img/reservations/uploading.png"
                class=""
                alt=""
              />
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-xl tw-text-red-350"
              >
                Estamos cargando tu pago
              </p>
            </div>
          </div>
          <hr class="tw-w-full md:tw-hidden" />
          <p class="tw-font-roboto tw-text-xs md:tw-text-lg tw-m-0">
            ¿Deseas realizar el pago después? recuerda que el plazo máximo para
            pagar tu reserva son 5 días antes del primer día reservado.
          </p>
          <div
            class="tw-btn-secondary-white tw-flex tw-justify-center tw-py-3 tw-w-full"
            @click="$router.push({ path: '/reservations' })"
          >
            <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
              Volver a reserva de espacios
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped>
input[type="radio"]:focus {
  border-width: 0;
  outline: 0; /* I have also tried outline:none */
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
