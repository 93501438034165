<template>
  <auth-layout>
    <auth-validate-account></auth-validate-account>
  </auth-layout>
</template>

<script>
import AuthLayout from "../../components/auth/AuthLayout.vue";
import AuthValidateAccount from "../../components/auth/AuthValidateAccount.vue";

export default {
  name: "ValidateAccount",
  components: {
    AuthLayout,
    AuthValidateAccount,
  },
  data() {
    return {};
  },
};
</script>
