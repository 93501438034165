<template>
  <div class="mt-0 mt-md-4 px-0 px-md-3 px-lg-5">
    <GamificationMainMenu />
    <div
      class="container-fluid gamification"
      :style="{
        backgroundImage: `url('${imgBgGamification}')`,
      }"
    >
      <div class="row">
        <div class="col-12 col-md-10 mx-auto">
          <div class="cont-user-data plg-5 text-center">
            <div v-if="view === 0">
              <div class="d-flex justify-content-center">
                <img
                  class="avatar"
                  width="100%"
                  :src="imgAvatar"
                  alt="avatar"
                />
              </div>

              <p class="title mb-4">¡Conoce la malteria!</p>
              <p class="desc mb-4">
                ¿Estás listo para conocer todas las recompensas que tiene ser un
                Embajador? Bienvenido a La Maltería, aquí encontrarás las
                misiones que te irán llevando a ser un Embajador #BEEROFFICE.
              </p>
              <button
                type="button"
                class="btn-go mt-3 mb-4 mt-md-4 mb-md-5 align-items-center"
                @click="nextView()"
              >
                ¡Empezar!
              </button>
              <div class="img-rotate d-block d-md-none mt-2 mb-2">
                <img alt="" :src="imgSymbol" />
              </div>
            </div>
            <div v-if="view === 1">
              <p class="title-2 mb-0">
                ¡La amistad del espíritu {{ user.prof_profile.team_title }}!
              </p>
              <div class="medal">
                <img
                  v-if="user.prof_profile.team_image != null"
                  class="mb-2"
                  width="100%"
                  :src="uriResources + '/' + user.prof_profile.team_image"
                  alt="avatar"
                />
                <img
                  alt=""
                  v-else
                  class="rounded-circle my-auto mb-4"
                  :src="imgVideo"
                />
              </div>
              <p class="desc-2 mt-3 mt-md-0">
                {{ user.prof_profile.team_description }}
              </p>
              <button
                type="button"
                class="btn-go mt-4 mb-4 align-items-center"
                @click="nextHome()"
              >
                Continuar
              </button>
              <div
                v-if="view === 0"
                class="img-rotate2 d-block d-md-none mt-0 mb-3"
              >
                <img :src="imgSymbol" alt="" />
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="img-top-rigth d-none d-md-block mb-5">
            <img :src="imgSymbolV" alt="" />
          </div>
        </div>
        <!-- .row -->
      </div>
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";

import GamificationMainMenu from "@/components/gamification/CompGamificationMainMenu";

export default {
  name: "gamificationWelcome",
  components: {
    GamificationMainMenu,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgBgGamification: require("@/assets/img/gamification/background.png"),
      imgSymbolV: require("@/assets/img/gamification/symbol-1Vertical.png"),
      imgSymbol: require("@/assets/img/gamification/symbol-1.png"),
      imgAvatar: require("@/assets/img/gamification/avatar.png"),
      reward: {},
      resultDiff: 0,
      data: {},
      view: 0,
    };
  },
  mounted() {
    this.actGamiRewards();
    this.itemReward(this.$route.params.id);
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["missions", "rewards", "redeem"]),
  },
  methods: {
    ...mapActions("modGamification", [
      "actGamiMissions",
      "actGamiRewards",
      "actRedeemGamiRewards",
    ]),
    nextView() {
      this.view = 1;
    },
    nextHome() {
      this.$router.push({ path: "/gamification" });
    },
    itemReward(itemRewardId) {
      setTimeout(() => {
        let find = this.rewards.findIndex(
          (element) => element.id === itemRewardId
        );
        if (find >= 0) {
          this.reward = this.rewards[find];
        } else {
          this.reward = [];
        }
      }, 1000);
    },
    // getFirstName(string) {
    //   string = string + "";
    //   let name = string.split(" ");
    //   return name[0].toLowerCase();
    // },
  },
};
</script>
<style lang="css" scoped>
.gamification {
  background-size: cover;
  padding: 90px 20px 50px 20px;
  height: 100%;
}

.gamification .cont-user-data {
  background: #ffffff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.16);
  padding: 100px 25px 0 25px;
  height: 100%;
}
.gamification .cont-user-data .avatar {
  width: 140px;
  height: 140px;
  flex: 240px 0 0;
  border-radius: 140px;
  position: absolute;
  background: #ffc12f;
  top: -70px;
}
.gamification .cont-user-data .medal img {
  width: 160px;
  height: 160px;
  flex: 240px 0 0;
  position: relative;
  top: -10px;
}
.gamification .cont-user-data .title {
  font: normal normal bold 48px TolyerBold1;
  letter-spacing: 0;
  line-height: 38px;
  color: #d82b2b;
}
.gamification .cont-user-data .title-2 {
  font: normal normal bold 48px TolyerBold1;
  letter-spacing: 0;
  color: #d82b2b;
  position: relative;
  top: -45px;
}
.gamification .cont-user-data .desc {
  font: normal normal normal 14px Roboto;
  letter-spacing: 0;
  text-align: left;
  color: #717171;
}
.gamification .cont-user-data .desc-2 {
  font: normal normal normal 14px Roboto;
  margin: 30px 0 15px 0;
  text-align: center;
  letter-spacing: 0;
  color: #717171;
}
.gamification .cont-user-data .btn-go {
  background: #d82b2b 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  font: normal normal bold 30px TolyerBold1;
  padding: 5px 40px 5px 40px;
  border-color: #d82b2b;
  opacity: 1;
}
.img-rotate img {
  width: 120px;
  height: 28px;
  margin-bottom: 24px;
}
.tab-links {
  list-style: none;
}

.tab-links li a {
  color: #bababa;
  font: normal normal bold 14px Roboto;
  border-bottom: 1px solid #bababa;
  text-decoration: none;
}

.tab-links li a.active {
  color: #d82b2b;
  border-bottom: 1px solid #d82b2b;
}
@media (min-width: 768px) {
  .gamification {
    padding: 110px 50px 0 50px;
    height: 100%;
  }
  .gamification .cont-user-data {
    padding: 150px 27% 0 27%;
    height: 100%;
  }
  .gamification .cont-user-data .avatar {
    width: 230px;
    height: 230px;
    border-radius: 230px;
    flex: 240px 0 0;
    position: absolute;
    background: #ffc12f;
    top: -110px;
  }
  .gamification .cont-user-data .medal img {
    width: 230px;
    height: 230px;
    flex: 240px 0 0;
    position: relative;
    top: -50px;
  }
  .gamification .cont-user-data .title {
    font: normal normal bold 64px/47px TolyerBold1;
    letter-spacing: 0;
    color: #d82b2b;
  }
  .gamification .cont-user-data .title-2 {
    font: normal normal bold 64px/47px TolyerBold1;
    letter-spacing: 0;
    color: #d82b2b;
    position: relative;
    top: -90px;
  }
  .gamification .cont-user-data .desc {
    font: normal normal normal 18px/21px Roboto;
    margin: 30px 0 15px 0;
    text-align: center;
    letter-spacing: 0;
    color: #717171;
  }
  .gamification .cont-user-data .desc-2 {
    font: normal normal normal 18px/21px Roboto;
    letter-spacing: 0;
    color: #717171;
  }
  .gamification .cont-user-data .btn-go {
    background: #d82b2b 0 0 no-repeat padding-box;
    border-radius: 8px;
    color: #ffffff;
    font: normal normal bold 38px/44px TolyerBold1;
    padding: 5px 40px 5px 40px;
    border-color: #d82b2b;
    opacity: 1;
  }
  .img-top-rigth img {
    position: absolute;
    top: 40px;
    right: 60px;
  }
  .tab-links {
    list-style: none;
  }

  .tab-links li a {
    text-align: center;
    color: #bababa;
    font: normal normal bold 16px Roboto;
    border-bottom: 1px solid #bababa;
    text-decoration: none;
  }

  .tab-links li a.active {
    color: #d82b2b;
    border-bottom: 1px solid #d82b2b;
  }
}
</style>
