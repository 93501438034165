var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-container-title"},[_c('ButtonsPeopleCycle',{staticClass:"py-3 mb-2 mb-md-4",attrs:{"peopleCycleButtonsActive":3}}),_c('PCNavigate',{attrs:{"pc_item":_vm.pc_item}}),_c('div',{staticClass:"container-md pb-5",attrs:{"id":"general-wrapper"}},[(_vm.isLoading)?_c('div',{staticClass:"mb-5"},[_vm._m(0)]):_c('div',[_c('div',{staticClass:"tw-shadow-lg tw-rounded-lg d-flex flex-column p-0"},[_c('div',{staticClass:"tw-shadow-lg tw-rounded-lg he-200",style:('background-color: ' + _vm.peopleCycleItem.color)},[_c('div',{staticClass:"container h-100 d-flex align-items-md-center"},[_c('div',{staticClass:"row align-items-center px-3 px-md"},[_c('div',{staticClass:"col-3 col-md-2 text-center order-2 order-md-1"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.uriResources + '/' + _vm.peopleCycleItem.icon,"alt":""}})]),_c('div',{staticClass:"col-9 col-md-4 text-uppercase tw-font-tolyerBold text-white p-0 order-1 order-md-2"},[_c('p',{staticClass:"fs-44 fs-md-70 line-height-40 line-height-md-60 p-0 m-0"},[_vm._v(" "+_vm._s(_vm.peopleCycleItem.title)+" ")])]),_c('div',{staticClass:"col-12 col-md-5 order-3 p-0"},[_c('p',{staticClass:"text-white tw-font-robotoLight tw-text-xs fs-md-20 line-height-18 line-height-md-24 text-md-right pl-md-4"},[_vm._v(" "+_vm._s(_vm.peopleCycleItem.description)+" ")])])])])]),_c('div',{staticClass:"px-3 px-md-5 py-5"},[_c('div',{staticClass:"text-center position-relative"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.uriResources + '/' + _vm.peopleCycleItem.image,"alt":""}}),_c('img',{staticClass:"h-10 h-md-100 tag",attrs:{"src":require("@/assets/img/peopleCycle/cc_icon_pdp.png"),"alt":""}})])]),(
            _vm.peopleCycleItem.sub_title !== null ||
            _vm.peopleCycleItem.sub_description !== null
          )?_c('div',{staticClass:"px-3 px-md-5 py-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 text-center position-relative px-3 px-md-5"},[_c('p',{staticClass:"tw-font-robotoBold text-uppercase fs-md-28"},[_vm._v(" "+_vm._s(_vm.peopleCycleItem.sub_title)+" ")])]),_c('div',{staticClass:"col-md-8 text-center position-relative px-3 px-md-5"},[_c('p',{staticClass:"tw-font-roboto fs-md-20 line-height-md-24"},[_vm._v(" "+_vm._s(_vm.peopleCycleItem.sub_description)+" ")])])])]):_vm._e(),(_vm.peopleCycleItem.people_cycle_sub_item.length > 0)?_c('div',{staticClass:"px-3 px-md-5"},[_c('div',{staticClass:"row justify-content-around justify-content-md-center px-3 px-md-5 py-3 pt-md-3"},_vm._l((_vm.peopleCycleItem.people_cycle_sub_item),function(item){return _c('div',{key:item.id,staticClass:"col-12 col-md m-1 m-md-3 tw-rounded-lg shadow-box-6 bg-white p-1 p-md-3 d-flex flex-md-column flex-row align-items-center"},[_c('div',{staticClass:"overflow-hidden text-center"},[_c('img',{staticClass:"img-fluid p-2",attrs:{"src":_vm.uriResources + '/' + item.icon,"alt":""}})]),_c('div',{staticClass:"overflow-hidden text-center w-80 w-md-100"},[_c('p',{staticClass:"tw-font-roboto tw-text-xxs fs-md-16 tw-font-bold py-2 m-0"},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0)]):_vm._e()]),(_vm.peopleCycleItem.indicator)?_c('TabPeople',{attrs:{"items":_vm.peopleCycleItem.indicator}}):_vm._e(),(_vm.peopleCycleItem.steps.length > 0)?_c('div',{staticClass:"mt-md-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"tw-rounded-lg col-12 col-md-12 py-4"},[_c('div',{staticClass:"text-center px-2 px-md-3 pt-3"},[_c('p',{staticClass:"tw-font-tolyerBold fs-34 fs-md-50"},[_vm._v(" "+_vm._s(_vm.peopleCycleItem.steps[0].title)+" ")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-justify-items-center lg:tw-grid-flow-col lg:tw-auto-cols-fr tw-gap-4"},_vm._l((_vm.peopleCycleItem.steps[0]
                    .people_cycle_step_card),function(item){return _c('div',{key:item.id,staticClass:"tw-h-full tw-rounded-2xl tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4 tw-items-center tw-text-center tw-overflow-hidden tw-p-5 lg:tw-px-6 lg:tw-py-8",style:('color: ' +
                    item.color_content +
                    '; background-color: ' +
                    item.color_background +
                    '; border: 2px solid ' +
                    item.color_content)},[(item.number_item_active)?_c('p',{staticClass:"tw-font-tolyerBold tw-text-4xl lg:tw-text-8xl tw-m-0"},[_vm._v(" "+_vm._s(item.number_item)+" ")]):_vm._e(),(item.icon_active)?_c('div',{staticClass:"tw-text-center tw-w-2/3 tw-mx-auto"},[_c('img',{staticClass:"tw-w-full",attrs:{"src":_vm.uriResources + '/' + item.icon,"alt":""}})]):_vm._e(),(item.title_active)?_c('p',{staticClass:"tw-font-tolyerBold tw-text-2xl lg:tw-text-3xl tw-m-0"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.sub_title_active)?_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-sm tw-font-medium tw-m-0"},[_vm._v(" "+_vm._s(item.sub_title)+" ")]):_vm._e(),(item.description_active)?_c('p',{staticClass:"tw-font-roboto tw-text-xxs lg:tw-text-xs tw-m-0"},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"d-flex flex-row justify-content-end mt-3 mb-5 my-md-3 mb-2"},[_c('div',{staticClass:"col-md-2 col px-0"},[_c('div',{staticClass:"tw-text-sm fs-md-14 line-height-16 tw-font-roboto hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-3 w-100 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center tw-cursor-pointer",on:{"click":function($event){return _vm.pushLink(_vm.peopleCycleItem.steps[0].url)}}},[_vm._v(" "+_vm._s(_vm.peopleCycleItem.steps[0].url_name)+" ")])])])])])])]):_vm._e()],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-3"},[_c('img',{attrs:{"alt":"loading","src":require("../../../assets/img/icons/loading.gif")}})])
}]

export { render, staticRenderFns }