<script setup>
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useContestsStore } from "@/stores/contestsStore";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import { storeToRefs } from "pinia";
import CompContestTriviaType from "@/components/contests/CompContestTriviaType.vue";
import CompContestImageVideoType from "@/components/contests/CompContestImageVideoType.vue";

const isLoading = ref(false);
const router = useRouter();
const route = useRoute();
const contestsStore = useContestsStore();

const { competition, currentQuestion, currentQuestionIndex } =
  storeToRefs(contestsStore);
const { actGetCompetition } = contestsStore;

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetCompetition(route.params.id);

  if (
    competition.value.comp_competition_type_id === 1 &&
    competition.value.unanswered_questions.length === 0
  ) {
    await router.push(`/contests/${route.params.id}/finish`);
  } else if (competition.value.comp_competition_type_id === 1) {
    currentQuestion.value =
      competition.value.unanswered_questions[currentQuestionIndex.value];
  }

  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Concursos" />
    <title-bar title="Concursos" />
    <main class="tw-container-general">
      <template v-if="isLoading">
        <div class="tw-animate-pulse">
          <div
            class="tw-bg-gray-300 tw-h-6 tw-w-1/2 tw-mb-4 tw-rounded-2xl"
          ></div>
          <section
            class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-8 lg:tw-grid-rows-rows_2_fit_last tw-gap-3 lg:tw-gap-x-6 lg:tw-gap-y-3"
          >
            <div class="lg:tw-col-span-2 tw-flex tw-justify-center">
              <div
                class="tw-rounded-2.5xl tw-aspect-1 tw-w-60 lg:tw-w-full lg:tw-h-full tw-bg-gray-300"
              ></div>
            </div>
            <div class="lg:tw-col-span-6 tw-row-span-1 tw-flex tw-flex-col">
              <div
                class="tw-bg-gray-300 tw-h-5 tw-w-2/3 tw-mb-4 tw-rounded-2xl"
              ></div>
              <div
                class="tw-h-44 lg:tw-flex-1 lg:tw-h-full tw-bg-gray-300 tw-rounded-2.5xl tw-mt-2"
              ></div>
            </div>
            <div
              class="lg:tw-col-span-8 tw-w-full tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-end tw:tw-items-center tw-gap-2 tw-mt-2"
            >
              <div
                class="tw-bg-gray-300 tw-w-full lg:tw-w-1/3 tw-h-full tw-py-3 tw-rounded-2xl"
              ></div>
            </div>
          </section>
        </div>
      </template>
      <template v-else>
        <comp-contest-trivia-type
          v-if="competition.comp_competition_type_id === 1"
        />
        <comp-contest-image-video-type
          v-else-if="
            competition.comp_competition_type_id === 2 ||
            competition.comp_competition_type_id === 3
          "
        />
      </template>
    </main>
  </div>
</template>

<style scoped>
/* Estilo para ocultar el botón de radio, manteniéndolo accesible */
.custom-radio-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Estilo para el contenedor del botón de radio personalizado */
.custom-radio-label {
  position: relative; /* Necesario para posicionar el pseudo-elemento correctamente */
  padding-left: 25px; /* Espacio para el botón de radio personalizado */
  cursor: pointer;
}

/* Estilo para el indicador visual del botón de radio personalizado */
.custom-radio-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
