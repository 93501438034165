export function toPercent(value) {
  return value / 100;
}

export const error = {
  sizeOfPie: {
    value: false,
  },
  entityPerformance: {
    value: false,
    message: "",
  },
  individualPerformance: {
    value: false,
    message: "",
  },
  discretionaryIncentive: {
    value: false,
    message: "",
  },
  achievementScore: {
    value: false,
    message: "",
  },
};

export function changeAchievementScoreValue(bonusPropsRange0to6) {
  if (
    bonusPropsRange0to6.sizeOfPie === 0 &&
    bonusPropsRange0to6.entityPerformance === 0
  ) {
    bonusPropsRange0to6.achievementScore = 0;
    bonusPropsRange0to6.sizeOfPie = 0;
    bonusPropsRange0to6.entityPerformance = 0;
  } else if (bonusPropsRange0to6.individualPerformance === 0) {
    bonusPropsRange0to6.achievementScore = 0;
  } else {
    bonusPropsRange0to6.achievementScore =
      toPercent(
        bonusPropsRange0to6.sizeOfPie * 0.4 +
          bonusPropsRange0to6.entityPerformance * 0.3 +
          bonusPropsRange0to6.individualPerformance * 0.3
      ) * toPercent(bonusPropsRange0to6.discretionaryIncentive);
  }
}

export function checkSizeOfPie(event, bonusPropsRange0to6) {
  let _tmpValue = event.target.value;
  if (parseFloat(_tmpValue) >= 0 && parseFloat(_tmpValue) <= 140) {
    error.sizeOfPie.value = false;
    bonusPropsRange0to6.sizeOfPie = _tmpValue;
  } else {
    error.sizeOfPie.value = true;
    error.sizeOfPie.message = "Ingresa un valor entre 0 y 140";
    bonusPropsRange0to6.sizeOfPie = 0;
  }
}

export function checkEntityPerformance(event, bonusPropsRange0to6) {
  let _tmpValue = event.target.value;
  if (parseFloat(_tmpValue) <= 140) {
    error.entityPerformance.value = false;
    bonusPropsRange0to6.entityPerformance = _tmpValue;
  } else {
    error.entityPerformance.value = true;
    error.entityPerformance.message = "Ingresa un valor entre 0 y 140";
    bonusPropsRange0to6.entityPerformance = 0;
  }
}

export function checkIndividualPerformance(
  event,
  bonusPropsRange0to6,
  employeeCorePosition
) {
  let _tmpValue = event.target.value;
  if (employeeCorePosition === "V-VI" || employeeCorePosition === "0-IV") {
    if (parseFloat(_tmpValue) >= 25 && parseFloat(_tmpValue) <= 100) {
      error.individualPerformance.value = false;
      bonusPropsRange0to6.individualPerformance = _tmpValue;
    } else {
      error.individualPerformance.value = true;
      error.individualPerformance.message =
        "Ingresa un valor con valores entre 25 y 100";
      bonusPropsRange0to6.individualPerformance = 0;
    }
  } else {
    if (parseFloat(_tmpValue) >= 35 && parseFloat(_tmpValue) <= 100) {
      error.individualPerformance.value = false;
      bonusPropsRange0to6.individualPerformance = _tmpValue;
    } else {
      error.individualPerformance.value = true;
      error.individualPerformance.message = "Ingresa un valor entre 35 y 100";
      bonusPropsRange0to6.individualPerformance = 0;
    }
  }
}

export function checkDiscretionaryPerformance(
  event,
  bonusPropsRange0to6,
  employeeCorePosition
) {
  let _tmpValue = event.target.value;
  if (employeeCorePosition === "V-VI") {
    if (parseFloat(_tmpValue) >= 70 && parseFloat(_tmpValue) <= 130) {
      error.discretionaryIncentive.value = false;
      bonusPropsRange0to6.discretionaryIncentive = _tmpValue;
    } else {
      error.discretionaryIncentive.value = true;
      error.discretionaryIncentive.message =
        "Ingresa un valor con valores entre 70 y 130";
      bonusPropsRange0to6.discretionaryIncentive = 0;
    }
  } else {
    if (parseFloat(_tmpValue) >= 80 && parseFloat(_tmpValue) <= 120) {
      error.discretionaryIncentive.value = false;
      bonusPropsRange0to6.discretionaryIncentive = _tmpValue;
    } else {
      error.discretionaryIncentive.value = true;
      error.discretionaryIncentive.message =
        "Ingresa un valor con valores entre 80 y 120";
      bonusPropsRange0to6.discretionaryIncentive = 0;
    }
  }
}

/*
export function setHelpText(textValue) {
  switch (textValue) {
    case "sizeOfPie":
      return "Determina el valor disponible para el pago de bonos, está determinado por el crecimiento orgánico del EBITDA a nivel global.";
    case "entityPerformance":
      return "Refleja la contribución de la BU Colombia a los resultados de ABI (EBITDA-Cash flow and Net revenue).";
    case "individualPerformance":
      return "Cumplimiento de tus metas individuales";
    case "discretionaryIncentive":
      return "Valor discrecional que puede oscilar entre un 70 y 130 %";
    case "achievementScore":
      return "Es el porcentaje que tiene en cuenta los resultados globales, de la BU Colombia y las metas indivuales.";
    case "bonusTarget":
      return "Porcentaje de bono acorde a tu banda ";
    case "mrs":
      return "Es el valor de referencia de mercado acorde a tu banda";
    case "tdrField":
      return "Tiempo laborado en la posición durante el año a evaluar (Para ingresos anteriores al 1 de Octubre).";
    case "stiTargetNA":
      return "Es el valor porcentual que corresponde a los días de bono meritocratico de acuerdo al ranking de planta que elijas. Ejemplo Ranking 1: 20/360=5.6%";
    case "anualSalaryNA":
      return "Salario Básico multiplicado por 13. ";
    case "tdrNA":
      return "Tiempo laborado en el año (Para ingresos anteriores al 1 de Julio)";
    case "achievementScoreAd":
      return "Es el porcentaje que tiene en cuenta los resultados globales, de la BU Colombia y las metas individuales del director de tu área.";
    case "stiAd":
      return "Es el valor porcentual que corresponde a 7,5 días de salario básico (7.5/360= 2,08%)";
    case "salaryAd":
      return "Salario Básico multiplicado por 13";
    case "tdrAd":
      return "Tiempo laborado en el año (Para ingresos anteriores al 1 de Julio)";
    default:
      return "Selecciona un objeto de la fórmula para ver su detalle...";
  }
}

export function formatPrice(value) {
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  }).format(value);
}*/
