import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    actualStep: 0,
    is_complete: false,
    terms: [],
    activeCvComponent: "CvProfile",
    itemsMenu: [
      {
        id: 0,
        name: "Perfil",
        component: "CvProfile",
        active: false,
      },
      {
        id: 1,
        name: "Educación",
        component: "CvEducation",
        active: false,
      },
      {
        id: 2,
        name: "Experiencia",
        component: "CvExperience",
        active: false,
      },
      {
        id: 3,
        name: "Cursos",
        component: "CvCourse",
        active: false,
      },
      {
        id: 4,
        name: "Idiomas",
        component: "CvLanguage",
        active: false,
      },
    ],
    formData: {
      profile: {},
      education: {
        items: [
          {
            institution: "",
            career: "",
            start_date: "",
            end_date: "",
            at_present: 0,
            place: "",
          },
        ],
      },
      work_experience: {
        items: [
          {
            company: "",
            position: "",
            start_date: "",
            end_date: "",
            at_present: 0,
            place: "",
            achievements: "",
          },
        ],
      },
      courses: {
        items: [
          {
            institution: "",
            course: "",
            start_date: "",
            end_date: "",
            at_present: 0,
            place: "",
          },
        ],
      },
      languages: {
        items: [
          {
            language: "",
            level: "",
            start_date: "",
            end_date: "",
            at_present: 0,
            institution: "",
          },
        ],
      },
    },
  },
  mutations: {
    setActualStep: (state, actualStep) => {
      state.actualStep = actualStep;
    },
    setItemMenu: (state, item) => {
      state.itemsMenu[item.id] = item;
    },
    setActiveCvComponent: (state, activeCvComponent) => {
      state.activeCvComponent = activeCvComponent;
    },
    setIsComplete: (state, is_complete) => {
      state.is_complete = is_complete;
    },
    setTerms: (state, terms) => {
      state.terms = terms;
    },
    setProfile: (state, data) => {
      state.formData.profile = data;
    },
    setEducation: (state, data) => {
      if (data.length > 0) {
        state.formData.education.items = data;
      }
    },
    setExperience: (state, data) => {
      if (data.length > 0) {
        state.formData.work_experience.items = data;
      }
    },
    setCourse: (state, data) => {
      if (data.length > 0) {
        state.formData.courses.items = data;
      }
    },
    setLanguages: (state, data) => {
      if (data.length > 0) {
        state.formData.languages.items = data;
      }
    },
    setAddUnivercity: (state, newItem) => {
      state.formData.education.items.push(newItem);
    },
    setRemoveUnivercity: (state, id) => {
      state.formData.education.items.splice(id, 1);
    },
    setRemoveEducationEndDate: (state, id) => {
      state.formData.education.items[id].end_date = "";
    },
    setAddExperience: (state, newItem) => {
      state.formData.work_experience.items.push(newItem);
    },
    setRemoveExperience: (state, id) => {
      state.formData.work_experience.items.splice(id, 1);
    },
    setRemoveExperienceEndDate: (state, id) => {
      state.formData.work_experience.items[id].end_date = "";
    },
    setAddCourse: (state, newItem) => {
      state.formData.courses.items.push(newItem);
    },
    setRemoveCourse: (state, id) => {
      state.formData.courses.items.splice(id, 1);
    },
    setRemoveCourseEndDate: (state, id) => {
      state.formData.courses.items[id].end_date = "";
    },
    setAddLanguage: (state, newItem) => {
      state.formData.languages.items.push(newItem);
    },
    setRemoveLanguage: (state, id) => {
      state.formData.languages.items.splice(id, 1);
    },
    setRemoveLanguageEndDate: (state, id) => {
      state.formData.languages.items[id].end_date = "";
    },
  },
  actions: {
    changeStep({ state, commit }, id) {
      //All items to false
      state.itemsMenu.forEach((item) => {
        item.active = false;
      });
      //Select item selected
      let item = state.itemsMenu[id];
      //Change active state
      item.active = true;

      commit("setActualStep", id);
      commit("setItemMenu", item);
      commit("setActiveCvComponent", item.component);
    },
    addUnivercity({ commit }) {
      let newItem = {
        institution: "",
        career: "",
        start_date: "",
        end_date: "",
        at_present: 0,
        place: "",
      };
      commit("setAddUnivercity", newItem);
    },
    removeUnivercity({ commit }, id) {
      commit("setRemoveUnivercity", id);
    },
    removeEducationEndDate({ commit }, id) {
      commit("setRemoveEducationEndDate", id);
    },
    addExperience({ commit }) {
      let newItem = {
        company: "",
        position: "",
        start_date: "",
        end_date: "",
        at_present: 0,
        place: "",
        achievements: "",
      };
      commit("setAddExperience", newItem);
    },
    removeExperience({ commit }, id) {
      commit("setRemoveExperience", id);
    },
    removeExperienceEndDate({ commit }, id) {
      commit("setRemoveExperienceEndDate", id);
    },
    addCourse({ commit }) {
      let newItem = {
        institution: "",
        course: "",
        start_date: "",
        end_date: "",
        at_present: 0,
        place: "",
      };
      commit("setAddCourse", newItem);
    },
    removeCourse({ commit }, id) {
      commit("setRemoveCourse", id);
    },
    removeCourseEndDate({ commit }, id) {
      commit("setRemoveCourseEndDate", id);
    },
    addLanguage({ commit }) {
      let newItem = {
        language: "",
        level: "",
        start_date: "",
        end_date: "",
        at_present: 0,
        institution: "",
      };
      commit("setAddLanguage", newItem);
    },
    removeLanguage({ commit }, id) {
      commit("setRemoveLanguage", id);
    },
    removeLanguageEndDate({ commit }, id) {
      commit("setRemoveLanguageEndDate", id);
    },
    getTerms: async ({ commit }) => {
      let response = await ApiBeerOffice.getTerms();
      if (response.error) {
        return response.error;
      } else {
        commit("setTerms", response.data);
      }
    },
    getPeopleResume: async ({ commit }) => {
      let response = await ApiBeerOffice.getPeopleResume();
      if (response.error) {
        return response.error;
      } else {
        commit("setIsComplete", response.data.resume.is_complete);
        commit("setProfile", response.data.resume.profile);
        commit("setEducation", response.data.resume.education);
        commit("setExperience", response.data.resume.work_experience);
        commit("setCourse", response.data.resume.courses);
        commit("setLanguages", response.data.resume.languages);
        return true;
      }
    },
    saveProfile: async (_, data) => {
      let response = await ApiBeerOffice.saveProfile(data);
      if (response.error) {
        return { data: response.error, error: true };
      } else {
        return { data: response.data, error: false };
      }
    },
    saveEducation: async (_, data) => {
      let response = await ApiBeerOffice.saveEducation(data);
      if (response.error) {
        return { data: response.error, error: true };
      } else {
        return { data: response.data, error: false };
      }
    },
    saveExperience: async (_, data) => {
      let response = await ApiBeerOffice.saveExperience(data);
      if (response.error) {
        return { data: response.error, error: true };
      } else {
        return { data: response.data, error: false };
      }
    },
    saveCourse: async (_, data) => {
      let response = await ApiBeerOffice.saveCourse(data);
      if (response.error) {
        return { data: response.error, error: true };
      } else {
        return { data: response.data, error: false };
      }
    },
    saveLanguages: async (_, data) => {
      let response = await ApiBeerOffice.saveLanguages(data);
      if (response.error) {
        return { data: response.error, error: true };
      } else {
        return { data: response.data, error: false };
      }
    },
  },
};
