import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  reactionsUri: "/reactions",
  commentsUri: "/comments",
  shareUri: "/shares",
  ratingsUri: "/coreReviews?category",
};

export const useSocialNetworkStore = defineStore("socialNetwork", () => {
  const reactions = ref([]);
  const showReactions = ref(false);
  const coordinates = ref({});
  const comments = ref([]);
  const employeesForShare = ref([]);

  async function actGetReactions() {
    const response = await AxiosBeerOffice.get(uri.reactionsUri, true, {});
    if (response.error) return response.error;
    const { data: reactionsData } = response;
    reactions.value = reactionsData;
    return true;
  }

  async function actPostReactions(data) {
    const response = await AxiosBeerOffice.post(uri.reactionsUri, true, data);
    if (response.error) return response.error;
    return response.data;
  }

  async function actDeleteReactions(id) {
    const response = await AxiosBeerOffice.delete(
      uri.reactionsUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    return response.data;
  }

  function setCoordinates(coord) {
    coordinates.value = coord;
  }

  async function actGetComments(data) {
    const response = await AxiosBeerOffice.get(
      uri.commentsUri +
        "?post_category=" +
        data.post_category +
        "&post_id=" +
        data.post_id,
      true,
      {}
    );
    if (response.error) return response.error;
    const {
      data: { data: commentsData },
    } = response;
    comments.value = commentsData;
    return true;
  }

  async function actPostComments(data) {
    const response = await AxiosBeerOffice.post(uri.commentsUri, true, data);
    if (response.error) return response.error;
    return true;
  }

  async function actDeleteComments(id) {
    const response = await AxiosBeerOffice.delete(
      uri.commentsUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    return true;
  }

  async function actGetEmployeesForShare(data) {
    const response = await AxiosBeerOffice.get(
      uri.shareUri +
        "/getEmployeesForShare" +
        "?post_category=" +
        data.post_category +
        "&post_id=" +
        data.post_id +
        "&name=" +
        data.name,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data: employeesForShareData } = response;
    employeesForShare.value = employeesForShareData;
    return true;
  }

  async function actPostShare(data) {
    const response = await AxiosBeerOffice.post(uri.shareUri, true, data);
    if (response.error) return response.error;
    return true;
  }

  async function actGetRating(data) {
    let response = await AxiosBeerOffice.get(
      uri.ratingsUri + "=" + data,
      true,
      data,
      true
    );
    if (response.error) {
      return response.error;
    } else {
      return response;
    }
  }

  // async function actPostRating(data) {
  //   let response = await AxiosBeerOffice.post(uri.ratingsUri, true, data, true);
  //   if (response.error) {
  //     return response.error;
  //   } else {
  //     return response.score;
  //   }
  // }

  return {
    reactions,
    comments,
    employeesForShare,
    showReactions,
    coordinates,
    actGetReactions,
    actPostReactions,
    actDeleteReactions,
    setCoordinates,
    actGetComments,
    actPostComments,
    actDeleteComments,
    actGetEmployeesForShare,
    actPostShare,
    actGetRating,
  };
});
