<template>
  <div class="tw-container-general">
    <ButtonsPeopleCycle
      class="py-3 mb-2 mb-md-4"
      :peopleCycleButtonsActive="5"
    />
    <PCNavigate :pc_item="pc_item" />
    <!-- STEPS -->
    <div class="mb-5" v-if="isLoading">
      <div class="text-center mt-3">
        <img src="../../../assets/img/icons/loading.gif" alt="loading" />
      </div>
    </div>
    <div v-else>
      <div
        class="container mt-3 mt-md-5"
        v-if="peopleCycleItem.steps.length > 0"
      >
        <div class="row">
          <div class="tw-rounded-lg col-12 col-md-12 py-md-4">
            <div class="row justify-content-center">
              <div class="col text-center px-3 pt-md-3">
                <p class="tw-font-tolyerBold fs-42 fs-md-50">
                  {{ peopleCycleItem.steps[0].title }}
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col col-md-8 text-center px-3 pt-3 pb-3">
                <p class="tw-font-roboto tw-text-base fs-md-16 line-height-24">
                  {{ peopleCycleItem.steps[0].description }}
                </p>
              </div>
            </div>
            <div class="container px-md-5">
              <div class="row mb-3 mx-0">
                <div
                  class="col-6 col-sm-4 px-md-2 px-1 my-md-3 py-1"
                  v-for="item in peopleCycleItem.steps[0]
                    .people_cycle_step_card"
                  :key="item.id"
                >
                  <div
                    class="he-200 he-md-440 tw-rounded-lg d-flex flex-column text-center overflow-hidden pb-md-4"
                    :style="
                      'background-color: ' +
                      item.color_background +
                      '; border: 2px solid ' +
                      item.color_content
                    "
                  >
                    <div
                      class="overflow-hidden text-center px-2 pt-1 pb-3 p-md-4"
                    >
                      <p
                        v-if="item.number_item_active"
                        class="tw-font-tolyerBold fs-44 fs-md-90 tw-font-bold m-0 pt-2 pt-md-4"
                        :style="'color: ' + item.color_content"
                      >
                        {{ item.number_item }}
                      </p>
                      <div
                        v-if="item.icon_active"
                        class="text-center w-40 mx-auto pt-3"
                      >
                        <img
                          :src="uriResources + '/' + item.icon"
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                      <p
                        class="tw-font-tolyerBold fs-28 fs-md-28 tw-font-bold m-0"
                        :style="'color: ' + item.color_content"
                        v-if="item.title !== null"
                      >
                        {{ item.title }}
                      </p>
                      <p
                        class="tw-font-roboto tw-text-sm fs-md-14 tw-font-medium m-0"
                        v-if="item.sub_title !== null"
                        :style="'color: ' + item.color_content"
                      >
                        {{ item.sub_title }}
                      </p>
                      <p
                        v-if="item.description_active"
                        class="tw-font-roboto tw-text-xxs fs-md-20 tw-font-normal pt-3 pt-md-5 m-0 px-3"
                        :style="'color: ' + item.color_content"
                      >
                        {{ item.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-row justify-content-end mt-3 mb-5 my-md-3 mb-2"
              >
                <div class="col-md-3 col px-0">
                  <a
                    :href="peopleCycleItem.steps[0].url"
                    class="tw-text-sm fs-md-14 line-height-16 tw-font-roboto hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-3 w-100 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                  >
                    {{ peopleCycleItem.steps[0].url_name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsPeopleCycle from "@/components/general/ButtonsPeopleCycle";
import PCNavigate from "@/components/general/PCNavigate";

import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import cvMixin from "@/mixins/user/cv";
import people from "@/mixins/user/people";

export default {
  name: "UpdateProfile",
  mixins: [cvMixin, people],
  components: {
    ButtonsPeopleCycle,
    PCNavigate,
  },
  data() {
    return {
      isLoading: true,
      uriResources: config.beerOfficeURL,
      pc_item: 7,
    };
  },
  async created() {
    this.isLoading = true;

    this.actGetPeopleCycleItem({
      id: 7,
    }).finally(() => {
      this.isLoading = false;
      this.detectLink(this.peopleCycleItem.steps);
    });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modPeopleCycle", ["peopleCycleItem"]),
  },
  methods: {
    ...mapActions("modPeopleCycle", ["actGetPeopleCycleItem"]),
    detectLink(steps) {
      for (let i = 0; i < steps.length; i++) {
        if (
          steps[i].url.indexOf("http://") === 0 ||
          steps[i].url.indexOf("https://") === 0
        ) {
          steps[i].url;
        } else {
          steps[i].url = this.uriResources + "/" + steps[i].url;
        }
      }
    },
  },
};
</script>

<style scoped>
.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>
