<template>
  <div>
    <general-modal
      :hide-button-support="true"
      v-if="showModalSucceed"
      modalName="request-success"
      @close="closeModal"
      :isShowModal="showModalSucceed"
      :modalWidth="{ md: 500, lg: 600 }"
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
    >
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
      >
        <img src="@/assets/img/modal/modal-success.svg" alt="" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-green-400"
        >
          GENIAL
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-w-8/12 tw-text-center"
        >
          {{
            user.name.split(" ")[0].charAt(0).toUpperCase() +
            user.name.split(" ")[0].slice(1).toLowerCase() +
            " tu solicitud de certificado está en proceso, revisa tu correo dentro de las próximas 24 horas."
          }}
        </p>
      </div>
    </general-modal>
    <MobileBar title="Solicitud de Certificados" />
    <div class="tw-container-general">
      <TitleBar :breadcrumb="breadcrumb" title="Solicitud de Certificados" />
      <div class="tw-grid tw-grid-cols-1 tw-gap-6 lg:tw-gap-10">
        <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-6">
          <p class="tw-m-0 tw-font-robotoBold tw-text-2xl lg:tw-text-3xl">
            Solicitar un documento
          </p>
          <div
            class="tw-grid tw-grid-cols-1 tw-gap-10 lg:tw-grid-cols-col2_fit_last lg:tw-gap-20"
          >
            <div class="tw-flex tw-items-center">
              <p class="tw-font-roboto tw-text-xs lg:tw-text-lg tw-m-0">
                En esta sección puedes solicitar tu certificado laboral,
                selecciona qué tipo de documento deseas, luego diligencia los
                datos
              </p>
            </div>
            <div>
              <CompGeneralDropdown
                :isLabel="false"
                :options="[
                  {
                    id: 0,
                    name: 'Certificado de Nómina',
                  },
                ]"
                :fitted="false"
                @changeSelectedOption="(opt) => selectCertificate(opt.id)"
                @chargeOption="(opt) => selectCertificate(opt.id)"
              />
            </div>
          </div>
        </div>
        <div class="tw-w-full tw-shadow-lg tw-rounded-xl tw-p-6 lg:tw-p-10">
          <div v-if="option === 0" class="tw-flex tw-flex-col tw-gap-10">
            <div
              class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-10 lg:tw-grid-cols-10 lg:tw-gap-24"
            >
              <div class="tw-col-span-6 tw-flex tw-flex-col tw-gap-6">
                <div>
                  <p class="tw-font-robotoBold tw-text-xl">
                    Información personal
                  </p>
                  <p class="tw-font-roboto tw-text-sm tw-text-gray-400">
                    Correo electrónico
                  </p>
                  <input
                    type="text"
                    class="tw-input-text-general tw-w-full tw-input-focus-no"
                    id="email"
                    name="email"
                    placeholder="micorreo@ab-inbev.com"
                    v-model="email"
                  />
                </div>
                <div>
                  <p class="tw-font-robotoBold tw-text-xl">Documentación</p>
                  <p class="tw-font-roboto tw-text-sm tw-text-gray-400">
                    Fecha de corte del certificado
                  </p>
                  <input
                    type="text"
                    class="tw-input-text-general tw-w-full tw-input-focus-no"
                    id="cutoff_date"
                    name="cutoff_date"
                    placeholder="DD/MM/AAAA"
                    maxlength="10"
                    v-model="cutoff_date"
                    @input="handleInput"
                    @keydown="getCode"
                  />
                </div>
              </div>
              <div class="tw-col-span-4">
                <div>
                  <p class="tw-font-robotoBold tw-text-xl">
                    Información adicional
                  </p>
                  <p class="tw-font-roboto tw-text-sm tw-text-gray-400">
                    Solicitar el último año fiscal
                  </p>
                  <div class="tw-flex tw-gap-10 md:tw-gap-14 lg:tw-gap-20">
                    <label class="tw-flex tw-items-center tw-gap-2">
                      <input
                        type="radio"
                        name="fiscal_year"
                        value="1"
                        v-model="fiscal_year"
                        class="tw-text-green-400 focus:tw-ring-transparent"
                      />Si
                    </label>
                    <label class="tw-flex tw-items-center tw-gap-2">
                      <input
                        type="radio"
                        name="fiscal_year"
                        value="0"
                        v-model="fiscal_year"
                        class="tw-text-green-400 focus:tw-ring-transparent"
                      />No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-justify-center tw-hidden lg:tw-flex">
              <div
                class="tw-text-center tw-w-6/12 lg:tw-w-3/12"
                :class="
                  dateIsValid && emailIsValid && fiscal_year !== null
                    ? 'tw-btn-primary-red'
                    : 'tw-btn-primary-disabled'
                "
                @click="requestWorkCertificate()"
              >
                <p class="tw-m-0 tw-font-roboto tw-text-lg">Solicitar</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-text-center tw-w-full tw-block lg:tw-hidden"
          :class="
            dateIsValid && emailIsValid && fiscal_year !== null
              ? 'tw-btn-primary-red'
              : 'tw-btn-primary-disabled'
          "
          @click="requestWorkCertificate()"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-lg">Solicitar</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import GeneralModal from "@/components/modals/GeneralModal";

export default {
  name: "CertificatesView",
  components: {
    MobileBar,
    TitleBar,
    CompGeneralDropdown,
    GeneralModal,
  },
  data() {
    return {
      option: 0,
      email: null,
      cutoff_date: null,
      fiscal_year: null,
      showModalSucceed: false,
      breadcrumb: [
        {
          id: 0,
          name: "Inicio",
          link: "/home",
          active: false,
        },
        {
          id: 1,
          name: "Bienestar",
          link: "#",
          active: false,
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
          active: false,
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
          active: false,
        },
        {
          id: 4,
          name: "Certificado de Nómina",
          link: "#",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
    dateIsValid() {
      // Regex for test if dd/mm/yyyy format is valid
      // ([0-2][0-9]|(3)[0-1]) is for first group, validate numbers from 01 to 31 (days)
      // (((0)[1-9])|((1)[0-2])) is for second group, validate numbers from 01 to 09 and 10 to 12 (months)
      // \d{4} is for third group, validate 4 numbers in the last group.

      let validDateFormat =
        /^([0-2][0-9]|(3)[0-1])(\/)(((0)[1-9])|((1)[0-2]))(\/)\d{4}$/.test(
          this.cutoff_date
        );

      // Check if date is null
      if (this.cutoff_date === null) return false;
      // Check if date length is not 10
      if (this.cutoff_date.length !== 10) return false;

      // Convert input date to Date format
      let convertedDate = this.cutoff_date.split("/");
      let year = parseInt(convertedDate[2]);
      let month = parseInt(convertedDate[1]) - 1;
      let day = parseInt(convertedDate[0]);
      convertedDate = new Date(year, month, day);

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      // Check if the date is valid in terms of days valid in the month
      let validDate =
        convertedDate.getFullYear() === year &&
        convertedDate.getMonth() === month &&
        convertedDate.getDate() === day;

      return (
        convertedDate instanceof
          Date /* validates convertedDate is a valid Date type */ &&
        !isNaN(convertedDate) /* validates convertedDate is a valid number */ &&
        validDate &&
        validDateFormat &&
        convertedDate.getTime() <= today.getTime()
      );
    },
    emailIsValid() {
      // Regex made to check if email format is the right one
      if (this.email === null) return false;
      return /^[\w.!#$%&'*+/=?^_`{|}~-]+@\w+([.-]?\w+)*(\.\w+)+$/.test(
        this.email
      );
    },
  },
  methods: {
    ...mapActions("modDocuments", ["actPostRequestWorkCertificate"]),
    selectCertificate(option) {
      this.option = option;
    },
    getCode(e) {
      // prevents Input Field from accepting characters different from numbers
      // triggered by keydown event
      if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      ) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    handleInput(e) {
      if (e.inputType === "deleteContentBackward") return; // if backspaced, break.

      if (this.cutoff_date.length === 2 && parseInt(this.cutoff_date) <= 31) {
        // add slashes after two digits
        this.cutoff_date = this.cutoff_date + "/";
      }

      if (
        this.cutoff_date.length === 5 &&
        parseInt(this.cutoff_date.slice(3)) <= 12
      ) {
        // add slashes after second two digits only if his lower than
        this.cutoff_date = this.cutoff_date + "/";
      }
    },
    requestWorkCertificate() {
      this.actPostRequestWorkCertificate({
        email: this.email,
        last: this.fiscal_year,
        cutoff_date: this.cutoff_date.split("/").reverse().join("-"),
      }).then((response) => {
        response.status === "Success" ? (this.showModalSucceed = true) : "";
      });
    },
    closeModal() {
      this.showModalSucceed = false;
    },
  },
};
</script>

<style scoped>
/*::placeholder {
  color: red;
  opacity: 1; !* Firefox *!
}

:-ms-input-placeholder { !* Internet Explorer 10-11 *!
  color: red;
}

::-ms-input-placeholder { !* Microsoft Edge *!
  color: red;
}*/
</style>
