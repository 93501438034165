var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row questions-trivia-2"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('img',{staticClass:"d-none d-lg-block bor-tr-rounded-100 tw-rounded-tl-lg tw-rounded-bl-lg tw-rounded-br-lg tw-object-cover he-600",attrs:{"src":_vm.uriResources + '/' + _vm.imageQuestion,"width":"100%","height":"auto","alt":"image trivia"}})]),_c('div',{staticClass:"col-12 col-lg-8"},[_c('form',{attrs:{"id":'triviaForm-' + this.trivia.id},on:{"submit":function($event){$event.preventDefault();return _vm.nextQuestion()}}},[_c('div',{staticClass:"d-flex mb-5 mt-4"},_vm._l((_vm.trivia.comp_question_by_category),function(category,index){return _c('div',{key:'trivia-category-' + index,staticClass:"w-100 mr-1"},[_c('p',{staticClass:"tw-text-xxs fs-md-12 mb-1 text-center",class:category.completed === 'to_complete'
                ? 'tw-text-gray-300'
                : category.completed === 'in_progress'
                ? 'tw-text-red-400'
                : 'tw-text-green-400'},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('div',{staticClass:"w-100 he-6 he-md-8",class:category.completed === 'to_complete'
                ? 'tw-bg-gray-300'
                : category.completed === 'in_progress'
                ? 'tw-bg-red-400'
                : 'tw-bg-green-400'})])}),0),_c('div',[_c('p',{staticClass:"tw-font-tolyerBold tw-text-gray-600 fs-40 mb-5"},[_vm._v(" CATEGORÍA: "+_vm._s(_vm.categoryName)+" ")]),_c('p',{staticClass:"tw-font-tolyerBold tw-text-gray-600 tw-text-xl mb-4"},[_vm._v(" Pregunta "+_vm._s(_vm.questionNumber)+"/"+_vm._s(_vm.questionNumberByCategory)+" ")]),_c('div',[_c('p',{staticClass:"tw-text-sm tw-text-gray-600 mb-5"},[_vm._v(" "+_vm._s(_vm.question.question)+" ")]),_vm._l((_vm.answers),function(answer){return _c('div',{key:'answer-' + answer.id,staticClass:"custom-control custom-radio mb-3"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":'radio-' + answer.id,"name":"radio-stacked","required":""},domProps:{"value":answer.id},on:{"change":function($event){return _vm.radioChange($event)}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'radio-' + answer.id}},[_vm._v(" "+_vm._s(answer.answer)+" ")])])})],2)]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-6 col-lg-3 ml-auto"},[_c('button',{staticClass:"w-100 tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 tw-rounded-lg py-2 px-3 tw-bg-white hover:tw-bg-gray-200",on:{"click":function($event){return _vm.previousQuestion()}}},[_vm._v(" Anterior ")])]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 col-lg-3"},[_c('button',{staticClass:"w-100 tw-text-white tw-border tw-border-solid tw-border-red-400 tw-rounded-lg py-2 px-3 tw-bg-red-400 hover:tw-bg-red-600",attrs:{"type":"submit"}},[_vm._v(" Siguiente ")])])
}]

export { render, staticRenderFns }