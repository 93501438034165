<script setup>
import { ref } from "vue";

const hover = ref(false);

defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    required: true,
    default: "Opción",
  },
  notificationNumber: {
    type: Number,
    default: 0,
  },
  borderColorProp: {
    type: String,
  },
});
</script>
<template>
  <div
    class="tw-relative tw-cursor-pointer tw-border-b"
    :style="{
      borderColor: hover || active ? 'white' : borderColorProp,
      '--tw-border-opacity': active ? '1' : 'initial',
    }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    id="navbar-link"
  >
    <div
      v-if="notificationNumber > 0"
      class="tw-absolute tw-bg-white tw-w-3 tw-h-3 tw-rounded-full tw-font-robotoBold tw-text-red-400 tw-flex tw-justify-center tw-items-center tw--top-1 tw--left-2"
      style="font-size: 9px"
    >
      {{ notificationNumber }}
    </div>
    <p
      class="tw-m-0 tw-text-white tw-text-lg tw-font-robotoBold tw-whitespace-nowrap"
    >
      {{ text }}
    </p>
  </div>
</template>
