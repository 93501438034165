<script setup>
import config from "@/services/config";
import { pushLink } from "@/helpers/linkHelpers";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  content: {
    type: Array,
    required: true,
  },
});
</script>
<template>
  <div class="tw-mb-4 md:tw-mb-5 tw-container">
    <div class="row">
      <slot>
        <div
          v-for="(item, idx) in props.content"
          :key="idx"
          class="col-12"
          :class="{
            'col-md-4': props.content.length % 2 > 0,
            'col-md-3': props.content.length % 2 === 0,
          }"
        >
          <div class="card border-0">
            <!--Type 1-->
            <div
              v-if="
                item.type.id === 1 || item.type.id === 2 || item.type.id === 3
              "
            >
              <a :href="item.link" target="_blank">
                <img
                  class="he-130 w-100 tw-object-cover"
                  :src="uriResources + '/' + item.image"
                  alt="Card image cap "
                />
              </a>

              <div class="card-body">
                <h5
                  class="tw-text-center tw-mb-3 tw-text-gray-400 tw-min-h-10 tw-font-robotoBold tw-text-sm md:tw-text-lg"
                  v-html="item.title"
                />
                <p
                  class="tw-text-gray-300 tw-text-sm"
                  v-html="item.description"
                />
              </div>
            </div>
          </div>
          <div
            class="tw-rounded-2xl tw-border tw-border-gray-300 tw-p-2 tw-flex tw-flex-col tw-gap-2"
            v-if="item.type.id === 4"
          >
            <img
              class="tw-aspect-1 tw-object-cover tw-rounded-2xl"
              :src="uriResources + '/' + item.image"
              alt=""
            />
            <comp-button-custom
              class="tw-w-full"
              text="¡Saber más!"
              :color="'white'"
              @click.native="pushLink(item.link)"
            />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>
