export function useDummyPeopleTourActivities() {
  const objetos = [
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Sin realizar",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Aprobada",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Rechazada",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Finalizado",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: null,
      field_activity: "1436",
      field_status: null,
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Sin realizar",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Aprobada",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Rechazada",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: "2024-05-17T19:45:56",
      field_activity: "1436",
      field_status: "Finalizado",
    },
    {
      start_date: "2024-05-09T19:45:56",
      end_date: null,
      field_activity: "1436",
      field_status: null,
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Sin realizar",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Aprobada",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Rechazada",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Finalizado",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: null,
      field_activity: "1436",
      field_status: null,
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Sin realizar",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Aprobada",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Rechazada",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: "2023-04-01T19:45:56",
      field_activity: "1436",
      field_status: "Finalizado",
    },
    {
      start_date: "2023-03-24T19:45:56",
      end_date: null,
      field_activity: "1436",
      field_status: null,
    },
  ];

  const additionalObjects = objetos.map((objeto) => ({
    id: "c2f043bb-117d-407e-9905-73f80c0fe2c8",
    title: "Prueba",
    description: {
      value: "Prueba",
      format: "basic_html",
      processed: "Prueba",
    },
    detail: {
      value: "<p>Detalle de la actividad</p>",
      format: "basic_html",
      processed: "<p>Detalle de la actividad</p>",
    },
    node_id: 1391,
    type: "Evidence",
    related_stage: 1390,
    max_points: 100,
    gamification_points: 200,
    start_date: objeto.start_date,
    end_date: objeto.end_date,
    buttons: [],
    status: true,
    field_csat_enlace: null,
    image:
      "https://sds-dev.somosmaz.com/sites/default/files/2023-03/PlantillaTECH_0.png",
    field_csat_imagen: "https://sds-dev.somosmaz.comundefined",
    points: 0,
    field_status: objeto.field_status,
  }));

  return {
    objetos,
    additionalObjects,
  };
}
