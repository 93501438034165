var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('mobile-bar',{attrs:{"title":"Diversidad, Equidad e Inclusión"}}),_c('title-bar',{attrs:{"title":"Diversidad, Equidad e Inclusión"}}),_c('div',{staticClass:"tw-container-general tw-mb-0 tw-pb-0"},[_c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-mb-5 lg:tw-my-5 lg:tw-mt-10 lg:tw-gap-6"},[_vm._m(0),_c('div',{staticClass:"tw-order-1 lg:tw-order-2 lg:tw-col-span-8 hijo"},[_c('div',{staticClass:"tw-rounded-br-3xl tw-rounded-bl-3xl tw-rounded-tr-none tw-rounded-tl-none lg:tw-rounded-3xl lg:tw-aspect-h-6 lg:tw-aspect-w-14 tw-overflow-hidden",class:_vm.isVideo && !_vm.$isMobile() ? '' : 'tw-bg-green-550'},[_c('div',{staticClass:"tw-w-full tw-h-full tw-p-8 tw-flex tw-flex-col tw-gap-2",class:_vm.isVideo ? 'lg:tw-p-0' : 'lg:tw-p-10'},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl tw-text-white lg:tw-hidden tw-text-center"},[_vm._v(" ¿Cómo podemos ayudarnos? ")]),_c('div',{staticClass:"tw-rounded-3xl tw-w-full tw-h-full tw-overflow-hidden",class:_vm.isVideo
                  ? 'lg:tw-rounded-none'
                  : 'lg:tw-rounded-bl-3xl lg:tw-rounded-tl-3xl lg:tw-rounded-br-none lg:tw-rounded-tr-none'},[(_vm.isVideo)?_c('div',{staticClass:"tw-w-auto tw-h-full tw-flex"},[_c('video',{ref:"videoRef",staticClass:"tw-w-full tw-object-cover",attrs:{"controls":"","autoplay":""}},[_c('source',{attrs:{"src":_vm.videoPrueba,"type":"video/mp4"}}),_vm._v(" Su navegador no soporta videos. ")])]):_c('div',{staticClass:"tw-w-full tw-h-full"},[_c('img',{staticClass:"tw-object-contain tw-w-full tw-h-full",attrs:{"src":require("@/assets/img/diversity/helplines.svg"),"alt":""}})])]),_c('p',{staticClass:"tw-m-0 tw-text-sm lg:tw-text-base tw-text-white tw-block lg:tw-hidden"},[_vm._v(" Fomentamos un entorno inclusivo y equitativo para todos. Encuentra recursos, solicita ayuda personalizada y conoce nuestras lineas de atención para la diversidad, equidad e inclusión. Estamos aquí para escucharte y apoyarte. ¡Contáctanos! ")])])])])]),_c('hr',{staticClass:"tw-w-full tw-my-10 tw-hidden lg:tw-block"}),_c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-6 lg:tw-gap-10 tw-my-5"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4"},[_vm._m(1),_c('div',[_c('comp-accordion-parent',_vm._l((_vm.questions),function(item,index){return _c('comp-accordion-item',{key:index,attrs:{"id":'item_' + index,"index-of-item":index,"array-length":_vm.questions.length},scopedSlots:_vm._u([{key:"mainInfo",fn:function(){return [_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg"},[_vm._v(" "+_vm._s(item.question_content.title)+" ")])]},proxy:true},{key:"secondaryInfo",fn:function(){return [_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg"},[_vm._v(" "+_vm._s(item.question_content.title)+" ")]),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v(" "+_vm._s(item.question_content.description)+" ")]),(
                      item.question_content.steps &&
                      item.question_content.steps.length > 0
                    )?_c('div',[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg"},[_vm._v(" Pasos ")]),_c('ol',{staticClass:"tw-m-0"},_vm._l((item.steps),function(step,index){return _c('li',{key:index,staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v(" "+_vm._s(step)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"tw-flex tw-items-center tw-cursor-pointer"},[_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"},[_vm._v(" Saber más ")]),_c('i',{staticClass:"icon-flecha-izq tw-text-red-350 tw-ml-1 tw-text-xxs lg:tw-text-xs"})]),(item.question_content.buttons.length > 0)?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-gap-3"},[_c('div',{staticClass:"tw-btn-secondary-white tw-p-3",on:{"click":function($event){return _vm.pushLink(item.question_content.buttons[0].url)}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v(" "+_vm._s(item.question_content.buttons[0].label)+" ")])]),_c('div',{staticClass:"tw-btn-primary-red tw-p-3",on:{"click":function($event){return _vm.pushLink(item.question_content.buttons[1].url)}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v(" "+_vm._s(item.question_content.buttons[1].label)+" ")])])]):_vm._e()])]},proxy:true}],null,true)})}),1)],1)]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4"},[_vm._m(2),_vm._m(3),_vm._l((_vm.contacts),function(item,idx){return _c('div',{key:idx,staticClass:"tw-flex tw-flex-col tw-items-center tw-gap-4 tw-py-5 tw-rounded-3xl lg:tw-border lg:tw-border-gray-300"},[_c('help-lines-cards',{attrs:{"title":item.contact_content.title,"description":item.contact_content.description,"icon":`diversity/icons/${item.contact_content.icon}.svg`}})],1)})],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-order-2 lg:tw-order-1 tw-flex tw-flex-col tw-justify-center lg:tw-col-span-4 tw-gap-4 tw-mt-5 lg:tw-m-auto"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-hidden lg:tw-block"},[_vm._v(" ¿Cómo podemos ayudarnos? ")]),_c('p',{staticClass:"tw-m-0 tw-text-sm lg:tw-text-base tw-hidden lg:tw-block"},[_vm._v(" Fomentamos un entorno inclusivo y equitativo para todos. Encuentra recursos, solicita ayuda personalizada y conoce nuestras lineas de atención para la diversidad, equidad e inclusión. Estamos aquí para escucharte y apoyarte. ¡Contáctanos! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-full tw-bg-pink-100 tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-6 hijo"},[_c('img',{staticClass:"tw-h-2/4 tw-transform tw-rotate-180",attrs:{"src":require("@/assets/img/diversity/icons/midcircle.svg"),"alt":""}}),_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"},[_vm._v(" ¿Cómo actuar? ")]),_c('img',{staticClass:"tw-h-2/4",attrs:{"src":require("@/assets/img/diversity/icons/midcircle.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-full tw-bg-red-350 tw-justify-between tw-rounded-2xl tw-py-4 tw-px-6 tw-hidden lg:tw-flex"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-2xl tw-text-white"},[_vm._v(" Líneas de atención ")]),_c('img',{staticClass:"tw-h-full",attrs:{"src":require("@/assets/img/icons/icon-5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-full tw-bg-pink-100 tw-items-center tw-justify-between tw-py-4 tw-px-6 hijo tw-flex lg:tw-hidden"},[_c('img',{staticClass:"tw-h-2/4 tw-transform tw-rotate-180",attrs:{"src":require("@/assets/img/diversity/icons/midcircle.svg"),"alt":""}}),_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"},[_vm._v(" Líneas de atención ")]),_c('img',{staticClass:"tw-h-2/4",attrs:{"src":require("@/assets/img/diversity/icons/midcircle.svg"),"alt":""}})])
}]

export { render, staticRenderFns }