import { render, staticRenderFns } from "./MainNavBarLinkOption.vue?vue&type=template&id=13a6f7c6"
import script from "./MainNavBarLinkOption.vue?vue&type=script&setup=true&lang=js"
export * from "./MainNavBarLinkOption.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports