<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { storeToRefs } from "pinia";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useStorage } from "@vueuse/core";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import {
  formatDateDDMMYYYY,
  compareDateWithToday,
} from "@/helpers/datesHelpers";
import { pushLink } from "@/helpers/linkHelpers";
import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { usePeopleTour } from "@/composables/peopleTour/usePeopleTour";
import SkeletonPeopleTourActivityDetail from "@/components/peopleTour/skeletons/SkeletonPeopleTourActivityDetail.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const router = useRouter();
const route = useRoute();
const peopleTourStore = usePeopleTourStore();
const { currentActivityData, currentStageData } = storeToRefs(peopleTourStore);
const isLoading = ref(false);
const isUploadEvidenceModalOpen = ref(false);
const isUploadingEvidence = ref(false);
const fileUploaded = ref(false);
const evidenceImage = ref(null);
const fileName = ref(null);
const user = useStorage("user", {});
const band = user.value.core_om_sideline?.name
  ? user.value.core_om_sideline?.name
  : user.value.core_pa_sideline?.name;
const { activityIcon, checkBP } = usePeopleTour();

function openUploadEvidenceModal(title) {
  pushDataLayer(buttonDatalayer(title));
  isUploadEvidenceModalOpen.value = true;
}

function closeUploadEvidenceModal() {
  isUploadEvidenceModalOpen.value = false;
}

function uploadImage() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/png, image/jpeg, image/jpg");
  input.click();
  input.onchange = () => {
    const file = input.files[0];
    if (file.size <= 5000000) {
      const reader = new FileReader();
      reader.onload = () => {
        evidenceImage.value = reader.result;
      };
      reader.readAsDataURL(file);
      fileName.value = file.name;
      fileUploaded.value = true;
    } else {
      alert("El archivo no puede ser mayor a 5MB");
    }
  };
}

function cancelUploadEvidence() {
  pushDataLayer({
    event_category: "Tour de gente",
    event_action: `${currentStageData.value.preTitle} ${currentStageData.value.title}`,
    event_label: "Cancelar",
    component_name: "Click",
    element_category: currentActivityData.value.title,
    element_text: "Subir comprobante",
  });
  closeUploadEvidenceModal();
}

async function fetchActivityData() {
  isLoading.value = true;
  await peopleTourStore.getCurrentActivityData(
    route.params.id,
    user.value.id_sharp,
    band
  );
  isLoading.value = false;
}

async function sendEvidence(activity_id) {
  pushDataLayer({
    event_category: "Tour de gente",
    event_action: `${currentStageData.value.preTitle} ${currentStageData.value.title}`,
    event_label: "Confirmar",
    component_name: "Click",
    element_category: currentActivityData.value.title,
    element_text: "Subir comprobante",
  });
  isUploadingEvidence.value = true;
  const response = await peopleTourStore.postEvidence(
    user.value.id_sharp,
    activity_id,
    evidenceImage.value
  );

  if (response.status === "ok") {
    await fetchActivityData();
    closeUploadEvidenceModal();
  }

  isUploadingEvidence.value = false;
}

const buttonDatalayer = (button_title) => {
  return {
    event_category: "Tour de gente",
    event_action: `${currentStageData.value.preTitle} ${currentStageData.value.title}`,
    event_label: button_title,
    component_name: "Click",
    element_category: currentActivityData.value.title,
    element_toins: `Toins: ${currentActivityData.value.gamification_points}`,
    element_state: currentActivityData.value.field_status,
  };
};

const voucherImg = computed(() => {
  return fileUploaded.value
    ? require("@/assets/img/timeBenefits/file-uploaded.png")
    : require("@/assets/img/timeBenefits/file-pending-blue.png");
});

onBeforeMount(async () => {
  isLoading.value = true;

  await checkBP(user.value.id_sharp);

  const response = await peopleTourStore.getCurrentActivityData(
    route.params.id,
    user.value.id_sharp,
    band
  );

  if (!response) {
    await router.push({ name: "peopleTour" });
    return;
  }

  isLoading.value = false;
});
</script>

<template>
  <div>
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 600, xl: 600 }"
      modal-name="uploadActivityFile"
      v-if="isUploadEvidenceModalOpen"
      :isShowModal="isUploadEvidenceModalOpen"
      @close="closeUploadEvidenceModal"
    >
      <template #title> Subir comprobante </template>
      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-3">
        <div
          class="lg:tw-col-span-4 tw-flex tw-flex-col tw-gap-2 tw-justify-end tw-order-2 lg:tw-order-1"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            Puedes subir un archivo jpg, jpeg o png de máximo 5MB
          </p>
          <button-primary-red
            :title="fileUploaded ? 'Cambiar imagen' : 'Subir imagen'"
            @click.native="uploadImage()"
          />
        </div>
        <div
          class="lg:tw-col-span-2 tw-order-1 lg:tw-order-2 tw-flex tw-justify-center"
        >
          <img
            :src="`${voucherImg}`"
            class="tw-object-contain tw-w-6/12 lg:tw-w-full tw-rounded-2xl lg:tw-rounded-3xl"
            alt=""
          />
        </div>
        <div class="lg:tw-col-span-6 tw-order-3">
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            {{
              fileName
                ? fileName
                : "Debes subir una imagen valida para poder continuar."
            }}
          </p>
        </div>
        <div class="lg:tw-col-span-6 tw-flex tw-gap-2 tw-order-4">
          <button-primary-red
            :title="isUploadingEvidence ? 'Subiendo...' : 'Confirmar'"
            :disabled="!fileUploaded"
            class="tw-w-full"
            @click.native="
              evidenceImage ? sendEvidence(currentActivityData.node_id) : null
            "
          />
          <comp-button-custom
            text="Cancelar"
            @click.native="cancelUploadEvidence"
            class="tw-w-full"
          />
        </div>
      </div>
    </general-modal>
    <mobile-bar title="Tour de Gente" />
    <title-bar title="Tour de Gente" />
    <aside class="tw-container-general" v-if="isLoading">
      <skeleton-people-tour-activity-detail />
    </aside>
    <main class="tw-container-general" v-else>
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-5 lg:tw-gap-20"
      >
        <section
          class="tw-rounded-2.5xl tw-border tw-p-4 lg:tw-p-8 tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4"
          :style="'border-color: ' + currentStageData.color.color"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-2xl lg:tw-text-4xl"
            :style="'color:' + currentStageData.color.color"
          >
            {{ currentActivityData.title }}
          </p>
          <div
            class="tw-rounded-xl tw-overflow-hidden tw-w-full tw-aspect-w-16 tw-aspect-h-9"
          >
            <img
              :src="currentActivityData.image"
              class="tw-object-cover tw-w-full tw-h-full"
              alt=""
            />
          </div>
          <div
            class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-w-full"
          >
            <div class="tw-flex tw-gap-2 lg:tw-gap-4 tw-items-center">
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base"
                :style="'color:' + currentStageData.color.color"
              >
                Fecha inicial:
              </p>
              <p class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base">
                {{ formatDateDDMMYYYY(currentActivityData.start_date) }}
              </p>
            </div>
            <div class="tw-flex tw-gap-2 lg:tw-gap-4 tw-items-center">
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base"
                :style="'color:' + currentStageData.color.color"
              >
                Fecha final:
              </p>
              <p class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base">
                {{ formatDateDDMMYYYY(currentActivityData.end_date) }}
              </p>
            </div>
          </div>
          <!--                    <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
                      {{ currentActivityData.description.value }}
                    </p>-->
          <div
            class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base"
            v-html="currentActivityData.description.processed"
          />
          <div
            class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base"
            v-html="currentActivityData.detail.processed"
          />
          <!-- div con dos botones pero no como button sino como div, el primero como tw-btn-secondary-white y con el texto 'Guía para trivia' y el segundo como tw-btn-primary-red y con el texto 'Responder trivia' -->
          <div
            class="tw-flex tw-gap-2 lg:tw-gap-4"
            v-if="currentActivityData.buttons.length > 0"
          >
            <div
              class="tw-btn-secondary-white"
              @click="
                pushLink(
                  currentActivityData.buttons[0].uri,
                  buttonDatalayer(currentActivityData.buttons[0].title)
                )
              "
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
                {{ currentActivityData.buttons[0].title }}
              </p>
            </div>
            <div
              class="tw-btn-primary-red"
              @click="
                pushLink(
                  currentActivityData.buttons[1].uri,
                  buttonDatalayer(currentActivityData.buttons[1].title)
                )
              "
              v-if="currentActivityData.buttons[1]"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
                {{ currentActivityData.buttons[1].title }}
              </p>
            </div>
          </div>
        </section>
        <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
          <section class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6">
            <div class="tw-flex tw-justify-between tw-items-center">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
                Estado de tu actividad
              </p>
              <img
                src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
                class="tw-max-h-5 lg:tw-max-h-10"
                alt=""
              />
            </div>
            <div
              class="tw-rounded-2.5xl tw-border tw-p-4 lg:tw-p-8 tw-flex tw-justify-between tw-items-center tw-gap-2 lg:tw-gap-4"
              :style="'border-color: ' + currentStageData.color.color"
            >
              <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
                {{
                  currentActivityData.field_status === "Aprobada"
                    ? "Has ganado"
                    : currentActivityData.field_status
                }}
              </p>
              <div>
                <div
                  class="tw-flex tw-gap-2"
                  v-if="currentActivityData.field_status === 'Aprobada'"
                >
                  <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-2xl">
                    {{
                      currentActivityData.points +
                      " de " +
                      currentActivityData.max_points +
                      " puntos"
                    }}
                  </p>
                  <div
                    class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-2"
                  >
                    <i
                      class="icon-star tw-golden-black-border-icon lg:tw-text-base"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="tw-col-span-2 tw-flex tw-flex-col tw-gap-2 tw-h-full tw-self-center"
              >
                <!--
                <img
                  src="
                    @/assets/img/peopleTour/icons/icono-toin.png
                  "
                  class="tw-object-contain tw-max-h-16 lg:tw-max-h-24"
                  alt=""
                />
-->
                <img
                  :src="
                    require('@/assets/img/peopleTour/icons/' +
                      activityIcon(currentActivityData.field_status))
                  "
                  class="tw-object-contain tw-max-h-16 lg:tw-max-h-24"
                  alt=""
                />
                <div
                  class="tw-status-bubble"
                  :style="'background-color:' + currentStageData.color.color"
                >
                  <p
                    class="tw-m-0 tw-text-xs lg:tw-text-base tw-text-white tw-text-center"
                  >
                    {{ currentActivityData.field_status }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="tw-rounded-2.5xl tw-border tw-p-4 lg:tw-p-8 tw-flex tw-justify-evenly tw-items-center tw-gap-2 lg:tw-gap-4"
              :style="'border-color: ' + currentStageData.color.color"
              v-if="currentActivityData.field_status === 'Aprobada'"
            >
              <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
                Toins ganados
              </p>
              <div class="tw-flex tw-gap-2 tw-items-center">
                <p class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-3xl">
                  {{ currentActivityData.gamification_points }}
                </p>
                <img
                  src="@/assets/img/peopleTour/icons/icono-toin.png"
                  class="tw-max-w-[2rem] lg:tw-max-w-[2.5rem]"
                  alt=""
                />
              </div>
            </div>
          </section>
          <section
            class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6"
            v-if="
              currentActivityData.type === 'Evidence' &&
              (currentActivityData.field_status === 'Por hacer' ||
                currentActivityData.field_status === 'Pendiente' ||
                currentActivityData.field_status === 'Rechazada') &&
              compareDateWithToday(currentActivityData.end_date)
            "
          >
            <div class="tw-flex tw-justify-between tw-items-center">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
                {{
                  currentActivityData.field_status === "Por hacer"
                    ? "Sube tu comprobante"
                    : "Cambia tu comprobante"
                }}
              </p>
              <img
                src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
                class="tw-max-h-5 lg:tw-max-h-10"
                alt=""
              />
            </div>
            <div
              class="tw-rounded-2.5xl tw-border tw-p-4 lg:tw-p-8 tw-grid tw-grid-cols-col2_fit_first tw-gap-2 lg:tw-gap-4"
              :style="'border-color: ' + currentStageData.color.color"
            >
              <div>
                <img
                  src="@/assets/img/peopleTour/icons/icono-cargacomprobante.png"
                  class="tw-max-h-24 lg:tw-max-h-36"
                  alt=""
                />
              </div>
              <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
                <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
                  Sube una imagen que no pese más de 5MB donde podamos ver
                  claramente que completaste la actividad.
                </p>
                <button
                  class="tw-btn-primary-red"
                  @click="
                    openUploadEvidenceModal(
                      currentActivityData.field_status === 'Por hacer'
                        ? 'Subir comprobante'
                        : 'Cambiar comprobante'
                    )
                  "
                >
                  <span
                    class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base"
                  >
                    {{
                      currentActivityData.field_status === "Por hacer"
                        ? "Subir comprobante"
                        : "Cambiar comprobante"
                    }}
                  </span>
                </button>
              </div>
            </div>
          </section>
          <!--          <section
            class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6"
            v-if="currentActivityData.field_status === 'Rechazada'"
          >
            <div class="tw-flex tw-justify-between tw-items-center">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
                Comentarios
              </p>
              <img
                src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
                class="tw-max-h-5 lg:tw-max-h-10"
                alt=""
              />
            </div>
            <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
              Lo sentimos, esta actividad fue rechazada por tu Business Partner,
              estos son sus comentarios:
            </p>
            <div
              class="tw-rounded-2.5xl tw-border tw-p-4 lg:tw-p-8 tw-grid tw-grid-cols-col2_fit_first tw-gap-2 lg:tw-gap-4"
              :style="'border-color: ' + currentStageData.color.color"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
                Rechacé tu solicitud debido a que la imagen no corresponde con
                lo solicitado en la actividad.
              </p>
            </div>
          </section>-->
          <section class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6">
            <div class="tw-flex tw-justify-between tw-items-center">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
                Queremos escucharte
              </p>
              <img
                src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
                class="tw-max-h-5 lg:tw-max-h-10"
                alt=""
              />
            </div>
            <div
              class="tw-rounded-2.5xl tw-border tw-p-4 lg:tw-p-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 lg:tw-gap-4"
              :style="'border-color: ' + currentStageData.color.color"
            >
              <div>
                <img
                  :src="currentActivityData?.field_csat_imagen"
                  class="tw-object-contain tw-w-full tw-h-full lg:tw-max-h-[350px]"
                  alt=""
                />
              </div>
              <button
                class="tw-btn-primary-red tw-w-max tw-px-4 lg:tw-px-8"
                @click="pushLink(currentActivityData?.field_csat_enlace?.uri)"
              >
                <span>{{ currentActivityData?.field_csat_enlace?.title }}</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>
