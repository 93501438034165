<script setup>
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});

const temporalValue = ref("");

const emit = defineEmits(["deleteInput"]);

const showCloseIcon = computed(() => {
  return temporalValue.value !== "";
});
// ...

const formatInput = (event) => {
  // Obtener la posición actual del cursor
  const cursorPosition = event.target.selectionStart;

  // Verificar si el valor ya está en el formato "YYYY-MM-DD"
  // const isFormatted = /^\d{4}-\d{2}-\d{2}$/.test(value.value);

  const formattedValue = temporalValue.value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
    .slice(0, 10);

  // Si se está eliminando hacia atrás y se encuentra en la posición de un guion, retrocede un paso más
  if (
    event.inputType === "deleteContentBackward" &&
    /-\d/.test(temporalValue.value.charAt(cursorPosition - 1))
  ) {
    event.target.selectionStart = event.target.selectionEnd =
      cursorPosition - 1;
  }

  // Limitar la longitud total a 10 caracteres, conservando el guion si está presente
  temporalValue.value =
    formattedValue.slice(0, 10) +
    (formattedValue.length > 10 ? formattedValue.charAt(10) : "");

  // Permitir escribir guiones en posiciones específicas
  if (cursorPosition >= 5 && temporalValue.value.charAt(4) !== "-") {
    temporalValue.value =
      temporalValue.value.slice(0, 4) +
      "-" +
      temporalValue.value.slice(4, temporalValue.value.length);
    // Incrementar la posición del cursor después de agregar el guion
    event.target.selectionStart = event.target.selectionEnd =
      cursorPosition + 1;
  }

  if (cursorPosition >= 8 && temporalValue.value.charAt(7) !== "-") {
    temporalValue.value =
      temporalValue.value.slice(0, 7) +
      "-" +
      temporalValue.value.slice(7, temporalValue.value.length);
    // Incrementar la posición del cursor después de agregar el guion
    event.target.selectionStart = event.target.selectionEnd =
      cursorPosition + 1;
  }

  // Actualizar el valor del modelo
  emit("update", temporalValue.value);
};

watch(
  () => props.modelValue,
  (newValue) => {
    temporalValue.value = newValue;
  }
);

onMounted(() => {
  temporalValue.value = props.modelValue;
});
</script>

<template>
  <div class="tw-relative">
    <i
      v-if="showCloseIcon"
      @click="emit('deleteInput')"
      class="tw-cursor-pointer icon-cerrar-simple tw-absolute tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-right-3 tw-text-red-350"
    />
    <input
      v-model="temporalValue"
      class="text-input"
      type="text"
      @input="formatInput"
    />
  </div>
</template>

<style scoped lang="postcss">
.text-input {
  @apply tw-text-xs lg:tw-text-sm tw-bg-white tw-text-black tw-border tw-border-gray-500 tw-rounded-xl tw-font-robotoLight tw-pl-2 tw-pr-8 tw-w-full;
  &:disabled {
    @apply tw-bg-gray-200 tw-cursor-not-allowed;
  }
  &:focus {
    @apply tw-outline-none tw-border-gray-500 tw-ring-0;
  }
}
</style>
