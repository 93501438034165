var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-orderStatus-' + _vm.item.id),expression:"'accordion-orderStatus-' + item.id"}],staticClass:"d-flex justify-content-between tw-cursor-pointer outline-none head-tab",attrs:{"role":"button"},on:{"click":function($event){return _vm.updateActualOrder(_vm.index)}}},[_c('p',{staticClass:"tw-text-gray-600 mb-0 tw-text-sm fs-md-16 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.item.created_at))+" ")]),_c('p',{staticClass:"tw-text-gray-300 mb-0 tw-text-sm"},[_vm._v("No. "+_vm._s(_vm.item.id))])]),_c('b-collapse',{attrs:{"id":'accordion-orderStatus-' + _vm.item.id,"accordion":"my-orderStatus-accordion"},model:{value:(_vm.collapses[_vm.index]),callback:function ($$v) {_vm.$set(_vm.collapses, _vm.index, $$v)},expression:"collapses[index]"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between pt-3 pb-4"},[_c('div',{staticClass:"position-relative he-90 d-flex align-items-center"},[_c('div',{staticClass:"tw-rounded-full d-flex justify-content-center align-items-center tw-font-roboto",class:_vm.item.ecomm_order_state.id === 1
              ? 'wi-50 wi-md-70 he-50 he-md-70 tw-bg-red-400 text-white fs-30 fs-md-40'
              : _vm.item.ecomm_order_state.id > 1
              ? _vm.item.ecomm_order_state.id === 4
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                : _vm.item.ecomm_order_state.id === 5
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white border-sm-red-100 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-red-100 text-white border-sm-red-100 tw-text-xl fs-md-30'
              : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'},[_c('i',{staticClass:"icon-factura"}),_c('p',{staticClass:"tw--bottom-5 md:tw--bottom-10 text-center tw-text-xs fs-md-14 position-absolute",class:_vm.item.ecomm_order_state.id === 1
                ? 'tw-text-red-400 font-weight-bold'
                : _vm.item.ecomm_order_state.id > 1
                ? _vm.item.ecomm_order_state.id === 4
                  ? 'tw-text-green-400'
                  : _vm.item.ecomm_order_state.id === 5
                  ? 'tw-text-gray-300'
                  : ' tw-text-red-100'
                : 'tw-text-gray-400'},[_vm._v(" Realizado ")])])]),_c('div',{staticClass:"w-100 he-4 mt-1",class:_vm.item.ecomm_order_state.id === 4 ? 'tw-bg-green-400' : _vm.item.ecomm_order_state.id === 2 || _vm.item.ecomm_order_state.id === 3 ? 'tw-bg-red-100' : 'tw-bg-gray-300'}),_c('div',{staticClass:"position-relative he-90 d-flex align-items-center"},[_c('div',{staticClass:"mt-2 tw-rounded-full d-flex justify-content-center align-items-center",class:_vm.item.ecomm_order_state.id === 2
              ? 'wi-50 wi-md-70 he-50 he-md-70 tw-bg-red-400 text-white fs-30 fs-md-40'
              : _vm.item.ecomm_order_state.id > 2
              ? _vm.item.ecomm_order_state.id === 4
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                : _vm.item.ecomm_order_state.id === 5
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white border-sm-red-100 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-red-100 text-white border-sm-red-100 tw-text-xl fs-md-30'
              : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'},[_c('i',{staticClass:"icon-botellas"}),_c('p',{staticClass:"tw--bottom-5 md:tw--bottom-10 text-center tw-text-xs fs-md-14 position-absolute",class:_vm.item.ecomm_order_state.id === 2
                ? 'tw-text-red-400 font-weight-bold'
                : _vm.item.ecomm_order_state.id > 2
                ? _vm.item.ecomm_order_state.id === 4
                  ? 'tw-text-green-400'
                  : _vm.item.ecomm_order_state.id === 5
                  ? 'tw-text-gray-300'
                  : ' tw-text-red-100'
                : 'tw-text-gray-400'},[_vm._v(" Alistando ")])])]),_c('div',{staticClass:"w-100 he-4 mt-1",class:_vm.item.ecomm_order_state.id === 4 ? 'tw-bg-green-400' : _vm.item.ecomm_order_state.id === 3 ? 'tw-bg-red-100' : 'tw-bg-gray-300'}),_c('div',{staticClass:"position-relative he-90 d-flex align-items-center"},[_c('div',{staticClass:"mt-2 tw-rounded-full d-flex justify-content-center align-items-center",class:_vm.item.ecomm_order_state.id === 3
              ? 'wi-50 wi-md-70 he-50 he-md-70 tw-bg-red-400 text-white fs-30 fs-md-40'
              : _vm.item.ecomm_order_state.id > 3
              ? _vm.item.ecomm_order_state.id === 4
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
                : _vm.item.ecomm_order_state.id === 5
                ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white border-sm-red-100 tw-text-xl fs-md-30'
                : 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-red-100 text-white border-sm-red-100 tw-text-xl fs-md-30'
              : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'},[_c('i',{staticClass:"icon-mobile-tienda"}),_c('p',{staticClass:"tw--bottom-5 md:tw--bottom-10 text-center tw-text-xs fs-md-14 position-absolute ml-n2 ml-md-n4",class:_vm.item.ecomm_order_state.id === 3
                ? 'tw-text-red-400 wi-50 wi-md-70 font-weight-bold'
                : _vm.item.ecomm_order_state.id > 3
                ? _vm.item.ecomm_order_state.id === 4
                  ? 'tw-text-green-400 wi-50 wi-md-50'
                  : _vm.item.ecomm_order_state.id === 5
                  ? 'tw-text-gray-300 wi-50 wi-md-50'
                  : ' tw-text-red-100 wi-50 wi-md-50'
                : 'tw-text-gray-400 wi-50 wi-md-50'},[_vm._v(" ¡Reclámalo! ")])])]),_c('div',{staticClass:"w-100 he-4 mt-1",class:_vm.item.ecomm_order_state.id === 4 ? 'tw-bg-green-400' : 'tw-bg-gray-300'}),_c('div',{staticClass:"position-relative he-90 d-flex align-items-center"},[_c('div',{staticClass:"mt-2 tw-rounded-full d-flex justify-content-center align-items-center",class:_vm.item.ecomm_order_state.id === 4
              ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-green-400 text-white tw-border-2 tw-border-solid tw-border-green-400  tw-text-xl fs-md-30'
              : _vm.item.ecomm_order_state.id === 5 ? 'wi-40 wi-md-50 he-40 he-md-50 tw-bg-gray-300 text-white tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'
              : 'wi-40 wi-md-50 he-40 he-md-50 bg-white tw-text-gray-300 tw-border-2 tw-border-solid tw-border-gray-300 tw-text-xl fs-md-30'},[_c('i',{staticClass:"icon-circulo-check"}),(_vm.item.ecomm_order_state.id === 5)?_c('p',{staticClass:"tw--bottom-5 md:tw--bottom-10 text-center tw-text-xs fs-md-14 position-absolute ml-n2 ml-md-n4",class:_vm.item.ecomm_order_state.id === 5
                ? 'tw-text-gray-300 font-weight-bold wi-50 wi-md-50'
                : 'tw-text-gray-400 wi-50 wi-md-50 '},[_vm._v(" Cancelado ")]):_c('p',{staticClass:"tw--bottom-5 md:tw--bottom-10 text-center tw-text-xs fs-md-14 position-absolute ml-n2 ml-md-n4",class:_vm.item.ecomm_order_state.id === 4
                ? 'tw-text-green-400 font-weight-bold wi-50 wi-md-50'
                : 'tw-text-gray-400 wi-50 wi-md-50'},[_vm._v(" Entregado ")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }