<script setup>
import { computed, ref } from "vue";
import CustomDoughnutChart from "@/components/graphs/CustomDoughnutChart.vue";

const isActive = ref(false);

const props = defineProps({
  content: {
    type: Object,
    default: () => {},
  },
});

const charts = computed(() => {
  return props.content.charts.slice(1);
});

const showInfo = () => {
  isActive.value = !isActive.value;
};
</script>
<template>
  <div
    class="tw-w-full tw-mx-auto tw-relative caja tw-rounded-3xl tw-mb-2 tw-h-min"
  >
    <div
      class="tw-w-full tw-grid tw-grid-cols-3 tw-border tw-border-gray-250 tw-rounded-3xl tw-gap-4 tw-p-6 tw-bg-white"
    >
      <div class="tw-col-span-2 tw-flex tw-flex-col tw-justify-evenly">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"
        >
          {{ props.content.title }}
        </p>
        <p
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-2 lg:tw-line-clamp-3"
        >
          {{ props.content.desc }}
        </p>
      </div>
      <div class="tw-col-span-1">
        <div class="tw-w-full tw-relative">
          <custom-doughnut-chart :percentage="props.content.charts[0]" />
        </div>
      </div>
    </div>
    <div>
      <div
        class="tw-bg-pink-100 tw-relative info"
        :class="isActive ? 'active' : ''"
      >
        <div class="tw-grid tw-grid-cols-5 tw-gap-2 tw-h-full tw-my-2 tw-mx-3">
          <div v-for="(chart, idx) in charts" :key="idx">
            <div
              class="tw-w-full tw-relative tw-text-center tw-flex tw-flex-col tw-gap-2"
            >
              <custom-doughnut-chart :percentage="chart" />
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-xs tw-text-red-350"
              >
                {{ chart.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="showInfo()"
        class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-p-3 tw-cursor-pointer selector"
      >
        <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
          {{ isActive ? "Ver menos" : "Ver detalles" }}
        </p>
        <i
          class="icon-flecha-abajo tw-text-red-350 tw-inline-block tw-text-xs tw-leading-none tw-transition-transform tw-duration-200"
        ></i>
      </div>
    </div>
  </div>
</template>

<style scoped>
.caja {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 30%,
    #fcf2eb 30%,
    #fcf2eb 100%
  );
}

.info {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.info.active {
  height: auto;
  max-height: 200px;
  transition: max-height 1000ms ease-in-out;
}

.info.active + .selector i {
  transform: rotate(180deg);
}
</style>
