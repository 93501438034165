import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();

const uri = {
  user_uri: "/coreEmployees",
  collections_uri: "/collections",
  profile_questions_uri: "/profQuestions",
  profile_onboarding_uri: "/profOnboardSlides",
  profile_onboardEmployee_uri: "/profOnboardEmployees",
  profile_children: "/profChildrens",
  profile_families: "/profFamilies",
  profile_pets: "/profPets",
  profile_hobbies: "/profHobbies",
  profile_communities: "/profCommunities",
  profile_disabilities: "/profDisabilities",
  profile_family_types: "/profFamilyTypes",
  profile_gender_identities: "/profGenderIdentities",
};

export const useProfileStore = defineStore("profile", () => {
  const pets = ref([]);
  const profileQuestions = ref([]);
  const profileType = ref({});
  const profileOnboardingSlides = ref([]);
  const profileOnboardingEmployee = ref([]);
  const userProfileData = ref([]);
  const pronounsCollection = ref([]);
  const communitiesCollection = ref([]);
  const disabilitiesCollection = ref([]);
  const familyTypesCollection = ref([]);
  const attractedToCollection = ref([]);
  const ethnicGroupsCollection = ref([]);
  const gendersCollection = ref([]);
  const genderIdentitiesCollection = ref([]);
  const hobbiesCollection = ref([]);
  const isProfileSaved = ref(false);

  async function actGetPets() {
    const response = await AxiosBeerOffice.get(uri.profile_pets, true, {});
    if (response.error) return response.error;
    const { data: petsData } = response;
    pets.value = petsData;
  }

  async function actGetProfileQuestions() {
    const response = await AxiosBeerOffice.get(
      uri.profile_questions_uri,
      true,
      {}
    );
    if (response.error) return response.error;
    profileQuestions.value = response;
  }

  async function actPostProfileQuestionsAnswer(data) {
    const response = await AxiosBeerOffice.post(
      uri.profile_questions_uri,
      true,
      data
    );
    if (response.error) return response.error;
    const { data: profileTypeData } = response;
    profileType.value = profileTypeData;
  }

  async function actGetProfileOnboarding() {
    const response = await AxiosBeerOffice.get(
      uri.profile_onboarding_uri,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data: profileOnboardingData } = response;
    profileOnboardingSlides.value = profileOnboardingData;
  }

  async function actPostProfileOnboardingEmployee(data) {
    const response = await AxiosBeerOffice.post(
      uri.profile_onboardEmployee_uri,
      true,
      data
    );
    if (response.error) return response.error;
    const { data: profileOnboardingEmployeeData } = response;
    profileOnboardingEmployee.value = profileOnboardingEmployeeData;
  }

  // async function actPatchUpdateProfile(data) {
  //   const response = await AxiosBeerOffice.patch(
  //     uri.user_uri + `/${userStore.user.id}`,
  //     true,
  //     data
  //   );
  //
  //   const { data: userData } = response;
  //
  //   if (!userData) {
  //     return { error: "An error occurred while updating the profile." };
  //   }
  //
  //   userStore.setUser(userData);
  //   return true;
  // }

  async function actGetProfileData() {
    const response = await AxiosBeerOffice.get(
      `${uri.user_uri}/${userStore.user.id}/features`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { features } = response;
    userProfileData.value = features;
    return true;
  }

  async function actPostProfileData(data) {
    const response = await AxiosBeerOffice.post(
      `${uri.user_uri}/${userStore.user.id}/features/profile`,
      true,
      {
        features: data,
      }
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostProfileImage(data) {
    const response = await AxiosBeerOffice.post(
      `${uri.user_uri}/${userStore.user.id}/features/picture`,
      true,
      data,
      true
    );
    if (response.error) return response.error;
    return true;
  }

  async function actGetCollectionPronouns() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/pronouns",
      true,
      {}
    );
    if (response.error) return response.error;
    pronounsCollection.value = response;
    return true;
  }

  async function actGetCollectionCommunities() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/communities",
      true,
      {}
    );
    if (response.error) return response.error;
    communitiesCollection.value = response;
    return true;
  }

  async function actGetCollectionDisabilities() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/disabilities",
      true,
      {}
    );
    if (response.error) return response.error;
    disabilitiesCollection.value = response;
    return true;
  }

  async function actGetCollectionFamilyTypes() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/family_types",
      true,
      {}
    );
    if (response.error) return response.error;
    familyTypesCollection.value = response;
    return true;
  }

  async function actGetCollectionAttractedTo() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/attracted_to",
      true,
      {}
    );
    if (response.error) return response.error;
    attractedToCollection.value = response;
    return true;
  }

  async function actGetCollectionEthnicGroups() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/ethnic_groups",
      true,
      {}
    );
    if (response.error) return response.error;
    ethnicGroupsCollection.value = response;
    return true;
  }

  async function actGetCollectionGenders() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/genders",
      true,
      {}
    );
    if (response.error) return response.error;
    gendersCollection.value = response;
    return true;
  }

  async function actGetCollectionGenderIdentities() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/gender_identities",
      true,
      {}
    );
    if (response.error) return response.error;
    genderIdentitiesCollection.value = response;
    return true;
  }

  async function actGetCollectionHobbies() {
    const response = await AxiosBeerOffice.get(
      uri.collections_uri + "/hobbies",
      true,
      {}
    );
    if (response.error) return response.error;
    hobbiesCollection.value = response;
    return true;
  }

  return {
    pets,
    userProfileData,
    pronounsCollection,
    communitiesCollection,
    disabilitiesCollection,
    familyTypesCollection,
    attractedToCollection,
    ethnicGroupsCollection,
    gendersCollection,
    genderIdentitiesCollection,
    hobbiesCollection,
    profileQuestions,
    profileType,
    profileOnboardingSlides,
    profileOnboardingEmployee,
    isProfileSaved,
    actGetPets,
    actGetProfileQuestions,
    actPostProfileQuestionsAnswer,
    actGetProfileOnboarding,
    actPostProfileOnboardingEmployee,
    actGetProfileData,
    actPostProfileData,
    actPostProfileImage,
    actGetCollectionPronouns,
    actGetCollectionCommunities,
    actGetCollectionDisabilities,
    actGetCollectionFamilyTypes,
    actGetCollectionAttractedTo,
    actGetCollectionEthnicGroups,
    actGetCollectionGenders,
    actGetCollectionGenderIdentities,
    actGetCollectionHobbies,
  };
});
