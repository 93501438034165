<template>
  <div
    class="
      tw-shadow-sm
      tw-rounded-lg
      my-2 my-md-0
      mr-md-3
      overflow-hidden
      wi-md-300
    "
  >
    <div class="row m-auto he-50 w-100 tw-bg-gray-300">
      <div
        class="col d-flex flex-column justify-content-center align-items-center"
      >
        <p class="font-RobotoBold  tw-text-xs  fs-md-14 line-height-16 m-auto text-center">
          {{ kpiObject.name }}
        </p>
      </div>
    </div>
    <p
      class="
        text-center
        font-Roboto
        fs-md-12
        line-height-20
        tw-text-gray-300
        px-0
        py-2
        m-0
      "
    >
      Peso: {{ formatNumber(kpiObject.variable_salary_weight) }} % / Meta:
      {{
        Number(kpiObject.target).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }}
    </p>
    <hr class="m-0 p-0" />
    <div class="row m-auto">
      <div class="col d-flex justify-content-between align-items-center py-2">
        <p class="font-RobotoBold  tw-text-xs  line-height-22 my-auto">Resultado</p>
        <p class="font-RobotoBold  tw-text-xs  line-height-22 my-auto">Cump</p>
      </div>
    </div>
		<div class="row m-auto">
      <div class="col d-flex justify-content-between align-items-center py-2">
        <input
          type="number"
          class="form-control wi-150 tw-shadow-md my-auto bor-none"
          aria-label="Username"
          aria-describedby="basic-addon1"
          :max="kpiObject.target * 10"
          v-model="percentValue"
        />
        <p class="font-Roboto tw-text-base line-height-20 tw-text-gray-600 my-auto">= {{ formatNumber(calcFulfilment) }} %</p>
      </div>
    </div>
    <div class="row m-auto tw-bg-gray-100">
      <div class="col d-flex justify-content-between align-items-center py-3">
        <p class="font-RobotoBold  tw-text-xs  my-0">Subtotal:</p>
        <p class="font-Roboto tw-text-base my-0 tw-text-green-400">{{ calcSubtotal | currency }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompItemCalc",
  props: {
    kpiObject: {
      type: Object,
      value: {},
      required: true,
    },
    rates: {
      type: Array,
      value: [],
      required: true,
    },
    salaryBase: {
      type: Number,
      value: 0,
      required: true,
    },
  },
  computed: {
    calcSubtotal() {
      const variableCasual = this.percentValue / this.kpiObject.target;
      const rate = this.getRate(variableCasual);

      const result =
        this.kpiObject.variable_salary_weight * rate * this.salaryBase;

      this.$emit("subTotal", result);
      return result;
    },
    calcFulfilment() {
      return this.percentValue / this.kpiObject.target;
    },
  },
  data() {
    return {
      percentValue: this.kpiObject.result,
      subTotalResult: 0,
    };
  },
  methods: {
    getRate(value) {
      if (value < this.rates[0].min) {
        return 0;
      }
      const ob = this.rates.filter(
        (rate) => value >= rate.min && value < rate.max
      );
      if (ob.length === 0) {
        return this.rates[this.rates.length - 1].payment;
      }

      return ob[0].payment;
    },
    formatNumber(numValue) {
      let newValue = numValue * 100;
      return Math.floor(newValue * 100) / 100;
    },
  },
};
</script>

<style scoped>
</style>