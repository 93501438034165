<script setup>
import { ref } from "vue";
import { pushLink } from "@/helpers/linkHelpers";
import config from "@/services/config";
import CompDiversityArticlesCard from "@/components/diversity/CompDiversityArticlesCard.vue";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";

const uriResources = config.beerOfficeURL;
const showAll = ref(false);

const emit = defineEmits(["updateReaction", "updatedComment"]);

const props = defineProps({
  articles: {
    type: Array,
    required: true,
  },
  featuredArticle: {
    type: Object,
    required: true,
  },
  articleCategory: {
    type: String,
    default: "",
  },
});

function toggleShowAll() {
  showAll.value = !showAll.value;
}

function goToArticle(article) {
  pushLink(`/articles/${article.id}`, {
    event_category: "Diversidad e Inclusión",
    event_action: props.articleCategory,
    event_label: article.title,
    component_name: "Click",
    element_category: props.articleCategory,
  });
}
</script>
<template>
  <div>
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-2 lg:tw-gap-10 tw-my-8"
      v-if="props.featuredArticle"
    >
      <div class="">
        <div class="tw-aspect-w-16 tw-aspect-h-8">
          <img
            :src="uriResources + '/' + props.featuredArticle?.resource"
            class="tw-rounded-2xl tw-object-cover"
            alt=""
          />
        </div>
      </div>
      <div class="">
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-h-full tw-gap-2 lg:tw-gap-8"
        >
          <!--          <div class="tw-flex tw-justify-end">
            <p
              class="tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-red-350 tw-px-4 tw-bg-pink-100 tw-rounded-xl tw-mb-0"
            >
              ¡Nuevo!
            </p>
          </div>-->
          <div class="tw-flex tw-flex-col tw-gap-2">
            <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
              {{ props.featuredArticle?.title }}
            </p>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-lg tw-leading-4 lg:tw-leading-5 tw-line-clamp-3 lg:tw-line-clamp-2"
            >
              {{ props.featuredArticle?.description }}
            </p>
          </div>
          <comp-social-network-interactions
            :id="props.featuredArticle?.id"
            :title="props.featuredArticle?.title"
            category="NEW_ARTICLES"
            :user_reaction="props.featuredArticle?.user_reaction"
            :amount_comments="Number(props.featuredArticle?.amount_comments)"
            :amount_reactions="Number(props.featuredArticle?.amount_reactions)"
            :image="props.featuredArticle?.resource"
            :description="props.featuredArticle?.description"
            :action-for-datalayer="articleCategory"
            :item="props.featuredArticle"
            category-for-datalayer="Diversidad e Inclusión"
            minimal-icons-desk
            @updatedComment="emit('updatedComment', $event)"
            @updatedReaction="emit('updatedReaction', $event)"
          />
          <div
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click="goToArticle(props.featuredArticle)"
          >
            <p
              class="tw-font-roboto tw-text-xs lg:tw-text-lg lg:tw-leading-5 tw-text-red-350 tw-m-0"
            >
              Saber más
            </p>
            <i class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-xs"></i>
          </div>
        </div>
      </div>
    </div>
    <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 lg:tw-gap-8">
      <div v-for="(article, idx) in props.articles" :key="idx">
        <comp-diversity-articles-card
          :article="article"
          category-for-social="NEW_ARTICLES"
          :article-category="props.articleCategory"
        />
        <hr class="tw-block lg:tw-hidden tw-w-full" />
      </div>
    </div>
    <div
      @click="toggleShowAll"
      class="tw-flex tw-justify-center tw-items-center tw-border tw-border-r-0 tw-border-l-0 tw-py-5 tw-border-gray-320 tw-my-4 md:tw-my-6 lg:tw-my-8 tw-cursor-pointer"
    >
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-base tw-leading-5 md:tw-text-xl md:tw-leading-6 lg:tw-text-2xl lg:tw-leading-7"
      >
        {{ showAll ? "Mostrar menos contenido" : "Mostrar más contenido" }}
      </p>
      <i
        class="tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-2xl"
        :class="showAll ? 'icon-flecha-arriba' : 'icon-flecha-abajo'"
      ></i>
    </div>
  </div>
</template>
