<script setup>
import { computed } from "vue";
import { differenceInDays } from "@/helpers/datesHelpers";

const today = new Date();

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
});

const getDays = computed(() => {
  return Math.round(
    (today.getTime() - new Date(props.notification.created_at).getTime()) /
      (1000 * 3600 * 24)
  ).toFixed(0);
});
</script>
<template>
  <div
    class="tw-px-3 tw-bg-white hover:tw-bg-gray-200"
    :class="{
      'tw-cursor-pointer': props.notification.url,
      'tw-bg-red-50': props.notification.read_at == null,
    }"
  >
    <div class="tw-border-b tw-border-gray-300 tw-py-3 tw-h-auto">
      <div class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-mb-3">
        <div class="tw-col-span-8 md:tw-col-span-9">
          <p class="tw-mb-0 tw-text-black tw-text-base tw-font-robotoBold">
            {{ props.notification.title }}
          </p>
        </div>
        <div class="tw-col-span-3 md:tw-col-span-2">
          <span
            v-if="
              differenceInDays(
                new Date(props.notification.created_at),
                new Date()
              ) <= 10
            "
            class="tw-rounded-md tw-bg-red-50 tw-px-2 tw-py-1 tw-text-red-400 tw-font-robotoBold"
            style="font-size: 10px"
          >
            ¡Nuevo!
          </span>
        </div>
        <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
          <i
            class="icon-flecha-der tw-text-red-400 tw-text-xl"
            v-if="props.notification.url"
          ></i>
        </div>
      </div>
      <p class="tw-mb-0 tw-font-roboto tw-text-xs tw-text-black">
        {{ props.notification.message }}
      </p>
      <p
        class="tw-mt-2 tw-mb-0 tw tw-font-roboto tw-text-xs tw-italic tw-text-black"
      >
        {{
          getDays === 0
            ? "Hoy"
            : getDays === 1
            ? "Ayer"
            : "Hace " + getDays + " días"
        }}
      </p>
    </div>
  </div>
</template>
