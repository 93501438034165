<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useNewsStore } from "@/stores/newsStore";
import SkeletonCardNewsFeatured from "@/components/news/SkeletonCardNewsFeatured";
import CompGeneralFeaturedCard from "@/components/general/CompGeneralFeaturedCard.vue";
const { $isMobile: mobile } = getCurrentInstance().proxy;

// Import Swiper
import { Navigation, Pagination, Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);

const store = useNewsStore();
const isLoaded = ref(false);

const onSwiper = (swiper) => {
  let index = swiper.realIndex - 1;
  if (index > store.featuredNews.length - 1) {
    index = 0;
  }
  pushDataLayer(store.featuredNews[index]);
};

const pushDataLayer = (news) => {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Banners principales",
    event_label: "Carrusel",
    interaction: "True",
    component_name: "Click",
    element_category: news?.post_category?.name,
    element_text: news?.title,
  });
};

const getFeaturedNews = async () => {
  await store.actGetFeaturedNews();
};

onMounted(async () => {
  getFeaturedNews().then(() => {
    isLoaded.value = true;
  });
});
</script>

<template>
  <div>
    <skeleton-card-news-featured v-if="!isLoaded" />
    <div v-if="isLoaded">
      <swiper
        ref="swiper"
        :slides-per-view="1"
        :space-between="16"
        class="swiper-news"
        :pagination="{ clickable: true, $el: '.swiper-pagination' }"
        @slideChange="onSwiper"
        :autoplay="{ delay: 5000 }"
        noSwiping
        noSwipingClass="swiper-no-swiping"
      >
        <swiper-slide v-for="(news, idx) in store.featuredNews" :key="idx">
          <comp-general-featured-card
            :item="news"
            :category="news.post_category?.name"
            :title="news.title"
            :description="news.description"
            :link="news.call_to_action"
            :image="
              mobile() && news.image_mobile ? news.image_mobile : news.image
            "
            :category-for-social="news.post_category_id"
            category-for-datalayer="Home"
            action-for-datalayer="Banners principales"
            datalayer-active
            image-cover
            mobile-button
            @updatedComment="getFeaturedNews()"
            @updatedReaction="getFeaturedNews()"
          />
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<style lang="postcss">
.swiper-news .swiper-pagination {
  @apply tw-text-center md:tw-text-left lg:tw-w-[300px] tw-mt-1 -tw-bottom-1;
}
.swiper-news .swiper-pagination-bullets {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}
.swiper-news .swiper-pagination-bullets {
  .swiper-pagination-bullet-active {
    @apply tw-bg-red-400;
  }
}
</style>
