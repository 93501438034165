<script setup>
import Vue, { ref, computed, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router/composables";
import { useStorage } from "@vueuse/core";
import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { usePeopleTour } from "@/composables/peopleTour/usePeopleTour";
import { storeToRefs } from "pinia";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { isPastDate } from "@/helpers/datesHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompPeopleTourProgress from "@/components/peopleTour/CompPeopleTourProgress.vue";
import CompGeneralTopCardDropdown from "@/components/cards/CompGeneralTopCardDropdown.vue";
import CompPeopleTourActivitiesCard from "@/components/peopleTour/CompPeopleTourActivitiesCard.vue";
import CompGeneralShowMore from "@/components/general/CompGeneralShowMore.vue";
import SkeletonCompGeneralTopCardDropdown from "@/components/cards/skeletons/SkeletonCompGeneralTopCardDropdown.vue";
import SkeletonCompPeopleTourProgress from "@/components/peopleTour/skeletons/SkeletonCompPeopleTourProgress.vue";
import SkeletonCompPeopleTourActivities from "@/components/peopleTour/skeletons/SkeletonCompPeopleTourActivities.vue";
import CompPeopleTourGamificationPreview from "@/components/peopleTour/CompPeopleTourGamificationPreview.vue";

const router = useRouter();
const route = useRoute();
const peopleTourStore = usePeopleTourStore();
const isLoading = ref(false);
const activitiesVisible = ref(6);
const pendingActivities = ref(true);
const finishedActivities = ref(false);
const currentStageForRewards = ref(null);
const { currentStageData } = storeToRefs(peopleTourStore);
const user = useStorage("user", {});
const band = user.value.core_om_sideline?.name
  ? user.value.core_om_sideline?.name
  : user.value.core_pa_sideline?.name;
const { checkBP } = usePeopleTour();
const filteredActivities = computed(() => {
  let activities = peopleTourStore.currentStageActivitiesData;
  if (finishedActivities.value) {
    activities = activities.filter((activity) => {
      return (
        activity.field_status === "Aprobada" ||
        (isPastDate(activity.end_date) &&
          activity.field_status === "Rechazada") ||
        activity.field_status === "Finalizado" ||
        (isPastDate(activity.end_date) &&
          activity.field_status === "Sin realizar") ||
        (isPastDate(activity.end_date) && activity.field_status === "Pendiente")
      );
    });
  } else if (pendingActivities.value) {
    activities = activities.filter((activity) => {
      return (
        (activity.field_status === "Pendiente" ||
          activity.field_status === "Por hacer" ||
          activity.field_status === "Rechazada" ||
          !activity.field_status) &&
        !isPastDate(activity.end_date)
      );
    });
  }
  return activities;
});

const showMore = computed(() => {
  return activitiesVisible.value < filteredActivities.value.length;
});

const showMoreContent = () => {
  if (
    activitiesVisible.value < peopleTourStore.currentStageActivitiesData.length
  ) {
    activitiesVisible.value += 6;
    pushDataLayer({
      event_category: "Tour de gente",
      event_action: `${peopleTourStore.currentStageData.preTitle} ${peopleTourStore.currentStageData.title}`,
      event_label: "Cargar más actividades",
      component_name: "Click",
      element_category: "Tus actividades",
    });
  } else {
    activitiesVisible.value = 6;
    pushDataLayer({
      event_category: "Tour de gente",
      event_action: `${peopleTourStore.currentStageData.preTitle} ${peopleTourStore.currentStageData.title}`,
      event_label: "Cargar menos actividades",
      component_name: "Click",
      element_category: "Tus actividades",
    });
  }
};

const pendingActivitiesActive = () => {
  Vue.set(pendingActivities, "value", true);
  Vue.set(finishedActivities, "value", false);
  pushDataLayer({
    event_category: "Tour de gente",
    event_action: `${peopleTourStore.currentStageData.preTitle} ${peopleTourStore.currentStageData.title}`,
    event_label: "Actividades pendientes",
    component_name: "Click",
    element_category: "Tus actividades",
  });
};

const finishedActivitiesActive = () => {
  Vue.set(pendingActivities, "value", false);
  Vue.set(finishedActivities, "value", true);
  pushDataLayer({
    event_category: "Tour de gente",
    event_action: `${peopleTourStore.currentStageData.preTitle} ${peopleTourStore.currentStageData.title}`,
    event_label: "Actividades finalizadas",
    component_name: "Click",
    element_category: "Tus actividades",
  });
};

const goToActivityDetail = (activity) => {
  pushDataLayer({
    event_category: "Tour de gente",
    event_action: `${peopleTourStore.currentStageData.preTitle} ${peopleTourStore.currentStageData.title}`,
    event_label: activity.title,
    component_name: "Click",
    element_category: "Tus actividades",
    element_toins: `Toins: ${activity.gamification_points}`,
    element_state: activity.field_status,
  });
  router.push({
    name: "peopleTourActivityDetail",
    params: {
      id: activity.node_id,
      activity: activity,
    },
  });
};

onBeforeMount(async () => {
  isLoading.value = true;

  await checkBP(user.value.id_sharp);

  const response = await peopleTourStore.getCurrentStageData(
    route.params.id,
    user.value.id_sharp,
    band
  );

  if (!response) {
    await router.push({ name: "peopleTour" });
    return;
  }

  await peopleTourStore.getCurrentStageActivities(route.params.id);

  await peopleTourStore.getStagesList().then(() => {
    currentStageForRewards.value = peopleTourStore.stagesList.find(
      (stage) => stage.name === peopleTourStore.currentStageData.title
    );
  });

  if (
    currentStageForRewards.value !== undefined &&
    currentStageForRewards.value !== null
  ) {
    await peopleTourStore.getRewardsByStage(
      currentStageForRewards.value.id,
      user.value.id_sharp,
      band
    );
  }
  /*
  if (peopleTourStore.rewardsList.length === 0) {
    await peopleTourStore.getRewardsRandom(5);
  }
*/

  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Tour de Gente" />
    <title-bar title="Tour de Gente" />
    <aside
      class="tw-container-general"
      :class="$isMobile() ? 'tw-mt-0' : ''"
      v-if="isLoading"
    >
      <SkeletonCompGeneralTopCardDropdown />
      <SkeletonCompPeopleTourProgress />
      <SkeletonCompPeopleTourActivities />
    </aside>
    <main
      class="tw-container-general"
      :class="$isMobile() ? 'tw-mt-0' : ''"
      v-else
    >
      <section>
        <comp-general-top-card-dropdown
          :title="currentStageData.preTitle + ' ' + currentStageData.title"
          :image="currentStageData.image"
          :bg-color="currentStageData.color.color"
          title-mobile-down
        >
          <template #description>
            {{ currentStageData.description.value }}
          </template>
        </comp-general-top-card-dropdown>
        <comp-people-tour-progress
          :color="currentStageData.color.color"
          :points="currentStageData.points"
          :progress="currentStageData.progress"
          :type="currentStageData.type"
        />
      </section>
      <section class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
        <div
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-8"
        >
          <div
            class="lg:tw-col-span-1 tw-flex tw-items-center tw-justify-between"
          >
            <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-3xl">
              Tus actividades
            </p>
            <img src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
            class="tw-max-h-5 lg:tw-hidden" alt= />
          </div>
          <div
            class="lg:tw-col-span-2 tw-flex tw-items-center tw-justify-center lg:tw-justify-end tw-gap-4 lg:tw-gap-8 tw-select-none"
          >
            <div
              class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-pb-1"
              :class="
                pendingActivities
                  ? 'tw-border-red-350 tw-text-red-350 tw-border-b-4'
                  : 'tw-border-black'
              "
              @click="pendingActivitiesActive"
            >
              <div
                class="tw-border tw-rounded-full tw-p-1 tw-h-5 tw-w-5 lg:tw-h-9 lg:tw-w-9 tw-flex tw-items-center tw-justify-center"
              >
                <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-2xl">
                  {{ currentStageData.pending_activities }}
                </p>
              </div>
              <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-2xl">
                Actividades pendientes
              </p>
            </div>
            <div
              class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-pb-1"
              :class="
                finishedActivities
                  ? 'tw-border-red-350 tw-text-red-350 tw-border-b-4'
                  : 'tw-border-black'
              "
              @click="finishedActivitiesActive"
            >
              <div
                class="tw-border tw-rounded-full tw-p-1 tw-h-5 tw-w-5 lg:tw-h-9 lg:tw-w-9 tw-flex tw-items-center tw-justify-center"
              >
                <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-2xl">
                  {{ currentStageData.approved_activities }}
                </p>
              </div>
              <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-2xl">
                Actividades finalizadas
              </p>
            </div>
          </div>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-8"
        >
          <comp-people-tour-activities-card
            v-for="(activity, idx) in filteredActivities.slice(
              0,
              activitiesVisible
            )"
            :key="idx"
            :stage="currentStageData"
            :activity="activity"
            class="tw-cursor-pointer tw-flex tw-w-full"
            @click.native="goToActivityDetail(activity)"
          />
        </div>
        <div
          class="tw-rounded-2.5xl tw-border tw-w-full tw-p-5 lg:tw-p-10 tw-flex tw-flex-col tw-justify-between tw-gap-4 lg:tw-gap-8 tw-items-center lg:tw-items-start"
          :style="'border-color:' + currentStageData.color.color"
          v-if="pendingActivities && filteredActivities.length === 0"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-2xl">
            Ya se han finalizado todas las actividades de esta etapa, revisa el
            estado en "Actividades finalizadas".
          </p>
          <img
            src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
            class="tw-max-h-6 lg:tw-max-h-12 tw-w-max tw-ml-auto"
            alt=""
          />
        </div>
        <div
          class="tw-rounded-2.5xl tw-border tw-w-full tw-p-5 lg:tw-p-10 tw-flex tw-flex-col tw-justify-between tw-gap-4 lg:tw-gap-8 tw-items-center lg:tw-items-start"
          :style="'border-color:' + currentStageData.color.color"
          v-if="finishedActivities && filteredActivities.length === 0"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl"
            :style="'color:' + currentStageData.color.color"
          >
            Lo sentimos,
          </p>
          <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-2xl">
            Todavía no tienes ninguna actividad finalizada y aprobada por tu BP
          </p>
          <img
            src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
            class="tw-max-h-6 lg:tw-max-h-12 tw-w-max tw-ml-auto"
            alt=""
          />
        </div>
        <CompGeneralShowMore
          v-if="filteredActivities.length > 6"
          :show-more="showMore"
          show-more-text="Cargar más actividades"
          show-less-text="Cargar menos actividades"
          @showMoreContent="showMoreContent"
        />
        <comp-people-tour-gamification-preview
          :rewards="peopleTourStore.rewardsList"
          :stage-name="`${currentStageData.preTitle} ${currentStageData.title}`"
          v-if="peopleTourStore.rewardsList.length > 0"
          class="tw-mt-5"
        />
      </section>
    </main>
  </div>
</template>
