<script setup>
import { ref, onBeforeMount } from "vue";
// import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const isLoading = ref(false);
// const uriResources = config.beerOfficeURL;
const claimStep = ref(1);
const option = ref({});
const emit = defineEmits(["close"]);

const changeDeliverySite = (opt) => {
  option.value = opt;
};

const changeStep = () => {
  if (claimStep.value === 1) {
    pushDataLayer({
      event_category: "Tienda",
      event_action: "Lugar de entrega",
      event_label: option.value.name,
      component_name: "Click",
    });
    claimStep.value++;
  } else if (claimStep.value === 2) {
    claimStep.value--;
  }
};

const updateDeliverySite = () => {
  console.log(option.value.id);
  userStore
    .actDeliverySiteUpdate(userStore.user.id, {
      ecomm_delivery_site_id: option.value.id,
    })
    .then(() => {
      userStore.actGetUserById(userStore.user.id);
    })
    .then(() => {
      emit("close");
    });
};

onBeforeMount(() => {
  option.value = userStore.user.ecomm_delivery_site;
});
</script>
<template>
  <div
    class="tw-flex tw-flex-col tw-gap-y-4 tw-m-0 tw-h-full tw-min-h-full tw-justify-between"
  >
    <div id="header" class="tw-flex-1">
      <p class="tw-font-robotoBold tw-text-xl lg:tw-text-3xl tw-text-red-350">
        Lugar de entrega
      </p>
    </div>
    <!--    <div
      id="siteImg"
      class="tw-aspect-w-16 tw-aspect-h-10 lg:tw-aspect-w-16 lg:tw-aspect-h-8 tw-rounded-2.5xl tw-overflow-hidden"
    >
      <img
        v-if="option && option.img"
        :src="uriResources + '/' + option.img"
        alt=""
        class="tw-h-full tw-object-cover tw-select-none"
      />
      <img
        v-else
        src="../../assets/img/ecommerce/no-site-img.png"
        alt=""
        class="tw-h-full tw-object-cover tw-select-none"
      />
    </div>-->
    <p
      class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg"
      v-if="claimStep === 1"
    >
      Selecciona tu lugar de preferencia para recoger tus compras.
    </p>
    <div
      id="dropdown"
      class="tw-flex-1 tw-overflow-hidden"
      v-if="claimStep === 1"
    >
      <CompGeneralDropdown
        v-if="!isLoading"
        extends-container
        :fitted="false"
        :isLabel="false"
        :options="ecommerceStore.locations"
        :default-option="
          option && option.id
            ? option.id
            : userStore.user.ecomm_delivery_site_id
            ? userStore.user.ecomm_delivery_site_id
            : null
        "
        @changeSelectedOption="changeDeliverySite"
        @chargeOption="changeDeliverySite"
      />
    </div>
    <div id="confirmationText" class="tw-flex-1" v-else>
      <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg">
        Recuerda que el punto de entrega de tus productos es en
        <span class="tw-text-red-350 tw-font-robotoBold">{{
          option.name
        }}</span>
      </p>
    </div>
    <hr class="tw-w-full" />
    <div id="button" class="tw-flex-1 tw-flex tw-justify-between tw-gap-4">
      <button
        class="tw-btn-primary-red"
        v-if="claimStep === 1"
        @click="changeStep"
      >
        Guardar
      </button>
      <button
        class="tw-btn-secondary-white"
        v-if="claimStep === 2"
        @click="changeStep"
      >
        Cancelar
      </button>
      <button
        class="tw-btn-primary-red"
        v-if="claimStep === 2"
        @click="updateDeliverySite"
      >
        Continuar
      </button>
    </div>
  </div>
</template>
