import { render, staticRenderFns } from "./CompContestTriviaType.vue?vue&type=template&id=5e4f3900&scoped=true"
import script from "./CompContestTriviaType.vue?vue&type=script&setup=true&lang=js"
export * from "./CompContestTriviaType.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4f3900",
  null
  
)

export default component.exports