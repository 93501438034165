var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_setup.isLoading)?_c('div',{staticClass:"supportButton"},[_c('portal',{attrs:{"to":"all-modals"}},[(_setup.isSuggestionsModal)?_c(_setup.GeneralModal,{attrs:{"modalName":"suggestion","isShowModal":_setup.isSuggestionsModal,"modalWidth":{ md: 500, lg: 600, xl: 600 },"maxHeight":{ md: 500, lg: 600, xl: 700 },"padding-x":false},on:{"close":_setup.hideSuggestionsModal}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-3"},[_c('div',{staticClass:"tw-px-6"},[_c('p',{staticClass:"tw-font-robotoBold tw-text-lg lg:tw-text-2xl lg:tw-leading-7 tw-text-red-350 tw-m-0"},[_vm._v(" Buzón de Sugerencias ")])]),_c(_setup.Mailbox,{on:{"close":_setup.hideSuggestionsModal}})],1)]):_vm._e()],1),_c('div',{staticClass:"tw-fixed tw-right-6 tw-bottom-56 tw-z-40"},[(_setup.isHelpersModal)?_c('div',{ref:"target",staticClass:"tw-z-3 tw-absolute tw-bottom-0 tw-right-0 tw-flex tw-bg-white tw-rounded-3xl tw-p-4 lg:tw-w-96",staticStyle:{"max-height":"620px"},attrs:{"id":"modalHelpers"}},[_c(_setup.HelpersGrid,{attrs:{"route":_setup.route.path.replace('/', '')},on:{"close":_setup.hideHelpersModal,"datalayer":_setup.pushDataLayerSoporte}})],1):_vm._e(),_c('div',{staticClass:"tw-relative"},[(!_setup.isHelpersModal)?_c('div',{staticClass:"tw-z-2 tw-absolute tw-bottom-0 tw-right-0 tw-flex tw-flex-col tw-items-end",class:_setup.isHelpersModal
              ? 'tw-hidden'
              : _setup.isOpen
              ? 'tw-bg-white tw-rounded-3xl tw-p-5'
              : 'tw-bg-red-350 tw-rounded-full tw-p-1 tw-cursor-pointer',attrs:{"id":"popupDiv"}},[_c('div',{staticClass:"animateDiv",class:_setup.isOpen
                ? 'tw-block tw-w-full tw-h-full'
                : 'tw-w-0 tw-h-0 tw-overflow-hidden'},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-1 tw-pt-3"},[_c('div',[_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-8 tw-cursor-pointer",on:{"click":function($event){return _setup.pushLink('https://soporte.somosmaz.com/pqr/Embajadores')}}},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-base tw-whitespace-nowrap"},[_vm._v(" Soporte Simplifica ")]),_c('i',{staticClass:"icon-cebada tw-text-xl"})]),_c('hr',{staticClass:"tw-w-full"})]),(_setup.routeHasHelpers)?_c('div',[_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-8 tw-cursor-pointer",on:{"click":_setup.routeHelpers}},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-base tw-whitespace-nowrap"},[_vm._v(" Ayudas contextuales ")]),_c('i',{staticClass:"icon-cebada tw-text-xl"})]),_c('hr',{staticClass:"tw-w-full"})]):_vm._e(),_c('div',[_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-8 tw-cursor-pointer",on:{"click":_setup.suggestionsMailbox}},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-base tw-whitespace-nowrap"},[_vm._v(" Buzón de sugerencias ")]),_c('i',{staticClass:"icon-cebada tw-text-xl"})]),_c('hr',{staticClass:"tw-w-full"})])])]),_c('div',{staticClass:"tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-rounded-full",class:_setup.isOpen ? 'tw-bg-red-350' : 'tw-bg-white',on:{"click":_setup.toggleSupportModal}},[_c('i',{class:_setup.isOpen
                  ? 'icon-cerrar-simple tw-text-white tw-text-3xl lg:tw-text-4xl tw-cursor-pointer'
                  : 'icon-headphones tw-text-2xl lg:tw-text-3xl'})])]):_vm._e()]),_c('div',{staticClass:"tw-h-screen tw-fixed tw-bottom-0 tw-right-0",class:_setup.isOpen ? 'tw-w-screen backActive' : 'tw-w-0'})])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }