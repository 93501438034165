<script setup>
import { ref, onBeforeMount } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompDetailStatusOrderProduct from "@/components/ecommerce/CompDetailStatusOrderProduct";
import CompDetailOrder from "@/components/ecommerce/CompDetailOrder";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const actualOrder = ref({});
const isLoading = ref(false);
const breadcrumb = ref([
  {
    id: 1,
    name: "Home",
    link: "/home",
  },
  {
    id: 2,
    name: "Mundo Embajada",
    link: "/e-commerce",
  },
  {
    id: 3,
    name: "Pedidos",
    link: "/e-commerce/orderStatus",
  },
  {
    id: 4,
    name: "Historial de compras",
    link: "/cart/history",
    active: true,
  },
]);

const changeActualOrder = (indexOrder) => {
  actualOrder.value = ecommerceStore.ordersHistory[indexOrder];
};

onBeforeMount(async () => {
  isLoading.value = true;
  await ecommerceStore.actGetOrdersHistory(userStore.user.id).then(() => {
    actualOrder.value = ecommerceStore.ordersHistory[0];
    isLoading.value = false;
  });
});
</script>
<template>
  <div class="ml-0 ml-md-5 ml-xl-0 mb-3 mb-md-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 px-0 px-md-3">
          <SecondaryNavbar
            title="Historial de compras"
            :breadcrumb="breadcrumb"
          />
        </div>
      </div>
      <div class="row" v-if="isLoading">
        <div class="col-12">
          <div class="text-center mt-3">
            <img src="../../assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row mt-5" v-if="ecommerceStore.ordersHistory.length === 0">
          <div class="col-8 col-md-6 text-center mx-auto">
            <img
              class="d-none d-md-block tw-w-100"
              src="@/assets/img/ecommerce/no-orders-1.png"
              alt="no hay ordenes"
            />
            <img
              class="d-block d-md-none tw-w-100"
              src="@/assets/img/ecommerce/no-orders-mobile.png"
              alt="no hay ordenes"
            />
          </div>
          <div class="col-12 mt-4">
            <p
              class="tw-text-red-400 font-weight-bold text-uppercase text-center fs-30 fs-md-40 tw-font-tolyerBold"
            >
              Aún no has comprado nada, ¿qué estás esperando?
            </p>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12 col-lg-6">
            <div class="cont-cards mb-3 max-he-400 overflow-auto px-2 pt-2">
              <div
                class="mb-4"
                v-for="(item, index) in ecommerceStore.ordersHistory"
                :key="item.id"
              >
                <div
                  class="tw-shadow-sm hover:tw-shadow-md tw-rounded-xl p-4 bg-white z-index-2 position-relative"
                >
                  <CompDetailStatusOrderProduct
                    @clickOrder="changeActualOrder"
                    :countOrders="ecommerceStore.ordersHistory.length"
                    :item="item"
                    :index="index"
                  />
                </div>
                <CompDetailOrder
                  :order="item"
                  :viewtitle="false"
                  :viewButtons="false"
                  class="tw-z-1 d-block d-md-none"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 pt-2">
            <CompDetailOrder
              :order="actualOrder"
              :viewtitle="true"
              :viewButtons="false"
              class="d-none d-md-block"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
