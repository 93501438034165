var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{attrs:{"active-nav-item-class":"tw-text-red-200","active-tab-class":"tw-text-gray-600","content-class":"mt-n0","fill":""}},[_c('b-tab',{attrs:{"title":"País","active":""}},[_c('div',{staticClass:"tw-bg-red-400 px-3 py-5"},[_c('p',{staticClass:"tw-text-white fs-30 text-center mb-0"},[_vm._v("Ranking País")])]),_c('div',{staticClass:"tw-bg-white tw-rounded-lg p-3 mt-n2 tw-shadow-sm position-relative overflow-auto he-390"},[_c('table',{staticClass:"w-100 table-1"},[_c('tr',[_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs text-center"},[_vm._v(" Posición ")])]),_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs text-center"},[_vm._v(" Gerencia ")])]),_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs text-center"},[_vm._v("Zona")])]),_c('th',{staticClass:"pb-1 tw-w-24"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs text-center"},[_vm._v("Pago")])])]),_vm._l((_vm.tabsData.country),function(item){return _c('tr',{key:'country' + item.id,staticClass:"tw-rounded-lg",class:item.select
              ? 'tw-bg-red-300 tw-text-white'
              : 'tw-bg-gray-200 tw-text-gray-400'},[_c('td',{staticClass:"py-2 px-3 text-center"},[_c('p',{staticClass:"font-weight-bold tw-text-lg mb-0"},[_vm._v(" "+_vm._s(item.ranking_score_country)+" ")])]),_c('td',{staticClass:"py-2 px-3"},[_c('p',{staticClass:"tw-text-xs mb-0"},[_vm._v(_vm._s(item.remu_manager_id))])]),_c('td',{staticClass:"py-2 px-3"},[_c('p',{staticClass:"tw-text-xs mb-0"},[_vm._v(" "+_vm._s(item.remu_zone_id == null ? "N/A" : item.remu_zone_id)+" ")])]),_c('td',{staticClass:"py-2 px-3"},[_c('div',{staticClass:"tw-bg-white p-3 tw-rounded-lg tw-shadow-sm"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 mb-0 font-weight-bold tw-text-base text-center"},[_vm._v(" "+_vm._s(item.payment)+"% ")])])])])})],2),_c('div',{staticClass:"wi-30 he-30 tw-rounded-full tw-bg-red-400 position-absolute bottom-n10 d-none justify-content-center align-items-center tw-left-1/2 ml-n3 hover:tw-bg-red-600",attrs:{"role":"button"}},[_c('i',{staticClass:"icon-arrow-down tw-text-lg tw-text-white"})])])]),_c('b-tab',{attrs:{"title":"Regional"}},[_c('div',{staticClass:"tw-bg-red-400 px-3 py-5"},[_c('p',{staticClass:"tw-text-white fs-30 text-center mb-0"},[_vm._v("Ranking Regional")])]),_c('div',{staticClass:"tw-bg-white tw-rounded-lg p-3 mt-n2 tw-shadow-sm position-relative he-350 overflow-auto"},[_c('table',{staticClass:"w-100 table-1"},[_c('tr',[_c('th',{staticClass:"pb-1 tw-w-14"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Posición")])]),_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Gerencia")])]),_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Zona")])]),_c('th',{staticClass:"pb-1 tw-w-24"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Pago")])])]),_vm._l((_vm.tabsData.regional),function(item){return _c('tr',{key:'country' + item.id,staticClass:"tw-rounded-lg",class:item.select
              ? 'tw-bg-red-300 tw-text-white'
              : 'tw-bg-gray-200 tw-text-gray-400'},[_c('td',{staticClass:"py-2 px-3 text-center"},[_c('p',{staticClass:"font-weight-bold tw-text-lg mb-0"},[_vm._v(" "+_vm._s(item.ranking_score_regional)+" ")])]),_c('td',{staticClass:"py-2 px-3"},[_c('p',{staticClass:"tw-text-xs mb-0"},[_vm._v(_vm._s(item.remu_manager_id))])]),_c('td',{staticClass:"py-2 px-3"},[_c('p',{staticClass:"tw-text-xs mb-0"},[_vm._v(" "+_vm._s(item.remu_zone_id == null ? "N/A" : item.remu_zone_id)+" ")])]),_c('td',{staticClass:"py-2 px-3"},[_c('div',{staticClass:"tw-bg-white p-3 tw-rounded-lg tw-shadow-sm"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 mb-0 font-weight-bold tw-text-base text-center"},[_vm._v(" "+_vm._s(item.payment)+"% ")])])])])})],2),_c('div',{staticClass:"wi-30 he-30 tw-rounded-full tw-bg-red-400 position-absolute bottom-n10 d-none justify-content-center align-items-center tw-left-1/2 ml-n3 hover:tw-bg-red-600",attrs:{"role":"button"}},[_c('i',{staticClass:"icon-arrow-down tw-text-lg tw-text-white"})])])]),_c('b-tab',{attrs:{"title":"Gerencia"}},[_c('div',{staticClass:"tw-bg-red-400 px-3 py-5"},[_c('p',{staticClass:"tw-text-white fs-30 text-center mb-0"},[_vm._v("Ranking Gerencia")])]),_c('div',{staticClass:"tw-bg-white tw-rounded-lg p-3 mt-n2 tw-shadow-sm position-relative h-350 overflow-auto"},[_c('table',{staticClass:"w-100 table-1"},[_c('tr',[_c('th',{staticClass:"pb-1 tw-w-14"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Posición")])]),_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Gerencia")])]),_c('th',{staticClass:"pb-1"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Zona")])]),_c('th',{staticClass:"pb-1 tw-w-24"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xs"},[_vm._v("Pago")])])]),_vm._l((_vm.tabsData.management),function(item){return _c('tr',{key:'country' + item.id,staticClass:"tw-rounded-lg",class:item.select
              ? 'tw-bg-red-300 tw-text-white'
              : 'tw-bg-gray-200 tw-text-gray-400'},[_c('td',{staticClass:"py-2 px-3 text-center"},[_c('p',{staticClass:"font-weight-bold tw-text-lg mb-0"},[_vm._v(" "+_vm._s(item.ranking_score_manager)+" ")])]),_c('td',{staticClass:"py-2 px-3"},[_c('p',{staticClass:"tw-text-xs mb-0"},[_vm._v(_vm._s(item.remu_manager_id))])]),_c('td',{staticClass:"py-2 px-3"},[_c('p',{staticClass:"tw-text-xs mb-0"},[_vm._v(" "+_vm._s(item.remu_zone_id == null ? "N/A" : item.remu_zone_id)+" ")])]),_c('td',{staticClass:"py-2 px-3"},[_c('div',{staticClass:"tw-bg-white p-3 tw-rounded-lg tw-shadow-sm"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 mb-0 font-weight-bold tw-text-base text-center"},[_vm._v(" "+_vm._s(item.payment)+"% ")])])])])})],2),_c('div',{staticClass:"wi-30 he-30 tw-rounded-full tw-bg-red-400 position-absolute bottom-n10 d-none justify-content-center align-items-center tw-left-1/2 ml-n3 hover:tw-bg-red-600",attrs:{"role":"button"}},[_c('i',{staticClass:"icon-arrow-down tw-text-lg tw-text-white"})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }