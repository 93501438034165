import { render, staticRenderFns } from "./CompProfileBasicInformationCard.vue?vue&type=template&id=6725b954&scoped=true"
import script from "./CompProfileBasicInformationCard.vue?vue&type=script&setup=true&lang=js"
export * from "./CompProfileBasicInformationCard.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6725b954",
  null
  
)

export default component.exports