<script setup>
import { computed } from "vue";
import {
  todayIsBetweenDates,
  isPastDate,
  isFutureDate,
} from "@/helpers/datesHelpers";
import { pushLink } from "@/helpers/linkHelpers";

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const isActive = computed(() =>
  todayIsBetweenDates(props.item.start_date, props.item.end_date)
);

const isPast = computed(() => isPastDate(props.item.end_date));

const isFuture = computed(() => isFutureDate(props.item.start_date));
</script>

<template>
  <div
    class="tw-rounded-2xl tw-border tw-grid tw-grid-rows-rows_3_fit_first_and_last tw-overflow-hidden"
    :style="'border-color:' + props.item.color.color"
  >
    <div
      class="tw-flex tw-flex-col tw-gap-1 tw-py-2 tw-px-3"
      :style="'background:' + props.item.color.color"
    >
      <p
        class="tw-m-0 tw-font-robotoMedium tw-text-white tw-text-xs tw-leading-3 lg:tw-text-lg lg:tw-leading-4"
      >
        {{ props.item.preTitle }}
      </p>
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-white tw-text-sm tw-leading-4 lg:tw-text-xl lg:tw-leading-6 tw-line-clamp-1"
      >
        {{ props.item.title }}
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-white tw-text-xxs lg:tw-text-xs">
        {{ props.item.dates }}
      </p>
    </div>
    <div class="tw-p-2 lg:tw-p-3 tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
      <div class="tw-grid tw-grid-cols-2 tw-gap-3 lg:tw-gap-6">
        <div class="tw-flex tw-justify-center tw-items-center">
          <img :src="props.item.image" class="tw-max-w-[90px]" alt="" />
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl"
            :style="'color:' + props.item.color.color"
          >
            {{ props.item.pending_activities }}
          </p>
          <p
            class="tw-m-0 tw-font-robotoMedium tw-text-xxs lg:tw-text-sm tw-text-center"
          >
            Actividades pendientes
          </p>
        </div>
      </div>
      <div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-3 lg:tw-gap-6">
          <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl"
              :style="'color:' + props.item.color.color"
            >
              {{ props.item.progress.toFixed(1) + "%" }}
            </p>
            <p
              class="tw-m-0 tw-font-robotoMedium tw-text-xxs lg:tw-text-sm tw-text-center"
            >
              Tu porcentaje
            </p>
          </div>
          <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl"
              :style="'color:' + props.item.color.color"
            >
              {{ props.item.points }}
            </p>
            <p
              class="tw-m-0 tw-font-robotoMedium tw-text-xxs lg:tw-text-sm tw-text-center"
            >
              Tu puntaje
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-p-2 lg:tw-p-3">
      <div
        class="tw-btn-general-shape"
        :style="
          isActive || isPast
            ? 'background:' + props.item.color.color
            : 'background: #C4C4C4'
        "
        @click="
          isActive || isPast
            ? pushLink(`/peopleTour/${props.item.node_id}/detail`, {
                event_category: 'Tour de Gente',
                event_action:
                  props.item.type === 'stage'
                    ? 'Tus etapas del tour'
                    : 'Tus estaciones de líder',
                event_label: `${props.item.preTitle} ${props.item.title}`,
                component_name: 'Click',
                element_category: isPast
                  ? 'Finalizada'
                  : 'Ir a tus actividades',
              })
            : null
        "
      >
        <p class="tw-m-0 tw-text-white">
          {{
            isFuture
              ? "Próximamente"
              : isPast
              ? "Finalizado"
              : "Ir a tus actividades"
          }}
        </p>
      </div>
    </div>
  </div>
</template>
