<script setup>
import { onBeforeMount, ref, computed, getCurrentInstance } from "vue";
import { useRoute } from "vue-router/composables";
import { useBenefitsAgreementsStore } from "@/stores/benefitsAgreementsStore";

import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCard from "@/components/cards/CompGeneralTopCard.vue";
import CompGeneralArticleContents from "@/components/general/CompGeneralArticleContents.vue";
import CompSocialNetworkInteractionsBig from "@/components/social-network/CompSocialNetworkInteractionsBig.vue";
import CompGeneralGridCard from "@/components/general/CompGeneralGridCard.vue";

const route = useRoute();
const benefitAgreementsStore = useBenefitsAgreementsStore();
const isLoading = ref(false);
const isHovering = ref(false);
const itemId = ref(route.params.id);
const { $cloneArray: cloneArray } = getCurrentInstance().proxy;
const { $isMobile: mobile } = getCurrentInstance().proxy;

async function getBenefitDetail() {
  await benefitAgreementsStore.actGetBenefitDetail(itemId.value);
}

async function updateRelatedBenefits() {
  await benefitAgreementsStore.actGetBenefits(
    benefitAgreementsStore.benefitDetail?.ben_benefit_category_id
  );
}

const relatedBenefits = computed(() => {
  if (benefitAgreementsStore.benefits.length === 0) {
    benefitAgreementsStore.actGetBenefits(
      benefitAgreementsStore.benefitDetail?.ben_benefit_category_id
    );
  }
  let relatedBenefits = cloneArray(benefitAgreementsStore.benefits);
  return relatedBenefits.sort(() => Math.random() - 0.5).slice(0, 3);
});

onBeforeMount(async () => {
  isLoading.value = true;
  await getBenefitDetail();
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Beneficios" v-if="!isLoading" />
    <title-bar title="Beneficios" />
    <div class="tw-container-general" v-if="!isLoading">
      <div class="tw-my-6">
        <comp-general-top-card
          :image="benefitAgreementsStore.benefitDetail.main_image"
          :title="benefitAgreementsStore.benefitDetail.name"
          :description="benefitAgreementsStore.benefitDetail.description"
        />
        <hr class="tw-w-full tw-bg-gray-250 tw-my-10" />
      </div>
      <div class="tw-my-6">
        <comp-general-article-contents
          v-if="
            benefitAgreementsStore.benefitDetail?.content !== undefined &&
            benefitAgreementsStore.benefitDetail?.content != null &&
            benefitAgreementsStore.benefitDetail?.content.length > 0
          "
          :content="benefitAgreementsStore.benefitDetail?.content"
          :article-name="benefitAgreementsStore.benefitDetail?.name"
          category-for-datalayer="Beneficios"
        />
      </div>
      <div class="tw-my-6">
        <comp-social-network-interactions-big
          :id="benefitAgreementsStore.benefitDetail.id"
          :title="benefitAgreementsStore.benefitDetail.name"
          category="BENEFITS"
          :user_reaction="benefitAgreementsStore.benefitDetail.user_reaction"
          :user_score_review="
            benefitAgreementsStore.benefitDetail.user_score_review
          "
          :amount_comments="
            benefitAgreementsStore.benefitDetail.amount_comments
          "
          :amount_reactions="
            benefitAgreementsStore.benefitDetail.amount_reactions
          "
          :image="benefitAgreementsStore.benefitDetail.main_image"
          :description="benefitAgreementsStore.benefitDetail.description"
          :item="benefitAgreementsStore.benefitDetail"
          :hover="isHovering"
          category-for-datalayer="Beneficios"
          @updatedComment="getBenefitDetail"
          @updatedReaction="getBenefitDetail"
        />
      </div>
      <div class="tw-my-5" id="Related">
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-4xl tw-font-robotoBold"
        >
          Otros beneficios que tenemos para ti
        </p>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8 tw-mb-4 md:tw-mb-8"
      >
        <div
          v-for="(item, idx) in relatedBenefits"
          :key="idx"
          class="lg:tw-flex lg:tw-flex-col"
        >
          <comp-general-grid-card
            :id="item.id"
            :item="item?.ben_detail"
            :category="item?.ben_detail?.ben_benefit_category?.category"
            category-for-social="BENEFITS"
            :title="item?.ben_detail?.name"
            :description="item?.ben_detail?.description"
            :link="`/benefits/${item?.ben_detail?.id}`"
            :image="
              mobile()
                ? item?.ben_detail?.banner_image
                : item?.ben_detail?.main_image
            "
            category-for-datalayer="Beneficios"
            action-for-datalayer="Otros beneficios que tenemos para ti"
            @updatedComment="updateRelatedBenefits"
            @updatedReaction="updateRelatedBenefits"
          />
          <hr class="md:tw-hidden" />
        </div>
      </div>
    </div>
  </div>
</template>
