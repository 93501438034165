var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 mx-3 min-wi-164"},[_c('div',{staticClass:"tw-shadow-sm tw-rounded-lg tw-bg-white"},[_c('div',{staticClass:"tw-bg-brown-400 text-center py-3 px-3 tw-rounded-tl-lg tw-rounded-tr-lg he-60"},[_c('p',{staticClass:"mb-0 tw-text-white tw-text-xs"},[_vm._v(" "+_vm._s(_vm.metric.name)+" ")])]),_c('div',{staticClass:"tw-bg-white tw-rounded-lg"},[_c('div',{staticClass:"d-flex justify-content-around my-3 align-items-center he-50"},[_c('p',{staticClass:"tw-text-red-400 tw-text-xl font-weight-bold mb-0"},[_vm._v(" "+_vm._s(Number((_vm.metric.result / _vm.metric.target) * 100).toFixed(2))+"% ")]),_c('img',{attrs:{"src":_vm.imageUrl,"alt":"beer 1","width":"45"}})]),_c('div',{staticClass:"text-center mb-3"},[_c('p',{staticClass:"mb-0 tw-text-red-400 tw-text-xs font-weight-bold"},[_vm._v(" Total pago: "),_c('span',{staticClass:"tw-text-gray-600"},[_vm._v("$"+_vm._s(_vm.formatNumber(_vm.metric.partial_salary, 0)))])])]),_c('table',{staticClass:"w-100"},[_c('tr',{staticClass:"tw-bg-gray-200 text-center"},[_vm._m(0),_c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-400 mb-0 tw-text-xs"},[_vm._v(" "+_vm._s((_vm.metric.cump_payment_max * 100).toFixed(0))+"% ")])]),_c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-600 mb-0 tw-text-xs"},[_vm._v(" "+_vm._s((Number(_vm.metric.target) * _vm.metric.cump_payment_max) .toFixed(0) .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, }))+_vm._s(_vm.title == "Volumen" ? ".hl" : "")+" ")])]),_c('td',{staticClass:"py-2"},[(
                Number(_vm.metric.fulfilment).toFixed(2) >=
                _vm.metric.cump_payment_max
              )?_c('div',{staticClass:"wi-10 he-10 tw-rounded-full tw-bg-green-400 d-flex justify-content-center align-items-center"},[_c('i',{staticClass:"tw-text-white tw-text-xxxs icon-check-simple"})]):_c('div',{staticClass:"wi-10 he-10 tw-rounded-full tw-bg-red-400 d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"tw-text-white tw-text-xs"},[_vm._v("-")])])])]),_c('tr',{staticClass:"tw-bg-gray-100 text-center"},[_vm._m(1),_vm._m(2),_c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-600 mb-0 tw-text-xxs"},[_vm._v(" "+_vm._s(Number(_vm.metric.target).toFixed(0))+_vm._s(_vm.title == "Volumen" ? ".hl" : "")+" ")])]),_c('td',{staticClass:"py-2"},[(Number(_vm.metric.fulfilment).toFixed(2) >= 1)?_c('div',{staticClass:"wi-10 he-10 tw-rounded-full tw-bg-green-400 d-flex justify-content-center align-items-center"},[_c('i',{staticClass:"tw-text-white tw-text-xxxs icon-check-simple"})]):_c('div',{staticClass:"wi-10 he-10 tw-rounded-full tw-bg-red-400 d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"tw-text-white tw-text-xs"},[_vm._v("-")])])])]),_c('tr',{staticClass:"tw-bg-gray-200 text-center"},[_vm._m(3),_c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-400 mb-0 tw-text-xs"},[_vm._v(" "+_vm._s((_vm.metric.cump_payment_min * 100).toFixed(0))+"% ")])]),_c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-600 mb-0 tw-text-xs"},[_vm._v(" "+_vm._s((Number(_vm.metric.target) * _vm.metric.cump_payment_min) .toFixed(0) .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, }))+_vm._s(_vm.title == "Volumen" ? ".hl" : "")+" ")])]),_c('td',{staticClass:"py-2"},[(
                Number(_vm.metric.fulfilment).toFixed(2) >=
                _vm.metric.cump_payment_min
              )?_c('div',{staticClass:"wi-10 he-10 tw-rounded-full tw-bg-green-400 d-flex justify-content-center align-items-center"},[_c('i',{staticClass:"tw-text-white tw-text-xxxs icon-check-simple"})]):_c('div',{staticClass:"wi-10 he-10 tw-rounded-full tw-bg-red-400 d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"tw-text-white tw-text-xs"},[_vm._v("-")])])])])])])]),_c('div',{staticClass:"tw-bg-white tw-shadow-md tw-rounded-lg mt-4"},[_c('div',{staticClass:"tw-bg-gray-200 py-2 tw-rounded-tl-lg tw-rounded-tr-lg d-flex justify-content-between px-3"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xxxs font-weight-bold"},[_vm._v("Proyecta tu KPI")]),_c('p',{staticClass:"tw-text-xxs tw-text-gray-400 mb-0 font-weight-bold"},[_vm._v(" Peso: "+_vm._s(_vm.weigthKpi)+"% ")])]),_c('div',{staticClass:"tw-bg-white p-3 tw-rounded-lg"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',{staticClass:"tw-text-gray-600 tw-text-xxs mb-2 font-weight-bold"},[_vm._v("Resultado")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.percentValue),expression:"percentValue"}],staticClass:"tw-shadow-sm tw-border-0 wi-80 tw-rounded-lg outline-none px-2 py-1 tw-text-xxs",attrs:{"type":"text"},domProps:{"value":(_vm.percentValue)},on:{"input":function($event){if($event.target.composing)return;_vm.percentValue=$event.target.value}}})]),_c('div',[_c('p',{staticClass:"tw-text-gray-600 tw-text-xxs mb-2 font-weight-bold"},[_vm._v("Cump.")]),_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xxs pt-2"},[_vm._v(" = "+_vm._s(_vm.calcFullfilment)+"% ")])])]),_c('div',{staticClass:"d-flex mt-2"},[_c('p',{staticClass:"mb-0 tw-text-gray-600 tw-text-xxs mr-3"},[_vm._v("Subtotal:")]),_c('div',{staticClass:"w-100 tw-border-b tw-border-solid tw-border-gray-300"},[_c('p',{staticClass:"mb-0 tw-text-green-400 tw-text-xs text-right"},[_vm._v(" $ "+_vm._s(_vm.formatNumber(_vm.calcSubtotal))+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-400 mb-0 tw-text-xs"},[_vm._v("Máximo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-400 mb-0 tw-text-xxs"},[_vm._v("Meta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-400 mb-0 tw-text-xxs"},[_vm._v("100%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"py-2"},[_c('p',{staticClass:"tw-text-gray-400 mb-0 tw-text-xs"},[_vm._v("Mínimo")])])
}]

export { render, staticRenderFns }