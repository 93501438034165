var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{attrs:{"id":"bv-modal-contact","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return undefined},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[(_vm.actualTypeContact === 'favorite')?_c('div',[_c('img',{staticClass:"rounded-circle mb-3 img-profile",attrs:{"src":_setup.props.modalContact.core_employee.prof_picture == null
            ? _setup.imgAvatar
            : _setup.uriResources +
              '/' +
              _setup.props.modalContact.core_employee.prof_picture,"alt":"user","width":"120","height":"120"}})]):_c('div',[_c('img',{staticClass:"rounded-circle mb-3 img-profile",attrs:{"src":_setup.props.modalContact.prof_picture == null
            ? _setup.imgAvatar
            : _setup.uriResources + '/' + _setup.props.modalContact.prof_picture,"alt":"user","width":"120","height":"120"}})]),_c('div',{staticClass:"cont-name"},[_c('p',{staticClass:"name mb-0 text-capitalize"},[_vm._v(" "+_vm._s(_vm.actualTypeContact === "favorite" ? _setup.props.modalContact.core_employee.name : _setup.props.modalContact.name)+" ")]),(_vm.actualTypeContact === 'favorite')?_c('p',{staticClass:"profile modal-profile-name"},[_vm._v(" "+_vm._s(_setup.props.modalContact.core_employee.core_position.name)+" ")]):_c('p',{staticClass:"profile modal-profile-name"},[_vm._v(" "+_vm._s(_setup.props.modalContact.position)+" ")])]),(_vm.actualTypeContact === 'main')?_c('div',{staticClass:"cont-1"},[_c('p',{staticClass:"label mb-0"},[_vm._v("Tipo de usuario")]),_c('p',{staticClass:"txt"},[_vm._v(_vm._s(_setup.props.modalContact.core_regional.name))])]):_vm._e(),_c('div',{staticClass:"cont-1"},[_c('p',{staticClass:"label mb-0"},[_vm._v("Correo")]),_c('p',{staticClass:"txt text-uppercase"},[_vm._v(" "+_vm._s(_vm.actualTypeContact === "favorite" ? _setup.props.modalContact.core_employee.email : _setup.props.modalContact.email)+" ")])]),(_vm.actualTypeContact === 'favorite')?_c('div',{staticClass:"cont-btn mt-3"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _setup.deleteContact(_vm.modalContact)}}},[_vm._v(" Quitar ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }