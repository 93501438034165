<script setup>
import useHover from "@/composables/general/useHover";

const { isHovering, mouseEnter, mouseLeave } = useHover();
</script>

<template>
  <section id="card" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <div class="tw-grid tw-grid-cols-1 tw-gap-3 lg:tw-gap-4">
      <slot name="image" />
      <slot name="category" />
      <slot name="titleAndDescription" />
      <slot name="callToAction" :hover="isHovering" />
    </div>
  </section>
</template>

<style scoped></style>
