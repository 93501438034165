<template>
  <div>
    <!-- Título Desktop y leyenda -->
    <div
      class="tw-container tw-mx-auto tw-max-w-screen-xl d-flex flex-md-row flex-column mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0">
        <div class="d-none d-sm-none d-md-flex">
          <p
            class="tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
          >
            La Maltería
          </p>
        </div>
        <div class="d-none d-md-flex">
          <div class="px-3 p-md-0">
            <p
              class="tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"
            >
              Completa misiones, gana puntos y redime premios
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Contenedor -->
    <div
      class="tw-container tw-mx-auto tw-max-w-screen-xl tw-mb-8 tw-p-5 lg:tw-p-0"
    >
      <div class="malts-grid">
        <div
          class="general tw-rounded-xl md:tw-rounded-3xl tw-shadow tw-shadow-md-lg overflow-hidden"
        >
          <div class="backgrounddiv"></div>
          <div
            class="p-3 px-md-4 pt-md-4 pb-md-3 d-flex flex-md-row flex-column justify-content-md-between"
          >
            <div class="m-0 p-0">
              <p
                class="tw-font-robotoBold tw-text-xs fs-md-18 tw-text-gray-600 m-0 name"
              >
                {{ user.name.split(" ")[0].toLowerCase() }}, tienes
              </p>
              <p
                class="tw-font-tolyerBold fs-40 fs-md-60 line-height-46 line-height-md-60 tw-text-red-400 tw-m-0"
              >
                {{
                  Number(user.gamification_maltas)
                    .toLocaleString()
                    .replace(",", ".")
                }}
              </p>
            </div>
            <div
              class="d-flex flex-md-column justify-content-end justify-content-md-center align-items-center"
            >
              <i
                class="icon-maltas fs-30 fs-md-50 d-inline-block ml-0 my-0 mr-2 tw-text-red-400"
              ></i>
              <p
                class="tw-font-robotoBold tw-text-xs fs-md-18 line-height-md-20 tw-text-red-400 m-0"
              >
                Maltas
              </p>
            </div>
          </div>
        </div>
        <div
          class="transfer tw-rounded-xl md:tw-rounded-3xl tw-shadow tw-shadow-md-lg p-4 d-flex flex-column justify-content-between"
        >
          <div class="d-flex flex-column justify-content-between">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0 tw-font-tolyerBold fs-22 fs-md-30">
                  Transfiere tus maltas
                </p>
                <p
                  class="m-0 tw-font-robotoBold tw-text-xxs fs-md-14 line-height-12 line-height-md-16 text-underline tw-cursor-pointer"
                  @click="$router.push({ name: 'gamificationMaltsHistory' })"
                >
                  <u>Historial</u>
                </p>
              </div>
              <div
                class="text-center mt-md-3 px-4 d-flex align-items-center justify-content-between slider-container mb-md-2"
              >
                <div class="slider-item wi-70 wi-md-100">
                  <div
                    class="wi-24 he-24 wi-md-30 he-md-30 tw-rounded-full d-inline-flex justify-content-center align-items-center"
                    :style="
                      step === 1 ? 'background: rgba(0, 191, 121, 0.3);' : ''
                    "
                  >
                    <div
                      class="wi-12 he-12 wi-md-20 he-md-20 tw-rounded-full d-inline-block"
                      :class="step === 1 ? 'tw-bg-green-700' : 'tw-bg-gray-300'"
                    ></div>
                  </div>
                  <p class="m-0 tw-font-roboto tw-text-xxs fs-md-12 mt-2">
                    Selecciona <br />
                    cantidad
                  </p>
                </div>
                <div class="slider-item wi-70 wi-md-100">
                  <div
                    class="wi-24 he-24 wi-md-30 he-md-30 tw-rounded-full d-inline-flex justify-content-center align-items-center"
                    :style="
                      step === 2 ? 'background: rgba(0, 191, 121, 0.3);' : ''
                    "
                  >
                    <div
                      class="wi-12 he-12 wi-md-20 he-md-20 tw-rounded-full d-inline-block"
                      :class="step === 2 ? 'tw-bg-green-700' : 'tw-bg-gray-300'"
                    ></div>
                  </div>
                  <p class="m-0 tw-font-roboto tw-text-xxs fs-md-12 mt-2">
                    Selecciona <br />
                    destino
                  </p>
                </div>
                <div class="slider-item wi-70 wi-md-100">
                  <div
                    class="wi-24 he-24 wi-md-30 he-md-30 tw-rounded-full d-inline-flex justify-content-center align-items-center"
                    :style="
                      step === 3 ? 'background: rgba(0, 191, 121, 0.3);' : ''
                    "
                  >
                    <div
                      class="wi-12 he-12 wi-md-20 he-md-20 tw-rounded-full d-inline-block"
                      :class="step === 3 ? 'tw-bg-green-700' : 'tw-bg-gray-300'"
                    ></div>
                  </div>
                  <p class="m-0 tw-font-roboto tw-text-xxs fs-md-12 mt-2">
                    Confirma <br />
                    transferencia
                  </p>
                </div>
              </div>
            </div>

            <div class="min-he-md-265">
              <div
                class="md:tw-shadow-md tw-rounded-lg px-4 py-2 p-md-4 h-100"
                id="transfer-first"
                v-if="step === 1"
              >
                <p
                  class="m-auto tw-font-robotoBold tw-text-xxs fs-md-14 line-height-14 line-height-md-24 tw-text-gray-600"
                >
                  ¿Cuántas Maltas quieres enviar a tu cuenta Tap It?
                </p>
                <div
                  class="mt-md-4 mt-3 mb-1 mb-md-2 d-flex justify-content-between"
                >
                  <p
                    class="m-0 tw-font-roboto tw-text-xxs fs-md-12 line-height-12 line-height-md-14 tw-text-gray-600"
                  >
                    Tu envías
                  </p>
                  <p
                    class="m-0 tw-font-roboto tw-text-xxs fs-md-12 line-height-12 line-height-md-14 tw-text-red-400"
                    v-show="user.gamification_maltas < maltsToSend"
                  >
                    No tienes maltas suficientes para está transferencia*
                  </p>
                </div>
                <div
                  class="tw-rounded-md tw-rounded-lg-sm tw-shadow-md px-3 py-1 py-md-2 inputarea"
                  :class="
                    errorStatus($v.maltsToSend) && $v.maltsToSend.$model > 0
                      ? 'outline-red'
                      : ''
                  "
                >
                  <div class="d-flex align-items-center">
                    <input
                      type="text"
                      class="w-100 tw-border-0 h-100"
                      v-model="$v.maltsToSend.$model"
                    />
                  </div>

                  <div class="d-flex align-items-center justify-content-around">
                    <div class="vl mx-2"></div>
                    <p
                      class="m-0 tw-font-robotoBold tw-text-xxs fs-md-14 line-height-10 line-height-md-16 tw-text-yellow-400"
                    >
                      Maltas
                    </p>
                    <i
                      class="icon-maltas tw-text-base fs-md-20 d-inline-block tw-text-yellow-400"
                    ></i>
                  </div>
                </div>
                <div class="mt-md-4 mt-3 mb-1 mb-md-2">
                  <p
                    class="m-auto tw-font-roboto tw-text-xxs fs-md-12 line-height-12 line-height-md-14 tw-text-gray-600"
                  >
                    Tu recibes
                  </p>
                </div>
                <div
                  class="tw-rounded-md tw-rounded-lg-sm tw-shadow-md px-3 py-1 py-md-2 inputarea"
                >
                  <div class="d-flex align-items-center">
                    <input
                      type="text"
                      class="w-100 tw-border-0 h-100"
                      v-model="tapsToReceive"
                    />
                  </div>

                  <div class="d-flex align-items-center justify-content-around">
                    <div class="vl mx-2"></div>
                    <p
                      class="m-0 tw-font-robotoBold tw-text-xxs fs-md-14 line-height-10 line-height-md-16 tw-text-orange-450"
                    >
                      puntos Tap It
                    </p>
                    <img
                      src="@/assets/img/gamification/tapitlogo.svg"
                      alt=""
                      class="he-12 he-md-16 mb-1"
                    />
                  </div>
                </div>
                <div class="text-center mt-3">
                  <p
                    class="m-auto tw-font-roboto tw-text-xxs fs-md-14 line-height-12 line-height-md-16 tw-text-gray-600"
                  >
                    125 Maltas = 1 punto Tap It
                  </p>
                </div>
              </div>
              <div
                class="md:tw-shadow-md tw-rounded-lg px-4 py-2 p-md-4"
                id="transfer-second"
                v-if="step === 2"
              >
                <div>
                  <p
                    class="m-auto tw-font-robotoBold tw-text-xxs fs-md-14 line-height-14 line-height-md-24 tw-text-gray-600"
                  >
                    Datos de la cuenta Tapit a enviar.
                  </p>
                </div>
                <div>
                  <div class="mt-md-4 mt-3 mb-2 d-flex justify-content-between">
                    <p
                      class="m-0 tw-font-roboto tw-text-xxs fs-md-12 line-height-12 line-height-md-14"
                      :class="
                        errorStatus($v.email) && $v.email.$model
                          ? 'tw-text-red-400'
                          : 'tw-text-gray-600'
                      "
                    >
                      Correo de tu cuenta Tapit
                    </p>
                    <p
                      class="m-0 tw-font-roboto tw-text-xxs fs-md-12 line-height-12 line-height-md-14 tw-text-red-400 text-right"
                      v-show="errorStatus($v.email) && $v.email.$model"
                    >
                      Esto no parece una dirección de correo
                    </p>
                  </div>
                  <div
                    class="tw-rounded-md tw-rounded-lg-sm tw-shadow-sm md:tw-shadow-md he-30 he-md-40 d-flex overflow-hidden px-2 py-1"
                    :class="
                      errorStatus($v.email) && $v.email.$model
                        ? 'outline-red'
                        : ''
                    "
                  >
                    <div class="w-100 d-flex align-items-center">
                      <input
                        type="text"
                        class="w-100 tw-border-0 h-100 m-0 py-0"
                        v-model="$v.email.$model"
                        :style="errorStatus($v.email) ? 'color: #d82b2b;' : ''"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="documento mt-md-4 mt-3 mb-2 outline-red">
                  <div>
                    <div id="tipodocumento">
                      <div>
                        <p
                          class="
                            m-auto
                            tw-font-roboto
                             tw-text-xxs  fs-md-12
                            line-height-12 line-height-md-14
                            tw-text-gray-600
                          "
                        >
                          Tipo de documento
                        </p>
                      </div>
                      <div class="doctype mt-2">
                        <div
                          v-for="doctype in idOptions"
                          v-bind:key="doctype.id"
                        >
                          <div
                            class="
                              tw-rounded-md tw-rounded-lg-sm
                              tw-shadow-sm md:tw-shadow-md
                              wi-35
                              he-30
                              wi-md-50
                              he-md-40
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                            role="button"
                            :class="
                              doctype.active
                                ? 'tw-bg-green-700 tw-text-white'
                                : ''
                            "
                            @click="toggleDocType(doctype.type)"
                          >
                            <p
                              class="m-0 tw-font-roboto md:tw-text-base line-height-md-20"
                            >
                              {{ doctype.type }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div id="numerodocumento">
                      <div>
                        <p
                          class="
                            m-auto
                            tw-font-roboto
                             tw-text-xxs  fs-md-12
                            line-height-12 line-height-md-14
                            tw-text-gray-600
                          "
                        >
                          Número de documento
                        </p>
                      </div>
                      <div
                        class="
                          tw-rounded-md tw-rounded-lg-sm
                          tw-shadow-sm md:tw-shadow-md
                          he-30 he-md-40
                          d-flex
                          overflow-hidden
                          px-2
                          py-1
                          mt-2
                        "
                      >
                        <div class="w-100 d-flex align-items-center">
                          <input
                            type="text"
                            class="w-100 tw-border-0 h-100 m-0 py-0"
                            v-model="$v.documento.$model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              
                </div> -->
                <!-- <div class="d-flex mt-3">
                  <label class="check-group m-0">
                    <input type="checkbox" value="1" v-model="rememberData" />
                    <span class="checkmark"></span>
                  </label>
                  <p
                    class="
                      my-0
                      ml-2
                      tw-font-roboto
                      tw-text-xxs fs-md-12
                      line-height-14 line-height-md-24
                      tw-text-gray-300
                    "
                  >
                    Recordar datos de tu cuenta Tapit
                  </p>
                </div> -->
              </div>
              <div
                class="md:tw-shadow-md tw-rounded-lg px-3 py-2 p-md-4 h-100 d-flex flex-column justify-content-between"
                id="transfer-third"
                v-if="step === 3"
              >
                <div>
                  <p
                    class="m-auto tw-font-robotoBold tw-text-xxs fs-md-14 line-height-14 line-height-md-24 tw-text-gray-600"
                  >
                    Vas a transferir
                  </p>
                </div>
                <div
                  class="tw-shadow-sm md:tw-shadow-md tw-rounded-lg px-md-4 mt-md-3 h-100 d-grid rows-2"
                >
                  <div
                    class="p-4 hr-border d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p
                        class="m-0 tw-font-robotoBold tw-text-xxs fs-md-14 line-height-12 line-height-md-16"
                      >
                        Cantidad
                      </p>
                    </div>
                    <div class="text-right d-flex align-items-center">
                      <p
                        class="m-0 tw-font-roboto tw-text-xxs fs-md-14 line-height-12 line-height-md-16"
                      >
                        {{
                          maltsToSend + " Maltas = " + tapsToReceive + " Taps"
                        }}
                      </p>
                      <i
                        class="icon-edit-data tw-text-sm fs-md-20 ml-2 my-0 tw-cursor-pointer"
                        @click="goToStep(1)"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="p-4 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p
                        class="m-0 tw-font-robotoBold tw-text-xxs fs-md-14 line-height-12 line-height-md-16"
                      >
                        Hacia
                      </p>
                    </div>
                    <div class="text-right d-flex align-items-center">
                      <div>
                        <p
                          class="m-0 tw-font-roboto tw-text-xxs fs-md-14 line-height-12 line-height-md-16 text-capitalize"
                        >
                          {{ user.name.toLowerCase() }}
                        </p>
                        <p
                          class="m-0 tw-font-roboto tw-text-xxs fs-md-14 line-height-12 line-height-md-16"
                        >
                          {{ "D.I.: " + user.cc }}
                        </p>
                      </div>
                      <i
                        class="icon-edit-data tw-text-sm fs-md-20 ml-2 my-0 tw-cursor-pointer"
                        @click="goToStep(2)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="mt-2 mt-md-3 px-3 px-md-0 d-inline-block w-100"
              id="button"
            >
              <button
                @click="
                  step === 3
                    ? maltsExchange(maltsToSend)
                    : validationsButton
                    ? changeStep()
                    : ''
                "
                type="button"
                class="tw-rounded-md tw-rounded-lg-sm tw-py-2 tw-py-md-3 w-100"
                :class="
                  validationsButton
                    ? 'btn-primary tw-cursor-pointer'
                    : 'btn-disabled tw-pointer-events-none'
                "
              >
                <span
                  class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base"
                  v-if="step === 1 || step === 2"
                >
                  Continuar
                </span>
                <span
                  class="tw-m-0 tw-font-roboto tw-text-xs fs-md-16"
                  v-if="step === 3"
                >
                  Transferir
                </span>
              </button>
              <div
                class="text-center d-flex justify-content-center align-items-center"
              >
                <p
                  class="my-2 tw-font-roboto tw-font-bold tw-text-xxs fs-md-12 line-height-12 line-height-md-14"
                >
                  <u>Términos y Condiciones</u>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="discover tw-rounded-xl md:tw-rounded-3xl tw-shadow tw-shadow-md-lg p-4"
        >
          <div>
            <div class="py-0 d-flex flex-column justify-content-around h-100">
              <p class="m-0 tw-font-tolyerBold fs-22 fs-md-30">
                Transfiere a puntos Tap It
              </p>
              <p
                class="mx-0 my-0 mt-md-2 mb-md-4 tw-font-roboto tw-text-xxs fs-md-14 line-height-12 line-height-md-20"
              >
                Ahora podrás disfrutar de tus maltas en la plataforma de
                beneficios cerveceros Tap It. Conviértelas en puntos Tap It y
                úsalas para reclamar cerveza gratis, productos y experiencias
                exclusivas perfectas para un cervecero como tú.
              </p>
            </div>
          </div>
          <div>
            <div
              class="tw-text-sm tw-font-roboto line-height-16 tw-font-bold hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center tw-cursor-pointer"
              @click="pushLink(linkToTapit)"
            >
              Ir a tapit
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-end px-xs-0">
              <div
                class="tw-shadow-md tw-rounded-full wi-80 he-80 he-md-130 wi-md-130 d-flex p-3"
              >
                <img
                  src="@/assets/img/gamification/tapitlogo.svg"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-transfer"
      body-class="p-0"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="transfer"
    >
      <div
        class="container m-auto overflow-hidden modal-content tw-border-0 pr-0 pl-0 h-100"
      >
        <div class="tw-flex tw-justify-center tw-text-center">
          <div class="tw-p-10 lg:tw-p-14" v-if="successState === 'Success'">
            <p
              class="tw-my-3 lg:tw-my-4 tw-font-tolyerBold tw-text-3xl lg:tw-text-5xl tw-text-red-400 tw-uppercase"
            >
              ¡Felicidades!
            </p>
            <img
              src="@/assets/img/gamification/success.svg"
              class="tw-w-32 lg:tw-w-44 tw-my-5"
              alt=""
            />
            <p
              class="lg:tw-my-5 tw-font-roboto tw-text-xs lg:tw-text-lg tw-text-gray-320"
            >
              ¡Ya tienes tus maltas en Tap It! conoce como canjear tus puntos
              por productos exclusivos
            </p>
            <button
              @click="hideModal()"
              class="tw-text-sm tw-font-medium tw-border-0 tw-py-2 lg:tw-py-3 tw-mt-3 lg:tw-mt-6 tw-rounded-md tw-bg-red-350 tw-text-white hover:tw-bg-red-500 hover:tw-text-white tw-block tw-text-center tw-w-full"
            >
              Redimir en Tapit
            </button>
            <button
              @click="hideModal()"
              class="tw-text-sm tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-black tw-py-2 lg:tw-py-3 px-md-3 tw-mt-3 lg:tw-mt-3 tw-rounded-md tw-bg-white tw-text-black hover:tw-bg-gray-200 tw-block tw-text-center tw-w-full"
            >
              Volver
            </button>
          </div>
          <div class="tw-p-10 lg:tw-p-14" v-else>
            <p
              class="tw-my-3 lg:tw-my-4 tw-font-tolyerBold tw-text-3xl lg:tw-text-5xl tw-text-red-400 tw-uppercase"
            >
              UPS...
            </p>
            <img
              src="@/assets/img/gamification/fail.svg"
              class="tw-w-32 lg:tw-w-44 tw-my-5"
              alt=""
            />

            <p
              class="lg:tw-my-5 tw-font-roboto tw-text-xs lg:tw-text-lg tw-text-gray-320"
            >
              Parece que algo ha salido mal, vuelve a intentarlo
            </p>
            <button
              @click="hideModal()"
              class="tw-text-sm tw-font-medium hover:tw-text-white tw-border-0 tw-py-2 lg:tw-py-3 px-md-3 tw-mt-3 lg:tw-mt-6 tw-rounded-md tw-bg-red-350 tw-text-white hover:tw-bg-red-500 tw-block tw-text-center tw-w-full"
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, email, minValue, minLength } from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";
import linksMixin from "@/mixins/general/links";

const maltsValidation = (value, vm) => value <= vm.user.gamification_maltas;

export default {
  name: "GamiMalts",
  mixins: [linksMixin],
  data() {
    return {
      step: 1,
      tapit_url: process.env.VUE_APP_TAPIT_URL,
      idOptions: [
        {
          id: 1,
          type: "CC",
          name: "Cédula de ciudadanía",
          active: false,
        },
        {
          id: 2,
          type: "CE",
          name: "Cédula de extranjería",
          active: false,
        },
      ],
      email: "",
      documento: null,
      maltsToSend: null,
      selectedDocType: null,
      successState: "Success",
      rememberData: false,
      customToken: null,
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(7),
    },
    // documento: {
    //   required,
    //   minLength: minLength(4),
    // },
    // selectedDocType: {
    //   required,
    // },
    maltsToSend: {
      required,
      minValue: minValue(125),
      maltsValidation: maltsValidation,
    },
  },
  mounted() {
    this.email = this.user.tapit_email;
  },
  methods: {
    ...mapActions("modGamification", ["actPostMaltsTapitExchange"]),
    ...mapActions("modCore", ["actUserTapitAccount", "actUser"]),
    ...mapMutations("modCore", ["setTapitAccount"]),
    // validationStatus(validation) {
    //   return typeof validation != "undefined" ? validation.$error : false;
    // },
    validationStatus(validation) {
      return validation.$invalid;
    },
    errorStatus(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    changeStep() {
      if (this.step === 1) {
        this.step = 2;
      } else if (this.step === 2) {
        this.step = 3;
      } else if (this.step === 3) {
        this.step = 1;
      }
    },
    goToStep(step) {
      this.step = step;
    },
    // toggleDocType(docType) {
    //   this.idOptions = this.idOptions.map((doc) => {
    //     if (doc.type === docType) {
    //       doc.active = true;
    //       this.$v.selectedDocType.$model = doc.type;
    //     }
    //     if (doc.type !== docType) {
    //       doc.active = false;
    //     }
    //     return doc;
    //   });
    // },
    maltsExchange() {
      let maltas = parseInt(this.maltsToSend);
      this.actPostMaltsTapitExchange(maltas)
        .then(() => {
          this.successState = this.success;
        })
        .finally(() => {
          this.actUser();
          this.showModal();
        });
    },
    showModal: function () {
      this.$bvModal.show("modal-transfer");
    },
    hideModal: function () {
      this.$bvModal.hide("modal-transfer");
    },
    // logoutsso() {
    //   window.ssoApp.logout();
    // },
  },
  computed: {
    ...mapState("modCore", ["user", "userTapitData"]),
    ...mapState("modGamification", ["success"]),
    linkToTapit() {
      return this.tapit_url + "?customToken=" + this.userTapitData.customToken;
    },
    tapsToReceive() {
      if (this.maltsToSend == null) {
        return null;
      }
      return this.maltsToSend / 125;
    },
    validationsButton() {
      if (this.step === 1) {
        return !this.validationStatus(this.$v.maltsToSend);
      } else if (this.step === 2) {
        return this.validationStatus(this.$v.email) === false;
      } else {
        this.$v.$touch();
        if (this.$v.$pending || this.$v.$error || this.$v.$invalid) return;
        return true;
      }
    },
  },
  watch: {
    maltsToSend(val) {
      if (val >= 0) {
        this.maltsToSend = parseInt(val, 10);
      } else {
        this.maltsToSend = 0;
      }
    },
    documento: function (newValue) {
      this.$v.documento.$model = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}
input,
input:focus {
  border-width: 0;
  outline: 0;
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.malts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
}

.general {
  grid-column: 1 / 2;
  grid-row: 1;
  display: grid;
  grid-template-rows: 175px 1fr;
}

.general .backgrounddiv {
  height: 100%;
  width: 100%;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  background-image: url("~@/assets/img/gamification/tapit-back-1.svg");
  background-position-y: center;
  background-repeat: no-repeat;
}

.transfer {
  grid-column: 2 / 3;
  grid-row: span 2;
  display: grid;
}

.discover {
  grid-column: 1 / 2;
  grid-row: 2;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 2fr 1fr;
}

.discover div:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.discover div:nth-child(2) {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  align-self: center;
}

.discover div:nth-child(3) {
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  align-self: center;
}

.documento {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1em;
}

.documento div:nth-child(1) {
  grid-column: 1;
}

.documento div:nth-child(2) {
  grid-column: 2;
}

.doctype {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.inputarea {
  display: grid;
  grid-template-columns: 70% 30%;
}

.vl {
  border: 1px solid #d8d8d8;
  height: 100%;
}

.hr-border {
  border-bottom: 1px solid #d0d0d0;
}

.name::first-letter {
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  input[type="text"] {
    font-size: 9px;
    font-family: "Roboto", sans-serif;
    line-height: 11px;
  }

  .general {
    grid-column: 1/-1;
    display: grid;
    grid-template-rows: 116px;
    grid-template-columns: repeat(2, 1fr);
  }

  .general .backgrounddiv {
    background-position-x: right;
  }

  .malts-grid .transfer {
    grid-column: 1/-1;
  }

  .malts-grid .discover {
    grid-column: 1/-1;
  }

  .discover {
    grid-row-gap: 15px;
  }

  .discover div:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  .discover div:nth-child(2) {
    grid-column: 1 / 3;
    grid-row: 3;
    align-self: center;
  }

  .discover div:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    align-self: center;
  }
}

/* slider css code */
.slider-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  overflow: hidden;
}

.slider-container::before {
  position: absolute;
  top: calc(50% - 22px);
  right: 80px;
  left: 80px;
  content: "";
  background-color: #d0d0d0;
  height: 1px;
  z-index: -1;
}

@media (max-width: 768px) {
  .slider-container::before {
    top: calc(50% - 20px);
    right: 60px;
    left: 60px;
  }
}

/**Checkbox */

.check-group {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background: #00bf79 0 0 no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid #00bf79;
  opacity: 1;
}

.check-group input:checked ~ .checkmark {
  background-color: #00bf79;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-group input:checked ~ .checkmark:after {
  display: block;
}

.check-group .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  /* -webkit-transform: rotate(45deg); */
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
