var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"tw-flex tw-flex-col tw-gap-2 tw-p-3 tw-border tw-border-gray-300 tw-rounded-2xl",attrs:{"id":"city-information"}},[_vm._m(0),_c(_setup.CompFormTextInput,{attrs:{"model-value":_setup.childrenSupportValue,"input-type":"number"},on:{"update":(value) => {
        const validatedValue = Math.max(0, Number(value));
        _setup.emit('update', {
          ..._setup.props.data,
          hours_children_support: validatedValue,
        });
      }}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm"},[_vm._v(" ¿Cuántas horas le dedicas a las tareas educativas y de acompañamiento de tus hijos/hijas? ")]),_c('p',{staticClass:"tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs"},[_vm._v(" *En caso de que no aplique, pon 0 en la respuesta ")])])
}]

export { render, staticRenderFns }