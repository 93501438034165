var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-0 md:tw-ml-20 ml-xl-0"},[(_vm.categories.id === this.$route.params.id)?_c('div',[(_vm.categories.banner)?_c('div',{staticClass:"tw-container-general"},[_c('SecondaryNavbar',{staticClass:"mx-0 ml-md-2",attrs:{"title":_vm.categories.name}}),_c('Banner',{attrs:{"bannerId":_vm.categories.banner.id,"gradient":"none"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-sm-2"},[_c('div',{staticClass:"col mt-4 mt-md-5"},[_c('h1',{staticClass:"text-center px-3 mt-4 mt-md-0 textColorPrimary tw-text-4xl md:tw-text-7xl tw-font-tolyerBold"},[_vm._v(" "+_vm._s(_vm.categories.title)+" ")])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-center textPlane"},[_vm._v(" "+_vm._s(_vm.categories.description)+" ")])])])]),_c('div',{staticClass:"row mx-2 mx-md-3 mt-4"},_vm._l((_vm.categories.items),function(item,index){return _c('div',{key:index,staticClass:"col-12 col-md-4 px-3"},[_c('div',{staticClass:"mobile"},[(index % 2 === 0)?_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{on:{"click":function($event){return _vm.pushDataLayer(item)}}},[_c('router-link',{attrs:{"to":{
                    name: 'ArticleOld',
                    params: {
                      id: item.id,
                      categoryArticles: _vm.categoryArticles.name,
                      Category: _vm.Category,
                      SubCategory: _vm.SubCategory,
                    },
                  }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 height"},[_c('div',{staticClass:"img-content img-mobile-radio",style:({
                          'background-image':
                            'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%), url(' +
                            _vm.url +
                            '/' +
                            item.img_card +
                            ')',
                        })})])]),_c('div',{staticClass:"mt-2 px-2 ml-2 mr-3 texto-up"},[_c('p',{staticClass:"pl-0 text-author"},[_vm._v(_vm._s(item.author))]),_c('p',{staticClass:"text-desc"},[_vm._v(_vm._s(item.description))])]),_c('div',{staticClass:"position-absolute comp-star-1"},[_c('CompStars',{attrs:{"category":"Articles","idContent":item.id,"ratingData":_vm.ratingData[item.id],"showStars":false}})],1)])],1)]):_c('div',{staticClass:"card mb-3 pr-3",staticStyle:{"border-radius":"30px","width":"100%"}},[_c('div',{on:{"click":function($event){return _vm.pushDataLayer(item)}}},[_c('router-link',{attrs:{"to":{
                    name: 'ArticleOld',
                    params: {
                      id: item.id,
                      categoryArticles: _vm.categoryArticles.name,
                      Category: _vm.Category,
                      SubCategory: _vm.SubCategory,
                    },
                  }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pr-0 height"},[_c('div',{staticClass:"img-content img-mobile-radio-right",style:({
                          'background-image':
                            'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%), url(' +
                            _vm.url +
                            '/' +
                            item.img_card +
                            ')',
                        })}),_c('div',{staticClass:"position-absolute comp-star-2"},[_c('CompStars',{attrs:{"category":"Articles","idContent":item.id,"ratingData":_vm.ratingData[item.id],"showStars":false}})],1)]),_c('div',{staticClass:"col-6 color px-0"},[_c('p',{staticClass:"ml-1 pl-0 text-author p-1 my-1"},[_vm._v(" "+_vm._s(item.author)+" ")]),_c('p',{staticClass:"ml-1 pl-0 text-desc p-1 textPlane"},[_vm._v(" "+_vm._s(item.description)+" ")])])])])],1)])]),_c('div',{staticClass:"desk height"},[_c('div',{on:{"click":function($event){return _vm.pushDataLayer(item)}}},[_c('router-link',{attrs:{"to":{
                  name: 'ArticleOld',
                  params: {
                    id: item.id,
                    categoryArticles: _vm.categoryArticles.name,
                    Category: _vm.Category,
                    SubCategory: _vm.SubCategory,
                  },
                }}},[_c('div',{staticClass:"img-content img-mobile-radio",style:({
                    'background-image':
                      'url(' + _vm.url + '/' + item.img_card + ')',
                  })})])],1),_c('div',{staticClass:"d-flex justify-content-between mt-2"},[_c('p',{staticClass:"mt-2 mr-2 text-desc textPlane"},[_vm._v(_vm._s(item.name))]),_c('CompStars',{attrs:{"category":"Articles","idContent":item.id,"ratingData":_vm.ratingData[item.id],"showStars":false}})],1)])])}),0)],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }