<template>
  <div class="row">
    <div
      v-for="(data, index) in dataUserRankSelected[0].information"
      :key="'data-rank-people-' + index"
      class="col-12 col-md-6 col-lg-4 mb-3"
    >
      <div class="tw-shadow-md tw-rounded-lg">
        <div
          class="
            tw-bg-gray-200
            d-flex
            justify-content-between
            align-items-center
            py-2
            px-2
            tw-rounded-tr-lg tw-rounded-tl-lg
          "
        >
          <p class="mb-0 tw-text-brown-400  tw-text-xs ">Mes</p>
          <p class="mb-0 tw-text-brown-400  tw-text-xxs ">{{ index }}</p>
        </div>
        <div class="tw-bg-white tw-rounded-bl-lg tw-rounded-br-lg">
          <div class="w-100 p-2">
            <p class="text-left tw-text-base mb-3 tw-text-gray-400">
              {{ calulationName }}
            </p>
            <p class="text-right tw-text-xl mb-0 tw-text-gray-400">
              {{ data }}
              <sup>º</sup>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCategory3",
  props: {
    dataUserRankSelected: {
      type: Array,
      required: true,
    },
    calulationName: {
      type: String,
      required: true,
    },
  },
};
</script>
