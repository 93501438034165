import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  psiTabFilter: "/indicatorDataEngCalcules/getTabFilter",
  psiLeaders: "/indicatorDataEngCalcules/getLeaders",
  psiCategories: "/indicatorDataEngCalcules/getCategories",
  psiGeneralFilter: "/indicatorDataEngCalcules/filter",
  psiTurnOver: "/indicatorDataEngCalcules/filterTo",
  psiPersonalitiesTabFilter: "/indicatorDataEngCalcules/getCoreVps",
  psiPersonalitiesAreaFilter:
    "/indicatorDataEngCalcules/calculatePersonalities",
  psiCompareLeaders: "/indicatorDataEngCalcules/comparisonLeader",
  psiYearsAndMonths: "/indicatorDataEngCalcules/getYearsAndMonths",
};

export const useIndicatorsStore = defineStore("indicators", () => {
  const tabFilter = ref([]);
  const leadersList = ref([]);
  const personalitiesAreaList = ref([]);
  const categoriesListData = ref([]);
  const generalFilterData = ref([]);
  const turnOverData = ref([]);
  const personalitiesData = ref([]);
  const leadersInfo = ref([]);
  const indLeaderInfo = ref([]);
  const yearsAndMonths = ref([]);

  async function actGetTabFilter() {
    let response = await AxiosBeerOffice.get(uri.psiTabFilter, true, {});
    if (response.error) {
      return response.error;
    }
    tabFilter.value = response.data;
    return true;
  }

  async function actGetLeaders(data) {
    let response = await AxiosBeerOffice.get(
      uri.psiLeaders + "/" + JSON.stringify(data),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    leadersList.value = response.data;
    return true;
  }

  async function actGetPersonalitiesAreas() {
    let response = await AxiosBeerOffice.get(
      uri.psiPersonalitiesAreaFilter,
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    personalitiesAreaList.value = response.data;
    return true;
  }

  async function actGetCategoriesInfo(data) {
    let response = await AxiosBeerOffice.get(
      uri.psiCategories + "/" + JSON.stringify(data),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    categoriesListData.value = response.data;
    return true;
  }

  async function actGetGeneralFilterData(data) {
    let response = await AxiosBeerOffice.get(
      uri.psiGeneralFilter + "/" + JSON.stringify(data),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    generalFilterData.value = response.data;
    return true;
  }

  async function actGetTurnOverData(data) {
    let response = await AxiosBeerOffice.get(
      uri.psiTurnOver + "/" + JSON.stringify(data),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    turnOverData.value = response.data;
    return true;
  }

  async function actGetPersonalitiesData(id) {
    let response = await AxiosBeerOffice.get(
      uri.psiPersonalitiesAreaFilter + "/" + JSON.stringify(id.id),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    personalitiesData.value = response.data;
    return true;
  }

  async function actGetLeadersInfo(data) {
    let response = await AxiosBeerOffice.get(
      uri.psiCompareLeaders + "/" + JSON.stringify(data),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    leadersInfo.value = response;
    return true;
  }

  async function actGetIndLeaderInfo(data) {
    let response = await AxiosBeerOffice.get(
      uri.psiCompareLeaders + "/" + JSON.stringify(data),
      true,
      {}
    );
    if (response.error) {
      return response.error;
    }
    indLeaderInfo.value = response;
    return response;
  }

  async function actGetYearsAndMonths() {
    let response = await AxiosBeerOffice.get(uri.psiYearsAndMonths, true, {});
    if (response.error) {
      return response.error;
    }
    yearsAndMonths.value = response;
    return true;
  }

  return {
    tabFilter,
    leadersList,
    personalitiesAreaList,
    categoriesListData,
    generalFilterData,
    turnOverData,
    personalitiesData,
    leadersInfo,
    indLeaderInfo,
    yearsAndMonths,
    actGetTabFilter,
    actGetLeaders,
    actGetPersonalitiesAreas,
    actGetCategoriesInfo,
    actGetGeneralFilterData,
    actGetTurnOverData,
    actGetPersonalitiesData,
    actGetLeadersInfo,
    actGetIndLeaderInfo,
    actGetYearsAndMonths,
  };
});
