<script setup>
import { reactive, watch, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  reward: {
    type: Object,
    required: true,
  },
  redeemEnabled: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["formSubmitted"]);

let { formData, validations, state } = toRefs(
  reactive({
    formData: props.fields.reduce((acc, field) => {
      acc[field.name] = null;
      return acc;
    }, {}),
    validations: props.fields.reduce((acc, field) => {
      acc[field.name] = { ...field.validations };
      return acc;
    }, {}),
    state: {
      v$: useVuelidate(
        props.fields.reduce((acc, field) => {
          acc[field.name] = { ...field.validations };
          return acc;
        }, {}),
        {}
      ),
    },
  })
);

// Función para activar la validación manualmente
// const activateValidation = () => {
//   Object.keys(formData.value).forEach((key) => {
//     state.value.v$[key]?.$touch();
//   });
// };

// // Activar validación al inicio del componente
// activateValidation();

watch(
  () => props.fields,
  async (newFields) => {
    // Reset the form
    Object.keys(formData).forEach((key) => {
      formData[key] = null;
    });

    // Create a new reactive formData and validations
    formData.value = reactive(
      newFields.reduce((acc, field) => {
        acc[field.name] = null;
        return acc;
      }, {})
    );

    validations.value = reactive(
      newFields.reduce((acc, field) => {
        acc[field.name] = { ...field.validations };
        return acc;
      }, {})
    );

    state.value.v$ = useVuelidate(validations, formData);

    // Reset the vuelidate instance
    state.value.v$.$reset();

    // // Activate validation
    // activateValidation();
  },
  { deep: true, immediate: true }
);

function submitForm() {
  console.log("submitForm");

  state.value.v$.$touch();

  // if (state.value.v$.$invalid || !props.redeemEnabled) {
  //   console.log("formData", formData);
  //   return;
  // }

  console.log("formData", formData);

  emit("formSubmitted", formData.value);
}

defineExpose({
  submitForm,
});
</script>

<template>
  <form @submit.prevent="submitForm">
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-2 lg:tw-gap-4">
      <div v-for="(field, idx) in props.fields" :key="idx">
        <label
          class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-gray-400"
          :for="field.name"
          >{{ field.label }}</label
        >
        <input
          class="tw-form-input-small"
          :class="state.v$[field.name]?.$error ? 'tw-border-red-350' : ''"
          v-model="formData[field.name]"
          :name="field.name"
          :type="field.type"
        />
        <div v-if="validations[field.name] && state.v$[field.name].$errors">
          <div v-for="(error, idx) in state.v$[field.name].$errors" :key="idx">
            <span
              class="tw-text-red-350 tw-font-roboto tw-text-xs lg:tw-text-sm"
              v-if="idx === 0"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped></style>
