import { ref } from "vue";

export default function useHover() {
  const isHovering = ref(false);

  const mouseEnter = () => {
    isHovering.value = true;
  };

  const mouseLeave = () => {
    isHovering.value = false;
  };

  return { isHovering, mouseEnter, mouseLeave };
}
