import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  timeBenefitsUri: "/familyTimeOptions",
  timeBenefitsRequestUri: "/familyTimeRequests",
};

export const useTimeBenefitsStore = defineStore("timeBenefits", () => {
  const timeBenefits = ref([]);
  const timeBenefitDetail = ref({});
  const userTimeBenefitsRequests = ref([]);
  const userTimeBenefitsPendingRequests = ref([]);
  const userTimeBenefitsApprovedRequests = ref([]);

  async function actGetTimeBenefits() {
    const response = await AxiosBeerOffice.get(uri.timeBenefitsUri, true, {});
    if (response.error) return response.error;
    timeBenefits.value = response;
    return true;
  }

  async function actGetTimeBenefitById(id) {
    const response = await AxiosBeerOffice.get(
      uri.timeBenefitsUri + "/" + id,
      true,
      id
    );
    if (response.error) return response.error;
    timeBenefitDetail.value = response;
    return true;
  }

  async function actGetTimeBenefitsRequests() {
    const response = await AxiosBeerOffice.get(
      uri.timeBenefitsRequestUri,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    userTimeBenefitsRequests.value = data;
    return true;
  }

  async function actGetTimeBenefitsPendingRequests() {
    const response = await AxiosBeerOffice.get(
      uri.timeBenefitsRequestUri + "?status=PENDING",
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    userTimeBenefitsPendingRequests.value = data;
    return true;
  }

  async function actGetTimeBenefitsApprovedRequests() {
    const response = await AxiosBeerOffice.get(
      uri.timeBenefitsRequestUri + "?status=APPROVED",
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    userTimeBenefitsApprovedRequests.value = data;
    return true;
  }

  async function actPostTimeBenefitRequest(benefitId, requestedDate) {
    const response = await AxiosBeerOffice.post(
      uri.timeBenefitsRequestUri,
      true,
      {
        family_time_option_id: benefitId,
        requested_date: requestedDate,
      }
    );
    if (response.error) {
      console.error("Error: ", response.error);
      return false;
    }
    return response;
  }

  async function actPostTimeBenefitRequestVoucher(benefitId, data) {
    const response = await AxiosBeerOffice.post(
      uri.timeBenefitsRequestUri + "/" + benefitId + "/voucher",
      true,
      data,
      true
    );
    if (response.error) return response.error;
    return true;
  }

  return {
    timeBenefits,
    timeBenefitDetail,
    userTimeBenefitsRequests,
    userTimeBenefitsPendingRequests,
    userTimeBenefitsApprovedRequests,
    actGetTimeBenefits,
    actGetTimeBenefitById,
    actGetTimeBenefitsRequests,
    actGetTimeBenefitsPendingRequests,
    actGetTimeBenefitsApprovedRequests,
    actPostTimeBenefitRequest,
    actPostTimeBenefitRequestVoucher,
  };
});
