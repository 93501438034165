<script setup>
import { ref } from "vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import AtomMedia from "@/components/atoms/AtomMedia.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";

const emit = defineEmits(["close"]);
const isModalOpen = ref(true);

const props = defineProps({
  success: {
    type: Boolean,
    required: true,
  },
  mainMessage: {
    type: String,
    default: "Operación completada con éxito.",
  },
  secondaryMessage: {
    type: String,
    default: "Puedes seguir navegando.",
  },
});

const defaultMainMessage = props.success
  ? "Operación completada con éxito."
  : "Encontramos un error que no te permite continuar.";
const defaultSecondaryMessage = props.success
  ? "Puedes seguir navegando."
  : "Inténtalo más tarde o contáctate con soporte.";

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  emit("close");
  isModalOpen.value = false;
};
</script>

<template>
  <portal to="all-modals">
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 500, xl: 600 }"
      modal-name="goFlexDetail"
      v-if="isModalOpen"
      :isShowModal="isModalOpen"
      @close="closeModal"
      :backdrop-opacity="0.8"
    >
      <div
        class="tw-py-5 tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4 tw-justify-center"
      >
        <div
          class="tw-w-full tw-flex tw-justify-center tw-items-center tw-gap-6"
        >
          <atom-media
            type="image"
            :src="
              props.success
                ? 'assets/img/modal/modal-success.svg'
                : 'assets/img/modal/modal-error.svg'
            "
            alt="success"
            custom-width="w-16"
          />
          <atom-text
            :text-color="props.success ? 'green-500' : 'red-350'"
            text-size-mobile="2xl"
            text-size-desktop="4xl"
            font="robotoBold"
          >
            {{ props.success ? "Listo" : "Lo sentimos" }}
          </atom-text>
        </div>
        <atom-text
          tag="h3"
          text-size-mobile="sm"
          text-size-desktop="lg"
          font="robotoRegular"
          text-alignment="center"
        >
          {{ props.mainMessage || defaultMainMessage }}
        </atom-text>
        <atom-text
          tag="p"
          text-size-mobile="sm"
          text-size-desktop="lg"
          font="roboto"
          text-alignment="center"
          text-color="gray-500"
        >
          {{ props.secondaryMessage || defaultSecondaryMessage }}
        </atom-text>
        <atom-button
          @button-click="closeModal"
          bg-color="red-350"
          text-color="white"
          text-size-mobile="sm"
          text-size-desktop="base"
          font="robotoBold"
          rounded
          >Cerrar</atom-button
        >
      </div>
    </general-modal>
  </portal>
</template>

<style scoped></style>
