<script setup>
import { ref, computed, onBeforeMount, getCurrentInstance } from "vue";
import config from "@/services/config";
import GeneralSplash from "@/components/general/GeneralSplash.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCardDropdown from "@/components/cards/CompGeneralTopCardDropdown.vue";
import CompGeneralDropdownIcon from "@/components/general/CompGeneralDropdownIcon.vue";
import CompGeneralFeaturedCard from "@/components/general/CompGeneralFeaturedCard.vue";
import CompGeneralOrderFilter from "@/components/general/CompGeneralOrderFilter.vue";
import CompGeneralGridCard from "@/components/general/CompGeneralGridCard.vue";
import CompGeneralShowMore from "@/components/general/CompGeneralShowMore.vue";
import CompBenefitsCard from "@/components/benefits/CompBenefitsCard.vue";
import { sortArrayByCriteria } from "@/helpers/arrayHelpers";
import { useBenefitsAgreementsStore } from "@/stores/benefitsAgreementsStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const uriResources = config.beerOfficeURL;
const isSplash = ref(false);
const isLoading = ref(false);
const optionSelected = ref(null);
const featuredBenefit = ref(null);
const otherBenefits = ref([]);
const benefitsVisible = ref(6);
const store = useBenefitsAgreementsStore();
const { $isMobile: mobile } = getCurrentInstance().proxy;

const showMore = computed(() => {
  return benefitsVisible.value < otherBenefits.value.length;
});

function orderBenefits(value) {
  otherBenefits.value = otherBenefits.value.map((item) => {
    return {
      ...item,
      ...item.ben_detail,
    };
  });

  otherBenefits.value = sortArrayByCriteria(otherBenefits.value, value.id);
}

async function changeBienestar(val, source, charge) {
  optionSelected.value = val;
  await getBenefits();
  if (source === "card") {
    window.scrollTo(0, 0);
    pushDataLayer({
      event_category: "Beneficios",
      event_action: "Descubre más beneficios",
      event_label: val.name,
      component_name: "Click",
    });
  } else {
    if (!charge) {
      console.log("no charge");
      pushDataLayer({
        event_category: "Beneficios",
        event_action: "¿Qué beneficios quieres ver?",
        event_label: val.name,
        component_name: "Click",
      });
    }
  }
}

const backColor = computed(() => {
  return optionSelected.value?.color;
});

const optionSelectedImage = computed(() => {
  return uriResources + "/" + optionSelected.value?.image;
});

async function getBenefits() {
  await store.actGetBenefits(optionSelected.value.id).then(() => {
    featuredBenefit.value = store.benefits.find(
      (b) => b.ben_detail.featured === 1
    );
    otherBenefits.value = store.benefits.filter(
      (b) => b.ben_detail.featured === 0
    );
  });
}

const showMoreContent = () => {
  if (benefitsVisible.value < otherBenefits.value.length) {
    benefitsVisible.value += 6;
  } else {
    benefitsVisible.value = 6;
  }
};

onBeforeMount(async () => {
  isSplash.value = true;
  isLoading.value = true;
  await store.actGetBenefitCategories().then(() => {
    optionSelected.value = store.benefitCategories[0];
  });
  orderBenefits({ id: 0 });
  isLoading.value = false;
});
</script>

<template>
  <div>
    <div v-if="isSplash && isLoading">
      <general-splash
        title="Beneficios"
        icon="icon-inside.svg"
        illustration="illustration-beneficios.svg"
        @loaded="isSplash = false"
      >
        Descubre nuestros convenios con marcas líderes y disfruta de descuentos,
        eventos exclusivos y obsequios especiales.
      </general-splash>
    </div>
    <div v-else-if="!isLoading">
      <mobile-bar title="Beneficios" />
      <title-bar title="Beneficios" />
      <main class="tw-container-general tw-mt-0 tw-py-0">
        <comp-general-top-card-dropdown
          title="¿Qué beneficios quieres ver?"
          :image="optionSelectedImage"
          :bg-color="backColor"
          dropdown
        >
          <template #description>
            Descubre nuestros convenios con marcas líderes y disfruta de
            descuentos, eventos exclusivos y obsequios especiales.
          </template>
          <template #dropdown>
            <comp-general-dropdown-icon
              :options="store.benefitCategories"
              :is-label="false"
              :fitted="false"
              :default-option="optionSelected.id"
              icon-visible
              @changeOption="changeBienestar"
            />
          </template>
          <template #dropdownMobile>
            <comp-general-dropdown-icon
              :options="store.benefitCategories"
              :is-label="false"
              :fitted="false"
              :default-option="optionSelected.id"
              embedded-mobile
              icon-visible
              @changeOption="changeBienestar"
            />
          </template>
        </comp-general-top-card-dropdown>
        <hr class="tw-w-full lg:tw-my-10" />
        <comp-general-featured-card
          v-if="featuredBenefit"
          :item="featuredBenefit?.ben_detail"
          :category="
            featuredBenefit?.ben_detail?.ben_benefit_category?.category
          "
          :title="featuredBenefit?.ben_detail?.name"
          :description="featuredBenefit?.ben_detail?.description"
          :link="`/benefits/${featuredBenefit?.ben_detail?.id}`"
          :image="featuredBenefit?.ben_detail?.main_image"
          category-for-social="BENEFITS"
          category-for-datalayer="Beneficios"
          action-for-datalayer="Destacado"
          datalayer-active
          main-title
          @updatedComment="getBenefits"
          @updatedReaction="getBenefits"
        />
        <hr class="tw-w-full lg:tw-my-10" v-if="featuredBenefit" />
        <section v-if="otherBenefits.length > 0">
          <div
            class="tw-flex tw-justify-between tw-items-start tw-mt-10 tw-mb-5 tw-h-auto tw-max-h-10"
          >
            <div class="tw-flex tw-gap-x-6 tw-items-center tw-justify-start">
              <p
                class="tw-mb-0 tw-text-black tw-text-xl md:tw-text-3xl tw-font-robotoBold"
              >
                Más beneficios
              </p>
            </div>
            <comp-general-order-filter
              @changeFilter="orderBenefits"
              category-for-datalayer="Beneficios"
              not-views
            />
          </div>
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8 tw-m"
          >
            <div
              class="tw-flex tw-flex-col"
              v-for="(item, idx) in otherBenefits.slice(0, benefitsVisible)"
              :key="idx"
            >
              <comp-general-grid-card
                :id="item?.ben_detail?.id"
                :item="item?.ben_detail"
                :category="item?.ben_detail?.ben_benefit_category?.category"
                category-for-social="BENEFITS"
                :title="item?.ben_detail?.name"
                :description="item?.ben_detail?.description"
                :link="`/benefits/${item?.ben_detail?.id}`"
                :image="
                  mobile() && item?.ben_detail?.banner_image
                    ? item?.ben_detail?.banner_image
                    : item?.ben_detail?.main_image
                "
                category-for-datalayer="Beneficios"
                action-for-datalayer="Más beneficios"
                @updatedComment="getBenefits"
                @updatedReaction="getBenefits"
              />
              <hr class="tw-w-full md:tw-hidden" />
            </div>
          </div>
          <comp-general-show-more
            :show-more="showMore"
            v-if="otherBenefits.length > 6"
            @showMoreContent="showMoreContent"
            datalayer-active
            category-for-datalayer="Beneficios"
            action-for-datalayer="Más beneficios"
          />
          <hr class="tw-w-full lg:tw-my-10" />
        </section>
        <section class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
          <p
            class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-3xl tw-font-robotoBold"
          >
            Descubre más beneficios
          </p>
          <div
            class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-8"
          >
            <div v-for="(item, idx) in store.benefitCategories" :key="idx">
              <comp-benefits-card
                :item="item"
                @click.native="changeBienestar(item, 'card')"
                class="tw-cursor-pointer"
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
