import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  competitionsUri: "/compCompetitions",
  compPostUri: "/compPosts",
};

export const useCompetitionStore = defineStore("competition", () => {
  const competitions = ref([]);
  const competition = ref({});
  const participant = ref([]);
  const competitionInfo = ref({
    ranking: [],
  });

  async function actGetCompetitions() {
    const response = await AxiosBeerOffice.get(
      `${uri.competitionsUri}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    competitions.value = data;
    return true;
  }

  async function actGetCompetition(id) {
    const response = await AxiosBeerOffice.get(
      `${uri.competitionsUri}/${id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    competition.value = data;
    return true;
  }

  async function actGetCompetitionByIdUser(_data) {
    const { id, idUser } = _data;
    const response = await AxiosBeerOffice.get(
      `${uri.competitionsUri}/${id}/${idUser}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    competitionInfo.value = data;
    return true;
  }

  async function actPostCompetition(data) {
    const response = await AxiosBeerOffice.post(
      `${uri.compPostUri}`,
      true,
      data
    );
    if (response.error) return response.error;
    participant.value = response.data;
    return true;
  }

  return {
    competitions,
    competition,
    participant,
    competitionInfo,
    actGetCompetitions,
    actGetCompetition,
    actGetCompetitionByIdUser,
    actPostCompetition,
  };
});
