import { render, staticRenderFns } from "./VamosPaLaCalleActividad.vue?vue&type=template&id=4b1db7de&scoped=true"
import script from "./VamosPaLaCalleActividad.vue?vue&type=script&setup=true&lang=js"
export * from "./VamosPaLaCalleActividad.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1db7de",
  null
  
)

export default component.exports