<script setup>
import { onMounted, ref } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import CompCardArticlesFeatured from "@/components/articles/article-body/CompCardArticlesFeatured.vue";

const touchstartX = ref(0);
const touchendX = ref(0);
const timer = ref(null);
const slider = ref(null);
const selected = ref(0);
// const lastTime = ref(new Date().getTime());
// const currentTime = ref(new Date().getTime());

const props = defineProps({
  articles: {
    type: Array,
    required: true,
  },
});

const startSlide = () => {
  timer.value = setInterval(next, 8000);
};

const next = () => {
  selected.value += 1;
  if (selected.value === props.articles.length) {
    selected.value = 0;
  }
};

const changeSlide = (i) => {
  selected.value = i;
  dataLayer(props.articles[selected.value], "Click", "Carrusel");
};

const handleGesture = () => {
  if (touchendX.value < touchstartX.value) {
    selected.value === props.articles.length - 1
      ? (selected.value = 0)
      : selected.value++;
  }
  if (touchendX.value > touchstartX.value) {
    selected.value === 0
      ? (selected.value = props.articles.length - 1)
      : selected.value--;
  }
};

const dataLayer = (article, component, label) => {
  pushDataLayer({
    event: "GAEvent",
    event_category: "Artículos",
    event_action: "Banners principales",
    event_label: label,
    interaction: "True",
    component_name: component,
    element_category: article.article_category.name,
    element_text: article.title,
  });
};

onMounted(() => {
  slider.value = document.getElementById("slider");

  if (slider.value) {
    startSlide();

    slider.value.addEventListener("touchstart", (e) => {
      touchstartX.value = e.changedTouches[0].screenX;
    });
    slider.value.addEventListener("mousedown", (e) => {
      touchstartX.value = e.screenX;
    });
    slider.value.addEventListener("touchend", (e) => {
      touchendX.value = e.changedTouches[0].screenX;
      handleGesture();
    });
    slider.value.addEventListener("mouseup", (e) => {
      touchendX.value = e.screenX;
      handleGesture();
    });
  }
});
</script>
<template>
  <div class="tw-relative tw-flex tw-flex-col tw-justify-center">
    <div class="tw-h-full tw-w-full" id="slider">
      <transition name="slide-fade" mode="out-in">
        <div :key="selected">
          <CompCardArticlesFeatured
            stats-active
            :article="articles[selected]"
            class="tw-select-none"
          />
        </div>
      </transition>
    </div>
    <div
      class="tw-w-max tw-flex tw-justify-center tw-m-auto lg:tw-m-0 tw-gap-2 tw-mt-4 lg:tw-mt-5"
    >
      <div
        v-for="(article, idx) in articles.length"
        :key="idx"
        @click="changeSlide(idx)"
      >
        <div
          class="tw-w-2 tw-h-2 tw-rounded-full tw-cursor-pointer"
          :class="idx === selected ? 'tw-bg-red-350' : 'tw-bg-gray-300'"
        ></div>
      </div>
    </div>

    <!-- <a class="tw-cursor-pointer tw-absolute tw-top-2/4 tw-w-auto tw-p-4 tw-text-white tw-font-robotoBold tw-text-base tw-right-0 hover:tw-bg-black" @click="prev" href="#">&#10094; Previous</a>
    <a class="tw-cursor-pointer tw-absolute tw-top-2/4 tw-w-auto tw-p-4 tw-text-white tw-font-robotoBold tw-text-base tw-left-0 hover:tw-bg-black" @click="next" href="#">&#10095; Next</a> -->
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.2s ease-out;
  /* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); */
}
.slide-fade-enter
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(20%);
  opacity: 0;
}

.slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(-20%);
  opacity: 0;
}
</style>
