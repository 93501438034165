import ApiBeerOffice from "../../services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    regionsFilter: [],
    managersFilter: [],
    supervisorsFilter: [],
    bdrFilter: [],
    remunerationHistory: [],
  },
  mutations: {
    setRegionsFilter: (state, regionsFilters) => {
      state.regionsFilter = regionsFilters;
    },
    setManagersFilter: (state, managersFilters) => {
      state.managersFilter = managersFilters;
    },
    setSupervisorsFilter: (state, supervisorsFilters) => {
      state.supervisorsFilter = supervisorsFilters;
    },
    setBdrFilter: (state, bdrFilters) => {
      state.bdrFilter = bdrFilters;
    },
    setRemunerationHistory: (state, remunerationHistory) => {
      state.remunerationHistory = remunerationHistory;
    },
  },
  actions: {
    actGetRegionsFilter: async ({ commit }) => {
      let response = await ApiBeerOffice.getRegionsFilter();
      if (response.error) {
        return response.error;
      }
      commit("setRegionsFilter", response.data);
      return true;
    },
    actGetManagersFilter: async ({ commit }, params) => {
      let response = await ApiBeerOffice.getManagersFilter(params.regional);
      if (response.error) {
        return response.error;
      }
      commit("setManagersFilter", response.data);
      return true;
    },
    actGetSupervisorsFilter: async ({ commit }, params) => {
      let response = await ApiBeerOffice.getSupervisorsFilter(
        params.regional,
        params.manager
      );
      if (response.error) {
        return response.error;
      }
      commit("setSupervisorsFilter", response.data);
      return true;
    },
    actGetBdrFilter: async ({ commit }, params) => {
      //
      let response = await ApiBeerOffice.getBdrFilter(
        params.regional,
        params.manager,
        params.supervisor
      );
      if (response.error) {
        return response.error;
      }
      commit("setBdrFilter", response.data);
      return true;
    },
    actGetRemunerationHistory: async ({ commit }, params) => {
      //
      let response = await ApiBeerOffice.getRemunerationHistory(
        params.year,
        params.month,
        params.regional,
        params.manager,
        params.supervisor,
        params.bdr
      );
      if (response.error) {
        return response.error;
      }
      commit("setRemunerationHistory", response.data);
      return true;
    },
  },
};
