<template>
  <div>
    <!-- Título Desktop y leyenda -->
    <div
      class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0">
        <div class="d-none d-md-flex">
          <p
            class="tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
          >
            Premios
          </p>
        </div>
        <div class="d-none d-md-flex">
          <div class="px-3 p-md-0">
            <p
              class="tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"
            >
              Acumula maltas para redimir premios.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 px-0">
        <div
          class="d-none d-md-flex flex-md-row justify-content-center justify-content-md-end py-0"
        >
          <div
            class="col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 order-first order-md-1 w-50p-md"
          >
            <router-link
              to="/gamification/rewardsHistory"
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
            >
              Historial
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Rango y Barra de Progreso -->
    <div class="container px-3 px-md-3 my-2 mt-md-3 mb-md-5">
      <div class="row align-items-end">
        <div class="col-12 col-md-4">
          <div>
            <p
              class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-30 m-0"
            >
              Tu rango actual
            </p>
            <div
              class="selectdiv position-relative he-60 border-0 tw-rounded-md tw-shadow-sm"
            >
              <select
                class="form-control w-100 h-100 tw-border tw-border-white tw-border-solid shadow-none tw-font-robotoBold"
                @change="onChangeEventRank($event)"
              >
                <option
                  v-for="item in userRanks"
                  :key="item.id"
                  :value="item.id"
                  :selected="
                    item.gami_level_id ===
                    user.current_rank.current.gami_level_id
                  "
                  class="tw-font-robotoBold"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="row d-flex pl-3 mt-md-auto mt-3" id="progress-bar">
            <div class="col-12 d-md-none d-block">
              <div class="row d-md-none d-block px-3">
                <div class="d-flex">
                  <p
                    class="m-0 tw-font-robotoBold tw-text-xs line-height-14 tw-text-green-400 text-uppercase mr-1"
                  >
                    {{
                      Number(user.gamification_points)
                        .toLocaleString()
                        .replace(",", ".")
                    }}
                    puntos
                  </p>
                  <p
                    class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-green-400 text-uppercase"
                  >
                    de tu rango
                  </p>
                </div>
              </div>
              <div class="row px-3 px-md-5 py-2" id="progress-bar">
                <div class="col-md-11 col-10 p-0 position-relative">
                  <div
                    class="he-44 tw-rounded-tl-lg tw-rounded-bl-lg tw-bg-gray-250 w-100 h-100 position-absolute"
                  ></div>
                  <div
                    class="he-44 tw-rounded-lg tw-bg-green-300 h-100 position-absolute"
                    role="progressbar"
                    :style="{ width: this.barProgress + '%' }"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="col-md-1 col-2 p-0">
                  <div
                    class="he-44 tw-rounded-tr-lg tw-rounded-br-lg tw-bg-gray-200 position-relative"
                  >
                    <img
                      src="../../assets/img/gamification/medal-2.png"
                      class="position-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 m-auto"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col px-md-5 px-3 d-flex justify-content-center">
                  <div class="d-flex" v-if="user.current_rank.next !== null">
                    <p
                      class="m-0 tw-font-roboto tw-text-xxs line-height-14 font-italic mr-1"
                    >
                      Te faltan
                      {{
                        Number(
                          this.user.current_rank.next.points -
                            this.user.gamification_points
                        )
                          .toLocaleString()
                          .replace(",", ".")
                      }}
                      puntos para el siguiente rango
                    </p>
                    <p
                      class="m-0 tw-font-roboto tw-text-xxs line-height-14 tw-text-red-400 font-italic"
                    >
                      {{ user.current_rank.next.name }}
                    </p>
                  </div>
                  <div class="d-flex" v-else>
                    <p
                      class="m-0 tw-font-roboto tw-text-xxs line-height-14 font-italic mr-1"
                    >
                      Has alcanzado el rango máximo
                    </p>
                    <p
                      class="m-0 tw-font-roboto tw-text-xxs line-height-14 tw-text-red-400 font-italic"
                    >
                      {{ user.current_rank.current.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-11 col-10 p-0 position-relative d-none d-md-block"
            >
              <div
                class="he-44 tw-rounded-tl-lg tw-rounded-bl-lg tw-bg-gray-250 w-100 h-100 position-absolute"
              ></div>
              <div
                class="he-44 tw-rounded-lg tw-bg-green-300 h-100 position-absolute"
                :style="{ width: this.barProgress + '%' }"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="col-md-1 col-2 p-0 d-none d-md-block">
              <div
                class="he-44 tw-rounded-tr-lg tw-rounded-br-lg tw-bg-gray-200 position-relative"
              >
                <img
                  src="../../assets/img/gamification/medal-2.png"
                  class="position-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 m-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row d-block pl-3">
            <div class="col d-none d-md-flex justify-content-between px-0 pt-1">
              <div class="d-flex">
                <p
                  class="m-0 tw-font-robotoBold tw-text-xs line-height-14 tw-text-green-400 text-uppercase mr-1"
                >
                  {{
                    Number(user.gamification_points)
                      .toLocaleString()
                      .replace(",", ".")
                  }}
                  puntos
                </p>
                <p
                  class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-green-400 text-uppercase"
                >
                  de tu rango
                </p>
              </div>

              <div class="d-flex" v-if="user.current_rank.next !== null">
                <p
                  class="m-0 tw-font-roboto tw-text-xs line-height-14 font-italic mr-1"
                >
                  Te faltan
                  {{
                    Number(
                      this.user.current_rank.next.points -
                        this.user.gamification_points
                    )
                      .toLocaleString()
                      .replace(",", ".")
                  }}
                  puntos para el siguiente rango
                </p>
                <p
                  class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-red-400 font-italic"
                >
                  {{ user.current_rank.next.name }}
                </p>
              </div>
              <div class="d-flex" v-else>
                <p
                  class="m-0 tw-font-roboto tw-text-xs line-height-14 font-italic mr-1"
                >
                  Has alcanzado el rango máximo
                </p>
                <p
                  class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-red-400 font-italic"
                >
                  {{ user.current_rank.current.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Premios -->
    <div class="container px-3 px-md-0 my-2 my-md-5">
      <div class="d-block d-md-none">
        <div class="grid-principal">
          <div
            v-for="reward in filterReward"
            :key="reward.id"
            class="d-flex flex-column"
          >
            <div
              class="cursor-pointer"
              @click="$router.push('/gamification/rewards/' + reward.id)"
            >
              <div class="position-relative">
                <div
                  class="mb-2 carousel-card wi-190 he-190 wi-md-280 he-md-340 overflow-hidden d-flex tw-rounded-3xl position-relative tw-z-1"
                  :class="
                    reward.gami_level_id !==
                    user.current_rank.current.gami_level_id
                      ? 'tw-blur-[2px]'
                      : ''
                  "
                >
                  <img
                    class="h-100"
                    :src="
                      uriResources + '/storage/images/' + reward.images[0].name
                    "
                    alt=""
                  />
                  <img
                    v-if="reward.id === rewardBest[0].id"
                    class="position-absolute tw-top-0 tw-right-5 md:tw-right-7 wi-35 he-45 wi-md-65 he-md-75"
                    :src="imgBand"
                    alt=""
                  />
                </div>
                <div
                  class="h-100 w-100 z-index-2 position-absolute tw-top-0 d-flex justify-content-center align-items-center"
                  v-if="
                    reward.gami_level_id !==
                    user.current_rank.current.gami_level_id
                  "
                >
                  <i class="icon-lockdown tw-text-xxs 0 text-white"></i>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center pt-2">
                <div class="d-flex flex-column">
                  <p
                    class="tw-font-roboto fs-md-18 line-height-md-22 tw-text-gray-600 m-0"
                  >
                    {{ reward.name }}
                  </p>
                  <div class="d-flex align-items-center pt-2">
                    <i
                      class="icon-maltas fs-26 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                    ></i>
                    <p
                      class="d-inline-block m-0 tw-font-roboto tw-text-lg line-height-24 tw-text-gray-500"
                    >
                      {{ reward.malts.toLocaleString().replace(",", ".") }}
                      maltas
                    </p>
                  </div>
                  <div class="pt-2 he-80 he-md-90">
                    <p
                      class="tw-font-roboto tw-text-xs line-height-16 tw-text-gray-500 truncate-text"
                    >
                      {{ reward.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-inline-flex flex-column justify-content-center align-items-center"
              @click="
                redeemProgress(reward.id) < 100 ||
                reward.gami_level_id !== user.current_rank.current.gami_level_id
                  ? ''
                  : goToRedeem(reward)
              "
            >
              <div
                class="tw-rounded-lg tw-bg-gray-500 he-40 w-100 overflow-hidden button-redeem position-relative"
                :class="
                  redeemProgress(reward.id) < 100 ||
                  reward.gami_level_id !==
                    user.current_rank.current.gami_level_id
                    ? ''
                    : 'cursor-pointer'
                "
              >
                <div
                  class="d-inline-flex justify-content-center align-items-center position-absolute tw-z-1 outline-purple h-100 w-100"
                >
                  <p
                    class="tw-font-roboto tw-font-black tw-text-base line-height-20 m-0 text-white"
                  >
                    Redimir
                  </p>
                </div>
                <div
                  class="position-absolute h-100"
                  :class="
                    redeemProgress(reward.id) < 33
                      ? 'tw-bg-red-500'
                      : redeemProgress(reward.id) < 100
                      ? 'tw-bg-yellow-400'
                      : 'tw-bg-green-400'
                  "
                  :style="{
                    width: redeemProgress(reward.id) + '%',
                  }"
                ></div>
              </div>
              <!-- <div class="redeem-hover fadeInTop position-relative">
                <div
                  class="
                    tw-rounded-bl-lg tw-rounded-br-lg
                    tw-shadow-md
                    w-100
                    he-30
                    position-absolute
                    d-inline-flex
                    justify-content-center
                    align-items-center
                    redeem-inside
                  "
                >
                  <i
                    class="
                      icon-maltas
                      tw-text-xl
                      d-inline-block
                      ml-0
                      my-0
                      mr-2
                      tw-text-orange-300
                    "
                  ></i>
                  <p
                    class="d-inline-block m-0 tw-font-roboto tw-text-sm line-height-24"
                    :class="
                      redeemProgress(reward.id) < 33
                        ? 'tw-text-red-500'
                        : redeemProgress(reward.id) < 100
                        ? 'tw-text-yellow-400'
                        : 'tw-text-green-400'
                    "
                  >
                    {{
                      redeemProgress(reward.id) < 33
                        ? "Te faltan " +
                          (reward.malts - user.gamification_maltas)
                            .toLocaleString()
                            .replace(",", ".") +
                          " maltas"
                        : redeemProgress(reward.id) < 100
                        ? "Ya casi lo logras sigue así"
                        : "Estas listo para redimirlo, disfrútalo"
                    }}
                  </p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="carousel pb-5 tw-overflow-x-scroll">
        <div class="d-none d-md-flex">
          <div
            v-for="reward in filterReward"
            :key="reward.id"
            class="d-flex flex-column mx-3"
          >
            <div
              class="cursor-pointer"
              @click="$router.push('/gamification/rewards/' + reward.id)"
            >
              <div class="position-relative">
                <div
                  class="mb-2 carousel-card wi-190 he-190 wi-md-280 he-md-340 overflow-hidden d-flex tw-rounded-3xl position-relative tw-z-1"
                  :class="
                    reward.gami_level_id !==
                    user.current_rank.current.gami_level_id
                      ? 'tw-blur-[2px]'
                      : ''
                  "
                >
                  <img
                    class="h-100"
                    :src="
                      uriResources + '/storage/images/' + reward.images[0].name
                    "
                    alt=""
                  />
                  <img
                    v-if="reward.id === rewardBest[0].id"
                    class="position-absolute tw-top-0 tw-right-5 md:tw-right-7 wi-35 he-45 wi-md-65 he-md-75"
                    :src="imgBand"
                    alt=""
                  />
                </div>
                <div
                  class="h-100 w-100 z-index-2 position-absolute tw-top-0 d-flex justify-content-center align-items-center"
                  v-if="
                    reward.gami_level_id !==
                    user.current_rank.current.gami_level_id
                  "
                >
                  <i class="icon-lockdown tw-text-xxs 0 text-white"></i>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <div class="d-flex flex-column">
                  <p
                    class="tw-font-roboto fs-md-18 line-height-md-22 tw-text-gray-600 m-0"
                  >
                    {{ reward.name }}
                  </p>
                  <div class="d-flex align-items-center pt-2">
                    <i
                      class="icon-maltas fs-26 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                    ></i>
                    <p
                      class="d-inline-block m-0 tw-font-roboto tw-text-lg line-height-24 tw-text-gray-500"
                    >
                      {{ reward.malts.toLocaleString().replace(",", ".") }}
                      maltas
                    </p>
                  </div>
                  <div class="pt-2 he-80 he-md-90">
                    <p
                      class="tw-font-roboto tw-text-xs line-height-16 tw-text-gray-500 truncate-text"
                    >
                      {{ reward.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-inline-flex flex-column justify-content-center align-items-center"
              @click="
                redeemProgress(reward.id) < 100 ||
                reward.gami_level_id !== user.current_rank.current.gami_level_id
                  ? ''
                  : $router.push('/gamification/rewards/' + reward.id)
              "
            >
              <div
                class="tw-rounded-lg tw-bg-gray-500 he-40 w-100 overflow-hidden button-redeem position-relative"
                :class="
                  redeemProgress(reward.id) < 100 ||
                  reward.gami_level_id !==
                    user.current_rank.current.gami_level_id
                    ? ''
                    : 'cursor-pointer'
                "
              >
                <div
                  class="d-inline-flex justify-content-center align-items-center position-absolute tw-z-1 outline-purple h-100 w-100"
                >
                  <p
                    class="tw-font-roboto tw-font-black tw-text-base line-height-20 m-0 text-white"
                  >
                    Redimir
                  </p>
                </div>
                <div
                  class="position-absolute h-100"
                  :class="
                    redeemProgress(reward.id) < 33
                      ? 'tw-bg-red-500'
                      : redeemProgress(reward.id) < 100
                      ? 'tw-bg-yellow-400'
                      : 'tw-bg-green-400'
                  "
                  :style="{
                    width: redeemProgress(reward.id) + '%',
                  }"
                ></div>
              </div>
              <div class="redeem-hover fadeInTop position-relative">
                <div
                  class="tw-rounded-bl-lg tw-rounded-br-lg tw-shadow-md w-100 he-30 position-absolute d-inline-flex justify-content-center align-items-center redeem-inside"
                >
                  <i
                    class="icon-maltas tw-text-xl d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                  ></i>
                  <p
                    class="d-inline-block m-0 tw-font-roboto tw-text-sm line-height-24"
                    :class="
                      redeemProgress(reward.id) < 33
                        ? 'tw-text-red-500'
                        : redeemProgress(reward.id) < 100
                        ? 'tw-text-yellow-400'
                        : 'tw-text-green-400'
                    "
                  >
                    {{
                      redeemProgress(reward.id) < 33
                        ? "Te faltan " +
                          (reward.malts - user.gamification_maltas)
                            .toLocaleString()
                            .replace(",", ".") +
                          " maltas"
                        : redeemProgress(reward.id) < 100
                        ? "Ya casi lo logras sigue así"
                        : "Estas listo para redimirlo, disfrútalo"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-n3">
        <p
          class="tw-font-robotoBold tw-text-sm fs-md-18 line-height-20 d-inline-block"
        >
          Ingresa a
          <i
            class="icon icon-gamimisiones tw-text-lg fs-md-22 tw-text-red-400 ml-1 my-0"
          ></i>
          <u
            class="tw-text-red-400"
            @click="$router.push('/gamification/missions')"
          >
            misiones</u
          >
          y gana suficientes maltas para redimir tus recompensas
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";

export default {
  name: "gamificationRewards",
  components: {},
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgBand: require("@/assets/img/gamification/Bandera-estrella2x.png"),
      filterRank: {},
      filterinit: 0,
      filterReward: [],
      rewardBest: {},
      state: [],
      percentBest: 0,
      isLoading: false,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "La Maltería",
          link: "/gamification/nuevo",
        },
      ],
    };
  },
  created() {
    this.isLoading = true;

    this.filterinit = this.user.current_rank.current.gami_level_id;

    this.actGamiRewards()
      .then(() => {
        this.filterReward = this.rewards.filter((reward) => {
          return (
            reward.gami_level_id ===
            this.user.current_rank.current.gami_level_id
          );
        });
        this.rewardBest = this.filterReward.filter((reward) => {
          return reward.featured === 1;
        });
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.actGamiUserRanks(this.user.prof_profile_id);
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["missions", "rewards", "userRanks"]),
    barProgress() {
      if (
        this.user.current_rank?.next != null ||
        this.user.current_rank?.next !== undefined
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pointsBar =
          (this.user.gamification_points * 100) /
          this.user?.current_rank?.next?.points;
      } else {
        return 100;
      }

      if (this.pointsBar > 100) {
        return 100;
      } else {
        return this.pointsBar;
      }
    },
  },
  methods: {
    ...mapActions("modGamification", [
      "actGamiMissions",
      "actGamiRewards",
      "actGamiUserRanks",
    ]),
    redeemProgress(id) {
      let rewardMalts = this.rewards.filter((reward) => {
        return reward.id === id;
      })[0].malts;
      let userMalts = this.user.gamification_maltas;

      let progress = (1 - (rewardMalts - userMalts) / rewardMalts) * 100;

      if (progress < 0) {
        return 100;
      } else {
        return progress;
      }
    },
    onChangeEventRank() {
      this.filterRank = this.userRanks.filter((userRank) => {
        return userRank.id === event.target.value;
      });
      this.filterReward = this.rewards.filter((reward) => {
        return reward.gami_level_id === this.filterRank[0].gami_level_id;
      });
      this.rewardBest = this.filterReward.filter((reward) => {
        return reward.featured === 1;
      });
    },
    pushDataLayer: function (rank, product_name) {
      let result = this.userRanks.filter(
        (urank) => urank.gami_level_id === rank
      );

      window.dataLayer.push({
        event: "TrackEvent-Gamification",
        category: "Gamification",
        SubCategory: "Recompensas",
        Action: "Clics",
        Rank: result[0].name,
        product: product_name,
      });
    },
    goToRedeem(reward) {
      window.datalayer.push({
        event: "GAEvent",
        event_category: "Juega",
        event_action: "Recompensas",
        event_label: "Ir a Redimir",
        interaction: "True",
        component_name: "Boton",
        element_text: "Redimir",
        reward_name: reward.name,
        reward_price: reward.malts,
      });
      this.$router.push({
        name: "gamificationRedeem",
        params: { id: reward.id },
      });
    },
  },
};
</script>

<style scoped>
.redeem-hover {
  display: none;
  width: 260px;
}

.redeem-hover:hover {
  display: block;
}

.button-redeem:hover + .redeem-hover {
  display: block;
  z-index: 1000;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.grid-principal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
</style>
