var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.MainNavbar),_c(_setup.MainSidebar),_c('portal-target',{attrs:{"name":"modal-thanks-suggestion"}}),_c('portal-target',{attrs:{"name":"modal-suggestions"}}),_c('portal-target',{attrs:{"name":"modal-change-password"}}),_c('portal-target',{attrs:{"name":"outer-modals"}}),_c('div',{staticClass:"tw-pt-16 md:tw-pt-11 md:tw-pl-20 lg:tw-pt-[100px] lg:tw-pl-[70px]",attrs:{"id":"content-body"}},[_c('portal-target',{attrs:{"name":"all-modals"}}),_c(_setup.NpsModal),_c('div',{staticClass:"modal-nps-poll"}),_c(_setup.CompGeneralAdvertising),_c(_setup.ProgressBar,{attrs:{"overlay":""}}),_c('div',[_vm._t("default")],2),(
        _setup.route.path.includes('goflex') &&
        _setup.route.name !== 'goFlexRequestsHistory'
      )?_c(_setup.CompGoFlexStates):_vm._e(),(
        _setup.route.name !== 'NewRequest' ||
        (_setup.route.name !== 'Login' && _setup.navbarStore.isSupportButtonOpen)
      )?_c(_setup.SupportButton):_vm._e(),(
        _setup.route.path.includes('reservations') && _setup.route.name !== 'BookingHistory'
      )?_c(_setup.CompBookingStates):_vm._e(),(_setup.route.path.includes('reservations'))?_c(_setup.CompBookingModal):_vm._e()],1),_c(_setup.TheFooter)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }