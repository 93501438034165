<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0">
    <div class="container px-0 px-md-3 mt-md-4">
      <BreadCrumb :crumbs="breadcrumb" />
    </div>
    <MobileBar title="CONCURSOS" />
    <!-- Título Desktop y leyenda -->
    <div
      class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0 order-md-1 order-last my-0 py-0">
        <div class="d-none d-md-flex py-0">
          <p class="tw-font-tolyerBold fs-md-40 text-uppercase my-0">
            Concursos
          </p>
        </div>
      </div>
    </div>
    <div class="container mb-4">
      <div class="row align-items-center">
        <div class="col categories px-0 py-3 py-md-2">
          <div class="mx-3 d-flex align-items-center">
            <label class="check-group">
              <span class="tw-font-roboto tw-text-gray-400 tw-text-sm"
                >Todos</span
              >
              <input
                type="checkbox"
                value="1"
                v-model="categories"
                v-on:change="filterCompetitionsByDateState()"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="mx-3 d-flex align-items-center">
            <label class="check-group">
              <span class="tw-font-roboto tw-text-gray-400 tw-text-sm"
                >En Curso</span
              >
              <input
                type="checkbox"
                value="2"
                v-model="categories"
                v-on:change="filterCompetitionsByDateState()"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="mx-3 d-flex align-items-center">
            <label class="check-group">
              <span class="tw-font-roboto tw-text-gray-400 tw-text-sm">
                Finalizados
              </span>
              <input
                type="checkbox"
                value="3"
                v-model="categories"
                v-on:change="filterCompetitionsByDateState()"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="row" v-if="isLoading">
        <div class="col-12">
          <div class="text-center mt-3">
            <img src="../../assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
      </div>

      <div v-else class="row row-eq-height px-3 px-md-0">
        <div
          class="col-12 col-md-6 col-lg-4 py-2"
          v-for="data in datospaginados"
          :key="data.id"
        >
          <div @click="pushDataLayer(data)">
            <router-link
              class="router-card"
              :to="'/competition/competition/' + data.id"
              @click.native="dataTrack(data.name)"
            >
              <div
                class="shadow-box-1 bg-white tw-rounded-lg d-flex flex-row overflow-hidden he-200"
              >
                <div
                  class="col-4 overflow-hidden d-flex justify-content-center align-items-center"
                >
                  <!-- <img src="@/assets/img/contest1.png" /> -->
                  <img
                    :src="uriResources + '/' + data.icon"
                    class="tw-h-full"
                    alt=""
                  />
                </div>
                <div
                  class="d-flex flex-column col-8 py-4 px-3 justify-content-between"
                >
                  <div>
                    <div class="d-flex flex-row justify-content-between">
                      <div
                        class="col-78 he-40p d-flex justify-content-start p-0"
                      >
                        <p
                          class="tw-font-robotoMedium tw-text-lg tw-text-black line-height-26"
                        >
                          {{ data.name }}
                        </p>
                      </div>
                      <div class="col-4 he-40p p-0">
                        <CompStars
                          category="Competitions"
                          :idContent="data.id"
                          :ratingData="ratingData[data.id]"
                          :showStars="false"
                          class="float-right"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-row">
                      <p
                        class="tw-font-roboto tw-text-xs tw-text-black pt-2 pt-md-0"
                      >
                        {{ data.short_desc }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="icon-calendario mr-2 tw-text-base"></i>
                      <p
                        class="tw-text-red-400 mr-2 tw-font-robotoBold tw-text-xs line-height-14 p-0 m-0"
                      >
                        Fecha de cierre:
                      </p>
                    </div>
                    <div>
                      <p
                        class="tw-font-robotoBold tw-text-xs line-height-14 tw-text-gray-600 p-0 m-0"
                      >
                        {{ data.end }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-12 my-md-4 my-3">
          <nav>
            <ul class="pagination justify-content-center">
              <li
                class="page-item"
                :class="pagActual > 1 ? 'active' : 'inactive'"
                @click="getPreviousPage()"
              >
                <a
                  class="page-link he-35 wi-30 d-flex justify-content-center align-items-center"
                  href="#"
                >
                  <i class="icon-flecha-izq tw-text-xl"></i>
                </a>
              </li>
              <li
                v-for="(pagina, index) in totalPaginas()"
                :key="index"
                @click="getDataPagina(pagina)"
                class="page-item"
                :class="pagina === pagActual ? 'pagactive' : ''"
              >
                <a class="page-link d-flex he-35 align-items-center" href="#">
                  <p
                    class="tw-font-roboto tw-text-xs line-height-14 m-auto p-0"
                  >
                    {{ pagina }}
                  </p>
                </a>
              </li>
              <li
                class="page-item"
                :class="pagActual < totalPaginas() ? 'active' : 'inactive'"
                @click="getNextPage()"
              >
                <a
                  class="page-link he-35 wi-30 d-flex justify-content-center align-items-center"
                  href="#"
                >
                  <i class="icon-flecha-der tw-text-xl"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- .row -->
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import CompStars from "@/components/rating/CompStars";
import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";

export default {
  name: "CompetitionsView",
  components: {
    CompStars,
    BreadCrumb,
    MobileBar,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      isLoading: false,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Universo de marcas",
          link: "/beer-universe",
        },
        {
          id: 3,
          name: "Mundo de marcas",
          link: "/brands",
        },
        {
          id: 4,
          name: "Concursos",
          link: "/competitions",
          active: true,
        },
      ],
      categories: [],
      filterCompetitions: [],
      v: 0,
      t: 0,
      ratingData: {},
      score: 0,
      elemporpag: 6,
      datospaginados: [],
      pagActual: 1,
    };
  },
  created() {
    this.isLoading = true;
    this.actGetCompetitions()
      .then(() => {
        this.filterCompetitions = this.competitions;
        this.getDataPagina(1);
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.actGetRating("Competitions").then((data) => {
      this.ratingData = data;
    });
  },
  mounted() {
    window.dataLayer.push({
      event: "'trackEvent",
      target: "Sub-secciones",
      action: "concursos",
      "target-properties": "clic",
      value: 5000,
    });
  },
  updated() {
    this.t = 0;
    while (this.t === 0) {
      if (this.competitions !== [] || this.competitions.length === 0) {
        this.refreshCompetitions();
        if (this.competitions !== [] || this.competitions.length === 0) {
          this.t = 1;
        }
        this.t = 1;
      }
    }
  },
  computed: {
    ...mapState("modCompetitions", ["competitions"]),
  },
  methods: {
    ...mapActions("modCompetitions", ["actGetCompetitions"]),
    ...mapActions("modRating", ["actGetRating"]),
    async refreshCompetitions() {
      await this.actGetCompetitions();
    },
    filterCompetitionsByDateState() {
      let today = new Date().toISOString().slice(0, 10);
      if (this.categories.includes("1") || this.categories.length === 0) {
        this.filterCompetitions = this.competitions;
      } else if (this.categories.includes("2")) {
        this.filterCompetitions = this.competitions.filter((competition) => {
          return competition.end >= today;
        });
      } else {
        this.filterCompetitions = this.competitions.filter((competition) => {
          return competition.end < today;
        });
      }
      this.getDataPagina(1);
    },
    totalPaginas() {
      return Math.ceil(this.filterCompetitions.length / this.elemporpag);
    },
    getDataPagina(noPagina) {
      this.pagActual = noPagina;
      this.datospaginados = [];
      let ini = noPagina * this.elemporpag - this.elemporpag;
      let fin = noPagina * this.elemporpag;
      this.datospaginados = this.filterCompetitions.slice(ini, fin);
    },
    getPreviousPage() {
      if (this.pagActual > 1) {
        this.pagActual--;
      }
      this.getDataPagina(this.pagActual);
    },
    getNextPage() {
      if (this.pagActual < this.totalPaginas()) {
        this.pagActual++;
      }
      this.getDataPagina(this.pagActual);
    },
    dataTrack(name) {
      window.dataLayer.push({
        event: "trackEvent",
        eventCategory: "Concursos",
        eventAction: "clic",
        eventLabel: name,
        eventValue: 5000,
      });
    },
    pushDataLayer: function (data) {
      if (this.ratingData[data.id] !== undefined) {
        this.score = this.ratingData[data.id]["score"];
      }
      window.dataLayer.push({
        event: "TrackEvent-UniversoCervecero",
        Category: "Mundo de Marcas",
        SubCategory: "Concursos",
        Action: "Clics",
        Competitions: data.name,
        qualification: this.score,
      });
    },
  },
};
</script>

<style scoped>
.categories {
  display: flex;
  justify-content: center;
  align-items: center;
}

/** Pagination */
.pagination > li > a {
  background-color: white;
  color: black;
  border: none;
  outline: none;
  box-shadow: none;
}

.pagination > .pagactive > a {
  color: #d82b2b !important;
}

.pagination > .pagactive > a > p {
  font-family: RobotoBold, sans-serif;
}

.pagination > .active > a {
  color: white;
  background-color: #d82b2b !important;
}

.pagination > .inactive > a {
  color: white;
  background-color: #bababa !important;
}

/**Checkbox */

.check-group {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 4px #00000042;
  border-radius: 2px;
  opacity: 1;
}

/* When the checkbox is checked, add a blue background */
.check-group input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-group input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-group .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #d82b2b;
  border-width: 0 2px 2px 0;
  /* -webkit-transform: rotate(45deg); */
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (min-width: 768px) {
  .categories {
    justify-content: start;
  }
}
</style>
