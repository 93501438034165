<script setup>
import config from "@/services/config";
import { onBeforeMount, ref } from "vue";
import { useCalendarStore } from "@/stores/calendarStore";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import CompEventsCalendar from "@/components/calendar/CompEventsCalendar.vue";
import CompGeneralSwitch from "@/components/general/CompGeneralSwitch.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import CompGeneralTopCardDropdown from "@/components/cards/CompGeneralTopCardDropdown.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const uriResources = config.beerOfficeURL;
const store = useCalendarStore();
const sliderSelect = ref(false);
const isLoading = ref(false);
const isEventModalOpen = ref(false);
const eventSelected = ref(null);
const selectedCategory = ref(null);

function changeSliderSelect(value) {
  sliderSelect.value = value;
}

function closeEventModal() {
  isEventModalOpen.value = false;
}

function openEventModal(event) {
  eventSelected.value = event;
  isEventModalOpen.value = true;
}

function selectOption(option, event) {
  selectedCategory.value = option;
  if (event === "change") {
    pushDataLayer({
      event_category: "Calendario",
      event_action: "Categorias actividades",
      event_label: option.name,
      component_name: "Click",
    });
  }
}

function goToLink(event) {
  console.log(event);
  const datalayer = {
    event_category: "Calendario",
    event_action: "Interacciones actividad",
    event_label: event.name, //trae el nombre de la actividad seleccionada ej.: Utilidad
    component_name: "Click",
    element_date: event.date, //trae la fecha de la actividad en formato DD/MM/YYYY ej: 22/05/2023
    element_hour: event.start_time, //trae la hora de la actividad ej: 3:00pm
    element_category: event.location, //trae el lugar del evento ej: Zoom
    element_text: event.link_name ? event.link_name : event.link, //trae el cta del botón clicado ej: Conocer más, Link de zoom
  };
  pushLink(event.link, datalayer);
}

onBeforeMount(async () => {
  isLoading.value = true;
  await store.actGetCalendarEventCategories();
  isLoading.value = false;
});
</script>

<template>
  <div>
    <general-modal
      :modalWidth="{ md: 500, lg: 600, xl: 600 }"
      :maxHeight="{ base: 400, md: 400, lg: 500, xl: 400 }"
      modal-name="uploadActivityFile"
      v-if="isEventModalOpen"
      :isShowModal="isEventModalOpen"
      @close="closeEventModal"
    >
      <p class="tw-font-robotoBold tw-text-xl lg:tw-text-3xl tw-text-red-350">
        {{ eventSelected.name }}
      </p>
      <img
        v-if="eventSelected.resource"
        :src="uriResources + '/' + eventSelected.resource"
        class="tw-w-full tw-h-full tw-object-cover tw-max-h-36"
        alt=""
      />
      <span
        class="tw-flex tw-flex-col tw-font-roboto tw-text-xs lg:tw-text-sm"
        >{{ eventSelected.desc }}</span
      >
      <div class="tw-flex tw-flex-col tw-font-roboto tw-text-xs lg:tw-text-sm">
        <span
          ><strong>Fecha: </strong
          >{{ formatDateYYYYMMDDLong(eventSelected.date) }}</span
        >
        <span v-if="eventSelected.location"
          ><strong>Lugar: </strong>{{ eventSelected.location }}</span
        >
        <span v-if="eventSelected.start_time"
          ><strong>Hora: </strong>{{ eventSelected.start_time }}</span
        >
      </div>
      <button-primary-red
        v-if="eventSelected.link"
        @click.native="goToLink(eventSelected)"
        :title="eventSelected.link_name"
      />
      <hr class="tw-w-full tw-my-1" />
      <div class="tw-w-full tw-h-full tw-flex tw-justify-end">
        <img
          src="@/assets/img/splash/icon-inside-2.svg"
          class="tw-object-contain tw-max-h-8"
          alt=""
        />
      </div>
    </general-modal>
    <mobile-bar title="Calendario" />
    <title-bar title="Calendario" />
    <section class="tw-container-general tw-mt-0" v-if="!isLoading">
      <comp-general-top-card-dropdown
        title="Selecciona la categoría"
        image="assets/img/dummy/calendar_image.png"
        bg-color="8541DB"
        description-mobile-inside
        dropdown
      >
        <template #description>
          Aquí, encontrarás actividades diarias que puedes hacer dentro de
          nuestra plataforma. Estas te ayudarán a estar al día con todo lo que
          tenemos para ti. Filtra por categoría y descubre eventos imperdibles.
        </template>
        <template #dropdown>
          <CompGeneralDropdown
            :isLabel="false"
            :fitted="false"
            :options="store.calendarEventCategories"
            icon-visible
            all-option-available
            all-option-text="Todas tus actividades"
            @changeSelectedOption="(option) => selectOption(option, 'change')"
            @chargeOption="(option) => selectOption(option, 'charge')"
          />
        </template>
        <template #dropdownMobile>
          <CompGeneralDropdown
            :isLabel="false"
            :fitted="false"
            :options="store.calendarEventCategories"
            :embeddedMobile="true"
            icon-visible
            all-option-available
            all-option-text="Todas tus actividades"
            @changeSelectedOption="(option) => selectOption(option, 'change')"
            @chargeOption="(option) => selectOption(option, 'charge')"
          />
        </template>
      </comp-general-top-card-dropdown>
      <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
      <comp-general-switch
        option_1="Día"
        option_2="Mes"
        @switch="sliderSelect = $event"
        :switch="sliderSelect"
        class="tw-block lg:tw-hidden tw-mb-5"
      />
      <comp-events-calendar
        :switch="sliderSelect"
        :selected-sub-category="selectedCategory"
        @switch="changeSliderSelect($event)"
        @eventModal="openEventModal($event)"
      />
    </section>
  </div>
</template>

<style scoped></style>
