var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"button",class:[
    _setup.props.roundedSize,
    `tw-${_setup.backgroundColor}`,
    `tw-${_setup.textColor}`,
    `tw-border-${_setup.props.borderColor}`,
    { active: _setup.isActive },
  ]},[_vm._v(" "+_vm._s(_setup.props.text ? _setup.props.text : "Button")+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }