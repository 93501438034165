<script setup>
import { returnMediaURL } from "@/helpers/imageHelpers";
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  linkUrl: {
    type: String,
    required: true,
  },
  linkLabel: {
    type: String,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
});

function goToLink(link, label) {
  pushDataLayer({
    event_category: "Diversidad e Inclusión",
    event_action: "Focos actuales",
    event_label: label,
    component_name: "Click",
  });
  pushLink(link);
}
</script>

<template>
  <div
    class="tw-bg-pink-100 tw-rounded-3xl lg:tw-rounded-4xl lg:tw-shadow-lg tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-p-6 lg:tw-p-4 tw-cursor-pointer"
    @click="goToLink(props.linkUrl, props.linkLabel)"
  >
    <img
      :src="returnMediaURL(image)"
      class="tw-w-full lg:tw-w-auto tw-object-contain lg:tw-object-none"
      alt=""
    />
    <p
      class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-xl tw-text-center"
    >
      {{ title }}
    </p>
  </div>
</template>

<style scoped></style>
