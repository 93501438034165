<template>
  <div id="showMeRanking">
    <div class="layout-content">
      <div class="container p-0 my-md-3">
        <div class="row py-md-2 align-items-center">
          <div class="col-md-7">
            <SecondaryNavbar
              title="Tu Ranking"
              :breadcrumb="
                showVariable(user.core_position.id) === true
                  ? restrictedBreadcrumb
                  : breadcrumb
              "
            />
          </div>
          <div class="col-md-5 d-none d-md-block">
            <CompNavigationOptions
              v-if="!showVariable(user.core_position.id)"
              selected="Ranking"
            />
          </div>
        </div>
        <div class="d-none d-md-block my-5">
          <!-- Ranking General -->
          <div class="row py-3">
            <div
              v-if="
                variableGeneralData !== null &&
                variableGeneralData.data.length > 0
              "
              class="col-12 col-lg-6"
            >
              <div class="cont-ranking">
                <div class="cont-title">
                  <h4
                    class="text-center tw-font-robotoBold tw-text-xl md:tw-text-2xl"
                  >
                    General
                  </h4>
                </div>
                <CompRankingContainer
                  :userName="user.name"
                  :userTitle="user.core_position.name"
                  :userPosition="getUserRanking().regional_position || 0"
                />
                <CompTableGeneral
                  :data="variableGeneralData.data"
                  :positionType="variableGeneralData.level"
                  :userPosition="user.id"
                />
              </div>
            </div>
            <div
              v-if="
                variableGeneralVariableData &&
                variableGeneralVariableData.positions.length > 0
              "
              class="col-12 col-lg-6"
            >
              <div class="cont-ranking">
                <div class="cont-title">
                  <h4
                    class="text-center tw-font-robotoBold tw-text-xl md:tw-text-2xl"
                  >
                    Variable
                  </h4>
                </div>
                <CompRankingContainer
                  :userName="user.name"
                  :userTitle="user.core_position.name"
                  :userPosition="
                    getGeneralVariableData().regional_position || 0
                  "
                />
                <CompTableVariable
                  v-if="variableGeneralVariableData"
                  :data="variableGeneralVariableData.positions"
                  :userPosition="user.id"
                  :positionType="variableGeneralVariableData.level"
                />
              </div>
            </div>
          </div>
          <!-- Ranking Director-->
          <div class="row py-3">
            <div
              v-if="monthData !== null && monthData.positions.length !== 0"
              class="col-12 col-lg-4"
            >
              <CompTableSwitch
                @click="trackMes(switchOptionsMonth.selected)"
                title="Mes"
                :tableNames="tableNames"
                :switchOptions="switchOptionsMonth"
                :tableData="monthData"
                :userId="user.id"
                :userPosition="
                  getQtData !== null ? getMonthData.employee_id : 0
                "
              />
            </div>
            <div
              v-if="qtData !== null && qtData.positions.length !== 0"
              class="col-12 col-lg-4"
              @click="trackTrimester(switchOptionsTrimester.selected)"
            >
              <CompTableSwitch
                title="Trimestre"
                :tableNames="tableNames"
                :switchOptions="switchOptionsTrimester"
                :tableData="qtData"
                :userId="user.id"
                :userPosition="getQtData !== null ? getQtData.employee_id : 0"
              />
            </div>
            <div
              v-if="yearData !== null && yearData.positions.length !== 0"
              class="col-12 col-lg-4"
            >
              <CompTableSwitch
                title="YTD"
                :tableNames="tableNames"
                :switchOptions="switchOptionsYear"
                :tableData="yearData"
                :userId="user.id"
                :userPosition="
                  getYearData !== null ? getYearData.employee_id : 0
                "
              />
            </div>
          </div>
        </div>
        <!-- Start Tabs mobile -->
        <div class="d-block d-sm-block d-md-none">
          <b-tabs
            class="mb-5"
            active-nav-item-class="font-weight-bold font-uppercase text-uppercase text-danger"
            active-tab-class="font-weight-bold"
            content-class="mt-3 pb-2"
          >
            <b-tab
              v-if="
                variableGeneralData !== null &&
                variableGeneralData.data.length > 0
              "
              title="GENERAL"
              active
            >
              <div class="container">
                <div class="row mt-4 mt-md-0">
                  <div class="col-12 col-lg-6">
                    <div class="cont-ranking">
                      <div class="cont-title">
                        <h4
                          class="text-center tw-font-robotoBold tw-text-xl md:tw-text-2xl"
                        >
                          General
                        </h4>
                      </div>
                      <CompRankingContainer
                        :userName="user.name"
                        :userTitle="user.core_position.name"
                        :userPosition="getUserRanking().regional_position || 0"
                      />
                      <CompTableGeneral
                        :data="variableGeneralData.data"
                        :positionType="variableGeneralData.level"
                        :userPosition="user.id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="
                variableGeneralVariableData &&
                variableGeneralVariableData.positions.length > 0
              "
              title="VARIABLE"
            >
              <div class="container">
                <div class="row mt-4 mt-md-0">
                  <div
                    v-if="
                      variableGeneralVariableData &&
                      variableGeneralVariableData.positions.length > 0
                    "
                    class="col-12 col-lg-6"
                  >
                    <div class="cont-ranking">
                      <div class="cont-title">
                        <h4
                          class="text-center tw-font-robotoBold tw-text-xl md:tw-text-2xl"
                        >
                          Variable
                        </h4>
                      </div>
                      <CompRankingContainer
                        :userName="user.name"
                        :userTitle="user.core_position.name"
                        :userPosition="getUserRanking().regional_position || 0"
                      />
                      <CompTableVariable
                        v-if="variableGeneralVariableData"
                        :data="variableGeneralVariableData.positions"
                        :userPosition="user.id"
                        :positionType="variableGeneralVariableData.level"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="monthData !== null && monthData.positions.length !== 0"
              title="Mes"
            >
              <div class="container">
                <div class="row mt-4 mt-md-0">
                  <div class="col-12">
                    <CompTableSwitch
                      title="Mes"
                      :tableNames="tableNames"
                      :switchOptions="switchOptionsMonth"
                      :tableData="monthData"
                      :userId="user.id"
                      :userPosition="getMonthData.employee_id"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="qtData !== null && qtData.positions.length !== 0"
              title="TRIMESTRE"
            >
              <div class="container">
                <div class="row mt-4 mt-md-0">
                  <div class="col-12">
                    <CompTableSwitch
                      title="Trimestre"
                      :tableNames="tableNames"
                      :switchOptions="switchOptionsTrimester"
                      :tableData="qtData"
                      :userId="user.id"
                      :userPosition="getQtData.employee_id"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="yearData !== null && yearData.positions.length !== 0"
              title="YDT"
            >
              <div class="container">
                <div class="row mt-4 mt-md-0">
                  <div class="col-12">
                    <CompTableSwitch
                      title="YTD"
                      :tableNames="tableNames"
                      :switchOptions="switchOptionsYear"
                      :tableData="yearData"
                      :userId="user.id"
                      :userPosition="getYearData.employee_id"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <!-- End Tabs mobile -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";

const CompNavigationOptions = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "compensationContent" */ "@/components/show-me-the-money/variable-compensation/CompNavigationOptions"
  );

const CompRankingContainer = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "compensationContent" */ "@/components/show-me-the-money/variable-compensation/CompRankingContainer"
  );

const CompTableGeneral = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "compensationContent" */ "@/components/show-me-the-money/variable-compensation/CompTableGeneral"
  );

const CompTableVariable = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "compensationContent" */ "@/components/show-me-the-money/variable-compensation/CompTableVariable"
  );

const CompTableSwitch = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "compensationContent" */ "@/components/show-me-the-money/variable-compensation/CompTableSwitch"
  );

export default {
  name: "VariableCompensationRanking",
  components: {
    SecondaryNavbar,
    CompNavigationOptions,
    CompRankingContainer,
    CompTableGeneral,
    CompTableVariable,
    CompTableSwitch,
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modVariableCompensation", [
      "variableGeneralData",
      "variableGeneralVariableData",
      "yearData",
      "monthData",
      "qtData",
    ]),
  },

  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración variable",
          link: "/showMeTheMoney/remuneracion-variable",
        },
        {
          id: 5,
          name: "Ranking",
          link: "/showMeTheMoney/ranking",
          active: true,
        },
      ],
      restrictedBreadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 5,
          name: "Ranking",
          link: "/showMeTheMoney/ranking",
          active: true,
        },
      ],
      tableNames: [
        {
          name: "Posición",
        },
        {
          name: "Regional",
        },
        {
          name: "Unidad de Negocio",
        },
      ],

      switchOptionsMonth: {
        selected: "option1",
        options: [
          { text: "Posiciones", value: "option1" },
          { text: "Mis resultados", value: "option2" },
        ],
      },

      switchOptionsTrimester: {
        selected: "option1",
        options: [
          { text: "Posiciones", value: "option1" },
          { text: "Mis resultados", value: "option2" },
        ],
      },

      switchOptionsYear: {
        selected: "option1",
        options: [{ text: "Posiciones", value: "option1" }],
      },

      allowedAccess: [
        339, 185, 103, 489, 298, 50, 329, 631, 390, 324, 297, 337,
      ],
      mesValue: 0,
      trimValue: 0,
    };
  },
  beforeMount() {
    this.actVariableData();
    this.actGeneralVariableRankingData();
    this.actDirectorVariableRankingData("yr");
    this.actDirectorVariableRankingData("qt");
    this.actDirectorVariableRankingData("month_director");
  },
  mounted() {
    window.dataLayer.push({
      event: "Ranking Compensación Variable",
      Area: this.user.core_vp.name,
      Banda: this.user.core_pa_sideline.name,
      Cargo: this.user.core_position.name,
      Regional: this.user.core_site.core_regional.name,
      Gerencia: this.user.core_organizational_unit.name,
    });
  },
  methods: {
    ...mapActions("modVariableCompensation", [
      "actVariableData",
      "actGeneralVariableRankingData",
      "actDirectorVariableRankingData",
    ]),

    getGeneralVariableData() {
      if (this.variableGeneralVariableData.positions.length > 0) {
        return (
          this.variableGeneralVariableData.positions.find(
            (employee) => employee.employee_id === this.user.id
          ) || null
        );
      } else {
        return null;
      }
    },
    getMonthData() {
      if (this.monthData) {
        return (
          this.monthData.positions.find(
            (employee) => employee.employee_id === this.user.id
          ) || null
        );
      } else {
        return null;
      }
    },
    getQtData() {
      if (this.qtData) {
        return (
          this.qtData.positions.find(
            (employee) => employee.employee_id === this.user.id
          ) || null
        );
      } else {
        return null;
      }
    },
    getYearData() {
      if (this.yearData) {
        return (
          this.yearData.positions.find(
            (employee) => employee.employee_id === this.user.id
          ) || null
        );
      } else {
        return null;
      }
    },

    getUserRanking() {
      if (this.variableGeneralData) {
        return (
          this.variableGeneralData.data.find(
            (employee) => employee.employee_id === this.user.id
          ) || null
        );
      } else {
        return null;
      }
    },

    showVariable(item) {
      return this.allowedAccess.includes(item);
    },
    trackMes(option) {
      if (option === "option1" && this.mesValue === 0) {
        this.mesValue = 1;
        window.dataLayer.push({
          event: "Mes mis resultados CV",
          Area: this.user.core_vp.name,
          Banda: this.user.core_pa_sideline.name,
          Cargo: this.user.core_position.name,
          Regional: this.user.core_site.core_regional.name,
          Gerencia: this.user.core_organizational_unit.name,
        });
      } else {
        window.dataLayer.push({
          event: "Mes Posiciones CV",
          Area: this.user.core_vp.name,
          Banda: this.user.core_pa_sideline.name,
          Cargo: this.user.core_position.name,
          Regional: this.user.core_site.core_regional.name,
          Gerencia: this.user.core_organizational_unit.name,
        });
      }
    },
    trackTrimester(option) {
      if (option === "option1") {
        window.dataLayer.push({
          event: "Trim mis resultados CV",
          Area: this.user.core_vp.name,
          Banda: this.user.core_pa_sideline.name,
          Cargo: this.user.core_position.name,
          Regional: this.user.core_site.core_regional.name,
          Gerencia: this.user.core_organizational_unit.name,
        });
      } else {
        window.dataLayer.push({
          event: "Trim Posiciones CV",
          Area: this.user.core_vp.name,
          Banda: this.user.core_pa_sideline.name,
          Cargo: this.user.core_position.name,
          Regional: this.user.core_site.core_regional.name,
          Gerencia: this.user.core_organizational_unit.name,
        });
      }
    },
  },
};
</script>

<style scoped>
.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.grey {
  color: #bababa;
}

.cont-ranking .cont-table {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.cont-ranking .cont-table table thead tr {
  background: #d6d6d6;
  border-radius: 8px 8px 0 0;
}

.cont-ranking .cont-table thead tr th {
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0;
  color: #343434;
  opacity: 1;
  height: 54px;
  vertical-align: inherit;
}

.cont-ranking .cont-table tbody tr td {
  height: 40px;
  font-size: 14px;
  color: #666666;
}

.b-table-details {
  background-color: #eeeeee !important;
}

.info {
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  font-size: 2rem;
  font-weight: bold;
  /* position relative to absolute position the .tooltip container */
  position: relative;
}

.table th {
  text-align: center;
}

.table tr {
  font-weight: normal;
}

.table td {
  vertical-align: middle;
  text-align: center;
}
.selected-row {
  background-color: #d82b2b;
  color: #ffffff !important;
}

tr.selected-row td {
  color: #ffffff !important;
}

.btn-light {
  background-color: #ffffff;
  color: #666666 !important;
}

.variable-detail {
  background-color: #eeeeee;
  color: #666666;
}

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
  background-color: #eeeeee;
  color: #666666;
}

.table.b-table > tbody > .table-active {
  background-color: #d82b2b;
  color: #ffffff !important;
}

tr.selected-row.b-table-row-selected.table-active.b-table-has-details td {
  background-color: #d82b2b;
  color: #ffffff !important;
}

.table-layer {
  border-radius: 8px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #eeeeee !important;
  margin: 0 auto;
  float: none;
  width: 90%;
  margin-bottom: 12% !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  outline: black;
  background-size: 100%, 100%;

  background-image: none;
}

#showMeRanking .carousel-control-next-icon:after {
  content: ">";
  font-size: 25px;
  color: #999999;
}

#showMeRanking .carousel-control-prev-icon:after {
  content: "<";
  font-size: 25px;
  color: #999999;
}
</style>
