import { render, staticRenderFns } from "./CompProductsCatalog.vue?vue&type=template&id=67d98a14&scoped=true"
import script from "./CompProductsCatalog.vue?vue&type=script&setup=true&lang=js"
export * from "./CompProductsCatalog.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CompProductsCatalog.vue?vue&type=style&index=0&id=67d98a14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d98a14",
  null
  
)

export default component.exports