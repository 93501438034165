<template>
  <div
    class="tw-rounded-lg he-md-580"
    :style="`background-image: url(${uriResources}/${image}); background-size: cover;`"
  >
    <div class="py-4 bg-1 w-100 h-100 d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-8 col-lg-7 mx-auto text-center py-5">
            <p
              class="
                tw-text-white
                fs-md-100 fs-40
                text-uppercase
                tw-font-tolyerBold
                line-height-md-70
              "
            >
              Upss,<br />Embajador
            </p>
            <p class="tw-text-white tw-text-xl fs-md-30">
              Contestaste correctamente <b>{{ responseTrivia.correct }}</b> de
              <b> {{ responseTrivia.total }}</b>
            </p>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 mx-auto">
                <div
                  @click="startTest(1), pushDataLayer('Repetir Trivia')"
                  role="button"
                  class="
                    tw-rounded-lg
                    p-2
                    tw-text-white text-center
                    tw-bg-red-400
                    hover:tw-bg-red-600
                  "
                >
                  Repetir Trivia
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
export default {
  name: "FinishIncompleteTrivia",
  props: {
    responseTrivia: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
    },
    triviaName: {
      type: String,
    },
    calification: {
      type: Number,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  methods: {
    startTest(state) {
      this.$emit("changeOpcVisible", state);
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-trivias",
        category: "Trivias",
        SubCategory: this.triviaName,
        Action: "Paso 3: Finalizó la trivia",
        Label: label,
        Calificacion: this.calification,
      });
    },
  },
  mounted() {
    window.dataLayer.push({
      event: "TrackEvent-trivias",
      category: "Trivias",
      SubCategory: this.triviaName,
      Action: "Paso 3: Finalizó la trivia",
      Label: "Reprobó",
      Calificacion: this.calification,
    });
  },
};
</script>

<style scoped>
.bg-1 {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 10px;
}
</style>