<template>
  <div class="my-3">
    <div class="d-flex justify-content-center">
      <div class="row base-ranking align-items-center">
        <div class="col-2 col-md-2 text-center">
          <img
            class="desc-image"
            src="@/assets/img/show-me/ranking-icon.png"
            alt=""
          />
        </div>
        <div class="col-7 col-md-8 text-left p-md-0 m-md-0">
          <div class="user-name pb-1">{{ userName }}</div>
          <div class="user-title">{{ userTitle }}</div>
        </div>
        <div class="col-3 col-md-2 ml-0 pl-0">
          <div class="base-ranking-box-value">
            <div>
              <div class="place pb-1">Puesto</div>
              <div class="place-value text-center">
                {{ userPosition }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompRankingContainer",
  props: {
    userName: {
      type: String,
      default: "",
    },
    userTitle: {
      type: String,
      default: "",
    },
    userPosition: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped>
.base-ranking {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  width: 100%;
  height: 88px;
}

.base-ranking-box-value {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 42px;
  height: 72px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.place {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0;
  color: #343434;
  opacity: 1;
}

.place-value {
  font: normal normal bold 26px/16px Roboto;
  letter-spacing: 0;
  color: #d82b2b;
  opacity: 1;
}

.user-name {
  font: normal normal bold 18px/16px Roboto;
  letter-spacing: 0;
  color: #d82b2b;
  opacity: 1;

  line-height: 20px;
}

.user-title {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0;
  color: #bababa;
  opacity: 1;
}
</style>
