<script setup>
import { computed } from "vue";
import { capitalizeParagraphFirstLetter } from "@/helpers/textHelpers";

const props = defineProps({
  data: Array,
  title: String,
  collection: Array,
  icon: Boolean,
});

// search name in collection based on props.data
const dataWithName = computed(() => {
  return props.data
    .filter((item) => !item.startsWith("other:"))
    .map((item) => {
      const found = props.collection.find((element) => element.id === item);
      return found ? found.name : found;
    });
});

// search in data if the item has the prefix "other:" and eliminate if the item is ""
const dataWithOther = computed(() => {
  return props.data
    .filter((item) => item.startsWith("other:") && item !== "")
    .map((item) => capitalizeParagraphFirstLetter(item.split(":")[1]));
});

// concatenate both arrays
const concatenatedData = computed(() => {
  return [...dataWithName.value, ...dataWithOther.value];
});
</script>

<template>
  <section
    class="tw-rounded-2xl lg:tw-rounded-3xl tw-border tw-border-gray-350 tw-px-4 tw-py-2 tw-flex tw-flex-col tw-gap-4"
  >
    <p
      class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg tw-text-center"
    >
      {{ props.title }}
    </p>
    <div
      class="tw-w-full tw-flex tw-flex-col tw-flex-wrap tw-gap-2"
      v-if="props.data?.length > 0"
    >
      <span
        v-for="(item, idx) in concatenatedData"
        :key="idx"
        class="tw-font-robotoLight tw-text-sm lg:tw-text-base"
      >
        {{ item }}
      </span>
    </div>
    <div v-else class="tw-w-full">
      <button class="tw-bubble tw-text-xs lg:tw-text-base tw-font-robotoLight">
        Ninguna
      </button>
    </div>
  </section>
</template>

<style scoped></style>
