<script setup>
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import { usePeopleTour } from "@/composables/peopleTour/usePeopleTour";

const { activityIcon } = usePeopleTour();

const props = defineProps({
  stage: {
    type: Object,
    default: () => {},
  },
  activity: {
    type: Object,
    default: () => {},
  },
});
</script>

<template>
  <div
    class="tw-rounded-2xl tw-p-3 lg:tw-p-4 tw-border tw-h-full tw-grid tw-grid-cols-8 tw-gap-2 lg:tw-gap-4"
    :style="'border-color:' + props.stage.color.color"
  >
    <div class="tw-col-span-2 tw-self-center">
      <div
        class="tw-rounded-xl tw-overflow-hidden lg:tw-max-h-36 lg:tw-max-w-36 tw-aspect-h-1 tw-aspect-w-1"
      >
        <img
          :src="props.activity.image"
          class="tw-object-cover tw-w-full tw-h-full"
          alt=""
        />
      </div>
    </div>
    <div class="tw-col-span-4 tw-flex tw-flex-col tw-justify-between">
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-lg"
        :style="'color:' + props.stage.color.color"
      >
        {{ props.activity.title }}
      </p>
      <p
        class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base tw-line-clamp-2"
      >
        {{ props.activity.description.value }}
      </p>
      <div class="tw-flex tw-gap-2 lg:tw-gap-4 tw-items-center">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base"
          :style="'color:' + props.stage.color.color"
        >
          Toins:
        </p>
        <p class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base">
          {{ props.activity.gamification_points }}
        </p>
        <img
          src="@/assets/img/peopleTour/icons/icono-toin.png"
          class="tw-max-w-[1.5rem] lg:tw-max-w-[2rem]"
          alt=""
        />
      </div>
      <div class="tw-flex tw-gap-2 lg:tw-gap-4 tw-items-center">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base"
          :style="'color:' + props.stage.color.color"
        >
          Fecha final:
        </p>
        <p class="tw-m-0 tw-font-robotoBold tw-text-xxs lg:tw-text-base">
          {{ formatDateDDMMYYYY(props.activity.end_date) }}
        </p>
      </div>
    </div>
    <div
      class="tw-col-span-2 tw-flex tw-flex-col tw-gap-2 tw-h-full tw-self-center"
    >
      <img
        :src="
          require('@/assets/img/peopleTour/icons/' +
            activityIcon(props.activity.field_status))
        "
        class="tw-object-contain tw-max-h-16 lg:tw-max-h-24"
        alt=""
      />
      <div
        class="tw-status-bubble"
        :style="'background-color:' + props.stage.color.color"
      >
        <p
          class="tw-m-0 tw-text-xs lg:tw-text-base tw-text-white tw-text-center"
        >
          {{
            props.activity.field_status === "Aprobada"
              ? props.activity.points + " puntos"
              : props.activity.field_status
          }}
        </p>
      </div>
    </div>
  </div>
</template>
