import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    contextualHelpers: []
  },
  mutations: {
    setHelpers(state, helpers) {
      state.contextualHelpers = helpers;
    }
  },
  getters: {
    contextualHelpers: state => state.contextualHelpers
  },
  actions: {
    actGetContextualHelpers: async ({ commit }, route) => {
      let response = await ApiBeerOffice.getContextualHelpers(route);
      if (response.error) {
        return response.error;
      }
      commit("setHelpers", response.data);
      return response.data;
    },
    actPostSeenContextualHelper: async (_, route) => {
      let response = await ApiBeerOffice.postSeenContextualHelper({
        route: route
      });
      if (response.error) {
        return response.error;
      }
      return response;
    },
    actGetSeenContextualHelper: async (_, route) => {
      let response = await ApiBeerOffice.getSeenContextualHelper(route);
      if (response.error) {
        return response.error;
      }
      return response.data;
    }
  }
};
