<script setup>
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCard from "@/components/cards/CompGeneralTopCard.vue";

const goToLink = (link, label, event) => {
  pushLink(link);
  pushDataLayer({
    event_category: "Beneficios a la Carta",
    event_action: "Gointegro",
    event_label: label,
    component_name: "Boton",
    element_text: event.target.innerText,
    redirection_link: link,
  });
};
</script>

<template>
  <div>
    <main>
      <mobile-bar title="Beneficios A La Carta" />
      <title-bar title="Beneficios A La Carta" />
      <section class="tw-container-general">
        <comp-general-top-card
          description="Descubre Beneficios a la Carta donde encuentras beneficios externos exclusivos para ti. ¡Mejora tu calidad de vida con ofertas especiales de empresas colaboradoras! ¡Disfruta de más opciones y cuida de ti!"
          image="assets/img/cb_benefits.png"
          image-right-desk
          title="¡Disfruta más!"
        />
      </section>
      <section class="tw-container-general tw-flex tw-flex-col tw-gap-5">
        <div
          class="tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-w-full tw-p-6 lg:tw-p-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-3 lg:tw-gap-6"
        >
          <div
            class="lg:tw-col-span-3 tw-order-1 lg:tw-order-1 tw-flex tw-justify-center lg:tw-justify-start"
          >
            <img
              alt="cursos"
              class="tw-object-contain"
              src="@/assets/img/gointegro_logo.png"
            />
          </div>
          <div
            class="lg:tw-col-span-3 tw-order-4 lg:tw-order-2 tw-flex lg:tw-justify-end"
          >
            <button
              class="tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full lg:tw-w-max"
              @click="
                goToLink(
                  'https://api.embajadores.com.co/storage/documents/1632327380.pdf',
                  'Go Integro',
                  $event
                )
              "
            >
              Conoce como registrarte en Go Flex
            </button>
          </div>
          <div class="lg:tw-col-span-6 tw-order-2 lg:tw-order-3">
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              Es una plataforma integral de beneficios y lealtad para empleados,
              donde se centralizan los beneficios, descuentos y programas de
              bienestar; ademas facilita el acceso, la comunicación y el
              seguimiento, mejorando así la experiencia del colaborador y
              simplificado la gestión de beneficios.
            </p>
          </div>
          <div
            class="lg:tw-col-span-6 tw-order-3 lg:tw-order-4 tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-3 lg:tw-gap-10"
          >
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-p-5 tw-flex tw-flex-col tw-justify-between tw-gap-6"
            >
              <div class="tw-flex tw-flex-col tw-gap-6">
                <div class="tw-bg-turquoise-200 tw-rounded-2xl tw-p-6">
                  <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                    <img
                      alt="cursos"
                      class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                      src="@/assets/img/cb_gobenefits.png"
                    />
                  </div>
                </div>
                <div class="tw-flex tw-gap-2 tw-justify-center tw-items-center">
                  <i
                    class="icon-tag tw-text-turquoise-200 tw-text-xl lg:tw-text-2xl"
                  />
                  <p
                    class="tw-text-center tw-m-0 tw-font-stark tw-text-base lg:tw-text-xl tw-text-gray-550"
                  >
                    GO benefits
                  </p>
                </div>
                <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                  >Ofrece increíbles descuentos exclusivos en diferentes
                  tiendas, plataformas y restaurantes</span
                >
              </div>
              <button
                class="tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full"
                @click="
                  goToLink(
                    'https://embajadoresbo.gointegro.com/gobenefits/',
                    'Go Benefits',
                    $event
                  )
                "
              >
                ¡Descúbrelo!
              </button>
            </div>
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-p-5 tw-flex tw-flex-col tw-justify-between tw-gap-6"
            >
              <div class="tw-flex tw-flex-col tw-gap-6">
                <div class="tw-bg-turquoise-200 tw-rounded-2xl tw-p-6">
                  <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                    <img
                      alt="cursos"
                      class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                      src="@/assets/img/cb_gorecognition.png"
                    />
                  </div>
                </div>
                <div class="tw-flex tw-gap-2 tw-justify-center tw-items-center">
                  <i
                    class="icon-rating tw-text-turquoise-200 tw-text-xl lg:tw-text-2xl"
                  />
                  <p
                    class="tw-text-center tw-m-0 tw-font-stark tw-text-base lg:tw-text-xl tw-text-gray-550"
                  >
                    GO recognition
                  </p>
                </div>

                <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                  >Reconoce a tu equipo por su excelente desempeño y acciones
                  destacadas dentro de la compañía. esto asignara puntos que
                  podrán ser redimidos en Go Benefits.</span
                >
              </div>
              <button
                class="tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full"
                @click="
                  goToLink(
                    'https://embajadoresbo.gointegro.com/gosocial/recognitions/stream',
                    'Go Recognition',
                    $event
                  )
                "
              >
                ¡Descúbrelo!
              </button>
            </div>
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-p-5 tw-flex tw-flex-col tw-justify-between tw-gap-6"
            >
              <div class="tw-flex tw-flex-col tw-gap-6">
                <div class="tw-bg-turquoise-200 tw-rounded-2xl tw-p-6">
                  <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                    <img
                      alt="cursos"
                      class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                      src="@/assets/img/cb_goflex.png"
                    />
                  </div>
                </div>
                <div class="tw-flex tw-gap-2 tw-justify-center tw-items-center">
                  <i
                    class="icon-rating-full tw-text-turquoise-200 tw-text-xl lg:tw-text-2xl"
                  />
                  <p
                    class="tw-text-center tw-m-0 tw-font-stark tw-text-base lg:tw-text-xl tw-text-gray-550"
                  >
                    GO flex
                  </p>
                </div>
                <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                  >Encuentra el equilibrio perfecto entre tu vida laboral y
                  personal, maximizando tu productividad y bienestar, solicitado
                  días libres.
                  <strong>¡Disponible en Embajadores!</strong></span
                >
              </div>
              <button
                class="tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full"
                @click="goToLink('/goflex', 'Go Flex', $event)"
              >
                ¡Descúbrelo!
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
