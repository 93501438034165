<script setup>
import {
  ref,
  computed,
  onBeforeMount,
  getCurrentInstance,
  onMounted,
} from "vue";
import { useTimeBenefitsStore } from "@/stores/timeBenefitsStore";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import { useRouter } from "vue-router/composables";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import CompTimeBenefitsRequestDetail from "@/components/timeBenefits/CompTimeBenefitsRequestDetail.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const router = useRouter();
const timeBenefitsStore = useTimeBenefitsStore();
const isLoading = ref(false);
const activeIndex = ref(0);
const { $isMobile } = getCurrentInstance().proxy;

const activeRequest = computed(() => {
  return timeBenefitsStore.userTimeBenefitsRequests[activeIndex.value];
});

const colorStatus = (status) => {
  const map = new Map([
    ["PENDING", "tw-bg-yellow-200"],
    ["APPROVED", "tw-bg-green-450"],
    ["COMPLETED", "tw-bg-green-450"],
  ]);
  return map.get(status);
};

const statusName = (name) => {
  const map = new Map([
    ["PENDING", "Pendiente"],
    ["APPROVED", "Aprobada"],
    ["COMPLETED", "Finalizada"],
  ]);
  return map.get(name) || "Pendiente";
};

const selectAccordionItem = (idx) => {
  activeIndex.value = idx;
};

onBeforeMount(async () => {
  isLoading.value = true;
  timeBenefitsStore.userTimeBenefitsRequests.length === 0
    ? await timeBenefitsStore.actGetTimeBenefitsRequests().then(() => {
        isLoading.value = false;
      })
    : (isLoading.value = false);
});

onMounted(async () => {
  await timeBenefitsStore.actGetTimeBenefitsRequests();
});
</script>

<template>
  <div>
    <mobile-bar title="Historial de solicitudes" />
    <title-bar title="Historial de solicitudes" />
    <main class="tw-container-general tw-relative tw-flex tw-flex-col">
      <div class="tw-my-5" v-if="!isLoading">
        <p class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0">
          Tus solicitudes
        </p>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-10 tw-gap-10 lg:tw-gap-20"
        v-if="!isLoading"
      >
        <div class="tw-flex tw-flex-col tw-gap-5 lg:tw-col-span-6">
          <comp-accordion-parent>
            <comp-accordion-item
              v-for="(
                request, idx
              ) in timeBenefitsStore.userTimeBenefitsRequests"
              :id="'request_' + idx"
              :key="idx"
              :border="'tw-border tw-border-gray-250 tw-rounded-2.5xl'"
              :padding="'tw-p-3'"
              :division="false"
              :open-accordion-desk="false"
              @selectAccordionItem="selectAccordionItem(idx)"
            >
              <template v-slot:mainInfo>
                <div class="tw-grid tw-grid-cols-col2_fit_last">
                  <div class="tw-grid tw-grid-rows-1">
                    <p
                      v-if="$isMobile()"
                      class="tw-font-roboto tw-text-xs lg:tw-text-base tw-m-0"
                    >
                      <span
                        class="tw-font-robotoMedium"
                        :class="activeIndex === idx ? 'tw-text-red-350' : ''"
                        >{{
                          formatDateYYYYMMDDLong(request.requested_date)
                        }}</span
                      ><br />
                      <span>{{ request.option_name }}</span>
                    </p>
                    <div class="tw-grid tw-grid-cols-2 tw-w-full" v-else>
                      <p
                        class="tw-font-robotoMedium tw-text-xs lg:tw-text-base tw-m-0"
                        :class="activeIndex === idx ? 'tw-text-red-350' : ''"
                      >
                        {{ formatDateYYYYMMDDLong(request.requested_date) }}
                      </p>
                      <p
                        class="tw-font-roboto tw-text-xs lg:tw-text-base tw-m-0 tw-text-gray-300"
                      >
                        {{ request.option_name }}
                      </p>
                    </div>
                  </div>
                  <div class="tw-h-full tw-flex tw-items-center">
                    <div
                      :class="colorStatus(request.status)"
                      class="tw-status-bubble"
                    >
                      <p class="tw-m-0 tw-text-white">
                        {{ statusName(request.status) }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:secondaryInfo>
                <comp-time-benefits-request-detail
                  :request="activeRequest"
                  @click="
                    () =>
                      router.push({
                        name: 'timeBenefitsUploadVoucher',
                        params: { id: activeRequest.id },
                      })
                  "
                />
              </template>
            </comp-accordion-item>
          </comp-accordion-parent>
        </div>
        <!-- Right Information Request (Only Desk) -->
        <div
          class="tw-hidden lg:tw-flex lg:tw-flex-col lg:tw-gap-3 lg:tw-col-span-4"
        >
          <div
            class="tw-w-full tw-rounded-2.5xl tw-p-6 tw-border tw-border-gray-250"
          >
            <div class="tw-grid tw-grid-cols-col2_fit_last tw-w-full">
              <div class="tw-grid tw-grid-rows-2">
                <p class="tw-font-robotoBold tw-text-sm tw-m-0">
                  {{ activeRequest.option_name }}
                </p>
                <p class="tw-font-roboto tw-text-sm tw-m-0 tw-text-gray-300">
                  {{ activeRequest.requested_date }}
                </p>
              </div>
              <div class="tw-h-full tw-flex tw-items-start">
                <div
                  :class="colorStatus(activeRequest.status)"
                  class="tw-status-bubble"
                >
                  <p
                    class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-white"
                  >
                    {{ statusName(activeRequest.status) }}
                  </p>
                </div>
              </div>
            </div>
            <comp-time-benefits-request-detail
              :request="activeRequest"
              @click="
                () =>
                  router.push({
                    name: 'timeBenefitsUploadVoucher',
                    params: { id: activeRequest.id },
                  })
              "
            />
          </div>
          <button-primary-red
            class="tw-w-full"
            title="Volver a beneficios de familias felices"
            @click.native="router.push({ name: 'timeBenefits' })"
          />
        </div>
      </div>
      <div
        class="tw-sticky lg:tw-static tw-bottom-0 tw-left-0 tw-z-100 tw-pt-2"
      >
        <button-primary-red
          id="mobile_button"
          class="tw-block lg:tw-hidden tw-w-full"
          title="Volver a beneficios de familias felices"
          @click.native="router.push({ name: 'timeBenefits' })"
        />
      </div>
    </main>
  </div>
</template>

<style scoped></style>
