<template>
  <div id="bonusHome">
    <div class="ml-0 md:tw-ml-20 ml-xl-0">
      <!-- Miga de pan -->
      <div class="container px-0 px-md-3 mt-md-4">
        <BreadCrumb :crumbs="breadcrumb" />
      </div>
      <MobileBar title="BONO: INCENTIVO" />
      <!-- Título Desktop y leyenda -->
      <div class="container px-3 px-md-3 mt-2 mt-md-5 mb-4 mb-md-0">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-none d-md-flex">
              <p class="tw-font-tolyerBold fs-md-40 text-uppercase">
                Simulador Bono {{ this.$route.params.bonusType }}
              </p>
            </div>
            <div class="d-flex">
              <div class="px-3 p-md-0">
                <p
                  class="tw-font-roboto tw-text-sm fs-md-16 line-height-20 tw-text-gray-400"
                  v-if="
                    employeeCorePosition === '0-IV' ||
                    employeeCorePosition === 'V-VI'
                  "
                >
                  Tu bono ABI es un incentivo anual calculado de acuerdo a los
                  resultados globales, de la BU Colombia y al cumplimiento de
                  tus metas individuales
                </p>
                <p
                  class="tw-font-roboto tw-text-sm fs-md-16 line-height-20 tw-text-gray-400"
                  v-if="
                    employeeCorePosition === 'VII-VIII' ||
                    employeeCorePosition === 'VIII+'
                  "
                >
                  El incentivo de corto plazo es el valor que la compañía
                  reconoce a sus trabajadores con el pago de días adicionales de
                  salario de acuerdo al Achievement Score del área a la cual
                  pertenece.
                </p>
              </div>
            </div>
          </div>
          <comp-bonus-abi-steps
            :step="step"
            :employeeCorePosition="employeeCorePosition"
          />
        </div>
      </div>

      <!-- Contenedor Principal STI -->
      <!-- Range 7 to 8+ -->
      <div class="container px-3 px-md-3 my-4 my-md-5">
        <div
          class="shadow-box-3 tw-rounded-lg px-3 py-4 p-md-5 d-flex flex-column flex-md-row"
          v-if="
            employeeCorePosition === 'VII-VIII' ||
            employeeCorePosition === 'VIII+'
          "
        >
          <div class="col-12 col-md-6">
            <div>
              <div class="row mb-2">
                <div class="col-md-12">
                  <p
                    class="tw-font-robotoBold fs-md-24 line-height-md-40 d-none d-md-block"
                  >
                    Bono STI
                  </p>
                  <p
                    class="tw-font-robotoBold tw-text-lg line-height-24 text-uppercase d-block d-md-none"
                  >
                    Bono STI
                  </p>
                </div>
              </div>
              <div
                class="row mb-2"
                v-if="
                  employeeCorePosition !== 'VIII+' &&
                  user.core_class_employee.name !== 'Planta'
                "
              >
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-ranking-2 fs-26"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Achievement Score
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Es el porcentaje que tiene en cuenta los resultados
                    globales, de la BU Colombia y las metas individuales del
                    Director de tu área.
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-percentage fs-24"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Bonus Target
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                    v-if="
                      (employeeCorePosition === 'VII-VIII' ||
                        employeeCorePosition === 'VIII+') &&
                      user.core_class_employee.name === 'Planta'
                    "
                  >
                    Porcentaje de bono según la posición del ranking que ocupe
                    la planta en la que te encuentras.
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                    v-else
                  >
                    Porcentaje de bono de acuerdo a tu banda.
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-anualsal fs-26"></i>
                  </div>
                </div>
                <div
                  class="col-9 col-md-10"
                  v-if="employeeCorePosition === 'VII-VIII'"
                >
                  <p class="tw-font-robotoBold fs-18 line-height-20 mb-2">
                    Salario Anual
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Salario básico + prestaciones legales y primas x 12
                  </p>
                </div>
                <div
                  class="col-9 col-md-10"
                  v-if="employeeCorePosition === 'VIII+'"
                >
                  <p class="tw-font-robotoBold fs-18 line-height-20 mb-2">
                    Salario Anual
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Salario Básico multiplicado por 13.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-worktime fs-30"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-1">
                    Dedicación de tiempo
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Tiempo laborado en el año (Para ingresos anteriores al 1 de
                    julio)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mx-auto mt-3 my-md-auto">
              <!-- Range 7 to 8+ Calculator -->
              <slot>
                <!-- None Administrative Calculator -->
                <slot v-if="user.core_class_employee.name !== 'Administrativo'">
                  <CalculatorNonAdministrative
                    class="pt-3 pb-3 pl-md-4"
                    :employeeCorePosition="employeeCorePosition"
                  />
                </slot>
                <!-- Administrative Calculator -->
                <slot v-else>
                  <CalculatorAdministrative
                    class="pt-3 pb-3 pl-md-4"
                    :employeeCorePosition="employeeCorePosition"
                  />
                </slot>
              </slot>
            </div>
          </div>
        </div>
      </div>

      <!-- Contenedor Principal ABI -->
      <!-- Range 0 to 6 -->
      <div class="container px-3 px-md-3 my-4 my-md-5">
        <div
          class="shadow-box-3 tw-rounded-lg px-3 py-4 p-md-5 d-flex flex-column flex-md-row"
          v-if="
            employeeCorePosition === '0-IV' || employeeCorePosition === 'V-VI'
          "
        >
          <div class="col-12 col-md-6" v-if="step === 1">
            <div>
              <div class="row mb-2">
                <div class="col-md-12">
                  <p
                    class="tw-font-robotoBold fs-md-24 line-height-md-40 d-none d-md-block"
                  >
                    Achievement Score
                  </p>
                  <p
                    class="tw-font-robotoBold tw-text-lg line-height-24 text-uppercase d-block d-md-none"
                  >
                    Achievement Score
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-sizeofpie fs-26"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Size of Pie
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Determina el valor disponible para el pago de bonos, está
                    dado por el crecimiento orgánico del EBITDA a nivel global
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-entityperformance fs-24"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Entity Performance
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Refleja la contribución de la BU Colombia a los resultados
                    de ABI (EBITDA Cash flow and Net Revenue)
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-individualperformance fs-26"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Individual Performance
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Cumplimiento de tus metas individuales
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-discincentive fs-30"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-1">
                    Discretionary Incentive
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Valor discrecional que puede oscilar entre un 70 y 130 %
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6" v-if="step === 2">
            <div>
              <div class="row mb-2">
                <div class="col-md-12">
                  <p
                    class="tw-font-robotoBold fs-md-24 line-height-md-40 d-none d-md-block"
                  >
                    Bono ABI
                  </p>
                  <p
                    class="tw-font-robotoBold tw-text-lg line-height-24 text-uppercase d-block d-md-none"
                  >
                    Bono ABI
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-percentage fs-26"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Achievement Score
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Es el porcentaje que tiene en cuenta los resultados
                    globales, de la BU Colombia y las metas individuales.
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-anualsal fs-24"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold tw-text-lg line-height-20 mb-2">
                    Bonus Target
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Porcentaje de bono acorde a tu banda
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-worktime fs-26"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold fs-18 line-height-20 mb-2">
                    Salario Anual
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Salario básico + prestaciones legales y primas x 12
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 col-md-2">
                  <div
                    class="circle d-flex wi-50 he-50 justify-content-center align-items-center tw-bg-yellow-300 tw-rounded-full"
                  >
                    <i class="icon-tdr fs-30"></i>
                  </div>
                </div>
                <div class="col-9 col-md-10">
                  <p class="tw-font-robotoBold fs-18 line-height-20 mb-1">
                    TDR
                  </p>
                  <p
                    class="tw-font-roboto fs-14 line-height-18 tw-text-gray-400"
                  >
                    Tiempo laborado en la posición durante el año a evaluar
                    (Para ingresos anteriores al 1 de Octubre)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mx-auto mt-3 my-md-auto">
              <!-- Range 0 to 6 Calculator -->
              <slot>
                <AchievementScoreRange0to6
                  class="pt-3 pb-3 pl-md-4"
                  v-if="step === 1"
                  :error="error"
                  :bonusPropsRange0to6="bonusPropsRange0to6"
                  :calculate-achievement-score="calculateAchievementScore"
                  :calculate-abi-value-range0to6="calculateAbiValueRange0to6"
                  :employeeCorePosition="employeeCorePosition"
                  @changeStep="step = 2"
                />
                <CalculatorRange0to6
                  class="pt-3 pb-3 pl-md-4"
                  v-if="step === 2"
                  :error="error"
                  :bonusPropsRange0to6="bonusPropsRange0to6"
                  :calculate-achievement-score="calculateAchievementScore"
                  :calculate-abi-value-range0to6="calculateAbiValueRange0to6"
                  :employeeCorePosition="employeeCorePosition"
                  :calculated-anual-salary="calculatedAnualSalary"
                  @changeStep="step = 1"
                  @updateTdrTime="updateTdrTime"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import userRemunerationMixin from "@/mixins/user/remuneration";
import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import CalculatorAdministrative from "@/components/show-me-the-money/calculator/CalculatorAdministrative.vue";
import CalculatorNonAdministrative from "@/components/show-me-the-money/calculator/CalculatorNonAdministrative.vue";
import AchievementScoreRange0to6 from "@/components/show-me-the-money/calculator/AchievementScoreRange0to6.vue";
import CalculatorRange0to6 from "@/components/show-me-the-money/calculator/CalculatorRange0to6.vue";
import { error, changeAchievementScoreValue } from "@/helpers/bonusHelpers";
import CompBonusAbiSteps from "@/components/show-me-the-money/calculator/CompBonusAbiSteps.vue";

export default {
  name: "StiBonus",
  mixins: [userRemunerationMixin],
  components: {
    CompBonusAbiSteps,
    BreadCrumb,
    MobileBar,
    CalculatorAdministrative,
    CalculatorNonAdministrative,
    AchievementScoreRange0to6,
    CalculatorRange0to6,
  },
  mounted() {
    window.dataLayer.push({
      event: "'trackEvent",
      target: "Sub-secciones",
      action: "Bono-sti",
      "target-properties": "clic",
      value: 5000,
    });
  },
  data() {
    return {
      error,
      helpText: "Selecciona un objeto de la fórmula para ver su detalle...",
      step: 1,
      tabIndex: 0,
      env: process.env.VUE_APP_ENVIRONMENT,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Simulador Bono",
          link: "/showMeTheMoney/fixedCalculator",
          active: true,
        },
      ],
      bonusPropsRange0to6: {
        sizeOfPie: 100,
        entityPerformance: 100,
        individualPerformance: 100,
        achievementScore: 0,
        discretionaryIncentive: 100,
        mrc: 0,
        bonusTarget: 0,
        tdrTime: 0,
      },
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
    calculateAchievementScore() {
      this.changeAchievementScoreValue(this.bonusPropsRange0to6);
      if (
        this.bonusPropsRange0to6.sizeOfPie === 0 &&
        this.bonusPropsRange0to6.entityPerformance === 0
      ) {
        return 0;
      } else if (this.bonusPropsRange0to6.individualPerformance === 0) {
        return 0;
      } else {
        // this.bonusPropsRange0to6.achievementScore =
        //   this.toPercent(
        //     this.bonusPropsRange0to6.sizeOfPie * 0.7 +
        //       this.bonusPropsRange0to6.entityPerformance * 0.3
        //   ) *
        //   ((this.toPercent(this.bonusPropsRange0to6.entityPerformance) +
        //     this.toPercent(this.bonusPropsRange0to6.individualPerformance)) /
        //     2) *
        //   this.toPercent(this.bonusPropsRange0to6.discretionaryIncentive);
        return (this.bonusPropsRange0to6.achievementScore * 100) | 0;
      }
    },
    calculateAbiValueRange0to6() {
      if (this.bonusPropsRange0to6.achievementScore === 0) {
        return 0;
      } else {
        return (
          this.bonusPropsRange0to6.achievementScore *
          (Number(this.user.anual_salary) +
            Number(this.user.legal_premium) +
            Number(this.user.extra_legal_premium)) *
          parseFloat(this.user.target.replace(",", ".")) *
          this.bonusPropsRange0to6.tdrTime
        );
      }
    },
    calculatedAnualSalary() {
      return (
        Number(this.user.anual_salary) +
        Number(this.user.legal_premium) +
        Number(this.user.extra_legal_premium)
      );
    },
  },
  methods: {
    changeAchievementScoreValue,
    updateTdrTime(value) {
      this.bonusPropsRange0to6.tdrTime = value;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

input {
  text-align: left;
}
.calculator-path a {
  font: 14px/24px Roboto !important;
  letter-spacing: 0;
  color: #0f0e0e !important;
  opacity: 1;
}
</style>
