<template>
  <div class="tw-container-title">
    <ButtonsPeopleCycle
      :peopleCycleButtonsActive="3"
      class="py-3 mb-2 mb-md-4"
    />
    <PCNavigate :pc_item="pc_item" />
    <!-- ALL CONTENT -->
    <div id="general-wrapper" class="container-md pb-5">
      <div v-if="isLoading" class="mb-5">
        <div class="text-center mt-3">
          <img alt="loading" src="../../../assets/img/icons/loading.gif" />
        </div>
      </div>
      <div v-else>
        <div class="tw-shadow-lg tw-rounded-lg d-flex flex-column p-0">
          <!-- People Cycle Title and Description-->
          <div
            :style="'background-color: ' + peopleCycleItem.color"
            class="tw-shadow-lg tw-rounded-lg he-200"
          >
            <div class="container h-100 d-flex align-items-md-center">
              <div class="row align-items-center px-3 px-md">
                <div class="col-3 col-md-2 text-center order-2 order-md-1">
                  <img
                    :src="uriResources + '/' + peopleCycleItem.icon"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  class="col-9 col-md-4 text-uppercase tw-font-tolyerBold text-white p-0 order-1 order-md-2"
                >
                  <p
                    class="fs-44 fs-md-70 line-height-40 line-height-md-60 p-0 m-0"
                  >
                    {{ peopleCycleItem.title }}
                  </p>
                </div>

                <div class="col-12 col-md-5 order-3 p-0">
                  <p
                    class="text-white tw-font-robotoLight tw-text-xs fs-md-20 line-height-18 line-height-md-24 text-md-right pl-md-4"
                  >
                    {{ peopleCycleItem.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- People Cycle Main Image-->
          <div class="px-3 px-md-5 py-5">
            <div class="text-center position-relative">
              <img
                :src="uriResources + '/' + peopleCycleItem.image"
                class="img-fluid"
                alt=""
              />
              <img
                class="h-10 h-md-100 tag"
                src="@/assets/img/peopleCycle/cc_icon_pdp.png"
                alt=""
              />
            </div>
          </div>
          <!-- People Cycle Sub Title and Sub Description-->
          <div
            v-if="
              peopleCycleItem.sub_title !== null ||
              peopleCycleItem.sub_description !== null
            "
            class="px-3 px-md-5 py-5"
          >
            <div class="row justify-content-center">
              <div class="col-md-8 text-center position-relative px-3 px-md-5">
                <p class="tw-font-robotoBold text-uppercase fs-md-28">
                  {{ peopleCycleItem.sub_title }}
                </p>
              </div>

              <div class="col-md-8 text-center position-relative px-3 px-md-5">
                <p class="tw-font-roboto fs-md-20 line-height-md-24">
                  {{ peopleCycleItem.sub_description }}
                </p>
              </div>
            </div>
          </div>
          <!-- People Cycle Sub Items-->
          <div
            v-if="peopleCycleItem.people_cycle_sub_item.length > 0"
            class="px-3 px-md-5"
          >
            <div
              class="row justify-content-around justify-content-md-center px-3 px-md-5 py-3 pt-md-3"
            >
              <div
                v-for="item in peopleCycleItem.people_cycle_sub_item"
                :key="item.id"
                class="col-12 col-md m-1 m-md-3 tw-rounded-lg shadow-box-6 bg-white p-1 p-md-3 d-flex flex-md-column flex-row align-items-center"
              >
                <div class="overflow-hidden text-center">
                  <img
                    :src="uriResources + '/' + item.icon"
                    class="img-fluid p-2"
                    alt=""
                  />
                </div>
                <div class="overflow-hidden text-center w-80 w-md-100">
                  <p
                    class="tw-font-roboto tw-text-xxs fs-md-16 tw-font-bold py-2 m-0"
                  >
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- CALENDAR -->
        <TabPeople
          v-if="peopleCycleItem.indicator"
          :items="peopleCycleItem.indicator"
        />

        <!-- STEPS -->
        <div v-if="peopleCycleItem.steps.length > 0" class="mt-md-5">
          <div class="row">
            <div class="tw-rounded-lg col-12 col-md-12 py-4">
              <div class="text-center px-2 px-md-3 pt-3">
                <p class="tw-font-tolyerBold fs-34 fs-md-50">
                  {{ peopleCycleItem.steps[0].title }}
                </p>
              </div>
              <div class="container">
                <div
                  class="tw-grid tw-grid-cols-2 tw-justify-items-center lg:tw-grid-flow-col lg:tw-auto-cols-fr tw-gap-4"
                >
                  <div
                    v-for="item in peopleCycleItem.steps[0]
                      .people_cycle_step_card"
                    :key="item.id"
                    class="tw-h-full tw-rounded-2xl tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4 tw-items-center tw-text-center tw-overflow-hidden tw-p-5 lg:tw-px-6 lg:tw-py-8"
                    :style="
                      'color: ' +
                      item.color_content +
                      '; background-color: ' +
                      item.color_background +
                      '; border: 2px solid ' +
                      item.color_content
                    "
                  >
                    <p
                      v-if="item.number_item_active"
                      class="tw-font-tolyerBold tw-text-4xl lg:tw-text-8xl tw-m-0"
                    >
                      {{ item.number_item }}
                    </p>
                    <div
                      v-if="item.icon_active"
                      class="tw-text-center tw-w-2/3 tw-mx-auto"
                    >
                      <img
                        :src="uriResources + '/' + item.icon"
                        alt=""
                        class="tw-w-full"
                      />
                    </div>
                    <p
                      v-if="item.title_active"
                      class="tw-font-tolyerBold tw-text-2xl lg:tw-text-3xl tw-m-0"
                    >
                      {{ item.title }}
                    </p>
                    <p
                      v-if="item.sub_title_active"
                      class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-font-medium tw-m-0"
                    >
                      {{ item.sub_title }}
                    </p>
                    <p
                      v-if="item.description_active"
                      class="tw-font-roboto tw-text-xxs lg:tw-text-xs tw-m-0"
                    >
                      {{ item.description }}
                    </p>
                  </div>
                </div>
                <div
                  class="d-flex flex-row justify-content-end mt-3 mb-5 my-md-3 mb-2"
                >
                  <div class="col-md-2 col px-0">
                    <div
                      class="tw-text-sm fs-md-14 line-height-16 tw-font-roboto hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-3 w-100 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center tw-cursor-pointer"
                      @click="pushLink(peopleCycleItem.steps[0].url)"
                    >
                      {{ peopleCycleItem.steps[0].url_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsPeopleCycle from "@/components/general/ButtonsPeopleCycle";
import PCNavigate from "@/components/general/PCNavigate";
import TabPeople from "@/components/people/tabs/TabPeople";
import linksMixin from "@/mixins/general/links";
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import cvMixin from "@/mixins/user/cv";
import people from "@/mixins/user/people";

export default {
  name: "PDP",
  mixins: [cvMixin, people, linksMixin],
  components: {
    ButtonsPeopleCycle,
    PCNavigate,
    TabPeople,
  },
  data() {
    return {
      isLoading: true,
      uriResources: config.beerOfficeURL,
      pc_item: 5,
    };
  },
  async created() {
    this.isLoading = true;

    this.actGetPeopleCycleItem({
      id: 5,
    })
      .then(() => {})
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modPeopleCycle", ["peopleCycleItem"]),
  },
  methods: {
    ...mapActions("modPeopleCycle", ["actGetPeopleCycleItem"]),
  },
};
</script>

<style scoped>
.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>
