<script setup>
import {
  computed,
  getCurrentInstance,
  ref,
  onMounted,
  onBeforeMount,
} from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import ClaimModal from "@/components/ecommerce/ClaimModal";
import BirthdayModal from "@/components/ecommerce/BirthdayModal.vue";
import CompOrderModal from "@/components/general/CompOrderModal";
import ChangePassModal from "@/components/general/ChangePassModal";
import CompHomeBeatSplash from "@/components/general/CompHomeBeatSplash.vue";
import CompNewsContainer from "@/components/news/CompNewsContainer.vue";
import { useCoreStore } from "@/stores/coreStore";

const isLoading = ref(false);
const gif = ref("");
const gifActive = ref(false);
const userStore = useUserStore();
const ecommerceStore = useEcommerceStore();
const { user } = storeToRefs(userStore);
const { actGetUserById } = userStore;
const { configurations } = storeToRefs(useCoreStore());

const showBirthdayModal = ref(false);
const showClaimModal = ref(false);
const { $bvModal } = getCurrentInstance().proxy;

const loginSSO = computed(() => {
  return process.env.VUE_APP_LOGIN_SSO;
});

const updateDeliverySite = async (deliverySite) => {
  await userStore
    .actDeliverySiteUpdate(user.value.id, deliverySite)
    .then(() => {
      showClaimModal.value = false;
      $bvModal.hide("modal-claim");
    })
    .finally(() => {
      showClaimModal.value = true;
      $bvModal.show("modal-birthday");
    });
};

const validateBirthdayAtCharge = () => {
  let birthMonth = new Date(user.value.birthdate).getMonth() + 1;
  let actualMonth = new Date().getMonth() + 1;
  ecommerceStore.actValidateBirthday().then(() => {
    if (user.value.ecomm_delivery_site_id == null) {
      if (birthMonth === actualMonth) {
        showBirthdayModal.value = true;
      }
    } else {
      if (
        birthMonth === actualMonth &&
        ecommerceStore.birthdayGiftStatus.permitted === false
      ) {
        showBirthdayModal.value = true;
      }
    }
  });
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetUserById(user.value.id);
  const loadingFile = configurations.value.find(
    (item) => item.key === "LOADING_GIF"
  );
  gif.value = loadingFile.value;
  const loadingGifActive = configurations.value.find(
    (item) => item.key === "LOADING_GIF_ACTIVE"
  );
  gifActive.value = loadingGifActive.value;

  setTimeout(() => {
    isLoading.value = false;
  }, 5000);
});

onMounted(() => {
  window.dataLayer.push({
    event: "trackEvent",
    target: "Sub-secciones",
    action: "Inicio",
    "target-properties": "clic",
    value: 5000,
  });
  validateBirthdayAtCharge();
});
</script>
<template>
  <div class="tw-flex">
    <portal to="modal-change-password">
      <ChangePassModal v-if="loginSSO === 'false'" />
    </portal>
    <CompOrderModal />
    <comp-home-beat-splash v-if="isLoading && gifActive" :gif="gif" />
    <div class="tw-container-general tw-z-10" v-if="!isLoading">
      <aside id="modals">
        <birthday-modal v-if="showBirthdayModal" />
        <claim-modal
          v-if="showClaimModal"
          @updateDeliverySite="updateDeliverySite"
        />
      </aside>
      <comp-news-container />
    </div>
  </div>
</template>
