<script setup>
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import { getCurrentInstance, onBeforeMount, ref } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router/composables";
import ProductCard from "@/components/cards/ProductCard.vue";
import { capitalizeEachWord } from "@/helpers/textHelpers";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import SellConfirmModal from "@/components/ecommerce/SellConfirmModal";
const { $bvModal } = getCurrentInstance().proxy;

const router = useRouter();
const uriResources = config.beerOfficeURL;
const userStore = useUserStore();
const ecommerceStore = useEcommerceStore();
const isLoading = ref(false);
const isLoadingModal = ref(false);
const max_day_gift = ref(null);

const datef = () => {
  let current_datetime = new Date();
  let formatted_date =
    current_datetime.getFullYear() +
    "" +
    (current_datetime.getMonth() + 1) +
    "" +
    current_datetime.getDate();
  let ale = Math.floor(Math.random() * 1000);
  return formatted_date + "" + ale;
};

const changeProduct = () => {
  ecommerceStore.subsProductToShoppingCart(ecommerceStore.gift);
  router.push({
    name: "MonthProduct",
  });
};

const sellConfirm = () => {
  isLoadingModal.value = true;
  $bvModal.show("modal-confirm-sell");
};

const sendShoppingCart = async () => {
  const order = {
    id: userStore.user.id,
    cc: userStore.user.cc,
    ecomm_deliver_site_id: userStore.user.ecomm_delivery_site_id,
    gift_product: ecommerceStore.gift.id,
    payment: 1,
  };

  await ecommerceStore.actPostShoppingProducts(order).then(() => {
    isLoadingModal.value = false;
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            "Forma de pago": "Descuento de Nomina",
            id: datef,
          },
          products: ecommerceStore.gift,
        },
      },
    });
    router.push({
      name: "CartThankYou",
    });
  });
};

onBeforeMount(async () => {
  isLoading.value = true;

  max_day_gift.value = ecommerceStore.products.filter(
    (product) => product.name === "OBSEQUIO"
  )[0].max_day;

  isLoading.value = false;
});
</script>

<template>
  <div>
    <SellConfirmModal
      @close="isLoadingModal = false"
      @shop="sendShoppingCart"
    />
    <mobile-bar title="Tienda" />
    <title-bar title="Tienda" />
    <main class="tw-container-general" v-if="!isLoading">
      <p class="tw-font-robotoBold tw-text-base lg:tw-text-2xl">
        CARRITO DE COMPRA
      </p>
      <div class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-6">
        <p
          class="tw-text-center tw-font-robotoBold tw-text-xs lg:tw-text-lg tw-m-0"
        >
          ¿Estás seguro de que deseas elegir este obsequio para este mes?
        </p>
        <div class="tw-flex tw-justify-center">
          <product-card class="tw-max-w-[250px]">
            <template #image>
              <img
                :src="`${uriResources}/${ecommerceStore.gift.img}`"
                :alt="ecommerceStore.gift.name"
                class="tw-w-full tw-aspect-1 tw-object-cover tw-rounded-xl lg:tw-rounded-2xl"
              />
            </template>
            <template #title>
              <p class="tw-font-robotoBold tw-m-0 tw-text-center">
                {{ capitalizeEachWord(ecommerceStore.gift.name) }}
              </p>
            </template>
            <template #description>
              <p
                class="tw-font-roboto tw-text-xxs lg:tw-text-xs tw-m-0 tw-text-center"
              >
                Reclama en el site que seleccionaste.<br />*Sujeto a términos y
                condiciones.
              </p>
            </template>
            <template #price>
              <p class="tw-font-roboto tw-text-sm lg:tw-text-base tw-m-0">
                <span class="tw-font-robotoBold">Precio:</span> ¡OBSEQUIO!
              </p>
            </template>
            <template #button>
              <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                <comp-button-custom
                  text="Cambiar"
                  class="tw-w-full"
                  @click.native="changeProduct"
                />
                <button-primary-red
                  title="Reclamar"
                  class="tw-w-full"
                  @click.native="sellConfirm"
                />
              </div>
            </template>
          </product-card>
        </div>
        <p
          class="tw-text-center tw-font-robotoLight tw-text-xs lg:tw-text-base tw-italic tw-m-0"
        >
          *Recuerda que solo puedes pedir tu obsequio antes del día 10 de cada
          mes
        </p>
      </div>
    </main>
  </div>
</template>

<style scoped></style>
