var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4 lg:tw-gap-10"},[_vm._m(0),_c('div',{staticClass:"lg:tw-col-span-5 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-justify-end"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-4xl tw-hidden lg:tw-block"},[_vm._v(" Tranqui es tu hora ")]),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v(" Es tu hora de empoderarte de tu bienestar con Flex Work un espacio para disfrutar de trabajar remotamente o para cambiar de ambiente y de Time Off un espacio que puedes tomar para ausentarte, sea para atender asuntos personales o simplemente porque quieres descansar de una jornada pesada. ")]),_c('div',{staticClass:"tw-flex tw-gap-4 tw-justify-between"},[_c('div',{staticClass:"tw-btn-primary-red",on:{"click":function($event){_setup.pushLink(
            'https://api.embajadores.com.co/storage/documents/1676993493.pdf',
            _setup.buttonDatalayer('Ver toolkit')
          )}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v("Ver toolkit")])]),_c('div',{staticClass:"tw-btn-secondary-white",on:{"click":function($event){_setup.pushLink(
            'https://api.embajadores.com.co/storage/documents/1676993646.jpg',
            _setup.buttonDatalayer('Descargar avatar')
          )}}},[_c('p',{staticClass:"tw-m-0"},[_vm._v("Descargar Avatar")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"lg:tw-col-span-7 tw-flex tw-flex-col tw-gap-4"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-2xl tw-block lg:tw-hidden"},[_vm._v(" Tranqui es tu hora ")]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-aspect-w-12 tw-aspect-h-6 tw-h-full"},[_c('img',{staticClass:"tw-max-h-[auto] tw-max-w-[auto] tw-rounded-2xl lg:tw-rounded-3xl",attrs:{"src":require("@/assets/img/goflex/goflex-principal.png"),"alt":""}})])])
}]

export { render, staticRenderFns }