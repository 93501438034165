import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  contestsUri: "/compCompetitions",
  compPostUri: "/compPost",
};

export const useContestsStore = defineStore("contests", () => {
  const competitions = ref([]);
  const competition = ref({});
  const participant = ref([]);
  const competitionUser = ref({});
  const competitionInfo = ref({
    ranking: [],
  });
  const currentQuestion = ref({});
  const currentQuestionIndex = ref(0);

  async function actGetCompetitions(status = "ALL") {
    const response = await AxiosBeerOffice.get(
      `${uri.contestsUri}?status=${status}`,
      true,
      {}
    );
    if (response.error) return response.error;
    competitions.value = response.data;
    return true;
  }

  async function actGetCompetition(id) {
    const response = await AxiosBeerOffice.get(
      `${uri.contestsUri}/${id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    competition.value = response.data;
    return true;
  }

  async function actGetCompetitionByIdUser(data) {
    const id = data.id;
    const idUser = data.idUser;
    const response = await AxiosBeerOffice.get(
      `${uri.contestsUri}/${id}/${idUser}`,
      true
    );
    if (response.error) return response.error;
    competitionUser.value = response.data;
    return true;
  }

  // actQuestionAnswer
  async function actPostQuestionAnswer(data) {
    return await AxiosBeerOffice.post(uri.contestsUri, true, data);
  }

  // actCompetition
  async function actPostCompetition(data) {
    console.log("data", data);
    return await AxiosBeerOffice.post(
      `${uri.contestsUri}/${data.comp_competition_id}/compPosts`,
      true,
      data.data,
      true
    );
  }

  return {
    competitions,
    competition,
    participant,
    competitionUser,
    competitionInfo,
    currentQuestion,
    currentQuestionIndex,
    actGetCompetitions,
    actGetCompetition,
    actGetCompetitionByIdUser,
    actPostQuestionAnswer,
    actPostCompetition,
  };
});
