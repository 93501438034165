<script setup>
import { computed } from "vue";

const emit = defineEmits(["click"]);

const props = defineProps({
  request: {
    type: Object,
    default: () => {},
  },
  showNumber: {
    type: Boolean,
    default: false,
  },
});

const requestDetailText = computed(() => {
  return props.request?.requested_time
    ? `Tu solicitud inicia a las ${props.request?.requested_time}`
    : `Tu solicitud es para el día ${props.request?.requested_date}`;
});

const statusText = (name) => {
  const map = new Map([
    [
      "PENDING",
      "Tu solicitud de tiempo ha sido creada con éxito y le notificamos a tu Line Manager. Recuerda que debes subir el documento de defunción.",
    ],
    [
      "APPROVED",
      "Tu solicitud de tiempo ha sido creada con éxito y le notificamos a tu Line Manager. Compartimos tu documento con tu Line Manager para respaldar tu ausencia.",
    ],
    [
      "COMPLETED",
      "Compartimos tu documento con tu Line Manager para respaldar tu ausencia.",
    ],
  ]);
  return map.get(name);
};
</script>
<template>
  <div>
    <div>
      <div class="tw-flex tw-flex-col tw-gap-5 tw-mt-4">
        <div class="tw-flex tw-flex-col tw-gap-3">
          <div>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              Detalle de tu solicitud
            </p>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              {{ requestDetailText }}
            </p>
          </div>
          <p
            class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0 tw-text-gray-400"
          >
            {{ statusText(request.status) }}
          </p>
          <div
            class="tw-btn-secondary-white"
            v-if="request.status === 'PENDING'"
            @click="emit('click')"
          >
            <p class="tw-my-1">Subir documento</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
