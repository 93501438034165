var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-3"},[_c('div',[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl"},[_vm._v(" "+_vm._s(_setup.props.title)+" ")])]),_c('div',{staticClass:"tw-flex tw-gap-3 lg:tw-gap-5 tw-w-full"},[_c(_setup.CompButtonIconText,{staticClass:"tw-border-gray-300 tw-w-full",class:[
        _setup.props.clickable
          ? 'tw-cursor-pointer hover:tw-opacity-100'
          : 'tw-opacity-100',
        _setup.props.clickable && _setup.props.optionSelected.id === _setup.props.option_1.id
          ? 'tw-opacity-100'
          : _vm.onlyShow
          ? 'tw-opacity-100'
          : 'tw-opacity-50',
        !_setup.props.option_2 ? 'tw-justify-center' : 'tw-justify-start',
        _setup.$isMobile() ? 'tw-max-h-16' : '',
      ],attrs:{"icon":"assets/img/reservations/icons/capacity1.svg","text":_setup.props.justCapacity
          ? _setup.props.option_1.capacity + ' personas'
          : _setup.props.option_1.qty + ' ' + _setup.props.option_1.name},nativeOn:{"click":function($event){_setup.props.clickable ? _setup.setCapacity(_setup.props.option_1) : null}}}),(_setup.props.option_2)?_c(_setup.CompButtonIconText,{staticClass:"tw-border-gray-300 tw-w-full tw-justify-start",class:[
        _setup.props.clickable
          ? 'tw-cursor-pointer hover:tw-opacity-100'
          : 'tw-opacity-100',
        _setup.props.clickable && _setup.props.optionSelected.id === _setup.props.option_2.id
          ? 'tw-opacity-100'
          : _vm.onlyShow
          ? 'tw-opacity-100'
          : 'tw-opacity-50',
        _setup.$isMobile() ? 'tw-max-h-16' : '',
      ],attrs:{"icon":"assets/img/reservations/icons/capacity1.svg","text":_setup.props.justCapacity
          ? _setup.props.option_2.capacity + ' personas'
          : _setup.props.option_2.qty + ' ' + _setup.props.option_2.name},nativeOn:{"click":function($event){_setup.props.clickable ? _setup.setCapacity(_setup.props.option_2) : null}}}):_vm._e()],1),(!_vm.onlyShow && _setup.props.optionSelected && _setup.isEmpty(_setup.props.optionSelected))?_c('span',{staticClass:"tw-font-robotoLight tw-text-sm tw-text-red-350"},[_vm._v("* Por favor selecciona la capacidad que deseas")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }