<script setup>
import { computed } from "vue";
import config from "@/services/config";
import imgAvatarEmpty from "@/assets/img/demo/avatar-empty-2.png";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  request: {
    type: Object,
    default: () => ({}),
  },
  buttons: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["approveRequest", "rejectRequest"]);

const userImage = computed(() => {
  const hasPredefinedAvatar = props.request.core_employee_is_predefined_avatar;
  const hasCustomAvatar =
    props.request.core_employee_custom_avatar_image != null;
  const hasProfPicture = props.request.core_employee_prof_picture != null;

  if (hasPredefinedAvatar) {
    return props.request.core_employee_predefined_avatar_image;
  }

  if (hasCustomAvatar) {
    return props.request.core_employee_custom_avatar_image;
  }

  if (hasProfPicture) {
    return props.request.core_employee_prof_picture;
  }

  return null;
});

const approveRequest = () => {
  emit("approveRequest", props.request);
};

const rejectRequest = () => {
  emit("rejectRequest", props.request);
};
</script>
<template>
  <div class="tw-h-full">
    <div
      class="tw-border tw-border-gray-300 tw-rounded-3xl tw-p-4 lg:tw-p-5 tw-flex tw-flex-col tw-items-center tw-gap-3 lg:tw-gap-5"
    >
      <div
        class="tw-rounded-full tw-h-16 tw-w-16 lg:tw-h-24 lg:tw-w-24 tw-border-2 tw-border-gray-300"
      >
        <img
          :src="
            userImage != null ? uriResources + '/' + userImage : imgAvatarEmpty
          "
          class="tw-object-cover tw-w-full tw-h-full tw-rounded-full"
          alt=""
        />
      </div>
      <p
        class="tw-m-0 tw-font-robotoMedium tw-text-xs lg:tw-text-base tw-capitalize tw-text-center"
      >
        {{ props.request.core_employee_name.toLowerCase() }}
      </p>
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-text-red-350"
      >
        {{ props.request.option_name }}
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
        {{ props.request.requested_date }}
      </p>
      <div
        class="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-w-full"
        v-if="props.request.status === 'PENDING' && buttons"
      >
        <div
          class="tw-btn-general-shape tw-bg-green-450 tw-text-white"
          @click="approveRequest"
        >
          <p class="tw-m-0">Aprobar</p>
        </div>
        <div
          class="tw-btn-general-shape tw-border tw-border-red-350 tw-text-red-350"
          @click="rejectRequest"
        >
          <p class="tw-m-0">Rechazar</p>
        </div>
      </div>
      <div
        class="tw-btn-general-shape tw-bg-green-450 tw-text-white tw-w-full"
        v-if="
          props.request.status === 'APPROVED' ||
          props.request.status === 'COMPLETED'
        "
      >
        <p class="tw-m-0">Aprobado</p>
      </div>
      <div
        class="tw-btn-general-shape tw-border tw-border-red-350 tw-text-red-350 tw-w-full"
        v-if="props.request.status === 'REJECTED'"
      >
        <p class="tw-m-0">Rechazado</p>
      </div>
    </div>
  </div>
</template>
