var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-grid tw-grid-cols-12 tw-gap-2 tw-h-full",on:{"mouseenter":function($event){_setup.isHovering = true},"mouseleave":function($event){_setup.isHovering = false}}},[_c('div',{staticClass:"md:tw-col-span-12 tw-flex",class:_setup.props.change === true ? 'tw-col-span-12' : 'tw-col-span-5'},[_c('div',{staticClass:"tw-flex-auto tw-cursor-pointer lg:tw-aspect-w-16 lg:tw-aspect-h-10",on:{"click":function($event){return _setup.linkTo(_setup.props.link, 'Imagen')}}},[_c('img',{staticClass:"tw-rounded-2xl tw-w-full tw-shrink-0",class:[
            _vm.deskHorizontal && !_setup.mobile()
              ? 'lg:tw-h-80'
              : _setup.mobile() && _vm.mobileHorizontal
              ? 'tw-h-44'
              : 'tw-max-h-48',
            _vm.imageCover ? 'tw-object-cover' : 'tw-object-contain',
          ],attrs:{"src":_setup.uriResources + '/' + _setup.props.image,"loading":"lazy","alt":"image"}})])]),_c('div',{staticClass:"md:tw-col-span-12 tw-flex tw-flex-col tw-justify-between lg:tw-justify-end",class:_setup.props.change === true ? 'tw-col-span-12' : 'tw-col-span-7'},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-3 tw-underline tw-my-1"},[_vm._v(" "+_vm._s(_setup.props.category)+" ")])]),_c('p',{staticClass:"tw-font-robotoBold tw-text-base lg:tw-text-2xl md:tw-my-2",class:_setup.props.change === true ? 'tw-my-2' : 'tw-mb-0'},[_vm._v(" "+_vm._s(_setup.props.title)+" ")]),_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-4 lg:tw-leading-5 tw-line-clamp-3 md:tw-line-clamp-2 md:tw-my-2",class:_setup.props.change === true ? 'tw-mb-2' : 'tw-mb-0'},[_vm._v(" "+_vm._s(_setup.removeHTMLTagsFromText(_setup.props.description))+" ")]),_c('div',{staticClass:"tw-flex md:tw-flex-row md:tw-items-center tw-justify-end md:tw-justify-between",class:_setup.props.change === true
            ? 'tw-flex-row-reverse tw-items-center tw-mt-2 md:tw-mt-auto tw-gap-4'
            : 'tw-flex-col tw-mt-3 tw-gap-2'},[_c(_setup.CompSocialNetworkInteractions,{attrs:{"id":_setup.props.id,"title":_setup.props.title,"category":_setup.props.categoryForSocial,"user_reaction":_setup.props.item.user_reaction,"amount_comments":Number(_setup.props.item.amount_comments),"amount_reactions":Number(_setup.props.item.amount_reactions),"image":_setup.props.image,"description":_setup.props.description,"item":_setup.props.item,"hover":_setup.isHovering,"category-for-datalayer":_setup.props.categoryForDatalayer,"action-for-datalayer":_setup.props.actionForDatalayer},on:{"updatedComment":function($event){return _setup.emit('updatedComment', $event)},"updatedReaction":function($event){return _setup.emit('updatedReaction', $event)}}}),_c('div',{staticClass:"tw-flex tw-items-center tw-cursor-pointer",on:{"click":function($event){return _setup.linkTo(_setup.props.link, 'Saber más')}}},[_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"},[_vm._v(" Saber más ")]),_c('i',{staticClass:"icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-xs lg:tw-text-base"})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }