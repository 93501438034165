<script setup>
import config from "@/services/config";
import { onBeforeMount, onMounted, ref } from "vue";
import { useRatingStore } from "@/stores/ratingStore";
import { useCompetitionStore } from "@/stores/competitionStore";
import { useUserStore } from "@/stores/userStore";
import { useRoute } from "vue-router/composables";
import Banner from "@/components/general/Banner";
import CompStars from "@/components/rating/CompStars";
import CompCompetitionsRewards from "@/components/competitions/CompCompetitionsRewards.vue";
import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import CompContents from "@/components/general/CompContents.vue";
import { storeToRefs } from "pinia";

const route = useRoute();
const { actGetRating } = useRatingStore();
const { actGetCompetition, actGetCompetitionByIdUser, actPostCompetition } =
  useCompetitionStore();
const { competition, participant } = storeToRefs(useCompetitionStore());
const { user } = storeToRefs(useUserStore());
const url = ref(config.beerOfficeURL);
const breadcrumb = ref([
  {
    id: 1,
    name: "Home",
    link: "/home",
  },
  {
    id: 2,
    name: "Universo de marcas",
    link: "/beer-universe",
  },
  {
    id: 3,
    name: "Mundo de marcas",
    link: "/brands",
  },
  {
    id: 4,
    name: "Concursos",
    link: "/competitions",
    active: true,
  },
]);
const idCompetition = ref(0);
// const data = ref([]);
const rep = ref(0);
// const v = ref(0);
// const f = ref(0);
const idRef = ref(0);
const state = ref(1);
// const text = ref(0);
// const cssProps = ref({
//   background: `url(${require("@/assets/img/icons/loading.gif")}) no-repeat center center`,
// });
// const isPlaying = ref(false);
const ratingData = ref({});
const score = ref(0);
const myCarousel = ref(null);

function assignDefaultGetCompetitions() {
  setTimeout(() => {
    idRef.value = route.params.id;

    if (rep.value === 0) {
      actPostCompetition({
        core_employee_id: user.value.id,
        comp_competition_id: competition?.value.id,
      });
      rep.value = 1;
    }
    if (idCompetition.value === idRef.value) {
      actGetCompetition(route.params.id);
      actGetCompetitionByIdUser({
        id: idCompetition.value,
        idUser: user.value.id,
      });
      searchCompeRank();
    }
    if (idCompetition.value !== idRef.value) {
      actGetCompetition(route.params.id);
      actGetCompetitionByIdUser({
        id: idCompetition.value,
        idUser: user.value.id,
      });
      idCompetition.value = idRef.value;
      searchCompeRank();
    }
  }, 800);
}

function searchCompeRank() {
  for (const item of competition.value.ranking) {
    let name = item.name.indexOf(user.value.name);
    if (name === 0) {
      state.value = name;
    }
  }
}

function pushDataLayer(value) {
  if (value != null) {
    score.value = value;
    window.dataLayer.push({
      event: "TrackEvent-UniversoCervecero",
      category: "Mundo de marcas",
      SubCategory: "Concursos",
      Action: "Calificación",
      Competitions: competition.value.name,
      qualification: score.value,
    });
  } else {
    if (ratingData.value[competition.value.id] !== undefined) {
      score.value = ratingData.value[competition.value.id]["score"];
    }
    window.dataLayer.push({
      event: "TrackEvent-UniversoCervecero",
      category: "Mundo de marcas",
      SubCategory: "Concursos",
      Action: "Quiero participar",
      Competitions: competition.value.name,
      qualification: score.value,
    });
  }
}

function textLength(item) {
  return item.substring(0, 180) + " ...";
}

function setImageUrl(imagePath) {
  return url.value + "/" + imagePath;
}

function setVideoUrl(videoPath) {
  return url.value + "/" + videoPath;
}

onBeforeMount(async () => {
  idCompetition.value = route.params.id;
  await actGetCompetition(route.params.id);
  await actGetCompetitionByIdUser({
    id: idCompetition.value,
    idUser: user.value.id,
  });
  await actGetRating("Competitions").then((data) => {
    ratingData.value = data;
  });
  assignDefaultGetCompetitions();
});

function prev() {
  myCarousel.value.prev();
}

function next() {
  myCarousel.value.next();
}

onMounted(() => {
  window.dataLayer.push({
    event: "trackEvent",
    target: "Sub-secciones",
    action: "concurso",
    "target-properties": "clic",
    value: 5000,
  });
});
</script>
<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0">
    <div
      class="layout-content mx-auto mb-3"
      v-if="competition?.id && competition?.comp_competition_type_id"
    >
      <div class="container px-0 px-md-3 mt-md-4">
        <BreadCrumb :crumbs="breadcrumb" />
      </div>
      <MobileBar :title="competition?.name" />
      <!-- Título Desktop y leyenda -->
      <div
        class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
      >
        <div class="col-12 col-md-6 px-0 order-md-1 order-last my-0 py-0">
          <div class="d-none d-md-flex flex-md-column py-0">
            <p class="tw-font-tolyerBold fs-md-40 text-uppercase my-0">
              {{ competition?.name }}
            </p>
            <CompStars
              category="Competitions"
              :idContent="competition?.id"
              :ratingData="ratingData[competition?.id]"
              :showStars="true"
              v-on:childToParent="pushDataLayer"
              class="mb-2"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 order-md-2 order-first">
          <div
            class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end py-0"
          >
            <div
              class="col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 mr-md-2 order-last order-md-1"
            >
              <router-link
                to="/competitions"
                class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
              >
                Ver más concursos
              </router-link>
              <!-- <router-link
                class="nav-link"
                v-if="
                  competition?.comp_competition_type_id === 2 ||
                  competition?.comp_competition_type_id === 3
                "
                :to="'/competition/participants/' + competition?.id"
                >Resultados</router-link
              > -->
            </div>
            <div
              class="col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 order-first order-md-1"
            >
              <router-link
                :to="'/competition/ranking/' + competition?.id"
                v-if="competition?.end < new Date().toISOString().slice(0, 10)"
                class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
              >
                Ver ganadores
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="container px-3 px-md-3 mb-3 mb-md-5 mt-3 mt-md-2">
        <div class="d-flex row">
          <div class="col-md-7 col-12">
            <div class="banner" v-if="competition?.banner.id">
              <Banner
                v-bind:bannerId="competition?.banner.id"
                gradient="none"
              ></Banner>
            </div>
          </div>
          <div
            class="col-md-5 col-12 d-flex flex-column justify-content-between mt-5 mt-md-0"
          >
            <div>
              <p class="tw-font-robotoBold fs-md-14 line-height-16">
                <span class="text-red icon-calendario mr-1"></span>
                <span
                  class="tw-font-robotoBold fs-md-14 line-height-16 tw-text-red-400 mr-2"
                  >Fecha de cierre:</span
                >
                {{ competition?.end }}
              </p>
              <p class="tw-font-roboto tw-text-sm line-height-16 pt-2">
                {{ competition?.desc }}
              </p>
              <p class="tw-font-roboto tw-text-sm line-height-16">
                Al participar en este concurso, estás aceptando los
                <a
                  :href="url + '/' + competition?.tyc"
                  class="tw-font-robotoBold tw-text-sm line-height-16 tw-text-red-400"
                  target="_blank"
                  >términos y condiciones</a
                >.
              </p>
            </div>

            <div>
              <div class="cont-awards p-3 tw-shadow-md tw-rounded-2xl">
                <p
                  class="tw-text-red-400 tw-font-robotoBold tw-text-sm line-height-16 text-uppercase"
                >
                  Premios
                </p>
                <div class="row px-3 px-md-3">
                  <div class="col-12 m-0 p-0">
                    <CompCompetitionsRewards />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row-reverse">
              <!-- <div
                class="col-md-5 col-12 px-0 mt-4"
                v-if="competition?.comp_competition_type_id === 1"
              > -->
              <div class="col-md-5 col-12 px-0 mt-4">
                <button
                  v-if="
                    competition?.end >= new Date().toISOString().slice(0, 10) &&
                    state !== 0
                  "
                  v-b-modal.modal-sell
                  type="button"
                  class="btn-principal btn-primary w-100 text-center"
                  name="btn-prod-mes"
                  @click="pushDataLayer()"
                >
                  <span
                    class="tw-font-roboto tw-text-sm line-height-16 m-0 p-0"
                  >
                    Quiero participar
                  </span>
                </button>
                <a
                  v-else
                  href="#"
                  type="button"
                  class="btn-principal btn btn-disabled disabled w-100"
                >
                  <span
                    class="tw-font-roboto tw-text-sm line-height-16 m-0 p-0"
                  >
                    Quiero participar
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container px-3 px-md-3 mb-3 mb-md-5 mt-3 mt-md-2">
        <CompContents
          v-if="
            competition?.content !== undefined ||
            competition?.content.length > 0
          "
          :content="competition?.content"
        />
      </div>
    </div>
    <b-modal
      v-if="participant.length !== 0 && participant.post.length !== 0"
      id="modal-participant"
      size="lg"
      hide-footer
      ref="vuemodal"
      class="pr-0"
    >
      <!--<template v-slot:modal-title></template>-->
      <div
        v-if="participant.length !== 0 && participant.post.length === 1"
        class="d-block text-center"
      >
        <div class="cont-video position-relative">
          <img
            v-if="participant.post[0].type === 'I'"
            :src="setImageUrl(participant.post[0].file)"
            width="100%"
            alt="no users"
            class="mb-3 carousel-img"
          />
          <video
            v-if="participant.post[0].type === 'V'"
            width="100%"
            height="auto"
            ref="participantVideo"
            controls
          >
            <source
              :src="setVideoUrl(participant.post[0].file)"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="text-left mt-3">
          <p class="name mb-1">{{ participant.post[0].name }}</p>
          <p class="desc-alone mb-0">
            {{ textLength(participant.post[0].description) }}
          </p>
        </div>
        <p class="alert-test-modal-alone mx-auto mb-0">
          Esta es una vista previa de tu publicación
        </p>
      </div>
      <div v-else class="d-block text-center">
        <b-carousel ref="myCarousel" no-animation indicators :interval="0">
          <b-carousel-slide
            v-for="item in participant.post"
            v-bind:key="item.id"
            class="py-0"
          >
            <div v-if="item.type === 'I'" class="content-img" style="">
              <img
                v-if="item.type === 'I'"
                :src="setImageUrl(item.file)"
                alt="no users"
                class="mb-0 carousel-img"
              />
            </div>
            <video
              v-if="item.type === 'V'"
              ref="participantVideo"
              style="box-shadow: 0 3px 6px #00000029"
              class="carousel-video"
              controls
            >
              <source :src="setVideoUrl(item.file)" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div class="text-left mt-3">
              <p class="name mb-1">{{ item.name }}</p>
              <p class="desc mb-0">{{ textLength(item.description) }}</p>
            </div>
          </b-carousel-slide>
        </b-carousel>
        <p class="alert-test-modal mx-auto mb-0">
          Esta es una vista previa de tu publicación
        </p>
        <router-link to="">
          <div @click="prev()" class="cont-arrow-izq">
            <span class="icon icon-flecha-izq"></span>
          </div>
        </router-link>
        <router-link to="">
          <div @click="next()" class="cont-arrow-der">
            <span class="icon icon-flecha-der"></span>
          </div>
        </router-link>
      </div>
    </b-modal>
    <div>
      <b-modal
        id="modal-sell"
        centered
        hide-footer
        hide-header
        class="text-center"
      >
        <img :src="url + '/' + competition?.icon" alt="img 1" width="100%" />
        <div class="row px-3 px-md-0 mt-2">
          <div class="col-12 px-5">
            <div
              class="cont-text d-flex h-100 flex-column justify-content-center text-center"
            >
              <p class="text-1 mb-3">{{ competition?.desc }}</p>
              <p class="text-2">
                Al participar en este concurso, estás aceptando los
                <a
                  :href="url + '/' + competition?.tyc"
                  class="text-red text-bold"
                  target="_blank"
                  >términos y condiciones</a
                >.
              </p>
              <router-link
                v-if="competition?.comp_competition_type_id === 1"
                :to="'/competition/question/' + competition?.id"
                class="btn btn-block btn-outline-danger text-bold mt-2"
                >Empezar</router-link
              >
              <router-link
                v-if="competition?.comp_competition_type_id === 2"
                :to="'/competition/social-form/' + competition?.id"
                class="btn btn-block btn-outline-danger text-bold mt-2"
                >Empezar</router-link
              >
              <router-link
                v-if="competition?.comp_competition_type_id === 3"
                :to="'/competition/social-form/' + competition?.id"
                class="btn btn-block btn-outline-danger text-bold mt-2"
                >Empezar</router-link
              >
            </div>
            <!-- .cont-text-1 -->
          </div>
          <!-- .col -->
        </div>
      </b-modal>
    </div>
    <!-- .layout-content -->
  </div>
</template>

<style lang="css" scoped>
#modal-sell .modal-content {
  border-radius: 25px;
  margin-top: 30px;
}
#modal-sell .modal-content img {
  border-radius: 25px;
}
#modal-participant .cont-video {
  min-height: 170px;
  cursor: pointer;
}
#modal-participant .carousel {
  width: 100%;
  margin: 0 auto;
}
#modal-participant .carousel .carousel-cell {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 30px;
}
.carousel-item {
  min-height: 380px;
  background-size: cover !important;
  background-position: center !important;
}
.content-img {
  height: 180px;
  width: 100%;
}
.carousel-img {
  object-fit: contain;
  width: 100%;
  height: 180px;
}
.carousel-video {
  object-fit: cover;
  width: 100%;
  height: 180px;
}
.carousel-item h4 {
  font-size: 48px;
}

.cont-arrow-izq {
  position: absolute;
  top: 35%;
  left: 2%;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 50px;
  padding: 5px 0 5px 0;
}
.cont-arrow-izq .icon {
  font-size: 20px;
  color: #d82b2b;
}
.cont-arrow-der {
  position: absolute;
  top: 35%;
  right: 2%;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 50px;
  padding: 5px 0 5px 0;
}
.cont-arrow-der .icon {
  color: #d82b2b;
  font-size: 20px;
}
.alert-test-modal {
  position: absolute;
  top: -23px;
  left: 10%;
  right: 10%;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
  color: #d82b2b;
}
.alert-test-modal-alone {
  position: absolute;
  top: -12px;
  left: 10%;
  right: 10%;
  font-size: 12px;
  font-family: "RobotoBold", sans-serif;
  color: #d82b2b;
}

/**Awards */

.cont-awards .awards {
  overflow: scroll;
}

.cont-awards .awards img {
  border-radius: 10px;
}

a.router-card:link,
a.router-card:visited,
a.router-card:active {
  color: #0f0e0e;
  text-decoration: none;
}
.color {
  border-color: #d82b2b;
  color: #d82b2b;
}
.color:hover {
  background-color: #d82b2b;
  color: #ffffff;
  border-color: #d82b2b;
}
.name {
  color: #454545;
  font-size: 16px;
  font-family: "RobotoBold", sans-serif;
}
.desc {
  color: #808080;
  font-size: 16px;
  height: 105px;
}
.desc-alone {
  color: #808080;
  font-size: 16px;
}
@media (min-width: 768px) {
  .carousel {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background-color: #ffffff !important;
    margin-bottom: 20px;
  }
  .carousel-item {
    overflow: hidden;
    min-height: 520px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .content-img {
    width: 100%;
    height: 330px;
  }
  .carousel-img {
    object-fit: contain;
    width: 100%;
    height: 300px;
  }
  .carousel-video {
    object-fit: cover;
    width: 100%;
    height: 300px;
  }
  .nav-link {
    color: #999999;
    text-transform: inherit;
  }
  .carousel-item span {
    position: absolute;
    top: -60px;
    right: 0;
  }
  .carousel-item .text-right {
    text-align: center !important;
  }
  .cont-arrow-izq {
    position: absolute;
    top: 40%;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 50px;
    padding: 5px 0 5px 0;
  }
  .cont-arrow-izq .icon {
    font-size: 25px;
    color: #d82b2b;
  }
  .cont-arrow-der {
    position: absolute;
    top: 40%;
    right: 2%;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 50px;
    padding: 5px 0 5px 0;
  }
  .cont-arrow-der .icon {
    color: #d82b2b;
    font-size: 25px;
  }
  .alert-test-modal {
    position: absolute;
    top: -6%;
    left: 78px;
    font-size: 17px;
    font-family: "RobotoBold", sans-serif;
    color: #d82b2b;
  }
  .alert-test-modal-alone {
    position: absolute;
    top: -6%;
    left: 78px;
    font-size: 17px;
    font-family: "RobotoBold", sans-serif;
    color: #d82b2b;
  }
}
</style>
