<template>
  <div class="mt-4 px-0 px-md-3 px-lg-5">
    <div
      class="container-fluid gamification"
      :style="{
        backgroundImage: `url('${imgBgGamification}')`,
      }"
    >
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8 mx-auto">
          <div class="cont-inicial">
            <div class="cont-main position-relative">
              <img
                src="@/assets/img/gamification/symbol-1.png"
                alt="symbol 1"
                class="position-absolute symbol-1 d-none d-md-block"
              />
              <div class="cont-avatar position-relative text-center">
                <img
                  src="@/assets/img/gamification/avatar-1.png"
                  alt="avatar 1"
                />
              </div>
              <p class="title text-center mb-4">¡CONOCE LA<br />MALTERÍA!</p>
              <p class="txt mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Bibendum est ultricies integer quis. Iaculis urna id volutpat
                lacus laoreet. Mauris vitae
              </p>
              <button
                class="btn btn-primary btn-block text-uppercase py-2"
                @click="startUserGamification()"
              >
                ¡Empezar!
              </button>
            </div>
            <!-- .cont-main -->
          </div>
          <!-- .cont-inicial -->
        </div>
        <!-- .col -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "gamificationFirstTime",
  data() {
    return {
      imgBgGamification: require("@/assets/img/gamification/background.png"),
    };
  },
  methods: {
    ...mapActions("modGamification", ["actChangeGamificationUserState"]),
    startUserGamification() {
      this.actChangeGamificationUserState();
      this.$router.push({ name: "gamificationSpirit" });
    },
  },
};
</script>

<style lang="css" scoped>
.gamification {
  background-size: cover;
  padding: 120px 20px 20px 20px;
}

.gamification .cont-inicial .cont-main {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  padding: 20px;
}

.gamification .cont-inicial .cont-main .symbol-1 {
  right: 10%;
}

.gamification .cont-inicial .cont-main .cont-avatar {
  margin-top: -120px;
}

.gamification .cont-inicial .cont-main .cont-avatar img {
  max-width: 230px;
}

.gamification .cont-inicial .cont-main .title {
  color: #d82b2b;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
}

.gamification .cont-inicial .cont-main .txt {
  font-size: 16px;
  color: #707070;
}

.gamification .cont-inicial .cont-main .btn {
  font-size: 22px;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .gamification {
    padding: 120px 50px 50px 50px;
  }

  .gamification .cont-inicial .cont-main {
    padding: 20px 10%;
  }

  .gamification .cont-inicial .cont-main .cont-avatar img {
    max-width: 270px;
  }

  .gamification .cont-inicial .cont-main .title {
    font-size: 60px;
    line-height: 60px;
  }

  .gamification .cont-inicial .cont-main .txt {
    font-size: 18px;
  }

  .gamification .cont-inicial .cont-main .btn {
    font-size: 45px;
    border-radius: 10px;
  }
}

@media (min-width: 1024px) {
  .gamification .cont-inicial .cont-main {
    padding: 20px 20%;
  }

  .gamification .cont-inicial .cont-main .symbol-1 {
    right: 20%;
  }
}
</style>