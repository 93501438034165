<script setup>
import { onBeforeMount, onMounted, ref, getCurrentInstance } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";

const { $bvModal } = getCurrentInstance().proxy;
const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const deliverySiteSelected = ref(null);
const emit = defineEmits(["close", "shop"]);

const closeModal = () => {
  $bvModal.hide("modal-confirm-sell");
  emit("close", true);
};

const sendShop = () => {
  emit("shop");
  $bvModal.hide("modal-confirm-sell");
};

onBeforeMount(async () => {
  await ecommerceStore.actGetLocations();
  deliverySiteSelected.value = userStore.user.ecomm_delivery_site_id;
});

onMounted(() => {
  if (userStore.user.ecomm_delivery_site_id == null) {
    $bvModal.show("modal-confirm-sell");
  }
});
</script>
<template>
  <b-modal
    id="modal-confirm-sell"
    body-class="p-0"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="custom3"
  >
    <div class="container m-auto">
      <div class="sub-container d-flex flex-md-row flex-column w-100 h-100">
        <div class="sub-container-img w-100"></div>
        <div
          class="d-flex flex-column sub-container-content justify-content-center align-items-center w-100 h-100 m-auto text-center"
        >
          <div
            class="d-block justify-content-center align-items-center h-md-100 mx-md-3 m-3 sub-container-text mt-4 mt-md-0 px-3 px-md-0"
          >
            <h4 class="tw-text-xs fs-md-18 tw-font-robotoBold">
              Confirmar el punto de entrega
            </h4>
            <p class="tw-text-xxs fs-md-12 mt-2 mt-md-0">
              Recuerda que el punto de recepción de este pedido es en:
            </p>
            <h5 class="tw-text-xs fs-md-14 font-weight-bold tw-font-robotoBold">
              {{ userStore.user.ecomm_delivery_site["name"] }}
            </h5>
            <div
              class="mt-3 mt-md-1 d-flex justify-content-around pt-2 pt-md-0"
            >
              <button
                @click="closeModal()"
                class="btn btn-actions cancel mt-md-5 mx-2"
              >
                Cancelar
              </button>
              <button
                @click="sendShop()"
                class="btn btn-actions save mt-md-5 mx-2"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.container {
  padding-right: 0;
  padding-left: 0;
}

.sub-container {
  border-radius: 10px;
  overflow: hidden;
  perspective: 1px;
  width: 690px;
  height: 311px !important;
}

.sub-container-img {
  background-image: url("@/assets/img/ecommerce/modal-place.png");
  background-size: cover;
  width: 245px;
  max-width: 245px !important;
}

.sub-container-content p {
  font-family: Roboto, sans-serif;
}

.btn {
  width: 110px;
  height: 45px;
}

.btn-actions {
  font: bold 14px/17px Roboto;
  border-radius: 6px;
  opacity: 1;
  height: 44px;
  width: 131px;
}

.btn-actions.cancel {
  color: #222222;
  border: 1px solid #222222;
  box-sizing: border-box;
}

.btn-actions.save {
  background-color: #d82b2b;
  color: #ffffff;
}

@media (max-width: 768px) {
  .sub-container {
    width: 258px;
    height: 307px !important;
  }

  .sub-container-img {
    max-width: 100% !important;
    max-height: 137px !important;
    height: 137px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position-x: center;
    padding: 0 0 55%;
  }

  .sub-container-text p:last-of-type {
    line-height: 10px;
  }

  .sub-container-text {
    width: 100%;
    height: 170px;
  }

  .btn-actions {
    font: bold 12px/14px Roboto;
    border-radius: 4px;
    opacity: 1;
    height: 30px;
    width: 100px;
  }
}
</style>
