<template>
  <div class="tw-container-general">
    <ButtonsMenu class="py-3 mb-2 mb-md-4" :buttonsMenuItemActive="0" />
    <div class="container mb-4">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <p
            class="tw-text-gray-600 text-uppercase mb-0 tw-text-lg font-weight-bold mb-4"
          >
            Nuevas adherencias
          </p>
          <div v-b-modal.modal-movementsVideo class="position-relative">
            <img
              src="@/assets/img/movements/men-1.png"
              class="tw-shadow-md border-radius-img tw-w-full"
              alt="hombre"
            />
            <i
              class="icon-play tw-text-white position-absolute fs-60 tw-top-1/2 tw-left-1/2 mt-n4 ml-n4"
            ></i>
          </div>
          <p class="tw-text-gray-400 tw-text-sm mt-3">
            Conoce las diferentes publicaciones de vacantes
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-9">
          <div class="row mb-3">
            <div class="col-12 col-md-8 col-lg-4 ml-auto">
              <div class="d-flex align-items-center">
                <p class="tw-text-gray-600 mb-0 mr-2 tw-text-lg">Áreas</p>
                <select
                  class="form-control tw-border tw-text-gray-400 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400 tw-shadow-sm"
                  v-model="vp"
                  @change="onChangeArea($event)"
                >
                  <option value="">Todas</option>
                  <option v-for="term in terms" :key="term.id" :value="term.id">
                    {{ term.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="isLoading">
            <div class="col-12">
              <div class="text-center mt-3">
                <img src="../../../assets/img/icons/loading.gif" alt="loading" />
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12" v-if="paginated.length === 0">
              <div class="alert alert-warning" role="alert">No hay datos.</div>
            </div>
            <div
              v-for="(movement, index) in paginated"
              :key="'movement-' + index"
              class="col-12 col-lg-6 mb-3"
            >
              <Movement :movement="movement" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-6 col-lg-4 ml-auto">
              <div
                v-if="this.movementsData.length > this.pageSize"
                class="d-flex justify-content-center justify-content-md-end"
              >
                <div
                  role="button"
                  @click="prev()"
                  class="wi-40 he-40 bg-white tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center mx-2"
                >
                  <i class="icon-flecha-izq tw-text-red-400 fs-30"></i>
                </div>
                <div
                  role="button"
                  @click="next()"
                  class="wi-40 he-40 bg-white tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center mx-2"
                >
                  <i class="icon-flecha-der tw-text-red-400 fs-30"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-movementsVideo"
      centered
      hide-footer
      :modal-title="false"
      size="lg"
    >
      <video
        controls
        class="w-100"
        src="https://api.embajadores.com.co/storage/documents/1648505675.mp4"
      ></video>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ApiBeerOffice from "../../../services/apiBeerOffice";
import ButtonsMenu from "@/components/general/ButtonsMenu";
import Movement from "@/components/people/movements/Movement";

export default {
  name: "MovementsView",
  components: {
    ButtonsMenu,
    Movement,
  },
  data() {
    return {
      vp: "",
      isLoading: false,
      movementsData: [],
      current: 1,
      pageSize: 4,
    };
  },
  created() {
    this.getTerms();
    this.isLoading = true;
    this.loadMovementsData("");
  },
  computed: {
    ...mapState("modCv", ["terms"]),
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.movementsData.slice(this.indexStart, this.indexEnd);
    },
  },
  methods: {
    ...mapActions("modCv", ["getTerms"]),
    loadMovementsData(vp) {
      this.isLoading = true;
      ApiBeerOffice.getMovements(vp)
        .then((data) => {
          this.movementsData = data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChangeArea(event) {
      this.loadMovementsData(event.target.value);
    },
    prev() {
      if (this.current <= 1) {
        this.current = 1;
      } else {
        this.current--;
      }
    },
    next() {
      if (this.indexEnd >= this.movementsData.length) {
        this.current = 1;
      } else {
        this.current++;
      }
    },
  },
};
</script>
<style scoped>
#modal-movementsVideo___BV_modal_content_ {
  background-color: transparent;
  border: 0;
}
#modal-movementsVideo___BV_modal_content_ .modal-header {
  border-bottom: 0 none;
  padding: 0;
}

#modal-movementsVideo___BV_modal_content_ .modal-header .close {
  font-size: 50px;
  text-shadow: none;
  opacity: 1;
  font-weight: 200;
  color: white;
}

#modal-movementsVideo___BV_modal_content_ .modal-body {
  padding: 0;
}
</style>
<style scoped>
.border-radius-img {
  border-radius: 13px 80px 14px 13px;
}
</style>
