var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.show === 1)?_c('div',[_c('div',{staticClass:"mt-4 mx-1 row d-md-none"},[_c('div',{staticClass:"p-0 tw-w-full tw-container"},[_c('div',{staticClass:"p-0"},_vm._l((_vm.categoriesListData),function(indicator){return _c('div',_vm._g({key:indicator.id,staticClass:"d-md-none psi shadow-box-4 bg-white tw-rounded-md mx-auto my-3 p-0",class:indicator.id === 3 || indicator.id === 4 || indicator.id === 5
                ? 'd-none'
                : indicator.classification === 1
                ? 'd-flex psi-1'
                : indicator.classification === 2
                ? 'd-flex psi-2'
                : indicator.classification === 3
                ? 'd-flex psi-3'
                : 'd-flex psi-4',attrs:{"cols":"5"}},
              indicator.id === 2
                ? {
                    click: () => {
                      _vm.filterIndicatorbyId(indicator.id, 2);
                      _vm.pushDataLayer(indicator.name);
                      _vm.changeShow(2);
                    },
                  }
                : {
                    click: () => {
                      _vm.filterIndicatorbyId(indicator.id, 1);
                      _vm.pushDataLayer(indicator.name);
                      _vm.changeShow(1);
                    },
                  }
            ),[_c('div',{staticClass:"psi-left"}),_c('div',{staticClass:"psi-t d-flex align-items-center pl-2 justify-content-between pr-3",class:_vm.id === indicator.id ? 'psi-trans' : ''},[_c('span',{staticClass:"tw-font-robotoBold tw-text-xxs text-capitalize"},[_vm._v(_vm._s(indicator.name.toLowerCase()))]),(indicator.average)?_c('span',{staticClass:"tw-font-robotoBold tw-text-lg value"},[_vm._v(_vm._s(Math.round( parseFloat( indicator.average.substring(0, 5).replace(",", ".") ) )))]):_vm._e()])])}),0)])]),_c('div',{staticClass:"mt-4 row d-none d-md-flex"},[_c('div',{staticClass:"col-6 col-md-4"},_vm._l((_vm.filterIndicators(1)),function(indicator){return _c('div',{key:indicator.id},[_c('div',{staticClass:"d-flex psi psi-1 shadow-box-4 bg-white tw-rounded-lg my-3",on:{"click":function($event){_vm.filterIndicatorbyId(indicator.id, 1);
              _vm.pushDataLayer(indicator.name);
              _vm.changeShow(1);}}},[_c('div',{staticClass:"psi-left"}),_c('div',{staticClass:"psi-t d-flex align-items-center pl-2 justify-content-between pr-3",class:_vm.id === indicator.id ? 'psi-trans' : ''},[_c('span',{staticClass:"tw-font-robotoBold tw-text-sm text-capitalize"},[_vm._v(_vm._s(indicator.name.toLowerCase()))]),_c('span',{staticClass:"tw-font-robotoBold tw-text-sm value"},[_vm._v(_vm._s(Math.round( parseFloat( indicator.average.substring(0, 5).replace(",", ".") ) )))])])])])}),0),_c('div',{staticClass:"col-6 col-md-4"},_vm._l((_vm.filterIndicators(2)),function(indicator){return _c('div',{key:indicator.id},[_c('div',_vm._g({staticClass:"d-flex psi psi-2 shadow-box-4 bg-white tw-rounded-lg my-3"},
              indicator.id === 3 || indicator.id === 4 || indicator.id === 5
                ? {
                    click: () => {
                      _vm.filterIndicatorbyId(indicator.id, 0);
                      _vm.pushDataLayer(indicator.name);
                      _vm.changeShow(0);
                    },
                  }
                : {
                    click: () => {
                      _vm.filterIndicatorbyId(indicator.id, 1);
                      _vm.pushDataLayer(indicator.name);
                      _vm.changeShow(1);
                    },
                  }
            ),[_c('div',{staticClass:"psi-left"}),_c('div',{staticClass:"psi-t d-flex align-items-center pl-2 justify-content-between pr-3",class:_vm.id === indicator.id ? 'psi-trans' : ''},[_c('span',{staticClass:"tw-font-robotoBold tw-text-sm text-capitalize"},[_vm._v(_vm._s(indicator.name.toLowerCase()))]),(indicator.average === '')?_c('span'):_c('span',{staticClass:"tw-font-robotoBold tw-text-sm value"},[_vm._v(_vm._s(Math.round( parseFloat( indicator.average.substring(0, 5).replace(",", ".") ) )))])])])])}),0),_c('div',{staticClass:"col-6 col-md-4"},_vm._l((_vm.filterIndicators(3)),function(indicator){return _c('div',{key:indicator.id},[_c('div',_vm._g({staticClass:"d-flex psi shadow-box-4 bg-white tw-rounded-lg my-3",class:indicator.classification === 3 ? 'psi-3' : 'psi-4'},
              indicator.id === 2
                ? {
                    click: () => {
                      _vm.filterIndicatorbyId(indicator.id, 2);
                      _vm.pushDataLayer(indicator.name);
                      _vm.changeShow(2);
                    },
                  }
                : {
                    click: () => {
                      _vm.filterIndicatorbyId(indicator.id, 1);
                      _vm.pushDataLayer(indicator.name);
                      _vm.changeShow(1);
                    },
                  }
            ),[_c('div',{staticClass:"psi-left"}),_c('div',{staticClass:"psi-t d-flex align-items-center pl-2 justify-content-between pr-3",class:_vm.id === indicator.id ? 'psi-trans' : ''},[_c('span',{staticClass:"tw-font-robotoBold tw-text-sm text-capitalize"},[_vm._v(_vm._s(indicator.name.toLowerCase()))]),(indicator.average)?_c('span',{staticClass:"tw-font-robotoBold tw-text-sm value"},[_vm._v(_vm._s(parseFloat( indicator.average.substring(0, 5).replace(",", ".") )))]):_vm._e()])])])}),0)])]):(_vm.show === 0)?_c('div',[_c('div',{staticClass:"mt-4 row"},[_vm._l((_vm.filteredCategories),function(indicator){return _c('div',{key:indicator.id,staticClass:"col-md-3"},[_c('div',{staticClass:"d-flex psi shadow-box-4 bg-white tw-rounded-lg my-3",class:indicator.classification === 1
              ? 'psi-1'
              : indicator.classification === 2
              ? 'psi-2'
              : indicator.classification === 3
              ? 'psi-3'
              : indicator.classification === 4
              ? 'psi-4'
              : '',on:{"click":function($event){_vm.filterIndicatorbyId(indicator.id, 0);
            _vm.pushDataLayer(indicator.name);
            _vm.changeShow(0);}}},[_c('div',{staticClass:"psi-left"}),_c('div',{staticClass:"psi-t d-flex align-items-center pl-2 justify-content-between pr-3",class:_vm.id === indicator.id ? 'psi-trans' : ''},[_c('span',{staticClass:"tw-font-robotoBold tw-text-sm text-capitalize"},[_vm._v(_vm._s(indicator.name.toLowerCase()))])])])])}),_c('div',{staticClass:"col-sm"},[_c('div',{staticClass:"d-flex psi psi-2 shadow-box-4 bg-white tw-rounded-lg my-3",on:{"click":function($event){_vm.filterIndicatorbyId(1, 1);
            _vm.changeShow(1);}}},[_c('div',{staticClass:"psi-left"}),_vm._m(0)])])],2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psi-t d-flex align-items-center pl-2 justify-content-between pr-3"},[_c('span',{staticClass:"tw-font-robotoBold tw-text-sm text-capitalize"},[_vm._v("Todos los Indicadores")])])
}]

export { render, staticRenderFns }