<script setup>
import { ref } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import CompOrderState from "@/components/ecommerce/CompOrderState.vue";
import CardProductsCart from "@/components/ecommerce/CardProductsCart.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompEcommerceQR from "@/components/ecommerce/CompEcommerceQR.vue";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const cancel = ref(false);
const isQrModal = ref(false);
const qrOrder = ref({
  id: "",
  date: "",
  name: "",
});

const props = defineProps({
  order: {
    type: Object,
  },
});

const cancelOrder = () => {
  if (!cancel.value) {
    cancel.value = true;
  } else {
    ecommerceStore.actPostOrderCancel(props.order.id).then(() => {
      // this.$emit("cancel-order");
      ecommerceStore.actGetOrdersHistory(userStore.user.id);
      ecommerceStore.actGetOrdersPending(userStore.user.id).then(() => {
        cancel.value = false;
      });
    });
  }
};

const downloadQr = async () => {
  const pdf = await ecommerceStore.actGetOrderPdf(props.order.id);
  let a = document.createElement("a");
  a.href = "data:application/pdf;base64," + pdf;
  a.download = props.order.id + ".pdf";
  a.click();
};

const showQrModal = () => {
  qrOrder.value.id = props.order.id;
  qrOrder.value.date = formatDateDDMMYYYY(props.order.created_at);
  qrOrder.value.name =
    props.order.ecomm_order_details[0].ecomm_product.ecomm_product_type.name.toLowerCase();
  isQrModal.value = true;
};

const hideQrModal = () => {
  isQrModal.value = false;
};
</script>
<template>
  <div>
    <portal to="modal-qr">
      <general-modal
        :hide-button-support="true"
        v-if="isQrModal"
        modalName="qrModal"
        @close="hideQrModal"
        :isShowModal="isQrModal"
        :modalWidth="{ md: 500, lg: 600 }"
        :maxHeight="{ base: 500, md: 500, lg: 600, xl: 700 }"
      >
        <CompEcommerceQR :qr-order="qrOrder" />
      </general-modal>
    </portal>
    <div class="tw-flex tw-flex-col tw-gap-5" v-if="!cancel">
      <CompOrderState :active-order-state="props.order.ecomm_order_state.id" />
      <!-- QR CODE -->
      <div v-if="props.order.ecomm_order_state.id === 3">
        <div
          class="tw-btn-primary-red tw-block lg:tw-hidden"
          @click="showQrModal"
        >
          <p class="tw-m-0">Ver código QR</p>
        </div>
        <div
          class="tw-btn-primary-red tw-hidden lg:tw-block"
          @click="downloadQr"
        >
          <p class="tw-m-0">Descargar código QR</p>
        </div>
      </div>
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base">
        Detalles de la compra
      </p>
      <div class="tw-flex tw-flex-col">
        <div
          v-for="(product, idx) in props.order.ecomm_order_details"
          :key="idx"
        >
          <card-products-cart :product="product" history />
          <hr class="tw-w-full" />
        </div>
        <div class="tw-flex tw-justify-end">
          <p
            class="tw-font-robotoBold tw-text-sm lg:tw-text-xl tw-text-red-350"
          >
            {{
              Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                maximumFractionDigits: 0,
              }).format(props.order.total)
            }}
          </p>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-gap-3"
        v-if="props.order.ecomm_order_state.id === 1"
      >
        <div class="tw-btn-primary-red" @click="cancelOrder()">
          <p class="tw-m-0">Cancelar pedido</p>
        </div>
        <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center tw-w-10/12">
          Recuerda que solo puedes cancelar el pedido en la fase de realizado
        </p>
      </div>
    </div>
    <div v-else class="tw-flex tw-flex-col tw-gap-5 tw-p-4">
      <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-center">
        Cancelar tu pedido
      </p>
      <div
        class="tw-p-5 tw-border tw-border-gray-320 tw-rounded-2xl tw-flex tw-justify-center"
      >
        <img
          src="@/assets/img/ecommerce/compra-rechazada.svg"
          class="tw-object-contain tw-w-2/3"
          alt=""
        />
      </div>
      <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-center">
        ¿Estás seguro de cancelar tu pedido?
      </p>
      <div class="tw-flex tw-justify-between tw-items-center tw-gap-3">
        <div class="tw-btn-secondary-white" @click="cancel = false">
          <p class="tw-m-0">Volver</p>
        </div>
        <div class="tw-btn-primary-red" @click="cancelOrder()">
          <p class="tw-m-0">Cancelar pedido</p>
        </div>
      </div>
    </div>
  </div>
</template>
