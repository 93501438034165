export function capitalizeEachWord(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

export function removeHTMLTagsFromText(str) {
  let textoSinTags = str?.replace(/<[^>]+>/g, "");

  // Convertir los caracteres especiales HTML a su equivalente legible
  let elemento = document.createElement("textarea");
  elemento.textContent = textoSinTags;
  // Retornar el texto sin etiquetas HTML
  return elemento.value;
}

export function capitalizeParagraphFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}
