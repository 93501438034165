var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-relative tw-flex tw-flex-col tw-justify-center",attrs:{"id":_vm.item ? 'slider_' + _vm.item.id : 'slider'}},[(!_vm.inner && !_vm.aspect)?_c('div',{staticClass:"tw-flex tw-justify-center tw-gap-2 tw-mb-2"},_vm._l((_vm.content.length),function(_,idx){return _c('div',{key:idx,on:{"click":function($event){return _vm.changeSlide(idx)}}},[_c('div',{staticClass:"tw-w-2 tw-h-2 md:tw-w-3 md:tw-h-3 tw-rounded-full tw-cursor-pointer",class:idx === _vm.selected
            ? 'tw-bg-red-350'
            : _vm.inner
            ? ' tw-bg-gray-50'
            : 'tw-bg-gray-300'})])}),0):_vm._e(),_c('div',{staticClass:"tw-h-auto tw-w-full tw-relative",class:_vm.aspect === 'horizontal'
        ? ''
        : 'tw-flex tw-justify-center tw-overflow-hidden'},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in","tag":"div"}},[_c('div',{key:_vm.selected,staticClass:"tw-relative",class:_vm.aspect === 'horizontal'
            ? 'tw-aspect-w-16 tw-aspect-h-9'
            : 'tw-flex tw-overflow-hidden'},[(_vm.blockedUser)?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-absolute tw-z-2"},[_c('i',{staticClass:"icon-empty tw-text-white tw-text-7xl lg:tw-text-9xl"})]):_vm._e(),_c('img',{staticClass:"tw-select-none",class:[
            _vm.blockedUser ? 'tw-filter tw-blur-sm' : '',
            _vm.linkeable ? 'tw-cursor-pointer' : '',
            _vm.aspect === 'horizontal'
              ? 'tw-w-full tw-object-cover'
              : 'tw-object-contain',
          ],attrs:{"src":_vm.linkImg,"alt":""},on:{"click":function($event){_vm.linkeable && !_vm.blockedUser ? _vm.goToLink() : null}}})])]),(_vm.inner || _vm.aspect === 'horizontal')?_c('div',{staticClass:"tw-w-full tw-flex tw-m-auto lg:tw-m-0 tw-gap-1 tw-mt-4 md:tw-gap-2 lg:tw-mt-5 tw-pr-1",class:[
        _vm.inner ? 'tw-absolute tw-bottom-3' : '',
        _vm.side === 'left'
          ? 'tw-justify-start'
          : _vm.side === 'right'
          ? 'tw-justify-end'
          : 'tw-justify-center',
      ]},_vm._l((_vm.content.length),function(_,idx){return _c('div',{key:idx,on:{"click":function($event){return _vm.changeSlide($event, idx)}}},[_c('div',{staticClass:"tw-w-2 tw-h-2 md:tw-w-3 md:tw-h-3 tw-rounded-full tw-cursor-pointer",class:idx === _vm.selected
              ? 'tw-bg-red-350'
              : _vm.inner
              ? ' tw-bg-gray-50'
              : 'tw-bg-gray-300'})])}),0):_vm._e()],1),(!_vm.onlyImages && _vm.content[_vm.selected].title)?_c('div',{staticClass:"tw-mt-2 tw-flex tw-flex-col tw-gap-2"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-sm md:tw-text-lg"},[_vm._v(" "+_vm._s(_vm.content[_vm.selected].title)+" ")]),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base"},[_vm._v(" "+_vm._s(_vm.content[_vm.selected].description)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }