<script setup>
import { ref, getCurrentInstance, onBeforeMount, computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useReservationsStore } from "@/stores/reservationsStore";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import CompReservationDetail from "@/components/reservations/CompReservationDetail";

const { $isMobile } = getCurrentInstance().proxy;
const route = useRoute();
const isLoading = ref(false);
const reservationsStore = useReservationsStore();
const activeIndex = ref(0);
const breadcrumb = ref([
  {
    id: 0,
    name: "Inicio",
    link: "/home",
    active: false,
  },
  {
    id: 1,
    name: "Bienestar",
    link: "#",
    active: false,
  },
  {
    id: 2,
    name: "Beneficios",
    link: "/care-benefits/benefits",
    active: false,
  },
  {
    id: 3,
    name: "Reserva de Espacios",
    link: "/reservations",
    active: false,
  },
  {
    id: 4,
    name: "Espacios 127",
    link: "#",
    active: false,
  },
  {
    id: 5,
    name: "Reserva",
    link: `/reservations/space/${route.params.id}/book`,
    active: true,
  },
]);

const showBookings = computed(() => {
  return [...reservationsStore.bookings].reverse();
});

const activeBooking = computed(() => {
  return showBookings.value[activeIndex.value];
});

const selectAccordionItem = (idx) => {
  activeIndex.value = idx;
};

const colorStatus = (status) => {
  const map = new Map([
    ["PENDING_RAFFLE", "tw-bg-brown-500"],
    ["WON_RAFFLE", "tw-bg-green-450"],
    ["UNWON_RAFFLE", "tw-bg-red-350"],
    ["PENDING_PAYMENT", "tw-bg-yellow-200"],
    ["PAYMENT_MADE", "tw-bg-yellow-200"],
    ["CONFIRMED", "tw-bg-green-450"],
    ["CANCELLED", "tw-bg-red-350"],
    ["REJECTED", "tw-bg-red-350"],
    ["COMPLETED", "tw-bg-brown-500"],
    ["NOT_COMPLETED", "tw-bg-brown-500"],
  ]);
  return map.get(status) || "tw-bg-yellow-200";
};

const statusName = (name) => {
  const map = new Map([
    ["PENDING_RAFFLE", "Sorteo"],
    ["WON_RAFFLE", "Ganaste"],
    ["UNWON_RAFFLE", "No ganaste"],
    ["PENDING_PAYMENT", "Pagar"],
    ["PAYMENT_MADE", "Pago realizado"],
    ["CONFIRMED", "Confirmada"],
    ["CANCELLED", "Cancelada"],
    ["REJECTED", "Rechazada"],
    ["COMPLETED", "Finalizada"],
    ["NOT_COMPLETED", "No finalizada"],
  ]);
  return map.get(name) || "Pendiente";
};

const convertDate = (date) => {
  let [year, month, day] = date.split("-");
  const dateObj = new Date(year, month - 1, day);
  month = dateObj.toLocaleString("es-ES", { month: "long" });
  return `${day} de ${month} del ${year}`;
};

onBeforeMount(async () => {
  isLoading.value = true;
  await Promise.all([
    reservationsStore.actGetBookings(),
  ]);
  isLoading.value = false;
});
</script>
<template>
  <div>
    <mobile-bar title="Reserva" />
    <title-bar :breadcrumb="breadcrumb" title="Reserva" />
    <main class="tw-container-general tw-mt-5" v-if="!isLoading">
      <div class="tw-my-5">
        <p class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0">
          Tus reservas
        </p>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-10 tw-gap-10 lg:tw-gap-20"
      >
        <div class="tw-flex tw-flex-col tw-gap-5 lg:tw-col-span-6">
          <comp-accordion-parent>
            <comp-accordion-item
              v-for="(book, idx) in showBookings"
              :id="'book_' + idx"
              :key="idx"
              :border="'tw-border tw-border-gray-250 tw-rounded-2.5xl'"
              :padding="'tw-p-3'"
              :division="false"
              :open-accordion-desk="false"
              @selectAccordionItem="selectAccordionItem(idx)"
            >
              <template v-slot:mainInfo>
                <div class="tw-grid tw-grid-cols-col2_fit_last">
                  <div class="tw-grid tw-grid-rows-1">
                    <p
                      v-if="$isMobile()"
                      class="tw-font-roboto tw-text-xs lg:tw-text-base tw-m-0"
                    >
                      <span class="tw-font-robotoMedium">{{
                        convertDate(book.start_date)
                      }}</span
                      ><br />
                      <span>{{ book?.booking_place.name }}</span>
                    </p>
                    <p
                      class="tw-font-roboto tw-text-xs lg:tw-text-base tw-m-0"
                      v-else
                    >
                      <span class="tw-font-robotoMedium">{{
                        convertDate(book.start_date)
                      }}</span>
                      {{ " / " + book.booking_place.name }}
                    </p>
                  </div>
                  <div class="tw-h-full tw-flex tw-items-center">
                    <div
                      :class="colorStatus(book.status)"
                      class="tw-status-bubble"
                    >
                      <p class="tw-m-0 tw-text-white">
                        {{ statusName(book.status) }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:secondaryInfo>
                <comp-reservation-detail :booking="activeBooking" />
              </template>
            </comp-accordion-item>
          </comp-accordion-parent>
        </div>

        <!-- Right Information Space (Only Desk) -->
        <div class="tw-hidden lg:tw-block lg:tw-col-span-4">
          <div
            class="tw-w-full tw-rounded-2.5xl tw-p-6 tw-border tw-border-gray-250"
          >
            <div class="tw-grid tw-grid-cols-col2_fit_last tw-w-full">
              <div class="tw-grid tw-grid-rows-2">
                <p class="tw-font-robotoBold tw-text-sm tw-m-0">
                  {{ activeBooking?.booking_place.name }}
                </p>
                <p class="tw-font-roboto tw-text-sm tw-m-0">
                  {{
                    activeBooking?.booking_place.booking_place_category_id === 2
                      ? convertDate(activeBooking?.start_date)
                      : activeBooking?.booking_season != null
                      ? activeBooking?.booking_season.name
                      : "Temporada Baja"
                  }}
                </p>
              </div>
              <div class="tw-h-full tw-flex tw-items-start">
                <div
                  :class="colorStatus(activeBooking?.status)"
                  class="tw-status-bubble"
                >
                  <p
                    class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-white"
                  >
                    {{ statusName(activeBooking?.status) }}
                  </p>
                </div>
              </div>
            </div>
            <comp-reservation-detail :booking="activeBooking" show-number />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
