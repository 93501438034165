import apiBeerOffice from "@/services/apiBeerOffice";

export default {
    namespaced: true,
    state: {
        months: [],
        rankCategories: [],
        rankPeople: [],
        rankStages: [],
        switchStage: 1,
        monthSelected: 1,
        rankPeopleSelected: 1,
        rankCategorySelected: 1,
        rankStageSelected: 1,
        rankUsers: [],
        dataUserRankSelected: [],
        userSelected: [],
        monthsByCategory: [],
        stagesByCategory: [],
        rankCategoryType: 1,
    },
    mutations: {
        setMonths: (state, months) => {
            state.months = months;
        },
        setRankCategories: (state, rankCategories) => {
            state.rankCategories = rankCategories;
        },
        setRankPeople: (state, rankPeople) => {
            state.rankPeople = rankPeople;
        },
        setRankStages: (state, rankStages) => {
            state.rankStages = rankStages;
        },
        setSwitchStage: (state, switchStage) => {
            state.switchStage = switchStage;
        },
        setMonthSelected: (state, monthSelected) => {
            state.monthSelected = monthSelected;
        },
        setRankPeopleSelected: (state, rankPeopleSelected) => {
            state.rankPeopleSelected = rankPeopleSelected;
        },
        setRankCategorySelected: (state, rankCategorySelected) => {
            state.rankCategorySelected = rankCategorySelected;
        },
        setRankStageSelected: (state, rankStageSelected) => {
            state.rankStageSelected = rankStageSelected;
        },
        setRankUsers: (state, rankUsers) => {
            state.rankUsers = rankUsers;
        },
        setDataUserRankSelected: (state, dataUserRankSelected) => {
            state.dataUserRankSelected = dataUserRankSelected;
        },
        setUserSelected: (state, userSelected) => {
            state.userSelected = userSelected;
        },
        setMonthsByCategory: (state, monthsByCategory) => {
            state.monthsByCategory = monthsByCategory;
        },
        setStagesByCategory: (state, stagesByCategory) => {
            state.stagesByCategory = stagesByCategory;
        },
        setRankCategoryType: (state, rankCategoryType) => {
            state.rankCategoryType = rankCategoryType;
        },
    },
    actions: {
        getFilters: async ({ commit }) => {
            let response = await apiBeerOffice.getRankPeopleFilters();
            if (response.error) {
                return response.error;
            }
            else {
                commit("setMonths", response.data.months);
                commit("setRankCategories", response.data.rank_categories);
                commit("setRankPeople", response.data.rank_people);
                commit("setRankStages", response.data.rank_stages);
                commit("setMonthSelected", response.data.months[0].month);
                commit("setRankPeopleSelected", response.data.rank_people[0].id);
                commit("setRankCategorySelected", response.data.rank_categories[0].id);
                commit("setRankStageSelected", response.data.rank_stages[0].id);
            }
        },
        changeSwitchStage({ commit, dispatch }, data) {
            commit("setSwitchStage", data);
            dispatch('filterMonthsByCategory');
            dispatch('filterStagesByCategory');
        },
        changeCategorySelected({ commit, dispatch }, data) {
            commit("setRankCategorySelected", data);
            dispatch('filterMonthsByCategory');
            dispatch('filterStagesByCategory');
        },
        changeRankCategoryType({ commit }, data) {
            commit("setRankCategoryType", data);
        },
        getResultFilter: async ({ commit, state, dispatch }) => {
            let response = await apiBeerOffice.getRankPeopleResult(state.switchStage, state.rankCategorySelected, state.rankPeopleSelected, state.monthSelected, state.rankStageSelected);
            if (response.error) {
                return response.error;
            }
            else {
                commit("setRankUsers", response.data);
                dispatch('filterMonthsByCategory');
            }
        },
        getRankPeopleByUser: async ({ commit }, filter) => {
            let response = await apiBeerOffice.getRankPeopleByUser(filter);
            if (response.error) {
                return response.error;
            }
            else {
                commit("setDataUserRankSelected", response.data);
            }
        },
        filterMonthsByCategory({ commit, state }) {
            let newMonths = [];
            state.months.forEach((element) => {
                if (element.rank_people_category_id === state.rankCategorySelected) {
                    newMonths.push(element);
                }
            });
            commit("setMonthsByCategory", newMonths);
        },
        filterStagesByCategory({ commit, state }) {
            let newStages = [];
            state.rankStages.forEach((element) => {
                if (element.rank_people_category_id === state.rankCategorySelected) {
                    newStages.push(element);
                }
                commit("setStagesByCategory", newStages);
            });
        },
    }
}