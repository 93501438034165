<script setup>
import { onMounted, ref, computed } from "vue";
import config from "@/services/config";
import { useRouter } from "vue-router/composables";
import { useUserStore } from "@/stores/userStore";
import { useAuthStore } from "@/stores/authStore";
import { useCoreStore } from "@/stores/coreStore";

const router = useRouter();
const userStore = useUserStore();
const authStore = useAuthStore();
const coreStore = useCoreStore();
const serverPath = config.beerOfficeURL;
const tmpTemplate = ref(Math.round(Math.random() * 10000));
const dateInput = ref([]);
const error = ref({
  flag: false,
  class: "", //is-invalid
  message: "",
});
const accountData = ref({
  cc: "",
  birthdate: "",
});

const title = computed(() => {
  return authStore.setPassword === 1
    ? "Recupera tu contraseña"
    : "¡Inicia la experiencia!";
});

const text = computed(() => {
  return authStore.setPassword === 1
    ? "Ingresa tu cédula y fecha de nacimiento para recuperar tu contraseña."
    : "Ingresa tus datos y activa tu cuenta para empezar a vivir una experiencia única con BeerOffice.";
});

const textButton = computed(() => {
  return authStore.setPassword === 1 ? "Continuar" : "Empezar";
});

const validate = async () => {
  error.value.flag = false;
  error.value.class = "";
  accountData.value.birthdate = dateInput.value.join("-");
  let response = await authStore.actAuthAccount(accountData.value);
  if (response === false) {
    error.value.flag = true;
    error.value.class = "is-invalid";
    error.value.message = "Los datos ingresados no son correctos";
  } else {
    await userStore.actGetUserById(response?.data?.user?.id);
    pushDataLayer();
    await router.push("/set-password");
  }
};

const goToLogin = () => {
  router.push("login");
};

const pushDataLayer = () => {
  try {
    let diff_ms =
      Date.now() -
      new Date(userStore.user.birthdate.replace("-", "/")).getTime();
    let edad = Math.abs(new Date(diff_ms).getUTCFullYear() - 1970);
    window.dataLayer.push({
      Event: "Registro",
      UserID: userStore.user?.id,
      Banda: userStore.user?.core_pa_sideline?.name,
      Cargo: userStore.user?.core_position?.name,
      Localizacion: userStore.user?.core_organizational_unit?.name,
      Convenio: userStore.user?.ben_agreement?.name,
      Tipodecontrato: userStore.user?.ben_contract?.acronym,
      Area: userStore.user?.core_vp?.name,
      Regional: userStore.user?.core_site?.core_regional?.name,
      Genero: userStore.user?.gender,
      Edad: edad,
    });
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  if (authStore.setPassword === 0) {
    await coreStore.actGetContentDocuments();
  }

  error.value.flag = false;
  error.value.class = "";
});
</script>
<template>
  <div class="tw-px-4 xl:tw-px-6 tw-font-roboto">
    <p
      class="tw-text-center tw-font-tolyerBold tw-text-3xl md:tw-text-4xl tw-text-gray-600"
    >
      {{ title }}
    </p>
    <p
      class="tw-text-sm md:tw-text-base tw-mb-4 tw-text-gray-600 tw-font-roboto"
    >
      {{ text }}
    </p>
    <form @submit.prevent="validate()" class="tw-font-roboto">
      <small v-show="error.flag" class="alert-danger">{{
        error.message
      }}</small>
      <div class="tw-mb-4">
        <label class="tw-block tw-text-gray-600">Cédula</label>
        <input
          type="text"
          v-model="accountData.cc"
          :class="
            'tw-w-full tw-text-gray-600 tw-border-gray-300 tw-rounded-md focus:tw-border-gray-320 focus:tw-outline-none focus:tw-ring-gray-320 ' +
            error.class
          "
          placeholder="Escribe tu cédula sin puntos ni comas"
          required
        />
      </div>
      <div class="tw-mb-4">
        <label class="tw-block tw-text-gray-600">Fecha de nacimiento</label>
        <div class="tw-grid tw-grid-cols-3 tw-gap-4">
          <div class="tw-col-span-1">
            <input
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="2"
              v-model="dateInput[2]"
              min="1"
              max="31"
              class="tw-w-full tw-text-gray-600 tw-border-gray-300 tw-rounded-md focus:tw-border-gray-320 focus:tw-outline-none focus:tw-ring-gray-320 tw-text-center"
              placeholder="DD"
              required
            />
          </div>
          <div class="tw-col-span-1">
            <input
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="2"
              v-model="dateInput[1]"
              min="1"
              max="12"
              class="tw-w-full tw-text-gray-600 tw-border-gray-300 tw-rounded-md focus:tw-border-gray-320 focus:tw-outline-none focus:tw-ring-gray-320 tw-text-center"
              placeholder="MM"
              required
            />
          </div>
          <div class="tw-col-span-1">
            <input
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="4"
              v-model="dateInput[0]"
              min="1900"
              class="tw-w-full tw-text-gray-600 tw-border-gray-300 tw-rounded-md focus:tw-border-gray-320 focus:tw-outline-none focus:tw-ring-gray-320 tw-text-center"
              placeholder="AAAA"
              required
            />
          </div>
        </div>
      </div>
      <div v-if="authStore.setPassword === 0">
        <div v-for="legalDoc in coreStore.legalDocs" :key="legalDoc.id">
          <table>
            <tr>
              <td>
                <input
                  required
                  type="checkbox"
                  :class="
                    'tw-w-4 tw-h-4 tw-border tw-border-solid tw-border-gray-300 tw-rounded-sm tw-text-red-400 focus:tw-ring-transparent tw-shadow-sm ' +
                    tmpTemplate
                  "
                  :id="'checkTC' + legalDoc.id"
                  :value="legalDoc.id"
                />
              </td>
              <td>
                <label
                  class="tw-font-roboto tw-text-gray-400 tw-pl-2 tw-text-xs"
                  :for="'checkTC' + legalDoc.id"
                >
                  ACEPTO -
                  <a
                    :href="serverPath + '/' + legalDoc.file"
                    class="text-muted"
                    target="_blank"
                    >{{ legalDoc.name }}</a
                  >
                </label>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="tw-flex tw-justify-center tw-text-center tw-my-4">
        <button type="submit" class="tw-btn-primary-red tw-w-fit">
          {{ textButton }}
        </button>
      </div>
    </form>
    <div class="tw-text-center">
      <button
        class="tw-text-xs tw-border-0 tw-bg-transparent tw-underline tw-text-red-400"
        @click="goToLogin()"
      >
        Ya tengo una cuenta
      </button>
    </div>
  </div>
</template>
