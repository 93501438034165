<template>
  <div>
    <div id="modals">
      <portal to="modal-wrong">
        <general-modal
          :hide-button-support="true"
          v-if="isModalStatesTapit"
          :isShowModal="isModalStatesTapit"
          :maxHeight="{ base: 400, md: 600, lg: 700 }"
          :modalWidth="{ md: 500, lg: 600 }"
          modalName="tapitState"
          @close="toggleModalStatesTapit"
        >
          <ModalStatesTapit
            :customToken="customToken"
            :modalState="modalState"
            :tapit_url="tapit_url"
            @close="toggleModalStatesTapit"
          />
        </general-modal>
      </portal>
    </div>
    <!-- Título Desktop y leyenda -->
    <div
      class="tw-container tw-mx-auto tw-max-w-screen-xl d-flex flex-md-row flex-column mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0">
        <div class="d-none d-md-flex">
          <p
            class="tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
          >
            La Maltería
          </p>
        </div>
        <div class="d-none d-md-flex">
          <div class="px-3 p-md-0">
            <p
              class="tw-font-roboto tw-text-base md:tw-text-base line-height-24 tw-text-gray-600"
            >
              Completa misiones, gana puntos y redime premios
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="tw-container tw-mx-auto tw-max-w-screen-xl tw-mb-8 tw-p-5 lg:tw-p-0"
    >
      <div class="container px-0 px-md-3 mt-md-4 he-500">
        <div
          class="d-flex align-items-center justify-content-center my-3 h-100"
        >
          <img alt="loading" src="../../assets/img/icons/loading.gif" />
        </div>
      </div>
    </div>

    <div
      v-else
      class="tw-container tw-mx-auto tw-max-w-screen-xl tw-mb-8 tw-p-5 lg:tw-p-0"
    >
      <div class="tw-mt-4">
        <div
          id="background"
          class="tw-w-full tw-px-10 tw-py-11 lg:tw-px-14 lg:tw-py-12 tw-rounded-2xl"
        >
          <div
            id="text-buttons-illustration"
            class="tw-grid tw-grid-cols-1 tw-grid-rows-row2_fit_last lg:tw-grid-cols-col2_fit_last tw-gap-2 tw-h-full tw-z-1"
          >
            <div
              id="text-buttons"
              class="tw-order-2 lg:tw-order-1 tw-flex tw-flex-col tw-justify-center tw-h-full"
            >
              <div id="intro-text">
                <p
                  class="tw-font-robotoLight tw-text-xl sm:tw-text-2xl lg:tw-text-4xl xl:tw-text-5xl"
                >
                  Estaremos trabajando para que tus
                  <strong class="tw-font-robotoBold">maltas</strong> se sigan
                  <strong class="tw-font-robotoBold">convirtiendo</strong> en lo
                  que
                  <strong class="tw-font-robotoBold"
                    >más te gusta de Tap It</strong
                  >
                </p>
              </div>
              <div
                id="buttons"
                class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-3 lg:tw-gap-4 lg:tw-mt-8"
              >
                <div
                  class="tw-rounded-2xl tw-bg-white tw-flex tw-justify-center tw-shadow-lg lg:tw-shadow-none tw-cursor-pointer"
                  @click="pushLink(tapit_url)"
                >
                  <p
                    class="tw-m-0 tw-p-3 tw-text-orange-200 tw-text-base tw-font-roboto tw-font-black"
                  >
                    Espéralo
                  </p>
                </div>
              </div>
            </div>
            <div
              id="illustration"
              class="tw-order-1 lg:tw-order-2 tw-h-full tw-min-h-0 tw-min-w-0 tw-flex tw-justify-end"
            >
              <img
                alt=""
                class="tw-object-cover tw-h-full tw-w-full"
                src="../../assets/img/gamification/personaje_tapit_work.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="lg:tw-overflow-hidden cards-parent">
        <div
          class="cards tw-flex tw-flex-col lg:tw-flex-row lg:tw-overflow-x-scroll tw-mt-4 tw-py-4 lg:tw-px-1 tw-gap-6 tw-h-38 lg:tw-h-full"
        >
          <div
            class="lg:tw-w-2/5 tw-flex-none tw-h-full lg:tw-h-auto tw-rounded-3xl tw-shadow-md tw-overflow-hidden"
          >
            <div class="tw-grid tw-auto-rows-fr lg:tw-grid-rows-5 tw-h-full">
              <div class="lg:tw-row-span-3 tw-bg-orange-350 tw-px-4">
                <div class="tw-grid tw-grid-cols-2 tw-w-full tw-h-full">
                  <div class="tw-flex tw-items-end">
                    <div class="tw-flex-1">
                      <img
                        alt=""
                        class="tw-object-contain tw-w-full tw-h-auto"
                        src="@/assets/img/gamification/logo_tapit_bw.png"
                      />
                    </div>
                  </div>
                  <div
                    class="tw-h-full tw-min-h-0 tw-min-w-0 tw-flex tw-justify-end"
                  >
                    <img
                      alt=""
                      class="tw-object-cover tw-h-full tw-w-full"
                      src="../../assets/img/gamification/personaje_tapit_2.svg"
                    />
                  </div>
                </div>
              </div>
              <div class="lg:tw-row-span-2 tw-p-5 lg:tw-px-6 lg:tw-py-6">
                <p
                  class="tw-text-orange-200 tw-font-roboto tw-font-black tw-text-lg lg:tw-text-2xl xl:tw-text-3xl tw-mb-7"
                >
                  ¿Qué es Tap It?
                </p>
                <p
                  class="tw-mt-3 xl:tw-mt-4 tw-mb-0 tw-text-gray-600 tw-font-roboto tw-text-xs lg:tw-text-xs xl:tw-text-base lg:tw-leading-5"
                >
                  Tap It es el programa de beneficios para los amantes de la
                  cerveza. Ganas puntos Tap It por tomar cerveza que puedes usar
                  para reclamar cerveza, productos y experiencias exclusivas.
                </p>
              </div>
            </div>
          </div>
          <div
            class="lg:tw-w-2/5 tw-flex-none tw-h-full lg:tw-h-auto tw-rounded-3xl tw-shadow-md tw-overflow-hidden"
          >
            <div class="tw-grid tw-auto-rows-fr lg:tw-grid-rows-5 tw-h-full">
              <div class="lg:tw-row-span-3 tw-bg-green-200 tw-px-4">
                <div class="tw-grid tw-grid-cols-2 tw-w-full tw-h-full">
                  <div class="tw-flex tw-items-end">
                    <div class="tw-flex-1">
                      <img
                        alt=""
                        class="tw-object-contain tw-w-full tw-h-auto"
                        src="@/assets/img/gamification/logo_tapit_embajadores.svg"
                      />
                    </div>
                  </div>
                  <div
                    class="tw-h-full tw-min-h-0 tw-min-w-0 tw-flex tw-justify-end"
                  >
                    <img
                      alt=""
                      class="tw-object-cover tw-h-full tw-w-full"
                      src="../../assets/img/gamification/personaje_tapit_3.svg"
                    />
                  </div>
                </div>
              </div>
              <div class="lg:tw-row-span-2 tw-p-5 lg:tw-px-6 lg:tw-py-6">
                <p
                  class="tw-text-orange-200 tw-font-roboto tw-font-black tw-text-lg lg:tw-text-2xl xl:tw-text-3xl tw-mb-7"
                >
                  Alianza con Embajadores
                </p>
                <p
                  class="tw-mt-3 xl:tw-mt-4 tw-mb-0 tw-text-gray-600 tw-font-roboto tw-text-xs lg:tw-text-xs xl:tw-text-base lg:tw-leading-5"
                >
                  Nos aliamos con Tap It para que puedas usar tus maltas como
                  puntos Tap It y disfrutes los beneficios exclusivos del
                  programa que te premia tu amor por la cerveza
                </p>
              </div>
            </div>
          </div>
          <div
            class="lg:tw-w-2/5 tw-flex-none tw-h-full lg:tw-h-auto tw-rounded-3xl tw-shadow-md tw-overflow-hidden"
          >
            <div class="tw-grid tw-auto-rows-fr lg:tw-grid-rows-5 tw-h-full">
              <div class="lg:tw-row-span-3">
                <div class="tw-grid tw-w-full tw-h-full">
                  <img
                    alt=""
                    class="tw-object-cover tw-w-full tw-h-full"
                    src="@/assets/img/gamification/fondo_tapit_4.png"
                  />
                </div>
              </div>
              <div class="lg:tw-row-span-2 tw-p-5 lg:tw-px-6 lg:tw-py-6">
                <p
                  class="tw-text-orange-200 tw-font-roboto tw-font-black tw-text-lg lg:tw-text-2xl xl:tw-text-3xl tw-mb-7"
                >
                  ¿Cómo participar?
                </p>
                <p
                  class="tw-mt-3 xl:tw-mt-4 tw-mb-0 tw-text-gray-600 tw-font-roboto tw-text-xs lg:tw-text-xs xl:tw-text-base lg:tw-leading-5"
                >
                  Debes tener una cuenta activa en Tap It. Si no la tienes
                  regístrate haciendo clic aquí. Si ya tienes una cuenta en Tap
                  It sigue los pasos
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TAPIT_SSO_CONFIG from "@/services/tapitsso";
import { mapActions, mapMutations, mapState } from "vuex";
import linksMixin from "@/mixins/general/links";
import GeneralModal from "@/components/modals/GeneralModal";
import ModalStatesTapit from "@/components/gamification/tapitModals/CompGamificationModalStatesTapit.vue";

// let recaptchaScript = document.createElement("script");
// recaptchaScript.setAttribute("src", process.env.VUE_APP_TAPIT_SSO_URL);
// document.body.append(recaptchaScript);

export default {
  name: "GamiMaltsWelcome",
  mixins: [linksMixin],
  data() {
    return {
      session: false,
      tapit_url: process.env.VUE_APP_TAPIT_URL,
      isLoading: false,
      isModalStatesTapit: false,
      modalState: 1,
      tapitUserData: {
        identity: null,
        identityVerificationState: null,
        email: null,
      },
      customToken: null,
      equalIdentity: false,
      verifyState: null,
      tapit_id: null,
    };
  },
  components: {
    GeneralModal,
    ModalStatesTapit,
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      window.ssoApp.configApp(TAPIT_SSO_CONFIG);
      window.ssoApp.auth.onAuthStateChanged((userCredential) => {
        if (userCredential) {
          this.handleUserCredential(userCredential);
        } else {
          this.session = false;
          this.isLoading = false;
          localStorage.removeItem("tapitSession");
        }
      });

      window.ssoApp.onFlowCompleted().subscribe((res) => {
        if (res.userCredential) {
          this.handleUserCredential(res.userCredential);
        } else {
          this.session = false;
          this.isLoading = false;
          localStorage.removeItem("tapitSession");
        }
      });
    }, 1500);
  },
  computed: {
    ...mapState("modCore", ["user", "userTapitData"]),
    ...mapState("modGamification", ["tapitVerifiedState"]),
  },
  methods: {
    ...mapActions("modCore", ["actUserTapitAccount"]),
    ...mapActions("modGamification", ["actGetTapitVerifyIdNumber"]),
    ...mapMutations("modCore", ["setUserTapitData"]),
    // showSSO() {
    //   window.ssoApp.showApp("sign-in");
    // },
    // logoutsso() {
    //   window.ssoApp.logout();
    //   localStorage.removeItem("tapitSession");
    // },
    // goToMalts() {
    //   this.identity === false
    //     ? this.toggleModalStatesTapit()
    //     : this.$router.push({
    //         name: "gamificationMalts",
    //         params: { customToken: this.customToken },
    //       });
    // },
    toggleModalStatesTapit(state) {
      this.modalState = state;
      this.isModalStatesTapit = !this.isModalStatesTapit;
    },
    async handleUserCredential(userCredential) {
      // Aa is the session token
      const { Aa, uid } = userCredential;
      localStorage.setItem("tapitSession", true);

      const doc = await window.ssoApp.firestore
        .collection("user_account_tap")
        .doc(uid)
        .get();
      this.tapitUserData = doc.data();

      /* To check the verification state of the id in Tap It
      It could be checked by the doc.data() or by the
      verifyIdNumber endpoint */

      await window.ssoApp
        .getCustomToken(userCredential)
        .subscribe((customToken) => {
          this.customToken = customToken;
        });

      await this.actGetTapitVerifyIdNumber(Aa).then(() => {
        this.verifyState = this.getVerifyState(this.tapitVerifiedState);
      });

      this.equalIdentity =
        this.tapitUserData.identity !== null &&
        this.tapitUserData.identity === this.user.cc;

      this.handleVerifyState();

      if (this.session && (!this.user.tapit_id || this.user.tapit_id !== uid)) {
        await this.updateTapitAccount(uid, Aa);
      }

      this.isLoading = false;
    },
    getVerifyState(tapitVerifiedState) {
      return tapitVerifiedState === "VERIFIED"
        ? 1
        : tapitVerifiedState === "IN_PROGRESS"
        ? 2
        : 3;
    },
    handleVerifyState() {
      if (
        this.verifyState === 3 ||
        this.tapitUserData.identity === null ||
        (this.verifyState !== 3 && !this.equalIdentity) ||
        (this.verifyState === 2 && this.equalIdentity)
      ) {
        this.toggleModalStatesTapit(this.verifyState);
        this.session = false;
      } else if (this.verifyState === 1 && this.equalIdentity) {
        this.session = true;
      }
    },
    async updateTapitAccount(uid, Aa) {
      await this.actUserTapitAccount({
        userId: this.user.id,
        tapit_id: uid,
        tapit_email: this.tapitUserData.email,
        tapit_identity: this.tapitUserData.identity,
        customToken: this.customToken,
      })
        .then(() => {
          this.setUserTapitData({
            tapit_id: uid,
            tapit_email: this.tapitUserData.email,
            tapit_identity: this.tapitUserData.identity,
            customToken: this.customToken,
            sessionToken: Aa,
          });
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    },
  },
};
</script>

<style scoped>
.cards::-webkit-scrollbar {
  display: none;
}

.cards {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#background {
  background-image: url("~@/assets/img/gamification/fondo_tapit_work.png");
  background-repeat: repeat-x;
  background-size: auto 100%;
}
</style>
