<template>
  <div>
    <div class="layout-header">
      <div class="row">
        <div class="col-md-4">
          <div class="d-none d-md-block">
            <router-link to="/home" class="p-0">
              <img
                class="logo ml-1"
                src="@/assets/img/logo-embajadores.png"
                alt=""
              />
            </router-link>
          </div>
          <div class="position-absolute w-100 d-xl-none d-md-none">
            <router-link to="/profile">
              <img
                class="rounded-circle m-2 float-left tw-w-10 tw-h-10"
                src="@/assets/img/demo/contact2.jpg"
                alt=""
              />
            </router-link>
            <router-link class="float-right mr-4 p-1 pl-2 pr-3" to="/home">
              <img
                class="tw-m-0 tw-w-6 tw-h-6"
                src="@/assets/img/icons/nav-shopping.png"
                alt=""
              />
            </router-link>
            <router-link class="float-right mx-0 p-1" to="/home">
              <img
                class="tw-w-6 tw-h-6"
                src="@/assets/img/icons/nav-help.png"
                alt=""
              />
            </router-link>
          </div>
          <div class="title">EMBAJADORES</div>
        </div>
        <div class="col-md-8 text-right pt-3 d-none d-md-block">
          <router-link to="/beer-universe">Universo cervecero</router-link>
          <router-link to="/care-benefits">Cuidados y beneficios</router-link>
          <router-link to="/">Un mundo mejor</router-link>
          <router-link to="/">¿Qué es BeerOffice?</router-link>
        </div>
      </div>
    </div>
    <div class="layout-navbar d-none d-md-block">
      <div class="text-center pt-4">
        <router-link to="/profile">
          <img
            v-if="user.prof_picture !== null"
            class="rounded-circle"
            :src="uriResources + '/' + user.prof_picture"
            style="object-fit: cover"
            width="40"
            height="40"
            alt=""
          />
          <img
            v-else
            class="profile rounded-circle"
            src="@/assets/img/demo/contact2.jpg"
            alt=""
          />
        </router-link>
        <div class="icon active">
          <img class src="@/assets/img/icons/nav-home.png" alt="" />
        </div>
        <div class="icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-shopping.png" alt="" />
          </router-link>
        </div>
        <div class="icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-bell.png" alt="" />
          </router-link>
        </div>
        <div class="icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-calendar.png" alt="" />
          </router-link>
        </div>
        <div class="icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-medals.png" alt="" />
          </router-link>
        </div>
        <div class="icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-message.png" alt="" />
          </router-link>
        </div>
        <div class="icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-support.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="layout-navbar-botton d-xl-none d-md-none">
      <div class="row text-center">
        <div class="col icon active">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-home.png" alt="" />
          </router-link>
        </div>
        <div class="col icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-calendar.png" alt="" />
          </router-link>
        </div>
        <div class="col icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-bell.png" alt="" />
          </router-link>
        </div>
        <div class="col icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-medals.png" alt="" />
          </router-link>
        </div>
        <div class="col icon">
          <router-link to="/home">
            <img class src="@/assets/img/icons/nav-menu.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/services/config";
export default {
  name: "CompMenu",
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
};
</script>

<style scoped>
.layout-header {
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  float: left;
  position: fixed;
  z-index: 2;
  -webkit-box-shadow: 0 9px 8px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 9px 8px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0 9px 8px -8px rgba(0, 0, 0, 0.75);
  padding: 8px;
}
.layout-header .logo {
  width: 54px;
  height: 54px;
  float: left;
  margin-right: 20px;
}
.layout-header .title {
  color: #d32e24;
  font: 45px TolyerBold1;
  line-height: 30px;
  float: left;
  padding-top: 13px;
}
.layout-header a {
  color: #444444;
  font-size: 14px;
  text-decoration: none;
  padding-right: 30px;
  margin-top: 10px;
}
.layout-header a:hover {
  color: #d32e24;
}
.layout-header .active {
  color: #d32e24;
}
.layout-navbar {
  position: fixed;
  width: 75px;
  top: 70px;
  bottom: 0;
  z-index: 3;
  background-color: #f0f0f0;
}
.layout-navbar .profile {
  width: 45px;
  height: 45px;
}
.layout-navbar .icon {
  padding-top: 35px;
}
.layout-navbar .icon img {
  width: 25px;
  height: 25px;
}
.layout-navbar .active::before {
  content: "";
  background-color: #d32e24;
  height: 35px;
  width: 5px;
  left: 0;
  margin-top: -5px;
  position: absolute;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.layout-navbar-botton {
  background-color: #ffffff;
  bottom: 0;
  height: 50px;
  width: 100%;
  float: left;
  position: fixed;
  z-index: 2;
  -webkit-box-shadow: 0 -6px 8px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 -6px 8px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0 -6px 8px -8px rgba(0, 0, 0, 0.75);
  padding-top: 12px;
}
.layout-navbar-botton .icon img {
  width: 25px;
  height: 25px;
}
.layout-navbar-botton .active::before {
  content: "";
  background-color: #d32e24;
  height: 5px;
  width: 45px;
  bottom: 0;
  margin-left: -10px;
  position: fixed;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
@media (max-width: 780px) {
  .layout-header .title {
    text-align: center !important;
    width: 100%;
    font-size: 35px;
  }
}
</style>
