import { render, staticRenderFns } from "./EventsHome.vue?vue&type=template&id=fd056860&scoped=true"
import script from "./EventsHome.vue?vue&type=script&setup=true&lang=js"
export * from "./EventsHome.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd056860",
  null
  
)

export default component.exports