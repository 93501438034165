<template>
  <auth-layout>
    <auth-set-password></auth-set-password>
  </auth-layout>
</template>

<script>
import AuthLayout from "../../components/auth/AuthLayout.vue";
import AuthSetPassword from "../../components/auth/AuthSetPassword.vue";

export default {
  name: "SetPassword",
  components: {
    AuthLayout,
    AuthSetPassword,
  },
  data() {
    return {};
  },
};
</script>
