<script setup>
import { getCurrentInstance, ref } from "vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompDiversityClickableCard from "@/components/diversity/CompDiversityClickableCard.vue";

const isVideo = ref(false);
const videoPrueba = require("@/assets/img/diversity/videoPrueba.mp4");
const { $isMobile } = getCurrentInstance().proxy;
const linksInformation = [
  {
    image: "assets/img/diversity/politicas.svg",
    linkLabel: "Nuestras políticas",
    linkUrl: "/diversity/OurPolitics",
    title: "Nuestras políticas",
  },
  {
    image: "assets/img/diversity/diversity.svg",
    linkLabel: "D&I en Embajadores",
    linkUrl: "/diversity/ambassadorsDI",
    title: "D&I en Embajadores",
  },
  {
    image: "assets/img/diversity/ayudarnos.svg",
    linkLabel: "¿Cómo podemos ayudarnos?",
    linkUrl: "/diversity/helpLinesDI",
    title: "¿Cómo podemos ayudarnos?",
  },
  // {
  //   image: "assets/img/diversity/icons/icono-champions.png",
  //   linkLabel: "Champions",
  //   linkUrl: "/diversity/champions",
  //   title: "Champions",
  // },
];
</script>

<template>
  <div>
    <mobile-bar title="Diversidad, Equidad e Inclusión" />
    <title-bar title="Diversidad, Equidad e Inclusión" />
    <div class="tw-container-general tw-mt-0 tw-py-0">
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-mb-5 lg:tw-my-5 lg:tw-mt-10 lg:tw-gap-6"
      >
        <div
          class="tw-order-2 lg:tw-order-1 tw-flex tw-flex-col tw-justify-end lg:tw-col-span-4 tw-gap-4 tw-mt-5 lg:tw-m-auto"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-hidden lg:tw-block"
          >
            Soñamos en grande para crear un futuro con más motivos para brindar
          </p>
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-text-red-350"
          >
            ¿Qué queremos hacer?
          </p>
          <p class="tw-m-0 tw-text-sm lg:tw-text-base">
            Nuestro sueño es crear un ambiente inclusivo donde todas las
            personas pueden ser su mejor versión.
          </p>
        </div>
        <div class="tw-order-1 lg:tw-order-2 lg:tw-col-span-8 hijo">
          <div
            class="tw-rounded-br-3xl tw-rounded-bl-3xl tw-rounded-tr-none tw-rounded-tl-none lg:tw-rounded-3xl lg:tw-aspect-h-6 lg:tw-aspect-w-16 tw-overflow-hidden"
            :class="isVideo && !$isMobile() ? '' : 'tw-bg-purple-300'"
          >
            <div
              class="tw-w-full tw-h-full tw-p-8 tw-flex tw-flex-col tw-gap-2"
              :class="isVideo ? 'lg:tw-p-0' : 'lg:tw-p-10'"
            >
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xl tw-text-white lg:tw-hidden tw-text-center"
              >
                Soñamos en grande para crear un futuro con más motivos para
                brindar
              </p>
              <div
                class="tw-rounded-3xl tw-w-full tw-h-full tw-overflow-hidden"
                :class="
                  isVideo
                    ? 'lg:tw-rounded-none'
                    : 'lg:tw-rounded-bl-3xl lg:tw-rounded-tl-3xl lg:tw-rounded-br-none lg:tw-rounded-tr-none'
                "
              >
                <div class="tw-w-auto tw-h-full tw-flex" v-if="isVideo">
                  <video
                    ref="videoRef"
                    class="tw-w-full tw-object-cover"
                    controls
                    autoplay
                  >
                    <source :src="videoPrueba" type="video/mp4" />
                    Su navegador no soporta videos.
                  </video>
                </div>
                <div class="tw-w-full tw-h-full" v-else>
                  <img
                    src="@/assets/img/diversity/principal_card_desk.png"
                    class="tw-object-cover tw-w-full tw-h-full tw-hidden lg:tw-block"
                    alt=""
                  />
                  <img
                    src="@/assets/img/diversity/principal_card_mobile.png"
                    class="tw-object-cover tw-w-full tw-h-full lg:tw-hidden tw-block"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
      <div
        class="tw-grid tw-gap-4 lg:tw-gap-6 tw-my-5"
        :class="`tw-grid-cols-${linksInformation.length}`"
      >
        <comp-diversity-clickable-card
          v-for="(link, index) in linksInformation"
          :key="index"
          :image="link.image"
          :link-label="link.linkLabel"
          :link-url="link.linkUrl"
          :title="link.title"
        />
      </div>
      <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
      <div
        class="hijo tw-p-10 tw-my-5"
        :style="{
          'background-image': `url(${require('@/assets/img/diversity/6017056.jpg')})`,
          'background-size': 'cover',
          'background-position': 'center',
        }"
      >
        <p
          class="tw-m-0 tw-font-robotoBold tw-italic tw-text-center tw-text-xl lg:tw-text-3xl"
        >
          “Nunca es demasiado tarde para abandonar tus prejuicios”
        </p>
      </div>
      <div class="tw-my-10 tw-flex tw-flex-col tw-gap-6">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"
        >
          Bavaria en cifras de diversidad
        </p>
        <img
          class="tw-mt-4 tw-mb-4 tw-w-full tw-hidden lg:tw-block"
          src="@/assets/img/diversity/Infografia_desk.png"
          alt=""
        />
        <img
          class="tw-mt-4 tw-mb-4 tw-w-full tw-block lg:tw-hidden"
          src="@/assets/img/diversity/Infografia_mobile.png"
          alt=""
        />
        <!--        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"
        >
          Trivia de diversidad
        </p>-->
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .hijo {
    position: relative;
    width: 100vw;
    left: calc(50% - 50vw);
  }
}
</style>
