var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"mainSideBar"}},[_c('div',{staticClass:"tw-fixed tw-bg-white tw-shadow-mobileBar md:tw-shadow-lg tw-bottom-2 tw-rounded-2.5xl lg:tw-rounded-none tw-left-1/2 tw-transform tw--translate-x-1/2 md:tw-translate-x-0 md:tw-top-0 md:tw-left-0 tw-h-auto md:tw-h-full tw-w-auto md:tw-w-20 md:tw-pt-24 lg:tw-pt-[7rem] tw-z-[45] lg:tw-w-[70px]"},[(!_setup.isLoading)?_c('div',{staticClass:"tw-h-full tw-items-center tw-gap-2 tw-px-4 tw-pt-4 tw-pb-2 md:tw-pt-4 md:tw-pb-0 md:tw-px-2 tw-grid tw-grid-flow-col md:tw-flex md:tw-flex-col md:tw-space-y-6"},[_vm._l((_setup.userShortcuts),function(favShortcut,idx){return _c('div',{key:idx,staticClass:"tw-group tw-flex tw-flex-col tw-text-center tw-cursor-pointer tw-w-full xs:tw-w-16 tw-h-16 md:tw-h-auto tw-items-center tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-b-0 tw-border-t-0 tw-relative",on:{"click":function($event){_setup.route.path === favShortcut.url
            ? '#'
            : _setup.goToView(favShortcut.url, favShortcut.title)}}},[_c('div',{staticClass:"tw-h-5/6 tw-w-full tw-absolute tw-z-0 tw--m-2 md:tw-m-0 md:tw--mr-2 tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-b-0 tw-border-t-0 md:tw-border-r-2 md:tw-border-transparent md:group-hover:tw-border-red-350",class:_setup.routeIncludesShortcut(favShortcut.url)
              ? 'tw-border-t-2 tw-border-red-350 md:tw-border-red-350 md:tw-border-t-0'
              : ''}),_c('div',{staticClass:"tw-relative tw-w-9 tw-h-9 tw-flex tw-items-center tw-justify-center"},[_c('img',{staticClass:"tw-absolute tw-rounded-md tw-mb-1 tw-object-cover tw-w-8 tw-h-8 xs:tw-w-9 xs:tw-h-9 tw-top-0 group-hover:tw-opacity-100",class:_setup.routeIncludesShortcut(favShortcut.url)
                ? 'tw-opacity-100'
                : 'tw-opacity-0',attrs:{"loading":"lazy","src":_setup.uriResources + '/' + favShortcut.image_hover,"alt":favShortcut.title}}),_c('img',{staticClass:"tw-absolute tw-rounded-md tw-mb-1 tw-object-cover tw-w-8 tw-h-8 xs:tw-w-9 xs:tw-h-9 tw-top-0",class:[
              favShortcut.image_hover ? 'group-hover:tw-opacity-0' : '',
              _setup.routeIncludesShortcut(favShortcut.url)
                ? 'tw-opacity-0'
                : 'tw-opacity-100',
            ],attrs:{"loading":"lazy","src":_setup.uriResources + '/' + favShortcut.image,"alt":favShortcut.title}})]),_c('p',{staticClass:"tw-mb-0 tw-font-roboto tw-pt-2",staticStyle:{"font-size":"9px","line-height":"10px"}},[_vm._v(" "+_vm._s(favShortcut.title)+" ")])])}),(_setup.showEditButton)?_c('div',{staticClass:"tw-flex tw-flex-col tw-text-center tw-items-center tw-cursor-pointer tw-w-12 xs:tw-w-14 md:tw-w-16 tw-h-16",on:{"click":_setup.showModal}},[_vm._m(0),_c('p',{staticClass:"tw-mb-0 tw-font-robotoBold tw-pt-2 tw-text-xxs"},[_vm._v("Editar")])]):_vm._e()],2):_vm._e()]),(_setup.modalView)?_c(_setup.CompFavoritesOption,{on:{"closeModal":_setup.hideModal}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-w-7 xs:tw-w-9 tw-h-9 tw-flex tw-items-center tw-justify-center"},[_c('i',{staticClass:"tw-text-3xl md:tw-text-3xl tw-text-black icon-editar"})])
}]

export { render, staticRenderFns }