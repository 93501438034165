<script setup>
import { computed, ref, onBeforeMount, onMounted } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useSocialNetworkStore } from "@/stores/socialNetworkStore";
import { useRouter, useRoute } from "vue-router/composables";
import CompStars from "@/components/rating/CompStars";
import BreadCrumb from "@/components/general/BreadCrumb";
import ClaimModal from "@/components/ecommerce/ClaimModal";
import CompButtonShoppingCart from "@/components/ecommerce/CompButtonShoppingCart";
import CompFooterEcommerceModal from "@/components/ecommerce/CompFooterEcommerceModal";
import MobileBar from "@/components/general/MobileBar";

const ecommerceStore = useEcommerceStore();
const socialNetworkStore = useSocialNetworkStore();
const router = useRouter();
const route = useRoute();
const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const searchText = ref("");
const max_day_gift = ref(null);
// const currentDay = ref(new Date().getDate());
const ratingData = ref({});
const breadcrumb = ref([
  {
    id: 1,
    name: "Home",
    link: "/home",
    active: false,
  },
  {
    id: 2,
    name: "Mundo Embajada",
    link: "/e-commerce",
    active: false,
  },
  {
    id: 3,
    name: "Nuestro Catálogo",
    link: "#",
    active: true,
  },
]);

const filterProducts = computed(() => {
  return ecommerceStore.products[route.params.cat].ecomm_product.filter(
    (product) => {
      return product.name
        .toLowerCase()
        .includes(searchText.value.toLowerCase());
    }
  );
});

const arrProducts = computed(() => {
  return ecommerceStore.products[route.params.cat].ecomm_product.map(
    (product, index) => {
      return {
        name: product.name,
        id: product.code_public,
        price: product.price_public,
        brand: product.ecomm_brand.name,
        position: index,
      };
    }
  );
});

const addToCart = (product) => {
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "COP",
      add: {
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price_public,
            brand: product.ecomm_brand.name,
            category: "Obsequio",
            quantity: 1,
          },
        ],
      },
    },
  });
  ecommerceStore.addProductToShoppingCart(product);
};

onMounted(() => {
  window.dataLayer.push({
    event: "productImpression",
    ecommerce: {
      currencyCode: "COP",
      impressions: arrProducts,
    },
    value: 5000,
  });
});

onBeforeMount(async () => {
  isLoading.value = true;
  if (ecommerceStore.products.length === 0) {
    await ecommerceStore.actGetProducts().then(() => {
      ecommerceStore.products.length === 0 ? router.push("/e-commerce") : null;
    });
  }

  max_day_gift.value = ecommerceStore.products.filter(
    (product) => product.name === "OBSEQUIO"
  )[0].max_day;

  // if (currentDay.value > max_day_gift.value) {
  //   router.push("/e-commerce");
  // } else {
  await socialNetworkStore
    .actGetRating("Ecommerce")
    .then((data) => {
      ratingData.value = data;
    })
    .then(() => {
      isLoading.value = false;
    });
  // }
});
</script>
<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0" v-if="!isLoading">
    <mobile-bar title="Nuestro Catálogo" />
    <div class="container px-0 px-md-3 mt-md-4">
      <div class="row">
        <div class="col-12 mt-2 mt-md-4">
          <BreadCrumb :crumbs="breadcrumb" />
        </div>
      </div>
    </div>

    <!-- Titulo Desktop y leyenda -->
    <div
      class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
    >
      <ClaimModal />
      <div class="col-12 col-md-6 px-0 d-none d-md-flex order-md-1 order-last">
        <div v-if="Number(route.params.cat) === 0">
          <p class="tw-font-tolyerBold fs-md-40 text-uppercase">
            Nuestro Catálogo
          </p>
        </div>
        <div v-else>
          <p class="tw-font-tolyerBold fs-md-40 text-uppercase">
            Elige tu Obsequio
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 px-md-0 order-md-2 order-first">
        <div class="d-flex justify-content-between justify-content-md-end py-0">
          <div class="col-auto col-md-auto my-md-0 my-1 px-md-0 px-3 py-0">
            <router-link
              to="/e-commerce/orderStatus"
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-3 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-flex text-center align-items-center"
            >
              <i
                class="icon-ecommerce-canasta tw-text-gray-600 mr-0 mr-md-2 fs-24 fs-md-16"
              ></i>
              <p
                class="mb-0 font-weight-bold tw-text-black tw-text-sm mr-2 d-none d-md-block"
              >
                Mis pedidos
              </p>
              <div class="d-none d-md-block">
                <div
                  class="wi-20 he-20 tw-rounded-full bg-white tw-border tw-border-solid tw-border-red-400 d-flex justify-content-center align-items-center"
                >
                  <div
                    class="wi-6 he-6 tw-bg-red-400 tw-rounded-full position-absolute mt-n3 ml-2 tw-border tw-border-white tw-border-solid"
                  ></div>
                  <span class="tw-text-red-400 tw-text-xs">
                    {{ ecommerceStore.ordersPending.length }}
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-2 mt-md-3">
      <div class="row d-flex align-items-center">
        <div class="d-none d-md-flex col-12 col-md-6 col-lg-8 px-0 px-md-3">
          <p
            class="tw-font-roboto tw-text-base line-height-24 my-auto"
            v-if="Number(route.params.cat) === 0"
          >
            ¿Ves algo que te puede interesar?
          </p>
          <p class="tw-font-roboto tw-text-base line-height-24 my-auto" v-else>
            Este es uno de tus beneficios por ser parte de la familia de
            Embajadores Beer Office
          </p>
        </div>
        <div
          class="col-12 col-md-6 col-lg-4"
          v-if="Number(route.params.cat) === 0"
        >
          <div class="d-flex px-3 px-md-0 py-3 py-md-0">
            <form action="#" class="position-relative w-100">
              <input
                type="text"
                name=""
                id="searchbar"
                class="w-100 tw-rounded-lg tw-bg-gray-200 px-3 input-lg line-height-30 tw-font-roboto tw-text-sm he-40 tw-border-none"
                v-model="searchText"
                autocomplete="off"
                placeholder="¿Qué estás buscando?"
              />
              <img
                src="@/assets/img/icons/search-solid.svg"
                width="17"
                height="17"
                alt="search"
                class="position-absolute tw-top-2 tw-right-2"
              />
            </form>
          </div>
        </div>
      </div>

      <!-- Grilla de productos -->
      <div
        class="row mt-3 mt-md-5 px-3 px-md-0"
        v-if="
          ecommerceStore.products &&
          ecommerceStore.products[0] &&
          filterProducts
        "
      >
        <div
          class="col-6 col-md-4 col-lg-3"
          v-for="(product, index) in filterProducts"
          :key="index"
        >
          <div class="tw-text-gray-600 mb-4">
            <div
              class="min-he-130 tw-shadow tw-rounded-lg bg-loading mt-2 mb-4"
            >
              <router-link :to="'/e-commerce/product/' + product.id">
                <img
                  :src="uriResources + '/' + product.img"
                  loading="lazy"
                  width="100%"
                  alt="img 140"
                  class="tw-rounded-lg"
                />
              </router-link>
            </div>
            <div>
              <p
                class="d-none d-md-block tw-font-roboto tw-text-sm line-height-18 mt-2 mb-2 he-40"
              >
                {{ product.name }}
              </p>
              <p
                class="d-block d-md-none tw-font-roboto tw-text-xs line-height-14 text-left tw-text-black mt-3 mb-2 he-25"
              >
                {{
                  product.name.charAt(0).toUpperCase() +
                  product.name.slice(1).toLowerCase()
                }}
              </p>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p
                class="tw-font-robotoBold tw-text-sm tw-text-gray-600"
                v-if="Number(route.params.cat) === 0"
              >
                {{
                  Intl.NumberFormat("es-CO", {
                    currency: "COP",
                    maximumFractionDigits: 0,
                  }).format(product.price_public)
                }}
              </p>
              <p class="tw-font-robotoBold tw-text-sm tw-text-gray-600" v-else>
                ¡OBSEQUIO!
              </p>
              <CompStars
                category="Ecommerce"
                :idContent="product.id"
                :ratingData="ratingData[product.id]"
                :showStars="false"
              />
            </div>
            <button
              @click="addToCart(product)"
              class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 btn-block"
            >
              <span class="d-none d-md-block m-0 tw-font-roboto tw-text-base">
                Agregar al carrito
              </span>
              <span class="d-block d-md-none m-0 tw-font-roboto tw-text-base">
                Agregar
              </span>
            </button>
          </div>
        </div>
        <!-- .col -->
      </div>
      <div class="row">
        <div class="col ml-auto">
          <CompFooterEcommerceModal />
        </div>
      </div>
      <!-- .row -->
      <CompButtonShoppingCart />
    </div>
  </div>
</template>

<style scoped>
input:focus {
  outline: none;
}
</style>
