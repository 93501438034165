<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";

const { user } = storeToRefs(useUserStore());

defineProps({
  reservationData: Object,
  updateGuest: Function,
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-5">
    <div id="employeeTitle">
      <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl">Información</p>
      <p class="tw-font-roboto tw-text-xs md:tw-text-lg">
        Ingresa los datos de tus acompañantes
      </p>
    </div>
    <div id="employeeInfo">
      <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl">Funcionario</p>
      <div
        class="tw-border tw-border-gray-250 tw-rounded-2xl tw-grid tw-grid-cols-col2_fit_first tw-p-5"
      >
        <div class="tw-flex">
          <div class="tw-flex tw-flex-col tw-gap-3">
            <p class="tw-font-robotoBold tw-text-xs md:tw-text-base tw-m-0">
              Nombre
            </p>
            <p class="tw-font-robotoBold tw-text-xs md:tw-text-base tw-m-0">
              Cédula
            </p>
            <p class="tw-font-robotoBold tw-text-xs md:tw-text-base tw-m-0">
              Site
            </p>
            <p class="tw-font-robotoBold tw-text-xs md:tw-text-base tw-m-0">
              Cargo
            </p>
          </div>
          <div
            class="tw-border-l tw-border-gray-250 tw-h-full tw-mx-2 md:tw-mx-4"
          ></div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <p
            class="tw-font-roboto tw-text-xs md:tw-text-base tw-m-0 tw-capitalize"
          >
            {{ user.name.toLowerCase() }}
          </p>
          <p class="tw-font-roboto tw-text-xs md:tw-text-base tw-m-0">
            {{ user.cc }}
          </p>
          <p class="tw-font-roboto tw-text-xs md:tw-text-base tw-m-0">
            {{ user.core_site.name }}
          </p>
          <p
            class="tw-font-roboto tw-text-xs md:tw-text-base tw-m-0 tw-capitalize"
          >
            {{ user.core_position.name.toLowerCase() }}
          </p>
        </div>
      </div>
    </div>
    <hr class="tw-w-full" />
    <div
      v-for="(_, idx) in reservationData.guestsNumber.adults - 1"
      :key="idx"
      class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-5"
    >
      <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl tw-m-0">
        Adulto nº {{ idx + 2 }}
      </p>
      <div>
        <label class="tw-font-roboto tw-text-xs md:tw-text-lg" for="adultName"
          >Nombre/s</label
        >
        <input
          :value="reservationData.adults[idx].name"
          @input="updateGuest('adults', $event.target.value, 'name', idx)"
          class="tw-form-input"
          name="adultName"
          placeholder="Ingresar datos..."
          type="text"
        />
      </div>
      <div>
        <label
          class="tw-font-roboto tw-text-xs md:tw-text-lg"
          for="adultLastName"
          >Apellidos</label
        >
        <input
          :value="reservationData.adults[idx].surname"
          @input="updateGuest('adults', $event.target.value, 'surname', idx)"
          class="tw-form-input"
          name="adultLastName"
          placeholder="Ingresar datos..."
          type="text"
        />
      </div>
      <div class="tw-grid tw-grid-cols-col2_fit_first tw-gap-6">
        <div>
          <label
            class="tw-font-roboto tw-text-xs md:tw-text-lg"
            for="adultDocument_type"
            >Tipo de documento</label
          >

          <CompGeneralDropdown
            :fitted="false"
            :isLabel="false"
            :options="[
              {
                id: 0,
                name: 'CC',
              },
              {
                id: 1,
                name: 'CE',
              },
              {
                id: 2,
                name: 'TI',
              },
              {
                id: 3,
                name: 'Pasaporte',
              },
            ]"
            name="adultDocument_type"
            @changeSelectedOption="
              (option) =>
                updateGuest('adults', option.name, 'document_type', idx)
            "
            @chargeOption="
              (option) =>
                updateGuest('adults', option.name, 'document_type', idx)
            "
          />
        </div>
        <div>
          <label class="tw-font-roboto tw-text-xs md:tw-text-lg" for="adultId"
            >Documento de identidad</label
          >
          <input
            :value="reservationData.adults[idx].document_id"
            @input="
              updateGuest('adults', $event.target.value, 'document_id', idx)
            "
            class="tw-form-input"
            name="adultId"
            placeholder="Ingresar datos..."
            type="text"
          />
        </div>
      </div>
      <div>
        <label class="tw-font-roboto tw-text-xs md:tw-text-lg" for="adultEPS"
          >EPS</label
        >
        <input
          :value="reservationData.adults[idx].eps"
          @input="updateGuest('adults', $event.target.value, 'eps', idx)"
          class="tw-form-input"
          name="adultEPS"
          placeholder="Ingresar datos..."
          type="text"
        />
      </div>
      <hr class="tw-w-full" />
    </div>
    <div
      v-for="(_, idx) in reservationData.guestsNumber.children"
      :key="'child' + idx"
      class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-5"
    >
      <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl tw-m-0">
        Niño/a nº {{ idx + 1 }}
      </p>
      <div>
        <label
          class="tw-font-roboto tw-text-xs md:tw-text-lg"
          for="childrenName"
          >Nombre/s</label
        >
        <input
          :value="reservationData.children[idx].name"
          @input="updateGuest('children', $event.target.value, 'name', idx)"
          class="tw-form-input"
          name="childrenName"
          placeholder="Ingresar datos..."
          type="text"
        />
      </div>
      <div>
        <label
          class="tw-font-roboto tw-text-xs md:tw-text-lg"
          for="childrenLastName"
          >Apellidos</label
        >
        <input
          :value="reservationData.children[idx].surname"
          @input="updateGuest('children', $event.target.value, 'surname', idx)"
          class="tw-form-input"
          name="childrenLastName"
          placeholder="Ingresar datos..."
          type="text"
        />
      </div>
      <div class="tw-grid tw-grid-cols-col2_fit_first tw-gap-6">
        <div>
          <label
            class="tw-font-roboto tw-text-xs md:tw-text-lg"
            for="childrenDocument_type"
            >Tipo de documento</label
          >
          <CompGeneralDropdown
            :fitted="false"
            :isLabel="false"
            :options="[
              {
                id: 0,
                name: 'TI',
              },
              {
                id: 1,
                name: 'Pasaporte',
              },
            ]"
            name="childrenDocument_type"
            @changeSelectedOption="
              (option) =>
                updateGuest('children', option.name, 'document_type', idx)
            "
            @chargeOption="
              (option) =>
                updateGuest('children', option.name, 'document_type', idx)
            "
          />
        </div>
        <div>
          <label
            class="tw-font-roboto tw-text-xs md:tw-text-lg"
            for="childrenId"
            >Documento de identidad</label
          >
          <input
            :value="reservationData.children[idx].document_id"
            @input="
              updateGuest('children', $event.target.value, 'document_id', idx)
            "
            class="tw-form-input"
            name="childrenId"
            placeholder="Ingresar datos..."
            type="text"
          />
        </div>
      </div>
      <div>
        <label class="tw-font-roboto tw-text-xs md:tw-text-lg" for="childrenEPS"
          >EPS</label
        >
        <input
          :value="reservationData.children[idx].eps"
          @input="updateGuest('children', $event.target.value, 'eps', idx)"
          class="tw-form-input"
          name="childrenEPS"
          placeholder="Ingresar datos..."
          type="text"
        />
      </div>
      <hr class="tw-w-full" />
    </div>
  </div>
</template>

<style scoped></style>
