<script setup>
import { onMounted, useSlots } from "vue";

const slots = useSlots();

const props = defineProps({
  openFirst: {
    type: Boolean,
  },
  alwaysOpenOne: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  if (props.openFirst) {
    slots.default()[0]?.elm?.classList.add("active");
  }
});
</script>
<template>
  <div
    id="accordion_container"
    class="accordion_container tw-flex tw-flex-col tw-w-full tw-gap-2"
  >
    <slot></slot>
  </div>
</template>
