var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"tw-rounded-xl lg:tw-rounded-2xl tw-border tw-border-gray-350 tw-p-4 tw-flex tw-flex-col tw-gap-2"},[_vm._m(0),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.hoursCleaning,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', { ..._setup.props.data, hours_cleaning: Number(value) })}}),_vm._m(1)],1),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.hoursCooking,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', { ..._setup.props.data, hours_cooking: Number(value) })}}),_vm._m(2)],1),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.hoursTransport,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', { ..._setup.props.data, hours_transport: Number(value) })}}),_vm._m(3)],1),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.hoursPersonalErrands,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', {
            ..._setup.props.data,
            hours_personal_errands: Number(value),
          })}}),_vm._m(4)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm"},[_vm._v(" ¿Cuántas horas dedicas a la semana en diferentes tareas extracurriculares en tu hogar?* ")]),_c('p',{staticClass:"tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs"},[_vm._v(" *En caso de que no aplique, pon 0 en la respuesta ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v("Limpieza del hogar")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v("Cocinar")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v(" Transportarse hasta lugar de residencia ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v("Hacer vueltas personales")])])
}]

export { render, staticRenderFns }