<script setup>
import { Navigation, Pagination, Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import CompDiversityChampionsArticleMainCard from "@/components/diversity/CompDiversityChampionsArticleMainCard.vue";
SwiperCore.use([Navigation, Pagination, Autoplay]);

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  loadedItems: {
    type: Boolean,
    required: false,
  },
});

const onSwiper = (swiper) => {
  let index = swiper.realIndex - 1;
  if (index > props.items.length - 1) {
    index = 0;
  }
};
</script>

<template>
  <div>
    <div v-if="loadedItems">
      <swiper
        ref="swiper"
        :slides-per-view="1"
        :space-between="16"
        class="swiper-diversity"
        :pagination="{ clickable: true, $el: '.swiper-pagination' }"
        @slideChange="onSwiper"
        :autoplay="{ delay: 5000 }"
        noSwiping
        noSwipingClass="swiper-no-swiping"
      >
        <swiper-slide v-for="(item, idx) in props.items" :key="idx">
          <comp-diversity-champions-article-main-card :item="item" />
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<style lang="postcss">
.swiper-diversity .swiper-pagination {
  @apply tw-text-center md:tw-text-left lg:tw-w-[300px] tw-mt-1 -tw-bottom-1;
}
.swiper-diversity .swiper-pagination-bullets {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}
.swiper-diversity .swiper-pagination-bullets {
  .swiper-pagination-bullet-active {
    @apply tw-bg-red-400;
  }
}
</style>
