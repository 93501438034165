<script setup>
import config from "@/services/config";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useContestsStore } from "@/stores/contestsStore";
import { useRoute, useRouter } from "vue-router/composables";

const uriResources = config.beerOfficeURL;
const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const contestsStore = useContestsStore();
const { user } = storeToRefs(userStore);
const { actPostQuestionAnswer } = contestsStore;
const { competition, currentQuestion, currentQuestionIndex } =
  storeToRefs(contestsStore);
const currentQuestionAnswer = ref(null);
const currentQuestionCorrectAnswers = ref(null);
const isCurrentAnswerReviewed = ref(false);
const isCurrentAnswerCorrect = ref(false);
const isLoadingAnswer = ref(false);

const answerClasses = {
  normal: "tw-bg-white tw-border-gray-300",
  correct: "tw-bg-green-400 tw-border-green-400 tw-text-white",
  incorrect: "tw-bg-red-350 tw-border-red-350 tw-text-white",
};

const checkIfAnswerIsCorrect = (answer) => {
  return currentQuestionCorrectAnswers.value?.some(
    (correctAnswer) => correctAnswer.id === answer.id
  );
};

const returnAnswerClass = (answer) => {
  if (!isCurrentAnswerReviewed.value) {
    return answerClasses.normal;
  }

  if (answer.id === currentQuestionAnswer.value?.id) {
    return isCurrentAnswerCorrect.value
      ? answerClasses.correct
      : answerClasses.incorrect;
  }

  if (checkIfAnswerIsCorrect(answer)) {
    return answerClasses.correct;
  }

  return answerClasses.normal;
};

const getLabelClasses = computed(() => {
  return (answer) => {
    return {
      "tw-m-0 custom-radio-label tw-font-roboto tw-w-full before:tw-border-solid before:tw-border-[1px]": true,
      "before:has-[:checked]:tw-bg-green-400": !isCurrentAnswerReviewed.value,
      "before:tw-bg-white before:tw-border-0":
        isCurrentAnswerReviewed.value &&
        answer.id !== currentQuestionAnswer.value?.id,
      "before:tw-bg-green-400":
        isCurrentAnswerReviewed.value &&
        answer.id === currentQuestionAnswer.value?.id &&
        checkIfAnswerIsCorrect(answer),
      "before:tw-bg-red-350":
        isCurrentAnswerReviewed.value &&
        answer.id === currentQuestionAnswer.value?.id &&
        !checkIfAnswerIsCorrect(answer),
    };
  };
});

const sendCurrentQuestionAnswer = async () => {
  isLoadingAnswer.value = true;
  if (currentQuestion.value.type.toLowerCase() === "multiple") {
    await actPostQuestionAnswer({
      id: user.value.id,
      cc: user.value.cc,
      comp_answer_id: currentQuestionAnswer.value.id,
      comp_question_id: currentQuestion.value.id,
    }).then((res) => {
      console.log(res);
      const { is_correct_answer } = res;
      const { correct_answers } = res;
      isCurrentAnswerCorrect.value = is_correct_answer;
      currentQuestionCorrectAnswers.value = correct_answers;
      isCurrentAnswerReviewed.value = true;
    });
  } else if (currentQuestion.value.type.toLowerCase() === "open") {
    await actPostQuestionAnswer({
      id: user.value.id,
      cc: user.value.cc,
      answer_text: currentQuestionAnswer.value,
      comp_question_id: currentQuestion.value.id,
    }).then((res) => {
      console.log(res);
      isCurrentAnswerReviewed.value = true;
    });
  }

  isLoadingAnswer.value = false;
};

const nextQuestion = () => {
  if (
    currentQuestionIndex.value + 1 ===
    competition.value.unanswered_questions.length
  ) {
    router.push(`/contests/${route.params.id}/finish`);
  } else {
    currentQuestionIndex.value++;
    currentQuestion.value =
      competition.value.unanswered_questions[currentQuestionIndex.value];
    currentQuestionAnswer.value = null;
    isCurrentAnswerCorrect.value = false;
    isCurrentAnswerReviewed.value = false;
  }
};
</script>

<template>
  <main>
    <p class="tw-font-robotoBold tw-text-lg lg:tw-text-2xl">
      {{ competition.name }}
    </p>
    <p class="tw-font-robotoBold tw-text-base lg:tw-text-xl">
      {{ currentQuestion.name }}
    </p>
    <section
      class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-8 lg:tw-grid-rows-rows_2_fit_last tw-gap-3 lg:tw-gap-x-6 lg:tw-gap-y-3"
    >
      <div class="lg:tw-col-span-2 tw-flex tw-justify-center">
        <img
          :src="`${uriResources}/${currentQuestion.image}`"
          alt=""
          class="tw-rounded-2.5xl tw-aspect-1 tw-w-60 lg:tw-w-full lg:tw-h-full tw-object-cover tw-object-center"
        />
      </div>
      <div class="lg:tw-col-span-6 tw-row-span-1 tw-flex tw-flex-col">
        <p class="tw-font-robotoBold tw-text-base lg:tw-text-xl">
          {{ currentQuestion.question }}
        </p>
        <!-- input radio -->
        <section
          class="tw-flex tw-flex-col tw-gap-2"
          v-if="currentQuestion.type.toLowerCase() === 'multiple'"
        >
          <div
            v-for="(answer, idx) in currentQuestion.comp_answer"
            :key="idx"
            class="tw-flex tw-items-center tw-gap-2 tw-p-2 tw-text-black tw-rounded-2.5xl tw-w-full tw-border"
            :class="returnAnswerClass(answer)"
          >
            <label
              :for="`question${currentQuestionIndex}-${idx}`"
              :class="getLabelClasses(answer)"
            >
              <input
                type="radio"
                :name="`question${currentQuestionIndex}`"
                :id="`question${currentQuestionIndex}-${idx}`"
                class="custom-radio-input"
                :value="answer"
                v-model="currentQuestionAnswer"
                :disabled="isCurrentAnswerReviewed"
              />
              {{ answer.answer }}
            </label>
          </div>
        </section>
        <section
          v-if="currentQuestion.type.toLowerCase() === 'open'"
          class="tw-h-32 lg:tw-flex-1 lg:tw-h-full"
        >
          <textarea
            class="tw-flex-auto tw-w-full tw-h-full tw-p-3 lg:tw-p-4 tw-rounded-2.5xl tw-border tw-border-gray-300 tw-resize-none"
            :class="
              isCurrentAnswerReviewed
                ? 'tw-bg-gray-100 tw-text-gray-550'
                : 'tw-bg-white tw-text-black'
            "
            v-model="currentQuestionAnswer"
            :disabled="isCurrentAnswerReviewed"
            placeholder="Escribe tu respuesta aquí..."
          />
        </section>
      </div>
      <div
        class="lg:tw-col-span-8 tw-w-full tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-end tw:tw-items-center tw-gap-2 tw-mt-2"
      >
        <div
          class="tw-flex tw-items-center"
          v-if="
            currentQuestion.type.toLowerCase() === 'multiple' &&
            isCurrentAnswerReviewed
          "
        >
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base"
            v-if="isCurrentAnswerReviewed && !isLoadingAnswer"
            :class="
              isCurrentAnswerCorrect ? 'tw-text-green-400' : 'tw-text-red-350'
            "
          >
            {{
              isCurrentAnswerCorrect
                ? "¡Respuesta correcta!"
                : "¡Respuesta incorrecta!"
            }}
          </p>
        </div>
        <comp-button-primary-red
          :disabled="!currentQuestionAnswer"
          :title="
            !isCurrentAnswerReviewed &&
            currentQuestion.type.toLowerCase() === 'multiple'
              ? 'Revisar'
              : !isCurrentAnswerReviewed &&
                currentQuestion.type.toLowerCase() === 'open'
              ? 'Continuar'
              : isCurrentAnswerReviewed &&
                currentQuestionIndex + 1 <
                  competition.unanswered_questions.length
              ? 'Continuar'
              : 'Finalizar'
          "
          class="tw-w-full lg:tw-w-1/3 tw-h-full tw-py-3"
          @click.native="
            !isCurrentAnswerReviewed
              ? sendCurrentQuestionAnswer()
              : nextQuestion()
          "
        />
      </div>
    </section>
  </main>
</template>

<style scoped></style>
