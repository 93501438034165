<template>
  <div id="compensationHome">
    <BottleSplash v-if="bottleSplash === true" />
    <div class="tw-container-general" v-else>
      <!-- <BreadCrumb :crumbs="breadcrumb" /> -->
      <BreadCrumb :crumbs="breadcrumb" />

      <MobileBar title="TU REMUNERACIÓN" />
      <!-- Título Desktop y leyenda -->
      <div class="tw-flex lg:tw-flex-row tw-flex-col mt-2 mt-md-4">
        <div class="col-12 col-md-6 px-0 order-md-1 order-last">
          <div class="d-none d-md-flex">
            <p class="tw-font-tolyerBold fs-md-40 text-uppercase">
              Tu Remuneración
            </p>
          </div>
          <div class="tw-flex">
            <div class="">
              <p
                class="tw-font-roboto tw-text-xs fs-md-14 line-height-20 tw-text-gray-400 pt-3"
              >
                Conoce los detalles de tu compensación y la propuesta de valor
                que la compañía te otorga.***
              </p>
              <p class="tw-font-robotoBold fs-md-14 d-inline-block pr-1 my-0">
                Nombre:
              </p>
              <p class="tw-font-roboto fs-md-14 d-inline-block pr-1 my-0">
                {{ user.name }}
              </p>
              <br />
              <p class="tw-font-robotoBold fs-md-14 d-inline-block pr-1 my-0">
                Cargo:
              </p>
              <p class="tw-font-roboto fs-md-14 d-inline-block pr-1 my-0">
                {{ user.core_position.name }}
              </p>
              <br />
              <p class="tw-font-robotoBold fs-md-14 d-inline-block pr-1 my-0">
                Banda:
              </p>
              <p class="tw-font-roboto fs-md-14 d-inline-block pr-1 my-0">
                {{ user.core_pa_sideline.name }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Contenedor Banner y Salario -->
      <div class="mt-2 mt-md-5">
        <div class="tw-flex lg:tw-flex-row tw-flex-col">
          <div class="col-md-4 p-0">
            <p class="tw-font-robotoBold tw-text-base line-height-20">
              Aprende los detalles de tu compensación en los siguientes videos
            </p>
            <RemunerationVideo class="mt-3" />
            <!-- <RemunerationVideo class="mt-3" /> -->
          </div>
          <div class="col-md-8 pl-md-5 pr-md-0 px-0 mt-4 mt-md-0">
            <div
              id="salario"
              class="tw-rounded-tr-2xl tw-rounded-tl-2xl tw-rounded-bl-lg tw-rounded-br-lg tw-shadow-md container overflow-hidden tw-flex tw-flex-col justify-content-between pb-0"
            >
              <!-- Remuneration items -->
              <div class="px-2 px-md-0 container">
                <!-- Header Salario -->
                <div class="row px-2 py-4 p-md-4">
                  <div class="col-10 col-md-8 tw-flex tw-flex-col">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-18 text-uppercase my-auto"
                    >
                      Tu Salario Anual
                    </p>
                    <!-- <p
                      class="
                        tw-font-roboto
                         tw-text-xxs  fs-md-14
                        my-auto
                        pt-4
                        line-height-12 line-height-md-18
                      "
                    >
                      Conoce los detalles de tu compensación y la propuesta de
                      valor que la compañia te otorga***
                    </p> -->
                  </div>
                  <div class="col-2 col-md-4">
                    <img
                      src="../../assets/img/show-me/salario-desk.svg"
                      class="he-md-40 float-right d-none d-md-block"
                      alt=""
                    />
                    <img
                      src="../../assets/img/show-me/salario-mob.svg"
                      class="he-65 float-right d-block d-md-none"
                      alt=""
                    />
                  </div>
                </div>

                <!-- User Salary -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 informative"
                  v-if="user.anual_salary > 0"
                  v-b-modal.modal-compensationPrice
                  @click="modalCompensationText('base')"
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                    >
                      Salario Base
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(user.anual_salary) }}
                    </p>
                    <div class="my-auto pl-3 pl-md-3">
                      <i class="icon-mas-informacion tw-text-xs fs-md-16"></i>
                    </div>
                  </div>
                </div>

                <!-- Prestaciones Legales -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 informative"
                  v-if="user.legal_premium > 0"
                  v-b-modal.modal-compensationPrice
                  @click="modalCompensationText('plegal')"
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                    >
                      Prestaciones Legales
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(user.legal_premium) }}
                    </p>
                    <div class="my-auto pl-3 pl-md-3">
                      <i class="icon-mas-informacion tw-text-xs fs-md-16"></i>
                    </div>
                  </div>
                </div>

                <!-- Primas Extraegales -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 informative"
                  v-if="user.extra_legal_premium * 1 > 0"
                  v-b-modal.modal-compensationPrice
                  @click="modalCompensationText('pelegal')"
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                    >
                      Primas Extra legales
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(user.extra_legal_premium) }}
                    </p>
                    <div class="my-auto pl-3 pl-md-3 tw-text-xs fs-md-16">
                      <i class="icon-mas-informacion"></i>
                    </div>
                  </div>
                </div>

                <!-- Bono LTI -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 informative"
                  v-if="user.values_lti * 1 > 0"
                  v-b-modal.modal-compensationPrice
                  @click="modalCompensationText('blti')"
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto tw-cursor-pointer"
                    >
                      Bono LTI
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(user.values_lti) }}
                    </p>
                    <div class="my-auto pl-3 pl-md-3 tw-text-xs fs-md-16">
                      <i class="icon-mas-informacion"></i>
                    </div>
                  </div>
                </div>

                <!-- Bono ABI -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 linked abi tw-cursor-pointer"
                  v-if="
                    (employeeCorePosition === '0-IV' ||
                      employeeCorePosition === 'V-VI') &&
                    user.target_bonus > 0
                  "
                  @click="
                    $router.push({
                      name: 'CalculadoraCompensacionFija',
                      params: { bonusType: 'ABI' },
                    }),
                      pushDataLayerClics('Bono ABI')
                  "
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                      data-hover="Simula tu "
                    >
                      Bono ABI
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(user.target_bonus) }}
                    </p>
                    <div class="my-auto pl-2 pl-md-3 tw-text-xl">
                      <i class="icon-flecha-der tw-text-red-400"></i>
                    </div>
                  </div>
                </div>

                <!-- Bono STI -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 linked abi"
                  v-if="
                    Number(user.total_values_sti) !== 0 &&
                    (employeeCorePosition === 'VII-VIII' ||
                      employeeCorePosition === 'VIII+')
                  "
                  @click="
                    $router.push({
                      name: 'CalculadoraCompensacionFija',
                      params: { bonusType: 'STI' },
                    }),
                      pushDataLayerClics('Bono STI')
                  "
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                      data-hover="Simula tu "
                    >
                      Bono STI
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(user.total_values_sti) }}
                    </p>
                    <div class="my-auto pl-2 pl-md-3 tw-text-xl">
                      <i class="icon-flecha-der tw-text-red-400"></i>
                    </div>
                  </div>
                </div>

                <!-- Beneficios -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 linked z-index-5"
                  type="button"
                  v-if="calcTotalBenefits * 1 > 0"
                  @click="
                    $router.push({ path: '/showMeTheMoney/benefits' }),
                      pushDataLayerClics('Beneficios')
                  "
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                    >
                      Beneficios
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    >
                      {{ formatCurrency(calcTotalBenefits) }}
                    </p>
                    <div class="my-auto pl-2 pl-md-3 tw-text-xl">
                      <i class="icon-flecha-der tw-text-red-400"></i>
                    </div>
                  </div>
                </div>

                <!-- Remuneración Variable -->
                <router-link
                  :to="userAccessOptions !== null ? userAccessOptions.path : ''"
                >
                  <div
                    class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 linked"
                    v-if="
                      Number(user.variable_salary) > 0 ||
                      (userAccessOptions !== null &&
                        userAccessOptions.accessType === 3)
                    "
                  >
                    <div class="col-6 col-md-8 tw-flex">
                      <p
                        class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                      >
                        Remuneración Variable
                      </p>
                    </div>
                    <div class="col-6 col-md-4 tw-flex justify-content-end">
                      <p
                        class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                      >
                        {{ formatCurrency(user.variable_salary) }}
                      </p>
                      <div class="my-auto pl-2 pl-md-3 tw-text-xl">
                        <i class="icon-flecha-der tw-text-red-400"></i>
                      </div>
                    </div>
                  </div>
                </router-link>

                <!-- Horas Extras -->
                <div
                  class="row tw-border-to tw-border-gray-200 he-55 px-3 px-md-4 linked"
                  v-if="user.core_vp.name === 'Supply'"
                  @click="$router.push({ path: '/showMeTheMoney/extra-hours' })"
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold tw-text-xs fs-md-18 tw-font-bold tw-text-brown-600 my-auto"
                    >
                      Conoce el valor de tus horas extras
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xs fs-md-20 tw-font-normal tw-text-brown-600 my-auto"
                    ></p>
                    <div class="my-auto pl-2 pl-md-3 tw-text-xl">
                      <i class="icon-flecha-der tw-text-red-400"></i>
                    </div>
                  </div>
                </div>

                <!-- Porcentaje CR -->
                <!--                <div
                  class="
                    row
                    tw-border-to tw-border-gray-200
                    he-55
                    px-3 px-md-4
                    informative
                  "
                  v-if="user.percentage_cr * 1 > 0"
                  v-b-modal.modal-compensationPrice
                  @click="modalCompensationText('percentel')"
                >
                  <div class="col-6 col-md-8 tw-flex">
                    <p
                      class="
                        tw-font-robotoBold tw-text-xs
                        fs-md-18
                        tw-font-bold tw-text-brown-600
                        my-auto
                      "
                    >
                      Porcentaje CR
                    </p>
                  </div>
                  <div class="col-6 col-md-4 tw-flex justify-content-end">
                    <p
                      class="
                        tw-font-roboto tw-text-xs
                        fs-md-20
                        tw-font-normal tw-text-brown-600
                        my-auto
                      "
                    >
                      {{ (user.percentage_cr * 100).toFixed(0) }} %
                    </p>
                    <div class="my-auto pl-2 pl-md-3">
                      <i class="icon-mas-informacion"></i>
                    </div>
                  </div>
                </div>-->

                <!-- Salario Total Desktop-->
                <div
                  class="row tw-bg-green-400 py-3 py-md-3 px-3 px-md-4 d-md-flex d-none"
                >
                  <div class="col col-md-8 tw-flex">
                    <p
                      class="tw-font-robotoBold fs-md-18 tw-font-bold text-white my-auto"
                    >
                      Total Anual
                    </p>
                  </div>
                  <div class="col-auto col-md-4 tw-flex justify-content-end">
                    <p
                      class="tw-font-roboto tw-text-xl fs-md-20 tw-font-normal text-white my-auto"
                    >
                      {{ formatCurrency(calcTotalSalary) }}
                    </p>
                    <div class="d-none d-md-block my-auto pl-2 pl-md-3">
                      <i
                        class="icon-mas-informacion tw-text-xs fs-md-16 tw-text-green-400"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Salario Total Mobile-->
              <div
                class="row tw-bg-green-400 py-3 py-md-3 px-3 px-md-4 d-md-none tw-flex"
              >
                <div class="col col-md-8 tw-flex">
                  <p
                    class="tw-font-robotoBold fs-md-18 tw-font-bold text-white my-auto"
                  >
                    Total Anual
                  </p>
                </div>
                <div class="col-auto col-md-4 tw-flex justify-content-end">
                  <p
                    class="tw-font-roboto tw-text-xl fs-md-20 tw-font-normal text-white my-auto"
                  >
                    {{ formatCurrency(calcTotalSalary) }}
                  </p>
                  <div class="d-none d-md-block my-auto pl-2 pl-md-3">
                    <i
                      class="icon-mas-informacion tw-text-xs fs-md-16 tw-text-green-400"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Disclaimers -->
      <div class="px-2 py-3 mr-0 mr-md-3">
        <div class="">
          <div class="row mt-3">
            <div class="col">
              <div class="d-block">
                <p
                  class="tw-font-roboto tw-text-sm line-height-14 tw-text-gray-400"
                  v-for="(disclaimer, index) in disclaimers"
                  :key="index"
                >
                  {{ disclaimer }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div>
        <div class="container">
          <div class="tw-flex">
            <div class="row align-items-center">
             
              <div
                v-if="
                  Number(user.variable_salary) > 0 ||
                    (userAccessOptions !== null && userAccessOptions.accessType === 3)
                "
                class="col-12 col-md-6 col-lg-4"
              >
                <div class="card-1 shadow-1 red px-3 mb-3">
                  <router-link :to="userAccessOptions !== null ? userAccessOptions.path : ''">
                    <div
                      v-b-modal.modal-compensationPrice
                      @click="modalCompensationText('salaryv')"
                      class="tw-flex justify-content-between align-items-center"
                    >
                      <div
                        class="cont-txt"
                        v-if="
                          Number(user.variable_salary) > 0 &&
                            userAccessOptions !== null &&
                            userAccessOptions.accessType !== 3
                        "
                      >
                        <p class="title mb-2 text-uppercase">
                          Salario Variable
                        </p>
                        <p class="price mb-0">
                          {{ formatCurrency(user.variable_salary) }}
                        </p>
                      </div>
                      <div class="cont-txt" v-else>
                        <p class="title mb-2 text-uppercase">Ranking</p>
                      </div>
                      <div>
                        <i class="icon-flecha-der tw-text-red-400"></i>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <b-modal id="modal-compensationPrice" centered hide-footer size="sm">
      <p class="modal-title text-center text-uppercase mb-4">
        {{ modalCompensationtxt.title }}
      </p>
      <p class="modal-text text-center">{{ modalCompensationtxt.desc }}</p>
      <p class="disclaimer textBaba text-left">
        {{ modalCompensationtxt.disclaimer }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import userRemunerationMixin from "@/mixins/user/remuneration";
import RemunerationVideo from "@/components/show-me-the-money/CompRemunerationVideo.vue";
import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import BottleSplash from "@/components/show-me-the-money/CompBottleSplash.vue";
import numbersMixin from "@/mixins/general/numbers";

export default {
  name: "CompensationHome",
  mixins: [userRemunerationMixin, numbersMixin],
  components: {
    RemunerationVideo,
    BreadCrumb,
    MobileBar,
    BottleSplash,
  },
  mounted() {
    setTimeout(() => {
      this.bottleSplash = false;
    }, 5500);
    this.pushDataLayer();
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "#",
          active: true,
        },
      ],
      modalCompensationtxt: {
        title: "",
        desc: "",
        disclaimer: "",
      },
      disclaimers: [
        "* Esta herramienta no muestra valores adicionales otorgados por expatriación, movilidad o cualquier otro incentivo temporal.",
        "** El valor de los bonos corresponde al valor objetivo según el 100% del cumplimiento. El valor a pagar depende de los cumplimientos y políticas de la compañía.",
        "*** En nuestra compañía la política de compensación se basa en la valoración de roles y posicionamiento de mercado, garantizamos la igualdad salarial y retribución laboral para hombres, mujeres y miembros de la comunidad LGBTIQ+ por igual; sin distinción alguna de su raza, color, sexo, idioma, religión, opinión política o de cualquier otra índole.",
      ],
      bottleSplash: true,
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modVariableCompensation", ["userAccess"]),
    calcTotalSalary() {
      let _result =
        Number(this.user.anual_salary) +
        Number(this.user.legal_premium) +
        Number(this.user.extra_legal_premium) +
        Number(this.user.total_anual_prepaid) +
        Number(this.user.total_anual_casino) +
        Number(this.user.total_anual_route) +
        Number(this.user.total_anual_product) +
        Number(this.user.variable_salary);
      if (
        this.employeeCorePosition === "0-IV" ||
        this.employeeCorePosition === "V-VI"
      ) {
        return (
          _result +
          Number(this.user.values_lti) +
          Number(this.user.target_bonus)
        );
      } else {
        return _result + Number(this.user.total_values_sti);
      }
    },
    calcTotalBenefits() {
      return (
        Number(this.user.total_anual_prepaid) +
        Number(this.user.total_anual_casino) +
        Number(this.user.total_anual_route) +
        Number(this.user.total_anual_product)
      );
    },
    userAccessOptions() {
      const result =
        this.userAccess.find((access) =>
          access.positionId.includes(this.user.core_position.id)
        ) || null;
      if (result === null) {
        return null;
      } else {
        if (result.accessType === 1) {
          if (this.user.remuneration !== null) {
            return result;
          }
          return null;
        }
        if (result.accessType === 3) {
          return result;
        }
        if (result.accessType === 2) {
          if (this.user.remuneration !== null) {
            return result;
          }
          return null;
        }
      }
      return null;
    },
  },
  methods: {
    modalCompensationText(type) {
      switch (type) {
        case "base":
          this.modalCompensationtxt.title = "Salario Base";
          this.modalCompensationtxt.desc =
            "Salario que recibes mensualmente presentado como el total anual.";
          this.modalCompensationtxt.disclaimer =
            "* El salario válido es el acordado en tu contrato y oferta laboral.";
          this.pushDataLayerClics("Salario Base");
          break;

        case "plegal":
          this.modalCompensationtxt.title = "Prestaciones Legales";
          this.modalCompensationtxt.desc =
            "Pagos legales por concepto de prestaciones y cesantías (Total anual).";
          this.modalCompensationtxt.disclaimer = "";
          this.pushDataLayerClics("Prestaciones Legales");
          break;

        case "pelegal":
          this.modalCompensationtxt.title = "Primas Extra Legales";
          this.modalCompensationtxt.desc =
            "Pago de días adicionales de salario que te otorga la compañía en meses específicos (Total anual).";
          this.modalCompensationtxt.disclaimer = "";
          this.pushDataLayerClics("Primas Extra Legales");
          break;

        case "blti":
          this.modalCompensationtxt.title = "Bono LTI";
          this.modalCompensationtxt.desc =
            "Incentivo discrecional de largo plazo expresado en RSU´s (Restricted Stock Units) para ver el detalle de este incentivo";
          this.modalCompensationtxt.disclaimer = "";
          this.pushDataLayerClics("Bono LTI");
          break;

        case "salaryv":
          this.modalCompensationtxt.title = "Salario variable";
          this.modalCompensationtxt.desc =
            "Este valor corresponde a tu remuneración variable Target";
          this.modalCompensationtxt.disclaimer =
            "* El valor de la remuneración variable a pagar será de acuerdo al cumplimiento de resultados y políticas de la compañía.";
          this.pushDataLayerClics("Salario Variable");
          break;

        case "percentel":
          this.modalCompensationtxt.title = "Indicador de Compa Ratio";
          this.modalCompensationtxt.desc =
            "El Compa Ratio es un indicador que mide tu compensación en función al valor de referencia del mercado (Ingreso anual / Valor de referencia del mercado MRS)";
          this.modalCompensationtxt.disclaimer = "";
          this.pushDataLayerClics("Indicador de Compa Ratio");
          break;
        default:
          break;
      }
    },
    pushDataLayer() {
      window.dataLayer.push({
        event: "TrackEvent-convenios",
        category: "Cuidados y beneficios",
        SubCategory: "showMeTheMoney",
        Action: "página vista",
        Cargo: this.user.core_position.name,
        Banda: this.user.core_pa_sideline.name,
      });
    },
    pushDataLayerClics(label) {
      window.dataLayer.push({
        event: "TrackEvent-convenios",
        category: "Cuidados y beneficios",
        SubCategory: "Tu Compensación",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>

<style scoped>
#modal-compensationPrice .modal-content header {
  border-bottom: 0 none;
}

#modal-compensationPrice .modal-content .close {
  color: #d82b2b;
  outline: none;
  opacity: 1;
}

#modal-compensationPrice .modal-content .modal-title {
  color: #d82b2b;
  font-family: "RobotoBold", sans-serif;
  font-size: 20px;
}

#modal-compensationPrice .modal-content .modal-text {
  color: #343434;
  font-size: 16px;
}

#modal-compensationPrice .modal-content .disclaimer {
  font-size: 12px;
  line-height: 1;
}

.modal-content .modal1-title {
  font-size: 18px;
  font-family: "RobotoBold", sans-serif;
}

.informative:hover {
  transform: scale(1.001);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.linked:hover {
  background: #d82b2b;
  border: none;
  transform: scale(1.001);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.linked:hover p {
  color: white;
}

.linked:hover i {
  color: white;
}

.abi:hover p:before {
  content: attr(data-hover);
}
</style>
