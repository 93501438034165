<script setup>
import { ref, computed, getCurrentInstance } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import VueQrcode from "vue-qrcode";

const { $bvModal, $bvToast } = getCurrentInstance().proxy;
const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const uriResources = config.beerOfficeURL;
const tabs = ref({
  detail: {
    visible: true,
  },
  dates: {
    visible: false,
  },
});
const qrButton = ref({
  text: "Ver Código QR",
  visible: false,
});

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
  viewtitle: {
    type: Boolean,
    default: false,
  },
  viewButtons: {
    type: Boolean,
    default: true,
  },
});

const statusText = computed(() => {
  const map = new Map([
    [1, "Realizado"],
    [2, "Alistando"],
    [3, "¡Reclámalo!"],
    [4, "Entregado"],
    [5, "Cancelado"],
  ]);
  return map.get(props.order.ecomm_order_state_id);
});

const buttonQrText = computed(() => {
  if (qrButton.value.visible) {
    return "Ocultar Código QR";
  } else {
    return "Ver Código QR";
  }
});

const visibleDetail = () => {
  tabs.value.detail.visible = true;
  tabs.value.dates.visible = false;
};

const visibleDates = () => {
  tabs.value.dates.visible = true;
  tabs.value.detail.visible = false;
};

const showModalCancelOrder = () => {
  $bvModal.show("bv-modal-cancelOrder");
};

const hideModalCancelOrder = () => {
  $bvModal.hide("bv-modal-cancelOrder");
};

const showToast = () => {
  $bvToast.show("toast-cancel-order");
};

const cancelOrder = async () => {
  await ecommerceStore.actPostOrderCancel(props.order.id);
  await ecommerceStore.actGetOrdersPending(userStore.state.user.id);
  hideModalCancelOrder();
  showToast();
};

const generatePdf = async () => {
  const pdf = await ecommerceStore.actGetOrderPdf(props.order.id);
  let a = document.createElement("a");
  a.href = "data:application/pdf;base64," + pdf;
  a.download = props.order.id + ".pdf";
  a.click();
};
</script>

<template>
  <div>
    <div
      v-if="props.viewtitle"
      class="tw-shadow-sm tw-rounded-xl p-4 mb-0 z-index-2 position-relative bg-white"
    >
      <div class="d-flex justify-content-between">
        <p
          v-if="props.order.created_at"
          class="tw-text-red-400 mb-0 tw-text-sm fs-md-16 font-weight-bold"
        >
          {{ formatDateDDMMYYYY(props.order.created_at) }}
        </p>
        <p class="mb-0 font-weight-bold tw-text-red-400 tw-text-sm fs-md-16">
          {{ statusText }}
        </p>
      </div>
    </div>
    <div
      class="px-3 tw-z-1 position-relative mt-n2"
      v-if="tabs.detail.visible"
    >
      <div class="tw-bg-gray-200 p-4 tw-rounded-lg">
        <div
          v-if="props.order.ecomm_delivery_dates"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 font-weight-bold tw-text-gray-600 tw-text-sm fs-md-16">
            Detalle de tu pedido
          </p>
          <p
            class="mb-0 tw-text-xxs fs-md-12 tw-text-gray-600 tw-underline"
            role="button"
            v-if="props.order.ecomm_delivery_dates.length > 0"
            @click="visibleDates()"
          >
            Fechas de entrega
          </p>
        </div>
        <div class="overflow-auto mt-4 he-150 pr-2">
          <div
            v-for="(detail, idx) in props.order.ecomm_order_details"
            :key="idx"
            class="d-flex mb-3"
          >
            <img
              :src="uriResources + '/' + detail.img"
              :alt="detail.name"
              width="60"
              height="60"
              loading="lazy"
              class="mr-3 tw-rounded-lg bg-white tw-object-cover"
            />
            <div class="w-100 mr-4">
              <p
                class="mb-1 tw-text-xs fs-md-14 tw-text-red-400 font-weight-bold"
              >
                {{ detail.ecomm_product.ecomm_product_type.name }}
              </p>
              <p class="mb-2 tw-text-xs fs-md-14 tw-text-gray-600">
                {{ detail.name }}
              </p>
              <p class="mb-0 tw-text-gray-600 font-weight-bold tw-text-lg">
                {{
                  Intl.NumberFormat("es-CO", {
                    currency: "COP",
                    maximumFractionDigits: 0,
                  }).format(detail.price_public)
                }}
              </p>
            </div>
            <div
              class="tw-rounded-lg bg-white d-flex justify-content-center align-items-center wi-30 he-30 p-3 tw-shadow-sm"
            >
              <p class="tw-text-xs tw-text-gray-600 mb-0">{{ detail.qty }}</p>
            </div>
          </div>
        </div>
        <div class="text-center mt-2">
          <p class="tw-text-lg tw-text-red-400 font-weight-bold">
            Total:
            {{
              Intl.NumberFormat("es-CO", {
                currency: "COP",
                maximumFractionDigits: 0,
              }).format(props.order.total)
            }}
          </p>
        </div>
        <div class="row" v-if="viewButtons">
          <div class="col-12 col-md-8 mx-auto">
            <button
              class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 btn-block"
              v-if="
                props.order.ecomm_order_state_id === 1 &&
                props.order.ecomm_order_details[0].ecomm_product
                  .ecomm_product_type_id !== 3
              "
              @click="showModalCancelOrder()"
            >
              Cancelar Pedido
            </button>
          </div>
        </div>
        <div
          class="row"
          v-if="
            props.order.ecomm_order_state_id > 2 &&
            props.order.ecomm_order_state_id < 4
          "
        >
          <div class="col-12">
            <p
              class="tw-text-gray-300 tw-text-xs text-center mt-3 d-none d-md-block"
            >
              Muestra este código QR al momento de reclamar el producto
            </p>
          </div>
          <div class="col-12 col-md-8 mx-auto" v-if="viewButtons">
            <button
              class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 btn-block d-none d-md-block"
              @click="generatePdf()"
            >
              Descargar orden de compra
            </button>
            <div
              role="button"
              v-b-toggle.accordion-qr-1
              class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 btn-block text-center z-index-2 position-relative tw-shadow-md d-block d-md-none"
            >
              {{ buttonQrText }}
            </div>
            <b-collapse
              v-model="qrButton.visible"
              class="px-3 mt-n2 tw-z-1"
              id="accordion-qr-1"
            >
              <div class="tw-rounded-lg pt-4 p-3 bg-white tw-shadow-md">
                <div class="d-flex justify-content-between">
                  <p class="tw-text-sm tw-text-gray-600 font-weight-bold">
                    N. orden:
                  </p>
                  <p class="tw-text-sm tw-text-gray-600 font-weight-bold">
                    {{ props.order.id }}
                  </p>
                </div>
                <vue-qrcode
                  class="w-100"
                  :margin="1"
                  :value="props.order.id.toString()"
                />
              </div>
            </b-collapse>
          </div>
          <div class="col-12 mt-2">
            <p
              class="tw-text-gray-300 tw-text-xs text-center mt-3 d-block d-md-none"
            >
              Muestra este código QR al momento de reclamar el producto
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="px-3 tw-z-1 position-relative mt-n2"
      v-if="tabs.dates.visible"
    >
      <div class="tw-bg-gray-200 p-4 tw-rounded-lg">
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0 font-weight-bold tw-text-gray-600 tw-text-sm fs-md-16">
            Fechas de entrega
          </p>
          <p
            class="mb-0 tw-text-xxs fs-md-12 tw-text-gray-600 tw-underline"
            role="button"
            @click="visibleDetail()"
          >
            Detalle de tu pedido
          </p>
        </div>
        <div class="mt-4 mb-3">
          <p class="tw-text-xs tw-text-black mb-3 font-weight-bold">
            Tu producto lo puedes recoger en las siguientes fechas:
          </p>
          <p
            class="tw-text-xs tw-text-black mb-3 mb-md-2 line-height-14"
            v-for="dateOrder in props.order.ecomm_delivery_dates"
            :key="dateOrder.id"
          >
            {{ dateOrder.day }} de {{ dateOrder.month }} entre las
            {{ dateOrder.schedule }}, en el {{ dateOrder.site.name }}
          </p>
        </div>
        <div class="text-center mt-5">
          <p class="tw-text-lg tw-text-red-400 font-weight-bold">
            Total:
            {{
              Intl.NumberFormat("es-CO", {
                currency: "COP",
                maximumFractionDigits: 0,
              }).format(props.order.total)
            }}
          </p>
        </div>
        <div class="row" v-if="viewButtons">
          <div class="col-12 col-md-8 mx-auto">
            <button
              class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 btn-block mt-3"
              v-if="
                props.order.ecomm_order_state_id === 1 &&
                props.order.ecomm_order_details[0].ecomm_product
                  .ecomm_product_type_id !== 3
              "
              @click="showModalCancelOrder()"
            >
              Cancelar Pedido
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="bv-modal-cancelOrder"
      centered
      size="sm"
      role="dialog"
      hide-footer
      hide-header
    >
      <div class="text-center mt-0">
        <div class="w-100 text-right mt-n3">
          <button
            type="button"
            @click="hideModalCancelOrder()"
            aria-label="Close"
            class="btn tw-text-xl tw-text-red-400 font-weight-bold"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p class="tw-text-gray-600 tw-text-base">
          ¿Estás seguro que quieres cancelar tu pedido?
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 border-0"
          @click="hideModalCancelOrder()"
        >
          No
        </b-button>
        <b-button
          class="mx-2 tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 border-0"
          @click="cancelOrder()"
        >
          Sí
        </b-button>
      </div>
      <p class="tw-text-gray-300 tw-text-xxs text-center line-height-1 mt-3">
        *Recuerda que puedes cancelar tu pedido solo en la fase de "Recibido"
      </p>
    </b-modal>
    <b-toast
      id="toast-cancel-order"
      ref="toast-cancel-order"
      class="m-4"
      autoHideDelay="3000"
      top-center
      variant="success"
      solid
    >
      <div class="d-flex flex-grow-1 text-center align-items-baseline px-4">
        <p class="text-center ml-2 my-auto tw-font-roboto">
          Tu pedido fue cancelado con éxito
        </p>
      </div>
    </b-toast>
  </div>
</template>
