<script setup>
import { computed } from "vue";
import AtomButton from "@/components/atoms/AtomButton.vue";
import AtomText from "@/components/atoms/AtomText.vue";

const emit = defineEmits(["button-click"]);

const props = defineProps({
  bgColor: {
    type: String,
    default: "transparent",
  },
  headingText: {
    type: String,
    required: true,
  },
  paragraphText: {
    type: String,
    required: true,
  },
  headingColor: {
    type: String,
    default: "black",
  },
  paragraphColor: {
    type: String,
    default: "black",
  },
  buttonText: {
    type: String,
    required: true,
  },
  buttonColor: {
    type: String,
  },
  buttonTextColor: {
    type: String,
    default: "white",
  },
  buttonBorderColor: {
    type: String,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});

const computedClass = computed(() => {
  return [
    `tw-bg-${props.bgColor}`,
    props.rounded ? "tw-rounded-2xl lg:tw-rounded-2.5xl" : "",
  ];
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-4 lg:tw-gap-6 tw-p-4 lg:tw-p-5"
    :class="computedClass"
  >
    <atom-text
      tag="h2"
      font="robotoBold"
      :text-color="props.headingColor"
      textSizeMobile="xl"
      textSizeDesktop="3xl"
    >
      {{ props.headingText }}
    </atom-text>
    <atom-text
      tag="p"
      :text-color="props.paragraphColor"
      textSizeMobile="xs"
      textSizeDesktop="sm"
    >
      {{ props.paragraphText }}
    </atom-text>
    <atom-button
      border
      rounded
      :bgColor="props.buttonColor"
      :borderColor="props.buttonBorderColor"
      @button-click="emit('button-click')"
    >
      <atom-text
        tag="span"
        :text-color="props.buttonTextColor"
        textSizeMobile="sm"
        textSizeDesktop="base"
        class="tw-text-nowrap"
      >
        {{ props.buttonText }}
      </atom-text>
    </atom-button>
  </div>
</template>
