<template>
  <div class="worldbrands md:tw-ml-20 tw-min-h-screen">
    <div class="tw-container-general">
      <SecondaryNavbar
        :title="'MUNDO ' + itemBrand.name"
        class="tw-mb-5 md:tw-mb-0"
      />
    </div>
    <div class="tw-container-general tw-mb-8 tw-px-4">
      <Banner
        v-if="itemBrand.banner"
        :bannerId="itemBrand.banner.id"
        gradient="none"
      />
      <div class="tw-mt-16 md:tw-mt-20">
        <div class="tw-max-w-3xl tw-mx-auto">
          <div
            v-if="itemBrand && itemBrand.sub_brands"
            class="tw-flex tw-justify-around tw-text-center"
          >
            <div
              v-for="(item, index) of itemBrand.sub_brands"
              v-bind:key="index"
            >
              <img
                @click="selectedSubBrand(item.id, item.name)"
                :src="uriResources + '/' + item.img"
                class="
                  tw-w-14 tw-h-14
                  md:tw-w-20 md:tw-h-20
                  tw-bg-red-50
                  tw-object-cover
                  tw-rounded-full
                  tw-border-2
                  tw-border-solid
                  tw-cursor-pointer
                  hover:tw-shadow-md
                "
                :class="
                  sub_brand === item.id
                    ? ' tw-border-yellow-300'
                    : 'tw-border-white'
                "
                :alt="item.name"
              />
              <p class="tw-text-xs tw-text-gray-400 tw-mt-2">{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="articlesBrands && articlesBrands.subBrand">
        <div class="tw-text-center tw-my-3">
          <h2
            class="
              tw-text-red-400 tw-text-3xl
              md:tw-text-4xl
              tw-font-tolyerBold
            "
          >
            {{ articlesBrands.subBrand.name }}
          </h2>
          <p class="tw-text-base tw-text-gray-600">
            {{ articlesBrands.subBrand.description }}
          </p>
        </div>
        <!-- . -->
        <div class="tw-text-center tw-mb-5">
          <h5
            class="
              tw-text-red-400 tw-mb-3 tw-font-robotoBold tw-text-sm
              md:tw-text-lg
            "
          >
            Síguenos en:
          </h5>
          <div
            :class="
              articlesBrands.subBrand.link_facebook !== null
                ? 'tw-block'
                : 'tw-hidden'
            "
          >
            <a
              :href="articlesBrands.subBrand.link_facebook"
              target="_blank"
              @click="
                pushDataLayer(
                  itemBrand.name,
                  articlesBrands.subBrand.name,
                  'facebook'
                )
              "
            >
              <img
                src="@/assets/img/icons/facebook.svg"
                width="40px"
                height="40px"
                class="tw-rounded-full tw-mx-3"
                alt="facebook"
              />
            </a>
            <a
              :href="articlesBrands.subBrand.link_instagram"
              target="_blank"
              @click="
                pushDataLayer(
                  itemBrand.name,
                  articlesBrands.subBrand.name,
                  'instagram'
                )
              "
            >
              <img
                src="@/assets/img/icons/instagram.svg"
                width="40px"
                height="40px"
                class="tw-rounded-full tw-mx-3"
                alt="instagram"
              />
            </a>
            <a
              :href="articlesBrands.subBrand.link_twitter"
              target="_blank"
              @click="
                pushDataLayer(
                  itemBrand.name,
                  articlesBrands.subBrand.name,
                  'twitter'
                )
              "
            >
              <img
                src="@/assets/img/icons/twitter.svg"
                width="40px"
                height="40px"
                class="tw-rounded-full tw-mx-3"
                alt="twitter"
              />
            </a>
          </div>
        </div>
        <slot class="tw-mb-5" v-for="item in content" @key="item.id">
          <CompArticleImage
            v-if="item.type.name === 'Imagen'"
            :content="setImageUrl(item.content)"
            category="Universo Cervecero"
            subcategory="Mundo de Marcas"
            :brand="brandName"
            :variant="articlesBrands.subBrand.name"
            videoname=""
          />
          <CompArticleQuote
            v-if="item.type.name === 'Cita'"
            :content="item.content"
          />
          <CompArticleText
            v-if="item.type.name === 'Texto'"
            :content="item.content"
          />
          <CompQualificationVideo
            :id="'video' + item.id"
            v-if="item.type.name === 'Video'"
            :idVideo="item.id"
            :urlVideo="uriResources + '/' + item.content"
            :title="item.name"
            category="Universo Cervecero"
            subcategory="Mundo de Marcas"
            :brand="brandName"
            :variant="articlesBrands.subBrand.name"
            videoname=""
          />
          <CompArticleInfographic
            v-if="item.type.name === 'Infografia'"
            :content="setImageUrl(item.content)"
            category="Universo Cervecero"
            subcategory="Mundo de Marcas"
            :brand="brandName"
            :variant="articlesBrands.subBrand.name"
            videoname=""
          />
          <CompArticleImageCarrousel
            v-if="item.type.name === 'Carrusel'"
            :content="item.cards"
          />
          <CompArticleCardContainer
            v-if="item.type.name === 'Grupo'"
            :content="item.cards"
          />
          <Trivia
            v-if="item.type.name === 'Trivia'"
            :key="'trivia-' + item.id"
            :trivia="item.trivia"
            :subbrand="articlesBrands.subBrand.name"
          />
        </slot>
      </div>
    </div>
    <div @change.once="selectedSubBrand"></div>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import Banner from "@/components/general/Banner";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompArticleImage from "@/components/articles/article-body/CompArticleImage";
import CompArticleQuote from "@/components/articles/article-body/CompArticleQuote";
import CompArticleText from "@/components/articles/article-body/CompArticleText";
import CompQualificationVideo from "@/components/video/CompVideoQualification.vue";
import CompArticleInfographic from "@/components/articles/article-body/CompArticleInfographic";
import CompArticleCardContainer from "@/components/articles/article-body/CompArticleCardContainer";
import CompArticleImageCarrousel from "@/components/articles/article-body/CompArticleImageCarrousel";
import Trivia from "@/components/trivia/Trivia";

export default {
  name: "BrandView",
  components: {
    Banner,
    SecondaryNavbar,
    CompArticleImage,
    CompArticleQuote,
    CompArticleText,
    CompArticleInfographic,
    CompArticleCardContainer,
    CompQualificationVideo,
    CompArticleImageCarrousel,
    Trivia,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      bin: 0,
      status: 0,
      bannerId: 0,
      temp: null,
      sub_brand: null,
      brandName: null,
      content: [],
    };
  },
  mounted() {
    window.dataLayer.push({
      event: "trackEvent",
      target: "Sub-secciones",
      action: "marca",
      "target-properties": "clic",
      value: 5000,
    });
  },
  created() {
    this.content = [];
    this.actGetItemBrand(this.$route.params.id);
    this.brandName = this.itemBrand.name;
  },
  watch: {
    itemBrand() {
      if (this.temp === null) {
        if (this.itemBrand.sub_brands !== []) {
          this.selectedSubBrand(
            this.itemBrand.sub_brands[0].id,
            this.itemBrand.sub_brands[0].name
          );
        } else {
          this.selectedSubBrand(this.itemBrand.id);
        }
      }
    },
    articlesBrands() {
      this.content = [];
      this.content = this.articlesBrands.subBrand.content;
    },
  },
  computed: {
    ...mapState("modBrands", ["itemBrand"]),
    ...mapState("modBrands", ["articlesBrands"]),
  },
  methods: {
    ...mapActions("modBrands", ["actGetItemBrand"]),
    ...mapActions("modBrands", ["actGetArticlesBrands"]),
    selectedSubBrand(id, item_name) {
      this.content = [];
      this.bin = 100;
      this.temp = id;
      this.sub_brand = id;
      this.pushDataLayer(this.itemBrand.name, item_name);
      return this.actGetArticlesBrands(this.temp);
    },
    setImageUrl(imagePath) {
      this.bin = 100;
      return this.uriResources + "/" + imagePath;
    },
    pushDataLayer: function (brandname, subbrandname, socialnet) {
      window.dataLayer.push({
        event: "TrackEvent-UniversoCervecero",
        category: "UniversoCervecero",
        subcategory: "Mundo de marcas - Variación",
        Action: "Clics",
        Brand: brandname,
        Variant: subbrandname,
        SocialNetwork: socialnet,
      });
    },
  },
};
</script>
