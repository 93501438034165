<template>
  <div class="container d-flex justify-content-end">
    <div class="row">
      <div
        v-for="(item, index) in optionList"
        :key="index"
        class="d-flex text-center"
      >
        <router-link :to="item.url">
          <div
            class="icon-group text-centerd-flex d-md-block justify-content-center mx-2"
            :class="[
              selected === item.name ? 'selectedShortCut' : '',
              item.styleClass,
            ]"
          >
            <span
              class="shortcut-icon icon-md icon-white mr-0 mr-md-2 mr-md-0"
              :class="[selected === item.name ? item.selectedIcon : item.icon]"
            ></span>
          </div>
          <div class="options">{{ item.name }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "CompNavigationOptions",
  props: {
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapGetters("modVariableCompensation", ["getUserHasPermission"]),
    optionList() {
      let optionList = [
        {
          name: "Calculadora",
          styleClass: "short-cuts calculator",
          url: "/showMeTheMoney/variable-calculator",
          icon: "icon-calculadora",
          selectedIcon: "icon-calculadora-activo",
        },
        {
          name: "Ranking",
          styleClass: "short-cuts ranking",
          url: "/showMeTheMoney/remuneracion-rankings",
          icon: "icon-ranking-2",
          selectedIcon: "icon-ranking-2-activo",
        },
        {
          name: "Pagos",
          styleClass: "short-cuts payment",
          url: "/showMeTheMoney/remuneracion-pagos",
          icon: "icon-pagos",
          selectedIcon: "icon-pagos-activo",
        },
      ];
      if (
        this.getUserHasPermission(
          "REPORT_REMUNERATION",
          this.user.core_position.id
        )
      ) {
        optionList.push({
          name: "Reportar",
          styleClass: "short-cuts report",
          url: "/showMeTheMoney/remuneracion-reportes",
          icon: "icon-reportar",
          selectedIcon: "icon-reportar-activo",
        });
      }
      return optionList;
    },
  },
};
</script>
<style scoped>
.options {
  font: 10px/24px Roboto !important;
  letter-spacing: 0;
  color: #0f0e0e !important;
  opacity: 1;
}

.short-cuts {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #bababa;
  background-position: center;
  vertical-align: middle;
  display: table !important;
}

.selectedShortCut {
  background: #fab23a;
}

.doSection1 .icon-group span {
  background-color: #ffc65c;
}

.doSection .icon-group {
  width: 100%;
  float: right;
}

.doSection .icon-group span {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: relative;
  line-height: 80px;
  display: block;
  float: right;
}

.icon-white {
  color: #ffffff !important;
}

.icon-xl {
  font-size: 60px;
}

.shortcut-icon {
  display: table-cell;
  vertical-align: middle;
}
</style>