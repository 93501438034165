<script setup>
import { ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useSearchStore } from "@/stores/searchStore";
import { useNavbarStore } from "@/stores/navbarStore";

const target = ref(null);
const searchStore = useSearchStore();
const navbarStore = useNavbarStore();
const kDebounceTimeoutMs = 1000;

const isSearching = ref(false);
const searchQuery = ref("");
const debounceTimeout = ref(null);
const found = ref(true);
const waitText = ref("");
const notSearch = ref(false);

const cleanQuery = () => {
  searchQuery.value = "";
  found.value = true;
};

const getStarsPercentage = (result) => {
  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result[i].results.length; j++) {
      if (result[i].results[j].rating === 0) {
        result[i].results[j].stars = 0 + "%";
      } else {
        result[i].results[j].stars =
          (parseFloat(result[i].results[j].rating) / 5) * 100 + "%";
      }
    }
  }
};

watch(searchQuery, (query) => {
  if (debounceTimeout.value) {
    isSearching.value = true;
    searchStore.resetSearchResult();
    clearTimeout(debounceTimeout.value);
  }

  if (query.length === 0) {
    notSearch.value = false;
    waitText.value = "";
    searchStore.resetSearchResult();
  } else if (query.length < 3) {
    notSearch.value = true;
    waitText.value = "Debes introducir más de tres caracteres...";
    searchStore.resetSearchResult();
  } else {
    waitText.value = "Buscando...";
    notSearch.value = true;
    searchStore.resetSearchResult();
    debounceTimeout.value = setTimeout(() => {
      searchStore
        .actGetSearchResults(query)
        .then(() => {
          if (searchStore.searchResult.length < 1) {
            waitText.value = "No hemos encontrado tu búsqueda";
            found.value = false;
          } else {
            found.value = true;
            getStarsPercentage(searchStore.searchResult);
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          isSearching.value = false;
        });
    }, kDebounceTimeoutMs);
  }
});

onClickOutside(target, () => {
  searchStore.hideSearch();
  navbarStore.showSupportButton();
});
</script>
<template>
  <div
    id="modal-searchBar"
    :class="{ active: searchStore.showSearch }"
    class="tw-fixed tw-h-screen tw-z-1"
  >
    <div
      class="slideoutSearch tw-shadow-sm tw-rounded-2xl tw-fixed tw-right-0 tw-top-24 lg:tw-top-32 tw-w-screen lg:tw-max-w-none"
      :class="searchStore.showSearch ? 'active' : ''"
      id="slideoutSearch"
      ref="target"
    >
      <div
        class="inside tw-bg-white tw-py-5 lg:tw-py-6 tw-h-auto tw-w-full tw-max-h-full tw-rounded-3xl tw-flex tw-flex-col"
      >
        <div
          class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-px-6 md:tw-px-7 lg:tw-px-8 tw-flex-initial"
        >
          <input
            type="text"
            name=""
            id="searchbar"
            class="tw-w-full tw-rounded-2xl tw-bg-gray-200 tw-py-3 tw-pl-10 lg:tw-py-4 tw-font-roboto tw-text-sm focus:tw-outline-none focus:tw-border-transparent focus:tw-ring-transparent tw-border-transparent"
            @keyup.esc="cleanQuery"
            v-model="searchQuery"
            autocomplete="off"
            placeholder="Buscar..."
          />
          <i
            class="icon-search-bold tw-absolute tw-px-2 tw-text-2xl tw-text-gray-320"
          ></i>
          <div class="tw-cursor-pointer" @click="cleanQuery">
            <i class="icon-cerrar-simple tw-text-4xl tw-text-red-350"></i>
          </div>
        </div>
        <div
          id="search-results"
          class="tw-flex-auto tw-mt-6 tw-overflow-auto"
          v-if="notSearch === true"
        >
          <div
            class="tw-pt-4 tw-px-6 md:tw-px-7 lg:tw-px-8"
            v-if="isSearching === true"
          >
            <p class="tw-font-roboto tw-text-sm">{{ waitText }}</p>
          </div>
          <div
            class="tw-my-6"
            v-else-if="isSearching === false && found === true"
          >
            <div v-for="(result, i) in searchStore.searchResult" :key="i">
              <div
                class="tw-flex tw-justify-between tw-items-center tw-my-3 tw-px-6 md:tw-px-7 lg:tw-px-8"
              >
                <p class="tw-m-0 tw-font-robotoBold tw-text-sm tw-text-black">
                  {{ result.category }}
                </p>
                <div class="tw-flex" v-if="result.rating !== null">
                  <i
                    class="icon-circle tw-text-sm tw-text-yellow-400 tw-mr-2"
                  ></i>
                  <p class="tw-m-0 tw-font-roboto tw-text-xxs">
                    {{ Number(result.rating).toFixed(1) }}
                  </p>
                </div>
              </div>
              <div
                v-for="(subresult, j) in result.results"
                :key="j"
                class="tw-py-3 hover:tw-bg-gray-200"
              >
                <router-link :to="subresult.call_to_action">
                  <p
                    class="tw-font-roboto tw-text-sm tw-my-0 tw-ml-2 tw-truncate tw-text-black tw-px-6 md:tw-px-7 lg:tw-px-8"
                  >
                    {{ subresult.title }}
                  </p>
                </router-link>
              </div>
              <div class="tw-px-6 md:tw-px-7 lg:tw-px-8">
                <hr class="tw-m-0" />
              </div>
            </div>
          </div>
          <div class="tw-pt-4 tw-px-6 md:tw-px-7 lg:tw-px-8" v-else>
            <p class="tw-font-roboto tw-text-sm">{{ waitText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#modal-searchBar {
  transition: z-index 0.5s step-end, opacity 0.5s linear;
}

#modal-searchBar.active {
  width: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  -ms-transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  z-index: 1000;
}

.slideoutSearch {
  width: 490px;
  z-index: -1;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}

#search-results {
  max-height: 530px;
}

.slideoutSearch.active {
  transform: translateX(-5%);
}

@media (max-width: 768px) {
  .slideoutSearch {
    width: 95%;
  }

  #search-results {
    max-height: 510px;
  }

  .slideoutSearch.active {
    transform: translateX(-2%);
  }
}
</style>
