<script setup>
import { getCurrentInstance, onBeforeMount, onMounted, ref } from "vue";
import config from "@/services/config";
import { useRoute, useRouter } from "vue-router/composables";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import BreadCrumb from "@/components/general/BreadCrumb";
import ClaimModal from "@/components/ecommerce/ClaimModal";
import CompStars from "@/components/rating/CompStars";
import CompProductsCatalog from "@/components/ecommerce/CompProductsCatalog";
import CompFooterEcommerceModal from "@/components/ecommerce/CompFooterEcommerceModal";
import CompButtonShoppingCart from "@/components/ecommerce/CompButtonShoppingCart";
import MobileBar from "@/components/general/MobileBar";
import { useRatingStore } from "@/stores/ratingStore";

const { $bvModal } = getCurrentInstance().proxy;
const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const ratingStore = useRatingStore();
const route = useRoute();
const router = useRouter();
const uriResources = config.beerOfficeURL;
const ratingData = ref({});
const isLoading = ref(false);
const breadcrumb = ref([
  {
    id: 1,
    name: "Home",
    link: "/home",
    active: false,
  },
  {
    id: 2,
    name: "Mundo Embajada",
    link: "/e-commerce",
    active: false,
  },
  {
    id: 3,
    name: "Producto",
    link: "#",
    active: true,
  },
]);

const openClaimModal = () => {
  $bvModal.show("modal-claim");
};

onBeforeMount(async () => {
  isLoading.value = true;
  await ecommerceStore.actGetProducts();
  await ratingStore.actGetRating("Ecommerce").then((data) => {
    ratingData.value = data;
  });
  if (ecommerceStore.products.length === 0) {
    await router.push("/e-commerce");
  }
  await ecommerceStore.actGetProductById(route.params.id).then(() => {
    isLoading.value = false;
  });
});

onMounted(() => {
  window.dataLayer.push({
    event: "productDetail",
    ecommerce: {
      detail: {
        actionField: { list: "Resultados de busqueda" },
        products: ecommerceStore.products,
      },
    },
    "gtm.elementUrl": document.location.href,
  });
});
</script>
<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0">
    <mobile-bar title="Mundo Embajada" />
    <div class="container px-0 px-md-3 mt-md-4">
      <div class="row">
        <div class="col-12 mt-4">
          <BreadCrumb :crumbs="breadcrumb" />
        </div>
      </div>
    </div>

    <!-- Título Desktop y leyenda -->
    <div
      class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0 d-none d-md-flex order-md-1 order-last">
        <div>
          <p
            class="tw-font-tolyerBold fs-md-40 text-uppercase"
            v-if="ecommerceStore.product.ecomm_product_type_id === 1"
          >
            PRODUCTO
          </p>
          <p
            class="tw-font-tolyerBold fs-md-40 text-uppercase"
            v-if="ecommerceStore.product.ecomm_product_type_id === 2"
          >
            ELIGE TU OBSEQUIO
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 px-0 order-md-2 order-first">
        <div class="d-flex justify-content-between justify-content-md-end py-0">
          <div
            class="col-auto col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 mr-md-2"
          >
            <div
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-flex text-center h-100 align-items-center"
              v-if="userStore.user.ecomm_delivery_site_id != null"
              role="button"
              @click="openClaimModal"
            >
              <i class="icon-pin-mapa-ubicacion mr-2 tw-text-base"></i>
              <p class="mb-0 font-weight-bold tw-text-black tw-text-sm">
                {{ userStore.user.ecomm_delivery_site["name"] }}
              </p>
            </div>
          </div>
          <div class="col-auto col-md-auto my-md-0 my-1 px-md-0 px-3 py-0">
            <router-link
              to="/e-commerce/orderStatus"
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-flex text-center align-items-center"
            >
              <i
                class="icon-ecommerce-canasta tw-text-gray-600 mr-0 mr-md-2 tw-text-xl fs-md-16"
              ></i>
              <p
                class="mb-0 font-weight-bold tw-text-black tw-text-sm mr-2 d-none d-md-block"
              >
                Mis pedidos
              </p>
              <div class="d-none d-md-block">
                <div
                  class="wi-20 he-20 tw-rounded-full bg-white tw-border tw-border-solid tw-border-red-400 d-flex justify-content-center align-items-center"
                >
                  <div
                    class="wi-6 he-6 tw-bg-red-400 tw-rounded-full position-absolute mt-n3 ml-2 tw-border tw-border-white tw-border-solid"
                  ></div>
                  <span class="tw-text-red-400 tw-text-xs">
                    {{ ecommerceStore.ordersPending.length }}
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Contenido principal -->
    <div class="container mt-md-3 mt-4">
      <div class="row align-items-center">
        <ClaimModal />
      </div>
      <div class="row" v-if="isLoading">
        <div class="col-12">
          <div class="text-center mt-3">
            <img src="../../assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
      </div>
      <div v-else class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-6 col-xl-7 text-center">
          <img
            :src="uriResources + '/' + ecommerceStore.product.img_detail"
            width="100%"
            class="max-wi-md-420"
            height="auto"
            alt="image 1"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">
          <div class="tw-shadow-md tw-rounded-lg p-3 p-md-4">
            <div>
              <p
                class="tw-font-roboto tw-text-xl line-height-20 fs-md-26 line-height-md-30"
              >
                {{ ecommerceStore.product.name }}
              </p>
              <p class="tw-font-roboto tw-text-base line-height-20">
                {{ ecommerceStore.product.description }}
              </p>
              <div
                class="d-flex justify-content-between align-items-center my-4"
              >
                <p
                  class="tw-text-lg fs-md-20 tw-text-red-400 font-weight-bold mb-0"
                  v-if="ecommerceStore.product.ecomm_product_type_id === 1"
                >
                  {{
                    Intl.NumberFormat("es-CO", {
                      currency: "COP",
                      maximumFractionDigits: 0,
                    }).format(ecommerceStore.product.price_public)
                  }}
                </p>
                <p
                  class="tw-font-robotoBold tw-text-lg fs-md-20 mb-0"
                  :class="
                    new Date().getDate() > ecommerceStore.products[1].max_day
                      ? 'tw-text-black'
                      : 'tw-text-red-400'
                  "
                  v-if="ecommerceStore.product.ecomm_product_type_id === 2"
                >
                  OBSEQUIO
                </p>
              </div>
              <CompStars
                category="Ecommerce"
                :idContent="ecommerceStore.product.id"
                :ratingData="ratingData[ecommerceStore.product.id]"
                :showStars="true"
              />
            </div>

            <div
              v-if="
                ecommerceStore.product.ecomm_product_type_id === 2 &&
                new Date().getDate() > ecommerceStore.products[1].max_day
              "
              class="tw-rounded-lg tw-bg-gray-200"
            >
              <p
                class="tw-font-roboto tw-text-xs line-height-14 tw-text-gray-600 p-3"
              >
                La fecha límite para solicitar tu producto obsequio ha pasado.
                Recuerda que debes solicitarlo durante los primeros
                {{ ecommerceStore.products[1].max_day }} días del mes.
              </p>
            </div>
            <div
              v-else
              role="button"
              @click="
                ecommerceStore.addProductToShoppingCart(ecommerceStore.product)
              "
              class="text-center tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 btn-block"
            >
              <p class="tw-font-roboto tw-text-base line-height-18 m-auto">
                Agregar al carrito
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          v-if="user.core_society.access_to_ecommerce === 1"
          class="col-12 col-md-12 col-lg-12 pt-0 pt-md-4"
        >
          <CompProductsCatalog
            v-if="
              ecommerceStore.products &&
              ecommerceStore.products[0] &&
              ecommerceStore.products[0].ecomm_product
            "
            :products="ecommerceStore.products[0].ecomm_product"
            :cat="ecommerceStore.product.ecomm_product_type_id"
          />
          <div v-else class="text-center py-3">
            <img src="@/assets/img/icons/loading.gif" alt="loading" />
          </div>
          <div class="row">
            <div class="col ml-auto">
              <CompFooterEcommerceModal />
            </div>
          </div>
        </div>
      </div>
      <CompButtonShoppingCart />
    </div>
  </div>
</template>
