<template>
  <div>
    <input
      type="date"
      v-model="date"
      @input="validateDate"
      maxlength="10"
      class="tw-w-full tw-h-full tw-border tw-rounded-2xl tw-px-3 focus:tw-outline-none focus:tw-border-gray-300 focus:tw-ring-transparent tw-text-center tw-text-base"
      :class="`tw-border-${borderColor}`"
    />
  </div>
</template>

<script>
export default {
  name: "DateField",
  props: {
    borderColor: {
      type: String,
      default: "gray-300",
    },
    allowBeyondCurrentMonth: {
      type: Boolean,
      default: false,
    },
    allowBeyondCurrentYear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      dateFormatted: null,
      validatedDate: false,
    };
  },
  methods: {
    validateDate() {
      const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
      const [year, month, day] = this.date.split("-");

      this.validatedDate = dateFormat.test(this.date);

      const date = new Date(year, month - 1, day);
      if (isNaN(date.getTime())) {
        this.validatedDate = false;
      }

      const today = new Date();

      const dateWithTimeZero = date.setHours(0, 0, 0, 0);
      const todayWithTimeZero = today.setHours(0, 0, 0, 0);

      if (dateWithTimeZero <= todayWithTimeZero) {
        if (dateWithTimeZero < todayWithTimeZero) {
          this.validatedDate = false;
          this.$emit("dateInPast");
        } else {
          this.$emit("dateToday");
        }
      }

      if (
        (!this.allowBeyondCurrentMonth && month > today.getMonth() + 1) ||
        (!this.allowBeyondCurrentYear && year > today.getFullYear())
      ) {
        this.validatedDate = false;
        this.$emit("notCurrentMonth");
      }

      this.convertedDate = [
        year,
        ("0" + month).slice(-2),
        ("0" + day).slice(-2),
      ].join("-");
      this.$emit("validated", this.validatedDate, this.convertedDate);
    },
  },
};
</script>

<style scoped></style>
