<template>
  <Doughnut
    :chart-data="chartData"
    :chart-id="chartId"
    :chart-options="options"
    :css-classes="cssClasses"
    :dataset-id-key="datasetIdKey"
    :height="height"
    :plugins="plugins"
    :styles="styles"
    :width="width"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    chartData: {
      type: Object,
      required: true,
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    cutout: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      /*chartData: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },*/
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
  computed: {
    options() {
      return {
        ...this.chartOptions,
        cutout: `${this.cutout}%`,
      };
    },
  },
  methods: {
    // textCenter(val) {
    //   ChartJS.register({
    //     beforeDraw: function (chart) {
    //       let width = chart.width,
    //         height = chart.height,
    //         ctx = chart.ctx;
    //
    //       ctx.restore();
    //       let fontSize = (height / 114).toFixed(2);
    //       ctx.font = fontSize + "em sans-serif";
    //       ctx.textBaseline = "middle";
    //
    //       let text = val + "%",
    //         textX = Math.round((width - ctx.measureText(text).width) / 2),
    //         textY = height / 2;
    //
    //       ctx.fillText(text, textX, textY);
    //       ctx.save();
    //     },
    //   });
    // },
  },
};
</script>
