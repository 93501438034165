import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  contactsUri: "/profFavorites",
};

export const useContactsStore = defineStore("contacts", () => {
  const contacts = ref({
    businessPartners: [],
    wellnessContacts: [],
    favorites: [],
  });
  const contactUsers = ref([]);

  async function actGetContacts() {
    const response = await AxiosBeerOffice.get(uri.contactsUri, true);
    if (response.error) return response.error;
    contacts.value = response.data;
    return true;
  }

  async function actGetFindUsers(name) {
    const response = await AxiosBeerOffice.get(
      `${uri.contactsUri}/find`,
      true,
      {
        name: name,
      }
    );
    if (response.error) return response.error;
    contactUsers.value = response.data;
    return true;
  }

  async function actPostAddContact(data) {
    const response = await AxiosBeerOffice.post(uri.contactsUri, true, {
      id: data.id,
    });
    if (response.error) return response.error;
    contacts.value.favorites.push({
      core_employee: data,
    });
    return true;
  }

  async function actPostDeleteContact(data) {
    const response = await AxiosBeerOffice.delete(
      `${uri.contactsUri}/${data.id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    contacts.value.favorites = contacts.value.favorites.filter((contact) => {
      return contact.id !== data.id;
    });
    return true;
  }

  function cleanContactUsers() {
    contactUsers.value = [];
  }

  return {
    contacts,
    contactUsers,
    actGetContacts,
    actGetFindUsers,
    actPostAddContact,
    actPostDeleteContact,
    cleanContactUsers,
  };
});
