<template>
  <form @submit.prevent="sendForm" class="needs-validation" novalidate>
    <div class="row">
      <div class="col-9">
        <p class="fs-24 text-uppercase tetx tw-font-tolyerBold tw-text-black">
          Experiencia Laboral
        </p>
      </div>
      <div class="col-3 text-right">
        <p
          @click="addExperience"
          role="button"
          class="tw-text-red-400 tw-underline tw-text-sm"
        >
          Agregar
        </p>
      </div>
    </div>
    <div
      v-for="(work_experience, index) in formData.work_experience.items"
      :key="index"
      class="row position-relative tw-z-1"
    >
      <span
        @click="removeExperience(index)"
        role="button"
        class="tw-text-white tw-bg-red-400 wi-20 he-20 tw-rounded-full d-flex justify-content-center align-items-center position-absolute tw-right-5 z-index-2"
      >
        <i class="icon-cerrar-simple tw-text-white font-weight-bold tw-text-sm"></i>
      </span>
      <div class="col-12 col-lg-6 mb-3">
        <label
          :for="`company-${index}`"
          class="tw-text-sm tw-text-gray-400 font-weight-bold"
        >
          Empresa*
        </label>
        <input
          type="text"
          :name="`formData.work_experience.items[${index}][company]`"
          :id="`company-${index}`"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="work_experience.company"
        />
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Este campo es obligatorio.</div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <label
          :for="`place-${index}`"
          class="tw-text-sm tw-text-gray-400 font-weight-bold"
        >
          Lugar*
        </label>
        <input
          type="text"
          :name="`formData.work_experience.items[${index}][place]`"
          :id="`place-${index}`"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="work_experience.place"
        />
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Este campo es obligatorio.</div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row row-cols-2">
          <div class="col">
            <label
              :for="`start_date-${index}`"
              class="tw-text-sm tw-text-gray-400 font-weight-bold"
            >
              Fecha de inicio*
            </label>
            <input
              type="date"
              :id="`start_date-${index}`"
              :name="`formData.work_experience.items[${index}][start_date]`"
              class="form-control tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400 px-2"
              required="required"
              :max="getTodayYYYYmmdd"
              v-model="work_experience.start_date"
            />
          </div>
          <div class="col">
            <label
              :for="`end_date-${index}`"
              class="tw-text-sm tw-text-gray-400 font-weight-bold"
            >
              Fecha de fin
            </label>
            <input
              type="date"
              :id="`end_date-${index}`"
              :name="`formData.work_experience.items[${index}][end_date]`"
              class="form-control tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400 px-2"
              required="required"
              :max="getTodayYYYYmmdd"
              :disabled="work_experience.at_present === 1"
              v-model="work_experience.end_date"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <label
          :for="`position-${index}`"
          class="tw-text-sm tw-text-gray-400 font-weight-bold"
        >
          Puesto*
        </label>
        <input
          type="text"
          :name="`formData.work_experience.items[${index}][position]`"
          :id="`position-${index}`"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="work_experience.position"
        />
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Este campo es obligatorio.</div>
      </div>
      <div class="col-12">
        <div class="custom-control custom-checkbox mb-2">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="`at_present-${index}`"
            :name="`formData.work_experience.items[${index}][at_present]`"
            v-model="work_experience.at_present"
            :checked="work_experience.at_present === 1"
            @click="toggleActivateEndDate(index, $event)"
          />
          <label class="custom-control-label" :for="`at_present-${index}`">
            <span class=" tw-text-xs  tw-text-gray-300">Actualmente</span>
          </label>
        </div>
      </div>
      <div class="col-12">
        <label
          :for="`achievements-${index}`"
          class="tw-text-sm tw-text-gray-400 font-weight-bold"
        >
          Logros*
        </label>
        <textarea
          :name="`formData.work_experience.items[${index}][achievements]`"
          :id="`achievements-${index}`"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="work_experience.achievements"
        >
        </textarea>
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Este campo es obligatorio.</div>
      </div>
      <div class="col-12">
        <div class="w-100 he-1 tw-bg-gray-300 my-3"></div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-12 col-xl-6 ml-auto">
        <div class="d-flex">
          <span
            @click="backStep"
            role="button"
            class="mx-2  tw-text-xs  fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block w-100 text-center"
          >
            Volver
          </span>
          <button
            class="mx-2  tw-text-xs  fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block w-100 text-center"
          >
            Siguiente paso
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import datesMixin from "@/mixins/general/dates";
import utilitiesComponents from "@/mixins/general/utilitiesComponents";

export default {
  name: "CvExperience",
  mixins: [datesMixin, utilitiesComponents],
  computed: {
    ...mapState("modCv", ["formData"]),
  },
  methods: {
    ...mapActions("modCv", [
      "changeStep",
      "addExperience",
      "removeExperience",
      "removeExperienceEndDate",
      "saveExperience",
    ]),
    async sendForm() {
      let form = document.querySelector(".needs-validation");
      if (form.checkValidity() === true) {
        let response = await this.saveExperience(this.formData.work_experience);
        if (response.error) {
          this.showToast(
            "Error",
            "Los datos ingresados no son correctos.",
            "danger",
            "b-toaster-bottom-right"
          );
        } else {
          this.changeStep(3);
        }
      }
      form.classList.add("was-validated");
    },
    backStep() {
      this.changeStep(1);
    },
    toggleActivateEndDate(index, event) {
      let inputEndDate = event.target.parentElement.parentElement.parentElement.querySelector(
        `#end_date-${index}`
      );
      if (event.target.checked) {
        this.removeExperienceEndDate(index);
        inputEndDate.setAttribute("disabled", "disabled");
      } else {
        inputEndDate.removeAttribute("disabled");
      }
    },
  },
};
</script>

<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #23bf79;
  background-color: #23bf79;
}
</style>