<script setup>
import config from "@/services/config";
import { ref } from "vue";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);

const uriResources = config.beerOfficeURL;

const props = defineProps({
  content: {
    type: Array,
  },
});

const swiperOptions = ref({
  slidesPerView: 2,
  spaceBetween: 15,
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
  },
  loop: false,
});
</script>
<template>
  <div class="tw-mb-4 md:tw-mb-5 tw-text-center">
    <div class="content-carousel">
      <swiper
        :slides-per-view="swiperOptions.slidesPerView"
        :space-between="swiperOptions.spaceBetween"
        :breakpoints="swiperOptions.breakpoints"
        :loop="swiperOptions.loop"
        class="tw-h-[250px] tw-pb-2"
      >
        <swiper-slide v-for="(item, idx) in props.content" :key="idx">
          <img
            :src="uriResources + '/' + item.image"
            class="tw-object-cover tw-w-full tw-h-[200px] tw-rounded-xl tw-shadow-md"
            alt=""
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
