const psi = {
  data() {
    return {
      psi_indicators: [
        {
          id: 0,
          name: "Ranking General",
          value: 87,
          active: "yes",
          clickeable: "yes",
          classification: 1,
        },
        {
          id: 1,
          name: "Liderazgo",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 1,
        },
        {
          id: 2,
          name: "Reconocimiento",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 1,
        },
        {
          id: 3,
          name: "Engagement",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 1,
        },
        {
          id: 4,
          name: "D&I",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 2,
        },
        {
          id: 5,
          name: "Turnover General",
          value: 87,
          active: "no",
          clickeable: "no",
          classification: 2,
        },
        {
          id: 6,
          name: "Turnover Femenino",
          value: 87,
          active: "no",
          clickeable: "no",
          classification: 2,
        },
        {
          id: 7,
          name: "Representación Femenina",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 2,
        },
        {
          id: 8,
          name: "Balance de vida",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 3,
        },
        {
          id: 9,
          name: "Bienestar",
          value: 87,
          active: "no",
          clickeable: "yes",
          classification: 3,
        },
        {
          id: 10,
          name: "Personalidades",
          value: "",
          active: "no",
          clickeable: "yes",
          classification: 4,
        },
      ],
      psi_colors: [
        {
          id: 1,
          color: "linear-gradient(180deg, #f1ce38 0%, #f05e22 37.01%);",
        },
      ],
      mejorar_ranking: [
        {
          id: 1,
          color: "linear-gradient(180deg, #f1ce38 0%, #f05e22 37.01%);",
        },
      ],
      ranking_personas: [
        {
          id: 0,
          name: "Camilo Arevalo",
          area: "People",
          ranking: 1,
          value: 89,
        },
        {
          id: 1,
          name: "Lucia Sandoval",
          area: "Support",
          ranking: 2,
          value: 88,
        },
        {
          id: 2,
          name: "Fernanda Pulido",
          area: "Supply",
          ranking: 3,
          value: 87,
        },
        {
          id: 3,
          name: "Camilo Arevalo",
          area: "Finanzas",
          ranking: 4,
          value: 89,
        },
        {
          id: 4,
          name: "Camilo Arevalo",
          area: "Fábrica de Tapas",
          ranking: 5,
          value: 89,
        },
        {
          id: 5,
          name: "Camilo Arevalo",
          area: "Maltería Tibitó",
          ranking: 6,
          value: 89,
        },
        {
          id: 6,
          name: "Camilo Arevalo",
          area: "Centro",
          ranking: 7,
          value: 89,
        },
        {
          id: 7,
          name: "Camilo Arevalo",
          area: "Andes",
          ranking: 8,
          value: 89,
        },
        {
          id: 8,
          name: "Camilo Arevalo",
          area: "Norte",
          ranking: 9,
          value: 89,
        },
        {
          id: 9,
          name: "Camilo Arevalo",
          area: "Sur",
          ranking: 10,
          value: 89,
        },
        {
          id: 10,
          name: "Camilo Arevalo",
          area: "Marketing",
          ranking: 11,
          value: 89,
        },
        {
          id: 11,
          name: "Camilo Arevalo",
          area: "C. Tocancipá",
          ranking: 12,
          value: 89,
        },
      ],
      psi_buttons: [
        {
          id: 0,
          name: "PSI",
          link: "/indicators/psi",
          active: false,
        },
        {
          id: 1,
          name: "Ranking",
          link: "/ambassadors/engagement",
          active: false,
        },
        {
          id: 2,
          name: "Ranking People",
          link: "/ambassadors/ranking-people",
          active: false,
        },
      ],
      months_name: {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
      },
      brandColors:
        ['#FFBF35', '#BF0D63', '#F05E22', '#5EA0F8', '#EAA8CA', '#4CC3C9']
    };
  },
};

export default psi;
