<script setup>
import { computed } from "vue";

const props = defineProps({
  text: {
    type: String || null,
  },
  active: {
    type: Boolean,
    default: false,
  },
  roundedSize: {
    type: String,
    default: "tw-rounded-2xl lg:tw-rounded-3xl",
  },
  color: {
    type: String,
    default: "white",
  },
  activeColor: {
    type: String,
    default: "black",
  },
  textColor: {
    type: String,
    default: "black",
  },
  textActiveColor: {
    type: String,
    default: "white",
  },
  borderColor: {
    type: String,
    default: "black",
  },
  icon: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    default: "icon-mas-informacion",
  },
});

const emit = defineEmits(["iconClick"]);

const isActive = computed(() => props.active);
const backgroundColor = computed(() =>
  props.active ? `bg-${props.activeColor}` : `bg-${props.color}`
);
const textColor = computed(() =>
  props.active ? `text-${props.textActiveColor}` : `text-${props.textColor}`
);

let iconClicked = false;

const onIconClick = (event) => {
  event.stopPropagation();
  iconClicked = !iconClicked;
  emit("iconClick", iconClicked);
};
</script>

<template>
  <button
    :class="[
      props.roundedSize,
      `tw-${backgroundColor}`,
      `tw-${textColor}`,
      `tw-border-${props.borderColor}`,
      { active: isActive },
      props.icon ? 'tw-justify-between' : 'tw-justify-center',
    ]"
    class="button"
  >
    <span>{{ props.text ? props.text : "Button" }}</span>
    <i :class="props.iconName" v-if="props.icon" @click="onIconClick($event)" />
  </button>
</template>

<style scoped lang="postcss">
.button {
  @apply tw-border tw-font-roboto tw-text-xs lg:tw-text-sm tw-py-2 tw-px-3 tw-text-center tw-cursor-pointer tw-opacity-100 tw-flex tw-gap-2 tw-items-center;

  &:not(.active):hover {
    @apply tw-opacity-70;
  }

  &:disabled {
    @apply tw-bg-gray-200 tw-cursor-not-allowed tw-border-none;
  }
}
</style>
