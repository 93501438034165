<script setup>
import { storeToRefs } from "pinia";
import { useReservationsStore } from "@/stores/reservationsStore";
import CompGuestsNumber from "@/components/reservations/CompGuestsNumber.vue";

const { bookingStep, bookingPlaceSelected } = storeToRefs(
  useReservationsStore()
);

defineProps({
  reservationData: Object,
  overCrowded: Boolean,
  incrementAdultGuests: Function,
  decrementAdultGuests: Function,
  incrementChildrenGuests: Function,
  decrementChildrenGuests: Function,
});
</script>

<template>
  <div v-show="bookingStep === 3" class="tw-flex tw-flex-col tw-gap-5">
    <div id="guestsTitle">
      <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl">Huéspedes</p>
      <p class="tw-font-roboto tw-text-xs md:tw-text-lg">
        Este alojamiento tiene una capacidad máxima de
        {{ bookingPlaceSelected.capacity }} huéspedes, los niños menores de 3
        años pueden ingresar sin ser contados en la capacidad del alojamiento
      </p>
    </div>
    <div id="guestsDetails" class="tw-flex tw-flex-col tw-gap-4 md:tw-gap-8">
      <CompGuestsNumber
        :guestsNumber="reservationData.guestsNumber.adults"
        :item="{
          idName: 'adultGuests',
          label: 'Adultos',
          description: 'Más de 13 años',
        }"
        :overCrowded="overCrowded"
        @decrementGuests="decrementAdultGuests"
        @incrementGuests="incrementAdultGuests"
      />
      <CompGuestsNumber
        :guestsNumber="reservationData.guestsNumber.children"
        :item="{
          idName: 'childrenGuests',
          label: 'Niños',
          description: 'De 3 a 12 años',
        }"
        :overCrowded="overCrowded"
        @decrementGuests="decrementChildrenGuests"
        @incrementGuests="incrementChildrenGuests"
      />
    </div>
  </div>
</template>

<style scoped></style>
