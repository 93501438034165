<script setup>
import config from "@/services/config";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useUserStore } from "@/stores/userStore";
import { useProfileStore } from "@/stores/profileStore";
import imgAvatar from "@/assets/img/gamification/avatar.png";
import imgAvatarEmpty from "@/assets/img/demo/avatar-empty.png";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import ButtonOutlineRed from "@/components/buttons/CompButtonOutlineRed.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const uriResources = config.beerOfficeURL;
const uploadedUserImage = ref(false);
const newUserImage = ref(null);
const newUserImageForShow = ref(null);
const isUploadPhotoModalOpen = ref(false);
const modalSuccess = ref(false);
const randomAvatar = Math.random();

const { user } = storeToRefs(useUserStore());
const { userProfileData } = storeToRefs(useProfileStore());

const { actPostProfileImage } = useProfileStore();

const userImage = computed(() => {
  if (userProfileData.value.prof_picture != null) {
    return uriResources + "/" + userProfileData.value.prof_picture;
  } else {
    return imgAvatarEmpty;
  }
});

const chooseAvatar = computed(() => {
  if (user?.value?.is_predefined_avatar === 1) {
    return user?.value?.predefined_avatar_image;
  } else {
    return user?.value?.custom_avatar_image;
  }
});

const userAvatar = computed(() => {
  if (
    user?.value?.custom_avatar_image != null ||
    user?.value?.predefined_avatar_image != null
  ) {
    return uriResources + "/" + chooseAvatar.value + "?v=" + randomAvatar;
  } else {
    return imgAvatar;
  }
});

const goToLink = (link, label, text) => {
  pushLink(link, {
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: label,
    component_name: "Boton",
    element_text: text,
  });
};

const changePhoto = () => {
  isUploadPhotoModalOpen.value = true;
  pushDataLayer({
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: "¿Cómo quieres verte?",
    component_name: "Boton",
    element_text: "Editar foto",
  });
};

const selectPreferredImage = (value) => {
  userProfileData.value.preferred_image = value;
  pushDataLayer({
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: "¿Cómo quieres verte?",
    component_name: "Boton",
    element_text: value === "photo" ? "Mostrar Foto" : "Mostrar Avatar",
  });
};

const closeUploadPhotoModal = () => {
  isUploadPhotoModalOpen.value = false;
  modalSuccess.value = false;
  pushDataLayer({
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: "Cambiar foto",
    component_name: "Boton",
    element_text: "Cancelar",
  });
};

function uploadImage() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/png, image/jpeg, image/jpg");
  input.click();
  input.onchange = () => {
    const file = input.files[0];
    if (file.size <= 5000000) {
      newUserImage.value = file;
      const reader = new FileReader();
      reader.onload = () => {
        newUserImageForShow.value = reader.result;
      };
      reader.readAsDataURL(file);
      uploadedUserImage.value = true;
    } else {
      alert("El archivo no puede ser mayor a 5MB");
    }
  };

  pushDataLayer({
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: "Cambiar foto",
    component_name: "Boton",
    element_text: "Seleccionar foto",
  });
}

async function sendImage() {
  let formData = new FormData();
  formData.append("prof_picture", newUserImage.value);
  await actPostProfileImage(formData);
  modalSuccess.value = true;

  pushDataLayer({
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: "Cambiar foto",
    component_name: "Botón",
    element_text: "Guardar",
  });
}

onMounted(() => {
  if (
    userProfileData?.value?.preferred_image === "" ||
    !userProfileData?.value?.preferred_image
  ) {
    userProfileData.value.preferred_image = "photo";
  }
});
</script>

<template>
  <div>
    <portal to="profileHome">
      <general-modal
        :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
        :modalWidth="{ md: 400, lg: 600, xl: 600 }"
        modal-name="uploadUserImage"
        v-if="isUploadPhotoModalOpen"
        :isShowModal="isUploadPhotoModalOpen"
        @close="closeUploadPhotoModal"
      >
        <template #title v-if="!modalSuccess"> Cambiar foto </template>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-gap-3 lg:tw-gap-6"
          v-if="!modalSuccess"
        >
          <div class="tw-cursor-pointer">
            <div
              class="tw-border-4 tw-border-solid tw-border-red-350 tw-rounded-full tw-bg-no-repeat tw-bg-center tw-w-fit tw-mx-auto lg:tw-mx-0 tw-relative"
            >
              <img
                :src="uploadedUserImage ? newUserImageForShow : userImage"
                class="tw-object-cover tw-rounded-full tw-h-20 tw-w-20 lg:tw-h-32 lg:tw-w-32"
                alt="User image"
              />
            </div>
            <div
              class="tw-mt-5 tw-btn-general-shape tw-border tw-border-black tw-py-1 tw-px-4"
              @click="uploadImage"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
                Seleccionar foto
              </p>
            </div>
          </div>
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-xl">
            Puedes subir un archivo jpg, jpeg o png de máximo 5MB
          </p>
          <div class="tw-grid tw-grid-cols-2 tw-gap-2 lg:tw-gap-4 tw-w-10/12">
            <button-primary-red
              rounded-size="tw-rounded-lg lg:tw-rounded-xl"
              @click.native="sendImage"
              title="Guardar"
              class="tw-w-full tw-my-2 lg:tw-my-4"
            />
            <button-outline-red
              rounded-size="tw-rounded-lg lg:tw-rounded-xl"
              border-color="tw-border-black"
              text-color="tw-text-black"
              title="Cancelar"
              @click.native="closeUploadPhotoModal"
            />
          </div>
        </div>
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3"
          v-else
        >
          <div class="tw-flex tw-w-6/12 tw-justify-between tw-items-center">
            <img src="@/assets/img/modal/modal-success.svg" alt="" />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-green-450"
            >
              LISTO
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            Tu foto se ha guardado correctamente
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            Ahora podrás usar esta foto para tu perfil
          </p>
          <button-primary-red
            rounded-size="tw-rounded-lg lg:tw-rounded-xl"
            @click.native="closeUploadPhotoModal"
            title="Cerrar"
            class="tw-w-full"
          />
        </div>
      </general-modal>
    </portal>
    <section
      id="picture-avatar"
      class="tw-rounded-2xl lg:tw-rounded-3xl tw-border tw-border-gray-350 tw-p-4"
    >
      <p class="tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        ¿Cómo quieres verte?
      </p>
      <div class="tw-grid tw-grid-cols-2 tw-gap-6">
        <div class="tw-flex tw-justify-end">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-w-full lg:tw-w-2/3"
          >
            <div
              class="tw-border-4 tw-border-solid tw-border-red-350 tw-rounded-full tw-bg-no-repeat tw-bg-center tw-w-fit tw-mx-auto lg:tw-mx-0 tw-relative"
            >
              <img
                :src="uploadedUserImage ? newUserImageForShow : userImage"
                class="tw-object-cover tw-rounded-full tw-h-20 tw-w-20 lg:tw-h-32 lg:tw-w-32"
                alt="User image"
              />
              <div
                class="tw-absolute -tw-bottom-1 -tw-right-1 tw-bg-red-350 tw-rounded-full tw-w-6 tw-h-6 lg:tw-w-9 lg:tw-h-9 tw-flex tw-items-center tw-justify-center tw-text-center"
                @click="changePhoto"
              >
                <i class="icon-edit tw-text-white tw-text-xs lg:tw-text-lg" />
              </div>
            </div>
            <div
              class="tw-status-bubble tw-border tw-cursor-pointer tw-px-2 tw-py-1 tw-w-fit"
              :class="
                userProfileData.preferred_image === 'photo'
                  ? 'tw-bg-red-350 tw-text-white'
                  : 'tw-bg-white hover:tw-bg-red-350 tw-text-black hover:tw-text-white tw-border-black hover:tw-border-red-350'
              "
              @click="selectPreferredImage('photo')"
            >
              <p class="tw-m-0 tw-text-xs lg:tw-text-sm tw-text-center">
                {{
                  userProfileData.preferred_image === "photo"
                    ? "Foto seleccionada"
                    : "Mostrar Foto"
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-justify-start">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-w-full lg:tw-w-2/3"
          >
            <div
              class="tw-border-4 tw-border-solid tw-border-red-350 tw-rounded-full tw-bg-no-repeat tw-bg-center tw-w-fit tw-mx-auto lg:tw-mx-0 tw-relative"
            >
              <img
                :src="userAvatar"
                class="tw-object-cover tw-rounded-full tw-h-20 tw-w-20 lg:tw-h-32 lg:tw-w-32"
                alt="User avatar"
              />
              <div
                class="tw-absolute -tw-bottom-1 -tw-right-1 tw-bg-red-350 tw-rounded-full tw-w-6 tw-h-6 lg:tw-w-9 lg:tw-h-9 tw-flex tw-items-center tw-justify-center tw-text-center tw-cursor-pointer"
                @click="
                  goToLink(
                    '/gamification/avatar',
                    '¿Cómo quieres verte?',
                    'Editar Avatar'
                  )
                "
              >
                <i class="icon-edit tw-text-white tw-text-xs lg:tw-text-lg" />
              </div>
            </div>
            <div
              class="tw-status-bubble tw-border tw-cursor-pointer tw-px-2 tw-py-1 tw-w-fit"
              :class="
                userProfileData.preferred_image === 'avatar'
                  ? 'tw-bg-red-350 tw-text-white'
                  : 'tw-bg-white hover:tw-bg-red-350 tw-text-black hover:tw-text-white tw-border-black hover:tw-border-red-350'
              "
              @click="selectPreferredImage('avatar')"
            >
              <p class="tw-m-0 tw-text-xs lg:tw-text-sm tw-text-center">
                {{
                  userProfileData.preferred_image === "avatar"
                    ? "Avatar seleccionado"
                    : "Mostrar Avatar"
                }}
              </p>
            </div>
          </div>
        </div>
        <span
          v-if="!userProfileData?.preferred_image"
          class="tw-font-robotoLight tw-text-sm tw-text-red-350"
          >* Por favor selecciona una opción</span
        >
      </div>
    </section>
  </div>
</template>

<style scoped></style>
