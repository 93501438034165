var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-gap-x-2 tw-items-start tw-justify-end tw-z-2",class:_setup.props.extendsContainer ? '' : 'tw-max-h-8 lg:tw-max-h-14'},[(_setup.props.isLabel)?_c('p',{staticClass:"tw-mt-2 tw-font-roboto tw-text-xs lg:tw-text-lg"},[_vm._v(" "+_vm._s(_setup.props.label)+" ")]):_vm._e(),_c('div',{staticClass:"tw-h-full tw-border-solid tw-border tw-bg-white tw-overflow-hidden tw-z-2 tw-p-1 lg:tw-p-2",class:[
      _setup.props.embeddedMobile === true
        ? 'tw-border-transparent'
        : `tw-border-${_setup.props.borderColor}`,
      _setup.props.embeddedDesktop
        ? 'lg:tw-border-transparent'
        : `lg:tw-border-${_setup.props.borderColor}`,
      _setup.openSelect ? 'tw-rounded-2xl' : 'tw-rounded-2xl',
      _vm.fitted ? 'tw-w-max' : 'tw-w-full',
    ]},[_c('div',{staticClass:"main tw-grid tw-items-center tw-gap-x-3 tw-cursor-pointer tw-px-3 tw-py-2 lg:tw-px-3 lg:tw-py-2",class:_vm.iconVisible
          ? 'tw-grid-cols-col3_fit_first_and_last'
          : 'tw-grid-cols-col2_fit_last',on:{"click":_setup.toggleSelectFilter}},[(_vm.iconVisible || (_setup.optionSelected && _setup.optionSelected.icon))?_c('div',{staticClass:"tw-flex"},[(
            _vm.iconVisible &&
            !_setup.openSelect &&
            _setup.optionSelected &&
            _setup.optionSelected.icon
          )?_c('i',{staticClass:"tw-text-red-350",class:_setup.optionSelected && `icon-${_setup.optionSelected.icon}`}):(_vm.iconVisible)?_c('i',{staticClass:"tw-text-red-350 lg:tw-text-xl",class:_vm.icon}):_vm._e()]):_vm._e(),_c('div',{staticClass:"tw-flex"},[(_setup.openSelect && _vm.defaultTextVisible)?_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base tw-text-red-350 tw-lowercase"},[_vm._v(" "+_vm._s(_setup.capitalizeParagraphFirstLetter(_vm.defaultText.toLowerCase()))+" ")]):_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base"},[_vm._v(" "+_vm._s(_setup.props.singleArrayMode ? _setup.optionSelectedId : _setup.capitalizeParagraphFirstLetter( _setup.optionSelected?.name.toLowerCase() ))+" ")])]),_c('i',{staticClass:"tw-text-xs tw-text-red-350",class:_setup.openSelect ? 'icon-flecha-arriba' : 'icon-flecha-abajo'})]),_c('div',{staticClass:"tw-bg-white tw-w-full tw-max-h-32 lg:tw-max-h-40 tw-overflow-scroll",class:_setup.openSelect ? 'tw-h-auto tw-visible' : 'tw-h-0 tw-invisible'},_vm._l((_setup.allOptions),function(opt,idx){return _c('div',{key:idx,staticClass:"tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 lg:tw-px-3 lg:tw-py-2 tw-grid tw-grid-cols-col2_fit_first tw-items-center tw-gap-x-3",on:{"click":function($event){return _setup.changeSelectFilter(opt)}}},[(_setup.props.iconVisible)?_c('i',{class:[
            opt.icon ? `icon-${opt.icon}` : _vm.icon,
            opt.icon ? '' : 'tw-text-red-350',
          ]}):_vm._e(),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v(" "+_vm._s(_setup.props.singleArrayMode ? opt : _setup.capitalizeParagraphFirstLetter(opt?.name.toLowerCase()))+" ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }