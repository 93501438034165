<template>
  <div class="tw-container-general">
    <ButtonsMenu class="py-3 mb-2 mb-md-4" :buttonsMenuItemActive="1" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p
            class="fs-30 fs-md-40 text-uppercase tw-text-gray-600 font-weight-bold tw-font-tolyerBold mb-3"
          >
            Oportunidades
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 bg-white shadow-none">
          <FilterOpportunities @filterVacants="(data) => filterVacants(data)" />
          <div class="text-center mt-3" v-if="isLoading">
            <img src="@/assets/img/icons/loading.gif" alt="loading" />
          </div>
          <div
            v-else-if="vacants.length > 0"
            class="mb-4 he-md-480 tw-overflow-y-scroll px-2 pt-2"
          >
            <Opportunity
              role="button"
              v-for="(vacant, index) in vacants"
              :key="vacant.id"
              :opportunity="vacant"
              :hvIsComplete="is_complete"
              @click.native="selectOpportunity($event, index)"
            />
          </div>
          <div v-else class="alert alert-warning mt-3" role="alert">
            No existen oportunidades para esta búsqueda.
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="text-center mt-3" v-if="isLoading">
            <img src="@/assets/img/icons/loading.gif" alt="loading" />
          </div>
          <OpportunityDetail
            v-else-if="Object.keys(vacantSelected).length > 0"
            :opportunity="vacantSelected"
            :isLoadingApply="isLoadingApply"
            :hvIsComplete="is_complete"
            class="d-none d-md-block"
          />
          <div v-else class="alert alert-warning mt-3" role="alert">
            No existen oportunidades para esta búsqueda.
          </div>
        </div>
      </div>
    </div>
    <ModalOpportunityApply @applyVacant="() => applyVacant()" />
    <ModalOpportunityCheck :user="user" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ButtonsMenu from "@/components/general/ButtonsMenu";
import OpportunityDetail from "@/components/people/opportunities/OpportunityDetail.vue";
import Opportunity from "@/components/people/opportunities/Opportunity.vue";
import FilterOpportunities from "@/components/people/opportunities/FilterOpportunities.vue";
import ModalOpportunityApply from "@/components/people/opportunities/ModalOpportunityApply.vue";
import ModalOpportunityCheck from "@/components/people/opportunities/ModalOpportunityCheck.vue";
import utilitiesComponents from "@/mixins/general/utilitiesComponents";

export default {
  name: "OpportunitiesView",
  mixins: [utilitiesComponents],
  components: {
    ButtonsMenu,
    OpportunityDetail,
    Opportunity,
    FilterOpportunities,
    ModalOpportunityApply,
    ModalOpportunityCheck,
  },
  data() {
    return {
      vacantSelected: {},
      isLoading: false,
      isLoadingApply: false,
    };
  },
  created() {
    this.isLoading = true;
    this.getVacants()
      .then(() => {
        if (this.$route.params.id === "all") {
          this.vacantSelected = this.vacants[0];
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.getPeopleResume();
    if (this.$route.params.id !== "all") {
      this.getVacantById(this.$route.params.id).then((data) => {
        this.vacantSelected = data;
      });
    }
  },
  computed: {
    ...mapState("modVacants", ["vacants"]),
    ...mapState("modCore", ["user"]),
    ...mapState("modCv", ["is_complete"]),
  },
  methods: {
    ...mapActions("modVacants", [
      "getVacants",
      "getVacantById",
      "getSearchVacants",
      "actApplyVacant",
    ]),
    ...mapActions("modCv", ["getPeopleResume"]),
    selectOpportunity(event, id) {
      this.vacantSelected = this.vacants[id];
      let vacantCard = event.srcElement.offsetParent;
      let opportunityCards =
        event.srcElement.offsetParent.parentElement.parentElement.querySelectorAll(
          "div .opportunity-card"
        );
      opportunityCards.forEach((i) => {
        i.classList.add("tw-shadow-sm");
        i.classList.remove("tw-shadow-md");
        i.classList.add("tw-bg-white");
        i.classList.remove("tw-bg-gray-200");
      });
      vacantCard.classList.remove("tw-shadow-sm");
      vacantCard.classList.add("tw-shadow-md");
      vacantCard.classList.remove("tw-bg-white");
      vacantCard.classList.add("tw-bg-gray-200");
    },
    // showModalOpportunityApply() {
    //   this.$bvModal.show("modal-opportunity-apply");
    // },
    filterVacants(data) {
      this.isLoading = true;
      let dataFilter = {
        area: data.area.split(",")[0],
        site: data.place.split(",")[0],
        sideline: data.sideline.split(",")[0],
        text: data.text,
      };
      this.getSearchVacants(dataFilter)
        .then(() => {
          if (this.vacants.length > 0) {
            this.vacantSelected = this.vacants[0];
          } else {
            this.vacantSelected = {};
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
          this.isLoading = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async applyVacant() {
      this.isLoadingApply = true;
      this.$bvModal.hide("modal-opportunity-apply");
      let response = await this.actApplyVacant(this.vacantSelected.id);
      if (response.error) {
        this.showToast(
          "Error",
          "No se ha podido aplicar a la vacante.",
          "danger",
          "b-toaster-bottom-right"
        );
        this.isLoadingApply = false;
      } else {
        this.isLoadingApply = false;
        this.$bvModal.show("modal-opportunity-check");
      }
    },
  },
};
</script>
