<script setup>
import { pushLink } from "@/helpers/linkHelpers";

const buttonDatalayer = (button_title) => {
  return {
    event_category: "Go Flex",
    event_action: "Tranqui es tu hora",
    event_label: button_title,
    component_name: "Click",
  };
};
</script>
<template>
  <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4 lg:tw-gap-10">
    <div class="lg:tw-col-span-7 tw-flex tw-flex-col tw-gap-4">
      <p class="tw-m-0 tw-font-robotoBold tw-text-2xl tw-block lg:tw-hidden">
        Tranqui es tu hora
      </p>
      <div
        class="tw-flex tw-items-center tw-justify-center tw-aspect-w-12 tw-aspect-h-6 tw-h-full"
      >
        <img
          src="@/assets/img/goflex/goflex-principal.png"
          class="tw-max-h-[auto] tw-max-w-[auto] tw-rounded-2xl lg:tw-rounded-3xl"
          alt=""
        />
        <!-- 
          class="tw-object-contain tw-w-auto tw-h-auto tw-max-w-full tw-max-h-full tw-rounded-2xl lg:tw-rounded-3xl"
        -->
      </div>
    </div>
    <div
      class="lg:tw-col-span-5 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-justify-end"
    >
      <p class="tw-m-0 tw-font-robotoBold tw-text-4xl tw-hidden lg:tw-block">
        Tranqui es tu hora
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
        Es tu hora de empoderarte de tu bienestar con Flex Work un espacio para
        disfrutar de trabajar remotamente o para cambiar de ambiente y de Time
        Off un espacio que puedes tomar para ausentarte, sea para atender
        asuntos personales o simplemente porque quieres descansar de una jornada
        pesada.
      </p>
      <div class="tw-flex tw-gap-4 tw-justify-between">
        <div
          class="tw-btn-primary-red"
          @click="
            pushLink(
              'https://api.embajadores.com.co/storage/documents/1676993493.pdf',
              buttonDatalayer('Ver toolkit')
            )
          "
        >
          <p class="tw-m-0">Ver toolkit</p>
        </div>
        <div
          class="tw-btn-secondary-white"
          @click="
            pushLink(
              'https://api.embajadores.com.co/storage/documents/1676993646.jpg',
              buttonDatalayer('Descargar avatar')
            )
          "
        >
          <p class="tw-m-0">Descargar Avatar</p>
        </div>
      </div>
    </div>
  </div>
</template>
