<template>
  <div id="page-contacts" class="mb-5">
    <div class="container px-3 px-md-0">
      <div class="row align-items-center">
        <div class="col-md-7 py-0">
          <SecondaryNavbar title="Tus contactos" :breadcrumb="breadcrumb" />
        </div>
        <!-- .col -->
        <div class="col-md-5 text-right d-none d-md-block">
          <button
            class="btn btn-principal btn-primary my-3 my-md-0 mr-3 mr-md-0"
            @click="$bvModal.show('bv-modal-favorite-contacts')"
          >
            Agregar nuevo contacto
          </button>
        </div>
        <div class="col-md-5 d-block d-md-none my-3">
          <div
            class="cont-btn-add-user d-flex align-items-center"
            @click="$bvModal.show('bv-modal-favorite-contacts')"
          >
            <img
              class="rounded-circle contactAdd"
              src="@/assets/img/icons/contactAdd.png"
              alt=""
            />
            <p class="mb-0 ml-2">Agregar</p>
          </div>
        </div>
        <!-- .col -->
      </div>
      <!-- .row -->
      <div class="cont-contacts">
        <div class="row cont-users">
          <div class="col-12">
            <div v-if="contacts.length <= 0">
              <div class="alert alert-warning" role="alert">
                No se encontró ningún usuario.
              </div>
            </div>
            <div
              class="cont-user mt-3 d-flex justify-content-between align-items-center"
              v-for="contact in contacts.businessPartners"
              v-else
              :key="contact.id"
            >
              <div
                class="user d-flex align-items-center"
                @click="loadAndShowDataInModal(contact.id, 'main')"
              >
                <img
                  :src="imgAvatar"
                  :alt="contact.name"
                  class="rounded-circle"
                />
                <div class="cont-txt ml-3">
                  <p class="profile mb-1">{{ contact.position }}</p>
                  <p class="name mb-0 text-capitalize">
                    {{ contact.name.toLowerCase() }}
                  </p>
                </div>
                <!-- .cont-txt -->
              </div>
              <!-- .user -->
              <div class="cont-espiga">
                <img src="../../assets/img/icons/espiga.png" width="9" alt="star" />
              </div>
            </div>
            <!-- .cont-user -->
            <div
              class="cont-user mt-3 d-flex justify-content-between align-items-center"
              v-for="contact in contacts.wellnessContacts"
              :key="contact.id"
            >
              <div
                class="user d-flex align-items-center"
                @click="loadAndShowDataInModal(contact.id, 'main')"
              >
                <img
                  :src="imgAvatar"
                  :alt="contact.name"
                  class="rounded-circle"
                />
                <div class="cont-txt ml-3">
                  <p class="profile mb-1">{{ contact.position }}</p>
                  <p class="name mb-0 text-capitalize">
                    {{ contact.name.toLowerCase() }}
                  </p>
                </div>
                <!-- .cont-txt -->
              </div>
              <!-- .user -->
              <div class="cont-espiga">
                <img src="../../assets/img/icons/espiga.png" width="9" alt="star" />
              </div>
            </div>
            <!-- .cont-user -->
            <div v-show="contacts.favorites.length > 0">
              <div
                class="cont-user mt-3 d-flex justify-content-between align-items-center"
                v-for="contact in contacts.favorites"
                :key="contact.core_employee.id"
              >
                <div
                  class="user d-flex align-items-center"
                  @click="loadAndShowDataInModal(contact.id, 'favorite')"
                >
                  <img
                    :src="
                      contact.core_employee.prof_picture == null
                        ? imgAvatar
                        : uriResources +
                          '/' +
                          contact.core_employee.prof_picture
                    "
                    :alt="contact.core_employee.name"
                    class="rounded-circle"
                  />
                  <div class="cont-txt ml-3">
                    <p class="profile mb-1">
                      {{ contact.core_employee.core_position.name }}
                    </p>
                    <p class="name mb-0 text-capitalize">
                      {{ contact.core_employee.name.toLowerCase() }}
                    </p>
                  </div>
                  <!-- .cont-txt -->
                </div>
                <!-- .user -->
                <div
                  class="cont-star"
                  @click="deleteContactToFavorites(contact)"
                >
                  <div class="d-flex">
                    <p class="d-none d-md-block textPlane">Quitar</p>
                    <span class="icon-remover ml-2"></span>
                  </div>
                </div>
              </div>
              <!-- .cont-user -->
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .cont-contacts -->
    </div>
    <!-- .container -->
    <CompModalContact
      :modal-contact="modalContact"
      :actual-type-contact="actualTypeContact"
    />
    <CompModalContacts />
  </div>
  <!-- #page-contacts -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import config from "@/services/config";

import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompModalContacts from "@/components/contacts/CompContactsModalContacts.vue";
import CompModalContact from "@/components/contacts/CompContactsModalContact.vue";

export default {
  name: "ContactsView",
  components: {
    SecondaryNavbar,
    CompModalContacts,
    CompModalContact,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      breadcrumb: [
        {
          id: 1,
          name: "Mi perfil",
          link: "/profile",
        },
        {
          id: 2,
          name: "Tus contactos",
          link: "/contacts",
          active: true,
        },
      ],
      searchText: "",
      imgAvatar: require("@/assets/img/contacts/avatar-empty.png"),
      modalContact: {
        id: null,
        core_regional: {
          name: "",
        },
      },
      actualTypeContact: "main",
    };
  },
  computed: {
    ...mapState("modContacts", ["contacts"]),
    mainContactsJoined() {
      return this.contacts.businessPartners.concat(
        this.contacts.wellnessContacts
      );
    },
  },
  methods: {
    ...mapActions("modContacts", ["actDeletecontact"]),
    deleteContactToFavorites(contact) {
      this.actDeletecontact(contact);
    },
    loadAndShowDataInModal(id, type) {
      if (type === "main") {
        this.actualTypeContact = "main";
        this.modalContact = this.mainContactsJoined.find(
          (contact) => contact.id === id
        );
      } else {
        this.actualTypeContact = "favorite";
        this.modalContact = this.contacts.favorites.find(
          (contact) => contact.id === id
        );
      }

      this.$bvModal.show("bv-modal-contact");
    },
  },
};
</script>

<style scoped>
.cont-contacts {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 15px;
}

.cont-btn-add-user {
  margin-left: 14px;
}

.cont-btn-add-user img {
  box-shadow: 0 3px 5px 0 rgba(112, 112, 112, 0.6);
  width: 30px;
}

/**Navbar */
.navbar-nav {
  background-color: #f7f7f7;
  height: 40px;
}

.nav-link {
  color: #999999;
  text-transform: uppercase;
}

.nav-link.active {
  color: #d82b2b;
  border-bottom: 3px solid #d82b2b;
}

@media (min-width: 768px) {
  .navbar-nav {
    background-color: transparent;
  }

  .nav-link {
    color: #999999;
    border-bottom: 3px solid #999999;
    text-transform: initial;
  }
}

.cont-users .cont-txt .profile {
  color: #171717;
  font-size: 8px;
}

.cont-users .cont-txt .name {
  color: #171717;
  font-size: 14px;
  font-family: "RobotoMedium", sans-serif;
}

.cont-users .user img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  object-fit: cover;
}

.cont-users .cont-star {
  cursor: pointer;
}

.cont-users .cont-star .icon-remover {
  color: #d82b2b;
  font-size: 18px;
}

@media (min-width: 768px) {
  .cont-users .user img {
    width: 50px;
    height: 50px;
  }

  .cont-users .cont-txt .profile {
    font-size: 11px;
  }

  .cont-users .cont-txt .name {
    font-size: 18px;
  }
}
</style>
