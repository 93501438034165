<template>
  <div class="px-0 px-md-3 tw-bg-gray-200">
    <div class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3">
      <div
        class="
          tw-bg-white
          w-100
          px-3
          py-3
          tw-border tw-border-solid tw-border-gray-600
          tw-rounded-md
          text-center
        "
      >
        <p class="mb-0 tw-text-gray-600  tw-text-xxs  fs-md-14">
          {{ this.psiData.kpi.name }}
        </p>
      </div>
      <PsiCardData
        :color="Object.values(this.psiData.kpi.indicator_month_color).pop()"
        :value="Object.values(this.psiData.kpi.indicator_month).pop()"
        :end_value="this.psiData.kpi.end"
      />
      <PsiCardData
        :color="this.psiData.kpi.year_to_date_color"
        :value="this.psiData.kpi.year_to_date"
        :end_value="this.psiData.kpi.end"
      />
      <PsiCardData
        :color="null"
        :value="this.psiData.kpi.last_year"
        :end_value="this.psiData.kpi.end"
        :hide_mobile="true"
      />
      <PsiCardData
        :color="'gray-400'"
        :value="this.psiData.kpi.budget"
        :end_value="this.psiData.kpi.end"
        :hide_mobile="true"
      />
      <PsiCardData
        :color="this.psiData.kpi.le_color"
        :value="this.psiData.kpi.le"
        :end_value="this.psiData.kpi.end"
        :hide_mobile="true"
      />
      <div
        class="
          d-flex
          justify-content-center
          align-items-center
          tw-bg-white
          tw-shadow-sm
          tw-rounded-md
        "
      >
        <div
          @click="selectFilterOption($event, psiData.kpi)"
          class="
            wi-20
            he-20
            tw-rounded-full
            tw-border-2 tw-border-solid tw-border-green-400 
            tw-shadow
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <i class="icon-check-simple tw-text-base text-white"></i>
        </div>
      </div>
    </div>
    <div v-for="vp in this.psiData.vp" :key="'vp-data-compare' + vp.id">
      <div class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3">
        <div
          v-b-toggle="'vp-row-collapse-' + vp.id"
          role="button"
          class="
            w-100
            px-3
            py-3
            text-center
            d-flex
            justify-content-between
            tw-border-b tw-border-solid tw-border-gray-300 
          "
        >
          <p class="w-100 text-center mb-0 tw-text-gray-600  tw-text-xxs  fs-md-14">
            {{ vp.name }}
          </p>
          <i v-if="vp.psah" class="icon-flecha-abajo tw-text-gray-600 ml-2"></i>
        </div>
        <PsiCardData
          :color="Object.values(vp.indicator_month_color).pop()"
          :value="Object.values(vp.indicator_month).pop()"
          :end_value="vp.end"
        />
        <PsiCardData
          :color="vp.year_to_date_color"
          :value="vp.year_to_date"
          :end_value="vp.end"
        />
        <PsiCardData
          :color="null"
          :value="vp.last_year"
          :end_value="vp.end"
          :hide_mobile="true"
        />
        <PsiCardData
          :color="'gray-400'"
          :value="vp.budget"
          :end_value="vp.end"
          :hide_mobile="true"
        />
        <PsiCardData
          :color="vp.le_color"
          :value="vp.le"
          :end_value="vp.end"
          :hide_mobile="true"
        />
        <div
          class="
            d-flex
            justify-content-center
            align-items-center
            tw-bg-white
            tw-shadow-sm
            tw-rounded-md
          "
        >
          <div
            @click="selectFilterOption($event, vp)"
            class="
              wi-20
              he-20
              tw-rounded-full
              tw-border-2 tw-border-solid tw-border-green-400 
              tw-shadow
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <i class="icon-check-simple tw-text-base text-white"></i>
          </div>
        </div>
      </div>
      <b-collapse :id="'vp-row-collapse-' + vp.id">
        <div
          v-for="scope in vp.psah"
          :key="'scope-' + scope.id"
          class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3"
        >
          <div class="w-100 px-3 py-3 text-center tw-border-b tw-border-solid tw-border-gray-300 ">
            <p class="mb-0 tw-text-gray-600  tw-text-xxs  fs-md-14">
              {{ scope.name }}
            </p>
          </div>
          <PsiCardData
            :color="Object.values(scope.indicator_month_color).pop()"
            :value="Object.values(scope.indicator_month).pop()"
            :end_value="scope.end"
          />
          <PsiCardData
            :color="scope.year_to_date_color"
            :value="scope.year_to_date"
            :end_value="scope.end"
          />
          <PsiCardData
            :color="null"
            :value="scope.last_year"
            :end_value="scope.end"
            :hide_mobile="true"
          />
          <PsiCardData
            :color="'gray-400'"
            :value="scope.budget"
            :end_value="scope.end"
            :hide_mobile="true"
          />
          <PsiCardData
            :color="scope.le_color"
            :value="scope.le"
            :end_value="scope.end"
            :hide_mobile="true"
          />
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
              tw-bg-white
              tw-shadow-sm
              tw-rounded-md
            "
          >
            <div
              @click="selectFilterOption($event, scope)"
              class="
                wi-20
                he-20
                tw-rounded-full
                tw-border-2 tw-border-solid tw-border-green-400 
                tw-shadow
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <i class="icon-check-simple tw-text-base text-white"></i>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PsiCardData from "@/components/psi/PsiCardData";

export default {
  name: "PsiRowDataCompare",
  props: {
    psiData: {
      required: true,
    },
  },
  components: {
    PsiCardData,
  },
  computed: {
    ...mapState("modPsi", ["dataCompare"]),
  },
  methods: {
    ...mapActions("modPsi", ["removeDataCompare", "addDataCompare"]),
    selectFilterOption(event, data) {
      if (event.target.classList.contains("tw-bg-green-400")) {
        this.removeDataCompare(data);
        event.target.classList.remove("tw-bg-green-400");
      } else {
        if (this.dataCompare.length < 2) {
          this.addDataCompare(data);
          event.target.classList.add("tw-bg-green-400");
        }
      }
    },
  },
};
</script>

<style scoped>
.grid-1 {
  grid-template-columns: 100px 90px 90px 50px;
}

@media (min-width: 768px) {
  .grid-1 {
    grid-template-columns: auto auto auto auto auto auto 70px;
  }
}

@media (min-width: 992px) {
  .grid-1 {
    grid-template-columns: auto auto auto auto auto auto 70px;
  }
}

@media (min-width: 1200px) {
  .grid-1 {
    grid-template-columns: 210px 150px 150px 150px 150px 150px 70px;
  }
}
</style>