<script setup>
import { onBeforeMount, ref, computed } from "vue";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { useUserStore } from "@/stores/userStore";
import { pushLink } from "@/helpers/linkHelpers";

const goFlexStore = useGoFlexStore();
const userStore = useUserStore();
const numberOfPendingRequests = ref(0);
const numberOfPendingTeamRequests = ref(0);
const isLoading = ref(false);

const requests = computed(() => goFlexStore.requests);
const teamRequests = computed(() => goFlexStore.teamRequests);
const user = computed(() => userStore.user);

const buttonDatalayer = (button_title) => {
  return {
    event_category: "Go Flex",
    event_action: "Botones solicitudes",
    event_label: button_title,
    component_name: "Click",
  };
};

onBeforeMount(async () => {
  isLoading.value = true;
  await goFlexStore.actGetGoFlexRequests();
  await goFlexStore.actGetGoFlexTeamRequests();

  numberOfPendingRequests.value = requests.value.reduce((acc, request) => {
    if (request.status === "PENDING") {
      acc++;
    }
    return acc;
  }, 0);

  numberOfPendingTeamRequests.value = teamRequests.value.reduce(
    (acc, request) => {
      if (request.status === "PENDING") {
        acc++;
      }
      return acc;
    },
    0
  );

  isLoading.value = false;
});
</script>

<template>
  <div
    class="tw-w-full lg:tw-flex lg:tw-justify-end lg:tw-mb-8"
    :class="
      requests.length > 0 || teamRequests.length > 0 ? ' lg:-tw-mt-14' : ''
    "
    v-if="!isLoading"
  >
    <div
      class="tw-grid lg:tw-auto-cols-max tw-grid-cols-1 lg:tw-grid-flow-col tw-justify-end tw-gap-4 lg:tw-gap-4 lg:tw-w-1/2 tw-mb-5 lg:tw-mb-0"
    >
      <div
        class="tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-py-1.5 lg:tw-py-2 tw-flex tw-justify-center tw-items-center tw-gap-2 tw-order-1 tw-cursor-default"
        v-if="user.immediate_boss != null || user.immediate_boss !== ''"
      >
        <p class="tw-m-0 tw-capitalize tw-text-xs lg:tw-text-sm tw-text-center">
          <span class="tw-font-robotoBold">Tu Line Manager:</span>
          {{ user.immediate_boss.toLowerCase() }}
        </p>
      </div>
      <div
        class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-order-1"
        @click="
          pushLink(
            '/goflex/lineManagerTeamRequests',
            buttonDatalayer('Solicitudes de tu equipo')
          )
        "
        :class="
          $route.name === 'goFlexLineManagerTeamRequests'
            ? 'tw-btn-primary-red'
            : numberOfPendingTeamRequests > 0
            ? 'tw-btn-general-shape tw-border tw-border-red-350 tw-shadow-lg'
            : 'tw-btn-general-shape tw-border tw-border-black'
        "
        v-if="teamRequests.length > 0"
      >
        <i class="icon-cebada-full" v-if="numberOfPendingTeamRequests === 0" />
        <div
          class="tw-w-5 tw-h-5 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full"
          :class="
            $route.name === 'goFlexLineManagerTeamRequests'
              ? 'tw-bg-white'
              : 'tw-bg-red-350'
          "
        >
          <span
            :class="
              $route.name === 'goFlexLineManagerTeamRequests'
                ? 'tw-text-red-350'
                : 'tw-text-white'
            "
            >{{ numberOfPendingTeamRequests }}</span
          >
        </div>
        <p class="tw-m-0">Solicitudes de tu equipo</p>
      </div>
      <div
        class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-order-2"
        :class="
          $route.name === 'goFlexRequestsHistory'
            ? 'tw-btn-primary-red'
            : numberOfPendingRequests > 0
            ? 'tw-btn-general-shape tw-border tw-border-red-350 tw-shadow-lg'
            : 'tw-btn-general-shape tw-border tw-border-black'
        "
        @click="
          pushLink(
            '/goflex/requestsHistory',
            buttonDatalayer('Tus solicitudes')
          )
        "
        v-if="requests.length > 0"
      >
        <i class="icon-cebada-full" v-if="numberOfPendingRequests === 0"></i>
        <div
          class="tw-w-5 tw-h-5 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full"
          :class="
            $route.name === 'goFlexRequestsHistory'
              ? 'tw-bg-white'
              : 'tw-bg-red-350'
          "
        >
          <span
            :class="
              $route.name === 'goFlexRequestsHistory'
                ? 'tw-text-red-350'
                : 'tw-text-white'
            "
            >{{ numberOfPendingRequests }}</span
          >
        </div>
        <p class="tw-m-0">Tus solicitudes</p>
      </div>
    </div>
  </div>
</template>
