import { render, staticRenderFns } from "./MonthProductShoppingCart.vue?vue&type=template&id=03ac18a9&scoped=true"
import script from "./MonthProductShoppingCart.vue?vue&type=script&setup=true&lang=js"
export * from "./MonthProductShoppingCart.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ac18a9",
  null
  
)

export default component.exports