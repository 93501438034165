<script setup>
import { onMounted } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";

const ecommerceStore = useEcommerceStore();

onMounted(() => {
  window.dataLayer.push({
    event: "'trackEvent",
    target: "ecommerce-gracias-por-su-compra",
    action: "clic",
    value: 5000,
  });

  ecommerceStore.emptyShoppingCart();
});
</script>
<template>
  <div class="justify-content-center text-center">
    <SecondaryNavbar class="d-block d-md-none" title="Gracias por tu compra" />
    <div
      id="shop-thankyou"
      class="container m-auto d-flex flex-column align-items-center"
    >
      <div id="shop-img" class="my-2">
        <img
          class="img-fluid"
          src="@/assets/img/ecommerce/compra-exitosa-desk.png"
          alt=""
        />
      </div>
      <div id="shop-text">
        <div
          class="alert alert-danger"
          v-if="ecommerceStore.giftErrorMsj !== ''"
        >
          {{ ecommerceStore.giftErrorMsj }}
        </div>
        <div v-else>
          <p
            class="text-uppercase fs-24 fs-md-32 tw-font-tolyerBold tw-text-red-400 mt-4"
          >
            ¡Disfruta tus productos!
          </p>
          <p class="tw-font-robotoBold fs-md-18 tw-text-gray-400">
            Hemos procesado tu pedido exitosamente.
          </p>
        </div>
      </div>
      <div
        id="shop-buttons"
        class="d-flex justify-content-between mt-3 my-md-4"
      >
        <router-link to="/e-commerce/shoppingHistory">
          <button
            class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 mb-3 tw-font-robotoBold tw-text-xs fs-md-16 line-height-20"
          >
            Mis pedidos
          </button>
        </router-link>
        <router-link to="/e-commerce">
          <button
            class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 mb-3 tw-font-robotoBold tw-text-xs fs-md-16 line-height-20"
          >
            Tienda
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
}

#shop-buttons {
  width: 80%;
}
@media (min-width: 768px) {
  .container {
    width: 560px;
  }

  #shop-buttons {
    width: 400px;
  }
}
</style>
