<script setup>
import { ref, computed, onBeforeMount, onUnmounted, watch } from "vue";
import config from "@/services/config";

const emit = defineEmits(["changeOption"]);

const uriResources = config.beerOfficeURL;
const openSelect = ref(false);
const optionSelectedId = ref(null);

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  defaultOption: {
    type: Number,
    default: null,
  },
  isLabel: {
    type: Boolean,
    default: false,
    required: true,
  },
  label: {
    type: String,
    default: "Selecciona una opción: ",
  },
  fitted: {
    type: Boolean,
    default: true,
  },
  iconVisible: {
    type: Boolean,
    default: false,
  },
  embeddedMobile: {
    type: Boolean,
    default: false,
  },
  embeddedDesktop: {
    type: Boolean,
    default: false,
  },
  extendsContainer: {
    type: Boolean,
    default: false,
  },
  defaultTextVisible: {
    type: Boolean,
    default: false,
  },
  defaultText: {
    type: String,
    default: "Selecciona una opción",
  },
  borderColor: {
    type: String,
    default: "gray-300",
  },
});

watch(
  () => props.defaultOption,
  (val) => {
    optionSelectedId.value = val;
  }
);

const optionSelected = computed(() => {
  return props.options.find((option) => option.id === optionSelectedId.value);
});

function toggleSelectFilter(e) {
  if (e.target.closest(".main") && openSelect.value) {
    openSelect.value = false;
  } else {
    openSelect.value = !openSelect.value;
  }
}

function changeSelectFilter(opt) {
  optionSelectedId.value = opt.id;
  emit("changeOption", optionSelected.value, true, false);
  openSelect.value = false;
}

onBeforeMount(() => {
  if (props.defaultOption) {
    optionSelectedId.value = props.defaultOption;
  }
  optionSelectedId.value =
    props.defaultOption != null ? props.defaultOption : props.options[0].id;
  emit("changeOption", optionSelected.value, false, true);
  window.addEventListener("click", function (e) {
    if (!e.target.closest(".dropdown-icon")) {
      openSelect.value = false;
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("click", function () {});
});
</script>

<template>
  <div
    class="dropdown-icon tw-flex tw-gap-x-2 tw-items-start tw-justify-end tw-z-2"
    :class="extendsContainer ? '' : 'tw-max-h-8 lg:tw-max-h-14'"
  >
    <!--  <div
    class="tw-flex tw-gap-x-2 tw-items-start tw-justify-end tw-z-2"
    :class="
      !extendsContainer && !openSelect
        ? 'tw-max-h-10 lg:tw-max-h-16'
        : !extendsContainer && openSelect
        ? 'tw-max-h-full'
        : ''
    "
  >-->
    <p
      v-if="props.isLabel"
      class="tw-mt-2 tw-font-roboto tw-text-xs lg:tw-text-lg"
    >
      {{ props.label }}
    </p>
    <div
      class="tw-h-full tw-border-solid tw-border tw-bg-white tw-overflow-hidden tw-z-2 tw-p-1 lg:tw-p-2"
      :class="[
        props.embeddedMobile === true
          ? 'tw-border-transparent'
          : `tw-border-${borderColor}`,
        props.embeddedDesktop
          ? 'lg:tw-border-transparent'
          : `lg:tw-border-${borderColor}`,
        openSelect ? 'tw-rounded-2xl' : 'tw-rounded-2xl',
        props.fitted ? 'tw-w-max' : 'tw-w-full',
      ]"
    >
      <div
        class="main tw-grid tw-items-center tw-gap-x-3 tw-cursor-pointer tw-px-2 tw-py-1"
        :class="
          props.iconVisible
            ? 'tw-grid-cols-col3_fit_first_and_last'
            : 'tw-grid-cols-col2_fit_last'
        "
        @click="toggleSelectFilter"
      >
        <div class="tw-flex" v-if="props.iconVisible">
          <img
            v-if="optionSelected.image_icon"
            :src="uriResources + '/' + optionSelected.image_icon"
            class="tw-object-cover tw-max-h-7 lg:tw-max-h-9"
            alt=""
          />
          <img
            v-else
            :src="uriResources + '/' + optionSelected.image"
            class="tw-object-cover tw-max-h-7 lg:tw-max-h-9"
            alt=""
          />
        </div>
        <div class="tw-flex">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base tw-capitalize tw-text-red-350"
            v-if="openSelect && props.defaultTextVisible"
          >
            {{ defaultText }}
          </p>
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base tw-capitalize"
            v-else
          >
            {{ optionSelected?.name }}
          </p>
        </div>
        <i
          class="tw-text-xs tw-text-red-350"
          :class="openSelect ? 'icon-flecha-arriba' : 'icon-flecha-abajo'"
        ></i>
      </div>
      <div
        class="tw-bg-white tw-w-full tw-max-h-32 lg:tw-max-h-40 tw-overflow-scroll"
        :class="openSelect ? 'tw-h-auto tw-visible' : 'tw-h-0 tw-invisible'"
      >
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 lg:tw-px-2 lg:tw-py-2 tw-grid tw-grid-cols-col2_fit_first tw-items-center tw-gap-x-3"
          v-for="(opt, idx) in props.options"
          :key="idx"
          @click="changeSelectFilter(opt)"
        >
          <img
            v-if="opt.image_icon"
            :src="uriResources + '/' + opt.image_icon"
            class="tw-object-cover tw-max-h-7 lg:tw-max-h-9"
            alt=""
          />
          <img
            v-else
            :src="uriResources + '/' + opt.image"
            class="tw-object-cover tw-max-h-7 lg:tw-max-h-9"
            alt=""
          />
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-capitalize"
          >
            {{ opt?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
