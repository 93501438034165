import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { useRouter } from "vue-router/composables";

export function usePeopleTour() {
  const peopleTourStore = usePeopleTourStore();
  const router = useRouter();

  const activityIcon = (status) => {
    const map = new Map([
      ["Sin realizar", "icono-sinrealizar.png"],
      ["Pendiente", "icono-pendiente.png"],
      ["Por hacer", "icono-porhacer.png"],
      ["Aprobada", "icono-aprobada.png"],
      ["Rechazada", "icono-rechazada.png"],
      ["Finalizado", "icono-finalizada.png"],
    ]);
    return map.get(status);
  };

  const checkBP = async (user_id_sharp) => {
    const userBP = await peopleTourStore.getBPList(user_id_sharp);

    const BPExist = peopleTourStore.BPList.find(
      (BP) => Number(BP.field_sharp) === Number(userBP)
    );

    if (!userBP || !BPExist) {
      await router.push({ name: "peopleTour" });
    }
  };

  return {
    activityIcon,
    checkBP,
  };
}
