<template>
  <div
    :style="`background-image: url(${uriResources}/${image}); background-size: cover;`"
    class="he-md-580 tw-rounded-lg"
  >
    <div class="bg-1 tw-w-full tw-h-full tw-flex tw-items-center tw-rounded-2xl">
      <div
        class="
          tw-flex
          tw-flex-col
          tw-justify-center
          tw-items-center
          tw-w-full
          tw-mx-auto
          max-wi-400
          tw-py-5
          md:tw-py-3
          tw-mb-4
        "
      >
        <p
          class="
            tw-text-white tw-font-bold tw-text-center tw-text-xl
            md:tw-text-3xl tw-mb-4
          "
        >
          {{ title }}
        </p>
        <p class="tw-text-white tw-text-center tw-text-sm md:tw-text-lg">
          {{ desc }}
        </p>
        <div
          @click="startTest(2), pushDataLayer(title)"
          class="
            tw-bg-red-400
            tw-p-3 md:tw-p-4
            tw-rounded-lg tw-text-white
            tw-text-center
            hover:tw-bg-red-600
          "
          role="button"
        >
          Iniciar test
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
export default {
  name: "HomeTrivia",
  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
    },
    image: {
      type: String,
    },
    subbrand: {
      type: String,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  methods: {
    startTest(state) {
      this.$emit("changeOpcVisible", state);
    },
    pushDataLayer(subcategory) {
      window.dataLayer.push({
        event: "TrackEvent-trivias",
        category: "Trivias",
        SubCategory: subcategory,
        Action: "Paso 1: Iniciar Test",
      });
    },
  },
};
</script>

<style scoped>
.bg-1 {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
</style>
