<template>
  <div class="tw-container-general">
    <PsiBreadcrumbButtons
      :path="this.$route.path"
      @buttonClicked="changeClearCheckboxFilter"
    />
    <div class="container mb-4">
      <div class="row tw-shadow-md tw-rounded-lg py-3">
        <PsiFilterData
          :isMySiblingClicked="isSibling1Clicked"
          @updateSummaryData="updateSummaryData"
        />
        <div class="col-12 px-0 px-md-3">
          <div class="grid-1 d-grid gap-10 columns-7 px-2 px-md-3 mb-2">
            <div class="d-flex justify-content-center align-items-center">
              <p
                class="text-uppercase tw-text-gray-600 font-weight-bold text-center tw-text-xxs fs-md-16 mb-0"
              >
                KPI PEOPLE
              </p>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <select
                class="text-uppercase tw-text-gray-600 font-weight-bold text-center tw-text-xxs fs-md-16 border-0 outline-none"
                v-model="monthSelected"
                @change="onChangeMonthSelected($event)"
              >
                <option
                  v-for="(month, index) in months"
                  :key="'month-' + index"
                  :value="index"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <p
                class="text-uppercase tw-text-gray-600 font-weight-bold text-center tw-text-xxs fs-md-16 mb-0"
              >
                YTD
              </p>
            </div>
            <div class="d-none d-md-block">
              <p
                class="text-uppercase tw-text-gray-600 font-weight-bold text-center tw-text-xs fs-md-16 mb-0 mt-1"
              >
                Last year
              </p>
            </div>
            <div class="d-none d-md-block">
              <p
                class="text-uppercase tw-text-gray-600 font-weight-bold text-center tw-text-xs fs-md-16 mb-0 mt-1"
              >
                Budget 2021
              </p>
            </div>
            <div class="d-none d-md-block">
              <p
                class="text-uppercase tw-text-gray-600 font-weight-bold text-center tw-text-xs fs-md-16 mb-0 mt-1"
              >
                Le
              </p>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <p
                class="tw-text-gray-600 font-weight-bold text-center tw-text-xxs fs-md-16 mb-0"
              >
                DETALLE
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 mx-0 px-0 tw-overflow-y-scroll max-he-550">
          <div class="text-center py-3" v-if="isLoadingData">
            <img :src="imageLoading" width="40" alt="loading" />
          </div>
          <div v-else-if="!viewRowCompareData">
            <PsiRowData
              v-for="(psiData, index) in dataFilterSummary"
              :key="'psi-row-data-' + index"
              :psiData="psiData"
            />
          </div>
          <PsiRowDataCompare
            v-else
            v-for="(psiData, index) in dataFilterSummary"
            :key="'psi-row-data-compare-' + index"
            :psiData="psiData"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 pt-4 ml-auto">
          <div
            v-if="viewRowCompareData && dataCompare.length < 2"
            @click="compare"
            role="button"
            class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 text-center"
          >
            Comparar
          </div>
          <div class="d-flex" v-if="dataCompare.length >= 2">
            <div
              class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 text-center mr-1"
              @click="cancelFilter"
            >
              Cancelar
            </div>
            <router-link
              to="/indicators/psi/compare"
              class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 text-center ml-1 hover:tw-text-white"
              >Comparar</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import imageLoading from "@/assets/img/icons/loading.gif";
import datesMixin from "@/mixins/general/dates";
import PsiRowData from "@/components/psi/PsiRowData";
import PsiRowDataCompare from "@/components/psi/PsiRowDataCompare";
import PsiBreadcrumbButtons from "@/components/psi/PsiBreadcrumbButtons";
import PsiFilterData from "@/components/psi/PsiFilterData";

export default {
  name: "PsiView",
  mixins: [datesMixin],
  components: {
    PsiRowData,
    PsiRowDataCompare,
    PsiBreadcrumbButtons,
    PsiFilterData,
  },
  data() {
    return {
      isSibling1Clicked: false,
      monthSelected: 1,
      imageLoading,
      selectedSummaryFilter: {
        kpi: this.$store.state.modPsi.kpi,
        vp: this.$store.state.modPsi.vp,
        psah: this.$store.state.modPsi.psah,
        month: this.$store.state.modPsi.month,
        year: this.$store.state.modPsi.year,
        first: this.$store.state.modPsi.first,
      },
    };
  },
  created() {
    this.monthSelected = this.month;
    this.updateSummaryData();
  },
  computed: {
    ...mapState("modPsi", [
      "dataFilterSummary",
      "viewRowCompareData",
      "isFilter",
      "isLoadingData",
      "dataCompare",
      "kpi",
      "vp",
      "psah",
      "month",
      "year",
      "first",
    ]),
  },
  methods: {
    ...mapActions("modPsi", [
      "getSummaryPsi",
      "updateMonth",
      "updateIsCompare",
      "updateFirst",
      "resetFilterData",
    ]),
    changeClearCheckboxFilter() {
      this.isSibling1Clicked = !this.isSibling1Clicked;
    },
    updateSummaryData() {
      let selectedFirst = this.isFilter === true;
      this.updateFirst(selectedFirst);
      setTimeout(() => {
        let selectedSummaryFilter = {
          kpi: this.kpi,
          vp: this.vp,
          psah: this.psah,
          month: this.month,
          year: this.year,
          first: selectedFirst,
        };
        this.getSummaryPsi(JSON.stringify(selectedSummaryFilter));
      }, 500);
    },
    onChangeMonthSelected() {
      this.updateMonth(this.monthSelected);
      this.updateSummaryData();
    },
    compare() {
      this.updateIsCompare(true);
    },
    cancelFilter() {
      this.resetFilterData();
    },
  },
};
</script>
<style scoped>
.grid-1 {
  grid-template-columns: 100px 90px 80px 50px;
}

@media (min-width: 768px) {
  .grid-1 {
    grid-template-columns: auto auto auto auto auto auto 70px;
  }
}

@media (min-width: 992px) {
  .grid-1 {
    grid-template-columns: auto auto auto auto auto auto 70px;
  }
}

@media (min-width: 1200px) {
  .grid-1 {
    grid-template-columns: 210px 150px 150px 150px 150px 150px 70px;
  }
}
</style>
