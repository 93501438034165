<template>
  <div class="container mt-0">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5 px-0 px-md-2 ml-auto">
        <div class="cont-menu-1 d-none d-md-block">
          <ul class="d-flex justify-content-end">
            <li
              class="mx-3 text-center"
              v-for="menu in menu1"
              :key="'menu-1-' + menu.id"
            >
              <router-link :to="menu.link">
                <div :class="menu.classes.opc1">
                  <i :class="menu.icon"></i>
                </div>
                <span>{{ menu.text }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 px-0">
        <div class="d-block d-md-none">
          <ul
            class="navbar-nav flex-row align-items-end justify-content-center mb-3"
          >
            <li
              class="col px-0 px-md-1 px-lg-3 text-center"
              v-for="menu in menu1"
              :key="'menu-2-' + menu.id"
            >
              <router-link :class="menu.classes.opc2" :to="menu.link">
                {{ menu.text }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuExtraHours",
  props: {
    page: {
      type: String,
      required: true,
      default: "calculator",
    },
  },
  data() {
    return {
      menu1: [
        {
          id: 1,
          classes: {
            opc1:
              this.page === "calculator"
                ? "active cont-icon mx-auto mb-1 d-flex justify-content-center align-items-center"
                : "inactive cont-icon mx-auto mb-1 d-flex justify-content-center align-items-center",
            opc2: this.page === "calculator" ? "nav-link active" : "nav-link",
          },
          link: "/showMeTheMoney/extra-hours/calculator",
          icon: "icon-calculadora-2",
          text: "Calculadora",
        },
        {
          id: 2,
          classes: {
            opc1:
              this.page === "howCalculate"
                ? "active cont-icon mx-auto mb-1 d-flex justify-content-center align-items-center"
                : "inactive cont-icon mx-auto mb-1 d-flex justify-content-center align-items-center",
            opc2: this.page === "howCalculate" ? "nav-link active" : "nav-link",
          },
          link: "/showMeTheMoney/extra-hours/how-calculate",
          icon: "icon-agenda",
          text: "¿Cómo se calcula?",
        },
      ],
    };
  },
};
</script>

<style scoped>
.navbar-nav {
  background-color: #f7f7f7;
  height: 40px;
}

.nav-link {
  color: #999999;
  text-transform: uppercase;
}

.nav-link.active {
  color: #d82b2b;
  border-bottom: 3px solid #d82b2b;
}

.cont-menu-1 {
  margin-top: -80px;
}

.cont-menu-1 ul li {
  list-style: none;
}

.cont-menu-1 ul li a {
  text-decoration: none;
  color: #454545;
}

.cont-menu-1 ul li a .cont-icon {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.cont-menu-1 ul li a .cont-icon.active {
  background-color: #fab23a;
}

.cont-menu-1 ul li a .cont-icon.inactive {
  background-color: #cfccce;
}

.cont-menu-1 ul li a .active i {
  color: #d82b2b;
}

.cont-menu-1 ul li a .inactive i {
  color: #757475;
}

.cont-menu-1 ul li a .cont-icon i {
  font-size: 28px;
}

.cont-menu-1 ul li a span {
  font-size: 11px;
}
</style>