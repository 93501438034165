<template>
  <div class="card w-100">
    <div class="mt-3 font-weight-bold">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3 text-left d-block d-md-none mb-2">
            <img class="icon-event" :src="imgIconPoints" alt />
          </div>
          <div class="col-12 col-md-9">
            <h5
              class="text-uppercase textColorPrimary tw-font-robotoBold tw-text-sm md:tw-text-lg"
            >
              puntos
            </h5>
          </div>
          <div class="col-md-3 text-right d-none d-md-block pl-1">
            <img class="icon-event" :src="imgIconPoints" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3">
      <div class="col-12 px-2 px-md-3">
        <div class="px-1 px-lg-3">
          <div class="card-1 mt-3 p-2 p-md-3 text-center">
            <img
              src="@/assets/img/gamification/patronShopRewards.png"
              alt="cebada"
              class="mr-1 pr-1 tw-w-6"
            />
            <span class="text text-1"
              >Tienes {{ formatMoney(user.gamification_maltas) }} maltas</span
            >
          </div>
          <div class="card-1 mt-3 p-2 p-md-3 text-center">
            <img
              src="@/assets/img/gamification/medal-2.png"
              alt="medalla"
              class="mr-1 pr-1 tw-w-6"
            />
            <span class="text text-2"
              >Tus puntos de rango
              {{ formatMoney(user.gamification_points) }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 text-right mt-3">
        <router-link to="/gamification" class="link">Detalle</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CompPoints",
  data() {
    return {
      imgIconPoints: require("@/assets/img/icons/puntos.png"),
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  methods: {
    formatMoney(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>

<style scoped>
.card-1 {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.card-1 .text {
  font-size: 13px;
  font-family: "RobotoBold", sans-serif;
}

.card-1 .text-1 {
  color: #222;
}

.card-1 .text-2 {
  color: #23bf79;
}

.link {
  color: #222;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  font-weight: bold;
}
</style>
