<script setup>
import { onBeforeMount } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const emit = defineEmits(["loaded"]);

const props = defineProps({
  illustration: {
    // Main illustration of the feature
    type: String,
    required: true,
  },
  // title of the feature
  title: {
    type: String,
    required: true,
  },
  // icon below the description of the feature
  icon: {
    type: String,
    required: true,
  },
});

onBeforeMount(() => {
  pushDataLayer({
    event_category: props.title,
    event_action: "Pageview",
    interaction: false,
  });
  setTimeout(() => {
    emit("loaded");
  }, 2000);
});
</script>
<template>
  <div class="tw-h-screen tw-w-screen tw-bg-pink-100">
    <div class="tw-absolute tw-h-full tw-w-full tw-z-2">
      <div
        class="tw-h-full tw-flex tw-flex-col lg:tw-grid lg:tw-grid-cols-2 tw-items-center tw-justify-center"
      >
        <div class="tw-flex tw-justify-end">
          <div
            class="tw-flex tw-justify-center lg:tw-justify-end lg:tw-w-10/12"
          >
            <img
              :src="require('@/assets/img/splash/' + props.illustration + '')"
              class="tw-w-full"
              alt=""
              style="max-height: 50vh"
            />
          </div>
        </div>

        <div>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-start lg:tw-w-10/12"
          >
            <p class="tw-font-robotoBold tw-text-3xl tw-leading-13">
              {{ props.title }}
            </p>
            <p
              class="tw-line-clamp-3 tw-text-center tw-font-roboto tw-text-xs lg:tw-text-lg tw-mx-5 md:tw-mx-24 lg:tw-mx-5"
            >
              <!-- Description of the feature is handled by slot, so we can use rich text and other html markup -->
              <slot></slot>
            </p>

            <img
              :src="require('@/assets/img/splash/' + props.icon + '')"
              class="tw-h-9 tw-mt-4"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <img
      src="@/assets/img/splash/icon-yellowcircle.svg"
      class="tw-absolute tw--top-20 tw--left-20 lg:tw-top-0 lg:tw-left-0 tw-z-1"
      alt=""
    />
    <img
      src="@/assets/img/splash/icon-downcorner.svg"
      class="tw-absolute tw--bottom-12 tw--right-11 lg:tw--bottom-6 lg:tw--right-5 tw-z-1"
      alt=""
    />
  </div>
</template>
