<script setup>
import { useReservationsStore } from "@/stores/reservationsStore";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import { storeToRefs } from "pinia";

const {
  bookingStep,
  bookingDatesSelected,
  bookingPlaceSelected,
  bookingIsHighSeason,
} = storeToRefs(useReservationsStore());

defineProps({
  guestsNumbers: String,
  changeStep: Function,
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-5">
    <div id="confirmBook">
      <p class="tw-font-robotoBold tw-text-base md:tw-text-2xl">
        Confirma tu reservas
      </p>
      <p class="tw-font-roboto tw-text-xs md:tw-text-lg">
        Este alojamiento tiene una capacidad máxima de
        {{ bookingPlaceSelected.capacity }} huéspedes, sin contar bebés.
      </p>
    </div>
    <div
      class="tw-border tw-border-gray-250 tw-rounded-4xl tw-w-full tw-p-4 tw-flex tw-flex-col tw-gap-5"
    >
      <div v-show="bookingStep === 5" id="chosenCharacteristicsMobile">
        <div>
          <div
            id="chosenCapacityMobile"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-2"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-base tw-text-gray-400"
              >
                Capacidad
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-gray-400">
                {{ bookingPlaceSelected.capacity + " personas" }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mb-5" />
        </div>
        <div>
          <div
            v-show="bookingStep >= 2"
            id="chosenSeasonMobile"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-2"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-base tw-text-gray-400"
              >
                Temporada
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-gray-400">
                {{ bookingIsHighSeason ? "Alta" : "Baja" }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mb-5" />
        </div>
        <div v-show="bookingStep >= 3">
          <div
            id="chosenStartDateMobile"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-2"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-base tw-text-gray-400"
              >
                Fecha inicio
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-gray-400">
                {{ formatDateYYYYMMDDLong(bookingDatesSelected.start_date) }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mb-5" />
        </div>
        <div v-show="bookingStep >= 3">
          <div
            id="chosenEndDateMobile"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-2"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-base tw-text-gray-400"
              >
                Fecha final
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-gray-400">
                {{ formatDateYYYYMMDDLong(bookingDatesSelected.end_date) }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mb-5" />
        </div>
        <div v-show="bookingStep >= 4">
          <div
            id="chosenGuestsMobile"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-2"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-base tw-text-gray-400"
              >
                Huéspedes
              </p>
            </div>
            <div class="tw-flex tw-items-center">
              <p
                class="tw-m-0 tw-font-roboto tw-text-xs tw-text-gray-400 tw-whitespace-pre-line"
              >
                {{ guestsNumbers }}
              </p>
            </div>
            <div class="tw-cursor-pointer" @click="changeStep(3)">
              <p
                class="tw-m-0 tw-font-roboto tw-text-xs tw-underline tw-text-red-350 tw-text-right"
              >
                Cambiar
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
