<script setup>
import { onBeforeMount, ref, computed } from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import HandleModal from "@/components/modals/HandleModal";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import CompReservationDetail from "@/components/reservations/CompReservationDetail";

const reservationsStore = useReservationsStore();
const isLoading = ref(false);

const bookingsToShow = computed(() =>
  [...reservationsStore.bookings]
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .slice(0, 3)
);

const colorStatus = (status) => {
  const map = new Map([
    ["PENDING_RAFFLE", "tw-bg-brown-500"],
    ["WON_RAFFLE", "tw-bg-green-450"],
    ["UNWON_RAFFLE", "tw-bg-red-350"],
    ["PENDING_PAYMENT", "tw-bg-yellow-200"],
    ["PAYMENT_MADE", "tw-bg-yellow-200"],
    ["CONFIRMED", "tw-bg-green-450"],
    ["CANCELLED", "tw-bg-red-350"],
    ["REJECTED", "tw-bg-red-350"],
    ["COMPLETED", "tw-bg-brown-500"],
    ["NOT_COMPLETED", "tw-bg-brown-500"],
  ]);
  return map.get(status) || "tw-bg-yellow-200";
};

const statusName = (name) => {
  const map = new Map([
    ["PENDING_WON_RAFFLE", "Pendiente"],
    ["PENDING", "Pendiente"],
    ["PENDING_RAFFLE", "Sorteo"],
    ["WON_RAFFLE", "Ganaste"],
    ["UNWON_RAFFLE", "No ganaste"],
    ["PENDING_PAYMENT", "Pagar"],
    ["PAYMENT_MADE", "Pago realizado"],
    ["CONFIRMED", "Confirmada"],
    ["CANCELLED", "Cancelada"],
    ["REJECTED", "Rechazada"],
    ["COMPLETED", "Finalizada"],
    ["NOT_COMPLETED", "No finalizada"],
  ]);
  return map.get(name) || "Pendiente";
};

onBeforeMount(async () => {
  isLoading.value = true;
  await reservationsStore.actGetBookings().then(() => {
    isLoading.value = false;
  });
});
</script>
<template>
  <div v-if="!isLoading">
    <HandleModal
      modal-name="bookingStates"
      icon="icon-booking"
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
    >
      <div class="tw-grid tw-auto-rows-auto tw-gap-3">
        <!-- Title -->
        <div class="tw-flex tw-justify-center">
          <p
            class="tw-font-robotoBold tw-text-lg lg:tw-text-xl tw-text-red-350"
          >
            Tus reservas
          </p>
        </div>
        <div
          v-if="reservationsStore.bookings.length === 0"
          class="tw-flex tw-flex-col tw-gap-6"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center">
            No has realizado ninguna reserva
          </p>
          <div class="tw-aspect-w-10 tw-aspect-h-8">
            <img
              src="@/assets/img/order-status/order-no-pending-order.svg"
              alt=""
            />
          </div>
        </div>
        <!-- Accordion -->
        <comp-accordion-parent open-first always-open-one v-else>
          <comp-accordion-item
            v-for="(book, idx) in bookingsToShow"
            openFirst
            :id="'book_' + idx"
            :key="idx"
          >
            <template v-slot:mainInfo>
              <div class="tw-grid tw-grid-cols-col2_fit_last tw-w-full">
                <div class="tw-grid tw-grid-rows-2">
                  <p class="tw-font-robotoBold tw-text-sm tw-m-0">
                    {{ book.booking_place.name }}
                  </p>
                  <p class="tw-font-roboto tw-text-sm tw-m-0 tw-text-gray-300">
                    {{
                      book.booking_place.booking_place_category_id === 2
                        ? formatDateYYYYMMDDLong(book.start_date)
                        : book.booking_season != null
                        ? book.booking_season.name
                        : "Temporada Baja"
                    }}
                  </p>
                </div>
                <div class="tw-h-full tw-flex tw-items-center">
                  <div
                    :class="colorStatus(book.status)"
                    class="tw-status-bubble"
                  >
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-white"
                    >
                      {{ statusName(book.status) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:secondaryInfo>
              <comp-reservation-detail :booking="book" />
            </template>
          </comp-accordion-item>
        </comp-accordion-parent>
        <!-- Buttons -->
        <div
          v-if="reservationsStore.bookings.length > 0"
          class="tw-btn-primary-red tw-text-center"
          @click="$router.push({ name: 'BookingHistory' })"
        >
          <p class="tw-my-1">Ver tus reservas</p>
        </div>
      </div>
    </HandleModal>
  </div>
</template>
