var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_setup.isLoading)?_c('div',{staticClass:"tw-w-full lg:tw-flex lg:tw-justify-end lg:tw-mb-8",class:_setup.requests.length > 0 || _setup.teamRequests.length > 0 ? ' lg:-tw-mt-14' : ''},[_c('div',{staticClass:"tw-grid lg:tw-auto-cols-max tw-grid-cols-1 lg:tw-grid-flow-col tw-justify-end tw-gap-4 lg:tw-gap-4 lg:tw-w-1/2 tw-mb-5 lg:tw-mb-0"},[(_setup.user.immediate_boss != null || _setup.user.immediate_boss !== '')?_c('div',{staticClass:"tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-py-1.5 lg:tw-py-2 tw-flex tw-justify-center tw-items-center tw-gap-2 tw-order-1 tw-cursor-default"},[_c('p',{staticClass:"tw-m-0 tw-capitalize tw-text-xs lg:tw-text-sm tw-text-center"},[_c('span',{staticClass:"tw-font-robotoBold"},[_vm._v("Tu Line Manager:")]),_vm._v(" "+_vm._s(_setup.user.immediate_boss.toLowerCase())+" ")])]):_vm._e(),(_setup.teamRequests.length > 0)?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-gap-2 tw-order-1",class:_vm.$route.name === 'goFlexLineManagerTeamRequests'
          ? 'tw-btn-primary-red'
          : _setup.numberOfPendingTeamRequests > 0
          ? 'tw-btn-general-shape tw-border tw-border-red-350 tw-shadow-lg'
          : 'tw-btn-general-shape tw-border tw-border-black',on:{"click":function($event){_setup.pushLink(
          '/goflex/lineManagerTeamRequests',
          _setup.buttonDatalayer('Solicitudes de tu equipo')
        )}}},[(_setup.numberOfPendingTeamRequests === 0)?_c('i',{staticClass:"icon-cebada-full"}):_vm._e(),_c('div',{staticClass:"tw-w-5 tw-h-5 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full",class:_vm.$route.name === 'goFlexLineManagerTeamRequests'
            ? 'tw-bg-white'
            : 'tw-bg-red-350'},[_c('span',{class:_vm.$route.name === 'goFlexLineManagerTeamRequests'
              ? 'tw-text-red-350'
              : 'tw-text-white'},[_vm._v(_vm._s(_setup.numberOfPendingTeamRequests))])]),_c('p',{staticClass:"tw-m-0"},[_vm._v("Solicitudes de tu equipo")])]):_vm._e(),(_setup.requests.length > 0)?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-gap-2 tw-order-2",class:_vm.$route.name === 'goFlexRequestsHistory'
          ? 'tw-btn-primary-red'
          : _setup.numberOfPendingRequests > 0
          ? 'tw-btn-general-shape tw-border tw-border-red-350 tw-shadow-lg'
          : 'tw-btn-general-shape tw-border tw-border-black',on:{"click":function($event){_setup.pushLink(
          '/goflex/requestsHistory',
          _setup.buttonDatalayer('Tus solicitudes')
        )}}},[(_setup.numberOfPendingRequests === 0)?_c('i',{staticClass:"icon-cebada-full"}):_vm._e(),_c('div',{staticClass:"tw-w-5 tw-h-5 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full",class:_vm.$route.name === 'goFlexRequestsHistory'
            ? 'tw-bg-white'
            : 'tw-bg-red-350'},[_c('span',{class:_vm.$route.name === 'goFlexRequestsHistory'
              ? 'tw-text-red-350'
              : 'tw-text-white'},[_vm._v(_vm._s(_setup.numberOfPendingRequests))])]),_c('p',{staticClass:"tw-m-0"},[_vm._v("Tus solicitudes")])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }