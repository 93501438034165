var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('portal-target',{attrs:{"name":"modal-qr"}}),_c(_setup.MobileBar,{attrs:{"title":"Mis Pedidos"}}),_c(_setup.TitleBar,{attrs:{"breadcrumb":_setup.breadcrumb,"title":"Mis Pedidos"}}),(!_setup.isLoading)?_c('div',{staticClass:"tw-container-general tw-mt-5"},[(
        _setup.ecommerceStore.ordersPending.length > 0 ||
        _setup.ecommerceStore.ordersHistory.length > 0
      )?_c('div',[_c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-10 tw-gap-10 lg:tw-gap-20"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-5 lg:tw-col-span-6"},[(_setup.ecommerceStore.ordersPending.length > 0)?_c('div',[_vm._m(0),_c(_setup.CompAccordionParent,_vm._l((_setup.ecommerceStore.ordersPending),function(order,idx){return _c(_setup.CompAccordionItem,{key:idx,attrs:{"id":'order_' + idx,"border":'tw-border tw-border-gray-250 tw-rounded-2.5xl',"padding":'tw-p-3',"division":false,"open-accordion-desk":false,"open-accordion-mobile":true},on:{"selectAccordionItem":function($event){return _setup.selectAccordionItem('pending', idx, order.type_order)}},scopedSlots:_vm._u([{key:"mainInfo",fn:function(){return [_c('div',{staticClass:"tw-grid tw-grid-cols-col2_fit_last"},[_c('div',{staticClass:"tw-grid tw-grid-rows-1"},[_c('p',{staticClass:"tw-text-xs lg:tw-text-base tw-m-0",class:_setup.activeIndex === idx && _setup.activeCategory === 'pending'
                            ? 'tw-text-red-350 tw-font-robotoBold'
                            : 'tw-font-roboto'},[_vm._v(" "+_vm._s(order.type_order === "TADA_CODE" ? _setup.parseDate(order.created_at) + "/ Cupón TaDa" : _setup.parseDate(order.created_at) + " / No. " + order.id)+" ")])]),_c('div',{staticClass:"tw-h-full tw-flex tw-items-center"},[_c('div',{staticClass:"tw-status-bubble tw-bg-red-350"},[_c('p',{staticClass:"tw-m-0 tw-text-white"},[_vm._v(" "+_vm._s(order.type_order === "TADA_CODE" ? _setup.couponDateStatus(order.due_date) : order.ecomm_order_state.name)+" ")])])])])]},proxy:true},{key:"secondaryInfo",fn:function(){return [(order.type_order === 'TADA_CODE')?_c(_setup.CompEcommerceCouponDetail,{attrs:{"order":order}}):_c(_setup.CompOrderDetail,{attrs:{"order":order}})]},proxy:true}],null,true)})}),1)],1):_vm._e(),(_setup.ecommerceStore.ordersHistory.length > 0)?_c('div',[_vm._m(1),_c(_setup.CompAccordionParent,_vm._l((_setup.ecommerceStore.ordersHistory),function(order,idx){return _c(_setup.CompAccordionItem,{key:idx,attrs:{"id":'order_' + idx,"border":'tw-border tw-border-gray-250 tw-rounded-2.5xl',"padding":'tw-p-3',"division":false,"open-accordion-desk":false,"open-accordion-mobile":true},on:{"selectAccordionItem":function($event){return _setup.selectAccordionItem('history', idx, order.type_order)}},scopedSlots:_vm._u([{key:"mainInfo",fn:function(){return [_c('div',{staticClass:"tw-grid tw-grid-cols-col2_fit_last"},[_c('div',{staticClass:"tw-grid tw-grid-rows-1"},[_c('p',{staticClass:"tw-text-xs lg:tw-text-base tw-m-0",class:_setup.activeIndex === idx && _setup.activeCategory === 'history'
                            ? 'tw-text-red-350 tw-font-robotoBold'
                            : 'tw-font-roboto'},[_vm._v(" "+_vm._s(order.type_order === "TADA_CODE" ? _setup.parseDate(order.created_at) + "/ Cupón TaDa" : _setup.parseDate(order.created_at) + " / No. " + order.id)+" ")])]),_c('div',{staticClass:"tw-h-full tw-flex tw-items-center"},[_c('div',{staticClass:"tw-status-bubble tw-bg-red-350"},[_c('p',{staticClass:"tw-m-0 tw-text-white"},[_vm._v(" "+_vm._s(order.ecomm_order_state.name)+" ")])])])])]},proxy:true},{key:"secondaryInfo",fn:function(){return [(order.type_order === 'TADA_CODE')?_c(_setup.CompEcommerceCouponDetail,{attrs:{"order":order}}):_c(_setup.CompOrderDetail,{attrs:{"order":order}})]},proxy:true}],null,true)})}),1)],1):_vm._e()]),_c('div',{staticClass:"tw-hidden lg:tw-block lg:tw-col-span-4"},[_c('div',{staticClass:"tw-w-full tw-rounded-2.5xl tw-p-6 tw-border tw-border-gray-250"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-5"},[_c('div',{staticClass:"tw-grid tw-grid-rows-1"},[_c('p',{staticClass:"tw-text-xs lg:tw-text-base tw-m-0 tw-text-red-350 tw-font-robotoBold"},[_vm._v(" "+_vm._s(_setup.activeOrder?.type_order === "TADA_CODE" ? _setup.parseDate(_setup.activeOrder?.created_at) + " / Cupón TaDa" : _setup.parseDate(_setup.activeOrder?.created_at) + " / No. " + _setup.activeOrder?.id)+" ")])]),(_setup.activeOrder?.type_order === 'TADA_CODE')?_c(_setup.CompEcommerceCouponDetail,{attrs:{"order":_setup.activeOrder}}):_c(_setup.CompOrderDetail,{attrs:{"order":_setup.activeOrder}})],1)])])])]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-mb-5"},[_c('p',{staticClass:"tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0"},[_vm._v(" Tus pedidos en curso ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-mb-5"},[_c('p',{staticClass:"tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0"},[_vm._v(" Tus pedidos finalizados ")])])
}]

export { render, staticRenderFns }