<script setup>
import { pushLink } from "@/helpers/linkHelpers";

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  datalayerData: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const linkTo = (link, label) => {
  pushLink(link, {
    ...props.datalayerData,
    element_text: label,
    component_name: "Click",
  });
};
</script>

<template>
  <div
    @click="linkTo(link, 'Saber más')"
    class="tw-flex tw-items-center tw-cursor-pointer"
  >
    <p
      class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
    >
      Saber más
    </p>
    <i
      class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-xs lg:tw-text-base"
    ></i>
  </div>
</template>

<style scoped></style>
