import Axios from "axios";

import config from "./config";

//CALLBACKS
const callback = {
  success: (response) => {
    return response.data;
  },
  error: (error) => {
    if (error) {
      return { error: error.response };
    } else {
      return {
        error: {
          message: "Error desconocido",
          status: 500,
        },
      };
    }
  },
};

//all services of beerOffice
export default {
  get: (uri, token) => {
    const request = Axios.get(
      config.beerOfficeApi + uri,
      config.axiosConf(token)
    );
    return request.then(callback.success).catch(callback.error);
  },
  post: (uri, token, data, formData = false) => {
    const request = Axios.post(
      config.beerOfficeApi + uri,
      data,
      config.axiosConf(token, formData)
    );
    return request.then(callback.success).catch(callback.error);
  },
  put: (uri, token, data) => {
    const request = Axios.put(
      config.beerOfficeApi + uri,
      data,
      config.axiosConf(token)
    );
    return request.then(callback.success).catch(callback.error);
  },
  patch: (uri, token, data) => {
    const request = Axios.patch(
      config.beerOfficeApi + uri,
      data,
      config.axiosConf(token)
    );
    return request.then(callback.success).catch(callback.error);
  },
  delete: (uri, token) => {
    const request = Axios.delete(
      config.beerOfficeApi + uri,
      config.axiosConf(token)
    );
    return request.then(callback.success).catch(callback.error);
  },
};
