<template>
  <div>
    <p class="text-uppercase tw-text-sm tw-text-gray-300 font-weight-bold">
      Descripción de la oferta
    </p>
    <div
      class="tw-bg-gray-200 tw-shadow tw-rounded-md p-4 z-index-2 position-relative"
    >
      <div class="d-flex justify-content-between">
        <p
          class="
            tw-text-gray-600
            tw-text-base fs-md-20
            text-uppercase
            mb-0
            mr-1
            font-weight-bold
          "
        >
          {{ request.position_name }}
        </p>
        <div
          v-if="
            requestsByLineManagerCount > 0 &&
            request.people_vacant_position_state_id === 'WAITING_LM'
          "
        >
          <div class="text-center" v-if="isLoadingApply">
            <img
              src="@/assets/img/icons/loading.gif"
              class="tw-w-10"
              alt="loading"
            />
          </div>

          <div v-else class="d-flex">
            <div
              @click="updateState(0)"
              role="button"
              class="
                 tw-text-xs 
                tw-bg-red-400
                tw-text-white
                py-2
                px-3
                tw-rounded-md
                hover:tw-bg-red-600
                mr-2
              "
            >
              Rechazar {{ request.people_vacant_position_employee_id }}
            </div>
            <div
              @click="updateState(1)"
              role="button"
              class="
                 tw-text-xs 
                tw-bg-green-400
                tw-text-white
                py-2
                px-3
                tw-rounded-md
                hover:tw-bg-green-600
                ml-2
              "
            >
              Autorizar
            </div>
          </div>
        </div>
        <div v-else class="d-flex align-items-center">
          <i
            class="tw-text-xl mr-2"
            :class="classStateVacant.icon + ' ' + classStateVacant.color"
          ></i>
          <p
            class="tw-text-sm mb-0 font-weight-bold"
            :class="classStateVacant.color"
          >
            {{ request.people_vacant_position_state_name }}
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div class="mr-2 ml-0" v-if="request.core_site">
          <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Lugar</p>
          <p class=" tw-text-xxs  fs-md-14 tw-text-gray-600 mb-0">
            {{ request.core_site.name }}
          </p>
        </div>
        <div class="mx-2">
          <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Fecha límite</p>
          <p class=" tw-text-xxs  fs-md-14 tw-text-gray-600 mb-0">
            {{ request.end_date }}
          </p>
        </div>
        <div class="mx-2" v-if="request.core_pa_sideline">
          <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Banda</p>
          <p class=" tw-text-xxs  fs-md-14 tw-text-gray-600 mb-0">
            {{ request.core_pa_sideline.name }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="
        z-index-1
        tw-bg-white
        py-4
        px-3 px-md-4
        mx-3 mx-md-4
        tw-shadow
        tw-rounded-md
        position-relative
        mt-n2
      "
    >
      <div class="he-350 tw-overflow-y-scroll">
        <p class="tw-text-base tw-text-gray-600 text-uppercase font-weight-bold">
          DESCRIPCIÓN DEL CARGO
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14">
          {{ request.position_desc }}
        </p>
        <p
          class="tw-text-base tw-text-gray-600 text-uppercase font-weight-bold mt-4"
        >
          PERFIL
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14 font-weight-bold">
          Educación:
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14">
          {{ request.education }}
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14 font-weight-bold">
          Habilidades:
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14">
          {{ request.skills }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import vacant from "@/mixins/user/vacant";

export default {
  name: "RequestDetail",
  mixins: [vacant],
  props: {
    request: {
      type: Object,
      required: true,
    },
    requestsByLineManagerCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    updateState(state) {
      this.$emit("updateState", state);
    },
  },
};
</script>
