var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"tbl-header tw-border tw-border-red-350 tw-bg-red-350 tw-rounded-t-2.5xl tw-overflow-hidden tw-py-2"},[_c('table',{staticClass:"tw-table-fixed tw-w-full"},[_c('thead',{staticClass:"tw-text-center"},[_c('tr',_vm._l((_setup.columnNames),function(column,idx){return _c('th',{key:idx,staticClass:"tw-py-3 tw-border first:tw-border-l-0 tw-border-l tw-border-r-0 tw-border-y-0 tw-border-white tw-text-white"},[_vm._v(" "+_vm._s(_setup.columnsReference[column])+" ")])}),0)])])]),_c('div',{staticClass:"tbl-content hidden-scrollbar"},[_c('table',{staticClass:"tw-table-fixed tw-w-full tw-border-separate tw-border-spacing-y-5"},[_c('tbody',_vm._l((_setup.props.teamRequests),function(item,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"tw-table-auto tw-flex tw-gap-4 tw-items-center tw-justify-center"},[_c('input',{staticClass:"tw-w-7 tw-h-7 tw-border tw-border-solid tw-border-gray-300 tw-rounded-md focus:tw-ring-transparent tw-shadow-sm",class:item.status === 'APPROVED' || item.status === 'REJECTED'
                  ? 'tw-cursor-not-allowed tw-text-gray-400'
                  : 'tw-cursor-pointer tw-text-green-450',attrs:{"disabled":item.status === 'APPROVED' || item.status === 'REJECTED',"required":"","type":"checkbox"},domProps:{"checked":item.status === 'APPROVED' ||
                item.status === 'REJECTED' ||
                _setup.selectedItems.includes(item.request_id)},on:{"click":function($event){return _setup.toggleSelectItem(item.request_id)}}}),_c('img',{staticClass:"tw-object-cover tw-w-14 tw-h-14 tw-rounded-full tw-border-2 tw-border-gray-300",attrs:{"src":_setup.avatarImage(item),"alt":""}})]),_c('td',[_c('div',{staticClass:"tw-h-full tw-w-full"},[_c('span',{staticClass:"tw-line-clamp-2 tw-font-robotoMedium"},[_vm._v(_vm._s(_setup.capitalizeEachWord(item.core_employee_name)))])])]),_c('td',[_vm._v(" "+_vm._s(_setup.catalogOption[item.option_catalog])+" ")]),_c('td',[_vm._v(_vm._s(item.option_name))]),_c('td',[_vm._v(" "+_vm._s(_setup.formatDateYYYYMMDDLong(item.requested_date))+" ")]),_c('td',[_vm._v(_vm._s(item.option_label))]),_c('td',[_c('div',{staticClass:"tw-bubble tw-w-full tw-border-none tw-py-2",class:`tw-bg-${_setup.statusColor(
                item.status
              )} tw-text-${_setup.statusTextColor(item.status)}`},[_vm._v(" "+_vm._s(_setup.statusName(item.status))+" ")])])])}),0)])]),_c('div',{staticClass:"tw-w-full tw-flex tw-justify-between tw-items-center tw-border tw-border-black tw-border-t-0 tw-rounded-b-2.5xl tw-p-3"},[_c('div',[_c(_setup.CompButtonCustom,{staticClass:"tw-w-full",attrs:{"text":_setup.selectedItems.length === _setup.pendingRequestsLength
            ? 'Deseleccionar todo'
            : 'Seleccionar todo',"color":"white"},nativeOn:{"click":function($event){return _setup.toggleSelectAllItems.apply(null, arguments)}}})],1),_c('div',{staticClass:"tw-flex tw-justify-end tw-items-center tw-gap-2"},[_c('p',{staticClass:"tw-m-0"},[_vm._v(" "+_vm._s(`has seleccionado (${_setup.selectedItems.length}) solicitudes`)+" ")]),_c(_setup.CompButtonCustomColor,{staticClass:"tw-w-32",attrs:{"disabled":_setup.selectedItems.length === 0,"text":"Aprobar","color":"green-450","text-color":"white"},nativeOn:{"click":function($event){return _setup.sentItemsForApproval.apply(null, arguments)}}}),_c(_setup.CompButtonCustomColor,{staticClass:"tw-w-32",attrs:{"disabled":_setup.selectedItems.length === 0,"text":"Rechazar","color":"red-350","text-color":"white"},nativeOn:{"click":function($event){return _setup.sentItemsForRejection.apply(null, arguments)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }