<script setup>
import { ref } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";

const articlesStore = useArticlesStore();
const emit = defineEmits(["articleSent"]);

const idea = ref({
  title: "",
  content: "",
  references: "",
  author_me: 1,
});
const formData = new FormData();

const submitArticle = () => {
  formData.append("type", "IDEA");
  formData.append("title", idea.value.title);
  formData.append("content", idea.value.content);
  formData.append("author_me", idea.value.author_me);
  idea.value.references !== ""
    ? formData.append("ref", idea.value.references)
    : "";

  articlesStore.actPostSuggestedArticle(formData).then(() => {
    articleSent();
  });
};

const articleSent = () => {
  emit("articleSent");
};
</script>
<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <div id="title">
      <p class="tw-font-robotoBold tw-text-red-350 tw-text-xl md:tw-text-3xl">
        Idea de un artículo
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-lg">
        Si tienes una idea para un artículo de embajadores, escríbenos.
      </p>
    </div>
    <div id="input" class="tw-mt-3 lg:tw-mt-4">
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Nombre del artículo
        </p>
        <input
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-full tw-bg-white tw-py-2 tw-px-2 tw-my-2 lg:tw-my-3 lg:tw-px-4 lg:tw-py-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm tw-border-solid tw-border tw-border-gray-250 focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent"
          v-model="idea.title"
          autocomplete="off"
          placeholder="Nombre..."
        />
      </div>
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Contenido
        </p>
        <textarea
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-2xl tw-bg-white tw-py-2 tw-px-2 tw-my-2 lg:tw-my-3 lg:tw-px-4 lg:tw-py-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm tw-border-solid tw-border tw-border-gray-250 focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent tw-resize-none tw-h-28"
          v-model="idea.content"
          autocomplete="off"
          placeholder="Escribe aquí tu contenido"
        ></textarea>
      </div>
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Referencias
        </p>
        <input
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-full tw-bg-white tw-py-2 tw-px-2 tw-my-2 lg:tw-my-3 lg:tw-px-4 lg:tw-py-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm tw-border-solid tw-border tw-border-gray-250 focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent"
          v-model="idea.references"
          autocomplete="off"
          placeholder="Referencias..."
        />
      </div>

      <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-mt-3">
        <div class="tw-btn-secondary-white">
          <p class="tw-my-1">Cancelar</p>
        </div>
        <div class="tw-btn-primary-red" @click="submitArticle()">
          <p class="tw-my-1">Enviar</p>
        </div>
      </div>
    </div>
  </div>
</template>
