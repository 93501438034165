<script setup>
import { computed } from "vue";
import config from "@/services/config";
import CompArticleImage from "@/components/articles/article-body/CompArticleImage";
import CompArticleQuote from "@/components/articles/article-body/CompArticleQuote";
import CompArticleText from "@/components/articles/article-body/CompArticleText";
import CompQualificationVideo from "@/components/video/CompVideoQualification.vue";
import CompArticleInfographic from "@/components/articles/article-body/CompArticleInfographic";
import CompArticleCardContainer from "@/components/articles/article-body/CompArticleCardContainer";
import CompArticleImageCarrousel from "@/components/articles/article-body/CompArticleImageCarrousel";
import CompArticleGalleryMasonry from "@/components/articles/article-body/CompArticleGalleryMasonry";
import CompArticleDoughnutDashboard from "@/components/articles/article-body/CompArticleDoughnutDashboard.vue";
import Trivia from "@/components/trivia/Trivia";
import Trivia2 from "@/components/trivia/trivia-2/Trivia2";
import { pushLink } from "@/helpers/linkHelpers";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  content: {
    type: Array,
    required: true,
  },
  datalayer: {
    type: Object,
  },
});

const charts = computed(() => {
  return props.content.filter((item) => item.type.name === "Grafico");
});

const setImageUrl = (imagePath) => {
  return uriResources + "/" + imagePath;
};

const linkTo = (link) => {
  if (link !== null) {
    pushLink(link);
  }
};
</script>
<template>
  <div>
    <div v-for="(item, idx) in props.content" :key="'part_1-' + idx">
      <CompArticleGalleryMasonry
        class="tw-mb-8"
        v-if="item.type.name === 'Galeria'"
        :content="item.cards"
      />
      <CompArticleImage
        class="tw-mb-8"
        v-if="item.type.name === 'Imagen'"
        :content="setImageUrl(item.content)"
        v-bind="$attrs"
      />
      <CompArticleQuote
        class="tw-mb-8"
        v-if="item.type.name === 'Cita'"
        :content="item.content"
      />
      <CompArticleText
        class="tw-mb-8"
        v-if="item.type.name === 'Texto'"
        :content="item.content"
      />
      <CompQualificationVideo
        class="tw-mb-8"
        v-if="item.type.name === 'Video'"
        :idVideo="item.id"
        :urlVideo="uriResources + '/' + item.content"
        :title="item.name"
      />
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4">
      <div v-for="(chart, idx) in charts" :key="idx">
        <CompArticleDoughnutDashboard class="tw-mb-8" :content="chart.chart" />
      </div>
    </div>
    <div v-for="(item, idx) in content" :key="'part_2-' + idx">
      <CompArticleInfographic
        class="tw-mb-8"
        v-if="item.type.name === 'Infografia'"
        :content="setImageUrl(item.content)"
      />
      <CompArticleCardContainer
        class="tw-mb-8"
        v-if="item.type.name === 'Grupo'"
        :content="item.cards"
      />
      <CompArticleImageCarrousel
        v-if="item.type.name === 'Carrusel'"
        :content="item.cards"
      />
      <Trivia
        class="tw-mb-8"
        v-if="item.type.name === 'Trivia'"
        :trivia="item.trivia"
      />
      <div
        v-if="item.type.name === 'Botones'"
        class="tw-flex tw-justify-center tw-mt-8 tw-gap-4 md:tw-w-8/12 md:tw-mx-auto"
      >
        <div
          v-for="button in item.buttons"
          :key="button.id"
          @click="linkTo(button.link)"
          class="tw-btn-tertiary-white tw-font-roboto tw-text-xs md:tw-text-xl tw-mt-0 tw-mb-2 md:tw-mx-3 tw-py-2 md:tw-py-3 tw-text-center tw-cursor-pointer hover:tw-bg-red-350 hover:tw-text-white hover:tw-border-transparent"
        >
          {{ button.name }}
        </div>
      </div>
      <Trivia2
        v-if="item.type.name === 'Trivia Categorizada'"
        :trivia="item.trivia"
        :datalayer="props.datalayer"
      />
    </div>
  </div>
</template>
