<template>
  <div class="table-responsive cont-table">
    <b-table
      selectable
      :select-mode="selectMode"
      @row-selected="onRowSelected"
      :fields="setFields()"
      :items="data"
      :tbody-tr-class="rowClass"
      @row-clicked="expandAdditionalInfo"
    >
      <template v-slot:cell(more)="row">
        <div @click="expandAdditionalInfo" class="mr-2 border-0">
          <svg
            v-if="!row.rowSelected"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-down"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            stroke="black"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
          <svg
            v-else
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-up"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            stroke="black"
          >
            <path
              fill-rule="evenodd"
              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
            />
          </svg>
        </div>
      </template>

      <template v-slot:row-details="row">
        <div class="container variable-detail">
          <div class="row">
            <div
              v-for="(item, index) in row.item.variables"
              :key="index"
              class="col-6 col-md-4 mb-3"
            >
              <div class="variable-type py-1">
                {{ item.variable_name }}
              </div>
              <div class="variable-value">
                {{ (item.fulfilment * 100).toFixed(2) }}%
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: "CompTableVariable",
  props: {
    data: {
      type: Array,
      default: [],
    },
    userPosition: {
      type: Number,
      default: 0,
    },
    positionType: {},
  },
  computed: {},
  data() {
    return {
      managerFields: [
        {
          key: "regional_position",
          label: "Posición",
        },
        {
          key: "manager",
          label: "Gerencia",
          formatter: "getItemName",
        },
        {
          key: "more",
          label: "",
        },
      ],
      zoneFields: [
        {
          key: "regional_position",
          label: "Posición",
        },
        {
          key: "zone",
          label: "Zona",
          formatter: "getZoneName",
        },
        {
          key: "more",
          label: "",
        },
      ],
      unitFields: [
        {
          key: "regional_position",
          label: "Posición",
        },
        {
          key: "unit",
          label: "Unidad",
          formatter: "getItemName",
        },
        {
          key: "more",
          label: "",
        },
      ],
      selectMode: "multi",
    };
  },
  methods: {
    getItemName(value) {
      return `${value.name}`;
    },
    getZoneName(value) {
      return `${value.id}`;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    expandAdditionalInfo(row) {
      row._showDetails = !row._showDetails;
    },
    // unselectRow(row) {
    //   row._showDetails = !row._showDetails;
    // },
    rowClass(item) {
      if (item.employee_id !== this.userPosition) return;
      if (item.employee_id === this.userPosition) return "selected-row ";
    },
    setFields() {
      const fieldMapping = {
        "Unidad Negocio": this.unitFields,
        Zona: this.zoneFields,
        Gerencia: this.managerFields,
      };

      return fieldMapping[this.positionType.name];
    },
  },
};
</script>
<style scoped>
.variable-type {
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
}

.variable-value {
  font: normal normal normal 16px/16px Roboto;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
}
</style>
