<script setup>
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router/composables";
import { useEventsStore } from "@/stores/eventsStore";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import OrganismVamosPaLaCalleActividadDetalleGrid from "@/components/organisms/OrganismVamosPaLaCalleActividadDetalleGrid.vue";

const { actGetStreetEvents } = useEventsStore();
const route = useRoute();
const isLoading = ref(false);
const { streetEvents } = storeToRefs(useEventsStore());

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetStreetEvents(route.params.id);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Actividades" />
    <title-bar title="Actividades" />
    <main class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
      <atom-text
        tag="h1"
        font="robotoMedium"
        text-size-mobile="xl"
        text-size-desktop="3xl"
      >
        Actividades
      </atom-text>
      <section
        class="tw-flex tw-justify-between tw-items-center tw-gap-2 lg:tw-gap-4 tw-border tw-border-y tw-border-x-0 tw-py-3 lg:tw-py-5"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl">
          Tu progreso general
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Horas acumuladas:</span>
          15
        </p>
      </section>
      <organism-vamos-pa-la-calle-actividad-detalle-grid
        :events="streetEvents"
      />
    </main>
  </div>
</template>

<style scoped></style>
