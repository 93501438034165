<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0">
    <div v-if="categories.id === this.$route.params.id">
      <div class="tw-container-general" v-if="categories.banner">
        <!--Header Mobile-->
        <SecondaryNavbar class="mx-0 ml-md-2" :title="categories.name" />
        <Banner :bannerId="categories.banner.id" gradient="none" />
        <!--Title and Paragraph-->
        <div class="container">
          <div class="row mt-sm-2">
            <div class="col mt-4 mt-md-5">
              <h1
                class="text-center px-3 mt-4 mt-md-0 textColorPrimary tw-text-4xl md:tw-text-7xl tw-font-tolyerBold"
              >
                {{ categories.title }}
              </h1>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="text-center textPlane">
                {{ categories.description }}
              </p>
            </div>
          </div>
        </div>

        <div class="row mx-2 mx-md-3 mt-4">
          <div
            class="col-12 col-md-4 px-3"
            v-for="(item, index) of categories.items"
            v-bind:key="index"
          >
            <div class="mobile">
              <div class="mb-3 mt-3" v-if="index % 2 === 0">
                <div @click="pushDataLayer(item)">
                  <router-link
                    :to="{
                      name: 'ArticleOld',
                      params: {
                        id: item.id,
                        categoryArticles: categoryArticles.name,
                        Category: Category,
                        SubCategory: SubCategory,
                      },
                    }"
                  >
                    <div class="row">
                      <div class="col-12 height">
                        <div
                          class="img-content img-mobile-radio"
                          :style="{
                            'background-image':
                              'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%), url(' +
                              url +
                              '/' +
                              item.img_card +
                              ')',
                          }"
                        ></div>
                      </div>
                    </div>
                    <div class="mt-2 px-2 ml-2 mr-3 texto-up">
                      <p class="pl-0 text-author">{{ item.author }}</p>
                      <p class="text-desc">{{ item.description }}</p>
                    </div>
                    <div class="position-absolute comp-star-1">
                      <CompStars
                        category="Articles"
                        :idContent="item.id"
                        :ratingData="ratingData[item.id]"
                        :showStars="false"
                      />
                    </div>
                  </router-link>
                </div>
              </div>
              <div
                v-else
                class="card mb-3 pr-3"
                style="border-radius: 30px; width: 100%"
              >
                <div @click="pushDataLayer(item)">
                  <router-link
                    :to="{
                      name: 'ArticleOld',
                      params: {
                        id: item.id,
                        categoryArticles: categoryArticles.name,
                        Category: Category,
                        SubCategory: SubCategory,
                      },
                    }"
                  >
                    <div class="row">
                      <div class="col-6 pr-0 height">
                        <div
                          class="img-content img-mobile-radio-right"
                          :style="{
                            'background-image':
                              'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%), url(' +
                              url +
                              '/' +
                              item.img_card +
                              ')',
                          }"
                        ></div>
                        <div class="position-absolute comp-star-2">
                          <CompStars
                            category="Articles"
                            :idContent="item.id"
                            :ratingData="ratingData[item.id]"
                            :showStars="false"
                          />
                        </div>
                      </div>
                      <div class="col-6 color px-0">
                        <p class="ml-1 pl-0 text-author p-1 my-1">
                          {{ item.author }}
                        </p>
                        <p class="ml-1 pl-0 text-desc p-1 textPlane">
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <!--Set images desk-->
            <div class="desk height">
              <div @click="pushDataLayer(item)">
                <router-link
                  :to="{
                    name: 'ArticleOld',
                    params: {
                      id: item.id,
                      categoryArticles: categoryArticles.name,
                      Category: Category,
                      SubCategory: SubCategory,
                    },
                  }"
                >
                  <div
                    class="img-content img-mobile-radio"
                    :style="{
                      'background-image':
                        'url(' + url + '/' + item.img_card + ')',
                    }"
                  ></div>
                </router-link>
              </div>
              <div class="d-flex justify-content-between mt-2">
                <p class="mt-2 mr-2 text-desc textPlane">{{ item.name }}</p>

                <CompStars
                  category="Articles"
                  :idContent="item.id"
                  :ratingData="ratingData[item.id]"
                  :showStars="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import Banner from "@/components/general/Banner";
import CompStars from "@/components/rating/CompStars";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";

export default {
  name: "ArticleCategories",
  data() {
    return {
      imgVideo: require("@/assets/img/demo/farming.jpg"),
      url: config.beerOfficeURL,
      v: false,
      categories: [],
      ratingData: {},
      score: 0,
      Category: null,
      SubCategory: null,
    };
  },
  props: {
    mod: {
      type: String,
    },
    act: {
      type: String,
    },
  },
  components: {
    SecondaryNavbar,
    Banner,
    CompStars,
  },
  created() {
    this.categories = [];
    this.actGetRating("Articles").then((data) => {
      this.ratingData = data;
    });
  },
  mounted() {
    this.actGetCategoryArticles(this.$route.params.id);
    this.Category = this.$route.params.Category;
    this.SubCategory = this.$route.params.SubCategory;
  },
  watch: {
    categoryArticles() {
      this.categories = [];
      this.categories = this.categoryArticles;
    },
  },
  computed: {
    ...mapState("modDiversityInclusion", ["categoryArticles"]),
  },
  methods: {
    ...mapActions("modDiversityInclusion", ["actGetCategoryArticles"]),
    ...mapActions("modRating", ["actGetRating"]),
    pushDataLayer(item) {
      if (this.ratingData[item.id] !== undefined) {
        this.score = this.ratingData[item.id]["score"];
      }
      window.dataLayer.push({
        event: "TrackEvent-Articles",
        category: this.Category,
        SubCategory: this.SubCategory,
        Action: "Clics",
        CategoryArticles: this.categoryArticles.name,
        Articles: item.name,
        qualification: this.score,
      });
    },
  },
};
</script>

<style scoped>
.text-author {
  font: normal normal normal 14px Roboto;
}
.text-desc {
  font: normal normal normal 16px Roboto;
}
a:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.card {
  border: 0;
  width: 335px;
  height: 210px;
}
.img-farming {
  width: 100%;
}
.img-big {
  width: 100%;
  height: 250px;
  background-position: 60% 10%;
}
.img-mobile-radio-right {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.tab {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 320px;
  background: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.texto-encima {
  position: absolute;
  top: 50%;
  left: 5%;
  color: white;
}
.card-img {
  width: 100%;
  height: 146px;
  border-radius: 20px;
}
.card-img-vertical {
  width: 100%;
  height: 200px;
}
.card-content {
  width: 100%;
  height: 146px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #f1f1f1;
}
.card-content-vertical {
  width: 100%;
  height: 200px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #f1f1f1;
}
.img-content {
  width: 100%;
  height: 250px;
  background-position: 50% 50%;
  background-size: cover;
}
.img-mobile-radio {
  border-radius: 20px;
}
.img-desk-radio {
  border-radius: 20px;
}
.img-mobile-radio-vertical {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.texto-up {
  position: absolute;
  top: 50%;
  left: 5%;
  color: white;
}
.color {
  width: 100%;
  color: #343434;
  background: #eeeeee;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media (min-width: 768px) {
  .img-video {
    width: 100%;
    height: 403px;
  }
  .img-big {
    width: 100%;
    height: 480px;
    background-position: 40% 20%;
    background-size: cover;
  }

  .mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .desk {
    display: none;
  }
  .text-author {
    font: normal normal bold 14px Roboto;
  }
  .text-desc {
    font: normal normal normal 14px Roboto;
  }
  .img-content {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
  }
  .height {
    height: 223px;
  }
}
</style>

<style lang="css">
.comp-star-1 {
  top: 30px;
  right: 30px;
}

.comp-star-1 .rate-number p,
.comp-star-2 .rate-number p {
  color: #ffffff;
}

.comp-star-2 {
  top: 10px;
  right: 10px;
}
</style>
