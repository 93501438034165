<template>
  <div class="table-responsive cont-table">
    <b-table
      class="table"
      :items="data"
      :fields="setFields()"
      :tbody-tr-class="rowClass"
    ></b-table>
  </div>
</template>
<script>
export default {
  name: "CompTableGeneral",
  props: {
    positionType: {},
    data: {
      type: Array,
      default() {
        return [
          {
            country_position: null,
            employee_id: null,
            manager: null,
            payment: null,
            regional: null,
            regional_position: null,
            unit: null,
            zone: null,
          },
        ];
      },
    },
    userPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  data() {
    return {
      managerFields: [
        {
          key: "country_position",
          label: "Posición país",
        },
        {
          key: "regional_position",
          label: "Posición Regional",
        },
        {
          key: "regional.id",
          label: "Regional",
        },
        {
          key: "manager.id",
          label: "Gerencia",
        },
        {
          key: "payment",
          label: "Pago",
          formatter: "setPayment",
        },
      ],
      zoneFields: [
        {
          key: "country_position",
          label: "Posición país",
        },
        {
          key: "regional_position",
          label: "Posición Regional",
        },
        {
          key: "manager.id",
          label: "Gerencia",
        },
        {
          key: "zone.id",
          label: "Zona",
        },
        {
          key: "payment",
          label: "Pago",
          formatter: "setPayment",
        },
      ],
      unitFields: [
        {
          key: "country_position",
          label: "Posición país",
        },
        {
          key: "regional_position",
          label: "Posición Regional",
        },
        {
          key: "manager.id",
          label: "Gerencia",
        },
        {
          key: "unit.id",
          label: "Unidad",
        },
        {
          key: "payment",
          label: "Pago",
          formatter: "setPayment",
        },
      ],
    };
  },
  methods: {
    setPayment(value) {
      return (value * 100).toFixed(2) + "%";
    },
    rowClass(item) {
      if (item.employee_id !== this.userPosition) return;
      if (item.employee_id === this.userPosition) return "selected-row ";
    },
    setFields() {
      switch (this.positionType.name) {
        case "Unidad Negocio":
          return this.unitFields;
        case "Zona":
          return this.zoneFields;
        case "Gerencia":
          return this.managerFields;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped></style>
