<script setup>
import { useRouter } from "vue-router/composables";
import {
  formatDateDDMMYYYY,
  formatTimeToAMPM,
  isStillTime,
  isPastDate,
} from "@/helpers/datesHelpers";
import AtomText from "@/components/atoms/AtomText.vue";
import MoleculeCardHeaderBodyFooter from "@/components/molecules/MoleculeCardHeaderBodyFooter.vue";
import AtomMedia from "@/components/atoms/AtomMedia.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";

const router = useRouter();

const props = defineProps({
  events: {
    type: Array,
    required: true,
  },
});

const goToEvent = (stage_id, event_id) => {
  router.push(`/vamosPaLaCalle/actividades/${stage_id}/evento/${event_id}`);
};
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-5">
    <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-6">
      <molecule-card-header-body-footer
        v-for="(event, idx) in events"
        class="tw-w-full tw-place-self-center tw-h-full"
        :key="idx"
        border
        border-color="gray-300"
        rounded
        rounded-size="2.5xl"
      >
        <template #header>
          <atom-text
            tag="h3"
            font="robotoBold"
            text-size-mobile="base"
            text-size-desktop="xl"
            text-alignment="left"
          >
            {{ event.name }}
          </atom-text>
        </template>
        <template #body>
          <div
            class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-end tw-h-full"
          >
            <atom-media
              rounded
              type="image"
              :src="event.resource"
              rectangular
              fill
            />
            <atom-text
              tag="p"
              font="robotoBold"
              text-size-mobile="xs"
              text-size-desktop="sm"
              text-alignment="center"
              text-color="red-350"
            >
              {{
                isStillTime(event.date) && event.active
                  ? "Evento disponible"
                  : "Evento Finalizado"
              }}
            </atom-text>
            <atom-text
              tag="p"
              font="roboto"
              text-size-mobile="sm"
              text-size-desktop="base"
              text-alignment="left"
              class="tw-w-full"
            >
              {{ event.desc }}
            </atom-text>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-w-full">
              <atom-text
                tag="p"
                font="roboto"
                text-size-mobile="xs"
                text-size-desktop="sm"
                text-alignment="left"
                class="tw-w-full"
              >
                Fecha: {{ formatDateDDMMYYYY(event.date) }}
              </atom-text>
              <atom-text
                tag="p"
                font="roboto"
                text-size-mobile="xs"
                text-size-desktop="sm"
                text-alignment="right"
                class="tw-w-full"
              >
                Hora: {{ formatTimeToAMPM(event.start_time) }}
              </atom-text>
            </div>
            <atom-text
              tag="p"
              font="roboto"
              text-size-mobile="xs"
              text-size-desktop="sm"
              text-alignment="left"
              text-color="red-350"
              class="tw-w-full"
            >
              Cupos disponibles: 200
            </atom-text>
          </div>
        </template>
        <template #footer>
          <atom-button
            bg-color="red-350"
            rounded
            class="tw-w-full"
            :disabled="isPastDate(event.date)"
            @button-click="goToEvent(event.street_event_stage_id, event.id)"
          >
            <atom-text
              tag="span"
              font="roboto"
              text-size-mobile="sm"
              text-size-desktop="base"
              text-color="white"
              text-alignment="center"
              class="tw-place-self-center"
            >
              {{
                isStillTime(event.date) && event.active
                  ? "Conoce más"
                  : "No disponible"
              }}
            </atom-text>
          </atom-button>
        </template>
      </molecule-card-header-body-footer>
    </div>
  </div>
</template>

<style scoped></style>
