<template>
  <div class="w-100">
    <div class="col-12 text-center pt-2 mb-2">
      <button type="button" class="btn btn-link btn-exit" @click="closeSesion()">
        Cerrar sesión
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "CompSocial",
  data() {
    return {};
  },
  computed: {
    ...mapState("modCore", ["user", "userDefault"]),
  },
  methods: {
    ...mapActions("modCore", ["actLogout"]),
    closeSesion() {
      this.actLogout();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.btn-exit {
  color: #171717 !important;
  text-decoration: underline;
}
.btn-exit:hover {
  font-weight: bold;
}
</style>
