<script setup>
import { ref, computed, onBeforeMount, getCurrentInstance } from "vue";
import { useNewsStore } from "@/stores/newsStore";

import CompGeneralShowMore from "@/components/general/CompGeneralShowMore.vue";
import SkeletonCardNews from "@/components/news/SkeletonCardNews.vue";
import OrganismCardNonResponsive from "@/components/molecules/MoleculeCardNonResponsive.vue";
import AtomImageRectangularForCard from "@/components/atoms/AtomImageRectangularForCard.vue";
import MoleculeCardTitleAndDescription from "@/components/molecules/MoleculeCardTitleAndDescription.vue";
import AtomCategoryAndBadge from "@/components/molecules/MoleculeCategoryAndBadge.vue";
import AtomKnowMore from "@/components/atoms/AtomKnowMore.vue";
import AtomImageResponsive from "@/components/atoms/AtomImageResponsive.vue";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";
import OrganismCardResponsive from "@/components/molecules/MoleculeCardResponsive.vue";

const newsStore = useNewsStore();
const isLoading = ref(false);
const page = ref(1);
const per_page = ref(8);
const news_charged = ref(0);
const number_of_news = ref(8);
const minimum_news = ref(8);
const { $isMobile: mobile } = getCurrentInstance().proxy;

const showMore = computed(() => {
  return (
    number_of_news.value < newsStore.totalNews ||
    number_of_news.value < minimum_news.value
  );
});

const getOtherNews = async (page_nr, per_page_news) => {
  await newsStore.actGetOtherNews({ page: page_nr, per_page: per_page_news });
  news_charged.value =
    news_charged.value + per_page_news < newsStore.totalNews
      ? news_charged.value + per_page_news
      : newsStore.totalNews;
};

const showMoreNews = () => {
  if (news_charged.value < newsStore.totalNews) {
    page.value++;
    getOtherNews(page.value, per_page.value);
    number_of_news.value += 8;
  } else {
    number_of_news.value = number_of_news.value === 8 ? news_charged.value : 8;
  }
};

onBeforeMount(async () => {
  isLoading.value = true;
  await getOtherNews(page.value, per_page.value);
  isLoading.value = false;
});
</script>
<template>
  <div>
    <section id="thinking_about_you">
      <div
        class="tw-flex tw-gap-x-6 tw-items-center tw-my-5 tw-justify-between md:tw-justify-start"
      >
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-3xl tw-font-robotoBold"
        >
          Pensando en ti
        </p>
        <img
          src="@/assets/img/icons/icon-4.png"
          alt="icon 3"
          class="tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24"
        />
      </div>

      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-6 lg:tw-gap-16"
        v-if="isLoading"
      >
        <div v-for="n in 2" :key="n">
          <skeleton-card-news change />
          <hr class="md:tw-hidden tw-mb-5" />
        </div>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-6 lg:tw-gap-16"
        v-else
      >
        <div
          v-for="(news, index) in newsStore.otherNews.slice(0, 2)"
          :key="index"
        >
          <organism-card-non-responsive>
            <template #image>
              <atom-image-rectangular-for-card
                alt=""
                :src="
                  mobile() && news?.image_mobile
                    ? news?.image_mobile
                    : news?.image
                "
              />
            </template>
            <template #category>
              <atom-category-and-badge :category="news?.post_category?.name" />
            </template>
            <template #titleAndDescription>
              <molecule-card-title-and-description
                :description="news?.description"
                :title="news?.title"
              />
            </template>
            <template #callToAction="{ hover }">
              <div
                class="tw-flex tw-items-center tw-justify-start lg:tw-justify-between tw-gap-3 lg:tw-gap-4"
              >
                <div class="tw-order-1 lg:tw-order-2">
                  <atom-know-more
                    :datalayer-data="{}"
                    :link="news?.call_to_action"
                  />
                </div>
                <div class="tw-order-2 lg:tw-order-1">
                  <comp-social-network-interactions
                    :category="news?.post_category_id"
                    :user_reaction="news?.user_reaction"
                    :amount_comments="Number(news?.amount_comments)"
                    :amount_reactions="Number(news?.amount_reactions)"
                    :image="
                      mobile() && news?.image_mobile
                        ? news?.image_mobile
                        : news?.image
                    "
                    :description="news?.description"
                    :title="news.title"
                    :id="news?.postable_id ? news?.postable_id : news?.id"
                    :item="news"
                    :hover="hover"
                    category-for-datalayer="Home"
                    action-for-datalayer="Otras noticias"
                    @updatedComment="getOtherNews(1, news_charged)"
                    @updatedReaction="getOtherNews(1, news_charged)"
                  />
                </div>
              </div>
            </template>
          </organism-card-non-responsive>
        </div>
      </div>
    </section>

    <hr class="tw-hidden md:tw-block md:tw-my-6 lg:tw-my-10" />

    <section id="other_news" class="tw-mt-5">
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8"
        v-if="isLoading"
      >
        <div v-for="_n in per_page" :key="_n">
          <skeleton-card-news />
          <hr class="md:tw-hidden" />
        </div>
      </div>
      <div
        v-else
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4 md:tw-gap-6 lg:tw-gap-8"
      >
        <div
          v-for="(news, idx) in newsStore.otherNews.slice(2, number_of_news)"
          :key="idx"
        >
          <organism-card-responsive>
            <template #image>
              <atom-image-responsive
                alt=""
                :src="
                  mobile() && news?.image_mobile
                    ? news?.image_mobile
                    : news?.image
                "
              />
            </template>
            <template #category>
              <atom-category-and-badge :category="news?.post_category?.name" />
            </template>
            <template #titleAndDescription>
              <molecule-card-title-and-description
                :description="news?.description"
                :title="news?.title"
              />
            </template>
            <template #callToAction="{ hover }">
              <div
                class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 lg:tw-justify-between"
              >
                <comp-social-network-interactions
                  :category="news?.post_category_id"
                  :user_reaction="news?.user_reaction"
                  :amount_comments="Number(news?.amount_comments)"
                  :amount_reactions="Number(news?.amount_reactions)"
                  :image="
                    mobile() && news?.image_mobile
                      ? news?.image_mobile
                      : news?.image
                  "
                  :description="news?.description"
                  :title="news.title"
                  :id="news?.postable_id ? news?.postable_id : news?.id"
                  :item="news"
                  :hover="hover"
                  category-for-datalayer="Home"
                  action-for-datalayer="Otras noticias"
                  @updatedComment="getOtherNews(1, news_charged)"
                  @updatedReaction="getOtherNews(1, news_charged)"
                />
                <atom-know-more
                  :datalayer-data="{}"
                  :link="news?.call_to_action"
                />
              </div>
            </template>
          </organism-card-responsive>
          <hr class="md:tw-hidden" />
        </div>
      </div>
    </section>

    <CompGeneralShowMore
      v-if="
        newsStore.totalNews > news_charged || minimum_news < newsStore.totalNews
      "
      :showMore="showMore"
      show-more-text="Mostrar más contenido"
      show-less-text="Mostrar menos contenido"
      @showMoreContent="showMoreNews"
    />
  </div>
</template>
