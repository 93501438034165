var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-md-4 px-0 px-md-3 px-lg-5"},[_c('div',{staticClass:"pb-3 content"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"cont-bar mx-md-auto mt-4 mb-2 mb-md-4 px-4 px-md-5 py-2"},[_c('div',{staticClass:"d-flex justify-content-between mb-0"},[_c('span',{staticClass:"font-weight-bold text-bar-title"},[_vm._v("INSIGNIAS")]),_c('span',{staticClass:"text-bar-gamification pt-md-3 pt-2 align-bottom"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/gamification/moneda.png"),"alt":""}}),_vm._v(_vm._s(_vm.cantCompleted)+" de "+_vm._s(_vm.cantCoins))])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({ width: this.Percent + '%' }),attrs:{"role":"progressbar","aria-valuemin":"0","aria-valuemax":"10"}})])])]),(_vm.isLoading)?_c('div',{staticClass:"row pt-0 pt-md-3 pb-5"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row cont-cards margin-cards",staticStyle:{"padding-bottom":"60px"}},_vm._l((_vm.missions),function(mission){return _c('div',{key:mission.id,staticClass:"col-4 col-lg-3 col-xl-2 mt-4"},[_c('div',{on:{"click":function($event){return _vm.modalItem(mission, mission.id)}}},[_c('router-link',{attrs:{"to":""}},[(
                    mission.status != null &&
                    mission.status.progress >= mission.status.required
                  )?_c('div',{staticClass:"cont-gamification p-0 py-3 text-center"},[_c('img',{attrs:{"src":_vm.uriResources + '/storage/images/' + mission.image,"alt":""}}),_c('p',{staticClass:"desc-card mb-0 mt-2 mt-md-3 d-flex align-items-center justify-content-center px-2"},[_vm._v(" "+_vm._s(mission.name)+" ")])]):_c('div',{staticClass:"cont-gamification p-0 py-3 text-center"},[_c('img',{attrs:{"src":require("@/assets/img/gamification/tapa-no-desploqueada.png"),"alt":""}}),_c('p',{staticClass:"desc-card d-flex align-items-center justify-content-center px-2 mb-0 mt-2 mt-md-3"},[_vm._v(" "+_vm._s(mission.name)+" ")])])])],1)])}),0)]),_c('b-modal',{ref:"modal-item",staticClass:"cont-modal",attrs:{"id":"modal-sell","centered":"","hide-header":"","hide-footer":"","size":"sm","no-close-on-esc":"","role":"dialog"}},[_c('router-link',{attrs:{"to":""}},[_c('img',{staticClass:"img-close",attrs:{"src":require("@/assets/img/icons/x.png"),"alt":""},on:{"click":function($event){return _vm.closeModal()}}})]),(
            this.gami.status != null &&
            this.gami.status.progress >= this.gami.status.required
          )?_c('div',{staticClass:"d-flex d-row justify-content-center mt-4"},[_c('img',{staticClass:"img-gami",attrs:{"src":_vm.uriResources + '/storage/images/' + this.gami.image,"width":"100%","alt":"","srcset":""}})]):_c('div',{staticClass:"d-flex d-row justify-content-center mt-4"},[_c('img',{staticClass:"img-gami tw-w-full",attrs:{"src":require("@/assets/img/gamification/tapa-no-desploqueada.png"),"alt":"","srcset":""}})]),_c('div',{staticClass:"px-3 px-md-0 mt-4"},[_c('div',{staticClass:"cont-text d-flex h-100 flex-column justify-content-center text-center mb-3 mb-md-0"},[_c('p',{staticClass:"mx-3 mb-3 text-title"},[_vm._v(" "+_vm._s(this.gami.name)+" ")]),_c('p',{staticClass:"mx-3 mb-0 text-desc"},[_vm._v(" "+_vm._s(this.gami.description)+" ")])]),_c('div',{staticClass:"d-flex d-row justify-content-center mt-3 mb-4 mb-md-4"},[_c('img',{staticClass:"img-symbol",attrs:{"src":require("@/assets/img/gamification/symbol-1.png"),"alt":""}})])])],1),_vm._m(2)],1)]),_c('ScrollToTop')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-row justify-content-end img-patron2"},[_c('img',{attrs:{"src":require("@/assets/img/gamification/patron2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"text-center mt-3"},[_c('img',{attrs:{"src":require("../../assets/img/icons/loading.gif"),"alt":"loading"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-row justify-content-start img-patron1"},[_c('img',{attrs:{"src":require("@/assets/img/gamification/patron1.png"),"alt":""}})])
}]

export { render, staticRenderFns }