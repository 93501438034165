<script setup>
import {
  ref,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watch,
  computed,
} from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { storeToRefs } from "pinia";
import { differenceInDays } from "@/helpers/datesHelpers";

const { $calendar } = getCurrentInstance().proxy;

const emit = defineEmits([
  "setDaySelected",
  "setFromDate",
  "setToDate",
  "isHighSeason",
]);

const reservationsStore = useReservationsStore();
const { bookingTempFromDate, bookingTempToDate } =
  storeToRefs(reservationsStore);
const innerWidth = ref(0);
const cal = ref(new $calendar(null, null, "es-ES"));
const fromDate = ref(null);
const toDate = ref(null);
const today = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const minimumDate = ref(null);
const minimumDateymd = ref(null);
const selectedDay = ref(null);
const calendar = ref(null);
const nextDayOppositeSeason = ref(null);
const isHighSeason = ref(false);

const props = defineProps({
  dateRangeSelected: {
    type: Object,
    default: null,
  },
  onlyWorkDays: {
    type: Boolean,
    default: false,
  },
  deleteSelectedDay: {
    type: Boolean,
    default: false,
  },
});

const cssVars = computed(() => {
  return {
    "--calendar-width": innerWidth.value + "px",
    "--columns-number": 7,
    "--weekend-visibility": props.onlyWorkDays ? "hidden" : "visible",
  };
});

const calMonthName = computed(() => {
  return cal.value.month.name;
});

const calYear = computed(() => {
  return cal.value.year;
});

const selectDay = (day) => {
  const isSameDay =
    selectedDay.value &&
    selectedDay.value.Date.getTime() === day.Date.getTime();

  selectedDay.value = isSameDay ? null : day;
  emit("setDaySelected", isSameDay ? null : day);
};

const selectDates = (day) => {
  if (fromDate.value === null && lastDayBeforeOppositeSeason(day)) {
    return;
  }

  if (
    fromDate.value !== null &&
    toDate.value !== null &&
    lastDayBeforeOppositeSeason(day)
  ) {
    return;
  }

  // Obtén la fecha actual
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Asegúrate de que la hora sea medianoche para una comparación justa

  // Comprueba si el día seleccionado es anterior a la fecha actual
  if (day.Date.getTime() < currentDate.getTime()) {
    // Si es así, simplemente regresa y no hagas nada más
    return;
  }

  if (
    fromDate.value === null ||
    (fromDate.value !== null && toDate.value !== null)
  ) {
    findNextOppositeSeasonDay(day, dayIsHighSeason(day.Dateymd));
  }

  if (
    fromDate.value === null ||
    day.Date.getTime() < fromDate.value.Date.getTime() ||
    toDate.value !== null
  ) {
    fromDate.value = day;
    emit("setFromDate", day);
    toDate.value = null;
  } else if (toDate.value === null) {
    // Calcula la diferencia en días entre fromDate y day
    const diffInDays = differenceInDays(fromDate.value.Date, day.Date);

    // Si la diferencia es mayor a 5, retorna de la función
    if (diffInDays >= 5) {
      return;
    }

    if (day.Date.getTime() !== fromDate.value.Date.getTime()) {
      toDate.value = day;
      emit("setToDate", day);
    } else {
      fromDate.value = null;
    }
    nextDayOppositeSeason.value = null;
  }

  // Stores the selected dates in the store
  bookingTempFromDate.value = fromDate.value;
  bookingTempToDate.value = toDate.value;

  if (fromDate.value && toDate.value) {
    isHighSeason.value =
      dayIsHighSeason(fromDate.value.Dateymd) &&
      dayIsHighSeason(toDate.value.Dateymd);
    emit("isHighSeason", isHighSeason.value);
  }
};

const betweenDates = (date) => {
  return (
    fromDate.value !== null &&
    toDate.value !== null &&
    date >= fromDate.value.Date &&
    date <= toDate.value.Date
  );
};

const outerMonth = (date) => {
  return date.monthNumber !== cal.value.month.number;
};

const passedDate = (day) => {
  return day.Date.getTime() < minimumDate.value.getTime();
};

const getMinimumDate = () => {
  const minDate = new Date(today.value);
  const daysToAdd = props.onlyWorkDays ? 2 : 3;
  minDate.setDate(minDate.getDate() + daysToAdd);

  minimumDate.value = minDate;
  minimumDateymd.value = minimumDate.value.toISOString().split("T")[0];
};

const dayIsHighSeason = (day) => {
  return reservationsStore.bookingHighSeasonsDays.includes(day);
};

const lastDayBeforeOppositeSeason = (day) => {
  let selectedDay = new Date(+day.Date);
  let nextDay = new Date(+day.Date);
  nextDay.setDate(nextDay.getDate() + 1);

  if (
    dayIsHighSeason(selectedDay.toISOString().split("T")[0]) !==
    dayIsHighSeason(nextDay.toISOString().split("T")[0])
  ) {
    return true;
  }
};

const findNextOppositeSeasonDay = (day, isHighSeason) => {
  let tempDay = new Date(+day.Date);
  let nextDayOppositeSeasonTemp = null;

  while (tempDay <= cal.value.month.last_date) {
    tempDay.setDate(tempDay.getDate() + 1);
    if (dayIsHighSeason(tempDay.toISOString().split("T")[0]) !== isHighSeason) {
      nextDayOppositeSeasonTemp = tempDay;
      break;
    }
  }

  nextDayOppositeSeason.value = nextDayOppositeSeasonTemp;
};

const deleteDates = () => {
  if (props.onlyWorkDays) {
    selectedDay.value = null;
  } else {
    fromDate.value = null;
    toDate.value = null;
    nextDayOppositeSeason.value = null;
  }
  emit("deleteDates");
};

const classesDay = (day) => {
  return {
    workday: day.workday,
    weekend: !day.workday,
    selected_day:
      selectedDay.value !== null &&
      day.Date.getTime() === selectedDay.value.Date.getTime(),
    selected_dates: betweenDates(day.Date),
    selected_from_date:
      fromDate.value !== null &&
      day.Date.getTime() === fromDate.value.Date.getTime(),
    selected_to_date:
      toDate.value !== null &&
      day.Date.getTime() === toDate.value.Date.getTime(),
    selectable_dates:
      !passedDate(day) &&
      fromDate.value !== null &&
      toDate.value === null &&
      day.Date >= fromDate.value.Date &&
      !(
        nextDayOppositeSeason.value && day.Date >= nextDayOppositeSeason.value
      ) &&
      differenceInDays(fromDate.value.Date, day.Date) < 5,
    days_grid_not_selected:
      // outerMonth(day) ||
      (props.onlyWorkDays && !day.workday) ||
      passedDate(day) ||
      (fromDate.value !== null && day.Date < fromDate.value.Date) ||
      (toDate.value !== null && day.Date > toDate.value.Date) ||
      day.Date.getTime() < minimumDate.value.getTime(),
    other_month_day: outerMonth(day),
    next_month_day:
      fromDate.value !== null &&
      toDate.value === null &&
      outerMonth(day) &&
      day.Date >= today.value,
    passed_date: passedDate(day),
    high_season_day: dayIsHighSeason(day.Dateymd),
    unselectable_date:
      (nextDayOppositeSeason.value &&
        day.Date >= nextDayOppositeSeason.value) ||
      (fromDate.value && differenceInDays(fromDate.value.Date, day.Date) >= 5),
    last_day_of_season:
      lastDayBeforeOppositeSeason(day) && fromDate.value === null,
  };
};

const classesDayInside = () => {
  return {
    hover_dates: fromDate.value === null && toDate.value === null,
  };
};

const fetchHighSeasonsDays = async () => {
  const start_date =
    cal.value.getFirstVisibleDay()["Dateymd"] < minimumDateymd.value
      ? minimumDateymd.value
      : cal.value.getFirstVisibleDay()["Dateymd"];

  const end_date = cal.value.getLastVisibleDay()["Dateymd"];

  await reservationsStore.actGetBookingHighSeasonsDays({
    start_date: start_date,
    end_date: end_date,
  });
};

const goToPrevMonth = async () => {
  cal.value.goToPreviousMonth();
  await fetchHighSeasonsDays();
};

const goToNextMonth = async () => {
  cal.value.goToNextMonth();
  await fetchHighSeasonsDays();
};

watch(
  () => props.dateRangeSelected,
  () => {
    deleteDates();
  }
);

onBeforeMount(async () => {
  getMinimumDate();
  await fetchHighSeasonsDays();
});

onMounted(() => {
  const interval = setInterval(() => {
    if (calendar.value) {
      innerWidth.value = calendar.value.offsetWidth;
      clearInterval(interval);
    }
  }, 0);
});
</script>
<template>
  <div ref="calendar" :style="cssVars" class="calendar-general">
    <div class="calendar">
      <div class="calendar-header tw-mb-1">
        <div class="calendar-title">
          <p
            class="tw-font-roboto tw-uppercase tw-text-base md:tw-text-2xl tw-m-0"
          >
            {{ calMonthName }} {{ calYear }}
          </p>
        </div>
        <div class="calendar-prev_next">
          <div
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click="goToPrevMonth()"
          >
            <i
              class="icon-flecha-izq tw-text-sm md:tw-text-2xl tw-text-red-350"
            ></i>
          </div>
          <div
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click="goToNextMonth()"
          >
            <i
              class="icon-flecha-der tw-text-sm md:tw-text-2xl tw-text-red-350"
            ></i>
          </div>
        </div>
      </div>
      <div class="weekdays_grid">
        <div
          v-for="(day, idx) in cal.weekDaysShort"
          :key="idx"
          :class="{ weekend: day.number === 0 || day.number === 6 }"
          class="weekdays_grid_item"
        >
          <p class="tw-m-0">{{ day.day }}.</p>
        </div>
      </div>
      <div class="days_grid_container">
        <div class="days_grid">
          <div
            v-for="(day, idx) in cal.monthDaysList"
            :key="idx"
            :class="classesDay(day)"
            class="days_grid_item"
            @click="onlyWorkDays ? selectDay(day) : selectDates(day)"
          >
            <div class="days_grid_item_over"></div>
            <div :class="classesDayInside()" class="days_grid_item_inside">
              <p class="tw-text-xs lg:tw-text-sm">
                {{ day && day.date }}
              </p>
            </div>
            <div class="days_grid_item_bg"></div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-justify-between tw-gap-3 tw-mt-2">
        <div class="tw-flex tw-justify-between tw-gap-3">
          <div class="tw-flex tw-items-center tw-gap-2">
            <i class="icon-circle-select md:tw-text-xl tw-text-yellow-200"></i>
            <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
              Temporada alta
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <i class="icon-circle-select md:tw-text-xl"></i>
            <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
              Temporada baja
            </p>
          </div>
        </div>
        <p
          class="tw-text-center tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0 tw-underline"
          @click="deleteDates"
        >
          Borrar fechas
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.calendar-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}

.calendar-title {
  display: flex;
  justify-content: left;
}

.calendar-prev_next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.weekdays_grid {
  display: grid;
  grid-template-columns: repeat(var(--columns-number), 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.weekdays_grid_item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  align-self: center;
  justify-self: center;
}

.days_grid_container {
  width: 100%;
  height: 100%;
}

.days_grid {
  --columns: var(--columns-number);
  --row-size: calc((var(--calendar-width)) / var(--columns));
  display: grid;
  width: 100%;
  max-width: var(--calendar-width);
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-auto-rows: var(--row-size);
  grid-row-gap: var(--gutter);
  grid-column-gap: 0;
}

.days_grid_item {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.days_grid_item_inside {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #000;
  z-index: 1;
}

.days_grid_item_inside p {
  margin: 0;
}

.days_grid_item_over {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 2;
}

.days_grid_item_bg {
  width: 100%;
  height: 80%;
  background-color: white;
  position: absolute;
  z-index: 0;
}

.selected_from_date {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  position: relative;
}

.selected_from_date > .days_grid_item_inside {
  border: 1px solid #00bf79;
  background: #00bf79;
  color: white;
}

.selected_from_date.high_season_day > .days_grid_item_inside {
  border: 1px solid #ffc12f;
  background: #ffc12f;
  color: white;
}

.selected_from_date > .days_grid_item_bg {
  width: 85%;
  position: absolute;
  right: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.selected_to_date {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
}

.selected_to_date > .days_grid_item_inside {
  border: 1px solid #00bf79;
  background: #00bf79;
  color: white;
}

.selected_to_date.high_season_day > .days_grid_item_inside {
  border: 1px solid #ffc12f;
  background: #ffc12f;
  color: white;
}

.selected_to_date > .days_grid_item_bg {
  width: 85%;
  position: absolute;
  left: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.selected_dates > .days_grid_item_inside {
  border: none !important;
}

.selected_dates:hover:not(.selected_from_date):not(.selected_to_date)
  > .days_grid_item_inside {
  background: none;
  color: black;
  border: 1px solid #00bf79;
}

.days_grid_item.selected_dates > .days_grid_item_bg {
  background: #00bf79;
  opacity: 0.2;
}

.days_grid_item.selected_dates.high_season_day > .days_grid_item_bg {
  background: #ffc12f;
  opacity: 0.2;
}

.days_grid_not_selected {
  opacity: 0.3;
}

.other_month_day {
  opacity: 0.3;
}

.selected_dates.other_month_day,
.selected_from_date.other_month_day,
.selected_to_date.other_month_day {
  opacity: 1;
}

.high_season_day .days_grid_item_inside {
  border: 1px solid #ffc12f;
}

.unselectable_date,
.passed_date {
  opacity: 0.3;
  pointer-events: none;
}

.last_day_of_season > .days_grid_item_inside p {
  text-decoration: line-through;
}

.days_grid_not_selected > .days_grid_item_inside p {
  text-decoration: none;
}

.unselectable_date > .days_grid_item_inside p {
  text-decoration: none;
}

.selectable_dates.next_month_day {
  opacity: 1;
}
</style>
