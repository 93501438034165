var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"tw-container-general tw-px-3 md:tw-px-0 my-2 mt-md-3 mb-md-5"},[_c('div',{staticClass:"tw-rounded-lg tw-rounded-lg-20 tw-shadow tw-shadow-md-lg overflow-hidden p-5"},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(1),_c('div',[_c('p',{staticClass:"m-0 tw-font-robotoBold tw-text-sm line-height-16 text-underline tw-cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'gamificationMalts' })}}},[_c('u',[_vm._v("Regresar")])])])]),_c('div',[_vm._l((_vm.datospaginados),function(transaction){return _c('div',{key:transaction.id},[_c('div',{staticClass:"tw-rounded-md tw-shadow-sm px-md-5 py-md-3 p-4 my-4 transactions-general"},[_c('div',{},[_c('p',{staticClass:"m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-gray-500"},[_vm._v(" "+_vm._s(transaction.created_at.slice(0, 10))+" ")])]),_c('div',{staticClass:"transactions-detail"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"m-0 tw-font-roboto tw-text-sm line-height-20"},[_c('span',{staticClass:"tw-font-robotoBold"},[_vm._v(_vm._s(transaction.malts)+" Maltas = ")]),_vm._v(" "+_vm._s(transaction.points_tapit)+" Puntos Tapit ")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"m-0 tw-font-roboto tw-text-sm line-height-20"},[_c('span',{staticClass:"tw-font-robotoBold"},[_vm._v("Transferencia Tapit: ")]),_vm._v(_vm._s(_vm.user.tapit_email)+" ")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"m-0 tw-font-roboto tw-text-sm line-height-20"},[_c('span',{staticClass:"tw-font-robotoBold tw-gree"},[_vm._v("Transacción: ")]),_c('span',{class:transaction.status === 'SUCCESS'
                        ? 'tw-text-green-400'
                        : 'tw-text-red-350'},[_vm._v(_vm._s(transaction.status === "SUCCESS" ? "Aprobada" : "Rechazada"))])])])])])])}),_c('div',[_c('nav',[_c('ul',{staticClass:"d-flex list-unstyled justify-content-between align-items-center mx-2 p-0"},[_c('li',{staticClass:"tw-cursor-pointer",class:_vm.pagActual > 1 ? '' : 'invisible',on:{"click":function($event){return _vm.getPreviousPage()}}},[_vm._m(2)]),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},_vm._l((_vm.totalPaginas()),function(pagina,index){return _c('li',{key:index,staticClass:"mx-1 d-flex",class:pagina === _vm.pagActual ? 'pagactive' : 'paginactive',on:{"click":function($event){return _vm.getDataPagina(pagina)}}},[_c('i',{staticClass:"icon-circle tw-cursor-pointer",class:pagina === _vm.pagActual
                        ? 'tw-text-xs tw-text-red-400'
                        : 'tw-text-xxxs tw-text-gray-320'})])}),0),_c('li',{staticClass:"tw-cursor-pointer",class:_vm.pagActual < _vm.totalPaginas() ? '' : 'invisible',on:{"click":function($event){return _vm.getNextPage()}}},[_vm._m(3)])])])])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-container-general d-flex flex-md-row flex-column px-0 mt-2 mt-md-4"},[_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"d-none d-sm-none d-md-flex"},[_c('p',{staticClass:"tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"},[_vm._v(" La Maltería ")])]),_c('div',{staticClass:"d-none d-md-flex"},[_c('div',{staticClass:"px-3 p-md-0"},[_c('p',{staticClass:"tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"},[_vm._v(" Completa misiones, gana puntos y redime premios ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"m-0 tw-font-tolyerBold fs-30 line-height-24 line-height-md-30"},[_vm._v(" Historial de transacciones ")]),_c('p',{staticClass:"m-0 tw-font-roboto tw-text-sm line-height-md-20 tw-text-gray-600 mt-2"},[_vm._v(" Consulta las transacciones que has realizado ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"m-0 tw-font-robotoBold tw-text-sm line-height-20 tw-text-red-400"},[_c('u',[_vm._v("Anterior")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"m-0 tw-font-robotoBold tw-text-sm line-height-20 tw-text-red-400"},[_c('u',[_vm._v("Siguiente")])])
}]

export { render, staticRenderFns }