var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"worldbrands md:tw-ml-20 tw-min-h-screen"},[_c('div',{staticClass:"tw-container-general"},[_c('SecondaryNavbar',{staticClass:"tw-mb-5 md:tw-mb-0",attrs:{"title":'MUNDO ' + _vm.itemBrand.name}})],1),_c('div',{staticClass:"tw-container-general tw-mb-8 tw-px-4"},[(_vm.itemBrand.banner)?_c('Banner',{attrs:{"bannerId":_vm.itemBrand.banner.id,"gradient":"none"}}):_vm._e(),_c('div',{staticClass:"tw-mt-16 md:tw-mt-20"},[_c('div',{staticClass:"tw-max-w-3xl tw-mx-auto"},[(_vm.itemBrand && _vm.itemBrand.sub_brands)?_c('div',{staticClass:"tw-flex tw-justify-around tw-text-center"},_vm._l((_vm.itemBrand.sub_brands),function(item,index){return _c('div',{key:index},[_c('img',{staticClass:"tw-w-14 tw-h-14 md:tw-w-20 md:tw-h-20 tw-bg-red-50 tw-object-cover tw-rounded-full tw-border-2 tw-border-solid tw-cursor-pointer hover:tw-shadow-md",class:_vm.sub_brand === item.id
                  ? ' tw-border-yellow-300'
                  : 'tw-border-white',attrs:{"src":_vm.uriResources + '/' + item.img,"alt":item.name},on:{"click":function($event){return _vm.selectedSubBrand(item.id, item.name)}}}),_c('p',{staticClass:"tw-text-xs tw-text-gray-400 tw-mt-2"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e()])]),(_vm.articlesBrands && _vm.articlesBrands.subBrand)?_c('div',[_c('div',{staticClass:"tw-text-center tw-my-3"},[_c('h2',{staticClass:"tw-text-red-400 tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"},[_vm._v(" "+_vm._s(_vm.articlesBrands.subBrand.name)+" ")]),_c('p',{staticClass:"tw-text-base tw-text-gray-600"},[_vm._v(" "+_vm._s(_vm.articlesBrands.subBrand.description)+" ")])]),_c('div',{staticClass:"tw-text-center tw-mb-5"},[_c('h5',{staticClass:"tw-text-red-400 tw-mb-3 tw-font-robotoBold tw-text-sm md:tw-text-lg"},[_vm._v(" Síguenos en: ")]),_c('div',{class:_vm.articlesBrands.subBrand.link_facebook !== null
              ? 'tw-block'
              : 'tw-hidden'},[_c('a',{attrs:{"href":_vm.articlesBrands.subBrand.link_facebook,"target":"_blank"},on:{"click":function($event){return _vm.pushDataLayer(
                _vm.itemBrand.name,
                _vm.articlesBrands.subBrand.name,
                'facebook'
              )}}},[_c('img',{staticClass:"tw-rounded-full tw-mx-3",attrs:{"src":require("@/assets/img/icons/facebook.svg"),"width":"40px","height":"40px","alt":"facebook"}})]),_c('a',{attrs:{"href":_vm.articlesBrands.subBrand.link_instagram,"target":"_blank"},on:{"click":function($event){return _vm.pushDataLayer(
                _vm.itemBrand.name,
                _vm.articlesBrands.subBrand.name,
                'instagram'
              )}}},[_c('img',{staticClass:"tw-rounded-full tw-mx-3",attrs:{"src":require("@/assets/img/icons/instagram.svg"),"width":"40px","height":"40px","alt":"instagram"}})]),_c('a',{attrs:{"href":_vm.articlesBrands.subBrand.link_twitter,"target":"_blank"},on:{"click":function($event){return _vm.pushDataLayer(
                _vm.itemBrand.name,
                _vm.articlesBrands.subBrand.name,
                'twitter'
              )}}},[_c('img',{staticClass:"tw-rounded-full tw-mx-3",attrs:{"src":require("@/assets/img/icons/twitter.svg"),"width":"40px","height":"40px","alt":"twitter"}})])])]),_vm._l((_vm.content),function(item){return _vm._t("default",function(){return [(item.type.name === 'Imagen')?_c('CompArticleImage',{attrs:{"content":_vm.setImageUrl(item.content),"category":"Universo Cervecero","subcategory":"Mundo de Marcas","brand":_vm.brandName,"variant":_vm.articlesBrands.subBrand.name,"videoname":""}}):_vm._e(),(item.type.name === 'Cita')?_c('CompArticleQuote',{attrs:{"content":item.content}}):_vm._e(),(item.type.name === 'Texto')?_c('CompArticleText',{attrs:{"content":item.content}}):_vm._e(),(item.type.name === 'Video')?_c('CompQualificationVideo',{attrs:{"id":'video' + item.id,"idVideo":item.id,"urlVideo":_vm.uriResources + '/' + item.content,"title":item.name,"category":"Universo Cervecero","subcategory":"Mundo de Marcas","brand":_vm.brandName,"variant":_vm.articlesBrands.subBrand.name,"videoname":""}}):_vm._e(),(item.type.name === 'Infografia')?_c('CompArticleInfographic',{attrs:{"content":_vm.setImageUrl(item.content),"category":"Universo Cervecero","subcategory":"Mundo de Marcas","brand":_vm.brandName,"variant":_vm.articlesBrands.subBrand.name,"videoname":""}}):_vm._e(),(item.type.name === 'Carrusel')?_c('CompArticleImageCarrousel',{attrs:{"content":item.cards}}):_vm._e(),(item.type.name === 'Grupo')?_c('CompArticleCardContainer',{attrs:{"content":item.cards}}):_vm._e(),(item.type.name === 'Trivia')?_c('Trivia',{key:'trivia-' + item.id,attrs:{"trivia":item.trivia,"subbrand":_vm.articlesBrands.subBrand.name}}):_vm._e()]})})],2):_vm._e()],1),_c('div',{on:{"~change":function($event){return _vm.selectedSubBrand.apply(null, arguments)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }