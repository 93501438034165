<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  tag: {
    type: String,
    default: "p",
    validator: (value) =>
      ["h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "label"].includes(
        value
      ),
  },
  for: {
    type: String,
    default: null,
  },
  textColor: {
    type: String,
    default: "black",
  },
  textSizeMobile: {
    type: String,
    default: "xs",
  },
  textSizeDesktop: {
    type: String,
    default: "base",
  },
  textAlignment: {
    type: String,
    default: "left",
  },
  margin: {
    type: String,
    default: "m-0",
  },
  font: {
    type: String,
    default: "roboto",
  },
  underline: {
    type: Boolean,
    default: false,
  },
  leading: {
    type: String,
    default: null,
  },
});

// Computar clases dinámicas
const computedClass = computed(() => {
  return [
    props.leading ? `tw-leading-${props.leading}` : "",
    props.underline ? "tw-underline" : "",
    `tw-font-${props.font}`,
    `tw-text-${props.textColor}`,
    `tw-text-${props.textSizeMobile}`,
    `lg:tw-text-${props.textSizeDesktop}`,
    `tw-text-${props.textAlignment}`,
    `tw-${props.margin}`,
  ].filter(Boolean);
});

// Computar atributos dinámicos como `for`
const dynamicAttrs = computed(() => {
  return props.tag === "label" && props.for ? { for: props.for } : {};
});
</script>

<template>
  <component :is="props.tag" v-bind="dynamicAttrs" :class="computedClass">
    <slot />
  </component>
</template>

<style scoped></style>
