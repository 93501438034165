<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const userName = computed(() =>
  user.value?.nickname
    ? user.value.nickname.toLowerCase()
    : user?.value?.name?.split(" ")[0].toLowerCase()
);
</script>

<template>
  <div class="tw-py-1 tw-pl-2 tw-bg-red-50 tw-flex tw-justify-between">
    <div>
      <p
        class="tw-text-sm tw-text-red-400 tw-m-0 tw-font-robotoBold md:tw-hidden"
      >
        Embajadores
      </p>
    </div>
    <div class="tw-flex tw-items-center tw-space-x-1">
      <p
        class="tw-text-sm tw-font-robotoBold tw-text-black tw-m-0 md:tw-text-base tw-capitalize"
      >
        ¡Hola, {{ userName }}!
      </p>
      <div class="tw-w-3 tw-h-3 tw-rounded-full tw-bg-red-400" />
      <div class="tw-w-4 md:tw-w-10 tw-h-3 tw-bg-yellow-400" />
    </div>
  </div>
</template>
