<script setup>
import { computed } from "vue";

const props = defineProps({
  text: {
    type: String || null,
  },
  active: {
    type: Boolean,
    default: false,
  },
  roundedSize: {
    type: String,
    default: "tw-rounded-2xl lg:tw-rounded-3xl",
  },
  color: {
    type: String,
    default: "white",
  },
  textColor: {
    type: String,
    default: "black",
  },
  borderColor: {
    type: String,
    default: "black",
  },
});

const isActive = computed(() => props.active);
const backgroundColor = computed(() =>
  props.active ? `bg-${props.textColor}` : `bg-${props.color}`
);
const textColor = computed(() =>
  props.active ? `text-${props.color}` : `text-${props.textColor}`
);
</script>

<template>
  <button
    :class="[
      props.roundedSize,
      `tw-${backgroundColor}`,
      `tw-${textColor}`,
      `tw-border-${props.borderColor}`,
      { active: isActive },
    ]"
    class="button"
  >
    {{ props.text ? props.text : "Button" }}
  </button>
</template>

<style scoped lang="postcss">
.button {
  @apply tw-border tw-font-roboto tw-text-xs lg:tw-text-sm tw-py-2 tw-px-3 tw-text-center tw-cursor-pointer tw-opacity-100;

  &:not(.active):hover {
    @apply tw-opacity-70;
  }

  &:disabled {
    @apply tw-bg-gray-200 tw-cursor-not-allowed tw-border-none;
  }
}
</style>
