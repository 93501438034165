<template>
  <div class="tw-container-general">
    <div class="container">
      <div>
        <p class="title textColorPrimary mt-4 mt-md-3 text-center text-md-left">
          Nuestras plataformas
        </p>
      </div>
      <div class="row pt-0 pt-md-3 pb-5" v-if="isLoading">
        <div class="col-12">
          <div class="text-center mt-3">
            <img src="../../assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
      </div>
      <div
        v-if="platformsUser.length === 0 && !isLoading"
        class="cont-null text-center d-flex align-items-center justify-content-center"
      >
        ¡Ups! En este momento no hay contenido
      </div>
      <div v-else class="row pt-0 pt-md-3 pb-5">
        <div
          class="col-md-6 col-lg-4"
          v-for="item in platformsUser"
          :key="item.id"
          @click="pushDataLayer(item.title)"
        >
          <a :href="item.link" target="_blank">
            <div class="divider"></div>
            <div
              class="card border-0 px-2 px-md-0 pt-1 pb-2 py-sm-2 flex-row flex-md-column"
            >
              <div class="ml-3 ml-md-0 pt-2">
                <img
                  class="item-img"
                  loading="lazy"
                  :src="uriResources + '/' + item.image"
                  :alt="item.name"
                />
              </div>
              <div class="card-body w-100 px-2 px-md-2 pt-2 pb-0 py-md-3">
                <h5
                  class="textColorPrimary tw-font-robotoBold tw-text-sm md:tw-text-lg"
                >
                  {{ item.title }}
                </h5>
                <div class>
                  <p class="description textSubTitle">{{ item.description }}</p>
                </div>
              </div>
              <div class="d-block d-md-none h-100 my-auto">
                <span class="icon-flecha-der icon-sm icon-primary"></span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

export default {
  name: "PlatformsView",
  data() {
    return {
      uriResources: config.beerOfficeURL,
      isLoading: false,
      platformsUser: [],
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Ambassadors",
          link: "/ambassadors",
        },
        {
          id: 3,
          name: "Modulo People",
          link: "/ambassadors/platforms",
          active: true,
        },
      ],
    };
  },
  created() {
    this.isLoading = true;

    this.actGetPeoplePlatforms()
      .then(() => {})
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        if (this.user.core_vp.name === "People") {
          this.platformsUser = this.platforms;
        } else {
          this.platformsUser = this.platforms.filter((e) => {
            return e.for_people === 0;
          });
        }
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modPeople", ["platforms"]),
  },
  methods: {
    ...mapActions("modPeople", ["actGetPeoplePlatforms"]),
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-TBO",
        category: "Somos Embajadores",
        SubCategory: "Nuestras Plataformas",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>
<style scoped>
.nav-link {
  color: #bababa;
  text-transform: uppercase;
}
.nav-link.active {
  color: #d82b2b;
  border-bottom: 3px solid #d82b2b;
}
.navbar-nav {
  background-color: #f7f7f7;
  height: 40px;
}
.card .item-img {
  height: 80px;
  width: 80px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  object-fit: cover;
}

.title {
  font: normal normal bold 20px Roboto;
}
.card {
  border: 0;
  /* align-items: center; */
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  color: #343434;
}

.description {
  font: normal normal normal 14px Roboto;
  letter-spacing: 0;
  opacity: 1;
}
.points {
  color: #bababa;
  text-align: left;
  font: normal normal normal 12px Roboto;
  letter-spacing: 0;
  opacity: 1;
}
.divider {
  border-bottom: 1px solid #bababa;
}
a:hover {
  text-decoration: none;
}
.cont-null {
  font-size: 35px;
  color: #d82b2b;
  font-family: "TolyerBold1", sans-serif;
  margin: 40px 0;
  height: 20vh;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .navbar-nav {
    background-color: transparent;
  }
  .nav-link {
    color: #bababa;
    border-bottom: 3px solid #bababa;
    text-transform: initial;
  }
  .card .item-img {
    height: 235px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 3px 6px #00000029;
  }
  .description {
    font: normal normal normal 16px Roboto;
    text-align: justify;
    letter-spacing: 0;
    opacity: 1;
  }
  .points {
    color: #bababa;
    text-align: left;
    font: normal normal normal 17px Roboto;
    letter-spacing: 0;
    opacity: 1;
  }
  .divider {
    display: none;
  }
  .cont-null {
    font-size: 70px;
    margin: 40px 0;
    height: 40vh;
  }
}
</style>
