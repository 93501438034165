<script setup>
import { useRouter } from "vue-router/composables";

const router = useRouter();
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div>
    <header
      class="lg:tw-container-title tw-my-3 lg:tw-my-5 tw-px-1 tw-py-2 tw-flex tw-gap-2 lg:tw-gap-0 tw-items-center tw-cursor-pointer tw-shadow-md lg:tw-shadow-none"
      @click="router.go(-1)"
    >
      <i
        class="icon-flecha-izq tw-text-xl lg:tw-text-3xl tw-text-red-350 md:tw--ml-9 md:tw-mr-1 xl:tw--ml-9 xl:tw-mr-1"
      ></i>
      <div
        class="tw-flex tw-gap-x-6 tw-items-center tw-justify-between md:tw-justify-start tw-w-full"
      >
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-4xl tw-font-robotoBold"
        >
          {{ props.title }}
        </p>
        <img
          src="@/assets/img/icons/icon-3.png"
          alt="icon 3"
          class="tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24 tw-hidden lg:tw-block"
        />
        <i
          class="icon-cebada-full tw-text-2xl tw-mr-3 tw-text-red-350 tw-block lg:tw-hidden"
        ></i>
      </div>
    </header>
  </div>
</template>

<style scoped></style>
