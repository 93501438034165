<template>
  <div class="tw-container-general">
    <ButtonsMenu class="py-3 mb-2 mb-md-4" :buttonsMenuItemActive="2" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p
            class="fs-30 fs-md-40 text-uppercase tw-text-gray-600 font-weight-bold tw-font-tolyerBold mb-3 d-none d-md-block"
          >
            Mi hoja de vida
          </p>
        </div>
      </div>
    </div>
    <div class="container mb-4 pb-3">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
          <AboutUser
            url="/ambassadors/people/cv"
            buttonText="Editar hoja de vida"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-8 pt-1 mt-4 mt-md-0">
          <div class="container">
            <div class="row">
              <div class="col-12 pl-0 pl-md-3">
                <p
                  class="fs-30 fs-md-40 text-uppercase tw-text-gray-600 font-weight-bold tw-font-tolyerBold mb-3 d-block d-md-none"
                >
                  Mi hoja de vida
                </p>
              </div>
            </div>
          </div>
          <CvView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ButtonsMenu from "@/components/general/ButtonsMenu";
import AboutUser from "@/components/user/CompUserAboutUser.vue";
import CvView from "@/components/user/cv/CvView";

export default {
  name: "ViewCv",
  components: {
    ButtonsMenu,
    AboutUser,
    CvView,
  },
  created() {
    this.getPeopleResume();
  },
  methods: {
    ...mapActions("modCv", ["getPeopleResume"]),
  },
};
</script>
