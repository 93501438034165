<template>
  <div>
    <div class="compare-md-back tw-rounded-lg-30 shadow-box-md-5">
      <div>
        <div class="div1 position-relative pb-4">
          <div class="container">
            <div class="row pt-4 mx-md-1 he-120 he-md-120">
              <div class="avatar col-auto col-md-auto">
                <div v-if="leadersInfo.prof_picture == null">
                  <img
                    src="@/assets/img/Engagement.png"
                    alt=""
                    class="tw-shadow-sm tw-rounded-full bg-white"
                  />
                </div>
                <div v-else>
                  <img
                    :src="uriResources + '/' + leadersInfo.prof_picture"
                    alt=""
                    class="tw-shadow-sm tw-rounded-full tw-object-cover"
                  />
                </div>
                <a
                  :href="'mailto:' + leadersInfo.email"
                  v-if="leadersInfo.email !== null"
                >
                  <div
                    class="
                      icon
                      bg-white
                      tw-rounded-full
                      d-flex
                      justify-content-center
                      align-items-center
                      position-absolute
                    "
                  >
                    <img src="../../assets/img/icons/message.png" class="mw-70 mh-70" alt=""/>
                  </div>
                </a>
                <div
                  class="
                    icon
                    bg-white
                    tw-rounded-full
                    d-flex
                    justify-content-center
                    align-items-center
                    position-absolute
                  "
                  v-else
                >
                  <img src="../../assets/img/icons/message.png" class="mw-70 mh-70" alt="" />
                </div>
              </div>
              <div class="col-7 col-md pl-2 pl-md-3">
                <p
                  class="
                    tw-font-robotoBold
                     tw-text-xs  fs-md-18
                    md:tw-text-white
                    m-0
                    text-capitalize
                  "
                >
                  {{ leadersInfo.name.toLowerCase() }}
                </p>
                <p
                  class="
                    tw-font-roboto
                     tw-text-xs  fs-md-14
                    tw-text-gray-300 md:tw-text-white
                    line-height-16
                    my-2
                    text-capitalize
                  "
                >
                  {{ leadersInfo.site.toLowerCase() }}
                </p>
              </div>
            </div>
            <div class="row px-2 px-md-0 mx-md-1 overflow-hidden">
              <div class="col-9 col-md position-relative">
                <p
                  class="
                    position-absolute
                    tw-bottom-0
                    start-0
                    m-0
                    bg-transparent
                    md:tw-text-white tw-text-gray-400
                     tw-text-xs  fs-md-13
                    tw-font-black tw-font-roboto
                  "
                >
                  Posición Actual del Ranking General
                </p>
              </div>
              <div
                class="
                  rank
                  col-3 col-md-auto
                  tw-rounded-md
                  d-flex
                  p-0
                  position-relative
                "
              >
                <div
                  class="
                    tw-bg-gray-200 md:tw-bg-white
                    tw-opacity-20
                    w-100
                    h-100
                    p-0
                    z-index-0
                    position-absolute
                    tw-rounded-md
                  "
                ></div>
                <div
                  class="
                    z-index-2
                    m-auto
                    tw-font-roboto
                    fs-24 fs-md-30
                    md:tw-text-white
                  "
                >
                  {{ leadersInfo.position + 1 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white tw-rounded-lg-30 py-md-3">
        <div
          v-for="indicator in this.leadersInfo.calculate"
          :key="indicator.id"
          class="px-2 px-md-5"
        >
          <div
            class="d-flex psi shadow-box-4 bg-white tw-rounded-lg my-3 mx-auto"
            :class="
              indicator.classification === 1
                ? 'psi-1'
                : indicator.classification === 2
                ? 'psi-2'
                : indicator.classification === 3
                ? 'psi-3'
                : 'psi-4'
            "
          >
            <div class="psi-left"></div>
            <div
              class="
                psi-t
                d-flex
                align-items-center
                pl-2
                justify-content-between
                pr-3
              "
            >
              <span
                class="
                  tw-font-robotoBold
                   tw-text-xs  fs-md-14
                  text-capitalize
                  pr-2 pr-md-0
                "
                >{{ indicator.name.toLowerCase() }}</span
              >
              <span v-if="typeof indicator.average == 'object'"></span>
              <span class="tw-font-robotoBold  tw-text-xs  value" v-else>{{
                Math.round(indicator.average)
              }}</span>
            </div>
          </div>
        </div>
        <div class="pt-2 pt-md-4 he-40 he-md-40">
          <p
            class="tw-font-tolyerBold text-center tw-text-lg fs-md-24 text-capitalize"
          >
            {{ "personalidades " + leadersInfo.site.toLowerCase() }}
          </p>
        </div>
        <div class="col-md-9 m-auto tw-py-6 md:tw-py-8">
          <DoughnutChart2 :chartData="datacollection" />
        </div>
        <div>
          <table class="table table-borderless table-striped">
            <tbody>
              <template v-for="brand in brandData">
                <tr :key="brand.profile.id">
                  <td>
                    <p
                      class="tw-font-robotoBold pl-1 pl-md-3  tw-text-xs  fs-md-14 m-0"
                      :style="'color:' + brand.color"
                    >
                      {{ brand.profile.name.toUpperCase() }}
                    </p>
                  </td>
                  <td
                    class="tw-font-roboto  tw-text-xs  fs-md-14 line-height-14"
                    :style="'color:' + brand.color"
                  >
                    {{
                      Math.round(brand.average) +"%"
                    }}
                  </td>
                  <td
                    class="tw-font-roboto  tw-text-xs  fs-md-14 line-height-14"
                    :style="'color:' + brand.color"
                  >
                    {{
                      "("+brand.amount + " PERSONAS)"
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import psi from "@/mixins/general/psi";
import DoughnutChart2 from "@/components/graphs/DoughnutChart.vue";
import config from "@/services/config";

export default {
  name: "LeaderInfo",
  mixins: [psi],
  props: {
    leadersInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    DoughnutChart2,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      brandData: [],
      donutData: {
        name: null,
        labels: [],
        data: [],
        colors: [],
      },
    };
  },
  created() {
    //
    this.getBrandData(this.leadersInfo);
  },
  computed: {
    datacollection() {
      return this.fillData();
    },
  },
  watch: {
    leadersInfo: function (newVal) {
      this.getBrandData(newVal);
    },
  },
  methods: {
    fillData() {
      return {
        labels: this.donutData.labels,
        datasets: [
          {
            backgroundColor: this.donutData.colors,
            data: this.donutData.data,
          },
        ],
      };
    },
    getBrandData(leaderInfo) {
      this.brandData = [];
      //
      let result = leaderInfo.calculate.filter((x) => x.id === 2);
      result = result[0].average;
      //
      for (let i = 0; i < result.calculate.length; i++) {
        result.calculate[i].color = this.brandColors[i];
        //
      }
      this.brandData = result.calculate;
      this.brandData.name = result.vp.name;
      //
      this.getDonutData();
    },
    getDonutData() {
      this.donutData.name = null;
      this.donutData.labels.length = 0;
      this.donutData.data.length = 0;
      this.donutData.colors.length = 0;
      this.donutData.name = this.brandData.name;
      this.brandData.sort(
        (a, b) => parseFloat(b.average) - parseFloat(a.average)
      );
      for (let i = 0; i < this.brandData.length; i++) {
        this.donutData.labels.push(this.brandData[i].profile.name);
        this.donutData.data.push(
          Math.round(parseFloat(this.brandData[i].average))
        );
        this.donutData.colors.push(this.brandData[i].color);
      }
    },
  },
};
</script>

<style scoped>
.div1 {
  z-index: 10;
}

.div2 {
  z-index: 51;
}

/* [class*="col"] {
  padding: 1%;
} */

.ranking-liderazgo {
  width: 100%;
  height: 1540px;
  top: 0;
  left: 0;
  z-index: 10;
}

.avatar img:first-of-type {
  width: 59px;
  height: 59px;
}

.rank {
  width: 40px;
  height: 40px;
}

.icon {
  width: 20px;
  height: 20px;
  float: right;
  right: 0;
  top: 0;
}

@media (min-width: 768px) {
  .compare-md-back {
    background: linear-gradient(180deg, #f1ce38 0%, #f05e22 30%);
  }

  .avatar img:first-of-type {
    width: 102px;
    height: 102px;
  }

  .icon {
    width: 30px;
    height: 30px;
    float: right;
    right: 0;
    top: 0;
  }

  .icon img {
    object-fit: none;
  }

  .rank {
    width: 60px;
    height: 46px;
  }
}

.button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: 0;
  width: 100%;
}

.graph {
  width: 100%;
  height: 454px;
}

.psi {
  position: relative;
  display: flex;
  width: 100%;
  height: 55px;
  overflow: hidden;
}

[class*="psi-"]:hover {
  color: white;
}

[class*="psi-"]:hover .value {
  color: white;
}

.psi-1 {
  background: linear-gradient(270deg, #f1ce38 0%, #f05e22);
  color: #f05e22;
}

.psi-2 {
  background: linear-gradient(90deg, #4cc3c9 5.33%, #5ea0f8 92.75%);
  color: #4cc3c9;
}

.psi-3 {
  background: linear-gradient(270deg, #eaa8ca 4.33%, #bf0d63 95.42%);
  color: #bf0d63;
}

.psi-4 {
  background: linear-gradient(270deg, #e83c3e 39.02%, #c02f33 95.64%);
  color: #c02f33;
}

.psi-left {
  background: transparent;
  width: 16px;
  float: left;
  z-index: 1;
}

.psi-t {
  width: 100%;
  z-index: 1;
  float: right;
  background-color: white;
}

.psi-trans {
  width: 100%;
  z-index: 1;
  float: right;
  background-color: transparent;
  color: white;
}

.psi-trans .value {
  color: white;
}

.psi:hover .psi-t {
  background-color: transparent;
}

.value {
  color: #717171;
}
</style>