<script setup>
import { ref, watch } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";

const ecommerceStore = useEcommerceStore();
const totalQtyProducts = ref(0);

const shoppingCartLength = () => {
  let qtyProducts = ecommerceStore.shoppingCartArray.length;
  let gitProducts = ecommerceStore.gift == null ? 0 : 1;
  totalQtyProducts.value = qtyProducts + gitProducts;
  return qtyProducts + gitProducts;
};

const tremble = () => {
  let element = document.getElementsByClassName("btn-shopping-cart");
  element[0].classList.add("tremble");
  setTimeout(() => {
    element[0].classList.remove("tremble");
  }, 500);
};

watch(
  () => ecommerceStore.gift,
  () => {
    tremble();
  }
);
</script>
<template>
  <div
    class="btn-shopping-cart d-flex justify-content-center align-items-center tw-bg-red-400 wi-60 he-60 tw-rounded-full z-index-2 position-fixed tw-right-5 tw-bottom-24 md:tw-bottom-7"
  >
    <router-link to="/e-commerce/cart/pending">
      <span class="icon-carrito-de-mercado icon text-white fs-30"></span>
      <div
        v-if="shoppingCartLength > 0"
        class="bg-white d-flex justify-content-center align-items-center tw-rounded-full wi-25 he-25 position-absolute tw-top-0 tw-right-0 tw-shadow-md"
      >
        <p class="mb-0 tw-text-red-400 tw-text-lg">{{ shoppingCartLength }}</p>
      </div>
    </router-link>
  </div>
</template>
