<template>
  <div class="row">
    <div
      v-for="data in dataUserRankSelected"
      :key="'data-rank-people-' + data.id"
      class="col-12 col-lg-6 mb-3"
    >
      <div class="tw-shadow-md tw-rounded-lg">
        <div
          class="
            tw-bg-gray-200
            d-flex
            justify-content-between
            align-items-center
            py-2
            px-2
            tw-rounded-tr-lg tw-rounded-tl-lg
          "
        >
          <p class="mb-0 tw-text-brown-400  tw-text-xs ">{{ data.name }}</p>
          <p class="mb-0 tw-text-brown-400  tw-text-xxs ">Peso: {{ data.weight }}%</p>
        </div>
        <div
          class="tw-bg-white tw-rounded-bl-lg tw-rounded-br-lg"
          v-if="data.detail.calculation"
        >
          <div class="d-flex">
            <div
              class="tw-border-r tw-border-solid tw-border-gray-200  w-50 p-2"
              :class="
                data.detail.calculation.color
                  ? 'tw-text-green-400'
                  : 'tw-text-red-400'
              "
            >
              <p class="text-left  tw-text-xxs  mb-3">{{ calulationName }}</p>
              <p class="text-right tw-text-xl mb-0">
                {{ data.detail.calculation.result }}
                <sup>{{ data.detail.end }}</sup>
              </p>
            </div>
            <div
              class="tw-border-r tw-border-solid tw-border-gray-200  w-50 p-2"
              :class="
                data.detail.year_to_date.color
                  ? 'tw-text-green-400'
                  : 'tw-text-red-400'
              "
            >
              <p class="text-left  tw-text-xxs  mb-3">YTD</p>
              <p class="text-right tw-text-xl mb-0">
                {{ data.detail.year_to_date.result }}
                <sup>{{ data.detail.end }}</sup>
              </p>
            </div>
            <div class="border-l-xs-gray-200 w-50 p-2 tw-text-gray-300">
              <p class="text-left  tw-text-xxs  mb-3">Meta</p>
              <p class="text-right tw-text-xl mb-0">
                {{ data.detail.budget }}<sup>{{ data.detail.end }}</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCategory1",
  props: {
    dataUserRankSelected: {
      type: Array,
      required: true,
    },
    calulationName: {
      type: String,
      required: true,
    },
  },
};
</script>
