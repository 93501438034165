<script setup>
import { computed, getCurrentInstance } from "vue";
const { $isMobile: mobile } = getCurrentInstance().proxy;

const props = defineProps({
  title: {
    type: String,
  },
  titleMobileDown: {
    type: Boolean,
    default: false,
  },
  image: {
    type: String,
  },
  dropdown: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
    default: "ffc041",
  },
  descriptionMobileInside: {
    type: Boolean,
    default: false,
  },
  descriptionMobileColor: {
    type: String,
    default: "FFFFFF",
  },
});

const cardImage = computed(() => {
  const protocolRegex = /^(http?|https?):\/\//i;
  if (protocolRegex.test(props.image)) {
    return props.image;
  }
  return require(`@/${props.image}`);
});

const backgroundColor = computed(() => {
  if (props.bgColor.includes("#")) {
    return props.bgColor.slice(1);
  }
  return props.bgColor;
});

const descriptionMobileColor = computed(() => {
  if (props.descriptionMobileColor.includes("#")) {
    return props.descriptionMobileColor.slice(1);
  }
  return props.descriptionMobileColor;
});
</script>

<template>
  <section
    class="tw-grid tw-grid-cols-12 lg:tw-my-5 tw-gap-5 tw--top-1 topcard"
  >
    <div class="tw-hidden lg:tw-block lg:tw-col-span-5">
      <div class="tw-flex tw-flex-col tw-gap-3 tw-justify-end tw-h-full">
        <p
          class="tw-mb-2 lg:tw-mb-5 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-line-clamp-2"
        >
          {{ props.title }}
        </p>
        <p class="tw-mb-0 tw-text-xs lg:tw-text-lg tw-text-black">
          <slot name="description" />
        </p>
        <div class="tw-mt-5" v-if="props.dropdown && !mobile()">
          <!-- Dropdown Desktop Version -->
          <slot name="dropdown" />
        </div>
      </div>
    </div>
    <div class="tw-col-span-12 lg:tw-col-span-7">
      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-rounded-b-2.5xl lg:tw-rounded-2.5xl lg:tw-overflow-hidden tw-p-6 lg:tw-p-10"
        :style="`background-color: #${backgroundColor}`"
      >
        <div
          class="tw-aspect-w-16 tw-aspect-h-9 tw-rounded-2.5xl tw-overflow-hidden"
        >
          <img
            :src="cardImage"
            class="tw-object-contain tw-rounded-lg tw-m-auto"
            alt=""
          />
        </div>
        <div
          class="tw-block lg:tw-hidden tw-w-full tw-p-3"
          v-if="props.dropdown && mobile()"
        >
          <!-- Dropdown Mobile Version -->
          <slot name="dropdownMobile" />
        </div>
        <p
          class="tw-m-0 tw-text-xs tw-text-center tw-block lg:tw-hidden"
          :style="`color: #${descriptionMobileColor}`"
        >
          <slot name="description" v-if="props.descriptionMobileInside" />
        </p>
      </div>
    </div>
    <div
      class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-col-span-12 lg:tw-hidden tw-px-4"
    >
      <p
        class="tw-mb-2 tw-font-robotoBold tw-text-base tw-line-clamp-2"
        v-if="props.titleMobileDown"
      >
        {{ props.title }}
      </p>
      <p class="lg:tw-hidden tw-font-roboto tw-text-xs">
        <slot name="description" v-if="!props.descriptionMobileInside" />
      </p>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 768px) {
  .topcard {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
}
</style>
