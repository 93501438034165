<script setup>
import { onBeforeMount, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useRouter } from "vue-router/composables";
import { useNavbarStore } from "@/stores/navbarStore";
import { useNotificationsStore } from "@/stores/notificationsStore";
import NotificationCard from "@/components/cards/NotificationCard.vue";

const router = useRouter();
const navbarStore = useNavbarStore();
const notificationsStore = useNotificationsStore();
const isLoading = ref(false);
const target = ref(null);

const actNotificationReadAndRedirect = (id, url) => {
  navbarStore.closeAllModals();
  if (url) {
    router.push(url);
  }
  notificationsStore.actMarkNotificationsAsRead(id);
};

const closeModal = () => {
  navbarStore.changeNotificationModalOpen(false);
};

onClickOutside(target, (event) => {
  console.log(event);
  closeModal();
});

onBeforeMount(() => {
  isLoading.value = true;
  notificationsStore.actGetNotifications().finally(() => {
    isLoading.value = false;
  });
});
</script>
<template>
  <div
    class="tw-fixed tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-50 tw-z-100 tw-flex tw-justify-end tw-items-start tw-pt-4 tw-px-4"
  >
    <div
      id="inner-notifications"
      class="tw-bg-white tw-rounded-lg tw-max-w-sm tw-py-4"
      ref="target"
    >
      <div class="tw-px-4 tw-flex tw-justify-between tw-items-center tw-mb-4">
        <p class="mb-0 tw-text-red-400 tw-text-xl tw-font-robotoBold">
          Tus notificaciones
        </p>
        <i
          @click="closeModal"
          class="icon-cerrar-simple tw-text-red-400 tw-text-xl tw-cursor-pointer"
        ></i>
      </div>
      <p class="tw-px-4 tw-text-sm tw-text-black tw-mb-4">
        Te mantenemos al tanto de los cambios de tus actividades dentro de
        Embajadores a través de estas alertas.
      </p>
      <div v-if="isLoading" class="tw-text-center tw-mt-3">
        <img src="@/assets/img/icons/loading.gif" alt="loading" />
      </div>
      <div v-else class="tw-overflow-scroll tw-max-h-96">
        <NotificationCard
          v-for="(notification, idx) in notificationsStore.notifications"
          :key="idx"
          @click.native="
            actNotificationReadAndRedirect(notification.id, notification.url)
          "
          :notification="notification"
        />
      </div>
      <div class="tw-flex tw-justify-end tw-px-4 tw-mt-3 md:tw-mt-6">
        <img
          src="@/assets/img/icons/icon-1-main-navbar.png"
          class="tw-w-[75px] tw-h-auto"
          alt="icon"
        />
      </div>
    </div>
  </div>
</template>
