var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.MobileBar,{attrs:{"title":"Diversidad, Equidad e Inclusión"}}),_c(_setup.TitleBar,{attrs:{"title":"Diversidad, Equidad e Inclusión"}}),_c('div',{staticClass:"tw-container-general tw-mt-0 tw-py-0"},[_c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-mb-5 lg:tw-my-5 lg:tw-mt-10 lg:tw-gap-6"},[_vm._m(0),_c('div',{staticClass:"tw-order-1 lg:tw-order-2 lg:tw-col-span-8 hijo"},[_c('div',{staticClass:"tw-rounded-br-3xl tw-rounded-bl-3xl tw-rounded-tr-none tw-rounded-tl-none lg:tw-rounded-3xl lg:tw-aspect-h-6 lg:tw-aspect-w-16 tw-overflow-hidden",class:_setup.isVideo && !_setup.$isMobile() ? '' : 'tw-bg-purple-300'},[_c('div',{staticClass:"tw-w-full tw-h-full tw-p-8 tw-flex tw-flex-col tw-gap-2",class:_setup.isVideo ? 'lg:tw-p-0' : 'lg:tw-p-10'},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl tw-text-white lg:tw-hidden tw-text-center"},[_vm._v(" Soñamos en grande para crear un futuro con más motivos para brindar ")]),_c('div',{staticClass:"tw-rounded-3xl tw-w-full tw-h-full tw-overflow-hidden",class:_setup.isVideo
                  ? 'lg:tw-rounded-none'
                  : 'lg:tw-rounded-bl-3xl lg:tw-rounded-tl-3xl lg:tw-rounded-br-none lg:tw-rounded-tr-none'},[(_setup.isVideo)?_c('div',{staticClass:"tw-w-auto tw-h-full tw-flex"},[_c('video',{ref:"videoRef",staticClass:"tw-w-full tw-object-cover",attrs:{"controls":"","autoplay":""}},[_c('source',{attrs:{"src":_setup.videoPrueba,"type":"video/mp4"}}),_vm._v(" Su navegador no soporta videos. ")])]):_c('div',{staticClass:"tw-w-full tw-h-full"},[_c('img',{staticClass:"tw-object-cover tw-w-full tw-h-full tw-hidden lg:tw-block",attrs:{"src":require("@/assets/img/diversity/principal_card_desk.png"),"alt":""}}),_c('img',{staticClass:"tw-object-cover tw-w-full tw-h-full lg:tw-hidden tw-block",attrs:{"src":require("@/assets/img/diversity/principal_card_mobile.png"),"alt":""}})])])])])])]),_c('hr',{staticClass:"tw-w-full tw-my-10 tw-hidden lg:tw-block"}),_c('div',{staticClass:"tw-grid tw-gap-4 lg:tw-gap-6 tw-my-5",class:`tw-grid-cols-${_setup.linksInformation.length}`},_vm._l((_setup.linksInformation),function(link,index){return _c(_setup.CompDiversityClickableCard,{key:index,attrs:{"image":link.image,"link-label":link.linkLabel,"link-url":link.linkUrl,"title":link.title}})}),1),_c('hr',{staticClass:"tw-w-full tw-my-10 tw-hidden lg:tw-block"}),_c('div',{staticClass:"hijo tw-p-10 tw-my-5",style:({
        'background-image': `url(${require('@/assets/img/diversity/6017056.jpg')})`,
        'background-size': 'cover',
        'background-position': 'center',
      })},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-italic tw-text-center tw-text-xl lg:tw-text-3xl"},[_vm._v(" “Nunca es demasiado tarde para abandonar tus prejuicios” ")])]),_vm._m(1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-order-2 lg:tw-order-1 tw-flex tw-flex-col tw-justify-end lg:tw-col-span-4 tw-gap-4 tw-mt-5 lg:tw-m-auto"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-hidden lg:tw-block"},[_vm._v(" Soñamos en grande para crear un futuro con más motivos para brindar ")]),_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-text-red-350"},[_vm._v(" ¿Qué queremos hacer? ")]),_c('p',{staticClass:"tw-m-0 tw-text-sm lg:tw-text-base"},[_vm._v(" Nuestro sueño es crear un ambiente inclusivo donde todas las personas pueden ser su mejor versión. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-my-10 tw-flex tw-flex-col tw-gap-6"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350"},[_vm._v(" Bavaria en cifras de diversidad ")]),_c('img',{staticClass:"tw-mt-4 tw-mb-4 tw-w-full tw-hidden lg:tw-block",attrs:{"src":require("@/assets/img/diversity/Infografia_desk.png"),"alt":""}}),_c('img',{staticClass:"tw-mt-4 tw-mb-4 tw-w-full tw-block lg:tw-hidden",attrs:{"src":require("@/assets/img/diversity/Infografia_mobile.png"),"alt":""}})])
}]

export { render, staticRenderFns }