<template>
  <div id="howCalculate" class="ml-0 md:tw-ml-20 ml-xl-0">
    <div class="tw-container-general">
      <div class="row px-0 mx-0">
        <div class="col-12 col-md-6 col-lg-7 px-0 px-md-2">
          <SecondaryNavbar title="Cómo se calcula" :breadcrumb="breadcrumb" />
        </div>
      </div>
    </div>
    <MenuExtraHours page="howCalculate" />
    <BannerExtraHours
      icon="icon-agenda"
      title="¿Qué son las horas extras / Tiempo suplementario?"
      text="Es la cantidad de tiempo adicional que un trabajador realiza sobre su jornada de trabajo."
    />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="cont-title d-flex align-items-center mb-4">
            <i class="icon-reloj mr-3"></i>
            <p class="mb-0 strong">Tipo de hora extra/ Tiempo suplementario</p>
          </div>
        </div>
      </div>
      <div class="row pt-0 pt-md-3 pb-5" v-if="isLoading">
        <div class="col-12">
          <div class="text-center mt-3">
            <img src="@/assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
      </div>
      <div v-else class="row mb-5 accordion-cont">
        <div
          v-for="(item, index) in itemsOrder"
          :key="item.id"
          class="col-12 col-md-6 col-lg-4 mb-0 mb-0 mb-lg-4"
        >
          <div class="accordion" role="tablist">
            <div class="cont-1">
              <div
                class="header d-flex justify-content-between align-items-center p-2 p-md-3"
                v-b-toggle="'accordion-' + item.id"
                @click="pushDataLayer(item.overtime_item.type, item.overtime_item.name)"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="icon d-flex justify-content-center align-items-center mr-3"
                  >
                    <i :class="item.overtime_item.icon"></i>
                  </div>
                  <p class="mb-0 strong">{{ item.overtime_item.name }}</p>
                </div>
                <i
                  :class="
                    collapses[index].visible
                      ? 'icon-arrow icon-flecha-arriba'
                      : 'icon-arrow icon-flecha-abajo'
                  "
                ></i>
              </div>
              <b-collapse
                :id="'accordion-' + item.id"
                accordion="my-accordion"
                role="tabpanel"
                v-model="collapses[index].visible"
              >
                <div class="body">
                  <div class="cont-1 p-3">
                    <p class="mb-0">{{ item.overtime_item.description }}</p>
                  </div>
                  <div class="cont-2 p-3">
                    <div class="d-flex">
                      <p class="mb-0 text">Valor de la hora ordinaria</p>
                      <div class="separator"></div>
                      <p class="price strong mb-0">
                        <span>x </span>{{ formatNumber(hourValue) }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 text">Valor de la hora extra</p>
                      <div class="separator"></div>
                      <p class="price strong mb-0">
                        <span>x </span>{{ item.pct }}%
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 text">Cantidad de horas</p>
                      <div class="separator"></div>
                      <p class="price strong mb-0"><span>x </span>3</p>
                    </div>
                    <div class="d-flex mt-3">
                      <p class="mb-0 text strong">Total</p>
                      <div class="separator"></div>
                      <p class="price strong mb-0">
                        <span>= </span>${{
                          formatNumber(hourValue * 3 * (item.pct / 100), 0)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiBeerOffice from "@/services/apiBeerOffice";
import numbersMixin from "@/mixins/general/numbers";

import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import MenuExtraHours from "@/components/show-me-the-money/extra-hours/MenuExtraHours";
import BannerExtraHours from "@/components/show-me-the-money/extra-hours/BannerExtraHours";

export default {
  name: "HowCalculate",
  components: {
    SecondaryNavbar,
    MenuExtraHours,
    BannerExtraHours,
  },
  mixins: [numbersMixin],
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Tus horas extras",
          link: "/showMeTheMoney/extra-hours",
        },
        {
          id: 5,
          name: "Cómo se calcula",
          link: "#",
          active: true,
        },
      ],
      isLoading: false,
      hourValue: 0,
      items: [],
      itemsOrder: [],
      collapses: [
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
      ],
    };
  },
  created() {
    this.isLoading = true;
    this.actgetOverTimeItems()
      .then(() => {
        this.itemsSorted();
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    async actgetOverTimeItems() {
      let response = await ApiBeerOffice.getOverTimeItmes();
      this.items = response.data.items;
      this.hourValue = response.data.hour_value;
    },
    itemsSorted() {
      this.itemsOrder = this.items.sort(function (a, b) {
        return a.overtime_item.order - b.overtime_item.order;
      });
    },
    pushDataLayer(label, type) {
      let labels = {
        'OVERTIME': 'Horas Extras',
        'HOLIDAY': 'Dominicales',
        'SURCHARGE': 'Recargos'
      }

      window.dataLayer.push({
        event: "TrakEvent-compensacion",
        category: "Remuneracion",
        SubCategory: "Tus horas extras",
        Action: "Formulación",
        label: labels[label],
        Type: type,
      });
    },
  },
};
</script>

<style scoped>
.cont-title i {
  color: #d82b2b;
  font-size: 24px;
}

.cont-title p {
  font-size: 18px;
  color: #343434;
}

.accordion-cont .col-12:first-child .cont-1 .header {
  border-radius: 8px 8px 0 0;
}

.accordion-cont .col-12:last-child .cont-1 .header {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px #00000029;
}

.accordion .header {
  box-shadow: 0 3px 6px #00000029;
  outline: none;
}

.accordion .header .icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ebebeb;
}

.accordion .header .icon i {
  color: #d82b2b;
  font-size: 25px;
}

.accordion .header p {
  font-size: 12px;
  line-height: 14px;
  color: #343434;
}

.accordion .header .icon-arrow {
  color: #d82b2b;
  font-size: 26px;
}

.accordion .body .cont-1 {
  background-color: #f3f3f3;
}

.accordion .body .cont-1 p {
  font-size: 12px;
  line-height: 14px;
  color: #34343466;
}

.accordion .body .cont-2 {
  background-color: #d8d8d8;
}

.accordion .body .cont-2 p {
  font-size: 12px;
  line-height: 14px;
  color: #343434;
}

.accordion .body .cont-2 .separator {
  flex-grow: 1;
  border-bottom: 1px solid #bababa;
}

.accordion .body .cont-2 .price span {
  color: #d82b2b;
}

@media (min-width: 992px) {
  .accordion .cont-1 .header {
    border-radius: 8px;
  }

  .accordion-cont .col-12:first-child .cont-1 .header {
    border-radius: 8px;
  }

  .accordion-cont .col-12:last-child .cont-1 .header {
    border-radius: 8px;
  }
}
</style>