<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { usePeopleTour } from "@/composables/peopleTour/usePeopleTour";
import { isPastDate } from "@/helpers/datesHelpers";
import { getBand } from "@/helpers/userHelpers";
import {
  formatDateYYYYMMDDLong,
  todayIsBetweenDates,
} from "@/helpers/datesHelpers";
import { pushLink } from "@/helpers/linkHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompPeopleTourRewardCard from "@/components/peopleTour/CompPeopleTourRewardCard.vue";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import SkeletonPeopleTourRewards from "@/components/peopleTour/skeletons/SkeletonPeopleTourRewards.vue";
import { useStorage } from "@vueuse/core";
import { storeToRefs } from "pinia";

const peopleTourStore = usePeopleTourStore();
const isLoading = ref(false);
const optionSelected = ref(null);
const user = useStorage("user", {});
const band = getBand(user.value);
const { checkBP } = usePeopleTour();
const { rewardsList, stagesList } = storeToRefs(peopleTourStore);

async function changeTour(option) {
  optionSelected.value = option;
  await peopleTourStore.getRewardsByStage(option.id, user.value.id_sharp, band);
}

const rewardsByStageDueDate = computed(() => {
  if (!rewardsList.value) return [];
  if (rewardsList.value.length > 1) {
    return [...rewardsList.value].sort((a, b) => {
      return (
        new Date(a.people_tour_stage.due_date) -
        new Date(b.people_tour_stage.due_date)
      );
    });
  } else {
    return rewardsList.value;
  }
});

const stagesByDueDateDesc = computed(() => {
  if (!stagesList.value) return [];
  return [...stagesList.value].sort((a, b) => {
    return new Date(b.due_date) - new Date(a.due_date);
  });
});

const currentStage = computed(() => {
  return stagesList.value.find((stage) => {
    return todayIsBetweenDates(stage.start_date, stage.due_date);
  });
});

const closestStage = computed(() => {
  return stagesList.value.reduce((prev, curr) => {
    return new Date(curr.due_date) > new Date(prev.due_date) ? curr : prev;
  });
});

onBeforeMount(async () => {
  isLoading.value = true;

  await checkBP(user.value.id_sharp);

  await peopleTourStore.getStagesList();

  if (Object.keys(peopleTourStore.currentStageData).length > 0) {
    optionSelected.value = stagesList.value.find(
      (item) => item.name === peopleTourStore.currentStageData.title
    );
  } else {
    optionSelected.value = currentStage.value
      ? currentStage.value
      : closestStage.value;
  }

  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Recompensas" />
    <title-bar title="Recompensas" />
    <main class="tw-container-general" v-if="!isLoading">
      <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
        <section>
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            Para tener acceso a estas recompensas, debes completar
            <span class="tw-font-robotoBold tw-text-red-350"
              >actividades en el Tour de Gente.</span
            >
            Cada vez que finalices una etapa, obtendrás una cantidad de puntos
            que podrás canjear en dicha etapa. Sin embargo, es importante
            recordar que estas recompensas
            <span class="tw-font-robotoBold tw-text-red-350"
              >dejarán de estar disponibles al finalizar la etapa.</span
            >
          </p>
        </section>
        <section class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
          <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2">
            <div
              class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-col2_fit_first tw-gap-4 md:tw-gap-8 tw-items-center"
            >
              <div>
                <p
                  class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl"
                >
                  Etapa
                </p>
              </div>
              <div class="tw-h-12 lg:tw-h-14 tw-w-full lg:tw-w-max">
                <CompGeneralDropdown
                  :fitted="false"
                  :isLabel="false"
                  :options="stagesByDueDateDesc"
                  :default-option="optionSelected?.id"
                  name="tour_dropdown"
                  border-color="black"
                  @changeSelectedOption="(option) => changeTour(option)"
                  @chargeOption="(option) => changeTour(option)"
                />
              </div>
            </div>
            <div class="tw-w-full lg:tw-w-max tw-justify-self-end">
              <div
                class="tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-flex tw-items-centert tw-justify-center lg:tw-justify-between tw-gap-3 tw-py-2 lg:tw-py-3"
              >
                <p
                  :class="{
                    'tw-m-0': true,
                    'tw-font-robotoMedium': true,
                    'tw-text-lg': true,
                    'lg:tw-text-xl': true,
                  }"
                >
                  {{
                    isPastDate(optionSelected?.due_date)
                      ? "Esta etapa ha finalizado"
                      : "Tus Toins de esta etapa "
                  }}

                  <span
                    v-if="!isPastDate(optionSelected?.due_date)"
                    class="tw-text-yellow-400"
                  >
                    {{ peopleTourStore.employeeToinsBalance }}
                  </span>
                </p>
                <div
                  class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-1"
                >
                  <i class="icon-star tw-golden-black-border-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-1 tw-gap-2">
            <div class="tw-flex tw-justify-start">
              <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-2xl">
                Premios - {{ optionSelected?.name }}
              </p>
            </div>
            <div class="tw-flex tw-justify-end lg:tw-justify-start">
              <p
                class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base tw-text-right"
              >
                Disponible desde el
                <span class="tw-font-robotoBold">{{
                  formatDateYYYYMMDDLong(optionSelected?.start_date)
                }}</span>
                hasta el
                <span class="tw-font-robotoBold">{{
                  formatDateYYYYMMDDLong(optionSelected?.due_date)
                }}</span>
              </p>
            </div>
          </div>
        </section>
        <section
          class="grid-rewards tw-grid tw-grid-cols-2 lg:tw-grid-cols-5 tw-gap-3 lg:tw-gap-6"
          v-if="!isLoading"
        >
          <comp-people-tour-reward-card
            v-for="(product, idx) in rewardsByStageDueDate"
            :key="idx"
            :product="product"
            :toinsBalance="peopleTourStore.employeeToinsBalance"
            :isActive="
              todayIsBetweenDates(
                optionSelected?.start_date,
                optionSelected?.due_date
              )
            "
            quantity-visible
            :redeem-button="!product.people_tour_stage?.has_redeemed"
          />
        </section>
        <aside
          class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-end tw-gap-2"
        >
          <p class="tw-m-0 tw-text-xs lg:tw-text-sm tw-font-roboto tw-italic">
            Conoce los términos y condiciones para tus premios de Tour de Gente
          </p>
          <button
            class="tw-btn-primary-white tw-flex tw-gap-1 tw-w-full lg:tw-w-max tw-p-2 lg:tw-p-3"
            @click="
              pushLink(
                'https://api.embajadores.com.co/storage/documents/1720207785.pdf'
              )
            "
          >
            <i class="icon-archivo tw-text-red-350 tw-text-base"></i>
            <span class="">Términos y condiciones</span>
          </button>
        </aside>
      </div>
    </main>
    <skeleton-people-tour-rewards v-else />
  </div>
</template>

<style scoped>
@media (max-width: 1024px) {
  .grid-rewards > *:last-child:nth-child(odd) {
    width: 50%;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-self: center;
  }
}
</style>
