export function compareArraysItemByItem(arr1, arr2) {
  const result = [];
  const obj = arr2.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  arr1.forEach((item) => {
    if (obj[item.id]) {
      result.push({
        ...obj[item.id],
      });
    }
  });

  return result;
}

export function compareArrayWithObjectByKey(arr, obj, key, property) {
  const result = [];
  if (obj) {
    arr.forEach((item) => {
      if (obj[item[key]]) {
        const objProp = obj[item[key]][property];
        result.push({
          ...item,
          [property]: objProp,
        });
      } else {
        result.push({
          ...item,
          [property]: null,
        });
      }
    });
  } else {
    arr.forEach((item) => {
      result.push({
        ...item,
        [property]: null,
      });
    });
  }
  return result;
}

export function compareTwoObjectsByKey(obj1, obj2, key, property) {
  let result;
  if (obj2[key]) {
    result = {
      ...obj1,
      [property]: obj2[key][property],
    };
  } else {
    result = {
      ...obj1,
      [property]: null,
    };
  }

  return result;
}

export function sortArrayByCriteria(array, criterio_id) {
  switch (criterio_id) {
    // Recent
    case 0:
      array.sort((a, b) => {
        if (!a.created_at || !b.created_at) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        }

        return new Date(b.created_at) - new Date(a.created_at);
      });
      break;
    // Oldest
    case 1:
      array.sort((a, b) => {
        if (!a.created_at || !b.created_at) {
          return new Date(a.updated_at) - new Date(b.updated_at);
        }

        return new Date(a.created_at) - new Date(b.created_at);
      });
      break;
    // Most viewed
    case 2:
      array.sort((a, b) => b.views - a.views);
      break;
    // Most liked
    case 3:
      array.sort((a, b) => b.amount_reactions - a.amount_reactions);
      break;
    // Most commented
    case 4:
      array.sort((a, b) => b.amount_comments - a.amount_comments);
      break;
    default:
      console.log("Criterio no válido");
  }
  return array;
}
