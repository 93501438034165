<template>
  <div class="tw-grid tw-gap-2 md:tw-gap-8 tw-grid-cols-12">
    <div
      class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-8 tw-w-full tw-h-80 tw-rounded-2xl tw-bg-gray-300 tw-animate-pulse"
    ></div>
    <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4">
        <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <div class=" tw-w-36 tw-h-4 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
            <div class="md:tw-hidden tw-w-36 tw-h-4 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
        </div>
        <div class="tw-w-full tw-h-6 md:tw-h-10 tw-bg-gray-300 tw-rounded tw-mb-2 md:tw-mb-10 tw-animate-pulse"></div>
        <div class="tw-w-full tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2 tw-animate-pulse"></div>
        <div class="tw-w-full tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2 tw-animate-pulse"></div>
        <div class="tw-w-full tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2 md:tw-mb-8 tw-animate-pulse"></div>
        <div class="tw-hidden md:tw-block tw-w-36 tw-h-10 tw-bg-gray-300 tw-rounded tw-mb-2 md:tw-mb-8 tw-animate-pulse"></div>
        <div class="tw-w-full tw-h-10 tw-bg-gray-300 tw-rounded-2xl tw-animate-pulse"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonCardNewsFeatured",
};
</script>
