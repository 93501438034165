<script setup>
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCard from "@/components/cards/CompGeneralTopCard.vue";

const goToLink = (link, title, label, event) => {
  pushLink(link);
  pushDataLayer({
    event_category: "Cursos",
    event_action: title,
    event_label: label,
    component_name: "Boton",
    element_text: event.target.innerText,
    redirection_link: link,
  });
};
</script>

<template>
  <div>
    <main>
      <mobile-bar title="Cursos" />
      <title-bar title="Cursos" />
      <section class="tw-container-general">
        <comp-general-top-card
          title="¡Aprende a tu gusto!"
          image="assets/img/education/main.png"
          description="Queremos ayudarte a ti y a tu equipo a mejorar, facilitando tu formación
          para mantenerte a la vanguardia de las funcionalidades,
          nuevas tendencias y bases técnicas que te permitirán maximizar tu
          potencial e incentivar tu crecimiento."
          image-right-desk
        />
      </section>
      <section class="tw-container-general tw-flex tw-flex-col tw-gap-5">
        <div
          class="tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-w-full tw-p-6 lg:tw-p-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-3 lg:tw-gap-6"
        >
          <div
            class="lg:tw-col-span-3 tw-order-1 lg:tw-order-1 tw-flex tw-justify-center lg:tw-justify-start"
          >
            <img
              src="@/assets/img/workday_logo.png"
              alt="cursos"
              class="tw-object-contain"
            />
          </div>
          <div
            class="lg:tw-col-span-3 tw-order-4 lg:tw-order-2 tw-flex lg:tw-justify-end"
          >
            <button
              class="tw-btn-general-shape tw-bg-blue-450 tw-border-none tw-text-white tw-w-full lg:tw-w-max"
              @click="
                goToLink(
                  'https://www.workday.com/',
                  'Workday',
                  'Workday',
                  $event
                )
              "
            >
              Conoce Workday
            </button>
          </div>
          <div class="lg:tw-col-span-6 tw-order-2 lg:tw-order-3">
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              Workday es una plataforma que te ofrece cursos creados
              exclusivamente por Ab-InBev para sus colaboradores, con los cuales
              podrás profundizar tu conocimiento, obtener herramientas para
              mejorar tu desempeño y conocer todo lo que la compañía tiene para
              ti.
            </p>
          </div>
          <div
            class="lg:tw-col-span-6 tw-order-3 lg:tw-order-4 tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-3 lg:tw-gap-10"
          >
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-p-5 tw-flex tw-flex-col tw-gap-6"
            >
              <div class="tw-bg-blue-450 tw-rounded-2xl tw-p-6">
                <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                  <img
                    src="@/assets/img/education/workday_1.jpeg"
                    alt="cursos"
                    class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                  />
                </div>
              </div>
              <p
                class="tw-text-center tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-blue-450"
              >
                Conoce tus cursos obligatorios
              </p>
              <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                >Dentro de Workday podrás encontrar los cursos que tu BP y LM
                han asignado para ti. Recuerda que estos son un requisito dentro
                de tus tareas y tienen una fecha de vencimiento.</span
              >
            </div>
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-p-5 tw-flex tw-flex-col tw-gap-6"
            >
              <div class="tw-bg-blue-450 tw-rounded-2xl tw-p-6">
                <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                  <img
                    src="@/assets/img/education/workday_2.jpeg"
                    alt="cursos"
                    class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                  />
                </div>
              </div>
              <p
                class="tw-text-center tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-blue-450"
              >
                Descubre nuevas rutas
              </p>
              <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                >Encuentra oportunidades para aprender y descubrir nuevas rutas
                de aprendizaje centradas en tu perfil y preferencias.</span
              >
            </div>
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-p-5 tw-flex tw-flex-col tw-gap-6"
            >
              <div class="tw-bg-blue-450 tw-rounded-2xl tw-p-6">
                <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                  <img
                    src="@/assets/img/education/workday_3.jpeg"
                    alt="cursos"
                    class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                  />
                </div>
              </div>
              <p
                class="tw-text-center tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-blue-450"
              >
                Cursos creados para ti
              </p>
              <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                >Todos los cursos que encontrarás en la plataforma de Workday
                son creados por AB-InBev para ti, lo que te permitirá tener
                cursos personalizados para tu área.</span
              >
            </div>
          </div>
        </div>
        <div
          class="tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-w-full tw-p-6 lg:tw-p-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-3 lg:tw-gap-6"
        >
          <div
            class="lg:tw-col-span-3 tw-order-1 lg:tw-order-1 tw-flex tw-justify-center lg:tw-justify-start"
          >
            <img
              src="@/assets/img/linkedin_logo.png"
              alt="cursos"
              class="tw-object-contain"
            />
          </div>
          <div
            class="lg:tw-col-span-3 tw-order-4 lg:tw-order-2 tw-flex lg:tw-justify-end"
          >
            <button
              class="tw-btn-general-shape tw-bg-blue-450 tw-border-none tw-text-white tw-w-full lg:tw-w-max"
              @click="
                goToLink(
                  'https://www.linkedin.com/learning',
                  'LinkedIn Learning',
                  'LinkedIn Learning',
                  $event
                )
              "
            >
              Ingresa a LinkedIn
            </button>
          </div>
          <div class="lg:tw-col-span-6 tw-order-2 lg:tw-order-3">
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              Por pertenecer a nuestra compañía, tienes acceso totalmente gratis
              a la plataforma de LinkedIn Learning, donde encontrarás varios
              cursos y tutoriales para desarrollar tus habilidades profesionales
              y personales.
            </p>
          </div>
          <div
            class="lg:tw-col-span-6 tw-order-3 lg:tw-order-4 tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-3 lg:tw-gap-10"
          >
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-p-5 tw-flex tw-flex-col tw-gap-6"
            >
              <div class="tw-bg-blue-450 tw-rounded-2xl tw-p-6">
                <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                  <img
                    src="@/assets/img/education/linkedin_1.jpeg"
                    alt="cursos"
                    class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                  />
                </div>
              </div>
              <p
                class="tw-text-center tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-blue-450"
              >
                Aprende nuevas habilidades
              </p>
              <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                >Podrás realizar cursos sobre una gran variedad de temas, como
                gestión de proyectos, liderazgo, habilidades interpersonales y
                mucho más. Además, puedes aprenderlos a tu ritmo.</span
              >
            </div>
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-p-5 tw-flex tw-flex-col tw-gap-6"
            >
              <div class="tw-bg-blue-450 tw-rounded-2xl tw-p-6">
                <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                  <img
                    src="@/assets/img/education/linkedin_2.jpeg"
                    alt="cursos"
                    class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                  />
                </div>
              </div>
              <p
                class="tw-text-center tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-blue-450"
              >
                Actualiza tu conocimiento
              </p>
              <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                >Puedes mantenerte actualizado sobre los últimos avances en tu
                área a través de los cursos que cubren las últimas tendencias y
                tecnologías.</span
              >
            </div>
            <div
              class="tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-blue-450 lg:tw-border-blue-450 tw-p-5 tw-flex tw-flex-col tw-gap-6"
            >
              <div class="tw-bg-blue-450 tw-rounded-2xl tw-p-6">
                <div class="tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10">
                  <img
                    src="@/assets/img/education/linkedin_3.jpeg"
                    alt="cursos"
                    class="tw-object-cover tw-h-full tw-w-full tw-rounded-2xl"
                  />
                </div>
              </div>
              <p
                class="tw-text-center tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-blue-450"
              >
                Obtener certificados
              </p>
              <span class="tw-font-roboto tw-text-xs lg:tw-text-base"
                >Obtén certificados después de completar los cursos; estos
                pueden ayudarte a demostrar tu conocimiento y habilidades en un
                área determinada.</span
              >
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
