<template>
  <div class="container mt-4">
    <div
      class="row"
      v-for="calification in califications"
      :key="'calification-' + calification.id"
    >
      <div class="col-12 mb-4">
        <h3 class="fs-30 fs-md-50 tw-font-tolyerBold tw-text-xl md:tw-text-3xl text-center">
          {{ calification.title }}
        </h3>
      </div>
      <div
        class="col-12 col-md-6 col-lg-3"
        v-for="card in calification.people_cycle_step_calification"
        :key="'card-calification-' + card.id"
      >
        <div class="tw-bg-red-100 tw-rounded-lg tw-border tw-border-solid tw-border-red-300  h-100">
          <div
            class="
              he-80
              d-flex
              justify-content-center
              align-items-center
              tw-border-b tw-border-solid tw-border-red-300 
            "
          >
            <p class="mb-0 text-uppercase tw-text-red-300 tw-font-tolyerBold fs-40">
              {{ card.title }}
            </p>
          </div>
          <div class="p-3">
            <p class="mb-0 tw-text-gray-600  tw-text-xs  line-height-14">
              {{ card.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-2 ml-auto">
            <a
              target="_blank"
              :href="calification.url"
              class="
                 tw-text-xs 
                hover:tw-text-gray-600
                tw-border tw-border-solid tw-border-gray-600
                py-2
                px-1
                tw-rounded-md
                tw-bg-white
                tw-text-gray-600
                hover:tw-bg-gray-200
                d-block
                text-center
              "
            >
              {{ calification.url_name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardPeople2",
  props: {
    califications: {
      type: Array,
      required: true,
    },
  },
};
</script>
