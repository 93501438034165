<template>
  <div>
    <div
      v-b-toggle="'request-collapse-' + request.id"
      class="
        opportunity-card
        p-3 p-md-4
        tw-bg-white
        tw-shadow-sm
        tw-rounded-md
        mb-3
        d-flex
        position-relative
        z-index-2
        hover:tw-shadow-md
      "
    >
      <img
        v-if="request.core_vp"
        :src="uriResources + '/' + request.core_vp.img_people_module"
        width="100%"
        height="auto"
        class="
          tw-object-cover
          tw-rounded-md
          mr-2 mr-md-3
          wi-50
          he-50
          wi-md-100
          he-md-100
        "
        :alt="request.name"
      />
      <div class="w-100 position-relative">
        <i
          class="tw-text-base fs-md-20 position-absolute tw-right-0 tw--top-2"
          :class="classStateVacant.icon + ' ' + classStateVacant.color"
        ></i>
        <p class="tw-text-gray-600 text-uppercase  tw-text-xs  fs-md-20 font-weight-bold">
          {{ request.position_name }}
        </p>
        <div class="d-flex justify-content-between">
          <div class="mr-2 ml-0" v-if="request.core_site">
            <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Lugar</p>
            <p class=" tw-text-xxs  fs-md-14 tw-text-gray-400 mb-0">
              {{ request.core_site.name }}
            </p>
          </div>
          <div class="mx-2">
            <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Fecha límite</p>
            <p class=" tw-text-xxs  fs-md-14 tw-text-gray-400 mb-0">
              {{ request.end_date }}
            </p>
          </div>
          <div class="mx-2" v-if="request.core_pa_sideline">
            <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Banda</p>
            <p class=" tw-text-xxs  fs-md-14 tw-text-gray-400 mb-0">
              {{ request.core_pa_sideline.name }}
            </p>
          </div>
        </div>
        <div
          class="
            d-flex
            align-items-center
            justify-content-end
            mt-4
            d-block d-md-none
          "
        >
          <i
            class="tw-text-xl mr-2"
            :class="classStateVacant.icon + ' ' + classStateVacant.color"
          ></i>
          <p
            class="tw-text-sm mb-0 font-weight-bold"
            :class="classStateVacant.color"
          >
            {{ request.people_vacant_position_state_name }}
          </p>
        </div>
      </div>
    </div>
    <div class="d-block d-md-none">
      <b-collapse
        :id="'request-collapse-' + request.id"
        class="
          tw-bg-gray-200
          pt-4
          pb-3
          px-3
          mx-3
          mb-3
          position-relative
          z-index-1
          mt-n4
          tw-shadow-sm
          tw-rounded-md
        "
      >
        <div class="max-he-450 tw-overflow-y-scroll">
          <p class="tw-text-base tw-text-gray-600 text-uppercase font-weight-bold">
            DESCRIPCIÓN DEL CARGO
          </p>
          <p class="tw-text-gray-600  tw-text-xs  line-height-14">
            {{ request.position_desc }}
          </p>
          <p
            class="
              tw-text-base
              tw-text-gray-600
              tw-text-base
              text-uppercase
              font-weight-bold
              mt-4
            "
          >
            PERFIL
          </p>
          <p class="tw-text-gray-600  tw-text-xs  line-height-14 font-weight-bold">
            Educación:
          </p>
          <p class="tw-text-gray-600  tw-text-xs  line-height-14">
            {{ request.education }}
          </p>
          <p class="tw-text-gray-600  tw-text-xs  line-height-14 font-weight-bold">
            Habilidades:
          </p>
          <p class="tw-text-gray-600  tw-text-xs  line-height-14">
            {{ request.skills }}
          </p>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import vacant from "@/mixins/user/vacant";

export default {
  name: "RequestComponent",
  mixins: [vacant],
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
};
</script>