import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
    namespaced: true,
    state: {
        tabFilter: [],
        leadersList: [],
        personalitiesAreaList: [],
        categoriesListData: [],
        generalFilterData: [],
        turnOverData: [],
        personalitiesData: [],
        leadersInfo: [],
        indLeaderInfo: [],
        yearsAndMonths: [],
    },
    mutations: {
        setTabFilter: (state, tabFilters) => {
            state.tabFilter = tabFilters;
        },
        setLeadersList: (state, leadersList) => {
            state.leadersList = leadersList;
        },
        setPersonalitiesAreaList: (state, personalitiesAreaList) => {
            state.personalitiesAreaList = personalitiesAreaList;
        },
        setCategoriesList: (state, categoriesList) => {
            state.categoriesListData = categoriesList;
        },
        setGeneralFilterData: (state, filterData) => {
            state.generalFilterData = filterData;
        },
        setTurnOverData: (state, turnoverdata) => {
            state.turnOverData = turnoverdata;
        },
        setPersonalitiesData: (state, personalitiesData) => {
            state.personalitiesData = personalitiesData;
        },
        setLeadersInfo: (state, leadersData) => {
            state.leadersInfo = leadersData;
        },
        setIndLeaderInfo: (state, indLeaderInfo) => {
            state.indLeaderInfo = indLeaderInfo;
        },
        setYearsAndMonths: (state, yearsAndMonths) => {
            state.yearsAndMonths = yearsAndMonths;
        }
    },
    actions: {
        actGetTabFilter: async ({commit}) => {
            let response = await ApiBeerOffice.getTabFilter();
            if (response.error) {
                return response.error;
            }
            commit("setTabFilter", response.data);
            return true;
        },
        actGetLeaders: async ({commit}, data) => {
            let response = await ApiBeerOffice.getLeaders(data);
            if (response.error) {
                return response.error;
            }
            commit("setLeadersList", response.data);
            return response;
        },
        actGetPersonalitiesAreas: async ({commit}) => {
            let response = await ApiBeerOffice.getPersonalitiesAreas();
            if (response.error) {
                return response.error;
            }
            commit("setPersonalitiesAreaList", response.data);
            return true;
        },
        actGetCategoriesInfo: async ({commit}, data) => {
            let response = await ApiBeerOffice.getCategoriesPsi(data);
            if (response.error) {
                return response.error;
            }
            commit("setCategoriesList", response.data);
            return true;
        },
        // Info para la gráfica
        actGetGeneralFilterData: async ({commit}, data) => {
            let response = await ApiBeerOffice.getGeneralPsiInfo(data);
            if (response.error) {
                return response.error;
            }
            commit("setGeneralFilterData", response.data);
            return true;
        },
        actGetTurnOverData: async ({commit}, data) => {
            let response = await ApiBeerOffice.getTurnOverInfo(data);
            if (response.error) {
                return response.error;
            }
            commit("setTurnOverData", response.data);
            return true;
        }
        ,
        actGetPersonalitiesData: async ({commit}, id) => {
            let response = await ApiBeerOffice.getPersonalitiesData(id);
            if (response.error) {
                return response.error;
            }
            commit("setPersonalitiesData", response.data);
            return true;
        },
        actGetLeadersInfo: async ({commit}, data) => {
            let response = await ApiBeerOffice.getLeadersInfo(data);
            if (response.error) {
                return response.error;
            }
            commit("setLeadersInfo", response);
            return true;
        },
        actGetIndLeaderInfo: async ({commit}, data) => {
            let response = await ApiBeerOffice.getLeadersInfo(data);
            if (response.error) {
                return response.error;
            }
            commit("setIndLeaderInfo", response);
            return response;
        },
        actGetYearsAndMonths: async ({commit}) => {
            let response = await ApiBeerOffice.getYearsAndMonths();
            if (response.error) {
                return response.error;
            }
            commit("setYearsAndMonths", response);
            return true;
        }
    },
};
