<template>
  <div>
    <CompGamificationRedeemModal />
    <div
      class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0">
        <div class="d-none d-md-flex">
          <p
            class="tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
          >
            Recompensa
          </p>
        </div>
        <div class="d-none d-md-flex">
          <div class="px-3 p-md-0">
            <p
              class="tw-font-roboto tw-text-base md:tw-text-base line-height-24 tw-text-gray-600"
            >
              Acumula maltas para redimir premios.
            </p>
          </div>
        </div>
      </div>
    </div>
    <form id="redeem-form" v-on:submit.prevent="submit">
      <div class="container px-3 px-md-3 my-2 mt-md-3 mb-md-5">
        <div class="row mt-3 mt-md-5" v-if="!isLoading">
          <div class="col-12 col-md-6 col-lg-8 order-2 order-md-1">
            <p
              class="tw-font-robotoBold line-height-24 tw-text-base fs-md-18 tw-text-gray-600"
            >
              ¿Dónde quieres recibir tu producto?
            </p>

            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 mb-4">
                  <div class="tw-bg-white tw-shadow-md p-2 tw-rounded-md">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label for="inline-radio-1 mb-0 d-inline-block">
                        <span
                          class="m-0 d-none d-md-block tw-font-roboto fs-16 tw-text-gray-600"
                        >
                          Tambien puedes retirarlo en nuestras oficinas calle
                          127 en Bogotá
                        </span>
                        <span
                          class="m-0 d-block d-md-none tw-font-roboto fs-12 tw-text-gray-600"
                        >
                          Oficinas Calle 127 en Bogotá
                        </span>
                      </label>
                      <input
                        type="radio"
                        id="inline-radio-1"
                        :value="1"
                        v-model="retirar127"
                        @click="cleanForm()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label
                      for="input-dept"
                      class="form-label tw-text-xs tw-text-gray-300"
                    >
                      Departamento*
                    </label>
                    <select
                      name="dept"
                      id="input-dept"
                      class="form-control tw-shadow-md tw-border-0"
                      :class="{
                        'is-invalid': validationStatus($v.departamento),
                      }"
                      v-model="$v.departamento.$model"
                      @change="getCities()"
                    >
                      <option selected disabled="disabled">Seleccione</option>
                      <option
                        v-for="department in departments"
                        :value="department.id"
                        :key="department.id"
                      >
                        {{ department.name }}
                      </option>
                    </select>
                    <div
                      v-if="!$v.departamento.required"
                      class="invalid-feedback"
                    >
                      El campo departamento no puede estar vacío.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label
                      for="input-mun"
                      class="form-label tw-text-xs tw-text-gray-300"
                    >
                      Municipio*
                    </label>
                    <select
                      name="mun"
                      id="input-mun"
                      class="form-control tw-shadow-md tw-border-0"
                      :class="{ 'is-invalid': validationStatus($v.municipio) }"
                      v-model="municipio"
                      :disabled="departamento == null"
                    >
                      <option selected disabled="disabled">Seleccione</option>
                      <option
                        v-for="city in cities"
                        :value="city.id"
                        :key="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                    <div v-if="!$v.municipio.required" class="invalid-feedback">
                      El campo municipio no puede estar vacío.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label
                      for="input-barr"
                      class="form-label tw-text-xs tw-text-gray-300"
                    >
                      Barrio*
                    </label>
                    <input
                      type="text"
                      class="form-control tw-shadow-md tw-border-0"
                      :class="{ 'is-invalid': validationStatus($v.barrio) }"
                      id="barr"
                      name="barr"
                      v-model="barrio"
                    />
                    <div v-if="!$v.barrio.required" class="invalid-feedback">
                      El campo barrio no puede estar vacío.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label
                      for="input-dir"
                      class="form-label tw-text-xs tw-text-gray-300"
                    >
                      Dirección*
                    </label>
                    <input
                      type="text"
                      class="form-control tw-shadow-md tw-border-0"
                      :class="{ 'is-invalid': validationStatus($v.direccion) }"
                      id="dir"
                      name="dir"
                      v-model="direccion"
                    />
                    <div v-if="!$v.direccion.required" class="invalid-feedback">
                      El campo departamento no puede estar vacío.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <div class="d-flex justify-content-between">
                      <label
                        for="input-ind"
                        class="form-label tw-text-xs tw-text-gray-300"
                      >
                        Indicaciones adicionales*
                      </label>
                      <p class="mb-0 tw-text-gray-300 fs-12">30/50</p>
                    </div>
                    <input
                      type="text"
                      class="form-control tw-shadow-md tw-border-0"
                      id="ind"
                      name="ind"
                      v-model="indicaciones"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <div class="d-flex justify-content-between">
                      <label
                        for="telefono"
                        class="form-label tw-text-xs tw-text-gray-300"
                      >
                        Telefono de contacto*
                      </label>
                    </div>
                    <input
                      type="text"
                      class="form-control tw-shadow-md tw-border-0"
                      :class="{ 'is-invalid': validationStatus($v.telefono) }"
                      id="tel"
                      name="telefono"
                      v-model.trim="telefono"
                    />
                    <div v-if="!$v.telefono.required" class="invalid-feedback">
                      El campo teléfono no puede estar vacío.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-4 text-center px-4 px-md-5 mb-3 order-1 order-md-2"
          >
            <div
              class="d-flex justify-content-center wi-300 he-380 position-relative overflow-hidden tw-rounded-lg"
            >
              <img
                :src="
                  uriResources +
                  '/storage/images/' +
                  rewardSelected.images[0].name
                "
                alt="img"
                class="tw-rounded-lg position-absolute d-block tw-top-0 mb-3"
              />
            </div>
            <p class="tw-text-gray-600 fs-20 font-weight-bold mt-3">
              {{ rewardSelected.name }}
            </p>
            <div
              class="d-flex justify-content-between mb-2"
              v-if="this.$route.params.color"
            >
              <p class="mb-0 tw-text-gray-600">Color</p>
              <div
                class="wi-15 he-15 tw-rounded-full"
                :style="'background-color:' + color.value"
              ></div>
            </div>
            <div
              class="d-flex justify-content-between mb-2"
              v-if="this.$route.params.size"
            >
              <p class="mb-0 tw-text-gray-600">Talla</p>
              <p class="mb-0 tw-text-gray-600">{{ size.value }}</p>
            </div>
            <div
              class="tw-border-b tw-border-solid tw-border-gray-300 mb-2"
            ></div>
            <div class="d-flex justify-content-between mb-3">
              <i class="icon-maltas fs-20 fs-md-26 tw-text-orange-300 mr-2"></i>
              <p class="mb-0 tw-text-gray-600">
                {{ reward.malts.toLocaleString().replace(",", ".") }} Maltas
              </p>
            </div>

            <button
              class="tw-text-white tw-rounded-lg p-2 tw-border-0 w-100 font-weight-bold d-none d-md-block"
              :class="formfilled() ? 'tw-bg-red-400' : 'tw-bg-gray-300'"
              type="submit"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
      <div class="container mt-3 mb-4">
        <div class="row">
          <div class="col-12 d-flex d-md-none px-4">
            <button
              class="tw-text-white tw-rounded-lg p-2 tw-border-0 w-100 font-weight-bold"
              :class="formfilled() ? 'tw-bg-red-400' : 'tw-bg-gray-300'"
              type="submit"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapState, mapActions, mapMutations } from "vuex";
import CompGamificationRedeemModal from "@/components/gamification/CompGamificationRedeemModal.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "gamificationForm",
  components: {
    CompGamificationRedeemModal,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      rewardSelected: null,
      isLoading: false,
      filterReward: [],
      data: {},
      reward_id: this.$route.query.reward_id,
      size: this.$route.params.size,
      color: this.$route.params.color,
      viewReward: 0,
      retirar127: 0,
      departamento: null,
      municipio: null,
      barrio: null,
      direccion: null,
      indicaciones: null,
      telefono: null,
      errors: {},
    };
  },
  validations: {
    departamento: {
      required: requiredIf(function () {
        return this.retirar127 === 0;
      }),
    },
    municipio: {
      required: requiredIf(function () {
        return this.retirar127 === 0;
      }),
    },
    barrio: {
      required: requiredIf(function () {
        return this.retirar127 === 0;
      }),
    },
    direccion: {
      required: requiredIf(function () {
        return this.retirar127 === 0;
      }),
    },
    telefono: { required },
  },
  created() {
    this.isLoading = true;
    this.actGamiRewardsNew(this.reward_id)
      .then(() => {
        this.setRedeemNull();
        this.filterReward = this.rewards.filter((reward) => {
          return (
            reward.gami_level_id ===
            this.user.current_rank.current.gami_level_id
          );
        });
        this.rewardSelected = this.filterReward.filter((reward) => {
          return reward.id === this.reward_id;
        })[0];
        this.actGamiFormDepartments();
        this.actGamiFormContactInfo();
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.$bvModal.show("modal-redeem");
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", [
      "rewards",
      "reward",
      "userRanks",
      "redeem",
      "departments",
      "cities",
    ]),
  },
  methods: {
    ...mapActions("modGamification", [
      "actGamiRewardsNew",
      "actRedeemGamiRewards",
      "actGamiFormDepartments",
      "actGamiFormCities",
      "actGamiFormContactInfo",
    ]),
    ...mapActions("modCore", ["actUser"]),
    ...mapMutations("modGamification", ["setRedeemNull"]),
    async redeemPoints(reward) {
      if (this.retirar127 === 1) {
        this.data.phone = this.telefono;
      }
      if (this.retirar127 === 0) {
        this.data.department_id = this.departamento;
        this.data.city_id = this.municipio;
        this.data.neighborhood = this.barrio;
        this.data.address = this.direccion;
        this.data.additional_address_info = this.indicaciones;
        this.data.phone = this.telefono;
      }

      this.data.reward_id = this.reward_id;
      this.data.pickup_on_site = this.retirar127;

      if (this.$route.params.size && this.$route.params.color) {
        this.data.combination = "[" + this.color.id + "," + this.size.id + "]";
      }

      await this.actRedeemGamiRewards(this.data);
      await this.actUser();
      setTimeout(() => {}, 1000);
      if (this.redeem === "ok") {
        this.$bvModal.show("modal-redeem");
      }
      this.pushDataLayer(reward.reward.gami_level_id, reward.reward.name);
    },
    pushDataLayer: function (rank, product_name) {
      let result = this.userRanks.filter(
        (urank) => urank.gami_level_id === rank
      );

      window.dataLayer.push({
        event: "TrackEvent-Gamification",
        category: "Gamification",
        SubCategory: "Recompensas",
        Action: "Redimir",
        Rank: result[0].name,
        product: product_name,
      });
    },
    getCities() {
      this.retirar127 = 0;
      this.actGamiFormCities(this.departamento);
    },
    cleanForm() {
      this.departamento = null;
      this.municipio = null;
      this.barrio = null;
      this.direccion = null;
    },
    validationStatus(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;

      this.redeemPoints({
        reward: this.reward,
      });
    },
    // checkVal() {
    //   this.$v.$touch();
    //   return this.$v.$error;
    // },
    formfilled() {
      if (this.retirar127 === 1) {
        return this.telefono !== null;
      } else {
        return !(
          this.departamento == null ||
          this.municipio == null ||
          this.barrio == null ||
          this.direccion == null ||
          this.telefono == null
        );
      }
    },
  },
};
</script>
<style scoped></style>
