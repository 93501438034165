var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',{attrs:{"id":"thinking_about_you"}},[_vm._m(0),(_setup.isLoading)?_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-6 lg:tw-gap-16"},_vm._l((2),function(n){return _c('div',{key:n},[_c(_setup.SkeletonCardNews,{attrs:{"change":""}}),_c('hr',{staticClass:"md:tw-hidden tw-mb-5"})],1)}),0):_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-6 lg:tw-gap-16"},_vm._l((_setup.newsStore.otherNews.slice(0, 2)),function(news,index){return _c('div',{key:index},[_c(_setup.OrganismCardNonResponsive,{scopedSlots:_vm._u([{key:"image",fn:function(){return [_c(_setup.AtomImageRectangularForCard,{attrs:{"alt":"","src":_setup.mobile() && news?.image_mobile
                  ? news?.image_mobile
                  : news?.image}})]},proxy:true},{key:"category",fn:function(){return [_c(_setup.AtomCategoryAndBadge,{attrs:{"category":news?.post_category?.name}})]},proxy:true},{key:"titleAndDescription",fn:function(){return [_c(_setup.MoleculeCardTitleAndDescription,{attrs:{"description":news?.description,"title":news?.title}})]},proxy:true},{key:"callToAction",fn:function({ hover }){return [_c('div',{staticClass:"tw-flex tw-items-center tw-justify-start lg:tw-justify-between tw-gap-3 lg:tw-gap-4"},[_c('div',{staticClass:"tw-order-1 lg:tw-order-2"},[_c(_setup.AtomKnowMore,{attrs:{"datalayer-data":{},"link":news?.call_to_action}})],1),_c('div',{staticClass:"tw-order-2 lg:tw-order-1"},[_c(_setup.CompSocialNetworkInteractions,{attrs:{"category":news?.post_category_id,"user_reaction":news?.user_reaction,"amount_comments":Number(news?.amount_comments),"amount_reactions":Number(news?.amount_reactions),"image":_setup.mobile() && news?.image_mobile
                      ? news?.image_mobile
                      : news?.image,"description":news?.description,"title":news.title,"id":news?.postable_id ? news?.postable_id : news?.id,"item":news,"hover":hover,"category-for-datalayer":"Home","action-for-datalayer":"Otras noticias"},on:{"updatedComment":function($event){return _setup.getOtherNews(1, _setup.news_charged)},"updatedReaction":function($event){return _setup.getOtherNews(1, _setup.news_charged)}}})],1)])]}}],null,true)})],1)}),0)]),_c('hr',{staticClass:"tw-hidden md:tw-block md:tw-my-6 lg:tw-my-10"}),_c('section',{staticClass:"tw-mt-5",attrs:{"id":"other_news"}},[(_setup.isLoading)?_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8"},_vm._l((_setup.per_page),function(_n){return _c('div',{key:_n},[_c(_setup.SkeletonCardNews),_c('hr',{staticClass:"md:tw-hidden"})],1)}),0):_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4 md:tw-gap-6 lg:tw-gap-8"},_vm._l((_setup.newsStore.otherNews.slice(2, _setup.number_of_news)),function(news,idx){return _c('div',{key:idx},[_c(_setup.OrganismCardResponsive,{scopedSlots:_vm._u([{key:"image",fn:function(){return [_c(_setup.AtomImageResponsive,{attrs:{"alt":"","src":_setup.mobile() && news?.image_mobile
                  ? news?.image_mobile
                  : news?.image}})]},proxy:true},{key:"category",fn:function(){return [_c(_setup.AtomCategoryAndBadge,{attrs:{"category":news?.post_category?.name}})]},proxy:true},{key:"titleAndDescription",fn:function(){return [_c(_setup.MoleculeCardTitleAndDescription,{attrs:{"description":news?.description,"title":news?.title}})]},proxy:true},{key:"callToAction",fn:function({ hover }){return [_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 lg:tw-justify-between"},[_c(_setup.CompSocialNetworkInteractions,{attrs:{"category":news?.post_category_id,"user_reaction":news?.user_reaction,"amount_comments":Number(news?.amount_comments),"amount_reactions":Number(news?.amount_reactions),"image":_setup.mobile() && news?.image_mobile
                    ? news?.image_mobile
                    : news?.image,"description":news?.description,"title":news.title,"id":news?.postable_id ? news?.postable_id : news?.id,"item":news,"hover":hover,"category-for-datalayer":"Home","action-for-datalayer":"Otras noticias"},on:{"updatedComment":function($event){return _setup.getOtherNews(1, _setup.news_charged)},"updatedReaction":function($event){return _setup.getOtherNews(1, _setup.news_charged)}}}),_c(_setup.AtomKnowMore,{attrs:{"datalayer-data":{},"link":news?.call_to_action}})],1)]}}],null,true)}),_c('hr',{staticClass:"md:tw-hidden"})],1)}),0)]),(
      _setup.newsStore.totalNews > _setup.news_charged || _setup.minimum_news < _setup.newsStore.totalNews
    )?_c(_setup.CompGeneralShowMore,{attrs:{"showMore":_setup.showMore,"show-more-text":"Mostrar más contenido","show-less-text":"Mostrar menos contenido"},on:{"showMoreContent":_setup.showMoreNews}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-gap-x-6 tw-items-center tw-my-5 tw-justify-between md:tw-justify-start"},[_c('p',{staticClass:"tw-mb-0 tw-text-black tw-text-2xl md:tw-text-3xl tw-font-robotoBold"},[_vm._v(" Pensando en ti ")]),_c('img',{staticClass:"tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24",attrs:{"src":require("@/assets/img/icons/icon-4.png"),"alt":"icon 3"}})])
}]

export { render, staticRenderFns }