<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12">
        <div class="tw-shadow-lg bg-white tw-rounded-lg p-3">
          <div>
            <h3 class="tw-text-gray-600 text-uppercase fs-34 tw-font-tolyerBold tw-text-xl md:tw-text-3xl">
              {{ title }}
            </h3>
          </div>
          <div class="d-flex flex-column flex-lg-row justify-content-between">
            <div
              class="
                d-flex
                align-items-center
                flex-column flex-md-row
                mr-0 mr-md-3
              "
            >
              <img
                width="90"
                :src="uriResources + '/' + icon"
                alt="icon description"
                class="mr-0 mr-md-3 mb-4 mb-md-0"
              />
              <div>
                <p class="tw-text-lg tw-text-gray-300">
                  {{ description }}
                </p>
              </div>
            </div>
            <div class="d-grid columns-3 columns-md-6 gap-10 mt-4 mt-md-0">
              <MonthPeople
                v-for="month in months"
                :key="'month-people-' + month.name"
                :month="month.name"
                :active="month.state"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import MonthPeople from "@/components/people/cards/MonthPeople";

export default {
  name: "CardPeople1",
  components: {
    MonthPeople,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    months: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
};
</script>
