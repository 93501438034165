<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { storeToRefs } from "pinia";
import { useProfileStore } from "@/stores/profileStore";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";
import ButtonOutlineRed from "@/components/buttons/CompButtonOutlineRed.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompFormTextInput from "@/components/form/CompFormTextInput.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const props = defineProps({
  data: Object,
});

const emit = defineEmits(["update"]);
const isChildrenModalOpen = ref(false);
const childrenSaved = ref(false);
const { gendersCollection } = storeToRefs(useProfileStore());
const child = reactive({
  name: null,
  birthdate: null,
  gender: null,
});

const childrenData = ref([]);

const transformDate = (date) => date.split("-").reverse().join("-");

const validateFields = computed(
  () => child.name && child.birthdate && child.gender
);

const validateDate = (date) =>
  /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d$/.test(date);

const updateChildrenData = (value, isDelete = false) => {
  const index = childrenData.value.findIndex((child) =>
    Object.keys(child).every((key) => child[key] === value[key])
  );

  if (isDelete) {
    if (index > -1) childrenData.value.splice(index, 1);
    childrenSaved.value = false;
  } else {
    const newValue = { ...value, birthdate: transformDate(value.birthdate) };
    index > -1
      ? (childrenData.value[index] = newValue)
      : childrenData.value.push(newValue);
    childrenSaved.value = true;
  }
  emit("update", { ...props.data, children: childrenData.value });

  if (childrenData.value.length > 0) {
    emit("update", { ...props.data, has_children: "yes" });
  }
};

const closeChildrenModal = () => {
  isChildrenModalOpen.value = false;
  childrenSaved.value = false;
};

const openChildrenModal = () => {
  // Create a new reactive object for each child
  Object.assign(child, {
    name: null,
    birthdate: null,
    gender: null,
  });

  isChildrenModalOpen.value = true;
};

const emptyChildren = () => {
  childrenData.value = [];
  emit("update", { ...props.data, has_children: "no", children: [] });
};

onMounted(() => {
  if (props.data.children?.length > 0) {
    childrenData.value = [...props.data.children];
    childrenData.value = childrenData.value.filter((child) => child.name);
  } else {
    emit("update", { ...props.data, has_children: "no", children: [] });
  }
});
</script>

<template>
  <div>
    <portal to="profileHome">
      <general-modal
        :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
        :modalWidth="{ md: 500, lg: 700, xl: 700 }"
        modal-name="childrenModal"
        v-if="isChildrenModalOpen"
        :isShowModal="isChildrenModalOpen"
        @close="closeChildrenModal"
      >
        <div
          class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4 tw-mb-2"
          v-if="!childrenSaved"
        >
          <h3 class="tw-text-center tw-font-robotoBold">
            Inscribe a tu hijo/a
          </h3>
          <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
            ¿Cuál es el género de tu hijo?
          </p>
          <div class="tw-flex tw-justify-between tw-gap-2">
            <comp-button-custom-color
              class="tw-w-full"
              rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
              v-for="(item, idx) in gendersCollection"
              :key="idx"
              :text="item.name"
              :active="child.gender === item.id"
              active-color="green-500"
              text-active-color="white"
              @click.native="child.gender = item.id"
            />
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-2">
            <div class="tw-col-span-2">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                ¿Cuál es su nombre?
              </p>
            </div>
            <div class="tw-col-span-1">
              <comp-form-text-input
                :modelValue="child.name"
                @update="(value) => (child.name = value)"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-2">
            <div class="tw-col-span-2">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
                ¿Cuál es su fecha de nacimiento? (DD-MM-YYYY)
              </p>
            </div>
            <div class="tw-col-span-1">
              <comp-form-text-input
                :modelValue="child.birthdate"
                @update="(value) => (child.birthdate = value)"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-2">
            <comp-button-custom
              class="tw-w-full"
              rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
              text="Cerrar"
              @click.native="closeChildrenModal"
            />
            <button-primary-red
              :disabled="!validateFields || !validateDate(child.birthdate)"
              class="tw-w-full"
              rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
              title="Guardar"
              @click.native="updateChildrenData(child)"
            />
          </div>
        </div>
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3"
          v-else-if="childrenSaved"
        >
          <div class="tw-flex tw-w-6/12 tw-justify-between tw-items-center">
            <img src="@/assets/img/modal/modal-success.svg" alt="" />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-green-450"
            >
              LISTO
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            {{ child.name }}
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            ha sido registrado/a correctamente
          </p>
          <button-primary-red
            rounded-size="tw-rounded-lg lg:tw-rounded-xl"
            @click.native="closeChildrenModal"
            title="Cerrar"
            class="tw-w-full"
          />
        </div>
      </general-modal>
    </portal>
    <section
      class="tw-rounded-xl lg:tw-rounded-2xl tw-border tw-border-gray-350 tw-p-4 tw-flex tw-flex-col tw-gap-2"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
          ¿Tienes hijos/as?*
        </p>
        <p class="tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs">
          *Puedes agregar varios
        </p>
      </div>
      <div class="tw-flex tw-items-center tw-gap-4 tw-w-full lg:tw-w-1/2">
        <comp-button-custom-color
          class="tw-w-full"
          rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
          text="Si"
          :color="props.data.has_children === 'yes' ? 'green-500' : 'white'"
          active-color="green-500"
          :text-color="props.data.has_children === 'yes' ? 'white' : 'gray-700'"
          text-active-color="white"
          @click.native="emit('update', { ...props.data, has_children: 'yes' })"
        />
        <comp-button-custom-color
          class="tw-w-full"
          rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
          text="No"
          :color="props.data.has_children === 'no' ? 'green-500' : 'white'"
          active-color="green-500"
          :text-color="props.data.has_children === 'no' ? 'white' : 'gray-700'"
          text-active-color="white"
          @click.native="emptyChildren()"
        />
      </div>
      <p
        v-if="childrenData?.length === 0 && props.data.has_children === 'yes'"
        class="tw-m-0 tw-font-robotoLight tw-text-xs lg:tw-text-sm tw-text-red-350"
      >
        *Debes inscribir al menos un/a hijo/a para poder continuar
      </p>
      <div
        class="tw-grid tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-2"
        v-if="childrenData.length > 0"
      >
        <div
          v-for="(child_, idx) in childrenData"
          :key="idx"
          class="tw-self-center"
        >
          <comp-button-custom-color
            class="tw-w-full"
            rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
            :text="child_.name"
            color="green-500"
            text-color="white"
            icon
            icon-name="icon-remover-signo"
            @iconClick="updateChildrenData(child_, true)"
          />
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-3 tw-gap-2">
        <div class="tw-col-span-3 lg:tw-col-span-2">
          <button-outline-red
            class="tw-w-full"
            rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
            title="Inscribe un/a hijo/a"
            @click.native="openChildrenModal"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped></style>
