<script setup>
import { ref, onBeforeMount } from "vue";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  defaultOption: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["changeBP"]);

let isLoading = ref(false);
let selectedOption = ref({});

function changeBP(option) {
  selectedOption.value = option;
}

function saveBP() {
  emit("changeBP", selectedOption.value);
}

onBeforeMount(() => {
  if (props.defaultOption) {
    selectedOption.value = {
      id: props.options.find((option) => option.name === props.defaultOption)
        .id,
      name: props.defaultOption,
    };
  }
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-gap-y-4 tw-m-0 tw-h-full tw-min-h-full tw-justify-between"
  >
    <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg">
      Selecciona tu Business Partner, recuerda que será la persona encargada de
      calificar tus actividades de Tour de gente.
    </p>
    <div id="dropdown" class="tw-flex-1 tw-overflow-hidden">
      <CompGeneralDropdown
        v-if="!isLoading"
        extends-container
        :fitted="false"
        :isLabel="false"
        :options="props.options"
        :defaultOption="selectedOption && selectedOption.id"
        @changeSelectedOption="changeBP"
        @chargeOption="changeBP"
      />
    </div>
    <div id="button">
      <button class="tw-btn-primary-red" @click="saveBP">Guardar</button>
    </div>
  </div>
</template>
