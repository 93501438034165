<template>
  <div>
    <img
      src="@/assets/img/trivia/symbols-vert.png"
      alt="symbols"
      class="wi-20 wi-md-30 position-absolute tw-top-5 top-md-0 tw-right-0 mr-3"
    />
    <div class="mt-4 mt-md-0">
      <p
        class="
          tw-text-gray-600
          font-weight-bold
          text-uppercase
          tw-font-tolyerBold
          fs-30 fs-md-40
          mb-3 mb-md-5
        "
      >
        Pregunta {{ actualQuestion + 1 }}
      </p>
      <p class="tw-text-gray-400 tw-text-sm mb-5">
        {{ question.question }}
      </p>
      <div
        v-for="answer in question.comp_answer"
        :key="'answer-' + answer.id"
        class="custom-control custom-radio mb-3"
      >
        <input
          type="radio"
          class="custom-control-input"
          :id="'radio-' + answer.id"
          name="radio-stacked"
          :value="answer.id"
          @change="radioChange($event)"
          required
        />
        <label
          class="custom-control-label tw-text-gray-400 tw-text-sm"
          :for="'radio-' + answer.id"
        >
          {{ answer.answer }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionTrivia",
  props: {
    question: {
      type: Object,
      required: true,
    },
    actualQuestion: {
      type: Number,
      required: true,
    },
  },
  methods: {
    radioChange(e) {
      let dataAnswer = {
        answerId: Number(e.target.value),
        questionId: this.question.id,
      };
      this.$emit("setActualAnswer", dataAnswer);
    },
  },
};
</script>
<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d82b2b !important;
  background-color: #d82b2b !important;
}
</style>