<script setup>
import { useRouter } from "vue-router/composables";
import AtomText from "@/components/atoms/AtomText.vue";

const router = useRouter();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const goBack = () => {
  return router.go(-1);
};
</script>
<template>
  <div class="tw-container-title tw-hidden md:tw-block">
    <header
      class="tw-w-max tw-mt-5 tw-text-gray-600 tw-bg-white"
      @click="goBack()"
    >
      <div class="tw-flex tw-items-center tw-cursor-pointer">
        <i
          class="icon-flecha-izq tw-text-3xl tw-text-red-350 md:tw--ml-9 md:tw-mr-1 xl:tw--ml-9 xl:tw-mr-1"
        ></i>
        <atom-text
          tag="h1"
          font="robotoBold"
          textSizeMobile="2xl"
          textSizeDesktop="3xl"
        >
          {{ props.title }}
        </atom-text>
        <img
          v-if="props.icon"
          src="@/assets/img/icons/icon-3.png"
          alt="icon 3"
          class="tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24 tw-ml-5"
        />
      </div>
    </header>
  </div>
</template>
