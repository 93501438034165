<script setup>
const smoothScroll = (element, direction) => {
  const scrollAmount = 200;
  const slideAmount = 1;
  let slide = 0;
  const slideInterval = setInterval(() => {
    if (slide < scrollAmount) {
      if (direction === "left") {
        element.scrollLeft -= slideAmount;
      } else {
        element.scrollLeft += slideAmount;
      }
      slide += slideAmount;
    } else {
      clearInterval(slideInterval);
    }
  }, 1);
};

const scrollLeft = () => {
  const scrollable = document.querySelector(".scrollable");
  smoothScroll(scrollable, "left");
};

const scrollRight = () => {
  const scrollable = document.querySelector(".scrollable");
  smoothScroll(scrollable, "right");
};
</script>
<template>
  <div class="tw-grid tw-grid-cols-col3_fit_first_and_last">
    <div class="tw-hidden lg:tw-flex tw-items-center">
      <i
        class="icon-flecha-izq tw-text-red-350 tw-text-3xl tw-cursor-pointer tw-mr-2"
        @click="scrollLeft"
      />
    </div>
    <div class="scrollable">
      <slot />
    </div>
    <div class="tw-hidden lg:tw-flex tw-items-center">
      <i
        class="icon-flecha-der tw-text-red-350 tw-text-3xl tw-cursor-pointer tw-ml-2"
        @click="scrollRight"
      />
    </div>
  </div>
</template>

<style scoped>
.scrollable {
  overflow-x: auto;
  overflow-y: auto; /* Cambiado a 'auto' para permitir desplazamiento con mouse */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scrollable::-webkit-scrollbar {
  display: none;
}
</style>
