<script setup>
import { useRouter, useRoute } from "vue-router/composables";
import { useContestsStore } from "@/stores/contestsStore";
import { pushLink } from "@/helpers/linkHelpers";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";
import { onBeforeMount } from "vue";
import { storeToRefs } from "pinia";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";

const router = useRouter();
const route = useRoute();
const contestsStore = useContestsStore();
const { competition } = storeToRefs(contestsStore);
const { actGetCompetition } = contestsStore;

onBeforeMount(async () => {
  if (!competition.value) {
    await actGetCompetition(route.params.id);
  }
});
</script>

<template>
  <div>
    <main class="tw-container-general">
      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-6">
        <div class="lg:tw-col-span-2 tw-flex tw-justify-center">
          <img
            src="@/assets/img/icons/icon-golden-trophy.svg"
            class="tw-w-5/6 tw-h-full tw-aspect-1"
            alt=""
          />
        </div>
        <div
          class="lg:tw-col-span-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-4"
        >
          <p
            class="tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-red-350 tw-text-center"
          >
            ¡Felicidades!
          </p>
          <p
            class="tw-font-robotoBold tw-text-base lg:tw-text-xl tw-text-center"
          >
            Espera al cierre del concurso para conocer los ganadores
          </p>
          <p
            class="tw-font-roboto tw-text-sm lg:tw-text-base tw-text-gray-400 tw-text-center"
          >
            Recuerda que este concurso cierra el
            {{ formatDateYYYYMMDDLong(competition?.end) }}
          </p>
          <div
            class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center tw-gap-4 tw-w-full"
          >
            <comp-button-custom-color
              v-if="competition.final_document"
              class="tw-w-full lg:tw-w-1/3 !tw-py-3"
              text="Descarga más información"
              @click.native="pushLink(competition.final_document)"
            />
            <comp-button-primary-red
              class="tw-w-full lg:tw-w-1/3 !tw-py-3"
              title="Finalizar"
              @click.native="router.push('/contests')"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped></style>
