var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"tw-mt-5",attrs:{"id":"diversity_articles"}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-start tw-mt-10 tw-mb-5 tw-h-auto tw-max-h-10"},[_vm._m(0),_c(_setup.CompGeneralOrderFilter,{attrs:{"category-for-datalayer":"Champions","not-views":""},on:{"changeFilter":_setup.changeFilter}})],1),(_setup.props.isLoading)?_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8"},_vm._l((_setup.per_page),function(_n){return _c('div',{key:_n},[_c(_setup.SkeletonCardNews),_c('hr',{staticClass:"md:tw-hidden"})],1)}),0):_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4 md:tw-gap-6 lg:tw-gap-8"},_vm._l((_setup.sortArrayByCriteria(
        _setup.getArticlesGeneral,
        _setup.filter
      )),function(article,index){return _c('div',{key:index},[_c(_setup.MoleculeCardResponsive,{scopedSlots:_vm._u([{key:"image",fn:function(){return [_c(_setup.AtomImageResponsive,{attrs:{"alt":"","src":_setup.mobile() && article?.thumbnail
                ? article?.thumbnail
                : article?.resource}})]},proxy:true},{key:"category",fn:function(){return [_c(_setup.AtomCategoryAndBadge,{attrs:{"category":article?.article_category?.name}})]},proxy:true},{key:"titleAndDescription",fn:function(){return [_c(_setup.MoleculeCardTitleAndDescription,{attrs:{"description":article?.description,"title":article?.title}})]},proxy:true},{key:"callToAction",fn:function({ hover }){return [_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 lg:tw-justify-between"},[_c(_setup.CompSocialNetworkInteractions,{attrs:{"category":"NEW_ARTICLES","user_reaction":article?.user_reaction,"amount_comments":Number(article?.amount_comments),"amount_reactions":Number(article?.amount_reactions),"image":_setup.mobile() && article?.thumbnail
                  ? article?.thumbnail
                  : article?.resource,"description":article?.description,"title":article?.title,"id":article?.id,"item":article,"hover":hover,"category-for-datalayer":"Home","action-for-datalayer":"Otras noticias"}}),_c(_setup.AtomKnowMore,{attrs:{"datalayer-data":{},"link":`/article/${article?.id}`}})],1)]}}],null,true)}),_c('hr',{staticClass:"md:tw-hidden"})],1)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-gap-x-6 tw-items-center tw-justify-start"},[_c('p',{staticClass:"tw-mb-0 tw-text-black tw-text-base md:tw-text-3xl tw-font-robotoBold"},[_vm._v(" Conoce más sobre tus champions ")])])
}]

export { render, staticRenderFns }