<script setup>
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const emit = defineEmits(["showMoreContent"]);
const props = defineProps({
  showMore: {
    type: Boolean,
    default: false,
  },
  showMoreText: {
    type: String,
    default: "Mostrar más contenido",
  },
  showLessText: {
    type: String,
    default: "Mostrar menos contenido",
  },
  datalayerActive: {
    type: Boolean,
    default: false,
  },
  categoryForDatalayer: {
    type: String,
    default: "",
  },
  actionForDatalayer: {
    type: String,
    default: "",
  },
});

function showMoreContent() {
  emit("showMoreContent");
  if (props.datalayerActive) {
    pushDataLayer({
      event_category: props.categoryForDatalayer,
      event_action: props.actionForDatalayer,
      event_label: props.showMore ? props.showMoreText : props.showLessText,
      component_name: "Click",
    });
  }
}
</script>
<template>
  <div
    @click="showMoreContent"
    class="tw-flex tw-justify-center tw-items-center tw-gap-2 lg:tw-gap-4 tw-border tw-border-r-0 tw-border-l-0 tw-py-5 tw-border-gray-320 tw-my-4 md:tw-my-6 lg:tw-my-8 tw-cursor-pointer"
  >
    <p
      class="tw-m-0 tw-font-robotoBold tw-text-base tw-leading-5 md:tw-text-xl md:tw-leading-6 lg:tw-text-2xl lg:tw-leading-7"
    >
      {{ props.showMore ? props.showMoreText : props.showLessText }}
    </p>
    <i
      class="tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-2xl"
      :class="props.showMore ? 'icon-flecha-abajo' : 'icon-flecha-arriba'"
    ></i>
  </div>
</template>
