<script setup>
import { useRoute, useRouter } from "vue-router/composables";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import { ref } from "vue";

const route = useRoute();
const router = useRouter();

const breadcrumb = ref([
  {
    id: 0,
    name: "Inicio",
    link: "/home",
    active: false,
  },
  {
    id: 1,
    name: "Bienestar",
    link: "#",
    active: false,
  },
  {
    id: 2,
    name: "Beneficios",
    link: "/care-benefits/benefits",
    active: false,
  },
  {
    id: 3,
    name: "Reserva de Espacios",
    link: "/reservations",
    active: false,
  },
  {
    id: 4,
    name: "Espacios Vacacionales",
    link: `/reservations/space/${route.params.id}`,
    active: false,
  },
  {
    id: 5,
    name: "Reserva",
    link: `/reservations/space/${route.params.id}/book`,
    active: true,
  },
]);
</script>
<template>
  <div class="tw-relative">
    <mobile-bar title="Reserva" />
    <title-bar :breadcrumb="breadcrumb" title="Reserva" />
    <div class="tw-container-general">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-gap-5 md:tw-gap-7 tw-my-3 md:tw-mt-10 md:tw-w-2/4 tw-m-auto"
      >
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xl md:tw-text-4xl tw-text-red-350"
        >
          Felicitaciones
        </p>
        <img
          alt=""
          class="tw-w-2/5 md:tw-w-64"
          src="@/assets/img/reservations/congratslot.png"
        />
        <p
          class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-xl tw-w-10/12 tw-text-center tw-px-2"
        >
          Tu reserva ha entrado en la lista del sorteo, te avisaremos por correo
          electrónico si eres uno de los seleccionados.
        </p>
        <div
          class="tw-btn-primary-red tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-py-2 md:tw-py-3"
          @click="router.push({ name: 'Reservations' })"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
            Volver a reserva de espacios
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
