<template>
  <div>
    <div class="d-flex mb-4">
      <div class="position-relative flex-grow-1">
        <input
          type="text"
          class="form-control w-100 tw-rounded-md tw-border he-40 p-2 tw-text-gray-400 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400"
          placeholder="Buscar..."
          v-model="filterData.text"
          @keyup="filterText"
        />
        <i
          class="position-absolute icon-buscar tw-top-2 tw-right-2 tw-text-xl tw-text-gray-300"
        ></i>
      </div>
      <div
        v-if="!viewSelects"
        @click="showSelects()"
        role="button"
        class="he-40 wi-40 ml-3 d-flex justify-content-center align-items-center tw-rounded-md tw-shadow-md"
      >
        <svg
          width="20"
          height="21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.75" cy="3.5" r="3" stroke="#727272" />
          <circle cx="3.75" cy="17.5" r="3" stroke="#727272" />
          <circle
            cx="16.25"
            cy="10.5"
            r="3"
            transform="rotate(-180 16.25 10.5)"
            stroke="#727272"
          />
          <path
            d="M7.25 3.5h12.5M7.25 17.5h12.5M12.75 10.5H.25"
            stroke="#727272"
          />
        </svg>
      </div>
      <div
        v-else
        @click="hideSelects()"
        role="button"
        class="he-40 wi-40 ml-3 d-flex justify-content-center align-items-center tw-rounded-md tw-shadow-md"
      >
        <i class="icon-cerrar-simple tw-text-red-400 fs-30"></i>
      </div>
    </div>
    <div v-if="viewSelects" class="tw-shadow-sm tw-rounded-md p-4 mb-3">
      <div class="row align-items-center mb-3">
        <div class="col-2">
          <p class="tw-text-gray-600 tw-text-sm fs-md-18 mb-0 text-right">
            Área:
          </p>
        </div>
        <div class="col-10">
          <select
            name="area"
            id="area"
            class="form-control tw-shadow border-0"
            v-model="filterData.area"
            @change="filterVacants()"
          >
            <option value="">Seleccionar...</option>
            <option
              v-for="term in terms"
              :key="term.id"
              :value="term.id + ',' + term.name"
            >
              {{ term.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-2">
          <p class="tw-text-gray-600 tw-text-sm fs-md-18 mb-0 text-right">
            Lugar:
          </p>
        </div>
        <div class="col-10">
          <select
            name="place"
            id="place"
            class="form-control tw-shadow border-0"
            v-model="filterData.place"
            @change="filterVacants()"
          >
            <option value="">Seleccionar...</option>
            <option
              v-for="place in places"
              :key="place.id"
              :value="place.id + ',' + place.name"
            >
              {{ place.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-2">
          <p class="tw-text-gray-600 tw-text-sm fs-md-18 mb-0 text-right">
            Banda:
          </p>
        </div>
        <div class="col-10">
          <select
            name="band"
            id="band"
            class="form-control tw-shadow border-0"
            v-model="filterData.sideline"
            @change="filterVacants()"
          >
            <option value="">Seleccionar...</option>
            <option
              v-for="sideline in sidelines"
              :key="sideline.id"
              :value="sideline.id + ',' + sideline.name"
            >
              {{ sideline.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div
        class="tw-bg-green-100 px-3 py-2 tw-rounded-md tw-border tw-border-solid tw-border-green-400 d-flex mx-2 mb-2"
        v-if="filterData.area !== ''"
      >
        <p class="mb-0 tw-text-green-400 tw-text-sm font-weight-bold">
          {{ filterData.area.split(",")[1] }}
        </p>
        <i
          role="button"
          class="icon-cerrar-simple tw-text-green-400 tw-text-base font-weight-bold ml-2"
          @click="removeArea()"
        ></i>
      </div>
      <div
        class="tw-bg-green-100 px-3 py-2 tw-rounded-md tw-border tw-border-solid tw-border-green-400 d-flex mx-2 mb-2"
        v-if="filterData.place !== ''"
      >
        <p class="mb-0 tw-text-green-400 tw-text-sm font-weight-bold">
          {{ filterData.place.split(",")[1] }}
        </p>
        <i
          role="button"
          class="icon-cerrar-simple tw-text-green-400 tw-text-base font-weight-bold ml-2"
          @click="removePlace()"
        ></i>
      </div>
      <div
        class="tw-bg-green-100 px-3 py-2 tw-rounded-md tw-border tw-border-solid tw-border-green-400 d-flex mx-2 mb-2"
        v-if="filterData.sideline !== ''"
      >
        <p class="mb-0 tw-text-green-400 tw-text-sm font-weight-bold">
          {{ filterData.sideline.split(",")[1] }}
        </p>
        <i
          role="button"
          class="icon-cerrar-simple tw-text-green-400 tw-text-base font-weight-bold ml-2"
          @click="removeSideline()"
        ></i>
      </div>
      <div
        class="tw-bg-green-100 px-3 py-2 tw-rounded-md tw-border tw-border-solid tw-border-green-400 d-flex mx-2 mb-2"
        v-if="filterData.text !== ''"
      >
        <p class="mb-0 tw-text-green-400 tw-text-sm font-weight-bold">
          {{ filterData.text }}
        </p>
        <i
          role="button"
          class="icon-cerrar-simple tw-text-green-400 tw-text-base font-weight-bold ml-2"
          @click="removeText()"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "FilterOpportunities",
  data() {
    return {
      viewSelects: false,
      filterData: {
        text: "",
        area: "",
        place: "",
        sideline: "",
      },
    };
  },
  created() {
    this.getTerms();
    this.getPlaces();
    this.getSideLines();
  },
  computed: {
    ...mapState("modVacants", ["places", "terms", "sidelines"]),
  },
  methods: {
    ...mapActions("modVacants", ["getPlaces", "getTerms", "getSideLines"]),
    showSelects() {
      this.viewSelects = true;
    },
    hideSelects() {
      this.viewSelects = false;
    },
    filterVacants() {
      this.$emit("filterVacants", this.filterData);
    },
    removeText() {
      this.filterData.text = "";
      this.filterVacants();
    },
    removeArea() {
      this.filterData.area = "";
      this.filterVacants();
    },
    removePlace() {
      this.filterData.place = "";
      this.filterVacants();
    },
    removeSideline() {
      this.filterData.sideline = "";
      this.filterVacants();
    },
    filterText() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filterVacants();
      }, 800);
    },
  },
};
</script>
