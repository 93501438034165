<script setup>
import { onBeforeMount, ref, toRefs, computed } from "vue";
import { useTimeBenefitsStore } from "@/stores/timeBenefitsStore";
import { useProfileStore } from "@/stores/profileStore";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router/composables";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCard from "@/components/cards/CompGeneralTopCard.vue";
import SkeletonCompGeneralTopCard from "@/components/cards/skeletons/SkeletonCompGeneralTopCard.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompTimeBenefitsCatalog from "@/components/timeBenefits/CompTimeBenefitsCatalog.vue";
import SkeletonCompTimeBenefitsCatalog from "@/components/timeBenefits/skeletons/SkeletonCompTimeBenefitsCatalog.vue";
import CompTimeBenefitsAdvices from "@/components/timeBenefits/CompTimeBenefitsAdvices.vue";
import SkeletonCompTimeBenefitsAdvices from "@/components/timeBenefits/skeletons/SkeletonCompTimeBenefitsAdvices.vue";

const timeBenefitsStore = useTimeBenefitsStore();
const profileStore = useProfileStore();
const userStore = useUserStore();
const isLoading = ref(false);
const { user } = toRefs(userStore);
const router = useRouter();
const isShowModal = ref(false);
const dummyHints = ref([
  {
    label: "Reconoce tus sentimientos",
    description:
      "Deja que tus emociones fluyan para que puedas procesarlas como deben ser. Libera eso que sientes.",
    image: "assets/img/timeBenefits/icons/icono_marranito.svg",
  },
  {
    label: "Expresa tus necesidades",
    description:
      "Si sientes la necesidad de hablar, hazlo con tu familia o personas cercanas. Podrán apoyarte en lo que necesites.",
    image: "assets/img/timeBenefits/icons/icono_marranito.svg",
  },
  {
    label: "Aprovecha los recursos de apoyo",
    description:
      "Hay muchas herramientas y servicios de apoyo, como asesorías y líneas de atención. Aprovéchalas al máximo.",
    image: "assets/img/timeBenefits/icons/icono_marranito.svg",
  },
  {
    label: "Establece límites",
    description:
      "No está mal definir límites en tu trabajo y tu vida personal, durante esta situación. Tu prioridad eres tú.",
    image: "assets/img/timeBenefits/icons/icono_marranito.svg",
  },
  {
    label: "Encuentra acciones reconfortantes",
    description:
      "Todas las personas somos diferentes, y habrá rituales o actividades que te podrán ayudar a superar el duelo.",
    image: "assets/img/timeBenefits/icons/icono_marranito.svg",
  },
  {
    label: "Planifica tu regreso",
    description:
      "Antes de volver a tus labores, organízate con tu equipo para tener una transición gradual y oportuna.",
    image: "assets/img/timeBenefits/icons/icono_marranito.svg",
  },
]);

const hasPets = computed(() => profileStore.userPets.length > 0);

const hasPerroOrGato = computed(() => {
  const petTypes = profileStore.userPets?.map((pet) =>
    pet?.type?.toLowerCase()
  );
  return (
    hasPets.value && (petTypes.includes("perro") || petTypes.includes("gato"))
  );
});

// check if any of the user's pets has any null values
const hasNullValues = computed(() => {
  const pets = profileStore.userPets;
  return pets.some((pet) => {
    return Object.values(pet).some((value) => value === null);
  });
});

const isLastRequestCompleted = computed(() => {
  const requests = timeBenefitsStore.userTimeBenefitsRequests;

  // Si no hay solicitudes, retorna true ya que no hay ninguna solicitud.
  if (requests.length === 0) {
    return true;
  }

  // Retorna true si la última solicitud está en estado "COMPLETED" o "APPROVED".
  return ["COMPLETED"].includes(requests[0].status);
});

const noRestrictions = computed(
  () => hasPerroOrGato.value && isLastRequestCompleted.value
);

onBeforeMount(async () => {
  isLoading.value = true;
  await Promise.all([
    timeBenefitsStore.actGetTimeBenefits(),
    timeBenefitsStore.actGetTimeBenefitsRequests(),
    timeBenefitsStore.actGetTimeBenefitsPendingRequests(),
    timeBenefitsStore.actGetTimeBenefitsApprovedRequests(),
    profileStore.actGetPetsById(user.value.id),
  ]);

  // Si el usuario no tiene mascotas, se redirige a la página de perfil.
  if (hasNullValues.value) {
    isShowModal.value = true;
  }

  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Beneficios de tiempo" />
    <title-bar title="Beneficios de tiempo" />
    <portal to="all-modals">
      <general-modal
        v-if="isShowModal"
        :modalWidth="{ md: 400, lg: 500, xl: 500 }"
        :maxHeight="{ base: 400, md: 450, lg: 500, xl: 500 }"
        modal-name="fixPetsModal"
        :isShowModal="isShowModal"
        :closable="false"
      >
        <div
          class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 lg:tw-gap-4"
        >
          <img src="@/assets/img/modal/modal-error.svg" alt="" />
          <p class="tw-text-center tw-text-lg tw-font-roboto tw-m-0">
            ¡Hay un error en la información de alguna de tus mascotas! Añádela
            nuevamente
          </p>
          <div class="tw-flex tw-justify-center">
            <button-primary-red
              title="Completar perfil"
              @click.native="router.push({ name: 'ProfileEdit' })"
            />
          </div>
        </div>
      </general-modal>
    </portal>
    <main class="tw-container-general">
      <comp-general-top-card
        title="Solicita tiempo para pasar con tu familia"
        image="assets/img/timeBenefits/main-image.png"
        description="Reconocemos la importancia de cuidar tanto las relaciones humanas como las relaciones de nuestros animales de compañía. Este beneficio te brinda la flexibilidad que necesitas para tomarte un tiempo para que puedas procesar esta difícil situación junto a tu familia o seres cercanos."
        description_2="Descarga la infografía que te ayudará a conocer más sobre el beneficio."
        image-right-desk
        have-buttons
        :buttons="[
          {
            text: 'Descargar',
            link: 'https://api.embajadores.com.co/storage/documents/1703258464.jpg',
          },
        ]"
        v-if="!isLoading"
      />
      <skeleton-comp-general-top-card image-right-desk v-else />
      <hr class="tw-w-full tw-my-5 lg:tw-my-10" />
      <!-- Catalog -->
      <section id="catalog" class="tw-my-2">
        <comp-time-benefits-catalog
          :pending-requests="timeBenefitsStore.userTimeBenefitsPendingRequests"
          :user-requests="timeBenefitsStore.userTimeBenefitsRequests"
          :no-restrictions="noRestrictions"
          :has-perro-or-gato="hasPerroOrGato"
          :time-benefits="timeBenefitsStore.timeBenefits"
          v-if="!isLoading"
        />
        <skeleton-comp-time-benefits-catalog v-else />
      </section>
      <!-- End Catalog -->
      <!-- Advices -->
      <section id="advices">
        <comp-time-benefits-advices
          :dummy-hints="dummyHints"
          v-if="!isLoading"
        />
        <skeleton-comp-time-benefits-advices v-else />
      </section>
    </main>
  </div>
</template>
