<script setup>
import { ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router/composables";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const authStore = useAuthStore();
const router = useRouter();
const { actAuthActivate } = authStore;
const { user } = storeToRefs(userStore);

const error = ref({
  flag: false,
  class: "",
  message: "",
});

const activeData = ref({
  password: "",
  cc: "",
  setPassword: 1,
});

const password = ref("");

async function activate() {
  error.value.flag = false;
  error.value.class = "";

  if (activeData.value.password !== password.value) {
    error.value.flag = true;
    error.value.class = "is-invalid";
    error.value.message = "Debe escribir la contraseña igual en los dos campos";
    return null;
  }

  if (activeData.value.cc == null || activeData.value.cc === "") {
    activeData.value.cc = user.value.cc;
  }

  let response = await actAuthActivate(activeData.value);

  if (response !== true) {
    error.value.flag = true;
    error.value.class = "is-invalid";
    error.value.message = response.message;

    return null;
  }

  await router.push({ path: "/" });
}
</script>
<template>
  <div class="tw-max-w-[800px]">
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-2">
      <div class="lg:tw-col-span-1">
        <img
          src="@/assets/img/changepass.png"
          alt="Imagen de cambio de contraseña"
          class="tw-w-full tw-rounded-2.5xl tw-object-cover tw-max-h-[200px] lg:tw-max-h-[350px]"
        />
      </div>
      <div
        class="tw-col-span-2 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center"
      >
        <h4 class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0">
          Actualiza tu contraseña
        </h4>
        <p
          class="tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-300 tw-m-0"
        >
          En Embajadores queremos garantizar tu seguridad, por eso debes
          actualizar tu contraseña periódicamente.
        </p>

        <p v-show="error.flag" class="tw-text-red-350 tw-mx-0 lg:tw-mx-2">
          {{ error.message }}
        </p>
        <div class="form-group tw-relative !tw-mb-1 -auto">
          <input
            v-model="activeData.password"
            :class="error.class"
            type="password"
            placeholder="Nueva contraseña"
            class="tw-text-sm tw-p-1"
          />
          <span
            @click="toggleInputPassword($event)"
            class="icon icon-visibilidad-sin-relleno tw-absolute"
          ></span>
        </div>
        <div class="form-group tw-relative !tw-mb-1">
          <input
            v-model="password"
            :class="error.class"
            type="password"
            placeholder="Confirmar contraseña"
            class="tw-text-sm tw-p-1"
          />
          <span
            @click="toggleInputPassword($event)"
            class="icon icon-visibilidad-sin-relleno tw-absolute"
          ></span>
        </div>
        <div class="mx-md-5 text-center smallText">
          <span class="tw-font-roboto tw-text-gray-300 tw-text-xs lg:tw-text-sm"
            >*Recuerda que tu contraseña segura debe incluír mayúsculas,
            minúsculas números y símbolos</span
          >
        </div>

        <comp-button-primary-red
          title="Confirmar"
          @click.native="activate"
          class="tw-w-2/3 lg:tw-w-1/2"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePassword",
  mounted() {
    window.dataLayer.push({
      event: "'trackEvent",
      target: "Sub-secciones",
      action: "cambiar-contraseña",
      "target-properties": "clic",
      value: 5000,
    });
  },
  methods: {
    toggleInputPassword(event) {
      let inputText = event.target.parentElement.querySelector("input");
      let spanIcon = event.target.parentElement.querySelector("span");
      if (inputText.getAttribute("type") === "password") {
        inputText.setAttribute("type", "text");
        spanIcon.classList.remove("icon-visibilidad-sin-relleno");
        spanIcon.classList.add("icon-visibilidad-relleno");
      } else {
        inputText.setAttribute("type", "password");
        spanIcon.classList.remove("icon-visibilidad-relleno");
        spanIcon.classList.add("icon-visibilidad-sin-relleno");
      }
    },
  },
};
</script>

<style scoped>
.form-group .icon {
  right: 15px;
  top: calc(50% - 10px);
  color: #00000033;
  font-size: 20px;
}

.form-group input {
  width: 490px;
  height: 45px;
  text-align: center;
  border: 1px solid #222222;
  box-sizing: border-box;
  border-radius: 6px;
}

.smallText {
  line-height: 70%;
  display: block;
}

@media (max-width: 768px) {
  .form-group input {
    width: 224px;
    height: 45px;
    text-align: center;
    border: 1px solid #222222;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .smallText {
    line-height: 50%;
    display: block;
  }
}
</style>
