<template>
  <div id="RemunerationHistory">
    <div class="ml-0 md:tw-ml-20 ml-xl-0">
      <!-- Miga de pan -->
      <div class="container px-0 px-md-3 mt-md-4">
        <BreadCrumb :crumbs="breadcrumb" />
      </div>
      <MobileBar title="HISTORIAL" />
      <!-- Título Desktop y leyenda -->
      <div
        class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
      >
        <div class="col-12 col-md-6 px-0 order-md-1 order-last">
          <div class="d-none d-md-flex">
            <p class="tw-font-tolyerBold fs-md-40 text-uppercase">Historial</p>
          </div>
        </div>
        <div class="col-12 col-md-6 px-md-0 order-md-2 order-first">
          <div
            class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end py-0"
          >
            <div
              class="col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 order-first order-md-1"
            >
              <router-link
                to="newRequestOptions/4"
                class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                @click.native="pushDataLayer('Tienes problemas con tus pagos')"
              >
                ¿Tienes problemas con tus pagos?
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Contenedor Filtro y Resultado -->
      <div class="container px-3 px-md-1 mt-3 mt-md-4" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="../../../assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div class="container px-3 px-md-1 mt-3 mt-md-4" v-else>
        <div class="d-flex flex-md-row flex-column">
          <div class="col-md-4 p-md-0">
            <div
              id="salario"
              class="tw-rounded-lg tw-shadow-md container overflow-hidden d-flex flex-column justify-content-between pb-0"
            >
              <div class="container-fluid my-4" v-if="!isLoading">
                <div class="row px-2 py-2 px-md-4 pt-md-1 pb-md-2">
                  <div class="col-12 col-md-12">
                    <p
                      class="tw-font-tolyerBold fs-28 fs-md-36 text-uppercase my-auto"
                    >
                      Filtra y Consulta
                    </p>
                  </div>
                </div>
                <div class="row px-2 px-md-4 py-2">
                  <div class="col-4 d-flex align-items-center">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-18 my-auto"
                    >
                      Año:
                    </p>
                  </div>
                  <div class="col-8">
                    <select
                      v-model="currentYear"
                      class="line-height-40 he-md-40 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-xxs fs-md-14 w-100 p-md-2 p-2 tw-text-gray-400"
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="year in this.years"
                        v-bind:value="year"
                        v-bind:key="year"
                        :selected="year === currentYear"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row px-2 px-md-4 py-2">
                  <div class="col-4 d-flex align-items-center">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-18 my-auto"
                    >
                      Mes:
                    </p>
                  </div>
                  <div class="col-8">
                    <select
                      v-model="currentMonth"
                      class="line-height-40 he-md-40 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-xxs fs-md-14 w-100 p-md-2 p-2 tw-text-gray-400"
                    >
                      <option :value="null" disabled>Seleccionar...</option>
                      <option value="1">Enero</option>
                      <option value="2">Febrero</option>
                      <option value="3">Marzo</option>
                      <option value="4">Abril</option>
                      <option value="5">Mayo</option>
                      <option value="6">Junio</option>
                      <option value="7">Julio</option>
                      <option value="8">Agosto</option>
                      <option value="9">Septiembre</option>
                      <option value="10">Octubre</option>
                      <option value="11">Noviembre</option>
                      <option value="12">Diciembre</option>
                    </select>
                  </div>
                </div>
                <div class="row px-2 px-md-4 py-2">
                  <div class="col-4 d-flex align-items-center">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-18 my-auto"
                    >
                      Regional:
                    </p>
                  </div>
                  <div class="col-8">
                    <select
                      v-model="regionalSelected"
                      class="line-height-40 he-md-40 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-xxs fs-md-14 w-100 p-md-2 p-2 tw-text-gray-400"
                      @change="
                        user.remuneration.role_remunerations.type === 1
                          ? getManagersFilters(regionalSelected)
                          : user.remuneration.role_remunerations.type === 2
                          ? getSupervisorsFilters(regionalSelected, null)
                          : getBdrFilters(regionalSelected, null, null)
                      "
                    >
                      >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="regional in this.regionsFilter"
                        v-bind:value="regional.id"
                        v-bind:key="regional.id"
                        :selected="regional.id === regionalSelected"
                      >
                        {{ regional.name.toLowerCase() }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="row px-2 px-md-4 py-2"
                  v-if="user.remuneration.role_remunerations.type === 1"
                >
                  <div class="col-4 d-flex align-items-center">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-18 my-auto"
                    >
                      Gerencia:
                    </p>
                  </div>
                  <div class="col-8">
                    <select
                      v-model="managerSelected"
                      class="line-height-40 he-md-40 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-xxs fs-md-14 w-100 p-md-2 p-2 tw-text-gray-400"
                      @change="
                        getSupervisorsFilters(regionalSelected, managerSelected)
                      "
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="manager in this.managersFilter"
                        v-bind:value="manager.id"
                        v-bind:key="manager.id"
                        :selected="manager.id === managerSelected"
                      >
                        {{ manager.name.toLowerCase() }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="row px-2 px-md-4 py-2"
                  v-if="
                    user.remuneration.role_remunerations.type === 1 ||
                    user.remuneration.role_remunerations.type === 2
                  "
                >
                  <div class="col-4 d-flex align-items-center">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-18 my-auto"
                    >
                      Supervisor:
                    </p>
                  </div>
                  <div class="col-8">
                    <select
                      v-model="supervisorSelected"
                      class="line-height-40 he-md-40 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-xxs fs-md-14 w-100 p-md-2 p-2 tw-text-gray-400"
                      @change="
                        getBdrFilters(
                          regionalSelected,
                          managerSelected,
                          supervisorSelected
                        )
                      "
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="supervisor in this.supervisorsFilter"
                        v-bind:value="supervisor.id"
                        v-bind:key="supervisor.id"
                        :selected="supervisor.id === supervisorSelected"
                      >
                        {{ supervisor.name.toLowerCase() }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  class="row px-2 px-md-4 py-2"
                  v-if="
                    user.remuneration.role_remunerations.type === 1 ||
                    user.remuneration.role_remunerations.type === 2 ||
                    user.remuneration.role_remunerations.type === 3
                  "
                >
                  <div class="col-4 d-flex align-items-center">
                    <p
                      class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-18 my-auto"
                    >
                      BDR:
                    </p>
                  </div>
                  <div class="col-8">
                    <select
                      v-model="bdrSelected"
                      class="line-height-40 he-md-40 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-xxs fs-md-14 w-100 p-md-2 p-2 tw-text-gray-400"
                    >
                      <option :value="null" disabled>Seleccionar...</option>

                      <option
                        v-for="bdr in this.bdrFilter"
                        v-bind:value="bdr.id"
                        v-bind:key="bdr.id"
                        :selected="bdr.id === bdrSelected"
                      >
                        {{ bdr.name.toLowerCase() }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row px-3 px-md-4 py-2 px-md-4 py-md-2">
                  <button
                    @click="
                      user.remuneration.role_remunerations.type === 1
                        ? getRemunerationHistory(
                            currentYear,
                            currentMonth,
                            regionalSelected,
                            managerSelected,
                            supervisorSelected,
                            bdrSelected
                          )
                        : user.remuneration.role_remunerations.type === 2
                        ? getRemunerationHistory(
                            currentYear,
                            currentMonth,
                            regionalSelected,
                            null,
                            supervisorSelected,
                            bdrSelected
                          )
                        : getRemunerationHistory(
                            currentYear,
                            currentMonth,
                            regionalSelected,
                            null,
                            null,
                            bdrSelected
                          )
                    "
                    type="button"
                    class="btn btn-primary btn-block"
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 pl-md-5 pr-md-0 pt-md-0 px-0 mt-4 mt-md-0">
            <div
              id="salario"
              class="overflow-hidden d-flex flex-column justify-content-between py-0"
              v-if="!isLoading"
            >
              <!-- Remuneration items -->
              <div
                class="container-fluid"
                v-if="
                  remunerationHistory === null ||
                  remunerationHistory.length === 0
                "
              >
                <div class="p-3 text-center">
                  <p class="tw-font-tolyerBold fs-26 fs-md-36 tw-text-red-400">
                    UPS no se encontraron datos
                  </p>
                  <img
                    src="@/assets/img/Contenido_no_Encontrado.svg"
                    class="img-responsive max-he-400"
                    alt=""
                  />
                </div>
              </div>
              <div class="container-fluid" v-else>
                <!-- Header Salario -->
                <div class="row">
                  <div
                    class="col-12 col-md-8 d-flex flex-column order-2 order-md-1"
                  >
                    <p class="tw-font-tolyerBold fs-28 text-uppercase my-auto">
                      {{
                        user.remuneration.role_remunerations.type === 1
                          ? managerName
                          : user.remuneration.role_remunerations.type === 2
                          ? supervisorName
                          : bdrName
                      }}
                    </p>
                  </div>
                  <div
                    class="col-12 col-md-4 order-1 order-md-2 my-3 mb-md-3 mt-md-0"
                  >
                    <router-link
                      to="/care-benefits/compensation/variable-salary/historical/teams"
                      class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                      @click.native="pushDataLayer('Revisar por equipos')"
                    >
                      Revisar por equipos
                    </router-link>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-md-3">
                    <div
                      class="tw-shadow-md tw-rounded-lg-lg tw-rounded-tr-lg tw-rounded-tl-lg he-70 he-md-130 wi-md-150 tw-bg-brown-500 d-flex flex-md-column justify-content-md-center justify-content-between align-items-center px-4 px-md-0"
                    >
                      <p
                        class="tw-font-robotoBold tw-text-base fs-md-18 text-white my-1"
                      >
                        {{ remunerationHistory[2].name }}
                      </p>
                      <p
                        class="tw-font-robotoBold fs-24 fs-md-24 text-white my-1"
                      >
                        {{ Math.round(remunerationHistory[2].percetage) + "%" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div
                      class="tw-shadow-md tw-rounded-lg-lg tw-rounded-br-lg tw-rounded-bl-lg he-md-130 d-flex flex-column flex-md-row px-4 py-2 p-md-0 tw-overflow-x-scroll invisible-scrollbar position-relative remuner-carousel1"
                    >
                      <div
                        class="tw-shadow-sm md:tw-shadow-none tw-rounded-lg he-100 he-md-full md:tw-border-0 my-2 my-md-0 col-md-4 px-0"
                        v-for="(variable, index) in remunerationHistory[2]
                          .variables"
                        :key="index"
                      >
                        <div class="row m-auto h-50 tw-bg-gray-100">
                          <div
                            class="col d-flex flex-column justify-content-center align-items-center px-3"
                          >
                            <p
                              class="tw-font-roboto tw-text-sm fs-md-16 line-height-16 tw-text-gray-300 m-auto text-center"
                            >
                              {{ variable.name }}
                            </p>
                          </div>
                        </div>
                        <div class="row m-auto h-50">
                          <div
                            class="col d-flex flex-column justify-content-center align-items-center"
                          >
                            <p
                              class="tw-font-robotoBold tw-text-xl fs-md-20 line-height-24 m-auto text-center"
                            >
                              {{ variable.percetage + "%" }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row justify-content-between w-100 position-absolute pl-1 pl-md-1 pr-1 pr-md-1 tw-top-1/3 bg-transparent no-mobile"
                      v-if="
                        !isLoading &&
                        remunerationHistory[2].variables.length > 3
                      "
                    >
                      <div
                        role="button"
                        class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
                        @click="previous(1)"
                      >
                        <i class="icon-flecha-izq fs-30 tw-text-red-400"></i>
                      </div>
                      <div
                        role="button"
                        class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
                        @click="next(1)"
                      >
                        <i class="icon-flecha-der fs-30 tw-text-red-400"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-md-3">
                    <div
                      class="tw-shadow-md tw-rounded-lg-lg tw-rounded-tr-lg tw-rounded-tl-lg he-70 he-md-130 wi-md-150 tw-bg-red-400 d-flex flex-md-column justify-content-md-center justify-content-between align-items-center px-4 px-md-0"
                    >
                      <p
                        class="tw-font-robotoBold tw-text-base fs-md-18 text-white my-1"
                      >
                        {{ remunerationHistory[0].name }}
                      </p>
                      <p
                        class="tw-font-robotoBold fs-24 fs-md-24 text-white my-1"
                      >
                        {{ Math.round(remunerationHistory[0].percetage) + "%" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div
                      class="tw-shadow-md tw-rounded-lg-lg tw-rounded-br-lg tw-rounded-bl-lg he-md-130 d-flex flex-column flex-md-row px-4 py-2 p-md-0 tw-overflow-x-scroll invisible-scrollbar position-relative remuner-carousel2"
                    >
                      <div
                        class="tw-shadow-sm md:tw-shadow-none tw-rounded-lg he-100 he-md-full md:tw-border-0 my-2 my-md-0 col-md-4 px-0"
                        v-for="(variable, index) in remunerationHistory[0]
                          .variables"
                        :key="index"
                      >
                        <div class="row m-auto h-50 tw-bg-gray-100">
                          <div
                            class="col d-flex flex-column justify-content-center align-items-center"
                          >
                            <p
                              class="tw-font-roboto tw-text-sm fs-md-16 line-height-16 tw-text-gray-300 my-auto text-center"
                            >
                              {{ variable.name }}
                            </p>
                          </div>
                        </div>
                        <div class="row m-auto h-50">
                          <div
                            class="col d-flex flex-column justify-content-center align-items-center"
                          >
                            <p
                              class="tw-font-robotoBold tw-text-xl fs-md-20 line-height-24 m-auto text-center"
                            >
                              {{ variable.percetage + "%" }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row justify-content-between w-100 position-absolute pl-1 pl-md-1 pr-1 pr-md-1 tw-top-1/3 bg-transparent no-mobile"
                      v-if="
                        !isLoading &&
                        remunerationHistory[0].variables.length > 3
                      "
                    >
                      <div
                        role="button"
                        class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
                        @click="previous(2)"
                      >
                        <i class="icon-flecha-izq fs-30 tw-text-red-400"></i>
                      </div>
                      <div
                        role="button"
                        class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
                        @click="next(2)"
                      >
                        <i class="icon-flecha-der fs-30 tw-text-red-400"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-md-3">
                    <div
                      class="tw-shadow-md tw-rounded-lg-lg tw-rounded-tr-lg tw-rounded-tl-lg he-70 he-md-130 wi-md-150 tw-bg-yellow-300 d-flex flex-md-column justify-content-md-center justify-content-between align-items-center px-4 px-md-0"
                    >
                      <p
                        class="tw-font-robotoBold tw-text-base fs-md-18 tw-text-brown-500 my-1"
                      >
                        {{ remunerationHistory[1].name }}
                      </p>
                      <p
                        class="tw-font-robotoBold fs-24 fs-md-24 tw-text-brown-500 my-1"
                      >
                        {{ Math.round(remunerationHistory[1].percetage) + "%" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div
                      class="tw-shadow-md tw-rounded-lg-lg tw-rounded-br-lg tw-rounded-bl-lg he-md-130 d-flex flex-column flex-md-row px-4 py-2 p-md-0 tw-overflow-x-scroll invisible-scrollbar position-relative remuner-carousel3"
                    >
                      <div
                        class="tw-shadow-sm md:tw-shadow-none tw-rounded-lg he-100 he-md-full md:tw-border-0 my-2 my-md-0 col-md-4 px-0"
                        v-for="(variable, index) in remunerationHistory[1]
                          .variables"
                        :key="index"
                      >
                        <div class="row m-auto h-50 tw-bg-gray-100">
                          <div
                            class="col d-flex flex-column justify-content-center align-items-center"
                          >
                            <p
                              class="tw-font-roboto tw-text-sm fs-md-16 line-height-16 tw-text-gray-300 my-auto text-center"
                            >
                              {{ variable.name }}
                            </p>
                          </div>
                        </div>
                        <div class="row m-auto h-50">
                          <div
                            class="col d-flex flex-column justify-content-center align-items-center"
                          >
                            <p
                              class="tw-font-robotoBold tw-text-xl fs-md-20 line-height-24 m-auto text-center"
                            >
                              {{ variable.percetage + "%" }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row justify-content-between w-100 position-absolute pl-1 pl-md-1 pr-1 pr-md-1 tw-top-1/3 bg-transparent no-mobile"
                      v-if="
                        !isLoading &&
                        remunerationHistory[1].variables.length > 3
                      "
                    >
                      <div
                        role="button"
                        class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
                        @click="previous(3)"
                      >
                        <i class="icon-flecha-izq fs-30 tw-text-red-400"></i>
                      </div>
                      <div
                        role="button"
                        class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center z-index-13"
                        @click="next(3)"
                      >
                        <i class="icon-flecha-der fs-30 tw-text-red-400"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Disclaimers -->
      <div class="container px-5 px-md-1 my-3 my-md-5">
        <div class="row">
          <div class="col">
            <p
              class="tw-font-robotoBold tw-text-lg line-height-20 tw-text-gray-600"
            >
              Importante
            </p>
            <p
              class="tw-font-roboto tw-text-xs line-height-20 tw-text-gray-400 my-auto"
            >
              Los resultados en esta sección son parciales. El pago que recibes
              a cierre de mes se calcula a partir de los cierres oficiales
              reportados en el sistema.
            </p>
            <p
              class="tw-font-roboto tw-text-xs line-height-20 tw-text-gray-400 my-auto"
            >
              Está información está basada en cálculos proyectados y están
              sujetos a la revisión final del área de nómina.*
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";

export default {
  name: "HistoryRemuneration",
  components: {
    BreadCrumb,
    MobileBar,
  },
  data() {
    return {
      isLoading: true,
      full: false,
      filterselected: 1,
      regionalSelected: null,
      regionalName: null,
      managerSelected: null,
      managerName: null,
      supervisorSelected: null,
      supervisorName: null,
      bdrSelected: null,
      bdrName: null,
      currentYear: null,
      currentMonth: null,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración Variable",
          link: "/showMeTheMoney/remuneracion-variable",
        },
        {
          id: 5,
          name: "Historial",
          link: "/showMeTheMoney/remuneracion-variable/historial",
          active: true,
        },
      ],
      filterselect: [
        {
          id: 1,
          name: "Gerencia 1",
        },
        {
          id: 2,
          name: "Gerencia 2",
        },
        {
          id: 3,
          name: "Gerencia 3",
        },
        {
          id: 4,
          name: "Gerencia 4",
        },
      ],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  async created() {
    this.isLoading = true;

    await this.actGetRegionsFilter()
      .then(() => {
        this.currentYear = new Date().getFullYear();
        this.currentMonth = new Date().getMonth();
        this.regionalSelected = this.regionsFilter[0].id;
        this.regionalName = this.regionsFilter[0].name;
      })
      .then(() => {
        if (this.user.remuneration.role_remunerations.type === 1) {
          this.getManagersFilters(this.regionalSelected);
        } else if (this.user.remuneration.role_remunerations.type === 2) {
          (this.managerSelected = null),
            this.getSupervisorsFilters(this.regionalSelected, null);
        } else if (this.user.remuneration.role_remunerations.type === 3) {
          (this.managerSelected = null),
            (this.supervisorSelected = null),
            this.getBdrFilters(this.regionalSelected, null, null);
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {});
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modVariableCompensation", ["userAccess"]),
    ...mapState("modRemuneration", [
      "regionsFilter",
      "managersFilter",
      "supervisorsFilter",
      "bdrFilter",
      "remunerationHistory",
    ]),
    years() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 0; i <= 2; i++) {
        years.push(year - i);
      }
      return years.reverse();
    },
  },
  methods: {
    ...mapActions("modRemuneration", [
      "actGetRegionsFilter",
      "actGetManagersFilter",
      "actGetSupervisorsFilter",
      "actGetBdrFilter",
      "actGetRemunerationHistory",
    ]),
    getManagersFilters(region) {
      this.actGetManagersFilter({ regional: region })
        .then(() => {
          //
          this.managerSelected = this.managersFilter[0].id;
          this.managerName = this.managersFilter[0].name;
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.getSupervisorsFilters(
            this.regionalSelected,
            this.managerSelected
          );
        });
    },
    getSupervisorsFilters(region, manager) {
      this.actGetSupervisorsFilter({
        regional: region,
        manager: manager,
      })
        .then(() => {
          //
          this.supervisorSelected = this.supervisorsFilter[0].id;
          this.supervisorName = this.supervisorsFilter[0].name;
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.getBdrFilters(
            this.regionalSelected,
            this.managerSelected,
            this.supervisorSelected
          );
        });
    },
    getBdrFilters(region, manager, supervisor) {
      this.actGetBdrFilter({
        regional: region,
        manager: manager,
        supervisor: supervisor,
      })
        .then(() => {
          //
          this.bdrSelected = this.bdrFilter[0].id;
          this.bdrName = this.bdrFilter[0].name;
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.getRemunerationHistory(
            this.currentYear,
            this.currentMonth,
            this.regionalSelected,
            this.managerSelected,
            this.supervisorSelected,
            this.bdrSelected
          );
        });
    },
    getRemunerationHistory(year, month, region, manager, supervisor, bdr) {
      this.actGetRemunerationHistory(
        supervisor == null
          ? {
              year: year,
              month: month,
              regional: region,
              bdr: bdr,
            }
          : manager == null
          ? {
              year: year,
              month: month,
              regional: region,
              supervisor: supervisor,
              bdr: bdr,
            }
          : {
              year: year,
              month: month,
              regional: region,
              manager: manager,
              supervisor: supervisor,
              bdr: bdr,
            }
      )
        .then(() => {})
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    next(number) {
      document.querySelector(".remuner-carousel" + number).scrollLeft += 100;
    },
    previous(number) {
      document.querySelector(".remuner-carousel" + number).scrollLeft -= 100;
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-Compensacion",
        category: "Remuneracion Variable",
        SubCategory: "Historial",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>

<style scoped></style>
