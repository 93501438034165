<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useProfileStore } from "@/stores/profileStore";
import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { useUserStore } from "@/stores/userStore";
import { pushLink } from "@/helpers/linkHelpers";
import { storeToRefs } from "pinia";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import CompProfileBasicInformationCard from "@/components/profile/CompProfileBasicInformationCard.vue";
import CompProfileBasicInformation from "@/components/profile/CompProfileBasicInformation.vue";
import CompProfileSkeletonBasicCard from "@/components/profile/skeletons/CompProfileSkeletonBasicCard.vue";
import CompProfileSkeletonTopInformation from "@/components/profile/skeletons/CompProfileSkeletonTopInformation.vue";
import CompProfileExtraInformationCard from "@/components/profile/CompProfileExtraInformationCard.vue";
import CompProfilePetsInformationCard from "@/components/profile/CompProfilePetsInformationCard.vue";

const isLoading = ref(false);
const flexPoints = ref(0);
const offPoints = ref(0);
const { user } = storeToRefs(useUserStore());
const { mainTourData } = storeToRefs(usePeopleTourStore());
const { userProfileData, familyTypesCollection, hobbiesCollection } =
  storeToRefs(useProfileStore());
const {
  actGetProfileData,
  actGetCollectionFamilyTypes,
  actGetCollectionHobbies,
} = useProfileStore();
const { actGetGoFlexCatalog } = useGoFlexStore();
const { actGetUserById } = useUserStore();
const { getTour } = usePeopleTourStore();

const goToLink = (link, label) => {
  pushLink(link, {
    event_category: "Perfil",
    event_action: "Submenu",
    component_name: "Boton",
    element_text: label,
  });
};

const userInformation = computed(() => {
  return [
    {
      icon: "icon-name",
      label: "Nombre",
      value: user?.value?.name.toLowerCase(),
    },
    {
      icon: "icon-mail",
      label: "Email",
      value: user?.value?.email
        ? user?.value?.email
        : user?.value?.email_personal
        ? user?.value?.email_personal
        : "No disponible",
    },
    {
      icon: "icon-phone",
      label: "Teléfono",
      value: userProfileData.value.phone_number || "No disponible",
    },
    {
      icon: "icon-since",
      label: "Cédula",
      value: user?.value?.cc,
    },
    {
      icon: "icon-since",
      label: "ID sharp",
      value: user?.value?.id_sharp,
    },
    {
      icon: "icon-usuario",
      label: "Tu line manager",
      value: user?.value?.immediate_boss?.toLowerCase(),
    },
  ];
});

const corporateInformation = computed(() => {
  return [
    {
      icon: "icon-world-user",
      label: "Banda",
      value:
        user?.value?.core_om_sideline?.name ||
        user?.value?.core_pa_sideline?.name,
    },
    {
      icon: "icon-position",
      label: "Cargo",
      value: user?.value?.core_position?.name.toLowerCase(),
    },
    {
      icon: "icon-site",
      label: "Site",
      value: user?.value?.core_site?.name.toLowerCase(),
    },
    {
      icon: "icon-mail",
      label: "Convenio",
      value: user?.value?.ben_agreement?.name.toLowerCase(),
    },
    {
      icon: "icon-contract-type",
      label: "Tipo de contrato",
      value: user?.value?.ben_contract?.acronym,
    },
    {
      icon: "icon-mail",
      label: "Sociedad",
      value: user?.value?.core_society?.name.toLowerCase(),
    },
    {
      icon: "icon-since",
      label: "Te uniste desde",
      value: formatDateYYYYMMDDLong(user?.value?.date_entry),
    },
  ];
});

const avatarData = ref([
  {
    label: "Mi avatar",
    value: {
      label: "Editar",
      link: "/gamification/avatar",
    },
    icon: "icon-mail",
  },
]);

/*const formsData = ref([
  {
    icon: "icon-since",
    label: "Tipo de cerveza",
    value: {
      label: "Conocer mi cerveza",
      link: "/gamification/beer",
    },
  },
  {
    icon: "icon-since",
    label: "Huella ecológica",
    value: {
      label: "Conocer mi huella",
      link: "/gamification/ecological-footprint",
    },
  },
  {
    icon: "icon-since",
    label: "Fundación Bavaria",
    value: {
      label: "Voluntariados",
      link: "/gamification/personality-test",
    },
  },
]);*/

const myAdvance = computed(() => {
  return [
    {
      label: "Mis Maltas",
      value: {
        label: `${Number(user?.value?.gamification_maltas).toLocaleString(
          "es-CO"
        )} maltas`,
      },
      icon: "icon-mail",
    },
    {
      label: "Mi avance de Tour de gente",
      value: {
        label: `${mainTourData.value.total_progress.toFixed(2)} %`,
      },
      icon: "icon-mail",
    },
  ];
});

const pointsInformation = computed(() => {
  return [
    {
      label: "Flex work",
      value: {
        label: `${Number(flexPoints.value)} puntos`,
      },
      icon: "icon-mail",
    },
    {
      label: "Tiempo + off",
      value: {
        label: `${Number(offPoints.value)} puntos`,
      },
      icon: "icon-mail",
    },
  ];
});

onBeforeMount(async () => {
  isLoading.value = true;

  await actGetCollectionFamilyTypes();
  await actGetCollectionHobbies();
  await actGetProfileData();

  const flexResponse = await actGetGoFlexCatalog("flex");
  flexPoints.value = flexResponse?.points_balance ?? 0;

  const offResponse = await actGetGoFlexCatalog("off");
  offPoints.value = offResponse?.points_balance ?? 0;

  await getTour(
    user.value?.id_sharp,
    user.value?.core_om_sideline?.name ?? user.value?.core_pa_sideline?.name
  );
  await actGetUserById(user.value?.id);

  isLoading.value = false;
});
</script>
<template>
  <div>
    <section
      class="tw-h-36 tw-bg-cover tw-bg-center tw-pb-2"
      :style="{
        'background-image': `url(${require('@/assets/img/pokerfondo.jpeg')})`,
      }"
      v-if="!isLoading"
    >
      <div
        class="tw-container-general tw-h-full tw-w-full tw-flex tw-items-end tw-justify-end tw-mt-0 tw-py-0"
      >
        <div
          class="tw-bubble tw-font-robotoLight tw-text-sm lg:tw-text-base tw-cursor-pointer"
          role="button"
          @click="goToLink('/profile/edit', 'Editar perfil')"
        >
          <i class="icon-edit" />
          <span class="tw-ml-2">Editar perfil</span>
        </div>
      </div>
    </section>
    <div
      class="tw-h-36 tw-w-full tw-bg-gray-300 tw-animate-pulse"
      v-if="isLoading"
    />
    <main class="tw-container-general" v-if="!isLoading">
      <comp-profile-basic-information />
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-8 tw-mt-4 lg:tw-mt-8"
      >
        <!-- Column 1 -->
        <div id="column-1" class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
          <comp-profile-basic-information-card
            id="your_information"
            title="Tu información"
            :fields="userInformation"
          />
          <comp-profile-basic-information-card
            id="corporate_data"
            title="Datos corporativos"
            :fields="corporateInformation"
          />
        </div>
        <!-- Column 2 -->
        <div id="column-2" class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
          <comp-profile-basic-information-card
            id="avatar_data"
            title="Avatar"
            :fields="avatarData"
          />
          <!--          <comp-profile-basic-information-card
            id="form_data"
            title="Formularios"
            :fields="formsData"
          />-->
          <comp-profile-basic-information-card
            id="my_advance_data"
            title="Mi avance"
            :fields="myAdvance"
          />
          <comp-profile-basic-information-card
            v-if="flexPoints || offPoints"
            id="time_benefits_data"
            title="Beneficios de tiempo"
            :fields="pointsInformation"
          />
        </div>
        <!-- Column 3 -->
        <div id="column-3" class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
          <comp-profile-extra-information-card
            id="family_data"
            :data="userProfileData.family_types"
            title="Mi tipología de familia"
            :collection="familyTypesCollection"
          />
          <comp-profile-pets-information-card
            id="pets_data"
            :data="userProfileData.pets"
            title="Mis mascotas"
          />
          <comp-profile-extra-information-card
            id="hobbies_data"
            :data="userProfileData.hobbies"
            title="Mis hobbies"
            :collection="hobbiesCollection"
          />
        </div>
      </div>
    </main>

    <div class="tw-container-general" v-else>
      <comp-profile-skeleton-top-information nr-of-rows="1" />
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-8 tw-mt-4 lg:tw-mt-8"
      >
        <!-- Column 1 -->
        <div id="column-1" class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
          <comp-profile-skeleton-basic-card :nr-of-rows="6" />
          <comp-profile-skeleton-basic-card :nr-of-rows="7" />
        </div>
        <!-- Column 2 -->
        <div id="column-2" class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
          <comp-profile-skeleton-basic-card :nr-of-rows="1" />
          <comp-profile-skeleton-basic-card :nr-of-rows="3" />
          <comp-profile-skeleton-basic-card :nr-of-rows="2" />
          <comp-profile-skeleton-basic-card :nr-of-rows="2" />
        </div>
        <!-- Column 3 -->
        <div id="column-3" class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
          <comp-profile-skeleton-basic-card :nr-of-rows="3" />
          <comp-profile-skeleton-basic-card :nr-of-rows="3" />
          <comp-profile-skeleton-basic-card :nr-of-rows="3" />
        </div>
      </div>
    </div>
  </div>
</template>
