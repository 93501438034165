<template>
  <div
    :class="active ? 'tw-bg-orange-450' : 'bg-white'"
    class="
      tw-rounded-lg
      tw-shadow-md
      p-2
      wi-70
      he-60
      d-flex
      justify-content-center
      position-relative
    "
  >
    <p
      :class="active ? 'tw-text-white' : 'tw-text-gray-300'"
      class="mb-0 mt-2 tw-text-base"
    >
      {{ month }}
    </p>
    <div
      :class="active ? 'bg-white' : 'tw-bg-gray-200'"
      class="
        position-absolute
        w-100
        he-15
        tw-bottom-0
        tw-rounded-br-lg tw-rounded-bl-lg
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: "MonthPeople",
  props: {
    month: {
      required: true,
      type: String,
    },
    active: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
