import { render, staticRenderFns } from "./CompMonthProduct.vue?vue&type=template&id=3c1e5aff&scoped=true"
import script from "./CompMonthProduct.vue?vue&type=script&lang=js"
export * from "./CompMonthProduct.vue?vue&type=script&lang=js"
import style0 from "./CompMonthProduct.vue?vue&type=style&index=0&id=3c1e5aff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c1e5aff",
  null
  
)

export default component.exports