var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-ranking"},[_c('div',{staticClass:"cont-title"},[_c('h4',{staticClass:"text-center tw-font-robotoBold tw-text-xl md:tw-text-2xl"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"d-flex justify-content-center cont-buttons"},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"cont-radio-mes ml-5 my-3",attrs:{"id":"","options":_vm.switchOptions.options,"buttons":"","name":"radios-btn-default"},model:{value:(_vm.switchOptions.selected),callback:function ($$v) {_vm.$set(_vm.switchOptions, "selected", $$v)},expression:"switchOptions.selected"}})],1)],1),(_vm.switchOptions.selected === 'option1')?_c('div',{staticClass:"table-responsive cont-table"},[_c('b-table',{staticClass:"table",attrs:{"hover":"","sticky-header":"","items":_vm.tableData.positions,"tbody-tr-class":_vm.rowClass,"fields":_vm.tableData.level === 'Unidad Negocio' ? _vm.unitFields : _vm.generalFields}})],1):_c('div',{staticClass:"graphs-bg"},[_c('div',{staticClass:"cont-graphs py-4 px-3 mb-3"},[_c('div',{staticClass:"row mb-3"},[_c('b-carousel',{attrs:{"id":"carousel-no-animation","no-animation":"","controls":"","indicators":"","background":"#ffffff","img-width":"540","img-height":"340"}},[_vm._l((_vm.getUserResult.variables),function(item,index){return _vm._t("default",function(){return [(_vm.title === 'Mes')?_c('b-carousel-slide',{attrs:{"img-blank":""}},[_vm._t("default",function(){return [_c('div',{staticClass:"cont-bar-graph position-relative mt-auto mx-auto"},[_c('div',{staticClass:"bar position-absolute d-flex justify-content-center align-items-center",class:index % 2 === 0 ? 'bg-red' : 'bg-yellow',style:({
                      height:
                        (item.fulfilment * 100 > 100
                          ? '100'
                          : item.fulfilment * 100) + '%',
                    })},[_c('div',{staticClass:"cont-value position-absolute d-flex"},[_c('div',{staticClass:"arrow-left"}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(Number( parseFloat(item.result).toFixed(0) ).toLocaleString("en")))])]),_c('span',{staticClass:"percent"},[_vm._v(_vm._s((item.fulfilment * 100).toFixed())+"%")])])]),_c('hr',{staticClass:"mt-0 pt-0"}),_c('p',{staticClass:"desc text-center"},[_vm._v(_vm._s(item.variable_name))])]})],2):_vm._t("default",function(){return [(item.variable_group.id !== 25)?_c('b-carousel-slide',{attrs:{"img-blank":""}},[_vm._t("default",function(){return [_c('div',{staticClass:"cont-bar-graph position-relative mt-auto mx-auto"},[_c('div',{staticClass:"bar position-absolute d-flex justify-content-center align-items-center",class:index % 2 === 0 ? 'bg-red' : 'bg-yellow',style:({
                        height:
                          (item.fulfilment * 100 > 100
                            ? '100'
                            : item.fulfilment * 100) + '%',
                      })},[_c('div',{staticClass:"cont-value position-absolute d-flex"},[_c('div',{staticClass:"arrow-left"}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(Number( parseFloat(item.result).toFixed(0) ).toLocaleString("en")))])]),_c('span',{staticClass:"percent"},[_vm._v(_vm._s((item.fulfilment * 100).toFixed())+"%")])])]),_c('hr',{staticClass:"mt-0 pt-0"}),_c('p',{staticClass:"desc text-center"},[_vm._v(_vm._s(item.variable_name))])]})],2):_vm._t("default",function(){return [(_vm.title === 'Trimestre')?_c('b-carousel-slide',{attrs:{"img-blank":""}},[_vm._v(" "+_vm._s(item.variable_name)+" "),_c('div',{staticClass:"table-responsive table-layer"},[_c('table',{staticClass:"table table-bordered"},[_c('tbody',[_c('tr',[_c('th',{staticClass:"layer-col"},[_vm._v("Suma de posiciones")]),_c('td',[_vm._v(_vm._s(_vm.getUserResult.sum_positions))])]),_c('tr',[_c('th',{staticClass:"layer-col"},[_vm._v("Ranking KPI")]),_c('td',[_vm._v(_vm._s(item.initial_ranking))])])])])])]):_vm._e()]})]})]})})],2)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }