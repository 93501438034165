<template>
  <div class="tw-w-full he-480 tw-relative">
    <img
      :src="uriResources + '/' + trivia.icon"
      class="tw-w-full he-480 tw-rounded-lg tw-object-cover"
      alt="hojas"
    />
    <div class="tw-absolute w-100 he-480 tw-top-0">
      <div
        class="
          h-100
          w-100
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-3
        "
      >
        <p
          class="tw-font-tolyerBold tw-text-white fs-40 fs-md-100"
          style="text-shadow: 0 4px 10px rgba(0, 0, 0, 0.25)"
        >
          {{ trivia.name }}
        </p>
        <p class="tw-text-white fs-20 fs-md-30 max-wi-500 tw-text-center">
          {{ trivia.desc }}
        </p>
        <button
          @click="startTrivia(2), pushDataLayer('Iniciar Trivia')"
          class="
            tw-bg-red-400
            tw-text-white
            tw-rounded-lg
            py-2
            px-5
            mt-4
            border-0
            hover:tw-bg-red-600
          "
        >
          Iniciar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";

export default {
  name: "HomeTrivia2",
  props: {
    trivia: {
      type: Object,
    },
    datalayer: {
      type: Object,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  methods: {
    startTrivia(state) {
      this.$emit("changeOpcVisible", state);
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: this.datalayer.event,
        Category: this.datalayer.Category,
        SubCategory: this.datalayer.SubCategory,
        Action: this.datalayer.Action,
        Label: label,
      });
    },
  },
};
</script>
