<template>
  <div class="container">
    <div class="d-flex flex-row justify-content-end mb-4 align-items-center">
      <div class="col-9 col-md-10">
        <p class="my-auto tw-font-robotoBold fs-md-25">People Cycle</p>
      </div>
      <div class="col-3 col-md-2">
        <div class="row justify-content-end">
          <div
            role="button"
            class="bg-white wi-30 he-30 wi-md-50 he-md-50 tw-rounded-full shadow-box-1 bg-white d-flex mx-2 justify-content-center align-items-center"
            @click="previous()"
          >
            <i
              class="icon-flecha-izq tw-text-sm lg:tw-text-lg tw-text-red-400"
            ></i>
          </div>
          <div
            role="button"
            class="bg-white wi-30 he-30 wi-md-50 he-md-50 tw-rounded-full shadow-box-1 bg-white d-flex mx-2 justify-content-center align-items-center"
            @click="next()"
          >
            <i
              class="icon-flecha-der tw-text-sm lg:tw-text-lg tw-text-red-400"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cvMixin from "@/mixins/user/cv";

export default {
  name: "PCNavigate",
  mixins: [cvMixin],
  data() {
    return {
      next_path: "",
      previous_path: "",
    };
  },
  props: {
    pc_item: {
      type: Number,
      required: true,
    },
  },
  created() {
    for (let i = 0; i < this.peopleCycleButtons.length; i++) {
      if (this.peopleCycleButtons[i].id === this.pc_item) {
        if (i === 0) {
          this.previous_path =
            this.peopleCycleButtons[this.peopleCycleButtons.length - 1].link;
        } else {
          this.previous_path = this.peopleCycleButtons[i - 1].link;
        }

        if (i === this.peopleCycleButtons.length - 1) {
          this.next_path = this.peopleCycleButtons[0].link;
        } else {
          this.next_path = this.peopleCycleButtons[i + 1].link;
        }
      }
    }
  },
  methods: {
    next() {
      this.$router.push({ path: this.next_path });
    },
    previous() {
      this.$router.push({ path: this.previous_path });
    },
  },
};
</script>

<style scoped></style>
