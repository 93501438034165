<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import { useRoute } from "vue-router/composables";
import { formatDateMMMDD } from "@/helpers/datesHelpers";
import CompContents from "@/components/general/CompContents.vue";
import CompCardArticles from "@/components/articles/CompArticlesCard.vue";
import CompArticlePrincipalCard from "@/components/articles/article-body/CompArticlePrincipalCard";
import CompSocialNetworkInteractionsBig from "@/components/social-network/CompSocialNetworkInteractionsBig.vue";
import CompGeneralTitleBar from "@/components/general/CompGeneralTitleBar.vue";

const route = useRoute();
const articlesStore = useArticlesStore();
const isLoading = ref(false);
const articleId = ref(route.params.id);

const relatedArticles = computed(() => {
  let relatedArticles = [];
  let relatedOtherArticles = [];
  // Related Same Category Articles
  articlesStore.articles.forEach((article) => {
    if (
      article.article_category_id ===
        articlesStore.article.article_category_id &&
      article.id !== articlesStore.article.id
    ) {
      relatedArticles.push(article);
      relatedArticles = relatedArticles
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
    }
  });

  let remainingLength = 3 - relatedArticles.length;

  if (remainingLength > 0) {
    // Related Other Category Articles
    articlesStore.articles.forEach((article) => {
      if (
        article.article_category_id !==
          articlesStore.article.article_category_id &&
        article.id !== articlesStore.article.id
      ) {
        relatedOtherArticles.push(article);
      }
    });

    const shuffled = relatedOtherArticles
      .sort(() => 0.5 - Math.random())
      .slice(0, remainingLength);

    relatedArticles = relatedArticles.concat(shuffled);
  }

  return relatedArticles;
});

const pushDataLayer = () => {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Artículos",
    event_action: "Detalle Artículo",
    event_label: articlesStore.article.title,
    interaction: "False",
    component_name: "PageView",
    element_category: articlesStore.article.author,
    element_text: articlesStore.article.title,
    element_date: formatDateMMMDD(articlesStore.article.created_at),
    element_time: `${articlesStore.article.reading_time} min`,
    element_view: articlesStore.article.views,
  });
};

async function getArticleDetails() {
  await articlesStore.actGetArticleDetail(articleId.value).then(() => {
    if (articlesStore.articles.length === 0) {
      articlesStore.actGetArticles();
    }
  });
}

onBeforeMount(async () => {
  isLoading.value = true;
  await getArticleDetails();
  isLoading.value = false;
  pushDataLayer();
});
</script>
<template>
  <div>
    <comp-general-title-bar title="Artículos" />
    <div class="tw-container-general" v-if="!isLoading">
      <div class="tw-my-6">
        <CompArticlePrincipalCard :article="articlesStore.article" />
        <hr class="tw-w-full tw-bg-gray-250" />
      </div>
      <div class="tw-my-7">
        <CompContents
          v-if="articlesStore.article.content !== undefined"
          :content="articlesStore.article.content"
        />
      </div>
      <!--      <InteractionsArticle :article="articlesStore.article" />-->

      <comp-social-network-interactions-big
        :id="articlesStore.article?.id"
        :title="articlesStore.article?.title"
        :hover="false"
        :category="'NEW_ARTICLES'"
        :user_score_review="articlesStore.article?.user_score_review"
        :user_reaction="articlesStore.article?.user_reaction"
        :image="articlesStore.article?.resource"
        :description="articlesStore.article?.description"
        :item="articlesStore.article"
        @updatedReaction="getArticleDetails()"
        @updatedComment="getArticleDetails()"
      />

      <div class="tw-my-5" id="Related">
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-4xl tw-font-robotoBold"
        >
          Temas Relacionados
        </p>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8 tw-mb-4 md:tw-mb-8"
      >
        <div
          v-for="(article, idx) in relatedArticles"
          :key="idx"
          class="lg:tw-flex lg:tw-flex-col"
        >
          <CompCardArticles
            :article="article"
            :change="false"
            class=""
            category="Temas Relacionados"
          />
          <hr class="md:tw-hidden" />
        </div>
      </div>
    </div>
  </div>
</template>
