<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import { useRatingStore } from "@/stores/ratingStore";
import CompStars from "@/components/rating/CompStars";
import CompShareSocialNetworks from "@/components/social-network/CompShareSocialNetworks";

const { actGetRating } = useRatingStore();

const replayBtn = require("@/assets/img/icons/replay.png");
const videoData = ref({
  videoParentElement: null,
  element: null,
  timeStarted: -1,
  timePlayed: 0,
  duration: 0,
});
const videos = ref(null);
const ratingData = ref({});

const props = defineProps({
  idVideo: {
    type: Number,
    required: true,
  },
  urlVideo: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Califica este video",
  },
});

const detectVideoElement = (e) => {
  if (e.currentTime === e.duration) {
    e.parentElement
      .querySelector(".modal-calification")
      .classList.remove("inactive");

    e.parentElement
      .querySelector(".modal-calification")
      .classList.add("active");
  }

  videoData.value.element = e;
  videoData.value.videoParentElement = e.parentElement;
};

const videoReset = (e) => {
  videoData.value.element =
    e.target.parentElement.parentElement.parentElement.parentElement.querySelector(
      ".videoCalification"
    );
  videoData.value.videoParentElement =
    e.target.parentElement.parentElement.parentElement.parentElement;

  videoData.value.timeStarted = -1;
  videoData.value.timePlayed = 0;
  videoData.value.element.load();
  videoData.value.element.play();
  videoData.value.videoParentElement
    .querySelector(".modal-calification")
    .classList.remove("active");
  videoData.value.videoParentElement
    .querySelector(".modal-calification")
    .classList.add("inactive");
};

const pushDataLayer = (action, actVideo) => {
  window.dataLayer.push({
    event: "TrackEvent-video",
    category: "Universo Cervecero",
    action: action,
    playedFor:
      Math.floor((actVideo.currentTime / actVideo.duration) * 100) + "%",
  });
};

onBeforeMount(() => {
  actGetRating("Videos").then((data) => {
    ratingData.value = data;
  });
});

onMounted(() => {
  videos.value = document.querySelectorAll("video");
  videos.value.forEach((actualVideo) => {
    actualVideo.onplaying = function () {
      detectVideoElement(actualVideo);
      pushDataLayer("Start", actualVideo);
    };

    actualVideo.onpause = function () {
      if (actualVideo.currentTime < actualVideo.duration) {
        detectVideoElement(actualVideo);
        pushDataLayer("Paused", actualVideo);
      }
    };

    actualVideo.onended = function () {
      detectVideoElement(actualVideo);
      pushDataLayer("Complete", actualVideo);
    };
  });
});
</script>
<template>
  <div>
    <div
      :id="'video-' + props.idVideo"
      class="cont-calification-video tw-relative tw-overflow-hidden"
    >
      <video
        class="videoCalification tw-w-full"
        width="100%"
        height="auto"
        controls
      >
        <source :src="props.urlVideo" type="video/mp4" />
        Su navegador no soporta videos.
      </video>
      <div
        class="modal-calification tw-absolute tw-flex tw-justify-center tw-items-center tw-left-0 tw-w-full tw-h-full tw-transition-all inactive"
      >
        <div class="cont-text tw-text-center tw-text-white">
          <p
            class="title tw-mb-0 md:tw-mb-3 tw-text-lg tw-font-robotoBold md:tw-text-3xl"
          >
            {{ props.title }}
          </p>
          <div class="video-stars tw-mx-auto tw-w-44">
            <CompStars
              category="Videos"
              :idContent="props.idVideo"
              :ratingData="ratingData[idVideo]"
              :showStars="true"
            />
          </div>
          <p
            class="text-replay tw-flex tw-items-center tw-justify-center tw-text-sm tw-cursor-pointer"
            @click="videoReset($event)"
          >
            <img
              :src="replayBtn"
              alt="replay"
              width="14"
              height="14"
              class="mr-2"
            />
            <span>Reproducir de nuevo</span>
          </p>
        </div>
      </div>
    </div>
    <CompShareSocialNetworks
      :url="props.urlVideo"
      title="Video"
      v-bind="$attrs"
    />
  </div>
</template>

<style lang="css" scoped>
.cont-calification-video .modal-calification {
  background: linear-gradient(
    0deg,
    rgba(22, 22, 22, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.cont-calification-video .inactive {
  top: 100%;
}

.cont-calification-video .active {
  top: 0;
}
</style>
