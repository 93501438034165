import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  vacantsUri: "/peopleVacantPositions",
  applyVacantUri: "/peopleVacantPositionsEmployees",
  termsUri: "/coreVps",
  placesUri: "/coreSites",
  sideLinesUri: "/corePaSidelines",
};

export const useVacantsStore = defineStore("vacantsStore", () => {
  const vacants = ref([]);
  const vacantRequests = ref([]);
  const vacantRequestsByLineManager = ref([]);
  const vacantSelected = ref({});
  const activeRequestComponent = ref("Request");
  const places = ref([]);
  const terms = ref([]);
  const sidelines = ref([]);

  async function actGetVacants() {
    const response = await AxiosBeerOffice.get(uri.vacantsUri, true, {});
    if (response.error) return response.error;
    vacants.value = response.data;
    return true;
  }

  async function actGetTerms() {
    const response = await AxiosBeerOffice.get(uri.termsUri, true, {});
    if (response.error) return response.error;
    terms.value = response.data;
    return true;
  }

  async function actGetPlaces() {
    const response = await AxiosBeerOffice.get(uri.placesUri, true, {});
    if (response.error) return response.error;
    places.value = response.data;
    return true;
  }

  async function actGetSideLines() {
    const response = await AxiosBeerOffice.get(uri.sideLinesUri, true, {});
    if (response.error) return response.error;
    sidelines.value = response.data;
    return true;
  }

  async function actGetVacantById(id) {
    const response = await AxiosBeerOffice.get(
      uri.vacantsUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    vacantSelected.value = response.data;
    return true;
  }

  async function actGetSearchVacants(data) {
    const response = await AxiosBeerOffice.get(
      uri.vacantsUri +
        "?vp=" +
        data.area +
        "&site=" +
        data.site +
        "&sideline=" +
        data.sideline +
        "&text=" +
        data.text,
      true,
      {}
    );
    if (response.error) return response.error;
    vacants.value = response.data;
    return true;
  }

  async function actPostApplyVacant(data) {
    const response = await AxiosBeerOffice.post(uri.applyVacantUri, true, {
      people_vacant_position_id: data,
    });
    if (response.error) return response.error;
    return true;
  }

  async function actGetVacantRequests() {
    const response = await AxiosBeerOffice.get(
      uri.vacantsUri + "/applied",
      true,
      {}
    );
    if (response.error) return response.error;
    vacantRequests.value = response.data;
    return true;
  }

  async function actGetVacantRequestsByLineManager() {
    const response = await AxiosBeerOffice.get(
      uri.vacantsUri + "/byLineManager",
      true,
      {}
    );
    if (response.error) return response.error;
    vacantRequestsByLineManager.value = response.data;
    return true;
  }

  async function actPostChangeVacantState(data) {
    const response = await AxiosBeerOffice.post(
      uri.vacantsUri + "/" + data.id + "/approved",
      true,
      {
        is_approved: data.is_approved,
      }
    );
    if (response.error) return response.error;
    return true;
  }

  function changeRequestMainComponent(component) {
    activeRequestComponent.value = component;
  }

  return {
    vacants,
    vacantRequests,
    vacantRequestsByLineManager,
    vacantSelected,
    activeRequestComponent,
    places,
    terms,
    sidelines,
    actGetVacants,
    actGetTerms,
    actGetPlaces,
    actGetSideLines,
    actGetVacantById,
    actGetSearchVacants,
    actPostApplyVacant,
    actGetVacantRequests,
    actGetVacantRequestsByLineManager,
    actPostChangeVacantState,
    changeRequestMainComponent,
  };
});
