// define a new store in pinia with store setup
import { defineStore } from "pinia";
import { ref, computed } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  articles: "/articles",
  articleCategories: "/articleCategories",
  suggestedArticle: "/suggestedArticles",
};

export const useArticlesStore = defineStore("articles", () => {
  const articles = ref([]);
  const articleCategories = ref([]);
  const article = ref({});

  const getArticlesGeneral = computed(() => {
    return articles.value.filter((item) => item.featured === 0);
  });
  const getArticlesFeatured = computed(() => {
    return articles.value.filter((item) => item.featured === 1);
  });

  async function actGetArticles() {
    const response = await AxiosBeerOffice.get(uri.articles, true, {});
    if (response.error) return response.error;
    const { data: articlesData } = response;
    articles.value = articlesData;
    return true;
  }

  async function actGetArticlesByCategory(category_key) {
    const response = await AxiosBeerOffice.get(
      `${uri.articles}?filter={"article_category_key":"${category_key}"}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data: articlesData } = response;
    articles.value = articlesData;
    return true;
  }

  async function actGetArticleCategories() {
    const response = await AxiosBeerOffice.get(uri.articleCategories, true);
    if (response.error) return response.error;
    const { data: articleCategoriesData } = response;
    articleCategories.value = articleCategoriesData;
    return true;
  }

  async function actPostSuggestedArticle(data) {
    const response = await AxiosBeerOffice.post(
      uri.suggestedArticle,
      true,
      data,
      true
    );
    if (response.error) {
      console.error(response.error);
      return response.error;
    }
    return true;
  }

  async function actGetArticleDetail(id) {
    const response = await AxiosBeerOffice.get(`${uri.articles}/${id}`, true);
    if (response.error) return response.error;
    const { data: articleData } = response;
    article.value = articleData;
    return true;
  }

  return {
    articles,
    articleCategories,
    article,
    actGetArticles,
    actGetArticlesByCategory,
    actGetArticleCategories,
    actGetArticleDetail,
    actPostSuggestedArticle,
    getArticlesFeatured,
    getArticlesGeneral,
  };
});
