import ApiBeerOffice from "@/services/apiBeerOffice";
import config from "@/services/config";

export default {
  namespaced: true,
  state: {
    token: sessionStorage.getItem("token") || "",
    login: 0,
    legalDocs: [],
    user:
      localStorage.getItem("user") !== "undefined"
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    setPassword: 0,
    resources: [],
    childrens: [],
    families: [],
    familiesDefault: [],
    familiesOthers: [],
    pets: [],
    petsDefault: [],
    petsOthers: [],
    hobbies: [],
    hobbiesDefault: [],
    hobbiesOthers: [],
    deliverySite: null,
    deliveryMenuSiteId: 0,
    avatar: null,
    barP: 0,
    userDefault: {
      id: 0,
      prof_profile_id: 0,
    },
    userTapitData: {},
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getSectionShortcuts: (state) => {
      if (state.user.custom_home_object) {
        const sectionShortcuts = JSON.parse(state.user.custom_home_object);
        if (Array.isArray(sectionShortcuts)) {
          return sectionShortcuts.slice(0, 4);
        }
        return [];
      }
      return [];
    },
    getURLResourceByKey:
      (state) =>
      (key = null) => {
        let resource = null;
        if (key) {
          resource = state.resources.find((resource) => resource.key === key);
        }

        return `${config.beerOfficeURL}/storage/documents/${
          resource && resource.filename ? resource.filename : ""
        }`;
      },
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    setLegalDocs: (state, legalDocs) => {
      state.legalDocs = legalDocs;
    },
    setUserShortcuts: (state, shortcuts) => {
      state.user.custom_home_object = shortcuts;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setUserTapitData: (state, userTapitData) => {
      state.userTapitData = userTapitData;
    },
    setTapitAccount: (state, data) => {
      state.user.tapit_id = data.tapit_id;
      state.user.tapit_email = data.tapit_email;
      state.user.tapit_identity = data.tapit_identity;
      state.user.customToken = data.customToken;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setSharedResources: (state, resources) => {
      state.resources = resources;
    },
    setPasswordUpdate: (state, setPassword) => {
      state.setPassword = setPassword;
    },
    setChildrens: (state, childrens) => {
      state.childrens = childrens;
    },
    addChildren: (state) => {
      state.childrens.push({ birthdate: "" });
    },
    setFamilies: (state, families) => {
      state.families = families;
    },
    setFamiliesDefault: (state, familiesDefault) => {
      state.familiesDefault = familiesDefault;
      state.familiesOthers = state.families;

      for (let i in state.familiesDefault) {
        state.familiesDefault[i].active = 0;
        if (state.familiesOthers !== []) {
          for (let j in state.familiesOthers) {
            if (state.familiesOthers[j] === state.familiesDefault[i].name) {
              state.familiesDefault[i].active = 1;
              state.familiesOthers.splice(j, 1);
            }
          }
        }
      }
    },
    addFamily: (state, family) => {
      let value = 0;
      state.families.forEach((e) => {
        if (e === family) {
          value = 1;
        }
      });
      if (value !== 1) {
        state.families.push(family);
      }
    },
    sFamily: (state, families) => {
      state.familiesOthers = families;
    },
    /*sFamiliesDefault: (state, familiesDefault) => {
      state.familiesDefault = familiesDefault;
    },*/
    setPets: (state, pets) => {
      state.pets = pets;
    },
    setPetsDefault: (state, petsDefault) => {
      state.petsDefault = petsDefault;
      state.petsOthers = state.pets;

      for (let i in state.petsDefault) {
        if (state.petsOthers !== []) {
          state.petsDefault[i].active = 0;
          for (let j in state.petsOthers) {
            if (state.petsOthers[j] === state.petsDefault[i].name) {
              state.petsDefault[i].active = 1;
              state.petsOthers.splice(j, 1);
            }
          }
        }
      }
    },
    addPet: (state, pet) => {
      let value = 0;
      state.pets.forEach((e) => {
        if (e === pet) {
          value = 1;
        }
      });
      if (value !== 1) {
        state.pets.push(pet);
      }
    },
    sPet: (state, pets) => {
      state.petsOthers = pets;
    },
    setHobbies: (state, hobbies) => {
      state.hobbies = hobbies;
    },
    setHobbiesDefault: (state, hobbiesDefault) => {
      state.hobbiesDefault = hobbiesDefault;
      state.hobbiesOthers = state.hobbies;

      for (let i in state.hobbiesDefault) {
        state.hobbiesDefault[i].active = 0;
        if (state.hobbiesOthers !== []) {
          for (let j in state.hobbiesOthers) {
            if (state.hobbiesOthers[j] === state.hobbiesDefault[i].name) {
              state.hobbiesDefault[i].active = 1;
              state.hobbiesOthers.splice(j, 1);
            }
          }
        }
      }
    },
    addHobbie: (state, hobbie) => {
      let value = 0;
      state.hobbies.forEach((e) => {
        if (e === hobbie) {
          value = 1;
        }
      });
      if (value !== 1) {
        state.hobbies.push(hobbie);
      }
    },
    sHobbie: (state, hobbies) => {
      state.hobbiesOthers = hobbies;
    },
    subsFamily: (state, index) => {
      state.familiesOthers.splice(index, 1);
    },
    subsPet: (state, index) => {
      state.petsOthers.splice(index, 1);
    },
    subsHobbie: (state, index) => {
      state.hobbies.splice(index, 1);
    },
    subsChild: (state, index) => {
      state.childrens.splice(index, 1);
    },
    updateDeliverySite(state, deliverySite) {
      state.deliverySite = deliverySite;
    },
    setDeliveryMenuSiteId: (state, deliveryMenuSiteId) => {
      state.deliveryMenuSiteId = deliveryMenuSiteId;
    },
    logout(state) {
      state.token = null;
      state.user = null;
    },
  },
  actions: {
    /*
     *
     * DATA USER
     *
     */
    actValidateToken: async () => {
      try {
        const response = await ApiBeerOffice.validateToken();
        return !response.error;
      } catch (error) {
        return false;
      }
    },
    /*
     *
     * DATA USER
     *
     */
    actUser: async ({ commit, state }) => {
      let response = await ApiBeerOffice.core_employee(state.user);
      if (response.error) {
        return response;
      }
      commit("setUser", response.data);
      return response.data;
    },
    /*
     *
     *   AUTHENTICATION
     *
     */
    actAuthAcount: async ({ commit }, data) => {
      let response = await ApiBeerOffice.identification_account(data);
      if (response.error) {
        return response.error;
      }
      commit("setUser", response.data.user);
      sessionStorage.setItem("token", response.data.api_token);
      return true;
    },
    actAuthLogin: async ({ commit }, data) => {
      let response = await ApiBeerOffice.identification_access(data);
      if (response.error) {
        return response.error;
      }
      commit("setUser", response.data.user);
      sessionStorage.setItem("token", response.data.api_token);
      return true;
    },
    actAuthLoginSSO: async ({ commit }, data) => {
      let response = await ApiBeerOffice.accessSSO(data);
      if (response.error) {
        return response.error;
      }
      commit("setUser", response.data.user);
      sessionStorage.setItem("token", response.data.api_token);
      return true;
    },
    actLogout: async ({ commit }) => {
      localStorage.clear();
      sessionStorage.clear();
      commit("logout");
      return true;
    },
    actAuthActivate: async ({ state }, data) => {
      data.id = state.user.id;
      if (data.setPassword !== 1) {
        data.cc = state.user.cc;
      }
      let response = await ApiBeerOffice.identification_activate(data);
      if (response.error) {
        return response.error;
      }
      return true;
    },
    actContentDocuments: ({ commit }) => {
      ApiBeerOffice.content_documents().then((response) => {
        commit("setLegalDocs", response.data);
      });
    },
    actSharedResources: async ({ commit }) => {
      await ApiBeerOffice.getSharedResources().then((response) => {
        commit("setSharedResources", response.data);
      });
    },
    actPatchUserShortcuts: async ({ commit }, data) => {
      let response = await ApiBeerOffice.set_favorites(data);
      if (response.error) {
        return response.error;
      }
      commit("setUserShortcuts", response.data.custom_home_object);
      return response;
    },
    actUserTapitAccount: ({ commit }, data) => {
      ApiBeerOffice.set_tapitAccount(data).then((response) => {
        commit("setTapitAccount", response.data);
      });
    },
    actUserUpdate: ({ commit, state }, data) => {
      console.log("actUserUpdate", data);
      data.id = state.user.id;
      ApiBeerOffice.profilling_about_me_data(data).then((response) => {
        if (response.data) {
          commit("setUser", response.data);
        }
      });
    },
    actGetChildrens: ({ commit, state }) => {
      ApiBeerOffice.profilling_childrens(state.user.id).then((response) => {
        commit(
          "setChildrens",
          response.data.map((e) => ({ birthdate: e.birthdate }))
        );
      });
    },
    actUpdateChildrens: ({ commit, state }) => {
      ApiBeerOffice.profilling_childrensUpdate({
        id: state.user.id,
        cc: state.user.cc,
        childrens: JSON.stringify(state.childrens),
      }).then((response) => {
        commit("setChildrens", response.data);
      });
    },

    actGetFamilies: ({ commit }) => {
      ApiBeerOffice.profilling_families().then((response) => {
        commit("setFamiliesDefault", response.data);
      });
    },
    actGetFamiliesById: ({ commit, state }) => {
      ApiBeerOffice.profilling_familiesById(state.user.id).then((response) => {
        commit(
          "setFamilies",
          response.data.map((e) => e.name)
        );
      });
    },
    actUpdateFamilies: ({ commit, state }) => {
      state.families.forEach((e, index) => {
        if (e.name === "") {
          state.families.splice(index, 1);
        }
      });
      ApiBeerOffice.profilling_familiesUpdate({
        id: state.user.id,
        cc: state.user.cc,
        family: JSON.stringify(state.families),
      }).then((response) => {
        commit("setFamilies", response.data);
      });
    },
    actGetPets: ({ commit }) => {
      ApiBeerOffice.profilling_pets().then((response) => {
        commit("setPetsDefault", response.data);
      });
    },
    actGetPetsById: ({ commit, state }) => {
      ApiBeerOffice.profilling_petsById(state.user.id).then((response) => {
        commit(
          "setPets",
          response.data.map((e) => e.name)
        );
      });
    },
    actUpdatePets: ({ commit, state }) => {
      state.pets.forEach((e, index) => {
        if (e.name === "") {
          state.pets.splice(index, 1);
        }
      });
      ApiBeerOffice.profilling_petsUpdate({
        id: state.user.id,
        cc: state.user.cc,
        pets: JSON.stringify(state.pets),
      }).then((response) => {
        commit("setPets", response.data);
      });
    },
    actGetHobbies: ({ commit }) => {
      ApiBeerOffice.profilling_hobbies().then((response) => {
        commit("setHobbiesDefault", response.data);
      });
    },
    actGetHobbiesById: ({ commit, state }) => {
      ApiBeerOffice.profilling_hobbiesById(state.user.id).then((response) => {
        commit(
          "setHobbies",
          response.data.map((e) => e.name)
        );
      });
    },
    actUpdateHobbies: ({ commit, state }) => {
      state.hobbies.forEach((e, index) => {
        if (e.name === "") {
          state.hobbies.splice(index, 1);
        }
      });
      ApiBeerOffice.profilling_hobbiesUpdate({
        id: state.user.id,
        cc: state.user.cc,
        hobbies: JSON.stringify(state.hobbies),
      }).then((response) => {
        commit("setHobbies", response.data);
      });
    },
    actUpdateImageProfile: async ({ state }, data) => {
      return ApiBeerOffice.core_employee_avatar(data, state.user.id);
    },
    actUpdateAvatar: async ({ commit, state }, data) => {
      let response = await ApiBeerOffice.updateCoreEmployeesAvatar(
        state.user.id,
        {
          custom_avatar_object: JSON.stringify(data.avatar),
          is_predefined_avatar: data.is_predefined_avatar,
          predefined_avatar_id: data.predefined_avatar_id,
        }
      );

      if (response.error) {
        return response.error;
      }
      commit("setUser", response.data);
      return response.data;
    },
    actDeliverySiteUpdate: async ({ commit, state }, data) => {
      ApiBeerOffice.core_employee_delivery(state.user.id, {
        ecomm_delivery_site_id: data,
      }).then((response) => {
        commit("setUser", response.data);
      });
    },
    updateDeliveryMenuSiteid: async ({ commit }, data) => {
      let response = await ApiBeerOffice.food_menu_delivery(data.userId, {
        ben_menu_site_id: data.siteId,
      });
      if (response.error) {
        return response.error;
      } else {
        commit("setUser", response.data);
        commit("setDeliveryMenuSiteId", response.data.ben_menu_site_id);
      }
    },
  },
};
