export const formatDateDDMMYYYY = (date, separator = "/", MMM = false) => {
  // Convertir la entrada a un objeto Date
  let inputDate;
  if (typeof date === "string") {
    // remove the part when it has T
    date = date.split("T")[0];
    inputDate = new Date(date + "T00:00:00"); // Forzar a medianoche en UTC
  } else {
    inputDate = new Date(date);
  }

  // Opciones para formatear la fecha
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  };
  const formattedDate = inputDate.toLocaleDateString("es-ES", options);

  if (separator === "-") {
    // Si el separador es un guion, reemplazamos las barras con guiones
    return formattedDate.replace(/\//g, "-");
  }

  if (MMM) {
    const [day, month, year] = formattedDate.split("/");
    return `${day}/${getMonthName(parseInt(month))}/${year}`;
  }

  return formattedDate;
};

export const formatDateYYYYMMDD = (date, separator = "/") => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = new Date(date).toLocaleDateString("es-ES", options);
  const [day, month, year] = formattedDate.split("/");

  return separator === "-"
    ? `${year}-${month}-${day}`
    : `${year}/${month}/${day}`;
};

export const compareDateWithToday = (date) => {
  const today = new Date();
  const dateToCompare = new Date(date);
  return today <= dateToCompare;
};

export const todayIsBetweenDates = (startDate, endDate) => {
  const today = new Date();
  const dateToCompareStart = new Date(startDate);
  const dateToCompareEnd = new Date(endDate);
  return today >= dateToCompareStart && today <= dateToCompareEnd;
};

export const isPastDate = (date) => {
  const today = new Date();
  const dateToCompare = new Date(date);
  return today > dateToCompare;
};

export const isFutureDate = (date) => {
  const today = new Date();
  const dateToCompare = new Date(date);
  return today < dateToCompare;
};

export const isStillTime = (date) => {
  const today = new Date();
  const dateToCompare = new Date(date);
  return today <= dateToCompare;
};

export const formatTimeHHMM = (time) => {
  const date = new Date(`1970-01-01T${time}Z`);
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatTimeToAMPM = (time) => {
  const [hours, minutes, seconds] = time.split(":");
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
/*export const formatDateYYYYMMDDLong = (date) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return new Date(date).toLocaleDateString("es-ES", options);
};*/

export const formatDateYYYYMMDDLong = (date) => {
  const dateObject = new Date(date + "T00:00:00Z");

  const year = dateObject.getUTCFullYear();
  const month = dateObject.toLocaleString("es-ES", {
    month: "long",
    timeZone: "UTC",
  });
  const day = dateObject.getUTCDate();

  return `${day} de ${month} de ${year}`;
};

export const formatDateMMMDD = (date) => {
  const options = { day: "2-digit", month: "short" };
  return new Date(date).toLocaleDateString("es-ES", options);
};

export const parseDayAndMonth = (date) => {
  let dateArray = date.split("-");
  return parseInt(dateArray[2]) + " " + getMonthName(parseInt(dateArray[1]));
};

export const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "Ene";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Abr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Ago";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dic";
    default:
      return "";
  }
};

export const calculateDaysToToday = (date) => {
  const today = new Date();
  const dateToCompare = new Date(`${date}T00:00:00`);

  // Convertir a medianoche UTC
  today.setHours(0, 0, 0, 0);
  dateToCompare.setHours(0, 0, 0, 0);

  const diffTime = Math.abs(today.getTime() - dateToCompare.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDateShortDDMMM = (date) => {
  const options = { day: "2-digit", month: "short" };
  return new Date(date).toLocaleDateString("es-ES", options);
};

export const formatDateDDMMMM = (date) => {
  const dateObject = new Date(date + "T00:00:00Z");

  const month = dateObject.toLocaleString("es-ES", {
    month: "long",
    timeZone: "UTC",
  });
  const day = dateObject.getUTCDate();

  return `${day} de ${month}`;
};

export const differenceInDays = (date1, date2) => {
  const date1utc = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const date2utc = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  return Math.floor((date2utc - date1utc) / (1000 * 60 * 60 * 24));
};

export const orderArrayByDateAsc = (array, key) => {
  return array.sort((a, b) => {
    return new Date(a[key]) - new Date(b[key]);
  });
};

export const orderArrayByDateDesc = (array, key) => {
  return array.sort((a, b) => {
    return new Date(b[key]) - new Date(a[key]);
  });
};
