<template>
  <div class="mb-2 mx-3 min-wi-164">
    <div class="tw-shadow-sm tw-rounded-lg tw-bg-white">
      <div
        class="
          tw-bg-brown-400
          text-center
          py-3
          px-3
          tw-rounded-tl-lg tw-rounded-tr-lg
          he-60
        "
      >
        <p class="mb-0 tw-text-white  tw-text-xs ">
          {{ metric.name }}
        </p>
      </div>
      <div class="tw-bg-white tw-rounded-lg">
        <div
          class="d-flex justify-content-around my-3 align-items-center he-50"
        >
          <p class="tw-text-red-400 tw-text-xl font-weight-bold mb-0">
            {{ Number((metric.result / metric.target) * 100).toFixed(2) }}%
          </p>
          <img :src="imageUrl" alt="beer 1" width="45" />
        </div>
        <div class="text-center mb-3">
          <p class="mb-0 tw-text-red-400  tw-text-xs  font-weight-bold">
            Total pago:
            <span class="tw-text-gray-600"
              >${{ formatNumber(metric.partial_salary, 0) }}</span
            >
          </p>
        </div>
        <table class="w-100">
          <tr class="tw-bg-gray-200 text-center">
            <td class="py-2">
              <p class="tw-text-gray-400 mb-0  tw-text-xs ">Máximo</p>
            </td>
            <td class="py-2">
              <p class="tw-text-gray-400 mb-0  tw-text-xs ">
                {{ (metric.cump_payment_max * 100).toFixed(0) }}%
              </p>
            </td>
            <td class="py-2">
              <p class="tw-text-gray-600 mb-0  tw-text-xs ">
                {{
                  (Number(metric.target) * metric.cump_payment_max)
                    .toFixed(0)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                }}{{ title == "Volumen" ? ".hl" : "" }}
              </p>
            </td>
            <td class="py-2">
              <div
                class="
                  wi-10
                  he-10
                  tw-rounded-full
                  tw-bg-green-400
                  d-flex
                  justify-content-center
                  align-items-center
                "
                v-if="
                  Number(metric.fulfilment).toFixed(2) >=
                  metric.cump_payment_max
                "
              >
                <i class="tw-text-white  tw-text-xxxs  icon-check-simple"></i>
              </div>
              <div
                v-else
                class="
                  wi-10
                  he-10
                  tw-rounded-full
                  tw-bg-red-400
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span class="tw-text-white  tw-text-xs ">-</span>
              </div>
            </td>
          </tr>
          <tr class="tw-bg-gray-100 text-center">
            <td class="py-2">
              <p class="tw-text-gray-400 mb-0 tw-text-xxs ">Meta</p>
            </td>
            <td class="py-2">
              <p class="tw-text-gray-400 mb-0 tw-text-xxs ">100%</p>
            </td>
            <td class="py-2">
              <p class="tw-text-gray-600 mb-0 tw-text-xxs ">
                {{ Number(metric.target).toFixed(0)
                }}{{ title == "Volumen" ? ".hl" : "" }}
              </p>
            </td>
            <td class="py-2">
              <div
                class="
                  wi-10
                  he-10
                  tw-rounded-full
                  tw-bg-green-400
                  d-flex
                  justify-content-center
                  align-items-center
                "
                v-if="Number(metric.fulfilment).toFixed(2) >= 1"
              >
                <i class="tw-text-white  tw-text-xxxs  icon-check-simple"></i>
              </div>
              <div
                v-else
                class="
                  wi-10
                  he-10
                  tw-rounded-full
                  tw-bg-red-400
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span class="tw-text-white  tw-text-xs ">-</span>
              </div>
            </td>
          </tr>
          <tr class="tw-bg-gray-200 text-center">
            <td class="py-2">
              <p class="tw-text-gray-400 mb-0  tw-text-xs ">Mínimo</p>
            </td>
            <td class="py-2">
              <p class="tw-text-gray-400 mb-0  tw-text-xs ">
                {{ (metric.cump_payment_min * 100).toFixed(0) }}%
              </p>
            </td>
            <td class="py-2">
              <p class="tw-text-gray-600 mb-0  tw-text-xs ">
                {{
                  (Number(metric.target) * metric.cump_payment_min)
                    .toFixed(0)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                }}{{ title == "Volumen" ? ".hl" : "" }}
              </p>
            </td>
            <td class="py-2">
              <div
                class="
                  wi-10
                  he-10
                  tw-rounded-full
                  tw-bg-green-400
                  d-flex
                  justify-content-center
                  align-items-center
                "
                v-if="
                  Number(metric.fulfilment).toFixed(2) >=
                  metric.cump_payment_min
                "
              >
                <i class="tw-text-white  tw-text-xxxs  icon-check-simple"></i>
              </div>
              <div
                v-else
                class="
                  wi-10
                  he-10
                  tw-rounded-full
                  tw-bg-red-400
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span class="tw-text-white  tw-text-xs ">-</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="tw-bg-white tw-shadow-md tw-rounded-lg mt-4">
      <div
        class="
          tw-bg-gray-200
          py-2
          tw-rounded-tl-lg tw-rounded-tr-lg
          d-flex
          justify-content-between
          px-3
        "
      >
        <p class="mb-0 tw-text-gray-600  tw-text-xxxs  font-weight-bold">Proyecta tu KPI</p>
        <p class=" tw-text-xxs  tw-text-gray-400 mb-0 font-weight-bold">
          Peso: {{ weigthKpi }}%
        </p>
      </div>
      <div class="tw-bg-white p-3 tw-rounded-lg">
        <div class="d-flex justify-content-between">
          <div>
            <p class="tw-text-gray-600  tw-text-xxs  mb-2 font-weight-bold">Resultado</p>
            <input
              type="text"
              class="
                tw-shadow-sm
                tw-border-0
                wi-80
                tw-rounded-lg
                outline-none
                px-2
                py-1
                 tw-text-xxs 
              "
              v-model="percentValue"
            />
          </div>
          <div>
            <p class="tw-text-gray-600  tw-text-xxs  mb-2 font-weight-bold">Cump.</p>
            <p class="mb-0 tw-text-gray-600  tw-text-xxs  pt-2">
              = {{ calcFullfilment }}%
            </p>
          </div>
        </div>
        <div class="d-flex mt-2">
          <p class="mb-0 tw-text-gray-600  tw-text-xxs  mr-3">Subtotal:</p>
          <div class="w-100 tw-border-b tw-border-solid tw-border-gray-300 ">
            <p class="mb-0 tw-text-green-400  tw-text-xs  text-right">
              $ {{ formatNumber(calcSubtotal) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numbersMixin from "@/mixins/general/numbers";

export default {
  name: "CompInnerTableProgress",
  props: {
    title: {
      type: String,
    },
    metric: {
      type: Object,
      required: true,
    },
    rates: {
      type: Array,
      value: [],
      required: true,
    },
    salaryBase: {
      type: Number,
      value: 0,
      required: true,
    },
  },
  mixins: [numbersMixin],
  data() {
    return {
      percentValue: 0,
    };
  },
  computed: {
    fullBeer() {
      let numberBeer = 0;
      let fulfilment = Number(this.metric.fulfilment);
      switch (true) {
        case fulfilment >= this.metric.cump_payment_max:
          numberBeer = 3;
          break;

        case fulfilment >= 1:
          numberBeer = 2;
          break;

        default:
          numberBeer = 1;
          break;
      }

      return numberBeer;
    },

    imageUrl() {
      return require(`@/assets/img/icons/beer/${this.fullBeer}.png`);
    },
    calcFullfilment() {
      return ((this.percentValue / Number(this.metric.target)) * 100).toFixed(
        1
      );
    },
    calcSubtotal() {
      const variableCasual = this.percentValue / this.metric.target;
      const rate = this.getRate(variableCasual);

      const result =
        this.metric.variable_salary_weight * rate * this.salaryBase;

      return result.toFixed(0);
    },
    weigthKpi() {
      return this.metric.variable_salary_weight * 100;
    },
  },
  methods: {
    getRate(value) {
      if (value < this.rates[0].min) {
        return 0;
      }
      const ob = this.rates.filter(
        (rate) => value >= rate.min && value < rate.max
      );
      if (ob.length === 0) {
        return this.rates[this.rates.length - 1].payment;
      }

      return ob[0].payment;
    },
  },
};
</script>
