<script setup>
import { getCurrentInstance, onMounted, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router/composables";
import { useCoreStore } from "@/stores/coreStore";
import HelpersGrid from "@/components/contextual-helpers/CompContextualHelpersGrid.vue";
import Mailbox from "@/components/support/CompMailbox.vue";
import GeneralModal from "@/components/modals/GeneralModal";
const { $isMobile } = getCurrentInstance().proxy;
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { pushLink } from "@/helpers/linkHelpers";

const route = useRoute();
const router = useRouter();
const coreStore = useCoreStore();
const target = ref(null);
const isLoading = ref(false);
const isOpen = ref(false);
const isHelpersModal = ref(false);
const isSuggestionsModal = ref(false);
const seenRoute = ref(false);
const routeHasHelpers = ref(false);

onClickOutside(target, () => {
  closeSupportModal();
});

const getSeenRoute = async () => {
  let routeEval = route.path.replace("/", "");
  await coreStore
    .actGetSeenContextualHelper(routeEval)
    .then((response) => {
      seenRoute.value = response;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const routeHelpers = () => {
  if (seenRoute.value) {
    if ($isMobile()) {
      router.push({
        name: "ContextualHelpers",
        params: { originRoute: route.path },
      });
    } else {
      showHelpersModal();
    }
  } else {
    router.push({
      path: "/contextual-helpers/routes",
      query: {
        route: route.path.replace("/", ""),
        helper: "all",
      },
    });
  }
};

const toggleSupportModal = () => {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    pushDataLayerGeneral();
  }
};

const closeSupportModal = () => {
  isHelpersModal.value = false;
  isOpen.value = false;
};

const showHelpersModal = () => {
  isHelpersModal.value = true;
};

const hideHelpersModal = () => {
  isHelpersModal.value = false;
  isOpen.value = true;
};

const showSuggestionsModal = () => {
  isSuggestionsModal.value = true;
  isOpen.value = false;
};

const hideSuggestionsModal = () => {
  isSuggestionsModal.value = false;
  isOpen.value = true;
};

// const whatsappSupport = () => {
//   pushDataLayerSoporte("Soporte de Whatsapp");
//   window.open("https://wa.me/573176437917?text=Hola%20Embajadores", "_blank");
// };

const suggestionsMailbox = () => {
  pushDataLayerSoporte("Buzón de sugerencias");
  if ($isMobile()) {
    showSuggestionsModal();
  } else {
    router.push({ name: "SuggestionsMailbox" });
  }
};

const pushDataLayerGeneral = () => {
  pushDataLayer({
    event_category: "Home",
    event_action: "Interacciones laterales",
    event_label: "Soporte",
    interaction: "True",
    component_name: "Click",
  });
};

const pushDataLayerSoporte = (label) => {
  pushDataLayer({
    event_category: "Soporte",
    event_action: "¿Cómo usar embajadores?",
    event_label: label,
    interaction: "True",
    component_name: "Click",
    element_category: "Soporte",
    page: route.path,
  });
};

onMounted(async () => {
  if (
    route.path !== "/contextual-helpers/routes" &&
    route.path !== "/contextual-helpers"
  ) {
    isLoading.value = true;
    await coreStore
      .actGetContextualHelpers(route.path.replace("/", ""))
      .then((response) => {
        if (response.length > 0) {
          routeHasHelpers.value = true;
          getSeenRoute();
        } else {
          routeHasHelpers.value = false;
          isLoading.value = false;
        }
      });
  }
});
</script>
<template>
  <div>
    <div v-if="!isLoading" class="supportButton">
      <portal to="all-modals">
        <general-modal
          v-if="isSuggestionsModal"
          modalName="suggestion"
          @close="hideSuggestionsModal"
          :isShowModal="isSuggestionsModal"
          :modalWidth="{ md: 500, lg: 600, xl: 600 }"
          :maxHeight="{ md: 500, lg: 600, xl: 700 }"
          :padding-x="false"
        >
          <div class="tw-flex tw-flex-col tw-gap-3">
            <div class="tw-px-6">
              <p
                class="tw-font-robotoBold tw-text-lg lg:tw-text-2xl lg:tw-leading-7 tw-text-red-350 tw-m-0"
              >
                Buzón de Sugerencias
              </p>
            </div>
            <mailbox @close="hideSuggestionsModal" />
          </div>
        </general-modal>
      </portal>
      <div class="tw-fixed tw-right-6 tw-bottom-56 tw-z-40">
        <div
          id="modalHelpers"
          class="tw-z-3 tw-absolute tw-bottom-0 tw-right-0 tw-flex tw-bg-white tw-rounded-3xl tw-p-4 lg:tw-w-96"
          v-if="isHelpersModal"
          ref="target"
          style="max-height: 620px"
        >
          <HelpersGrid
            :route="route.path.replace('/', '')"
            @close="hideHelpersModal"
            @datalayer="pushDataLayerSoporte"
          />
        </div>
        <div class="tw-relative">
          <div
            id="popupDiv"
            v-if="!isHelpersModal"
            class="tw-z-2 tw-absolute tw-bottom-0 tw-right-0 tw-flex tw-flex-col tw-items-end"
            :class="
              isHelpersModal
                ? 'tw-hidden'
                : isOpen
                ? 'tw-bg-white tw-rounded-3xl tw-p-5'
                : 'tw-bg-red-350 tw-rounded-full tw-p-1 tw-cursor-pointer'
            "
          >
            <div
              class="animateDiv"
              :class="
                isOpen
                  ? 'tw-block tw-w-full tw-h-full'
                  : 'tw-w-0 tw-h-0 tw-overflow-hidden'
              "
            >
              <div class="tw-flex tw-flex-col tw-gap-1 tw-pt-3">
                <div>
                  <div
                    class="tw-flex tw-justify-between tw-gap-8 tw-cursor-pointer"
                    @click="
                      pushLink('https://soporte.somosmaz.com/pqr/Embajadores')
                    "
                  >
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-base tw-whitespace-nowrap"
                    >
                      Soporte Simplifica
                    </p>
                    <i class="icon-cebada tw-text-xl"></i>
                  </div>
                  <hr class="tw-w-full" />
                </div>
                <div v-if="routeHasHelpers">
                  <div
                    class="tw-flex tw-justify-between tw-gap-8 tw-cursor-pointer"
                    @click="routeHelpers"
                  >
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-base tw-whitespace-nowrap"
                    >
                      Ayudas contextuales
                    </p>
                    <i class="icon-cebada tw-text-xl"></i>
                  </div>
                  <hr class="tw-w-full" />
                </div>
                <div>
                  <div
                    class="tw-flex tw-justify-between tw-gap-8 tw-cursor-pointer"
                    @click="suggestionsMailbox"
                  >
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-base tw-whitespace-nowrap"
                    >
                      Buzón de sugerencias
                    </p>
                    <i class="icon-cebada tw-text-xl"></i>
                  </div>
                  <hr class="tw-w-full" />
                </div>
              </div>
            </div>
            <div
              class="tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-rounded-full"
              :class="isOpen ? 'tw-bg-red-350' : 'tw-bg-white'"
              @click="toggleSupportModal"
            >
              <i
                :class="
                  isOpen
                    ? 'icon-cerrar-simple tw-text-white tw-text-3xl lg:tw-text-4xl tw-cursor-pointer'
                    : 'icon-headphones tw-text-2xl lg:tw-text-3xl'
                "
              ></i>
            </div>
          </div>
        </div>
        <div
          class="tw-h-screen tw-fixed tw-bottom-0 tw-right-0"
          :class="isOpen ? 'tw-w-screen backActive' : 'tw-w-0'"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.backActive {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  -ms-transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
}
</style>
