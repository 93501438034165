<script setup>
import { computed, ref } from "vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import { useTimeBenefitsStore } from "@/stores/timeBenefitsStore";
import { useRoute, useRouter } from "vue-router/composables";
import GeneralModal from "@/components/modals/GeneralModal.vue";

const route = useRoute();
const router = useRouter();
const isModalOpen = ref(false);
const timeBenefitsStore = useTimeBenefitsStore();
const fileUploaded = ref(false);
const voucherSent = ref(false);
const fileName = ref("");
const formData = new FormData();

function uploadImage() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/png, image/jpeg, image/jpg");
  input.click();
  input.onchange = () => {
    const file = input.files[0];
    if (file) {
      if (file.size <= 5000000) {
        formData.append("voucher", file);
        fileUploaded.value = true;

        // Obtener y mostrar el nombre del archivo
        fileName.value = file.name;
      } else {
        alert("El archivo no puede ser mayor a 5MB");
      }
    }
  };
}

async function sendImage() {
  try {
    await timeBenefitsStore.actPostTimeBenefitRequestVoucher(
      route.params.id,
      formData
    );
    voucherSent.value = true;
    openModal();
  } catch (error) {
    console.error(error);
  }
}

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const voucherImg = computed(() => {
  return fileUploaded.value
    ? require("@/assets/img/timeBenefits/file-uploaded.png")
    : require("@/assets/img/timeBenefits/file-pending.png");
});
</script>

<template>
  <div>
    <portal to="all-modals">
      <general-modal
        :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
        :modalWidth="{ md: 450, lg: 500, xl: 550 }"
        modal-name="avatar_saved"
        v-if="isModalOpen"
        :isShowModal="isModalOpen"
        @close="closeModal"
        :closable="false"
      >
        <div
          class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
          v-if="voucherSent"
        >
          <div
            class="tw-flex tw-gap-10 tw-items-center tw-w-full tw-justify-center"
          >
            <img
              src="@/assets/img/modal/modal-success.svg"
              class="tw-max-h-20"
              alt=""
            />
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-green-450"
            >
              LISTO
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            Documento cargado exitosamente.
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            Lo compartiremos con tu Line Manager para respaldar tu ausencia.
          </p>

          <div class="tw-w-full tw-flex tw-gap-2">
            <div
              class="tw-btn-primary-red"
              @click="
                router.push({
                  name: 'timeBenefitsHistory',
                })
              "
            >
              <p class="tw-m-0">Cerrar</p>
            </div>
          </div>
        </div>
        <div
          class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
          v-else
        >
          <div
            class="tw-flex tw-gap-10 tw-items-center tw-w-full tw-justify-center"
          >
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
            >
              ¡Lo sentimos!
            </p>
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            Algo salió mal
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center tw-text-gray-400"
          >
            Cierra sesión e inténtalo nuevamente más tarde.
          </p>

          <div class="tw-w-full tw-flex tw-gap-2">
            <div class="tw-btn-primary-red" @click="closeModal">
              <p class="tw-m-0">Cerrar</p>
            </div>
          </div>
        </div>
      </general-modal>
    </portal>
    <mobile-bar title="Beneficio por pérdida" />
    <title-bar title="Beneficio por pérdida" />
    <main class="tw-container-general">
      <section
        class="tw-flex tw-flex-col tw-justify-between tw-gap-4 lg:tw-gap-6 tw-p-4 lg:tw-p-6 tw-border tw-border-blue-700 tw-rounded-2xl lg:tw-rounded-3xl"
      >
        <section class="tw-flex tw-justify-between tw-items-center">
          <p
            class="tw-m-0 tw-font-robotoMedium tw-text-blue-700 tw-text-sm lg:tw-text-lg"
          >
            Sube aquí tu comprobante
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-blue-700 tw-text-sm lg:tw-text-lg"
          >
            {{ "No. " + route.params.id }}
          </p>
        </section>
        <section
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-4 lg:tw-gap-6"
        >
          <div
            class="lg:tw-col-span-5 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6"
          >
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              Queremos ser siempre un apoyo para ti, por eso te invitamos a
              subir el documento de defunción relacionado con la pérdida que
              estás atravesando. Este documento debe ser expedido por un
              veterinario y allí debe aparecer tu nombre como propietario. Esto
              nos permitirá contar con un respaldo para el proceso y ofrecerte
              asistencia y demás beneficios disponibles para esta situación.
              Agradecemos tu colaboración y recuerda que estamos aquí para
              ayudarte durante este momento. Si tienes alguna pregunta, no dudes
              en contactarnos.
            </p>
            <div
              class="tw-hidden lg:tw-flex lg:tw-flex-row tw-items-center tw-gap-2 lg:tw-gap-4 tw-w-full lg:tw-w-6/12"
            >
              <comp-button-custom
                color="blue-700"
                text-color="white"
                border-color="blue-700"
                :text="fileUploaded ? 'Cambiar imagen' : 'Subir imagen'"
                class="tw-w-full lg:tw-w-6/12"
                @click.native="uploadImage"
              />
              <p class="tw-font-roboto tw-m-0 tw-text-xs lg:tw-text-base">
                {{ fileUploaded ? fileName : "Documento pendiente" }}
              </p>
            </div>
            <small class="tw-font-robotoBold tw-hidden lg:tw-block"
              >Sube una image en formato png, jpg con un tamaño máximo de
              5MB</small
            >
          </div>
          <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6">
            <img
              :src="`${voucherImg}`"
              class="tw-object-contain tw-w-8/12 lg:tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-self-center"
              alt=""
            />
            <small class="tw-font-robotoBold lg:tw-hidden"
              >Sube una image en formato png, jpg con un tamaño máximo de
              5MB</small
            >
            <comp-button-custom
              color="blue-700"
              text-color="white"
              border-color="blue-700"
              :text="fileUploaded ? 'Cambiar imagen' : 'Subir imagen'"
              class="tw-w-full lg:tw-w-6/12 lg:tw-hidden"
              @click.native="uploadImage"
            />
            <p
              class="tw-font-roboto tw-m-0 tw-text-xs lg:tw-text-base lg:tw-hidden"
            >
              {{ fileUploaded ? fileName : "Documento pendiente" }}
            </p>
            <comp-button-custom
              color="blue-700"
              text-color="white"
              border-color="blue-700"
              text="Guardar"
              class="tw-w-full"
              :disabled="!fileUploaded"
              @click.native="sendImage"
            />
          </div>
        </section>
      </section>
    </main>
  </div>
</template>
