import { render, staticRenderFns } from "./UserBenefits.vue?vue&type=template&id=430ad10e&scoped=true"
import script from "./UserBenefits.vue?vue&type=script&lang=js"
export * from "./UserBenefits.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/showme.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./UserBenefits.vue?vue&type=style&index=1&id=430ad10e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430ad10e",
  null
  
)

export default component.exports