import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    peopleCycleTabFilter: [],
    peopleCycleItem: [],
  },
  mutations: {
    setPeopleCycleTabFilter: (state, tabFilters) => {
      state.peopleCycleTabFilter = tabFilters;
    },
    setPeopleCycleItem: (state, item) => {
      state.peopleCycleItem = item;
    },
  },
  actions: {
    actGetPeopleCycleTabFilter: async ({ commit }) => {
      let response = await ApiBeerOffice.getPeopleCycleTabFilter();
      if (response.error) {
        return response.error;
      }
      commit("setPeopleCycleTabFilter", response.data);
      return true;
    },
    actGetPeopleCycleItem: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getPeopleCycleItem(id);
      if (response.error) {
        return response.error;
      }
      commit("setPeopleCycleItem", response.data.item);
      return true;
    },
  },
};
