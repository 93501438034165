<template>
  <div class="tw-mb-4" v-if="!isLoading">
    <HomeTrivia
      @changeOpcVisible="changeOpcVisible"
      :title="trivia.name"
      :desc="trivia.short_desc"
      :image="trivia.icon"
      :subbrand="subbrand"
      v-if="opcVisible === 1"
    />
    <QuestionsTrivia
      v-if="opcVisible === 2"
      :title="trivia.name"
      :questions="trivia.comp_question"
      :triviaId="trivia.id"
      @changeResponseTrivia="changeResponseTrivia"
      @changeOpcVisible="changeOpcVisible"
    />
    <FinishIncompleteTrivia
      v-if="opcVisible === 3"
      :responseTrivia="responseTrivia"
      :image="trivia.icon"
      :triviaName="trivia.name"
      :calification="responseTrivia.correct"
      @changeOpcVisible="changeOpcVisible"
    />
    <FinishFullTrivia
      v-if="opcVisible === 4"
      :image="trivia.icon"
      :responseTrivia="responseTrivia"
      :triviaName="trivia.name"
      :calification="responseTrivia.correct"
    />
  </div>
</template>

<script>
import ApiBeerOffice from "../../services/apiBeerOffice";

const HomeTrivia = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/HomeTrivia"
  );
const QuestionsTrivia = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/QuestionsTrivia"
  );
const FinishIncompleteTrivia = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/FinishIncompleteTrivia"
  );
const FinishFullTrivia = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/FinishFullTrivia"
  );

export default {
  name: "TriviaComponent",
  props: {
    trivia: {
      type: Object,
      required: true,
    },
    subbrand: {
      type: String,
    },
  },
  components: {
    HomeTrivia,
    QuestionsTrivia,
    FinishIncompleteTrivia,
    FinishFullTrivia,
  },
  data() {
    return {
      isLoading: false,
      opcVisible: 1,
      responseTrivia: {},
    };
  },
  async created() {
    this.isLoading = true
    await this.getTriviaResult();
  },
  methods: {
    getTriviaResult() {
      ApiBeerOffice.getTriviaResult(this.trivia.id).then((data) => {
        let result = data.data;
        this.responseTrivia = result;
        if (result.answered) {
          if (result.correct === result.total) {
            this.opcVisible = 4;
            // this.pushDataLayer("Aprobó");
          } else {
            this.opcVisible = 3;
            // this.pushDataLayer("Reprobó");
          }
        } else {
          this.opcVisible = 1;
        }

        this.isLoading = false;
      });
    },
    changeOpcVisible(opc) {
      this.opcVisible = opc;
    },
    changeResponseTrivia(data) {
      this.responseTrivia = data;
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-trivias",
        category: "Trivias",
        SubCategory: this.trivia.name,
        Action: "Paso 3: Finalizó la trivia",
        Label: label,
        Calificacion: this.responseTrivia.correct,
      });
    },
  },
};
</script>
