import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";

const { user } = storeToRefs(useUserStore());

const userRemunerationMixin = {
  computed: {
    employeeCorePosition() {
      let corePosition =
        user.value.core_pa_sideline === null
          ? user.value.core_om_sideline.name
          : user.value.core_pa_sideline.name;

      const positionMapping = {
        0: "0-IV",
        I: "0-IV",
        "I-A": "0-IV",
        "I-B": "0-IV",
        II: "0-IV",
        "II-A": "0-IV",
        "II-B": "0-IV",
        III: "0-IV",
        "III-A": "0-IV",
        "III-B": "0-IV",
        IV: "0-IV",
        "IV-A": "0-IV",
        "IV-B": "0-IV",
        V: "V-VI",
        "V-A": "V-VI",
        "V-B": "V-VI",
        VI: "V-VI",
        "VI-A": "V-VI",
        "VI-B": "V-VI",
        VII: "VII-VIII",
        "VII-A": "VII-VIII",
        "VII-B": "VII-VIII",
        VIII: "VII-VIII",
        "VIII-A": "VII-VIII",
        "VIII-B": "VII-VIII",
      };

      return positionMapping[corePosition] || "VIII+";
    },
  },
};

export default userRemunerationMixin;
