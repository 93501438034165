import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  benefitCategories: "/benBenefitCategories",
  benefits: "/benBenefits",
  agreementCategories: "/benEmpAgreementCategories",
  agreements: "/benEmpAgreements",
};

export const useBenefitsAgreementsStore = defineStore(
  "benefitsAgreements",
  () => {
    const benefitCategories = ref([]);
    const benefits = ref([]);
    const benefitDetail = ref([]);
    const agreementCategories = ref([]);
    const agreements = ref([]);
    const agreementDetail = ref([]);
    const agreementSelected = ref(null);

    async function actGetBenefitCategories() {
      const response = await AxiosBeerOffice.get(
        uri.benefitCategories,
        true,
        {}
      );
      if (response.error) return response.error;
      const { data: benefitCategoriesData } = response;
      benefitCategories.value = benefitCategoriesData;
      return true;
    }

    async function actGetAgreementCategories() {
      const response = await AxiosBeerOffice.get(
        uri.agreementCategories,
        true,
        {}
      );
      if (response.error) return response.error;
      const { data: agreementCategoriesData } = response;
      agreementCategories.value = agreementCategoriesData;
      return true;
    }

    async function actGetBenefits(category_id) {
      const response = await AxiosBeerOffice.get(
        uri.benefits + "?category_id=" + category_id,
        true,
        {}
      );
      if (response.error) return response.error;
      const { data: benefitsData } = response;
      benefits.value = benefitsData;
      return true;
    }

    async function actGetBenefitDetail(benefit_id) {
      const response = await AxiosBeerOffice.get(
        uri.benefits + "/" + benefit_id,
        true,
        {}
      );
      if (response.error) return response.error;
      const { data: benefitData } = response;
      benefitDetail.value = benefitData;
      return true;
    }

    async function actGetAgreements(category_id) {
      const response = await AxiosBeerOffice.get(
        uri.agreements + "?category_id=" + category_id,
        true,
        {}
      );
      if (response.error) return response.error;
      const { data: agreementsData } = response;
      agreements.value = agreementsData;
      return true;
    }

    async function actGetAgreementDetail(agreement_id) {
      const response = await AxiosBeerOffice.get(
        uri.agreements + "/" + agreement_id,
        true,
        {}
      );
      if (response.error) return response.error;
      const { data: agreementData } = response;
      agreementDetail.value = agreementData;
      return true;
    }

    return {
      benefitCategories,
      agreementCategories,
      benefits,
      agreements,
      benefitDetail,
      agreementDetail,
      agreementSelected,
      actGetBenefitCategories,
      actGetAgreementCategories,
      actGetBenefits,
      actGetAgreements,
      actGetBenefitDetail,
      actGetAgreementDetail,
    };
  }
);
