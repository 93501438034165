import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  eventsUri: "/events",
  eventUri: "/calendarEvents",
  streetEventsUri: "/streetEvents",
  streetEventStagesUri: "/streetEventStages",
};

export const useEventsStore = defineStore("events", () => {
  const events = ref([]);
  const event = ref({});
  const streetEvents = ref([]);
  const streetEvent = ref({});
  const streetEventStages = ref([]);

  async function actGetEvents() {
    const response = await AxiosBeerOffice.get(uri.eventsUri, true, {});
    if (response.error) return response.error;
    events.value = response.events;
    return true;
  }

  async function actGetEventById(id) {
    const response = await AxiosBeerOffice.get(
      uri.eventUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    event.value = response.data;
    return true;
  }

  async function actPostEventRegister(id) {
    const response = await AxiosBeerOffice.post(
      `${uri.eventsUri}/${id}/register`,
      true,
      {}
    );
    console.log(response);
    if (response.error) return response.error;
    return response;
  }

  async function actGetStreetEvents(id) {
    const response = await AxiosBeerOffice.get(
      `${uri.streetEventsUri}?stage=${id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    streetEvents.value = response.events;
    return true;
  }

  async function actGetStreetEventById(id) {
    const response = await AxiosBeerOffice.get(
      uri.streetEventsUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    streetEvent.value = response.data;
    return true;
  }

  async function actGetStreetEventStages(year) {
    const response = await AxiosBeerOffice.get(
      `${uri.streetEventStagesUri}?year=${year}`,
      true,
      {}
    );
    if (response.error) return response.error;
    streetEventStages.value = response.stages;
    return true;
  }

  async function actPostStreetEventsRegister(id) {
    const response = await AxiosBeerOffice.post(
      `${uri.streetEventsUri}/${id}/register`,
      true,
      {}
    );
    console.log(response);
    if (response.error) return response.error;
    return response;
  }

  return {
    events,
    event,
    streetEvents,
    streetEvent,
    streetEventStages,
    actGetEvents,
    actGetEventById,
    actPostEventRegister,
    actGetStreetEvents,
    actGetStreetEventById,
    actGetStreetEventStages,
    actPostStreetEventsRegister,
  };
});
