<template>
  <div class="col-12 py-4">
    <div class="row align-items-center">
      <div class="col-12 col-lg-7">
        <p class="text-left tw-text-xl text-uppercase font-weight-bold pl-0 pl-lg-3">
          {{ tableTitle }}
        </p>
      </div>
      <div class="col-12 col-lg-5 d-flex justify-content-end position-relative">
        <div
          @click="resetFilter"
          role="button"
          class="
            tw-rounded-md
            tw-border tw-border-solid tw-border-gray-600
            p-2
            d-flex
            justify-content-center
            align-items-center
            tw-text-gray-600
            mr-0 mr-lg-3
          "
        >
          <p class="mb-0 tw-text-base">Limpiar filtro</p>
        </div>
        <div class="mx-3 d-flex align-items-center">
          <p class="mb-0 mr-2">Año</p>
          <select class="tw-rounded-md p-2" v-model="yearSelected" @change="onChangeYear">
            <option
              v-for="(year, index) in years"
              :key="'year-psi-' + index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
        </div>
        <div
          @click="showOptionFilter"
          role="button"
          class="
            tw-rounded-md
            tw-border tw-border-solid tw-border-red-400 
            p-2
            d-flex
            justify-content-center
            align-items-center
            wi-100
            tw-text-red-400
            hover:tw-bg-red-400 hover:tw-text-white
            mr-0 mr-lg-3
          "
        >
          <i class="icon-menu-filtro mr-3"></i>
          <p class="mb-0 tw-text-base">Filtrar</p>
        </div>

        <div
          v-if="optionsFilter"
          class="
            position-absolute
            p-3
            tw-bg-white
            tw-rounded-lg
            tw-shadow-md
            wi-360
            mt-5
            z-index-2
          "
        >
          <div class="row">
            <div class="col-10 mb-4">
              <p class="mb-0 tw-text-gray-600 font-weight-bold tw-text-lg">FILTRO</p>
            </div>
            <div class="col-2">
              <i
                @click="hideOptionsFilter"
                role="button"
                class="icon-cerrar-simple tw-text-red-400 tw-text-lg font-weight-bold"
              ></i>
            </div>
            <div class="col-12 he-400 tw-overflow-y-scroll">
              <div v-for="vp in filterVices" :key="'vp-' + vp.id">
                <div
                  v-b-toggle="'vp-collapse-' + vp.id"
                  class="
                    d-flex
                    tw-border-b-2 tw-border-solid tw-border-gray-300 
                    justify-content-between
                    align-items-center
                    pt-2
                  "
                >
                  <div class="custom-control custom-checkbox mb-2">
                    <input
                      type="checkbox"
                      class="custom-control-input check-filter"
                      :id="`check-vice-${vp.id}`"
                      :value="vp.id"
                      v-model="vpsSelected"
                      @click="checkVp($event)"
                    />
                    <label
                      class="custom-control-label"
                      :for="`check-vice-${vp.id}`"
                    >
                      <span class="tw-text-sm tw-text-gray-600">{{ vp.name }}</span>
                    </label>
                  </div>
                  <i
                    v-if="vp.psah.length > 0"
                    class="icon-flecha-abajo tw-text-xl"
                  ></i>
                </div>
                <b-collapse
                  :id="'vp-collapse-' + vp.id"
                  v-if="vp.psah.length > 0"
                >
                  <div class="pl-3 py-2 tw-bg-gray-200">
                    <div
                      v-for="scope in vp.psah"
                      :key="'scope-inner-' + scope.id"
                      class="custom-control custom-checkbox mb-2"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`check-inner-scope-${scope.id}-${vp.id}`"
                        :value="scope.id"
                        v-model="scopeSelected"
                        @click="checkScope"
                      />
                      <label
                        class="custom-control-label"
                        :for="`check-inner-scope-${scope.id}-${vp.id}`"
                      >
                        <span class="tw-text-sm tw-text-gray-600">{{
                          scope.name
                        }}</span>
                      </label>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class="col-12 pt-3">
              <div
                @click="filterData"
                class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 text-center"
                role="button"
              >
                <p class="mb-0">Filtrar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PsiFilterData",
  props: {
    isMySiblingClicked: {
      type: Boolean,
    },
  },
  data() {
    return {
      vps: {},
      vpsSelected: [],
      scopeSelected: [],
      optionsFilter: false,
      selectedSummaryFilter: {
        kpi: this.$store.state.modPsi.kpi,
        vp: this.$store.state.modPsi.vp,
        psah: this.$store.state.modPsi.psah,
        month: this.$store.state.modPsi.month,
        year: this.$store.state.modPsi.year,
        first: this.$store.state.modPsi.first,
      },
      yearSelected: new Date().getFullYear()
    };
  },
  created() {
    this.getSelectFilters();
  },
  computed: {
    ...mapState("modPsi", [
      "tableTitle",
      "filterVices",
      "kpi",
      "vp",
      "psah",
      "month",
      "year",
      "first"
    ]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: 3 },
        (value, index) => (year - 2) + index
      );
    },
  },
  watch: {
    isMySiblingClicked() {
      this.clearVpsAndScopesSelected();
    },
  },
  methods: {
    ...mapActions("modPsi", [
      "getSelectFilters",
      "getSummaryPsi",
      "updateVp",
      "updateScope",
      "updateKpi",
      "updateFirst",
      "updateIsFilter",
      "updateYear",
      "resetFilterData",
    ]),
    showOptionFilter() {
      this.optionsFilter = true;
    },
    hideOptionsFilter() {
      this.optionsFilter = false;
    },
    checkVp(event) {
      let isChecked = event.srcElement.checked;
      let scopeCheckBoxes =
        event.srcElement.parentElement.parentElement.nextSibling
          .querySelector("div")
          .querySelectorAll("input[type='checkbox']");

      scopeCheckBoxes.forEach((element) => {
        if (isChecked) {
          element.checked = false;
          element.disabled = true;
          let index = this.scopeSelected.indexOf(Number(element.value));
          if (index > -1) {
            this.scopeSelected.splice(index, 1);
          }
          this.updateScope(this.scopeSelected);
        } else {
          element.disabled = false;
        }
      });
      setTimeout(() => {
        this.updateVp(this.vpsSelected);
      }, 200);
    },
    checkScope() {
      setTimeout(() => {
        this.updateScope(this.scopeSelected);
      }, 200);
    },
    filterData() {
      this.updateIsFilter(true);
      this.hideOptionsFilter();
      this.updateKpi([]);
      this.updateFirst(true);
      this.getSummaryPsi(
        JSON.stringify({
          kpi: this.kpi,
          vp: this.vp,
          psah: this.psah,
          month: this.month,
          year: this.yearSelected,
          first: true,
        })
      );
    },
    clearVpsAndScopesSelected() {
      this.vpsSelected = [];
      this.scopeSelected = [];
    },
    resetFilter() {
      let currentDate = new Date;
      this.yearSelected = currentDate.getFullYear();
      this.hideOptionsFilter();
      this.clearVpsAndScopesSelected();
      this.resetFilterData();
      this.getSummaryPsi(
        JSON.stringify({
          kpi: this.kpi,
          vp: this.vp,
          psah: this.psah,
          month: this.month,
          year: currentDate.getFullYear()
        })
      );
      //this.$router.push({ path: "/indicators/psi/" });
    },
    onChangeYear(){
      this.updateYear(this.yearSelected);
      this.updateSummaryData();
    },
    updateSummaryData() {
      this.$emit("updateSummaryData");
    }
  },
};
</script>
<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #23bf79;
  background-color: #23bf79;
}
</style>
