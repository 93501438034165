<script setup>
import config from "@/services/config";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  gif: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div
    class="splash tw-flex tw-items-center tw-justify-center tw-w-full tw-bg-white tw-max-h-[80vh]"
  >
    <img
      alt=""
      class="tw-w-[300px] lg:tw-w-[600px]"
      :src="`${uriResources}/storage/documents/${props.gif}`"
    />
  </div>
</template>

<style scoped>
.splash {
  height: calc(100vh - 4rem);
}

@media (min-width: 1024px) {
  .splash {
    height: calc(100vh - 6rem);
  }
}
</style>
