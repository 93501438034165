<template>
  <div class="row">
    <div class="col-12">
      <div class="tw-w-full tw-flex tw-items-center">
        <div class="rate tw-relative tw-mr-1 md:tw-mr-2" style="height: 26px;">
          <i class=" tw-text-gray-320 tw-text-xl" :class="this.state ? 'icon-star tw-text-yellow-300' : 'icon-star'"></i>
        </div>
        <div class="rate-number tw-flex tw-items-center">
          <p class="tw-mb-0 tw-ml-0 tw-font-roboto tw-text-sm md:tw-text-base tw-text-gray-400">{{ this.stars }}/5</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompStar",
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    stars: {
      type: String,
      default: "0.0",
    },
  },
};
</script>
