<script setup>
import { ref } from "vue";

const hover = ref(false);

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "icon-bell-1",
    required: true,
  },
  notificationNumber: {
    type: Number,
    default: 0,
  },
  borderColorProp: {
    type: String,
  },
});
</script>
<template>
  <div
    class="tw-border-b tw-cursor-pointer tw-relative"
    :style="{
      borderColor: hover || isOpen ? 'white' : borderColorProp,
      '--tw-border-opacity': isOpen ? '1' : 'initial',
    }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <i :class="icon" class="tw-text-xl tw-text-white"></i>
    <div
      v-if="notificationNumber !== 0"
      class="tw-absolute tw-bg-white tw-w-2 tw-h-2 tw-rounded-full tw-font-robotoBold tw--top-1 tw-left-0"
    ></div>
  </div>
</template>
