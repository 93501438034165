<script setup>
import { getCurrentInstance } from "vue";

const { $isMobile } = getCurrentInstance().proxy;
const props = defineProps({
  title: {
    type: String,
    default: "Ingreso",
  },
  checkin: {
    type: String,
  },
  checkout: {
    type: String,
  },
});
</script>
<template>
  <div class="tw-flex tw-flex-col tw-gap-y-3">
    <div>
      <p
        class="tw-m-0 tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl"
      >
        {{ props.title }}
      </p>
    </div>

    <div
      class="tw-border tw-border-solid tw-border-gray-250 tw-rounded-3xl tw-w-full tw-grid tw-grid-flow-col-dense tw-gap-3 tw-px-7 tw-py-4 tw-mb-5"
      :class="$isMobile() ? 'tw-max-h-16' : ''"
    >
      <div
        class="tw-flex tw-justify-center tw-items-center tw-gap-2 sm:tw-gap-3"
      >
        <div class="tw-h-full tw-flex tw-justify-center tw-items-center">
          <img
            src="@/assets/img/reservations/icons/checkin.svg"
            class="tw-h-full tw-object-cover tw-max-h-8 lg:tw-max-h-12"
            alt=""
          />
        </div>
        <div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-xxs sm:tw-text-xs md:tw-text-base"
          >
            {{ "Check in " + props.checkin }}
          </p>
        </div>
      </div>
      <div class="tw-h-full tw-border-l tw-border-gray-250 tw-mx-auto"></div>
      <div
        class="tw-flex tw-justify-center tw-items-center tw-gap-2 sm:tw-gap-3"
      >
        <div class="tw-h-full tw-flex tw-justify-center tw-items-center">
          <img
            src="@/assets/img/reservations/icons/checkout.svg"
            class="tw-h-full tw-max-h-8 lg:tw-max-h-12"
            alt=""
          />
        </div>
        <div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-xxs sm:tw-text-xs md:tw-text-base"
          >
            {{ "Check out " + props.checkout }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
