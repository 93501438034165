<template>
  <div>
    <!-- Título Desktop y leyenda -->
    <div
      class="tw-container-general d-flex flex-md-row flex-column px-0 mt-2 mt-md-4"
    >
      <div class="col-12 col-md-6 px-0">
        <div class="d-none d-sm-none d-md-flex">
          <p
            class="
              tw-font-tolyerBold
              fs-md-42
              text-uppercase
              tw-text-red-400
              m-0
              p-0
            "
          >
            La Maltería
          </p>
        </div>
        <div class="d-none d-md-flex">
          <div class="px-3 p-md-0">
            <p
              class="
                tw-font-roboto tw-text-base
                fs-md-16
                line-height-24
                tw-text-gray-600
              "
            >
              Completa misiones, gana puntos y redime premios
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-container-general tw-px-3 md:tw-px-0 my-2 mt-md-3 mb-md-5">
      <div
        class="
          tw-rounded-lg tw-rounded-lg-20 tw-shadow tw-shadow-md-lg
          overflow-hidden
          p-5
        "
      >
        <div class="d-flex justify-content-between">
          <div>
            <p
              class="
                m-0
                tw-font-tolyerBold
                fs-30
                line-height-24 line-height-md-30
              "
            >
              Historial de transacciones
            </p>
            <p
              class="
                m-0
                tw-font-roboto tw-text-sm
                line-height-md-20
                tw-text-gray-600
                mt-2
              "
            >
              Consulta las transacciones que has realizado
            </p>
          </div>
          <div>
            <p
              class="
                m-0
                tw-font-robotoBold tw-text-sm
                line-height-16
                text-underline
                tw-cursor-pointer
              "
              @click="$router.push({ name: 'gamificationMalts' })"
            >
              <u>Regresar</u>
            </p>
          </div>
        </div>
        <div>
          <div v-for="transaction in datospaginados" :key="transaction.id">
            <div
              class="
                tw-rounded-md tw-shadow-sm
                px-md-5
                py-md-3
                p-4
                my-4
                transactions-general
              "
            >
              <div class="">
                <p
                  class="
                    m-0
                    tw-font-roboto tw-text-xs
                    line-height-14
                    tw-text-gray-500
                  "
                >
                  {{ transaction.created_at.slice(0, 10) }}
                </p>
              </div>
              <div class="transactions-detail">
                <div class="d-flex align-items-center">
                  <p class="m-0 tw-font-roboto tw-text-sm line-height-20">
                    <span class="tw-font-robotoBold"
                      >{{ transaction.malts }} Maltas =
                    </span>
                    {{ transaction.points_tapit }} Puntos Tapit
                  </p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="m-0 tw-font-roboto tw-text-sm line-height-20">
                    <span class="tw-font-robotoBold">Transferencia Tapit: </span
                    >{{ user.tapit_email }}
                  </p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="m-0 tw-font-roboto tw-text-sm line-height-20">
                    <span class="tw-font-robotoBold tw-gree"
                      >Transacción:
                    </span>
                    <span
                      :class="
                        transaction.status === 'SUCCESS'
                          ? 'tw-text-green-400'
                          : 'tw-text-red-350'
                      "
                      >{{
                        transaction.status === "SUCCESS"
                          ? "Aprobada"
                          : "Rechazada"
                      }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <nav>
              <ul
                class="
                  d-flex
                  list-unstyled
                  justify-content-between
                  align-items-center
                  mx-2
                  p-0
                "
              >
                <li
                  :class="pagActual > 1 ? '' : 'invisible'"
                  @click="getPreviousPage()"
                  class="tw-cursor-pointer"
                >
                  <p
                    class="
                      m-0
                      tw-font-robotoBold tw-text-sm
                      line-height-20
                      tw-text-red-400
                    "
                  >
                    <u>Anterior</u>
                  </p>
                </li>
                <div class="d-flex justify-content-center align-items-center">
                  <li
                    v-for="(pagina, index) in totalPaginas()"
                    :key="index"
                    @click="getDataPagina(pagina)"
                    class="mx-1 d-flex"
                    :class="pagina === pagActual ? 'pagactive' : 'paginactive'"
                  >
                    <i
                      class="icon-circle tw-cursor-pointer"
                      :class="
                        pagina === pagActual
                          ? 'tw-text-xs tw-text-red-400'
                          : 'tw-text-xxxs tw-text-gray-320'
                      "
                    ></i>
                  </li>
                </div>
                <li
                  :class="pagActual < totalPaginas() ? '' : 'invisible'"
                  @click="getNextPage()"
                  class="tw-cursor-pointer"
                >
                  <p
                    class="
                      m-0
                      tw-font-robotoBold tw-text-sm
                      line-height-20
                      tw-text-red-400
                    "
                  >
                    <u>Siguiente</u>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import datesMixin from "@/mixins/general/dates";

export default {
  name: "GamiMaltsHistory",
  data() {
    return {
      elemporpag: 3,
      datospaginados: [],
      filterTransactions: [],
      pagActual: 1,
      isLoading: false,
    };
  },
  mixins: [datesMixin],
  created() {},
  computed: {
    ...mapState("modGamification", ["tapitTransactions"]),
    ...mapState("modCore", ["user"]),
  },
  mounted() {
    this.isLoading = true;
    this.actGetTapitTransactions()
      .then(() => {
        this.filterTransactions = this.tapitTransactions;
        this.getDataPagina(1);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions("modGamification", ["actGetTapitTransactions"]),
    getDataPagina(noPagina) {
      this.pagActual = noPagina;
      this.datospaginados = [];
      let ini = noPagina * this.elemporpag - this.elemporpag;
      let fin = noPagina * this.elemporpag;
      this.datospaginados = this.filterTransactions.slice(ini, fin);
    },
    totalPaginas() {
      return Math.ceil(this.filterTransactions.length / this.elemporpag);
    },
    getPreviousPage() {
      if (this.pagActual > 1) {
        this.pagActual--;
      }
      this.getDataPagina(this.pagActual);
    },
    getNextPage() {
      if (this.pagActual < this.totalPaginas()) {
        this.pagActual++;
      }
      this.getDataPagina(this.pagActual);
    },
  },
};
</script>

<style scoped>
.transactions-general {
  display: grid;
  grid-row-gap: 1em;
}

.transactions-detail {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.8fr;
}

@media (max-width: 768px) {
  .transactions-detail {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
}
</style>