export const reservationStatusText = (name) => {
  const map = new Map([
    ["PENDING_RAFFLE", "Salir del sorteo"],
    ["WON_RAFFLE", "Realizar el pago"],
    [
      "UNWON_RAFFLE",
      "Lo lamentamos, no has sido uno de los ganadores del sorteo, no dejes de intentarlo.",
    ],
    ["PENDING_PAYMENT", "Realizar el pago"],
    [
      "PAYMENT_MADE",
      "Hemos recibido tu pago, en los próximos días estaremos validando esta información.",
    ],
    ["CONFIRMED", "Cancelar reserva"],
    ["CANCELLED", "Lo lamentamos, tu reserva ha sido cancelada"],
    [
      "REJECTED",
      "Lo lamentamos, tu reserva ha sido rechazada. Si subiste tu comprobante de pago, contáctate con soporte.",
    ],
    [
      "COMPLETED",
      "¡Has finalizado tu viaje! Esperamos que hayas disfrutado tu estancia y que vuelvas pronto.",
    ],
    [
      "NOT_COMPLETED",
      "Tu reserva no ha finalizado, esperamos que estés disfrutando tu estadía.",
    ],
  ]);
  return map.get(name) || "Pendiente";
};

export const reservationColorText = (name) => {
  const map = new Map([
    ["PENDING_RAFFLE", "tw-text-brown-500"],
    ["WON_RAFFLE", "tw-text-green-450"],
    ["UNWON_RAFFLE", "tw-text-red-350"],
    ["PENDING_PAYMENT", "tw-text-yellow-200"],
    ["PAYMENT_MADE", "tw-text-yellow-200"],
    ["CONFIRMED", "tw-text-green-450"],
    ["CANCELLED", "tw-text-red-350"],
    ["REJECTED", "tw-text-red-350"],
    ["COMPLETED", "tw-text-brown-500"],
    ["NOT_COMPLETED", "tw-text-brown-500"],
  ]);
  return map.get(name) || "tw-text-yellow-200";
};

export const reservationButton = (name) => {
  return (
    name === "PENDING_RAFFLE" ||
    name === "WON_RAFFLE" ||
    name === "PENDING_PAYMENT" ||
    name === "CONFIRMED" ||
    name === "PENDING" ||
    name === "PENDING_WON_RAFFLE"
  );
};
