<script setup>
import AtomSection from "@/components/atoms/AtomSection.vue";
import { computed } from "vue";

const props = defineProps({
  headerBgColor: {
    type: String,
    default: "white",
  },
  bodyBgColor: {
    type: String,
    default: "white",
  },
  footerBgColor: {
    type: String,
    default: "white",
  },
  border: {
    type: Boolean,
    default: false,
  },
  borderColor: {
    type: String,
    default: "black",
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  roundedSize: {
    type: String,
    default: "",
  },
});

const computedClass = computed(() => {
  return [
    props.border ? `tw-border tw-border-${props.borderColor}` : "",
    props.rounded
      ? props.roundedSize
        ? `tw-rounded-${props.roundedSize}`
        : "tw-rounded"
      : "",
  ];
});
</script>

<template>
  <div
    class="tw-grid tw-grid-rows-rows_3_fit_first_and_last tw-gap-2 tw-overflow-hidden"
    :class="computedClass"
  >
    <atom-section :bgColor="headerBgColor" :padding="{ 'p-2': $slots.header }">
      <slot name="header" />
    </atom-section>
    <atom-section :bgColor="bodyBgColor" :padding="{ 'p-2': $slots.body }">
      <slot name="body" />
    </atom-section>
    <atom-section :bgColor="footerBgColor" :padding="{ 'p-2': $slots.footer }">
      <slot name="footer" />
    </atom-section>
  </div>
</template>

<style scoped></style>
