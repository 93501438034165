<template>
  <div id="calculatorExtraHours" class="ml-0 md:tw-ml-20 ml-xl-0">
    <div class="tw-container-general">
      <div class="row px-0 mx-0">
        <div class="col-12 col-md-6 col-lg-7 px-0 px-md-2">
          <SecondaryNavbar title="Calculadora" :breadcrumb="breadcrumb" />
        </div>
      </div>
    </div>
    <MenuExtraHours page="calculator" />
    <BannerExtraHours
      icon="icon-calculadora-2"
      title="Calculadora"
      text="Ingresa tus datos para calcular el valor total de tu trabajo suplementario, dominicales y festivos."
    />
    <div class="container mt-0">
      <div class="cont-2 py-5 px-4 w-100 mb-3">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="cont-text">
              <div class="cont-title d-flex position-relative">
                <div class="main-cont-icon">
                  <div
                    class="cont-icon d-flex justify-content-center align-items-center mr-3"
                  >
                    <i class="icon-reloj-mas"></i>
                  </div>
                </div>
                <div class="cont-desc">
                  <p class="title mb-1">Conoce el valor de tus horas extras</p>
                  <p class="text">
                    Calcula el valor de tu trabajo suplementario, dominicales y
                    festivos.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion mt-0 mt-md-4 mb-3 mb-md-0" role="tablist">
              <div class="cont-1">
                <div
                  class="header d-flex justify-content-between align-items-center"
                  v-b-toggle.accordion-4
                >
                  <p class="mb-0 text-uppercase">Tu hora de trabajo</p>
                  <i
                    :class="
                      accordion.workhour.visible
                        ? 'icon-flecha-arriba'
                        : 'icon-flecha-abajo'
                    "
                  ></i>
                </div>
                <b-collapse
                  id="accordion-4"
                  v-model="accordion.workhour.visible"
                  accordion="my-accordion-workhour"
                  role="tabpanel"
                >
                  <div class="body">
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tr class="bg-0">
                          <td colspan="3" class="p-3">
                            <p class="mb-0">
                              Se calcula de la siguiente manera: <br /><br />
                              Tu salario total divido por los 30 días del mes,
                              dividido por las 8 horas de trabajo legales
                              vigentes.
                            </p>
                          </td>
                        </tr>
                        <tr class="bg-1">
                          <td>
                            <p class="mb-0 strong text-center">
                              Tu salario total
                            </p>
                          </td>
                          <td class="text-center pt-2 tw-w-14">
                            <i class="icon icon-calendario"></i>
                          </td>
                          <td class="text-center pt-2 tw-w-14">
                            <i class="icon icon-reloj"></i>
                          </td>
                        </tr>
                        <tr class="bg-2">
                          <td class="px-2 px-md-3 px-lg-4">
                            <p class="mb-0 desc mt-1 text-center">
                              ${{ formatNumber(monthSalary) }}
                            </p>
                          </td>
                          <td>
                            <p class="mb-0 desc strong mt-1 text-center">30</p>
                          </td>
                          <td>
                            <p class="mb-0 desc strong mt-1 text-center">8</p>
                          </td>
                        </tr>
                        <tr class="bg-1">
                          <td colspan="3">
                            <p class="mb-0 text-center">
                              <strong class="strong">Total: </strong>
                              <span class="subtotal-workhour">
                                ${{ formatNumber(hourValue) }}
                              </span>
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="cont-cards">
              <div class="card-1 p-4 mb-3 black d-flex justify-content-between">
                <p class="text text-uppercase mb-0">Tu salario base</p>
                <p class="price mb-0">${{ formatMoney(user.salary) }}</p>
              </div>
              <div class="card-1 p-4 mb-3 red d-flex justify-content-between">
                <p class="text text-uppercase mb-0">Tu bono de desempeño</p>
                <p class="price mb-0">
                  ${{ formatMoney(user.performance_bonus) }}
                </p>
              </div>
              <div class="card-1 p-4 mb-3 black d-flex justify-content-between">
                <p class="text text-uppercase mb-0">Tu salario total</p>
                <p class="price mb-0">${{ formatMoney(monthSalary) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="cont-3">
            <div class="cont-title d-flex align-items-center mb-3">
              <div class="cont-icon mr-3">
                <i class="icon-reloj-mas"></i>
              </div>
              <p class="text mb-0">Tipo de hora extra/ Tiempo suplementario</p>
            </div>
            <div class="desc">
              <p>
                Ingresa la cantidad de horas extras trabajadas en el recuadro de
                cada categoría, según corresponda:
              </p>
            </div>
            <div class="accordion" role="tablist">
              <div class="cont-1">
                <div
                  class="header d-flex justify-content-between align-items-center"
                  v-b-toggle.accordion-1
                >
                  <p class="mb-0">Horas extras</p>
                  <i
                    :class="
                      accordion.overtime.visible
                        ? 'icon-flecha-arriba'
                        : 'icon-flecha-abajo'
                    "
                  ></i>
                </div>
                <b-collapse
                  id="accordion-1"
                  v-model="accordion.overtime.visible"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <div class="body">
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tr class="bg-1">
                          <td></td>
                          <td class="tw-w-20">
                            <i class="icon icon-reloj ml-3"></i>
                          </td>
                          <td class="tw-w-20">
                            <span class="icon strong">%</span>
                          </td>
                        </tr>
                        <template
                          v-for="(itemExtraHour, index) in arrayExtraHours"
                          @key="'extra-hour-' + itemExtraHour.overtime_item.id"
                        >
                          <template>
                            <tr class="bg-2">
                              <td class="px-2 px-md-3 px-lg-4">
                                <p class="mb-0 desc mt-2">
                                  {{ itemExtraHour.overtime_item.name }}
                                </p>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  max="99"
                                  class="number-text form-control"
                                  v-model="form.overtime[index]"
                                  @change="
                                    calculateSubtotalExtraHours;
                                    pushDataLayer(
                                      'Horas Extras',
                                      itemExtraHour.overtime_item.name
                                    );
                                  "
                                  @keyup="calculateSubtotalExtraHours"
                                />
                              </td>
                              <td>
                                <p class="mb-0 desc strong mt-2">
                                  = {{ itemExtraHour.pct }}%
                                </p>
                              </td>
                            </tr>
                            <tr class="bg-1">
                              <td colspan="3">
                                <p class="mb-0 text-center">
                                  <strong class="strong"> Subtotal: </strong>
                                  <span class="subtotal-extra-hours">
                                    ${{
                                      formatNumber(
                                        itemExtraHour.value *
                                          form.overtime[index],
                                        0
                                      )
                                    }}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </table>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="cont-1">
                <div
                  class="header d-flex justify-content-between align-items-center"
                  v-b-toggle.accordion-2
                >
                  <p class="mb-0">Dominicales</p>
                  <i
                    :class="
                      accordion.holiday.visible
                        ? 'icon-flecha-arriba'
                        : 'icon-flecha-abajo'
                    "
                  ></i>
                </div>
                <b-collapse
                  id="accordion-2"
                  v-model="accordion.holiday.visible"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <div class="body">
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tr class="bg-1">
                          <td></td>
                          <td class="tw-w-20">
                            <i class="icon icon-reloj ml-3"></i>
                          </td>
                          <td class="tw-w-20">
                            <span class="icon strong">%</span>
                          </td>
                        </tr>
                        <template
                          v-for="(itemHoliday, index) in arrayHoliday"
                          @key="'extra-hour-' + itemHoliday.overtime_item.id"
                        >
                          <template>
                            <tr class="bg-2">
                              <td class="px-2 px-md-3 px-lg-4">
                                <p class="mb-0 desc mt-2">
                                  {{ itemHoliday.overtime_item.name }}
                                </p>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  max="99"
                                  class="number-text form-control"
                                  v-model="form.holiday[index]"
                                  @change="
                                    calculateSubtotalHoliday;
                                    pushDataLayer(
                                      'Dominicales',
                                      itemHoliday.overtime_item.name
                                    );
                                  "
                                  @keyup="calculateSubtotalHoliday"
                                />
                              </td>
                              <td>
                                <p class="mb-0 desc strong mt-2">
                                  = {{ itemHoliday.pct }}%
                                </p>
                              </td>
                            </tr>
                            <tr class="bg-1">
                              <td colspan="3">
                                <p class="mb-0 text-center">
                                  <strong class="strong"> Subtotal: </strong>
                                  <span class="subtotal-holiday">
                                    ${{
                                      formatNumber(
                                        itemHoliday.value * form.holiday[index],
                                        0
                                      )
                                    }}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </table>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="cont-1">
                <div
                  class="header d-flex justify-content-between align-items-center"
                  v-b-toggle.accordion-3
                >
                  <p class="mb-0">Recargos</p>
                  <i
                    :class="
                      accordion.surcharge.visible
                        ? 'icon-flecha-arriba'
                        : 'icon-flecha-abajo'
                    "
                  ></i>
                </div>
                <b-collapse
                  id="accordion-3"
                  v-model="accordion.surcharge.visible"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <div class="body">
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tr class="bg-1">
                          <td></td>
                          <td class="tw-w-20">
                            <i class="icon icon-reloj ml-3"></i>
                          </td>
                          <td class="tw-w-20">
                            <span class="icon strong">%</span>
                          </td>
                        </tr>
                        <template
                          v-for="(itemSurchage, index) in arraySurcharge"
                          @key="'extra-hour-' + itemSurchage.overtime_item.id"
                        >
                          <template>
                            <tr class="bg-2">
                              <td class="px-2 px-md-3 px-lg-4">
                                <p class="mb-0 desc mt-2">
                                  {{ itemSurchage.overtime_item.name }}
                                </p>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  max="99"
                                  class="number-text form-control"
                                  v-model="form.surcharge[index]"
                                  @change="
                                    calculateSubtotalSurcharge;
                                    pushDataLayer(
                                      'Recargos',
                                      itemSurchage.overtime_item.name
                                    );
                                  "
                                  @keyup="calculateSubtotalSurcharge"
                                />
                              </td>
                              <td>
                                <p class="mb-0 desc strong mt-2">
                                  = {{ itemSurchage.pct }}%
                                </p>
                              </td>
                            </tr>
                            <tr class="bg-1">
                              <td colspan="3">
                                <p class="mb-0 text-center">
                                  <strong class="strong"> Subtotal: </strong>
                                  <span class="subtotal-surcharge">
                                    ${{
                                      formatNumber(
                                        itemSurchage.value *
                                          form.surcharge[index],
                                        0
                                      )
                                    }}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </table>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class="text-right my-4">
              <span class="link" @click="resetData">Borrar datos</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-3">
          <div class="cont-4 p-3 mb-4">
            <div class="cont-title text-center mb-4">
              <p class="strong"><span class="icon text-red">$ </span>Total</p>
            </div>
            <div class="cont-data">
              <div class="data-1 py-3 d-flex justify-content-between">
                <p class="text mb-0 mr-3 strong">Horas extras</p>
                <p class="price mb-0 light">
                  ${{ formatNumber(totalExtraHours, 0) }}
                </p>
              </div>
              <div class="data-1 py-3 d-flex justify-content-between">
                <p class="text mb-0 mr-3 strong">Dominicales y festivos:</p>
                <p class="price mb-0 light">
                  ${{ formatNumber(totalHoliday, 0) }}
                </p>
              </div>
              <div class="data-1 py-3 d-flex justify-content-between">
                <p class="text mb-0 mr-3 strong">Recargos:</p>
                <p class="price mb-0 light">
                  ${{ formatNumber(totalSurcharge, 0) }}
                </p>
              </div>
              <div class="data-1 py-3 d-flex justify-content-between text-red">
                <p class="text mb-0 mr-3 strong">PAGO TOTAL:</p>
                <p class="price mb-0 light">
                  ${{ formatNumber(fullPayment, 0) }}
                </p>
              </div>
            </div>
            <div class="desc mt-4">
              <p>
                <span>*</span> Esta herramienta arroja un valor aproximado de
                referencia y el pago de tiempo suplementario se hace acorde al
                cronograma de nomina. Cualquier novedad consulta con tu líder.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApiBeerOffice from "@/services/apiBeerOffice";
import numbersMixin from "@/mixins/general/numbers";

import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import MenuExtraHours from "@/components/show-me-the-money/extra-hours/MenuExtraHours";
import BannerExtraHours from "@/components/show-me-the-money/extra-hours/BannerExtraHours";

export default {
  name: "CalculatorExtraHours",
  components: {
    SecondaryNavbar,
    MenuExtraHours,
    BannerExtraHours,
  },
  mixins: [numbersMixin],
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Tus horas extras",
          link: "/showMeTheMoney/extra-hours",
        },
        {
          id: 5,
          name: "Calculadora",
          link: "#",
          active: true,
        },
      ],
      hourValue: 0,
      monthSalary: 0,
      accordion: {
        overtime: {
          visible: true,
        },
        holiday: {
          visible: false,
        },
        surcharge: {
          visible: false,
        },
        workhour: {
          visible: false,
        },
      },
      form: {
        overtime: [],
        holiday: [],
        surcharge: [],
      },
      totalExtraHours: 0,
      totalHoliday: 0,
      totalSurcharge: 0,
      arrayExtraHours: [],
      arrayHoliday: [],
      arraySurcharge: [],
    };
  },
  created() {
    this.actgetOverTimeItems().then(() => {
      setTimeout(() => {
        this.assignInitialArrayValues();
      }, 200);
      setTimeout(() => {
        this.calculateAllValues();
      }, 300);
    });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    fullPayment() {
      return (
        parseFloat(this.totalExtraHours) +
        parseFloat(this.totalHoliday) +
        parseFloat(this.totalSurcharge)
      );
    },
  },
  methods: {
    async actgetOverTimeItems() {
      let response = await ApiBeerOffice.getOverTimeItmes();
      if (response.error) {
      } else {
        this.hourValue = response.data.hour_value;
        this.monthSalary = response.data.total_salary;
        this.arrayExtraHours = response.data.items.filter((item) => {
          return item.overtime_item.type === "OVERTIME";
        });
        this.arrayHoliday = response.data.items.filter((item) => {
          return item.overtime_item.type === "HOLIDAY";
        });
        this.arraySurcharge = response.data.items.filter((item) => {
          return item.overtime_item.type === "SURCHARGE";
        });
      }
    },
    calculateSubtotalExtraHours() {
      let subtotals = document.querySelectorAll(".subtotal-extra-hours");
      let sumSubTotals = 0;
      subtotals.forEach((subtotal) => {
        sumSubTotals =
          sumSubTotals +
          parseFloat(subtotal.innerText.replace("$", "").replace(",", ""));
      });
      this.totalExtraHours = sumSubTotals;
    },
    calculateSubtotalHoliday() {
      let subtotals = document.querySelectorAll(".subtotal-holiday");
      let sumSubTotals = 0;
      subtotals.forEach((subtotal) => {
        sumSubTotals =
          sumSubTotals +
          parseFloat(subtotal.innerText.replace("$", "").replace(",", ""));
      });
      this.totalHoliday = sumSubTotals;
    },
    calculateSubtotalSurcharge() {
      let subtotals = document.querySelectorAll(".subtotal-surcharge");
      let sumSubTotals = 0;
      subtotals.forEach((subtotal) => {
        sumSubTotals =
          sumSubTotals +
          parseFloat(subtotal.innerText.replace("$", "").replace(",", ""));
      });
      this.totalSurcharge = sumSubTotals;
    },
    calculateAllValues() {
      this.calculateSubtotalExtraHours();
      this.calculateSubtotalHoliday();
      this.calculateSubtotalSurcharge();
    },
    assignInitialArrayValues() {
      this.form.overtime = Array(this.arrayExtraHours.length).fill(0);
      this.form.holiday = Array(this.arrayHoliday.length).fill(0);
      this.form.surcharge = Array(this.arraySurcharge.length).fill(0);
    },
    resetData() {
      let inputsNumberText = document.querySelectorAll(".number-text");
      inputsNumberText.forEach((inputText) => {
        inputText.value = 0;
      });
      this.assignInitialArrayValues();
      setTimeout(() => {
        this.calculateAllValues();
      }, 200);
    },
    pushDataLayer(label, type) {
      window.dataLayer.push({
        event: "TrakEvent-compensacion",
        category: "Remuneracion",
        SubCategory: "Tus horas extras",
        Action: "Resultado",
        label: label,
        Type: type,
      });
    },
  },
};
</script>

<style scoped>
.text-red {
  color: #d82b2b;
}
.cont-2 {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 14px;
}

.cont-2 .cont-text .cont-desc .title {
  color: #d82b2b;
  font-size: 18px;
  font-family: "RobotoBold", sans-serif;
}

.cont-2 .cont-text .cont-desc .text {
  color: #454545;
  font-size: 14px;
}

.cont-2 .cont-text .cont-icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #ebebeb;
  margin-top: -10px;
}

.cont-2 .cont-text .cont-icon i {
  color: #d82b2b;
  font-size: 28px;
}

.cont-2 .cont-text .main-cont-icon {
  position: absolute;
  top: -60px;
  left: calc(50% - 19px);
}

.cont-cards .card-1 {
  padding: 14px;
  border-radius: 8px;
}

.cont-cards .card-1.black {
  border: 1px solid #343434;
}

.cont-cards .card-1.red {
  border: 1px solid #d82b2b;
  color: #d82b2b;
}

.cont-cards .card-1 .text {
  font-family: "RobotoBold", sans-serif;
  font-size: 14px;
}

.cont-cards .card-1 .price {
  font-family: "RobotoLight", sans-serif;
  font-size: 16px;
}

.cont-3 .cont-title .cont-icon i {
  color: #d82b2b;
  font-size: 30px;
}

.cont-3 .cont-title .text {
  color: #343434;
  font-family: "RobotoBold", sans-serif;
  font-size: 18px;
}

.accordion {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.accordion .header {
  padding: 20px;
  outline: none;
  border-bottom: 1px solid #bababa46;
}

.accordion .header p {
  color: #343434;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
}

.accordion .header i {
  color: #d82b2b;
  font-size: 28px;
}

.table p {
  font-size: 14px;
}

.table .bg-0 {
  background: #fff;
}

.table .bg-0 p {
  color: #34343466;
  font-size: 12px;
  line-height: 14px;
}

.table .bg-1 {
  background-color: #d8d8d8;
}

.table .bg-2 {
  background-color: #f3f3f3;
}

.table .icon {
  color: #343434;
  font-size: 14px;
  font-weight: bold;
}

.table .desc {
  color: #34343466;
}

.table input {
  color: #d82b2b;
  font-family: "RobotoBold", sans-serif;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.cont-3 .link {
  color: #d82b2b;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.cont-4 {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 14px;
  color: #343434;
}

.cont-4 .cont-title p {
  font-size: 20px;
}

.cont-4 .cont-data .data-1 {
  border-bottom: 1px solid #bababa45;
}

.cont-4 .cont-data .data-1 .text {
  font-size: 14px;
}

.cont-4 .cont-data .data-1 .price {
  font-size: 20px;
}

.cont-4 .cont-data .text-red {
  color: #d82b2b;
}

.cont-4 .desc p {
  color: #bababa;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
}

.cont-4 .desc p span {
  color: #d82b2b;
}

@media (min-width: 768px) {
  .cont-2 .cont-text .cont-desc .title {
    font-size: 28px;
  }

  .cont-2 .cont-text .main-cont-icon {
    position: relative;
    top: inherit;
    left: inherit;
  }

  .cont-cards .card-1 {
    padding: 24px;
  }

  .cont-cards .card-1 .price {
    font-size: 22px;
  }

  .table .bg-0 p {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
