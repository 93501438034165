var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-ranking"},[_c('b-tabs',{attrs:{"active-nav-item-class":"tw-text-white","active-tab-class":"tw-text-gray-600","content-class":"mt-n0","fill":""}},_vm._l((_vm.rankCategories),function(category){return _c('b-tab',{key:'ranking-category-' + category.id,attrs:{"title":category.name},on:{"click":function($event){return _vm.onChangeCategorySelected(
          category.id,
          category.rank_people_category_type_id,
          category.color
        )}}},[_c('div',{staticClass:"px-3 pt-3 pb-5",style:('background-color:' + category.color + ';')},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('img',{staticClass:"tw-rounded-full tw-object-cover",attrs:{"src":_vm.uriResources + '/' + category.icon,"alt":"flag","width":"26","height":"26"}}),_c('p',{staticClass:"tw-text-white tw-text-xl font-weight-bold mx-2 mb-0"},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('img',{staticClass:"tw-object-cover",attrs:{"src":_vm.uriResources + '/' + category.rank_people.logo,"width":"90","height":"50","alt":"img 1"}})])]),_c('div',{staticClass:"tw-bg-white px-2 pt-4 pb-3 mt-n4 tw-rounded-xl tw-shadow-md he-350 overflow-auto"},_vm._l((_vm.rankUsers),function(user){return _c('div',{key:'ranking-user-' + user.id,staticClass:"d-flex p-3 tw-rounded-lg align-items-center mb-2",class:user.business_partner.selected
              ? 'tw-bg-green-300'
              : 'tw-bg-gray-200',attrs:{"role":"button"},on:{"click":function($event){return _vm.changeUserInfo(user)}}},[(user.position <= 3)?_c('img',{staticClass:"mr-2",attrs:{"src":_vm.imgMedal(user.position),"width":"24","height":"34","alt":"medalla"}}):_c('div',{staticClass:"mr-3",staticStyle:{"width":"24px"}},[_c('p',{staticClass:"mb-0 font-weight-bold tw-text-gray-300"},[_vm._v(" "+_vm._s(user.position)+" ")])]),_c('img',{staticClass:"tw-rounded-full",class:user.position === 1
                ? 'tw-border-4 tw-border-solid tw-border-yellow-400 '
                : user.position === 2
                ? 'tw-border-4 tw-border-solid tw-border-gray-400 '
                : user.position === 3
                ? 'tw-border-4 tw-border-solid tw-border-brown-400 '
                : '',attrs:{"src":user.business_partner.prof_picture
                ? _vm.uriResources + '/' + user.business_partner.prof_picture
                : _vm.imgAvatar,"width":"40","height":"40","alt":"user"}}),_c('div',{staticClass:"mx-2 w-100"},[_c('p',{staticClass:"mb-1 tw-text-gray-400 tw-text-xs font-weight-bold"},[_vm._v(" "+_vm._s(user.business_partner.name)+" ")]),_c('p',{staticClass:"mb-0 tw-text-gray-400 tw-text-xxs"},[_vm._v(_vm._s(user.name))])]),_c('i',{staticClass:"icon-flecha-der tw-text-gray-400 tw-text-xl"})])}),0)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }