<template>
  <div id="page-request-forms">
    <div class="layout-content">
      <div class="tw-container-general">
        <SecondaryNavbar title="Solicitar documento" :breadcrumb="breadcrumb" />
      </div>
      <div class="container mt-4 mt-md-2">
        <div class="col-12 px-0">
          <div class="shadow-1 py-4 px-3 mb-5">
            <div class="row">
              <div class="col-12 col-lg-6">
                <p class="title">Solicitar un documento</p>
                <p class="desc">
                  En esta sección puedes solicitar tu certificado laboral,
                  selecciona qué tipo de documento deseas, luego diligencia los
                  datos para solicitar tu certificado.
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <select
                  class="form-control"
                  v-model="formData.typeDocument"
                  @change="resetFormData($event)"
                >
                  <option value="1">Certificado laboral</option>
                  <option value="2">Comprobante de nómina</option>
                  <option value="3">Certificado de deudas</option>
                </select>
              </div>
            </div>
            <form v-if="showForm" @submit.prevent="sendFormDocumentData">
              <div class="row mt-3 mt-lg-0">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="email">Correo electrónico</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      class="form-control"
                      required="required"
                      v-model="formData.email"
                      disabled
                    />
                    <div
                      v-if="formData.email == null || formData.email === ''"
                      class="alert alert-warning mt-2"
                      role="alert"
                    >
                      No tienes un correo, por favor agrégalo haciendo
                      <router-link to="/profile/personalData"
                        >clic aquí</router-link
                      >.
                    </div>
                  </div>
                  <template v-if="isAdressee">
                    <div class="form-group">
                      <label>Destinatario</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="radioAdressee"
                        class="custom-control-input"
                        id="radioAddresseeWho"
                        value="interese"
                        v-on:change="changeRadioAdressee($event)"
                        v-model="formData.radioAdressee"
                        checked
                      />
                      <label
                        class="custom-control-label"
                        for="radioAddresseeWho"
                        >A quien le interese</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="radioAdressee"
                        class="custom-control-input"
                        id="radioAddresseeOther"
                        value="otro"
                        v-on:change="changeRadioAdressee($event)"
                        v-model="formData.radioAdressee"
                      />
                      <label
                        class="custom-control-label"
                        for="radioAddresseeOther"
                        >Otro</label
                      >
                    </div>
                    <div class="form-group mt-3">
                      <input
                        type="text"
                        class="form-control"
                        id="inputTextAdressee"
                        name="emailAdressee"
                        placeholder="Escribe el destinatario"
                        required="required"
                        maxlength="20"
                        v-model="formData.inputTextAdressee"
                        disabled
                      />
                    </div>
                  </template>
                </div>
                <div class="col-12 col-lg-6">
                  <div v-if="formData.typeDocument === '1'">
                    <div class="form-group mt-3 mb-1 mt-lg-0">
                      <label>Vacaciones</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="vacations"
                        v-model="formData[1].vacations"
                        class="custom-control-input"
                        id="vacationsYes"
                        value="si"
                      />
                      <label class="custom-control-label" for="vacationsYes"
                        >Sí</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="vacations"
                        v-model="formData[1].vacations"
                        class="custom-control-input"
                        id="vacationsNo"
                        value="no"
                        checked
                      />
                      <label class="custom-control-label" for="vacationsNo"
                        >No</label
                      >
                    </div>
                    <div class="form-group mt-3 mb-1">
                      <label>Salario</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="salary"
                        v-model="formData[1].salary"
                        class="custom-control-input"
                        id="salaryYes"
                        value="si"
                      />
                      <label class="custom-control-label" for="salaryYes"
                        >Sí</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="salary"
                        v-model="formData[1].salary"
                        class="custom-control-input"
                        id="salaryNo"
                        value="no"
                        checked
                      />
                      <label class="custom-control-label" for="salaryNo"
                        >No</label
                      >
                    </div>
                    <div class="form-group mt-3 mb-1">
                      <label>Información prima</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="bonus"
                        v-model="formData[1].bonus"
                        class="custom-control-input"
                        id="bonusYes"
                        value="si"
                      />
                      <label class="custom-control-label" for="bonusYes"
                        >Sí</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="bonus"
                        v-model="formData[1].bonus"
                        class="custom-control-input"
                        id="bonusNo"
                        value="no"
                        checked
                      />
                      <label class="custom-control-label" for="bonusNo"
                        >No</label
                      >
                    </div>
                    <div class="form-group mt-3 mb-1">
                      <label>Patrocinio</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="sponsorship"
                        v-model="formData[1].sponsorship"
                        class="custom-control-input"
                        id="sponsorshipYes"
                        value="si"
                      />
                      <label class="custom-control-label" for="sponsorshipYes"
                        >Sí</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="sponsorship"
                        v-model="formData[1].sponsorship"
                        class="custom-control-input"
                        id="sponsorshipNo"
                        value="no"
                        checked
                      />
                      <label class="custom-control-label" for="sponsorshipNo"
                        >No</label
                      >
                    </div>
                    <div class="form-group mt-3 mb-1">
                      <label>Bono de desempeño</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="bonusPerformance"
                        v-model="formData[1].bonusPerformance"
                        class="custom-control-input"
                        id="bonusPerformanceYes"
                        value="si"
                      />
                      <label
                        class="custom-control-label"
                        for="bonusPerformanceYes"
                        >Sí</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="bonusPerformance"
                        v-model="formData[1].bonusPerformance"
                        class="custom-control-input"
                        id="bonusPerformanceNo"
                        value="no"
                        checked
                      />
                      <label
                        class="custom-control-label"
                        for="bonusPerformanceNo"
                        >No</label
                      >
                    </div>
                  </div>
                  <!-- Type 1 -->
                  <div v-if="formData.typeDocument === '2'">
                    <div class="form-group mt-3 mb-1 mt-lg-0">
                      <label>Fecha</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="datePayroll"
                        class="custom-control-input"
                        id="dateLastMonth"
                        value="mes"
                        v-on:change="changeRadioDate($event)"
                        v-model="formData[2].datePayroll"
                        checked
                      />
                      <label class="custom-control-label" for="dateLastMonth"
                        >Último mes</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="datePayroll"
                        class="custom-control-input"
                        id="dateOther"
                        value="otro"
                        v-on:change="changeRadioDate($event)"
                        v-model="formData[2].datePayroll"
                      />
                      <label class="custom-control-label" for="dateOther"
                        >Otro</label
                      >
                    </div>
                    <div class="form-group mt-3" style="width: 200px">
                      <small>Indica la fecha</small>
                      <input
                        type="date"
                        class="form-control"
                        id="inputDate"
                        name="inputDate"
                        placeholder="DD/MM/AAAA"
                        min="2017-01-01"
                        :max="convertDate(new Date())"
                        required="required"
                        v-model="formData[2].date"
                        disabled
                      />
                    </div>
                  </div>
                  <!-- Type 2 -->
                  <div v-if="formData.typeDocument === '3'">
                    <div class="form-group mt-3 mb-1 mt-lg-0">
                      <label>Fecha</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="dateDebt"
                        class="custom-control-input"
                        id="dateLastYear"
                        value="anio"
                        v-on:change="changeRadioDateDebt($event)"
                        v-model="formData[3].dateDebt"
                        checked
                      />
                      <label class="custom-control-label" for="dateLastYear"
                        >Último año</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        name="dateDebt"
                        class="custom-control-input"
                        id="dateOther"
                        value="otro"
                        v-on:change="changeRadioDateDebt($event)"
                        v-model="formData[3].dateDebt"
                      />
                      <label class="custom-control-label" for="dateOther"
                        >Otro</label
                      >
                    </div>
                    <div class="form-group mt-3">
                      <small>Indica el año</small>
                      <select
                        name="yearDebt"
                        class="form-control"
                        style="width: 100px"
                        id="selYearDebt"
                        disabled
                        v-model="formData[3].yearDebt"
                      >
                        <option
                          v-for="year in years"
                          :value="year"
                          :key="'anio-' + year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Type 3 -->
                </div>
              </div>
              <div v-if="this.formData.email !== ''" class="row mt-5">
                <div class="col-12 text-center">
                  <button class="btn btn-primary px-5" type="submit">
                    Solicitar
                  </button>
                </div>
              </div>
            </form>
            <div v-else id="cont-callbackForm" class="mt-4">
              <div class="row" v-if="stateSend === 'loading'">
                <div class="col-12">
                  <div class="text-center mt-3">
                    <img src="../../assets/img/icons/loading.gif" alt="loading" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="stateSend === 'success'">
                <div class="col-12">
                  <div class="cont-icon success text-center mb-4">
                    <i :class="notification.icon"></i>
                  </div>
                  <div :class="notification.type + ' cont-text text-center'">
                    <p class="title">{{ notification.title }}</p>
                    <p class="desc">{{ notification.message }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import SecondaryNavbar from "@/components/general/SecondaryNavbar";

export default {
  name: "RequestForm",
  components: {
    SecondaryNavbar,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Solicitar documento",
          link: "#",
          active: true,
        },
      ],
      notification: {
        type: "success",
        icon: "icon-check-simple",
        title: "¡Listo!",
        message: "Tu certificado ha sido solicitado.",
      },
      showForm: true,
      stateSend: "loading",
      isAdressee: true,
      formData: {
        typeDocument: this.$route.params.id,
        email: "",
        radioAdressee: "interese",
        inputTextAdressee: "A quien le interese",
        1: {
          vacations: "no",
          salary: "no",
          bonus: "no",
          sponsorship: "no",
          bonusPerformance: "no",
        },
        2: {
          datePayroll: "mes",
          date: new Date(),
        },
        3: {
          dateDebt: "anio",
          yearDebt: new Date().getFullYear() - 1,
        },
      },
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
    years() {
      const year = new Date().getFullYear() - 1;
      return Array.from(
        { length: year - 2011 },
        (value, index) => 2012 + index
      );
    },
  },
  created() {
    this.formData.email = this.user.email
      ? this.user.email
      : this.user.email_personal;

    this.formData.typeDocument === "2"
      ? (this.isAdressee = false)
      : (this.isAdressee = true);
  },
  methods: {
    ...mapActions("modDocuments", ["actPostDocuments", "actPostDocumentsTest"]),
    convertDate(date) {
      let year = "";
      let month = "";
      let day = "";
      if (typeof date === "object") {
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
        if (month < 10) {
          month = "0" + month.toString();
        }
        if (day < 10) {
          day = "0" + day.toString();
        }
        return `${year}-${month}-${day}`;
      } else {
        return date;
      }
    },
    resetFormData() {
      this.showForm = true;
      this.isAdressee = true;
      this.formData.radioAdressee = "interese";
      this.formData.inputTextAdressee = "A quien le interese";

      this.formData[1].vacations = "no";
      this.formData[1].salary = "no";
      this.formData[1].bonus = "no";
      this.formData[1].sponsorship = "no";
      this.formData[1].bonusPerformance = "no";

      this.formData[2].datePayroll = "mes";
      this.formData[2].date = new Date();

      this.formData[3].dateDebt = "anio";
      this.formData[3].yearDebt = new Date().getFullYear() - 1;

      setTimeout(() => {
        this.changeRadioAdressee();
        if (this.formData.typeDocument === "2") {
          this.changeRadioDate();
          this.isAdressee = false;
        } else if (this.formData.typeDocument === "3") {
          this.changeRadioDateDebt();
        }
      }, 100);
    },
    changeRadioAdressee() {
      let checked = document.querySelector("input[name=radioAdressee]:checked");
      if (checked.value === "otro") {
        document
          .querySelector("#inputTextAdressee")
          .removeAttribute("disabled");
      } else {
        document
          .querySelector("#inputTextAdressee")
          .setAttribute("disabled", "disabled");
      }
    },
    changeRadioDate() {
      let checked = document.querySelector("input[name=datePayroll]:checked");
      if (checked.value === "otro") {
        document.querySelector("#inputDate").removeAttribute("disabled");
      } else {
        document
          .querySelector("#inputDate")
          .setAttribute("disabled", "disabled");
      }
    },
    changeRadioDateDebt() {
      let checked = document.querySelector("input[name=dateDebt]:checked");
      if (checked.value === "otro") {
        document.querySelector("#selYearDebt").removeAttribute("disabled");
      } else {
        document
          .querySelector("#selYearDebt")
          .setAttribute("disabled", "disabled");
      }
    },
    async sendFormDocumentData() {
      this.stateSend = "loading";
      this.showForm = false;
      let dataToSend = {
        to: this.formData.inputTextAdressee,
        vacation: this.formData[1].vacations !== "no",
        salary: this.formData[1].salary !== "no",
        raw_information: this.formData[1].bonus !== "no",
        sponsorship: this.formData[1].sponsorship !== "no",
        performance_bonus: this.formData[1].bonusPerformance !== "no",
        last: this.formData[2].date !== "no",
        payroll_date: this.convertDate(this.formData[2].date),
        year_debts: this.formData[3].yearDebt,
        core_employee_id: this.user.id,
        remu_baucher_type_id: this.formData.typeDocument,
      };

      let response = await this.actPostDocuments(dataToSend);
      if (response) {
        if (response.data.error) {
          this.stateSend = "success";
          this.notification.type = "error";
          this.notification.icon = "icon-cerrar-simple";
          this.notification.title = "Error";
          this.notification.message = response.data.error;
        } else {
          this.stateSend = "success";
          this.notification.type = "success";
          this.notification.icon = "icon-check-simple";
          this.notification.title = "Listo";
          this.notification.message = `Tu certificado ha sido solicitado. Durante los siguientes dos días hábiles te llegará el documento al correo electrónico ${this.formData.email}.`;
        }
      } else {
        this.stateSend = "success";
        this.notification.type = "error";
        this.notification.icon = "icon-cerrar-simple";
        this.notification.title = "Error";
        this.notification.message =
          "Ha ocurrido un error al enviar la información, por favor intentar más tarde.";
      }
    },
  },
};
</script>

<style scoped>
#page-request-forms {
  color: #333333;
}

#page-request-forms .shadow-1 {
  border-radius: 15px;
  box-shadow: 0 2px 4px 2px #e0e0e0;
  margin-bottom: 10px;
}

#page-rquest-forms small {
  color: #bababa;
}

#page-request-forms .title {
  font-size: 20px;
  font-family: "RobotoBold", sans-serif;
}

#page-request-forms .desc {
  font-size: 14px;
}

#page-request-forms .form-group label {
  font-family: "RobotoBold", sans-serif;
  font-size: 16px;
}

#page-request-forms
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #23bf79;
  background-color: #23bf79;
}

#page-request-forms .custom-control-label {
  padding-top: 4px;
}

#page-request-forms #cont-callbackForm .cont-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

#page-request-forms #cont-callbackForm .cont-icon i {
  font-size: 70px;
  font-weight: bold;
}

#page-request-forms #cont-callbackForm .cont-icon.success {
  background-color: #90dfbb;
  border: 1px solid #23bf79;
}

#page-request-forms #cont-callbackForm .cont-icon.success i {
  color: #23bf79;
}

#page-request-forms #cont-callbackForm .cont-text .title {
  font-size: 26px;
  font-family: "RobotoBold", sans-serif;
}

#page-request-forms #cont-callbackForm .cont-text.success .title {
  color: #23bf79;
}

#page-request-forms #cont-callbackForm .cont-text .desc {
  font-size: 14px;
}

@media (min-width: 768px) {
  #page-request-forms .title {
    font-size: 25px;
  }

  #page-request-forms #cont-callbackForm .cont-icon {
    width: 160px;
    height: 160px;
  }

  #page-request-forms #cont-callbackForm .cont-icon i {
    font-size: 100px;
  }

  #page-request-forms #cont-callbackForm .cont-text .desc {
    font-size: 16px;
  }
}
</style>
