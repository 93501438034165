<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useRatingStore } from "@/stores/ratingStore";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const ratingStore = useRatingStore();
const isShowModal = ref(false);
const rating = ref(0);
const comment = ref("");

const disabled = computed(() => {
  return rating.value === 0 || comment.value === "";
});

function closeModal() {
  isShowModal.value = false;
}

function setRating(value) {
  rating.value = value;
}

async function sendRating() {
  await ratingStore.actPostNPSScore({
    score: rating.value,
    comment: comment.value,
  });
  closeModal();
}

onBeforeMount(async () => {
  await ratingStore.actGetNPS();
  if (!ratingStore.nps) {
    isShowModal.value = true;
  }
});
</script>

<template>
  <div>
    <general-modal
      v-if="isShowModal"
      :modalWidth="{ md: 400, lg: 500, xl: 500 }"
      :maxHeight="{ base: 400, md: 450, lg: 500, xl: 500 }"
      modal-name="nps"
      :isShowModal="isShowModal"
      @close="closeModal"
      :closable="false"
    >
      <div
        class="tw-flex tw-flex-col tw-h-full tw-gap-3 tw-mb-2"
        v-if="isShowModal"
      >
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-lg lg:tw-text-2xl tw-text-center"
        >
          Cuéntanos tu experiencia
        </p>
        <p class="tw-font-roboto tw-text-sm lg:tw-text-base tw-m-0">
          ¿Qué tan probable es que recomiendes Embajadores a tus amigos o
          compañeros de trabajo?
        </p>
        <div class="tw-grid tw-grid-cols-10 tw-gap-2">
          <div
            v-for="i in 10"
            :key="i"
            class="tw-border tw-py-1 tw-text-center tw-text-sm lg:tw-text-lg tw-font-medium tw-cursor-pointer hover:tw-text-white"
            :class="{
              'tw-border-red-350 tw-text-red-350 hover:tw-bg-red-350':
                i <= 6 && rating !== i,
              'tw-border-yellow-400 tw-text-yellow-400 hover:tw-bg-yellow-400':
                i > 6 && i <= 8 && rating !== i,
              'tw-border-green-400 tw-text-green-400 hover:tw-bg-green-400':
                i > 8 && rating !== i,
              'tw-bg-red-350 tw-text-white': i === rating && i <= 6,
              'tw-bg-yellow-400 tw-text-white': i === rating && i > 6 && i <= 8,
              'tw-bg-green-400 tw-text-white': i === rating && i > 8,
            }"
            @click="setRating(i)"
          >
            <span class="tw-pointer-events-none">{{ i }}</span>
          </div>
        </div>
        <div class="tw-w-full tw-flex tw-justify-between">
          <p class="tw-m-0 tw-font-roboto tw-text-red-350">Nada probable</p>
          <p class="tw-m-0 tw-font-roboto tw-text-green-400">Muy probable</p>
        </div>
        <p class="tw-font-roboto tw-text-sm lg:tw-text-base tw-m-0">
          ¿Quieres compartir con nosotros tu experiencia? Escríbenos, es muy
          importante conocer tu opinión.
        </p>
        <textarea
          name="comentario"
          rows="2"
          cols="40"
          class="tw-resize-none tw-border tw-border-gray-300 tw-rounded-2xl comment-area"
          v-model="comment"
        ></textarea>
        <button-primary-red
          title="Siguiente"
          :disabled="disabled"
          @click.native="sendRating"
        />
      </div>
    </general-modal>
  </div>
</template>

<style scoped>
.comment-area:focus {
  outline: none !important;
  border: 1px solid #bababa;
  box-shadow: 0 0 0;
}
</style>
