<script setup>
import config from "@/services/config";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import { storeToRefs } from "pinia";
import { useReservationsStore } from "@/stores/reservationsStore";
import { computed } from "vue";

const uriResources = config.beerOfficeURL;
const {
  bookingStep,
  bookingDatesSelected,
  bookingPlaceSelected,
  bookingIsHighSeason,
  bookingPlaceDetail,
} = storeToRefs(useReservationsStore());

defineProps({
  guestsNumbers: String,
  changeStep: Function,
});

const mainImg = computed(() => {
  return bookingPlaceDetail.value.photos.filter(
    (item) => item.is_main === true
  )[0].image;
});
</script>

<template>
  <div id="spaceInfo" class="tw-hidden md:tw-block">
    <div
      class="tw-border tw-border-gray-250 tw-rounded-4xl tw-w-full tw-p-10 tw-flex tw-flex-col tw-gap-5"
    >
      <div id="spaceTitle" class="tw-flex tw-flex-col tw-gap-3">
        <p class="tw-m-0 tw-font-robotoBold tw-text-2xl">
          Información de la reserva
        </p>
        <p class="tw-font-robotoBold tw-text-4xl">
          {{ bookingPlaceDetail.name }}
        </p>
        <div id="photoAndPrice">
          <div
            class="tw-aspect-w-16 tw-aspect-h-8 tw-rounded-2.5xl tw-overflow-hidden"
          >
            <img
              :src="uriResources + '/' + mainImg"
              alt=""
              class="tw-h-full tw-object-cover tw-select-none"
            />
          </div>
          <p
            class="tw-font-robotoBold tw-text-xl tw-mt-2 tw-mb-0 tw-text-right"
          >
            {{
              Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                maximumFractionDigits: 0,
              }).format(bookingPlaceDetail.price) + " COP / noche"
            }}
          </p>
        </div>
      </div>

      <div v-show="bookingStep === 1" id="placeDescription">
        <p class="tw-m-0 tw-font-roboto tw-text-lg">
          {{ bookingPlaceDetail.description }}
        </p>
      </div>
      <div v-show="bookingStep >= 2" id="chosenCharacteristics">
        <hr class="tw-w-full tw-my-5" />
        <div>
          <div
            id="chosenCapacity"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
              >
                Capacidad
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400">
                {{ bookingPlaceSelected.capacity + " personas" }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-my-5" />
        </div>
        <div>
          <div
            v-show="bookingStep >= 2"
            id="chosenSeason"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
              >
                Temporada
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400">
                {{ bookingIsHighSeason ? "Alta" : "Baja" }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mt-5" />
        </div>
        <div v-show="bookingStep >= 3">
          <div
            id="chosenStartDate"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
              >
                Fecha inicio
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400">
                {{ formatDateYYYYMMDDLong(bookingDatesSelected.start_date) }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mt-5" />
        </div>
        <div v-show="bookingStep >= 3">
          <div
            id="chosenEndDate"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
              >
                Fecha final
              </p>
            </div>
            <div>
              <p class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400">
                {{ formatDateYYYYMMDDLong(bookingDatesSelected.end_date) }}
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mt-5" />
        </div>
        <div v-show="bookingStep >= 4">
          <div
            id="chosenGuests"
            class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
          >
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
              >
                Huéspedes
              </p>
            </div>
            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400 tw-whitespace-pre-line"
              >
                {{ guestsNumbers }}
              </p>
            </div>
            <div class="tw-cursor-pointer" @click="changeStep(3)">
              <p
                class="tw-m-0 tw-font-roboto tw-text-lg tw-underline tw-text-red-350 tw-text-right"
              >
                Cambiar
              </p>
            </div>
          </div>
          <hr class="tw-w-full tw-mt-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
