<script setup>
import MobileBar from "@/components/general/MobileBar";
import BreadCrumb from "@/components/general/BreadCrumb";
import CompGamificationButtons from "@/components/gamification/CompGamificationButtons.vue";

const breadcrumb = [
  {
    id: 1,
    name: "Home",
    link: "/home",
  },
  {
    id: 2,
    name: "La Maltería",
    link: "/gamification/nuevo",
  },
];
</script>
<template>
  <div>
    <portal-target name="modal-wrong" />
    <div class="tw-container-general" id="gamificationHome">
      <mobile-bar title="LA MALTERÍA" />
      <div class="tw-container tw-mx-auto tw-max-w-screen-xl d-md-block d-none">
        <div class="row">
          <div class="col-12 mt-4">
            <BreadCrumb :crumbs="breadcrumb" />
          </div>
        </div>
      </div>
      <div class="tw-container tw-mx-auto tw-max-w-screen-xl">
        <div class="d-flex d-md-none">
          <div class="px-3 p-md-0">
            <p
              class="tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"
            >
              Completa misiones, gana puntos y redime premios
            </p>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <div class="col-12 col-md-auto px-0">
            <comp-gamification-buttons class="py-2" />
          </div>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>
