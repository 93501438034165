<template>
  <div>
    <div class="mt-md-4 px-0 px-md-3 px-lg-5">
      <div class="pb-3 content">
        <div class="d-flex d-row justify-content-end img-patron2">
          <img src="@/assets/img/gamification/patron2.png" alt="" />
        </div>
        <div class="container">
          <div class="cont-bar mx-md-auto mt-4 mb-2 mb-md-4 px-4 px-md-5 py-2">
            <div class="d-flex justify-content-between mb-0">
              <span class="font-weight-bold text-bar-title">INSIGNIAS</span>

              <span class="text-bar-gamification pt-md-3 pt-2 align-bottom"
                ><img
                  class="mr-2"
                  src="@/assets/img/gamification/moneda.png"
                  alt=""
                />{{ cantCompleted }} de {{ cantCoins }}</span
              >
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: this.Percent + '%' }"
                aria-valuemin="0"
                aria-valuemax="10"
              ></div>
            </div>
          </div>
        </div>
        <!--Gamifications-->

        <div class="row pt-0 pt-md-3 pb-5" v-if="isLoading">
          <div class="col">
            <div class="text-center mt-3">
              <img src="../../assets/img/icons/loading.gif" alt="loading" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row cont-cards margin-cards" style="padding-bottom: 60px">
            <div
              class="col-4 col-lg-3 col-xl-2 mt-4"
              v-for="mission of missions"
              v-bind:key="mission.id"
            >
              <div @click="modalItem(mission, mission.id)">
                <router-link to="">
                  <div
                    class="cont-gamification p-0 py-3 text-center"
                    v-if="
                      mission.status != null &&
                      mission.status.progress >= mission.status.required
                    "
                  >
                    <img
                      :src="uriResources + '/storage/images/' + mission.image"
                      alt=""
                    />
                    <p
                      class="desc-card mb-0 mt-2 mt-md-3 d-flex align-items-center justify-content-center px-2"
                    >
                      {{ mission.name }}
                    </p>
                  </div>
                  <div v-else class="cont-gamification p-0 py-3 text-center">
                    <img
                      src="@/assets/img/gamification/tapa-no-desploqueada.png"
                      alt=""
                    />
                    <p
                      class="desc-card d-flex align-items-center justify-content-center px-2 mb-0 mt-2 mt-md-3"
                    >
                      {{ mission.name }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          ref="modal-item"
          id="modal-sell"
          centered
          hide-header
          hide-footer
          size="sm"
          no-close-on-esc
          role="dialog"
          class="cont-modal"
        >
          <router-link to="">
            <img
              @click="closeModal()"
              class="img-close"
              src="@/assets/img/icons/x.png"
              alt=""
            />
          </router-link>
          <div
            v-if="
              this.gami.status != null &&
              this.gami.status.progress >= this.gami.status.required
            "
            class="d-flex d-row justify-content-center mt-4"
          >
            <img
              :src="uriResources + '/storage/images/' + this.gami.image"
              width="100%"
              alt=""
              srcset=""
              class="img-gami"
            />
          </div>
          <div v-else class="d-flex d-row justify-content-center mt-4">
            <img
              src="@/assets/img/gamification/tapa-no-desploqueada.png"
              alt=""
              srcset=""
              class="img-gami tw-w-full"
            />
          </div>
          <div class="px-3 px-md-0 mt-4">
            <div
              class="cont-text d-flex h-100 flex-column justify-content-center text-center mb-3 mb-md-0"
            >
              <p class="mx-3 mb-3 text-title">
                {{ this.gami.name }}
              </p>
              <p class="mx-3 mb-0 text-desc">
                {{ this.gami.description }}
              </p>
            </div>
            <div class="d-flex d-row justify-content-center mt-3 mb-4 mb-md-4">
              <img
                class="img-symbol"
                src="@/assets/img/gamification/symbol-1.png"
                alt=""
              />
            </div>
            <!-- .cont-text-1 -->
          </div>
          <!-- .col -->
        </b-modal>
        <div class="d-flex d-row justify-content-start img-patron1">
          <img src="@/assets/img/gamification/patron1.png" alt="" />
        </div>
      </div>
    </div>
    <ScrollToTop />
  </div>
</template>
<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";

import ScrollToTop from "@/components/general/ScrollToTop";
// import GamificationMainMenu from "@/components/gamification/CompGamificationMainMenu";

export default {
  name: "gamificationCollections",
  components: {
    ScrollToTop,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgGamification: "@/assets/img/gamification/medalla.png",
      imgSymbol: "@/assets/img/gamification/symbol-1.png",
      bar: 50,
      gami: {},
      cantCoins: 0,
      cantCompleted: 0,
      Percent: 0,
      isLoading: false,
      collections: [],
    };
  },
  created() {
    this.isLoading = true;
    this.actGamiMissions()
      .then(() => {
        this.cantCoins = this.missions.length;
        this.missions.filter((mission) => {
          this.collections.push(mission.name);
          if (
            mission.status !== null &&
            mission.status.progress >= mission.status.required
          ) {
            this.cantCompleted++;
          }
        });
        this.pushDataLayer("Collections", this.collections);
        this.Percent = (this.cantCompleted * 100) / this.cantCoins;
      })
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["missions"]),
  },
  methods: {
    ...mapActions("modGamification", ["actGamiMissions"]),
    modalItem(mission, mission_id) {
      this.gami = mission;
      this.pushDataLayer("Collection", mission_id);
      this.$refs["modal-item"].show();
    },
    closeModal() {
      this.$refs["modal-item"].hide();
    },
    pushDataLayer: function (subcategory, collection) {
      window.dataLayer.push({
        event: "TrackEvent-Gamification",
        category: "Gamification",
        SubCategory: subcategory,
        collections: collection,
      });
    },
  },
};
</script>
<style scoped>
.modal-item .modal-content {
  border-radius: 20px;
}
.img-close {
  position: absolute;
  top: -13px;
  right: -13px;
  width: 55px;
  border-radius: 55px;
  box-shadow: 0 3px 6px #00000029;
}
.img-symbol {
  width: 110px;
  height: 25px;
}
.img-gami {
  width: 143px;
  height: 143px;
}
.cont-text .text-desc {
  font: normal normal normal 16px Roboto;
  letter-spacing: 0;
  color: #171717;
}
.cont-text .text-title {
  font: normal normal bold 22px/25px Roboto;
  letter-spacing: 0;
  color: #222222;
  opacity: 1;
}
.content {
  position: relative;
}
.img-patron1 img {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 40px;
  width: 90px;
  height: 90px;
  margin-left: 15px;
}
.img-patron2 img {
  position: absolute;
  z-index: 2;
  width: 90px;
  height: 90px;
}
.cont-gamification {
  height: 100%;
}
.margin-cards {
  position: relative;
  z-index: 3;
}
.cont-gamification img {
  width: 90px;
  height: 90px;
}
.cont-gamification .desc-card {
  font: normal normal bold 14px Roboto;
  letter-spacing: 0;
  color: #171717;
  opacity: 1;
  max-height: 70px;
}
a:hover {
  text-decoration: none;
}
.cont-gamification {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  height: 180px;
}
.cont-bar .progress {
  border-radius: 8px;
}
.cont-bar .progress .progress-bar {
  background-color: #52c49d !important;
}
.cont-bar .text-bar-gamification {
  font: normal normal bold 14px Roboto;
  letter-spacing: 0;
  color: #52c49d;
  opacity: 1;
}
.cont-bar .text-bar-title {
  font: normal normal 44px TolyerBold1;
  letter-spacing: 0;
  color: #222222;
  opacity: 1;
}
.cont-bar {
  position: relative;
  z-index: 3;
  top: 50%;
  border-radius: 10px;
  background: #fff;
  padding: 0.375rem 0.75rem;
  width: 500px;
  height: 100px;
  color: #ced4da;
  box-shadow: 0 2px 4px 2px #0000001a;
}
@media (max-width: 768px) {
  .cont-gamification {
    height: 140px;
  }
  .cont-gamification img {
    width: 60px;
    height: 60px;
  }
  .cont-gamification .desc-card {
    font: normal normal bold 11px Roboto;
    height: 55px;
  }
  .cont-text .text-title {
    font: normal normal bold 18px Roboto;
  }
  .cont-text .text-desc {
    font: normal normal normal 14px Roboto;
  }
  .img-patron1 img {
    z-index: 2;
    width: 100px;
    height: 100px;
    margin-left: 0;
    margin-bottom: 0;
  }
  .img-patron2 img {
    position: absolute;
    z-index: 2;
    width: 90px;
    height: 110px;
  }
  .cont-bar .text-bar-gamification img {
    width: 20px;
  }
  .cont-bar .text-bar-gamification {
    font: normal normal bold 12px Roboto;
    letter-spacing: 0;
    color: #28a745;
    opacity: 1;
  }
  .cont-bar .text-bar-title {
    font: normal normal 26px TolyerBold1;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
  }
  .cont-bar {
    border-radius: 10px;
    background: #fff;
    padding: 0.375rem 0.75rem;
    width: 100%;
    height: 70px;
    color: #ced4da;
    box-shadow: 0 2px 4px 2px #0000001a;
  }
}
</style>
