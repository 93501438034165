<script setup>
import {
  ref,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watch,
  computed,
} from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { storeToRefs } from "pinia";

const { $calendar } = getCurrentInstance().proxy;

const emit = defineEmits(["setDaySelected", "setFromDate", "setToDate"]);

const reservationsStore = useReservationsStore();
const { bookingTempFromDate, bookingTempToDate } =
  storeToRefs(reservationsStore);
const isLoadingAvailableDays = ref(false);
const innerWidth = ref(0);
const cal = ref(new $calendar(null, null, "es-ES"));
const fromDate = ref(null);
const toDate = ref(null);
const today = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const availableDatesFetched = ref(false);
const maxDate = ref(null);
const minimumDate = ref(null);
const minimumDateymd = ref(null);
const available_dates_parsed = ref([]);
const selectedDay = ref(null);
const calendar = ref(null);

const props = defineProps({
  peakSeason: {
    type: Boolean,
    default: false,
  },
  season: {
    type: Number,
  },
  daysBookable: {
    type: Number,
    default: 5,
  },
  anticipationDays: {
    type: Number,
    default: 3,
  },
  dateRangeSelected: {
    type: Object,
    default: null,
  },
  place: {
    type: Object,
    default: null,
  },
  optionIdSelected: {
    type: Number,
    default: null,
  },
  onlyWorkDays: {
    type: Boolean,
    default: false,
  },
  deleteSelectedDay: {
    type: Boolean,
    default: false,
  },
});

const cssVars = computed(() => {
  return {
    "--calendar-width": innerWidth.value + "px",
    "--columns-number": 7,
    "--weekend-visibility": props.onlyWorkDays ? "hidden" : "visible",
  };
});

const calMonthName = computed(() => {
  return cal.value.month.name;
});

const calYear = computed(() => {
  return cal.value.year;
});

const selectDay = (day) => {
  const isSameDay =
    selectedDay.value &&
    selectedDay.value.Date.getTime() === day.Date.getTime();

  selectedDay.value = isSameDay ? null : day;
  emit("setDaySelected", isSameDay ? null : day);
};

const selectDates = (day) => {
  if (fromDate.value === null) {
    fromDate.value = day;
    emit("setFromDate", day);
  } else if (day.Date.getTime() < fromDate.value.Date.getTime()) {
    fromDate.value = day;
    toDate.value = null;
  } else if (toDate.value === null) {
    if (day.Date.getTime() === fromDate.value.Date.getTime()) {
      fromDate.value = null;
    } else {
      toDate.value = day;
      emit("setToDate", day);
    }
  } else {
    fromDate.value = day;
    emit("setFromDate", day);
    toDate.value = null;
  }

  // Setting the max date beginning from the from date selected
  if (fromDate.value !== null) {
    setMaxDate(day);
  }

  // Stores the selected dates in the store
  bookingTempFromDate.value = fromDate.value;
  bookingTempToDate.value = toDate.value;

  // Emitting "notAvailable" event if necessary
  if (fromDate.value === null && getNextDateUnavailable(day)) {
    emit("notAvailable");
  }
};

const getMonthlyAvailableDays = async () => {
  isLoadingAvailableDays.value = true;
  await reservationsStore
    .actGetBookingAvailableDays({
      booking_place_id: props.place.id,
      booking_place_option_id: props.optionIdSelected,
      booking_season_category_id: props.onlyWorkDays ? 2 : props.season,
      start_date:
        cal.value.month.start_date > minimumDateymd.value
          ? cal.value.month.start_date
          : minimumDateymd.value,
      end_date: cal.value.month.next_month_first_date,
    })
    .then(() => {
      parseDates();
    })
    .then(() => {
      isLoadingAvailableDays.value = false;
    });
};

const getRangeAvailableDays = async () => {
  isLoadingAvailableDays.value = true;
  await reservationsStore
    .actGetBookingAvailableDays({
      booking_place_id: props.place.id,
      booking_place_option_id: props.optionIdSelected,
      booking_season_category_id: props.season,
      start_date:
        props.dateRangeSelected.start > minimumDateymd.value
          ? props.dateRangeSelected.start
          : minimumDateymd.value,
      end_date: props.dateRangeSelected.end,
    })
    .then(() => {
      parseDates();
    })
    .then(() => {
      isLoadingAvailableDays.value = false;
    });
};

const betweenDates = (date) => {
  return (
    fromDate.value !== null &&
    toDate.value !== null &&
    date >= fromDate.value.Date &&
    date <= toDate.value.Date
  );
};

const outerMonth = (date) => {
  return date.monthNumber !== cal.value.month.number;
};

const passedDate = (date) => {
  return date.Date.getTime() < today.value.getTime();
};

const getMinimumDate = () => {
  const minDate = new Date(today.value);
  const daysToAdd = props.onlyWorkDays ? 2 : 3;
  minDate.setDate(minDate.getDate() + daysToAdd);

  minimumDate.value = minDate;
  minimumDateymd.value = minimumDate.value.toISOString().split("T")[0];
};

const setMaxDate = (day) => {
  let tempDay = new Date(+day.Date);
  let maxDateTemp = new Date(+fromDate.value.Date);

  if (props.peakSeason) {
    maxDateTemp.setDate(maxDateTemp.getDate() + props.daysBookable - 1);
    maxDate.value = maxDateTemp;
  } else {
    maxDateTemp = cal.value.month.last_date;
    maxDate.value = null;
  }

  while (tempDay <= maxDateTemp) {
    let tempDayDate = tempDay.getTime();
    if (available_dates_parsed.value.includes(tempDayDate)) {
      tempDay.setDate(tempDay.getDate() + 1);
    } else {
      tempDay.setDate(tempDay.getDate() - 1);
      maxDate.value = new Date(tempDay.getTime());
      break;
    }
  }
};

const getNextDateUnavailable = (date) => {
  let nextDate = new Date(date.Date.getTime());
  nextDate.setDate(nextDate.getDate() + 1);
  return (
    !available_dates_parsed.value.includes(nextDate.getTime()) &&
    available_dates_parsed.value.includes(date.Date.getTime())
  );
};

const deleteDates = () => {
  if (props.onlyWorkDays) {
    selectedDay.value = null;
  } else {
    fromDate.value = null;
    toDate.value = null;
  }
  emit("deleteDates");
};

const classesDay = (day) => {
  return {
    workday: day.workday,
    weekend: !day.workday,
    selected_day:
      selectedDay.value !== null &&
      day.Date.getTime() === selectedDay.value.Date.getTime(),
    selected_dates: betweenDates(day.Date),
    available_date: available_dates_parsed.value.includes(day.Date.getTime()),
    not_available_date: !available_dates_parsed.value.includes(
      day.Date.getTime()
    ),
    days_grid_not_selected:
      // outerMonth(day) ||
      (props.onlyWorkDays && !day.workday) ||
      passedDate(day) ||
      (fromDate.value !== null && day.Date < fromDate.value.Date) ||
      (toDate.value !== null && day.Date > toDate.value.Date) ||
      (fromDate.value !== null &&
        maxDate.value !== null &&
        day.Date > maxDate.value) ||
      day.Date.getTime() < minimumDate.value.getTime(),
    selected_from_date:
      fromDate.value !== null &&
      day.Date.getTime() === fromDate.value.Date.getTime(),
    selected_to_date:
      toDate.value !== null &&
      day.Date.getTime() === toDate.value.Date.getTime(),
    selectable_dates:
      fromDate.value !== null &&
      toDate.value === null &&
      maxDate.value !== null &&
      day.Date >= fromDate.value.Date &&
      day.Date <= maxDate.value,
    first_selectable_date:
      fromDate.value !== null &&
      toDate.value === null &&
      day.Date.getTime() === fromDate.value.Date.getTime(),
    last_selectable_date:
      fromDate.value !== null &&
      toDate.value === null &&
      maxDate.value !== null &&
      day.Date.getTime() === maxDate.value.getTime(),
    beyond_max_date:
      fromDate.value !== null &&
      toDate.value === null &&
      maxDate.value !== null &&
      day.Date.getTime() > maxDate.value.getTime(),
    other_month_day: outerMonth(day),
    passed_date: passedDate(day),
    day_before_first_unavailable_date:
      fromDate.value === null &&
      !props.onlyWorkDays &&
      getNextDateUnavailable(day) &&
      !passedDate(day),
  };
};

const classesDayInside = () => {
  return {
    hover_dates: fromDate.value === null && toDate.value === null,
  };
};

const goToPrevMonth = () => {
  cal.value.goToPreviousMonth();

  if (!props.peakSeason) {
    getMonthlyAvailableDays();
  }
};

const goToNextMonth = () => {
  cal.value.goToNextMonth();

  if (!props.peakSeason) {
    getMonthlyAvailableDays();
  }
};

const goToRangeMonth = () => {
  cal.value.goToRangeMonth(props.dateRangeSelected.start);
  getRangeAvailableDays();
  availableDatesFetched.value = true;
};

const parseDates = () => {
  // Create empty array to store the available dates parsed
  available_dates_parsed.value = [];

  // Format the available dates and push them to the array
  available_dates_parsed.value = reservationsStore.bookingAvailableDays.map(
    (date) => {
      date = date.split("-");
      return new Date(date[0], date[1] - 1, date[2]).getTime();
    }
  );
};

watch(
  () => props.optionIdSelected,
  () => {
    getMonthlyAvailableDays();
  }
);

watch(
  () => props.dateRangeSelected,
  () => {
    deleteDates();
  }
);

onBeforeMount(() => {
  getMinimumDate();
  if (props.peakSeason === true) {
    goToRangeMonth();
  } else {
    getMonthlyAvailableDays();
  }
});

onMounted(() => {
  const interval = setInterval(() => {
    if (calendar.value) {
      innerWidth.value = calendar.value.offsetWidth;
      clearInterval(interval);
    }
  }, 0);
});
</script>
<template>
  <div ref="calendar" :style="cssVars" class="calendar-general">
    <div class="calendar">
      <div class="calendar-header tw-mb-5 tw-pr-4">
        <div class="calendar-title">
          <p class="tw-font-roboto tw-uppercase tw-text-base md:tw-text-2xl">
            {{ calMonthName }} {{ calYear }}
          </p>
        </div>
        <div class="calendar-prev_next">
          <div
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click="goToPrevMonth()"
          >
            <i
              class="icon-flecha-izq tw-text-sm md:tw-text-2xl tw-text-red-350"
            ></i>
          </div>
          <div
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click="goToNextMonth()"
          >
            <i
              class="icon-flecha-der tw-text-sm md:tw-text-2xl tw-text-red-350"
            ></i>
          </div>
        </div>
      </div>
      <div class="weekdays_grid">
        <div
          v-for="(day, idx) in cal.weekDaysShort"
          :key="idx"
          :class="{ weekend: day.number === 0 || day.number === 6 }"
          class="weekdays_grid_item"
        >
          <p>{{ day.day }}.</p>
        </div>
      </div>
      <div v-if="isLoadingAvailableDays === false" class="days_grid_container">
        <div class="days_grid">
          <div
            v-for="(day, idx) in cal.monthDaysList"
            :key="idx"
            :class="classesDay(day)"
            class="days_grid_item"
            @click="onlyWorkDays ? selectDay(day) : selectDates(day)"
          >
            <div class="days_grid_item_over"></div>
            <div :class="classesDayInside()" class="days_grid_item_inside">
              <p class="tw-text-xs md:tw-text-lg">
                {{ day && day.date }}
              </p>
            </div>
            <div class="days_grid_item_bg"></div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-p-20"
      >
        <img alt="" src="../../../assets/img/icons/loading.gif" />
      </div>
      <div class="calendar-footer tw-mt-2">
        <p
          class="tw-font-roboto tw-text-xs md:tw-text-base"
          @click="deleteDates"
        >
          Borrar fechas
        </p>
        <div class="tw-flex tw-justify-end tw-gap-4 tw-font-semibold">
          <div class="tw-flex tw-items-center tw-gap-2">
            <i class="icon-circle-select md:tw-text-xl"></i>
            <p class="tw-m-0 tw-font-roboto tw-text-sm md:tw-text-lg">
              Disponible
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2 tw-text-red-350">
            <i class="icon-circle-select md:tw-text-xl"></i>
            <p class="tw-m-0 tw-font-roboto tw-text-sm md:tw-text-lg">
              No disponible
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.calendar-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}

.calendar-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
}

.calendar-title {
  display: flex;
  justify-content: left;
}

.weekdays_grid {
  display: grid;
  grid-template-columns: repeat(var(--columns-number), 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.weekdays_grid_item {
  height: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  align-self: center;
  justify-self: center;
}

.days_grid_container {
  width: 100%;
  height: 100%;
}

.days_grid {
  --gutter: 0.5rem;
  --columns: var(--columns-number);
  --row-size: calc((var(--calendar-width)) / var(--columns));
  display: grid;
  width: 100%;
  max-width: var(--calendar-width);
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-auto-rows: var(--row-size);
  grid-row-gap: var(--gutter);
  grid-column-gap: 0;
}

/* .days_grid
  > .days_grid_item:not(.beyond_max_date):not(.other_month_day):not(.passed_date):not(.not_available_date) {
  pointer-events: auto;
} */

.days_grid_item {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  position: relative;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.days_grid_item_inside {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #000;
}

.days_grid_item_over {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 2;
}

.days_grid_item_bg {
  width: 100%;
  height: 80%;
  background-color: white;
  position: absolute;
  z-index: -1;
}

.days_grid_not_selected {
  opacity: 0.3;
}

/* .days_grid_item.selectable_dates > .days_grid_item_bg {
  background-color: rgb(220, 220, 220);
} */

.days_grid_item_inside p {
  margin: 0;
}

.days_grid:hover
  .selectable_dates:hover:not(.selected_from_date)
  > .days_grid_item_bg {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.beyond_max_date {
  pointer-events: none;
}

.passed_date {
  pointer-events: none;
}

.selectable_dates:hover ~ .selectable_dates > .days_grid_item_bg {
  background-color: white;
}

.selectable_dates:hover > .days_grid_item_inside {
  background: #00bf79;
  color: white;
}

.selected_dates > .days_grid_item_bg {
  background-color: #00bf79;
  opacity: 0.2;
}

.selected_from_date {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  position: relative;
}

.selected_from_date > .days_grid_item_inside {
  border: 1px solid #00bf79;
  background: #00bf79;
  color: white;
}

.selected_from_date > .days_grid_item_bg {
  width: 85%;
  position: absolute;
  right: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.selected_day {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  position: relative;
}

.selected_day > .days_grid_item_inside {
  border: 1px solid #00bf79;
  background: #00bf79;
  color: white;
}

.selected_day > .days_grid_item_bg {
  width: 85%;
  position: absolute;
  right: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.selected_to_date {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
}

.selected_to_date > .days_grid_item_bg {
  width: 85%;
  position: absolute;
  left: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.available_date.days_grid_not_selected {
  pointer-events: none;
}

.not_available_date {
  pointer-events: none;
}

.not_available_date:not(.passed_date):not(.days_grid_not_selected)
  > .days_grid_item_inside {
  border: 1px solid #fa2727;
}

.not_available_date:not(.passed_date):not(.days_grid_not_selected)
  > .days_grid_item_inside
  p {
  color: #fa2727;
  opacity: 0.6;
}

/* .day_before_first_unavailable_date {
  pointer-events: none;
} */

.day_before_first_unavailable_date {
  opacity: 0.5;
}

.day_before_first_unavailable_date > .days_grid_item_inside p {
  text-decoration: line-through;
}

.selected_to_date > .days_grid_item_inside {
  border: 1px solid #00bf79;
  background: #00bf79;
  color: white;
}

/*Somehow hover is working on mobile device, so I left-it just for not mobile*/
@media (min-width: 768px) {
  .days_grid_item:hover > .days_grid_item_inside {
    border: 1px solid #00bf79;
    background: #00bf79;
    color: white;
  }
}

.selected_dates > .days_grid_item_inside {
  border: none;
}

.selected_dates:hover:not(.selected_from_date):not(.selected_to_date)
  > .days_grid_item_inside {
  background: none;
  color: black;
  border: 1px solid #00bf79;
}

.days_grid_item :not(.selected_from_date) > .days_grid_item_inside {
  background-color: transparent;
  color: white;
}

.hover_dates:hover {
  background-color: rgb(220, 220, 220);
}

.other_month_day {
  opacity: 0;
  visibility: hidden;
}

.calendar-prev_next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.calendar-prev_next p {
  cursor: pointer;
  color: #fa2727;
}

.calendar-prev_next p:first-child:hover {
  background: #fa2727;
  color: white;
}

.calendar-prev_next p:last-child:hover {
  background: #fa2727;
  color: white;
}

p,
h2 {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.weekdays_grid p {
  text-transform: capitalize;
}

.calendar-footer p:first-child {
  text-decoration: underline;
}

/*
.weekend {
  visibility: var(--weekend-visibility);
}
*/
</style>
