<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router/composables";
import { formatNumber } from "@/helpers/numbersHelpers";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompFooterEcommerceModal from "@/components/ecommerce/CompFooterEcommerceModal";
import SellConfirmModal from "@/components/ecommerce/SellConfirmModal";

const { $bvModal } = getCurrentInstance().proxy;
const router = useRouter();
const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const uriResources = config.beerOfficeURL;
const dues = ref(1);
const totalPrice = ref(0);
const totalQty = ref(0);
const isLoading = ref(false);

const addQty = (product) => {
  ecommerceStore.addProductToShoppingCart(product);
  totalPriceCalc();
};

const subsQty = (product) => {
  ecommerceStore.subsProductToShoppingCart(product);
  totalPriceCalc();
};

const removeProduct = (product) => {
  ecommerceStore.delProductToShoppingCart(product);
  totalPriceCalc();
};

const totalPriceCalc = () => {
  totalPrice.value = 0;
  totalQty.value = 0;

  for (const product in ecommerceStore.shoppingCart) {
    totalQty.value = totalQty.value + ecommerceStore.shoppingCart[product].qty;
    totalPrice.value +=
      ecommerceStore.shoppingCart[product].price_public *
      ecommerceStore.shoppingCart[product].qty;
  }
};

const shopCartTwo = () => {
  let arrShop = Object.values(ecommerceStore.shoppingCart);
  if (ecommerceStore.gift) {
    arrShop.push(ecommerceStore.gift);
  }

  return arrShop.map((product) => {
    return {
      name: product.name,
      id: product.code_public,
      price: product.price_public,
      brand: product.ecomm_brand.name,
      quantity: product.qty,
    };
  });
};

const datef = () => {
  let current_datetime = new Date();
  let formatted_date =
    current_datetime.getFullYear() +
    "" +
    (current_datetime.getMonth() + 1) +
    "" +
    current_datetime.getDate();
  let ale = Math.floor(Math.random() * 1000);
  return formatted_date + "" + ale;
};

const sellConfirm = () => {
  isLoading.value = true;
  $bvModal.show("modal-confirm-sell");
};

const sendShopping = async () => {
  const order = {
    id: userStore.user.id,
    cc: userStore.user.cc,
    ecomm_deliver_site_id: userStore.user.ecomm_delivery_site_id,
    payment: dues.value,
  };
  if (Object.keys(ecommerceStore.shoppingCart).length > 0) {
    order.buy_products = JSON.stringify(
      Object.values(ecommerceStore.shoppingCart)
    );
  }
  if (ecommerceStore.gift != null) {
    order.gift_product = ecommerceStore.gift.id;
  }
  await ecommerceStore.actPostShoppingProducts(order).then(() => {
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            "Forma de pago": "Descuento de Nomina",
            id: datef,
            revenue: totalPrice.value,
          },
          products: shopCartTwo,
        },
      },
    });
    router.push({ path: "/e-commerce/thanks" });
  });
};

onMounted(async () => {
  await ecommerceStore.actGetProducts();
  totalPriceCalc();
  window.dataLayer.push({
    event: "'trackEvent",
    target: "Sub-secciones",
    action: "ecommerce-carrito",
    "target-properties": "clic",
    value: 5000,
  });
});
</script>
<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0">
    <div class="container">
      <div class="row px-0 px-md-3">
        <SecondaryNavbar title="CARRITO DE COMPRA" />
        <SellConfirmModal @close="isLoading = false" @shop="sendShopping" />
      </div>
      <div
        class="row"
        v-if="
          ecommerceStore.shoppingCartArray.length === 0 &&
          ecommerceStore.gift == null
        "
      >
        <div class="col-12">
          <div class="alert alert-warning textPlane mt-3">
            No hay productos en el carrito.
          </div>
        </div>
      </div>
      <div v-else class="row mt-3">
        <div class="col-12 col-lg-8">
          <div
            v-for="product in ecommerceStore.shoppingCartArray"
            :key="product.id"
          >
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="d-flex align-items-center wi-360">
                <img
                  class="wi-60 he-60 tw-rounded-lg mr-3 tw-shadow-sm"
                  :src="uriResources + '/' + product.img"
                  :alt="product.name"
                />
                <p
                  class="mb-0 text-uppercase tw-text-gray-600 tw-text-xs fs-md-14"
                >
                  {{ product.name }}
                  <span
                    class="tw-text-xs tw-text-red-400 font-weight-bold d-block d-md-none mt-2"
                    >${{ formatNumber(product.price_public) }}</span
                  >
                </p>
              </div>
              <p
                class="d-none d-md-block mb-0 tw-text-gray-600 font-weight-bold tw-text-lg mx-3"
              >
                ${{ formatNumber(product.price_public) }}
              </p>
              <div class="d-flex ml-3 ml-md-0">
                <span
                  @click="subsQty(product)"
                  class="tw-text-lg font-weight-bold tw-text-gray-400"
                  role="button"
                >
                  -
                </span>
                <div>
                  <div
                    class="wi-30 he-30 p-2 tw-rounded-lg bg-white tw-shadow-sm mx-2 d-flex align-items-center justify-content-center"
                  >
                    <p class="mb-0 tw-text-gray-600 tw-text-xs">
                      {{ product.qty }}
                    </p>
                  </div>
                </div>
                <span
                  @click="addQty(product)"
                  class="tw-text-lg font-weight-bold tw-text-gray-400"
                  role="button"
                >
                  +
                </span>
              </div>
              <div class="d-none d-md-block">
                <div class="d-flex">
                  <p
                    class="mb-0 tw-text-gray-600 font-weight-bold tw-text-lg mx-3"
                  >
                    ${{ formatNumber(product.price_public * product.qty) }}
                  </p>
                  <i
                    @click="removeProduct(product)"
                    class="icon-cerrar-simple tw-text-red-400 font-weight-bold tw-text-xl"
                    role="button"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div v-if="ecommerceStore.gift != null">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="d-flex align-items-center">
                <img
                  class="wi-60 he-60 tw-rounded-lg mr-3 tw-shadow-sm tw-object-cover"
                  :src="uriResources + '/' + ecommerceStore.gift.img"
                  :alt="ecommerceStore.gift.name"
                />
                <p
                  class="mb-0 text-uppercase tw-text-gray-600 tw-text-xs fs-md-14"
                >
                  {{ ecommerceStore.gift.name }}
                </p>
              </div>

              <div class="d-none d-md-block">
                <div class="d-flex">
                  <p
                    class="mb-0 tw-text-gray-600 font-weight-bold tw-text-lg mx-3"
                  >
                    Obsequio*
                  </p>
                  <i
                    @click="removeProduct(ecommerceStore.gift)"
                    class="icon-cerrar-simple tw-text-red-400 font-weight-bold tw-text-xl"
                    role="button"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <p class="tw-text-xs tw-text-gray-300 mt-3">
            * Recuerda que solo puedes pedir tu obsequio antes del
            {{ ecommerceStore.products[1]?.max_day }} de cada mes.
          </p>
        </div>
        <div class="col-12 col-lg-4">
          <div class="bg-white tw-shadow-md p-3 tw-rounded-lg">
            <div v-if="totalQty < 5">
              <div
                class="d-flex align-items-center justify-content-between px-0 px-lg-5"
              >
                <p class="mb-0 tw-text-black tw-text-sm">Número de cuotas</p>
                <select
                  v-model="dues"
                  class="wi-60 form-control tw-bg-gray-200 tw-text-black tw-border tw-border-solid tw-border-gray-200"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
              <div class="text-center mt-4">
                <p class="mb-0 tw-text-red-400 font-weight-bold tw-text-lg">
                  Total: ${{ formatNumber(totalPrice) }}
                </p>
              </div>
              <div class="px-3 px-md-5 mt-4">
                <div class="text-center" v-if="isLoading">
                  <img
                    src="@/assets/img/icons/loading.gif"
                    class="tw-w-10"
                    alt="loading"
                  />
                </div>
                <div
                  v-else
                  class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 text-center"
                  @click="sellConfirm()"
                  role="button"
                >
                  Comprar
                </div>
              </div>
            </div>
            <div v-else>
              <div class="alert alert-danger mt-2 mt-md-3">
                Recuerda que mensualmente solamente podrás comprar
                <b>hasta 4 productos de tu preferencia</b> y reclamar una vez tu
                producto de obsequio.
              </div>
            </div>
            <div class="mt-5 px-3">
              <p class="tw-text-xs tw-text-gray-300 text-center line-height-14">
                Recuerda que mensualmente solamente podrás comprar hasta
                <span class="font-weight-bold tw-text-gray-400"
                  >4 productos</span
                >
                de tu preferencia y reclamar una vez tu producto de obsequio.
              </p>
            </div>
          </div>
          <CompFooterEcommerceModal />
        </div>
      </div>
    </div>
  </div>
</template>
