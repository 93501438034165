import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCurrencyFilter from "vue-currency-filter";
import moment from "moment";
import VueMoment from "vue-moment";
import Vuelidate from "vuelidate";
import PortalVue from "portal-vue";
import ScreenSizes from "./plugins/screenSizes";
import { cloneArray } from "@/plugins/cloneArrays";
import Calendar from "./components/calendar/datepicker/datepicker";
require("moment/locale/es");

//Pinia
import { createPinia, PiniaVuePlugin } from "pinia";
//Load Pinia
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

//Tailwind
import "./index.css";
//bootstrap
//import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
//general css
import "@/assets/icomoon/style.css";
import "@/assets/css/general.css";
import "@/assets/css/grid.css";
import "@/assets/css/text.css";
import "@/assets/css/width-height.css";
import "@/assets/css/z-index.css";
import "@/assets/css/animations.css";
//icons
import "@/assets/css/icons-aux.css";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

//load axios
Vue.use(VueAxios, axios);

// Install BootstrapVue
//Vue.use(BootstrapVue);
import {
  ModalPlugin,
  CarouselPlugin,
  CollapsePlugin,
  TabsPlugin,
  ProgressPlugin,
  ToastPlugin,
  DropdownPlugin,
  TablePlugin,
  CardPlugin,
  FormGroupPlugin,
  FormRadioPlugin,
  ButtonPlugin,
} from "bootstrap-vue";
Vue.use(ModalPlugin);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(TabsPlugin);
Vue.use(ProgressPlugin);
Vue.use(ToastPlugin);
Vue.use(DropdownPlugin);
Vue.use(TablePlugin);
Vue.use(CardPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormRadioPlugin);
Vue.use(ButtonPlugin);

// Currency Pipe
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});

// Donut Chart
Vue.use(Donut);

//Moment
Vue.use(VueMoment, { moment });

//vuelidate
Vue.use(Vuelidate);

//portal-vue
Vue.use(PortalVue);

//ScreenSizes Calculator
Vue.use(ScreenSizes);

// Create calendar prototype for use in components
Vue.prototype.$calendar = Calendar;

// Create cloneArray prototype for use in components
Vue.prototype.$cloneArray = cloneArray;

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENABLE_DEVTOOLS === "true") {
  Vue.config.devtools = true;
}

new Vue({
  router,
  store,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
