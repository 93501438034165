<template>
  <div class="bg-white px-3 py-3 tw-rounded-md" :class="cardClasses">
    <p class="mb-0  tw-text-xs  fs-md-16 text-center" :class="textClasses">
      {{ value == null ? "- " : value }}
      {{ end_value }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PsiCardData",
  props: {
    color: {
      default: null,
    },
    value: {
      default: "-",
    },
    end_value: {
      type: String,
      default: "",
    },
    hide_mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClasses() {
      let hideClass = this.hide_mobile ? "d-none d-md-block" : "";
      let bgColor = "";
      if (this.color === true) {
        bgColor += "tw-border-l-10 tw-border-solid tw-border-green-400  ";
      } else if (this.color == null) {
        bgColor += "tw-border-l-10 tw-border-solid tw-border-gray-300  ";
      } else if (this.color === "gray-400") {
        bgColor += "tw-border-l-10 tw-border-solid tw-border-gray-400  ";
      } else {
        bgColor += "tw-border-l-10 tw-border-solid tw-border-red-400  ";
      }

      return bgColor + hideClass;
    },
    textClasses() {
      let textColor = "";
      if (this.color === true) {
        textColor += "tw-text-green-400 ";
      } else if (this.color == null) {
        textColor += "tw-text-gray-300 ";
      } else if (this.color === "gray-400") {
        textColor += "tw-text-gray-400 ";
      } else {
        textColor += "tw-text-red-400 ";
      }

      return textColor;
    },
  },
};
</script>
