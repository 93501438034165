<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="tw-text-gray-600 tw-font-tolyerBold fs-40">TUS RESULTADOS</p>
      </div>
    </div>
    <div class=" tw-grid tw-grid-cols-12 tw-gap-4">
      <div
        class="tw-h-full tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3"
        v-for="result in trivia"
        :key="'result-trivia-' + result.id"
      >
        <div class="tw-shadow-md px-3 py-4 tw-rounded-lg tw-h-full">
          <div class="text-center">
            <img
              :src="uriResources + '/' + result.image"
              class="wi-md-140 he-md-140 tw-rounded-full"
              alt=""
            />
          </div>
          <p class="text-center tw-text-gray-600 tw-font-tolyerBold fs-30 mt-4">
            {{ result.title }}
          </p>
          <p class="tw-text-gray-300 tw-text-sm text-center mb-0">
            {{ result.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";

export default {
  name: "ResultsTrivia2",
  props: {
    trivia: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
};
</script>
