<script setup>
import { ref, onBeforeMount, computed } from "vue";
import config from "@/services/config";
import { useStore } from "@/store";
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const store = useStore();
const isLoading = ref(false);
const uriResources = config.beerOfficeURL;
const img = ref(null);

const getCurrentDay = computed(() => {
  return new Date().getDate();
});

const props = defineProps({
  max_day_gift: {
    type: Number,
    default: 10,
  },
});

const linkTo = (link) => {
  if (link !== null) {
    pushLink(link);
  }
};

const goToMonthProduct = () => {
  if (getCurrentDay.value <= props.max_day_gift) {
    pushDataLayer({
      event_category: "Tienda",
      event_action: "Producto del Mes",
      event_label: "Seleccionar producto",
      component_name: "Click",
    });
    linkTo("/e-commerce/monthProduct");
  }
};

onBeforeMount(() => {
  isLoading.value = true;
  store
    .dispatch("modGeneral/actGetBannerById", 6)
    .then((response) => {
      img.value = response.data.banner.slides[0].img;
    })
    .finally(() => {
      isLoading.value = false;
    });
});
</script>
<template>
  <div class="tw-grid tw-gap-2 md:tw-gap-8 tw-grid-cols-12" v-if="!isLoading">
    <div
      class="tw-bg-no-repeat tw-bg-center tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-8 tw-aspect-w-5 tw-aspect-h-4 md:tw-aspect-w-16 md:tw-aspect-h-7"
      :style="{
        'background-image': `url(${require('@/assets/img/icons/loading.gif')})`,
      }"
    >
      <img
        :src="uriResources + '/' + img"
        loading="lazy"
        alt="image"
        class="tw-rounded-2xl tw-w-full tw-h-full tw-object-cover tw-pointer-events-none"
      />
    </div>
    <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4 tw-h-full">
      <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-3">
        <div class="tw-flex tw-flex-col tw-gap-3 tw-justify-evenly tw-h-full">
          <p
            class="tw-mb-2 md:tw-mb-5 tw-font-robotoBold tw-text-base md:tw-text-4xl tw-line-clamp-2"
          >
            Producto del Mes
          </p>
          <p
            class="tw-mb-0 tw-text-xs md:tw-text-base tw-text-black tw-line-clamp-3"
          >
            No te pierdas la oportunidad de pedir tu producto obsequio, recuerda
            que esto solo lo puedes hacer los 10 primeros dias de cada mes.
          </p>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-center tw-py-3"
          :class="
            getCurrentDay <= max_day_gift
              ? 'tw-btn-primary-red'
              : 'tw-btn-primary-disabled'
          "
          @click="getCurrentDay <= max_day_gift ? goToMonthProduct() : null"
        >
          Selecciona tu producto del mes
        </div>
      </div>
    </div>
  </div>
</template>
