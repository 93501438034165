<template>
  <div class="cont-social-network mt-3 mb-3 text-center text-md-left">
    <div class="row m-auto">
      <div
        @click="
          pushDataLayer(
            'facebook',
            category,
            subcategory,
            brand,
            variant,
            contribute,
            farming,
            videoname
          )
        "
      >
        <facebook :url="url" scale="2" class="mx-1"></facebook>
      </div>
      <div
        @click="
          pushDataLayer(
            'twitter',
            category,
            subcategory,
            brand,
            variant,
            contribute,
            farming,
            videoname
          )
        "
      >
        <twitter :url="url" :title="title" scale="2" class="mx-1"></twitter>
      </div>
      <div
        @click="
          pushDataLayer(
            'whatsapp',
            category,
            subcategory,
            brand,
            variant,
            contribute,
            farming,
            videoname
          )
        "
      >
        <whats-app :url="url" :title="title" scale="2" class="mx-1"></whats-app>
      </div>
      <!--
    <telegram :url="url" scale="2" class="mx-1"></telegram>
    <reddit :url="url" :title="title" scale="2" class="mx-1"></reddit>
    <email :url="url" :subject="title" scale="2" class="mx-1"></email>
    --></div>
  </div>
</template>

<script>
import {
  Facebook,
  Twitter,
  WhatsApp,
} from "vue-socialmedia-share";

export default {
  name: "CompShareSocialNetworks",
  components: {
    Facebook,
    Twitter,
    WhatsApp,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    category: {
      type: String,
    },
    subcategory: {
      type: String,
    },
    brand: {
      type: String,
    },
    variant: {
      type: String,
    },
    contribute: {
      type: String,
    },
    farming: {
      type: String,
    },
    videoname: {
      type: String,
    },
  },
  methods: {
    pushDataLayer(
      net,
      category_,
      subcategory_,
      brand_,
      variant_,
      contribute_,
      farming_,
      videoname_
    ) {
      window.dataLayer.push({
        event: "TrackEvent-SocialNetwork",
        category: category_,
        SubCategory: subcategory_,
        Action: "Compartir",
        Brand: brand_,
        Variant: variant_,
        Contribute: contribute_,
        Farming: farming_,
        videoname: videoname_,
        SocialNetwork: net,
      });
    },
  },
};
</script>

<style scoped>
</style>