<script setup>
import config from "@/services/config";
import { useCoreStore } from "@/stores/coreStore";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router/composables";

const router = useRouter();
const $route = useRoute();
const uriResources = config.beerOfficeURL;
const coreStore = useCoreStore();
const { contextualHelpers } = storeToRefs(coreStore);
const emit = defineEmits(["datalayer", "close"]);

const props = defineProps({
  route: {
    type: String,
    default: "",
  },
});

const goToHelper = (helper, idx) => {
  emit("datalayer", helper.name);
  router.push({
    path: "/contextual-helpers/routes",
    query: {
      route: props.route.replace("/", ""),
      helper: idx,
      originRoute: $route.path,
    },
  });
};
</script>
<template>
  <div class="tw-flex tw-flex-col tw-gap-3 tw-overflow-hidden">
    <div class="tw-flex tw-flex-col tw-gap-3 tw-px-3">
      <div class="tw-flex tw-items-center tw-justify-between">
        <p
          class="tw-m-0 tw-font-robotoBold tw-font-lg tw-text-red-350 tw-whitespace-nowrap"
        >
          ¿Cómo usar Embajadores?
        </p>
        <div
          class="tw-flex tw-items-center tw-cursor-pointer"
          @click="emit('close')"
        >
          <i class="icon-cerrar-simple tw-text-xl tw-text-red-350"></i>
        </div>
      </div>
      <p class="tw-m-0 tw-font-roboto tw-text-sm">
        Estas ayudas contextuales te ayudarán a entender más fácil como funciona
        nuestra plataforma.
      </p>
      <div
        class="tw-flex tw-gap-3 tw-border tw-border-gray-250 tw-rounded-2xl tw-p-3"
      >
        <i class="icon-cebada tw-text-xl"></i>
        <i class="icon-vertical-line tw-text-xl tw-text-gray-300"></i>
        <div
          class="tw-flex tw-justify-between tw-items-center tw-w-full tw-cursor-pointer"
          @click="
            router.push({
              path: '/contextual-helpers/routes',
              query: { route: route.replace('/', ''), helper: 'all' },
            })
          "
        >
          <p class="tw-m-0 tw-font-roboto tw-text-sm">
            Realizar todo el recorrido
          </p>
          <i
            class="icon-flecha-der tw-text-sm tw-text-red-350 tw-font-bold"
          ></i>
        </div>
      </div>
    </div>
    <div
      ref="helpersGrid"
      class="tw-grid tw-grid-cols-3 tw-gap-3 tw-px-3 tw-overflow-y-auto"
    >
      <div
        v-for="(helper, idx) in contextualHelpers"
        :key="idx"
        class="tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center tw-border tw-border-gray-250 tw-rounded-2xl tw-p-2 tw-text-center tw-cursor-pointer"
        @click="goToHelper(helper, idx)"
      >
        <img :src="uriResources + '/' + helper.icon" alt="" />
        <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-gray-450">
          {{ helper.name }}
        </p>
      </div>
    </div>
  </div>
</template>
