import ApiBeerOffice from "@/services/apiBeerOffice";
const roles = {
  BDR: 55,
  BDR_HELPER: 26,
  SALES_SUPERVISOR: 25,
  TRADE_MARKETING_SUPERVISOR: 116,
  NEGOTIATION_SUPERVISOR: 206,
  EXECUTION_SUPERVISOR: 13,
  TRADE_MARKETING_ADMINISTRATIVE: 31,
  TRADE_MARKETING_REGIONAL_MANAGER: 339,
  TELE_SALES_LEAD_1: 185,
  COMMERCIAL_MANAGER: 103,
  CODIGOMODERN_CHANNEL_MANAGER: 386,
  NABS_TRADE_MARKETING_MANAGER_ANDES: 298,
  // NABS_TRADE_MARKETING_MANAGER_SUR: 329,
  NABS_TRADE_MARKETING_MANAGER_SUR: 631,
  NABS_TRADE_MARKETING_MANAGER_NORTE: 324,
  SALES_INTELLIGENCE_MANAGER: 297,
  SALES_MANAGER: 337,
  HIGH_END_LEAD_1: 92,
  SALES_LEAD_1: 197,
  SALES_LEAD_2: 118,
  REGIONAL_DIRECTOR: 50,
};

export default {
  namespaced: true,
  state: {
    variableGeneralData: null,
    variableGeneralVariableData: null,
    yearData: null,
    monthData: null,
    qtData: null,
    variableDetails: null,
    permissions: {
      REPORT_REMUNERATION: [
        roles.SALES_SUPERVISOR,
        roles.TRADE_MARKETING_SUPERVISOR,
        roles.NEGOTIATION_SUPERVISOR,
        roles.EXECUTION_SUPERVISOR,
        roles.TRADE_MARKETING_ADMINISTRATIVE,
        roles.HIGH_END_LEAD_1,
        roles.SALES_LEAD_1,
        roles.SALES_LEAD_2,
      ],
    },
    userAccess: [
      {
        accessType: 1,
        positionId: [25, 116, 206, 13, 31, 92, 197, 118],
        path: "/showMeTheMoney/remuneracion-variable",
      },
      {
        accessType: 2,
        positionId: [55, 26],
        path: "/showMeTheMoney/remuneracion-variable",
      },
      // SOLO PUEDE ACCEDER A RANKING
      {
        accessType: 3,
        positionId: [339, 185, 103, 489, 298, 50, 329, 631, 390, 324, 297, 337],
        path: "/showMeTheMoney/remuneracion-rankings",
      },
    ],
  },
  getters: {
    getVariableData: (state) => {
      return state.variableData;
    },
    getYearData: (state) => {
      return state.yearData;
    },
    getMonthData: (state) => {
      return state.monthData;
    },
    getQtData: (state) => {
      return state.qtData;
    },
    getVariableDetails: (state) => {
      return state.variableDetails;
    },
    getUserHasPermission: (state) => (permissionName, positionId) => {
      return state.permissions[permissionName].includes(positionId);
    },
  },
  mutations: {
    setVariableData: (state, variableData) => {
      state.variableGeneralData = variableData;
      localStorage.setItem("variableGeneralData", JSON.stringify(variableData));
    },
    setGeneralVariableData: (state, variableData) => {
      state.variableGeneralVariableData = variableData;
      localStorage.setItem(
        "variableGeneralVariableData",
        JSON.stringify(variableData)
      );
    },
    setYearData: (state, yearData) => {
      state.yearData = yearData;
      localStorage.setItem("yearData", JSON.stringify(yearData));
    },
    setMonthData: (state, monthData) => {
      state.monthData = monthData;
      localStorage.setItem("monthData", JSON.stringify(monthData));
    },
    setQtData: (state, qtData) => {
      state.qtData = qtData;
      localStorage.setItem("qtData", JSON.stringify(qtData));
    },
    setVariableDetails: (state, variableDetails) => {
      state.variableDetails = variableDetails;
    },
  },
  actions: {
    actVariableData: async ({ commit }) => {
      let response = await ApiBeerOffice.getGeneralRankingData();
      if (response.error) {
        return response.error;
      }
      commit("setVariableData", response);
      return response.data;
    },

    actGeneralVariableRankingData: async ({ commit }) => {
      let response = await ApiBeerOffice.getGeneralVariableRankingData();
      if (response.error) {
        return response.error;
      }
      commit("setGeneralVariableData", response);
      return response.data;
    },
    actDirectorVariableRankingData: async ({ commit }, dateType) => {
      let response = await ApiBeerOffice.getDirectorRankingData(dateType);
      if (response.error) {
        return response.error;
      } else {
        if (dateType === "month_director") {
          commit("setMonthData", response);
          return true;
        } else if (dateType === "yr") {
          commit("setYearData", response);
          return true;
        } else {
          commit("setQtData", response);
          return true;
        }
      }
    },
    actGetVariableMoreDetails: async ({ commit }) => {
      let response = await ApiBeerOffice.getVariableMoreDetails();
      if (response.error) {
        return response.error;
      }
      commit("setVariableDetails", response.data);
      return response.data;
    },
  },
};
