<script setup>
import { onMounted, ref } from "vue";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";
import CompGeneralTooltipSlot from "@/components/general/CompGeneralTooltipSlot.vue";
import { capitalizeParagraphFirstLetter } from "@/helpers/textHelpers";
import ButtonOutlineRed from "@/components/buttons/CompButtonOutlineRed.vue";
import CompFormTextInputWithClear from "@/components/form/CompFormTextInputWithClear.vue";

const props = defineProps({
  name: String,
  description: String,
  collection: Array,
  data: Object,
  collectionName: String,
  singleChoice: Boolean,
  allowAdditionalItems: Boolean,
});

const emit = defineEmits(["update"]);

const additionalItems = ref([]);

const activeTooltip = ref(null);

const openTooltip = (id) => {
  activeTooltip.value = id;
};

const addAdditionalItem = () => {
  if (
    !additionalItems.value.length ||
    additionalItems.value.at(-1)?.name !== ""
  ) {
    additionalItems.value.push({});
  }
};

const updateAdditionalItemName = (value, idx) => {
  const rawValue = value.trim();
  const rawIndex = `other:${rawValue.toLowerCase()}`;
  const capitalizedValue = capitalizeParagraphFirstLetter(value.trim());

  const oldItem = additionalItems.value[idx].id;

  // find if item is already on additionalItems
  const itemIndex = additionalItems.value.findIndex(
    (item, index) => String(item.id).toLowerCase() === rawIndex && index !== idx
  );

  if (itemIndex > -1) {
    additionalItems.value.splice(idx, 1);
    return;
  }

  // find if item is on props.collection and select it
  const itemIndexOnCollection = props.collection.findIndex(
    (item) => item.name.toLowerCase() === rawValue
  );

  if (itemIndexOnCollection > -1) {
    toggleSelection(
      props.collectionName,
      props.collection[itemIndexOnCollection].id
    );
    additionalItems.value.splice(idx, 1);
    return;
  }

  additionalItems.value[idx].name = capitalizedValue;
  additionalItems.value[idx].id = rawIndex;

  if (includesItem(oldItem)) {
    const updatedCollection = props.data[props.collectionName].map((item) =>
      item === oldItem ? rawIndex : item
    );
    emit("update", { [props.collectionName]: updatedCollection });
  }

  if (!includesItem(rawIndex)) {
    emit("update", {
      [props.collectionName]: [...props.data[props.collectionName], rawIndex],
    });
  }
};

const deleteAdditionalItem = (item, idx) => {
  const item_ = props.data[props.collectionName].findIndex(
    (item_) => item_ === item.id
  );

  if (item_ === -1) {
    return;
  }

  const updatedCollection = [
    ...props.data[props.collectionName].filter((item_) => item_ !== item.id),
  ];

  additionalItems.value.splice(idx, 1); // Explicitly remove the item from the array

  emit("update", { [props.collectionName]: updatedCollection });
};

const includesItem = (itemId) => {
  return props.data[props.collectionName]?.some((item) => item === itemId);
};

function toggleSelection(collectionName, id) {
  if (!props.data[collectionName]) {
    emit("update", { [collectionName]: [] });
  }

  if (id === "none") {
    const noneItem = props.collection.find((item) => item.id === "none");
    if (noneItem) {
      additionalItems.value = [];
      emit("update", { [collectionName]: [noneItem.id] });
    }
  } else if (props.singleChoice && id !== "none") {
    // Si singleChoice es true, limpiar la selección actual antes de añadir el nuevo elemento

    emit("update", { [collectionName]: id });
  } else {
    const noneIndex = props.data[collectionName].findIndex(
      (item) => item === "none"
    );
    if (noneIndex > -1) {
      const updatedCollection = props.data[collectionName].filter(
        (item) => item !== "none"
      );

      emit("update", {
        [collectionName]: updatedCollection.map((item) => item.id),
      });
    }

    const itemIndex = props.data[collectionName].findIndex(
      (item) => item === id
    );
    if (itemIndex > -1) {
      const updatedCollection = [
        ...props.data[collectionName].filter((item) => item !== id),
      ];
      emit("update", {
        [collectionName]: updatedCollection.map((item) => item),
      });
    } else {
      const itemToAdd = props.collection.find((item) => item.id === id);
      if (itemToAdd) {
        emit("update", {
          [collectionName]: [...props.data[collectionName], itemToAdd.id],
        });
      }
    }
  }
}

onMounted(() => {
  if (props.allowAdditionalItems) {
    additionalItems.value = props.data[props.collectionName]
      .filter((item) => item.startsWith("other:") && item !== "")
      .map((item) => ({
        id: item,
        name: capitalizeParagraphFirstLetter(item.split(":")[1]),
      }));
  }
});
</script>

<template>
  <section
    :id="name"
    class="tw-flex tw-flex-col tw-gap-2 tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-2xl"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        {{ props.description }}
      </p>
    </div>
    <div class="tw-grid tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-2">
      <div
        v-for="(item, idx) in props.collection"
        :key="idx"
        class="tw-self-center"
      >
        <comp-general-tooltip-slot
          :tooltip-text="item.description"
          :tooltip-active="activeTooltip === item.id"
        >
          <comp-button-custom-color
            class="tw-w-full"
            rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
            :text="item.name"
            active-color="green-500"
            text-color="gray-700"
            text-active-color="white"
            @click.native="toggleSelection(props.collectionName, item.id)"
            :icon="!!item.description"
            icon-name="icon-mas-informacion"
            :active="
              singleChoice
                ? props.data[collectionName] === item.id
                : includesItem(item.id)
            "
            @iconClick="openTooltip(item.id)"
            @mouseleave.native="activeTooltip = null"
          />
        </comp-general-tooltip-slot>
      </div>
      <button-outline-red
        v-if="allowAdditionalItems"
        rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
        title="Agregar"
        @click.native="addAdditionalItem"
      />
      <div
        class="tw-flex tw-w-full"
        v-for="(item, idx_) in additionalItems"
        :key="`${idx_}_additionalItem`"
      >
        <comp-form-text-input-with-clear
          :model-value="item.name"
          @update="(value) => updateAdditionalItemName(value, idx_)"
          @deleteInput="deleteAdditionalItem(item, idx_)"
        />
      </div>
    </div>
  </section>
</template>

<style scoped></style>
