<script setup>
import { onBeforeMount, ref, computed, getCurrentInstance } from "vue";
import { useRoute } from "vue-router/composables";
import { useBenefitsAgreementsStore } from "@/stores/benefitsAgreementsStore";

import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCard from "@/components/cards/CompGeneralTopCard.vue";
import CompContents from "@/components/general/CompContents.vue";
import CompSocialNetworkInteractionsBig from "@/components/social-network/CompSocialNetworkInteractionsBig.vue";
import CompGeneralGridCard from "@/components/general/CompGeneralGridCard.vue";

const route = useRoute();
const benefitAgreementsStore = useBenefitsAgreementsStore();
const isLoading = ref(false);
const isHovering = ref(false);
const itemId = ref(route.params.id);
const { $cloneArray: cloneArray } = getCurrentInstance().proxy;
const { $isMobile: mobile } = getCurrentInstance().proxy;

async function getAgreementDetail() {
  await benefitAgreementsStore.actGetAgreementDetail(itemId.value);
}

async function updateRelatedAgreements() {
  await benefitAgreementsStore.actGetAgreements(
    benefitAgreementsStore.agreementDetail?.ben_emp_agreement_category_id
  );
}

const relatedAgreements = computed(() => {
  if (benefitAgreementsStore.agreements.length === 0) {
    benefitAgreementsStore.actGetBenefits(
      benefitAgreementsStore.agreementDetail?.ben_emp_agreement_category_id
    );
  }
  let relatedAgreements = cloneArray(benefitAgreementsStore.agreements);
  return relatedAgreements.sort(() => Math.random() - 0.5).slice(0, 3);
});

onBeforeMount(async () => {
  isLoading.value = true;
  await getAgreementDetail();
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Convenios" v-if="!isLoading" />
    <title-bar title="Convenios" />
    <div class="tw-container-general" v-if="!isLoading">
      <div class="tw-my-6">
        <comp-general-top-card
          :image="benefitAgreementsStore.agreementDetail.main_image"
          :title="benefitAgreementsStore.agreementDetail.name"
          :description="benefitAgreementsStore.agreementDetail.description"
        />
        <hr class="tw-w-full tw-bg-gray-250 tw-my-10" />
      </div>
      <div class="tw-my-6">
        <CompContents
          v-if="
            benefitAgreementsStore.agreementDetail?.content !== undefined &&
            benefitAgreementsStore.agreementDetail?.content != null &&
            benefitAgreementsStore.agreementDetail?.content.length > 0
          "
          :content="benefitAgreementsStore.agreementDetail?.content"
        />
      </div>
      <div class="tw-my-6">
        <comp-social-network-interactions-big
          :id="benefitAgreementsStore.agreementDetail.id"
          :title="benefitAgreementsStore.agreementDetail.name"
          category="AGREEMENTS"
          :user_reaction="benefitAgreementsStore.agreementDetail.user_reaction"
          :user_score_review="
            benefitAgreementsStore.agreementDetail.user_score_review
          "
          :amount_comments="
            benefitAgreementsStore.agreementDetail.amount_comments
          "
          :amount_reactions="
            benefitAgreementsStore.agreementDetail.amount_reactions
          "
          :image="benefitAgreementsStore.agreementDetail.main_image"
          :description="benefitAgreementsStore.agreementDetail.description"
          :item="benefitAgreementsStore.agreementDetail"
          :hover="isHovering"
          category-for-datalayer="Convenios"
          @updatedComment="getAgreementDetail"
          @updatedReaction="getAgreementDetail"
        />
      </div>
      <div class="tw-my-5" id="Related">
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-4xl tw-font-robotoBold"
        >
          Otros convenio que tenemos para ti
        </p>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8 tw-mb-4 md:tw-mb-8"
      >
        <div
          v-for="(item, idx) in relatedAgreements"
          :key="idx"
          class="lg:tw-flex lg:tw-flex-col"
        >
          <comp-general-grid-card
            :id="item.id"
            :item="item"
            :category="item?.ben_emp_agreement_category?.category"
            category-for-social="AGREEMENTS"
            :title="item?.name"
            :description="item?.description"
            :link="`/agreements/${item?.id}`"
            :image="mobile() ? item?.banner_image : item?.main_image"
            category-for-datalayer="Convenios"
            action-for-datalayer="Otros convenios que tenemos para ti"
            @updatedComment="updateRelatedAgreements"
            @updatedReaction="updateRelatedAgreements"
          />
          <hr class="md:tw-hidden" />
        </div>
      </div>
    </div>
  </div>
</template>
