<template>
  <div>
    <div class="ml-0 md:tw-ml-20 ml-xl-0">
      <!-- <div class="container px-0 px-md-3 mt-md-4">
        <BreadCrumb :breadcrumb="breadcrumb" />
      </div> -->
      <MobileBar :title="competition.name" />
      <!-- Título Desktop y leyenda -->
      <div
        class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
      >
        <div
          class="col-12 col-lg-8 col-md-6 px-0 order-md-1 order-last mx-auto"
        >
          <div class="d-none d-md-flex">
            <p class="tw-font-tolyerBold fs-md-40 text-uppercase">
              {{ competition.name }}
            </p>
          </div>
        </div>
      </div>

      <!-- Contenido -->
      <div class="container p-0">
        <div class="row mt-0 mt-md-4">
          <div class="col-12 col-md-8 mx-auto" style="height: 300px">
            <img
              class
              :src="uriResources + '/' + competition.comp_question[idQ].image"
              alt="Imagen pregunta"
              width="100%"
              height="100%"
              style="object-fit: cover"
            />
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
        <div
          class="bg-white tw-rounded-lg-20 z-index-10 position-relative pt-1 mt-n3 mt-md-0 md:tw-rounded-none pt-md-2"
        >
          <div class="row mt-4 px-3 px-md-0">
            <div class="col-12 col-lg-8 mx-auto">
              <div class="cont-question mb-4 mb-md-2">
                <div class="cont-text-question">
                  <h2
                    class="mb-4 tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"
                  >
                    {{ competition.comp_question[idQ].question }}
                  </h2>
                </div>
                <!-- .cont-text-question -->
              </div>
              <!-- .cont-question -->
              <div class="cont-answers mt-3 mt-lg-5">
                <div
                  class="answer tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-text-gray-600 p-3 d-flex mb-3"
                  v-for="answer in competition.comp_question[idQ].comp_answer"
                  v-bind:key="answer.id"
                  v-bind:class="[
                    rta !== answer.id
                      ? ''
                      : 'tw-bg-red-400 text-white tw-font-medium',
                  ]"
                  @click="setRta(answer.id)"
                >
                  <p class="text mb-0 w-100 text-center">{{ answer.answer }}</p>
                </div>
                <!-- .col -->
              </div>
              <!-- .row -->
            </div>
            <div class="col-12 col-lg-8 py-3 px-0 mb-5 mx-auto">
              <table class="w-100">
                <tr>
                  <td>
                    <div
                      class="rounded-circle mt-md-2 tw-bg-gray-300 tw-bg-red-400"
                      v-for="conQuestion in competition.comp_question"
                      v-bind:class="[
                        conQuestion.id !== competition.comp_question[idQ].id
                          ? ''
                          : 'porfPointsActive rounded-pill',
                      ]"
                      v-bind:key="conQuestion.id"
                    ></div>
                  </td>
                  <td class="text-right">
                    <div class="d-none d-md-block">
                      <a
                        href="javascript:void(0)"
                        @click="sendRta()"
                        v-bind:class="
                          'btn btn-primary rounded-pill  background-primary ' +
                          [rta > 0 ? '' : 'disableBtn']
                        "
                        >Siguiente</a
                      >
                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="sendRta()"
                      v-bind:class="
                        'd-xl-none d-md-none ' + [rta > 0 ? '' : 'disableBtn']
                      "
                    >
                      <img
                        class="mt-3"
                        src="../../assets/img/icons/onboardingL.png"
                        alt=""
                      />
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <!-- .cont-anwers -->
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .cont-question-main  -->
      <!-- .container -->
    </div>
    <!-- .layout-content -->
  </div>
</template>

<script>
// import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import { mapActions, mapState } from "vuex";
import config from "@/services/config";

export default {
  name: "QuestionView",
  components: {
    // BreadCrumb,
    MobileBar,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      idQ: 0,
      rta: 0,
    };
  },
  created() {
    this.actGetCompetition(this.$route.params.id);

    // Funcion que recibe ID concurso y ID User
    this.actGetCompetitionByIdUser({
      id: this.$route.params.id,
      idUser: this.user.id,
    });
  },
  mounted() {},
  computed: {
    ...mapState("modCompetitions", ["competition", "competitionInfo"]),
    ...mapState("modCore", ["user"]),
    // question() {
    //   //return this.conQuestions[];
    //   return this.competition.comp_question[this.idQ].comp_answer;
    // },
  },
  methods: {
    ...mapActions("modCompetitions", [
      "actGetCompetition",
      "actQuestionsAnswer",
      "actGetCompetitionByIdUser",
    ]),
    setRta(id) {
      this.rta = id;
    },
    sendRta() {
      if (this.rta > 0) {
        this.actQuestionsAnswer({
          id: this.user.id,
          cc: this.user.cc,
          comp_answer_id: this.rta,
          comp_question_id: this.competition.comp_question[this.idQ].id,
        });
        this.next();
      }
    },
    next() {
      if (this.competition.comp_question.length > this.idQ + 1) {
        this.idQ++;
        this.rta = 0;
      } else {
        this.$router.push({ path: "/competition/congrats" });
      }
    },
  },
};
</script>

<style scoped>
.btn {
  width: 150px;
}

.porfPoints {
  width: 6px;
  height: 6px;
  float: left;
  margin: 0 2px;
}
.porfPointsActive {
  width: 18px;
}

/**Questions */

.cont-answers .answer:hover {
  border: 1px solid #d82b2b;
  background: #d82b2b;
  color: #ffffff;
}

.disableBtn {
  background-color: #ffffff;
  border: #ffffff;
}
.disableBtn:hover {
  background-color: #ffffff;
  border: #ffffff;
}
.disableBtn:focus {
  background-color: #ffffff;
  border: #ffffff;
}

@media (min-width: 600px) {
  .disableBtn {
    background-color: #bbbbbb !important;
    border: #bbbbbb !important;
  }
  .disableBtn:hover {
    background-color: #bbbbbb !important;
    border: #bbbbbb !important;
  }
  .disableBtn:focus {
    background-color: #bbbbbb !important;
    border: #bbbbbb !important;
  }
}
</style>
