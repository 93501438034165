<template>
  <div class="w-100 he-580 position-relative">
    <img
      src="@/assets/img/trivia/bg-finish.jpg"
      class="tw-object-cover w-100 he-580 tw-rounded-lg"
      alt="planeta manos"
    />
    <div class="position-absolute w-100 he-580 tw-top-0">
      <div
        class="
          h-100
          w-100
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-3
        "
      >
        <div class="d-flex mb-4">
          <img
            src="@/assets/img/trivia/earth.png"
            alt="earth"
            class="mx-1 wi-40 wi-md-70"
            v-for="index in resultEarth"
            :key="'earth-' + index"
          />
        </div>
        <p
          class="
            tw-text-white
            fs-50 fs-md-90
            line-height-40 line-height-md-70
            tw-font-tolyerBold
            mb-0
          "
        >
          NECESITAS
        </p>
        <div class="d-flex flex-column flex-md-row align-items-center">
          <p class="tw-text-white tw-font-tolyerBold  tw-text-xxxs 0 fs-md-150 mr-4">
            {{ result }}
          </p>
          <p
            class="
              text-center text-md-left tw-text-white
              tw-font-tolyerBold
              fs-50 fs-md-90
              line-height-40 line-height-md-70
              mb-0
            "
          >
            PLANETAS<br />TIERRA
          </p>
        </div>
        <p
          class="
            tw-text-white
            fs-50 fs-md-90
            line-height-40 line-height-md-70
            tw-font-tolyerBold
            mb-0
          "
        >
          PARA VIVIR
        </p>
        <button
          class="
            tw-bg-red-400
            tw-text-white
            py-2
            px-4
            tw-rounded-lg
            tw-border tw-border-solid tw-border-red-400 
            hover:tw-bg-red-600
            mt-5 mt-md-2
          "
          @click="movetrivia(4)"
        >
          Ver resultados
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiBeerOffice from "../../../services/apiBeerOffice";

export default {
  name: "FinishTrivia2",
  props: {
    trivia: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      result: 0,
      resultEarth: 1,
    };
  },
  created() {
    this.getTriviaResult();
  },
  methods: {
    movetrivia(state) {
      this.$emit("changeOpcVisible", state);
    },
    getTriviaResult() {
      ApiBeerOffice.getTriviaResult(this.trivia.id).then((data) => {
        let resultData = data.data;
        if (resultData.completed) {
          this.result = resultData.total.toFixed(1);
          this.resultEarth = Number(resultData.total.toFixed(0));
        }
      });
    },
  },
};
</script>
