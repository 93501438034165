<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: [String, Number],
  inputType: {
    type: String,
    default: "text",
  },
});
const emit = defineEmits(["update"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update", value);
  },
});
</script>

<template>
  <div class="relative">
    <input v-model="value" class="text-input" :type="inputType" />
  </div>
</template>

<style scoped lang="postcss">
.text-input {
  @apply tw-text-xs lg:tw-text-sm tw-bg-white tw-text-black tw-border tw-border-gray-500 tw-rounded-3xl tw-font-robotoLight tw-py-2 tw-w-full;

  &:disabled {
    @apply tw-bg-gray-200 tw-cursor-not-allowed;
  }

  &:focus {
    @apply tw-outline-none tw-border-gray-500 tw-ring-0;
  }
}
</style>
