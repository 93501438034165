<script setup>
import { ref } from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { useRouter } from "vue-router/composables";
import {
  reservationStatusText,
  reservationColorText,
  reservationButton,
} from "@/helpers/reservationStatusHelpers";
import { downloadFile } from "@/helpers/linkHelpers";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const reservationsStore = useReservationsStore();
const cancel = ref(false);
const router = useRouter();

const props = defineProps({
  booking: {
    type: Object,
    required: true,
  },
  showNumber: {
    type: Boolean,
    default: false,
  },
});

const buttonCallToAction = (name) => {
  switch (name) {
    case "PENDING_RAFFLE":
      return cancelReservation();
    case "WON_RAFFLE":
      return completePayment();
    case "PENDING_PAYMENT":
      return completePayment();
    case "CONFIRMED":
      return cancelReservation();
    default:
      return "Pendiente";
  }
};

const convertDate = (date) => {
  let [year, month, day] = date.split("-");
  const dateObj = new Date(year, month - 1, day);
  month = dateObj.toLocaleString("es-ES", { month: "long" });
  return `${day} de ${month} del ${year}`;
};

const cancelReservation = () => {
  if (!cancel.value) {
    cancel.value = true;
  } else {
    reservationsStore.actPostCancelBooking(props.booking.id).then(() => {
      reservationsStore.actGetBookings().then(() => {
        cancel.value = false;
      });
    });
  }
};

const completePayment = () => {
  router.push({
    name: "BookPayment",
    params: {
      id: props.booking.booking_place_id,
      bookingId: props.booking.id,
      totalPrice: props.booking.amount,
    },
  });
};
</script>
<template>
  <div>
    <div v-if="!cancel">
      <p
        class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-gray-300 tw-m-0 tw-underline"
        v-if="props.showNumber"
      >
        {{ "No. " + props.booking.id }}
      </p>
      <div class="tw-flex tw-flex-col tw-gap-5 tw-mt-4">
        <div class="tw-flex tw-flex-col tw-gap-3">
          <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
            {{
              `Reserva del día ${convertDate(
                props.booking.start_date
              )} al ${convertDate(props.booking.end_date)}${
                props.booking.status === "PENDING_PAYMENT"
                  ? ". Estamos pendientes de tu pago"
                  : ""
              }.`
            }}
          </p>
        </div>
        <div
          class="tw-grid tw-grid-cols-2 tw-gap-3"
          v-if="props.booking.status === 'CONFIRMED'"
        >
          <comp-button-custom
            text="Desc. credencial"
            @click.native="downloadFile(props.booking.confirmation_voucher)"
          />
          <comp-button-custom
            text="Desc. reglamento"
            @click.native="
              downloadFile('REGLAMENTO_SEDES_VACACIONALES.pdf', '/documents')
            "
          />
        </div>
        <div
          class="tw-flex tw-justify-between tw-gap-4"
          v-if="reservationButton(props.booking.status)"
        >
          <div
            class="tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer tw-w-full tw-btn-secondary-white"
            @click="buttonCallToAction(props.booking.status)"
            v-if="
              props.booking.status !== 'PENDING' ||
              props.booking.status !== 'PENDING_WON_RAFFLE'
            "
          >
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
              {{ reservationStatusText(props.booking.status) }}
            </p>
          </div>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer tw-w-full tw-btn-primary-black"
            @click="cancelReservation()"
            v-if="
              props.booking.status === 'PENDING_PAYMENT' ||
              props.booking.status === 'WON_RAFFLE' ||
              props.booking.status === 'PENDING' ||
              props.booking.status === 'PENDING_WON_RAFFLE'
            "
          >
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
              Cancelar reserva
            </p>
          </div>
        </div>
        <div v-else>
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm"
            :class="reservationColorText(props.booking.status)"
          >
            {{ reservationStatusText(props.booking.status) }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="tw-flex tw-flex-col tw-gap-5">
      <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-3">
        <div class="tw-col-span-1">
          <div class="tw-flex tw-justify-center tw-w-full tw-h-full">
            <img
              src="@/assets/img/ecommerce/compra-rechazada.svg"
              class="tw-object-contain tw-w-full tw-h-full"
              alt=""
            />
          </div>
        </div>
        <div class="tw-col-span-2 tw-flex tw-items-center">
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-center"
          >
            ¿Estás seguro de cancelar la reserva?, si quieres reservar de nuevo
            puedes correr el riesgo de perder los días y estarán sujetos a
            disponibilidad.
          </p>
        </div>
      </div>
      <div class="tw-flex tw-justify-between tw-items-center tw-gap-3">
        <div class="tw-btn-secondary-white" @click="cancel = false">
          <p class="tw-m-0">Volver</p>
        </div>
        <div class="tw-btn-primary-red" @click="cancelReservation()">
          <p class="tw-m-0">Cancelar reserva</p>
        </div>
      </div>
    </div>
  </div>
</template>
