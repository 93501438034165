import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  notificationsUri: "/notifications",
};

export const useNotificationsStore = defineStore("notifications", () => {
  const showNotifications = ref(false);
  const hasNotificationsRead = ref(true);
  const notifications = ref([]);
  const notificationsRead = ref([]);
  const notificationsUnread = ref([]);
  const sections = ref([]);

  async function actGetNotifications() {
    const response = await AxiosBeerOffice.get(uri.notificationsUri, true, {});
    if (response.error) return response.error;
    const { data } = response;
    notifications.value = data;
    setHasNotificationsRead();

    notificationsRead.value = notifications.value.filter(
      (notification) => notification.read_at != null
    );

    notificationsUnread.value = notifications.value.filter(
      (notification) => notification.read_at == null
    );

    return true;
  }

  async function actGetSections() {
    const response = await AxiosBeerOffice.get(
      uri.notificationsUri + "/sections",
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    sections.value = data;
    return true;
  }

  async function actMarkNotificationsAsRead(notificationId) {
    let data = [];
    data.push(notificationId);

    const response = await AxiosBeerOffice.post(
      uri.notificationsUri + "/updateBulk",
      true,
      {
        notifications: data,
      }
    );

    if (response === "ok") {
      notifications.value = notifications.value.map((notification) => {
        notification.read_at = new Date();
        return notification;
      });
    } else {
      return response.error;
    }
  }

  function toggleShowNotifications() {
    showNotifications.value = !showNotifications.value;
  }

  function hideNotifications() {
    showNotifications.value = false;
  }

  function setHasNotificationsRead() {
    let stateHasNotificationRead = notifications.value.find(
      (notification) => notification.read_at != null
    );
    hasNotificationsRead.value = stateHasNotificationRead === undefined;
  }

  return {
    notifications,
    notificationsRead,
    notificationsUnread,
    sections,
    showNotifications,
    hasNotificationsRead,
    actMarkNotificationsAsRead,
    toggleShowNotifications,
    hideNotifications,
    actGetSections,
    actGetNotifications,
  };
});
