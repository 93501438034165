<script setup>
import { ref, computed, watch, onBeforeMount } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { useNavbarStore } from "@/stores/navbarStore";

import config from "@/services/config";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import HandleModal from "@/components/modals/HandleModal";
import CardProductsCart from "@/components/ecommerce/CardProductsCart";
import CompGeneralRatingStars from "@/components/rating/CompGeneralRatingStars";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const navbarStore = useNavbarStore();
const step = ref(1);
const successfulPayment = ref(false);
const rejectedPayment = ref(false);
const order_id = ref(null);
const uriResources = config.beerOfficeURL;
const productsToDataLayer = ref([]);
const option = ref({});
const review = ref("");
const dues = ref(0);
const score = ref(0);
const handleModalRef = ref(null);
const gift = ref(null);

const shoppingCartArray = computed(() => ecommerceStore.shoppingCartArray);
const shoppingCartQty = computed(() => ecommerceStore.shoppingCartQty);
const bubble = computed(() => shoppingCartQty.value > 0);

const shoppingCartTotal = computed(() => {
  return Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  }).format(
    shoppingCartArray.value.reduce(
      (acc, product) => acc + product.price_public * product.qty,
      0
    )
  );
});

watch(
  () => userStore.user.ecomm_delivery_site,
  (newSite) => {
    option.value = newSite;
  }
);

watch(
  () => shoppingCartQty.value,
  (newVal) => {
    newVal === 0 ? (dues.value = 0) : null;
  }
);

function voteRating(scr) {
  score.value = scr;
}

const nextStep = () => {
  switch (step.value) {
    case 4:
      if (successfulPayment.value) {
        ecommerceStore
          .actPostOrderReview({
            id: order_id.value,
            score: score.value,
            review: review.value,
          })
          .then(() => {
            navbarStore.hideOrderModal();
            sendDataLayer(4);
            step.value = 1;
          });
      } else {
        navbarStore.hideOrderModal();
        step.value = 1;
      }
      break;
    case 3:
      step.value++;
      break;
    case 2:
      sendDataLayer(3);
      sendShopping();
      step.value++;
      break;
    case 1:
      step.value++;
      sendDataLayer(2);
      break;
  }
};

function previousStep() {
  if (step.value === 3 && successfulPayment.value) {
    navbarStore.hideOrderModal();
    step.value = 1;
  } else {
    step.value--;
  }
}

async function sendShopping() {
  const order = {
    id: userStore.user.id,
    cc: userStore.user.cc,
    ecomm_deliver_site_id: userStore.user.ecomm_delivery_site_id,
    payment: dues.value,
  };
  if (shoppingCartArray.value.length > 0) {
    order.buy_products = JSON.stringify(shoppingCartArray.value);
  }
  if (gift.value != null) {
    order.gift_product = gift.value.id;
  }
  await ecommerceStore.actPostShoppingProducts(order).then((response) => {
    if (response.buy) {
      successfulPayment.value = true;
      order_id.value = response.buy.id;
      ecommerceStore.emptyShoppingCart();
      pushDataLayer({ ecommerce: null }, false);
      pushDataLayer(
        {
          ecommerce: {
            purchase: {
              actionField: {
                id: order_id.value,
                affiliation: "Online Store",
                revenue: shoppingCartTotal.value.slice(2),
                Numberofinstalments: dues.value,
                Location: option.value.name,
              },
              products: productsToDataLayer.value,
            },
          },
        },
        true
      );
    } else {
      successfulPayment.value = false;
      rejectedPayment.value = true;
    }
  });
}

onBeforeMount(() => {
  option.value = userStore.user.ecomm_delivery_site;
});

function sendDataLayer(step) {
  if (shoppingCartQty.value > 0 && step === 1) {
    shoppingCartArray.value.forEach((product) => {
      productsToDataLayer.value.push({
        name: product.name,
        id: product.id,
        price: product.price_public,
        brand: product.ecomm_brand.name,
        category: product.ecomm_product_category?.name,
        quantity: product.qty,
      });
    });
  }
  if (step === 1 && productsToDataLayer.value.length > 0) {
    pushDataLayer({ ecommerce: null }, false);
    pushDataLayer({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: productsToDataLayer.value,
        },
      },
    });
  }
  if (step === 2 && dues.value > 0) {
    pushDataLayer({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 2, Numberofinstalments: dues.value },
          products: productsToDataLayer.value,
        },
      },
    });
  }
  if (step === 3 && dues.value > 0 && !successfulPayment.value) {
    pushDataLayer({ ecommerce: null }, false);
    pushDataLayer({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step: 3,
            Numberofinstalments: dues.value,
            Location: option.value.name,
          },
          products: productsToDataLayer.value,
        },
      },
    });
  }
  if (step === 4) {
    pushDataLayer(
      {
        event_category: "Tienda",
        event_action: "Calificación experiencia",
        event_label: review.value,
        component_name: "Click",
      },
      true
    );
    review.value = "";
  }
}
</script>
<template>
  <div>
    <handle-modal
      :max-height="{ base: 400, md: 500, lg: 600, xl: 600 }"
      :modal-width="{ md: 400, lg: 400, xl: 400 }"
      modal-name="shopping-cart"
      icon="icon-carrito-de-mercado"
      :bubble="bubble"
      :nrOfItems="shoppingCartQty"
      ref="handleModalRef"
      @open="sendDataLayer(1)"
    >
      <!-- step 1 -->
      <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6 tw-overflow-hidden">
        <div class="tw-flex-initial">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xl tw-text-red-350 tw-text-center"
          >
            {{
              step === 1
                ? "Carrito de compras"
                : step === 2
                ? "Lugar de Entrega"
                : step === 3 && successfulPayment
                ? "Compra exitosa"
                : step === 3 && !successfulPayment
                ? "Compra rechazada"
                : step === 4 && successfulPayment
                ? "Compra exitosa"
                : ""
            }}
          </p>
        </div>
        <div
          class="tw-w-full tw-h-full tw-px-2 tw-py-1 tw-overflow-auto"
          v-if="shoppingCartQty > 0 && step === 1"
        >
          <div class="tw-grid tw-grid-cols-1">
            <div v-for="(product, idx) in shoppingCartArray" :key="idx">
              <card-products-cart :product="product" />
              <hr class="tw-w-full" />
            </div>
            <div class="tw-flex tw-justify-end">
              <p class="tw-font-robotoBold tw-text-base">
                {{ "Subtotal: " + shoppingCartTotal }}
              </p>
            </div>
          </div>
        </div>

        <!-- step 2 -->
        <div
          class="tw-w-full tw-h-full tw-px-2 tw-py-1 tw-overflow-auto"
          v-if="step === 2"
        >
          <div class="tw-flex tw-flex-col tw-gap-y-4">
            <div
              id="siteImg"
              class="tw-aspect-w-16 tw-aspect-h-10 lg:tw-aspect-w-16 lg:tw-aspect-h-8 tw-rounded-2.5xl tw-overflow-hidden"
            >
              <img
                v-if="option.img"
                :src="uriResources + '/' + option.img"
                alt=""
                class="tw-h-full tw-object-cover tw-select-none"
              />
              <img
                v-else
                src="@/assets/img/ecommerce/no-site-img.png"
                alt=""
                class="tw-h-full tw-object-cover tw-select-none"
              />
            </div>
            <div id="confirmationText" class="tw-flex-1">
              <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-lg">
                Recuerda que el punto de entrega de tus productos es en
                <span class="tw-text-red-350 tw-font-robotoBold">{{
                  option.name
                }}</span>
              </p>
            </div>
          </div>
          <hr class="tw-w-full" />
        </div>

        <!-- step 3 -->
        <div v-if="step === 3" class="tw-flex tw-flex-col tw-gap-6">
          <div class="tw-aspect-w-10 tw-aspect-h-8">
            <img
              v-if="!successfulPayment"
              src="@/assets/img/order-status/order-no-pending-order.svg"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/order-status/order-no-pending-order.svg"
              alt=""
            />
          </div>
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center"
            v-if="!successfulPayment"
          >
            Tu compra ha sido rechazada, revisa que la ubicación y los productos
            estén correctos, si el problema continua, comunicate con soporte
          </p>
          <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center" v-else>
            Tu compra se ha registrada, puedes ver su estado en tus pedidos
          </p>
        </div>
        <div
          v-if="shoppingCartQty > 0 && shoppingCartQty <= 4 && step === 1"
          class="tw-flex tw-gap-3 tw-justify-around"
        >
          <p class="tw-font-robotoBold tw-text-base tw-m-0">Cuotas:</p>
          <div class="tw-flex tw-justify-center tw-items-center tw-gap-2">
            <input
              id="value-1"
              v-model="dues"
              class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
              name="proyector"
              type="radio"
              value="1"
            />
            <label class="tw-m-0 tw-text-sm" for="radio-1">1</label>
            <input
              id="value-2"
              v-model="dues"
              class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
              name="proyector"
              type="radio"
              value="2"
            />
            <label class="tw-m-0 tw-text-sm" for="radio-1">2</label>
            <input
              id="value-3"
              v-model="dues"
              class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
              name="proyector"
              type="radio"
              value="3"
            />
            <label class="tw-m-0 tw-text-sm" for="radio-1">3</label>
            <input
              id="value-4"
              v-model="dues"
              class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
              name="proyector"
              type="radio"
              value="4"
            />
            <label class="tw-m-0 tw-text-sm" for="radio-1">4</label>
          </div>
        </div>
        <p
          class="tw-m-0 tw-text-roboto tw-text-red-350 tw-text-xs"
          v-if="shoppingCartQty > 4 && step === 1"
        >
          Recuerda que solo puedes comprar 4 productos por mes, elimina algunos
          para seguir con la compra
        </p>

        <!-- step 4 -->
        <div
          v-if="step === 4 && successfulPayment"
          class="tw-flex tw-flex-col tw-gap-6"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs">
            ¿Cómo calificarías nuestro servicio para la compra de productos?
          </p>
          <div class="tw-w-10/12 tw-self-center">
            <comp-general-rating-stars
              star-background-unchecked-color="white"
              @voteRating="voteRating"
              star-border-color="black"
            />
          </div>
          <p class="tw-m-0 tw-font-roboto tw-text-xs">
            ¿Quieres compartir con nosotros tu experiencia? Escríbenos, es muy
            importante conocer tu opinión.
          </p>
          <textarea
            class="tw-w-full tw-h-40 tw-p-6 tw-border tw-border-gray-300 tw-rounded-2.5xl focus:tw-outline-none focus:tw-ring-gray-300 tw-text-roboto tw-text-sm"
            name="comment"
            placeholder="Contenido..."
            v-model="review"
          />
        </div>

        <!-- Buttons -->
        <div class="tw-grow-0">
          <div
            v-if="step === 1 && shoppingCartQty > 0"
            :class="
              shoppingCartQty > 4 || dues === 0
                ? 'tw-btn-secondary-disabled'
                : 'tw-btn-primary-red'
            "
            @click="shoppingCartQty <= 4 && dues > 0 && nextStep()"
          >
            Pagar
          </div>
          <div
            class="tw-grid tw-grid-cols-2 tw-gap-4"
            v-if="step === 2 && shoppingCartQty > 0"
          >
            <div class="tw-btn-secondary-white" @click="previousStep()">
              Cancelar
            </div>
            <div class="tw-btn-primary-red" @click="nextStep()">Continuar</div>
          </div>
          <div
            v-if="step === 3 && !successfulPayment"
            class="tw-btn-primary-red"
            @click="previousStep()"
          >
            Volver
          </div>
          <div
            v-if="step === 3 && successfulPayment"
            class="tw-btn-primary-red"
            @click="nextStep()"
          >
            Siguiente
          </div>
          <div
            v-if="step === 4 && successfulPayment"
            class="tw-btn-primary-red"
            @click="nextStep()"
          >
            Siguiente
          </div>
        </div>
        <div
          v-if="shoppingCartQty === 0 && step === 1"
          class="tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-aspect-w-10 tw-aspect-h-8">
            <img
              src="@/assets/img/order-status/order-no-pending-order.svg"
              alt=""
            />
          </div>
          <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center">
            No tienes ningún producto en tu carrito de compra.
          </p>
        </div>
      </div>
    </handle-modal>
  </div>
</template>
