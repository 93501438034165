<script setup>
import { computed } from "vue";
import CompFormTextInput from "@/components/form/CompFormTextInput.vue";

const props = defineProps({
  data: Object,
  profileSaved: Boolean,
});

const emit = defineEmits(["update"]);

const nativeCity = computed(() => {
  if (props.data.native_city) {
    return props.data.native_city;
  } else {
    return "";
  }
});

const isNativeCityEmpty = computed(
  () => !props.data?.native_city && !props.profileSaved
);
</script>

<template>
  <section
    id="city-information"
    class="tw-flex tw-flex-col tw-gap-2 tw-p-3 tw-border tw-border-gray-300 tw-rounded-2xl"
  >
    <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
      ¿Cuál es tu ciudad de orígen?
    </p>
    <comp-form-text-input
      :model-value="nativeCity"
      @update="(value) => emit('update', { ...props.data, native_city: value })"
    />
    <span
      v-if="isNativeCityEmpty"
      class="tw-font-robotoLight tw-text-sm tw-text-red-350"
      >* Por favor escribe tu ciudad de orígen</span
    >
  </section>
</template>

<style scoped></style>
