<script setup>
import { ref, onBeforeMount, computed, getCurrentInstance, watch } from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { useCoreStore } from "@/stores/coreStore";
import { useRoute, useRouter } from "vue-router/composables";
import config from "@/services/config";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompCapacity from "@/components/reservations/CompCapacity";
import CompDatePicker from "@/components/calendar/datepicker/CompDatePicker";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import GeneralModal from "@/components/modals/GeneralModal";

const { $cloneArray, $isMobile } = getCurrentInstance().proxy;
const route = useRoute();
const router = useRouter();
const reservationsStore = useReservationsStore();
const coreStore = useCoreStore();
const uriResources = config.beerOfficeURL;
const datePicker = ref(null);
const isLoading = ref(false);
const isNoAvailableModal = ref(false);
const bookingStep = ref(1);
const sliderSelect = ref(true);
const availableHours = ref([]);
const reservationData = ref({
  capacity: {},
  selectedDay: null,
  hours: [],
  table_organization: {
    reference: null,
    option: null,
  },
  require_projector: null,
  full_place: 0,
  outside_people: null,
  montage: null,
  montage_description: null,
  guestsNumber: 0,
  guests: [],
  formData: new FormData(),
  guest_file: false,
  peopleNumber: 0,
});
const tables = ref([
  {
    id: 0,
    name: "Forma de U",
    img: require("@/assets/img/reservations/arr_formau.svg"),
    reference: "U_SHAPE",
  },
  {
    id: 1,
    name: "Mesa redonda",
    img: require("@/assets/img/reservations/arr_redonda.svg"),
    reference: "ROUND_TABLE",
  },
  {
    id: 2,
    name: "Vacío",
    img: require("@/assets/img/reservations/arr_vacio.svg"),
    reference: "EMPTY",
  },
  {
    id: 3,
    name: "Auditorio",
    img: require("@/assets/img/reservations/arr_auditorio.svg"),
    reference: "AUDIENCE",
  },
]);

// Computed
const mainImg = computed(() => {
  return reservationsStore.bookingPlaceDetail.photos.filter(
    (item) => item.is_main === true
  )[0].image;
});

const peopleCapacityNumber = computed(() => {
  let capacity =
    reservationData.value.full_place === 0
      ? reservationData.value.capacity.capacity
      : reservationData.value.capacity.capacity * 2;
  return Array.from(Array(capacity).keys(), (_, i) => i + 1);
});

const table_organization = computed(() => {
  let with_table =
    reservationsStore.bookingPlaceDetail.with_table_organization === 1 &&
    reservationData.value.table_organization.option != null;
  return with_table || reservationData.value.table_organization.option === 0;
});

const validateSteps = () => {
  switch (bookingStep.value) {
    case 1:
      return (
        Object.keys(reservationData.value.capacity).length > 0 &&
        reservationData.value.selectedDay != null &&
        availableHours.value.length > 0
      );
    case 2:
      return reservationData.value.hours.length > 0;
    case 3:
      return (
        table_organization.value &&
        reservationData.value.outside_people != null &&
        reservationData.value.montage != null
      );
    case 4:
      return (
        (reservationData.value.guest_file && !sliderSelect.value) ||
        (checkValueGuests() && sliderSelect.value)
      );
    case 5:
      return true;
    default:
      return false;
  }
};

//Methods
const setCapacity = (option) => {
  reservationData.value.capacity = option ?? option != null;
};

const setDaySelected = (day) => {
  if (day != null) {
    reservationData.value.selectedDay = day;
    getAvailableHours(day);
  } else {
    reservationData.value.selectedDay = null;
    availableHours.value = [];
  }
};

const getAvailableHours = async (day) => {
  await reservationsStore
    .actGetBookingAvailableHours({
      booking_place_id: route.params.id,
      booking_place_option_id: reservationData.value.capacity.id,
      date: day.Dateymd,
      full_place: Number(reservationData.value.full_place),
    })
    .then(() => {
      if (reservationsStore.bookingAvailableHours.length > 0) {
        availableHours.value = $cloneArray(
          reservationsStore.bookingAvailableHours
        );
        availableHours.value.forEach((hour) => {
          hour.selected = false;
        });
      } else {
        isNoAvailableModal.value = true;
        datePicker.value.unSelectDay();
      }
    });
};

const nextStep = () => {
  if (bookingStep.value < 5) {
    if (
      bookingStep.value === 3 &&
      parseInt(reservationData.value.outside_people) === 0 &&
      parseInt(reservationData.value.montage) === 1
    ) {
      bookingStep.value = 5;
    } else if (
      (bookingStep.value === 3 &&
        parseInt(reservationData.value.outside_people) === 0 &&
        parseInt(reservationData.value.montage) === 0) ||
      (bookingStep.value === 4 && parseInt(reservationData.value.montage) === 0)
    ) {
      postBooking();
    } else {
      bookingStep.value++;
    }
  } else {
    postBooking();
  }
};

const postBooking = () => {
  reservationData.value.formData.append(
    "booking_place_id",
    reservationsStore.bookingPlaceDetail.id
  );
  reservationData.value.formData.append(
    "booking_place_option_id",
    reservationData.value.capacity.id
  );
  reservationData.value.formData.append(
    "booking_place_category_id",
    reservationsStore.bookingPlaceDetail.booking_place_category_id
  );
  reservationData.value.formData.append(
    "start_date",
    reservationData.value.selectedDay.Dateymd
  );
  reservationData.value.formData.append(
    "end_date",
    reservationData.value.selectedDay.Dateymd
  );
  reservationData.value.formData.append(
    "start_time",
    reservationData.value.hours[0].start_time_short_24
  );
  reservationData.value.formData.append(
    "end_time",
    reservationData.value.hours[0].end_time_short_24
  );
  reservationData.value.formData.append(
    "table_organization",
    reservationData.value.table_organization.reference
  );
  /*reservationData.value.formData.append(
    "require_projector",
    reservationData.value.require_projector);*/
  reservationData.value.formData.append(
    "outside_people",
    reservationData.value.outside_people
  );
  reservationData.value.formData.append(
    "montage",
    reservationData.value.montage
  );
  reservationData.value.formData.append(
    "number_guests",
    reservationData.value.peopleNumber
  );
  if (reservationData.value.montage) {
    reservationData.value.formData.append(
      "montage_description",
      reservationData.value.montage_description
    );
  }

  if (!reservationData.value.guest_file) {
    reservationData.value.guests.forEach((guest, index) => {
      reservationData.value.formData.append(
        `guests[${index}][name]`,
        guest.name
      );
      reservationData.value.formData.append(`guests[${index}][type]`, "ADULT");
    });
  }

  reservationsStore
    .actPostBooking(reservationData.value.formData)
    .then((response) => {
      if (response.message === "Booking created") {
        router.push({
          name: "CongratulationsFinal127",
          params: {
            id: route.params.id,
          },
        });
      }
    });
};

const selectHour = (e, hour, idx) => {
  if (isHourSelected(hour)) {
    // check if the hour is already selected
    let index = reservationData.value.hours.indexOf(hour);

    // if its the first or the last hour in reservationData.hours remove it
    if (index === 0 || index === reservationData.value.hours.length - 1) {
      reservationData.value.hours.splice(index, 1);
      availableHours.value[idx].selected = false;
      return;
    }

    // if selected hours array is empty, restore hours to their original state
    if (reservationData.value.hours.length === 0) {
      restoreHours();
    }
  } else {
    //find next closest unavailable hour
    let firstUnavailableHourIndex = findNextUnavailableHour(idx);
    turnNextHoursUnavailable(firstUnavailableHourIndex);
    // find last closest unavailable hour
    let lastUnavailableHourIndex = findLastUnavailableHour(idx);
    turnLastHoursUnavailable(lastUnavailableHourIndex);
    // add hour to selected hours

    // if the hour is the first one selected, add it to the array
    if (reservationData.value.hours.length === 0) {
      reservationData.value.hours.push(hour);
      availableHours.value[idx].selected = true;
      return;
    }

    // if the hour is not the first one selected, but it is the first in the availableHours array, check if the next hour is already selected
    if (idx === 0) {
      if (availableHours.value[idx + 1].selected === true) {
        reservationData.value.hours.push(hour);
        availableHours.value[idx].selected = true;
        return;
      }
    }

    // if the hour is not the first one selected, but it is the last in the availableHours array, check if the previous hour is already selected
    if (idx === availableHours.value.length - 1) {
      if (availableHours.value[idx - 1].selected === true) {
        reservationData.value.hours.push(hour);
        availableHours.value[idx].selected = true;
        return;
      }
    }

    // if the hour is not the first one selected, and it is not the first or the last in the availableHours array, check if the previous or next hours are already selected
    if (
      idx !== 0 &&
      idx !== availableHours.value.length - 1 &&
      reservationData.value.hours.length > 0
    ) {
      if (
        availableHours.value[idx - 1].selected === true ||
        availableHours.value[idx + 1].selected === true
      ) {
        reservationData.value.hours.push(hour);
        availableHours.value[idx].selected = true;
        reservationData.value.hours.sort((a, b) =>
          a.start_time.localeCompare(b.start_time)
        );
      }
    }
  }
};

const deleteDay = () => {
  reservationData.value.selectedDay = null;
};

const isHourSelected = (hour) => {
  return reservationData.value.hours.some((el) => {
    return el === hour;
  });
};

const findNextUnavailableHour = (idx) => {
  let nextHour = availableHours.value[idx + 1];
  if (nextHour) {
    if (nextHour.available) {
      return findNextUnavailableHour(idx + 1);
    } else {
      return availableHours.value.indexOf(nextHour);
    }
  } else {
    return false;
  }
};

const findLastUnavailableHour = (idx) => {
  let pastHour = availableHours.value[idx - 1];
  if (pastHour) {
    if (pastHour.available) {
      return findLastUnavailableHour(idx - 1);
    } else {
      return availableHours.value.indexOf(pastHour);
    }
  } else {
    return false;
  }
};

const turnNextHoursUnavailable = (idx) => {
  availableHours.value.forEach((hour, index) => {
    if (idx && index >= idx) {
      hour.available = false;
    }
  });
};

const turnLastHoursUnavailable = (idx) => {
  availableHours.value.forEach((hour, index) => {
    if (idx && index <= idx) {
      hour.available = false;
    }
  });
};

const restoreHours = () => {
  availableHours.value = $cloneArray(reservationsStore.bookingAvailableHours);
};

const changeSwitch = () => {
  sliderSelect.value = !sliderSelect.value;
};

const addGuest = () => {
  if (reservationData.value.guestsNumber < 6) {
    reservationData.value.guestsNumber++;
    reservationData.value.guests.push({});
  }
};

const decrementGuest = () => {
  if (reservationData.value.guestsNumber > 1) {
    reservationData.value.guestsNumber--;
    reservationData.value.guests.pop();
  }
};

const removeGuests = () => {
  reservationData.value.guestsNumber = 0;
  reservationData.value.guests = [];
};

const updateAdult = (value, key, idx) => {
  !reservationData.value.guests[idx]
    ? (reservationData.value.guests[idx] = {})
    : null;
  reservationData.value.guests[idx][key] = value;
};

const updateTableName = (id, reference, name) => {
  reservationData.value.table_organization.reference = reference;
  reservationData.value.table_organization.option = id;
  reservationData.value.table_organization.name = name;
};

const uploadGuestFile = () => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute(
    "accept",
    "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
  input.click();
  input.onchange = () => {
    const file = input.files[0];
    if (file.size <= 20000000) {
      reservationData.value.formData.append("guest_file", file, file.name);
      reservationData.value.guest_file = true;
    } else {
      alert("El archivo es muy pesado");
    }
  };
};

const downloadGuestFile = (url) => {
  let downloadURL = coreStore.getURLResourceByKey(url);
  let a = document.createElement("a");
  a.href = downloadURL;
  a.click();
};

const checkValueGuests = () => {
  let valid = true;
  let keys = ["name", "surname", "document_id", "document_type", "eps"];

  // check if all fields are filled
  reservationData.value.guests.forEach((guest) => {
    keys.forEach((key) => {
      if (guest[key] === null || guest[key] === "") {
        valid = false;
      }
    });
  });

  if (valid) {
    reservationData.value.guests.forEach((guest) => {
      if (
        guest.name === "" ||
        guest.surname === "" ||
        guest.document_id === "" ||
        guest.document_type === "" ||
        guest.eps === ""
      ) {
        valid = false;
      }
    });
  }

  return valid;
};

const cancel = () => {
  bookingStep.value === 1
    ? router.push({
        path: `/reservations/space/${route.params.id}`,
      })
    : bookingStep.value === 5 &&
      parseInt(reservationData.value.outside_people) === 0
    ? (bookingStep.value = 3)
    : bookingStep.value--;
};

const showNoAvailableModal = () => {
  isNoAvailableModal.value = true;
};

const hideNoAvailableModal = () => {
  isNoAvailableModal.value = false;
};

const changeStep = (step) => {
  bookingStep.value = step;
};

const setPeopleCapacity = (option) => {
  reservationData.value.peopleNumber = option;
};

watch(
  () => reservationData.value.full_place,
  () => {
    if (reservationData.value.selectedDay != null) {
      reservationData.value.selectedDay = null;
    }
  }
);

onBeforeMount(async () => {
  isLoading.value = true;
  await Promise.all([
    reservationsStore.actGetBookingPlaceDetail(route.params.id),
    coreStore.actGetSharedResources(),
  ]);
  isLoading.value = false;
});
</script>
<template>
  <div v-if="!isLoading" class="tw-relative">
    <general-modal
      :hide-button-support="true"
      v-if="isNoAvailableModal"
      :isShowModal="isNoAvailableModal"
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
      modalName="noAvailableHour"
      @close="hideNoAvailableModal"
    >
      <div class="tw-flex tw-flex-col tw-justify-between">
        <p
          class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350 tw-mb-2"
        >
          ¡Lo sentimos!
        </p>

        <p class="tw-mt-2 tw-font-roboto tw-text-xs lg:tw-text-sm">
          No puedes seleccionar este día porque no tiene horas disponibles,
          selecciona una fecha diferente.
        </p>

        <div class="tw-w-full tw-flex tw-justify-end">
          <img
            alt=""
            class="tw-max-h-6"
            src="@/assets/img/icons/icon-corner.svg"
          />
        </div>
      </div>
    </general-modal>
    <mobile-bar title="Reserva" />
    <title-bar title="Reserva" />
    <div class="tw-container-general tw-mt-5">
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-20">
        <div
          id="characteristics"
          class="tw-my-3 md:tw-my-4 tw-flex tw-flex-col tw-gap-5"
        >
          <!-- Booking Step 1 -->
          <!-- Capacity and Date -->
          <div v-show="bookingStep === 1" class="tw-flex tw-flex-col tw-gap-5">
            <div id="capacidad">
              <CompCapacity
                :clickable="true"
                :justCapacity="true"
                :optionSelected="reservationData.capacity"
                :option_1="reservationsStore.bookingPlaceDetail.options[0]"
                :option_2="reservationsStore.bookingPlaceDetail.options[1]"
                title="Selecciona la capacidad"
                @set-capacity="setCapacity"
              />
              <div class="tw-flex tw-items-center tw-gap-3 tw-px-2">
                <input type="hidden" name="full_place" value="0" />
                <input
                  id="full_place"
                  value="1"
                  name="full_place"
                  class="checkbox-round focus:tw-ring-transparent"
                  type="checkbox"
                  v-model="reservationData.full_place"
                />
                <p class="tw-m-0 tw-font-roboto tw-text-sm">
                  Reserva todo el espacio
                </p>
              </div>
            </div>
            <div id="calendar">
              <CompDatePicker
                ref="datePicker"
                :onlyWorkDays="true"
                :place="reservationsStore.bookingPlaceDetail"
                :optionIdSelected="reservationData.capacity.id"
                :delete-selected-day="reservationData.selectedDay == null"
                @deleteDates="deleteDay"
                @setDaySelected="setDaySelected"
                @notAvailable="showNoAvailableModal"
              />
            </div>
          </div>

          <!-- Booking Step 2 -->
          <div v-show="bookingStep === 2" class="tw-flex tw-flex-col tw-gap-5">
            <div class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-6">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                Selecciona el horario
              </p>
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Puedes seleccionar varias horas continuas por cada reserva
              </p>
              <p
                class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base tw-hidden md:tw-block tw-text-center md:tw-text-gray-450"
              >
                {{
                  reservationData.selectedDay &&
                  reservationData.selectedDay.date +
                    " de " +
                    reservationData.selectedDay.month +
                    " de " +
                    reservationData.selectedDay.year
                }}
              </p>
            </div>
            <div
              class="tw-grid tw-grid-cols-1 tw-gap-5 md:tw-gap-10 md:tw-grid-cols-2 md:tw-mt-5"
            >
              <div id="morningHours" class="tw-flex tw-flex-col tw-gap-5">
                <p
                  class="tw-font-robotoBold tw-text-base md:tw-text-2xl tw-text-red-350 md:tw-text-gray-400 tw-m-0 md:tw-text-center"
                >
                  Mañana
                </p>
                <div
                  class="tw-flex md:tw-flex-col tw-gap-3 md:tw-gap-6 tw-overflow-x-scroll md:tw-overflow-x-auto tw-py-2"
                >
                  <div
                    v-for="(hour, idx) in availableHours"
                    :key="idx"
                    :class="
                      hour.start_timing === 'AM' ? 'tw-block' : 'tw-hidden'
                    "
                  >
                    <div
                      :class="[
                        hour.available ? 'tw-cursor-pointer' : '',
                        hour.available && !$isMobile()
                          ? 'md:tw-border-gray-450'
                          : 'md:tw-border-gray-250',
                      ]"
                      class="tw-border tw-border-solid tw-border-gray-250 tw-rounded-2xl tw-w-32 tw-h-28 md:tw-w-full md:tw-h-auto tw-p-2 md:tw-p-6 tw-relative"
                      @click.prevent="
                        hour.available ? selectHour($event, hour, idx) : null
                      "
                    >
                      <div
                        v-show="hour.available"
                        class="tw-absolute tw-top-2 tw-right-3"
                      >
                        <div class="round tw-flex tw-items-center">
                          <input
                            :id="'morning' + idx"
                            type="checkbox"
                            :checked="isHourSelected(hour)"
                          />
                          <label :for="'morning' + idx"></label>
                        </div>
                      </div>
                      <div
                        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full"
                      >
                        <i
                          :class="
                            hour.available
                              ? 'tw-text-black'
                              : 'tw-text-gray-250'
                          "
                          class="icon-clock-book tw-text-6xl tw-m-auto tw-block md:tw-hidden"
                        />
                        <p
                          :class="[
                            hour.available && $isMobile()
                              ? 'tw-text-black'
                              : 'tw-text-gray-250',
                            hour.available && !$isMobile()
                              ? 'tw-text-gray-450'
                              : 'tw-text-gray-250',
                          ]"
                          class="tw-font-robotoBold tw-text-xs md:tw-text-lg tw-m-0"
                        >
                          {{
                            hour.start_time_short + " - " + hour.end_time_short
                          }}
                        </p>
                        <p
                          :class="
                            hour.available
                              ? 'tw-text-gray-500'
                              : 'tw-text-gray-250'
                          "
                          class="tw-font-roboto tw-text-xxs tw-m-0 tw-block md:tw-hidden tw-text-center"
                        >
                          {{
                            reservationData.selectedDay &&
                            reservationData.selectedDay.date +
                              " de " +
                              reservationData.selectedDay.month +
                              " de " +
                              reservationData.selectedDay.year
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="afternoonHours" class="tw-flex tw-flex-col tw-gap-5">
                <p
                  class="tw-font-robotoBold tw-text-base md:tw-text-2xl tw-text-red-350 md:tw-text-gray-400 tw-m-0 md:tw-text-center"
                >
                  Tarde
                </p>
                <div
                  class="tw-flex md:tw-flex-col tw-gap-3 md:tw-gap-6 tw-overflow-x-scroll md:tw-overflow-x-auto tw-py-2"
                >
                  <div
                    v-for="(hour, idx) in availableHours"
                    :key="idx"
                    :class="
                      hour.start_timing === 'PM' ? 'tw-block' : 'tw-hidden'
                    "
                  >
                    <div
                      :class="[
                        hour.available ? 'tw-cursor-pointer' : '',
                        hour.available && !$isMobile()
                          ? 'md:tw-border-gray-450'
                          : 'md:tw-border-gray-250',
                      ]"
                      class="tw-border tw-border-solid tw-border-gray-250 tw-rounded-2xl tw-w-32 tw-h-28 md:tw-w-full md:tw-h-auto tw-p-2 md:tw-p-6 tw-relative"
                      @click.prevent="
                        hour.available ? selectHour($event, hour, idx) : null
                      "
                    >
                      <div
                        v-show="hour.available"
                        class="tw-absolute tw-top-2 tw-right-3"
                      >
                        <div class="round tw-flex tw-items-center">
                          <input
                            :id="'afternoon' + idx"
                            type="checkbox"
                            :checked="isHourSelected(hour)"
                          />
                          <label :for="'afternoon' + idx"></label>
                        </div>
                      </div>
                      <div
                        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full"
                      >
                        <i
                          :class="
                            hour.available
                              ? 'tw-text-black'
                              : 'tw-text-gray-250'
                          "
                          class="icon-clock-book tw-text-6xl tw-m-auto tw-block md:tw-hidden"
                        />
                        <p
                          :class="[
                            hour.available && $isMobile()
                              ? 'tw-text-black'
                              : 'tw-text-gray-250',
                            hour.available && !$isMobile()
                              ? 'tw-text-gray-450'
                              : 'tw-text-gray-250',
                          ]"
                          class="tw-font-robotoBold tw-text-xs md:tw-text-lg tw-m-0"
                        >
                          {{
                            hour.start_time_short + " - " + hour.end_time_short
                          }}
                        </p>
                        <p
                          :class="
                            hour.available
                              ? 'tw-text-gray-500'
                              : 'tw-text-gray-250'
                          "
                          class="tw-font-roboto tw-text-xxs tw-m-0 tw-block md:tw-hidden tw-text-center"
                        >
                          {{
                            reservationData.selectedDay &&
                            reservationData.selectedDay.date +
                              " de " +
                              reservationData.selectedDay.month +
                              " de " +
                              reservationData.selectedDay.year
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tw-hidden md:tw-grid md:tw-grid-cols-2 md:tw-gap-10 md:tw-mt-5"
            >
              <div
                class="md:tw-grid md:tw-grid-cols-col2_fit_first md:tw-gap-6 tw-hidden"
              >
                <div
                  class="tw-w-14 tw-h-14 tw-rounded-full tw-border tw-border-gray-450 tw-flex tw-items-center tw-justify-center"
                >
                  <p class="tw-m-0 tw-text-xl tw-text-gray-450">1</p>
                </div>
                <div class="tw-flex tw-items-center">
                  <p
                    class="tw-m-0 tw-font-robotoBold tw-text-lg tw-text-gray-450"
                  >
                    Disponible
                  </p>
                </div>
              </div>
              <div
                class="md:tw-grid md:tw-grid-cols-col2_fit_first md:tw-gap-6 tw-hidden"
              >
                <div
                  class="tw-w-14 tw-h-14 tw-rounded-full tw-border tw-border-gray-250 tw-flex tw-items-center tw-justify-center"
                >
                  <p class="tw-m-0 tw-text-xl tw-text-gray-200">1</p>
                </div>
                <div class="tw-flex tw-items-center">
                  <p
                    class="tw-m-0 tw-font-robotoBold tw-text-lg tw-text-gray-450"
                  >
                    No disponible
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Booking Step 3 -->
          <div v-show="bookingStep === 3" class="tw-flex tw-flex-col tw-gap-5">
            <div
              class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-6"
              v-if="
                reservationsStore.bookingPlaceDetail.with_table_organization ===
                1
              "
            >
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                Selecciona la organización de las mesas
              </p>
            </div>
            <div
              class="tw-grid tw-grid-cols-2 tw-gap-5 md:tw-gap-10"
              v-if="
                reservationsStore.bookingPlaceDetail.with_table_organization ===
                1
              "
            >
              <div
                class="tw-border tw-border-black tw-rounded-3xl tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-p-3 md:tw-p-6 tw-relative"
                v-for="(item, idx) in tables"
                :key="idx"
                @click.prevent="
                  updateTableName(item.id, item.reference, item.name)
                "
              >
                <div
                  class="tw-absolute tw-top-2 tw-right-3 lg:tw-top-3 lg:tw-right-4"
                >
                  <input
                    :id="item.id"
                    :value="item.reference"
                    name="table_organization"
                    class="tw-text-red-350 focus:tw-ring-transparent tw-w-4 tw-h-4 lg:tw-w-5 lg:tw-h-5"
                    type="radio"
                    v-model="reservationData.table_organization.reference"
                  />
                </div>
                <img :src="item.img" alt="" />
                <p class="tw-font-robotoBold tw-text-xs md:tw-text-lg tw-m-0">
                  {{ item.name }}
                </p>
              </div>
            </div>
            <hr
              class="tw-w-full"
              v-if="
                reservationsStore.bookingPlaceDetail.with_table_organization ===
                1
              "
            />
            <div
              class="tw-flex tw-items-center tw-justify-between tw-gap-3 md:tw-gap-6"
              v-if="reservationData.hours.length > 0"
            >
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                ¿Cuántas personas van a asistir?
              </p>
              <div>
                <CompGeneralDropdown
                  :is-label="false"
                  :options="peopleCapacityNumber"
                  name="peopleNumber"
                  single-array-mode
                  @changeSelectedOption="setPeopleCapacity"
                  @chargeOption="setPeopleCapacity"
                />
              </div>
            </div>
            <!--            <div class="tw-flex tw-flex-col tw-gap-5 tw-mt-2 md:tw-mt-5">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                ¿Necesitas proyector?
              </p>
              <div class="tw-grid tw-grid-cols-3">
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <input
                    id="radio-1"
                    v-model="reservationData.require_projector"
                    class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
                    name="proyector"
                    type="radio"
                    value="1"
                  />
                  <label class="tw-m-0 tw-text-xs md:tw-text-lg" for="radio-1"
                    >Si</label
                  >
                </div>
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <input
                    id="radio-2"
                    v-model="reservationData.require_projector"
                    class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
                    name="proyector"
                    type="radio"
                    value="0"
                  />
                  <label class="tw-m-0 tw-text-xs md:tw-text-lg" for="radio-2"
                    >No</label
                  >
                </div>
              </div>
            </div>-->
            <hr class="tw-w-full" />
            <div class="tw-flex tw-flex-col tw-gap-5">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                ¿Las personas que ingresan son externas a Bavaria?
              </p>
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
                Para permitir el ingreso de personas externas a la compañía
                necesitamos unos datos.
              </p>
              <div class="tw-grid tw-grid-cols-3">
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <input
                    id="radio-3"
                    v-model="reservationData.outside_people"
                    class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
                    name="personas"
                    type="radio"
                    value="1"
                    @change="addGuest()"
                  />
                  <label class="tw-m-0 tw-text-xs md:tw-text-lg" for="radio-3"
                    >Si</label
                  >
                </div>
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <input
                    id="radio-4"
                    v-model="reservationData.outside_people"
                    class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
                    name="personas"
                    type="radio"
                    value="0"
                    @change="removeGuests()"
                  />
                  <label class="tw-m-0 tw-text-xs md:tw-text-lg" for="radio-4"
                    >No</label
                  >
                </div>
              </div>
            </div>
            <hr class="tw-w-full" />
            <div class="tw-flex tw-flex-col tw-gap-5">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                ¿Vas a hacer algún montaje?
              </p>
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
                Recuerda que el montaje va por tu cuenta y debes dejar el lugar
                como lo encontraste.
              </p>
              <div class="tw-grid tw-grid-cols-3">
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <input
                    id="radio-5"
                    v-model="reservationData.montage"
                    class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
                    name="montaje"
                    type="radio"
                    value="1"
                  />
                  <label class="tw-m-0 tw-text-xs md:tw-text-lg" for="radio-5"
                    >Si</label
                  >
                </div>
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <input
                    id="radio-6"
                    v-model="reservationData.montage"
                    class="tw-text-red-350 tw-border tw-border-gray-600 md:tw-w-5 md:tw-h-5 focus:tw-ring-transparent"
                    name="montaje"
                    type="radio"
                    value="0"
                  />
                  <label class="tw-m-0 tw-text-xs md:tw-text-lg" for="radio-6"
                    >No</label
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Booking Step 4 -->
          <div
            v-show="
              bookingStep === 4 &&
              parseInt(reservationData.outside_people) === 1
            "
            class="tw-flex tw-flex-col tw-gap-5 md:tw-gap-8"
          >
            <div class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-6">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                Cantidad de personas
              </p>
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Selecciona la cantidad de personas que van a ingresar al espacio
              </p>
            </div>
            <!-- toggle switch -->
            <div
              class="tw-rounded-full tw-w-full tw-h-10 md:tw-h-12 tw-border tw-border-black tw-relative tw-cursor-pointer"
              @click="changeSwitch()"
            >
              <div
                :class="
                  sliderSelect ? 'tw-translate-x-0' : 'tw-translate-x-full'
                "
                class="tw-w-6/12 tw-rounded-full tw-border-4 tw-border-red-350 tw-h-full tw-bg-red-350 tw-transform tw-transition-transform tw-duration-500 tw-absolute tw-z-1"
              ></div>
              <div
                class="tw-grid tw-grid-cols-2 tw-w-full tw-h-full tw-text-center tw-absolute tw-z-10"
              >
                <div class="tw-flex tw-items-center tw-justify-center">
                  <p
                    :class="sliderSelect ? 'tw-text-white' : 'tw-text-gray-600'"
                    class="tw-m-0 tw-font-roboto tw-text-sm md:tw-text-base tw-transform tw-transition-all tw-duration-700"
                  >
                    Hasta 5
                  </p>
                </div>
                <div class="tw-flex tw-items-center tw-justify-center">
                  <p
                    :class="sliderSelect ? 'tw-text-gray-600' : 'tw-text-white'"
                    class="tw-m-0 tw-font-roboto tw-text-sm md:tw-text-base tw-transform tw-transition-all tw-duration-700"
                  >
                    Más de 5
                  </p>
                </div>
              </div>
            </div>
            <div
              v-show="sliderSelect"
              id="guestsInfo"
              class="tw-flex tw-flex-col tw-gap-5 md:tw-gap-8"
            >
              <div
                v-for="(_, idx) in reservationData.guestsNumber"
                :key="idx"
                class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-5"
              >
                <p
                  class="tw-font-robotoBold tw-text-base md:tw-text-2xl tw-m-0"
                >
                  Acompañante nº {{ idx + 1 }}
                </p>
                <div>
                  <label
                    class="tw-font-roboto tw-text-xs md:tw-text-lg"
                    for="adultName"
                    >Nombre/s</label
                  >
                  <input
                    :id="'adultName' + idx"
                    v-model="reservationData.guests[idx].name"
                    class="tw-form-input"
                    name="adultName"
                    placeholder="Ingresar datos..."
                    type="text"
                  />
                </div>
                <div>
                  <label
                    class="tw-font-roboto tw-text-xs md:tw-text-lg"
                    for="adultLastName"
                    >Apellidos</label
                  >
                  <input
                    :id="'adultLastName' + idx"
                    v-model="reservationData.guests[idx].surname"
                    class="tw-form-input"
                    name="adultLastName"
                    placeholder="Ingresar datos..."
                    type="text"
                  />
                </div>
                <div class="tw-grid tw-grid-cols-col2_fit_first tw-gap-6">
                  <div>
                    <label
                      class="tw-font-roboto tw-text-xs md:tw-text-lg"
                      for="adultDocument_type"
                      >Tipo de documento</label
                    >
                    <CompGeneralDropdown
                      :fitted="false"
                      :isLabel="false"
                      :options="[
                        {
                          id: 0,
                          name: 'CC',
                        },
                        {
                          id: 1,
                          name: 'CE',
                        },
                        {
                          id: 2,
                          name: 'TI',
                        },
                        {
                          id: 3,
                          name: 'Pasaporte',
                        },
                      ]"
                      name="adultDocument_type"
                      @changeSelectedOption="
                        (option) =>
                          updateAdult(option.name, 'document_type', idx)
                      "
                      @chargeOption="
                        (option) =>
                          updateAdult(option.name, 'document_type', idx)
                      "
                    />
                  </div>
                  <div>
                    <label
                      class="tw-font-roboto tw-text-xs md:tw-text-lg"
                      for="adultId"
                      >Documento de identidad</label
                    >
                    <input
                      :id="'adultId' + idx"
                      v-model="reservationData.guests[idx].document_id"
                      class="tw-form-input"
                      name="adultId"
                      placeholder="Ingresar datos..."
                      type="text"
                    />
                  </div>
                </div>
                <div>
                  <label
                    class="tw-font-roboto tw-text-xs md:tw-text-lg"
                    for="adultEPS"
                    >EPS</label
                  >
                  <input
                    :id="'adultEPS' + idx"
                    v-model="reservationData.guests[idx].eps"
                    class="tw-form-input"
                    name="adultEPS"
                    placeholder="Ingresar datos..."
                    type="text"
                  />
                </div>
                <hr class="tw-w-full" />
              </div>
              <p
                v-show="reservationData.guestsNumber > 1"
                class="text-center"
                @click="decrementGuest()"
              >
                Eliminar acompañante
              </p>
              <div
                v-show="reservationData.guestsNumber < 6"
                class="tw-w-full tw-grid tw-grid-cols-col2_fit_first tw-rounded-2xl tw-gap-5 tw-bg-red-350 tw-px-5 tw-py-4 tw-cursor-pointer"
                @click="addGuest()"
              >
                <i
                  class="icon-agregar tw-text-3xl md:tw-text-5xl tw-text-white"
                ></i>
                <div class="tw-flex tw-items-center tw-justify-center">
                  <p
                    class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg tw-text-white"
                  >
                    Agregar nuevo acompañante
                  </p>
                </div>
              </div>
            </div>
            <div
              v-show="!sliderSelect"
              id="fileUpload"
              class="tw-flex tw-flex-col tw-gap-5 md:tw-gap-8"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Para facilitar los datos de las personas, necesitamos que
                descargues un archivo excel, sea completado con la información
                de las personas y subirlo nuevamente a la plataforma
              </p>
              <div
                class="tw-grid tw-grid-cols-2 tw-gap-5 md:tw-gap-10 md:tw-mb-10"
              >
                <div class="tw-flex tw-flex-col tw-gap-5 tw-items-center">
                  <p
                    class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl"
                  >
                    Descargar archivo
                  </p>
                  <div
                    class="tw-w-min tw-rounded-2xl tw-border tw-border-gray-300 tw-p-2 md:tw-p-6 tw-flex tw-justify-center"
                  >
                    <img alt="" src="@/assets/img/reservations/file.svg" />
                  </div>
                  <div
                    class="tw-btn-secondary-white tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer"
                    @click="downloadGuestFile('REGISTRO_INGRESO_EXTERNOS')"
                  >
                    <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                      Descargar
                    </p>
                  </div>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-5 tw-items-center">
                  <p
                    class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl"
                  >
                    Subir archivo
                  </p>
                  <div
                    class="tw-w-min tw-rounded-2xl tw-border tw-border-gray-300 tw-p-2 md:tw-p-6 tw-flex tw-justify-center"
                  >
                    <img alt="" src="@/assets/img/reservations/file.svg" />
                  </div>
                  <div
                    class="tw-btn-primary-red tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer"
                    @click="uploadGuestFile()"
                  >
                    <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                      Subir
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Booking Step 5 -->
          <div
            v-show="
              bookingStep === 5 && parseInt(reservationData.montage) === 1
            "
            class="tw-flex tw-flex-col tw-gap-5 md:tw-gap-8"
          >
            <div class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-6">
              <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
                Ingresa los datos del montaje
              </p>
              <textarea
                v-model="reservationData.montage_description"
                class="tw-h-32 md:tw-h-48 tw-w-full tw-rounded-3xl tw-border tw-border-gray-450 tw-resize-none focus:tw-outline-none focus:tw-border-gray-450 focus:tw-ring-transparent"
              ></textarea>
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Estos datos serán enviados a la persona encargada, y
                posteriormente se te pedirán los documentos respectivos a través
                de tu correo electrónico.
              </p>
            </div>
          </div>

          <!-- Buttons -->
          <div
            id="buttons"
            class="tw-grid tw-grid-cols-2 tw-gap-3 tw-mt-2 md:tw-mt-4"
          >
            <div
              class="tw-btn-secondary-white tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer"
              @click="cancel()"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Cancelar
              </p>
            </div>
            <div
              :class="
                validateSteps
                  ? 'tw-btn-primary-red tw-cursor-pointer'
                  : 'tw-btn-primary-disabled'
              "
              class="tw-btn-primary-red tw-flex tw-justify-center tw-items-center tw-py-2"
              @click="validateSteps ? nextStep() : null"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Siguiente
              </p>
            </div>
          </div>
        </div>
        <div id="spaceInfo" class="tw-hidden md:tw-block">
          <div
            class="tw-border tw-border-gray-250 tw-rounded-4xl tw-w-full tw-p-10 tw-flex tw-flex-col tw-gap-5"
          >
            <div id="spaceTitle" class="tw-flex tw-flex-col tw-gap-3">
              <p class="tw-m-0 tw-font-robotoBold tw-text-2xl">
                Información de la reserva
              </p>
              <p class="tw-font-robotoBold tw-text-4xl">
                {{ reservationsStore.bookingPlaceDetail.name }}
              </p>
              <div id="photoAndPrice">
                <div
                  class="tw-aspect-w-16 tw-aspect-h-8 tw-rounded-2.5xl tw-overflow-hidden"
                >
                  <img
                    :src="uriResources + '/' + mainImg"
                    alt=""
                    class="tw-h-full tw-object-cover tw-select-none"
                  />
                </div>
              </div>
            </div>

            <div v-show="bookingStep === 1" id="placeDescription">
              <p class="tw-m-0 tw-font-roboto tw-text-lg">
                {{ reservationsStore.bookingPlaceDetail.description }}
              </p>
            </div>
            <div v-show="bookingStep >= 2" id="chosenCharacteristics">
              <hr class="tw-w-full tw-my-5" />
              <div v-show="bookingStep >= 2">
                <div
                  id="chosenCapacity"
                  class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
                >
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
                    >
                      Capacidad
                    </p>
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400"
                    >
                      {{
                        reservationData.capacity.capacity > 0
                          ? reservationData.capacity.capacity + " personas"
                          : ""
                      }}
                    </p>
                  </div>
                  <div class="tw-cursor-pointer" @click="changeStep(1)">
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-underline tw-text-red-350 tw-text-right"
                    >
                      Cambiar
                    </p>
                  </div>
                </div>
                <hr class="tw-w-full tw-my-5" />
              </div>
              <div v-show="bookingStep >= 2">
                <div
                  id="selectedDay"
                  class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
                >
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
                    >
                      Día
                    </p>
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400"
                    >
                      {{
                        reservationData.selectedDay &&
                        reservationData.selectedDay.date +
                          " de " +
                          reservationData.selectedDay.month
                      }}
                    </p>
                  </div>
                  <div class="tw-cursor-pointer" @click="changeStep(1)">
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-underline tw-text-red-350 tw-text-right"
                    >
                      Cambiar
                    </p>
                  </div>
                </div>
                <hr class="tw-w-full tw-mt-5" />
              </div>
              <div v-show="bookingStep >= 3">
                <div
                  id="chosenHour"
                  class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
                >
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
                    >
                      Hora
                    </p>
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400"
                    >
                      {{
                        reservationData.hours.length > 0 &&
                        `${reservationData.hours[0].start_time_short} ${
                          reservationData.hours[0].start_timing
                        } - ${
                          reservationData.hours[
                            reservationData.hours.length - 1
                          ].end_time_short
                        } ${
                          reservationData.hours[
                            reservationData.hours.length - 1
                          ].end_timing
                        }`
                      }}
                    </p>
                  </div>
                  <div class="tw-cursor-pointer" @click="changeStep(2)">
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-underline tw-text-red-350 tw-text-right"
                    >
                      Cambiar
                    </p>
                  </div>
                </div>
                <hr class="tw-w-full tw-mt-5" />
              </div>
              <div v-show="bookingStep >= 4">
                <div
                  id="table_organization"
                  class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
                >
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
                    >
                      Organización
                    </p>
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400"
                    >
                      {{ reservationData.table_organization.name }}
                    </p>
                  </div>
                  <div class="tw-cursor-pointer" @click="changeStep(3)">
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-underline tw-text-red-350 tw-text-right"
                    >
                      Cambiar
                    </p>
                  </div>
                </div>
                <hr class="tw-w-full tw-mt-5" />
              </div>
              <!--
              <div v-show="bookingStep >= 4">
                <div
                  id="projector"
                  class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
                >
                  <div>
                    <p
                      class="
                                    tw-m-0
                                    tw-font-roboto
                                    tw-font-bold
                                    tw-text-xl
                                    tw-text-gray-400
                                  "
                    >
                      Proyector
                    </p>
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400"
                    >
                      {{
                        parseInt(reservationData.require_projector) === 1
                          ? "Si"
                          : "No"
                      }}
                    </p>
                  </div>
                  <div class="tw-cursor-pointer" @click="changeStep(3)">
                    <p
                      class="
                                    tw-m-0
                                    tw-font-roboto
                                    tw-text-lg
                                    tw-underline
                                    tw-text-red-350
                                    tw-text-right
                                  "
                    >
                      Cambiar
                    </p>
                  </div>
                </div>
                <hr class="tw-w-full tw-mt-5" />
              </div>
-->
              <div v-show="bookingStep >= 4">
                <div
                  id="montage"
                  class="tw-grid tw-grid-cols-3 tw-gap-3 md:tw-gap-5 tw-px-6"
                >
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-font-bold tw-text-xl tw-text-gray-400"
                    >
                      Montaje
                    </p>
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-text-gray-400"
                    >
                      {{
                        parseInt(reservationData.montage) === 1 ? "Si" : "No"
                      }}
                    </p>
                  </div>
                  <div class="tw-cursor-pointer" @click="changeStep(3)">
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-lg tw-underline tw-text-red-350 tw-text-right"
                    >
                      Cambiar
                    </p>
                  </div>
                </div>
                <hr class="tw-w-full tw-mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 1.2rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.2rem;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #fa2727;
  border-color: #fa2727;
}

.round input[type="checkbox"]:disabled + label {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.round input[type="checkbox"]:disabled + label:after {
  opacity: 1;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #fa2727;
}
</style>
