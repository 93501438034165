<script setup>
import config from "@/services/config";
import { onBeforeMount, ref } from "vue";
import { useEventsStore } from "@/stores/eventsStore";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router/composables";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import {
  formatDateDDMMYYYY,
  formatTimeToAMPM,
  isPastDate,
} from "@/helpers/datesHelpers";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";

const uriResources = config.beerOfficeURL;
const route = useRoute();
const eventsStore = useEventsStore();
const isModalOpen = ref(false);
const registerSuccess = ref(false);
const responseMessage = ref("");
const { actGetEventById, actPostEventRegister } = eventsStore;
const { event } = storeToRefs(eventsStore);

const eventRegister = async () => {
  const response = await actPostEventRegister(event.value.id);
  if (response.code && response.code === "ERR_BAD_REQUEST") {
    registerSuccess.value = false;
    responseMessage.value = response.response.data.message;
  } else {
    registerSuccess.value = true;
    responseMessage.value = response.message;
  }
  openModal();
};

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
  getEventById(route.params.id);
};

const getEventById = async (id) => {
  await actGetEventById(id);
};

onBeforeMount(async () => {
  await getEventById(route.params.id);
});
</script>

<template>
  <div>
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 500, lg: 600, xl: 700 }"
      modal-name="eventRegister"
      v-if="isModalOpen"
      :isShowModal="isModalOpen"
      @close="closeModal"
    >
      <div
        class="tw-pt-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-4"
        v-if="registerSuccess"
      >
        <div class="tw-flex tw-items-center tw-gap-4 lg:tw-gap-6">
          <img
            src="@/assets/img/modal/modal-success.svg"
            alt=""
            class="tw-h-14 lg:tw-h-20"
          />
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-green-400"
          >
            LISTO
          </p>
        </div>
        <p
          class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
        >
          {{ responseMessage }}
        </p>
      </div>
      <div
        class="tw-pt-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-4"
        v-else-if="!registerSuccess"
      >
        <div class="tw-flex tw-items-center tw-gap-4 lg:tw-gap-6">
          <img
            src="@/assets/img/modal/modal-error.svg"
            alt=""
            class="tw-h-14 lg:tw-h-20"
          />
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
          >
            Lo sentimos
          </p>
        </div>
        <p
          class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-w-8/12 tw-text-center"
        >
          {{ responseMessage }}
        </p>
      </div>
      <comp-button-primary-red title="Cerrar" @click.native="closeModal" />
    </general-modal>
    <mobile-bar title="Horas de vuelo" />
    <title-bar title="Horas de vuelo" />
    <main class="tw-container-general">
      <div
        v-if="event.registered_user"
        class="tw-py-3 tw-bg-pink-100 tw-w-full"
      >
        <p class="tw-m-0 tw-text-center tw-font-robotoBold">
          ¡Ya te encuentras registrado en
          <span class="tw-text-red-350">este evento!</span>
        </p>
      </div>
      <section
        id="event-card"
        class="tw-mt-5 tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-grid-rows-rows_2_fit_first tw-gap-4 lg:tw-gap-8 tw-border tw-border-gray-300 tw-rounded-2xl tw-p-2 lg:tw-p-4"
      >
        <div class="tw-row-span-2 tw-order-2 lg:tw-order-1">
          <img
            :src="`${uriResources}/${event.resource}`"
            alt=""
            class="tw-aspect-[16/9] tw-w-full tw-h-full tw-object-cover tw-rounded-2xl"
          />
        </div>
        <div class="tw-row-span-1 tw-order-1 lg:tw-order-2">
          <p class="tw-m-0 tw-font-robotoBold tw-text-lg tw-text-center">
            {{ event.name }}
          </p>
        </div>
        <div
          class="tw-row-span-1 tw-flex tw-flex-col tw-gap-2 tw-justify-between tw-order-3"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
            {{ event.desc }}
          </p>
          <div class="tw-flex tw-justify-around tw-items-center">
            <p class="tw-m-0 tw-font-roboto tw-text-sm">
              <span class="tw-font-robotoBold">Fecha: </span>
              {{ formatDateDDMMYYYY(event.date, "-") }}
            </p>
            <p class="tw-m-0 tw-font-roboto tw-text-sm">
              <span class="tw-font-robotoBold">Hora: </span>
              {{ formatTimeToAMPM(event.start_time) }}
            </p>
          </div>
          <p class="tw-m-0 tw-font-roboto tw-text-sm tw-text-red-350">
            Cupos disponibles: {{ event.places_available }}
          </p>
          <div class="tw-grid tw-grid-cols-2 tw-gap-3 lg:tw-gap-4">
            <comp-button-custom text="Cerrar" />
            <comp-button-primary-red
              title="Inscribirme"
              @click.native="eventRegister"
              :disabled="isPastDate(event.start_date)"
            />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
