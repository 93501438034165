<template>
  <div class="tw-container-general">
    <ButtonsPeopleCycle
      class="py-3 mb-2 mb-md-4"
      :peopleCycleButtonsActive="0"
    />
    <PCNavigate :pc_item="pc_item" />
    <!-- ALL CONTENT -->
    <div class="container-md pb-5" id="general-wrapper">
      <div class="mb-5" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="../../assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div v-else>
        <div class="tw-shadow-lg tw-rounded-lg d-flex flex-column p-0">
          <!-- People Cycle Title and Description-->
          <div
            class="tw-shadow-lg tw-rounded-lg he-200"
            :style="'background-color: ' + peopleCycleItem.color"
          >
            <div class="container h-100 d-flex align-items-md-center">
              <div class="row align-items-center px-3 px-md">
                <div class="col-3 col-md-2 text-center order-2 order-md-1">
                  <img
                    :src="uriResources + '/' + peopleCycleItem.icon"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  class="col-9 col-md-4 text-uppercase tw-font-tolyerBold text-white p-0 order-1 order-md-2"
                >
                  <p
                    class="fs-44 fs-md-70 line-height-40 line-height-md-60 p-0 m-0"
                  >
                    {{ peopleCycleItem.title }}
                  </p>
                </div>

                <div class="col-12 col-md-5 order-3 p-0">
                  <p
                    class="text-white tw-font-robotoLight tw-text-xs fs-md-20 line-height-18 line-height-md-24 text-md-right pl-md-4"
                  >
                    {{ peopleCycleItem.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-3 px-md-5 pt-5">
            <div class="text-center position-relative">
              <img
                :src="uriResources + '/' + peopleCycleItem.image"
                class="img-fluid"
                alt=""
              />
              <img
                src="@/assets/img/peopleCycle/cc_icon_pc.png"
                class="h-10 h-md-100 tag"
                alt=""
              />
            </div>
            <div>
              <div
                class="row justify-content-center"
                v-if="peopleCycleItem.sub_title !== null"
              >
                <div
                  class="col-md-8 text-center position-relative px-3 px-md-5 pt-5"
                >
                  <p class="tw-font-robotoBold fs-md-28">
                    {{ peopleCycleItem.sub_title }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                class="row justify-content-center"
                v-if="peopleCycleItem.sub_description !== null"
              >
                <div
                  class="col-md-8 text-center position-relative px-3 px-md-5 pt-2"
                >
                  <p class="tw-font-roboto fs-md-20 line-height-md-24">
                    {{ peopleCycleItem.sub_description }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="row justify-content-md-center justify-content-between px-3 px-md-5 py-4 py-md-5"
              v-if="peopleCycleItem.people_cycle_sub_item.length > 0"
            >
              <div
                class="wi-90 wi-md-170 he-130 he-md-200 m-1 m-md-3 tw-rounded-lg shadow-box-6 bg-white p-1 p-md-3"
                v-for="item in peopleCycleItem.people_cycle_sub_item"
                :key="item.id"
              >
                <div class="overflow-hidden text-center">
                  <img
                    :src="uriResources + '/' + item.icon"
                    class="img-fluid p-2"
                    alt=""
                  />
                  <p
                    class="tw-font-roboto tw-text-xxs fs-md-16 tw-font-bold py-2 m-0"
                  >
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabPeople
          :items="peopleCycleItem.indicator"
          v-if="peopleCycleItem.indicator"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsPeopleCycle from "@/components/general/ButtonsPeopleCycle";
import PCNavigate from "@/components/general/PCNavigate";
import TabPeople from "@/components/people/tabs/TabPeople";

import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import cvMixin from "@/mixins/user/cv";
import people from "@/mixins/user/people";

export default {
  name: "PeopleCycle",
  mixins: [cvMixin, people],
  components: {
    ButtonsPeopleCycle,
    PCNavigate,
    TabPeople,
  },
  data() {
    return {
      isLoading: true,
      uriResources: config.beerOfficeURL,
      pc_item: 1,
    };
  },
  async created() {
    this.isLoading = true;

    this.actGetPeopleCycleItem({
      id: 1,
    })
      .then(() => {})
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modPeopleCycle", ["peopleCycleItem"]),
  },
  methods: {
    ...mapActions("modPeopleCycle", ["actGetPeopleCycleItem"]),
  },
};
</script>

<style scoped>
.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>
