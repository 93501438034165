<template>
  <div class="tw-shadow-md tw-rounded-lg mb-3 h-100 pt-3">
    <div class="row">
      <div class="col-12 col-lg-4 pr-3 pr-md-0">
        <div class="d-flex flex-row flex-md-column p-2 align-items-center">
          <img
            :src="
              movement.core_employee_prof_picture == null
                ? this.imgAvatar
                : movement.core_employee_prof_picture
            "
            width="90"
            height="90"
            alt="user"
            class="tw-rounded-full tw-object-cover"
          />
          <p
            class="
              text-uppercase tw-text-gray-600
              mb-2
              tw-text-base fs-md-10
              text-center
              font-weight-bold
              mt-3
            "
          >
            {{ movement.core_employee_name }}
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <div
          class="
            row row-cols-2
            tw-shadow-md-quit
            mr-0
            ml-0
            h-100
            tw-rounded-lg
            py-2
          "
        >
          <div class="col tw-border-r tw-border-solid tw-border-gray-200  d-flex flex-column justify-content-center">
            <div class="mb-2">
              <i class="icon-camion tw-text-gray-300 fs-30 fs-md-40"></i>
            </div>
            <p class=" tw-text-xs  tw-text-gray-300 mb-2">Cargo anterior</p>
            <p class="tw-text-sm tw-text-gray-300 mb-2 text-uppercase font-weight-bold">
              {{ movement.previous_position_name }}
            </p>
            <p class=" tw-text-xs  tw-text-gray-300 mb-1">
              {{ movement.previous_site_name }}
            </p>
          </div>
          <div class="col pr-md-2 d-flex flex-column justify-content-center">
            <div class="mb-2">
              <i class="icon-usuarios tw-text-green-400 fs-30 fs-md-40"></i>
            </div>
            <p class=" tw-text-xs  tw-text-green-400 mb-2">Cargo actual</p>
            <p
              class="tw-text-sm tw-text-green-400 mb-2 text-uppercase font-weight-bold"
            >
              {{ movement.actual_position_name }}
            </p>
            <p class=" tw-text-xxs  tw-text-green-400 mb-1">
              {{ movement.actual_site_name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovementComponent",
  props: {
    movement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgAvatar: require("@/assets/img/contacts/avatar-empty.png"),
    };
  },
};
</script>
