<script setup>
import { capitalizeEachWord } from "@/helpers/textHelpers";
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import { useGoFlexRequestTable } from "@/composables/goFlex/useGoFlexRequestTable";

import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";

const props = defineProps({
  teamRequests: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["approve", "reject"]);

const {
  selectedItems,
  pendingRequestsLength,
  catalogOption,
  statusColor,
  avatarImage,
  toggleSelectAllItems,
  toggleSelectItem,
} = useGoFlexRequestTable(props.teamRequests);
</script>

<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-justify-between tw-border tw-border-black tw-rounded-2.5xl tw-overflow-hidden"
    >
      <div class="tw-bg-red-350 tw-py-1">
        <p class="tw-m-0 tw-text-center tw-text-white">Solicitudes</p>
      </div>
      <div
        class="tw-p-2 tw-flex tw-flex-col tw-gap-3 tw-h-[400px] tw-overflow-y-auto hidden-scrollbar"
      >
        <div
          v-for="(item, idx) in props.teamRequests"
          :key="idx"
          class="tw-w-full tw-rounded-2.5xl tw-border tw-border-gray-300 tw-py-2 tw-px-4 tw-grid tw-grid-cols-col3_fit_first_and_last tw-gap-2"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <input
              :disabled="
                item.status === 'APPROVED' || item.status === 'REJECTED'
              "
              :checked="
                item.status === 'APPROVED' ||
                item.status === 'REJECTED' ||
                selectedItems.includes(item.request_id)
              "
              required
              type="checkbox"
              class="tw-w-6 tw-h-6 tw-border tw-border-solid tw-border-gray-300 tw-rounded-md focus:tw-ring-transparent tw-shadow-sm"
              :class="
                item.status === 'APPROVED' || item.status === 'REJECTED'
                  ? 'tw-cursor-not-allowed tw-text-gray-400'
                  : 'tw-cursor-pointer tw-text-green-450'
              "
              @click="toggleSelectItem(item.request_id)"
            />
            <img
              :src="avatarImage(item)"
              class="tw-object-cover tw-w-12 tw-h-12 tw-rounded-full tw-border-2 tw-border-gray-300"
              alt=""
            />
          </div>
          <div class="tw-flex tw-flex-col tw-justify-center">
            <span class="tw-line-clamp-2 tw-font-robotoMedium tw-text-xs">{{
              capitalizeEachWord(item.core_employee_name)
            }}</span>
            <span class="tw-text-xs tw-text-gray-400">{{
              catalogOption[item.option_catalog]
            }}</span>
            <span class="tw-text-xs tw-text-gray-400">
              {{ item.option_name }} </span
            ><span class="tw-text-xs tw-text-gray-400">{{
              formatDateDDMMYYYY(item.requested_date, "/", true)
            }}</span>
          </div>
          <div class="tw-flex tw-items-center">
            <div
              :class="
                'tw-h-1/2 tw-aspect-1 tw-rounded-full tw-bg-' +
                statusColor(item.status)
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-flex tw-flex-col tw-gap-2 tw-w-full tw-border tw-border-black tw-rounded-2.5xl tw-p-3 tw-mt-3"
    >
      <p class="tw-m-0 tw-text-end tw-text-xs">
        {{ `has seleccionado (${selectedItems.length}) solicitudes` }}
      </p>
      <div class="tw-flex tw-justify-between tw-items-center tw-gap-2">
        <div>
          <comp-button-custom
            class="tw-w-full"
            :text="
              selectedItems.length === pendingRequestsLength
                ? 'Deseleccionar todo'
                : 'Seleccionar todo'
            "
            color="white"
            @click.native="toggleSelectAllItems"
          />
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
          <comp-button-custom-color
            :disabled="selectedItems.length === 0"
            class="tw-w-max"
            text="Aprobar"
            color="green-450"
            text-color="white"
            @click.native="emit('approve', selectedItems)"
          />
          <comp-button-custom-color
            :disabled="selectedItems.length === 0"
            class="tw-w-max"
            text="Rechazar"
            color="red-350"
            text-color="white"
            @click.native="emit('reject', selectedItems)"
          />
        </div>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-2 tw-mt-4">
      <div class="tw-flex tw-items-center tw-gap-2">
        <div class="tw-h-6 tw-aspect-1 tw-rounded-full tw-bg-yellow-300"></div>
        <span class="tw-font-roboto tw-text-sm">Pendientes</span>
      </div>
      <div class="tw-flex tw-items-center tw-gap-2">
        <div class="tw-h-6 tw-aspect-1 tw-rounded-full tw-bg-green-450"></div>
        <span class="tw-font-roboto tw-text-sm">Aprobadas</span>
      </div>
      <div class="tw-flex tw-items-center tw-gap-2">
        <div class="tw-h-6 tw-aspect-1 tw-rounded-full tw-bg-red-350"></div>
        <span class="tw-font-roboto tw-text-sm">Rechazadas</span>
      </div>
    </div>
  </section>
</template>

<style scoped>
.hidden-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
