<script setup>
import { computed, onBeforeMount, ref } from "vue";
import config from "@/services/config";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useReservationsStore } from "@/stores/reservationsStore";
import { useRoute, useRouter } from "vue-router/composables";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompReservationGalleryMasonry from "@/components/reservations/CompReservationGalleryMasonry";
import CompCapacity from "@/components/reservations/CompCapacity";
import CompCheckIn from "@/components/reservations/CompCheckIn";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const route = useRoute();
const router = useRouter();
const reservationsStore = useReservationsStore();
const isLoading = ref(false);
const uriResources = config.beerOfficeURL;
const { openBookingModal } = reservationsStore;

const listItems = computed(() => {
  return reservationsStore.bookingPlaceDetail.details.filter((item) => {
    return item.type === "LIST";
  });
});

const mainImg = computed(() => {
  return reservationsStore.bookingPlaceDetail.photos.filter(
    (item) => item.is_main === true
  )[0].image;
});

const book = () => {
  pushDataLayer({
    event_category: "Reserva de Espacios",
    event_action: reservationsStore.bookingPlaceDetail.category.name,
    event_label: reservationsStore.bookingPlaceDetail.name,
    component_name: "Reserve",
  });
  reservationsStore.bookingPlaceDetail.booking_place_category_id === 1
    ? openBookingModal()
    : router.push({
        name: "Book127",
        params: {
          id: route.params.id,
          place: { ...reservationsStore.bookingPlaceDetail },
        },
      });
};

onBeforeMount(async () => {
  isLoading.value = true;
  await Promise.all([
    reservationsStore.actGetBookingPlaceDetail(route.params.id),
    reservationsStore.actGetBookingActive(),
  ]);
  isLoading.value = false;
});
</script>
<template>
  <div v-if="!isLoading" class="tw-relative">
    <mobile-bar :title="reservationsStore.bookingPlaceDetail?.category?.name" />
    <title-bar :title="reservationsStore.bookingPlaceDetail?.category?.name" />
    <div
      id="activeBookingDisclaimer"
      class="md:tw-my-5 tw-bg-pink-100"
      v-if="
        reservationsStore.bookingActive &&
        reservationsStore.bookingPlaceDetail.booking_place_category_id === 1
      "
    >
      <div class="tw-container-general tw-py-5">
        <div class="tw-grid tw-grid-cols-col2_fit_first tw-gap-4 lg:tw-gap-10">
          <div class="tw-flex tw-items-center">
            <i class="icon-lot tw-text-red-350 tw-text-5xl md:tw-text-7xl"></i>
          </div>
          <div
            class="tw-flex tw-flex-col lg:tw-grid lg:tw-grid-cols-12 tw-items-center tw-justify-between tw-gap-2 lg:tw-gap-10"
          >
            <p
              class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base lg:tw-col-span-8"
            >
              Tienes una reserva activa, no puedes realizar otra hasta que
              canceles o se complete tu reserva.
            </p>
            <div
              class="tw-btn-primary-red tw-py-2 lg:tw-py-3 lg:tw-px-8 tw-w-full lg:tw-col-span-4"
              @click="router.push({ name: 'BookingHistory' })"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-sm">
                Ver historial de reservas
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-container-general">
      <div
        class="tw-grid tw-grid-cols-12 md:tw-grid-rows-rows_2_fit_last tw-gap-3 md:tw-gap-8 md:tw-my-10 tw-my-4"
      >
        <div
          class="tw-col-span-12 md:tw-col-span-7 md:tw-row-span-2 tw-order-2 md:tw-order-1"
        >
          <div
            class="tw-aspect-w-16 tw-aspect-h-8 tw-rounded-2.5xl tw-overflow-hidden"
          >
            <img
              :src="uriResources + '/' + mainImg"
              alt=""
              class="tw-h-full tw-object-cover tw-select-none"
            />
          </div>
        </div>
        <div
          class="tw-col-span-12 md:tw-col-span-4 tw-order-1 md:tw-order-2 tw-flex tw-items-end"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-xl md:tw-text-4xl tw-w-full tw-text-center md:tw-text-left"
          >
            {{ reservationsStore.bookingPlaceDetail.name }}
          </p>
        </div>
        <div class="tw-col-span-12 md:tw-col-span-5 tw-order-3">
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg tw-line-clamp-5"
          >
            {{ reservationsStore.bookingPlaceDetail.description }}
          </p>
        </div>
      </div>
      <hr />
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mt-5 md:tw-mt-10 tw-gap-4 md:tw-gap-8 lg:tw-gap-10 xl:tw-gap-12"
      >
        <div>
          <div
            v-if="reservationsStore.bookingPlaceDetail.options.length > 0"
            id="capacidad"
          >
            <CompCapacity
              only-show
              :option_1="reservationsStore.bookingPlaceDetail.options[0]"
              :option_2="reservationsStore.bookingPlaceDetail.options[1]"
            />
            <CompCheckIn
              v-if="reservationsStore.bookingPlaceDetail.category.id === 1"
              :checkin="reservationsStore.bookingPlaceDetail.checkin_time"
              :checkout="reservationsStore.bookingPlaceDetail.checkout_time"
            />
          </div>

          <div id="services">
            <div v-for="(item, idx) in listItems" :key="idx">
              <div
                :class="
                  item.title === 'Actividades en la ciudad'
                    ? 'tw-hidden md:tw-block'
                    : ''
                "
              >
                <hr class="tw-my-7" />
                <div>
                  <p
                    class="tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl"
                  >
                    {{ item.title }}
                  </p>
                </div>
                <div
                  v-for="(item, idx) in item.items"
                  :key="idx"
                  class="tw-flex tw-items-center tw-gap-3 tw-mb-3"
                >
                  <i
                    class="icon-cebada-full tw-text-yellow-400 tw-text-sm md:tw-text-base"
                  ></i>
                  <!--                  <i class=" tw-text-sm md:tw-text-base"
                     :class="item.title === 'Reglas de la casa' ? 'icon-Target' : item.title === 'Inclusión y accesibilidad' ? 'icon-Diamante': 'icon-cebada-full tw-text-yellow-400'"
                  ></i>-->
                  <p class="tw-m-0 tw-text-xs md:tw-text-base">
                    {{ item }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr class="tw-my-7 tw-block md:tw-hidden" />
        </div>

        <div>
          <div
            class="md:tw-border md:tw-border-solid md:tw-border-gray-250 tw-rounded-3xl tw-w-full md:tw-px-12 md:tw-py-6 tw-mb-5"
          >
            <div class="tw-mb-3">
              <p
                class="tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl"
              >
                Fotos
              </p>
              <CompReservationGalleryMasonry
                :content="reservationsStore.bookingPlaceDetail.photos"
                class="tw-mb-8"
              />
            </div>
            <div
              v-if="reservationsStore.bookingPlaceDetail.category.id === 1"
              class="tw-mb-3"
            >
              <hr class="tw-my-7 tw-block md:tw-hidden" />
              <p
                class="tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl"
              >
                Ubicación
              </p>
              <!-- <div
                class="
                  tw-rounded-3xl tw-overflow-hidden tw-aspect-w-16 tw-aspect-h-8
                "
              >
                <img
                  src="https://i0.wp.com/www.cssscript.com/wp-content/uploads/2018/03/Simple-Location-Picker.png"
                  class="tw-w-full tw-h-full tw-object-cover"
                />
              </div> -->
              <div class="tw-my-5">
                <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
                  {{ reservationsStore.bookingPlaceDetail.location }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="tw-bg-pink-100 tw-rounded-3xl tw-w-full tw-p-2 tw-mb-5 tw-hidden lg:tw-block"
          >
            <p class="tw-font-roboto tw-text-xs md:tw-text-base">
              ¿Quieres reservar alguno de estos espacios?
            </p>
            <comp-button-primary-red
              :disabled="
                reservationsStore.bookingActive &&
                reservationsStore.bookingPlaceDetail
                  .booking_place_category_id === 1
              "
              title="Reserva"
              @click.native="
                reservationsStore.bookingActive &&
                reservationsStore.bookingPlaceDetail
                  .booking_place_category_id === 1
                  ? null
                  : book()
              "
              class="tw-w-full"
            />
          </div>
        </div>
      </div>
      <hr class="tw-my-7 tw-hidden md:tw-block" />
      <div v-for="(item, idx) in listItems" :key="idx">
        <div
          v-if="item.title === 'Actividades en la ciudad'"
          class="tw-block md:tw-hidden"
        >
          <div>
            <p class="tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl">
              {{ item.title }}
            </p>
          </div>
          <div
            v-for="(item, idx) in item.items"
            :key="idx"
            class="tw-flex tw-items-center tw-gap-3 tw-mb-3"
          >
            <i class="icon-cebada-full tw-text-sm md:tw-text-base"></i>
            <p class="tw-m-0 tw-text-xs md:tw-text-base">
              {{ item }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="reservationsStore.bookingPlaceDetail.category.id === 2"
        id="externalServices"
      >
        <hr class="tw-my-7 tw-block md:tw-hidden" />
        <div>
          <p class="tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl">
            Servicios que puedes contratar
          </p>
        </div>

        <div
          class="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 md:tw-gap-5 tw-overflow-x-scroll"
        >
          <div
            v-for="(contact, idx) in reservationsStore.bookingPlaceDetail
              .contacts"
            :key="idx"
          >
            <div
              class="tw-border tw-border-solid tw-border-gray-250 tw-rounded-3xl tw-w-auto md:tw-w-96 tw-p-4 md:tw-p-6 tw-mb-5 tw-grid tw-grid-cols-1 tw-place-items-center tw-gap-3"
            >
              <div class="tw-flex tw-flex-col tw-w-full">
                <div class="tw-grid tw-grid-cols-col2_fit_last tw-gap-1">
                  <p
                    class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-lg tw-mb-2"
                  >
                    {{ contact.name }}
                  </p>
                  <i
                    class="icon-cebada-full tw-text-2xl md:tw-text-4xl tw-text-red-350"
                  ></i>
                </div>
                <p class="tw-m-0 tw-font-robotoBold tw-text-xs md:tw-text-sm">
                  Telefono
                </p>
                <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-sm">
                  {{ contact.phone }}
                </p>
                <p class="tw-m-0 tw-font-robotoBold tw-text-xs md:tw-text-sm">
                  Correo
                </p>
                <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-sm">
                  {{ contact.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="reservationsStore.bookingPlaceDetail.category.id === 1"
        id="resenas"
      >
        <hr class="tw-my-7 tw-block md:tw-hidden" />
        <div>
          <p class="tw-font-roboto tw-font-black tw-text-base md:tw-text-2xl">
            Reseñas
          </p>
        </div>
        <div class="tw-flex tw-gap-5 tw-overflow-x-scroll">
          <div
            v-for="(review, idx) in reservationsStore.bookingPlaceDetail
              .reviews"
            :key="idx"
          >
            <div
              class="tw-border tw-border-solid tw-border-gray-250 tw-rounded-3xl tw-w-64 md:tw-w-96 tw-px-6 tw-py-3 md:tw-px-12 md:tw-py-6 tw-mb-5 tw-grid tw-grid-cols-col2_fit_first tw-place-items-center tw-gap-3"
            >
              <div
                class="tw-rounded-full tw-w-16 tw-h-16 md:tw-w-24 lg:tw-h-24 tw-mr-2 tw-flex tw-items-center"
              >
                <img
                  v-if="review.core_employee.prof_picture !== null"
                  :src="uriResources + '/' + review.core_employee.prof_picture"
                  class="tw-object-cover tw-h-full tw-w-full tw-rounded-full"
                  alt=""
                />
                <img
                  v-else
                  class="tw-object-cover tw-h-full tw-w-full tw-rounded-full"
                  src="../../assets/img/no-participantes.png"
                  alt=""
                />
              </div>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <p class="tw-m-0 tw-font-robotoBold tw-text-xs md:tw-text-base">
                  {{ review.core_employee.name }}
                </p>
                <p
                  class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base tw-line-clamp-3"
                >
                  {{ review.review }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tw-fixed tw-bottom-28 tw-bg-pink-100 tw-p-5 tw-w-full tw-shadow-mobileBar tw-border tw-border-solid tw-border-gray-250 tw-block lg:tw-hidden"
      v-if="!isLoading"
    >
      <p class="tw-font-roboto tw-text-sm tw-text-center">
        ¿Quieres reservar alguno de estos espacios?
      </p>
      <comp-button-primary-red
        :disabled="
          reservationsStore.bookingActive &&
          reservationsStore.bookingPlaceDetail.booking_place_category_id === 1
        "
        title="Reserva"
        @click.native="
          reservationsStore.bookingActive &&
          reservationsStore.bookingPlaceDetail.booking_place_category_id === 1
            ? null
            : book()
        "
        class="tw-w-full"
      />
    </div>
  </div>
</template>
