<template>
  <div v-if="opportunity">
    <p class="text-uppercase tw-text-sm tw-text-gray-300 font-weight-bold">
      Descripción de la oferta
    </p>
    <div
      class="tw-bg-gray-200 tw-shadow tw-rounded-md p-4 z-index-2 position-relative"
    >
      <div class="d-flex justify-content-between">
        <p
          class="tw-text-gray-600 tw-text-base fs-md-20 text-uppercase mb-0 mr-1 font-weight-bold"
        >
          {{ opportunity.position_name }}
        </p>
        <div class="text-center" v-if="isLoadingApply">
          <img
            src="@/assets/img/icons/loading.gif"
            class="tw-w-10"
            alt="loading"
          />
        </div>
        <div v-else>
          <div v-if="hvIsComplete === false" class="text-right">
            <router-link
              to="/ambassadors/people/cv"
              class="tw-text-yellow-400 tw-text-base font-weight bold tw-underline"
            >
              Completa tu HV
            </router-link>
            <p class="tw-text-yellow-400 tw-text-xs font-italic">
              Aplica a las oportunidades que tenemos para ti.
            </p>
          </div>
          <div v-else-if="opportunity.state != null" class="text-right">
            <p class="tw-text-green-400 tw-text-base font-weight bold">
              ¡Ya aplicaste!
            </p>
            <p class="tw-text-green-400 tw-text-xs font-italic">
              Pronto se pondrán en contacto contigo.
            </p>
          </div>
          <div v-else-if="opportunity.state == null">
            <div v-if="false" class="text-right">
              <p class="tw-text-red-400 tw-text-base font-weight bold">
                No aplicas
              </p>
              <p class="tw-text-red-400 tw-text-xs font-italic">
                Tu perfil no se ajusta a esta vacante.
              </p>
            </div>
            <div
              v-else
              role="button"
              class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 text-center ml-1 px-4 py-2"
              @click="showModalOpportunityApply"
            >
              Aplicar
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div class="mr-2 ml-0">
          <p class="tw-text-xxs fs-md-12 tw-text-gray-300 mb-1">Lugar</p>
          <p
            class="tw-text-xxs fs-md-14 tw-text-gray-600 mb-0"
            v-if="opportunity.core_site"
          >
            {{ opportunity.core_site.name }}
          </p>
        </div>
        <div class="mx-2">
          <p class="tw-text-xxs fs-md-12 tw-text-gray-300 mb-1">Fecha límite</p>
          <p class="tw-text-xxs fs-md-14 tw-text-gray-600 mb-0">
            {{ opportunity.end_date }}
          </p>
        </div>
        <div class="mx-2">
          <p class="tw-text-xxs fs-md-12 tw-text-gray-300 mb-1">Banda</p>
          <p
            class="tw-text-xxs fs-md-14 tw-text-gray-600 mb-0"
            v-if="opportunity.core_pa_sideline"
          >
            {{ opportunity.core_pa_sideline.name }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="z-index-1 tw-bg-white py-4 px-3 px-md-4 mx-3 mx-md-4 tw-shadow tw-rounded-md position-relative mt-n2"
    >
      <div class="he-310 tw-overflow-y-scroll">
        <p
          class="tw-text-gray-600 tw-text-base text-uppercase font-weight-bold"
        >
          DESCRIPCIÓN DEL CARGO
        </p>
        <p class="tw-text-gray-600 tw-text-xs line-height-14">
          {{ opportunity.position_desc }}
        </p>
        <p
          class="tw-text-gray-600 tw-text-base text-uppercase font-weight-bold mt-4"
        >
          PERFIL
        </p>
        <p class="tw-text-gray-600 tw-text-xs line-height-14 font-weight-bold">
          Educación:
        </p>
        <p class="tw-text-gray-600 tw-text-xs line-height-14">
          {{ opportunity.education }}
        </p>
        <p class="tw-text-gray-600 tw-text-xs line-height-14 font-weight-bold">
          Habilidades:
        </p>
        <p class="tw-text-gray-600 tw-text-xs line-height-14">
          {{ opportunity.skills }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import sidelines from "@/mixins/user/sidelines";

export default {
  name: "OpportunityDetail",
  mixins: [sidelines],
  data() {
    return {
      sideLinesApprove: [],
    };
  },
  props: {
    opportunity: {
      required: true,
    },
    isLoadingApply: {
      required: true,
      default: false,
    },
    hvIsComplete: {
      required: true,
      default: true,
    },
  },
  created() {
    this.sideLinesApprove = this.selectPrevSidelines(
      this.user.core_pa_sideline.name,
      2
    );
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  methods: {
    showModalOpportunityApply() {
      this.$bvModal.show("modal-opportunity-apply");
    },
  },
};
</script>
