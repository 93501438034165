<template>
  <div
    class="tw-border tw-border-gray-300 tw-rounded-2xl tw-p-2 tw-flex tw-flex-col tw-gap-2"
  >
    <img
      :src="uriResources + '/' + props.item.booking_place?.photos[0]?.image"
      alt="place"
      class="tw-w-full tw-h-20 lg:tw-h-32 tw-object-cover tw-rounded-2xl"
    />
    <p class="tw-font-robotoBold tw-text-sm lg:tw-text-base tw-m-0">
      {{ props.item.booking_place?.name }}
    </p>
    <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
      <span class="tw-font-robotoBold">Capacidad:</span>
      {{ props.item.capacity }}
    </p>
    <div class="tw-flex tw-items-center tw-gap-3">
      <p class="tw-font-robotoBold tw-m-0 tw-text-xs lg:tw-text-sm">Fechas:</p>
      <p class="tw-font-roboto tw-m-0 tw-text-xs lg:tw-text-sm">
        {{
          `${formatDateDDMMYYYY(startDate)} - ${formatDateDDMMYYYY(endDate)} `
        }}
      </p>
    </div>
    <comp-button-custom
      text="Ver detalles"
      @click.native="emit('close')"
      class="tw-w-full"
    />
    <comp-button-primary-red
      title="Selecciona este lugar"
      class="tw-w-full"
      @click.native="emit('continueBooking')"
    />
  </div>
</template>
<script setup>
import config from "@/services/config";
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const uriResources = config.beerOfficeURL;
const emit = defineEmits(["close", "continueBooking"]);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  startDate: {
    type: String,
    required: true,
  },
  endDate: {
    type: String,
    required: true,
  },
});
</script>
<style scoped>
@media (max-width: 1023px) {
  .grid-container > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
    justify-self: center;
    width: calc(50% - 0.5rem);
  }
}
</style>
