import config from "@/services/config";

const linksMixin = {
  methods: {
    dataLayerEvent(event, category, name) {
      window.dataLayer.push({
        event: event,
        category: category,
        Action: "Clics",
        Banner: name,
      });
    },
    redirectToLinkWithDataLayer(event, link, category, name) {
      this.dataLayerEvent(event, category, name);
      if (link) {
        if (
          link.includes("http://") ||
          link.includes("https://") ||
          link.includes("tel:")
        ) {
          window.open(link);
        } else {
          this.$router.push(link);
        }
      }
    },
    pushLink(link) {
      if (link) {
        if (
          link.includes("http://") ||
          link.includes("https://") ||
          link.includes("tel:")
        ) {
          window.open(link);
        } else if (link.startsWith("storage")) {
          window.open(config.beerOfficeURL + "/" + link);
        } else {
          this.$router.push(link);
        }
      }
    },
    pushDataLayer(data) {
      let standardData = {
        event: "GAEvent",
        interaction: "True",
      };

      const dataLayer = Object.assign(standardData, data);

      window.dataLayer.push(dataLayer);
    },
  },
};

export default linksMixin;
