export const dummyQuestions = [
  {
    name: "Question 0",
    image: "https://picsum.photos/200/300",
    question: "What do you think about Renaissance art?",
    type: "open",
    answers: [],
  },
  {
    name: "Question 1",
    image: "https://picsum.photos/200/300",
    question: "What is the capital of France?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Paris", correct: true },
      { id: 2, answer: "London", correct: false },
      { id: 3, answer: "Berlin", correct: false },
      { id: 4, answer: "Rome", correct: false },
    ],
  },
  {
    name: "Question 2",
    image: "https://picsum.photos/200/300",
    question: "Who painted the Mona Lisa?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Leonardo da Vinci", correct: true },
      { id: 2, answer: "Vincent van Gogh", correct: false },
      { id: 3, answer: "Pablo Picasso", correct: false },
      { id: 4, answer: "Michelangelo", correct: false },
    ],
  },
  {
    name: "Question 3",
    image: "https://picsum.photos/200/300",
    question: "What is the largest ocean in the world?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Pacific", correct: true },
      { id: 2, answer: "Atlantic", correct: false },
      { id: 3, answer: "Indian", correct: false },
      { id: 4, answer: "Arctic", correct: false },
    ],
  },
  {
    name: "Question 4",
    image: "https://picsum.photos/200/300",
    question: "Which planet is known as the Red Planet?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Mars", correct: true },
      { id: 2, answer: "Venus", correct: false },
      { id: 3, answer: "Jupiter", correct: false },
      { id: 4, answer: "Saturn", correct: false },
    ],
  },
  {
    name: "Question 5",
    image: "https://picsum.photos/200/300",
    question: "What is the chemical symbol for gold?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Au", correct: true },
      { id: 2, answer: "Ag", correct: false },
      { id: 3, answer: "Hg", correct: false },
      { id: 4, answer: "Pb", correct: false },
    ],
  },
  {
    name: "Question 6",
    image: "https://picsum.photos/200/300",
    question: "Who wrote the novel 'Pride and Prejudice'?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Jane Austen", correct: true },
      { id: 2, answer: "Charlotte Bronte", correct: false },
      { id: 3, answer: "Emily Bronte", correct: false },
      { id: 4, answer: "Louisa May Alcott", correct: false },
    ],
  },
  {
    name: "Question 7",
    image: "https://picsum.photos/200/300",
    question: "What is the tallest mountain in the world?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Mount Everest", correct: true },
      { id: 2, answer: "K2", correct: false },
      { id: 3, answer: "Kangchenjunga", correct: false },
      { id: 4, answer: "Makalu", correct: false },
    ],
  },
  {
    name: "Question 8",
    image: "https://picsum.photos/200/300",
    question: "Which country is known as the Land of the Rising Sun?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Japan", correct: true },
      { id: 2, answer: "China", correct: false },
      { id: 3, answer: "South Korea", correct: false },
      { id: 4, answer: "Vietnam", correct: false },
    ],
  },
  {
    name: "Question 9",
    image: "https://picsum.photos/200/300",
    question: "Who is the Greek god of the sea?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Poseidon", correct: true },
      { id: 2, answer: "Zeus", correct: false },
      { id: 3, answer: "Apollo", correct: false },
      { id: 4, answer: "Hades", correct: false },
    ],
  },
  {
    name: "Question 10",
    image: "https://picsum.photos/200/300",
    question: "What is the largest organ in the human body?",
    type: "multiple",
    answers: [
      { id: 1, answer: "Skin", correct: true },
      { id: 2, answer: "Liver", correct: false },
      { id: 3, answer: "Heart", correct: false },
      { id: 4, answer: "Kidney", correct: false },
    ],
  },
  {
    name: "Question 11",
    image: "https://picsum.photos/200/300",
    question: "What do you think about Renaissance art?",
    type: "open",
    answers: [],
  },
];
