var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_setup.isLoading)?_c(_setup.MobileBar,{attrs:{"title":"Beneficios"}}):_vm._e(),_c(_setup.TitleBar,{attrs:{"title":"Beneficios"}}),(!_setup.isLoading)?_c('div',{staticClass:"tw-container-general"},[_c('div',{staticClass:"tw-my-6"},[_c(_setup.CompGeneralTopCard,{attrs:{"image":_setup.benefitAgreementsStore.benefitDetail.main_image,"title":_setup.benefitAgreementsStore.benefitDetail.name,"description":_setup.benefitAgreementsStore.benefitDetail.description}}),_c('hr',{staticClass:"tw-w-full tw-bg-gray-250 tw-my-10"})],1),_c('div',{staticClass:"tw-my-6"},[(
          _setup.benefitAgreementsStore.benefitDetail?.content !== undefined &&
          _setup.benefitAgreementsStore.benefitDetail?.content != null &&
          _setup.benefitAgreementsStore.benefitDetail?.content.length > 0
        )?_c(_setup.CompGeneralArticleContents,{attrs:{"content":_setup.benefitAgreementsStore.benefitDetail?.content,"article-name":_setup.benefitAgreementsStore.benefitDetail?.name,"category-for-datalayer":"Beneficios"}}):_vm._e()],1),_c('div',{staticClass:"tw-my-6"},[_c(_setup.CompSocialNetworkInteractionsBig,{attrs:{"id":_setup.benefitAgreementsStore.benefitDetail.id,"title":_setup.benefitAgreementsStore.benefitDetail.name,"category":"BENEFITS","user_reaction":_setup.benefitAgreementsStore.benefitDetail.user_reaction,"user_score_review":_setup.benefitAgreementsStore.benefitDetail.user_score_review,"amount_comments":_setup.benefitAgreementsStore.benefitDetail.amount_comments,"amount_reactions":_setup.benefitAgreementsStore.benefitDetail.amount_reactions,"image":_setup.benefitAgreementsStore.benefitDetail.main_image,"description":_setup.benefitAgreementsStore.benefitDetail.description,"item":_setup.benefitAgreementsStore.benefitDetail,"hover":_setup.isHovering,"category-for-datalayer":"Beneficios"},on:{"updatedComment":_setup.getBenefitDetail,"updatedReaction":_setup.getBenefitDetail}})],1),_vm._m(0),_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8 tw-mb-4 md:tw-mb-8"},_vm._l((_setup.relatedBenefits),function(item,idx){return _c('div',{key:idx,staticClass:"lg:tw-flex lg:tw-flex-col"},[_c(_setup.CompGeneralGridCard,{attrs:{"id":item.id,"item":item?.ben_detail,"category":item?.ben_detail?.ben_benefit_category?.category,"category-for-social":"BENEFITS","title":item?.ben_detail?.name,"description":item?.ben_detail?.description,"link":`/benefits/${item?.ben_detail?.id}`,"image":_setup.mobile()
              ? item?.ben_detail?.banner_image
              : item?.ben_detail?.main_image,"category-for-datalayer":"Beneficios","action-for-datalayer":"Otros beneficios que tenemos para ti"},on:{"updatedComment":_setup.updateRelatedBenefits,"updatedReaction":_setup.updateRelatedBenefits}}),_c('hr',{staticClass:"md:tw-hidden"})],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-my-5",attrs:{"id":"Related"}},[_c('p',{staticClass:"tw-mb-0 tw-text-black tw-text-2xl md:tw-text-4xl tw-font-robotoBold"},[_vm._v(" Otros beneficios que tenemos para ti ")])])
}]

export { render, staticRenderFns }