<template>
  <b-tabs
    active-nav-item-class="tw-text-red-200"
    active-tab-class="tw-text-gray-600"
    content-class="mt-n0"
    fill
  >
    <b-tab title="País" active>
      <div class="tw-bg-red-400 px-3 py-5">
        <p class="tw-text-white fs-30 text-center mb-0">Ranking País</p>
      </div>
      <div
        class="tw-bg-white tw-rounded-lg p-3 mt-n2 tw-shadow-sm position-relative overflow-auto he-390"
      >
        <table class="w-100 table-1">
          <tr>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs text-center">
                Posición
              </p>
            </th>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs text-center">
                Gerencia
              </p>
            </th>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs text-center">Zona</p>
            </th>
            <th class="pb-1 tw-w-24">
              <p class="mb-0 tw-text-gray-600 tw-text-xs text-center">Pago</p>
            </th>
          </tr>
          <tr
            v-for="item in tabsData.country"
            :key="'country' + item.id"
            class="tw-rounded-lg"
            :class="
              item.select
                ? 'tw-bg-red-300 tw-text-white'
                : 'tw-bg-gray-200 tw-text-gray-400'
            "
          >
            <td class="py-2 px-3 text-center">
              <p class="font-weight-bold tw-text-lg mb-0">
                {{ item.ranking_score_country }}
              </p>
            </td>
            <td class="py-2 px-3">
              <p class="tw-text-xs mb-0">{{ item.remu_manager_id }}</p>
            </td>
            <td class="py-2 px-3">
              <p class="tw-text-xs mb-0">
                {{ item.remu_zone_id == null ? "N/A" : item.remu_zone_id }}
              </p>
            </td>
            <td class="py-2 px-3">
              <div class="tw-bg-white p-3 tw-rounded-lg tw-shadow-sm">
                <p
                  class="mb-0 tw-text-gray-600 mb-0 font-weight-bold tw-text-base text-center"
                >
                  {{ item.payment }}%
                </p>
              </div>
            </td>
          </tr>
        </table>
        <div
          role="button"
          class="wi-30 he-30 tw-rounded-full tw-bg-red-400 position-absolute bottom-n10 d-none justify-content-center align-items-center tw-left-1/2 ml-n3 hover:tw-bg-red-600"
        >
          <i class="icon-arrow-down tw-text-lg tw-text-white"></i>
        </div>
      </div>
    </b-tab>
    <b-tab title="Regional">
      <div class="tw-bg-red-400 px-3 py-5">
        <p class="tw-text-white fs-30 text-center mb-0">Ranking Regional</p>
      </div>
      <div
        class="tw-bg-white tw-rounded-lg p-3 mt-n2 tw-shadow-sm position-relative he-350 overflow-auto"
      >
        <table class="w-100 table-1">
          <tr>
            <th class="pb-1 tw-w-14">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Posición</p>
            </th>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Gerencia</p>
            </th>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Zona</p>
            </th>
            <th class="pb-1 tw-w-24">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Pago</p>
            </th>
          </tr>
          <tr
            v-for="item in tabsData.regional"
            :key="'country' + item.id"
            class="tw-rounded-lg"
            :class="
              item.select
                ? 'tw-bg-red-300 tw-text-white'
                : 'tw-bg-gray-200 tw-text-gray-400'
            "
          >
            <td class="py-2 px-3 text-center">
              <p class="font-weight-bold tw-text-lg mb-0">
                {{ item.ranking_score_regional }}
              </p>
            </td>
            <td class="py-2 px-3">
              <p class="tw-text-xs mb-0">{{ item.remu_manager_id }}</p>
            </td>
            <td class="py-2 px-3">
              <p class="tw-text-xs mb-0">
                {{ item.remu_zone_id == null ? "N/A" : item.remu_zone_id }}
              </p>
            </td>
            <td class="py-2 px-3">
              <div class="tw-bg-white p-3 tw-rounded-lg tw-shadow-sm">
                <p
                  class="mb-0 tw-text-gray-600 mb-0 font-weight-bold tw-text-base text-center"
                >
                  {{ item.payment }}%
                </p>
              </div>
            </td>
          </tr>
        </table>
        <div
          role="button"
          class="wi-30 he-30 tw-rounded-full tw-bg-red-400 position-absolute bottom-n10 d-none justify-content-center align-items-center tw-left-1/2 ml-n3 hover:tw-bg-red-600"
        >
          <i class="icon-arrow-down tw-text-lg tw-text-white"></i>
        </div>
      </div>
    </b-tab>
    <b-tab title="Gerencia"
      ><div class="tw-bg-red-400 px-3 py-5">
        <p class="tw-text-white fs-30 text-center mb-0">Ranking Gerencia</p>
      </div>
      <div
        class="tw-bg-white tw-rounded-lg p-3 mt-n2 tw-shadow-sm position-relative h-350 overflow-auto"
      >
        <table class="w-100 table-1">
          <tr>
            <th class="pb-1 tw-w-14">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Posición</p>
            </th>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Gerencia</p>
            </th>
            <th class="pb-1">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Zona</p>
            </th>
            <th class="pb-1 tw-w-24">
              <p class="mb-0 tw-text-gray-600 tw-text-xs">Pago</p>
            </th>
          </tr>
          <tr
            v-for="item in tabsData.management"
            :key="'country' + item.id"
            class="tw-rounded-lg"
            :class="
              item.select
                ? 'tw-bg-red-300 tw-text-white'
                : 'tw-bg-gray-200 tw-text-gray-400'
            "
          >
            <td class="py-2 px-3 text-center">
              <p class="font-weight-bold tw-text-lg mb-0">
                {{ item.ranking_score_manager }}
              </p>
            </td>
            <td class="py-2 px-3">
              <p class="tw-text-xs mb-0">{{ item.remu_manager_id }}</p>
            </td>
            <td class="py-2 px-3">
              <p class="tw-text-xs mb-0">
                {{ item.remu_zone_id == null ? "N/A" : item.remu_zone_id }}
              </p>
            </td>
            <td class="py-2 px-3">
              <div class="tw-bg-white p-3 tw-rounded-lg tw-shadow-sm">
                <p
                  class="mb-0 tw-text-gray-600 mb-0 font-weight-bold tw-text-base text-center"
                >
                  {{ item.payment }}%
                </p>
              </div>
            </td>
          </tr>
        </table>
        <div
          role="button"
          class="wi-30 he-30 tw-rounded-full tw-bg-red-400 position-absolute bottom-n10 d-none justify-content-center align-items-center tw-left-1/2 ml-n3 hover:tw-bg-red-600"
        >
          <i class="icon-arrow-down tw-text-lg tw-text-white"></i>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import apiBeerOffice from "@/services/apiBeerOffice";

export default {
  name: "CompRankingTabs",
  data() {
    return {
      tabsData: {},
    };
  },
  created() {
    apiBeerOffice.getRankingTabData().then((data) => {
      this.tabsData = data.data;
    });
  },
};
</script>

<style scoped>
.tabs-red .nav-tabs li:first-child,
.tabs-red .nav-tabs li:first-child a {
  border-radius: 10px 0 0 0;
}

.tabs-red .nav-tabs li:last-child,
.tabs-red .nav-tabs li:last-child a {
  border-radius: 0 10px 0 0;
}

.tabs-red .nav-tabs {
  border-radius: 10px 10px 0 0;
}

.tabs-red .nav-tabs {
  border-bottom: solid 2px rgba(255, 255, 255, 0.7);
}

.tabs-red .nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.tabs-red .nav-tabs .nav-link.active {
  color: #fff;
}

.tabs-red .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.tabs-red .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom: 6px solid rgba(255, 255, 255, 0.7);
}

.tabs-red .nav-item {
  background-color: var(--red-200);
  width: 33.333%;
}
.tabs-red .nav-item .active {
  background-color: var(--red-300);
}

.tabs-2 .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.tabs-2 .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-bottom: solid 4px var(--red-400);
}
</style>
