<script setup>
import { onBeforeMount, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCoreStore } from "@/stores/coreStore";
import { useRouter } from "vue-router/composables";

const showLegal = ref(false);
const legalText =
  "Procedimiento para Atención y Respuesta a Peticiones, Consultas, Quejas y Reclamos de los Titulares de Datos Personales: Los Titulares de Datos Personales tratados por LA COMPAÑÍA tienen derecho a acceder a sus Datos Personales y a los detalles de dicho Tratamiento, así como a rectificarlos y actualizarlos en caso de ser inexactos o a solicitar su eliminación cuando consideren que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al Tratamiento de los mismos para fines específicos. Las vías que se han implementado para garantizar el ejercicio de dichos derechos a través de la presentación de la solicitud respectiva son: Comunicación dirigida a Bavaria & CIA S.C.A. área de Compliance: Carrera 53 A No. 127-35, Bogotá D.C. Colombia. Solicitud presentada al correo electrónico: protecciondedatos@co.ab-inbev.com. Solicitud presentada a través del teléfono: +57 (1) 6389000 al área de Compliance. ";
const navbarColor = ref(null);
const router = useRouter();
const isLoading = ref(false);

const toggleLegal = () => {
  showLegal.value = !showLegal.value;
};

const { configurations } = storeToRefs(useCoreStore());

const updateNavbarColor = () => {
  navbarColor.value = configurations.value.find(
    (item) => item.key === "NAVBAR_COLOR"
  ).value;
};

onBeforeMount(async () => {
  isLoading.value = true;
  updateNavbarColor();
  isLoading.value = false;
});

watch(
  () => router.currentRoute,
  () => {
    updateNavbarColor();
  }
);
</script>
<template>
  <footer class="">
    <div class="tw-py-1 tw-bg-red-50 tw-grid tw-grid-cols-2 md:tw-hidden">
      <div class="tw-col-span-1">
        <div class="tw-flex tw-items-center tw-space-x-2">
          <img src="@/assets/img/footer/logo-footer-1.png" alt="logo footer" />
          <p class="tw-mb-0 tw-text-red-400 tw-text-base tw-font-robotoBold">
            Embajadores
          </p>
        </div>
      </div>
      <div class="tw-col-span-1 tw-ml-auto tw-pr-4">
        <div class="tw-flex tw-space-x-2">
          <img src="@/assets/img/footer/logo-bavaria.png" alt="bavaria" />
          <img
            src="@/assets/img/footer/logo-embajadores.png"
            alt="embajadores"
          />
        </div>
      </div>
    </div>
    <div
      :class="{ 'tw-bg-red-400': !navbarColor }"
      class="tw-py-4 md:tw-py-3 md:tw-pl-16 md:tw-px-4"
      :style="{ backgroundColor: navbarColor }"
    >
      <div class="tw-container-general">
        <div class="tw-grid tw-grid-cols-12 tw-gap-x-4">
          <div class="tw-col-span-12 xl:tw-col-span-2"></div>
          <div class="tw-col-span-12 xl:tw-col-span-8 lg:tw-pt-5">
            <div class="tw-grid tw-grid-cols-12 tw-gap-4">
              <div class="tw-col-span-12 lg:tw-col-span-4">
                <div class="tw-text-white tw-px-4">
                  <div
                    @click="toggleLegal"
                    class="tw-grid tw-grid-cols-12 tw-items-center tw-border-b tw-border-white md:tw-border-none tw-pb-4 tw-cursor-pointer"
                  >
                    <div class="tw-col-span-10">
                      <p
                        class="tw-mb-0 tw-text-center tw-text-base tw-font-robotoBold tw-ml-12 md:tw-ml-0"
                      >
                        Legal
                      </p>
                    </div>
                    <div
                      class="tw-col-span-2 tw-flex tw-justify-center tw-items-center"
                    >
                      <i
                        class="tw-text-xl"
                        :class="
                          showLegal ? 'icon-flecha-arriba' : 'icon-flecha-abajo'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="tw-pt-4 md:tw-hidden" v-if="showLegal">
                    <p class="tw-text-xs tw-font-robotoLight">
                      {{ legalText }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="tw-col-span-12 lg:tw-col-span-4">
                <div class="tw-text-white tw-px-4">
                  <div
                    class="tw-grid tw-grid-cols-12 tw-items-center tw-pb-4 tw-border-b tw-border-white md:tw-border-none"
                  >
                    <div class="tw-col-span-12 tw-text-center">
                      <a
                        href="https://api.embajadores.com.co/storage/documents/1598974669.pdf"
                        class="tw-mb-0 tw-text-white tw-text-center tw-text-base tw-font-robotoBold hover:tw-text-white"
                        >Términos y condiciones
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-col-span-12 lg:tw-col-span-4 tw-text-center">
                <div class="tw-text-white tw-px-4">
                  <div
                    class="tw-grid tw-grid-cols-12 tw-items-center tw-pb-4 tw-border-b tw-border-white md:tw-border-none"
                  >
                    <div class="tw-col-span-12">
                      <a
                        href="https://api.embajadores.com.co/storage/documents/1598979816.pdf"
                        class="tw-mb-0 tw-text-white tw-text-center tw-text-base tw-font-robotoBold hover:tw-text-white"
                      >
                        Políticas de privacidad
                      </a>
                    </div>
                  </div>
                  <p class="tw-pt-4 tw-text-xs tw-font-robotoThin md:tw-hidden">
                    Embajadores 3.0 - 2022
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-col-span-12 xl:tw-col-span-2">
            <div
              class="tw-hidden lg:tw-flex tw-gap-4 tw-justify-end tw-items-center"
            >
              <img
                src="@/assets/img/footer/logo-embajadores-blanco.png"
                class="tw-w-14 tw-h-auto"
                alt="embajadores"
              />
              <img
                src="@/assets/img/footer/nuevo-logo-bavaria.png"
                class="tw-w-14 tw-h-auto"
                alt="bavaria"
              />
            </div>
          </div>
        </div>
        <div
          class="tw-hidden md:tw-grid tw-grid-cols-12 tw-gap-x-4"
          v-if="showLegal"
        >
          <div class="tw-col-span-12 xl:tw-col-span-2"></div>
          <div class="tw-col-span-12 xl:tw-col-span-8">
            <div
              class="tw-w-full tw-bg-white tw-mb-3"
              style="height: 1px"
            ></div>
            <p class="tw-text-xs tw-text-white tw-font-robotoThin">
              {{ legalText }}
            </p>
          </div>
          <div class="tw-col-span-12 xl:tw-col-span-2"></div>
        </div>
      </div>
    </div>
  </footer>
</template>
