<template>
  <div>
    <div class="tw-shadow-sm p-3 tw-rounded-lg mb-2">
      <div
        role="button"
        class="d-flex align-items-center"
        v-b-toggle="'approval-collapse-' + request.id"
      >
        <img
          :src="
            request.prof_picture !== null
              ? uriResources + '/' + request.prof_picture
              : imgAvatarEmpty
          "
          :alt="request.name"
          class="wi-80 he-80 wi-md-90 he-md-90 tw-rounded-full mr-3"
        />
        <div>
          <p
            class="text-uppercase tw-text-sm fs-md-20 tw-text-gray-600 font-weight-bold"
          >
            {{ request.name }}
          </p>
          <p class="text-uppercase  tw-text-xs  fs-md-14 tw-text-gray-600">
            {{ request.position }}
          </p>
        </div>
        <i class="icon-flecha-abajo tw-text-red-400 fs-30 ml-2"></i>
      </div>
      <b-collapse :id="'approval-collapse-' + request.id" class="mt-3">
        <RequestLm
          role="button"
          v-for="(requestIn, index) in request.people_vacant_positions"
          :key="requestIn.id"
          :opportunity="requestIn"
          @click.native="selectRequest(index)"
          @updateState="(state) => updateState(state)"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import RequestLm from "@/components/people/requests/RequestLm";

export default {
  name: "ApprovalView",
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  components: {
    RequestLm,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgAvatarEmpty: require("@/assets/img/demo/avatar-empty.png"),
    };
  },
  methods: {
    selectRequest(id) {
      this.$emit("selectRequest", id);
    },
    updateState(state) {
      this.$emit("updateState", state);
    },
  },
};
</script>
