<template>
  <b-modal id="modal-opportunity-check" hide-header hide-footer centered>
    <div class="text-center py-5 px-4 position-relative">
      <div
        class="position-absolute tw-top-2 tw-right-2"
        role="button"
        @click="hideModal()"
      >
        <i class="icon-cerrar-simple fs-40 tw-text-red-400"></i>
      </div>
      <div class="mx-auto mb-4 wi-80 he-80 tw-bg-green-400 tw-rounded-full">
        <i class=" tw-text-xxxs 0 tw-text-white icon-check-simple"></i>
      </div>
      <p
        class="tw-text-green-400 text-uppercase font-weight-bold fs-30 fs-md-50 tw-font-tolyerBold"
      >
        Genial
      </p>
      <p class="tw-text-gray-600 tw-text-lg">
        {{ user.name }} has enviado tu solicitud. Espera la aprobación de tu LM
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalOpportunityCheck",
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-opportunity-check");
    },
  },
};
</script>