import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    viewCalendar: false,
    events: [],
    months_calendar: [],
    categories: [],
  },
  mutations: {
    showCalendar: (state) => {
      state.viewCalendar = true;
    },
    hideCalendar: (state) => {
      state.viewCalendar = false;
    },
    toggleViewCalendar: (state) => {
      state.viewCalendar = !state.viewCalendar;
    },
    setEvents: (state, events) => {
      state.events = events;
    },
    setMonths: (state, months_calendar) => {
      state.months_calendar = months_calendar;
    },
    setCategories: (state, categories) => {
      state.categories = categories;
    },
  },
  actions: {
    actGetCalendarData: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getCalendarData(data);
      if (response.error) {
        return response.error;
      }
      commit("setEvents", response.data.events);
      commit("setMonths", response.data.months);
      return true;
    },
    actGetEventCategories: async ({ commit }) => {
      let response = await ApiBeerOffice.getEventCategories();
      if (response.error) {
        return response.error;
      }
      commit("setCategories", response.data);
      return true;
    },
  },
};
