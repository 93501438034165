<script setup>
import { getCurrentInstance, onBeforeMount, ref, watch } from "vue";
import { useCalendarStore } from "@/stores/calendarStore";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { capitalizeEachWord } from "@/helpers/textHelpers";
import CompEventCard from "@/components/calendar/CompEventCard.vue";
import { pushLink } from "@/helpers/linkHelpers";
import CompGeneralTooltip from "@/components/general/CompGeneralTooltip.vue";

const calendarStore = useCalendarStore();
const goFlexStore = useGoFlexStore();
const { $calendar: calendar, $isMobile: mobile } = getCurrentInstance().proxy;
const cal = ref(new calendar(null, null, "es-ES"));
const selectedDate = ref(null);
const filteredPersonalEvents = ref([]);
const filteredGeneralEvents = ref([]);
const nextDate = ref(null);

const props = defineProps({
  switch: {
    type: Boolean,
  },
  selectedSubCategory: {
    type: Object,
  },
});

const emit = defineEmits(["switch", "eventModal"]);

const getCalendarEvents = async () => {
  const firstDay = cal.value.monthDaysList[0].Dateymd;
  const lastDay =
    cal.value.monthDaysList[cal.value.monthDaysList.length - 1].Dateymd;
  await calendarStore.actGetCalendarEvents(firstDay, lastDay).then(() => {
    filterEventsBySubcategory();
  });
};

const goToPrevMonth = () => {
  cal.value.goToPreviousMonth();
  getCalendarEvents();
};

const goToNextMonth = () => {
  cal.value.goToNextMonth();
  getCalendarEvents();
};

const filterEventsBySubcategory = () => {
  if (props.selectedSubCategory?.key === "all") {
    filteredPersonalEvents.value = calendarStore.calendarPersonalEvents;
    filteredGeneralEvents.value = calendarStore.calendarGeneralEvents;
  } else if (props.selectedSubCategory?.name) {
    const categoryName = props.selectedSubCategory.name;
    filteredPersonalEvents.value = calendarStore.calendarPersonalEvents.filter(
      (event) => event.category.name === categoryName
    );
    filteredGeneralEvents.value = calendarStore.calendarGeneralEvents.filter(
      (event) => event.category.name === categoryName
    );
  }
};

function filterEventsByCategoryAndDate(category, date) {
  if (category === "Personal") {
    return filteredPersonalEvents.value.filter((event) => event.date === date);
  }
  if (category === "Embajadores") {
    return filteredGeneralEvents.value.filter((event) => event.date === date);
  }
  if (category === "GoFlex") {
    return goFlexStore.teamRequests.filter(
      (request) => request.requested_date === date
    );
  }
}

function changeSelectedDate(day) {
  selectedDate.value = {
    Date: day,
    Dateymd: day.Dateymd,
    DayAndMonth: day.format("D de MMMM"),
  };
  mobile() ? emit("switch", true) : "";

  const dayFound = cal.value.monthDaysList.find(
    (d) => d.Dateymd === day.Dateymd
  );
  if (!dayFound) {
    cal.value.goToRangeMonth(day.Date);
  }
}

function openEventModal(event) {
  emit("eventModal", event);
  pushDataLayer({
    event_category: "Calendario",
    event_action: "Detalles de actividad",
    event_label: event.name, //trae el nombre de la actividad seleccionada ej: Cabaña en tibasosa, Producto del mes, ¿Sabes de diseño?
    component_name: "Click",
    element_date: event.date, //trae la fecha de la actividad en formato DD/MM/YYYY ej: 22/05/2023
    element_hour: event.start_time, //trae la hora de la actividad ej: 3:00pm
    element_category: event.location, //trae el lugar del evento ej: Zoom
    element_text: event.category?.name, //trae el nombre de la sección a la que pertenece la actividad ej: Tus actividades, Actividades embajadores
  });
}

function forwardDate() {
  nextDate.value.setDate(nextDate.value.getDate() + 5);
  calendarStore.setSurroundingDays(
    cal.value.getSurroundingDays(nextDate.value)
  );
}

function backwardDate() {
  nextDate.value.setDate(nextDate.value.getDate() - 5);
  calendarStore.setSurroundingDays(
    cal.value.getSurroundingDays(nextDate.value)
  );
}

const isSelectedDay = (day) => {
  return day.Dateymd === selectedDate.value.Dateymd;
};

const request_type = (option) => {
  if (option === "off") {
    return "Tiempo Off";
  } else if (option === "flex") {
    return "un día de Flex Work";
  } else {
    return "Otro";
  }
};

watch(
  () => props.selectedSubCategory,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      filterEventsBySubcategory();
    }
  }
);

onBeforeMount(async () => {
  cal.value.goToCurrentMonth();
  selectedDate.value = {
    Date: cal.value.today,
    Dateymd: cal.value.todayymd,
    DayAndMonth: cal.value.todayDayAndMonth,
  };

  await getCalendarEvents();
  await goFlexStore.actGetGoFlexTeamRequests();

  nextDate.value = new Date(selectedDate.value.Date.Date);
  calendarStore.setSurroundingDays(
    cal.value.getSurroundingDays(nextDate.value)
  );
});
</script>

<template>
  <div
    class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-p-4 lg:tw-p-8 tw-rounded-2xl tw-shadow-xl lg:tw-shadow-none tw-border tw-border-gray-300 tw-gap-5 lg:tw-gap-10"
  >
    <div
      v-if="(!props.switch && mobile()) || !mobile()"
      class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4"
    >
      <div class="tw-w-full tw-grid tw-grid-cols-col3_fit_first_and_last">
        <div
          @click="goToPrevMonth()"
          class="tw-flex tw-items-center tw-cursor-pointer"
        >
          <i
            class="icon-flecha-izq tw-text-sm md:tw-text-2xl tw-text-red-350"
          ></i>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-uppercase tw-text-base md:tw-text-2xl"
          >
            {{ cal.month.name }}
          </p>
        </div>
        <div
          @click="goToNextMonth()"
          class="tw-flex tw-items-center tw-cursor-pointer"
        >
          <i
            class="icon-flecha-der tw-text-sm md:tw-text-2xl tw-text-red-350"
          ></i>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-7">
        <div
          class="tw-flex tw-justify-center tw-items-center"
          :class="{ weekend: day.number === 0 || day.number === 6 }"
          v-for="(day, idx) in cal.weekDaysShort"
          :key="idx"
        >
          <p
            class="tw-m-0 tw-capitalize tw-font-robotoBold tw-text-red-350 tw-text-xs lg:tw-text-base"
          >
            {{ day.day }}
          </p>
        </div>
      </div>
      <div class="tw-w-full tw-h-full">
        <div class="tw-grid tw-grid-cols-7 tw-gap-2">
          <div
            v-for="(day, idx) in cal.monthDaysList"
            :key="idx"
            class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-justify-center tw-content-center tw-bg-[#F0F0F0] tw-rounded-xl lg:tw-rounded-2xl tw-px-2 tw-py-2 lg:tw-px-2 lg:tw-pt-2 lg:tw-pb-4 tw-gap-2 tw-cursor-pointer"
            :class="
              selectedDate?.Dateymd === day?.Dateymd
                ? 'tw-border tw-border-black'
                : 'tw-border tw-border-transparent'
            "
            @click="changeSelectedDate(day)"
          >
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xs tw-leading-[14px] lg:tw-text-lg lg:tw-leading-5 tw-text-center"
            >
              {{ day?.date }}
            </p>
            <div
              class="tw-w-5 tw-h-5 lg:tw-w-8 lg:tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-xs md:tw-text-lg tw-place-self-center"
              :class="
                filterEventsByCategoryAndDate('Personal', day?.Dateymd).length >
                0
                  ? 'tw-bg-yellow-400 tw-text-white'
                  : 'tw-bg-[#F0F0F0] tw-text-[#F0F0F0] tw-select-none'
              "
            >
              {{
                filterEventsByCategoryAndDate("Personal", day?.Dateymd).length
              }}
            </div>
            <div
              class="tw-w-5 tw-h-5 lg:tw-w-8 lg:tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-xs md:tw-text-lg tw-place-self-center"
              :class="
                filterEventsByCategoryAndDate('Embajadores', day?.Dateymd)
                  .length > 0
                  ? 'tw-bg-red-350 tw-text-white'
                  : 'tw-bg-[#F0F0F0] tw-text-[#F0F0F0] tw-select-none'
              "
            >
              {{
                filterEventsByCategoryAndDate("Embajadores", day?.Dateymd)
                  .length
              }}
            </div>
            <div
              class="tw-w-5 tw-h-5 lg:tw-w-8 lg:tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-xs md:tw-text-lg tw-place-self-center"
              :class="
                filterEventsByCategoryAndDate('GoFlex', day?.Dateymd).length > 0
                  ? 'tw-bg-green-400 tw-text-white'
                  : 'tw-bg-[#F0F0F0] tw-text-[#F0F0F0] tw-select-none'
              "
            >
              {{ filterEventsByCategoryAndDate("GoFlex", day?.Dateymd).length }}
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-2 tw-mt-4">
        <div class="tw-flex tw-justify-center tw-items-center tw-gap-1 lg:tw-gap-2">
          <div
            class="tw-w-4 tw-h-4 lg:tw-w-8 lg:tw-h-8 tw-rounded-full tw-bg-yellow-400 tw-flex tw-items-center tw-justify-center tw-text-xs md:tw-text-lg tw-place-self-center"
          />
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            Tus actividades
          </p>
          <comp-general-tooltip
            tooltip-text="Podrás ver las actividades que tenemos para ti como concursos y trivias."
            color="#ffbf35"
          />
        </div>
        <div class="tw-flex tw-justify-center tw-items-center tw-gap-2">
          <div
            class="tw-w-4 tw-h-4 lg:tw-w-8 lg:tw-h-8 tw-rounded-full tw-bg-red-350 tw-flex tw-items-center tw-justify-center tw-text-xs md:tw-text-lg tw-place-self-center"
          />
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            Embajadores
          </p>
          <comp-general-tooltip
            tooltip-text="Podrás ver las actividades fijas de Embajadores como el producto del mes."
            color="#FA2727"
          />
        </div>
        <div
          class="tw-flex tw-justify-center tw-items-center tw-gap-2"
          v-if="goFlexStore.teamRequests.length > 0"
        >
          <div
            class="tw-w-4 tw-h-4 lg:tw-w-8 lg:tw-h-8 tw-rounded-full tw-bg-green-400 tw-flex tw-items-center tw-justify-center tw-text-xs md:tw-text-lg tw-place-self-center"
          />
          <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
            B. de tiempo
          </p>
          <comp-general-tooltip
            tooltip-text="Podrás ver las solicitudes aprobadas para tu equipo."
            color="#00bf79"
          />
        </div>
      </div>
    </div>

    <div
      v-if="(props.switch && mobile()) || !mobile()"
      class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-5"
    >
      <!-- Mobile Days Selector -->
      <div
        class="tw-grid tw-grid-cols-col3_fit_first_and_last lg:tw-hidden tw-gap-4"
      >
        <div class="tw-flex tw-items-center" @click="backwardDate">
          <i
            class="icon-flecha-izq tw-text-red-350 tw-ml-1 tw-text-sm lg:tw-text-lg"
          ></i>
        </div>
        <div class="tw-grid tw-grid-cols-5 tw-gap-3">
          <div
            class="tw-rounded-lg tw-flex tw-flex-col tw-gap-2 tw-justify-between tw-items-center tw-py-2 tw-px-4"
            :class="
              isSelectedDay(day)
                ? 'tw-bg-red-350 tw-text-white'
                : 'tw-bg-pink-100 tw-text-black tw-cursor-pointer'
            "
            v-for="(day, idx) in calendarStore.surroundingDays"
            :key="idx"
            @click="changeSelectedDate(day)"
          >
            <p class="tw-m-0 tw-font-roboto tw-text-xs tw-uppercase">
              {{ day.dayShort }}
            </p>
            <p class="tw-m-0 tw-font-roboto tw-text-lg">{{ day.date }}</p>
          </div>
        </div>
        <div class="tw-flex tw-items-center" @click="forwardDate">
          <i
            class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-sm lg:tw-text-lg"
          ></i>
        </div>
      </div>
      <!-- Mobile Days Selector -->

      <div class="tw-hidden lg:tw-flex tw-justify-center tw-items-center">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-base md:tw-text-2xl"
        >
          {{ selectedDate.DayAndMonth }}
        </p>
      </div>
      <div
        class="tw-max-h-[500px] lg:tw-max-h-[900px] tw-w-full tw-overflow-y-scroll invisible-scrollbar tw-flex tw-flex-col tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          v-if="
            filterEventsByCategoryAndDate('Personal', selectedDate.Dateymd)
              .length > 0
          "
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-2xl">
            Tus Actividades
          </p>
          <comp-event-card
            v-for="(event, idx) in filterEventsByCategoryAndDate(
              'Personal',
              selectedDate.Dateymd
            )"
            :key="idx"
            :event-name="event?.name"
            :event-desc="event?.desc"
            :event-category="event?.category?.name"
            :event-date="event?.date"
            icon="assets/img/dummy/demo_icon_1.png"
            @click.native="openEventModal(event)"
          />
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          v-if="
            filterEventsByCategoryAndDate('Embajadores', selectedDate.Dateymd)
              .length > 0
          "
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-2xl">
            Actividades de Embajadores
          </p>
          <comp-event-card
            v-for="(event, idx) in filterEventsByCategoryAndDate(
              'Embajadores',
              selectedDate.Dateymd
            )"
            :key="idx"
            :event-name="event?.name"
            :event-desc="event?.desc"
            :event-category="event?.category?.name"
            :event-date="event?.date"
            icon="assets/img/dummy/demo_icon_1.png"
            @click.native="openEventModal(event)"
          />
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          v-if="
            filterEventsByCategoryAndDate('GoFlex', selectedDate.Dateymd)
              .length > 0
          "
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-2xl">
            Beneficios de Tiempo
          </p>
          <comp-event-card
            v-for="(event, idx) in filterEventsByCategoryAndDate(
              'GoFlex',
              selectedDate.Dateymd
            )"
            :key="idx"
            :event-name="capitalizeEachWord(event?.core_employee_name)"
            :event-desc="`Has solicitado ${request_type(
              event?.option_catalog
            )} para esta fecha.`"
            :event-category="`B. de tiempo`"
            :event-date="event?.requested_date"
            icon="assets/img/goflex/icon/icon_time.png"
            @click.native="pushLink('/goflex/lineManagerTeamRequests')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
