<script setup>
import { onMounted, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const user = storeToRefs(userStore.user);
const isChangePassModalOpen = ref(false);

const closeChangePassModal = () => {
  isChangePassModalOpen.value = false;
};

onMounted(() => {
  if (
    user.password_expiration_date == null ||
    new Date(user.password_expiration_date) < new Date(Date.now())
  ) {
    isChangePassModalOpen.value = true;
  }
});
</script>
<template>
  <general-modal
    :maxHeight="{ base: 600, md: 600, lg: 600, xl: 600 }"
    :modalWidth="{ md: 600, lg: 600, xl: 600 }"
    modal-name="uploadActivityFile"
    v-if="isChangePassModalOpen"
    :isShowModal="isChangePassModalOpen"
    @close="closeChangePassModal"
    overall
    :closable="false"
    width-fit
    height-fit
  >
    <ChangePassword />
  </general-modal>
</template>
