import { defineStore } from "pinia";
import { ref } from "vue";

import axiosPeopleTour, { gamificationURL } from "@/services/axiosPeopleTour";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

import {
  compareArraysItemByItem,
  compareArrayWithObjectByKey,
  compareTwoObjectsByKey,
} from "@/helpers/arrayHelpers";
import { isPastDate } from "@/helpers/datesHelpers";

const uri = {
  stages: "/peopleTour/stages",
  rewards: "/peopleTour/rewards",
  employeeToinsBalance: "/peopleTour/employeeToinsByStage",
  redeem: "/peopleTour/redeem",
};

export const usePeopleTourStore = defineStore("peopleTour", () => {
  const currentTourId = ref(0);
  const currentTourName = ref("");
  const mainTourData = ref({});
  const stages = ref([]);
  const stations = ref([]);
  const pointsProgressStatus = ref({});
  const currentStageData = ref({});
  const currentStageActivitiesData = ref([]);
  const currentActivityData = ref({});
  const currentBP = ref(null);
  const BPList = ref([]);
  const stagesList = ref([]);
  const rewardsList = ref([]);
  const rewardDetail = ref({});
  const employeeToinsBalance = ref(0);

  // Auxiliary functions
  function emptyCurrentStageData() {
    currentStageData.value = {};
  }

  function getMedia(source, included) {
    const compare = compareArraysItemByItem(source, included);
    getUrlMedia(compare, included);
  }

  function getUrlMedia(media, included) {
    mainTourData.value.media = [];
    media.forEach((item) => {
      if (item.type === "media--video") {
        const mediaVideo = {
          type: "video",
          uri: null,
        };

        const fileId = item.relationships?.field_media_video_file?.data?.id;
        const file = included.find(
          (item) => item.type === "file--file" && item.id === fileId
        );

        if (file) {
          mediaVideo.uri = `${gamificationURL}${file?.attributes?.uri.url}`;
        }

        mainTourData.value.media.push(mediaVideo);
      }

      if (item.type === "media--image") {
        const mediaImage = {
          type: "image",
          uri: null,
        };

        const fileId = item.relationships?.field_media_image?.data?.id;
        const file = included.find(
          (item) => item.type === "file--file" && item.id === fileId
        );

        if (file) {
          mediaImage.uri = `${gamificationURL}${file?.attributes?.uri.url}`;
        }

        mainTourData.value.media.push(mediaImage);
      }

      if (item.type === "media--remote_video") {
        const mediaRemoteVideo = {
          type: "remote_video",
          uri: item.attributes?.field_media_oembed_video,
        };

        mainTourData.value.media.push(mediaRemoteVideo);
      }
    });
  }

  function getStagesPoints(node_id) {
    const { stages: stagesPoints } = pointsProgressStatus.value.points;
    const { stages: stagesProgress } = pointsProgressStatus.value.progress;
    const { status } = pointsProgressStatus.value;

    const combinedStages = Object.keys(stagesPoints).reduce((acc, id) => {
      const stageData = {};
      if (stagesProgress[id]) {
        stageData.id = id;
        stageData.points = stagesPoints[id];
        stageData.progress = stagesProgress[id];
        stageData.status = status[id] ? status[id] : null;
        acc.push(stageData);
      }
      return acc;
    }, []);

    return combinedStages.find((item) => Number(item.id) === Number(node_id));
  }

  function setActivityStatus(activity) {
    const final_date = new Date(activity.end_date);
    const today = new Date();

    if (
      activity.field_status === "Aprobada" ||
      activity.field_status === "Rechazada" ||
      activity.field_status === "Sin realizar" ||
      activity.field_status === "Por hacer"
    ) {
      return;
    }

    if (!activity.field_status) {
      if (today <= final_date) {
        activity.field_status = "Por hacer";
      } else {
        activity.field_status = "Sin realizar";
      }
    }
  }

  function getPendingApprovedActivities(activities) {
    let pending = 0;
    let finished = 0;

    for (let obj in activities) {
      if (
        (!activities[obj].field_status ||
          activities[obj].field_status === "Pendiente" ||
          activities[obj].field_status === "Por hacer") &&
        !isPastDate(activities[obj].end_date)
      ) {
        pending++;
      } else if (
        activities[obj].field_status === "Aprobada" ||
        activities[obj].field_status === "Rechazada" ||
        activities[obj].field_status === "Finalizado" ||
        isPastDate(activities[obj].end_date)
      ) {
        finished++;
      }
    }

    return {
      pending,
      finished,
    };
  }

  function filterStageAndStations(stage_nid) {
    const stage = stages.value.find((item) => item.node_id === stage_nid);
    const station = stations.value.find((item) => item.node_id === stage_nid);

    if (stage) {
      currentStageData.value = stage;
      return true;
    }
    if (station) {
      currentStageData.value = station;
      return true;
    }
    return false;
  }

  function filterCurrentStageActivities(activity_nid) {
    const activity = currentStageActivitiesData.value.find(
      (item) => item.node_id === activity_nid
    );

    if (activity) {
      currentActivityData.value = activity;
      return true;
    }
    return false;
  }

  // API call functions
  async function getTour(sharp_id, band) {
    if (currentTourId.value !== 0) {
      await getTourPoints(currentTourId.value, {
        sharp_id: sharp_id,
        band: band,
      });
      return;
    }

    const response = await axiosPeopleTour.get({
      uri: "tour",
      filter: [
        {
          field: "field_bu.entity.name",
          value: "CO",
        },
      ],
    });
    if (response.error) {
      console.error(response.error);
      return;
    }

    const { data } = response;

    currentTourName.value = data[0]?.attributes?.title;
    currentTourId.value = data[0]?.attributes?.drupal_internal__nid;

    await getTourPoints(currentTourId.value, {
      sharp_id: sharp_id,
      band: band,
    });
  }

  async function getTourPoints(id, data) {
    const response = await axiosPeopleTour.post({
      uri: "points",
      queryParams: {
        id: id,
      },
      data: data,
    });

    if (response.error) {
      console.error(response.error);
      return;
    }

    pointsProgressStatus.value = response;

    const total_points = Number(response.points?.total_points);
    const total_progress = Number(response.progress?.total_progress);

    mainTourData.value = {
      ...mainTourData.value,
      total_points: total_points,
      total_progress: total_progress,
    };

    return true;
  }

  async function getTourData(sharp_id, band) {
    await getTour(sharp_id, band);

    const response = await axiosPeopleTour.get({
      uri: "tour",
      includes: [
        "field_image",
        "field_media",
        "field_media.field_media_image",
        "field_media.field_media_video_file",
      ],
      filter: [
        {
          field: "drupal_internal__nid",
          value: currentTourId.value,
        },
      ],
    });

    if (response.error) {
      console.error(response.error);
      return;
    }

    const { data, included } = response;

    extractTourData(data, included);
    await getTourStages(band);
  }

  function extractTourData(data, included) {
    const {
      title,
      field_description,
      link,
      drupal_internal__nid,
      field_csat_enlace,
      field_csat_descripcion,
    } = data[0].attributes;

    mainTourData.value = {
      ...mainTourData.value,
      title,
      description: field_description,
      link,
      id: drupal_internal__nid,
      field_csat_enlace,
      field_csat_descripcion,
    };

    mainTourData.value.image = `${gamificationURL}${getImageURI(
      data,
      included
    )}`;
    getMedia(data[0].relationships.field_media.data, included);
  }

  function getImageURI(data, included) {
    const imageId = data[0].relationships.field_image.data.id;
    const file = included.find(
      (item) => item.type === "file--file" && item.id === imageId
    );

    return file ? file.attributes.uri.url : "";
  }

  async function getTourStages(band) {
    const response = await axiosPeopleTour.get({
      uri: "stage",
      includes: ["field_image", "field_type_stage"],
      filter: [
        {
          field: "field_bu.entity.name",
          value: "CO",
        },
        {
          field: "field_banda.entity.name",
          value: band,
        },
        {
          field: "field_tour.entity.nid",
          value: currentTourId.value,
        },
      ],
      sort: true,
    });
    if (response.error) {
      console.error(response.error);
      return;
    }

    const { data, included } = response;

    let tempStages = [];

    data.forEach((obj) => {
      const stage = {
        id: obj?.id,
        title: obj?.attributes?.title,
        node_id: obj?.attributes?.drupal_internal__nid,
        description: obj?.attributes?.field_description,
        type:
          included.find(
            (item) => item.id === obj?.relationships?.field_type_stage?.data?.id
          )?.attributes?.name === "Estaciones de lider"
            ? "station"
            : "stage",
        color: obj?.attributes?.field_color,
        end_date: obj?.attributes?.field_end_date,
        start_date: obj?.attributes?.field_start_date,
      };

      stage.image = `${gamificationURL}${
        included.find(
          (item) => item.id === obj?.relationships?.field_image?.data?.id
        )?.attributes?.uri.url
      }`;

      stage.preTitle = stage.type === "station" ? "Estación de" : "Etapa de";

      const stagePointsProgress = getStagesPoints(stage.node_id);

      ({
        points: stage.points,
        progress: stage.progress,
        status: stage.status,
      } = stagePointsProgress || {
        points: 0,
        progress: 0,
        status: null,
      });

      tempStages.push(stage);
    });

    await Promise.all(
      tempStages.map(async (stage) => {
        await getCurrentStageActivities(stage.node_id).then((activities) => {
          stage.activities = activities;
        });

        const { pending, finished } = getPendingApprovedActivities(
          stage.activities
        );

        stage.pending_activities = pending;
        stage.approved_activities = finished;
      })
    );

    stages.value = tempStages.filter((stage) => stage.type === "stage");
    stations.value = tempStages.filter((stage) => stage.type === "station");
  }

  async function getCurrentStageData(stage_nid, sharp_id, band) {
    if (filterStageAndStations(stage_nid)) {
      return true;
    }
    await getTour(sharp_id, band);

    const response = await axiosPeopleTour.get({
      uri: "stage",
      filter: [
        {
          field: "drupal_internal__nid",
          value: stage_nid,
        },
        {
          field: "field_bu.entity.name",
          value: "CO",
        },
        {
          field: "field_banda.entity.name",
          value: band,
        },
        {
          field: "field_tour.entity.nid",
          value: currentTourId.value,
        },
      ],
      includes: ["field_image", "field_type_stage"],
      sort: true,
    });

    if (response.error) {
      console.error(response);
      return false;
    }

    if (response.data.length === 0) {
      return false;
    }

    const { data, included } = response;

    ({
      title: currentStageData.value.title,
      field_description: currentStageData.value.description,
      field_color: currentStageData.value.color,
      drupal_internal__nid: currentStageData.value.id,
    } = data[0].attributes);

    currentStageData.value.image = `${gamificationURL}${
      included.find(
        (item) => item.id === data[0]?.relationships?.field_image?.data?.id
      )?.attributes?.uri.url
    }`;

    currentStageData.value.type =
      included.find(
        (item) => item.id === data[0]?.relationships?.field_type_stage?.data?.id
      )?.attributes?.name === "Estaciones de líder"
        ? "station"
        : "stage";

    currentStageData.value.preTitle =
      currentStageData.value.type === "station" ? "Estación de" : "Etapa de";

    const stagePointsProgress = getStagesPoints(currentStageData.value.id);

    ({
      points: currentStageData.value.points,
      progress: currentStageData.value.progress,
      status: currentStageData.value.status,
    } = stagePointsProgress || {
      points: 0,
      progress: 0,
      status: null,
    });

    const { pending, finished } = getPendingApprovedActivities(
      currentStageData.value.status
    );

    currentStageData.value.pending_activities = pending;
    currentStageData.value.approved_activities = finished;

    return true;
  }

  async function getCurrentStageActivities(stage_nid) {
    const response = await axiosPeopleTour.get({
      uri: "activity",
      filter: [
        {
          field: "field_tour.entity.nid",
          value: currentTourId.value,
        },
        {
          field: "field_stage.entity.nid",
          value: stage_nid,
        },
      ],
      includes: [
        "field_image",
        "field_stage",
        "field_type",
        "field_csat_imagen",
      ],
      sort: true,
    });

    if (response.error) {
      console.error(response.error);
      return;
    }

    const {
      points: { activities: activitiesPoints },
    } = pointsProgressStatus.value;

    const { data, included } = response;

    let tempActivities = [];

    data.forEach((obj) => {
      const activity = {
        id: obj?.id,
        title: obj?.attributes?.title,
        description: obj?.attributes?.field_description,
        detail: obj?.attributes?.field_detail,
        node_id: obj?.attributes?.drupal_internal__nid,
        type: included.find(
          (item) => item.id === obj?.relationships?.field_type?.data?.id
        )?.attributes?.name,
        related_stage: included.find(
          (item) => item.id === data[0].relationships?.field_stage?.data?.id
        )?.attributes?.drupal_internal__nid,
        max_points: obj?.attributes?.field_points,
        gamification_points: obj?.attributes?.field_gamification_points,
        start_date: obj?.attributes?.field_start_date,
        end_date: obj?.attributes?.field_end_date,
        buttons: obj?.attributes?.field_buttons,
        status: obj?.attributes?.status,
        field_csat_enlace: obj?.attributes?.field_csat_enlace,
      };

      activity.image = `${gamificationURL}${
        included.find(
          (item) => item.id === obj?.relationships?.field_image?.data?.id
        )?.attributes?.uri?.url
      }`;

      activity.field_csat_imagen = `${gamificationURL}${
        included.find(
          (item) => item.id === obj?.relationships?.field_csat_imagen?.data?.id
        )?.attributes?.uri?.url
      }`;

      if (activitiesPoints) {
        if (activitiesPoints[activity.node_id]) {
          activity.points = activitiesPoints[activity.node_id];
        } else {
          activity.points = 0;
        }
      }

      tempActivities.push(activity);
    });

    const { status } = pointsProgressStatus.value;

    if (status) {
      tempActivities = compareArrayWithObjectByKey(
        tempActivities,
        status[stage_nid],
        "node_id",
        "field_status"
      );
    }

    tempActivities.forEach((activity) => {
      setActivityStatus(activity);
    });

    const { pending, finished } = getPendingApprovedActivities(tempActivities);

    currentStageData.value.pending_activities = pending;
    currentStageData.value.approved_activities = finished;

    currentStageActivitiesData.value = tempActivities;
    return tempActivities;
  }

  async function getCurrentActivityData(activity_nid, sharp_id, band) {
    if (filterCurrentStageActivities(activity_nid)) {
      return true;
    }

    const response = await axiosPeopleTour.get({
      uri: "activity",
      filter: [
        {
          field: "drupal_internal__nid",
          value: activity_nid,
        },
      ],
      includes: [
        "field_image",
        "field_stage",
        "field_type",
        "field_csat_imagen",
      ],
    });

    if (response.error) {
      console.error(response.error);
      return false;
    }

    if (response.data.length === 0) {
      return false;
    }

    const { data, included } = response;

    let activity = {
      id: data[0].id,
      title: data[0]?.attributes?.title,
      description: data[0]?.attributes?.field_description,
      detail: data[0]?.attributes?.field_detail,
      node_id: data[0]?.attributes?.drupal_internal__nid,
      type: included.find(
        (item) => item.id === data[0].relationships?.field_type?.data?.id
      )?.attributes?.name,
      related_stage: included.find(
        (item) => item.id === data[0].relationships?.field_stage?.data?.id
      )?.attributes?.drupal_internal__nid,
      points: data[0]?.attributes?.field_points,
      gamification_points: data[0]?.attributes?.field_gamification_points,
      start_date: data[0]?.attributes?.field_start_date,
      end_date: data[0]?.attributes?.field_end_date,
      buttons: data[0]?.attributes?.field_buttons,
      status: data[0]?.attributes?.status,
      field_csat_enlace: data[0]?.attributes?.field_csat_enlace,
    };

    if (Object.keys(currentStageData.value).length === 0) {
      const responseStage = await getCurrentStageData(
        activity.related_stage,
        sharp_id,
        band
      );

      if (!responseStage) {
        return false;
      }
    }

    activity.image = `${gamificationURL}${
      included.find(
        (item) => item.id === data[0].relationships?.field_image?.data?.id
      )?.attributes?.uri.url
    }`;

    activity.field_csat_imagen = `${gamificationURL}${
      included.find(
        (item) => item.id === data[0].relationships?.field_csat_imagen?.data?.id
      )?.attributes?.uri.url
    }`;

    await getTourPoints(currentTourId.value, {
      sharp_id: sharp_id,
      band: band,
    });

    const { status } = pointsProgressStatus.value;

    if (status) {
      activity = compareTwoObjectsByKey(
        activity,
        status[activity.related_stage],
        activity.node_id,
        "field_status"
      );
    }

    setActivityStatus(activity);

    currentActivityData.value = activity;

    return true;
  }

  async function getBPList(sharp_id) {
    const response = await axiosPeopleTour.get({
      uri: "bp",
      queryParams: {
        sharp: sharp_id,
        bu: "CO",
      },
    });

    if (response.error) {
      console.error(response.error);
      return;
    }

    const { bp, bps } = response;

    bps.forEach((item, index) => {
      item.id = Number(index);
    });

    currentBP.value = {
      sharp_id: bp,
      name: bps.find((item) => item.field_sharp === bp)?.name,
    };
    BPList.value = bps;
    return bp;
  }

  async function postBP(sharp_id, bp_id) {
    const response = await axiosPeopleTour.post({
      uri: "bpAssign",
      queryParams: {
        sharp: sharp_id,
        bp: bp_id,
      },
    });

    if (response.status === "error") {
      console.error(response.messaje);
      return response;
    }

    return response.status === "ok";
  }

  async function postEvidence(sharp_id, activity_id, image) {
    let formData = new FormData();

    formData.append("sharp", sharp_id);
    formData.append("activity", activity_id);
    formData.append("image", image);

    const response = await axiosPeopleTour.post({
      uri: "uploadEvidence",
      data: formData,
    });

    if (response.status === "error") {
      console.error(response.messaje);
      return false;
    }

    return response;
  }

  // Funciones internas de Embajadores para el servicio de recompensas y puntos
  async function getStagesList() {
    const response = await AxiosBeerOffice.get(uri.stages, true, {});
    if (response.error) {
      console.error(response.error);
      return;
    }
    stagesList.value = response;
    return true;
  }

  async function getRewardsByStage(stage_id, sharp_id, band) {
    const response = await AxiosBeerOffice.get(
      `${uri.rewards}?filter={"people_tour_stage_id":${stage_id}}`,
      true,
      {}
    );
    if (response.error) {
      console.error(response.error);
      return;
    }
    rewardsList.value = response;

    if (stagesList.value.length === 0) {
      await getStagesList();
    }

    const currentStageName = stagesList.value.find(
      (item) => item.id === stage_id
    )?.name;

    if (currentStageName) {
      await getEmployeeToinsByStage(currentStageName, sharp_id, band);

      for (let i = 0; i < rewardsList.value.length; i++) {
        const stage = stagesList.value.find(
          (item) => item.id === rewardsList.value[i].people_tour_stage_id
        );
        rewardsList.value[i].people_tour_stage_name = stage.name;
        rewardsList.value[i].people_tour_stage_toins =
          employeeToinsBalance.value;
      }
    }

    return true;
  }

  async function getRewardsRandom(number) {
    const response = await AxiosBeerOffice.get(uri.rewards, true, {});
    if (response.error) {
      console.error(response.error);
      return;
    }
    const rewards = response;
    rewards.sort(() => Math.random() - 0.5);
    rewardsList.value = rewards.slice(0, number);
    return true;
  }

  async function getRewardDetail(reward_id, sharp_id, band) {
    const response = await AxiosBeerOffice.get(
      `${uri.rewards}/${reward_id}`,
      true,
      {}
    );
    if (response.error) {
      console.error(response.error);
      return;
    }
    rewardDetail.value = response;

    if (stagesList.value.length === 0) {
      await getStagesList();
    }

    const stage = stagesList.value.find(
      (item) => item.id === response.people_tour_stage_id
    );

    rewardDetail.value.people_tour_stage_name = stage.name;

    await getEmployeeToinsByStage(stage.name, sharp_id, band).then(() => {
      rewardDetail.value.people_tour_stage_toins = employeeToinsBalance.value;
    });

    return true;
  }

  async function getEmployeeToinsByStage(stage_name, sharp_id, band) {
    if (currentTourName.value === "") {
      await getTour(sharp_id, band);
    }

    const response = await AxiosBeerOffice.get(
      `${uri.employeeToinsBalance}?tour=${currentTourName.value}&stage=${stage_name}`,
      true,
      {}
    );
    if (response.error) {
      console.error(response.error);
      return;
    }
    const { people_tour_toins_stage_balance } = response;
    employeeToinsBalance.value = people_tour_toins_stage_balance;
    return true;
  }

  async function postRedeemReward(data) {
    const response = await AxiosBeerOffice.post(`${uri.redeem}`, true, data);
    if (response.error) {
      console.error(response);
      console.error("response error: ", response.error);
      console.error("response status: ", response.error.status);
      return response.error;
    }

    return response;
  }

  return {
    mainTourData,
    currentStageData,
    currentStageActivitiesData,
    currentActivityData,
    currentBP,
    BPList,
    getTour,
    getTourData,
    getCurrentStageData,
    getCurrentStageActivities,
    getCurrentActivityData,
    getBPList,
    postBP,
    postEvidence,
    emptyCurrentStageData,
    stages,
    stations,
    getStagesList,
    getRewardsByStage,
    getRewardsRandom,
    getRewardDetail,
    employeeToinsBalance,
    rewardsList,
    rewardDetail,
    stagesList,
    postRedeemReward,
  };
});
