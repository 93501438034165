<template>
  <LineChart :chartdata="this.chartdata" :options="this.options" />
</template>

<script>
import LineChart from "@/components/graphs/line/line";

export default {
  name: "Line-1",
  components: {
    LineChart,
  },
  props: {
    propChartdata: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.chartdata.labels = this.propChartdata.labels;
    this.chartdata.datasets = this.propChartdata.datasets;
  },
  data: () => ({
    chartdata: {
      labels: null,
      datasets: null,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            display: true,
          },
        ],
      },
      legend: {
        display: true,
      },
      tooltips: {
        enabled: true,
      },
    },
  }),
};
</script>