import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  goFlexUri: "/goFlex",
};

export const useGoFlexStore = defineStore("goFlex", () => {
  const catalog = ref({});
  const optionDetail = ref({});
  const requests = ref([]);
  const teamRequests = ref([]);

  async function actGetGoFlexCatalog(cat) {
    const response = await AxiosBeerOffice.get(
      `${uri.goFlexUri}/flexTimeByCatalog?catalog=${cat}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    catalog.value = data;
    return data;
  }

  async function actGetGoFlexOptionDetail(optionId) {
    const response = await AxiosBeerOffice.get(
      `${uri.goFlexUri}/flexTimeOptions/${optionId}`,
      true,
      {}
    );
    if (response.error) return response.error;
    optionDetail.value = response;
    return response;
  }

  async function actGetGoFlexRequests() {
    const response = await AxiosBeerOffice.get(
      `${uri.goFlexUri}/flexTimeEmployeeRequests`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    requests.value = data;
    return data;
  }

  async function actGetGoFlexTeamRequests() {
    const response = await AxiosBeerOffice.get(
      `${uri.goFlexUri}/flexTimeEmployeeRequests/byLineManager`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    teamRequests.value = data;
    return data;
  }

  async function actPostGoFlexEmployeeRequest(data) {
    const response = await AxiosBeerOffice.post(
      `${uri.goFlexUri}/flexTimeEmployeeRequests/${data.id}`,
      true,
      data.data
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostCancelGoFlexEmployeeRequest(id) {
    const response = await AxiosBeerOffice.post(
      `${uri.goFlexUri}/flexTimeEmployeeRequests/${id}/cancel`,
      true,
      {}
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostApproveGoFlexEmployeeRequest(data) {
    const response = await AxiosBeerOffice.post(
      `${uri.goFlexUri}/flexTimeEmployeeRequests/approve`,
      true,
      {
        request_ids: data,
      }
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostRejectGoFlexEmployeeRequest(data) {
    const response = await AxiosBeerOffice.post(
      `${uri.goFlexUri}/flexTimeEmployeeRequests/reject`,
      true,
      {
        request_ids: data,
      }
    );
    if (response.error) return response.error;
    return response;
  }

  return {
    catalog,
    optionDetail,
    requests,
    teamRequests,
    actGetGoFlexCatalog,
    actGetGoFlexOptionDetail,
    actGetGoFlexRequests,
    actGetGoFlexTeamRequests,
    actPostGoFlexEmployeeRequest,
    actPostRejectGoFlexEmployeeRequest,
    actPostApproveGoFlexEmployeeRequest,
    actPostCancelGoFlexEmployeeRequest,
  };
});
