<template>
  <div class="d-flex mb-5">
    <div
      v-for="item in itemsMenu"
      :key="item.id"
      class="flex-grow-1 pr-1"
      role="button"
      @click="changeStepValidate(item)"
    >
      <p
        class=" tw-text-xs  mb-2"
        :class="item.active ? 'tw-text-red-400' : 'tw-text-gray-300'"
      >
        {{ item.name }}
      </p>
      <div
        class="he-6"
        :class="item.active ? 'tw-bg-red-400' : 'tw-bg-gray-200'"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "MenuCv",
  mounted() {
    this.changeStep(this.actualStep);
  },
  computed: {
    ...mapState("modCv", ["actualStep", "itemsMenu", "formData"]),
  },
  methods: {
    ...mapActions("modCv", ["changeStep"]),
    changeStepValidate(item) {
      let canChangeStep = false;
      switch (item.id) {
        case 0:
          canChangeStep = true;
          break;
        case 1:
          this.formData.education.items[0].institution === ""
            ? (canChangeStep = false)
            : (canChangeStep = true);
          break;

        case 2:
          this.formData.work_experience.items[0].company === ""
            ? (canChangeStep = false)
            : (canChangeStep = true);
          break;

        case 3:
          this.formData.courses.items[0].course === ""
            ? (canChangeStep = false)
            : (canChangeStep = true);
          break;

        case 4:
          this.formData.languages.items[0].language === ""
            ? (canChangeStep = false)
            : (canChangeStep = true);
          break;

        default:
          canChangeStep = false;
          break;
      }
      if (canChangeStep) {
        this.changeStep(item.id);
      }
    },
  },
};
</script>