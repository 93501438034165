<script setup>
import { computed, onBeforeMount, ref } from "vue";
import config from "@/services/config";
import { useGamificationStore } from "@/stores/gamificationStore";
import { useUserStore } from "@/stores/userStore";
import imgAvatar from "@/assets/img/gamification/avatar.png";
import { useRouter } from "vue-router/composables";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const gamificationStore = useGamificationStore();
const userStore = useUserStore();
const uriResources = config.beerOfficeURL;
const router = useRouter();
const isLoading = ref(false);
const filterInit = ref(0);
const filterReward = ref([]);
const filterRank = ref({});
const selectedReward = ref({});
const rewardBest = ref([]);
const randomAvatar = ref(Math.random());

const barProgress = computed(() => {
  const user = userStore.user;
  const currentRank = user?.current_rank;

  if (currentRank?.next !== null && currentRank?.next !== undefined) {
    const nextPoints = currentRank.next.points;
    const gamificationPoints = user?.gamification_points || 0;

    // Calcular el progreso
    const progress = (gamificationPoints * 100) / nextPoints;

    // Limitar el progreso a un máximo del 100%
    return Math.min(progress, 100);
  }

  // Si no hay próximo rango, el progreso es 100%
  return 100;
});

const chooseAvatar = computed(() => {
  const user = userStore.user;
  const isPredefinedAvatar = user?.is_predefined_avatar === 1;

  return isPredefinedAvatar
    ? user?.predefined_avatar_image
    : user?.custom_avatar_image || imgAvatar;
});

const remainingPoints = computed(() => {
  const user = userStore.user;
  const currentRank = user?.current_rank?.next;

  if (!currentRank) {
    return 0;
  }

  return Math.max(currentRank.points - (user?.gamification_points || 0), 0);
});

const onChangeEventRank = (event) => {
  const selectedRankId = event.target.value;

  filterRank.value =
    gamificationStore.userRanks.find(
      (userRank) => userRank.id === selectedRankId
    ) || [];
  filterReward.value = gamificationStore.rewards.filter((reward) => {
    return reward.gami_level_id === filterRank.value.gami_level_id;
  });
  rewardBest.value = filterReward.value.filter((reward) => {
    return reward.featured === 1;
  });

  if (filterReward.value.length > 0) {
    filterReward.value[0].isActive = true;
    selectedReward.value = filterReward.value[0];
  }
};

const toggleRewardActive = (reward_id) => {
  filterReward.value.forEach((reward) => {
    reward.isActive = reward.id === reward_id;
    if (reward.isActive) {
      selectedReward.value = reward;
    }
  });
};

const pushDataLayerEvent = (event) => {
  pushDataLayer({
    event_category: "Juega",
    event_action: "La maltería",
    component_name: "Boton",
    element_text: event.target.innerText,
  });
};

const goToLink = (link, text) => {
  router.push(link);
  pushDataLayer({
    event_category: "Juega",
    event_action: "La maltería",
    component_name: "Boton",
    element_text: text,
  });
};

onBeforeMount(() => {
  isLoading.value = true;
  filterInit.value = userStore.user?.current_rank?.current?.gami_level_id;
  gamificationStore
    .actGetGamificationRewards()
    .then(() => {
      filterReward.value = gamificationStore.rewards.filter((reward) => {
        return (
          reward?.gami_level_id ===
          userStore.user?.current_rank?.current?.gami_level_id
        );
      });
      filterReward.value.map((reward) => {
        reward.isActive = false;
      });
      filterReward.value[0].isActive = true;
      selectedReward.value = filterReward.value[0];
    })
    .catch((err) => {
      console.error("Error: ", err);
    })
    .finally(() => {
      rewardBest.value = filterReward.value.filter((reward) => {
        return reward?.featured === 1;
      });
      isLoading.value = false;
    });
  gamificationStore.actGetGamificationUserRanks(
    userStore.user?.prof_profile_id
  );
});
</script>

<template>
  <div>
    <!-- Encabezado -->
    <header
      class="tw-hidden lg:tw-block tw-container tw-mx-auto tw-max-w-screen-xl mt-2 mt-md-4"
    >
      <div class="tw-grid tw-grid-cols-12">
        <!-- Columna del Título y Descripción -->
        <div class="tw-col-span-12 md:tw-col-span-6">
          <!-- Título -->
          <h1
            class="tw-flex tw-font-tolyerBold fs-md-42 text-uppercase tw-text-red-400 m-0 p-0"
          >
            La Maltería
          </h1>

          <!-- Descripción -->
          <div class="tw-hidden md:tw-flex">
            <p
              class="tw-font-roboto tw-text-base fs-md-16 line-height-24 tw-text-gray-600"
            >
              Completa misiones, gana puntos y redime premios
            </p>
          </div>
        </div>

        <!-- Columna del Enlace "Cómo funciona" -->
        <div
          class="tw-col-span-12 md:tw-col-span-6 tw-flex tw-justify-center md:tw-justify-end tw-items-center py-0"
        >
          <div class="tw-flex md:tw-flex-row">
            <div
              class="tw-col tw-col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 md:tw-order-first lg:tw-order-none"
            >
              <router-link
                to="/gamification/how-it-work"
                class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                @click.native="pushDataLayerEvent($event)"
              >
                ¿Cómo funciona?
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Contenedor Banner y Salario -->
    <section
      class="tw-container tw-mx-auto tw-max-w-screen-xl my-2 mt-md-3 mb-md-5"
    >
      <div class="row">
        <!-- Columna Avatar -->
        <div class="col-12 col-md-3 p-2">
          <div
            class="tw-rounded-lg tw-shadow-md max-wi-280 he-300 tw-overflow-hidden mx-auto tw-relative"
          >
            <!-- Avatar -->
            <div
              class="backgrounddiv tw-w-full tw-h-full tw-z-0 tw-absolute"
            ></div>
            <div class="tw-z-1 tw-absolute tw-bottom-0 tw-h-full tw-w-full">
              <div class="d-flex tw-h-full">
                <img
                  :src="
                    uriResources + '/' + chooseAvatar + '?v=' + randomAvatar
                  "
                  alt="avatar"
                  class="tw-w-full tw-h-full tw-object-cover -tw-bottom-4"
                />
              </div>
            </div>
          </div>

          <!-- Editar Avatar -->
          <div
            class="tw-rounded-lg tw-shadow-md max-wi-280 mx-auto mt-3 row py-3"
          >
            <div
              class="col-12 d-inline-flex align-items-center justify-content-center tw-cursor-pointer"
              role="button"
              @click="goToLink('/gamification/avatar', 'Editar avatar')"
            >
              <i
                class="icon-editavatar tw-text-xl d-inline-block ml-0 my-0 mr-2"
              ></i>
              <p
                class="m-0 d-inline-block tw-font-roboto tw-text-lg line-height-md-20"
              >
                Editar avatar
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-auto d-md-none d-flex justify-content-center">
          <div class="col my-1 px-0 py-0 max-wi-280">
            <router-link
              to="/gamification/how-it-work"
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
              @click.native="pushDataLayerEvent($event)"
            >
              ¿Cómo funciona?
            </router-link>
          </div>
        </div>

        <!-- Columna Información -->
        <div
          class="col-12 col-md-9 px-md-2 tw-rounded-3xl md:tw-shadow-md"
          v-if="isLoading"
        >
          <!-- Contenido mientras carga -->
          <div
            class="d-flex align-items-center justify-content-center mb-2 h-100"
          >
            <div class="text-center">
              <img src="../../assets/img/icons/loading.gif" alt="loading" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-9" v-else>
          <div class="row">
            <div class="col">
              <div class="tw-rounded-3xl md:tw-shadow-md">
                <div class="col">
                  <div class="row">
                    <div
                      class="col-12 col-md-7 p-md-5 px-5 py-4 d-flex flex-column justify-content-between"
                    >
                      <p
                        class="m-0 p-0 tw-font-tolyerBold fs-44 line-height-30"
                      >
                        {{ userStore.user?.name.toUpperCase() }}
                      </p>
                      <div class="d-md-none my-md-auto my-2">
                        <div
                          class="d-flex flex-md-column justify-content-around justify-content-md-center align-items-center wi-md-150 he-md-150 he-70 tw-rounded-2xl tw-shadow-md float-md-right py-2 px-3 text-center my-3"
                        >
                          <i
                            class="icon-maltas fs-50 fs-md-60 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                          ></i>
                          <p
                            class="tw-font-robotoBold tw-text-lg line-height-22 m-0 pt-2"
                          >
                            Tienes
                            {{
                              Number(userStore.user?.gamification_maltas)
                                .toLocaleString()
                                .replace(",", ".")
                            }}
                            maltas
                          </p>
                        </div>
                      </div>
                      <div class="d-md-none d-block">
                        <div class="row d-md-none d-block px-3">
                          <div class="d-flex">
                            <p
                              class="m-0 tw-font-robotoBold tw-text-xs line-height-14 tw-text-green-400 text-uppercase mr-1"
                            >
                              {{
                                Number(userStore.user?.gamification_points)
                                  .toLocaleString()
                                  .replace(",", ".")
                              }}
                              puntos
                            </p>
                            <p
                              class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-green-400 text-uppercase"
                            >
                              de tu rango
                            </p>
                          </div>
                        </div>
                        <div class="row px-3 px-md-5 py-2" id="progress-bar">
                          <div class="col-md-11 col-10 p-0 position-relative">
                            <div
                              class="he-44 tw-rounded-tl-lg tw-rounded-bl-lg tw-bg-gray-250 w-100 h-100 position-absolute"
                            ></div>
                            <div
                              class="he-44 tw-rounded-lg tw-bg-green-300 h-100 position-absolute"
                              role="progressbar"
                              :style="{ width: barProgress + '%' }"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div class="col-md-1 col-2 p-0">
                            <div
                              class="he-44 tw-rounded-tr-lg tw-rounded-br-lg tw-bg-gray-200 position-relative"
                            >
                              <img
                                src="../../assets/img/gamification/medal-2.png"
                                class="position-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 m-auto"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div
                            class="col px-md-5 px-3 d-flex justify-content-center"
                          >
                            <div
                              class="d-flex"
                              v-if="userStore.user.current_rank.next !== null"
                            >
                              <p
                                class="m-0 tw-font-roboto tw-text-xxs line-height-14 font-italic mr-1"
                              >
                                Te faltan
                                {{
                                  remainingPoints
                                    .toLocaleString()
                                    .replace(",", ".")
                                }}
                                puntos para el siguiente rango
                              </p>
                              <p
                                class="m-0 tw-font-roboto tw-text-xxs line-height-14 tw-text-red-400 font-italic"
                              >
                                {{ userStore.user?.current_rank.next?.name }}
                              </p>
                            </div>
                            <div class="d-flex" v-else>
                              <p
                                class="m-0 tw-font-roboto tw-text-xxs line-height-14 font-italic mr-1"
                              >
                                Has alcanzado el rango máximo
                              </p>
                              <p
                                class="m-0 tw-font-roboto tw-text-xxs line-height-14 tw-text-red-400 font-italic"
                              >
                                {{ userStore.user.current_rank.current?.name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p
                          class="tw-font-robotoBold tw-text-sm fs-md-16 line-height-30 m-0"
                        >
                          Tu rango actual
                        </p>
                        <div
                          class="selectdiv position-relative he-50 border-0 tw-rounded-lg tw-shadow"
                        >
                          <select
                            class="form-control w-100 h-100 border-0 shadow-none tw-font-robotoBold tw-appearance-none"
                            @change="onChangeEventRank($event)"
                          >
                            <option
                              v-for="item in gamificationStore.userRanks"
                              :key="item.id"
                              :value="item.id"
                              :selected="
                                item.gami_level_id ===
                                userStore.user.current_rank.current
                                  .gami_level_id
                              "
                              class="tw-font-robotoBold"
                            >
                              {{ item?.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5 p-md-5">
                      <div
                        class="d-none d-md-flex flex-md-column justify-content-around justify-content-md-center align-items-center wi-md-150 he-md-150 he-70 tw-rounded-2xl tw-shadow-md float-md-right py-2 px-3 text-center"
                      >
                        <i
                          class="icon-maltas fs-50 fs-md-60 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                        ></i>
                        <p
                          class="tw-font-robotoBold tw-text-lg line-height-22 m-0 pt-2"
                        >
                          Tienes
                          {{
                            Number(userStore.user.gamification_maltas)
                              .toLocaleString()
                              .replace(",", ".")
                          }}
                          maltas
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row px-5">
                    <div class="col px-0">
                      <p
                        class="tw-font-roboto fs-md-16 line-height-20 m-0 tw-text-gray-400"
                      >
                        {{ userStore.user.prof_profile.team_description }}
                      </p>
                    </div>
                  </div>
                  <div class="row px-5 pt-4 pb-2">
                    <div
                      class="col tw-rounded-lg tw-shadow md:tw-shadow-none py-2 py-md-0"
                    >
                      <div class="row">
                        <div class="col-12 col-md-8 px-3 px-md-0">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <p
                              class="tw-font-robotoBold tw-text-xs line-height-14 m-0"
                            >
                              Productos Exclusivos
                            </p>
                            <p
                              class="tw-font-robotoBold tw-text-xxxs line-height-10 m-0"
                            >
                              {{
                                Object.entries(filterRank).length > 0
                                  ? filterRank[0]?.name
                                  : userStore.user?.current_rank.current?.name
                              }}
                            </p>
                          </div>
                          <div
                            class="tw-overflow-hidden tw-overflow-x-auto tw-overflow-y-hidden"
                          >
                            <div class="d-flex pb-2 tw-overflow-x-scroll">
                              <div
                                v-for="reward in filterReward"
                                :key="reward.id"
                                class="d-inline-flex justify-content-between"
                              >
                                <div
                                  class="my-2 carousel-card wi-140 he-200 overflow-hidden d-inline-flex tw-rounded-lg mx-1 tw-cursor-pointer"
                                  :class="{ active: reward.isActive }"
                                  @click="toggleRewardActive(reward.id)"
                                >
                                  <img
                                    class="h-100"
                                    :src="
                                      uriResources +
                                      '/' +
                                      reward.images[0]?.name
                                    "
                                    alt="Medalla"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-4 my-3">
                          <div>
                            <p
                              class="tw-font-robotoMedium fs-md-18 line-height-md-22 tw-text-gray-600 m-0"
                            >
                              {{ selectedReward?.name }}
                            </p>
                          </div>
                          <div class="d-flex align-items-center pt-2">
                            <i
                              class="icon-maltas fs-26 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                            ></i>
                            <p
                              class="d-inline-block m-0 tw-font-roboto tw-text-lg line-height-24 tw-text-gray-500"
                            >
                              {{
                                selectedReward.malts
                                  .toLocaleString()
                                  .replace(",", ".")
                              }}
                              maltas
                            </p>
                          </div>
                          <div class="pt-2">
                            <p
                              class="tw-font-roboto tw-text-xs line-height-16 tw-text-gray-500"
                            >
                              {{ selectedReward.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row px-4 px-md-5 py-2 d-none d-md-flex"
                    id="progress-bar"
                  >
                    <div class="col-md-11 col-10 p-0 position-relative">
                      <div
                        class="he-44 tw-rounded-tl-lg tw-rounded-bl-lg tw-bg-gray-250 w-100 h-100 position-absolute"
                      ></div>
                      <div
                        class="he-44 tw-rounded-lg tw-bg-green-300 h-100 position-absolute"
                        :style="{ width: barProgress + '%' }"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="col-md-1 col-2 p-0">
                      <div
                        class="he-44 tw-rounded-tr-lg tw-rounded-br-lg tw-bg-gray-200 position-relative"
                      >
                        <img
                          src="../../assets/img/gamification/medal-2.png"
                          class="position-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 m-auto"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4 pb-md-3 d-none d-md-block">
                    <div
                      class="col px-4 mb-4 px-md-5 d-flex justify-content-between"
                    >
                      <div class="d-md-flex d-none">
                        <p
                          class="m-0 tw-font-robotoBold tw-text-xs line-height-14 tw-text-green-400 text-uppercase mr-1"
                        >
                          {{
                            Number(userStore.user?.gamification_points)
                              .toLocaleString()
                              .replace(",", ".")
                          }}
                          puntos
                        </p>
                        <p
                          class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-green-400 text-uppercase"
                        >
                          de tu rango
                        </p>
                      </div>

                      <div
                        class="d-flex"
                        v-if="userStore.user?.current_rank.next != null"
                      >
                        <p
                          class="m-0 tw-font-roboto tw-text-xs line-height-14 font-italic mr-1"
                        >
                          Te faltan
                          {{
                            remainingPoints.toLocaleString().replace(",", ".")
                          }}
                          puntos para el siguiente rango
                        </p>
                        <p
                          class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-red-400 font-italic"
                        >
                          {{ userStore.user?.current_rank.next?.name }}
                        </p>
                      </div>
                      <div class="d-flex" v-else>
                        <p
                          class="m-0 tw-font-roboto tw-text-xs line-height-14 font-italic mr-1"
                        >
                          Has alcanzado el rango máximo
                        </p>
                        <p
                          class="m-0 tw-font-roboto tw-text-xs line-height-14 tw-text-red-400 font-italic"
                        >
                          {{ userStore.user?.current_rank.current?.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.backgrounddiv {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  background-image: url("~@/assets/img/gamification/profileback.png");
  background-repeat: no-repeat;
}

select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: "\e9ab";
  font: normal normal normal 16px/1 IcoMoon;
  color: #222222;
  right: 10px;
  top: calc(50% - 8px);
  position: absolute;
  pointer-events: none;
}

.active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
