<template>
  <div id="showMeRanking">
    <div class="ml-0 md:tw-ml-20 ml-xl-0">
      <!-- Miga de pan -->
      <div class="container px-0 px-md-3 mt-md-4">
        <BreadCrumb :crumbs="breadcrumb" />
      </div>
      <MobileBar title="TU REMUNERACIÓN" />
      <!-- Título Desktop y leyenda -->
      <div
        class="container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"
      >
        <div class="col-12 col-md-6 px-0 order-md-1 order-last">
          <div class="d-none d-md-flex">
            <p class="tw-font-tolyerBold fs-md-40 text-uppercase">
              Calculadora
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 order-md-2 order-first">
          <div
            class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end py-0"
          >
            <div
              class="col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 order-first order-md-1"
            >
              <router-link
                to="newRequestOptions/4"
                class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                @click.native="pushDataLayer('Tienes problemas con tu pedido')"
              >
                ¿Tienes problemas con tu pedido?
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Contenedor Banner y Salario -->
      <div class="container px-3 px-md-3 mt-2 mt-md-5">
        <div class="row py-2" v-for="(item, index) in variables" :key="index">
          <div
            class="col-md-3 d-md-none"
            v-if="
              item.metrics.some(function (e) {
                return e.variable_salary_weight > 0;
              }) === true
            "
          >
            <div
              class="tw-shadow-md tw-rounded-lg-lg tw-rounded-tr-lg tw-rounded-tl-lg he-70 he-md-130 wi-md-150 tw-bg-brown-500 d-flex flex-row align-items-center justify-content-center px-4 px-md-0"
              :style="'background: ' + categories[index].background"
            >
              <p class="tw-font-roboto text-white my-1">|</p>
              <p class="tw-font-robotoBold tw-text-xl text-white my-1 px-2">
                {{ user.remuneration.variables[index].variable_group }}
              </p>
            </div>
          </div>
          <div
            class="col-md d-flex"
            v-if="
              item.metrics.some(function (e) {
                return e.variable_salary_weight > 0;
              }) === true
            "
          >
            <div
              class="shadow-box-2 d-none d-md-inline-flex tw-rounded-tl-lg tw-rounded-bl-lg p-3 flex-column w-10p-md left he-100"
              :style="'background: ' + categories[index].background"
            >
              <p
                class="tw-font-robotoBold tw-text-base fs-md-18 my-1 text-white"
              >
                {{ user.remuneration.variables[index].variable_group }}
              </p>
              <div class="line"></div>
            </div>
            <div
              class="md:tw-rounded-none tw-rounded-br-lg tw-rounded-bl-lg d-inline-flex flex-column flex-md-row px-4 py-2 p-md-4 w-90p-md w-100p right tw-overflow-y-scroll"
            >
              <div v-for="(metrics, subIndex) in item.metrics" :key="subIndex">
                <CompItemCalc
                  v-if="metrics.variable_salary_weight > 0"
                  :kpiObject="metrics"
                  :rates="item.rates"
                  :salaryBase="variableRemuneration"
                  class="pb-3"
                  @subTotal="(val) => setSubTotal(metrics.id, val)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <div
            class="col-12 col-md-5 my-3 my-md-5 d-flex justify-content-between border-bottom"
          >
            <p class="tw-font-robotoBold tw-text-xl fs-md-24 tw-text-gray-300">
              Total
            </p>
            <p class="tw-font-robotoBold tw-text-xl fs-md-24 tw-text-green-400">
              {{ totalSalary | currency }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import CompItemCalc from "@/components/show-me-the-money/variable-compensation/CompItemCalc";
import numbersMixin from "@/mixins/general/numbers";

export default {
  name: "VariableCompensationCalculator",
  mixins: [numbersMixin],
  components: {
    CompItemCalc,
    BreadCrumb,
    MobileBar,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración variable",
          link: "/showMeTheMoney/remuneracion-variable",
        },
        {
          id: 5,
          name: "Calculadora",
          link: "/showMeTheMoney/variable-calculator",
          active: true,
        },
      ],
      categories: {
        1: {
          id: 1,
          name: "Digital Bees",
          background: "#53190d",
        },
        25: {
          id: 25,
          name: "Cobertura",
          background: "#D82B2B;",
        },
        62: {
          id: 62,
          name: "Ejecución",
          background: "#FFC041;",
        },
        621: {
          id: 621,
          name: "Digital",
          background: "#5417af",
        },
      },
      subTotalList: [],
      subtotalObject: {},
    };
  },
  created() {
    window.dataLayer.push({
      event: "Calculadora Compensación Variable",
      Area: this.user.core_vp.name,
      Banda: this.user.core_pa_sideline.name,
      Cargo: this.user.core_position.name,
      Regional: this.user.core_site.core_regional.name,
      Gerencia: this.user.core_organizational_unit.name,
    });
  },
  computed: {
    variables() {
      return this.user.remuneration.variables;
    },
    ...mapState("modCore", ["user"]),
    variableRemuneration() {
      return this.user.variable_salary / 12;
    },
    totalSalary() {
      let result = 0;
      Object.entries(this.subtotalObject).forEach((element) => {
        result += element[1];
      });
      return result;
    },
  },
  methods: {
    setSubTotal(id, valor) {
      this.$set(this.subtotalObject, id, valor);
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-Compensacion",
        category: "Remuneracion Variable",
        SubCategory: "Calculadora",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>
<style src="@/assets/css/showme.css"></style>
<style scoped>
.left {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  border-radius: 9px 0 0 9px;
}

.right {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.line {
  width: 20px;
  height: 47px;
  border-bottom: 1px solid white;
  position: absolute;
}

.base-remuneration {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  width: 500px;
}

.base-remuneration-box-value {
  width: auto;
  height: 46px;
  background: #d82b2b 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.base-remuneration-box-value .value-number {
  font: normal normal bold 18px/22px Roboto;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.form-control {
  color: #d82b2b;
  text-align: center;
}

.section-title {
  font: normal normal bold 14px/45px Roboto;
  letter-spacing: 0;
  color: #bababa;
  opacity: 1;
}

.item-title {
  font: bold 12px/14px Roboto;
  letter-spacing: 0;
  color: #343434;
  opacity: 1;
}

.total-value {
  font-weight: bold;
  font-size: 1.3rem;
}

.type-container {
  height: 90px;
  background: #fab23a 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.type-container-total {
  height: 60px;
  background: white 0 0 no-repeat padding-box;
  border: 1px solid #d82b2b;
  border-radius: 8px;
  opacity: 1;
  width: 350px;
}
.type-container-total span {
  color: #d82b2b !important;
}
.type-title {
  font: bold 14px/17px Roboto;
  letter-spacing: 0;
  color: #40140f;
  opacity: 1;
}

.type-description {
  font: normal 12px/14px Roboto;
  letter-spacing: 0;
  color: #40140f;
  opacity: 1;
}

.type-result {
  font: normal 14px/17px Roboto;
  letter-spacing: 0;
  color: #40140f;
  opacity: 1;
}

.restart {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 14px/17px Roboto;
  letter-spacing: 0;
  color: #d82b2b;
  opacity: 1;
}

.sub-total {
  width: 80%;
  background: #eeeeee 0 0 no-repeat padding-box;
  border-radius: 0 0 8px 8px;
  opacity: 1;
}

.modal-title {
  color: #d82b2b;
  font: bold 16px/19px Roboto;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 1;
  margin-bottom: 10px;
}

.view-description {
  width: 453px;
}

@media (max-width: 770px) {
  .base-remuneration {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 12px;
    opacity: 1;
    width: 100%;
  }

  .base-remuneration-box-value {
    width: auto;
    height: 46px;
    background: #d82b2b 0 0 no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
  }

  .view-description {
    padding: 3%;
    width: 100%;
  }
  .type-container-total {
    height: 70px;
    color: #d82b2b !important;
    background: white;
    border-top: 1px solid #d82b2b;
    border-bottom: 1px solid #d82b2b;
    border-left: 0;
    border-right: 0;
    border-radius: unset;
    width: 100%;
  }
  .type-container-total span {
    color: #d82b2b !important;
  }
  .bg-content-variable {
    background: #f3f3f3;
  }
}

.accordion-button {
  background: transparent;
  color: black !important;
}
.accordion button:focus {
  box-shadow: none !important;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
