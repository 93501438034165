import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    isLoadingData: true,
    filterVices: [],
    filterScopes: [],
    isFilter: false,
    kpi: [],
    vp: [0],
    psah: [0],
    month: (new Date().getMonth() - 1 === -1) ? 11 : new Date().getMonth() - 1,
    year: new Date().getFullYear(),
    first: false,
    vpName: "",
    scopeName: "",
    dataFilterSummary: [],
    tableTitle: "Summary",
    viewRowCompareData: false,
    dataDetail: {},
    isCompare: false,
    dataCompare: [],
  },
  mutations: {
    setFilterVices: (state, filterVices) => {
      state.filterVices = filterVices;
    },
    setFilterScopes: (state, filterScopes) => {
      state.filterScopes = filterScopes;
    },
    setIsFilter: (state, isFilter) => {
      state.isFilter = isFilter;
    },
    setKpi: (state, kpi) => {
      state.kpi = kpi;
    },
    setVp: (state, vp) => {
      state.vp = vp;
    },
    setScope: (state, psah) => {
      state.psah = psah;
    },
    setMonth: (state, month) => {
      state.month = month;
    },
    setYear: (state, year) => {
      state.year = year;
    },
    SetDataFilterSummary: (state, dataFilterSummary) => {
      state.dataFilterSummary = dataFilterSummary;
    },
    setTableTitle: (state, tableTitle) => {
      state.tableTitle = tableTitle;
    },
    setViewRowCompareData: (state, viewRowCompareData) => {
      state.viewRowCompareData = viewRowCompareData;
    },
    setIsLoadingData: (state, isLoadingData) => {
      state.isLoadingData = isLoadingData;
    },
    setDataDetail: (state, dataDetail) => {
      state.dataDetail = dataDetail;
    },
    setVpName: (state, vpName) => {
      state.vpName = vpName;
    },
    setScopeName: (state, scopeName) => {
      state.scopeName = scopeName;
    },
    setFirst: (state, status) => {
      state.status = status;
    },
    setIsCompare: (state, isCompare) => {
      state.isCompare = isCompare;
    },
    setDataCompare: (state, dataCompare) => {
      state.dataCompare = dataCompare;
    },
    setAddDataCompare: (state, dataCompare) => {
      state.dataCompare.push(dataCompare);
    },
    setAddDataCompare0: (state, dataCompare) => {
      state.dataCompare[0] = dataCompare;
    },
    setAddDataCompare1: (state, dataCompare) => {
      state.dataCompare[1] = dataCompare;
    },
    setRemoveDataCompare: (state, dataCompare) => {
      let index = state.dataCompare.indexOf(dataCompare);
      state.dataCompare.splice(index, 1);
    },
  },
  actions: {
    getSelectFilters: async ({ commit }) => {
      let response = await ApiBeerOffice.getSelectFilters();
      if (response.error) {
        return response.error;
      } else {
        commit("setFilterVices", response.data.vps);
        //commit("setFilterScopes", response.data.psahs);
      }
    },
    updateIsFilter: ({ commit }, state) => {
      commit("setIsFilter", state);
    },
    updateKpi: ({ commit }, kpi) => {
      commit("setKpi", kpi);
    },
    updateVp: ({ commit }, vp) => {
      commit("setVp", vp);
    },
    updateScope: ({ commit }, scope) => {
      commit("setScope", scope);
    },
    updateMonth: ({ commit }, month) => {
      commit("setMonth", month);
    },
    updateYear: ({ commit }, year) => {
      commit("setYear", year);
    },
    updateTableTitle: ({ commit }, title) => {
      commit("setTableTitle", title);
    },
    updateViewRowCompareData: ({ commit }, state) => {
      commit("setViewRowCompareData", state);
    },
    updateVpname: ({ commit }, name) => {
      commit("setVpName", name);
    },
    updateScopeName: ({ commit }, name) => {
      commit("setScopeName", name);
    },
    updateFirst: ({ commit }, status) => {
      commit("setFirst", status);
    },
    updateIsCompare: ({ commit }, status) => {
      commit("setIsCompare", status);
    },
    addDataCompare: ({ commit }, data) => {
      commit("setAddDataCompare", data);
    },
    updateDataCompare0: ({ commit }, data) => {
      commit("setAddDataCompare0", data);
    },
    updateDataCompare1: ({ commit }, data) => {
      commit("setAddDataCompare1", data);
    },
    removeDataCompare: ({ commit }, data) => {
      commit("setRemoveDataCompare", data);
    },
    getSummaryPsi: async ({ commit }, data) => {
      commit("setIsLoadingData", true);

      let response = await ApiBeerOffice.getSummaryPsi(data);
      if (response.error) {
        return response.error;
      } else {
        commit("setIsLoadingData", false);
        commit("SetDataFilterSummary", response.data);
      }
    },
    getLastMonth({ commit }) {
      const current = new Date();
      let lastMonth = (current.getMonth() - 1) ? 11 : current.getMonth() - 1;
      commit("setMonth", lastMonth);
    },
    resetFilterData({ commit }) {
      const current = new Date();
      let lastMonth = (current.getMonth() - 1) ? 11 : current.getMonth() - 1;

      commit("setFirst", false);
      commit("setIsFilter", false);
      commit("setViewRowCompareData", false);
      commit("setTableTitle", "Summary");
      commit("setKpi", []);
      commit("setVp", [0]);
      commit("setScope", [0]);
      commit("setMonth", lastMonth);
      commit("setYear", current.getFullYear);
      commit("setIsCompare", false);
      commit("setDataCompare", []);
    },
    resetDataCompare({ commit }) {
      commit("setDataCompare", []);
    },
    updateDataDeatil({ commit }, data) {
      commit("setDataDetail", data);
    },
  },
};
