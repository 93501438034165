<script setup>
import { computed, ref } from "vue";
import { useProfileStore } from "@/stores/profileStore";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router/composables";

const profileStore = useProfileStore();
const userStore = useUserStore();
const router = useRouter();

const { user } = storeToRefs(userStore);
const idQ = ref(0);
const rta = ref(0);
const res = ref({});

const question = computed(() => profileStore.profileQuestions[idQ.value]);

const setRta = (id) => {
  rta.value = id;
};

const sendRta = async () => {
  if (rta.value > 0) {
    res.value = await profileStore.actPostProfileQuestionsAnswer({
      id: user.id,
      cc: user.cc,
      prof_question_option_id: rta.value,
    });
  }
  next();
};

const next = () => {
  if (profileStore.profileQuestions.length > idQ.value + 1) {
    idQ.value++;
    rta.value = 0;
  } else {
    router.push({ path: "/result" });
  }
};
</script>
<template>
  <div class="my-auto mx-auto pr-5 pl-5 profQuestionComp">
    <small class="form-text text-muted textColorMute"
      >Ahora queremos conocerte un poco más...</small
    >
    <h2
      class="mb-3 mb-md-4 questionQ tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"
    >
      {{ question.question }}
    </h2>

    <div class="mx-4">
      <div
        class="option my-2 px-3 btn-principal w-100 text-left px-4"
        v-for="(option, idx) in question.prof_options"
        :key="idx"
        :class="[rta !== option.id ? '' : 'active']"
        @click="setRta(option.id)"
      >
        {{ option.option }}
      </div>
    </div>

    <div class="next m-2 p-3 mx-auto text-left p-0 pl-4 pr-4">
      <div class="row">
        <div class="col-6 p-0 pl-3 pr-3 pt-2 pt-md-3">
          <div
            class="rounded-circle profPoints"
            v-for="(profQuestion, idx) in profQuestions"
            :key="idx"
            :class="[
              profQuestion.id !== question.id
                ? ''
                : 'profPointsActive rounded-pill',
            ]"
          ></div>
        </div>
        <div class="col-6 text-right hmin">
          <div class="d-none d-md-block">
            <a
              href="javascript:void(0)"
              @click="sendRta()"
              v-bind:class="
                'btn btn-primary btn-principal  background-primary w-75 ' +
                [rta > 0 ? '' : 'disableBtn']
              "
              >Siguiente</a
            >
          </div>
          <a
            href="javascript:void(0)"
            @click="sendRta()"
            v-bind:class="
              'd-xl-none d-md-none ' + [rta > 0 ? '' : 'disableBtn']
            "
          >
            <img class="mt-2" src="../../assets/img/icons/onboardingL.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.questionQ {
  line-height: 30px;
  margin-top: 12px;
}
.hmin {
  height: 30px;
}
.option {
  border: 2px solid #491f15;
  font-size: 14px;
}
.active {
  background-color: #491f15;
  color: #ffffff;
  font-weight: 500;
}
.next {
  position: absolute;
  bottom: 30px;
  width: 80% !important;
}
.profPoints {
  width: 6px;
  height: 6px;
  float: left;
  background-color: #cccccc;
  margin: 0 2px;
}
.profPointsActive {
  width: 18px;
  background-color: #d32e24;
}
.disableBtn {
  background-color: #bbbbbb;
  border: #bbbbbb;
}
.disableBtn:hover {
  background-color: #bbbbbb;
  border: #bbbbbb;
}
.disableBtn:focus {
  background-color: #bbbbbb;
  border: #bbbbbb;
}
/* SMALL DISPLAY */
@media (max-width: 600px) {
  .disableBtn {
    background-color: #ffffff;
    border: #ffffff;
  }
  .disableBtn:hover {
    background-color: #ffffff;
    border: #ffffff;
  }
  .disableBtn:focus {
    background-color: #ffffff;
    border: #ffffff;
  }
  .profQuestionComp {
    margin: 0 !important;
    padding: 0 !important;
  }
  .next {
    width: auto !important;
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    left: 0;
    right: 0;
    padding: 0 !important;
    margin: 0 !important;
  }
  .next .row {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 5px 18px;
  }
  .profPoints {
    margin-top: 8px;
  }
}
</style>
