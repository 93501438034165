<script setup>
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router/composables";
import { useContestsStore } from "@/stores/contestsStore";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompContestDetailCard from "@/components/contests/CompContestDetailCard.vue";
import CompContents from "@/components/general/CompContents.vue";

const route = useRoute();
const isLoading = ref(false);
const contestsStore = useContestsStore();
const { actGetCompetition } = contestsStore;
const { competition } = storeToRefs(contestsStore);

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetCompetition(route.params.id);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Concursos" />
    <title-bar title="Concursos" />
    <main class="tw-container-general" v-if="!isLoading">
      <comp-contest-detail-card :contest="competition" />
      <CompContents
        v-if="competition.content.length > 0"
        :content="competition.content"
      />
    </main>
  </div>
</template>

<style scoped></style>
