<template>
  <div class="layout-content mt-4 px-0 px-md-3 px-lg-5">
    <div
      class="container-fluid gamification"
      :style="{
        backgroundImage: `url('${imgBgGamification}')`,
      }"
    >
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8 mx-auto">
          <div class="cont-inicial">
            <div class="cont-main position-relative">
              <img
                src="@/assets/img/gamification/symbol-1.png"
                alt="symbol 1"
                class="position-absolute symbol-1 d-none d-md-block"
              />
              <p class="title text-center mb-4 text-uppercase">
                {{ user.prof_profile.team_title }}
              </p>
              <div class="text-center mb-4">
                <img
                  :src="uriResources + '/' + user.prof_profile.team_image"
                  width="280"
                  alt="medal"
                />
              </div>
              <p class="txt mb-5">{{ user.prof_profile.team_description }}</p>
              <router-link
                to="/gamification"
                class="btn btn-primary btn-block text-uppercase py-2"
                >Continuar</router-link
              >
            </div>
            <!-- .cont-main -->
          </div>
          <!-- .cont-inicial -->
        </div>
        <!-- .col -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { mapState } from "vuex";

import config from "@/services/config";

export default {
  name: "gamificationSpirit",
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgBgGamification: require("@/assets/img/gamification/background.png"),
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
};
</script>

<style lang="css" scoped>
.gamification {
  background-size: cover;
  padding: 120px 20px 20px 20px;
}

.gamification .cont-inicial .cont-main {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  padding: 20px;
}

.gamification .cont-inicial .cont-main .symbol-1 {
  right: 10%;
}

.gamification .cont-inicial .cont-main .title {
  color: #d82b2b;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}

.gamification .cont-inicial .cont-main .txt {
  font-size: 16px;
  color: #717171;
}

.gamification .cont-inicial .cont-main .btn {
  font-size: 22px;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .gamification {
    padding: 120px 50px 50px 50px;
  }

  .gamification .cont-inicial .cont-main {
    padding: 20px 10%;
  }

  .gamification .cont-inicial .cont-main .title {
    font-size: 40px;
    line-height: 40px;
  }

  .gamification .cont-inicial .cont-main .txt {
    font-size: 18px;
  }

  .gamification .cont-inicial .cont-main .btn {
    font-size: 45px;
    border-radius: 10px;
  }
}

@media (min-width: 1024px) {
  .gamification .cont-inicial .cont-main {
    padding: 20px 20%;
  }

  .gamification .cont-inicial .cont-main .symbol-1 {
    right: calc(20% - 50px);
  }
}
</style>