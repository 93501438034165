<template>
  <div class="container my-md-3">
    <div class="row py-md-2 align-items-center">
      <div class="col-12 col-md-7 p-0">
        <SecondaryNavbar title="Tus Pagos" :breadcrumb="breadcrumb" />
      </div>
      <div class="col-12 col-md-5 d-none d-md-block">
        <CompNavigationOptions selected="Pagos" />
      </div>
    </div>
    <div class="cont-img-mobile d-block d-sm-none">
      <img
        src="@/assets/img/show-me/img-payments.jpg"
        alt="show payments"
        class="bgimg-mobile w-100"
      />
    </div>
    <div class="container mt-4 mt-md-5">
      <div class="row">
        <div class="col-12 col-md-6 text-center text-md-left">
          <p>Podrás visualizar el porcentaje de pago de los últimos 3 meses</p>
          <div
            v-if="user.payments.length !== 0"
            class="table-responsive cont-table"
          >
            <b-table
              hover
              :items="user.payments"
              :fields="fields"
              class="table text-capitalize"
            >
            </b-table>
          </div>
          <div v-else>
            <h5 class="tw-font-robotoBold tw-text-sm md:tw-text-lg">
              No hay registro de tus pagos
            </h5>
          </div>
          <!-- .cont-table -->
        </div>
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="cont-img">
            <img
              src="@/assets/img/show-me/img-payments.jpg"
              alt="show payments"
              class="bgimg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompNavigationOptions from "@/components/show-me-the-money/variable-compensation/CompNavigationOptions";

export default {
  name: "VariableCompensationPayments",
  components: {
    SecondaryNavbar,
    CompNavigationOptions,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración variable",
          link: "/showMeTheMoney/remuneracion-variable",
        },
        {
          id: 5,
          name: "Reporta una novedad",
          link: "/showMeTheMoney/variable-tickets",
          active: true,
        },
      ],

      fields: [
        { key: "date", label: "Mes", formatter: "setDate" },
        { key: "percentage", label: "Porcentage", formatter: "setPayment" },
      ],
    };
  },
  mounted() {
    window.dataLayer.push({
      event: "Pagos Compensación Variable",
      Area: this.user.core_vp.name,
      Banda: this.user.core_pa_sideline.name,
      Cargo: this.user.core_position.name,
      Regional: this.user.core_site.core_regional.name,
      Gerencia: this.user.core_organizational_unit.name,
    });
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  methods: {
    setPayment(value) {
      return (value * 100).toFixed(2) + "%";
    },
    setDate(value) {
      return moment(value).format("MMMM");
    },
  },
};
</script>
<style scoped>
.cont-table {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.cont-table table thead tr {
  background: #d6d6d6;
  border-radius: 8px 8px 0 0;
}

.cont-table thead tr th {
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0;
  color: #343434;
  opacity: 1;
}

.cont-table tbody tr td {
  height: 40px;
  font-size: 14px;
  color: #666666;
}

.cont-title h4 {
  color: #343434;
  font-size: 24px;
  font-family: "RobotoBold", sans-serif;
}

.bgimg {
  border-radius: 16px 0 0 16px;
  width: 95% !important;
  height: calc(50vh);
}

.bgimg-mobile {
  border-radius: 0 0 16px 16px;
  height: calc(40vh - 50px);
}

@media (min-width: 768px) {
  .cont-img img {
    border-radius: 30px 0 0 30px;
  }
}
</style>
