<script setup>
import config from "@/services/config";
import { ref } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useSocialNetworkStore } from "@/stores/socialNetworkStore";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompRatingStars from "@/components/rating/CompRatingStars.vue";
import CompSocialNetworkReactions from "@/components/social-network/CompSocialNetworkReactions.vue";
import CompSocialNetworkShare from "@/components/social-network/CompSocialNetworkShare.vue";
import CompSocialNetworkComments from "@/components/social-network/CompSocialNetworkComments.vue";

const store = useSocialNetworkStore();

const uriResources = config.beerOfficeURL;
const isShowModalComments = ref(false);
const isShowModalShare = ref(false);
const isShowModalReactions = ref(false);
const isShowModalStars = ref(false);
const reactions = ref(null);

const emit = defineEmits(["compartir", "updatedReaction", "updatedComment"]);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  user_reaction: {
    validator(value) {
      return value == null || typeof value === "object";
    },
    required: true,
  },
  user_score_review: {
    validator(value) {
      return value == null || typeof value === "number";
    },
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
  categoryForDatalayer: {
    type: String,
  },
  hover: {
    type: Boolean,
    required: true,
  },
});

function toggleModalComments() {
  isShowModalComments.value = !isShowModalComments.value;
  if (isShowModalComments.value) {
    interactionsDatalayer("Comentar");
  }
}

function toggleModalShare() {
  isShowModalShare.value = !isShowModalShare.value;
  if (isShowModalShare.value) {
    interactionsDatalayer("Compartir");
  }
}

function toggleModalStars() {
  const { x, y } = reactions.value.getBoundingClientRect();
  if (!isShowModalReactions.value) {
    store.setCoordinates({ x, y });
  }
  isShowModalStars.value = !isShowModalStars.value;
  if (isShowModalStars.value) {
    interactionsDatalayer("Calificar");
  }
}

function toggleModalReactions() {
  const { x, y } = reactions.value.getBoundingClientRect();
  if (!isShowModalReactions.value) {
    store.setCoordinates({ x, y });
  }
  isShowModalReactions.value = !isShowModalReactions.value;
}

const manageReactions = ($event) => {
  emit("updatedReaction", $event);
  interactionsDatalayer($event?.reaction?.name);
};

function interactionsDatalayer(label) {
  pushDataLayer({
    event_category: props.categoryForDatalayer,
    event_action: props.title,
    event_label: label,
    component_name: "Click",
  });
}
</script>

<template>
  <div>
    <portal to="all-modals" >
      <general-modal
        :hide-button-support="true"
        v-if="isShowModalComments"
        modalName="comments"
        @close="toggleModalComments"
        :isShowModal="isShowModalComments"
        :modalWidth="{ md: 500, lg: 600, xl: 600 }"
        :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
      >
        <comp-social-network-comments
          :id="props.id"
          :category="props.category"
          :item="props.item"
          :is-show-modal="isShowModalComments"
          class="tw-h-full"
          @updatedComment="emit('updatedComment', $event)"
        />
      </general-modal>
    </portal>
    <portal to="all-modals" >
      <general-modal
        :hide-button-support="true"
        v-if="isShowModalShare"
        modalName="share"
        @close="toggleModalShare"
        :isShowModal="isShowModalShare"
        :modalWidth="{ md: 500, lg: 600, xl: 600 }"
        :maxHeight="{ base: 400, md: 550, lg: 550, xl: 600 }"
      >
        <comp-social-network-share
          :id="props.id"
          :category="props.category"
          :item="props.item"
          :title="props.title"
          :image="props.image"
          :description="props.description"
          class="tw-h-full"
        />
      </general-modal>
    </portal>
    <portal to="all-modals" >
      <general-modal
        :hide-button-support="true"
        v-if="isShowModalReactions"
        modalName="reactions"
        @close="toggleModalReactions"
        :isShowModal="isShowModalReactions"
        :modalWidth="{ base: 320, md: 320, lg: 320, xl: 320 }"
        :maxHeight="{ base: 60, md: 60, lg: 60, xl: 60 }"
        :closable="false"
        :backdrop="false"
        :tiny="true"
        :coordinates="store.coordinates"
        :padding-x="false"
        :padding-y="false"
      >
        <comp-social-network-reactions
          :reaction="props.user_reaction"
          :category="props.category"
          :id="props.id"
          :item="props.item"
          @closeModal="toggleModalReactions"
          @updateReaction="manageReactions"
        />
      </general-modal>
    </portal>
    <portal to="all-modals" >
      <general-modal
        :hide-button-support="true"
        v-if="isShowModalStars"
        modalName="stars"
        @close="toggleModalStars"
        :isShowModal="isShowModalStars"
        :modalWidth="{ base: 320, md: 320, lg: 320 }"
        :maxHeight="{ base: 60, md: 60, lg: 60, xl: 70 }"
        :closable="false"
        :backdrop="false"
        :tiny="true"
        :coordinates="store.coordinates"
      >
        <CompRatingStars
          category="NEW_ARTICLES"
          :item="props.item"
          :type="props.category"
          @closeModal="toggleModalStars"
        />
      </general-modal>
    </portal>
    <div
      class="tw-my-7 tw-flex tw-items-center tw-justify-center tw-overflow-hidden"
      id="social"
    >
      <div class="tw-w-full sm:tw-w-auto">
        <hr class="tw-w-full tw-bg-gray-250" />
        <div class="tw-grid tw-grid-flow-col tw-gap-4">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-cursor-pointer tw-group"
            @click="toggleModalReactions"
            ref="reactions"
          >
            <div
              class="tw-relative tw-flex tw-items-center tw-justify-center"
              v-if="props.user_reaction == null"
            >
              <i
                class="icon-like-red tw-absolute tw-top-0 tw-text-xl lg:tw-text-4xl tw-opacity-0 group-hover:tw-opacity-100"
              ></i>
              <i
                class="icon-like tw-absolute tw-text-xl tw-top-0 lg:tw-text-4xl tw-opacity-1 group-hover:tw-opacity-0"
              ></i>
            </div>

            <img
              v-else
              ref="reactions"
              :src="uriResources + '/' + props.user_reaction?.reaction?.icon"
              alt="image"
              class="tw-w-5 tw-h-5 md:tw-w-9 md:tw-h-9 lg:tw-w-10 lg:tw-h-10"
            />

            <div>
              <p
                class="tw-m-0 tw-font-roboto tw-text-xxs sm:tw-text-xs md:tw-text-sm group-hover:tw-text-red-350"
              >
                Reaccionar
              </p>
            </div>
          </div>
          <div
            class="tw-h-full tw-border-l tw-border-gray-250 tw-mx-auto"
          ></div>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-cursor-pointer tw-group"
            @click="toggleModalShare"
          >
            <div class="tw-relative tw-flex tw-items-center tw-justify-center">
              <i
                class="icon-share-red tw-absolute tw-top-0 tw-text-xl lg:tw-text-4xl tw-opacity-0 group-hover:tw-opacity-100"
              ></i>
              <i
                class="icon-share tw-absolute tw-text-xl tw-top-0 lg:tw-text-4xl tw-opacity-1 group-hover:tw-opacity-0"
              ></i>
            </div>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xxs sm:tw-text-xs md:tw-text-sm group-hover:tw-text-red-350"
            >
              Compartir
            </p>
          </div>
          <div
            class="tw-h-full tw-border-l tw-border-gray-250 tw-mx-auto"
          ></div>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-1 md:tw-gap-2 tw-cursor-pointer tw-group"
            @click="toggleModalStars"
            ref="stars"
          >
            <div
              class="tw-relative tw-flex tw-items-center tw-justify-center"
              v-if="props.user_score_review == null"
            >
              <i
                class="icon-rating-red tw-absolute tw-top-0 tw-text-xl lg:tw-text-4xl tw-opacity-0 group-hover:tw-opacity-100"
              ></i>
              <i
                class="icon-rating tw-absolute tw-text-xl tw-top-0 lg:tw-text-4xl tw-opacity-1 group-hover:tw-opacity-0"
              ></i>
            </div>
            <div
              class="tw-flex tw-w-full tw-justify-center tw-items-baseline"
              v-else
            >
              <p class="tw-m-0 tw-font-robotoBold tw-text-xl md:tw-text-3xl">
                {{ props.user_score_review }}
              </p>
              <i
                class="icon-rating-full tw-text-xl lg:tw-text-3xl tw-opacity-1 tw-text-orange-300"
              ></i>
            </div>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xxs sm:tw-text-xs md:tw-text-sm group-hover:tw-text-red-350"
            >
              Calificar
            </p>
          </div>
          <div
            class="tw-h-full tw-border-l tw-border-gray-250 tw-mx-auto"
          ></div>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-cursor-pointer tw-group"
            @click="toggleModalComments"
          >
            <div class="tw-relative tw-flex tw-items-center tw-justify-center">
              <i
                class="icon-comment-red tw-absolute tw-top-0 tw-text-xl lg:tw-text-4xl tw-opacity-0 group-hover:tw-opacity-100"
              ></i>
              <i
                class="icon-comment tw-text-xl tw-top-0 lg:tw-text-4xl tw-opacity-1 group-hover:tw-opacity-0"
              ></i>
            </div>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xxs sm:tw-text-xs md:tw-text-sm group-hover:tw-text-red-350"
            >
              Comentar
            </p>
          </div>
        </div>
        <hr class="tw-w-full tw-bg-gray-250" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
