import AxiosBeerOffice from "@/services/axiosBeerOffice";
import { defineStore } from "pinia";

const uri = {
  addAgreementUri: "/proposedAgreements",
};

export const useAgreementStore = defineStore("agreement", {
  actions: {
    async actSendNewAgreement(data) {
      const response = await AxiosBeerOffice.post(
        uri.addAgreementUri,
        true,
        data
      );
      if (response.error) {
        return false;
      } else {
        return response;
      }
    },
  },
});
