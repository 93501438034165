<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0 main-content">
    <MobileBar title=" Talento Beer office" />
    <div class="container pt-md-4">
      <div class="row">
        <div class="col-12 mt-4">
          <BreadCrumb :crumbs="breadcrumb" class="mb-4" />
          <p
            class="tw-text-gray-600 fs-30 fs-md-40 tw-font-tolyerBold d-none d-md-block"
          >
            Talento Beer office
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <p class="tw-text-base tw-text-gray-600">
            Conoce nuestras plataformas y explora cada una de ellas.
          </p>
        </div>
      </div>
      <div class="row mt-3 mb-4">
        <div
          class="col-12 col-md-6 col-lg-4 mb-3 mb-md-4"
          v-for="card in cards"
          :key="'card-' + card.id"
        >
          <CardPillar
            :pillar="card"
            :viewIcon="false"
            @click.native="pushDataLayer(card.title)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileBar from "@/components/general/MobileBar";
import BreadCrumb from "@/components/general/BreadCrumb";
import CardPillar from "@/components/general/CardPillar";

export default {
  name: "TalentView",
  components: {
    MobileBar,
    BreadCrumb,
    CardPillar,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Somos embajadores",
          link: "/ambassadors",
          active: false,
        },
        {
          id: 3,
          name: "Talento Beer Office",
          link: "#",
          active: true,
        },
      ],
      cards: [
        {
          id: 1,
          url: this.$router.resolve({ name: "GoRecognition" }).href,
          img: require("@/assets/img/ambassadors/routes/go-integro.png"),
          title: "GO RECOGNITION",
          desc: "Tus reconocimientos, ahora son grandes beneficios.",
        },
        {
          id: 2,
          url: this.$router.resolve({ name: "Platforms" }).href,
          img: require("@/assets/img/ambassadors/routes/platforms.png"),
          title: "NUESTRAS PLATAFORMAS",
          desc: "Nuestro sueño es contruir un mejor país para todos y todas.",
        },
        {
          id: 3,
          url: this.$router.resolve({ name: "PeopleMovements" }).href,
          img: require("@/assets/img/ambassadors/routes/movement.png"),
          title: "GENTE EN MOVIMIENTO",
          desc: "Accede al cargo que siempre has soñado, ingresa y conoce más.",
        },
        {
          id: 4,
          url: this.$router.resolve({ name: "indicatorsPsi" }).href,
          img: require("@/assets/img/ambassadors/routes/indicators.png"),
          title: "INDICADORES",
          desc: "Queremos compartirte los grandes logros de nuestra compañía.",
        },
        {
          id: 5,
          url: this.$router.resolve({ name: "PeopleCycle" }).href,
          img: require("@/assets/img/ambassadors/routes/people.png"),
          title: "PEOPLE CYCLE",
          desc: "Creemos firmemente que tu crecimiento dentro la compañía es fundamental, conoce por qué.",
        },
      ],
    };
  },
  methods: {
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-TBO",
        category: "Somos embajadores",
        SubCategory: "Talento Beer Office",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>

<style scoped></style>
