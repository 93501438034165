<script setup>
import config from "@/services/config";
import { onBeforeMount, onUnmounted } from "vue";
import { useRouter } from "vue-router/composables";
import CompArticlesStats from "@/components/articles/CompArticlesStats.vue";

const router = useRouter();
const uriResources = config.beerOfficeURL;

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
});

const goToArticle = (article, label, component) => {
  pushDataLayer(article, label, component);
  router.push("/articles/" + article.id);
};

const pushDataLayer = (article, label, component) => {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Artículos",
    event_action: "Banners principales",
    event_label: label,
    interaction: "True",
    component_name: component,
    element_category: article.article_category.name,
    element_text: article.title,
  });
};

const onMouseUp = (e) => {
  const clickedE1 = e.target;
  if (clickedE1.closest("#stats")) {
    console.log("inside");
  } else {
    console.log("outside");
  }
};

onBeforeMount(() => {
  window.addEventListener("mouseup", onMouseUp);
});

onUnmounted(() => {
  window.removeEventListener("mouseup", onMouseUp);
});
</script>
<template>
  <div class="tw-grid tw-gap-2 md:tw-gap-8 tw-grid-cols-12">
    <div
      class="tw-bg-no-repeat tw-bg-center tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-8 tw-aspect-w-5 tw-aspect-h-4 md:tw-aspect-w-16 md:tw-aspect-h-9 tw-cursor-pointer"
      :style="{
        'background-image': `url(${require('@/assets/img/icons/loading.gif')})`,
      }"
      @click="$router.push('/articles/' + props.article.content_content_id)"
    >
      <img
        :src="uriResources + '/' + props.article.resource"
        loading="lazy"
        width="100%"
        height="auto"
        alt="image"
        class="tw-rounded-2xl tw-w-full tw-h-full tw-object-cover tw-pointer-events-none"
      />
    </div>
    <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4 tw-h-full">
      <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
        <div>
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <p
              class="tw-font-roboto tw-text-xs md:tw-text-base tw-text-black tw-mb-0 tw-inline-block tw-underline"
            >
              {{ props.article.article_category.name }}
            </p>
            <comp-articles-stats class="md:tw-hidden" :article="article" />
          </div>
          <div class="">
            <p
              class="tw-mb-2 md:tw-mb-5 tw-font-robotoBold tw-text-base md:tw-text-4xl tw-line-clamp-2"
            >
              {{ props.article.title }}
            </p>
            <p
              class="tw-mb-0 tw-text-xs md:tw-text-base tw-text-black tw-line-clamp-3"
            >
              {{ props.article.description }}
            </p>
          </div>
        </div>

        <div id="stats" class="">
          <comp-articles-stats
            :article="article"
            class="tw-mt-5 tw-hidden md:tw-flex"
          />
          <div
            @click="goToArticle(article, 'Leer más', 'Button')"
            class="tw-mt-5 tw-block tw-text-center tw-text-sm md:tw-text-base tw-cursor-pointer tw-text-white tw-p-2 tw-rounded-2xl tw-w-full tw-bg-red-400 hover:tw-bg-red-600 hover:tw-text-white"
          >
            Leer más
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
