<script setup>
import { ref, computed } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useRouter } from "vue-router/composables";
import CardProducts from "@/components/ecommerce/CardProducts";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import CompSelectCategories from "@/components/general/CompSelectCategories.vue";

const router = useRouter();
const dynamicFilter = ref("");
const searchQuery = ref("");

const ecommerceStore = useEcommerceStore();

const products = computed(() => {
  return ecommerceStore.products;
});

const buyProducts = products.value?.filter((products) => {
  return products.name === "COMPRA";
});

console.log("buyProducts: ", buyProducts);

const productsToShow = computed(() => {
  let initProducts = products.value?.filter(
    (product) => product.name === "COMPRA"
  )[0].ecomm_product;

  initProducts.forEach((product) => {
    if (!product.ecomm_product_category) {
      product.ecomm_product_category = { id: 0, name: "Sin Categoría" };
    }
  });

  return initProducts
    .filter((product) => {
      if (searchQuery.value.length < 3) return product;
      let lowerCaseQuery = searchQuery.value.toLowerCase();
      return (
        product.name.toLowerCase().includes(lowerCaseQuery) ||
        product.description.toLowerCase().includes(lowerCaseQuery)
      );
    })
    .filter((product) =>
      product.ecomm_product_category.name
        .toLowerCase()
        .includes(dynamicFilter.value.toLowerCase())
    );
});

const changeFilter = (filter) => {
  dynamicFilter.value = filter;
  let filterDataLayer = filter;
  if (filterDataLayer === "") {
    filterDataLayer = "Todos";
  }
  pushDataLayer({
    event_category: "Tienda",
    event_action: "Filtros",
    event_label: filterDataLayer,
    component_name: "Click",
  });
};

const cleanQuery = () => (searchQuery.value = "");

const goToOrders = () => {
  pushDataLayer({
    event_category: "Tienda",
    event_action: "Click",
    event_label: "Tus pedidos",
    component_name: "Click",
  });
  router.push({ name: "ShoppingHistory" });
};
</script>
<template>
  <div>
    <div
      class="tw-flex tw-justify-between tw-items-start tw-mt-10 tw-mb-5 tw-h-auto tw-max-h-10"
    >
      <div class="tw-flex tw-gap-x-6 tw-items-center tw-justify-start">
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-3xl tw-font-robotoBold"
        >
          Productos
        </p>
        <img
          src="@/assets/img/icons/icon-5.svg"
          alt="icon 3"
          class="tw-hidden md:tw-block md:tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24"
        />
      </div>
      <div class="tw-flex tw-gap-x-2 tw-items-start tw-justify-end">
        <div class="tw-btn-secondary-white tw-px-6" @click="goToOrders()">
          Tus pedidos
        </div>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-5">
      <div
        class="lg:tw-col-span-8 tw-order-2 lg:tw-order-1 tw-flex tw-items-center"
      >
        <comp-select-categories
          :items="buyProducts[0].ecomm_product"
          categoryToFilter="ecomm_product_category"
          @changeFilter="changeFilter"
        />
      </div>
      <!-- buscador -->
      <div class="lg:tw-col-span-4 tw-order-1 lg:tw-order-2">
        <div class="tw-flex tw-justify-between tw-items-center tw-gap-2">
          <div
            class="tw-w-full tw-rounded-full tw-bg-gray-200 tw-px-3 tw-grid tw-grid-cols-col2_fit_last"
          >
            <input
              type="text"
              name=""
              id="searchbar"
              class="tw-w-full tw-bg-gray-200 tw-font-roboto tw-text-sm focus:tw-outline-none focus:tw-border-transparent focus:tw-ring-transparent tw-border-transparent"
              @keyup.esc="cleanQuery"
              v-model.trim="searchQuery"
              autocomplete="off"
            />
            <div class="tw-flex tw-items-center">
              <i class="icon-search-bold tw-px-2 tw-text-2xl tw-text-black"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-6 lg:tw-gap-8 tw-z-1 tw-my-5"
    >
      <div v-for="(product, idx) in productsToShow" :key="idx">
        <CardProducts :product="product" v-on="$listeners" />
      </div>
    </div>
  </div>
</template>
