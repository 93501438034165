var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-card col-md-12",attrs:{"id":"comp-contact"}},[_c('div',{staticClass:"card w-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row pl-3 pr-3 font-weight-bold mb-3 mb-md-0"},[_vm._m(0),_c('div',{staticClass:"col-6 p-0 text-right"},[_c('router-link',{staticClass:"text-red",attrs:{"to":"/my-contacts"}},[_vm._v("Ver todos")])],1)]),_c('div',{staticClass:"profOverflow"},[_c('div',{staticClass:"carousel-contacts d-flex",style:({
            'background-image': 'url(' + _vm.backgroundContacts + ')',
            'background-repeat': 'repeat-x',
          }),on:{"click":function($event){return _vm.$bvModal.show('bv-modal-favorite-contacts')}}},[_c('div',{staticClass:"cell"},[_c('div',{staticClass:"onboard-slide"},[_c('div',{attrs:{"id":"btn-add-user"},on:{"click":function($event){return _vm.$bvModal.show('bv-modal-favorite-contacts')}}},[_c('img',{staticClass:"rounded-circle contactAdd",attrs:{"src":require("@/assets/img/icons/contactAdd.png"),"alt":""}}),_c('p',{staticClass:"mb-0"},[_vm._v("Agregar")])])])]),_vm._l((_vm.contacts.businessPartners),function(contact,index){return _c('div',{key:`bp${index}`,staticClass:"cell"},[_c('div',{staticClass:"onboard-slide"},[_c('div',{on:{"click":function($event){return _vm.loadAndShowDataInModal(contact.id, 'main')}}},[_c('img',{staticClass:"rounded-circle",attrs:{"src":contact.prof_picture == null
                      ? _vm.imgAvatar
                      : _vm.uriResources + '/' + contact.prof_picture,"alt":""}}),_c('p',{staticClass:"mb-0 text-capitalize"},[_vm._v(" "+_vm._s(contact.name.toLowerCase())+" ")])])])])}),_vm._l((_vm.contacts.wellnessContacts),function(contact,index){return _c('div',{key:`wc${index}`,staticClass:"cell"},[_c('div',{staticClass:"onboard-slide"},[_c('div',{on:{"click":function($event){return _vm.loadAndShowDataInModal(contact.id, 'main')}}},[_c('img',{staticClass:"rounded-circle",attrs:{"src":contact.prof_picture == null
                      ? _vm.imgAvatar
                      : _vm.uriResources + '/' + contact.prof_picture,"alt":""}}),_c('p',{staticClass:"mb-0 text-capitalize"},[_vm._v(" "+_vm._s(contact.name.toLowerCase())+" ")])])])])}),_vm._l((_vm.contacts.favorites.slice(0, 8)),function(contact,index){return _c('div',{key:`fav${index}`,staticClass:"cell"},[(index <= 4)?_c('div',{staticClass:"onboard-slide"},[_c('div',{on:{"click":function($event){return _vm.loadAndShowDataInModal(contact.id, 'favorite')}}},[_c('img',{staticClass:"rounded-circle",attrs:{"src":contact.core_employee.prof_picture == null
                      ? _vm.imgAvatar
                      : _vm.uriResources +
                        '/' +
                        contact.core_employee.prof_picture,"alt":""}}),_c('p',{staticClass:"mb-0 text-capitalize"},[_vm._v(" "+_vm._s(contact.core_employee.name.toLowerCase())+" ")])])]):_vm._e()])})],2)])])]),_c('CompModalContact',{attrs:{"modal-contact":_vm.modalContact,"actual-type-contact":_vm.actualTypeContact}}),_c('CompModalContacts')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 p-0"},[_c('h4',{staticClass:"textColorPrimary tw-font-robotoBold tw-text-xl md:tw-text-2xl"},[_vm._v(" Tus contactos ")])])
}]

export { render, staticRenderFns }