<script setup>
import config from "@/services/config";

const uriResources = config.beerOfficeURL;
const props = defineProps({
  content: {
    type: Array,
  },
  category: {
    type: String,
  },
});

const pushDataLayer = (subcategory) => {
  window.dataLayer.push({
    event: "TrackEvent-CategoryArticles",
    Category: "Somos embajadores",
    SubCategory: subcategory,
    Action: "Clics",
  });
};
</script>
<template>
  <div class="tw-mb-4 md:tw-mb-5">
    <div class="row mt-4">
      <div
        class="mb-4 col-12 col-md-6 he-300"
        v-for="(item, idx) in props.content"
        :key="idx"
        :class="item.columns_class"
      >
        <router-link
          :to="{
            name: 'Article Categories',
            params: {
              id: item.id,
              Category: props.category,
              SubCategory: item.name,
            },
          }"
        >
          <div
            class="p-2 text-center h-100 tw-bg-cover tw-bg-center"
            :style="{
              'background-image':
                '-webkit-gradient(linear, left 20, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.6))), url(' +
                uriResources +
                '/' +
                item.image +
                ')',
            }"
            @click="pushDataLayer(item.name)"
          >
            <div
              class="w-100 h-100 d-flex justify-content-center align-items-center"
            >
              <p class="tw-text-white tw-font-tolyerBold fs-40">
                {{ item.name }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
