<script setup>
import { ref } from "vue";
import CompContestsContestGridCard from "@/components/contests/CompContestsContestGridCard.vue";

const isContestsInformation = ref(true);
const props = defineProps({
  contests: Array,
});
</script>

<template>
  <div v-if="isContestsInformation">
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-3 lg:tw-gap-4">
      <div v-for="(contest, idx) in props.contests" :key="idx">
        <comp-contests-contest-grid-card :contest="contest" />
      </div>
    </div>
  </div>
  <div v-else>
    <p
      class="tw-font-roboto tw-text-red-350 tw-text-center tw-text-xl lg:tw-text-2xl"
    >
      Lo sentimos, no tenemos concursos activos en este momento
    </p>
  </div>
</template>

<style scoped></style>
