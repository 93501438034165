<template>
  <div class="tw-container-general">
    <ButtonsPeopleCycle
      class="py-3 mb-2 mb-md-4"
      :peopleCycleButtonsActive="2"
    />
    <PCNavigate :pc_item="pc_item" />
    <!-- ALL CONTENT -->
    <div class="container-md pb-5" id="general-wrapper">
      <div class="mb-5" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="../../../assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div v-else>
        <div class="tw-shadow-lg tw-rounded-lg d-flex flex-column p-0">
          <!-- People Cycle Title and Description-->
          <div
            class="tw-shadow-lg tw-rounded-lg he-200"
            :style="'background-color: ' + peopleCycleItem.color"
          >
            <div class="container h-100 d-flex align-items-md-center">
              <div class="row align-items-center px-3 px-md">
                <div class="col-3 col-md-2 text-center order-2 order-md-1">
                  <img
                    :src="uriResources + '/' + peopleCycleItem.icon"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  class="col-9 col-md-4 text-uppercase tw-font-tolyerBold text-white p-0 order-1 order-md-2"
                >
                  <p
                    class="fs-44 fs-md-70 line-height-40 line-height-md-60 p-0 m-0"
                  >
                    {{ peopleCycleItem.title }}
                  </p>
                </div>

                <div class="col-12 col-md-5 order-3 p-0">
                  <p
                    class="text-white tw-font-robotoLight tw-text-xs fs-md-20 line-height-18 line-height-md-24 text-md-right pl-md-4"
                  >
                    {{ peopleCycleItem.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- People Cycle Main Image-->
          <div class="px-3 px-md-5 pt-5 pb-3">
            <div class="text-center position-relative">
              <img
                :src="uriResources + '/' + peopleCycleItem.image"
                class="img-fluid"
                alt=""
              />
              <img
                src="@/assets/img/peopleCycle/cc_icon_cp.png"
                class="h-10 h-md-100 tag"
                alt=""
              />
            </div>
          </div>
          <!-- People Cycle Sub Title and Sub Description-->
          <div
            class="px-3 px-md-5 pt-5 pb-3"
            v-if="
              peopleCycleItem.sub_title !== null ||
              peopleCycleItem.sub_description !== null
            "
          >
            <div class="row justify-content-center">
              <div class="col-md-8 text-center position-relative px-3 px-md-5">
                <p class="tw-font-robotoBold text-uppercase fs-md-28">
                  {{ peopleCycleItem.sub_title }}
                </p>
              </div>

              <div class="col-md-8 text-center position-relative px-3 px-md-5">
                <p class="tw-font-roboto fs-md-20 line-height-md-24">
                  {{ peopleCycleItem.sub_description }}
                </p>
              </div>
            </div>
          </div>
          <!-- People Cycle Sub Items-->
          <div
            class="px-3 px-md-5 mb-5"
            v-if="peopleCycleItem.evaluator_modes.length > 0"
          >
            <div
              class="d-flex flex-row justify-content-around px-2 px-md-2 py-1 pt-md-1"
            >
              <!-- mobile -->
              <div
                class="d-flex flex-column my-1 mx-2 mx-md-2 p-0 p-md-0 d-md-none"
                v-for="item in peopleCycleItem.evaluator_modes"
                :key="item.id"
              >
                <div
                  class="shadow-box-7 bg-white tw-rounded-lg d-flex flex-column"
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items tw-rounded-tr-lg tw-rounded-tl-lg shadow-box-7 bg-white"
                  >
                    <div
                      class="d-flex mt-2 mx-auto p-0 w-50 w-md-80 text-center tw-rounded-2xl justify-content-center"
                      :style="'background: ' + item.color_background"
                    >
                      <p
                        class="tw-font-roboto tw-text-xs fs-md-16 text-white my-1"
                      >
                        {{ item.grades }}º
                      </p>
                    </div>
                    <div class="mx-auto col-8 pb-1">
                      <a :href="item.url">
                        <img
                          :src="uriResources + '/' + item.icon"
                          class="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-md-row flex-column h-100 tw-rounded-br-lg tw-rounded-bl-lg px-3 px-md"
                  >
                    <div class="col-md-4 text-center p-1">
                      <div class="tw-font-robotoBold tw-text-xxs">
                        ¿Quienes son los evaluados?
                      </div>
                      <div class="text-wrap text-break w-100">
                        <p
                          v-for="(subitem, index) in item.evaluated"
                          :key="index"
                          class="my-1 tw-font-roboto tw-text-xxs line-height-14"
                        >
                          {{ subitem }}
                        </p>
                      </div>
                    </div>
                    <hr class="w-30 my-1" />
                    <div class="col-md-4 text-center p-1">
                      <div class="tw-font-robotoBold tw-text-xxs">
                        ¿Quienes evalúan?
                      </div>
                      <div class="text-wrap text-break w-100">
                        <p
                          v-for="(subitem, index) in item.evaluator"
                          :key="index"
                          class="my-1 tw-font-roboto tw-text-xxs line-height-14"
                        >
                          {{ subitem }}
                        </p>
                      </div>
                    </div>
                    <hr class="w-30 my-1" />
                    <div class="col-md-4 text-center p-1">
                      <div class="tw-font-robotoBold tw-text-xxs">Proceso</div>
                      <div class="text-wrap text-break w-100">
                        <p
                          v-for="(subitem, index) in item.process"
                          :key="index"
                          class="my-1 tw-font-roboto tw-text-xxs line-height-14"
                        >
                          {{ subitem }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- desktop -->
              <div
                class="col col-md my-1 mx-2 mx-md-2 tw-rounded-lg p-0 p-md-0 no-mobile"
                v-for="item in peopleCycleItem.evaluator_modes"
                :key="item.id"
              >
                <div class="d-flex flex-md-row flex-column">
                  <div
                    class="shadow-box-7 bg-white tw-rounded-lg he-md-180 w-md-30 z-index-4 d-flex flex-column align-items-center justify-content-center"
                  >
                    <div class="w-100 mt-2">
                      <div
                        class="d-flex m-auto w-md-80 text-center tw-rounded-2xl justify-content-center"
                        :style="'background: ' + item.color_background"
                      >
                        <p
                          class="tw-font-roboto tw-text-xs fs-md-16 text-white my-2"
                        >
                          {{ item.grades }}º
                        </p>
                      </div>
                    </div>

                    <div class="w-50 mb-2">
                      <a :href="item.url">
                        <img
                          :src="uriResources + '/' + item.icon"
                          class="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>

                  <div
                    class="shadow-box-7 bg-white tw-rounded-lg he-md-180 w-md-75 z-index-3 position-absolute tw-right-0 container"
                  >
                    <div class="row ml-md-2 pr-1 pt-2">
                      <div class="col-md-4 text-center p-1">
                        <div
                          class="tw-font-robotoBold tw-text-xxs border-right border-dark"
                        >
                          ¿Quienes son los evaluados?
                        </div>
                        <div class="text-wrap text-break w-100">
                          <p
                            v-for="(subitem, index) in item.evaluated"
                            :key="index"
                            class="my-1 tw-font-roboto tw-text-xxs line-height-14"
                          >
                            {{ subitem }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4 text-center p-1">
                        <div class="tw-font-robotoBold tw-text-xxs">
                          ¿Quienes evalúan?
                        </div>
                        <div class="text-wrap text-break w-100">
                          <p
                            v-for="(subitem, index) in item.evaluator"
                            :key="index"
                            class="my-1 tw-font-roboto tw-text-xxs line-height-14"
                          >
                            {{ subitem }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4 text-center p-1">
                        <div
                          class="tw-font-robotoBold tw-text-xxs border-left border-dark"
                        >
                          Proceso
                        </div>
                        <div class="text-wrap text-break w-100">
                          <p
                            v-for="(subitem, index) in item.process"
                            :key="index"
                            class="my-1 tw-font-roboto tw-text-xxs line-height-14"
                          >
                            {{ subitem }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- CALENDAR -->
        <TabPeople
          :items="peopleCycleItem.indicator"
          v-if="peopleCycleItem.indicator"
        />

        <!-- CAPABILITIES -->
        <div class="container mt-2 mt-md-4">
          <div class="row">
            <div
              class="tw-shadow-lg bg-white tw-rounded-lg col-12 col-md-12 py-3 py-md-4 my-3 my-md-4"
              v-for="item in peopleCycleItem.capabilities"
              :key="item.id"
            >
              <div class="text-center px-3 pt-3">
                <p class="tw-font-tolyerBold fs-34 fs-md-50">
                  {{ item.title }}
                </p>
              </div>
              <div class="container">
                <div class="row">
                  <div
                    class="col-sm-4 py-md py-2"
                    v-for="item in item.people_cycle_capabilitie_card"
                    :key="item.id"
                  >
                    <div
                      class="tw-rounded-lg d-flex flex-md-column flex-row text-center overflow-hidden"
                      :style="
                        'background-color: ' +
                        item.color_background +
                        '; border: 2px solid ' +
                        item.color_content
                      "
                    >
                      <div
                        class="overflow-hidden text-center p-5 d-flex flex-row flex-md-column"
                      >
                        <div class="col-6 col-md-12">
                          <img
                            :src="uriResources + '/' + item.icon"
                            class="img-fluid p-md-3"
                            alt=""
                          />
                        </div>
                        <div class="col-6 col-md my-auto">
                          <p
                            class="tw-font-roboto tw-text-base fs-md-18 line-height-20 tw-font-bold m-0"
                            :style="'color: ' + item.color_content"
                          >
                            {{ item.title }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-end mt-3 mb-2">
                  <div class="col col-md-2 px-0">
                    <a
                      :href="item.url"
                      class="tw-text-sm fs-md-14 line-height-16 tw-font-roboto hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-3 w-100 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                    >
                      {{ item.url_name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TIPS -->
        <div class="container-fluid mt-3 mt-md-4">
          <div class="row">
            <div
              class="tw-shadow-lg bg-white tw-rounded-lg col-12 col-md-12 py-3 py-md-4"
            >
              <div class="text-center px-3 pt-3">
                <p class="tw-font-tolyerBold fs-34 fs-md-50">TIPS</p>
              </div>
              <div class="container">
                <div class="row text-center">
                  <div
                    class="col-sm-6 py-md-3 py-2"
                    v-for="item in peopleCycleItem.tips"
                    :key="item.id"
                  >
                    <img
                      :src="uriResources + '/' + item.image"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Videos -->
        <div class="mt-4 mt-md-4">
          <div class="row">
            <div class="tw-rounded-lg col-12 col-md-12 py-4">
              <div class="text-center px-3 pt-3">
                <p class="tw-font-tolyerBold fs-34 fs-md-50">
                  VIDEOS QUE TE PUEDEN INTERESAR
                </p>
              </div>
              <CompCarouselPeopleCycle :videos="peopleCycleItem.videos" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";
import cvMixin from "@/mixins/user/cv";
import people from "@/mixins/user/people";
import ButtonsPeopleCycle from "@/components/general/ButtonsPeopleCycle";
import CompCarouselPeopleCycle from "@/components/general/CompCarouselPeopleCycle";
import PCNavigate from "@/components/general/PCNavigate";
import TabPeople from "@/components/people/tabs/TabPeople";

export default {
  name: "CapabilitiesView",
  mixins: [cvMixin, people],
  components: {
    ButtonsPeopleCycle,
    CompCarouselPeopleCycle,
    PCNavigate,
    TabPeople,
  },
  data() {
    return {
      isLoading: true,
      uriResources: config.beerOfficeURL,
      pc_item: 4,
    };
  },
  async created() {
    this.isLoading = true;

    await this.actGetPeopleCycleItem({
      id: 4,
    })
      .then(() => {})
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
        this.detectLink(this.peopleCycleItem.capabilities);
        this.detectLink(this.peopleCycleItem.evaluator_modes);
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modPeopleCycle", ["peopleCycleItem"]),
  },
  methods: {
    ...mapActions("modPeopleCycle", ["actGetPeopleCycleItem"]),
    detectLink(subitem) {
      for (let i = 0; i < subitem.length; i++) {
        if (
          subitem[i].url.indexOf("http://") === 0 ||
          subitem[i].url.indexOf("https://") === 0
        ) {
          subitem[i].url;
        } else {
          subitem[i].url = this.uriResources + "/" + subitem[i].url;
        }
      }
    },
  },
};
</script>

<style scoped>
.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>
