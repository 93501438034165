var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-px-3 tw-bg-white hover:tw-bg-gray-200",class:{
    'tw-cursor-pointer': _setup.props.notification.url,
    'tw-bg-red-50': _setup.props.notification.read_at == null,
  }},[_c('div',{staticClass:"tw-border-b tw-border-gray-300 tw-py-3 tw-h-auto"},[_c('div',{staticClass:"tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-mb-3"},[_c('div',{staticClass:"tw-col-span-8 md:tw-col-span-9"},[_c('p',{staticClass:"tw-mb-0 tw-text-black tw-text-base tw-font-robotoBold"},[_vm._v(" "+_vm._s(_setup.props.notification.title)+" ")])]),_c('div',{staticClass:"tw-col-span-3 md:tw-col-span-2"},[(
            _setup.differenceInDays(
              new Date(_setup.props.notification.created_at),
              new Date()
            ) <= 10
          )?_c('span',{staticClass:"tw-rounded-md tw-bg-red-50 tw-px-2 tw-py-1 tw-text-red-400 tw-font-robotoBold",staticStyle:{"font-size":"10px"}},[_vm._v(" ¡Nuevo! ")]):_vm._e()]),_c('div',{staticClass:"tw-col-span-1 tw-flex tw-justify-center tw-items-center"},[(_setup.props.notification.url)?_c('i',{staticClass:"icon-flecha-der tw-text-red-400 tw-text-xl"}):_vm._e()])]),_c('p',{staticClass:"tw-mb-0 tw-font-roboto tw-text-xs tw-text-black"},[_vm._v(" "+_vm._s(_setup.props.notification.message)+" ")]),_c('p',{staticClass:"tw-mt-2 tw-mb-0 tw tw-font-roboto tw-text-xs tw-italic tw-text-black"},[_vm._v(" "+_vm._s(_setup.getDays === 0 ? "Hoy" : _setup.getDays === 1 ? "Ayer" : "Hace " + _setup.getDays + " días")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }