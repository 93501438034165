<script setup>
import { computed } from "vue";

const emit = defineEmits(["switch"]);

const props = defineProps({
  option_1: {
    type: String,
    required: true,
  },
  option_2: {
    type: String,
    required: true,
  },
  switch: {
    type: Boolean,
    default: false,
  },
});

const changeSwitch = () => {
  emit("switch", !props.switch);
};

const switchValue = computed(() => {
  return props.switch;
});
</script>

<template>
  <div
    class="tw-rounded-full tw-w-full tw-h-8 lg:tw-h-9 tw-border tw-border-black tw-relative tw-cursor-pointer"
    @click="changeSwitch()"
  >
    <div
      :class="switchValue ? 'tw-translate-x-full' : 'tw-translate-x-0'"
      class="tw-w-6/12 tw-rounded-full tw-border-4 tw-border-red-350 tw-h-full tw-bg-red-350 tw-transform tw-transition-transform tw-duration-500 tw-absolute tw-z-1"
    ></div>
    <div
      class="tw-grid tw-grid-cols-2 tw-w-full tw-h-full tw-text-center tw-absolute tw-z-10"
    >
      <div class="tw-flex tw-items-center tw-justify-center">
        <p
          :class="switchValue ? 'tw-text-gray-600' : 'tw-text-white'"
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-transform tw-transition-all tw-duration-700 tw-whitespace-nowrap"
        >
          {{ props.option_1 }}
        </p>
      </div>
      <div class="tw-flex tw-items-center tw-justify-center">
        <p
          :class="switchValue ? 'tw-text-white' : 'tw-text-gray-600'"
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-transform tw-transition-all tw-duration-700"
        >
          {{ props.option_2 }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
