import { render, staticRenderFns } from "./Competitions.vue?vue&type=template&id=68284ae6&scoped=true"
import script from "./Competitions.vue?vue&type=script&lang=js"
export * from "./Competitions.vue?vue&type=script&lang=js"
import style0 from "./Competitions.vue?vue&type=style&index=0&id=68284ae6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68284ae6",
  null
  
)

export default component.exports