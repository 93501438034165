<script setup>
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
// import { pushLink } from "@/helpers/linkHelpers";
import { useArticlesStore } from "@/stores/articlesStore";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import MoleculeHeaderCard from "@/components/molecules/MoleculeHeaderCard.vue";
// import MoleculeHeadingTextButton from "@/components/molecules/MoleculeHeadingTextButton.vue";
import OrganismTalentFestArticlesGrid from "@/components/organisms/OrganismTalentFestArticlesGrid.vue";

const isLoading = ref(false);
const { actGetArticlesByCategory } = useArticlesStore();
const { articles } = storeToRefs(useArticlesStore());

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetArticlesByCategory("talent_fest");
  console.log(articles.value);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Talent Fest" />
    <title-bar title="Talent Fest" />
    <main class="tw-container-general">
      <molecule-header-card
        media-type="video"
        media-src="assets/videos/talent_fest_video.mp4"
        media-location="right"
        paragraph="Todas las áreas en un mismo encuentro que te llevará a conocer el 360 de nuestra compañía y a proyectar tu carrera en el área que más te apasiona y te mueve."
        heading-text="¿Qué es Talent Fest?"
      />
      <!-- Oportunidades -->
      <!--      <molecule-heading-text-button
        rounded
        class="tw-mt-4 lg:tw-mt-6"
        button-text="Conoce más"
        paragraph-text="Explora las vacantes disponibles y descubre cómo puedes dar el siguiente paso en tu carrera dentro del equipo de Creadores de Brindis."
        paragraph-color="white"
        heading-text="Oportunidades"
        heading-color="white"
        bg-color="red-350"
        button-color="white"
        button-text-color="red-350"
        button-border-color="red-350"
        @button-click="() => pushLink('/ambassadors/people/opportunity/all')"
      />-->
      <organism-talent-fest-articles-grid
        class="tw-mt-4 lg:tw-mt-6"
        :articles="articles"
      />
    </main>
  </div>
</template>

<style scoped></style>
