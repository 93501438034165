import Axios from "axios";

export const gamificationURL = process.env.VUE_APP_PEOPLETOUR_URL;

const apiURL = {
  tour: `${gamificationURL}/jsonapi/node/tour`,
  stage: `${gamificationURL}/jsonapi/node/stage`,
  activity: `${gamificationURL}/jsonapi/node/activity`,
  evidence: `${gamificationURL}/jsonapi/node/evidence`,
  points: `${gamificationURL}/api/get-points`,
  bp: `${gamificationURL}/api/tours/bp`,
  bpAssign: `${gamificationURL}/api/tours/bp-assign`,
  uploadEvidence: `${gamificationURL}/api/tours/upload-evidence`,
};

const callback = {
  success: (response) => {
    return response.data;
  },
  errorConn: () => {
    return false;
  },
};

export default {
  get: (data) => {
    let url = apiURL[data.uri];
    if (data.includes) {
      url += url.includes("?") ? "&" : "?"; // Aquí se verifica si ya hay un parámetro en la URL
      url += "include=" + data.includes;
    }
    if (data.filter) {
      data.filter.forEach((item, index) => {
        url += `${index === 0 && !url.includes("?") ? "?" : "&"}filter[${
          item.field
        }]=${item.value}`; // Aquí se verifica si es el primer parámetro o si ya hay un parámetro en la URL
      });
    }
    if (data.queryParams) {
      for (let param in data.queryParams) {
        url += `${!url.includes("?") ? "?" : "&"}${param}=${
          data.queryParams[param]
        }`; // Aquí se verifica si es el primer parámetro o si ya hay un parámetro en la URL
      }
    }
    if (data.sort) {
      url += "&sort=field_orden";
    }
    const request = Axios.get(url);
    return request.then(callback.success).catch(callback.errorConn);
  },
  post: (data) => {
    let url = apiURL[data.uri];
    if (data.queryParams) {
      for (let param in data.queryParams) {
        if (data.queryParams.id) {
          url += "/" + data.queryParams.id;
        } else {
          url += `${!url.includes("?") ? "?" : "&"}${param}=${
            data.queryParams[param]
          }`; // Aquí se verifica si es el primer parámetro o si ya hay un parámetro en la URL
        }
      }
    }

    const request = Axios.post(url, data.data);
    return request.then(callback.success).catch(callback.errorConn);
  },
};
