<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import CompOrderDetail from "@/components/ecommerce/CompOrderDetail";
import CompEcommerceCouponDetail from "@/components/ecommerce/CompEcommerceCouponDetail.vue";
import { isPastDate } from "@/helpers/datesHelpers";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const isLoading = ref(false);
const activeIndex = ref(0);
const activeCategory = ref("pending");
const activeOrderType = ref("");
const breadcrumb = ref([
  {
    id: 0,
    name: "Inicio",
    link: "/home",
    active: false,
  },
  {
    id: 1,
    name: "Bienestar",
    link: "#",
    active: false,
  },
  {
    id: 2,
    name: "Beneficios",
    link: "/care-benefits/benefits",
    active: false,
  },
  {
    id: 3,
    name: "Reserva de Espacios",
    link: "#",
    active: true,
  },
]);

const activeOrder = computed(() => {
  if (activeCategory.value === "pending") {
    return ecommerceStore.ordersPending[activeIndex.value];
  } else {
    return ecommerceStore.ordersHistory[activeIndex.value];
  }
});

const parseDate = computed(() => {
  return function (created_at) {
    let date = new Date(created_at);
    let month = date.toLocaleString("es-ES", { month: "long" });
    let day = date.toLocaleString("es-ES", { day: "numeric" });
    let year = date.toLocaleString("es-ES", { year: "numeric" });

    // return date in format: month day of year
    return `${month[0].toUpperCase()}${month.slice(1)} ${day} de ${year}`;
  };
});

const selectAccordionItem = (category, idx, type_order) => {
  activeCategory.value = category;
  activeIndex.value = idx;
  activeOrderType.value = type_order;
};

const couponDateStatus = (date) => {
  return isPastDate(date) ? "Expirado" : "Vigente";
};

onBeforeMount(async () => {
  isLoading.value = true;
  await ecommerceStore.actGetOrdersPending(userStore.user.id);
  await ecommerceStore.actGetOrdersHistory(userStore.user.id).then(() => {
    if (ecommerceStore.ordersPending.length > 0) {
      activeCategory.value = "pending";
    } else if (ecommerceStore.ordersHistory.length > 0) {
      activeCategory.value = "history";
    }
    isLoading.value = false;
  });
});
</script>
<template>
  <div>
    <portal-target name="modal-qr" />
    <mobile-bar title="Mis Pedidos" />
    <title-bar :breadcrumb="breadcrumb" title="Mis Pedidos" />
    <div class="tw-container-general tw-mt-5" v-if="!isLoading">
      <div
        v-if="
          ecommerceStore.ordersPending.length > 0 ||
          ecommerceStore.ordersHistory.length > 0
        "
      >
        <div
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-10 tw-gap-10 lg:tw-gap-20"
        >
          <div class="tw-flex tw-flex-col tw-gap-5 lg:tw-col-span-6">
            <div v-if="ecommerceStore.ordersPending.length > 0">
              <div class="tw-mb-5">
                <p class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0">
                  Tus pedidos en curso
                </p>
              </div>
              <comp-accordion-parent>
                <comp-accordion-item
                  v-for="(order, idx) in ecommerceStore.ordersPending"
                  :id="'order_' + idx"
                  :key="idx"
                  :border="'tw-border tw-border-gray-250 tw-rounded-2.5xl'"
                  :padding="'tw-p-3'"
                  :division="false"
                  :open-accordion-desk="false"
                  :open-accordion-mobile="true"
                  @selectAccordionItem="
                    selectAccordionItem('pending', idx, order.type_order)
                  "
                >
                  <template v-slot:mainInfo>
                    <div class="tw-grid tw-grid-cols-col2_fit_last">
                      <div class="tw-grid tw-grid-rows-1">
                        <p
                          class="tw-text-xs lg:tw-text-base tw-m-0"
                          :class="
                            activeIndex === idx && activeCategory === 'pending'
                              ? 'tw-text-red-350 tw-font-robotoBold'
                              : 'tw-font-roboto'
                          "
                        >
                          {{
                            order.type_order === "TADA_CODE"
                              ? parseDate(order.created_at) + "/ Cupón TaDa"
                              : parseDate(order.created_at) +
                                " / No. " +
                                order.id
                          }}
                        </p>
                      </div>
                      <div class="tw-h-full tw-flex tw-items-center">
                        <div class="tw-status-bubble tw-bg-red-350">
                          <p class="tw-m-0 tw-text-white">
                            {{
                              order.type_order === "TADA_CODE"
                                ? couponDateStatus(order.due_date)
                                : order.ecomm_order_state.name
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:secondaryInfo>
                    <comp-ecommerce-coupon-detail
                      :order="order"
                      v-if="order.type_order === 'TADA_CODE'"
                    />
                    <comp-order-detail :order="order" v-else />
                  </template>
                </comp-accordion-item>
              </comp-accordion-parent>
            </div>
            <div v-if="ecommerceStore.ordersHistory.length > 0">
              <div class="tw-mb-5">
                <p class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-m-0">
                  Tus pedidos finalizados
                </p>
              </div>
              <comp-accordion-parent>
                <comp-accordion-item
                  v-for="(order, idx) in ecommerceStore.ordersHistory"
                  :id="'order_' + idx"
                  :key="idx"
                  :border="'tw-border tw-border-gray-250 tw-rounded-2.5xl'"
                  :padding="'tw-p-3'"
                  :division="false"
                  :open-accordion-desk="false"
                  :open-accordion-mobile="true"
                  @selectAccordionItem="
                    selectAccordionItem('history', idx, order.type_order)
                  "
                >
                  <template v-slot:mainInfo>
                    <div class="tw-grid tw-grid-cols-col2_fit_last">
                      <div class="tw-grid tw-grid-rows-1">
                        <p
                          class="tw-text-xs lg:tw-text-base tw-m-0"
                          :class="
                            activeIndex === idx && activeCategory === 'history'
                              ? 'tw-text-red-350 tw-font-robotoBold'
                              : 'tw-font-roboto'
                          "
                        >
                          {{
                            order.type_order === "TADA_CODE"
                              ? parseDate(order.created_at) + "/ Cupón TaDa"
                              : parseDate(order.created_at) +
                                " / No. " +
                                order.id
                          }}
                        </p>
                      </div>
                      <div class="tw-h-full tw-flex tw-items-center">
                        <div class="tw-status-bubble tw-bg-red-350">
                          <p class="tw-m-0 tw-text-white">
                            {{ order.ecomm_order_state.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:secondaryInfo>
                    <comp-ecommerce-coupon-detail
                      :order="order"
                      v-if="order.type_order === 'TADA_CODE'"
                    />
                    <comp-order-detail :order="order" v-else />
                  </template>
                </comp-accordion-item>
              </comp-accordion-parent>
            </div>
          </div>

          <div class="tw-hidden lg:tw-block lg:tw-col-span-4">
            <div
              class="tw-w-full tw-rounded-2.5xl tw-p-6 tw-border tw-border-gray-250"
            >
              <div class="tw-flex tw-flex-col tw-gap-5">
                <div class="tw-grid tw-grid-rows-1">
                  <p
                    class="tw-text-xs lg:tw-text-base tw-m-0 tw-text-red-350 tw-font-robotoBold"
                  >
                    {{
                      activeOrder?.type_order === "TADA_CODE"
                        ? parseDate(activeOrder?.created_at) + " / Cupón TaDa"
                        : parseDate(activeOrder?.created_at) +
                          " / No. " +
                          activeOrder?.id
                    }}
                  </p>
                </div>
                <comp-ecommerce-coupon-detail
                  :order="activeOrder"
                  v-if="activeOrder?.type_order === 'TADA_CODE'"
                />
                <comp-order-detail :order="activeOrder" v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
