<script setup>
import { ref } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";

const articlesStore = useArticlesStore();

const send = ref({
  title: "",
  author_me: 0,
  author: "",
  content: "",
  references: "",
  media: [],
});
const filesAttached = ref([]);
const filesAttached2 = ref([]);
const formData = ref(new FormData());

const emit = defineEmits(["articleSent", "closeModal"]);

const selectFiles = () => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*,video/*");
  input.click();
  input.onchange = () => {
    const file = input.files[0];
    let url = URL.createObjectURL(file);
    if (filesAttached.value.length < 8) {
      if (file.type.match("image")) {
        if (file.size <= 10000000) {
          const preview = document.createElement("img");
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              preview.src = reader.result;
              filesAttached.value.push({
                file: file,
                url: url,
                thumbnail: reader.result,
              });
              filesAttached2.value.push(file);
            }.bind(this),
            false
          );

          if (file) {
            reader.readAsDataURL(file);
          }
        } else {
          alert("El archivo es muy pesado");
        }
      } else if (file.type.match("video")) {
        if (file.size <= 40000000) {
          let video = document.createElement("video");
          video.src = URL.createObjectURL(file);
          video.type = "video/mp4";
          video.currentTime = 1;

          video.addEventListener(
            "seeked",
            function () {
              let canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              canvas
                .getContext("2d")
                .drawImage(video, 0, 0, canvas.width, canvas.height);
              let dataURL = canvas.toDataURL("image/png");

              filesAttached.value.push({
                file: file,
                url: url,
                thumbnail: dataURL,
              });
              filesAttached2.value.push(file);
            }.bind(this)
          );
        } else {
          alert("El archivo es muy pesado");
        }
      }
    } else {
      alert("No puedes subir más de 8 archivos");
    }
  };
};

const removeFile = (idx) => {
  filesAttached.value.splice(idx, 1);
  filesAttached2.value.splice(idx, 1);
};

const submitArticle = () => {
  formData.value.append("type", "ARTICLE");
  formData.value.append("title", send.value.title);
  formData.value.append("content", send.value.content);
  formData.value.append("author_me", send.value.author_me);
  send.value.author_me === 0
    ? formData.value.append("author", send.value.author)
    : "";
  send.value.references !== ""
    ? formData.value.append("ref", send.value.references)
    : "";
  for (let i = 0; i < filesAttached2.value.length; i++) {
    formData.value.append("resources[" + i + "]", filesAttached2.value[i]);
  }
  // formData.value.append("resources", filesAttached2.value);
  articlesStore.actPostSuggestedArticle(formData.value).then(() => {
    articleSent();
  });
};

const articleSent = () => {
  emit("articleSent");
};

const closeModal = () => {
  emit("closeModal");
};

const changeAuthorMe = () => {
  send.value.author_me === 1
    ? (send.value.author_me = 0)
    : (send.value.author_me = 1);
};
</script>
<template>
  <div class="tw-relative tw-overflow-scroll invisible-scrollbar">
    <div id="title" class="tw-sticky tw-top-0 tw-bg-white">
      <p class="tw-font-robotoBold tw-text-red-350 tw-text-xl md:tw-text-3xl">
        Enviar un artículo
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-lg">
        Puedes escribir un artículo o compartir uno que conozcas.
      </p>
    </div>
    <div id="input" class="tw-mt-3 lg:tw-mt-4 tw-overflow-scroll">
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Nombre del artículo
        </p>
        <input
          type="text"
          name=""
          id="commentField"
          class="input-field"
          v-model="send.title"
          autocomplete="off"
          placeholder="Nombre..."
        />
      </div>
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Autor
        </p>
        <input
          type="text"
          name=""
          id="commentField"
          class="input-field"
          :class="send.author_me === 1 ? 'tw-bg-gray-250' : 'tw-bg-white'"
          :disabled="send.author_me === 1"
          v-model="send.author"
          autocomplete="off"
          placeholder="Autor..."
        />
      </div>
      <div class="tw-flex tw-mt-1 tw-mb-2">
        <label
          class="check-group tw-block tw-relative tw-pl-6 tw-m-0 tw-cursor-pointer"
        >
          <input
            class="checkbox tw-absolute tw-opacity-0 tw-cursor-pointer tw-h-0 tw-w-0"
            type="checkbox"
            value="1"
            @change="changeAuthorMe()"
          />
          <span
            class="checkmark tw-absolute tw-top-0 tw-left-0 tw-w-4 tw-h-4 lg:tw-w-5 lg:tw-h-5 tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-opacity-100"
          ></span>
        </label>
        <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-ml-1 tw-mb-0">
          Yo soy el autor
        </p>
      </div>
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Contenido
        </p>
        <textarea
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-2xl tw-bg-white tw-py-2 tw-px-2 tw-my-2 lg:tw-my-3 lg:tw-px-4 lg:tw-py-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm tw-border-solid tw-border tw-border-gray-250 focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent tw-resize-none tw-h-28"
          v-model="send.content"
          autocomplete="off"
          placeholder="Escribe aquí tu contenido"
        ></textarea>
      </div>
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Referencias
        </p>
        <input
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-full tw-bg-white tw-py-2 tw-px-2 tw-my-2 lg:tw-my-3 lg:tw-px-4 lg:tw-py-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm tw-border-solid tw-border tw-border-gray-250 focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent"
          v-model="send.references"
          autocomplete="off"
          placeholder="Referencias..."
        />
      </div>
      <div class="tw-my-1">
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Añadir imágenes o vídeos
        </p>
        <div class="tw-grid tw-grid-cols-3 tw-gap-8 tw-mt-5 tw-mb-8">
          <div v-for="(file, idx) in filesAttached" :key="idx">
            <div
              class="tw-rounded-3xl lg:tw-rounded-4xl tw-bg-red-350 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-relative"
              style="aspect-ratio: 1"
            >
              <div
                class="tw-absolute tw--top-2 tw--right-2 tw-w-8 tw-h-8 lg:tw-w-10 lg:tw-h-10 tw-bg-black tw-rounded-full tw-flex tw-items-center tw-justify-center"
                @click="removeFile(idx)"
              >
                <i
                  class="icon-remover-signo tw-text-white tw-text-2xl lg:tw-text-3xl"
                ></i>
              </div>
              <img
                :src="file.thumbnail ? file.thumbnail : file.url"
                alt=""
                class="tw-w-full tw-h-full tw-object-cover tw-object-center tw-rounded-3xl lg:tw-rounded-4xl"
              />
            </div>
          </div>
          <div
            class="tw-rounded-3xl lg:tw-rounded-4xl tw-bg-red-350 tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
            style="aspect-ratio: 1"
            @click="selectFiles()"
          >
            <i
              class="icon-signo-mas tw-text-6xl lg:tw-text-7xl tw-text-white"
            ></i>
          </div>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-mt-3">
        <div class="tw-btn-secondary-white" @click="closeModal()">
          <p class="tw-my-1 tw-font-roboto">Cancelar</p>
        </div>
        <div class="tw-btn-primary-red" @click="submitArticle()">
          <p class="tw-my-1 tw-font-roboto">Enviar</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
/**Checkbox */

.check-group {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-group input:checked ~ .checkmark {
  border: 1px solid #00bf79;
  background-color: #00bf79;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-group input:checked ~ .checkmark:after {
  display: block;
}

.check-group .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  --webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .check-group .checkmark:after {
    left: 5px;
    top: 1px;
  }
}

.input-field {
  @apply tw-w-full tw-rounded-full tw-bg-white tw-py-2 tw-px-2 tw-my-2 lg:tw-my-3 lg:tw-px-4 lg:tw-py-4
  tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm tw-border tw-border-solid tw-border-gray-300
  focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent;
}
</style>
