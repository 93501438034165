<script setup>
import { useCoreStore } from "@/stores/coreStore";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";

const { getURLResourceByKey } = useCoreStore();
const { user } = storeToRefs(useUserStore());
</script>
<template>
  <div id="remunerationVideo">
    <!-- Video Cómo funciona tu remuneración -->
    <div
      v-b-modal.modal-remunerationVideo
      class="cont-img mt-4 mt-lg-0 position-relative tw-shadow-md md:tw-shadow-lg bor-banner-2 bor-banner-md-2 overflow-hidden he-150 he-md-220"
    >
      <img
        src="../../assets/img/icons/btn-play-shadow.png"
        alt="play"
        class="position-absolute btn-play z-index-2"
      />
      <div class="d-flex overlayer p-0 tw-z-1">
        <div class="col-5 col-md-5 pl-4">
          <p
            class="text-white text-uppercase tw-font-roboto tw-font-black tw-text-xs fs-md-16 position-absolute tw-bottom-0"
          >
            ¿Cómo funciona tu remuneración?
          </p>
        </div>
      </div>
      <img src="@/assets/img/Onboard3.jpg" class="tw-w-full" alt="man" />
    </div>
    <br />
    <!-- Video general -->
    <div
      v-b-modal.modal-generalVideo
      class="cont-img mt-4 mt-lg-0 position-relative tw-shadow-md md:tw-shadow-lg bor-banner-2 bor-banner-md-2 overflow-hidden he-150 he-md-220"
    >
      <img
        src="../../assets/img/icons/btn-play-shadow.png"
        alt="play"
        class="position-absolute btn-play z-index-2"
      />
      <div class="d-flex overlayer p-0 tw-z-1">
        <div class="col-5 col-md-5 pl-4">
          <p
            class="text-white text-uppercase tw-font-roboto tw-font-black tw-text-xs fs-md-16 position-absolute tw-bottom-0"
          >
            PEOPLE REWARDS
          </p>
        </div>
      </div>
      <img src="@/assets/img/Onboard5.jpg" class="tw-w-full" alt="man" />
    </div>
    <b-modal
      id="modal-remunerationVideo"
      centered
      hide-footer
      :modal-title="false"
      size="lg"
    >
      <p class="modal1-title text-center text-uppercase mb-2">
        ¿cómo funciona tu remuneración?
      </p>
      <p class="modal1-desc text-center">Aquí te explicamos</p>
      <div class="mt-4 w-100">
        <video
          v-if="
            employeeCorePosition === '0-IV' || employeeCorePosition === 'V-VI'
          "
          class="w-100"
          :src="getURLResourceByKey('VIDEO_BONO_ABI')"
          poster="@/assets/videos/placeholder.png"
          controls
        ></video>
        <video
          v-if="
            employeeCorePosition !== '0-IV' &&
            employeeCorePosition !== 'V-VI' &&
            user.core_class_employee.name === 'Planta'
          "
          class="w-100"
          :src="getURLResourceByKey('VIDEO_BONO_PLANTAS')"
          poster="@/assets/videos/placeholder.png"
          controls
        ></video>
        <video
          v-if="
            employeeCorePosition !== '0-IV' &&
            employeeCorePosition !== 'V-VI' &&
            user.core_class_employee.name === 'Administrativo'
          "
          class="w-100"
          :src="getURLResourceByKey('VIDEO_BONO_STI')"
          poster="@/assets/videos/placeholder.png"
          controls
        ></video>
      </div>
    </b-modal>
    <b-modal
      id="modal-generalVideo"
      centered
      hide-footer
      :modal-title="false"
      size="lg"
    >
      <p class="modal1-title text-center text-uppercase mb-2">PEOPLE REWARDS</p>
      <div class="mt-4 w-100">
        <video
          class="w-100"
          :src="getURLResourceByKey('VIDEO_PEOPLE_REWARDS')"
          poster="@/assets/videos/placeholder.png"
          controls
        ></video>
      </div>
    </b-modal>
  </div>
</template>

<script>
import userRemunerationMixin from "@/mixins/user/remuneration";

export default {
  name: "RemunerationVideo",
  mixins: [userRemunerationMixin],
};
</script>

<style scoped>
.bor-banner-2 {
  border-radius: 6px 30px 6px 6px;
}

@media (min-width: 768px) {
  .bor-banner-md-2 {
    border-radius: 10px 50px 10px 10px;
  }
}

.cont-img {
  background-image: url("~@/assets/img/Onboard3.jpg");
  position: relative;
}

.overlayer {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#remunerationVideo .cont-img {
  outline: none;
}

#remunerationVideo .cont-img .btn-play {
  width: 60px;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
}

#modal-remunerationVideo video {
  outline: none;
}

#modal-remunerationVideo .modal-content header {
  border-bottom: 0 none;
}

#modal-remunerationVideo .modal-content .close {
  color: #d82b2b;
  outline: none;
  opacity: 1;
}
</style>
