var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-auto"},[_c('div',{attrs:{"id":"header"}},[_c('p',{staticClass:"tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350 tw-m-0"},[_vm._v(" Comentarios ")]),_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-2 tw-ml-0 tw-my-0 tw-mr-10",domProps:{"innerHTML":_vm._s(_setup.props.item.description)}}),_c('hr',{staticClass:"tw-mb-2"})]),_c('div',{staticClass:"tw-flex-1 tw-overflow-auto",attrs:{"id":"comments"}},[(_setup.props.item.amount_comments === 0)?_c('div',[_c('p',{staticClass:"tw-font-roboto tw-text-xs md:tw-text-sm lg:tw-text-base tw-text-gray-300 lg:tw-my-8"},[_vm._v(" No se ha realizado ningún comentario, el tuyo puede ser el primero. ")])]):_c('div',{staticClass:"tw-my-1 md:tw-my-2 lg:tw-my-3"},[(_setup.isLoading)?_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"tw-w-14",attrs:{"src":require("@/assets/img/icons/loading.gif"),"alt":"loading"}})]):_c('div',{staticClass:"tw-ml-2 tw-mr-8",attrs:{"id":"comments-inside"}},_vm._l((_setup.updatedComments),function(comment,index){return _c('div',{key:index,staticClass:"tw-flex tw-my-3 lg:tw-my-4",attrs:{"id":'comment_' + index}},[_c('div',{staticClass:"tw-mr-3"},[_c('img',{staticClass:"tw-object-cover tw-rounded-full tw-w-10 tw-h-10 lg:tw-w-14 lg:tw-h-14",attrs:{"src":comment.core_employee.prof_picture == null
                  ? _setup.imgAvatar
                  : _setup.uriResources + '/' + comment.core_employee.prof_picture,"alt":""}})]),_c('div',{staticClass:"tw-flex-1"},[_c('p',{staticClass:"tw-font-robotoBold tw-text-sm lg:tw-text-base tw-m-0"},[_vm._v(" "+_vm._s(_setup.capitalizeEachWord(comment.core_employee.name))+" ")]),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-start"},[_c('p',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-3"},[_vm._v(" "+_vm._s(comment.comment)+" ")]),(comment.core_employee.id === _setup.user.id)?_c('i',{staticClass:"icon-cerrar-simple tw-ml-3 tw-text-red-350 tw-cursor-pointer",on:{"click":function($event){return _setup.deleteComment(comment.id)}}}):_vm._e()])])])}),0)])]),_c('div',{staticClass:"tw-text-center",attrs:{"id":"input"}},[_c('hr',{staticClass:"tw-mt-2"}),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',{staticClass:"tw-mr-2 lg:tw-mr-3"},[_c('img',{staticClass:"tw-object-cover tw-rounded-full tw-w-10 tw-h-10 lg:tw-w-14 lg:tw-h-14",attrs:{"src":_setup.user.prof_picture == null
              ? _setup.imgAvatar
              : _setup.uriResources + '/' + _setup.user.prof_picture,"alt":""}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.comment.value),expression:"comment.value"}],staticClass:"tw-w-full tw-rounded-full tw-bg-gray-200 tw-py-2 tw-px-2 lg:tw-px-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm focus:tw-outline-none focus:tw-border-transparent focus:tw-ring-transparent tw-border-transparent",attrs:{"type":"text","name":"","id":"commentField","maxlength":_setup.comment.maxLength,"autocomplete":"off"},domProps:{"value":(_setup.comment.value)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _setup.cleanComment.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.postComment.apply(null, arguments)}],"input":function($event){if($event.target.composing)return;_vm.$set(_setup.comment, "value", $event.target.value)}}}),_c('i',{staticClass:"tw-text-xl md:tw-text-2xl tw-text-gray-320 tw-ml-2 lg:tw-ml-4",class:_setup.comment.value.length > 0
            ? 'icon-triangle-right-full tw-text-red-350 tw-cursor-pointer'
            : 'icon-triangle-right',on:{"click":function($event){_setup.comment.value.length > 0 ? _setup.postComment() : ''}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }