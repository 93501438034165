import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";
import { sortArrayByCriteria } from "@/helpers/arrayHelpers";

const uri = {
  birthdayGiftUri: "/coreEmployees/validateBirthday",
  ordersUri: "/ecommOrders",
  productsUri: "/ecommProductTypes",
  productDetailUri: "/ecommProducts",
  locationsUri: "/ecommDeliverSites",
  tadaUri: "/TaDa",
};

export const useEcommerceStore = defineStore("ecommerce", () => {
  const products = ref([]);
  const product = ref({});
  const locations = ref([]);
  const ordersPending = ref([]);
  const ordersHistory = ref([]);
  const orderCancel = ref([]);
  const shoppingCart = ref({});
  const shoppingCartArray = ref([]);
  const shoppingCartQty = ref(0);
  const gift = ref(null);
  const giftErrorMsj = ref("");
  const birthdayGiftStatus = ref(false);
  const taDaDisplayable = ref(false);
  const taDaAvailable = ref(false);

  async function actGetProducts() {
    const response = await AxiosBeerOffice.get(uri.productsUri, true, {});
    if (response.error) return response.error;
    products.value = response;
    return true;
  }

  async function actGetProductById(id) {
    const response = await AxiosBeerOffice.get(
      uri.productDetailUri + "/" + id,
      true,
      id
    );
    if (response.error) return response.error;
    const { data: productData } = response;
    product.value = productData;
    return true;
  }

  async function actGetLocations() {
    const response = await AxiosBeerOffice.get(uri.locationsUri, true, {});
    if (response.error) return response.error;
    locations.value = response;
    return true;
  }

  async function actPostShoppingProducts(data) {
    giftErrorMsj.value = "";
    const response = await AxiosBeerOffice.post(uri.productsUri, true, data);
    if (response.error) {
      return response.error;
    }

    try {
      if (response.gift) {
        giftErrorMsj.value = response.gift.error.message;
      }
    } catch (error) {
      console.error(error);
    }

    return response;
  }

  async function actGetOrdersHistory(id) {
    const response = await AxiosBeerOffice.get(
      uri.ordersUri + "/history/" + id,
      true,
      id
    );
    if (response.error) return response.error;
    const { data: ordersHistoryData } = response;
    ordersHistory.value = sortArrayByCriteria(ordersHistoryData, 0);
    return true;
  }

  async function actGetOrdersPending(id) {
    const response = await AxiosBeerOffice.get(
      uri.ordersUri + "/pending/" + id,
      true,
      id
    );
    if (response.error) return response.error;
    const { data: ordersPendingData } = response;
    ordersPending.value = sortArrayByCriteria(ordersPendingData, 0);
    return true;
  }

  async function actPostOrderCancel(id) {
    const response = await AxiosBeerOffice.post(
      uri.ordersUri + "/cancel/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    orderCancel.value = response;
    return true;
  }

  async function actGetOrderPdf(id) {
    const response = await AxiosBeerOffice.get(
      uri.ordersUri + "/generetePdf/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    return response.data;
  }

  async function actValidateBirthday() {
    const response = await AxiosBeerOffice.get(uri.birthdayGiftUri, true, {});
    if (response.error) return response.error;
    const { data: birthdayGiftData } = response;
    birthdayGiftStatus.value = birthdayGiftData;
    return true;
  }

  async function actChangeOrderStateView(id) {
    const response = await AxiosBeerOffice.put(
      uri.ordersUri + "/changeStateView/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    return true;
  }

  async function actPostOrderReview(data) {
    const response = await AxiosBeerOffice.post(
      uri.ordersUri + "/" + data.id + "/review",
      true,
      {
        review: data.review,
        score: data.score,
      }
    );
    if (response.error) return response.error;
    return response;
  }

  async function actGetTaDaStatus() {
    const response = await AxiosBeerOffice.get(uri.tadaUri, true, {});
    if (response.error) return response.error;
    taDaDisplayable.value = response.displayable;
    taDaAvailable.value = response.available;
    return response;
  }

  async function actPostTaDaRequest() {
    const response = await AxiosBeerOffice.post(uri.tadaUri, true, {});
    if (response.error) return response.error;
    return response;
  }

  function addProductToShoppingCart(product) {
    if (product.ecomm_product_type_id === 2) {
      product.ecomm_brand = {
        name: "Producto Sorpresa",
      };
      gift.value = product;
      console.log(gift.value);
    } else {
      const { id } = product;
      const cartItem = shoppingCart.value[id];

      if (cartItem) {
        cartItem.qty = cartItem.qty + 1;
      } else {
        product.qty = 1;
      }

      shoppingCart.value[id] = product;
      shoppingCartQty.value++;
      shoppingCartArray.value = Object.values(shoppingCart.value);
    }
  }

  function subsProductToShoppingCart(product) {
    if (product.ecomm_product_type_id === 2) {
      gift.value = null;
    } else {
      const { id } = product;
      const cartItem = shoppingCart.value[id];

      if (cartItem) {
        cartItem.qty = cartItem.qty - 1;
        if (cartItem.qty <= 0) {
          delete shoppingCart.value[id];
        }
      }

      shoppingCartQty.value--;
      shoppingCartArray.value = Object.values(shoppingCart.value);
    }
  }

  function delProductToShoppingCart(product) {
    if (product.ecomm_product_type_id === 2) {
      gift.value = null;
    } else {
      const { id } = product;
      delete shoppingCart.value[id];
    }
    shoppingCartArray.value = Object.values(shoppingCart.value);
  }

  function emptyShoppingCart() {
    shoppingCartArray.value = [];
    shoppingCart.value = {};
    shoppingCartQty.value = 0;
    gift.value = null;
  }

  return {
    birthdayGiftStatus,
    ordersPending,
    ordersHistory,
    orderCancel,
    products,
    product,
    locations,
    shoppingCart,
    shoppingCartArray,
    shoppingCartQty,
    gift,
    giftErrorMsj,
    taDaDisplayable,
    taDaAvailable,
    actValidateBirthday,
    actGetOrdersPending,
    actChangeOrderStateView,
    actGetOrderPdf,
    actGetProducts,
    actGetProductById,
    actGetLocations,
    actPostShoppingProducts,
    actGetOrdersHistory,
    actPostOrderCancel,
    actPostOrderReview,
    actGetTaDaStatus,
    actPostTaDaRequest,
    addProductToShoppingCart,
    subsProductToShoppingCart,
    delProductToShoppingCart,
    emptyShoppingCart,
  };
});
