<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update", "deleteInput"]);

const value = ref(props.modelValue);

const typingTimeout = ref(null);
const debounceTime = 600; // 600ms

const updateValue = (newValue) => {
  clearTimeout(typingTimeout.value);
  value.value = newValue;
  typingTimeout.value = setTimeout(() => {
    emit("update", newValue);
  }, debounceTime);
};

const deleteInput = () => {
  emit("deleteInput");
  value.value = "";
};

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue;
  }
);
</script>

<template>
  <div class="tw-relative">
    <i
      @click="deleteInput"
      class="tw-cursor-pointer icon-cerrar-simple tw-absolute tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-right-3 tw-text-red-350"
    />
    <input
      v-model="value"
      @input="updateValue($event.target.value)"
      class="text-input"
    />
  </div>
</template>

<style scoped lang="postcss">
.text-input {
  @apply tw-text-xs lg:tw-text-sm tw-bg-white tw-text-black tw-border tw-border-gray-500 tw-rounded-xl lg:tw-rounded-3xl tw-font-robotoLight tw-pl-2 tw-pr-8 tw-w-full;

  &:disabled {
    @apply tw-bg-gray-200 tw-cursor-not-allowed;
  }

  &:focus {
    @apply tw-outline-none tw-border-gray-500 tw-ring-0;
  }
}
</style>
