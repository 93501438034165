<script setup>
import { onBeforeMount, onMounted, ref } from "vue";
import config from "@/services/config";
import { useRatingStore } from "@/stores/ratingStore";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import CompButtonShoppingCart from "@/components/ecommerce/CompButtonShoppingCart";

const { actGetRating } = useRatingStore();
const { addProductToShoppingCart } = useEcommerceStore();
const uriResources = config.beerOfficeURL;
const ratingData = ref({});
const isLoading = ref(false);

const props = defineProps({
  products: {
    type: Array,
    required: true,
  },
  cat: {
    type: Number,
    default: 1,
  },
});

const getStars = () => {
  props.products.map((product) => {
    ratingData.value[product.id] === undefined
      ? (product.stars = 0 + "%")
      : (product.stars = (ratingData.value[product.id].score / 5) * 100 + "%");
  });
};

const next = () => {
  document.querySelector(".catalog-products").scrollLeft += 160;
};

const previous = () => {
  document.querySelector(".catalog-products").scrollLeft -= 160;
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetRating("Ecommerce").then((data) => {
    ratingData.value = data;
  });
  getStars();
  isLoading.value = false;
});

onMounted(() => {
  document.querySelector(".catalog-products").scrollLeft = 10;
});
</script>
<template>
  <div class="mt-4 mt-md-0">
    <div class="d-flex justify-content-between">
      <p
        class="tw-font-robotoBold text-uppercase mb-0 tw-text-lg d-block d-md-none"
        v-if="cat === 1"
      >
        NUESTRO CATÁLOGO
      </p>
      <p
        class="tw-font-robotoBold text-uppercase mb-0 tw-text-lg d-none d-md-block"
        v-if="cat === 1"
      >
        CONOCE NUESTRO CATÁLOGO
      </p>
      <p
        class="tw-font-robotoBold text-uppercase mb-0 tw-text-lg d-block"
        v-if="cat === 2"
      >
        TE PODRÍA INTERESAR
      </p>
      <router-link
        :to="'/e-commerce/view-all/' + 0"
        class="mb-0 tw-text-sm font-weight-bold tw-underline tw-text-gray-600 hover:tw-text-gray-600"
      >
        <p class="d-block d-md-none">Ver más</p>
        <p class="d-none d-md-block">Ver todos</p>
      </router-link>
    </div>
    <div class="mt-4 px-3 px-lg-4 pb-3">
      <div
        class="d-flex tw-overflow-x-scroll invisible-scrollbar catalog-products"
        v-if="!isLoading"
      >
        <div
          class="wi-130 wi-md-140 mx-3"
          v-for="product in products"
          :key="product.id"
        >
          <div
            class="wi-130 wi-md-140 min-he-130 tw-shadow tw-rounded-lg bg-loading mt-2"
          >
            <router-link :to="'/e-commerce/product/' + product.id">
              <img
                :src="uriResources + '/' + product.img"
                loading="lazy"
                width="100%"
                alt="img 140"
                class="tw-rounded-lg"
              />
            </router-link>
          </div>
          <p
            class="tw-font-roboto tw-text-xxs line-height-12 text-center tw-text-black mt-3 mb-0 he-25"
          >
            {{
              product.name.charAt(0).toUpperCase() +
              product.name.slice(1).toLowerCase()
            }}
          </p>
          <div class="d-flex justify-content-center mb-1">
            <div class="stars-outer">
              <div class="stars-inner" :style="'width:' + product.stars"></div>
            </div>
          </div>
          <p class="text-center font-weight-bold tw-text-sm mb-3">
            {{
              Intl.NumberFormat("es-CO", {
                currency: "COP",
                maximumFractionDigits: 0,
              }).format(product.price_public)
            }}
          </p>
          <div
            class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200"
            @click="addProductToShoppingCart(product)"
            role="button"
          >
            <p class="mb-0 text-center">Agregar</p>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between w-100 position-absolute ml-n4 ml-md-n5 mt-n3 pl-0 pl-md-2 pr-3 pr-md-2 tw-top-2/4"
      >
        <div
          role="button"
          class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center"
          @click="previous()"
        >
          <i class="icon-flecha-izq fs-30 tw-text-red-400"></i>
        </div>
        <div
          role="button"
          class="bg-white wi-30 he-30 tw-rounded-full tw-shadow-md d-flex justify-content-center align-items-center"
          @click="next()"
        >
          <i class="icon-flecha-der fs-30 tw-text-red-400"></i>
        </div>
      </div>
    </div>
    <CompButtonShoppingCart />
  </div>
</template>

<style scoped>
.stars-outer {
  position: relative;
  display: inline-block;
}

.stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.stars-outer::before {
  content: "\e979  \e979  \e979  \e979  \e979";
  font-family: "icomoon";
  -webkit-text-stroke: 1px #ffc141;
  color: #fff;
  font-size: 12px;
}

.stars-inner::before {
  content: "\e979  \e979  \e979  \e979  \e979";
  font-family: "icomoon";
  -webkit-text-stroke: 1px #ffc141;
  color: #ffc141;
  font-size: 12px;
}
</style>
