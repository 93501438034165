<script setup>
const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>
<template>
  <div
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center"
  >
    <p
      class="tw-font-robotoBold tw-text-xl lg:tw-text-3xl tw-text-red-350 tw-mb-2"
    >
      Gracias Embajador
    </p>
    <img
      src="@/assets/img/articles/articles-thanks.svg"
      class="tw-w-5/12"
      alt=""
    />
    <p class="tw-w-4/5 tw-mt-2 tw-font-roboto tw-text-xs lg:tw-text-base">
      <strong>Gracias por compartir tus ideas con nosotros,</strong> y ayudarnos
      a crecer, nuestro equipo revisará la información para poder ser publicada.
    </p>
    <div class="tw-btn-primary-red tw-p-3 tw-cursor-pointer" @click="close()">
      <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-lg">Finalizar</p>
    </div>
  </div>
</template>
