<script setup>
import config from "@/services/config";
import { useRouter } from "vue-router/composables";
import { isPastDate, isStillTime } from "@/helpers/datesHelpers";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const uriResources = config.beerOfficeURL;
const router = useRouter();
const props = defineProps({
  contest: {
    type: Object,
    required: true,
  },
});

const goToContest = () => {
  router.push(`/contests/${props.contest.id}`);
  // if (props.contest?.comp_competition_type_id === 1) {
  //   router.push(`/contests/${props.contest.id}`);
  // } else if (
  //   props.contest?.comp_competition_type_id === 2 ||
  //   props.contest?.comp_competition_type_id === 3
  // ) {
  //   router.push(`/competition/competition/${props.contest.id}`);
  // }
};
</script>

<template>
  <div
    class="tw-rounded-2.5xl tw-border tw-border-gray-400 tw-p-3 lg:tw-px-4 lg:tw-py-3 tw-grid tw-grid-rows-rows_3_fit_first_and_last tw-gap-2 tw-h-full"
  >
    <div class="tw-flex tw-justify-between">
      <p class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-base">
        {{ props.contest.name }}
      </p>
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm tw-text-red-350"
        v-if="isStillTime(props.contest.end)"
      >
        Activo
      </p>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-3 lg:tw-gap-4">
      <div>
        <img
          :src="`${uriResources}/${props.contest.icon}`"
          alt=""
          class="tw-rounded-2xl lg:tw-rounded-2.5xl tw-h-full tw-w-full tw-object-cover tw-max-h-[150px]"
        />
      </div>
      <div>
        <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
          {{ props.contest.short_desc }}
        </p>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-3 lg:tw-gap-4">
      <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-evenly tw-gap-2">
        <div class="tw-flex tw-justify-evenly tw-gap-8">
          <p
            class="tw-font-roboto tw-text-xxs lg:tw-text-xs tw-text-nowrap tw-m-0 tw-self-center"
          >
            <span class="tw-font-robotoBold">Inicio: </span>
            {{ props.contest.start ? props.contest.start : "No hay fecha" }}
          </p>
          <p
            class="tw-font-roboto tw-text-xxs lg:tw-text-xs tw-text-nowrap tw-m-0 tw-self-center"
          >
            <span class="tw-font-robotoBold">Fin: </span
            >{{ props.contest.end ? props.contest.end : "No hay fecha" }}
          </p>
        </div>
      </div>

      <comp-button-primary-red
        :title="
          isPastDate(props.contest.end)
            ? 'Concurso finalizado'
            : 'Acceder al concurso'
        "
        class="tw-w-full tw-h-full"
        @click.native="isPastDate(props.contest.end) ? () => {} : goToContest()"
      />
    </div>
  </div>
</template>

<style scoped></style>
