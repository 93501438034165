import { render, staticRenderFns } from "./Capabilities.vue?vue&type=template&id=76bb4496&scoped=true"
import script from "./Capabilities.vue?vue&type=script&lang=js"
export * from "./Capabilities.vue?vue&type=script&lang=js"
import style0 from "./Capabilities.vue?vue&type=style&index=0&id=76bb4496&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76bb4496",
  null
  
)

export default component.exports