<template>
  <div class="tabs-ranking">
    <b-tabs
      active-nav-item-class="tw-text-white"
      active-tab-class="tw-text-gray-600"
      content-class="mt-n0"
      fill
    >
      <b-tab
        v-for="category in rankCategories"
        :key="'ranking-category-' + category.id"
        :title="category.name"
        @click="
          onChangeCategorySelected(
            category.id,
            category.rank_people_category_type_id,
            category.color
          )
        "
      >
        <div
          class="px-3 pt-3 pb-5"
          :style="'background-color:' + category.color + ';'"
        >
          <div class="d-flex align-items-center justify-content-between">
            <img
              :src="uriResources + '/' + category.icon"
              alt="flag"
              width="26"
              height="26"
              class="tw-rounded-full tw-object-cover"
            />
            <p class="tw-text-white tw-text-xl font-weight-bold mx-2 mb-0">
              {{ category.name }}
            </p>
            <img
              :src="uriResources + '/' + category.rank_people.logo"
              width="90"
              height="50"
              alt="img 1"
              class="tw-object-cover"
            />
          </div>
        </div>
        <div
          class="tw-bg-white px-2 pt-4 pb-3 mt-n4 tw-rounded-xl tw-shadow-md he-350 overflow-auto"
        >
          <div
            role="button"
            v-for="user in rankUsers"
            :key="'ranking-user-' + user.id"
            class="d-flex p-3 tw-rounded-lg align-items-center mb-2"
            :class="
              user.business_partner.selected
                ? 'tw-bg-green-300'
                : 'tw-bg-gray-200'
            "
            @click="changeUserInfo(user)"
          >
            <img
              v-if="user.position <= 3"
              :src="imgMedal(user.position)"
              width="24"
              height="34"
              alt="medalla"
              class="mr-2"
            />
            <div v-else style="width: 24px" class="mr-3">
              <p class="mb-0 font-weight-bold tw-text-gray-300">
                {{ user.position }}
              </p>
            </div>
            <img
              :src="
                user.business_partner.prof_picture
                  ? uriResources + '/' + user.business_partner.prof_picture
                  : imgAvatar
              "
              width="40"
              height="40"
              class="tw-rounded-full"
              :class="
                user.position === 1
                  ? 'tw-border-4 tw-border-solid tw-border-yellow-400 '
                  : user.position === 2
                  ? 'tw-border-4 tw-border-solid tw-border-gray-400 '
                  : user.position === 3
                  ? 'tw-border-4 tw-border-solid tw-border-brown-400 '
                  : ''
              "
              alt="user"
            />
            <div class="mx-2 w-100">
              <p class="mb-1 tw-text-gray-400 tw-text-xs font-weight-bold">
                {{ user.business_partner.name }}
              </p>
              <p class="mb-0 tw-text-gray-400 tw-text-xxs">{{ user.name }}</p>
            </div>
            <i class="icon-flecha-der tw-text-gray-400 tw-text-xl"></i>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import config from "@/services/config";

export default {
  name: "TabsPeopleRanking",
  computed: {
    ...mapState("modRankingPeople", [
      "rankCategories",
      "rankUsers",
      "switchStage",
    ]),
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgAvatar: require("@/assets/img/contacts/avatar-empty.png"),
    };
  },
  mounted() {},
  methods: {
    ...mapActions("modRankingPeople", [
      "getRankPeopleByUser",
      "changeCategorySelected",
      "changeRankCategoryType",
    ]),
    ...mapMutations("modRankingPeople", [
      "setUserSelected",
      "setDataUserRankSelected",
      "setMonthSelected",
      "setRankStageSelected",
    ]),
    imgMedal(position) {
      let img = "";
      switch (position) {
        case 1:
          img = require("@/assets/img/ranking/gold.png");
          break;
        case 2:
          img = require("@/assets/img/ranking/silver.png");
          break;
        case 3:
          img = require("@/assets/img/ranking/bronze.png");
          break;

        default:
          img = null;
          break;
      }
      return img;
    },
    changeUserInfo(user) {
      if (this.switchStage === 1) {
        this.getRankPeopleByUser(user.information);
      } else {
        this.setDataUserRankSelected([user]);
      }
      this.setUserSelected(user);
    },
    onChangeCategorySelected(categoryId, typeId) {
      this.changeCategorySelected(categoryId);
      this.changeRankCategoryType(typeId).then(() => {
        this.changeMonth();
      });
    },
    changeMonth() {
      let targetId = document.querySelector(".select-ranking-type").value;
      this.setMonthSelected(targetId);
      this.setRankStageSelected(targetId);
    },
  },
};
</script>

<style scoped>
.tabs-ranking .nav-tabs li:first-child a {
  border-radius: 10px 0 0 0;
}
.tabs-ranking .nav-tabs li:last-child a {
  border-radius: 0 10px 0 0;
}

.tabs-ranking .nav-tabs .nav-link {
  font-size: 10px;
  background-color: #a0a0a0;
  border-bottom: solid 4px #fff;
  color: #fff;
}

.tabs-ranking .nav-tabs .nav-link.active,
.tabs-ranking .nav-tabs .nav-item.show .nav-link {
  background-color: #818181;
  color: #fff;
  font-size: 10px;
  border-bottom: solid 4px #fff;
}

.tabs-ranking .nav {
  flex-wrap: nowrap;
}
</style>
