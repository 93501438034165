<script setup>
import { onBeforeMount, ref } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import { storeToRefs } from "pinia";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import CompDiversityChampionsArticlesSlider from "@/components/diversity/CompDiversityChampionsArticlesSlider.vue";
import CompDiversityChampionsOtherArticles from "@/components/diversity/CompDiversityChampionsOtherArticles.vue";

const articlesStore = useArticlesStore();
const isLoading = ref(false);
const { actGetArticlesByCategory } = articlesStore;
const { getArticlesFeatured } = storeToRefs(articlesStore);

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetArticlesByCategory("champions");
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Champions" />
    <title-bar title="Champions" />
    <main class="tw-container-general">
      <comp-diversity-champions-articles-slider
        :items="getArticlesFeatured"
        :loaded-items="!isLoading"
      />
      <section
        id="banner_description"
        class="tw-my-5 tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4"
      >
        <img
          src="@/assets/img/dummy/ChampionsImg.png"
          class="tw-rounded-2xl tw-w-full tw-h-full tw-object-cover"
          alt=""
        />
        <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          consectetur euismod lacus. Sed commodo gravida magna. Sed ornare
          pretium risus, sit amet finibus odio auctor a. Aliquam tempus vitae
          ipsum sit amet eleifend. Pellentesque efficitur arcu id ligula
          facilisis eleifend. In hac habitasse platea dictumst. Donec aliquet
          vel dolor vehicula sodales.
        </p>
      </section>
      <section id="other_articles">
        <comp-diversity-champions-other-articles :is-loading="isLoading" />
      </section>
    </main>
  </div>
</template>

<style scoped></style>
