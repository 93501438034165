import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  searchV2Uri: "/contentSearchV2",
};

export const useSearchStore = defineStore("store", () => {
  const showSearch = ref(false);
  const searchResult = ref([]);

  async function actGetSearchResults(query) {
    const response = await AxiosBeerOffice.get(
      uri.searchV2Uri + "/" + query,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    searchResult.value = data;
    return true;
  }

  function resetSearchResult() {
    searchResult.value = [];
  }

  function toggleStateSearch() {
    showSearch.value = !showSearch.value;
  }

  function hideSearch() {
    showSearch.value = false;
  }

  // function mutShowSearch() {
  //   showSearch.value = true;
  // }

  return {
    showSearch,
    searchResult,
    actGetSearchResults,
    resetSearchResult,
    toggleStateSearch,
    hideSearch,
    // mutShowSearch,
  };
});
