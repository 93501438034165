import { render, staticRenderFns } from "./CompHomeBeatSplash.vue?vue&type=template&id=f5ed4430&scoped=true"
import script from "./CompHomeBeatSplash.vue?vue&type=script&setup=true&lang=js"
export * from "./CompHomeBeatSplash.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CompHomeBeatSplash.vue?vue&type=style&index=0&id=f5ed4430&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5ed4430",
  null
  
)

export default component.exports