const cvMixin = {
  props: {
    menuNavigationItemActive: {
      type: String,
      default: "Nuestras Plataformas",
    },
    buttonsMenuItemActive: {
      type: Number,
      default: 0,
    },
    peopleCycleButtonsActive: {
      type: Number,
      default: 1,
    },
    OPRButtonsActive: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      buttonsMenuItems: [
        {
          id: 0,
          name: "Movimientos",
          link: "/ambassadors/people/movements",
          active: false,
        },
        {
          id: 1,
          name: "Oportunidades",
          link: "/ambassadors/people/opportunity/all",
          active: false,
        },
        {
          id: 2,
          name: "Mi hoja de vida",
          link: "/ambassadors/people/cv",
          active: false,
        },
        {
          id: 3,
          name: "Mis solicitudes",
          link: "/ambassadors/people/request/all",
          active: false,
        },
      ],
      peopleCycleButtons: [
        {
          id: 1,
          name: "¿Qué es People Cycle?",
          link: "/ambassadors/PeopleCycle",
          active: false,
        },
        {
          id: 2,
          name: "Target & Bonus",
          link: "/ambassadors/peopleCycle/TargetBonus",
          active: false,
        },
        {
          id: 4,
          name: "Capabilities",
          link: "/ambassadors/peopleCycle/Capabilities",
          active: false,
        },
        {
          id: 5,
          name: "PDP",
          link: "/ambassadors/peopleCycle/PDP",
          active: false,
        },
        {
          id: 6,
          name: "OPR",
          link: "/ambassadors/peopleCycle/OPR",
          active: false,
        },
        {
          id: 7,
          name: "Actualización de Perfil",
          link: "/ambassadors/peopleCycle/UpdateProfile",
          active: false,
        },
      ],
      OPRButtons: [
        {
          id: 2,
          name: "Talent Card",
          active: false,
        },
        {
          id: 3,
          name: "PRE OPR",
          active: false,
        },
        {
          id: 7,
          name: "Zona OPR",
          active: false,
        },
        {
          id: 5,
          name: "Global OPR",
          active: false,
        },
        {
          id: 6,
          name: "STL OPR",
          active: false,
        },
      ],
    };
  },
  created() {
    this.selectButtonsMenuItemActive();
    this.selectPeopleCycleButtonsActive();
    this.selectOPRButtonsActive();
  },
  methods: {
    selectButtonsMenuItemActive() {
      this.buttonsMenuItems[this.buttonsMenuItemActive].active = true;
    },
    selectPeopleCycleButtonsActive() {
      this.peopleCycleButtons[this.peopleCycleButtonsActive].active = true;
    },
    selectOPRButtonsActive() {
      this.OPRButtons[this.OPRButtonsActive].active = true;
    },
  },
};

export default cvMixin;
