import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    contacts: {
      businessPartners: [],
      wellnessContacts: [],
      favorites: [],
    },
    contactUsers: [],
  },
  mutations: {
    setContacts: (state, contacts) => {
      state.contacts = contacts;
    },
    setContactUsers: (state, contactUsers) => {
      state.contactUsers = contactUsers;
    },
    setAddContactsFavorites: (state, contactFavorites) => {
      state.contacts.favorites.push({
        core_employee: contactFavorites,
      });
    },
    deleteContactsFavorites: (state, deletecontactFavorites) => {
      state.contacts.favorites = state.contacts.favorites.filter((contact) => {
        return contact.id !== deletecontactFavorites.id;
      });
    },
  },
  actions: {
    actGetContacts: async ({ commit }) => {
      let response = await ApiBeerOffice.getContacts();
      if (response.error) {
        return response.error;
      }
      commit("setContacts", response.data);
      return true;
    },

    actFindUsers: async ({ commit }, data) => {
      let response = await ApiBeerOffice.findContactUsers(data);
      if (response.error) {
        return response.error;
      }
      commit("setContactUsers", response.data);
      return true;
    },

    actAddcontact: async ({ commit }, data) => {
      let response = await ApiBeerOffice.addContactToUser(data.id);
      if (response.error) {
        return response.error;
      }

      commit("setAddContactsFavorites", data);
      return true;
    },

    actDeletecontact: async ({ commit }, data) => {
      let response = await ApiBeerOffice.deleteContactToUser(data.id);
      if (response.error) {
        return response.error;
      }

      commit("deleteContactsFavorites", data);
      return true;
    },

    actCleanContactUsers({ commit }) {
      commit("setContactUsers", []);
    },
  },
};
