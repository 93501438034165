<script setup>
import { useArticlesStore } from "@/stores/articlesStore";
import SkeletonCardNews from "@/components/news/SkeletonCardNews.vue";
import { getCurrentInstance, ref } from "vue";
import { storeToRefs } from "pinia";
import { sortArrayByCriteria } from "@/helpers/arrayHelpers";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";
import AtomKnowMore from "@/components/atoms/AtomKnowMore.vue";
import MoleculeCardTitleAndDescription from "@/components/molecules/MoleculeCardTitleAndDescription.vue";
import AtomImageResponsive from "@/components/atoms/AtomImageResponsive.vue";
import AtomCategoryAndBadge from "@/components/molecules/MoleculeCategoryAndBadge.vue";
import MoleculeCardResponsive from "@/components/molecules/MoleculeCardResponsive.vue";
import CompGeneralOrderFilter from "@/components/general/CompGeneralOrderFilter.vue";

const articlesStore = useArticlesStore();
const { getArticlesGeneral } = storeToRefs(articlesStore);
const { $isMobile: mobile } = getCurrentInstance().proxy;
const per_page = ref(8);
const filter = ref(0);

const changeFilter = (newFilter) => {
  filter.value = newFilter.id;
};

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <section id="diversity_articles" class="tw-mt-5">
    <div
      class="tw-flex tw-justify-between tw-items-start tw-mt-10 tw-mb-5 tw-h-auto tw-max-h-10"
    >
      <div class="tw-flex tw-gap-x-6 tw-items-center tw-justify-start">
        <p
          class="tw-mb-0 tw-text-black tw-text-base md:tw-text-3xl tw-font-robotoBold"
        >
          Conoce más sobre tus champions
        </p>
      </div>
      <comp-general-order-filter
        category-for-datalayer="Champions"
        not-views
        @changeFilter="changeFilter"
      />
    </div>
    <div
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8"
      v-if="props.isLoading"
    >
      <div v-for="_n in per_page" :key="_n">
        <skeleton-card-news />
        <hr class="md:tw-hidden" />
      </div>
    </div>
    <div
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4 md:tw-gap-6 lg:tw-gap-8"
      v-else
    >
      <div
        v-for="(article, index) in sortArrayByCriteria(
          getArticlesGeneral,
          filter
        )"
        :key="index"
      >
        <molecule-card-responsive>
          <template #image>
            <atom-image-responsive
              alt=""
              :src="
                mobile() && article?.thumbnail
                  ? article?.thumbnail
                  : article?.resource
              "
            />
          </template>
          <template #category>
            <atom-category-and-badge
              :category="article?.article_category?.name"
            />
          </template>
          <template #titleAndDescription>
            <molecule-card-title-and-description
              :description="article?.description"
              :title="article?.title"
            />
          </template>
          <template #callToAction="{ hover }">
            <div
              class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 lg:tw-justify-between"
            >
              <comp-social-network-interactions
                category="NEW_ARTICLES"
                :user_reaction="article?.user_reaction"
                :amount_comments="Number(article?.amount_comments)"
                :amount_reactions="Number(article?.amount_reactions)"
                :image="
                  mobile() && article?.thumbnail
                    ? article?.thumbnail
                    : article?.resource
                "
                :description="article?.description"
                :title="article?.title"
                :id="article?.id"
                :item="article"
                :hover="hover"
                category-for-datalayer="Home"
                action-for-datalayer="Otras noticias"
              />
              <atom-know-more
                :datalayer-data="{}"
                :link="`/article/${article?.id}`"
              />
            </div>
          </template>
        </molecule-card-responsive>
        <hr class="md:tw-hidden" />
      </div>
    </div>
  </section>
</template>

<style scoped></style>
