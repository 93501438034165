<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router/composables";
import { useUserStore } from "@/stores/userStore";
import { useAuthStore } from "@/stores/authStore";

const router = useRouter();
const userStore = useUserStore();
const authStore = useAuthStore();
const password = ref("");
const error = ref({
  flag: false,
  class: "", //is-invalid
  message: "",
});
const activeData = ref({
  cc: 0,
  password: "",
  setPassword: 0,
});

const goToLogin = () => {
  router.push({ path: "/login" });
};

const toggleInputPassword = (event) => {
  let inputText = event.target.parentElement.querySelector("input");
  let spanIcon = event.target.parentElement.querySelector("span");
  if (inputText.getAttribute("type") === "password") {
    inputText.setAttribute("type", "text");
    spanIcon.classList.remove("icon-visibilidad-sin-relleno");
    spanIcon.classList.add("icon-visibilidad-relleno");
  } else {
    inputText.setAttribute("type", "password");
    spanIcon.classList.remove("icon-visibilidad-relleno");
    spanIcon.classList.add("icon-visibilidad-sin-relleno");
  }
};

const activate = async () => {
  error.value.flag = false;
  error.value.class = "";
  if (activeData.value.password !== password.value) {
    error.value.flag = true;
    error.value.class = "is-invalid";
    error.value.message = "Debe escribir la contraseña igual en los dos campos";
    return;
  }
  activeData.value.setPassword = authStore.setPassword;
  activeData.value.cc = userStore.user.cc;
  let response = await authStore.actAuthActivate(activeData.value);
  if (response !== true) {
    error.value.flag = true;
    error.value.class = "is-invalid";
    error.value.message = response.message;
    return;
  }
  await router.push({ path: "/" });
};

onMounted(() => {
  error.value.flag = false;
  error.value.class = "";
  password.value = "";
  activeData.value.password = "";
});
</script>
<template>
  <div class="pt-2 px-2 px-md-4">
    <h2 class="text-center tw-text-3xl md:tw-text-4xl tw-font-tolyerBold">
      CREA TU CONTRASEÑA
    </h2>
    <p class="text-left">
      Recuerda que tu contraseña debe tener mínimo 8 caracteres, al menos una
      mayúscula, al menos un número y un carácter especial.
    </p>
    <form @submit.prevent="activate()">
      <div class="text-left pt-4">
        <p v-show="error.flag" class="alert-danger">{{ error.message }}</p>
        <div class="form-group position-relative">
          <input
            type="password"
            v-model="activeData.password"
            :class="'form-control form-control-lg ' + error.class"
            placeholder="Contraseña"
            required
          />
          <span
            @click="toggleInputPassword($event)"
            class="icon icon-visibilidad-sin-relleno position-absolute"
          ></span>
        </div>
        <div class="form-group position-relative">
          <input
            type="password"
            v-model="password"
            :class="'form-control form-control-lg ' + error.class"
            placeholder="Repetir contraseña"
            required
          />
          <span
            @click="toggleInputPassword($event)"
            class="icon icon-visibilidad-sin-relleno position-absolute"
          ></span>
        </div>
        <div class="text-center pt-4">
          <button
            type="submit"
            class="btn btn-primary rounded-pill px-5 background-primary mb-2"
          >
            Asignar Contraseña
          </button>
        </div>
      </div>
    </form>
    <div class="text-center">
      <button class="btn btn-link btn-login" @click="goToLogin()">
        Iniciar sesión
      </button>
    </div>
    <br />
    <br />
  </div>
</template>

<style scoped>
.btn-login {
  color: #d82b2b;
  font-size: 13px;
  text-decoration: underline;
  padding: 0;
}
.form-group .icon {
  margin-right: 10px;
  right: 0;
  top: calc(50% - 10px);
  color: #00000033;
  font-size: 20px;
}
</style>
