<script setup>
import CompFormTextInput from "@/components/form/CompFormTextInput.vue";
import {computed, onMounted} from "vue";

const props = defineProps({
  data: Object,
});

const emit = defineEmits(["update"]);

const dependentsDisabledNumber = computed(() => {
  return props.data.dependents_disabled_number || 0;
});

const dependentsSeniorNumber = computed(() => {
  return props.data.dependents_seniors_number || 0;
});

const dependentsJuniorNumber = computed(() => {
  return props.data.dependents_juniors_number || 0;
});

onMounted(() => {
  emit("update", {
    ...props.data,
    dependents_disabled_number: dependentsDisabledNumber.value,
    dependents_seniors_number: dependentsSeniorNumber.value,
    dependents_juniors_number: dependentsJuniorNumber.value,
  });
});
</script>

<template>
  <section
    class="tw-rounded-xl lg:tw-rounded-2xl tw-border tw-border-gray-350 tw-p-4 tw-flex tw-flex-col tw-gap-2"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        ¿Cuántas personas tienes a tu cargo?*
      </p>
      <p class="tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs">
        *En caso de que no aplique, pon 0 en la respuesta
      </p>
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-2">
      <comp-form-text-input
        class="tw-col-span-1"
        :model-value="dependentsDisabledNumber"
        @update="
          (value) =>
            emit('update', {
              ...props.data,
              dependents_disabled_number: Number(value),
            })
        "
        input-type="number"
      />
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <p class="tw-m-0 tw-font-roboto tw-text-sm">
          Personas con discapacidad
        </p>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-2">
      <comp-form-text-input
        class="tw-col-span-1"
        :model-value="dependentsSeniorNumber"
        @update="
          (value) =>
            emit('update', {
              ...props.data,
              dependents_seniors_number: Number(value),
            })
        "
        input-type="number"
      />
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <p class="tw-m-0 tw-font-roboto tw-text-sm">Personas mayores</p>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-2">
      <comp-form-text-input
        class="tw-col-span-1"
        :model-value="dependentsJuniorNumber"
        @update="
          (value) =>
            emit('update', {
              ...props.data,
              dependents_juniors_number: Number(value),
            })
        "
        input-type="number"
      />
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <p class="tw-m-0 tw-font-roboto tw-text-sm">Personas menores de edad</p>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
