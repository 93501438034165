import Vue from "vue";
import Vuex from "vuex";

import modCore from "./modCore/index";
import modDiversityInclusion from "./modDiversityInclusion/index";
import modBrands from "./modBrands/index";
import modGeneral from "./modGeneral/index";
import modCompetitions from "./modCompetitions/index";
import modNotifications from "./modNotifications/index";
import modVariableCompensation from "./modVariableCompensation/index";
import modContacts from "./modContacts/index";
import modCalendar from "./modCalendar/index";
import modGamification from "./modGamification/index";
import modRating from "./modRating";
import modDocuments from "./modDocuments";
import modContextualHelpers from "./modContextualHelpers";
import modPeople from "./modPeople";
import modCv from "./modCv";
import modIndicators from "./modIndicators";
import modVacants from "./modVacants";
import modPsi from "./modPsi";
import modPeopleCycle from "./modPeopleCycle";
import modRemuneration from "./modRemuneration";
import modRankingPeople from "./modRankingPeople";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    modCore,
    modDiversityInclusion,
    modBrands,
    modGeneral,
    modCompetitions,
    modVariableCompensation,
    modNotifications,
    modContacts,
    modCalendar,
    modGamification,
    modRating,
    modDocuments,
    modContextualHelpers,
    modPeople,
    modCv,
    modIndicators,
    modVacants,
    modPsi,
    modPeopleCycle,
    modRemuneration,
    modRankingPeople,
  },
});

export default store;
export const useStore = () => store;
