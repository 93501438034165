<script setup>
import { pushLink } from "@/helpers/linkHelpers";
import AtomText from "@/components/atoms/AtomText.vue";
import MoleculeCardImageButton from "@/components/molecules/MoleculeCardImageButton.vue";

const props = defineProps({
  articles: {
    type: Array,
    required: true,
  },
});

const openArticle = (article) => {
  pushLink(`/articles/${article.id}`);
};
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-5">
    <atom-text
      tag="h2"
      font="robotoBold"
      text-size-mobile="2xl"
      text-size-desktop="3xl"
      text-color="black"
    >
      Conoce los eventos que cada área tiene para ti
    </atom-text>
    <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-5">
      <molecule-card-image-button
        v-for="(article, idx) in props.articles"
        :key="idx"
        :item="article"
        @button-click="openArticle(article)"
      />
    </div>
  </div>
</template>
