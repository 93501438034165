<script setup>
import { onBeforeMount, ref, computed, onBeforeUnmount, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useNavbarStore } from "@/stores/navbarStore";
import { useRoute } from "vue-router/composables";

const navbarStore = useNavbarStore();
const route = useRoute();

const target = ref(null);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);

const emit = defineEmits(["close", "open", "toggle"]);

const props = defineProps({
  modalName: {
    type: String,
    required: true,
  },
  modalWidth: {
    type: Object,
    required: true,
  },
  maxHeight: {
    type: Object,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  bubble: {
    type: Boolean,
    default: false,
  },
  nrOfItems: {
    type: Number,
    default: 0,
  },
});

const widthContainer = computed(() => {
  if (windowWidth.value >= 1280) {
    if (props.modalWidth.xl) {
      return props.modalWidth.xl + "px;";
    } else {
      return props.modalWidth.lg + "px;";
    }
  } else if (windowWidth.value >= 1024) {
    return props.modalWidth.lg + "px;";
  } else if (windowWidth.value >= 768) {
    return props.modalWidth.md + "px;";
  } else {
    return "85vw;";
  }
});

const heightThresholds = [
  { threshold: 600, value: props.maxHeight.base },
  { threshold: 768, value: props.maxHeight.md },
  { threshold: 900, value: props.maxHeight.lg },
  { threshold: Infinity, value: props.maxHeight.xl },
];

const maxHeightContainer = computed(() => {
  const appropriateHeight = heightThresholds.find(({ threshold }) => windowHeight.value <= threshold);
  return appropriateHeight.value + "px;";
});

const toggleActive = () => {
  emit("toggle");
  navbarStore.toggleOrderModal();
  if (navbarStore.isOrderModalOpen) {
    emit("open");
  } else {
    emit("close");
  }
};

onClickOutside(target, (event) => {
  handleClose(event);
});

const handleClose = (event) => {
  if (!event.target.closest("#full-stars")) {
    navbarStore.hideOrderModal();
  }
};

onBeforeMount(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });

  window.addEventListener("resize", () => {
    windowHeight.value = window.innerHeight;
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });

  window.removeEventListener("resize", () => {
    windowHeight.value = window.innerHeight;
  });
});

watch(
  () => route,
  () => {
    handleClose();
  }
);
</script>
<template>
  <div
    id="modal-handle"
    class="tw-fixed tw-h-screen tw-top-0 tw-z-20 tw-overflow-auto"
    :class="navbarStore.isOrderModalOpen ? 'active' : ''"
  >
    <div
      :id="modalName"
      ref="target"
      :class="navbarStore.isOrderModalOpen ? 'active' : ''"
      :style="'width: ' + widthContainer + 'max-height: ' + maxHeightContainer"
      class="modal-container tw-mx-auto tw-fixed tw-top-24 lg:tw-top-32 tw-right-0 tw-flex tw-justify-end"
    >
      <div
        class="handle tw-absolute tw-top-7 tw-w-14 tw-h-14 md:tw-w-16 md:tw-h-16 lg:tw-w-24 lg:tw-h-24 tw--left-7 md:tw--left-8 lg:tw--left-12 tw-rounded-full tw-cursor-pointer tw-bg-pink-100 tw-shadow-md tw-flex tw-justify-start tw-items-center tw-z-1"
        @click="toggleActive"
      >
        <i
          :class="
            navbarStore.isOrderModalOpen
              ? 'icon-cerrar-simple tw-text-2xl lg:tw-text-4xl'
              : `${icon} tw-text-xl lg:tw-text-3xl`
          "
          class="tw-text-red-350 ml-2 tw-relative tw-flex tw-justify-center"
        >
          <span
            v-if="!navbarStore.isOrderModalOpen && bubble"
            class="tw-absolute tw--right-1 tw--top-1 tw-w-4 tw-h-4 tw-p-0.5 tw-bg-pink-100 tw-rounded-full tw-flex tw-justify-center tw-items-center"
          >
            <span
              class="tw-w-full tw-h-full tw-bg-red-350 tw-rounded-full tw-flex tw-items-center tw-justify-center"
            >
              <span class="tw-m-0 tw-text-white tw-font-robotoBold tw-text-xxs">
                {{ nrOfItems }}
              </span>
            </span>
          </span>
        </i>
      </div>
      <div
        :style="'max-height: ' + maxHeightContainer"
        class="inside tw-bg-white tw-h-auto tw-w-full tw-max-h-full tw-rounded-3xl tw-flex tw-flex-col tw-shadow-md tw-p-6 md:tw-p-7 lg:tw-p-9 tw-overflow-hidden tw-ease-in-out tw-z-2"
      >
        <slot class="tw-z-1 tw-h-full" />
      </div>
    </div>
  </div>
</template>

<style scoped>
#modal-handle {
  transition: z-index 0.5s step-end, opacity 0.5s linear;
}

#modal-handle.active {
  width: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  -ms-transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  transition: background-color 500ms, z-index 0.5s step-start,
    opacity 0.5s linear;
  z-index: 1000;
}

.modal-container {
  z-index: -1;
  transform: translateX(100%);
  transition: transform 0.5s linear;
}

.modal-container.active {
  transform: translateX(-5%);
}

@media (max-width: 768px) {
  .modal-container.active {
    transform: translateX(-2%);
  }
}
</style>
