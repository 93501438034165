<template>
  <div>
    <div
      v-b-toggle="'opportunity-collapse-' + opportunity.id"
      class="opportunity-card p-3 p-md-4 tw-bg-white tw-shadow-sm tw-rounded-md mb-3 d-flex position-relative z-index-2 hover:tw-shadow-md"
    >
      <img
        v-if="opportunity.core_vp"
        :src="uriResources + '/' + opportunity.core_vp.img_people_module"
        width="100%"
        height="auto"
        class="tw-object-cover tw-rounded-md mr-2 mr-md-3 wi-50 he-50 wi-md-100 he-md-100"
        alt="img 1"
      />
      <div class="w-100">
        <p
          class="tw-text-gray-600 text-uppercase tw-text-xs fs-md-20 font-weight-bold"
        >
          {{ opportunity.position_name }}
        </p>
        <div class="d-flex justify-content-between">
          <div class="mr-2 ml-0">
            <p class="tw-text-xxs fs-md-12 tw-text-gray-300 mb-1">Lugar</p>
            <p class="tw-text-xxs fs-md-14 tw-text-gray-400 mb-0">
              {{ opportunity.core_site.name }}
            </p>
          </div>
          <div class="mx-2">
            <p class="tw-text-xxs fs-md-12 tw-text-gray-300 mb-1">
              Fecha límite
            </p>
            <p class="tw-text-xxs fs-md-14 tw-text-gray-400 mb-0">
              {{ opportunity.end_date }}
            </p>
          </div>
          <div class="mx-2">
            <p class="tw-text-xxs fs-md-12 tw-text-gray-300 mb-1">Banda</p>
            <p class="tw-text-xxs fs-md-14 tw-text-gray-400 mb-0">
              {{ opportunity.core_pa_sideline.name }}
            </p>
          </div>
        </div>
        <div
          v-if="hvIsComplete === false"
          class="d-block d-md-none text-right mt-4"
        >
          <router-link
            to="/ambassadors/people/cv"
            class="tw-text-yellow-400 tw-text-xs font-weight bold tw-underline"
          >
            Completa tu HV
          </router-link>
          <p class="tw-text-yellow-400 tw-text-xxs font-italic mb-0">
            Aplica a las oportunidades que tenemos para ti.
          </p>
        </div>
        <div
          v-else-if="
            sideLinesApprove.indexOf(opportunity.core_pa_sideline.name) === -1
          "
          class="text-right d-block d-md-none text-right mt-4"
        >
          <p class="tw-text-red-400 tw-text-xs font-weight bold mb-2">
            No aplicas
          </p>
          <p class="tw-text-red-400 tw-text-xxs font-italic">
            Tu perfil no se ajusta a esta vacante.
          </p>
        </div>
        <div v-else-if="opportunity.state != null">
          <div class="d-block d-md-none text-right mt-4">
            <p class="tw-text-green-400 tw-text-xs font-weight bold mb-2">
              ¡Ya aplicaste!
            </p>
            <p class="tw-text-green-400 tw-text-xxs font-italic mb-0">
              Pronto se pondrán en contacto contigo.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block d-md-none">
      <b-collapse
        :id="'opportunity-collapse-' + opportunity.id"
        class="tw-bg-gray-200 pt-4 pb-3 px-3 mx-3 mb-3 position-relative z-index-1 mt-n4 tw-shadow-sm tw-rounded-md"
      >
        <div class="max-he-450 tw-overflow-y-scroll">
          <p
            class="tw-text-base tw-text-gray-600 text-uppercase font-weight-bold"
          >
            DESCRIPCIÓN DEL CARGO
          </p>
          <p class="tw-text-gray-600 tw-text-xs line-height-14">
            {{ opportunity.position_desc }}
          </p>
          <p
            class="tw-text-base tw-text-gray-600 tw-text-base text-uppercase font-weight-bold mt-4"
          >
            PERFIL
          </p>
          <p
            class="tw-text-gray-600 tw-text-xs line-height-14 font-weight-bold"
          >
            Educación:
          </p>
          <p class="tw-text-gray-600 tw-text-xs line-height-14">
            {{ opportunity.education }}
          </p>
          <p
            class="tw-text-gray-600 tw-text-xs line-height-14 font-weight-bold"
          >
            Habilidades:
          </p>
          <p class="tw-text-gray-600 tw-text-xs line-height-14">
            {{ opportunity.skills }}
          </p>
          <div v-if="opportunity.state == null">
            <div
              v-if="true"
              role="button"
              class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 text-center ml-1 px-4"
              @click="showModalOpportunityApply"
            >
              Aplicar
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "@/services/config";

import sidelines from "@/mixins/user/sidelines";

export default {
  name: "OpportunityComponent",
  mixins: [sidelines],
  props: {
    opportunity: {
      type: Object,
    },
    hvIsComplete: {
      required: true,
      default: false,
    },
  },
  data() {
    return {
      sideLinesApprove: [],
      uriResources: config.beerOfficeURL,
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  created() {
    this.sideLinesApprove = this.selectPrevSidelines(
      this.user.core_pa_sideline.name,
      2
    );
  },
  methods: {
    showModalOpportunityApply() {
      this.$bvModal.show("modal-opportunity-apply");
    },
  },
};
</script>
