<script setup>
import { computed } from "vue";
import config from "@/services/config";
import ArticleStats from "@/components/articles/CompArticlesStats.vue";
import imgAvatarEmpty from "@/assets/img/demo/avatar-empty.png";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
});

const userImage = computed(() => {
  if (props.article?.author_profile != null) {
    return uriResources + "/" + props.article?.author_profile;
  } else {
    return imgAvatarEmpty;
  }
});
</script>
<template>
  <div
    class="tw-grid tw-gap-2 md:tw-gap-y-3 md:tw-gap-x-8 tw-grid-cols-12 tw-auto-rows-min"
  >
    <div id="mobile_titles" class="md:tw-hidden tw-col-span-12">
      <p class="tw-font-robotoBold tw-text-3xl tw-m-0">
        {{ props.article?.title }}
      </p>
      <div
        class="tw-mt-3 tw-mb-2 tw-rounded-full tw-border tw-w-max tw-flex tw-justify-center tw-items-center tw-py-1 tw-px-2"
      >
        <p
          class="tw-font-roboto tw-text-xxs tw-leading-3 tw-m-0 tw-inline-block"
        >
          {{ props.article?.article_category?.name }}
        </p>
      </div>
    </div>
    <div id="image" class="tw-col-span-12 md:tw-col-span-7">
      <div
        class="tw-bg-no-repeat tw-bg-center tw-aspect-w-5 tw-aspect-h-4 md:tw-aspect-w-16 md:tw-aspect-h-7"
        :style="
          props.article?.resource == null
            ? {
                'background-image': `url(${require('@/assets/img/icons/loading.gif')})`,
              }
            : ''
        "
      >
        <img
          :src="uriResources + '/' + props.article?.resource"
          loading="lazy"
          alt="image"
          class="tw-rounded-2xl tw-w-full tw-h-full tw-object-contain tw-pointer-events-none"
        />
      </div>
    </div>
    <div id="description" class="md:tw-col-span-5 tw-hidden md:tw-block">
      <div class="tw-flex tw-flex-col tw-justify-end tw-h-full">
        <div>
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <p
              class="tw-font-roboto tw-text-xs md:tw-text-base tw-text-black tw-mb-0 tw-inline-block tw-underline"
            >
              {{ props.article?.article_category?.name }}
            </p>
          </div>
          <div class="">
            <p
              class="tw-mb-2 md:tw-mb-5 tw-font-robotoBold tw-text-base md:tw-text-4xl tw-line-clamp-2"
            >
              {{ props.article?.title }}
            </p>
            <p
              class="tw-mb-0 tw-text-xs md:tw-text-base tw-text-black tw-line-clamp-3"
            >
              {{ props.article?.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="stats-author" class="tw-col-span-12 md:tw-col-span-7">
      <div
        class="tw-grid tw-grid-flow-col tw-gap-1 tw-justify-between"
        style="min-width: 0"
      >
        <div id="author" class="tw-flex tw-items-center">
          <div
            class="tw-rounded-full tw-w-7 tw-h-7 md:tw-w-9 lg:tw-h-9 tw-mr-2"
          >
            <img
              :src="userImage"
              class="tw-object-cover tw-h-full tw-w-full tw-rounded-full"
              alt=""
            />
          </div>
          <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
            <strong class="tw-hidden md:tw-inline">Escrito por: </strong>
            {{ props.article?.author }}
          </p>
        </div>
        <div id="stats" class="tw-flex tw-items-center">
          <ArticleStats :article="article" />
        </div>
      </div>
    </div>
  </div>
</template>
