var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('img',{staticClass:"imgtr d-none d-md-block",attrs:{"src":require("../../assets/img/icons/iconRightResponse.png"),"alt":""}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 p-0 m-0"},[_c('div',{staticClass:"h-100 account-background text-center d-none d-md-block pt-4",style:({
          'background-image':
            'url(' +
            _setup.uriResources +
            '/' +
            _setup.profileStore.profileType.image +
            ')',
        })},[_c('h1',{staticClass:"w-100 tw-text-4xl md:tw-text-7xl tw-font-tolyerBold"},[_vm._v(" EMBAJADORES ")]),_vm._m(0)]),_c('div',{staticClass:"height35 account-background account-background-xs text-center d-xl-none d-md-none pt-4",style:({
          'background-image':
            'url(' +
            _setup.uriResources +
            '/' +
            _setup.profileStore.profileType.image +
            ')',
        })},[_c('h1',{staticClass:"w-100"},[_vm._v("EMBAJADORES")])])]),_c('div',{staticClass:"height100 col-md-6 p-0 pl-1 pr-1 d-flex justify-content-center profQuestion"},[_c('div',{staticClass:"height100 mt-md-2 mt-sm-4 align-items-center text-center h100 profContent"},[_c(_setup.CompResult,{staticClass:"content-sm mr-md-3 ml-md-3 d-none d-md-block d-none d-sm-block"}),_c(_setup.CompResult,{staticClass:"content-sm mr-md-3 ml-md-3 d-block d-sm-none text-left"})],1)])]),_c('img',{staticClass:"imgbr d-none d-md-block",attrs:{"src":require("../../assets/img/icons/onboardingBL.png"),"alt":""}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"account-logo"},[_c('img',{staticClass:"tw-w-2.5",attrs:{"src":require("@/assets/img/LogoBavariaBL.png"),"alt":""}})])
}]

export { render, staticRenderFns }