export function pushDataLayer(data, standardData = true) {
  const standardDataArr = {
    event: "GAEvent",
    interaction: "True",
  };

  const dataLayer = standardData
    ? Object.assign({}, standardDataArr, data)
    : data;

  window.dataLayer.push(dataLayer);
}
