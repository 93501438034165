<template id="pageCvLanguage">
  <div>
    <form @submit.prevent="sendForm" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-9">
          <p class="fs-24 text-uppercase tetx tw-font-tolyerBold tw-text-black">
            Idiomas
          </p>
        </div>
        <div class="col-3 text-right">
          <p
            @click="addLanguage"
            role="button"
            class="tw-text-red-400 tw-underline tw-text-sm"
          >
            Agregar
          </p>
        </div>
      </div>
      <div
        v-for="(language, index) in formData.languages.items"
        :key="index"
        class="row position-relative tw-z-1"
      >
        <span
          @click="removeLanguage(index)"
          role="button"
          class="tw-text-white tw-bg-red-400 wi-20 he-20 tw-rounded-full d-flex justify-content-center align-items-center position-absolute tw-right-5 z-index-2"
        >
          <i
            class="icon-cerrar-simple tw-text-white font-weight-bold tw-text-sm"
          ></i>
        </span>
        <div class="col-12 col-lg-6 mb-3">
          <label
            :for="`language-${index}`"
            class="tw-text-sm tw-text-gray-400 font-weight-bold"
          >
            Idioma*
          </label>
          <input
            type="text"
            :name="`formData.language.items[${index}][language]`"
            :id="`language-${index}`"
            placeholder="Escribe aquí"
            required="required"
            class="form-control w-100 tw-rounded-md tw-border tw-border-solid he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm focus:tw-border tw-border-gray-400"
            v-model="language.language"
          />
          <div class="valid-feedback"></div>
          <div class="invalid-feedback">Este campo es obligatorio.</div>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <label
            :for="`level-${index}`"
            class="tw-text-sm tw-text-gray-400 font-weight-bold"
          >
            Nivel*
          </label>
          <input
            type="text"
            :name="`formData.language.items[${index}][level]`"
            :id="`level-${index}`"
            placeholder="Escribe aquí"
            required="required"
            class="form-control w-100 tw-rounded-md tw-border he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400"
            v-model="language.level"
          />
          <div class="valid-feedback"></div>
          <div class="invalid-feedback">Este campo es obligatorio.</div>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <div class="row row-cols-2">
            <div class="col">
              <label
                :for="`start_date-${index}`"
                class="tw-text-sm tw-text-gray-400 font-weight-bold"
              >
                Fecha de inicio*
              </label>
              <input
                type="date"
                :id="`start_date-${index}`"
                :name="`formData.language.items[${index}][start_date]`"
                class="form-control tw-rounded-md tw-border he-40 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400 px-2"
                required="required"
                :max="getTodayYYYYmmdd"
                v-model="language.start_date"
              />
            </div>
            <div class="col">
              <label
                :for="`end_date-${index}`"
                class="tw-text-sm tw-text-gray-400 font-weight-bold"
              >
                Fecha de fin
              </label>
              <input
                type="date"
                :id="`end_date-${index}`"
                :name="`formData.language.items[${index}][end_date]`"
                class="form-control tw-rounded-md tw-border he-40 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400 px-2"
                required="required"
                :max="getTodayYYYYmmdd"
                :disabled="language.at_present === 1"
                v-model="language.end_date"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <label
            :for="`institution-${index}`"
            class="tw-text-sm tw-text-gray-400 font-weight-bold"
          >
            Institución*
          </label>
          <input
            type="text"
            :name="`formData.language.items[${index}][institution]`"
            :id="`institution-${index}`"
            placeholder="Escribe aquí"
            required="required"
            class="form-control w-100 tw-rounded-md tw-border he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400"
            v-model="language.institution"
          />
          <div class="valid-feedback"></div>
          <div class="invalid-feedback">Este campo es obligatorio.</div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-checkbox mb-2">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`at_present-${index}`"
              :name="`formData.language.items[${index}][at_present]`"
              v-model="language.at_present"
              :checked="language.at_present === 1"
              @click="toggleActivateEndDate(index, $event)"
            />
            <label class="custom-control-label" :for="`at_present-${index}`">
              <span class="tw-text-xs tw-text-gray-300">Actualmente</span>
            </label>
          </div>
        </div>
        <div class="col-12">
          <div class="w-100 he-1 tw-bg-gray-300 my-3"></div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-md-12 col-xl-6 ml-auto">
          <div class="d-flex">
            <span
              @click="backStep"
              role="button"
              class="mx-2 tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block w-100 text-center"
            >
              Volver
            </span>
            <button
              class="mx-2 tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block w-100 text-center"
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </form>
    <b-modal id="modal-cv-finish" hide-header hide-footer centered>
      <div class="p-4">
        <div class="mx-auto mb-4 wi-80 he-80 tw-bg-green-400 tw-rounded-full">
          <i class="tw-text-xxxs 0 tw-text-white icon-check-simple"></i>
        </div>
        <p
          class="tw-text-black text-uppercase tw-font-tolyerBold text-center fs-40"
        >
          Finalizado
        </p>
        <p
          class="tw-text-gray-600 text-center tw-text-base px-2 px-md-3 mb-5 line-height-20"
        >
          ¡Genial! has finalizado el proceso de tu hoja de vida. <br />
          Estas listo para aplicar a ofertas laborales.
        </p>
        <div class="text-center">
          <router-link
            to="/ambassadors/people/cv/view"
            class="mx-2 tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-3 px-4 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 w-100 text-center"
          >
            <svg
              width="24"
              height="30"
              viewBox="0 0 24 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-3"
            >
              <path
                d="M20.2017 0H2.80611C1.26124 0 0.00390625 1.27747 0.00390625 2.84708V24.2984L6.11758 30H20.2017C21.7466 30 23.0039 28.7225 23.0039 27.1529V2.84708C23.0039 1.27747 21.7466 0 20.2017 0ZM5.8731 28.2034L2.03286 24.6219H5.82274C5.85117 24.6219 5.87391 24.645 5.87391 24.6739V28.2034H5.8731ZM21.8668 27.1529C21.8668 28.0854 21.1195 28.8447 20.2017 28.8447H7.01023V24.6739C7.01023 24.0079 6.4774 23.4666 5.82193 23.4666H1.14103V2.84708C1.14103 1.91456 1.88829 1.15534 2.80611 1.15534H20.2017C21.1195 1.15534 21.8668 1.91456 21.8668 2.84708V27.1529Z"
                fill="#222222"
              />
              <path
                d="M17.2784 19.8242H4.81466C4.50032 19.8242 4.24609 20.0825 4.24609 20.4019C4.24609 20.7213 4.50032 20.9796 4.81466 20.9796H17.2784C17.5927 20.9796 17.8469 20.7213 17.8469 20.4019C17.8469 20.0825 17.5919 19.8242 17.2784 19.8242Z"
                fill="#222222"
              />
              <path
                d="M17.2777 22.1914H10.6272C10.3128 22.1914 10.0586 22.4497 10.0586 22.7691C10.0586 23.0884 10.3128 23.3467 10.6272 23.3467H17.2777C17.592 23.3467 17.8463 23.0884 17.8463 22.7691C17.8463 22.4497 17.5912 22.1914 17.2777 22.1914Z"
                fill="#222222"
              />
              <path
                d="M17.2777 24.5625H10.6272C10.3128 24.5625 10.0586 24.8208 10.0586 25.1402C10.0586 25.4595 10.3128 25.7178 10.6272 25.7178H17.2777C17.592 25.7178 17.8463 25.4595 17.8463 25.1402C17.8463 24.8208 17.5912 24.5625 17.2777 24.5625Z"
                fill="#222222"
              />
              <path
                d="M11.6132 9.48355C13.2157 9.48355 14.5194 8.15904 14.5194 6.53084C14.5194 4.90264 13.2157 3.57812 11.6132 3.57812C10.0107 3.57812 8.70703 4.90264 8.70703 6.53084C8.70703 8.15904 10.0107 9.48355 11.6132 9.48355ZM11.6132 4.73346C12.5887 4.73346 13.3822 5.53972 13.3822 6.53084C13.3822 7.52195 12.5887 8.32821 11.6132 8.32821C10.6377 8.32821 9.84416 7.52195 9.84416 6.53084C9.84335 5.53972 10.6377 4.73346 11.6132 4.73346Z"
                fill="#222222"
              />
              <path
                d="M6.44275 16.6994H16.5705C16.8848 16.6994 17.139 16.4411 17.139 16.1217V15.4722C17.139 12.3165 14.6122 9.75 11.507 9.75C8.40186 9.75 5.875 12.3173 5.875 15.4722V16.1217C5.87419 16.4411 6.12923 16.6994 6.44275 16.6994ZM7.01132 15.4722C7.01132 12.9536 9.02809 10.9053 11.5062 10.9053C13.9843 10.9053 16.0011 12.9544 16.0011 15.4722V15.544H7.01132V15.4722Z"
                fill="#222222"
              />
            </svg>
            <span class="tw-text-gray-600 font-weight-bold"
              >Ver hoja de vida</span
            >
          </router-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import datesMixin from "@/mixins/general/dates";
import utilitiesComponents from "@/mixins/general/utilitiesComponents";

export default {
  name: "CvLanguage",
  mixins: [datesMixin, utilitiesComponents],
  computed: {
    ...mapState("modCv", ["formData"]),
  },
  methods: {
    ...mapActions("modCv", [
      "getPeopleResume",
      "changeStep",
      "addLanguage",
      "removeLanguage",
      "removeLanguageEndDate",
      "saveLanguages",
    ]),
    async sendForm() {
      let form = document.querySelector(".needs-validation");
      if (form.checkValidity() === true) {
        let response = await this.saveLanguages(this.formData.languages);
        if (response.error) {
          this.showToast(
            "Error",
            "Los datos ingresados no son correctos.",
            "danger",
            "b-toaster-bottom-right"
          );
        } else {
          await this.getPeopleResume();
          this.$bvModal.show("modal-cv-finish");
        }
      }
      form.classList.add("was-validated");
    },
    backStep() {
      this.changeStep(3);
    },
    toggleActivateEndDate(index, event) {
      let inputEndDate =
        event.target.parentElement.parentElement.parentElement.querySelector(
          `#end_date-${index}`
        );
      if (event.target.checked) {
        this.removeLanguageEndDate(index);
        inputEndDate.setAttribute("disabled", "disabled");
      } else {
        inputEndDate.removeAttribute("disabled");
      }
    },
  },
};
</script>
<style scoped>
#modal-cv-finish .modal-content {
  border-radius: 10px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #23bf79;
  background-color: #23bf79;
}
</style>
