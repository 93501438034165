<script setup>
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router/composables";
import { useEventsStore } from "@/stores/eventsStore";
import { formatDateDDMMYYYY, formatTimeToAMPM } from "@/helpers/datesHelpers";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import MoleculeCardHeaderBodyFooter from "@/components/molecules/MoleculeCardHeaderBodyFooter.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";
import AtomMedia from "@/components/atoms/AtomMedia.vue";
import AtomIcon from "@/components/atoms/AtomIcon.vue";

const isLoading = ref(false);
const route = useRoute();
const router = useRouter();
const { actGetStreetEventById, actPostStreetEventsRegister } = useEventsStore();
const { streetEvent } = storeToRefs(useEventsStore());

const registerToEvent = async () => {
  isLoading.value = true;
  await actPostStreetEventsRegister(streetEvent.value.id);
  await actGetStreetEventById(route.params.eventId);
  isLoading.value = false;
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetStreetEventById(route.params.eventId);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Eventos" />
    <title-bar title="Eventos" />
    <main
      class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4"
      v-if="!isLoading"
    >
      <section
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-3 lg:tw-gap-4"
      >
        <div class="tw-col-span-1 lg:tw-col-span-7">
          <molecule-card-header-body-footer
            rounded
            rounded-size="2.5xl"
            border
            border-color="gray-300"
          >
            <template #header>
              <atom-media
                :src="streetEvent.resource"
                alt="Imagen del evento"
                :type="streetEvent.resource_type === 'I' ? 'image' : 'video'"
                fill
                custom-width="w-9/12"
                rounded
                rectangular
              />
            </template>
            <template #body>
              <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
                <atom-text
                  tag="h3"
                  font="robotoBold"
                  text-size-mobile="base"
                  text-size-desktop="lg"
                  text-alignment="center"
                >
                  {{ streetEvent.name }}
                </atom-text>
                <atom-text
                  tag="p"
                  font="roboto"
                  text-size-mobile="sm"
                  text-size-desktop="base"
                  text-alignment="center"
                >
                  {{ streetEvent.desc }}
                </atom-text>
                <div
                  class="tw-flex tw-items-center tw-justify-center tw-gap-2 lg:tw-gap-4"
                >
                  <atom-text
                    tag="p"
                    font="roboto"
                    text-size-mobile="xs"
                    text-size-desktop="sm"
                    text-alignment="left"
                  >
                    <span class="tw-font-robotoBold">Fecha:</span>
                    {{ formatDateDDMMYYYY(streetEvent.date) }}
                  </atom-text>
                  <atom-text
                    tag="p"
                    font="roboto"
                    text-size-mobile="xs"
                    text-size-desktop="sm"
                    text-alignment="right"
                  >
                    <span class="tw-font-robotoBold">Hora:</span>
                    {{ formatTimeToAMPM(streetEvent.start_time) }}
                  </atom-text>
                </div>
                <atom-text
                  tag="p"
                  font="roboto"
                  text-size-mobile="xs"
                  text-size-desktop="sm"
                  text-alignment="left"
                  text-color="red-350"
                  class="tw-w-full"
                >
                  Cupos disponibles: {{ streetEvent.places_available }}
                </atom-text>
              </div>
            </template>
            <template #footer>
              <div
                class="tw-grid tw-grid-cols-2 tw-gap-2 lg:tw-gap-4"
                v-if="!streetEvent.registered_user"
              >
                <atom-button
                  border
                  bg-color="white"
                  selected-bg-color="red-350"
                  selected-text-color="white"
                  rounded
                  class="tw-w-full"
                  @button-click="
                    router.push(
                      `/vamosPaLaCalle/actividades/${streetEvent.street_event_stage_id}`
                    )
                  "
                >
                  <atom-text
                    tag="span"
                    font="roboto"
                    text-size-mobile="sm"
                    text-size-desktop="base"
                    text-alignment="center"
                    text-color=""
                    class="tw-place-self-center"
                  >
                    Cerrar
                  </atom-text>
                </atom-button>
                <atom-button
                  bg-color="red-350"
                  rounded
                  class="tw-w-full"
                  @button-click="registerToEvent()"
                >
                  <atom-text
                    tag="span"
                    font="roboto"
                    text-size-mobile="sm"
                    text-size-desktop="base"
                    text-color="white"
                    text-alignment="center"
                    class="tw-place-self-center"
                  >
                    Inscribirme
                  </atom-text>
                </atom-button>
              </div>
              <atom-text
                tag="p"
                font="robotoBold"
                text-size-mobile="sm"
                text-size-desktop="lg"
                text-alignment="center"
                text-color="red-350"
                v-else
              >
                ¡Ya estás inscrito!
              </atom-text>
            </template>
          </molecule-card-header-body-footer>
        </div>
        <div class="tw-col-span-1 lg:tw-col-span-5">
          <molecule-card-header-body-footer
            rounded
            rounded-size="2.5xl"
            border
            border-color="gray-300"
          >
            <template #header>
              <atom-text
                tag="h3"
                font="robotoBold"
                text-size-mobile="base"
                text-size-desktop="xl"
                text-alignment="center"
              >
                Subir evidencia
              </atom-text>
            </template>
            <template #body>
              <atom-text
                tag="p"
                font="roboto"
                text-size-mobile="xs"
                text-size-desktop="sm"
                text-alignment="left"
              >
                No olvides que debes subir tu evidencia máximo dos (2) días
                después del evento. <br />
                Se habilitara la opción de subir evidencia una vez estes
                inscrito en el evento
              </atom-text>
            </template>
            <template #footer>
              <atom-button bg-color="red-350" rounded class="tw-w-full">
                <atom-text
                  tag="span"
                  font="roboto"
                  text-size-mobile="sm"
                  text-size-desktop="base"
                  text-color="white"
                  text-alignment="center"
                  class="tw-place-self-center"
                >
                  Subir evidencia
                </atom-text>
              </atom-button>
            </template>
          </molecule-card-header-body-footer>
          <molecule-card-header-body-footer
            rounded
            rounded-size="2.5xl"
            border
            border-color="gray-300"
            class="tw-mt-3 lg:tw-mt-4"
          >
            <template #body>
              <div class="tw-flex tw-justify-between tw-items-center">
                <atom-text
                  tag="p"
                  font="robotoBold"
                  text-size-mobile="xs"
                  text-size-desktop="sm"
                  text-alignment="left"
                >
                  Horas Ganadas
                </atom-text>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <atom-text
                    tag="p"
                    font="roboto"
                    text-size-mobile="xs"
                    text-size-desktop="sm"
                    text-alignment="right"
                    class="tw-w-full"
                  >
                    5 Horas
                  </atom-text>
                  <atom-icon icon="icon-reloj" class="tw-text-xl" />
                </div>
              </div>
            </template>
          </molecule-card-header-body-footer>
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
