<template>
  <div class="row pt-3">
    <div class="col">
      <p
        class="m-0 tw-font-robotoBold tw-text-lg fs-md-22 line-height-md-30 tw-text-yellow-300 d-md-inline-block"
      >
        Paso 2.
      </p>
      <p
        class="ml-md-2 my-1 tw-font-robotoBold tw-text-lg fs-md-22 line-height-md-30 d-md-inline-block"
      >
        Simula tu Bono aquí
      </p>
    </div>
    <div
      v-b-modal.modal-sti-0to6
      class="col-1 btn-modal-info d-flex align-items-center justify-content-end"
    >
      <i class="icon-mas-informacion tw-text-lg fs-md-22 tw-text-red-400"></i>
    </div>
    <b-modal
      class="showme d-flex justify-content-center"
      :id="'modal-sti-0to6'"
      centered
      hide-footer
      :modal-title="false"
      size="lg"
    >
      <div class="container text-center">
        <p class="modal-title strong">Ten en cuenta</p>
        <p>Así es como se calcula el valor del Bono ABI</p>
        <img
          class="desc-image d-none d-md-block"
          src="@/assets/img/show-me/abi-bonus.png"
          alt=""
        />
        <img
          class="desc-image d-block d-md-none"
          src="@/assets/img/show-me/abi-bonus-mobile.png"
          alt=""
        />
        <ul
          class="tw-text-gray-550 tw-italic tw-font-roboto tw-text-xxs lg:tw-text-xs tw-text-left tw-m-0 tw-p-5"
        >
          <li>
            El Job Band Multiplier corresponde al % de acuerdo a tu Banda.
          </li>
          <li>
            El simulador es una herramienta para calcular valores estimados del
            bono.
          </li>
          <li>
            El valor a pagar es el que esté acorde a las políticas de
            compensación de la compañía, la cual se reserva el derecho a
            modificarlas.
          </li>
        </ul>
      </div>
    </b-modal>

    <div
      class="col-12 pt-2 tw-font-roboto tw-text-sm line-height-18 tw-text-gray-600"
    >
      Introduce los valores de cada objeto en la fórmula para simular tu bono.
      Recuerda calcular antes el valor del Achievement Score.
    </div>
    <div class="col-12 pt-3">
      <form>
        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
        >
          Achievement Score
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-percentage text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="text"
            class="form-control text-right"
            id="anual-salary"
            placeholder="Ingresa tu costo"
            :value="calculateAchievementScore"
            readonly
          />
          <div class="input-group-append">
            <span
              class="input-group-text tw-rounded-lg"
              style="background-color: #e9ecef"
              >%</span
            >
          </div>
        </div>

        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
        >
          Bonus Target
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-anualsal text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="text"
            class="form-control text-right"
            id="anual-salary"
            placeholder="Ingresa tu costo"
            :value="(parseFloat(user.target.replace(',', '.')) * 100).toFixed()"
            readonly
          />
          <div class="input-group-append">
            <span
              class="input-group-text tw-rounded-lg"
              style="background-color: #e9ecef"
              >%</span
            >
          </div>
        </div>

        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
        >
          Salario anual
        </p>
        <small v-if="bonusPropsRange0to6.mrc !== 0"
          >- Valor: {{ formatCurrency(bonusPropsRange0to6.mrc) }}</small
        >
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-worktime text-white tw-text-xl"></i>
            </div>
          </div>
          <input
            type="text"
            class="form-control text-right tw-rounded-lg"
            id="anual-salary"
            placeholder="Ingresa tu costo"
            :value="formatCurrency(calculatedAnualSalary)"
            readonly
          />
        </div>
        <p
          class="m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black d-inline-block"
        >
          TDR
        </p>
        <p
          v-if="bonusPropsRange0to6.tdrTime !== 0"
          class="d-inline-block m-auto tw-font-roboto tw-text-xs line-height-16 tw-text-black"
        >
          - Valor: {{ (bonusPropsRange0to6.tdrTime * 100).toFixed(0) }}%
        </p>
        <div class="input-group mb-2 mr-sm-2 pt-1">
          <div class="input-group-prepend">
            <div
              class="input-group-text tw-bg-gray-320 tw-rounded-lg wi-50 d-flex justify-content-center align-items-center"
            >
              <i class="icon-tdr text-white tw-text-xl"></i>
            </div>
          </div>
          <select
            class="form-control tw-rounded-lg"
            id="tdr-value"
            :value="bonusPropsRange0to6.tdrTime"
            @change="updateTdrTime($event.target.value)"
          >
            <option value="0" selected>Tiempo laborado</option>
            <option value="0.33">4 meses</option>
            <option value="0.41">5 meses</option>
            <option value="0.5">6 meses</option>
            <option value="0.58">7 meses</option>
            <option value="0.66">8 meses</option>
            <option value="0.75">9 meses</option>
            <option value="0.83">10 meses</option>
            <option value="0.91">11 meses</option>
            <option value="1">12 meses</option>
          </select>
        </div>
      </form>
    </div>
    <div class="col-12 pt-2 pb-2">
      <div
        class="calculator-total-value text-center text-light d-flex justify-content-between align-items-center pt-2 pb-2 tw-border-b tw-border-gray-300"
      >
        <span
          class="pr-2 tw-font-robotoBold tw-text-sm fs-md-16 tw-text-gray-600"
          >Valor del bono ABI</span
        >
        <span
          class="tw-font-roboto tw-text-lg fs-md-24 line-height-32 tw-text-green-500"
          >{{ formatCurrency(calculateAbiValueRange0to6) }}</span
        >
      </div>
    </div>
    <div class="col-12 pt-2 pb-2">
      <div class="calculator-total-value pt-1 pb-2">
        <div
          role="button"
          class="tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
          @click="changeStep()"
        >
          Volver
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/assets/css/showme.css"></style>
<script>
import { mapState } from "vuex";
import numbersMixin from "@/mixins/general/numbers";

export default {
  name: "CalculatorRange0to6",
  mixins: [numbersMixin],
  props: {
    error: {
      type: Object,
    },
    bonusPropsRange0to6: {
      type: Object,
    },
    calculateAchievementScore: {
      type: Number,
    },
    calculateAbiValueRange0to6: {
      type: Number,
    },
    employeeCorePosition: {
      type: String,
    },
    calculatedAnualSalary: {
      type: Number,
    },
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  watch: {
    calculateAbiValueRange0to6: function (newVal) {
      this.pushDataLayer(
        this.calculateAchievementScore,
        parseFloat(this.user.target.replace(",", ".")) * 100,
        Number(this.user.total_salary),
        this.bonusPropsRange0to6.tdrTime * 100,
        Math.round(newVal)
      );
    },
  },
  methods: {
    updateTdrTime(value) {
      this.$emit("updateTdrTime", value);
    },
    changeStep() {
      this.$emit("changeStep");
    },
    pushDataLayer(achscore, bontar, mrs, tdr, value) {
      window.dataLayer.push({
        event: "TrackEvent-Compensacion",
        Category: "Remuneración - Bono ABI",
        Action: "paso 2 - Bono ABI",
        Label: "Simula tu Bono ABI",
        AchievementScore: achscore + "%",
        BonusTarget: bontar + "%",
        MRS: mrs,
        TDR: tdr + "%",
        Value: value,
      });
    },
  },
};
</script>

<style scoped>
.btn-modal-info,
.btn-modal-info:focus {
  outline: none;
}

.modal-title {
  color: #d82b2b;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 1;
  margin-bottom: 10px;
}

.desc-image {
  max-width: 100%;
  height: auto;
}
</style>
