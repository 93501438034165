import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";
import { useStore } from "@/store";

const uri = {
  core_employeeUri: "/coreEmployees",
};

const vuexStore = useStore();

export const useUserStore = defineStore("user", () => {
  const storedUser = localStorage.getItem("user");
  const user = ref(
    storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : {}
  );

  async function actGetUserById(id) {
    const response = await AxiosBeerOffice.get(
      uri.core_employeeUri + `/${id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data: userData } = response;
    user.value = userData;
    vuexStore.state.modCore.user = userData;
    localStorage.setItem("user", JSON.stringify(user.value));
    return response;
  }

  async function actDeliverySiteUpdate(id, data) {
    const response = await AxiosBeerOffice.patch(
      uri.core_employeeUri + `/${id}` + "/ecommDeliverySite",
      true,
      data
    );
    if (response.error) return response.error;
    const { data: userData } = response;
    user.value = userData;
    localStorage.setItem("user", JSON.stringify(user.value));
  }

  async function actPatchUserShortcuts(data) {
    let custom_home_object = [];
    if (data.favorites.length > 0) {
      custom_home_object = data.favorites;
    }

    const response = await AxiosBeerOffice.patch(
      uri.core_employeeUri + `/${data.userId}/home`,
      true,
      { custom_home_object: custom_home_object }
    );

    if (response.error) return response.error;
    const { data: userData } = response;
    user.value = userData;
    localStorage.setItem("user", JSON.stringify(user.value));
  }

  async function actPostUserProfileImage(data, userId) {
    const response = await AxiosBeerOffice.post(
      uri.core_employeeUri + `/${userId}/profPicture`,
      true,
      data,
      true
    );
    if (response.error) return response.error;
    return true;
  }

  function getSectionShortcuts() {
    const sectionShortcuts = JSON.parse(user.value?.custom_home_object) || [];
    const fixedShortcuts = user.value?.fixed_shortcuts || [];

    if (Array.isArray(fixedShortcuts) && Array.isArray(sectionShortcuts)) {
      return fixedShortcuts.concat(sectionShortcuts.slice(0, 3));
    }

    if (Array.isArray(sectionShortcuts)) {
      return sectionShortcuts.slice(0, 4);
    }

    return [];
  }

  function setUser(data) {
    user.value = data;
    vuexStore.state.modCore.user = data;

    if (user.value) {
      localStorage.setItem("user", JSON.stringify(user.value));
    } else {
      localStorage.removeItem("user");
    }
  }

  return {
    user,
    actGetUserById,
    actDeliverySiteUpdate,
    getSectionShortcuts,
    actPatchUserShortcuts,
    actPostUserProfileImage,
    setUser,
  };
});
