<script setup>
import {
  ref,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  getCurrentInstance,
} from "vue";
import { useUserStore } from "@/stores/userStore";
import { useEcommerceStore } from "@/stores/ecommerceStore";

const userStore = useUserStore();
const ecommerceStore = useEcommerceStore();
const { $bvModal } = getCurrentInstance().proxy;
const principalText = ref("");
const buttonText = ref("");

const updateModal = () => {
  $bvModal.hide("modal-birthday");
  $bvModal.show("modal-claim");
};

const closeModal = () => {
  $bvModal.hide("modal-birthday");
};

onBeforeMount(async () => {
  if (userStore.user.ecomm_delivery_site_id == null) {
    principalText.value =
      "Tienes un obsequio sorpresa en cola, selecciona tu site de preferencia para reclamarlo";
    buttonText.value = "Selecciona Site";
  } else {
    principalText.value = "Hemos cargado un obsequio sorpresa a tus pedidos";
    buttonText.value = "Mis pedidos";
    await ecommerceStore.actValidateBirthday();
  }
});

onMounted(() => {
  $bvModal.show("modal-birthday");
});

onBeforeUpdate(() => {
  if (userStore.user.ecomm_delivery_site_id !== null) {
    ecommerceStore.actValidateBirthday();
  }
});
</script>
<template>
  <b-modal
    id="modal-birthday"
    body-class="p-0"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    size="birthday"
  >
    <div
      class="container m-auto overflow-hidden modal-content tw-border-0 pr-0 pl-0"
    >
      <div class="sub-container m-auto mw-100 overflow-hidden">
        <img
          src="@/assets/img/ecommerce/modal-birthday.png"
          class="w-100"
          alt=""
        />
      </div>
      <div class="d-flex flex-column my-3 text-center w-80p-md mx-auto py-md-2">
        <p
          class="tw-text-red-400 tw-font-tolyerBold tw-font-bold fs-24 fs-md-44 my-1"
        >
          ¡ESTE ES TU MES DE CUMPLEAÑOS!
        </p>
        <p
          class="tw-font-robotoBold tw-text-xs fs-md-16 line-height-md-18 mx-md-3 px-md-5 mt-1 mb-2"
        >
          {{ principalText }}
        </p>
        <button
          @click="
            userStore.user.ecomm_delivery_site_id !== null
              ? $router.push('/e-commerce/orderStatus')
              : updateModal()
          "
          class="tw-bg-white tw-rounded-md tw-py-3 tw-px-6 tw-text-gray-600 tw-border tw-border-gray-600 tw-outline-none hover:tw-bg-gray-200 mt-1 mt-md-3 d-inline-block w-fit-content mx-auto"
        >
          <span
            class="m-0 tw-font-robotoBold tw-text-xs fs-md-14 line-height-12 line-height-md-16"
          >
            {{ buttonText }}
          </span>
        </button>
      </div>
      <div
        class="tag wi-25 he-25 wi-md-40 he-md-40 rounded-circle bg-white mt-2 mr-2 d-flex justify-content-center align-items-center"
        @click="closeModal()"
        role="button"
      >
        <i
          class="icon-cerrar-simple tw-text-red-400 font-weight-bold tw-text-base fs-md-28"
        ></i>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.sub-container {
  perspective: 1px;
  width: 630px !important;
}

.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .sub-container {
    width: 258px;
  }
}
</style>
