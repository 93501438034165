import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    vacants: [],
    vacantRequests: [],
    vacantRequestsByLineManager: [],
    vacantSelected: {},
    activeRequestComponent: "Request",
    places: [],
    terms: [],
    sidelines: [],
  },
  mutations: {
    setVacants: (state, vacants) => {
      state.vacants = vacants;
    },
    setPlaces: (state, places) => {
      state.places = places;
    },
    setTerms: (state, terms) => {
      state.terms = terms;
    },
    setSideLines: (state, sidelines) => {
      state.sidelines = sidelines;
    },
    setVacantSelected: (state, vacantSelected) => {
      state.vacantSelected = vacantSelected;
    },
    setVacantRequests: (state, vacantRequests) => {
      state.vacantRequests = vacantRequests;
    },
    setVacantRequestsByLineManager: (state, vacantRequestsByLineManager) => {
      state.vacantRequestsByLineManager = vacantRequestsByLineManager;
    },
    setActiveRequestComponent: (state, activeRequestComponent) => {
      state.activeRequestComponent = activeRequestComponent;
    },
  },
  actions: {
    getVacants: async ({ commit }) => {
      let response = await ApiBeerOffice.getVacants();
      if (response.error) {
        return response.error;
      } else {
        commit("setVacants", response.data);
      }
    },
    getTerms: async ({ commit }) => {
      let response = await ApiBeerOffice.getTerms();
      if (response.error) {
        return response.error;
      } else {
        commit("setTerms", response.data);
      }
    },
    getPlaces: async ({ commit }) => {
      let response = await ApiBeerOffice.getPlaces();
      if (response.error) {
        return response.error;
      } else {
        commit("setPlaces", response.data);
      }
    },
    getSideLines: async ({ commit }) => {
      let response = await ApiBeerOffice.getSideLines();
      if (response.error) {
        return response.error;
      } else {
        commit("setSideLines", response.data);
      }
    },
    getVacantById: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getVacantById(data);
      if (response.error) {
        return response.error;
      } else {
        commit("setVacantSelected", response.data);
        return response.data;
      }
    },
    getSearchVacants: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getSearchVacants(data);
      if (response.error) {
        return response.error;
      } else {
        commit("setVacants", response.data);
      }
    },
    actApplyVacant: async (_, data) => {
      let response = await ApiBeerOffice.applyVacant({
        people_vacant_position_id: data,
      });

      if (response.error) {
        return { data: response.error, error: true };
      } else {
        return { data: response.data, error: false };
      }
    },
    getVacantRequests: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getVacantRequests(data);
      if (response.error) {
        return response.error;
      } else {
        commit("setVacantRequests", response.data);
      }
    },
    getVacantRequestsByLineManager: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getVacantRequestsByLineManager(data);
      if (response.error) {
        return response.error;
      } else {
        commit("setVacantRequestsByLineManager", response.data);
      }
    },
    changeRequestMainComponent({ commit }, component) {
      commit("setActiveRequestComponent", component);
    },
    changeVacantState: async (_, data) => {
      let response = await ApiBeerOffice.changeVacantState(
        {
          is_approved: data.is_approved,
        },
        data.id
      );

      if (response) {
        return { data: response.data, error: false };
      } else {
        return { data: null, error: true };
      }
    },
  },
};
