<template>
  <div class="tw-bg-white tw-rounded-lg tw-shadow-md p-3">
    <div class="d-flex justify-content-between mb-4">
      <p class="tw-text-lg tw-text-gray-600 mb-1 font-weight-bold">
        Porcentajes de Pago<br />(últimos 3 meses)
      </p>
      <router-link
        to="#"
        class="mb-1 tw-text-gray-300 tw-text-xs hover:tw-text-gray-400"
        >Detalle</router-link
      >
    </div>
    <div class="d-flex mt-4">
      <div class="mx-1 w-100" v-if="paymentPercentData.payment_1">
        <p class="mb-4 tw-text-gray-600 tw-text-sm mb-2 font-weight-bold">
          {{ paymentPercentData.payment_1[0] }}
        </p>
        <div class="tw-bg-red-300 px-3 py-4 text-center tw-rounded-lg">
          <p class="mb-0 tw-text-xl tw-text-white">
            {{ paymentPercentData.payment_1[1] }}%
          </p>
        </div>
      </div>
      <div class="mx-1 w-100" v-if="paymentPercentData.payment_2">
        <p class="mb-4 tw-text-gray-600 tw-text-sm mb-2 font-weight-bold">
          {{ paymentPercentData.payment_2[0] }}
        </p>
        <div class="tw-bg-red-200 px-3 py-4 text-center tw-rounded-lg">
          <p class="mb-0 tw-text-xl tw-text-white">
            {{ paymentPercentData.payment_2[1] }}%
          </p>
        </div>
      </div>
      <div class="mx-1 w-100" v-if="paymentPercentData.payment_3">
        <p class="mb-4 tw-text-gray-600 tw-text-sm mb-2 font-weight-bold">
          {{ paymentPercentData.payment_3[0] }}
        </p>
        <div class="tw-bg-red-400 px-3 py-4 text-center tw-rounded-lg">
          <p class="mb-0 tw-text-xl tw-text-white">
            {{ paymentPercentData.payment_3[1] }}%
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompPaymentPercent",
  props: {
    paymentPercentData: {
      required: true,
    },
  },
};
</script>

<style scoped></style>
