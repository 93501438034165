import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    platforms: [],
  },
  mutations: {
    setPeoplePlatforms: (state, platforms) => {
      state.platforms = platforms;
    },
  },
  actions: {
    actGetPeoplePlatforms: async ({ commit }) => {
      let response = await ApiBeerOffice.getPeoplePlatforms();
      if (response.error) {
        return response.error;
      }
      commit("setPeoplePlatforms", response.data.platforms);
      return true;
    },
  },
};
