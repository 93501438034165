<script setup>
import config from "@/services/config";
import { useRouter } from "vue-router/composables";

defineProps({
  shortCut: {
    type: Object,
    required: true,
  },
  icon: {
    type: String,
    default: "icon-beneficios-banco",
  },
  state: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["addRemoveFavorite"]);
const router = useRouter();
const uriResources = config.beerOfficeURL;

const addRemoveFavorite = (state, shortCut) => {
  emit("addRemoveFavorite", { state, shortCut });
};

const goToView = (urlPath) => {
  router.push({ path: urlPath });
};
</script>
<template>
  <div
    class="tw-col-span-1 tw-border tw-border-solid tw-border-red-50 tw-rounded-xl tw-p-2 tw-text-center tw-relative"
  >
    <div
      @click="addRemoveFavorite(state, shortCut)"
      class="tw-absolute tw-w-4 tw-h-4 tw-rounded-full tw--top-1 tw-right-0 tw-flex tw-justify-center tw-items-center tw-cursor-pointer"
      :class="state ? 'tw-bg-red-400' : 'tw-bg-black'"
    >
      <span class="tw-text-white">{{ state ? "+" : "-" }}</span>
    </div>
    <div @click="goToView(shortCut.url)" class="tw-cursor-pointer">
      <img
        width="40"
        height="40"
        class="tw-rounded-md tw-mb-1 tw-object-cover"
        loading="lazy"
        :src="uriResources + '/' + shortCut.image"
        :alt="shortCut.title"
      />
      <p class="tw-mb-0 tw-text-black" style="font-size: 11px">
        {{ shortCut.title }}
      </p>
    </div>
  </div>
</template>
