<template>
  <div class="mt-md-4 px-0 px-md-3 px-lg-5">
    <GamificationMainMenu />
    <div style="z-index: 10">
      <div class="d-flex d-row justify-content-end img-patron2">
        <img src="@/assets/img/gamification/patron2.png" alt="" />
      </div>
      <div class="text-center mt-3 mt-md-4">
        <p class="title-primary">Historial de recompensas</p>
      </div>
      <div class="d-flex row mx-1 mx-md-2 mx-md-3 cont-header">
        <div class="col-12 px-0">
          <div class="row align-items-center d-flex mr-md-3">
            <div
              class="col-12 d-flex px-2 px-md-0 py-3 py-md-2 align-items-center justify-content-center"
            >
              <div class="mx-1 mx-md-2 d-flex align-items-center">
                <label class="check-group textColorPrimary">
                  Eventos
                  <input
                    type="checkbox"
                    value="1"
                    v-model="state"
                    v-on:change="filterRewardsHistoryData()"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="mx-1 mx-md-2 d-flex align-items-center">
                <label class="check-group textColorPrimary">
                  Productos
                  <input
                    type="checkbox"
                    value="2"
                    v-model="state"
                    v-on:change="filterRewardsHistoryData()"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="mr-2 mx-1 mx-md-2 d-flex align-items-center">
                <label class="check-group textColorPrimary">
                  Otros
                  <input
                    type="checkbox"
                    value="3"
                    v-model="state"
                    v-on:change="filterRewardsHistoryData()"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-0 pt-md-3 pb-5" v-if="isLoading">
        <div class="col">
          <div class="text-center mt-3">
            <img src="../../assets/img/icons/loading.gif" alt="loading" />
          </div>
        </div>
      </div>
      <div class="container px-0">
        <div class="row mt-4 mx-1 mb-5" v-if="filterRewardsHistory.length > 0">
          <div
            class="col-12 col-md-4 mb-4 cont-card-history"
            v-for="item of filterRewardsHistory"
            v-bind:key="item.id"
          >
            <router-link :to="'/gamification/redeemHistory/' + item.id">
              <div class="d-flex cont-event">
                <div class="col-8 p-md-3" style="padding: 14px 12px 14px 12px">
                  <p class="title mb-1">{{ item.reward_name }}</p>
                  <p class="date mb-5">
                    {{ dateFormatHistory(item.created_at) }}
                  </p>
                  <p class="maltas text-right mb-0">
                    <span><img :src="imgCebada" alt="" /></span>
                    {{ formatMoney(item.reward_malts) }}
                  </p>
                </div>
                <div
                  class="col-4 px-0 cont-img"
                  :style="{
                    'background-image':
                      'url(' +
                      uriResources +
                      '/storage/images/' +
                      item.reward_image +
                      ')',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center center',
                    'background-size': 'cover',
                  }"
                ></div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import { mapState, mapActions } from "vuex";

import GamificationMainMenu from "@/components/gamification/CompGamificationMainMenu";

export default {
  name: "gamificationRewardsHistory",
  components: {
    GamificationMainMenu,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgGamification: "@/assets/img/gamification/medalla.png",
      imgRewards: "@/assets/img/gamification/patronShopRewards.png",
      imgCebada: require("@/assets/img/gamification/patron4-copia2.png"),
      state: [],
      filterRewardsHistory: [],
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    this.actGamiRewardsHistory()
      .catch((err) => {
        console.error("Error: ", err);
      })
      .finally(() => {
        this.isLoading = false;
        this.filterRewardsHistory = this.history;
      });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modGamification", ["missions", "rewards", "history"]),
  },
  methods: {
    ...mapActions("modGamification", [
      "actGamiMissions",
      "actGamiRewards",
      "actGamiRewardsHistory",
    ]),

    filterRewardsHistoryData() {
      if (this.state.includes("1")) {
        this.filterRewardsHistory = this.history.filter((history) => {
          return history.category_id === 200;
        });
      } else if (this.state.includes("2")) {
        this.filterRewardsHistory = this.history.filter((history) => {
          return history.category_id === 201;
        });
      } else if (this.state.includes("3")) {
        this.filterRewardsHistory = this.history.filter((history) => {
          return history.category_id === 202;
        });
      } else {
        this.filterRewardsHistory = this.history;
      }
    },
    formatMoney(malts) {
      return malts.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    dateFormatHistory(date) {
      let fecha = date.replace(/\D/g, " ");
      let components = fecha.split(" ");
      let month = {
        Enero: 1,
        Febrero: 2,
        Marzo: 3,
        Abril: 4,
        Mayo: 5,
        Junio: 6,
        Julio: 7,
        Agosto: 8,
        Septiembre: 9,
        Octubre: 10,
        Noviembre: 11,
        Diciembre: 12,
      };
      for (const key in month) {
        if (month[key] === components[1]) {
          return key + " " + components[2] + " de " + components[0];
        }
      }
    },
  },
};
</script>
<style scoped>
.cont-card-history .cont-event .maltas {
  font: normal normal bold 25px TolyerBold1;
  letter-spacing: 0;
  color: #d82b2b;
  opacity: 1;
  position: absolute;
  bottom: 2px;
  right: 16px;
}
.cont-card-history .cont-event .maltas img {
  height: 23px;
  width: 23px;
}
.cont-card-history .cont-event .date {
  font: normal normal bold 11px Roboto;
  letter-spacing: 0;
  color: #717171;
  opacity: 1;
}
.cont-card-history .cont-event .title {
  font: normal normal bold 21px Roboto;
  letter-spacing: 0;
  color: #222;
}
.cont-card-history a {
  text-decoration: none;
}
.cont-img {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.cont-event {
  box-shadow: 0 0 6px #00000029;
  border-radius: 10px;
  height: 100%;
}
.cont-card a {
  text-decoration: none;
}
.cont-header {
  position: relative;
  z-index: 100;
}
.cont-card {
  position: relative;
  z-index: 100;
}
.img-patron1 img {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 90px;
  margin-left: 34px;
}
.img-patron2 img {
  position: absolute;
  z-index: 2;
  width: 90px;
  height: 90px;
}

.tab-links {
  list-style: none;
}

.tab-links li a {
  color: #717171;
  font-size: 14px;
  border-bottom: 1px solid #717171;
  text-decoration: none;
}

.tab-links li a.active {
  color: #d82b2b;
  border-bottom: 1px solid #d82b2b;
}
.check-group {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 4px #00000042;
  border-radius: 2px;
  opacity: 1;
}

/* When the checkbox is checked, add a blue background */
.check-group input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-group input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-group .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #d82b2b;
  border-width: 0 2px 2px 0;
  /* -webkit-transform: rotate(45deg); */
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.title-primary {
  font: normal normal 44px TolyerBold1;
  letter-spacing: 0;
  color: #222;
  opacity: 1;
  position: relative;
  z-index: 12;
}

@media (max-width: 768px) {
  .img-patron1 img {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 90px;
    margin-left: 0;
    margin-bottom: 0;
  }
  .cont-border .cont-box {
    margin-left: 0;
    margin-right: 0;
  }
  .title-primary {
    font: normal normal 26px TolyerBold1;
  }
  .cont-img img {
    width: 100%;
    height: 100%;
  }
  .cont-event {
    height: 100%;
  }
}
</style>
