<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useReservationsStore } from "@/stores/reservationsStore";
import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import CompGeneralRatingStars from "@/components/rating/CompGeneralRatingStars";

const route = useRoute();
const router = useRouter();
const reservationsStore = useReservationsStore();

const ratingStep = ref(1);
const score = ref(0);
const review = ref("");
const breadcrumb = ref([
  {
    id: 0,
    name: "Inicio",
    link: "/home",
    active: false,
  },
  {
    id: 1,
    name: "Bienestar",
    link: "#",
    active: false,
  },
  {
    id: 2,
    name: "Beneficios",
    link: "/care-benefits/benefits",
    active: false,
  },
  {
    id: 3,
    name: "Reserva de Espacios",
    link: "/reservations",
    active: false,
  },
  {
    id: 4,
    name: "Espacios 127",
    link: `/reservations/space/${route.params.id}`,
    active: false,
  },
  {
    id: 5,
    name: "Reserva",
    link: `/reservations/space/${route.params.id}/book`,
    active: true,
  },
]);

const voteRating = (sc) => {
  score.value = sc;
};

const postReview = async () => {
  await reservationsStore
    .actPostBookingReview({
      id: route.params.id,
      data: {
        review: review.value,
        score: score.value,
      },
    })
    .then(() => {
      ratingStep.value = 2;
    });
};
</script>
<template>
  <div class="tw-relative">
    <mobile-bar title="Reserva" />
    <title-bar title="Reserva" :breadcrumb="breadcrumb" />
    <div class="tw-container-general tw-mt-5">
      <div
        class="tw-rounded-2.5xl tw-border tw-border-gray-300 tw-p-4 tw-flex tw-flex-col tw-gap-6"
        v-if="ratingStep === 1"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base">
          Califica tu estadía
        </p>
        <div class="tw-w-10/12 tw-self-center">
          <CompGeneralRatingStars
            star-background-unchecked-color="white"
            star-border-color="black"
            @voteRating="voteRating"
          />
        </div>
        <p class="tw-m-0 tw-font-roboto tw-text-sm">
          Coméntanos que te pareció nuestras instalaciones, y que
          recomendaciones le harías a otros embajadores.
        </p>
        <textarea
          class="tw-w-full tw-h-40 tw-p-6 tw-border tw-border-gray-300 tw-rounded-2.5xl focus:tw-outline-none focus:tw-ring-gray-300 tw-text-roboto tw-text-sm"
          name="comment"
          placeholder="Contenido..."
          v-model="review"
        />
        <div class="tw-grid tw-grid-cols-2 tw-gap-5">
          <div
            class="tw-btn-secondary-white"
            @click="router.push({ name: 'Reservations' })"
          >
            <p class="tw-m-0 tw-text-center">Omitir</p>
          </div>
          <div
            class="tw-flex tw-items-center tw-justify-center"
            :class="
              score > 0 && review.length > 0
                ? 'tw-btn-primary-red'
                : 'tw-btn-primary-disabled'
            "
            @click="score > 0 && review.length > 0 ? postReview() : ''"
          >
            <p class="tw-m-0 tw-text-center">Enviar</p>
          </div>
        </div>
      </div>
      <div
        class="tw-rounded-2.5xl tw-border tw-border-gray-300 tw-p-4 tw-flex tw-flex-col tw-gap-6"
        v-if="ratingStep === 2"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base tw-text-red-350">
          Gracias por tu calificación
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-sm">
          Esto nos ayudará a mejorar nuestro contenido.
        </p>
        <img src="@/assets/img/reservations/RatingThanks.svg" alt="" />
      </div>
    </div>
  </div>
</template>
