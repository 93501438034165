<script setup>
import { onBeforeMount, ref, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router/composables";
import {
  required,
  maxLength,
  minLength,
  numeric,
  helpers,
} from "@vuelidate/validators";
import config from "@/services/config";
import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { usePeopleTour } from "@/composables/peopleTour/usePeopleTour";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useStorage } from "@vueuse/core";
import SkeletonPeopleTourRewardsProduct from "@/components/peopleTour/skeletons/SkeletonPeopleTourRewardsProduct.vue";
import { getBand } from "@/helpers/userHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import SuccessFailModalContent from "@/components/modals/SuccessFailModalContent.vue";
import CompGeneralSwitch from "@/components/general/CompGeneralSwitch.vue";
import CompPeopleTourRewardForm from "@/components/peopleTour/CompPeopleTourRewardForm.vue";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const router = useRouter();
const route = useRoute();
const peopleTourStore = usePeopleTourStore();
const step = ref(1);
const isModalOpen = ref(false);
const isLoading = ref(false);
const reward = ref(null);
const uriResources = config.beerOfficeURL;
const delivery_127 = ref(false);
const isFormSubmitted = ref(false);
const form = ref(null);
const { checkBP } = usePeopleTour();
const isRedeemed = ref(false);
const message = ref(null);

const alpha = helpers.regex(/^[a-zA-ZÀ-ž\s]*$/);

const redeemData = reactive({
  people_tour_reward_id: route.params.id,
  department: null,
  address: null,
  city: null,
  neighborhood: null,
  additional_address_info: null,
  phone: null,
});

const user = useStorage("user", {});
const band = getBand(user.value);

const formFields = computed(() => {
  if (delivery_127.value) {
    return [
      {
        name: "floor",
        type: "text",
        label: "En que piso te encuentras*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
        },
      },
      {
        name: "indications",
        type: "text",
        label: "Indícanos como llegar a tu puesto de trabajo*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
        },
      },
      {
        name: "phone",
        type: "tel",
        label: "Número de celular*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
          minLength: helpers.withMessage(
            "El número de teléfono debe tener al menos 7 caracteres",
            minLength(7)
          ),
          maxLength: helpers.withMessage(
            "El número de teléfono debe tener máximo 10 caracteres",
            maxLength(10)
          ),
          numeric: helpers.withMessage(
            "Solo se permiten caracteres numéricos",
            numeric
          ),
        },
      },
    ];
  } else {
    return [
      {
        name: "department",
        type: "text",
        label: "Departamento*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
          alpha: helpers.withMessage(
            "Solo se permiten caracteres alfabéticos",
            alpha
          ),
        },
      },
      {
        name: "address",
        type: "text",
        label: "Dirección*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
        },
      },
      {
        name: "city",
        type: "text",
        label: "Municipio o ciudad*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
          alpha: helpers.withMessage(
            "Solo se permiten caracteres alfabéticos",
            alpha
          ),
        },
      },
      {
        name: "additional_address_info",
        type: "text",
        label: "Indicaciones adicionales",
        validations: {},
      },
      {
        name: "neighborhood",
        type: "text",
        label: "Barrio*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
        },
      },
      {
        name: "phone",
        type: "tel",
        label: "Teléfono de contacto*",
        validations: {
          required: helpers.withMessage("Campo requerido", required),
          minLength: helpers.withMessage(
            "El número de teléfono debe tener al menos 7 caracteres",
            minLength(7)
          ),
          maxLength: helpers.withMessage(
            "El número de teléfono debe tener máximo 10 caracteres",
            maxLength(10)
          ),
          numeric: helpers.withMessage(
            "Solo se permiten caracteres numéricos",
            numeric
          ),
        },
      },
    ];
  }
});

const formLabels = computed(() => {
  if (delivery_127.value) {
    return {
      floor: "En que piso te encuentras*",
      indications: "Indícanos como llegar a tu puesto de trabajo*",
      phone: "Número de celular*",
    };
  } else {
    return {
      department: "Departamento*",
      address: "Dirección*",
      city: "Municipio o ciudad*",
      additional_address_info: "Indicaciones adicionales",
      neighborhood: "Barrio*",
      phone: "Teléfono de contacto*",
    };
  }
});

function openModal() {
  isModalOpen.value = true;
  pushDataLayer({
    event_category: "Tour de Gente",
    event_action: "Redimir premio",
    event_label: "Success",
    component_name: "Popup",
    reward_name: reward.value.name,
    reward_price: reward.value.toins,
  });
}

const redeemEnabled = computed(() => {
  if (reward.value.people_tour_stage?.has_redeemed) {
    return false;
  }

  return true;
  // return reward.value.people_tour_stage_toins >= reward.value.toins;
});

function updateRedeemData(newFormData) {
  for (let [key, value] of Object.entries(newFormData)) {
    if (value !== null && value !== undefined) {
      redeemData[key] = value;
    }
  }
  if (delivery_127.value !== null && delivery_127.value !== undefined) {
    redeemData.delivery_127 = delivery_127.value;
  }

  // Limpiar redeemData de claves con valores null o undefined
  for (let key in redeemData) {
    if (redeemData[key] === null || redeemData[key] === undefined) {
      delete redeemData[key];
    }
  }
}

const onFormSubmitted = (formData) => {
  console.log("formData", formData);
  updateRedeemData(formData);
  isFormSubmitted.value = true;
};

async function validateForm() {
  await peopleTourStore.postRedeemReward(redeemData).then((res) => {
    if (res.status && res.status !== 200) {
      isRedeemed.value = false;
      message.value = res.data.message;
    } else {
      isRedeemed.value = true;
      message.value = res.message;
    }
  });
  openModal();
}

function closeModal() {
  isModalOpen.value = false;
  router.push({ name: "peopleTour" });
}
function nextStep() {
  if (step.value === 1) {
    step.value = 2;
  } else if (step.value === 2) {
    form.value.submitForm();
  } else {
    step.value = 1;
  }
}

watch(isFormSubmitted, (value) => {
  if (value) {
    validateForm();
  }
});

onBeforeMount(async () => {
  isLoading.value = true;

  await checkBP(user.value.id_sharp);

  reward.value = peopleTourStore.rewardsList.find(
    (reward) => reward.id === route.params.id
  );

  if (reward.value === undefined) {
    await peopleTourStore
      .getRewardDetail(route.params.id, user.value.id_sharp, band)
      .then(() => {
        reward.value = peopleTourStore.rewardDetail;
      });
  }

  isLoading.value = false;
});
</script>

<template>
  <div>
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 500, xl: 600 }"
      modal-name="goFlexDetail"
      v-if="isModalOpen"
      :isShowModal="isModalOpen"
      @close="closeModal"
    >
      <SuccessFailModalContent :success="isRedeemed" :message="message" />
    </general-modal>
    <mobile-bar title="Tour de Gente" />
    <title-bar title="Tour de Gente" />
    <div class="tw-container-general" v-if="!isLoading">
      <div class="tw-flex tw-justify-center lg:tw-justify-end">
        <div class="tw-w-max tw-self-center">
          <div
            class="tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-flex tw-items-center tw-justify-between tw-gap-3 tw-py-2 lg:tw-py-3"
          >
            <p class="tw-m-0 tw-font-robotoMedium tw-text-lg lg:tw-text-xl">
              Tus Toins
              <span class="tw-text-yellow-400">{{
                reward.people_tour_stage_toins
              }}</span>
            </p>
            <div
              class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-1"
            >
              <i class="icon-star tw-golden-black-border-icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-my-5 lg:tw-my-10">
        <div
          class="tw-w-full tw-rounded-3xl tw-border tw-border-red-350 tw-p-6 lg:tw-px-5 lg:tw-py-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-8"
        >
          <div class="lg:tw-col-span-1 tw-flex tw-flex-col tw-gap-3">
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-2xl lg:tw-text-xl tw-text-red-350 tw-block lg:tw-hidden"
            >
              {{ reward.name }}
            </p>
            <div class="tw-aspect-h-1 tw-aspect-w-1 tw-max-h-32">
              <img
                :src="uriResources + '/' + reward.image"
                alt=""
                class="tw-w-full tw-h-full tw-rounded-2xl lg:tw-rounded-2.5xl tw-object-cover"
              />
            </div>
          </div>
          <div
            class="lg:tw-col-span-3 tw-flex tw-flex-col tw-justify-start tw-gap-4"
          >
            <div
              class="tw-flex tw-items-center tw-justify-center lg:tw-justify-between"
            >
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-lg lg:tw-text-2xl tw-hidden lg:tw-block"
              >
                {{ reward.name }}
              </p>
              <div class="tw-flex tw-gap-2 tw-items-center">
                <img
                  src="@/assets/img/peopleTour/icons/icono-toin.png"
                  class="tw-h-5 tw-w-5 lg:tw-h-7 lg:tw-w-7"
                  alt=""
                />
                <p
                  class="tw-m-0 tw-font-roboto tw-text-xl lg:tw-text-lg tw-text-center"
                >
                  {{ reward.toins }} Toins
                </p>
              </div>
            </div>
            <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
              {{ reward.description }}
            </p>
            <div
              class="tw-flex tw-justify-between tw-items-center"
              v-if="step === 2"
            >
              <div class="tw-w-[60%]">
                <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm">
                  Dónde deseas recibir tu pedido
                </p>
              </div>
              <div class="tw-w-[40%]">
                <comp-general-switch
                  option_1="Tu dirección"
                  option_2="Site Calle 127"
                  @switch="delivery_127 = $event"
                  :switch="delivery_127"
                />
              </div>
            </div>
            <comp-people-tour-reward-form
              ref="form"
              v-if="step === 2"
              :labels="formLabels"
              :fields="formFields"
              :reward="reward"
              :redeemEnabled="redeemEnabled"
              @formSubmitted="onFormSubmitted"
            />
            <div class="tw-flex tw-justify-end">
              <button-primary-red
                class="!tw-mt-4 tw-w-3/12"
                type="button"
                title="Redimir"
                @click.native="nextStep"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
          Para tener acceso a estas recompensas, debes completar
          <span class="tw-font-robotoBold tw-text-red-350"
            >actividades en el Tour de Gente.</span
          >
          Cada vez que finalices una etapa, obtendrás una cantidad de puntos que
          podrás canjear en dicha etapa. Sin embargo, es importante recordar que
          estas recompensas
          <span class="tw-font-robotoBold tw-text-red-350"
            >dejarán de estar disponibles al finalizar la etapa.</span
          >
        </p>
      </div>
    </div>
    <skeleton-people-tour-rewards-product v-else />
  </div>
</template>
