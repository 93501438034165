<script setup>
import { onMounted, ref } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const openSelect = ref(false);

const selectFilter = ref({
  id: 0,
  name: "Lo más reciente",
});

const props = defineProps({
  notViews: {
    type: Boolean,
    default: false,
  },
  categoryForDatalayer: {
    type: String,
    default: "Artículos",
  },
});

const emit = defineEmits(["changeFilter"]);

function toggleSelectFilter() {
  openSelect.value = !openSelect.value;
}

function closeSelectFilter() {
  openSelect.value = false;
}

function sendDataLayer(action, component, label) {
  pushDataLayer({
    event_category: props.categoryForDatalayer,
    event_action: action,
    event_label: label,
    component_name: component,
  });
}

function changeSelectFilter(e, id, label) {
  selectFilter.value.id = id;
  selectFilter.value.name = e.target.innerHTML;
  emit("changeFilter", selectFilter.value);
  closeSelectFilter();
  sendDataLayer("Filtros", "Click", label);
}

onMounted(() => {
  emit("changeFilter", selectFilter.value);
});
</script>

<template>
  <div class="tw-flex tw-gap-x-2 tw-items-start tw-justify-end">
    <p class="tw-mt-2 tw-font-roboto tw-text-xs md:tw-text-lg">Organizar</p>
    <div
      class="tw-w-max tw-h-100 tw-border-solid tw-border tw-border-black tw-shadow-md tw-h-auto tw-bg-white tw-overflow-hidden tw-z-2"
      :class="
        openSelect ? 'tw-rounded-2xl md:tw-rounded-2.5xl' : 'tw-rounded-full'
      "
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-gap-x-3 tw-cursor-pointer tw-px-3 tw-py-2 tw-w-max"
        @click="toggleSelectFilter"
      >
        <span class="tw-font-roboto tw-text-xs lg:tw-text-base">{{
          selectFilter.name
        }}</span>
        <i
          class="tw-text-xs tw-text-red-350"
          :class="openSelect ? 'icon-flecha-arriba' : 'icon-flecha-abajo'"
        ></i>
      </div>
      <div
        class="tw-bg-white tw-w-full"
        :class="openSelect ? 'tw-h-auto tw-visible' : 'tw-h-0 tw-invisible'"
      >
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
          @click="changeSelectFilter($event, 0, 'Lo más reciente')"
        >
          Lo más reciente
        </div>
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
          @click="changeSelectFilter($event, 1, 'Lo más antiguo')"
        >
          Lo más antiguo
        </div>
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
          @click="changeSelectFilter($event, 2, 'Lo más visto')"
          v-if="!props.notViews"
        >
          Lo más visto
        </div>
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
          @click="changeSelectFilter($event, 3, 'Lo más valorado')"
        >
          Lo más valorado
        </div>
        <div
          class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
          @click="changeSelectFilter($event, 4, 'Lo más comentado')"
        >
          Lo más comentado
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
