<script setup>
import { ref, onBeforeMount, getCurrentInstance } from "vue";
import config from "@/services/config";
import { useStore } from "@/store";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router/composables";

const { $bvModal } = getCurrentInstance().proxy;
const store = useStore();
const router = useRouter();
const userStore = useUserStore();
const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const rewardBest = ref([]);
const filterReward = ref([]);
const imgBand = import("@/assets/img/gamification/Bandera-estrella2x.png");

const closeModal = () => {
  $bvModal.hide("modal-redeem");
  router.push({ path: "/gamification" });
};

onBeforeMount(async () => {
  isLoading.value = true;
  await store
    .dispatch("modGamification/actGamiRewards")
    .then(() => {
      filterReward.value = store.state.modGamification.rewards.filter(
        (reward) => {
          return (
            reward.gami_level_id ===
            userStore.user.current_rank.current.gami_level_id
          );
        }
      );
      rewardBest.value = filterReward.value.filter((reward) => {
        return reward.featured === 1;
      });
    })
    .catch((err) => {
      console.error("Error: ", err);
    })
    .finally(() => {
      isLoading.value = false;
    });
});
</script>
<template>
  <b-modal
    id="modal-redeem"
    body-class="p-0"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    size="redeem"
  >
    <div
      class="container m-auto overflow-hidden modal-content tw-border-0 pr-0 pl-0"
    >
      <div
        class="sub-container m-auto mw-100 overflow-hidden h-100 he-md-320 w-100 tw-bg-red-400 border border-info"
      >
        <div class="row my-3">
          <div class="col-12 col-md-7 py-4 px-5">
            <div class="d-flex flex-column">
              <div
                class="d-flex d-md-none justify-content-center align-items-center h-100 mb-3"
              >
                <img
                  src="@/assets/img/gamification/background-modal-redeem-mobile.svg"
                  class="img-fluid max-wi-200"
                  alt=""
                />
              </div>
              <p
                class="tw-font-tolyerBold fs-40 fs-md-60 line-height-24 line-height-md-40 text-white"
              >
                Haz redimido tu recompensa con exito
              </p>
              <p
                class="tw-font-roboto tw-font-black tw-text-sm line-height-20 fs-md-20 line-height-md-24 text-white"
              >
                Pronto recibiras un mail de confirmación para hacer valida tu
                recompensa, sigue participando y ganando innumerables premios.
              </p>
            </div>
          </div>
          <div class="col-md-5">
            <div
              class="d-none d-md-flex justify-content-center align-items-center h-100"
            >
              <img
                src="@/assets/img/gamification/background-modal-redeem.svg"
                class="img-fluid max-he-260"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="sub-container m-auto mw-100 overflow-hidden my-3 mx-auto pt-4 py-md-4 px-md-5 he-420 he-md-420"
      >
        <div class="d-flex justify-content-between">
          <p
            class="m-0 tw-font-robotoBold fs-md-20 line-height-md-40 tw-text-red-400"
          >
            Estas recompensas te pueden interesar
          </p>
          <div
            class="d-none d-md-flex justify-content-between align-items-center"
          >
            <i
              class="icon-maltas fs-50 fs-md-30 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
            ></i>
            <p class="tw-font-robotoBold tw-text-base line-height-20 m-0">
              Tienes
              {{
                Number(userStore.user.gamification_maltas)
                  .toLocaleString()
                  .replace(",", ".")
              }}
              maltas
            </p>
          </div>
        </div>
        <div class="mt-2">
          <div class="carousel pb-3 tw-overflow-x-scroll">
            <div class="d-flex">
              <div
                v-for="reward in filterReward"
                :key="reward.id"
                class="d-flex flex-column mx-3"
              >
                <div
                  class="cursor-pointer"
                  @click="router.push('/gamification/rewards/' + reward.id)"
                >
                  <div class="position-relative">
                    <div
                      class="mb-2 carousel-card wi-190 he-190 wi-md-170 he-md-200 overflow-hidden d-flex tw-rounded-lg position-relative tw-z-1"
                    >
                      <img
                        class="h-100"
                        :src="
                          uriResources +
                          '/storage/images/' +
                          reward.images[0].name
                        "
                        alt=""
                      />
                      <img
                        v-if="reward.id === rewardBest[0].id"
                        class="position-absolute tw-top-0 tw-right-5 md:tw-right-7 wi-35 he-45 wi-md-50 he-md-55"
                        :src="imgBand"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div>
                      <p
                        class="tw-font-robotoBold tw-text-xs line-height-14 tw-text-gray-600 m-0"
                      >
                        {{ reward.name }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center mt-1">
                      <i
                        class="icon-maltas tw-text-xl d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
                      ></i>
                      <p
                        class="tw-font-roboto tw-text-xs line-height-20 m-0 tw-text-gray-500"
                      >
                        {{ reward.malts.toLocaleString().replace(",", ".") }}
                        maltas
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-2 mx-1 py-2 d-flex d-md-none justify-content-around align-items-center tw-rounded-lg tw-shadow-sm"
        >
          <i
            class="icon-maltas fs-30 d-inline-block ml-0 my-0 mr-2 tw-text-orange-300"
          ></i>
          <p class="tw-font-robotoBold tw-text-base line-height-20 m-0">
            Tienes
            {{
              Number(userStore.user.gamification_maltas)
                .toLocaleString()
                .replace(",", ".")
            }}
            maltas
          </p>
        </div>
        <div class="mt-3 d-flex justify-content-center">
          <button
            class="tw-bg-red-400 text-white tw-rounded-lg p-2 tw-border-0 wi-280 wi-md-280 font-weight-bold tw-font-roboto tw-text-base"
            @click="router.push({ name: 'gamificationRewards' })"
          >
            Ver más
          </button>
        </div>
      </div>
      <div
        class="tag wi-25 he-25 wi-md-40 he-md-40 rounded-circle bg-white mt-2 mr-2 d-flex justify-content-center align-items-center"
        @click="closeModal()"
        role="button"
      >
        <i
          class="icon-cerrar-simple tw-text-red-400 font-weight-bold tw-text-base fs-md-28"
        ></i>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.sub-container {
  perspective: 1px;
  width: 100%;
}

.tag {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .sub-container {
    width: 258px;
  }
}
</style>
