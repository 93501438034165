import { render, staticRenderFns } from "./CompArticleGalleryMasonry.vue?vue&type=template&id=88398196&scoped=true"
import script from "./CompArticleGalleryMasonry.vue?vue&type=script&setup=true&lang=js"
export * from "./CompArticleGalleryMasonry.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CompArticleGalleryMasonry.vue?vue&type=style&index=0&id=88398196&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88398196",
  null
  
)

export default component.exports