<template>
  <div class="card w-100">
    <div class="mt-3 font-weight-bold">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3 text-left d-block d-md-none mb-2">
            <img width="24" class="icon-event" :src="imgIconPoints" alt />
          </div>
          <div class="col-12 col-md-9">
            <h5 class="text-uppercase textColorPrimary tw-font-robotoBold tw-text-sm md:tw-text-lg">Tus concursos</h5>
          </div>
          <div class="col-md-3 text-right d-none d-md-block pl-1">
            <img width="24" class="icon-event" :src="imgIconPoints" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3 px-2 mt-3">
      <div class="col-12 mt-3" v-if="isLoading">
        <div class="text-center mt-3">
          <img src="@/assets/img/icons/loading.gif" alt="loading" />
        </div>
      </div>
      <div class="col-12 mt-3" v-else-if="lastCompetitions.length === 0">
        <div class="alert alert-warning" role="alert">
          No hay concursos disponibles.
        </div>
      </div>
      <div v-else class="col-12 px-1 px-md-2">
        <div class="card-1 bg-brown p-3 mb-3">
          <p class="mb-1 date">
            {{ lastCompetitions[0].end | moment("LL") }}
          </p>
          <p class="mb-0 desc">{{ lastCompetitions[0].name }}</p>
        </div>
        <div class="card-1 bg-yellow p-3 mb-3">
          <p class="mb-1 date">{{ lastCompetitions[1].end | moment("LL") }}</p>
          <p class="mb-0 desc">{{ lastCompetitions[1].name }}</p>
        </div>
      </div>
      <div class="col-12 text-right px-1 px-md-2 mt-3">
        <router-link to="/competitions" class="link">Ver concursos</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CompCompetitions",
  data() {
    return {
      isLoading: false,
      lastCompetitions: [],
      imgIconPoints: require("@/assets/img/icons/courses.png"),
    };
  },
  created() {
    this.isLoading = true;
    this.actGetCompetitions()
      .then(() => {
        this.lastCompetitions = this.competitions.splice(0, 2);
      })
      .catch((err) => {
        console.error("Error: ", err);
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapState("modCompetitions", ["competitions"]),
  },
  methods: {
    ...mapActions("modCompetitions", ["actGetCompetitions"]),
  },
};
</script>

<style scoped>
.card-1 {
  border-radius: 8px;
}

.bg-brown {
  background-color: #40140f;
}

.bg-brown .date {
  font-family: "RobotoBold", sans-serif;
  color: #fab23a;
  font-size: 10px;
  line-height: 10px;
}

.bg-brown .desc {
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
}

.bg-yellow {
  background-color: #fab23a;
}

.bg-yellow .date {
  font-family: "RobotoBold", sans-serif;
  color: #717171;
  font-size: 10px;
  line-height: 10px;
}

.bg-yellow .desc {
  color: #171717;
  font-size: 12px;
  line-height: 12px;
}

.btn-primary {
  border-radius: 8px;
}

.link {
  color: #222;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  font-weight: bold;
}
</style>