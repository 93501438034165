var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.banner)?_c('div',{staticClass:"cont-inner-banner",style:(_vm.backgroundLoading)}):_c('div',{staticClass:"component-banner bg-white tw-shadow-md bor-banner-1"},[_c('b-carousel',{ref:"myCarousel",attrs:{"id":"carousel-banner","interval":5000,"indicators":"","background":"transparent"}},_vm._l((_vm.banner.slides),function(slide){return _c('b-carousel-slide',{key:slide.id,scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"cont-inner-banner",style:(_vm.backgroundLoading),on:{"click":function($event){return _vm.redirectToLinkWithDataLayer(
                _vm.event,
                slide.url,
                _vm.category,
                slide.label_datalayer
              )}}},[_c('img',{staticClass:"slide-img",class:{
                'd-block': !slide.img_mobile,
                'd-none': slide.img_mobile,
                'd-md-block': slide.img_mobile,
                'slide-rounded-corner': _vm.roundedCorner,
              },attrs:{"loading":"lazy","src":_vm.uriResources + '/' + slide.img,"alt":"Diapositiva"}}),_c('img',{staticClass:"slide-img",class:{
                'd-none': !slide.img_mobile,
                'd-block': slide.img_mobile,
                'd-md-none': slide.img_mobile,
                'slide-rounded-corner': _vm.roundedCorner,
              },attrs:{"loading":"lazy","src":_vm.uriResources + '/' + slide.img_mobile,"alt":"Diapositiva"}}),_c('div',{staticClass:"slide-tag",class:{
                'd-none': _vm.legendMobileOutside,
                'd-md-block': _vm.legendMobileOutside,
                'd-block': !_vm.legendMobileOutside,
              }},[_c('h6',[_c('span',{staticClass:"badge tw-bg-red-350 tw-text-white"},[_vm._v(_vm._s(slide.tag))])])]),(slide.description)?_c('div',{staticClass:"slide-legend",class:{ 'legend-outside': _vm.legendMobileOutside }},[_c('h4',{staticClass:"tw-font-robotoBold tw-text-2xl md:tw-text-4xl"},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('p',{staticClass:"tw-mb-0 tw-text-sm"},[_vm._v(_vm._s(slide.description))])]):_vm._e()]),(
              slide.content_type_slide_id === 2 ||
              slide.content_type_slide_id === 4
            )?_c('div',{staticClass:"slide-play"},[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.showModal(slide)}}},[_c('span',{staticClass:"icon-play icon-pointer icon-xl icon-white"})])],1):_vm._e()]},proxy:true}],null,true)})}),1),_c('b-modal',{ref:"modal-video-player",attrs:{"size":"xl","centered":"","title":"qualification-video-banner","hide-footer":"","hide-header":""}},[_c('button',{staticClass:"close-modal tw-text-2xl tw-border-0 tw-text-red-400 tw-absolute tw-bg-transparent tw-z-1",on:{"click":_vm.hideModal}},[_c('span',{staticClass:"icon-cerrar-simple",on:{"click":_vm.hideModal}})]),_c('div',{staticClass:"container-video tw-w-full"},[_c('CompQualificationVideo',{attrs:{"idVideo":_vm.selectedSlide.id,"urlVideo":_vm.uriResources + '/' + _vm.selectedSlide.video,"title":_vm.selectedSlide.title}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }