import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  actions: {
    actGetRating: async (_, data) => {
      let response = await ApiBeerOffice.getRating(data);
      if (response.error) {
        return response.error;
      } else {
        return response;
      }
    },
  },
};
