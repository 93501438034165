<script setup>
import { storeToRefs } from "pinia";
import { useProfileStore } from "@/stores/profileStore";
import { capitalizeParagraphFirstLetter } from "@/helpers/textHelpers";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";
import CompFormTextInput from "@/components/form/CompFormTextInput.vue";
import { computed } from "vue";

const props = defineProps({
  data: Object,
  profileSaved: Boolean,
});

const emit = defineEmits(["update"]);

const { pronounsCollection, gendersCollection } = storeToRefs(
  useProfileStore()
);

const isPersonalEmailEmpty = computed(
  () => !props.data?.personal_email && !props.profileSaved
);
const isPhoneNumberEmpty = computed(
  () => !props.data?.phone_number && !props.profileSaved
);
const isNicknameEmpty = computed(
  () => !props.data?.nickname && !props.profileSaved
);
const isPronounEmpty = computed(
  () => !props.data?.pronoun && !props.profileSaved
);
const isGenderEmpty = computed(
  () => !props.data?.gender && !props.profileSaved
);
</script>

<template>
  <section
    id="basic-information"
    class="tw-flex tw-flex-col tw-gap-2 tw-p-1 tw-border tw-border-gray-300 tw-rounded-2xl"
  >
    <!-- Nickname -->
    <div class="tw-flex tw-flex-col tw-gap-2 tw-p-2">
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        ¿Cómo quieres que te llamemos?*
      </p>
      <comp-form-text-input
        :model-value="capitalizeParagraphFirstLetter(props.data.nickname)"
        @update="(value) => emit('update', { ...props.data, nickname: value })"
      />
      <span
        v-if="isNicknameEmpty"
        class="tw-font-robotoLight tw-text-sm tw-text-red-350"
        >* Por favor escribe como deseas que te llamemos</span
      >
    </div>

    <!-- Teléfono celular -->
    <div class="tw-flex tw-flex-col tw-gap-2 tw-p-2">
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        ¿Cuál es tu número de celular?*
      </p>
      <comp-form-text-input
        :modelValue="props.data.phone_number"
        @update="
          (value) => emit('update', { ...props.data, phone_number: value })
        "
      />
      <span
        v-if="isPhoneNumberEmpty"
        class="tw-font-robotoLight tw-text-sm tw-text-red-350"
        >* Por favor escribe tu número de celular</span
      >
    </div>

    <!-- Correo -->
    <div class="tw-flex tw-flex-col tw-gap-2 tw-p-2">
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        ¿Cuál es tu correo personal?*
      </p>
      <comp-form-text-input
        :model-value="props.data.personal_email"
        @update="
          (value) => emit('update', { ...props.data, personal_email: value })
        "
      />
      <span
        v-if="isPersonalEmailEmpty"
        class="tw-font-robotoLight tw-text-sm tw-text-red-350"
        >* Por favor escribe tu correo personal</span
      >
    </div>

    <!-- Género -->
    <div class="tw-flex tw-flex-col tw-gap-4 tw-p-2">
      <div class="tw-flex tw-justify-between tw-items-center">
        <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
          ¿Con qué género te identificas?
        </p>
        <p class="tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs">
          *Puedes seleccionar solo una opción
        </p>
      </div>
      <div class="tw-flex tw-justify-between tw-gap-2">
        <comp-button-custom-color
          class="tw-w-full"
          rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
          v-for="(item, idx) in gendersCollection"
          :key="idx"
          :text="item.name"
          :active="props.data.gender === item.id"
          active-color="green-500"
          text-active-color="white"
          @click.native="
            () => emit('update', { ...props.data, gender: item.id })
          "
        />
      </div>
      <span
        v-if="isGenderEmpty"
        class="tw-font-robotoLight tw-text-sm tw-text-red-350"
        >* Por favor selecciona tu género</span
      >
    </div>
    <!-- Género -->

    <!-- Pronombre-->
    <div class="tw-flex tw-flex-col tw-gap-4 tw-p-2">
      <div class="tw-flex tw-justify-between tw-items-center">
        <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
          ¿Con qué pronombre te identificas?
        </p>
        <p class="tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs">
          *Puedes seleccionar solo una opción
        </p>
      </div>
      <div class="tw-flex tw-justify-between tw-gap-2">
        <comp-button-custom-color
          class="tw-w-full"
          rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
          v-for="(item, idx) in pronounsCollection"
          :key="idx"
          :text="item.name"
          :active="props.data.pronoun === item.id"
          active-color="green-500"
          text-active-color="white"
          @click.native="
            () => emit('update', { ...props.data, pronoun: item.id })
          "
        />
      </div>
      <span
        v-if="isPronounEmpty"
        class="tw-font-robotoLight tw-text-sm tw-text-red-350"
        >* Por favor selecciona tu pronombre</span
      >
    </div>
  </section>
</template>

<style scoped></style>
