<script setup>
import { computed, getCurrentInstance } from "vue";

const { $isMobile } = getCurrentInstance().proxy;
const { $parent } = getCurrentInstance().proxy;
const emit = defineEmits(["selectAccordionItem", "open"]);

const props = defineProps({
  division: {
    type: Boolean,
    default: true,
  },
  openAccordionDesk: {
    type: Boolean,
    default: true,
  },
  openAccordionMobile: {
    type: Boolean,
    default: true,
  },
  border: {
    type: String,
  },
  padding: {
    type: String,
  },
  indexOfItem: {
    type: Number,
  },
  arrayLength: {
    type: Number,
  },
});

const lastItem = computed(() => props.indexOfItem === props.arrayLength - 1);

const toggleAccordionItem = (event) => {
  const parentProps = $parent.$props;
  $parent.$children.forEach((item) => {
    if (item.$el !== event.currentTarget.parentNode) {
      item.$el.classList.remove("active");
    } else {
      parentProps.alwaysOpenOne
        ? item.$el.classList.add("active")
        : item.$el.classList.toggle("active");
    }
  });
};

const toggleAndEmit = ($event) => {
  toggleAccordionItem($event);
  emit("selectAccordionItem");
};
</script>
<template>
  <div
    class="accordion_item tw-grid tw-grid-rows-row2_fit_first"
    :class="[props.border, props.padding]"
  >
    <div
      class="tw-grid tw-grid-cols-col2_fit_last tw-gap-2 tw-cursor-pointer"
      @click="
        ($isMobile() && props.openAccordionMobile) || props.openAccordionDesk
          ? toggleAndEmit($event)
          : emit('selectAccordionItem')
      "
    >
      <slot name="mainInfo"></slot>
      <div id="arrows" class="tw-h-full tw-flex tw-items-center">
        <i
          v-if="
            ($isMobile() && props.openAccordionMobile) ||
            props.openAccordionDesk
          "
          class="icon-flecha-abajo tw-text-red-350 tw-inline-block tw-text-xs tw-leading-none tw-transition-all tw-duration-200 tw-transform"
        ></i>
        <i
          v-else
          class="icon-flecha-der tw-text-red-350 tw-inline-block tw-text-xs tw-leading-none"
        ></i>
      </div>
    </div>
    <div
      class="accordion_description tw-max-h-0 tw-overflow-hidden tw-relative"
    >
      <slot name="secondaryInfo"></slot>
    </div>
    <hr v-show="props.division" class="tw-w-full" v-if="!lastItem" />
  </div>
</template>

<style scoped>
.accordion_item.active {
  gap: 0.75rem;
}

.accordion_item .accordion_description {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion_item.active .accordion_description {
  height: auto;
  max-height: max-content;
  transition: max-height 500ms ease-in-out;
}

.accordion_item.active > * i {
  transform: rotate(180deg);
}
</style>
