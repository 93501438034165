<script setup>
import config from "@/services/config";
import { pushLink } from "@/helpers/linkHelpers";
import { removeHTMLTagsFromText } from "@/helpers/textHelpers";
import { computed } from "vue";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  title: {
    type: String,
    default: "Título de la tarjeta",
  },
  image: {
    type: String,
  },
  description: {
    type: String,
  },
  description_2: {
    type: String,
  },
  haveButtons: {
    type: Boolean,
    default: false,
  },
  // Array of objects with text property, can be up to 2
  buttons: {
    type: Array,
    default: () => [
      {
        text: "Botón 1",
        link: null,
      },
      {
        text: "Botón 2",
        link: null,
      },
    ],
  },
  imageRightDesk: {
    type: Boolean,
    default: false,
  },
});

const cardImage = computed(() => {
  const protocolRegex = /^(http?|https?):\/\//i;
  if (protocolRegex.test(props.image)) {
    return props.image;
  } else if (props.image.includes("assets")) {
    return require(`@/${props.image}`);
  }
  return `${uriResources}/${props.image}`;
});
</script>

<template>
  <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4 lg:tw-gap-10">
    <div
      class="lg:tw-col-span-7 tw-flex tw-flex-col tw-gap-4"
      :class="props.imageRightDesk ? 'md:tw-order-last' : ''"
    >
      <p class="tw-m-0 tw-font-robotoBold tw-text-2xl tw-block lg:tw-hidden">
        {{ title }}
      </p>
      <div
        class="tw-flex tw-items-center tw-justify-center tw-aspect-w-12 tw-aspect-h-6 tw-h-full"
      >
        <img
          :src="cardImage"
          class="tw-object-contain tw-max-h-full tw-max-w-[auto] tw-rounded-2xl lg:tw-rounded-3xl"
          alt=""
        />
        <!--
          class="tw-object-contain tw-w-auto tw-h-auto tw-max-w-full tw-max-h-full tw-rounded-2xl lg:tw-rounded-3xl"
        -->
      </div>
    </div>
    <div
      class="lg:tw-col-span-5 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-justify-end"
    >
      <p class="tw-m-0 tw-font-robotoBold tw-text-4xl tw-hidden lg:tw-block">
        {{ props.title }}
      </p>
      <p
        class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"
        v-if="props.description"
      >
        {{ removeHTMLTagsFromText(props.description) }}
      </p>
      <p
        class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"
        v-if="props.description_2"
      >
        {{ removeHTMLTagsFromText(props.description_2) }}
      </p>
      <div class="tw-flex tw-gap-4 tw-justify-between" v-if="haveButtons">
        <div
          class="tw-btn-primary-red"
          @click="pushLink(props.buttons[0].link, props.buttons[0].datalayer)"
          v-if="buttons[0] && buttons[0].link !== 'undefined'"
        >
          <p class="tw-m-0">{{ props.buttons[0].text }}</p>
        </div>
        <div
          class="tw-btn-secondary-white"
          v-if="buttons[1] && buttons[1].link !== 'undefined'"
          @click="pushLink(props.buttons[1].link, props.buttons[1].datalayer)"
        >
          <p class="tw-m-0">{{ props.buttons[1].text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
