<script setup>
import { computed } from "vue";
import config from "@/services/config";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const color = computed(() => {
  return props.item.color;
});
</script>
<template>
  <div
    class="parent-card lg:tw-relative tw-h-full tw-flex tw-items-center tw-gap-2 tw-p-3 lg:tw-p-0 lg:tw-bg-transparent tw-rounded-2xl"
  >
    <div class="lg:tw-absolute lg:tw-h-full tw-flex tw-items-center lg:tw-py-3">
      <img
        v-if="item.image_icon"
        :src="uriResources + '/' + item.image_icon"
        class="tw-object-contain tw-w-16 lg:tw-w-32"
        alt=""
      />
      <img
        v-else
        :src="uriResources + '/' + item.image"
        class="tw-object-contain tw-w-16 lg:tw-w-32"
        alt=""
      />
    </div>
    <div
      class="child-card tw-flex tw-flex-col tw-justify-center tw-h-full tw-gap-2 tw-rounded-2xl lg:tw-py-6 lg:tw-pl-20 lg:tw-pr-10 lg:tw-ml-14"
    >
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-xl tw-text-white"
      >
        {{ item.name }}
      </p>
      <p
        class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-white tw-line-clamp-3"
      >
        {{ item.description }}
      </p>
    </div>
    <i
      class="icon-flecha-der tw-text-white tw-ml-1 tw-text-base lg:tw-hidden"
    ></i>
  </div>
</template>

<style scoped>
@media (max-width: 1024px) {
  .parent-card {
    background-color: v-bind(color);
  }
}

@media (min-width: 1024px) {
  .child-card {
    background-color: v-bind(color);
  }
}
</style>
