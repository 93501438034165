<script setup>
import { getCurrentInstance } from "vue";
import CompGeneralTooltip from "@/components/general/CompGeneralTooltip.vue";

const { $isMobile } = getCurrentInstance().proxy;

const props = defineProps({
  color: {
    type: String,
    default: "#37487F",
  },
  progress: {
    type: Number,
    default: 0,
  },
  points: {
    type: Number,
    default: 0,
  },
  type: {
    type: String,
    default: "general",
  },
});

const percentageTooltipText =
  "Es el porcentaje total de actividades que has completado a lo largo del año dentro del tour de gente.";

const pointsTooltipText =
  "Es el puntaje que has ganado por realizar actividades a lo largo del año dentro del tour de gente.";
</script>
<template>
  <div class="tw-my-5 lg:tw-my-10">
    <hr class="tw-w-full tw-hidden lg:tw-block" />
    <div
      class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-8 lg:tw-px-4"
    >
      <div
        class="tw-col-span-2 lg:tw-col-span-1 tw-flex tw-items-center tw-justify-between"
      >
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-3xl"
          :style="$isMobile() ? '' : 'color:' + props.color"
        >
          {{
            props.type === "general"
              ? "Progreso general"
              : props.type === "stage"
              ? "Progreso de la etapa"
              : "Progreso de la estación"
          }}
        </p>
        <img
          src="@/assets/img/peopleTour/icons/peopleTourIcon.svg"
          class="tw-max-h-5 lg:tw-hidden"
          alt=""
        />
      </div>
      <div
        class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-gap-2"
      >
        <p class="tw-m-0 tw-font-robotoMedium tw-text-xs lg:tw-text-lg">
          Tu porcentaje
        </p>
        <div class="tw-flex tw-gap-2 tw-items-center">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl"
            :style="`color: ${props.color}`"
          >
            {{ props.progress.toFixed(1) }}
          </p>
          <div
            class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-2"
          >
            <i
              class="icon-percentage-outline tw-golden-black-border-icon lg:tw-text-xl"
            ></i>
          </div>
          <comp-general-tooltip
            :tooltip-text="percentageTooltipText"
            :color="props.color"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-end tw-gap-2"
      >
        <p class="tw-m-0 tw-font-robotoMedium tw-text-xs lg:tw-text-lg">
          Tu puntaje
        </p>
        <div class="tw-flex tw-gap-2 tw-items-center">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl"
            :style="'color:' + props.color"
          >
            {{ props.points }}
          </p>
          <div
            class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-2"
          >
            <i class="icon-star tw-golden-black-border-icon lg:tw-text-xl"></i>
          </div>
          <comp-general-tooltip
            :tooltip-text="pointsTooltipText"
            :color="props.color"
          />
        </div>
      </div>
    </div>
    <hr class="tw-w-full tw-hidden lg:tw-block" />
  </div>
</template>
