<script setup>
import { useUserStore } from "@/stores/userStore";
import { toRefs } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";

const { user } = toRefs(useUserStore());

const pushDataLayerEvent = (text) => {
  pushDataLayer({
    event_category: "Juega",
    event_action: "Submenu",
    component_name: "Boton",
    element_text: text,
  });
};
</script>

<template>
  <div class="overflow-hidden">
    <div
      class="tw-grid tw-grid-flow-col tw-gap-3 tw-overflow-x-scroll py-2 px-1"
      id="scroll-container"
    >
      <router-link
        :to="{ name: 'gamificationProfile' }"
        @click.native="pushDataLayerEvent('Perfil')"
        class="btn tw-rounded-md tw-shadow-sm d-inline-flex justify-content-center align-items-center"
      >
        <i class="icon icon-gamiperfil tw-text-xl d-inline-block mr-2"></i>
        <p
          class="m-0 d-inline-block tw-font-roboto tw-font-black tw-text-gray-500 tw-text-sm line-height-md-16"
        >
          Perfil
        </p>
      </router-link>
      <router-link
        :to="{ name: 'gamificationMaltsWelcome' }"
        @click.native="pushDataLayerEvent('Maltas')"
        class="btn tw-rounded-md tw-shadow-sm d-inline-flex justify-content-center align-items-center"
        v-if="user.tapit_feature === 1"
      >
        <i class="icon icon-gamimaltas tw-text-xl d-inline-block mr-2"></i>
        <p
          class="m-0 d-inline-block tw-font-roboto tw-font-black tw-text-sm line-height-md-16 tw-text-gray-500"
        >
          Maltas
        </p>
      </router-link>
      <router-link
        :to="{ name: 'gamificationMissions' }"
        @click.native="pushDataLayerEvent('Misiones')"
        class="btn tw-rounded-md tw-shadow-sm d-inline-flex justify-content-center align-items-center"
      >
        <i class="icon icon-gamimisiones tw-text-xl d-inline-block mr-2"></i>
        <p
          class="m-0 d-inline-block tw-font-roboto tw-font-black tw-text-sm line-height-md-16 tw-text-gray-500"
        >
          Misiones
        </p>
      </router-link>
      <router-link
        :to="{ name: 'gamificationCollections' }"
        @click.native="pushDataLayerEvent('Insignias')"
        class="btn tw-rounded-md tw-shadow-sm d-inline-flex justify-content-center align-items-center"
      >
        <i class="icon icon-gaminsignias tw-text-xl d-inline-block mr-2"></i>
        <p
          class="m-0 d-inline-block tw-font-roboto tw-font-black tw-text-sm line-height-md-16 tw-text-gray-500"
        >
          Insignias
        </p>
      </router-link>
      <router-link
        :to="{ name: 'gamificationRewards' }"
        @click="pushDataLayerEvent('Recompensas')"
        class="btn tw-rounded-md tw-shadow-sm d-inline-flex justify-content-center align-items-center"
      >
        <i class="icon icon-gamirecompensas tw-text-xl d-inline-block mr-2"></i>
        <p
          class="m-0 d-inline-block tw-font-roboto tw-font-black tw-text-sm line-height-md-16 tw-text-gray-500"
        >
          Recompensas
        </p>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.router-link-exact-active .icon:before {
  color: white;
}

.router-link-exact-active p {
  color: white;
}

.router-link-exact-active {
  background-color: var(--red-400);
}
</style>
