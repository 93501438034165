import { reactive, ref } from "vue";
import config from "@/services/config";

export function useGoFlexRequestTable(teamRequests) {
  const uriResources = config.beerOfficeURL;
  const selectedItems = ref([]);
  const pendingRequestsLength = ref(
    teamRequests.filter((request) => request.status === "PENDING").length
  );

  const catalogOption = reactive({
    off: "Tiempo +Off",
    flex: "Flex Work",
  });

  const statusName = (name) => {
    const map = new Map([
      ["PENDING", "Pendiente"],
      ["APPROVED", "Aprobada"],
      ["REJECTED", "Rechazada"],
      ["CANCELLED", "Cancelada"],
      ["COMPLETED", "Finalizada"],
    ]);
    return map.get(name) || "Pendiente";
  };

  const statusColor = (name) => {
    const map = new Map([
      ["PENDING", "yellow-300"],
      ["APPROVED", "green-450"],
      ["REJECTED", "red-350"],
      ["CANCELLED", "red-350"],
      ["COMPLETED", "green-450"],
    ]);
    return map.get(name) || "yellow-300";
  };

  const statusTextColor = (name) => {
    const map = new Map([
      ["PENDING", "black"],
      ["APPROVED", "white"],
      ["REJECTED", "white"],
      ["CANCELLED", "white"],
      ["COMPLETED", "white"],
    ]);
    return map.get(name) || "black";
  };

  const avatarImage = (request) => {
    if (request.core_employee_is_predefined_avatar === 1) {
      return `${uriResources}/${request.core_employee_predefined_avatar_image}`;
    } else {
      return request.core_employee_custom_avatar_image;
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.value.length === pendingRequestsLength.value) {
      selectedItems.value.splice(0, selectedItems.value.length);
    } else {
      teamRequests.forEach((item) => {
        if (
          item.status !== "APPROVED" &&
          item.status !== "REJECTED" &&
          !selectedItems.value.includes(item.request_id)
        ) {
          selectedItems.value.push(item.request_id);
        }
      });
    }
  };

  const toggleSelectItem = (id) => {
    const index = selectedItems.value.indexOf(id);
    if (index === -1) {
      selectedItems.value.push(id);
    } else {
      selectedItems.value.splice(index, 1);
    }
  };

  return {
    selectedItems,
    pendingRequestsLength,
    catalogOption,
    statusName,
    statusColor,
    statusTextColor,
    avatarImage,
    toggleSelectAllItems,
    toggleSelectItem,
  };
}
