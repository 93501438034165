<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router/composables";
import CompPeopleTourRewardCard from "@/components/peopleTour/CompPeopleTourRewardCard.vue";

const router = useRouter();

const props = defineProps({
  rewards: {
    type: Array,
    required: true,
  },
  stageName: {
    type: String,
    default: "Tour de Gente",
  },
});

const rewardsByStageDueDate = computed(() => {
  if (!props.rewards) return [];
  if (props.rewards.length > 1) {
    return [...props.rewards].sort((a, b) => {
      return (
        new Date(a.people_tour_stage.due_date) -
        new Date(b.people_tour_stage.due_date)
      );
    });
  } else {
    return props.rewards;
  }
});

const prodVisible = computed(() => {
  return window.innerWidth < 768 ? 2 : 5;
});
</script>

<template>
  <div class="tw-flex-col tw-gap-3 lg:tw-gap-6">
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-8">
      <div class="lg:tw-col-span-1 tw-order-1 lg:tw-order-1">
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-3xl tw-text-center lg:tw-text-left"
        >
          {{ `Premios - ${props.stageName}` }}
        </p>
      </div>
      <div
        class="lg:tw-col-span-1 tw-order-3 lg:tw-order-2 tw-flex tw-justify-center lg:tw-justify-end"
      >
        <div
          class="tw-btn-primary-red tw-w-9/12 lg:tw-w-max tw-py-2 tw-flex tw-gap-2"
          @click="
            router.push({
              name: 'peopleTourRewards',
            })
          "
        >
          <i class="icon-regalo tw-text-lg" />
          <p class="tw-m-0 lg:tw-text-base">
            Reclama tus premios del Tour de Gente
          </p>
        </div>
      </div>
      <div class="lg:tw-col-span-2 tw-order-2 lg:tw-order-3">
        <div
          class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-5 tw-gap-3 lg:tw-gap-6"
        >
          <comp-people-tour-reward-card
            v-for="(product, idx) in rewardsByStageDueDate.slice(
              0,
              prodVisible
            )"
            :key="idx"
            :product="product"
          />
        </div>
      </div>
    </div>
  </div>
</template>
