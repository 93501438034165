import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    categoriesBrands: [],
    itemBrand: {},
    articlesBrands: {},
    //categoriesDiversityInclusion: [],
    /*categoryArticles: {},
		setArticle: {},*/
  },
  mutations: {
    setCategoriesBrands: (state, categoriesBrands) => {
      state.categoriesBrands = categoriesBrands;
    },
    setItemBrand: (state, itemBrand) => {
      state.itemBrand = itemBrand;
    },
    setArticlesBrands: (state, articlesBrands) => {
      state.articlesBrands = articlesBrands;
    },
  },
  actions: {
    actGetBrands: async ({ commit }) => {
      let response = await ApiBeerOffice.getCategoriesBrands();
      if (response.error) {
        return response.error;
      }
      commit("setCategoriesBrands", response.data.brands);
      return true;
    },
    actGetItemBrand: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getArticleBrand(id);
      if (response.error) {
        return response.error;
      }
      commit("setItemBrand", response.data.brand);
      return true;
    },
    actGetArticlesBrands: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getArticlesBrands(id);
      if (response.error) {
        return response.error;
      }
      commit("setArticlesBrands", response.data);
      return true;
    },
  },
};
