<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 py-0">
        <SecondaryNavbar :title="competition.name" />
      </div>
    </div>

    <div class="row px-3 px-md-0">
      <div class="col-12 col-md-6 text-center">
        <img
          class="mx-auto tw-w-full"
          src="../../assets/img/icons/icon-golden-trophy.svg"
          style="width: 90%"
          alt="cup"
        />
      </div>
      <!-- .col -->
      <div class="col-12 col-md-6 pb-2 mt-1 mt-lg-5">
        <div class="card-congrats py-4 px-3 text-center">
          <p class="title">¡Gracias por participar!</p>
          <div
            class="cont-points d-flex align-items-center justify-content-center"
          >
            <div v-if="competitionInfo.ranking.length === 0">
              <p class="text-red w-100 text-bold mb-0">
                Espera al cierre del concurso
              </p>
            </div>
            <div v-else>
              <p class="text-red w-100 text-bold mb-0">
                Obtuviste un puntaje de:
                {{ competitionInfo.ranking[0].score }}
              </p>
            </div>
          </div>
        </div>
        <!-- .card-congrats -->
        <div class="cont-desc mt-3 mt-md-4">
          <p>
            Recuerda que este concurso cierra el
            <span class="text-red text-bold">{{ competition.end }}</span>
          </p>
        </div>
        <!-- .cont-desc -->
        <div class="col-12 col-md-8 col-lg-6 ml-auto mb-5">
          <router-link
            :to="'/competition/competition/' + competition.id"
            class="btn btn-block btn-outline-danger divBTN text-bold mt-4"
            >Finalizar</router-link
          >
        </div>
        <!-- .col -->
      </div>
      <!-- .col -->
    </div>
    <!-- .row -->
  </div>
</template>

<script>
import config from "@/services/config";
import { mapActions, mapState } from "vuex";

import SecondaryNavbar from "@/components/general/SecondaryNavbar";

export default {
  name: "CompetitionCongrats",
  components: {
    SecondaryNavbar,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  mounted() {
    window.dataLayer.push({
      event: "'trackEvent",
      target: "Sub-secciones",
      action: "concursos-gracias-por-participar",
      "target-properties": "clic",
      value: 5000,
    });
  },
  beforeMount() {
    this.actGetCompetition(this.competition.id);

    // Funcion que recibe ID concurso y ID User
    this.data = { id: this.competition.id, idUser: this.user.id };
    this.actGetCompetitionByIdUser(this.data);
  },
  computed: {
    ...mapState("modCompetitions", ["competition", "competitionInfo"]),
    ...mapState("modCore", ["user"]),
  },
  methods: {
    ...mapActions("modCompetitions", [
      "actGetCompetition",
      "actGetCompetitionByIdUser",
    ]),
  },
};
</script>

<style scoped>
.text-bold {
  font-family: "RobotoBold", sans-serif;
}

.text-red {
  color: #d82b2b;
}

/**Card congrats */
.card-congrats {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.card-congrats .title {
  font-size: 24px;
  color: #454545;
  font-family: "RobotoLight", sans-serif;
}

.card-congrats .text-red {
  font-size: 25px;
}

.card-congrats span {
  color: #bababa;
}

/**Cont desc */
.cont-desc p {
  font-size: 12px;
  color: #808080;
}

@media (min-width: 768px) {
  .card-congrats .title {
    font-size: 34px;
  }

  .card-congrats .text-red {
    font-size: 35px;
  }

  .cont-desc p {
    font-size: 22px;
  }
}
</style>
