import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    userCanAccessGamification: false,
    //modalHowItWork: true,
    howItWorkSlides: [],
    avatarElements: [],
    predefinedAvatars: [],
    missions: [],
    rewards: [],
    reward: {},
    rewardsStock: [],
    redeem: null,
    history: [],
    userRanks: [],
    departments: {},
    cities: {},
    contactInfo: [],
    success: null,
    tapitTransactions: [],
    tapitVerifiedState: null
  },
  mutations: {
    setUserCanGamification: (state, userState) => {
      state.userCanAccessGamification = userState;
    },
    setModalHowItWork: (state, stateModal) => {
      state.modalHowItWork = stateModal;
    },
    setHowItWorkSlides: (state, slides) => {
      state.howItWorkSlides = slides;
    },
    setAvatarElements: (state, elements) => {
      state.avatarElements = elements;
    },
    setPredefinedAvatars: (state, avatars) => {
      state.predefinedAvatars = avatars;
    },
    setGamiMissions: (state, missions) => {
      state.missions = missions;
    },
    setGamiRewards: (state, rewards) => {
      state.rewards = rewards;
    },
    setGamiReward: (state, reward) => {
      state.reward = reward;
    },
    setGamiRewardsStock: (state, rewardsStock) => {
      state.rewardsStock = rewardsStock;
    },
    setRedeemGamiRewards: (state, redeem) => {
      state.redeem = redeem;
    },
    setGamiRewardsHistory: (state, history) => {
      state.history = history;
    },
    setGamiUserRanks: (state, userRanks) => {
      state.userRanks = userRanks;
    },
    setDepartments: (state, departments) => {
      state.departments = departments;
    },
    setCities: (state, cities) => {
      state.cities = cities;
    },
    setContactInfo: (state, contactInfo) => {
      state.contactInfo = contactInfo;
    },
    emptyRewardsStock: state => {
      state.rewardsStock = [];
    },
    setRedeemNull: state => {
      state.redeem = null;
    },
    setSuccessExchangeState: (state, response) => {
      state.success = response;
    },
    setTapitTransactions: (state, transactions) => {
      state.tapitTransactions = transactions;
    },
    setTapitVerifiedState: (state, verifiedState) => {
      state.tapitVerifiedState = verifiedState;
    }
  },
  actions: {
    /*actHideModalHowItWork: ({ commit }, data) => {
      commit("setModalHowItWork", false);
      return true;
    },*/
    actChangeGamificationUserState: async ({ commit }) => {
      let response = await ApiBeerOffice.updateGamificationUserState();
      if (response.error) {
        return response.error;
      }
      commit("setUserCanGamification", true);
      return true;
    },
    actGetGamificationHelp: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamificationHowItWorkSlides();
      if (response.error) {
        return response.error;
      }
      commit("setHowItWorkSlides", response.data);
      return true;
    },
    actAvatarElements: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamificationAvatarElements();
      if (response.error) {
        return response.error;
      }

      commit("setAvatarElements", response.data);
      return response.data;
    },
    actGamiPredefinedAvatars: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamiPredefinedAvatars();
      if (response.error) {
        return response.error;
      }
      commit("setPredefinedAvatars", response.data);
      return response.data;
    },
    actGamiMissions: async ({ commit }, level) => {
      let response = await ApiBeerOffice.getGamiMissions(level);
      if (response.error) {
        return response.error;
      }
      commit("setGamiMissions", response.data);
    },
    actGamiRewards: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamiRewards();
      if (response.error) {
        return response.error;
      }
      commit("setGamiRewards", response.data);
    },
    actGamiRewardsNew: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getGamiRewardsNew(id);
      if (response.error) {
        return response.error;
      }
      commit("setGamiReward", response.data);
    },
    actGamiRewardsStock: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getGamiRewardsStock(data);
      if (response.error) {
        return response.error;
      }
      commit("setGamiRewardsStock", response.data);
    },
    actRedeemGamiRewards: async ({ commit }, data) => {
      let response = await ApiBeerOffice.postGamiRewards(data);
      if (response.error) {
        return response.error;
      }
      commit("setRedeemGamiRewards", response);
    },
    actGamiRewardsHistory: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamiRewardsHistory();
      if (response.error) {
        return response.error;
      }
      commit("setGamiRewardsHistory", response.data);
    },
    actGamiUserRanks: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getGamiUserRanks(id);
      if (response.error) {
        return response.error;
      }
      commit("setGamiUserRanks", response.data);
    },
    actGamiFormDepartments: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamiFormDepartments();
      if (response.error) {
        return response.error;
      }
      commit("setDepartments", response.data);
    },
    actGamiFormCities: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getGamiFormCities(id);
      if (response.error) {
        return response.error;
      }
      commit("setCities", response.data);
    },
    actGamiFormContactInfo: async ({ commit }) => {
      let response = await ApiBeerOffice.getGamiFormContactInfo();
      if (response.error) {
        return response.error;
      }
      commit("setContactInfo", response.data);
    },
    actPostMaltsTapitExchange: async ({ commit }, malts) => {
      let response = await ApiBeerOffice.postMaltsTapitExchange(malts);

      if (response.error) {
        return response.error;
      }
      commit("setSuccessExchangeState", response.status);
    },
    actGetTapitTransactions: async ({ commit }) => {
      let response = await ApiBeerOffice.getTapitTransactions();
      if (response.error) {
        return response.error;
      }
      commit("setTapitTransactions", response.data);
    },
    actGetTapitVerifyIdNumber: async ({ commit }, token) => {
      let response = await ApiBeerOffice.getTapitVerifyIdNumber(token);
      if (response.error) {
        return response.error;
      }
      const { status } = response.data;
      commit("setTapitVerifiedState", status);
    }
  }
};
