import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  newsPostsUri: "/posts",
};

export const useNewsStore = defineStore("news", () => {
  const news = ref([]);
  const featuredNews = ref([]);
  const otherNews = ref([]);
  const totalNews = ref(0);

  async function actGetNews() {
    const response = await AxiosBeerOffice.get(uri.newsPostsUri, true, {});
    if (response.error) return response.error;
    const { data: newsData } = response;
    news.value = newsData.data;
    return true;
  }

  async function actGetFeaturedNews() {
    const response = await AxiosBeerOffice.get(
      uri.newsPostsUri + `?filter={"featured": 1}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data: newsData } = response;
    featuredNews.value = newsData.data;
    return true;
  }

  async function actGetOtherNews(data) {
    const { page, per_page } = data;
    const response = await AxiosBeerOffice.get(
      uri.newsPostsUri +
        `?filter={"featured":0}&page=${page}&per_page=${per_page}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data: newsData } = response;
    totalNews.value = newsData.total;
    if (data.page === 1) {
      otherNews.value = newsData.data;
    } else {
      otherNews.value = [...otherNews.value, ...newsData.data];
    }
    return response;
  }

  return {
    news,
    featuredNews,
    otherNews,
    totalNews,
    actGetNews,
    actGetFeaturedNews,
    actGetOtherNews,
  };
});
