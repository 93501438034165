<script setup>
import { ref, onBeforeMount } from "vue";
import { usePeopleTourStore } from "@/stores/peopleTourStore";
import { pushLink } from "@/helpers/linkHelpers";
import { getBand } from "@/helpers/userHelpers";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router/composables";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGeneralTopCard from "@/components/cards/CompGeneralTopCard.vue";
import CompPeopleTourProgress from "@/components/peopleTour/CompPeopleTourProgress.vue";
import CompPeopleTourStagesStations from "@/components/peopleTour/CompPeopleTourStagesStations.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompBPModal from "@/components/peopleTour/CompBPModal.vue";
import SkeletonCompGeneralTopCard from "@/components/cards/skeletons/SkeletonCompGeneralTopCard.vue";
import SkeletonCompPeopleTourProgress from "@/components/peopleTour/skeletons/SkeletonCompPeopleTourProgress.vue";
import SkeletonCompPeopleTourStagesStations from "@/components/peopleTour/skeletons/SkeletonCompPeopleTourStagesStations.vue";
import CompPeopleTourGamificationPreview from "@/components/peopleTour/CompPeopleTourGamificationPreview.vue";
import { useUserStore } from "@/stores/userStore";

const router = useRouter();
let isBPModal = ref(false);
const errorBPModal = ref(false);
const errorBPMessage = ref("");
const isLoading = ref(false);
const {
  mainTourData,
  currentBP,
  currentStageData,
  BPList,
  stages,
  stations,
  rewardsList,
} = storeToRefs(usePeopleTourStore());
const { user } = storeToRefs(useUserStore());
const band = getBand(user.value);
const { actGetUserById } = useUserStore();

const {
  emptyCurrentStageData,
  getBPList,
  getStagesList,
  getRewardsRandom,
  getTourData,
  postBP,
} = usePeopleTourStore();

function showBPModal() {
  isBPModal.value = true;
}
function hideBPModal() {
  isBPModal.value = false;
}

const selectBP = async (bp) => {
  const response = await postBP(user.value.id_sharp, bp.field_sharp);
  if (response !== false) {
    currentBP.value = bp;
  }
  hideBPModal();
};

const checkBPequivalence = async () => {
  await getBPList(user.value.id_sharp);
  if (
    user.value.business_partner_id_sharp === null &&
    currentBP.value == null
  ) {
    errorBPMessage.value = "No tienes BP asignado, contáctate con soporte.";
    errorBPModal.value = true;
  } else if (
    currentBP.value.sharp_id !== user.value.business_partner_id_sharp
  ) {
    const res = await postBP(
      user.value.id_sharp,
      user.value.business_partner_id_sharp
    );
    if (res.status === "error") {
      errorBPMessage.value = "El BP asignado no existe, contáctate con soporte";
      errorBPModal.value = true;
    }
  }
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetUserById(user.value.id);
  if (currentStageData.value) {
    emptyCurrentStageData();
  }
  await checkBPequivalence();
  await getBPList(user.value.id_sharp);
  // currentBP.value.name == null
  //   ? (isBPModal.value = true)
  //   : (isBPModal.value = false);
  await getStagesList();
  await getRewardsRandom(5);
  await getTourData(user.value.id_sharp, band);

  isLoading.value = false;
});
</script>

<template>
  <div>
    <general-modal
      :hide-button-support="true"
      v-if="isBPModal"
      modal-name="changeBPModal"
      :is-show-modal="isBPModal"
      :modalWidth="{ md: 500, lg: 600 }"
      :maxHeight="{ base: 700, md: 700, lg: 800 }"
      @close="hideBPModal"
      :closable="currentBP.name != null"
    >
      <template #title>Business Partner</template>
      <comp-b-p-modal
        @changeBP="selectBP($event)"
        :options="BPList"
        :default-option="currentBP?.name"
      />
    </general-modal>
    <general-modal
      :hide-button-support="true"
      v-if="errorBPModal"
      modal-name="errorBPModal"
      :is-show-modal="errorBPModal"
      :modalWidth="{ md: 500, lg: 600 }"
      :maxHeight="{ base: 700, md: 700, lg: 800 }"
      @close="router.push({ path: '/home' })"
    >
      <div class="tw-flex tw-justify-center tw-items-center tw-gap-4">
        <img src="@/assets/img/modal/modal-error.svg" alt="" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-red-350"
        >
          LO SENTIMOS
        </p>
      </div>
      <p class="tw-text-center tw-font-roboto tw-text-lg lg:tw-text-xl">
        {{
          errorBPMessage
        }}
      </p>
    </general-modal>
    <mobile-bar title="Tour de Gente" />
    <title-bar title="Tour de Gente" />

    <main class="tw-container-general lg:-tw-mt-10" v-if="!isLoading">
      <section class="lg:tw-flex lg:tw-justify-end tw-mb-5 lg:tw-mb-8">
        <div
          class="tw-btn-general-shape tw-border tw-border-black lg:tw-w-max"
          :class="currentBP ? 'tw-cursor-auto' : ''"
          @click="currentBP ? null : showBPModal()"
        >
          <p class="tw-m-0">
            <span class="tw-font-robotoBold">Tu BP:</span>
            {{ currentBP ? currentBP.name : "No tienes BP Asignado" }}
          </p>
        </div>
      </section>
      <section>
        <comp-general-top-card
          :title="mainTourData.title"
          :description="mainTourData.description.processed"
          have-buttons
          :buttons="[
            {
              id: 0,
              text: `${mainTourData?.link?.title}`,
              link: `${mainTourData?.link?.uri}`,
              datalayer: {
                event_category: 'Tour de Gente',
                event_action: 'Conoce nuestro Tour',
                event_label: `${mainTourData?.link?.title}`,
                component_name: 'Click',
              },
            },
          ]"
          :image="mainTourData.image"
        />
        <comp-people-tour-progress
          :progress="mainTourData.total_progress"
          :points="mainTourData.total_points"
        />
      </section>
      <section class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
        <comp-people-tour-stages-stations
          title="Tus etapas"
          :icon="require('@/assets/img/peopleTour/icons/peopleTourIcon.svg')"
          :stages="stages"
        />
        <comp-people-tour-stages-stations
          title="Tus estaciones"
          :icon="require('@/assets/img/peopleTour/icons/peopleTourIcon.svg')"
          :stages="stations"
        />
        <comp-people-tour-gamification-preview :rewards="rewardsList" />
      </section>
      <aside
        class="tw-mt-4 lg:tw-mt-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-col2_fit_last tw-gap-2 lg:tw-gap-4"
      >
        <p
          class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-self-center"
        >
          {{ mainTourData?.field_csat_descripcion }}
        </p>
        <button
          class="tw-btn-primary-red tw-flex tw-gap-2"
          @click="pushLink(mainTourData?.field_csat_enlace?.uri)"
        >
          <i class="icon-heart tw-text-white" />
          <span class="tw-m-0">{{
            mainTourData?.field_csat_enlace?.title
          }}</span>
        </button>
      </aside>
    </main>
    <div class="tw-container-general" v-else>
      <skeleton-comp-general-top-card />
      <skeleton-comp-people-tour-progress />
      <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
        <skeleton-comp-people-tour-stages-stations />
        <skeleton-comp-people-tour-stages-stations />
      </div>
    </div>
  </div>
</template>
