<script setup>
import { ref, onBeforeMount } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import CompDetailStatusOrderProduct from "@/components/ecommerce/CompDetailStatusOrderProduct";
import CompDetailOrder from "@/components/ecommerce/CompDetailOrder";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const isLoading = ref(false);
const actualOrder = ref({});

const changeActualOrder = (indexOrder) => {
  actualOrder.value = ecommerceStore.ordersPending[indexOrder];
};

onBeforeMount(async () => {
  isLoading.value = true;
  await ecommerceStore
    .actGetOrdersPending(userStore.user.id)
    .then(() => {
      actualOrder.value = ecommerceStore.ordersPending[0];
    })
    .catch((err) => {
      console.error("Error: ", err);
    })
    .finally(() => {
      isLoading.value = false;
    });
});
</script>
<template>
  <div>
    <div class="container mt-md-4">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
          <SecondaryNavbar title="Mis pedidos en curso" />
        </div>
        <div class="col-12 col-md-6 col-lg-8">
          <div class="d-none d-md-block">
            <div
              class="mb-0 text-right d-flex align-items-center justify-content-between"
            >
              <p class="tw-text-base tw-text-gray-300 mb-0 mr-4">
                *Recuerda que solamente puedes cancelar tu pedido en la fase de
                "Realizado"
              </p>
              <router-link
                to="/e-commerce/cart/history"
                class="tw-shadow-md py-2 px-4 tw-rounded-lg tw-text-gray-600 tw-text-sm hover:tw-text-gray-600"
              >
                Historial
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        <div class="row">
          <div
            class="col-12 d-flex align-items-center justify-content-center mb-2"
            style="height: 40vh"
          >
            <div class="text-center">
              <img src="../../assets/img/icons/loading.gif" alt="loading" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-8">
            <p class="tw-text-base fs-md-18 font-weight-bold mt-3 mt-md-0">
              Pedidos en curso: {{ ecommerceStore.ordersPending.length }}
            </p>
          </div>
          <div class="col-4 pt-2 text-right">
            <router-link
              to="/e-commerce/cart/history"
              class="tw-text-gray-600 tw-text-xs hover:tw-text-gray-600 tw-underline d-block d-md-none"
            >
              Historial
            </router-link>
          </div>
        </div>
        <div class="row mt-5" v-if="ecommerceStore.ordersPending.length === 0">
          <div class="col-8 col-md-6 text-center mx-auto">
            <img
              class="d-none d-md-block tw-w-100"
              src="@/assets/img/ecommerce/no-orders.png"
              alt="no hay ordenes"
            />
            <img
              class="d-block d-md-none tw-w-100"
              src="@/assets/img/ecommerce/no-orders-mobile.png"
              alt="no hay ordenes"
            />
          </div>
          <div class="col-12 mt-4">
            <p
              class="tw-text-red-400 font-weight-bold text-uppercase text-center fs-30 fs-md-40 tw-font-tolyerBold"
            >
              En este momento no tienes pedidos en curso.
            </p>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12 col-lg-6">
            <div class="cont-cards mb-3 max-he-400 overflow-auto px-2 pt-2">
              <div
                class="mb-4"
                v-for="(item, index) in ecommerceStore.ordersPending"
                :key="item.id"
              >
                <div
                  class="tw-shadow-sm hover:tw-shadow-md tw-rounded-xl p-4 bg-white z-index-2 position-relative"
                >
                  <CompDetailStatusOrderProduct
                    @clickOrder="changeActualOrder"
                    :countOrders="ecommerceStore.ordersPending.length"
                    :item="item"
                    :index="index"
                  />
                </div>
                <CompDetailOrder
                  :order="item"
                  :viewtitle="false"
                  class="tw-z-1 d-block d-md-none"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 pt-2">
            <CompDetailOrder
              :order="actualOrder"
              :viewtitle="true"
              class="d-none d-md-block"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
