<script setup>
import { ref, computed, onBeforeMount, watch } from "vue";
import config from "@/services/config";
import { useSocialNetworkStore } from "@/stores/socialNetworkStore";
import { capitalizeEachWord } from "@/helpers/textHelpers";

const kDebounceTimeoutMs = 1000;
const uriResources = config.beerOfficeURL;
const imgAvatar = require("@/assets/img/contacts/avatar-empty.png");
const shareSearch = ref("");
const isLoading = ref(false);
const shareTo = ref([]);
const shared = ref(false);
const found = ref(true);
const debounceTimeout = ref(null);
const notSearch = ref(false);
const store = useSocialNetworkStore();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});

const employeesForShare = computed(() => store.employeesForShare);

/*const searchEmployeesForShare = (query = "") => {
  store.actGetEmployeesForShare(query).then((response) => {
    isLoading.value = false;
    if (response.length > 0) {
      shareTo.value = response;
      found.value = true;
    } else {
      found.value = false;
    }
  });
};*/

function searchEmployeesForShare(name) {
  if (name == null) {
    name = "";
  }
  store
    .actGetEmployeesForShare({
      post_category: props.category,
      post_id: props.id,
      name: name,
    })
    .finally(() => {
      found.value = store.employeesForShare.length !== 0;
      isLoading.value = false;
    });
}

function sharePost() {
  store
    .actPostShare({
      post_category: props.category,
      post_id: props.id,
      employees: shareTo.value,
    })
    .then(() => {
      shared.value = true;
    });
}

function selectEmployee(employee) {
  const index = shareTo.value.findIndex((item) => item.id === employee.id);
  if (index === -1) {
    shareTo.value.push(employee.id);
  } else {
    shareTo.value.splice(index, 1);
  }
}

function cleanQuery() {
  shareSearch.value = "";
  notSearch.value = false;
}

/*    selectEmployee(employee) {
      if (this.shareTo.includes(employee.id)) {
        this.shareTo.splice(this.shareTo.indexOf(employee.id), 1);
      } else {
        this.shareTo.push(employee.id);
      }
    }, */

watch(shareSearch, (query) => {
  if (debounceTimeout.value) {
    isLoading.value = true;
    clearTimeout(debounceTimeout.value);
  }

  if (query.length < 3) {
    notSearch.value = false;
    searchEmployeesForShare();
  } else {
    debounceTimeout.value = setTimeout(() => {
      searchEmployeesForShare(query);
    }, kDebounceTimeoutMs);
  }
});

onBeforeMount(() => {
  isLoading.value = true;
  searchEmployeesForShare();
});
</script>
<template>
  <div
    class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-auto"
  >
    <div
      v-if="shared === false"
      class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-auto"
    >
      <div id="header">
        <p
          class="tw-font-robotoBold tw-text-lg lg:tw-text-3xl tw-text-red-350 tw-mt-0"
        >
          Comparte
        </p>
        <p class="tw-font-roboto tw-text-sm lg:tw-text-base">
          Puedes compartir esta publicación a cualquier embajador, recuerda
          buscar con el
          <strong class="tw-text-red-350"
            >primer nombre y primer apellido</strong
          >
        </p>
        <!--        <div-->
        <!--          class="tw-rounded-2xl tw-bg-gray-200 tw-grid tw-grid-flow-col tw-grid-cols-comments tw-overflow-hidden"-->
        <!--        >-->
        <!--          <div class="tw-overflow-hidden tw-h-28">-->
        <!--            <img-->
        <!--              :src="uriResources + '/' + props.image"-->
        <!--              alt="image"-->
        <!--              class="tw-object-cover tw-object-center tw-h-full tw-w-full"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div class="tw-p-3 md:tw-p-4 tw-flex tw-flex-col tw-justify-center">-->
        <!--            <p-->
        <!--              class="tw-mb-2 md:tw-mb-auto tw-font-robotoBold tw-text-sm md:tw-text-xl"-->
        <!--            >-->
        <!--              {{ props.title }}-->
        <!--            </p>-->
        <!--            <p-->
        <!--              class="tw-mb-2 md:tw-mb-auto tw-line-clamp-3 tw-font-roboto tw-text-xxxs md:tw-text-xs"-->
        <!--              v-html="props.description"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
        <div
          class="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-my-5"
        >
          <input
            id="searchbar"
            v-model="shareSearch"
            autocomplete="off"
            class="tw-w-full tw-rounded-full tw-bg-gray-200 tw-py-3 tw-pl-14 tw-font-roboto tw-text-sm focus:tw-outline-none focus:tw-border-transparent focus:tw-ring-transparent tw-border-transparent"
            name=""
            type="text"
            @keyup.esc="cleanQuery"
          />
          <i
            class="icon-search-bold tw-absolute tw-px-5 tw-text-2xl tw-text-gray-320"
          ></i>
        </div>
      </div>
      <div v-if="!isLoading && employeesForShare.length > 0" class="tw-px-4">
        <p
          class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-mx-auto tw-mb-2 tw-text-gray-320"
        >
          Sugerencias
        </p>
      </div>
      <div id="search" class="tw-flex-1 tw-overflow-auto">
        <div v-if="isLoading" class="text-center">
          <img
            alt="loading"
            src="@/assets/img/icons/loading.gif"
            class="tw-w-10"
          />
        </div>
        <div v-else-if="!isLoading && !found" class="text-center">
          <p
            class="tw-font-roboto tw-text-sm lg:tw-text-base tw-mx-auto tw-my-3"
          >
            No se encontraron resultados
          </p>
        </div>
        <div
          v-for="(employee, index) in employeesForShare"
          v-else
          :key="index"
          class="tw-flex tw-justify-between tw-items-center tw-px-4 tw-my-4"
        >
          <div class="tw-flex tw-items-center">
            <img
              :src="
                employee.prof_picture == null
                  ? imgAvatar
                  : uriResources + '/' + employee.prof_picture
              "
              alt=""
              class="tw-object-cover tw-rounded-full tw-w-10 tw-h-10 lg:tw-w-14 lg:tw-h-14 tw-mr-2 lg:tw-mr-3"
            />
            <p class="tw-font-roboto tw-text-xs lg:tw-text-base tw-m-0">
              {{ capitalizeEachWord(employee.name) }}
            </p>
          </div>

          <div class="round tw-flex tw-items-center">
            <input
              :id="index"
              :disabled="employee.approved_share === false"
              type="checkbox"
              @change="selectEmployee(employee)"
            />
            <label :for="index"></label>
          </div>
          <!-- <div class="checkbox_input">
          <input
            id="checkbox-share"
            aria-describedby="checkbox-share"
            type="checkbox"
            class="tw-bg-white tw-h-4 tw-w-4 tw-rounded-full"
            :disabled="employee.approved_share === false"
            style="outline: 1px solid #c4c4c4"
            @change="selectEmployee(employee)"
          />
        </div> -->
        </div>
      </div>
      <div id="footer">
        <div
          :class="
            shareTo.length > 0
              ? 'tw-btn-primary-red tw-cursor-pointer'
              : 'tw-btn-primary-disabled'
          "
          class="tw-mt-3 tw-text-center"
          @click="shareTo.length > 0 ? sharePost() : ''"
        >
          <p class="tw-m-0">Compartir</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="header">
        <p
          class="tw-font-robotoBold tw-text-lg lg:tw-text-3xl tw-text-red-350 tw-mt-0"
        >
          Gracias por compartir
        </p>
        <div
          class="tw-flex tw-items-center tw-justify-between tw-py-4 lg:tw-py-8"
        >
          <p class="tw-font-roboto tw-text-sm lg:tw-text-xl tw-m-0">
            Has compartido éxito una nueva publicación.
          </p>
          <img
            class="tw-w-4 tw-h-4 lg:tw-w-8 lg:tw-h-8"
            src="@/assets/img/check.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 1.2rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.2rem;
}

.round label:after {
  border: 1px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  top: 4px;
  left: 4px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #fa2727;
  border-color: #fa2727;
}

.round input[type="checkbox"]:disabled + label {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.round input[type="checkbox"]:disabled + label:after {
  opacity: 1;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
</style>
