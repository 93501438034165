<script setup>
import { ref, computed, onBeforeMount } from "vue";
import config from "@/services/config";
import { useSocialNetworkStore } from "@/stores/socialNetworkStore";
import { useStorage } from "@vueuse/core";

const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const imgAvatar = require("@/assets/img/contacts/avatar-empty.png");
const comment = ref({
  maxLength: 200,
  value: "",
});
const user = useStorage("user", {});
const store = useSocialNetworkStore();

const emit = defineEmits(["updatedComment"]);

const updatedComments = computed(() => {
  return store.comments;
});

const props = defineProps({
  isShowModal: {
    type: Boolean,
  },
  item: {
    type: Object,
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
});

function cleanComment() {
  comment.value.value = "";
}

function getComments() {
  store
    .actGetComments({
      post_category: props.category,
      post_id: props.id,
    })
    .catch((err) => {
      console.error("Error", err);
    })
    .finally(() => {
      /*
      this.type === "article"
          ? this.articlesStore.actGetArticleDetail(this.item.id)
          : this.actGetNews();  */
      isLoading.value = false;
    });
}

function postComment() {
  store
    .actPostComments({
      post_category: props.category,
      post_id: props.id,
      comment: comment.value.value,
    })
    .then(() => {
      getComments();
      emit("updatedComment");
    })
    .catch((err) => {
      console.error("Error", err);
    });

  cleanComment();
}

function deleteComment(id) {
  store
    .actDeleteComments(id)
    .then(() => {
      getComments();
      emit("updatedComment");
    })
    .catch((err) => {
      console.error("Error", err);
    });
}

function capitalizeEachWord(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

onBeforeMount(() => {
  isLoading.value = true;
  getComments();
});
</script>
<template>
  <div
    class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-auto"
  >
    <div id="header">
      <p
        class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350 tw-m-0"
      >
        Comentarios
      </p>
      <p
        class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-2 tw-ml-0 tw-my-0 tw-mr-10"
        v-html="props.item.description"
      />
      <hr class="tw-mb-2" />
    </div>
    <div id="comments" class="tw-flex-1 tw-overflow-auto">
      <div v-if="props.item.amount_comments === 0">
        <p
          class="tw-font-roboto tw-text-xs md:tw-text-sm lg:tw-text-base tw-text-gray-300 lg:tw-my-8"
        >
          No se ha realizado ningún comentario, el tuyo puede ser el primero.
        </p>
      </div>
      <div class="tw-my-1 md:tw-my-2 lg:tw-my-3" v-else>
        <div class="text-center" v-if="isLoading">
          <img
            src="@/assets/img/icons/loading.gif"
            class="tw-w-14"
            alt="loading"
          />
        </div>
        <div id="comments-inside" class="tw-ml-2 tw-mr-8" v-else>
          <div
            v-for="(comment, index) in updatedComments"
            :key="index"
            class="tw-flex tw-my-3 lg:tw-my-4"
            :id="'comment_' + index"
          >
            <div class="tw-mr-3">
              <img
                :src="
                  comment.core_employee.prof_picture == null
                    ? imgAvatar
                    : uriResources + '/' + comment.core_employee.prof_picture
                "
                class="tw-object-cover tw-rounded-full tw-w-10 tw-h-10 lg:tw-w-14 lg:tw-h-14"
                alt=""
              />
            </div>
            <div class="tw-flex-1">
              <p class="tw-font-robotoBold tw-text-sm lg:tw-text-base tw-m-0">
                {{ capitalizeEachWord(comment.core_employee.name) }}
              </p>
              <div class="tw-flex tw-justify-between tw-items-start">
                <p
                  class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-3"
                >
                  {{ comment.comment }}
                </p>
                <i
                  v-if="comment.core_employee.id === user.id"
                  class="icon-cerrar-simple tw-ml-3 tw-text-red-350 tw-cursor-pointer"
                  @click="deleteComment(comment.id)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="input" class="tw-text-center">
      <hr class="tw-mt-2" />
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-mr-2 lg:tw-mr-3">
          <img
            :src="
              user.prof_picture == null
                ? imgAvatar
                : uriResources + '/' + user.prof_picture
            "
            class="tw-object-cover tw-rounded-full tw-w-10 tw-h-10 lg:tw-w-14 lg:tw-h-14"
            alt=""
          />
        </div>

        <input
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-full tw-bg-gray-200 tw-py-2 tw-px-2 lg:tw-px-4 tw-font-roboto tw-text-xxs md:tw-text-xs lg:tw-text-sm focus:tw-outline-none focus:tw-border-transparent focus:tw-ring-transparent tw-border-transparent"
          :maxlength="comment.maxLength"
          @keyup.esc="cleanComment"
          @keyup.enter="postComment"
          v-model="comment.value"
          autocomplete="off"
        />
        <i
          class="tw-text-xl md:tw-text-2xl tw-text-gray-320 tw-ml-2 lg:tw-ml-4"
          :class="
            comment.value.length > 0
              ? 'icon-triangle-right-full tw-text-red-350 tw-cursor-pointer'
              : 'icon-triangle-right'
          "
          @click="comment.value.length > 0 ? postComment() : ''"
        ></i>
      </div>
      <!--      <p class="tw-font-roboto tw-underline tw-text-xxxs lg:tw-text-sm tw-m-0">
        Este espacio está destinado para comentar información del contenido
        relacionado
      </p>-->
    </div>
  </div>
</template>
