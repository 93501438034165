<script setup>
import { getCurrentInstance, ref } from "vue";
import { useCoreStore } from "@/stores/coreStore";
import { useRouter } from "vue-router/composables";
import CompGeneralDropdown from "@/components/general/CompGeneralDropdown.vue";
import GeneralModal from "@/components/modals/GeneralModal";
import MailboxThanks from "@/components/support/CompMailboxThanks.vue";

const router = useRouter();
const coreStore = useCoreStore();
const { $isMobile } = getCurrentInstance().proxy;
const isModalThanksSuggestion = ref(false);
const suggestion = ref("");
const selectedCategory = ref(null);

const emit = defineEmits(["close"]);

const sendSuggestion = async () => {
  await coreStore
    .actPostSuggestions({
      suggestion_category_id: selectedCategory.value.id,
      suggestion: suggestion.value,
    })
    .then(() => {
      isModalThanksSuggestion.value = true;
    });
};

const closeSuggestions = () => {
  emit("close");
};

const hideModalThanksSuggestion = () => {
  isModalThanksSuggestion.value = false;
  !$isMobile() ? router.go(-1) : null;
};

const setSelectedCategory = (option) => {
  selectedCategory.value = option;
};
</script>
<template>
  <div>
    <portal to="all-modals">
      <general-modal
        v-if="isModalThanksSuggestion && !$isMobile()"
        :isShowModal="isModalThanksSuggestion"
        :maxHeight="{ base: 600, md: 600, lg: 700 }"
        :modalWidth="{ md: 500, lg: 600 }"
        modalName="thanksSuggestion"
        @close="hideModalThanksSuggestion"
      >
        <div class="tw-flex tw-flex-col tw-gap-3">
          <p
            class="tw-font-robotoBold tw-text-lg lg:tw-text-2xl lg:tw-leading-7 tw-text-red-350 tw-m-0"
          >
            Buzón de Sugerencias
          </p>
          <mailbox-thanks @close="hideModalThanksSuggestion" />
        </div>
      </general-modal>
    </portal>
    <div
      v-if="isModalThanksSuggestion && $isMobile()"
      class="tw-px-6 md:tw-px-7 lg:tw-px-10"
    >
      <mailbox-thanks @close="closeSuggestions()" />
    </div>
    <div
      class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-5 lg:tw-gap-10"
      v-if="!isModalThanksSuggestion || !$isMobile()"
    >
      <div
        class="tw-bg-blue-400 lg:tw-rounded-3xl tw-p-5 lg:tw-p-10 tw-grid tw-grid-cols-8 tw-gap-4"
      >
        <div
          class="tw-col-span-4 lg:tw-col-span-3 tw-flex tw-flex-col tw-justify-between tw-gap-3"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-base">
            En este espacio puedes escribirnos tus comentarios y sugerencias,
            queremos siempre tener en cuenta tu opinión para mejorar la
            plataforma junto a ti
          </p>
          <p class="tw-m-0 tw-font-roboto tw-text-xxxs lg:tw-text-sm">
            * En este espacio no resolveremos casos de soporte.
          </p>
          <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-base">
            Selecciona la categoría
          </p>
        </div>
        <div class="tw-col-span-4 lg:tw-col-span-5">
          <img
            src="@/assets/img/suggestions_mailbox.svg"
            class="tw-w-full tw-h-full tw-object-cover"
            alt=""
          />
        </div>
        <div class="tw-col-span-8">
          <CompGeneralDropdown
            :fitted="false"
            :icon="'icon-beneficios-viajes'"
            icon-visible
            :isLabel="false"
            :options="coreStore.suggestionsCategories"
            @changeSelectedOption="setSelectedCategory"
            @chargeOption="setSelectedCategory"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-justify-evenly tw-gap-2"
        :class="$isMobile() ? 'tw-px-6 md:tw-px-7 lg:tw-px-10' : ''"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl">
          Cuéntanos que sugerencia tienes para nosotros
        </p>
        <textarea
          type="text"
          name=""
          id="commentField"
          class="tw-w-full tw-rounded-2xl tw-bg-white tw-p-3 tw-my-2 lg:tw-my-3 lg:tw-p-4 tw-font-roboto tw-text-xs md:tw-text-sm lg:tw-text-sm tw-border-solid tw-border tw-border-gray-250 focus:tw-outline-none focus:tw-border-gray-250 focus:tw-ring-transparent tw-border-transparent tw-resize-none tw-h-36 lg:tw-h-48"
          v-model="suggestion"
          autocomplete="off"
          placeholder="Contenido..."
        ></textarea>
        <div class="tw-flex tw-justify-evenly tw-gap-3">
          <div
            class="tw-btn-secondary-white tw-w-4/12"
            @click="closeSuggestions()"
          >
            <p class="tw-m-0">Cancelar</p>
          </div>
          <div
            class="tw-w-4/12"
            :class="
              suggestion ? 'tw-btn-primary-red' : 'tw-btn-primary-disabled'
            "
            @click="sendSuggestion()"
          >
            <p class="tw-m-0">Enviar</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
