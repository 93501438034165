<template>
  <div id="worldbrands">
    <MobileBar title="MUNDO DE MARCAS" />
    <div class="tw-container-general">
      <BreadCrumb :crumbs="breadcrumb" />
      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-mt-8">
        <div class="tw-col-span-12 md:tw-col-span-3">
          <p
            class="tw-font-tolyerBold tw-text-base md:tw-text-4xl tw-mb-4 tw-hidden md:tw-block"
          >
            Mundo de marcas
          </p>
          <p
            class="tw-font-roboto tw-text-xs md:tw-text-sm tw-text-gray-400 tw-mb-4"
          >
            Conoce todas las experiencias que nuestras marcas traen para ti.
          </p>
          <CompDivLink
            title="Concursos"
            description="Conoce las últimas novedades de tus marcas favoritas"
            :icon="require('@/assets/img/icons/icon-golden-trophy.svg')"
            :image="require('@/assets/img/stellaartois.png')"
            buttonName="Concursos"
            link="/competitions"
            :datalayer="{
              event: 'TrackEvent-concursos',
              Category: 'Concursos',
              Action: 'Clics',
              Label: 'Concursos',
            }"
          />
        </div>
        <div class="tw-col-span-12 md:tw-col-span-9 tw-mb-5">
          <img
            alt=""
            src="@/assets/img/brandsbanner.png"
            class="tw-w-full tw-h-auto tw-object-cover tw-mb-8"
          />
          <div class="tw-grid tw-gap-4 tw-grid-cols-12">
            <div
              class="tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3 tw-mx-auto"
              v-for="(item, index) in categoriesBrands"
              v-bind:key="index"
            >
              <div @click="pushDataLayer(item.name)" class="tw-w-full">
                <router-link
                  class="tw-text-center"
                  :to="'/brand/' + item.id"
                  @click.native="dataTrack(item.name)"
                >
                  <img
                    :src="uriResources + '/' + item.img"
                    loading="lazy"
                    :alt="item.img"
                    class="tw-w-24 md:tw-w-36 lg:tw-w-44 tw-h-24 lg:tw-h-44 tw-rounded-tr-3xl tw-object-cover"
                  />
                  <p
                    class="tw-font-tolyerBold tw-text-xl md:tw-text-3xl tw-text-gray-600 tw-m-0 tw-mt-3"
                  >
                    {{ item.name }}
                  </p>
                  <p
                    class="tw-font-robotoBold tw-text-xs tw-text-gray-600 tw-uppercase tw-m-0"
                  >
                    {{ item.description }}
                  </p>
                </router-link>
              </div>
              <!-- .brand -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "@/services/config";

import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import CompDivLink from "@/components/general/CompDivLink";

export default {
  name: "WorldBrands",
  components: {
    BreadCrumb,
    MobileBar,
    CompDivLink,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Universo Cervecero",
          link: "/beer-universe",
        },
        {
          id: 3,
          name: "Mundo de Marcas",
          link: "#",
          active: true,
        },
      ],
    };
  },
  beforeMount() {
    this.actGetBrands();
  },
  mounted() {
    window.dataLayer.push({
      event: "'trackEvent",
      target: "Sub-secciones",
      action: "universo-de-marcas",
      "target-properties": "clic",
      value: 5000,
    });
  },
  computed: {
    ...mapState("modBrands", ["categoriesBrands"]),
  },
  methods: {
    ...mapActions("modBrands", ["actGetBrands"]),
    // pushConcursos() {
    //   this.$router.push({ path: "competitions" });
    // },
    dataTrack(name) {
      window.dataLayer.push({
        event: "trackEvent",
        eventCategory: "Concursos",
        eventAction: "clic",
        eventLabel: name,
        eventValue: 5000,
      });
    },
    pushDataLayer: function (brandname) {
      window.dataLayer.push({
        event: "TrackEvent-UniversoCervecero",
        category: "UniversoCervecero",
        subcategory: "Mundo de marcas - Marcas",
        Action: "Clics",
        Brand: brandname,
      });
    },
  },
};
</script>
