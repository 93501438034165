<script setup>
import { reactive, ref } from "vue";
import { capitalizeEachWord } from "@/helpers/textHelpers";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import { useGoFlexRequestTable } from "@/composables/goFlex/useGoFlexRequestTable";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import CompButtonCustomColor from "@/components/buttons/CompButtonCustomColor.vue";

const props = defineProps({
  teamRequests: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["approve", "reject"]);

const columnNames = ref([
  "avatar",
  "name",
  "type",
  "request_name",
  "date",
  "detail",
  "state",
]);

const columnsReference = reactive({
  avatar: "Avatar",
  name: "Nombre",
  type: "Tipo",
  request_name: "Solicitud",
  date: "Días",
  detail: "Detalle",
  state: "Estado",
});

const {
  selectedItems,
  pendingRequestsLength,
  catalogOption,
  statusName,
  statusColor,
  statusTextColor,
  avatarImage,
  toggleSelectAllItems,
  toggleSelectItem,
} = useGoFlexRequestTable(props.teamRequests);

const sentItemsForApproval = () => {
  emit("approve", selectedItems);
};

const sentItemsForRejection = () => {
  emit("reject", selectedItems);
};
</script>

<template>
  <section>
    <div
      class="tbl-header tw-border tw-border-red-350 tw-bg-red-350 tw-rounded-t-2.5xl tw-overflow-hidden tw-py-2"
    >
      <table class="tw-table-fixed tw-w-full">
        <thead class="tw-text-center">
          <tr>
            <th
              v-for="(column, idx) in columnNames"
              :key="idx"
              class="tw-py-3 tw-border first:tw-border-l-0 tw-border-l tw-border-r-0 tw-border-y-0 tw-border-white tw-text-white"
            >
              {{ columnsReference[column] }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="tbl-content hidden-scrollbar">
      <table
        class="tw-table-fixed tw-w-full tw-border-separate tw-border-spacing-y-5"
      >
        <tbody>
          <tr v-for="(item, idx) in props.teamRequests" :key="idx">
            <td
              class="tw-table-auto tw-flex tw-gap-4 tw-items-center tw-justify-center"
            >
              <input
                :disabled="
                  item.status === 'APPROVED' || item.status === 'REJECTED'
                "
                :checked="
                  item.status === 'APPROVED' ||
                  item.status === 'REJECTED' ||
                  selectedItems.includes(item.request_id)
                "
                required
                type="checkbox"
                class="tw-w-7 tw-h-7 tw-border tw-border-solid tw-border-gray-300 tw-rounded-md focus:tw-ring-transparent tw-shadow-sm"
                :class="
                  item.status === 'APPROVED' || item.status === 'REJECTED'
                    ? 'tw-cursor-not-allowed tw-text-gray-400'
                    : 'tw-cursor-pointer tw-text-green-450'
                "
                @click="toggleSelectItem(item.request_id)"
              />
              <img
                :src="avatarImage(item)"
                class="tw-object-cover tw-w-14 tw-h-14 tw-rounded-full tw-border-2 tw-border-gray-300"
                alt=""
              />
            </td>
            <td>
              <div class="tw-h-full tw-w-full">
                <span class="tw-line-clamp-2 tw-font-robotoMedium">{{
                  capitalizeEachWord(item.core_employee_name)
                }}</span>
              </div>
            </td>
            <td>
              {{ catalogOption[item.option_catalog] }}
            </td>
            <td>{{ item.option_name }}</td>
            <td>
              {{ formatDateYYYYMMDDLong(item.requested_date) }}
            </td>
            <td>{{ item.option_label }}</td>
            <td>
              <div
                class="tw-bubble tw-w-full tw-border-none tw-py-2"
                :class="`tw-bg-${statusColor(
                  item.status
                )} tw-text-${statusTextColor(item.status)}`"
              >
                {{ statusName(item.status) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="tw-w-full tw-flex tw-justify-between tw-items-center tw-border tw-border-black tw-border-t-0 tw-rounded-b-2.5xl tw-p-3"
    >
      <div>
        <comp-button-custom
          class="tw-w-full"
          :text="
            selectedItems.length === pendingRequestsLength
              ? 'Deseleccionar todo'
              : 'Seleccionar todo'
          "
          color="white"
          @click.native="toggleSelectAllItems"
        />
      </div>
      <div class="tw-flex tw-justify-end tw-items-center tw-gap-2">
        <p class="tw-m-0">
          {{ `has seleccionado (${selectedItems.length}) solicitudes` }}
        </p>
        <comp-button-custom-color
          :disabled="selectedItems.length === 0"
          class="tw-w-32"
          text="Aprobar"
          color="green-450"
          text-color="white"
          @click.native="sentItemsForApproval"
        />
        <comp-button-custom-color
          :disabled="selectedItems.length === 0"
          class="tw-w-32"
          text="Rechazar"
          color="red-350"
          text-color="white"
          @click.native="sentItemsForRejection"
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
/*table {
  border-collapse: collapse;
}

td {
  padding: 8px;
}*/

.tbl-content {
  @apply tw-h-[400px] tw-overflow-y-auto tw-mt-0 tw-border tw-border-black tw-border-t-0;
}
th {
  @apply tw-font-robotoMedium tw-text-sm tw-text-center tw-px-4 tw-py-2;
}

td {
  @apply tw-font-roboto tw-text-center tw-text-sm tw-px-4 tw-align-middle [&:nth-child(2)]:tw-text-left tw-border tw-border-l first:tw-border-l-0 tw-border-b-0 tw-border-t-0 tw-border-r-0;
}

.hidden-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
