import AxiosBeerOffice from "@/services/axiosBeerOffice";
import { defineStore } from "pinia";
import { ref } from "vue";

const uri = {
  ratingsUserUri: "/coreReviews",
  ratingsUri: "/coreReviews?category",
  npsUri: "/nps",
};

export const useRatingStore = defineStore("rating", () => {
  const nps = ref(null);

  async function actGetRating(data) {
    const response = await AxiosBeerOffice.get(
      uri.ratingsUri + "=" + data,
      true,
      data,
      true
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostRating(data) {
    const response = await AxiosBeerOffice.post(
      uri.ratingsUserUri,
      true,
      data,
      true
    );
    if (response.error) {
      return false;
    } else {
      return response.score;
    }
  }

  async function actGetNPS() {
    const response = await AxiosBeerOffice.get(uri.npsUri, true, {});
    if (response.error) return response.error;
    const { data } = response;
    nps.value = data;
    return !!nps.value;
  }

  async function actPostNPSScore(data) {
    const response = await AxiosBeerOffice.post(uri.npsUri, true, data, true);
    if (response.error) {
      return false;
    } else {
      return response;
    }
  }

  return {
    nps,
    actGetRating,
    actPostRating,
    actGetNPS,
    actPostNPSScore,
  };
});
