<script setup>
import config from "@/services/config";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router/composables";
import { pushLink } from "@/helpers/linkHelpers";
import { isStillTime } from "@/helpers/datesHelpers";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const uriResources = config.beerOfficeURL;
const router = useRouter();
const route = useRoute();
const isMoreInfo = ref(false);

const props = defineProps({
  contest: {
    type: Object,
    required: true,
  },
});

const canAccessContest = () => {
  const verifyCompetitionType = props.contest.comp_competition_type_id === 1;
  const hasUnansweredQuestions = props.contest.unanswered_questions?.length > 0;

  if (props.contest.comp_competition_type_id === 1) {
    return (
      verifyCompetitionType &&
      hasUnansweredQuestions &&
      isStillTime(props.contest.end)
    );
  }

  return isStillTime(props.contest.end);
};
</script>

<template>
  <div
    class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-3 lg:tw-gap-6 lg:tw-grid-rows-rows_3_fit_first_and_last"
  >
    <div
      class="lg:tw-row-span-2 tw-order-2 lg:tw-order-1 tw-rounded-2.5xl tw-overflow-hidden"
    >
      <img
        :src="`${uriResources}/${props.contest.icon}`"
        alt=""
        class="tw-rounded-2.5xl tw-h-full tw-w-full tw-object-contain tw-object-center tw-max-h-80"
      />
    </div>
    <div class="tw-order-1 lg:tw-order-2">
      <p
        class="tw-text-center tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-m-0"
      >
        {{ props.contest.name }}
      </p>
    </div>
    <div class="tw-order-3 tw-flex tw-flex-col tw-gap-2 tw-justify-between">
      <p
        class="tw-font-roboto tw-text-sm lg:tw-text-base tw-order-3 lg:tw-order-1"
      >
        <span class="tw-font-robotoBold">Inicio: </span>
        {{ props.contest.start ? props.contest.start : "No hay fecha" }}
        <span class="tw-font-robotoBold">Fin: </span
        >{{ props.contest.end ? props.contest.end : "No hay fecha" }}
      </p>
      <p
        class="tw-font-roboto tw-text-sm lg:tw-text-base tw-order-1 lg:tw-order-2"
      >
        {{ props.contest.desc }}
      </p>
      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-order-2 lg:tw-order-3"
        v-if="props.contest.comp_awar.length > 0"
      >
        <p class="tw-font-robotoBold tw-text-lg lg:tw-text-xl tw-m-0">
          Premios
        </p>
        <!-- a group of 5 gray circles -->
        <div class="tw-flex tw-gap-2 lg:tw-gap-4">
          <div v-for="(reward, idx) in props.contest.comp_awar" :key="idx">
            <div
              class="tw-aspect-1 tw-w-16 tw-rounded-full tw-bg-gray-250"
              v-if="!reward.icon"
            ></div>
            <img
              :src="`${uriResources}/${reward.icon}`"
              :alt="reward.desc"
              class="tw-aspect-1 tw-w-16 tw-rounded-full tw-object-cover tw-border tw-border-gray-300"
              v-else
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tw-order-5 lg:tw-order-4">
      <div
        v-if="isMoreInfo"
        class="tw-flex tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-4"
      >
        <i
          class="icon-flecha-abajo tw-text-red-350 tw-text-xl lg:tw-text-2.5xl"
        />
        <p class="tw-font-robotoBold tw-text-lg lg:tw-text-xl tw-m-0">
          Conoce más información
        </p>
        <i
          class="icon-flecha-abajo tw-text-red-350 tw-text-xl lg:tw-text-2.5xl"
        />
      </div>
    </div>
    <div class="tw-order-4 lg:tw-order-5 tw-flex tw-gap-2">
      <comp-button-custom
        text="Términos y condiciones"
        class="tw-w-full tw-h-full tw-py-3"
        @click.native="pushLink(props.contest.tyc)"
      />
      <comp-button-primary-red
        :disabled="!canAccessContest()"
        :title="
          canAccessContest() ? 'Acceder al concurso' : 'Concurso finalizado'
        "
        class="tw-w-full tw-h-full tw-py-3"
        @click.native="
          canAccessContest() &&
            router.push(`/contests/${route.params.id}/inside`)
        "
      />
    </div>
  </div>
</template>

<style scoped></style>
