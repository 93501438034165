<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import CompCardArticles from "@/components/articles/CompArticlesCard.vue";
import CompSelectCategories from "@/components/general/CompSelectCategories.vue";

const windowWidth = ref(window.innerWidth);
const showAll = ref(false);
const dynamicFilter = ref("");
const openSelect = ref(false);
const selectFilter = ref({
  id: 0,
  name: "Lo más reciente",
});

const props = defineProps({
  articlesGeneral: {
    type: Array,
    required: true,
  },
});

const numberOfArticles = computed(() => {
  if (windowWidth.value < 768) {
    return 3;
  } else {
    return 6;
  }
});

const articles = computed(() => {
  let articles;
  if (dynamicFilter.value === "") {
    articles = props.articlesGeneral;
  } else {
    articles = props.articlesGeneral.filter((article) =>
      article?.article_category.name
        .toLowerCase()
        .includes(dynamicFilter.value.toLowerCase())
    );
  }
  return articles;
});

const nrOfArticles = computed(() => {
  if (showAll.value === true) {
    return articles.value.length;
  } else {
    return numberOfArticles.value;
  }
});

const toggleShowAll = () => {
  showAll.value = !showAll.value;
};

const changeFilter = (dynFilter) => {
  dynamicFilter.value = dynFilter;
  let filter = dynFilter || "Todos";
  dataLayer("Filtros", "Click", filter);
};

const closeSelectFilter = () => {
  openSelect.value = false;
};

const toggleSelectFilter = () => {
  openSelect.value = !openSelect.value;
};

const sortBy = () => {
  switch (selectFilter.value.id) {
    case 0:
      return articles.value.sort((a, b) => {
        return b.id - a.id;
      });
    case 1:
      return articles.value.sort((a, b) => {
        return a.id - b.id;
      });
    case 2:
      return articles.value.sort((a, b) => {
        return b.views - a.views;
      });
    case 3:
      return articles.value.sort((a, b) => {
        return b.amount_reactions - a.amount_reactions;
      });
    case 4:
      return articles.value.sort((a, b) => {
        return b.amount_comments - a.amount_comments;
      });
  }
};

const changeSelectFilter = (e, id, label) => {
  selectFilter.value.id = id;
  selectFilter.value.name = e.target.innerHTML;
  sortBy(id);
  closeSelectFilter();
  dataLayer("Filtros", "Click", label);
};

const dataLayer = (action, component, label) => {
  pushDataLayer({
    event: "GAEvent",
    event_category: "Artículos",
    event_action: action,
    event_label: label,
    interaction: "True",
    component_name: component,
  });
};

onBeforeMount(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});
</script>
<template>
  <div>
    <div
      class="tw-flex tw-justify-between tw-items-start tw-mt-10 tw-mb-5 tw-h-auto tw-max-h-10"
    >
      <div class="tw-flex tw-gap-x-6 tw-items-center tw-justify-start">
        <p
          class="tw-mb-0 tw-text-black tw-text-2xl md:tw-text-3xl tw-font-robotoBold"
        >
          Para ti
        </p>
        <img
          src="@/assets/img/icons/icon-5.svg"
          alt="icon 3"
          class="tw-hidden md:tw-block md:tw-grow-0 tw-object-cover tw-w-20 md:tw-w-24"
        />
      </div>
      <div class="tw-flex tw-gap-x-2 tw-items-start tw-justify-end">
        <p class="tw-mt-2 tw-font-roboto tw-text-xs md:tw-text-lg">Organizar</p>
        <div
          class="tw-w-max tw-h-100 tw-border-solid tw-border tw-border-black tw-shadow-md tw-h-auto tw-bg-white tw-overflow-hidden tw-z-2"
          :class="
            openSelect
              ? 'tw-rounded-2xl md:tw-rounded-2.5xl'
              : 'tw-rounded-full'
          "
        >
          <div
            class="tw-flex tw-items-center tw-justify-between tw-gap-x-3 tw-cursor-pointer tw-px-3 tw-py-2"
            @click="toggleSelectFilter"
          >
            <span class="tw-font-roboto tw-text-xs lg:tw-text-base">{{
              selectFilter.name
            }}</span>
            <i
              class="tw-text-xs tw-text-red-350"
              :class="openSelect ? 'icon-flecha-arriba' : 'icon-flecha-abajo'"
            ></i>
          </div>
          <div
            class="tw-bg-white tw-w-full"
            :class="openSelect ? 'tw-h-auto tw-visible' : 'tw-h-0 tw-invisible'"
          >
            <div
              class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
              @click="changeSelectFilter($event, 0, 'Lo más reciente')"
            >
              Lo más reciente
            </div>
            <div
              class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
              @click="changeSelectFilter($event, 1, 'Lo más antiguo')"
            >
              Lo más antiguo
            </div>
            <div
              class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
              @click="changeSelectFilter($event, 2, 'Lo más visto')"
            >
              Lo más visto
            </div>
            <div
              class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
              @click="changeSelectFilter($event, 3, 'Lo más valorado')"
            >
              Lo más valorado
            </div>
            <div
              class="tw-cursor-pointer hover:tw-bg-gray-250 tw-px-3 tw-py-2 tw-font-roboto tw-text-xs lg:tw-text-base"
              @click="changeSelectFilter($event, 4, 'Lo más comentado')"
            >
              Lo más comentado
            </div>
          </div>
        </div>
      </div>
    </div>
    <comp-select-categories
      :items="articlesGeneral"
      categoryToFilter="article_category"
      @changeFilter="changeFilter"
    />

    <div
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 md:tw-gap-6 lg:tw-gap-8 tw-z-1"
    >
      <div
        v-for="(article, idx) in articles.slice(0, nrOfArticles)"
        :key="idx"
        class="lg:tw-flex lg:tw-flex-col"
      >
        <CompCardArticles
          :article="article"
          :change="false"
          category="Para tí"
        />
        <hr class="md:tw-hidden" />
      </div>
    </div>
    <div
      @click="toggleShowAll"
      class="tw-flex tw-justify-center tw-items-center tw-border tw-border-r-0 tw-border-l-0 tw-py-5 tw-border-gray-320 tw-my-4 md:tw-my-6 lg:tw-my-8 tw-cursor-pointer"
    >
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-base tw-leading-5 md:tw-text-xl md:tw-leading-6 lg:tw-text-2xl lg:tw-leading-7"
      >
        {{ showAll ? "Mostrar menos contenido" : "Mostrar más contenido" }}
      </p>
      <i
        class="tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-lg"
        :class="showAll ? 'icon-flecha-arriba' : 'icon-flecha-abajo'"
      ></i>
    </div>
  </div>
</template>
