<script setup>
import CompFormTextInput from "@/components/form/CompFormTextInput.vue";
import { computed, onMounted } from "vue";

const props = defineProps({
  data: Object,
});

const emit = defineEmits(["update"]);

const childrenSupportValue = computed(() => {
  return props.data.hours_children_support || 0;
});

onMounted(() => {
  emit("update", {
    ...props.data,
    hours_children_support: childrenSupportValue.value,
  });
});
</script>

<template>
  <section
    id="city-information"
    class="tw-flex tw-flex-col tw-gap-2 tw-p-3 tw-border tw-border-gray-300 tw-rounded-2xl"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <p class="tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm">
        ¿Cuántas horas le dedicas a las tareas educativas y de acompañamiento de
        tus hijos/hijas?
      </p>
      <p class="tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs">
        *En caso de que no aplique, pon 0 en la respuesta
      </p>
    </div>
    <comp-form-text-input
      :model-value="childrenSupportValue"
      input-type="number"
      @update="
        (value) => {
          const validatedValue = Math.max(0, Number(value));
          emit('update', {
            ...props.data,
            hours_children_support: validatedValue,
          });
        }
      "
    />
  </section>
</template>

<style scoped></style>
