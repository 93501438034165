<template>
  <div class="ml-0 md:tw-ml-20 ml-xl-0 main-content">
    <MobileBar title="Remuneración Variable" />
    <div class="container pt-md-4">
      <div class="row">
        <div class="col-12 mt-4">
          <BreadCrumb :crumbs="breadcrumb" />
        </div>
      </div>
      <div class="row mt-0 mt-md-4 align-items-center">
        <div class="col-12 col-md-5">
          <p
            class="tw-text-gray-600 tw-text-xl fs-md-40 tw-font-tolyerBold mb-0 d-none d-md-block"
          >
            Remuneración Variable
          </p>
        </div>
        <div class="col-12 col-md-7">
          <div
            class="d-flex justify-content-center flex-wrap justify-content-md-end"
          >
            <router-link
              to="/showMeTheMoney/remuneracion-variable/historial"
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-flex text-center h-100 align-items-center mr-2"
              @click.native="pushDataLayer('Historial')"
            >
              Historial
            </router-link>
            <router-link
              to="/showMeTheMoney/variable-calculator"
              class="tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-flex text-center h-100 align-items-center mr-2"
              @click.native="pushDataLayer('Simulador')"
            >
              Simulador
            </router-link>
          </div>
        </div>
      </div>
      <div class="row mt-0 mt-md-5">
        <div class="col-12 col-md-6 col-lg-4">
          <CompFollow :followData="followData" />
          <CompPaymentPercent :paymentPercentData="paymentPercentData" />
        </div>
        <div class="col-12 col-md-6 col-lg-8">
          <CompVariableCompensationCarousel :slides="slides" class="mb-4" />
        </div>
      </div>
      <div class="row mt-3 mt-md-4 mb-4">
        <div class="col-12 col-lg-5 tabs-red">
          <CompRankingTabs />
        </div>
        <div class="col-12 col-lg-7 mt-4 mt-md-0">
          <CompTableProgressMonth />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import apiBeerOffice from "@/services/apiBeerOffice";
import BreadCrumb from "@/components/general/BreadCrumb";
import MobileBar from "@/components/general/MobileBar";
import CompVariableCompensationCarousel from "@/components/carousels/CompCarouselVariableCompensation.vue";
import CompFollow from "@/components/show-me-the-money/variable-compensation/CompFollow";
import CompPaymentPercent from "@/components/show-me-the-money/variable-compensation/CompPaymentPercent";
import CompRankingTabs from "@/components/show-me-the-money/variable-compensation/CompRankingTabs";
import CompTableProgressMonth from "@/components/show-me-the-money/variable-compensation/CompTableProgressMonth";

export default {
  name: "VariableCompensation",
  components: {
    BreadCrumb,
    CompVariableCompensationCarousel,
    MobileBar,
    CompFollow,
    CompPaymentPercent,
    CompRankingTabs,
    CompTableProgressMonth,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Remuneración variable",
          link: "/showMeTheMoney/remuneracion-variable",
          active: true,
        },
      ],
      followData: {},
      paymentPercentData: {},
      slides: [],
    };
  },
  created() {
    apiBeerOffice.getFollowMeData().then((data) => {
      this.followData = data.data.dailyFollow;
      this.paymentPercentData = data.data.paymentPercentage;
    });
    this.actGetBannerById(67).then((data) => {
      this.slides = data.data.banner.slides;
      this.title = data.data.banner.name;
    });
  },
  mounted() {
    window.dataLayer.push({
      event: "Remuneración variable",
      Area: this.user.core_vp.name,
      Banda: this.user.core_pa_sideline.name,
      Cargo: this.user.core_position.name,
      Regional: this.user.core_site.core_regional.name,
      Gerencia: this.user.core_organizational_unit.name,
    });
  },
  computed: {
    ...mapState("modCore", ["user"]),
  },
  methods: {
    ...mapActions("modGeneral", ["actGetBannerById"]),
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-Compensacion",
        category: "Remuneracion",
        SubCategory: "Remuneracion Variable",
        Action: "Clics",
        Label: label,
      });
    },
  },
};
</script>

<style scoped>
.bg-success {
  background-color: #00bf79 !important;
}
.tabs-red table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}
.tabs-red td {
  border: solid 1px transparent;
  padding: 10px;
}
.tabs-red td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.tabs-red td:last-child {
  border-left-style: solid;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
