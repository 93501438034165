<script setup>
import { ref } from "vue";
import { pushLink } from "@/helpers/linkHelpers";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import config from "@/services/config";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";

const isHovering = ref(false);
const uriResources = config.beerOfficeURL;

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
  categoryForSocial: {
    type: String,
    default: "",
  },
  articleCategory: {
    type: String,
    default: "",
  },
});

function goToArticle() {
  pushDataLayer({
    event_category: "Diversidad e Inclusión",
    event_action: props.articleCategory,
    event_label: props.article.title,
    component_name: "Click",
    element_category: props.articleCategory,
  });
  pushLink(`/articles/${props.article.id}`);
}
</script>
<template>
  <div @mouseenter="isHovering = true" @mouseleave="isHovering = false">
    <div class="tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-5">
        <div class="tw-aspect-w-4 tw-aspect-h-4">
          <img
            :src="uriResources + '/' + props.article.resource"
            class="tw-rounded-2xl tw-object-cover tw-w-full tw-h-full"
            alt=""
          />
        </div>
      </div>
      <div class="tw-col-span-7">
        <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <p class="tw-m-0 tw-font-robotoBold tw-text-base">
              {{ props.article.title }}
            </p>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-xs tw-leading-4 lg:tw-leading-5 tw-line-clamp-3 lg:tw-line-clamp-2"
            >
              {{ props.article.description }}
            </p>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <comp-social-network-interactions
              :id="props.article?.id"
              :title="props.article?.title"
              :category="categoryForSocial"
              :action-for-datalayer="articleCategory"
              :user_reaction="props.article?.user_reaction"
              :amount_comments="Number(props.article?.amount_comments)"
              :amount_reactions="Number(props.article?.amount_reactions)"
              :image="props.article?.resource"
              :description="props.article?.description"
              :item="props.article"
              category-for-datalayer="Diversidad e Inclusión"
              minimal-icons-desk
            />
            <div
              class="tw-flex tw-items-center tw-cursor-pointer"
              @click="goToArticle()"
            >
              <p
                class="tw-font-roboto tw-text-xs lg:tw-leading-5 tw-text-red-350 tw-m-0"
              >
                Saber más
              </p>
              <i class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-xs"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
