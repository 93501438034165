<script setup>
import { ref, onBeforeMount, watchEffect } from "vue";
import config from "@/services/config";
import { useNavbarStore } from "@/stores/navbarStore";
import { useUserStore } from "@/stores/userStore";
import { useRoute, useRouter } from "vue-router/composables";
import CompFavoritesOption from "@/components/favorites/CompFavoritesOption.vue";

const route = useRoute();
const router = useRouter();
const navbarStore = useNavbarStore();
const userStore = useUserStore();
const uriResources = config.beerOfficeURL;
const modalView = ref(false);
const showEditButton = ref(false);
const isLoading = ref(false);
const userShortcuts = ref([]);

const goToView = function (urlPath, brandname) {
  pushDataLayer(brandname);
  router.push({ path: urlPath });
};

const showModal = function () {
  navbarStore.hideSupportButton();
  modalView.value = true;
};

const hideModal = function () {
  navbarStore.showSupportButton();
  modalView.value = false;
};

const pushDataLayer = function (brandname) {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Interacciones laterales",
    event_label: brandname,
    interaction: "True",
    component_name: "Click",
  });
};

const updateUserShortcuts = () => {
  userShortcuts.value = [...userStore.getSectionShortcuts()];

  if (userShortcuts.value.length > 0) {
    userShortcuts.value.forEach((shortcut) => {
      navbarStore.shortcutSections.forEach((navbarShortcut) => {
        if (shortcut.id === navbarShortcut.id) {
          Object.assign(shortcut, navbarShortcut);
        }
      });
    });
  }
};

const routeIncludesShortcut = (url) => {
  return route.path.includes(url);
};

onBeforeMount(async () => {
  isLoading.value = true;

  watchEffect(() => {
    updateUserShortcuts();
  });

  await navbarStore.actGetShortcutSections().then(() => {
    navbarStore.shortcutSections.length > 0
      ? (showEditButton.value = true)
      : (showEditButton.value = false);
  });
  updateUserShortcuts();
  isLoading.value = false;
});
</script>

<template>
  <div id="mainSideBar">
    <div
      class="tw-fixed tw-bg-white tw-shadow-mobileBar md:tw-shadow-lg tw-bottom-2 tw-rounded-2.5xl lg:tw-rounded-none tw-left-1/2 tw-transform tw--translate-x-1/2 md:tw-translate-x-0 md:tw-top-0 md:tw-left-0 tw-h-auto md:tw-h-full tw-w-auto md:tw-w-20 md:tw-pt-24 lg:tw-pt-[7rem] tw-z-[45] lg:tw-w-[70px]"
    >
      <div
        class="tw-h-full tw-items-center tw-gap-2 tw-px-4 tw-pt-4 tw-pb-2 md:tw-pt-4 md:tw-pb-0 md:tw-px-2 tw-grid tw-grid-flow-col md:tw-flex md:tw-flex-col md:tw-space-y-6"
        v-if="!isLoading"
      >
        <div
          class="tw-group tw-flex tw-flex-col tw-text-center tw-cursor-pointer tw-w-full xs:tw-w-16 tw-h-16 md:tw-h-auto tw-items-center tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-b-0 tw-border-t-0 tw-relative"
          v-for="(favShortcut, idx) in userShortcuts"
          :key="idx"
          @click="
            route.path === favShortcut.url
              ? '#'
              : goToView(favShortcut.url, favShortcut.title)
          "
        >
          <div
            class="tw-h-5/6 tw-w-full tw-absolute tw-z-0 tw--m-2 md:tw-m-0 md:tw--mr-2 tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-b-0 tw-border-t-0 md:tw-border-r-2 md:tw-border-transparent md:group-hover:tw-border-red-350"
            :class="
              routeIncludesShortcut(favShortcut.url)
                ? 'tw-border-t-2 tw-border-red-350 md:tw-border-red-350 md:tw-border-t-0'
                : ''
            "
          />
          <div
            class="tw-relative tw-w-9 tw-h-9 tw-flex tw-items-center tw-justify-center"
          >
            <img
              class="tw-absolute tw-rounded-md tw-mb-1 tw-object-cover tw-w-8 tw-h-8 xs:tw-w-9 xs:tw-h-9 tw-top-0 group-hover:tw-opacity-100"
              :class="
                routeIncludesShortcut(favShortcut.url)
                  ? 'tw-opacity-100'
                  : 'tw-opacity-0'
              "
              loading="lazy"
              :src="uriResources + '/' + favShortcut.image_hover"
              :alt="favShortcut.title"
            />
            <img
              class="tw-absolute tw-rounded-md tw-mb-1 tw-object-cover tw-w-8 tw-h-8 xs:tw-w-9 xs:tw-h-9 tw-top-0"
              :class="[
                favShortcut.image_hover ? 'group-hover:tw-opacity-0' : '',
                routeIncludesShortcut(favShortcut.url)
                  ? 'tw-opacity-0'
                  : 'tw-opacity-100',
              ]"
              loading="lazy"
              :src="uriResources + '/' + favShortcut.image"
              :alt="favShortcut.title"
            />
          </div>
          <p
            class="tw-mb-0 tw-font-roboto tw-pt-2"
            style="font-size: 9px; line-height: 10px"
          >
            {{ favShortcut.title }}
          </p>
        </div>
        <div
          class="tw-flex tw-flex-col tw-text-center tw-items-center tw-cursor-pointer tw-w-12 xs:tw-w-14 md:tw-w-16 tw-h-16"
          v-if="showEditButton"
          @click="showModal"
        >
          <div
            class="tw-w-7 xs:tw-w-9 tw-h-9 tw-flex tw-items-center tw-justify-center"
          >
            <i class="tw-text-3xl md:tw-text-3xl tw-text-black icon-editar"></i>
          </div>
          <p class="tw-mb-0 tw-font-robotoBold tw-pt-2 tw-text-xxs">Editar</p>
        </div>
      </div>
    </div>
    <CompFavoritesOption v-if="modalView" @closeModal="hideModal" />
  </div>
</template>
