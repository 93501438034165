<template>
  <div class="tw-shadow-md p-3 tw-rounded-lg">
    <div class="tw-grid tw-grid-cols-12 tw-gap-4">
      <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4">
        <div class="border-1">
          <img
            :src="uriResources + '/' + this.questions[actualQuestion].image"
            alt="mark"
            width="100%"
            height="auto"
            class="border-1"
          />
        </div>
      </div>
      <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-8 tw-pt-4 md:tw-pt-6 tw-relative">
        <form
          :id="'triviaForm-' + this.triviaId"
          @submit.prevent="nextQuestion()"
        >
          <QuestionTrivia
            :question="this.questions[actualQuestion]"
            :actualQuestion="actualQuestion"
            @setActualAnswer="setActualAnswer"
          />
          <div class="tw-mt-5 tw-items-center tw-grid tw-grid-cols-12 tw-gap-4">
            <div class="tw-col-span-12 lg:tw-col-span-9">
              <div class="tw-flex tw-mb-4 md:tw-mb-0 tw-space-x-2">
                <div
                  v-for="(_, index) in questions"
                  :key="'question-item-' + index"
                  class="tw-rounded-full tw-w-3 tw-h-3"
                  :class="actualQuestion === index ? 'tw-bg-red-400' : 'tw-bg-red-300'"
                ></div>
              </div>
            </div>
            <div class="tw-col-span-12 lg:tw-col-span-3">
              <button
                class="
                  tw-bg-red-400
                  tw-text-white
                  tw-p-4
                  tw-rounded-lg
                  tw-text-center
                  hover:tw-bg-red-600
                  tw-border-none
                "
                @click="pushDataLayer(questions[actualQuestion].question)"
                type="submit"
              >
                Continuar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import ApiBeerOffice from "../../services/apiBeerOffice";
import QuestionTrivia from "@/components/trivia/QuestionTrivia";

export default {
  name: "QuestionsTrivia",
  props: {
    questions: {
      type: Array,
      required: true,
    },
    triviaId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
    }
  },
  components: {
    QuestionTrivia,
  },
  data() {
    return {
      actualQuestion: 0,
      uriResources: config.beerOfficeURL,
      answers: {
        answers: [],
      },
      actualQuestionId: null,
      actualAnswerId: null,
    };
  },
  methods: {
    nextQuestion() {
      if (
        document.getElementById("triviaForm-" + this.triviaId).checkValidity()
      ) {
        if (this.actualQuestion < this.questions.length) {
          this.addAnswer(this.actualQuestionId, this.actualAnswerId);
          this.actualQuestion++;
        }
        if (this.actualQuestion === this.questions.length) {
          this.actualQuestion = this.questions.length - 1;
          this.sendAnswer();
        }
      }
    },
    setActualAnswer(dataAnswer) {
      this.actualQuestionId = dataAnswer.questionId;
      this.actualAnswerId = dataAnswer.answerId;
    },
    addAnswer(comp_question_id, comp_answer_id) {
      this.answers.answers.push({
        comp_question_id: comp_question_id,
        comp_answer_id: comp_answer_id,
      });
    },
    sendAnswer() {
      ApiBeerOffice.answerTrivia(this.answers, this.triviaId).then(
        (response) => {
          this.$emit("changeResponseTrivia", response);
          if (response.completed) {
            this.$emit("changeOpcVisible", 4);
          } else {
            this.$emit("changeOpcVisible", 3);
          }
        }
      );
    },
    pushDataLayer(question) {
      window.dataLayer.push({
        event: "TrackEvent-trivias",
        category: "Trivias",
        SubCategory: this.title,
        Action: "Paso 2: " + question ,
      })
    },
  },
};
</script>

<style scoped>
.border-1 {
  border-radius: 10px 120px 10px 10px;
}
</style>
