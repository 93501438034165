<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useRoute } from "vue-router/composables";
import { useNavbarStore } from "@/stores/navbarStore";

const route = useRoute();
const navbarStore = useNavbarStore();
const target = ref(null);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);

const emit = defineEmits(["close"]);

const props = defineProps({
  modalName: {
    type: String,
    required: true,
  },
  isShowModal: {
    type: Boolean,
    default: false,
  },
  modalWidth: {
    type: Object,
    required: true,
  },
  maxHeight: {
    type: Object,
    required: true,
  },
  closable: {
    type: Boolean,
    default: true,
  },
  backdrop: {
    type: Boolean,
    default: true,
  },
  backdropOpacity: {
    type: Number,
    default: 0.5,
  },
  coordinates: {
    type: Object,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
  hideButtonSupport: {
    type: Boolean,
    default: false,
  },
  paddingX: {
    type: Boolean,
    default: true,
  },
  paddingY: {
    type: Boolean,
    default: true,
  },
  overall: {
    type: Boolean,
    default: false,
  },
  widthFit: {
    type: Boolean,
    default: false,
  },
  heightFit: {
    type: Boolean,
    default: false,
  },
});

const widthContainer = computed(() => {
  if (props.widthFit) {
    return "fit-content;";
  } else if (props.tiny === true) {
    return "auto;";
  } else if (windowWidth.value >= 1280) {
    if (props.modalWidth.xl) {
      return props.modalWidth.xl + "px;";
    } else {
      return props.modalWidth.lg + "px;";
    }
  } else if (windowWidth.value >= 1024) {
    return props.modalWidth.lg + "px;";
  } else if (windowWidth.value >= 768) {
    return props.modalWidth.md + "px;";
  } else {
    return "95vw;";
  }
});

const maxHeightContainer = computed(() => {
  if (windowWidth.value >= 1280) {
    return props.maxHeight.lg + "px;";
  } else if (windowWidth.value >= 1024) {
    return props.maxHeight.md + "px;";
  } else {
    return props.maxHeight.base + "px;";
  }
});

const handleClose = () => {
  navbarStore.showSupportButton();
  emit("close");
};

const calculateCoordinatesY = () => {
  if (
    windowHeight.value - props.coordinates.y <
    Number(maxHeightContainer.value.replace(/\D/g, "")) + 20
  ) {
    return (
      props.coordinates.y -
      Number(maxHeightContainer.value.replace(/\D/g, "")) -
      10
    );
  } else {
    return props.coordinates.y + 20;
  }
};

const calculateCoordinatesX = () => {
  if (windowWidth.value < 768) {
    return windowWidth.value - props.modalWidth.base;
  } else {
    return props.coordinates.x;
  }
};

onClickOutside(target, () => {
  if (props.closable) {
    handleClose();
  }
});

watch(
  () => route,
  () => {
    handleClose();
  }
);

onMounted(() => {
  if (props.isShowModal && props.hideButtonSupport) {
    navbarStore.hideSupportButton();
  }
});
</script>
<template>
  <div
    id="modal-general"
    :class="
      props.isShowModal && props.backdrop && props.overall
        ? 'active tw-h-screen tw-w-full tw-z-100'
        : props.isShowModal && props.backdrop && !props.overall
        ? 'active tw-h-screen tw-w-full tw-z-40'
        : props.isShowModal && !props.backdrop
        ? 'tw-z-100'
        : 'tw-h-0 tw-w-full'
    "
    :style="
      props.isShowModal && !props.backdrop
        ? 'top: ' +
          calculateCoordinatesY() +
          'px; left: ' +
          calculateCoordinatesX() +
          'px;'
        : props.isShowModal && props.backdrop
        ? 'background-color: rgba(0, 0, 0, ' + props.backdropOpacity + ');'
        : ''
    "
    class="tw-fixed"
    @mouseleave="props.tiny ? handleClose() : ''"
  >
    <div
      :id="modalName"
      :class="
        props.isShowModal && !props.tiny
          ? 'active tw-transform tw-translate-y-4 md:tw-translate-y-8 lg:tw-translate-y-10'
          : 'active'
      "
      :style="`width: ${widthContainer}; max-height: ${maxHeightContainer} ${
        heightFit ? 'height: fit-content;' : 'height: auto;'
      }`"
      class="modal-container tw-mx-auto"
    >
      <div
        ref="target"
        :class="[
          props.tiny
            ? 'tw-shadow-lg tw-px-3 tw-gap-x-4'
            : 'tw-relative tw-shadow-sm tw-overflow-hidden tw-gap-4',
          props.paddingX ? 'tw-px-6 md:tw-px-7 lg:tw-px-10' : '',
          props.paddingY ? 'tw-py-3 md:tw-py-4 lg:tw-py-5' : '',
        ]"
        :style="'max-height: ' + maxHeightContainer"
        class="inside tw-bg-white tw-h-auto tw-w-full tw-max-h-full tw-rounded-3xl tw-flex tw-flex-col tw-relative"
      >
        <div>
          <div v-if="$slots.title">
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl tw-text-red-350"
            >
              <slot name="title" />
            </p>
          </div>
          <div
            v-if="props.closable"
            class="tw-absolute tw-right-5 tw-top-5 md:tw-right-6 md:tw-top-6 lg:tw-right-8 lg:tw-top-8 tw-cursor-pointer tw-z-2"
            @click="handleClose"
          >
            <i
              class="icon-cerrar-simple tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-text-red-350 tw-font-black"
            ></i>
          </div>
        </div>
        <slot class="tw-z-1" />
      </div>
    </div>
  </div>
</template>

<style scoped>
#modal-general {
  transition: z-index 0.5s step-end, opacity 1s linear;
}

#modal-general.active {
  backdrop-filter: blur(6px);
}

.modal-container {
  z-index: -1;
}

.modal-container.active {
  transition: transform 0.5s ease-in;
}
</style>
