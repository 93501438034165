<script setup>
import { onBeforeMount, ref, computed } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useRouter } from "vue-router/composables";
import { capitalizeEachWord } from "@/helpers/textHelpers";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import ProductCard from "@/components/cards/ProductCard.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";

const ecommerceStore = useEcommerceStore();
const router = useRouter();
const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const max_day_gift = ref(null);

const monthProducts = computed(() => {
  return ecommerceStore.products.find((product) => product.name === "OBSEQUIO");
});

const addToCart = (product) => {
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "COP",
      add: {
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price_public,
            brand: product.ecomm_brand.name,
            category: "Obsequio",
            quantity: 1,
          },
        ],
      },
    },
  });
  ecommerceStore.addProductToShoppingCart(product);
  router.push({
    name: "MonthProductShoppingCart",
    params: { id: product.id },
  });
};

onBeforeMount(async () => {
  isLoading.value = true;
  if (ecommerceStore.products.length === 0) {
    await ecommerceStore.actGetProducts().then(() => {
      ecommerceStore.products.length === 0 ? router.push("/e-commerce") : null;
    });
  }

  max_day_gift.value = ecommerceStore.products.filter(
    (product) => product.name === "OBSEQUIO"
  )[0].max_day;

  await ecommerceStore.actGetTaDaStatus().then(() => {
    console.log(ecommerceStore.taDaAvailable, ecommerceStore.taDaDisplayable);
  });

  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Tienda" />
    <title-bar title="Tienda" />
    <main class="tw-container-general" v-if="!isLoading">
      <p class="tw-font-robotoBold tw-text-base lg:tw-text-2xl">
        ELIGE TU OBSEQUIO
      </p>
      <p class="tw-font-roboto tw-text-xs lg:tw-text-base">
        Este es uno de tus beneficios por ser parte de la familia Embajadores,
        recuerda que
        <span class="tw-text-red-350 tw-font-bold"
          >solo puedes seleccionar uno de los siguientes productos.</span
        >
      </p>
      <section
        id="products"
        class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-6"
      >
        <product-card v-if="ecommerceStore.taDaDisplayable">
          <template #image>
            <img
              src="@/assets/img/ecommerce/logo-tada.png"
              alt="Cupón TaDa"
              class="tw-w-full tw-aspect-1 tw-object-cover tw-rounded-xl lg:tw-rounded-2xl"
            />
          </template>
          <template #title>
            <p class="tw-font-robotoBold tw-m-0">Cupón de TaDa</p>
          </template>
          <template #description>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              Reclama a través de la aplicación de TaDa.<br />
              *Sujeto a términos y condiciones.
            </p>
          </template>
          <template #price>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              <span class="tw-font-robotoBold">Precio:</span> ¡OBSEQUIO!
            </p>
          </template>
          <template #button>
            <comp-button-custom
              class="tw-w-full"
              :disabled="!ecommerceStore.taDaAvailable"
              :text="!ecommerceStore.taDaAvailable ? 'Agotado' : 'Seleccionar'"
              @click.native="router.push('/e-commerce/couponDetail')"
            />
          </template>
        </product-card>
        <product-card
          v-for="(product, idx) in monthProducts.ecomm_product"
          :key="idx"
        >
          <template #image>
            <img
              :src="`${uriResources}/${product.img}`"
              :alt="product.name"
              class="tw-w-full tw-aspect-1 tw-object-cover tw-rounded-xl lg:tw-rounded-2xl"
            />
          </template>
          <template #title>
            <p class="tw-font-robotoBold tw-m-0 tw-text-center">
              {{ capitalizeEachWord(product.name) }}
            </p>
          </template>
          <template #description>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              {{ product.description }}
            </p>
          </template>
          <template #price>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              <span class="tw-font-robotoBold">Precio:</span> ¡OBSEQUIO!
            </p>
          </template>
          <template #button>
            <comp-button-custom
              class="tw-w-full"
              text="Seleccionar"
              @click.native="addToCart(product)"
            />
          </template>
        </product-card>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
