<template>
  <div
    class="tw-relative tw-flex tw-flex-col tw-justify-center"
    :id="item ? 'slider_' + item.id : 'slider'"
  >
    <div
      class="tw-flex tw-justify-center tw-gap-2 tw-mb-2"
      v-if="!inner && !aspect"
    >
      <div
        v-for="(_, idx) in content.length"
        :key="idx"
        @click="changeSlide(idx)"
      >
        <div
          class="tw-w-2 tw-h-2 md:tw-w-3 md:tw-h-3 tw-rounded-full tw-cursor-pointer"
          :class="
            idx === selected
              ? 'tw-bg-red-350'
              : inner
              ? ' tw-bg-gray-50'
              : 'tw-bg-gray-300'
          "
        ></div>
      </div>
    </div>
    <div
      class="tw-h-auto tw-w-full tw-relative"
      :class="
        aspect === 'horizontal'
          ? ''
          : 'tw-flex tw-justify-center tw-overflow-hidden'
      "
    >
      <transition name="slide-fade" mode="out-in" tag="div">
        <div
          :key="selected"
          :class="
            aspect === 'horizontal'
              ? 'tw-aspect-w-16 tw-aspect-h-9'
              : 'tw-flex tw-overflow-hidden'
          "
          class="tw-relative"
        >
          <div
            v-if="blockedUser"
            class="tw-flex tw-justify-center tw-items-center tw-absolute tw-z-2"
          >
            <i class="icon-empty tw-text-white tw-text-7xl lg:tw-text-9xl"></i>
          </div>
          <img
            :src="linkImg"
            alt=""
            class="tw-select-none"
            :class="[
              blockedUser ? 'tw-filter tw-blur-sm' : '',
              linkeable ? 'tw-cursor-pointer' : '',
              aspect === 'horizontal'
                ? 'tw-w-full tw-object-cover'
                : 'tw-object-contain',
            ]"
            @click="linkeable && !blockedUser ? goToLink() : null"
          />
        </div>
      </transition>
      <div
        v-if="inner || aspect === 'horizontal'"
        class="tw-w-full tw-flex tw-m-auto lg:tw-m-0 tw-gap-1 tw-mt-4 md:tw-gap-2 lg:tw-mt-5 tw-pr-1"
        :class="[
          inner ? 'tw-absolute tw-bottom-3' : '',
          side === 'left'
            ? 'tw-justify-start'
            : side === 'right'
            ? 'tw-justify-end'
            : 'tw-justify-center',
        ]"
      >
        <div
          v-for="(_, idx) in content.length"
          :key="idx"
          @click="changeSlide($event, idx)"
        >
          <div
            class="tw-w-2 tw-h-2 md:tw-w-3 md:tw-h-3 tw-rounded-full tw-cursor-pointer"
            :class="
              idx === selected
                ? 'tw-bg-red-350'
                : inner
                ? ' tw-bg-gray-50'
                : 'tw-bg-gray-300'
            "
          ></div>
        </div>
      </div>
    </div>
    <div
      class="tw-mt-2 tw-flex tw-flex-col tw-gap-2"
      v-if="!onlyImages && content[selected].title"
    >
      <p class="tw-m-0 tw-font-robotoBold tw-text-sm md:tw-text-lg">
        {{ content[selected].title }}
      </p>
      <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
        {{ content[selected].description }}
      </p>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
let touchstartX = 0;
let touchendX = 0;

export default {
  name: "ImageSlider",
  data() {
    return {
      uriResources: config.beerOfficeURL,
      selected: this.sel,
    };
  },
  props: {
    content: {
      type: Array,
    },
    sel: {
      type: Number,
      default: 0,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      default: "center",
    },
    onlyImages: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    linkeable: {
      type: Boolean,
      default: false,
    },
    aspect: {
      type: String,
    },
    blockedUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    transform() {
      const transformArg = `translateX(${500}%)`;
      return { transform: transformArg };
    },
    // transitionX() {
    //   const transitionArg = `${this.transition}`;
    //   return { transition: transitionArg };
    // },
    linkImg() {
      if (!this.content[this.selected].image) {
        if (
          this.content[this.selected].includes("http://") ||
          this.content[this.selected].includes("https://")
        ) {
          return this.content[this.selected];
        } else {
          return this.uriResources + "/" + this.content[this.selected];
        }
      } else {
        if (
          this.content[this.selected].image.includes("http://") ||
          this.content[this.selected].image.includes("https://")
        ) {
          return this.content[this.selected].image;
        } else {
          return this.uriResources + "/" + this.content[this.selected].image;
        }
      }
    },
  },
  mounted() {
    const vm = this;
    const slider = this.item
      ? document.getElementById(`slider_${this.item.id}`)
      : document.getElementById("slider");

    slider.addEventListener("touchstart", (e) => {
      touchstartX = e.changedTouches[0].screenX;
    });

    slider.addEventListener("mousedown", (e) => {
      e.preventDefault();
      touchstartX = e.screenX;
    });

    slider.addEventListener("touchend", (e) => {
      touchendX = e.changedTouches[0].screenX;
      handleGesture();
    });

    slider.addEventListener("mouseup", (e) => {
      touchendX = e.screenX;
      handleGesture();
    });

    function handleGesture() {
      if (touchendX < touchstartX) {
        vm.selected === vm.content.length - 1
          ? (vm.selected = 0)
          : vm.selected++;
      }
      if (touchendX > touchstartX) {
        vm.selected === 0
          ? (vm.selected = vm.content.length - 1)
          : vm.selected--;
      }
    }
  },

  methods: {
    next: function () {
      this.selected += 1;
      if (this.selected === this.content.length) {
        this.selected = 0;
      }
    },
    prev: function () {
      this.selected -= 1;
    },
    changeSlide(event, i) {
      event.stopPropagation();
      this.selected = i;
    },
    goToLink() {
      this.$router.push({
        name: "ReservationsSpace",
        params: { id: this.item.id },
      });
    },
  },
};
</script>

<style scoped>
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter {
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(20%);
  opacity: 0;
}
</style>
