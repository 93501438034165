<script setup>
import { ref, onBeforeMount } from "vue";
import config from "@/services/config";
import { pushLink } from "@/helpers/linkHelpers";
import { useArticlesStore } from "@/stores/articlesStore";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";

const store = useArticlesStore();
const uriResources = config.beerOfficeURL;
const isLoading = ref(false);
const politicsDiversity = ref([]);
const compromisesDiversity = ref([]);

function goToArticle(article, action, event) {
  pushLink(`/articles/${article.id}`, {
    event_category: "Diversidad e Inclusión",
    event_action: action,
    event_label: article.title,
    component_name: event,
    element_category: "Políticas",
  });
}

onBeforeMount(async () => {
  isLoading.value = true;
  await store
    .actGetArticlesByCategory("politicas_diversidad_e_inclusion")
    .then(() => {
      politicsDiversity.value = store.articles;
    });
  await store
    .actGetArticlesByCategory("compromisos_diversidad_e_inclusion")
    .then(() => {
      compromisesDiversity.value = store.articles;
    });
  isLoading.value = false;
});
</script>
<template>
  <div>
    <mobile-bar title="Diversidad, Equidad e Inclusión" />
    <title-bar title="Diversidad, Equidad e Inclusión" />
    <div class="tw-container-general tw-pb-0">
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-mb-5 lg:tw-my-5 lg:tw-mt-10 lg:tw-gap-6"
      >
        <div
          class="tw-order-2 lg:tw-order-1 tw-flex tw-flex-col tw-justify-center lg:tw-col-span-5 tw-gap-4 tw-mt-5 lg:tw-m-auto"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-2xl tw-hidden lg:tw-block"
          >
            Nuestras políticas
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-hidden lg:tw-block"
          >
            Como compañía hemos establecido la existencia de políticas asociadas
            a Diversidad, Equidad e Inclusión, siendo la base fundamental para
            mantener un ambiente laboral justo, equitativo e inclusivo, que
            construya sobre el propósito de la compañía:
            <span class="tw-font-robotoBold">
              “Soñamos en grande para crear un futuro con más motivos para
              brindar”</span
            >
          </p>
        </div>
        <div class="tw-order-1 lg:tw-order-2 lg:tw-col-span-7 hijo">
          <div
            class="tw-rounded-br-3xl tw-rounded-bl-3xl tw-rounded-tr-none tw-rounded-tl-none lg:tw-rounded-3xl tw-bg-purple-100 lg:tw-aspect-h-9 lg:tw-aspect-w-16"
          >
            <div
              class="tw-w-full tw-h-full tw-p-8 lg:tw-p-10 tw-flex tw-flex-col tw-gap-2"
            >
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-xl tw-text-white lg:tw-hidden tw-text-center"
              >
                Nuestras políticas
              </p>
              <div
                class="tw-rounded-3xl lg:tw-rounded-bl-3xl lg:tw-rounded-tl-3xl lg:tw-rounded-br-none lg:tw-rounded-tr-none tw-w-full tw-h-full tw-overflow-hidden"
              >
                <video
                  ref="videoRef"
                  class="tw-w-full tw-h-full tw-object-contain"
                  autoplay
                  muted
                  loop
                >
                  <source
                    src="@/assets/videos/diversity_our_politics.mp4"
                    type="video/mp4"
                  />
                  Su navegador no soporta videos.
                </video>
                <!--                <img
                  src="@/assets/img/diversity/ourpolitics.svg"
                  class="tw-object-contain tw-w-full tw-h-full"
                  alt=""
                />-->
              </div>
              <p class="tw-m-0 tw-text-sm tw-text-white lg:tw-hidden tw-block">
                Como compañía hemos establecido la existencia de políticas
                asociadas a Diversidad, Equidad e Inclusión, siendo la base
                fundamental para mantener un ambiente laboral justo, equitativo
                e inclusivo, que construya sobre el propósito de la compañía:
                <span class="tw-font-robotoBold">
                  “Soñamos en grande para crear un futuro con más motivos para
                  brindar”</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr class="tw-w-full tw-my-10 tw-hidden lg:tw-block" />
      <div
        class="tw-grid tw-grid-flow-row tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 tw-my-6 lg:tw-my-10"
        v-if="!isLoading"
      >
        <div v-for="(item, idx) in politicsDiversity" :key="idx">
          <div
            class="tw-aspect-h-12 tw-aspect-w-16 tw-rounded-3xl tw-overflow-hidden tw-cursor-pointer"
            @click="goToArticle(item, 'Políticas', 'Click')"
          >
            <img
              class="tw-w-full tw-h-full tw-object-cover"
              :src="uriResources + '/' + item.resource"
              alt
            />
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <div class="tw-flex tw-flex-col tw-gap-6">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-text-red-350"
          >
            Nuestros compromisos
          </p>
          <p class="tw-m-0 tw-text-sm lg:tw-text-base">
            Nuestro sueño es crear un ambiente inclusivo donde todas las
            personas pueden ser su mejor versión y para que esto sea una
            realidad es necesario mantener nuestras creencias, guiando el
            comportamiento de las personas que hacen parte de la compañía y
            tomando decisiones de manera consistente.
          </p>
        </div>
        <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-6">
          <div v-for="(item, idx) in compromisesDiversity" :key="idx">
            <div
              class="tw-h-full tw-border tw-border-gray-350 tw-rounded-3xl tw-shadow-sm lg:tw-shadow-lg tw-p-2 lg:tw-px-2 lg:tw-py-6 tw-grid tw-grid-cols-6 tw-gap-4"
            >
              <div
                class="tw-col-span-2 tw-rounded-2xl tw-overflow-hidden tw-flex tw-justify-center tw-items-center tw-bg-blue-200 tw-aspect-w-4 tw-aspect-h-4"
              >
                <img
                  :src="uriResources + '/' + item.resource"
                  class="tw-object-cover tw-w-full tw-h-full"
                  alt=""
                />
              </div>
              <div class="tw-col-span-4 tw-flex tw-flex-col tw-justify-between">
                <p
                  class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-sm lg:tw-text-base"
                >
                  {{ item.title }}
                </p>
                <p
                  class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-leading-6 tw-line-clamp-5 lg:tw-line-clamp-5"
                >
                  {{ item.description }}
                </p>
                <div
                  class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                  @click="
                    goToArticle(item, 'Nuestros compromisos', 'Saber más')
                  "
                >
                  <p
                    class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-red-350 tw-underline"
                  >
                    Saber más
                  </p>
                  <i
                    class="icon-flecha-der tw-text-red-350 tw-font-bold tw-text-xxs"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6 tw-mt-6 lg:tw-mt-10">
        <div class="tw-flex tw-flex-col tw-gap-6">
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-text-red-350"
          >
            Nuestras metas
          </p>
          <img
            class="tw-mt-4 tw-mb-4 tw-w-full tw-hidden lg:tw-block"
            src="@/assets/img/diversity/ourgoals_desk.png"
            alt=""
          />
          <img
            class="tw-mt-4 tw-mb-4 tw-w-full tw-block lg:tw-hidden"
            src="@/assets/img/diversity/ourgoals_mobile.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .hijo {
    position: relative;
    width: 100vw;
    left: calc(50% - 50vw);
  }
}
</style>
