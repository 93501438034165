<script setup>
import { pushLink } from "@/helpers/linkHelpers";
import { ref } from "vue";
import config from "@/services/config";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";
import { removeHTMLTagsFromText } from "@/helpers/textHelpers";

const isHovering = ref(false);
const uriResources = config.beerOfficeURL;

const emit = defineEmits(["compartir", "updateReaction", "updatedComment"]);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  category: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  link: {
    type: String,
    default: null,
  },
  image: {
    type: String,
    default: null,
  },
  categoryForSocial: {
    type: String,
    default: "",
  },
  datalayerActive: {
    type: Boolean,
    default: false,
  },
  categoryForDatalayer: {
    type: String,
    default: "",
  },
  actionForDatalayer: {
    type: String,
    default: "",
  },
  imageCover: {
    type: Boolean,
    default: false,
  },
  mainTitle: {
    type: Boolean,
    default: false,
  },
  mobileButton: {
    type: Boolean,
    default: false,
  },
});

function changeHovering(boolean) {
  isHovering.value = boolean;
}

function goToLink() {
  let datalayer = null;
  if (props.datalayerActive) {
    datalayer = {
      event_category: props.categoryForDatalayer,
      event_action: "Destacado",
      event_label: "Saber más",
      component_name: "Click",
    };
  }
  pushLink(props.link, datalayer);
}
</script>

<template>
  <section>
    <div
      class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4 lg:tw-gap-x-10 lg:tw-gap-y-4 tw-pb-4 lg:tw-pb-6"
      @mouseenter="changeHovering(true)"
      @mouseleave="changeHovering(false)"
    >
      <div class="tw-col-span-1 lg:tw-col-span-12" v-if="mainTitle">
        <p class="tw-m-0 tw-font-robotoBold tw-text-2xl lg:tw-text-4xl">
          Destacado
        </p>
      </div>
      <div class="lg:tw-col-span-7 tw-flex tw-flex-col tw-gap-4">
        <div>
          <img
            :src="uriResources + '/' + props.image"
            class="tw-w-full tw-h-72 lg:tw-h-auto lg:tw-max-h-[22rem] tw-rounded-2xl lg:tw-rounded-3xl"
            :class="imageCover ? 'tw-object-cover' : 'tw-object-contain'"
            alt=""
          />
        </div>
      </div>
      <div class="lg:tw-col-span-5 tw-flex tw-flex-col tw-justify-end">
        <div class="tw-grid tw-grid-cols-12 tw-gap-4 lg:tw-gap-8">
          <div class="tw-col-span-6 lg:tw-col-span-12 tw-order-1">
            <p
              class="tw-m-0 tw-font-roboto tw-underline tw-text-xs lg:tw-text-base"
            >
              {{ props.category }}
            </p>
          </div>
          <div
            class="tw-col-span-12 tw-order-3 lg:tw-order-2 tw-flex tw-flex-col tw-gap-2"
          >
            <p
              class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl tw-line-clamp-2"
            >
              {{ props.title }}
            </p>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-line-clamp-3"
            >
              {{ removeHTMLTagsFromText(props.description) }}
            </p>
          </div>
          <div
            class="tw-col-span-6 lg:tw-col-span-12 tw-order-2 lg:tw-order-3 tw-flex tw-justify-end lg:tw-justify-start swiper-no-swiping"
          >
            <comp-social-network-interactions
              :item="props.item"
              :id="
                props.item.postable_id ? props.item.postable_id : props.item.id
              "
              :title="props.title"
              :description="props.description"
              :image="props.image"
              :amount_reactions="Number(props.item.amount_reactions)"
              :amount_comments="Number(props.item.amount_comments)"
              :user_reaction="props.item.user_reaction"
              :category="props.categoryForSocial"
              :category-for-datalayer="props.categoryForDatalayer"
              :action-for-datalayer="props.actionForDatalayer"
              :hover="isHovering"
              @updatedComment="emit('updatedComment', $event)"
              @updatedReaction="emit('updatedReaction', $event)"
            />
          </div>
          <div class="tw-col-span-12 tw-order-4" @click="goToLink">
            <div
              class="tw-flex tw-items-center tw-cursor-pointer lg:tw-hidden"
              :class="mobileButton ? 'tw-hidden' : ''"
            >
              <p
                class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
              >
                Saber más
              </p>
              <i
                class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-xl"
              ></i>
            </div>
            <div
              class="tw-btn-primary-red lg:tw-block tw-bg-red-400"
              :class="mobileButton ? 'tw-block' : 'tw-hidden'"
            >
              <p class="tw-m-0">Saber más</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
p {
  margin: 0;
}
</style>
