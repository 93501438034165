var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ml-0 md:tw-ml-20 ml-xl-0"},[_c('MobileBar',{attrs:{"title":_vm.competition.name}}),_c('div',{staticClass:"container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"},[_c('div',{staticClass:"col-12 col-lg-8 col-md-6 px-0 order-md-1 order-last mx-auto"},[_c('div',{staticClass:"d-none d-md-flex"},[_c('p',{staticClass:"tw-font-tolyerBold fs-md-40 text-uppercase"},[_vm._v(" "+_vm._s(_vm.competition.name)+" ")])])])]),_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"row mt-0 mt-md-4"},[_c('div',{staticClass:"col-12 col-md-8 mx-auto",staticStyle:{"height":"300px"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.uriResources + '/' + _vm.competition.comp_question[_vm.idQ].image,"alt":"Imagen pregunta","width":"100%","height":"100%"}})])]),_c('div',{staticClass:"bg-white tw-rounded-lg-20 z-index-10 position-relative pt-1 mt-n3 mt-md-0 md:tw-rounded-none pt-md-2"},[_c('div',{staticClass:"row mt-4 px-3 px-md-0"},[_c('div',{staticClass:"col-12 col-lg-8 mx-auto"},[_c('div',{staticClass:"cont-question mb-4 mb-md-2"},[_c('div',{staticClass:"cont-text-question"},[_c('h2',{staticClass:"mb-4 tw-text-3xl md:tw-text-4xl tw-font-tolyerBold"},[_vm._v(" "+_vm._s(_vm.competition.comp_question[_vm.idQ].question)+" ")])])]),_c('div',{staticClass:"cont-answers mt-3 mt-lg-5"},_vm._l((_vm.competition.comp_question[_vm.idQ].comp_answer),function(answer){return _c('div',{key:answer.id,staticClass:"answer tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-text-gray-600 p-3 d-flex mb-3",class:[
                  _vm.rta !== answer.id
                    ? ''
                    : 'tw-bg-red-400 text-white tw-font-medium',
                ],on:{"click":function($event){return _vm.setRta(answer.id)}}},[_c('p',{staticClass:"text mb-0 w-100 text-center"},[_vm._v(_vm._s(answer.answer))])])}),0)]),_c('div',{staticClass:"col-12 col-lg-8 py-3 px-0 mb-5 mx-auto"},[_c('table',{staticClass:"w-100"},[_c('tr',[_c('td',_vm._l((_vm.competition.comp_question),function(conQuestion){return _c('div',{key:conQuestion.id,staticClass:"rounded-circle mt-md-2 tw-bg-gray-300 tw-bg-red-400",class:[
                      conQuestion.id !== _vm.competition.comp_question[_vm.idQ].id
                        ? ''
                        : 'porfPointsActive rounded-pill',
                    ]})}),0),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"d-none d-md-block"},[_c('a',{class:'btn btn-primary rounded-pill  background-primary ' +
                        [_vm.rta > 0 ? '' : 'disableBtn'],attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.sendRta()}}},[_vm._v("Siguiente")])]),_c('a',{class:'d-xl-none d-md-none ' + [_vm.rta > 0 ? '' : 'disableBtn'],attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.sendRta()}}},[_c('img',{staticClass:"mt-3",attrs:{"src":require("../../assets/img/icons/onboardingL.png"),"alt":""}})])])])])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }