import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  bookingPlaceCategoriesUri: "/bookingPlaceCategories",
  bookingPlacesUri: "/bookingPlaces",
  bookingsUri: "/bookings",
  bookingSeasonsUri: "/bookingSeasonCategories",
  bookingOptions: "/bookingPlaceOptions/available",
};

export const useReservationsStore = defineStore("reservations", () => {
  const bookingPlaceCategories = ref([]);
  const bookingPlacesList = ref([]);
  const bookingPlaceDetail = ref({});
  const bookingAvailableDays = ref([]);
  const bookingAvailableHours = ref([]);
  const bookingSeasons = ref([]);
  const bookingSeasonsByCategory = ref([]);
  const bookingTempFromDate = ref(null);
  const bookingTempToDate = ref(null);
  const bookingActive = ref(false);
  const bookingBlockedUser = ref(false);
  const bookings = ref([]);
  const bookingPlaceOptions = ref([]);
  const bookingHighSeasonsDays = ref([]);
  const isBookingModalOpen = ref(false);
  const bookingStep = ref(1);
  const bookingDatesSelected = ref(null);
  const bookingIsHighSeason = ref(false);
  const bookingPlaceSelected = ref(null);

  function getBookingAvailableHours() {
    return bookingAvailableHours.value;
  }

  function getBookings() {
    return bookings.value;
  }

  function getBookingPlacesList() {
    return bookingPlacesList.value;
  }

  async function actGetBookingPlaceCategories() {
    const response = await AxiosBeerOffice.get(
      uri.bookingPlaceCategoriesUri,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    bookingPlaceCategories.value = data;
    return data;
  }

  async function actGetBookingPlaceOptions(dates) {
    console.log("dates", dates);
    const response = await AxiosBeerOffice.get(
      `${uri.bookingOptions}?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      true,
      {}
    );
    if (response.error) return response.error;
    bookingPlaceOptions.value = response;
    return true;
  }

  async function actGetBookingHighSeasonsDays(dates) {
    const response = await AxiosBeerOffice.get(
      `${uri.bookingsUri}/highSeasonDays?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    bookingHighSeasonsDays.value = data;
    return data;
  }

  async function actGetBookingPlacesList(categoryId) {
    const response = await AxiosBeerOffice.get(
      `${uri.bookingPlaceCategoriesUri}/${categoryId}/bookingPlaces`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    bookingPlacesList.value = data;
    return data;
  }

  async function actGetBookingPlaceDetail(placeId) {
    const response = await AxiosBeerOffice.get(
      `${uri.bookingPlacesUri}/${placeId}`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    bookingPlaceDetail.value = data;
    return data;
  }

  async function actGetBookingAvailableDays(data) {
    const {
      booking_place_id,
      booking_place_option_id,
      booking_season_category_id,
      start_date,
      end_date,
    } = data;
    const response = await AxiosBeerOffice.get(
      uri.bookingsUri +
        "/availableDays?booking_place_id=" +
        booking_place_id +
        "&booking_place_option_id=" +
        booking_place_option_id +
        "&booking_season_category_id=" +
        booking_season_category_id +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date,
      true,
      {}
    );
    if (response.error) return response.error;
    bookingAvailableDays.value = response.data;
    return response.data;
  }

  async function actGetBookingAvailableHours(data) {
    const { booking_place_id, booking_place_option_id, date, full_place } =
      data;
    const response = await AxiosBeerOffice.get(
      uri.bookingsUri +
        "/availableHours?booking_place_id=" +
        booking_place_id +
        "&booking_place_option_id=" +
        booking_place_option_id +
        "&date=" +
        date +
        "&full_place=" +
        full_place,
      true,
      {}
    );
    if (response.error) return response.error;
    let hours = response.data;

    // get if hours are morning or afternoon and convert to 12h format
    hours.forEach((item) => {
      item.start_time_short = item.start_time.substring(0, 5);
      item.end_time_short = item.end_time.substring(0, 5);

      let start_time = item.start_time.split(":");
      let start_hour = start_time[0];
      let start_minute = start_time[1];
      item.start_time_short_24 = start_hour + ":" + start_minute;
      start_hour < 12 ? (item.start_timing = "AM") : (item.start_timing = "PM");
      start_hour = start_hour % 12;
      start_hour = start_hour ? start_hour : 12;
      item.start_time_short = start_hour + ":" + start_minute;

      let end_time = item.end_time.split(":");
      let end_hour = end_time[0];
      let end_minute = end_time[1];
      item.end_time_short_24 = end_hour + ":" + end_minute;
      end_hour < 12 ? (item.end_timing = "AM") : (item.end_timing = "PM");
      end_hour = end_hour % 12;
      end_hour = end_hour ? end_hour : 12;
      item.end_time_short = end_hour + ":" + end_minute;
    });

    bookingAvailableHours.value = hours;
    return response.data;
  }

  async function actGetBookings() {
    const response = await AxiosBeerOffice.get(uri.bookingsUri, true, {});
    if (response.error) return response.error;
    bookings.value = response;
    return response;
  }

  async function actGetBookingSeasons() {
    const response = await AxiosBeerOffice.get(uri.bookingSeasonsUri, true, {});
    if (response.error) return response.error;
    const { data } = response;
    bookingSeasons.value = data;
    return data;
  }

  async function actGetBookingSeasonsByCategory(categoryId) {
    const response = await AxiosBeerOffice.get(
      `${uri.bookingSeasonsUri}/${categoryId}/bookingSeasons`,
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    bookingSeasonsByCategory.value = data;
    return data;
  }

  async function actGetBookingActive() {
    const response = await AxiosBeerOffice.get(
      uri.bookingsUri + "/employeeHasActiveBooking",
      true,
      {}
    );
    if (response.error) return response.error;
    bookingActive.value = response.data;
    return response;
  }

  async function actGetBookingBlockedUser() {
    const response = await AxiosBeerOffice.get(
      uri.bookingsUri + "/employeeIsBlocked",
      true,
      {}
    );
    if (response.error) return response.error;
    const { data } = response;
    bookingBlockedUser.value = data;
    return data;
  }

  async function actPostBooking(data) {
    const response = await AxiosBeerOffice.post(uri.bookingsUri, true, data);
    if (response.error) return response.error;
    return response;
  }

  async function actPostBookingPayment(data) {
    const response = await AxiosBeerOffice.post(
      uri.bookingsUri + "/" + data.id + "/payment",
      true,
      data.data,
      true
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostBookingReview(data) {
    const response = await AxiosBeerOffice.post(
      uri.bookingPlacesUri + "/" + data.id + "/review",
      true,
      data.data
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostCancelBooking(id) {
    const response = await AxiosBeerOffice.post(
      uri.bookingsUri + "/" + id + "/cancel",
      true,
      {}
    );
    if (response.error) return response.error;
    return response;
  }

  const openBookingModal = () => {
    isBookingModalOpen.value = true;
  };

  const closeBookingModal = () => {
    isBookingModalOpen.value = false;
  };

  const setBookingDatesSelected = (dates) => {
    bookingDatesSelected.value = dates;
  };

  const selectBookingPlace = (place) => {
    bookingPlaceSelected.value = place;
  };

  return {
    bookingPlaceCategories,
    bookingPlacesList,
    bookingPlaceDetail,
    bookingAvailableDays,
    bookingAvailableHours,
    bookingSeasons,
    bookingSeasonsByCategory,
    bookingTempFromDate,
    bookingTempToDate,
    bookingActive,
    bookingBlockedUser,
    bookings,
    bookingPlaceOptions,
    bookingHighSeasonsDays,
    isBookingModalOpen,
    bookingStep,
    bookingIsHighSeason,
    bookingDatesSelected,
    bookingPlaceSelected,
    getBookingAvailableHours,
    getBookings,
    getBookingPlacesList,
    actGetBookingPlaceCategories,
    actGetBookingPlacesList,
    actGetBookingPlaceDetail,
    actGetBookingAvailableDays,
    actGetBookingAvailableHours,
    actGetBookingSeasons,
    actGetBookingSeasonsByCategory,
    actGetBookingActive,
    actGetBookingBlockedUser,
    actGetBookings,
    actGetBookingPlaceOptions,
    actGetBookingHighSeasonsDays,
    actPostBooking,
    actPostBookingPayment,
    actPostBookingReview,
    actPostCancelBooking,
    openBookingModal,
    closeBookingModal,
    setBookingDatesSelected,
    selectBookingPlace,
  };
});
