<script setup>
import { useArticlesStore } from "@/stores/articlesStore";
import { useNewsStore } from "@/stores/newsStore";
import { useRatingStore } from "@/stores/ratingStore";

const articlesStore = useArticlesStore();
const newsStore = useNewsStore();
const ratingStore = useRatingStore();

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  category: {
    type: String,
    default: "",
  },
  item: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

const closeModal = () => {
  emit("closeModal");
};

const voteRating = async (e) => {
  await ratingStore.actPostRating({
    category: props.category,
    reviewable_id: props.item.id,
    score: e.target.value,
  });
  props.type === "article"
    ? await articlesStore.actGetArticleDetail(props.item.id)
    : await newsStore.actGetNews();

  closeModal();
};
</script>
<template>
  <div id="full-stars">
    <div class="rating-group">
      <input
        disabled
        checked
        class="rating__input rating__input--none"
        name="rating3"
        id="rating3-none"
        value="0"
        type="radio"
      />
      <label aria-label="1 star" class="rating__label" for="rating3-1"
        ><i class="rating__icon rating__icon--star icon-star"></i
      ></label>
      <input
        class="rating__input"
        name="rating3"
        id="rating3-1"
        value="1"
        type="radio"
        @change="voteRating($event)"
      />
      <label aria-label="2 stars" class="rating__label" for="rating3-2"
        ><i class="rating__icon rating__icon--star icon-star"></i
      ></label>
      <input
        class="rating__input"
        name="rating3"
        id="rating3-2"
        value="2"
        type="radio"
        @change="voteRating($event)"
      />
      <label aria-label="3 stars" class="rating__label" for="rating3-3"
        ><i class="rating__icon rating__icon--star icon-star"></i
      ></label>
      <input
        class="rating__input"
        name="rating3"
        id="rating3-3"
        value="3"
        type="radio"
        @change="voteRating($event)"
      />
      <label aria-label="4 stars" class="rating__label" for="rating3-4"
        ><i class="rating__icon rating__icon--star icon-star"></i
      ></label>
      <input
        class="rating__input"
        name="rating3"
        id="rating3-4"
        value="4"
        type="radio"
        @change="voteRating($event)"
      />
      <label aria-label="5 stars" class="rating__label" for="rating3-5"
        ><i class="rating__icon rating__icon--star icon-star"></i
      ></label>
      <input
        class="rating__input"
        name="rating3"
        id="rating3-5"
        value="5"
        type="radio"
        @change="voteRating($event)"
      />
    </div>
  </div>
</template>

<style scoped>
#full-stars .rating-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#full-stars .rating__icon {
  width: 100%;
  pointer-events: none;
  text-align: center;
}
#full-stars .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
#full-stars .rating__input--none {
  display: none;
}
#full-stars .rating__label {
  display: flex;
  cursor: pointer;
  margin: 0;
  font-size: 2rem;
  text-decoration: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}
#full-stars .rating__icon--star {
  color: orange;
}

#full-stars .rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}
#full-stars .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
#full-stars .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}
</style>
