<script setup>
import { ref, getCurrentInstance } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useNavbarStore } from "@/stores/navbarStore";
import MainNavbarSubItemCard from "@/components/navbars/CompMainNavbarSubItemCard.vue";

const navbarStore = useNavbarStore();
const target = ref(null);
const { $isMobile: mobile } = getCurrentInstance().proxy;

defineProps({
  mainItem: {
    type: Object,
    default: () => {},
  },
});

const closeModal = () => {
  navbarStore.changeGeneralNavbarModalOpen(false);
  mobile() ? navbarStore.changeUserModalOpen(true) : null;
};

onClickOutside(target, () => {
  closeModal();
});
</script>
<template>
  <div
    id="navbar-general-modal"
    class="tw-fixed tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-50 tw-z-100 tw-flex tw-justify-center tw-items-start tw-pt-4 tw-px-4"
  >
    <div
      id="inner-modal"
      class="tw-rounded-xl tw-bg-white tw-max-w-5xl tw-p-4 lg:tw-p-8"
      ref="target"
    >
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <p
          class="tw-mb-0 tw-text-lg md:tw-text-2xl tw-text-red-400 tw-font-robotoBold"
        >
          {{ mainItem.name }}
        </p>
        <i
          @click="closeModal"
          class="icon-cerrar-simple tw-cursor-pointer tw-text-xl tw-text-red-400"
        ></i>
      </div>
      <p
        class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base tw-text-black tw-mb-4"
      >
        {{ mainItem.description }}
      </p>
      <hr class="tw-w-full tw-bg-gray-300" />
      <div class="tw-h-72 md:tw-h-96 tw-overflow-scroll tw-pb-2 md:tw-pb-3">
        <div class="tw-grid tw-grid-cols-12 lg:tw-gap-6">
          <div
            v-for="(item, idx) in mainItem.items"
            :key="idx"
            class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4"
          >
            <main-navbar-sub-item-card
              class="tw-h-full"
              :image="item.image"
              :title="item.name"
              :category="mainItem.name"
              :description="item.description"
              :link="item.call_to_action"
              :icon="item.icon"
              @close="closeModal"
            />
          </div>
        </div>
      </div>
      <img
        src="@/assets/img/icons/icon-2-main-navbar.png"
        alt="icon"
        class="tw-float-right tw-mt-4 md:tw-hidden"
      />
    </div>
  </div>
</template>
