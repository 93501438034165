<template>
  <div>
    <CompMenu></CompMenu>
    <div class="layout-content mx-auto competitionParticipate">
      <div class="container p-0 mb-5 mb-md-0">
        <div class="row">
          <div class="col-12">
            <div class="mainbar-desktop d-none d-md-block">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0 text-red tw-font-tolyerBold tw-text-xl md:tw-text-3xl">{{ competition.name }}</h3>
              </div>
              <!-- .d-flex -->
            </div>
            <!-- .mainbar-desktop -->
            <div class="mainbar-mobile d-block d-md-none py-2 px-3">
              <div class="d-flex justify-content-between align-items-center">
                <a href="#">
                  <img
                    src="@/assets/img/icons/left-chevron.png"
                    class="tw-w-5 tw-h-5"
                    alt="left chevron"
                  />
                </a>
                <h3 class="mb-0 tw-font-tolyerBold tw-text-xl md:tw-text-3xl">{{ competition.name }}</h3>
                <div class="cont-cart"></div>
              </div>
              <!-- .d-flex -->
            </div>
            <!-- .mainbar-mobile -->
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
        <div class="row h-100 px-3 px-md-0">
          <div class="ml-auto col-12 col-md-5">
            <div
              class="cont-text d-flex h-100 flex-column justify-content-center"
            >
              <p class="text-1 text-red mb-5">{{ competition.desc }}</p>
              <p class="text-2 text-red text-bold">
                Al participar en este concurso, estás aceptando los
                <a href="#" class="text-red">términos y condiciones</a>.
              </p>
              <router-link
                to="/competition/question"
                class="btn btn-block btn-outline-danger divBTN text-bold mt-4"
                >Empezar</router-link
              >
            </div>
            <!-- .cont-text-1 -->
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </div>
    <!-- .layout -->
  </div>
</template>

<script>
import CompMenu from "@/components/navbars/CompMenu.vue";
import { mapActions, mapState } from "vuex";
import config from "@/services/config";

export default {
  name: "CompetitionParticipate",
  components: {
    CompMenu,
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  beforeMount() {
    this.actGetCompetition(this.$route.params.id);
  },
  computed: {
    ...mapState("modCompetitions", ["competition"]),
  },
  methods: {
    ...mapActions("modCompetitions", ["actGetCompetition"]),
  },
};
</script>

<style scoped>
.layout-content {
  min-height: 100vh;
  padding-top: 80px;
}

.text-bold {
  font-family: "RobotoBold", sans-serif;
}

.text-red {
  color: #7f0000;
}

.competitionParticipate {
  /*background: url("https://picsum.photos/1920/1080") no-repeat;*/
  background-color: #fde542;
  background-size: cover;
}

.competitionParticipate .container {
  height: calc(100vh - 150px);
}

/**Cont txt */
.cont-text .text-1 {
  font-size: 14px;
}

.cont-text .text-2 a {
  text-decoration: underline;
}

.btn-outline-danger {
  background: #ffffff;
}

@media (min-width: 768px) {
  .layout-content {
    width: calc(100% - 75px);
    top: 0 ;
    padding-top: 100px;
  }

  .cont-text .text-1 {
    font-size: 20px;
  }
}
</style>
