<template>
  <div class="card w-100">
    <div class="mt-3 font-weight-bold">
      <div class="col-12">
        <div class="row mr-0">
          <div class="col-12 col-md-3 text-left d-block d-md-none mb-2">
            <img src="@/assets/img/icons/gc-arcos.png" alt="arcos" />
          </div>
          <div class="col-12 col-md-9">
            <h5
              class="text-uppercase textColorPrimary tw-font-robotoBold tw-text-sm md:tw-text-lg"
            >
              datos corporativos
            </h5>
          </div>
          <div class="col-md-3 text-right d-none d-md-block p-0">
            <img src="@/assets/img/icons/gc-arcos.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p class="mb-1">
        <span class="font-weight-bold">Banda: </span>
        {{ user.core_pa_sideline.name }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Cargo: </span>
        {{ user.core_position.name }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Localización: </span>
        {{ user.core_organizational_unit.name }}
      </p>
      <b-collapse id="datosMore">
        <p class="mb-1">
          <span class="font-weight-bold">Convenio: </span>
          {{ user.ben_agreement.name }}
        </p>
        <p class="mb-1">
          <span class="font-weight-bold">Tipo Contrato: </span>
          {{ user.ben_contract.acronym }}
        </p>
        <p class="mb-1">
          <span class="font-weight-bold">Sociedad: </span>
          {{ user.core_society.name }}
        </p>
        <p class="mb-1">
          <span class="font-weight-bold">Fecha Ingreso: </span>
          {{ user.date_entry }}
        </p>
        <p class="mb-1">
          <span class="font-weight-bold">Jefe Inmediato: </span>
          {{ user.immediate_boss }}
        </p>
      </b-collapse>
      <div v-b-toggle.datosMore variant="primary" class="cont-link text-right">
        <span class="link">Detalle</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CompCompanyData",
  computed: {
    ...mapState("modCore", ["user"]),
  },
};
</script>

<style scoped>
.card-body p {
  color: #222;
  font-size: 13px;
}

.card-body .font-weight-bold {
  font-family: "RobotoBold", sans-serif;
}

.cont-link {
  outline: none;
}

.link {
  color: #222;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  font-weight: bold;
}
</style>
