<template>
  <div>
    <div
      class="
        opportunity-card
        p-3 p-md-4
        tw-bg-white
        tw-shadow-sm
        tw-rounded-md
        mb-3
        d-flex
        position-relative
        z-index-2
        hover:tw-shadow-md
      "
    >
      <img
        v-if="opportunity.core_vp"
        :src="uriResources + '/' + opportunity.core_vp.img_people_module"
        width="100%"
        height="auto"
        class="
          tw-object-cover
          tw-rounded-md
          mr-2 mr-md-3
          wi-50
          he-50
          wi-md-100
          he-md-100
        "
        alt="img 1"
      />
      <div class="w-100">
        <p class="tw-text-gray-600 text-uppercase  tw-text-xs  fs-md-20 font-weight-bold">
          {{ opportunity.position_name }}
        </p>
        <div class="d-flex justify-content-between">
          <div class="mr-2 ml-0">
            <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Lugar</p>
            <p class=" tw-text-xxs  fs-md-14 tw-text-gray-400 mb-0">
              {{ opportunity.core_site.name }}
            </p>
          </div>
          <div class="mx-2">
            <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Fecha límite</p>
            <p class=" tw-text-xxs  fs-md-14 tw-text-gray-400 mb-0">
              {{ opportunity.end_date }}
            </p>
          </div>
          <div class="mx-2">
            <p class=" tw-text-xxs  fs-md-12 tw-text-gray-300 mb-1">Banda</p>
            <p class=" tw-text-xxs  fs-md-14 tw-text-gray-400 mb-0">
              {{ opportunity.core_pa_sideline.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        tw-bg-gray-200
        pt-4
        pb-3
        px-3
        mx-3
        mb-3
        position-relative
        z-index-1
        mt-n4
        tw-shadow-sm
        tw-rounded-md
        d-block d-md-none
      "
    >
      <div class="max-he-450 tw-overflow-y-scroll">
        <p class="tw-text-base tw-text-gray-600 text-uppercase font-weight-bold">
          DESCRIPCIÓN DEL CARGO
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14">
          {{ opportunity.position_desc }}
        </p>
        <p
          class="tw-text-base tw-text-gray-600 text-uppercase font-weight-bold mt-4"
        >
          PERFIL
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14 font-weight-bold">
          Educación:
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14">
          {{ opportunity.education }}
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14 font-weight-bold">
          Habilidades:
        </p>
        <p class="tw-text-gray-600  tw-text-xs  line-height-14">
          {{ opportunity.skills }}
        </p>
      </div>
      <div
        v-if="
          vacantRequestsByLineManager.length > 0 &&
          opportunity.people_vacant_position_state_id === 'WAITING_LM'
        "
      >
        <div class="d-flex">
          <div
            @click="
              updateState(0, opportunity.people_vacant_position_employee_id)
            "
            role="button"
            class="
              w-100
              text-center
               tw-text-xs 
              tw-bg-red-400
              tw-text-white
              py-2
              px-3
              tw-rounded-lg
              hover:tw-bg-red-600
              mr-2
            "
          >
            Rechazar
          </div>
          <div
            @click="
              updateState(1, opportunity.people_vacant_position_employee_id)
            "
            role="button"
            class="
              w-100
              text-center
               tw-text-xs 
              tw-bg-green-400
              tw-text-white
              py-2
              px-3
              tw-rounded-lg
              hover:tw-bg-green-600
              ml-2
            "
          >
            Autorizar
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "@/services/config";
import vacant from "@/mixins/user/vacant";

export default {
  name: "RequestLm",
  mixins: [vacant],
  props: {
    opportunity: {
      type: Object,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  computed: {
    ...mapState("modVacants", ["vacantRequestsByLineManager"]),
  },
};
</script>