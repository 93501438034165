<script setup>
import config from "@/services/config";
import { getCurrentInstance } from "vue";
import { useContactsStore } from "@/stores/contactsStore";

const { actPostDeleteContact } = useContactsStore();
const uriResources = config.beerOfficeURL;
const imgAvatar = require("@/assets/img/contacts/avatar-empty.png");
const { $bvModal } = getCurrentInstance().proxy;

const props = defineProps({
  modalContact: {
    type: Object,
    default: () => ({
      id: null,
      name: "",
      core_regional: {
        name: "",
      },
    }),
  },
  actualTypeContact: String,
});

const deleteContact = async (contact) => {
  await actPostDeleteContact(contact);
  $bvModal.hide("bv-modal-contact");
};
</script>
<template>
  <b-modal id="bv-modal-contact" centered hide-footer>
    <template v-slot:modal-title></template>
    <div class="d-block text-center">
      <div v-if="actualTypeContact === 'favorite'">
        <img
          :src="
            props.modalContact.core_employee.prof_picture == null
              ? imgAvatar
              : uriResources +
                '/' +
                props.modalContact.core_employee.prof_picture
          "
          alt="user"
          class="rounded-circle mb-3 img-profile"
          width="120"
          height="120"
        />
      </div>
      <div v-else>
        <img
          :src="
            props.modalContact.prof_picture == null
              ? imgAvatar
              : uriResources + '/' + props.modalContact.prof_picture
          "
          alt="user"
          class="rounded-circle mb-3 img-profile"
          width="120"
          height="120"
        />
      </div>
      <div class="cont-name">
        <p class="name mb-0 text-capitalize">
          {{
            actualTypeContact === "favorite"
              ? props.modalContact.core_employee.name
              : props.modalContact.name
          }}
        </p>
        <p
          class="profile modal-profile-name"
          v-if="actualTypeContact === 'favorite'"
        >
          {{ props.modalContact.core_employee.core_position.name }}
        </p>
        <p class="profile modal-profile-name" v-else>
          {{ props.modalContact.position }}
        </p>
      </div>
      <div class="cont-1" v-if="actualTypeContact === 'main'">
        <p class="label mb-0">Tipo de usuario</p>
        <p class="txt">{{ props.modalContact.core_regional.name }}</p>
      </div>
      <div class="cont-1">
        <p class="label mb-0">Correo</p>
        <p class="txt text-uppercase">
          {{
            actualTypeContact === "favorite"
              ? props.modalContact.core_employee.email
              : props.modalContact.email
          }}
        </p>
      </div>
      <div class="cont-btn mt-3" v-if="actualTypeContact === 'favorite'">
        <button class="btn btn-primary" @click="deleteContact(modalContact)">
          Quitar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style lang="css">
/**Modal */

#bv-modal-contact header {
  border-bottom: 0 none;
}

#bv-modal-contact .modal-dialog {
  border-radius: 16px;
}

#bv-modal-contact .close {
  outline: none;
}

#bv-modal-contact header button {
  opacity: 1;
}

#bv-modal-contact .cont-name .name {
  color: #d82b2b;
  font-size: 18px;
  font-family: "RobotoBold", sans-serif;
}

#bv-modal-contact .cont-name .profile {
  color: #222;
  font-size: 14px;
}

#bv-modal-contact .cont-1 .label {
  font-size: 11px;
}

#bv-modal-contact .cont-1 .txt {
  font-size: 14px;
  color: #d82b2b;
  overflow-wrap: break-word;
}

#bv-modal-contact .img-profile {
  object-fit: cover;
}

@media (min-width: 768px) {
  #bv-modal-contact .cont-name .name {
    font-size: 20px;
  }

  #bv-modal-contact .cont-1 .txt {
    font-size: 16px;
  }
}
</style>
