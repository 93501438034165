<template>
  <div class="tw-container-general">
    <!-- Title -->
    <div class="container-md">
      <!--Linea superior Titulos, Toggle Switch y Botones -->
      <div class="d-flex flex-column-reverse flex-md-row">
        <div class="col-md-8 mt-4 mt-md-0 p-0">
          <div class="row">
            <!-- Title -->
            <div class="col text-center text-md-left">
              <p class="text-uppercase tw-font-tolyerBold fs-30">
                COMPARATIVA DE LÍDERES
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 p-0">
          <div class="row justify-content-center justify-content-md-end">
            <div
              v-for="button in psi_buttons"
              :key="button.id"
              class="col px-2"
            >
              <router-link
                :class="button.active ? 'tw-bg-gray-200' : ''"
                :to="button.link"
                class="tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
              >
                {{ button.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Filter -->
    <div v-if="isLoadingYearsAndMonths === false" class="container">
      <div class="row">
        <div class="col-6 col-md-2 order-1 order-md-1 mb-3 mb-md-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-2 col-md-3 w-auto m-auto">
                <span
                  class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                  >Año</span
                >
              </div>
              <div class="col-10 col-md-9 pl-3 px-md-0">
                <select
                  v-model="year"
                  class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1 tw-text-gray-400"
                >
                  <option :value="null" disabled>Seleccionar...</option>

                  <option
                    v-for="(data, idx) in yearsAndMonths"
                    :key="idx"
                    :selected="data.year === 2022"
                    :value="data.year"
                  >
                    {{ data.year }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2 order-2 order-md-2 mb-3 mb-md-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-2 col-md-3 w-auto m-auto">
                <span
                  class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                  >Mes</span
                >
              </div>
              <div class="col-10 col-md-9 pl-3 px-md-0">
                <select
                  v-model="month"
                  class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1 tw-text-gray-400"
                >
                  <option :value="null" disabled>Seleccionar...</option>

                  <option
                    v-for="(month, idx) in filteredMonths"
                    :key="idx"
                    :selected="month === filteredMonths.slice(-1)[0]"
                    v-bind:value="month"
                  >
                    {{ months_name[month] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 order-5 order-md-3 mb-3 mb-md-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md px-4 px-md-0">
                <button
                  class="tw-bg-red-400 tw-rounded-md tw-py-3 tw-px-6 tw-text-white tw-outline-none hover:tw-bg-red-600 tw-rounded-md w-100 float-right p-0"
                  style="height: 40px"
                  type="button"
                  @click="getLeaders()"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-md-block d-none order-md-4"></div>
        <div class="col-6 col-md-4 order-3 order-md-5 mb-3 mb-md-0 mt-md-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-2 w-auto m-auto d-block d-md-none">
                <span
                  class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                  >Líder</span
                >
              </div>
              <div class="col-10 col-md-12 pl-3 px-md-0">
                <select
                  v-model="leader1"
                  class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto tw-capitalize tw-text-sm w-100 p-md-2 p-1 tw-text-gray-400"
                  @change="getLeaderInfo(leader1, 1)"
                >
                  <option :value="null" disabled>Seleccionar...</option>

                  <option
                    v-for="(leader, index) in leadersList"
                    v-bind:key="index"
                    :selected="
                      (leader.id_sharp === leader1[0]) &
                      (leader.site[0].id === leader1[1])
                    "
                    class="tw-capitalize"
                    v-bind:value="[leader.id_sharp, leader.site[0].id]"
                  >
                    {{ leader.name.toLowerCase() }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4 order-4 order-md-6 mb-3 mb-md-0 mt-md-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-2 w-auto m-auto d-block d-md-none">
                <span
                  class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                  >Líder</span
                >
              </div>
              <div class="col-10 col-md-12 pl-3 px-md-0">
                <select
                  v-model="leader2"
                  class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1 tw-text-gray-400"
                  @change="getLeaderInfo(leader2, 2)"
                >
                  <option :value="null" disabled>Seleccionar...</option>

                  <option
                    v-for="(leader, index) in leadersList"
                    v-bind:key="index"
                    :selected="
                      (leader.id_sharp === leader2[0]) &
                      (leader.site[0].id === leader2[1])
                    "
                    v-bind:value="[leader.id_sharp, leader.site[0].id]"
                  >
                    {{ leader.name.toLowerCase() }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-md-block d-none order-md-7 mt-md-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-2 w-auto m-auto d-block d-md-none">
                <span
                  class="tw-font-robotoBold tw-text-base font-weight-light pr-2"
                  >Líder</span
                >
              </div>
              <div class="col-10 col-md-12 pl-3 px-md-0">
                <select
                  v-model="leader3"
                  class="dropdown-2 tw-rounded-md tw-border-0 shadow-box-5 bg-white tw-font-roboto text-capitalize tw-text-sm w-100 p-md-2 p-1 tw-text-gray-400"
                  @change="getLeaderInfo(leader3, 3)"
                >
                  <option :value="null" disabled>Seleccionar...</option>

                  <option
                    v-for="(leader, index) in leadersList"
                    v-bind:key="index"
                    :selected="
                      (leader.id_sharp === leader3[0]) &
                      (leader.site[0].id === leader3[1])
                    "
                    v-bind:value="[leader.id_sharp, leader.site[0].id]"
                  >
                    {{ leader.name.toLowerCase() }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="mb-5">
        <div class="row overflow-hidden">
          <div class="col-6 col-md-4">
            <LeaderInfo :leadersInfo="leader1Info" />
          </div>
          <div class="col-6 col-md-4 overflow-x-hidden">
            <LeaderInfo :leadersInfo="leader2Info" />
          </div>
          <div class="col-md-4 d-md-block d-none">
            <LeaderInfo :leadersInfo="leader3Info" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeaderInfo from "@/components/engagement/LeaderInfo";
import cvMixin from "@/mixins/user/cv";
import psi from "@/mixins/general/psi";
import { mapActions, mapState } from "vuex";

export default {
  name: "CompareLeaders",
  mixins: [cvMixin, psi],
  components: {
    LeaderInfo,
  },
  data() {
    return {
      isLoading: false,
      isLoadingYearsAndMonths: false,
      year: null,
      month: null,
      id: 1,
      listOfLeaders: [],
      leader1: [],
      leader2: [],
      leader3: [],
      leader1Info: {},
      leader2Info: {},
      leader3Info: {},
      category: [],
      categoryInfo: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.isLoadingYearsAndMonths = true;

    await this.actGetYearsAndMonths()
      .then(() => {
        this.year = this.yearsAndMonths.slice(-1)[0].year;
        this.month = this.filteredMonths.slice(-1)[0];
        this.isLoadingYearsAndMonths = false;
      })
      .then(() => {
        this.getLeaders().then(() => {});
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
    /*await this.actGetLeaders()
        .then(() => {
          this.actGetYearsAndMonths().then(() => {
            this.year = this.yearsAndMonths.slice(-1)[0].year;
            this.month = this.filteredMonths.slice(-1)[0];
          });
          this.listOfLeaders = this.leadersList.filter(leader => {
            return !leader.name.includes('NOMBRE');
          });
        }).then(() => {
          this.leader1 = [
            this.listOfLeaders[0].id_sharp,
            this.listOfLeaders[0].site[0].id,
          ];
          this.leader2 = [
            this.listOfLeaders[1].id_sharp,
            this.listOfLeaders[1].site[0].id,
          ];
          this.leader3 = [
            this.listOfLeaders[2].id_sharp,
            this.listOfLeaders[2].site[0].id,
          ];
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.getLeadersInfo([this.leader1, this.leader2, this.leader3]);
        });*/
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modIndicators", [
      "tabFilter",
      "leadersList",
      "leadersInfo",
      "yearsAndMonths",
    ]),
    filteredMonths() {
      return this.yearsAndMonths
        .filter((year) => {
          return year.year === this.year;
        })
        .map((year) => {
          return year.months;
        })
        .flat();
    },
  },
  methods: {
    ...mapActions("modIndicators", [
      "actGetTabFilter",
      "actGetLeaders",
      "actGetLeadersInfo",
      "actGetIndLeaderInfo",
      "actGetYearsAndMonths",
    ]),
    getLeadersInfo(parameters) {
      this.actGetLeadersInfo({
        leader: parameters,
        year: this.year,
        month: this.month,
      })
        .then(() => {
          this.leadersInfo.data[0].calculate.sort(
            (a, b) => a.classification - b.classification
          );
          this.leadersInfo.data[1].calculate.sort(
            (a, b) => a.classification - b.classification
          );
          this.leadersInfo.data[2].calculate.sort(
            (a, b) => a.classification - b.classification
          );
        })
        .then(() => {
          this.leader1Info = this.leadersInfo.data[0];
          this.leader2Info = this.leadersInfo.data[1];
          this.leader3Info = this.leadersInfo.data[2];
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // filterAgain() {
    //   this.getLeadersInfo([this.leader1, this.leader2, this.leader3]);
    // },
    async getLeaders() {
      await this.actGetLeaders({ year: this.year, month: this.month })
        .then(() => {
          this.leader1 = [
            this.leadersList[0].id_sharp,
            this.leadersList[0].site[0].id,
          ];
          this.leader2 = [
            this.leadersList[1].id_sharp,
            this.leadersList[1].site[0].id,
          ];
          this.leader3 = [
            this.leadersList[2].id_sharp,
            this.leadersList[2].site[0].id,
          ];
        })
        .catch((err) => {
          console.error("Error: ", err);
        })
        .finally(() => {
          this.getLeadersInfo([this.leader1, this.leader2, this.leader3]);
        });
    },
    getLeaderInfo(leader, number) {
      this.actGetIndLeaderInfo({
        leader: [leader],
        year: this.year,
        month: this.month,
      })
        .then((response) => {
          response.data[0].calculate.sort(
            (a, b) => a.classification - b.classification
          );
          switch (number) {
            case 1:
              this.leader1Info = response.data[0];
              break;
            case 2:
              this.leader2Info = response.data[0];
              break;
            case 3:
              this.leader3Info = response.data[0];
              break;
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    },
  },
};
</script>

<style scoped>
.avatar img:first-of-type {
  width: 59px;
  height: 59px;
}

@media (min-width: 768px) {
  .avatar img:first-of-type {
    width: 102px;
    height: 102px;
  }

  .icon img {
    object-fit: none;
  }
}
</style>
