<template>
  <div
    class="
      tw-fixed
      tw-top-0
      tw-left-0
      tw-w-screen
      tw-h-screen
      tw-bg-black
      tw-bg-opacity-50
    "
    :class="zIndexTotal ? 'tw-z-60' : 'tw-z-40'"
  >
    <div
      id="modal-content"
      class="
        tw-fixed
        tw-top-0
        tw-left-0
        tw-w-full
        tw-h-full
        tw-overflow-hidden
        tw-flex
        tw-justify-center
        tw-items-center
        tw-px-4
        lg:tw-px-0
      "
      tabindex="0"
      @keydown.esc="close"
      @click="closeClickOutSide"
    >
      <div
        class="tw-bg-white tw-shadow-md tw-rounded-xl tw-w-full"
        :class="widthModal"
      >
        <header class="tw-relative">
          <button
            v-if="viewCloseButton"
            type="button"
            class="
              tw-absolute tw-border-0 tw-bg-transparent tw--top-4 tw--right-3
              md:tw-text-2xl
              tw-text-xl tw-bg-white tw-rounded-full tw-w-8
              md:tw-w-10
              tw-h-8
              md:tw-h-10
              tw-shadow-md tw-z-20 tw-cursor-pointer
            "
            @click="close"
            id="modal-btn-close"
          >
            <i class="icon-cerrar-simple tw-text-red-400"></i>
          </button>
          <slot name="header"></slot>
        </header>
        <section class="tw-relative">
          <slot name="body"></slot>
          <div
            class="tw-absolute tw-bottom-16 tw-left-4"
            v-if="timeToViewCloseButton > 0 && timeCountDown !== '00:00'"
          >
            <p class="tw-text-white  tw-text-xs  tw-mb-0">
              Saltar publicidad en: {{ timeCountDown }}
            </p>
          </div>
        </section>
        <footer>
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    timeToViewCloseButton: {
      type: Number,
      default: 0
    },
    widthModal: {
      type: String,
      default: "tw-max-w-screen-md"
    },
    zIndexTotal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewCloseButton: false,
      timeCountDown: "00:00"
    };
  },
  created() {
    if (this.timeToViewCloseButton > 0) {
      this.timeToShowCloseButton();
      this.countDownTimer(this.timeToViewCloseButton);
    } else {
      this.viewCloseButton = true;
      this.timeCountDown = "00:00";
    }
  },
  methods: {
    close() {
      
      if (this.timeCountDown === "00:00") {
        this.$emit("close");
      }
    },
    closeClickOutSide(e) {
      if (e.path[0].id === "modal-content") {
        this.close();
      }
    },
    showCloseButton() {
      this.viewCloseButton = true;
    },
    // hideCloseButton() {
    //   this.viewCloseButton = false;
    // },
    timeToShowCloseButton() {
      setTimeout(() => {
        this.showCloseButton();
      }, this.timeToViewCloseButton * 1000);
    },
    countDownTimer(duration) {
      let self = this;
      let timer = duration >= 1 ? duration - 1 : 1,
        minutes,
        seconds;
      let timeDownInterval = setInterval(function() {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        self.timeCountDown = minutes + ":" + seconds;

        if (--timer < 0) {
          timer = 0;
          clearInterval(timeDownInterval);
        }
      }, 1000);
    }
  }
};
</script>
