var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"remunerationVideo"}},[_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-remunerationVideo",modifiers:{"modal-remunerationVideo":true}}],staticClass:"cont-img mt-4 mt-lg-0 position-relative tw-shadow-md md:tw-shadow-lg bor-banner-2 bor-banner-md-2 overflow-hidden he-150 he-md-220"},[_c('img',{staticClass:"position-absolute btn-play z-index-2",attrs:{"src":require("../../assets/img/icons/btn-play-shadow.png"),"alt":"play"}}),_vm._m(0),_c('img',{staticClass:"tw-w-full",attrs:{"src":require("@/assets/img/Onboard3.jpg"),"alt":"man"}})]),_c('br'),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-generalVideo",modifiers:{"modal-generalVideo":true}}],staticClass:"cont-img mt-4 mt-lg-0 position-relative tw-shadow-md md:tw-shadow-lg bor-banner-2 bor-banner-md-2 overflow-hidden he-150 he-md-220"},[_c('img',{staticClass:"position-absolute btn-play z-index-2",attrs:{"src":require("../../assets/img/icons/btn-play-shadow.png"),"alt":"play"}}),_vm._m(1),_c('img',{staticClass:"tw-w-full",attrs:{"src":require("@/assets/img/Onboard5.jpg"),"alt":"man"}})]),_c('b-modal',{attrs:{"id":"modal-remunerationVideo","centered":"","hide-footer":"","modal-title":false,"size":"lg"}},[_c('p',{staticClass:"modal1-title text-center text-uppercase mb-2"},[_vm._v(" ¿cómo funciona tu remuneración? ")]),_c('p',{staticClass:"modal1-desc text-center"},[_vm._v("Aquí te explicamos")]),_c('div',{staticClass:"mt-4 w-100"},[(
          _vm.employeeCorePosition === '0-IV' || _vm.employeeCorePosition === 'V-VI'
        )?_c('video',{staticClass:"w-100",attrs:{"src":_setup.getURLResourceByKey('VIDEO_BONO_ABI'),"poster":require("@/assets/videos/placeholder.png"),"controls":""}}):_vm._e(),(
          _vm.employeeCorePosition !== '0-IV' &&
          _vm.employeeCorePosition !== 'V-VI' &&
          _setup.user.core_class_employee.name === 'Planta'
        )?_c('video',{staticClass:"w-100",attrs:{"src":_setup.getURLResourceByKey('VIDEO_BONO_PLANTAS'),"poster":require("@/assets/videos/placeholder.png"),"controls":""}}):_vm._e(),(
          _vm.employeeCorePosition !== '0-IV' &&
          _vm.employeeCorePosition !== 'V-VI' &&
          _setup.user.core_class_employee.name === 'Administrativo'
        )?_c('video',{staticClass:"w-100",attrs:{"src":_setup.getURLResourceByKey('VIDEO_BONO_STI'),"poster":require("@/assets/videos/placeholder.png"),"controls":""}}):_vm._e()])]),_c('b-modal',{attrs:{"id":"modal-generalVideo","centered":"","hide-footer":"","modal-title":false,"size":"lg"}},[_c('p',{staticClass:"modal1-title text-center text-uppercase mb-2"},[_vm._v("PEOPLE REWARDS")]),_c('div',{staticClass:"mt-4 w-100"},[_c('video',{staticClass:"w-100",attrs:{"src":_setup.getURLResourceByKey('VIDEO_PEOPLE_REWARDS'),"poster":require("@/assets/videos/placeholder.png"),"controls":""}})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex overlayer p-0 tw-z-1"},[_c('div',{staticClass:"col-5 col-md-5 pl-4"},[_c('p',{staticClass:"text-white text-uppercase tw-font-roboto tw-font-black tw-text-xs fs-md-16 position-absolute tw-bottom-0"},[_vm._v(" ¿Cómo funciona tu remuneración? ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex overlayer p-0 tw-z-1"},[_c('div',{staticClass:"col-5 col-md-5 pl-4"},[_c('p',{staticClass:"text-white text-uppercase tw-font-roboto tw-font-black tw-text-xs fs-md-16 position-absolute tw-bottom-0"},[_vm._v(" PEOPLE REWARDS ")])])])
}]

export { render, staticRenderFns }