import { ref } from "vue";

export function useImageUploader() {
  const fileUploaded = ref(false);
  const fileName = ref("");
  const fileType = ref("");
  const formData = new FormData();

  function uploadFile(fileType) {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      let fileSize;

      if (fileType === "image") {
        input.setAttribute("accept", "image/png, image/jpeg, image/jpg");
        fileSize = 5000000;
      } else if (fileType === "video") {
        input.setAttribute("accept", "video/mp4");
        fileSize = 10000000;
      }

      input.click();
      input.onchange = async () => {
        const file = input.files[0];
        if (file) {
          if (file.size <= fileSize) {
            formData.append("file", file);
            fileUploaded.value = true;
            fileName.value = file.name;
            console.log("Subiendo archivo...");

            // Aquí deberías implementar la lógica de carga real
            // Por ahora, simularé una carga exitosa con setTimeout
            setTimeout(() => {
              console.log("El archivo ha sido subido.");
              resolve({ success: true, fileName: file.name });
            }, 2000);
          } else {
            alert(`El archivo no puede ser mayor a ${fileSize / 1000000}MB`);
            reject({
              success: false,
              message: "El archivo es demasiado grande.",
            });
          }
        }
      };
    });
  }

  return { uploadFile, fileType, fileUploaded, fileName, formData };
}
