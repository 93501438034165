import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

// API URI constants
const uri = {
  getCalendarEvents: "/calendarEvents",
  getCalendarEventCategories: "/calendarEventCategories",
};

export const useCalendarStore = defineStore("calendar", () => {
  // Reactive variables
  const calendarEvents = ref([]);
  const calendarPersonalEvents = ref([]);
  const calendarGeneralEvents = ref([]);
  const calendarEventCategories = ref([]);
  const surroundingDays = ref([]);

  // Get calendar events
  async function actGetCalendarEvents(start_date, end_date) {
    const response = await AxiosBeerOffice.get(
      uri.getCalendarEvents +
        "?start_date=" +
        start_date +
        "&end_date=" +
        end_date,
      true
    );
    if (response.error) return response.error;
    const { data } = response;
    calendarEvents.value = data;
    calendarPersonalEvents.value = data.personal_events;
    calendarGeneralEvents.value = data.general_events;
    return true;
  }

  // Get calendar event categories
  async function actGetCalendarEventCategories() {
    const response = await AxiosBeerOffice.get(
      uri.getCalendarEventCategories,
      true
    );
    if (response.error) return response.error;
    const { data } = response;
    calendarEventCategories.value = data;
    return true;
  }

  function setSurroundingDays(days) {
    surroundingDays.value = days;
  }

  // Exports
  return {
    calendarEvents,
    calendarPersonalEvents,
    calendarGeneralEvents,
    calendarEventCategories,
    surroundingDays,
    actGetCalendarEvents,
    actGetCalendarEventCategories,
    setSurroundingDays,
  };
});
