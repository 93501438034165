<script setup>
import config from "@/services/config";

const uriResources = config.beerOfficeURL;

const props = defineProps({
  slides: {
    required: true,
  },
});
</script>
<template>
  <b-carousel
    id="carousel-banner"
    ref="carousel-banner"
    :interval="5000"
    background="transparent"
    class="w-100"
    indicators
  >
    <b-carousel-slide
      v-for="slide in props.slides"
      :key="'banner-slide-' + slide.id"
      class="he-200 he-md-410 w-100"
    >
      <div
        v-if="slide.content_type_slide_id === 1"
        :style="`background: url(${uriResources}/${slide.img}) no-repeat center center; background-size: cover;`"
        class="position-relative tw-rounded-2xl he-200 he-md-410 w-100"
      >
        <div class="w-100 h-100 position-absolute bg-1">
          <div
            class="col-12 col-md-9 col-lg-7 position-absolute tw-bottom-0 p-3 p-md-4"
          >
            <p
              class="tw-text-white tw-text-base fs-md-40 tw-font-tolyerBold mb-0"
            >
              {{ slide.title }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-else-if="slide.content_type_slide_id === 2"
        class="tw-rounded-2xl w-100"
      >
        <b-embed
          :poster="uriResources + '/' + slide.img"
          aspect="16by9"
          class="tw-rounded-2xl"
          controls
          type="video"
        >
          <source :src="uriResources + '/' + slide.video" type="video/mp4" />
        </b-embed>
      </div>
    </b-carousel-slide>
  </b-carousel>
</template>
