<script setup>
import { ref, onMounted } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useUserStore } from "@/stores/userStore";
import { useNavbarStore } from "@/stores/navbarStore";
import CompFavoritesOptionItem from "@/components/favorites/CompFavoritesOptionItem.vue";

const userStore = useUserStore();
const navbarStore = useNavbarStore();
const target = ref(null);
const isLoading = ref(false);
const shortCuts = ref([]);
const userShortcuts = ref([]);
const beforeShortCuts = ref([]);

const emit = defineEmits(["closeModal"]);

const addRemoveFavorite = (state) => {
  const { state: isActive, shortCut } = state;
  const targetArray = isActive ? userShortcuts.value : shortCuts.value;
  const oppositeArray = isActive ? shortCuts.value : userShortcuts.value;

  if (isActive && userShortcuts.value.length >= 4) return;

  const index = oppositeArray.findIndex((item) => item.id === shortCut.id);
  if (index === -1) return;

  targetArray.push(shortCut);
  pushDataLayer(oppositeArray[index].title, isActive ? "Agregar" : "Quitar");
  oppositeArray.splice(index, 1);
};

const updateShortCuts = () => {
  shortCuts.value = [...navbarStore.shortcutSections];
  userShortcuts.value = [...userStore.getSectionShortcuts()];
  let favoritesId = userShortcuts.value.map((fav) => fav.id);
  shortCuts.value = shortCuts.value.filter(
    (item) => !favoritesId.includes(item.id)
  );

  if (userShortcuts.value.length > 0) {
    userShortcuts.value.forEach((shortcut) => {
      navbarStore.shortcutSections.forEach((navbarShortcut) => {
        if (shortcut.id === navbarShortcut.id) {
          Object.assign(shortcut, navbarShortcut);
        }
      });
    });
  }
};

const saveUserShortcuts = async () => {
  const beforeShortcutsId = beforeShortCuts.value.map((fav) => fav.id);
  const userShortcutsId = userShortcuts.value.map((fav) => fav.id);

  const compareBeforeAndAfter =
    beforeShortcutsId.length === userShortcutsId.length &&
    beforeShortcutsId.every((value, index) => value === userShortcutsId[index]);

  if (!compareBeforeAndAfter) {
    await userStore.actPatchUserShortcuts({
      userId: userStore.user.id,
      favorites: userShortcuts.value,
    });
  }
};

const pushDataLayer = (brandname, category) => {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Accesos Directos",
    event_label: brandname,
    interaction: "True",
    component_name: "Click",
    element_category: category,
  });
};

const closeModal = () => {
  emit("closeModal");
  saveUserShortcuts();
};

onClickOutside(target, () => {
  closeModal();
});

onMounted(() => {
  beforeShortCuts.value = userStore.getSectionShortcuts();
  updateShortCuts();
});
</script>
<template>
  <div
    class="tw-overflow-auto tw-left-0 tw-top-0 tw-w-full tw-h-full tw-fixed tw-bg-black tw-bg-opacity-50 tw-z-50 tw-flex tw-justify-start tw-items-start tw-pt-32 tw-px-4"
  >
    <div
      class="tw-max-w-md tw-bg-white tw-rounded-2xl tw-p-4 md:tw-p-6 md:tw-ml-20"
      id="modal-shortcuts"
      ref="target"
    >
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <p
          class="tw-mb-0 tw-text-red-400 tw-font-robotoBold tw-text-lg md:tw-text-xl"
        >
          Accesos directos
        </p>
        <i
          class="icon-cerrar-simple tw-text-xl tw-text-red-400 tw-cursor-pointer"
          @click="closeModal"
        ></i>
      </div>
      <p class="tw-text-xs md:tw-text-sm tw-text-black">
        Configura tus secciones favoritas para tenerlas siempre en tu pantalla,
        recuerda que solo se pueden seleccionar cuatro.
      </p>
      <div class="tw-w-full tw-bg-gray-300" style="height: 1px"></div>
      <div class="tw-text-center tw-my-4" v-if="isLoading">
        <img
          src="@/assets/img/icons/loading.gif"
          class="tw-w-10"
          alt="loading"
        />
      </div>
      <div v-else>
        <div v-if="userShortcuts.length === 0">
          <p class="tw-font-roboto tw-italic tw-text-sm tw-my-6">
            No tienes ningún acceso directo
          </p>
        </div>
        <div
          v-else
          class="tw-grid tw-grid-cols-3 md:tw-grid-cols-4 tw-gap-4 tw-border-t tw-border-gray-300 tw-py-4"
        >
          <CompFavoritesOptionItem
            @addRemoveFavorite="addRemoveFavorite"
            v-for="(shortcut, idx) in userShortcuts"
            :key="idx"
            :shortCut="shortcut"
            icon="icon-beneficios-banco"
          />
        </div>
      </div>
      <div class="tw-w-full tw-bg-gray-300" style="height: 1px"></div>
      <div class="tw-text-center tw-my-4" v-if="isLoading">
        <img
          src="@/assets/img/icons/loading.gif"
          class="tw-w-10"
          alt="loading"
        />
      </div>
      <div
        v-else
        class="tw-grid tw-grid-cols-3 md:tw-grid-cols-4 tw-gap-4 tw-border-t tw-border-gray-300 tw-py-4"
      >
        <CompFavoritesOptionItem
          @addRemoveFavorite="addRemoveFavorite"
          v-for="(shortcut, idx) in shortCuts"
          :key="idx"
          :shortCut="shortcut"
          icon="icon-beneficios-banco"
          state
        />
      </div>
    </div>
  </div>
</template>
