<template>
  <div id="page-socialForm">
    <div class="layout-content mx-auto">
      <div class="container p-0">
        <div class="row px-3 px md-0">
          <div class="col-12">
            <SecondaryNavbar :title="competition.name" />
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
        <div class="row px-3 px-md-0 mb-5 mb-md-0">
          <div class="col-12 col-md-6">
            <!--<div class="cont-img">
              <img src="https://picsum.photos/1000/800" width="100%" alt="banner" />
            </div>-->
            <!--<Banner v-bind:bannerId="this.competition.banner.id" gradient="none"></Banner>-->
            <img
              class="img-s mt-3 mt-md-0"
              :src="url + '/' + competition.icon"
              alt="img 1"
            />
            <!-- .cont-img -->
          </div>
          <!-- .col -->
          <div class="col-12 col-md-6">
            <div class="cont-form mt-3 mt-md-0">
              <p class="title">{{ this.competition.name }}</p>
              <p class="desc">{{ this.competition.desc }}</p>
              <form class="mb-5 mb-md-3">
                <div class="form-group">
                  <input
                    :class="error.flag === true ? 'b-red' : ''"
                    v-model="dataCompetition.name"
                    type="text"
                    class="form-control"
                    name="publicationName"
                    required="required"
                    placeholder="Nombre de tu publicación*."
                  />
                  <small v-show="error.flag" class="alert-danger">{{
                    error.messageName
                  }}</small>
                </div>
                <div class="form-group">
                  <textarea
                    :class="error.flag === true ? 'b-red' : ''"
                    v-model="dataCompetition.description"
                    name="description"
                    rows="5"
                    class="form-control"
                    required="required"
                    placeholder="Descripción*."
                  ></textarea>
                  <small v-show="error.flag" class="alert-danger">{{
                    error.messageDesc
                  }}</small>
                </div>
                <div class="form-group">
                  <input
                    :class="error.flag === true ? 'b-red' : ''"
                    v-if="competition.comp_competition_type_id === 3"
                    accept="image/png, image/jpeg, image/jpg"
                    type="file"
                    id="archivo"
                    class="form-control"
                    required="required"
                  />
                  <input
                    :class="error.flag === true ? 'b-red' : ''"
                    v-if="competition.comp_competition_type_id === 2"
                    accept="video/mp4"
                    type="file"
                    id="archivo"
                    class="form-control"
                    required="required"
                  />
                  <small
                    v-if="competition.comp_competition_type_id === 2"
                    v-show="error.flag"
                    class="alert-danger"
                    >{{ error.messageFileVideo }}</small
                  >
                  <small
                    v-if="competition.comp_competition_type_id === 3"
                    v-show="error.flag"
                    class="alert-danger"
                    >{{ error.messageFileImg }}</small
                  >
                </div>
                <div class="w-100 text-center">
                  <button
                    v-b-modal.modal-sell
                    type="button"
                    class="btn btn-primary background-primary px-5"
                    @click="saveCompetition()"
                  >
                    Continuar
                  </button>
                </div>
              </form>
            </div>
            <!-- .cont-form -->
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </div>
    <div>
      <b-modal
        ref="modal-sell"
        centered
        hide-header
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        class="cont-modal"
      >
        <!--<img src="url + '/' + competition.icon" alt="img 1" width="100%" />-->
        <img
          src="@/assets/img/loading-beer-office.gif"
          class="tw-w-full"
          alt=""
          srcset=""
        />
        <div class="row px-3 px-md-0 mt-2">
          <div class="col-12 px-5">
            <div
              class="cont-text d-flex h-100 flex-column justify-content-center text-center"
            >
              <p
                class="mx-3 mb-5 text-bold"
                v-if="competition.comp_competition_type_id === 3"
              >
                Estamos cargando tu imagen. Cuando esté listo, podrás verlo
                ingresando nuevamente al concurso.
              </p>
              <p
                class="mx-3 mb-5 text-bold"
                v-if="competition.comp_competition_type_id === 2"
              >
                Estamos cargando tu video. Cuando esté listo, podrás verlo
                ingresando nuevamente al concurso.
              </p>
              <!--<router-link
                to="/competition/congrats"
                class="btn btn-block btn-outline-danger text-bold mt-2"
                >Continuar</router-link
              >-->
            </div>
            <!-- .cont-text-1 -->
          </div>
          <!-- .col -->
        </div>
      </b-modal>
    </div>
    <!-- .layout-content -->
  </div>
  <!-- #page-socialForm -->
</template>

<script>
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import { mapActions, mapState } from "vuex";
import config from "@/services/config";

export default {
  name: "SocialForm",
  components: {
    SecondaryNavbar,
  },
  data() {
    return {
      dataCompetition: {
        core_employee_id: null,
        comp_competition_id: null,
        name: null,
        description: null,
        file: "",
      },
      url: config.beerOfficeURL,
      file: "",
      congrats: false,
      data: {},
      error: {
        flag: false,
        class: "", //is-invalid
        messageName: "*Son campos obligatorios. Máximo 200 caracteres. ",
        messageDesc: "*Son campos obligatorios.",
        messageFileVideo:
          "*Recuerda que debes subir un archivo en .mp4 y de un peso máximo de 30mb.",
        messageFileImg:
          "*Recuerda que debes subir un archivo .png o jpg y de un peso máximo de 10mb.",
      },
    };
  },
  beforeMount() {
    this.idCompetition = this.$route.params.id;
    this.actGetCompetition(this.idCompetition);
  },
  mounted() {
    window.dataLayer.push({
      event: "trackEvent",
      target: "Sub-secciones",
      action: "concursos-detalle",
      "target-properties": "clic",
      value: 5000,
    });
  },
  computed: {
    ...mapState("modCompetitions", [
      "competition",
      "participant",
      "competitionUser",
    ]),
    ...mapState("modCore", ["user"]),
  },
  methods: {
    ...mapActions("modCompetitions", ["actCompetition", "actGetCompetition"]),

    async saveCompetition() {
      let formData = new FormData();
      let contentFile = document.querySelector("#archivo");
      let sizeFile = 0;
      let lenName = this.dataCompetition.name;
      if (this.competition.comp_competition_type_id === 2) {
        sizeFile = 30000000;
      } else {
        sizeFile = 10000000;
      }

      if (
        this.dataCompetition.name === null ||
        lenName.length >= 201 ||
        this.dataCompetition.description === null ||
        contentFile.files[0].name === "" ||
        contentFile.files[0].size > sizeFile
      ) {
        this.error.flag = true;
        //this.$refs["modal-sell"].show();
      } else {
        this.error.files = false;
        this.error.messageName = "";
        this.error.messageDesc = "";
        this.error.messageFile = "";
        formData.append("file", contentFile.files[0]);
        formData.append("core_employee_id", this.user.id);
        formData.append("comp_competition_id", this.competition.id);
        formData.append("name", this.dataCompetition.name);
        formData.append("description", this.dataCompetition.description);
        this.$refs["modal-sell"].show();
        //let dataResult = await this.actCompetition(formData);
        const asyncExample = async () => {
          return await this.actCompetition(formData);
        };

        await (async () => {
          const dataResult = await asyncExample();
          if (dataResult.data) {
            await this.$router.push({ path: "/competition/congrats" });
          } else {
            this.$refs["modal-sell"].hide();
            this.error.flag = true;
          }
        })();
      }
    },
  },
};
</script>

<style lang="css" scoped>
#modal-sell .modal-content {
  border-radius: 25px;
}
#modal-sell .modal-content img {
  border-radius: 25px;
}
.img-s {
  width: 80%;
  display: block;
  margin: auto;
}
.cont-img img {
  border-radius: 6px;
}

.cont-form .title {
  color: #454545;
  font-size: 18px;
  font-family: "RobotoBold", serif;
}

.cont-form .form-control[type="text"] {
  color: #bababa;
  border-radius: 4px;
  height: 54px;
  font-size: 16px;
}

.cont-form .form-control[type="file"] {
  padding: 10px;
  height: 54px;
  position: relative;
}

.cont-form .form-control[type="file"]:before {
  content: "Adjuntar archivo";
  width: 160px;
  height: 30px;
  background: #d82b2b;
  position: absolute;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  border-radius: 6px;
  left: 8px;
  font-size: 14px;
}
.b-red {
  border: solid 1px #d82b2b;
}
</style>
