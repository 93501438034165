<script setup>
import config from "@/services/config";
import { useNavbarStore } from "@/stores/navbarStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { pushLink } from "@/helpers/linkHelpers";
import { getCurrentInstance } from "vue";

const navbarStore = useNavbarStore();
const uriResources = config.beerOfficeURL;
const { $isMobile } = getCurrentInstance().proxy;

const emit = defineEmits(["close"]);

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  category: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  image: {
    type: String,
    default: "",
  },
  link: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
});

const dataLayer = (label, category) => {
  pushDataLayer({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Pop Ups",
    event_label: label,
    interaction: "True",
    component_name: "Button",
    element_category: category,
  });
};

const linkTo = (link, title, category) => {
  if (link !== null) {
    emit("close", true);
    navbarStore.closeAllModals();
    dataLayer(title, category);
    pushLink(link);
  }
};
</script>
<template>
  <div class="tw-flex tw-flex-col tw-justify-between">
    <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
      <div
        :style="{
          'background-image': `url(${require('@/assets/img/icons/loading.gif')})`,
        }"
        class="tw-h-44 tw-hidden md:tw-block tw-bg-no-repeat tw-bg-center"
      >
        <img
          :src="uriResources + '/' + props.image"
          class="tw-object-cover tw-rounded-2xl tw-w-full tw-h-44"
          alt="image"
          loading="lazy"
        />
      </div>
      <div
        class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4"
        @click="
          $isMobile() ? linkTo(props.link, props.title, props.category) : null
        "
      >
        <div class="tw-grid tw-grid-cols-12 tw-items-center">
          <div class="tw-col-span-1 tw-flex tw-justify-start tw-items-center">
            <img :src="uriResources + '/' + props.icon" class="tw-w-6" alt="" />
          </div>
          <div class="tw-col-span-7 md:tw-col-span-8 tw-ml-2">
            <p
              class="tw-mb-0 tw-text-sm md:tw-text-lg tw-text-black tw-font-robotoBold"
            >
              {{ props.title }}
            </p>
          </div>
          <!-- <div
          class="
            tw-col-span-4
            md:tw-col-span-3
            tw-text-right tw-flex tw-items-center tw-justify-between
          "
        >
          <span
            class="
              tw-text-xs
              tw-text-red-400
              tw-bg-red-50
              tw-rounded-lg
              tw-py-1
              tw-px-3
              tw-font-robotoBold
            "
          >
            ¡Nuevo!
          </span>
          <i
            class="icon-flecha-der tw-text-red-400 tw-text-lg md:tw-hidden"
          ></i>
        </div> -->
        </div>
        <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-sm tw-text-black">
          {{ props.description }}
        </p>
      </div>
    </div>
    <div
      @click="linkTo(props.link, props.title, props.category)"
      class="tw-py-2 tw-px-4 tw-text-center tw-text-sm tw-text-white tw-bg-red-400 tw-rounded-3xl hover:tw-bg-red-600 hover:tw-text-white tw-hidden md:tw-block tw-mt-2 md:tw-mt-4 tw-cursor-pointer"
    >
      Ver más
    </div>
    <hr class="tw-w-full tw-bg-gray-300 lg:tw-hidden" />
  </div>
</template>
