import { render, staticRenderFns } from "./CompButtonCustomColor.vue?vue&type=template&id=475b8bec&scoped=true"
import script from "./CompButtonCustomColor.vue?vue&type=script&setup=true&lang=js"
export * from "./CompButtonCustomColor.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CompButtonCustomColor.vue?vue&type=style&index=0&id=475b8bec&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475b8bec",
  null
  
)

export default component.exports