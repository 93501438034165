<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  items: Array,
  categoryToFilter: String,
});

const emit = defineEmits(["changeFilter"]);

const filter = ref("");

const changeFilter = (opt) => {
  filter.value = opt;
  emit("changeFilter", opt);
};

const filteredCategories = computed(() => {
  let result = props.items.map(
    (item) => item[props.categoryToFilter]
  );

  result = result.filter(
    (elem, idx, array) =>
      idx === array.findIndex((t) => t.id === elem.id && t.name === elem.name)
  );

  return result;
});
</script>
<template>
  <div class="tw-overflow-x-scroll tw-overflow-y-auto invisible-scrollbar tw-mb-4">
    <div
      class="tw-grid tw-grid-flow-col tw-gap-2 tw-w-max"
      id="scroll-container"
    >
      <div
        class="tw-rounded-full tw-w-max tw-px-3 md:tw-px-5 tw-py-2 tw-cursor-pointer"
        :class="
          filter === ''
            ? 'tw-bg-red-350 tw-text-white hover:tw-bg-red-350 hover:tw-text-white'
            : 'tw-bg-pink-100 hover:tw-bg-pink-200'
        "
        @click="changeFilter('')"
      >
        <p
          class="tw-m-0 tw-p-0 tw-font-roboto tw-text-xs md:tw-text-base md:tw-leading-5"
        >
          Todos
        </p>
      </div>
      <div
        class="tw-rounded-full tw-w-max tw-px-3 md:tw-px-5 tw-py-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
        :class="
          filter === category.name
            ? 'tw-bg-red-350 tw-text-white hover:tw-bg-red-350 hover:tw-text-white'
            : 'tw-bg-pink-100 hover:tw-bg-pink-200'
        "
        v-for="(category, idx) in filteredCategories"
        :key="idx"
        @click="changeFilter(category.name)"
      >
        <p
          class="tw-m-0 tw-p-0 tw-font-roboto tw-text-xs md:tw-text-base md:tw-leading-5"
        >
          {{ category.name }}
        </p>
      </div>
    </div>
  </div>
</template>
