<template>
  <div>
    <div
      @click="showModal"
      id="tooltip-button-variant"
      class="tw-fixed tw-flex tw-items-center tw-right-5 tw-bottom-5 tw-cursor-pointer"
    >
      <div class="tw-relative tw-group">
        <div
          class="tw-w-11 tw-h-11 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-border tw-border-red-400"
          style="border-style: solid"
        >
          <span class="icon-soporte tw-text-xl tw-text-red-400"></span>
        </div>
        <div
          class="tw-absolute tw-top-2 tw--left-32 tw-bg-gray-300 tw-py-2 tw-pl-2 tw-pr-3 tw-rounded-lg tw-opacity-0 group-hover:tw-opacity-100"
        >
          <div
            class="tw-w-3 tw-h-3 tw--mt-2 tw-transform tw-rotate-45 tw-bg-gray-300 tw-absolute tw--right-1 tw-top-4"
          ></div>
          <p class="tw-text-xs tw-text-white tw-m-0 tw-font-roboto">
            Escribir a soporte
          </p>
        </div>
      </div>
    </div>
    <Modal
      v-if="isModalVisible"
      @close="closeModal"
      widthModal="tw-max-w-screen-sm"
    >
      <template v-slot:body>
        <div class="tw-p-5 tw-text-center">
          <img
            :src="imageSupport"
            class="tw-inline-block tw-mb-3"
            alt="support"
          />
          <p
            class="tw-text-3xl tw-font-bold tw-font-roboto tw-text-red-400 tw-mb-4"
          >
            Soporte
          </p>
          <div>
            <p
              class="tw-font-normal tw-text-sm md:tw-text-base tw-text-gray-400 tw-font-roboto tw-mb-8"
            >
              Si tienes algún problema en Embajadores, ayúdanos a reportarlo a
              través de nuestra plataforma de soporte.
            </p>
          </div>
          <a
            href="https://soporte.somosmaz.com/pqr/SimplificaHUB"
            class="tw-rounded-lg tw-bg-red-400 tw-font-normal tw-text-base tw-text-white tw-py-3 tw-px-6 tw-mb-4 hover:tw-bg-red-600 tw-no-underline tw-font-roboto"
            target="_blank"
          >
            Soporte Simplifica
          </a>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modals/Modal.vue";

export default {
  name: "SupportModal",
  components: {
    Modal,
  },
  data() {
    return {
      isModalVisible: false,
      imageSupport: require("@/assets/img/icons/Soporte1x.png"),
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>
