<script setup>
import { getCurrentInstance } from "vue";
const { $isMobile: mobile } = getCurrentInstance().proxy;
import { returnMediaURL } from "@/helpers/imageHelpers";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <img
    :src="returnMediaURL(props.src)"
    :alt="props.alt"
    loading="lazy"
    class="tw-rounded-2xl tw-w-full tw-h-full tw-object-center tw-object-cover tw-max-h-44 lg:tw-max-h-48"
    :class="mobile() ? 'tw-aspect-[16/10]' : 'tw-aspect-[1/1]'"
  />
</template>

<style scoped></style>
