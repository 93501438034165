<template>
  <div class="container">
    <div class="row justify-content-center justify-content-md-end">
      <div
        v-for="(item, index) in OPRButtonsApi"
        :key="item.id"
        class="col-6 col-md mb-3 mb-lg-0"
      >
        <div
          class="tw-text-xs fs-md-12 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-1 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
          @click="changeOPR(item.id, index)"
          :class="item.active ? 'tw-bg-gray-200' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonsOPR",
  props: {
    OPRButtonsApi: {
      type: Array,
    },
  },
  methods: {
    changeOPR(number, index) {
      this.$parent.changeOPR(number, index);
      let updatedOPRButtonsApi = [...this.OPRButtonsApi];
      for (let i = 0; i < updatedOPRButtonsApi.length; i++) {
        updatedOPRButtonsApi[i].active = false;
      }

      updatedOPRButtonsApi[index].active = true;
      this.$emit("update:OPRButtonsApi", updatedOPRButtonsApi);
    },
  },
};
</script>
