<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "CustomDoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    percentage: {
      type: Object,
      required: true,
    },
    aspectRatio: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            data: [this.percentage.value, 100 - this.percentage.value],
            backgroundColor: ["#FA2727", "#c0c0c0"],
            borderWidth: 0,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutout: "70%",
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
        layout: {
          padding: {
            top: -10,
          },
        },
      },
      plugins: [
        {
          id: "centerText",
          beforeDraw: (chart) => {
            const text = chart.data.datasets[0].data[0] + "%";
            const {
              ctx,
              chartArea: { width, height },
            } = chart;
            ctx.save();
            const fontSize = (width / 80).toFixed(2);
            ctx.font = `${fontSize}em robotoBold`;
            ctx.fillStyle = "#FA2727";
            ctx.textAlign = "center";
            ctx.fillText(text, width / 2, height / 2);
            ctx.restore();
          },
        },
      ],
    };
  },
};
</script>
