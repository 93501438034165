<template>
  <div
    id="btn-scrolltotop"
    class="d-flex justify-content-center align-items-center"
    @click="scrollToTop()"
    v-if="isVisible"
  >
    <span class="icon-flecha-arriba"></span>
  </div>
</template>

<script>
export default {
  name: "scrollToTop",
  data() {
    return {
      isVisible: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.isVisible = window.scrollY >= 700;
    },
  },
};
</script>

<style lang="css" scoped>
#btn-scrolltotop {
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  z-index: 20;
  box-shadow: 0 6px 8px 0 rgba(50, 50, 50, 0.6);
  cursor: pointer;
}

#btn-scrolltotop {
  color: #d82b2b;
  font-size: 40px;
}

@media (min-width: 768px) {
  #btn-scrolltotop {
    bottom: 30px;
    right: 15px;
  }
}
</style>