<template>
  <div class="row mb-5 he-md-580 tw-shadow-md tw-rounded-lg pb-5 pb-md-0">
    <div class="col-12 col-lg-8 px-md-0">
      <div
        class="bg-1 h-100"
        :style="`background-image: url(${uriResources}/${image}); background-size: cover;`"
      >
        <div class="bg-1 py-3 px-3 h-100">
          <div class="row h-100 align-items-center">
            <div class="col-12 col-lg-8 mx-auto text-center">
              <p
                class="
                  fs-30 fs-md-90
                  tw-text-white text-uppercase
                  tw-font-tolyerBold
                  line-height-md-70
                "
              >
                Genial, Embajador
              </p>
              <p class="tw-text-white tw-text-base fs-md-30">
                Contestaste correctamente todas las preguntas
              </p>
              <div class="d-flex justify-content-center mt-4">
                <div
                  class="
                    tw-border tw-border-white tw-border-solid
                    tw-rounded-lg
                    p-2
                    d-flex
                    align-items-center
                    px-2 px-md-3 px-lg-5
                    tw-bg-white tw-opacity-25
                  "
                >
                  <svg
                    width="45"
                    height="44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1993 23.8026c2.0604-1.9833.136-4.5469-.0146-7.1106-.1312-2.2251.7338-3.4199 3.0664-3.1828 1.4141.1402 1.9486-.2612 2.0312-1.8091.1458-2.83457 2.4444-5.86744 4.1549-5.93516 1.7737-.07256 4.014 2.97967 4.3542 6.02226.102.9093.0048 1.6446 1.3606 1.7462 3.5961.266 4.602 1.78 3.3579 5.1418-.5394 1.4657-.69 2.4524.0778 4.0632 1.6279 3.415-1.7446 8.3295-5.7877 8.5907-2.1576.1403-2.4443.9916-2.4103 2.8007.0243 1.1125.831 3.207-.8796 3.1925-1.6522-.0145-.8844-2.109-.8407-3.2312.0778-1.8865-.3644-2.6314-2.4589-2.7523-3.5426-.1983-6.0015-3.2796-6.0112-7.5362zm3.2121-8.494c-.9281-.0823-1.6085.1596-1.6133 1.4124-.0146 2.4137 2.6435 5.1999 5.1219 5.3112 1.2926.058 1.696-.5708 1.6619-1.7656-.068-2.4621-2.5561-4.929-5.1705-4.958zm.1555 7.7781c-1.3315-.0049-1.6765.2757-1.7348 1.4269-.1312 2.3702 2.5804 5.1661 5.0733 5.287 1.2781.0629 1.7057-.5273 1.6814-1.7317-.0534-2.4524-2.5561-4.9726-5.0199-4.9822zm6.8811 4.9096c-.0292 1.5237.52 1.9881 2.0847 1.7511 2.2063-.3386 4.3299-2.5056 4.6312-4.6824.2041-1.4704-.3888-2.0848-1.8807-1.988-2.386.1596-4.7866 2.5637-4.8352 4.9193zm-3.4405-15.7448c.2381 1.6108.4665 3.9568 2.4686 3.9423 1.8612-.0145 2.5027-2.1961 2.5075-4.0874.0049-1.8816-.6171-4.09704-2.4589-4.11155-1.8806-.00967-2.3957 2.24445-2.5172 4.25665zm3.4454 7.2896c-.0243 2.0315.2818 2.4234 1.3752 2.4669 2.3909.1016 5.2483-2.583 5.3503-5.0113.0438-1.0399-.3013-1.6398-1.4773-1.7026-2.3617-.1258-5.0684 2.2589-5.2482 4.721-.0049.0774 0 .1548 0-.474z"
                      fill="#fff"
                    />
                    <path
                      d="M2.68691 22.3322c.09233 2.3024.62688 5.2047 2.00212 7.9135.87958 1.7317 1.6085 3.3231 4.17919 3.2699 1.82228-.0387 2.70188 1.6543 2.46868 3.4972-.243 1.872-1.54051 2.9216-3.34825 2.7765-1.77859-.1451-3.12953-1.3253-2.88656-3.2118.20896-1.6108-.44707-2.6991-1.1517-3.9713-4.198625-7.6233-4.227782-15.324.52483-22.62321C9.37361 2.47095 16.6629-.465185 25.5509.439357c.8359.087068 2.1042.077394 1.8855 1.286673-.1895 1.04482-1.3315.57078-2.0798.4692C13.3438.569959 2.72093 9.67342 2.68691 22.3322zm5.65648 15.6867c.70463-.1838 1.2246-.6143 1.176-1.4656-.04373-.8175-.48595-1.3689-1.35094-1.3302-.85528.0387-1.27805.6046-1.25862 1.4269.01458.8369.51511 1.2819 1.43356 1.3689zM43.987 21.5298c-.0243 13.1182-10.8659 22.7586-23.8408 21.4574-.7629-.0774-1.9632.0339-1.8563-1.0206.1312-1.3206 1.3266-.7111 2.143-.653 7.7267.5514 14.0246-2.1961 18.3787-8.5666 4.4076-6.4575 4.6749-13.3553 1.1323-20.3546-.7095-1.4076-1.4433-2.49108-3.4114-2.47174-2.0021.02419-3.0226-1.46081-2.8525-3.43919.1652-1.89615 1.3655-2.84422 3.2802-2.80553 1.832.0387 3.2072 1.42212 2.9594 3.35213-.1701 1.33504.2333 2.25893.8942 3.31823 2.1819 3.4924 3.3336 7.3041 3.1732 11.1835zM36.8192 8.15512c.8504-.08223 1.3072-.56111 1.3072-1.39793 0-.89487-.6123-1.277-1.3995-1.29151-.7581-.00968-1.1371.52724-1.1566 1.24314-.0291.82714.3888 1.33988 1.2489 1.4463zM29.3843 2.6505c-.0243-.48855.2575-.76426.69-.82715.5248-.07255.9816.10642 1.0691.69655.068.47404-.2527.75943-.6803.82231-.5248.07256-.9573-.09674-1.0788-.69171zM14.856 41.5506c-.5686-.0193-.9136-.2708-.8699-.7159.0535-.5369.4617-.8416 1.0157-.7594.4373.0629.6998.3676.6171.8417-.0826.474-.4373.6143-.7629.6336z"
                      fill="#fff"
                    />
                  </svg>
                  <div class="wi-1 he-40 tw-bg-white ml-4 mr-4"></div>
                  <p class="tw-text-white mb-0 text-center d-block">
                    Conseguiste
                    {{ responseTrivia.comp_competition_malts }} maltas
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 px-md-0">
      <div class="position-relative">
        <svg
          class="position-absolute tw-top-0 right-0 d-none d-lg-block"
          width="101"
          height="100"
          viewBox="0 0 101 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.355464 0.00137329C0.355467 54.6486 44.9641 99.055 100.354 100L100.354 87.2615C52.1578 87.2615 13.0939 48.1974 13.0939 0.00137235L0.355464 0.00137329Z"
            fill="#FFC141"
          />
          <path
            d="M41.7054 0.000845356L26.9805 0.000846863C26.9805 40.521 59.8343 73.375 100.354 73.375L100.354 58.65C67.9634 58.65 41.7054 32.3919 41.7054 0.000845356Z"
            fill="#FFC141"
          />
          <path
            d="M69.7733 0.00147869L55.9355 0.0014801C55.9355 24.5335 75.8195 44.4175 100.351 44.4175L100.351 30.5797C83.4664 30.5797 69.7733 16.8865 69.7733 0.00147869Z"
            fill="#FFC141"
          />
        </svg>
        <div class="row pt-3 pt-md-5">
          <div class="col-12 col-lg-9 mx-auto pt-md-5">
            <div
              class="
                d-flex
                align-items-center
                tw-bg-white
                tw-shadow-md
                tw-rounded-lg
                py-2
                px-3
              "
            >
              <svg
                width="38"
                height="37"
                viewBox="0 0 38 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9375 20.0913C12.6973 18.3974 11.0537 16.2077 10.925 14.0181C10.813 12.1176 11.5518 11.0971 13.544 11.2996C14.7518 11.4194 15.2084 11.0765 15.279 9.75443C15.4035 7.33341 17.3667 4.74301 18.8277 4.68517C20.3426 4.62319 22.256 7.23013 22.5466 9.8288C22.6337 10.6055 22.5507 11.2335 23.7087 11.3202C26.7802 11.5475 27.6393 12.8406 26.5768 15.712C26.1161 16.9638 25.9874 17.8066 26.6432 19.1824C28.0336 22.0991 25.1531 26.2967 21.6999 26.5198C19.857 26.6396 19.6121 27.3667 19.6412 28.9119C19.6619 29.8621 20.3509 31.651 18.8899 31.6386C17.4788 31.6262 18.1345 29.8373 18.1719 28.8788C18.2383 27.2676 17.8606 26.6313 16.0717 26.528C13.046 26.3586 10.9458 23.7269 10.9375 20.0913ZM13.681 12.8365C12.8882 12.7662 12.3072 12.9728 12.303 14.0429C12.2906 16.1044 14.5609 18.4841 16.6777 18.5792C17.7817 18.6287 18.1262 18.0917 18.0972 17.0712C18.0391 14.9683 15.914 12.8613 13.681 12.8365ZM13.8138 19.4798C12.6766 19.4757 12.3819 19.7153 12.3321 20.6986C12.22 22.723 14.536 25.111 16.6652 25.2142C17.7568 25.2679 18.1221 24.7639 18.1013 23.7352C18.0557 21.6406 15.9181 19.4881 13.8138 19.4798ZM19.691 23.6732C19.6661 24.9746 20.1351 25.3712 21.4716 25.1688C23.3559 24.8796 25.1697 23.0287 25.4271 21.1696C25.6014 19.9136 25.095 19.3889 23.8208 19.4716C21.7829 19.6079 19.7325 21.6612 19.691 23.6732ZM16.7524 10.2254C16.9558 11.6012 17.1509 13.6049 18.8609 13.5925C20.4506 13.5801 20.9984 11.7169 21.0026 10.1015C21.0067 8.49434 20.4755 6.60215 18.9024 6.58975C17.2961 6.58149 16.8562 8.50674 16.7524 10.2254ZM19.6952 16.4515C19.6744 18.1867 19.9359 18.5213 20.8698 18.5585C22.9118 18.6453 25.3524 16.3523 25.4395 14.2783C25.4769 13.3901 25.1822 12.8778 24.1778 12.8241C22.1606 12.7167 19.8487 14.7535 19.6952 16.8564C19.691 16.9225 19.6952 16.9886 19.6952 16.4515Z"
                  fill="#F9B542"
                />
                <path
                  d="M1.96045 18.8353C2.03931 20.8018 2.49588 23.2807 3.67049 25.5943C4.42174 27.0734 5.04432 28.4326 7.23997 28.3872C8.79643 28.3541 9.54768 29.8001 9.34845 31.3742C9.14092 32.973 8.03272 33.8696 6.48872 33.7456C4.96961 33.6217 3.81575 32.6136 4.02328 31.0024C4.20176 29.6266 3.64143 28.697 3.0396 27.6104C-0.546485 21.0993 -0.571388 14.5221 3.48786 8.28775C7.67163 1.87163 13.8975 -0.636143 21.4888 0.136434C22.2027 0.2108 23.286 0.202537 23.0993 1.23539C22.9374 2.12778 21.962 1.7229 21.3228 1.63614C11.0626 0.247983 1.98951 8.02334 1.96045 18.8353ZM6.79171 32.2335C7.39354 32.0765 7.83765 31.7088 7.79614 30.9817C7.75879 30.2835 7.38109 29.8125 6.64229 29.8455C5.91179 29.8786 5.55069 30.362 5.56729 31.0643C5.57974 31.7791 6.00725 32.1591 6.79171 32.2335Z"
                  fill="#F9B544"
                />
                <path
                  d="M37.2371 18.1495C37.2163 29.3539 27.9564 37.5879 16.8744 36.4765C16.2228 36.4104 15.1976 36.5054 15.2889 35.6048C15.401 34.4769 16.422 34.9975 17.1193 35.047C23.7187 35.518 29.0978 33.1714 32.8167 27.7303C36.5813 22.2148 36.8096 16.3234 33.7838 10.3452C33.1778 9.14298 32.5511 8.21754 30.8701 8.23406C29.1601 8.25472 28.2885 6.98637 28.4337 5.29662C28.5749 3.6771 29.6 2.86733 31.2354 2.90039C32.8001 2.93344 33.9747 4.11503 33.7631 5.76347C33.6178 6.90374 33.9623 7.69285 34.5268 8.59763C36.3904 11.5805 37.374 14.8361 37.2371 18.1495ZM31.115 6.72609C31.8413 6.65586 32.2315 6.24685 32.2315 5.53211C32.2315 4.76779 31.7085 4.44141 31.0361 4.42902C30.3887 4.42075 30.0649 4.87934 30.0483 5.49079C30.0234 6.19727 30.3804 6.6352 31.115 6.72609Z"
                  fill="#F9B543"
                />
                <path
                  d="M24.763 2.02467C24.7422 1.6074 24.9829 1.37191 25.3523 1.3182C25.8006 1.25623 26.1908 1.40909 26.2655 1.91313C26.3236 2.31801 26.0496 2.56176 25.6844 2.61547C25.2361 2.67744 24.8667 2.53284 24.763 2.02467Z"
                  fill="#FBC873"
                />
                <path
                  d="M12.3535 35.2495C11.8679 35.2329 11.5732 35.0181 11.6106 34.638C11.6563 34.1794 12.0049 33.9192 12.4781 33.9894C12.8516 34.0431 13.0757 34.3034 13.0052 34.7083C12.9346 35.1131 12.6316 35.2329 12.3535 35.2495Z"
                  fill="#FBCF87"
                />
              </svg>
              <div class="wi-1 he-30 tw-bg-brown-400 mx-4"></div>
              <p class="mb-0 tw-text-base tw-text-brown-400">
                Tienes {{ responseTrivia.core_employee_malts }} maltas
              </p>
            </div>
            <div class="w-100 text-center mt-4">
              <svg
                width="53"
                height="73"
                viewBox="0 0 53 73"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.066 26.979L49.164 13.1534C49.8173 11.902 50.1268 10.4995 50.0607 9.08937C49.9947 7.67919 49.5555 6.31182 48.788 5.12693C46.7828 1.94823 42.7838 0 38.3576 0H14.506C9.90312 0 5.83575 2.08495 3.89321 5.44594C3.14136 6.70945 2.75641 8.15746 2.78156 9.62754C2.80672 11.0976 3.24098 12.5316 4.03563 13.7687L11.7659 26.3012C8.29467 28.5942 5.44357 31.7092 3.46592 35.3694C1.48827 39.0295 0.445305 43.1215 0.429688 47.2817C0.429688 61.2953 12.0906 72.6999 26.4289 72.6999C40.7672 72.6999 52.4281 61.3067 52.4281 47.2817C52.4167 43.3216 51.4727 39.4198 49.6724 35.8925C47.8721 32.3652 45.2663 29.3116 42.066 26.979ZM9.02584 10.8121C8.76558 10.4323 8.62159 9.98494 8.61147 9.52463C8.60136 9.06433 8.72555 8.61104 8.96887 8.22018C9.65246 7.04098 11.5152 5.65671 14.5173 5.65671H38.369C40.7558 5.65671 42.9262 6.61943 43.8491 8.11194C44.1 8.47375 44.2452 8.89832 44.2684 9.33803C44.2915 9.77774 44.1917 10.2152 43.9801 10.6014L37.0473 24.0795C30.6564 21.2818 23.4195 21.1201 16.9099 23.6294L9.02584 10.8121ZM26.4403 67.0147C15.3092 67.0147 6.2516 58.1565 6.2516 47.276C6.2516 36.3955 15.3092 27.5373 26.4403 27.5373C37.5714 27.5373 46.629 36.3955 46.629 47.276C46.629 58.1565 37.5885 67.0147 26.4289 67.0147H26.4403Z"
                  fill="#C43D35"
                />
                <path
                  d="M21.6437 42.1438L25.8079 33.0635L30.6443 41.8134L40.7671 42.8787L33.7489 50.0849L35.8396 59.8204L26.6624 55.5194L17.8327 60.4755L19.1827 50.6147L11.6348 43.9383L21.6437 42.1438Z"
                  fill="#C43D35"
                />
              </svg>
            </div>
            <div class="w-100 text-center mt-4">
              <p class="tw-text-gray-600 tw-text-lg mb-3 mb-md-5">
                Recuerda, puedes redimir cualquiera de nuestros productos, en la
                sección de Gamification. <b>¡Qué esperas!</b>
              </p>
            </div>
            <div class="w-100 text-center mt-4 mb-3 mb-md-5">
              <svg
                width="108"
                height="32"
                viewBox="0 0 108 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.2695 1.45508L0.000496649 1.45508C0.000495203 17.9965 13.5033 31.4381 30.2695 31.7241L30.2695 1.45508Z"
                  fill="#D82B2B"
                />
                <path
                  d="M48.8385 19.1825C53.0767 23.397 52.8438 30.4573 52.8438 30.4573C52.8438 30.4573 45.7409 30.6859 41.5057 26.4744C37.2675 22.2599 37.5003 15.1995 37.5003 15.1995C37.5003 15.1995 44.6003 14.968 48.8385 19.1825Z"
                  fill="#FFBF35"
                />
                <path
                  d="M56.8377 19.182C52.5995 23.3965 52.8324 30.4568 52.8324 30.4568C52.8324 30.4568 59.9353 30.6854 64.1705 26.4739C68.4087 22.2594 68.1759 15.199 68.1759 15.199C68.1759 15.199 61.0759 14.9675 56.8377 19.182Z"
                  fill="#FFBF35"
                />
                <path
                  d="M48.8385 3.98524C53.0766 8.19974 52.8438 15.2601 52.8438 15.2601C52.8438 15.2601 45.7409 15.4887 41.5057 11.2771C37.2675 7.06259 37.5003 0.00226918 37.5003 0.00226918C37.5003 0.00226918 44.6003 -0.229266 48.8385 3.98524Z"
                  fill="#FFBF35"
                />
                <path
                  d="M56.8377 3.98523C52.5995 8.19973 52.8324 15.26 52.8324 15.26C52.8324 15.26 59.9353 15.4886 64.1705 11.2771C68.4087 7.06258 68.1758 0.00226918 68.1758 0.00226918C68.1758 0.00226918 61.0759 -0.229265 56.8377 3.98523Z"
                  fill="#FFBF35"
                />
                <path
                  d="M108 0.0633554L96.0164 0.0633544L102.008 6.02459L108 11.9829L108 0.0633554Z"
                  fill="#00BF79"
                />
                <path
                  d="M77.4353 30.4595L77.4353 18.5399L83.4271 24.4982L89.4219 30.4595L77.4353 30.4595Z"
                  fill="#00BF79"
                />
                <path
                  d="M108 30.46L108 18.5404L102.008 24.4987L96.0164 30.46L108 30.46Z"
                  fill="#00BF79"
                />
                <path
                  d="M77.4353 0.0633544L89.4219 0.0633554L83.4271 6.02459L77.4353 11.9829L77.4353 0.0633544Z"
                  fill="#00BF79"
                />
                <path
                  d="M92.72 6.67213C87.9507 6.67213 84.0845 10.5168 84.0845 15.2594C84.0845 20.002 87.9507 23.8467 92.72 23.8467C97.4892 23.8467 101.355 20.002 101.355 15.2594C101.355 10.5168 97.4892 6.67213 92.72 6.67213Z"
                  fill="#00BF79"
                />
              </svg>
            </div>
            <div class="mt-4 text-center">
              <router-link
                to="/gamification/rewards"
                role="button"
                class="
                  tw-bg-red-400
                  hover:tw-bg-red-600 hover:tw-text-white
                  tw-text-white
                  px-2
                  py-3
                  tw-rounded-lg
                  tw-text-base
                "
              >
                Ir a la maltería
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
export default {
  name: "FinishFullTrivia",
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
  props: {
    responseTrivia: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
    },
    triviaName: {
      type: String,
    },
    calification: {
      type: Number,
    },
  },
  mounted() {
    window.dataLayer.push({
      event: "TrackEvent-trivias",
      category: "Trivias",
      SubCategory: this.triviaName,
      Action: "Paso 3: Finalizó la trivia",
      Label: "Aprobó",
      Calificacion: this.calification,
    });
  },
};
</script>

<style scoped>
.bg-1 {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 10px 120px 10px 10px;
}
</style>
