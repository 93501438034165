import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  getNavbarItems: "/navbarItems",
  shortcutSectionsUri: "/shortcutSections",
};

export const useNavbarStore = defineStore("navbar", () => {
  const navbarItems = ref([]);
  const shortcutSections = ref([]);
  const isUserModalOpen = ref(false);
  const isGeneralNavbarModalOpen = ref(false);
  const isNotificationModalOpen = ref(false);
  const isOrderModalOpen = ref(false);
  const isSupportButtonOpen = ref(true);
  const activeCategory = ref("");

  async function actGetNavbarItems() {
    const response = await AxiosBeerOffice.get(uri.getNavbarItems, true);
    if (response.error) return response.error;
    const { data } = response;
    navbarItems.value = data;
    return true;
  }

  async function actGetShortcutSections() {
    const response = await AxiosBeerOffice.get(uri.shortcutSectionsUri, true);
    if (response.error) return response.error;
    const { data } = response;
    shortcutSections.value = data;
    return true;
  }

  function closeAllModals() {
    isUserModalOpen.value = false;
    isNotificationModalOpen.value = false;
    isGeneralNavbarModalOpen.value = false;
    isOrderModalOpen.value = false;
  }

  function changeUserModalOpen(data) {
    isUserModalOpen.value = data;
  }

  function changeGeneralNavbarModalOpen(data) {
    isGeneralNavbarModalOpen.value = data;
  }

  function changeNotificationModalOpen(data) {
    isNotificationModalOpen.value = data;
  }

  function showSupportButton() {
    isSupportButtonOpen.value = true;
  }

  function hideSupportButton() {
    isSupportButtonOpen.value = false;
  }

  function changeActiveCategory(data) {
    activeCategory.value = data;
  }

  function toggleOrderModal() {
    isOrderModalOpen.value = !isOrderModalOpen.value;
  }

  function hideOrderModal() {
    isOrderModalOpen.value = false;
  }

  function getAllModalsStatus() {
    return Boolean(
      isUserModalOpen.value ||
        isGeneralNavbarModalOpen.value ||
        isNotificationModalOpen.value ||
        isOrderModalOpen.value
    );
  }

  return {
    navbarItems,
    shortcutSections,
    isUserModalOpen,
    isGeneralNavbarModalOpen,
    isOrderModalOpen,
    isNotificationModalOpen,
    isSupportButtonOpen,
    activeCategory,
    actGetNavbarItems,
    actGetShortcutSections,
    closeAllModals,
    changeUserModalOpen,
    changeGeneralNavbarModalOpen,
    changeNotificationModalOpen,
    showSupportButton,
    hideSupportButton,
    changeActiveCategory,
    toggleOrderModal,
    hideOrderModal,
    getAllModalsStatus,
  };
});
