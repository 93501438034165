<script setup>
import { ref, getCurrentInstance, onBeforeMount, onMounted } from "vue";
import { useEcommerceStore } from "@/stores/ecommerceStore";
import { useUserStore } from "@/stores/userStore";
import { useRoute } from "vue-router/composables";

const ecommerceStore = useEcommerceStore();
const userStore = useUserStore();
const route = useRoute();
const { $bvModal } = getCurrentInstance().proxy;
const deliverySiteSelected = ref(null);
const emit = defineEmits(["updateDeliverySite"]);

const updateDeliverySite = () => {
  emit("updateDeliverySite", deliverySiteSelected);
};

onBeforeMount(async () => {
  await ecommerceStore.actGetLocations().then(() => {
    deliverySiteSelected.value = userStore.user.ecomm_delivery_site_id;
  });
});

onMounted(() => {
  if (userStore.user.ecomm_delivery_site_id == null && route.path !== "/home") {
    $bvModal.show("modal-claim");
  }
});
</script>
<template>
  <b-modal
    id="modal-claim"
    body-class="p-0"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="custom3"
  >
    <div class="tw-container tw-m-auto">
      <div
        class="tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-h-full"
      >
        <img
          src="@/assets/img/ecommerce/modal-place.png"
          alt="banner"
          class="tw-h-32 md:tw-h-72 tw-w-full tw-object-cover"
        />
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-full tw-m-auto"
        >
          <div class="tw-px-4 tw-py-4">
            <h4
              class="tw-text-xs md:tw-text-lg tw-text-gray-600 tw-font-robotoBold"
            >
              Selecciona el lugar donde quieres reclamar
            </h4>
            <p class="tw-text-gray-300 tw-text-xs">
              La disponibilidad de los productos varía según el punto de
              elección
            </p>
            <div class="tw-mt-3">
              <select
                v-model="deliverySiteSelected"
                class="tw-w-full tw-bg-gray-200 tw-text-sm tw-rounded-md tw-shadow-md focus:tw-outline-none focus:tw-border-gray-300 focus:tw-ring-0 focus:tw-ring-gray-320"
              >
                <option :value="null" disabled>Seleccionar...</option>
                <option
                  v-for="(location, idx) in ecommerceStore.locations"
                  :value="location.id"
                  :key="idx"
                  :selected="location.id === deliverySiteSelected"
                >
                  {{ location.name }}
                </option>
              </select>
              <button
                @click="updateDeliverySite()"
                class="tw-mt-6 tw-border-0 tw-bg-red-400 tw-w-full tw-text-white tw-p-2 tw-rounded-lg hover:tw-bg-red-600"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
