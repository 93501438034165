<script setup>
import { useRouter } from "vue-router/composables";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";

const router = useRouter();
</script>

<template>
  <div>
    <mobile-bar title="Reserva" />
    <title-bar title="Reserva" />
    <main class="tw-container-general">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-gap-5 md:tw-gap-7 tw-my-3 md:tw-mt-10 md:tw-w-2/4 tw-m-auto"
      >
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-xl md:tw-text-4xl tw-text-red-350"
        >
          ¡Confirmación pendiente!
        </p>
        <img
          alt=""
          class="tw-w-3/5 md:tw-w-3/5"
          src="@/assets/img/splash/reservations_cel.png"
        />
        <p
          class="tw-font-roboto tw-text-xs md:tw-text-lg tw-text-center"
        >
          Tu reserva ha sido guardada con éxito. Nuestro equipo está revisando
          la disponibilidad del espacio y te notificaremos tan pronto como sea
          confirmada o rechazada. ¡Gracias por tu paciencia!
        </p>
        <div
          class="tw-btn-primary-red tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-py-2 md:tw-py-3"
          @click="router.push({ name: 'Reservations' })"
        >
          <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-lg">
            Volver a reserva de espacios
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped></style>
