<script setup>
import { getCurrentInstance, ref } from "vue";
import config from "@/services/config";
import { pushLink } from "@/helpers/linkHelpers";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";
import { removeHTMLTagsFromText } from "@/helpers/textHelpers";

const isHovering = ref(false);
const uriResources = config.beerOfficeURL;
const { $isMobile: mobile } = getCurrentInstance().proxy;

const emit = defineEmits(["updatedReaction", "updatedComment"]);

function linkTo(link, label) {
  if (link !== null) {
    pushLink(link, {
      event_category: props.categoryForDatalayer,
      event_action: props.actionForDatalayer,
      event_label: props.title,
      element_text: label,
      component_name: "Click",
    });
  }
}

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  item: {
    type: Object,
    required: true,
  },
  change: {
    type: Boolean,
    default: false,
  },
  mobileHorizontal: {
    type: Boolean,
    default: false,
  },
  deskHorizontal: {
    type: Boolean,
    default: false,
  },
  deskSquare: {
    type: Boolean,
    default: false,
  },
  category: {
    type: String,
    default: "",
  },
  categoryForSocial: {
    type: String,
    default: "",
  },
  categoryForDatalayer: {
    type: String,
    default: "",
  },
  actionForDatalayer: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  link: {
    type: String,
    default: null,
  },
  image: {
    type: String,
    default: null,
  },
  imageCover: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div
    class="tw-grid tw-grid-cols-12 tw-gap-2 tw-h-full"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div
      class="md:tw-col-span-12 tw-flex"
      :class="props.change === true ? 'tw-col-span-12' : 'tw-col-span-5'"
    >
      <div
        @click="linkTo(props.link, 'Imagen')"
        class="tw-flex-auto tw-cursor-pointer lg:tw-aspect-w-16 lg:tw-aspect-h-10"
      >
        <img
          :src="uriResources + '/' + props.image"
          loading="lazy"
          alt="image"
          class="tw-rounded-2xl tw-w-full tw-shrink-0"
          :class="[
            deskHorizontal && !mobile()
              ? 'lg:tw-h-80'
              : mobile() && mobileHorizontal
              ? 'tw-h-44'
              : 'tw-max-h-48',
            imageCover ? 'tw-object-cover' : 'tw-object-contain',
          ]"
        />
        <!--
          <img
            :src="
              item.image_mobile &&
              (($isMobile() && !mobileHorizontal) || deskSquare)
                ? uriResources + '/' + item.image_mobile
                : uriResources + '/' + item.image
            "
            loading="lazy"
            alt="image"
            class="tw-rounded-2xl tw-object-cover tw-w-full tw-h-44 md:tw-h-44 tw-shrink-0 lg:tw-h-80"
          />
-->
      </div>
    </div>
    <div
      class="md:tw-col-span-12 tw-flex tw-flex-col tw-justify-between lg:tw-justify-end"
      :class="props.change === true ? 'tw-col-span-12' : 'tw-col-span-7'"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <p
          class="tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-3 tw-underline tw-my-1"
        >
          {{ props.category }}
        </p>
        <!--        <p
          v-if="item.new === 1"
          class="tw-font-robotoBold tw-text-xs lg:tw-text-base tw-text-red-350 tw-px-4 tw-bg-pink-100 tw-rounded-xl tw-mb-0"
        >
          ¡Nuevo!
        </p>-->
      </div>
      <p
        class="tw-font-robotoBold tw-text-base lg:tw-text-2xl md:tw-my-2"
        :class="props.change === true ? 'tw-my-2' : 'tw-mb-0'"
      >
        {{ props.title }}
      </p>
      <p
        class="tw-font-roboto tw-text-xs lg:tw-text-base tw-leading-4 lg:tw-leading-5 tw-line-clamp-3 md:tw-line-clamp-2 md:tw-my-2"
        :class="props.change === true ? 'tw-mb-2' : 'tw-mb-0'"
      >
        {{ removeHTMLTagsFromText(props.description) }}
      </p>
      <div
        class="tw-flex md:tw-flex-row md:tw-items-center tw-justify-end md:tw-justify-between"
        :class="
          props.change === true
            ? 'tw-flex-row-reverse tw-items-center tw-mt-2 md:tw-mt-auto tw-gap-4'
            : 'tw-flex-col tw-mt-3 tw-gap-2'
        "
      >
        <comp-social-network-interactions
          :id="props.id"
          :title="props.title"
          :category="props.categoryForSocial"
          :user_reaction="props.item.user_reaction"
          :amount_comments="Number(props.item.amount_comments)"
          :amount_reactions="Number(props.item.amount_reactions)"
          :image="props.image"
          :description="props.description"
          :item="props.item"
          :hover="isHovering"
          :category-for-datalayer="props.categoryForDatalayer"
          :action-for-datalayer="props.actionForDatalayer"
          @updatedComment="emit('updatedComment', $event)"
          @updatedReaction="emit('updatedReaction', $event)"
        />
        <div
          @click="linkTo(props.link, 'Saber más')"
          class="tw-flex tw-items-center tw-cursor-pointer"
        >
          <p
            class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
          >
            Saber más
          </p>
          <i
            class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-xs lg:tw-text-base"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
