const ScreenSizes = {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {
    const windowProp = Vue.observable({ $windowWidth: window.innerWidth });
    Vue.mixin({
      created() {
        window.addEventListener("resize", () => {
          windowProp.$windowWidth = window.innerWidth;
        });
      },
    });
    Vue.prototype.$isMobile = function () {
      return windowProp.$windowWidth < 768;
    };
  },
};

export default ScreenSizes;
