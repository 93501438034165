<script setup>
import config from "@/services/config";
import { isPastDate, isStillTime } from "@/helpers/datesHelpers";
import { useRouter } from "vue-router/composables";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";

const router = useRouter();
const uriResources = config.beerOfficeURL;

const props = defineProps({
  contest: {
    type: Object,
    required: true,
  },
});

const goToContest = () => {
  if (props.contest?.comp_competition_type_id === 1) {
    router.push(`/contests/${props.contest.id}`);
  } else if (
    props.contest?.comp_competition_type_id === 2 ||
    props.contest?.comp_competition_type_id === 3
  ) {
    router.push(`/competition/competition/${props.contest.id}`);
  }
};
</script>

<template>
  <div
    class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-grid-rows-rows_3_fit_first_and_last tw-gap-2 lg:tw-gap-3"
  >
    <div class="">
      <p
        class="tw-text-center tw-font-robotoBold tw-text-red-350 tw-text-sm lg:tw-text-base tw-m-0"
        v-if="isStillTime(props.contest.end)"
      >
        ¡Concurso Activo!
      </p>
    </div>
    <div class="tw-row-span-3">
      <img
        :src="`${uriResources}/${props.contest.icon}`"
        alt=""
        class="tw-rounded-2.5xl tw-h-full tw-w-full tw-object-cover tw-max-h-[300px]"
      />
    </div>
    <div class="tw-flex tw-flex-col tw-gap-2">
      <p
        class="tw-text-center tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-m-0"
      >
        {{ props.contest.name }}
      </p>
      <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-evenly tw-gap-2">
        <p class="tw-font-roboto tw-text-sm lg:tw-text-base">
          {{ props.contest.desc }}
        </p>
        <div class="tw-flex tw-justify-evenly tw-gap-8">
          <p class="tw-font-roboto tw-text-sm lg:tw-text-base">
            <span class="tw-font-robotoBold">Inicio: </span>
            {{ props.contest.start ? props.contest.start : "No hay fecha" }}
          </p>
          <p class="tw-font-roboto tw-text-sm lg:tw-text-base">
            <span class="tw-font-robotoBold">Fin: </span
            >{{ props.contest.end ? props.contest.end : "No hay fecha" }}
          </p>
        </div>
      </div>
    </div>
    <div class="tw-row-span-1 tw-order-3">
      <comp-button-primary-red
        :title="
          isPastDate(props.contest.end)
            ? 'Concurso finalizado'
            : 'Acceder al concurso'
        "
        class="tw-w-full tw-h-full"
        @click.native="isPastDate(props.contest.end) ? () => {} : goToContest()"
      />
    </div>
  </div>
</template>
