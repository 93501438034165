<script setup>
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const userStore = useUserStore();

const error = ref({ flag: false, class: "", message: "" });
const login = ref({ cc: "", password: "" });
const isLoading = ref(false);

const resetPassword = (password) => {
  authStore.setPassword = password;
  router.push("validate-account");
};

const validateLogin = async () => {
  error.value = { flag: false, class: "", message: "" };
  isLoading.value = true;
  const response = await authStore.actAuthLogin(login.value);
  if (response !== true) {
    isLoading.value = false;
    error.value = {
      flag: true,
      class: "is-invalid",
      message: response.message || "Los datos ingresados no son correctos",
    };
  } else {
    await userStore.actGetUserById(userStore.user?.id).then(() => {
      pushDataLayer();
      const redirect = route.query.redirect || "/";
      if (typeof redirect === "string") {
        router.replace(redirect);
      } else {
        // Handle other types or provide a default redirect
        router.replace("/");
      }
    });
  }
};

const toggleInputPassword = (event) => {
  let inputText = event.target.parentElement.querySelector("input");
  let spanIcon = event.target.parentElement.querySelector("span");
  if (inputText.getAttribute("type") === "password") {
    inputText.setAttribute("type", "text");
    spanIcon.classList.remove("icon-visibilidad-sin-relleno");
    spanIcon.classList.add("icon-visibilidad-relleno");
  } else {
    inputText.setAttribute("type", "password");
    spanIcon.classList.remove("icon-visibilidad-relleno");
    spanIcon.classList.add("icon-visibilidad-sin-relleno");
  }
};

const pushDataLayer = () => {
  try {
    const diff_ms =
      Date.now() -
      new Date(userStore.user.birthdate.replace("-", "/")).getTime();
    const edad = Math.abs(new Date(diff_ms).getUTCFullYear() - 1970);
    window.dataLayer.push({
      event: "Login",
      userID: userStore.user.id,
      Banda: userStore.user.core_pa_sideline.name,
      cargo: userStore.user.core_position.name,
      Localizacion: userStore.user.core_organizational_unit.name,
      convenio: userStore.user.ben_agreement.name,
      Tipodecontrato: userStore.user.ben_contract.acronym,
      area: userStore.user.core_vp.name,
      Regional: userStore.user.core_site.core_regional?.name,
      Genero: userStore.user.gender,
      Edad: edad,
    });
  } catch (error) {
    console.error(error);
  }
};

onBeforeMount(() => {
  error.value.flag = false;
  error.value.class = "";
});
</script>
<template>
  <div class="tw-px-4 xl:tw-px-6 tw-font-roboto">
    <h2 class="text-center tw-text-3xl md:tw-text-4xl tw-font-tolyerBold">
      ¡Inicia la experiencia!
    </h2>
    <p
      class="tw-text-sm md:tw-text-base tw-mb-4 tw-text-gray-600 tw-font-roboto"
    >
      Ingresa tus datos e inicia sesión para empezar a vivir una experiencia
      única con BeerOffice.
    </p>
    <form @submit.prevent="validateLogin()" class="tw-font-roboto">
      <small
        v-show="error.flag"
        class="tw-text-red-600 tw-bg-red-100 tw-px-2 tw-py-1 tw-mb-3 tw-block"
        >{{ error.message }}</small
      >
      <div class="tw-mb-4">
        <label class="tw-block">Cédula</label>
        <input
          type="text"
          v-model="login.cc"
          :class="
            'tw-w-full tw-text-gray-600 tw-border-gray-300 tw-rounded-md focus:tw-border-gray-320 focus:tw-outline-none focus:tw-ring-gray-320 ' +
            error.class
          "
          placeholder="Escribe tu cédula sin puntos ni comas"
          required
        />
      </div>
      <div class="tw-mb-4 tw-relative">
        <label class="tw-block">Contraseña</label>
        <input
          type="password"
          v-model="login.password"
          :class="
            'tw-w-full tw-text-gray-600 tw-border-gray-300 tw-rounded-md focus:tw-border-gray-320 focus:tw-outline-none focus:tw-ring-gray-320 ' +
            error.class
          "
          placeholder="Escribe tu contraseña"
          required
        />
        <span
          @click="toggleInputPassword($event)"
          class="icon-visibilidad-sin-relleno tw-absolute tw-top-11 tw-right-2 tw-text-gray-320 tw-text-xl"
        ></span>
      </div>
      <div class="tw-text-right">
        <button
          type="button"
          class="tw-text-xs tw-border-0 tw-bg-transparent tw-underline tw-text-gray-300"
          @click="resetPassword(1)"
        >
          ¿Olvidaste tu contraseña?
        </button>
      </div>
      <div class="tw-text-center tw-mt-4 tw-mb-4">
        <div class="text-center" v-if="isLoading">
          <img
            src="@/assets/img/icons/loading.gif"
            class="tw-h-10"
            alt="loading"
          />
        </div>
        <button
          v-else
          type="submit"
          class="tw-border-0 tw-bg-red-400 tw-text-white tw-px-12 tw-py-2 tw-rounded-full hover:tw-bg-red-600"
        >
          Iniciar sesión
        </button>
      </div>
    </form>
    <div class="tw-text-center">
      <button
        type="button"
        class="tw-text-xs tw-border-0 tw-bg-transparent tw-underline tw-text-red-400"
        @click="resetPassword(0)"
      >
        No he activado mi cuenta
      </button>
    </div>
  </div>
</template>
