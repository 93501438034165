const people = {
  data() {
    return {
      people_cards: [
        {
          id: 0,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "A las personas correctas",
        },
        {
          id: 1,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "En el lugar correcto",
        },
        {
          id: 2,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "En el momento correcto",
        },
        {
          id: 3,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "Recibiendo las recompensas correspondientes",
        },
        {
          id: 4,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "Con el compromiso esperado",
        },
        {
          id: 5,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "Con las capacidades adecuadas",
        },
        {
          id: 6,
          icon: require("@/assets/img/peopleCycle/pc_icon_1.png"),
          text: "Dando resultados de calidad",
        },
      ],
      target: {
        target_cards: [
          {
            id: 0,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "A las personas correctas",
          },
          {
            id: 1,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "En el lugar correcto",
          },
          {
            id: 2,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "En el momento correcto",
          },
          {
            id: 3,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "Recibiendo las recompensas correspondientes",
          },
          {
            id: 4,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "Con el compromiso esperado",
          },
        ],
      },
      capabilities: {
        capabilities_pilars_desc: "",
        capabilities_pilars: [
          {
            id: 0,
            icon: require("@/assets/img/peopleCycle/cap_pilars_icon_1.png"),
            text: "Sueña en grande",
            bg_color: "tw-bg-yellow-100",
            text_color: "tw-text-orange-300",
            border_color: "tw-border-2 tw-border-solid tw-border-orange-300 ",
          },
          {
            id: 1,
            icon: require("@/assets/img/peopleCycle/cap_pilars_icon_2.png"),
            text: "Desarrolla nuestra gente",
            bg_color: "tw-bg-yellow-100",
            text_color: "tw-text-orange-600",
            border_color: "tw-border-2 tw-border-solid tw-border-orange-600 ",
          },
          {
            id: 2,
            icon: require("@/assets/img/peopleCycle/cap_pilars_icon_3.png"),
            text: "Vive nuestra cultura",
            bg_color: "tw-bg-yellow-100",
            text_color: "tw-text-orange-300",
            border_color: "tw-border-2 tw-border-solid tw-border-orange-300 ",
          },
        ],
        capabilities_feedback_desc:
          "Este es el momento en el que formalmente tu líder te comunicará el resultado de tu evaluación de capabilities, resaltando tus fortalezas y oportunidades de mejora mostradas en el último año. Juntos definirán las acciones foco de tu Plan de Desarrollo Personal.",
        capabilities_feedback: [
          {
            id: 0,
            icon: require("@/assets/img/peopleCycle/cap_feedback_icon_1.png"),
            text:
              "> Revisar resultados de evaluación de Capabilities (Líder y evaluado)",
            bg_color: "tw-bg-yellow-100",
            text_color: "tw-text-orange-300",
            border_color: "tw-border-2 tw-border-solid tw-border-orange-300 ",
          },
          {
            id: 1,
            icon: require("@/assets/img/peopleCycle/cap_feedback_icon_2.png"),
            text: "Reservar el espacio adecuado",
            bg_color: "tw-bg-yellow-100",
            text_color: "tw-text-orange-600",
            border_color: "tw-border-2 tw-border-solid tw-border-orange-600 ",
          },
          {
            id: 2,
            icon: require("@/assets/img/peopleCycle/cap_feedback_icon_3.png"),
            text: "Construir el PDP",
            bg_color: "tw-bg-yellow-100",
            text_color: "tw-text-orange-300",
            border_color: "tw-border-2 tw-border-solid tw-border-orange-300 ",
          },
        ],
      },
      pdp: {
        steps: [
          {
            id: 1,
            text_1: "Identificar",
            text_2: "Las brechas de desarrollo",
            desc:
              "Con base en lo conversado durante la retroalimentación de Capabilities",
            bg_color: "rgba(210, 48, 54, 0.2)",
            text_color: "tw-text-red-500",
            border_color: "tw-border-2 tw-border-solid tw-border-red-500 ",
          },
          {
            id: 2,
            text_1: "Definir",
            text_2: "Los objetivos de desarrollo",
            desc: "Siguiendo la metodología SMART",
            bg_color: "#FFCAC9",
            text_color: "tw-text-red-200",
            border_color: "tw-border-2 tw-border-solid tw-border-red-200 ",
          },
          {
            id: 3,
            text_1: "Clasificar",
            text_2: "Los objetivos de desarrollo",
            desc:
              "De acuerdo con el modelo de aprendizaje 70 - 20 - 10 y metodología SMART",
            bg_color: "rgba(210, 48, 54, 0.2)",
            text_color: "tw-text-red-500",
            border_color: "tw-border-2 tw-border-solid tw-border-red-500 ",
          },
          {
            id: 4,
            text_1: "Validar PDP",
            text_2: "Con line Manager y registrar en el sistema",
            desc: "Registrar PDP en Navigate/Mercer",
            bg_color: "#FFCAC9",
            text_color: "tw-text-red-200",
            border_color: "tw-border-2 tw-border-solid tw-border-red-200 ",
          },
          {
            id: 5,
            text_1: "Monitorear",
            text_2: "Ajustar las necesidades",
            desc: "Hacer seguimiento continuo y ajustar según necesidades",
            bg_color: "rgba(210, 48, 54, 0.2)",
            text_color: "tw-text-red-500",
            border_color: "tw-border-2 tw-border-solid tw-border-red-500 ",
          },
        ],
        pdp_cards: [
          {
            id: 0,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "A las personas correctas",
          },
          {
            id: 1,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "En el lugar correcto",
          },
          {
            id: 2,
            icon: require("@/assets/img/peopleCycle/tb_icon_1.png"),
            text: "En el momento correcto",
          },
        ],
      },
    };
  },
};

export default people;
