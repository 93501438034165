<script setup>
import { onBeforeMount, ref } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import { useNavbarStore } from "@/stores/navbarStore";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import GeneralArticles from "@/components/articles/CompArticlesGeneral.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import GeneralSlider from "@/components/sliders/CompGeneralSlider.vue";
import GeneralSplash from "@/components/general/GeneralSplash.vue";
import SendArticle from "@/components/articles/CompArticlesSendArticle.vue";
import IdeaArticle from "@/components/articles/CompArticlesIdea.vue";
import ThanksArticle from "@/components/articles/CompArticlesThanks.vue";
import CompGeneralTitleBar from "@/components/general/CompGeneralTitleBar.vue";

const articlesStore = useArticlesStore();
const navbarStore = useNavbarStore();
const isLoading = ref(false);
const isArticlesModal = ref(false);
const isArticleIdeaModal = ref(false);
const isSendArticleModal = ref(false);
const isThanksArticleModal = ref(false);
const isSplash = ref(true);

const showArticlesModal = () => {
  isArticlesModal.value = true;
  navbarStore.hideSupportButton();
  pushDataLayer({
    event: "GAEvent",
    event_category: "Artículos",
    event_action: "Interacciones",
    event_label: "¿Te gustaría ver algún artículo? Escríbenos",
    interaction: "True",
    component_name: "Click",
  });
};

const hideArticlesModal = () => {
  isArticlesModal.value = false;
  navbarStore.showSupportButton();
};

const showArticleIdeaModal = () => {
  isArticlesModal.value = false;
  isArticleIdeaModal.value = true;
  navbarStore.hideSupportButton();
};

const hideArticleIdeaModal = () => {
  isArticleIdeaModal.value = false;
  isArticlesModal.value = true;
};

const showSendArticleModal = () => {
  isArticlesModal.value = false;
  isSendArticleModal.value = true;
  navbarStore.hideSupportButton();
};

const hideSendArticleModal = () => {
  isSendArticleModal.value = false;
  isArticlesModal.value = true;
};

const hideThanksArticleModal = () => {
  isThanksArticleModal.value = false;
  navbarStore.showSupportButton();
};

const articleSent = () => {
  isSendArticleModal.value = false;
  isArticleIdeaModal.value = false;
  isThanksArticleModal.value = true;
  navbarStore.hideSupportButton();
};

onBeforeMount(async () => {
  isLoading.value = true;
  await articlesStore.actGetArticles();
  await articlesStore.actGetArticleCategories();
  isLoading.value = false;
});
</script>
<template>
  <div>
    <!-- ¿Qué deseas ver? Modal-->
    <portal to="all-modals">
      <general-modal
        :hide-button-support="true"
        v-if="isArticlesModal"
        modalName="articles"
        @close="hideArticlesModal"
        :isShowModal="isArticlesModal"
        :modalWidth="{ md: 500, lg: 600 }"
        :maxHeight="{ base: 400, md: 500, lg: 600 }"
      >
        <div class="tw-pb-5">
          <p
            class="tw-font-robotoBold tw-text-red-350 tw-text-xl md:tw-text-3xl"
          >
            ¿Qué deseas ver?
          </p>
          <div class="tw-grid tw-grid-rows-2 tw-gap-7 tw-mt-5 lg:tw-mt-10">
            <div class="tw-grid tw-grid-cols-col2_fit_first tw-gap-5">
              <div
                class="tw-h-24 tw-w-24 lg:tw-h-36 lg:tw-w-36 tw-rounded-2.5xl lg:tw-rounded-4xl tw-overflow-hidden"
              >
                <img
                  src="@/assets/img/Aporta-al-pais.jpg"
                  class="tw-w-full tw-h-full tw-object-cover"
                  alt=""
                />
              </div>
              <div class="tw-flex tw-flex-col tw-justify-between">
                <p class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-xl">
                  Dar una idea para un artículo
                </p>
                <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-lg">
                  Si tienes una idea para un artículo de embajadores,
                  escríbenos.
                </p>
                <div
                  class="tw-flex tw-items-center tw-cursor-pointer"
                  @click="showArticleIdeaModal"
                >
                  <p
                    class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
                  >
                    Saber más
                  </p>
                  <i
                    class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-xl"
                  ></i>
                </div>
              </div>
            </div>
            <div class="tw-grid tw-grid-cols-col2_fit_first tw-gap-5">
              <div
                class="tw-h-24 tw-w-24 lg:tw-h-36 lg:tw-w-36 tw-rounded-2.5xl lg:tw-rounded-4xl tw-overflow-hidden"
              >
                <img
                  src="@/assets/img/Aporta-al-pais.jpg"
                  class="tw-w-full tw-h-full tw-object-cover"
                  alt=""
                />
              </div>
              <div class="tw-flex tw-flex-col tw-justify-between">
                <p class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-xl">
                  Enviar un artículo
                </p>
                <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-lg">
                  Puedes escribir un artículo o compartir uno que conozcas.
                </p>
                <div
                  class="tw-flex tw-items-center tw-cursor-pointer"
                  @click="showSendArticleModal"
                >
                  <p
                    class="tw-font-roboto tw-text-xs lg:tw-text-base lg:tw-leading-5 tw-text-red-350 tw-m-0"
                  >
                    Saber más
                  </p>
                  <i
                    class="icon-flecha-der tw-text-red-350 tw-ml-1 tw-text-base lg:tw-text-xl"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </general-modal>

      <!-- Idea Modal-->
      <general-modal
        :hide-button-support="true"
        v-if="isArticleIdeaModal"
        modalName="articleIdea"
        @close="hideArticleIdeaModal"
        :isShowModal="isArticleIdeaModal"
        :modalWidth="{ md: 500, lg: 600 }"
        :maxHeight="{ base: 400, md: 500, lg: 700 }"
      >
        <IdeaArticle @articleSent="articleSent" />
      </general-modal>
      <!-- Send Article Modal-->
      <general-modal
        :hide-button-support="true"
        v-if="isSendArticleModal"
        modalName="sendArticle"
        @close="hideSendArticleModal"
        :isShowModal="isSendArticleModal"
        :modalWidth="{ md: 500, lg: 600, xl: 600 }"
        :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      >
        <SendArticle
          @articleSent="articleSent"
          @closeModal="hideSendArticleModal"
        />
      </general-modal>
      <!-- Thanks Ambassador Modal -->
      <general-modal
        :hide-button-support="true"
        v-if="isThanksArticleModal"
        modalName="sendArticle"
        @close="hideThanksArticleModal"
        :isShowModal="isThanksArticleModal"
        :modalWidth="{ md: 500, lg: 600, xl: 600 }"
        :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      >
        <ThanksArticle @close="hideThanksArticleModal" />
      </general-modal>
    </portal>
    <div v-if="isSplash">
      <GeneralSplash
        illustration="illustration-articles.svg"
        title="Artículos"
        icon="icon-inside.svg"
        @loaded="isSplash = false"
      >
        Bienvenidos a nuestra sección de
        <strong>noticias, blogs informativos y contenidos</strong> pensados para
        ti.
      </GeneralSplash>
    </div>
    <div v-else>
      <comp-general-title-bar title="Artículos" />
      <div class="tw-container-general">
        <general-slider :articles="articlesStore.getArticlesFeatured" />
        <hr class="tw-my-4 md:tw-my-6 lg:tw-my-10" />
        <GeneralArticles
          :articlesGeneral="articlesStore.getArticlesGeneral"
          :isLoading="isLoading"
        />
        <div
          class="tw-mt-8 lg:tw-my-14 tw-block tw-text-center tw-text-xs md:tw-text-base lg:tw-text-xl tw-cursor-pointer tw-text-white tw-p-3 lg:tw-px-12 tw-rounded-full tw-w-full lg:tw-w-max lg:tw-mx-auto tw-bg-red-400 hover:tw-bg-red-600 hover:tw-text-white"
          @click="showArticlesModal"
        >
          ¿Te gustaría ver algún artículo ? Escríbenos
        </div>
      </div>
    </div>
  </div>
</template>
