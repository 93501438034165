<template>
  <router-link
    :to="'/e-commerce/product/' + product.id"
    class="d-block w-100 he-100 tw-rounded-lg mb-3 tw-object-cover"
    :style="
      'background: url(' +
      uriResources +
      '/' +
      product.img +
      ') no-repeat; background-size: cover;'
    "
  >
    <div class="position-absolute shaw-h-sm wi-300 he-100 tw-rounded-lg">
      <p class="text-white text-uppercase tw-text-sm wi-200 mt-3 ml-3 line-height-1">
        {{ product.name }}
      </p>
      <p class="text-white font-weight-bold tw-text-sm ml-3">¡Regalo!*</p>
    </div>
  </router-link>
</template>

<script>
import config from "@/services/config";

export default {
  name: "CompMonthProduct",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
    };
  },
};
</script>
<style scoped>
.shaw-h-sm {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.3588) 2.81%,
    rgba(0, 0, 0, 0) 84.49%
  );
}
</style>