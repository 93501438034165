<template>
  <div class="tw-p-4 tw-shadow-md tw-rounded-md">
    <div class="tw-flex tw-items-center tw-content-between">
      <div>
        <p class="tw-font-bold tw-text-gray-600 tw-text-xl">{{ title }}</p>
        <p class="tw-text-gray-400  tw-text-xs ">
          {{ description }}
        </p>
      </div>
      <img
        class="tw-object-cover tw-ml-4"
        :src="icon"
        width="60"
        height="60"
        alt="icon"
      />
    </div>
    <div class="tw-mt-3">
      <img :src="image" class="tw-w-full tw-rounded-lg" alt="image" />
    </div>
    <router-link
      class="
        tw-block tw-bg-red-400 tw-rounded-lg tw-p-3 tw-text-base
        hover:tw-bg-red-600 tw-text-white tw-text-center tw-mt-4 hover:tw-text-white
      "
      :to="link"
      @click.native="datalayer ? pushDataLayer() : ''"
    >
      {{ buttonName }}
    </router-link>
    <p class="tw-text-gray-400  tw-text-xs  tw-mt-3" v-if="this.disclaimer">
      {{ disclaimer }}
    </p>
  </div>
</template>

<script>
export default {
  name: "CompDivLink",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    image: {
      type: String,
    },
    buttonName: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    disclaimer: {
      type: String,
    },
    datalayer: {
      type: Object,
    },
  },
  methods: {
    pushDataLayer() {
      window.dataLayer.push({
        event: this.datalayer.event,
        Category: this.datalayer.Category,
        SubCategory: this.datalayer.SubCategory,
        Action: this.datalayer.Action,
        Label: this.datalayer.Label,
        Value: this.datalayer.Value,
      });
    },
  },
};
</script>
