<script setup>
import { ref, computed } from "vue";
import config from "@/services/config";
import { useEcommerceStore } from "@/stores/ecommerceStore";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const ecommerceStore = useEcommerceStore();
const uriResources = config.beerOfficeURL;
const hoverImage = ref(false);

const getProductQty = computed(() => {
  let qty = 0;
  if (ecommerceStore.shoppingCartArray.length > 0) {
    ecommerceStore.shoppingCartArray.forEach((item) => {
      if (item.id === props.product.id) {
        qty = item.qty;
      }
    });
  }
  return qty;
});

const incrementNumber = () => {
  pushDataLayerAddToCart();
  ecommerceStore.addProductToShoppingCart(props.product);
};

const decrementNumber = () => {
  pushDataLayerRemoveToCart();
  if (getProductQty.value >= 1) {
    ecommerceStore.subsProductToShoppingCart(props.product);
  }
  if (getProductQty.value === 0) {
    ecommerceStore.delProductToShoppingCart(props.product);
  }
};

const addFirstUnity = () => {
  incrementNumber();
};

const pushDataLayerAddToCart = () => {
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "COP",
      add: {
        products: [
          {
            name: props.product.name,
            id: props.product.id,
            price: props.product.price_public,
            brand: props.product.ecomm_brand.name,
            category: props.product.ecomm_product_category?.name,
            quantity: 1,
          },
        ],
      },
    },
  });
};

const pushDataLayerRemoveToCart = () => {
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "removeToCart",
    ecommerce: {
      currencyCode: "COP",
      remove: {
        products: [
          {
            name: props.product.name,
            id: props.product.id,
            price: props.product.price_public,
            brand: props.product.ecomm_brand.name,
            category: props.product.ecomm_product_category?.name,
            quantity: 1,
          },
        ],
      },
    },
  });
};
</script>
<template>
  <div class="lg:tw-h-full">
    <div
      class="tw-grid tw-grid-cols-12 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-6 tw-h-full"
    >
      <div
        class="tw-col-span-5 lg:tw-col-span-1 tw-flex tw-relative"
        @mouseover="hoverImage = true"
        @mouseleave="hoverImage = false"
      >
        <div
          class="tw-flex-auto tw-cursor-pointer tw-h-full tw-aspect-w-5 tw-aspect-h-5 md:tw-aspect-w-16 md:tw-aspect-h-9"
        >
          <div
            v-if="
              hoverImage &&
              props.product.label != null &&
              props.product.color != null
            "
            class="tw-w-full tw-h-full tw-rounded-2xl tw-flex tw-justify-center tw-items-center tw-text-center tw-p-3"
            :style="'background:' + props.product.color"
          >
            <p
              class="tw-m-0 tw-font-roboto tw-text-white tw-text-sm lg:tw-text-lg"
            >
              {{ props.product.label }}
            </p>
          </div>
          <img
            v-else
            :src="uriResources + '/' + props.product.img"
            loading="lazy"
            alt="image"
            class="tw-rounded-2xl tw-object-cover tw-w-full tw-h-full"
          />
        </div>
        <div
          v-if="props.product.new === 1"
          class="tw-rounded-full tw-px-4 tw-h-min tw-absolute tw-top-5 tw--right-2 tw-bg-pink-100"
        >
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-xs lg:tw-text-sm"
          >
            ¡Nuevo!
          </p>
        </div>
      </div>
      <div
        class="tw-col-span-7 lg:tw-col-span-1 tw-flex tw-flex-col tw-justify-between tw-gap-3 lg:tw-gap-5"
      >
        <div>
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-base tw-capitalize lg:tw-text-xl tw-line-clamp-2"
          >
            {{ props.product.name.toLowerCase() }}
          </p>
          <p
            class="tw-m-0 tw-font-roboto tw-text-xxs lg:tw-text-xs tw-text-gray-300 tw-line-clamp-2"
          >
            {{ props.product.ecomm_product_category.name }}
          </p>
        </div>
        <p class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base">
          {{
            props.product.description.charAt(0).toUpperCase() +
            props.product.description.slice(1).toLowerCase()
          }}
        </p>
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg tw-text-red-350"
        >
          {{
            Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
              maximumFractionDigits: 0,
            }).format(props.product.price_public)
          }}
        </p>
        <div class="tw-grow-0 tw-h-8 lg:tw-h-10">
          <!-- add cart (on hover) -->
          <div
            class="tw-rounded-3xl tw-font-roboto tw-text-xs lg:tw-text-sm tw-w-full tw-shadow-none tw-border tw-border-red-350 tw-cursor-pointer tw-overflow-hidden"
            v-if="getProductQty > 0"
          >
            <div
              class="tw-grid tw-grid-cols-col3_fit_first_and_last tw-w-full tw-h-full tw-text-black"
            >
              <div
                class="tw-flex tw-justify-center tw-items-center tw-bg-red-350 tw-cursor-pointer tw-py-1 tw-px-2 md:tw-px-4"
                @click="decrementNumber()"
              >
                <i
                  class="icon-remover-signo tw-text-2xl md:tw-text-3xl tw-text-white"
                ></i>
              </div>
              <div class="tw-flex tw-justify-center tw-items-center">
                <p
                  class="tw-font-roboto tw-font-semibold tw-m-0 tw-text-red-350"
                >
                  {{ getProductQty }}
                </p>
              </div>
              <div
                class="tw-flex tw-justify-center tw-items-center tw-bg-red-350 tw-cursor-pointer tw-py-1 tw-px-2 md:tw-px-4"
                @click="incrementNumber()"
              >
                <i
                  class="icon-signo-mas tw-text-2xl md:tw-text-3xl tw-text-white"
                ></i>
              </div>
            </div>
          </div>
          <div
            class="tw-btn-secondary-white tw-text-center tw-py-2 tw-h-full"
            @click="addFirstUnity()"
            v-else
          >
            <p class="tw-m-0 tw-font-robotoMedium">Agregar al carrito</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
