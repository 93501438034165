<script setup>
import { ref, onBeforeMount, computed } from "vue";
import config from "@/services/config";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router/composables";
import { useProfileStore } from "@/stores/profileStore";
import { useUserStore } from "@/stores/userStore";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

const { actGetProfileQuestions, actPostProfileOnboardingEmployee } =
  useProfileStore();
const { user } = storeToRefs(useUserStore());
const { profileOnboardingSlides, profileQuestions } = storeToRefs(
  useProfileStore()
);
const uriResources = config.beerOfficeURL;
const router = useRouter();

const slide = ref(0);
const mySwiper = ref(null);
const swiperOptions = ref({
  slidesPerView: 1,
  spaceBetween: 15,
  loop: false,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".btn-next",
    prevEl: ".btn-prev",
  },
});
const slideLen = ref(0);
const idSlide = ref(0);
const count = ref(1);
const temp = ref([]);

const swiper_ = computed(() => {
  return mySwiper.value.$swiper;
});

const getFirstName = computed(() => {
  let name = user.value.name.split(" ");
  return name[0];
});

const next = () => {
  swiper_.value.slideNext();
  if (temp.value.length === 0 || temp.value.indexOf(count.value) !== 0) {
    actPostProfileOnboardingEmployee(profileOnboardingSlides.value[count].id);
  }
  temp.value.push(count.value);
  count.value++;
  idSlide.value++;
};

const previous = () => {
  swiper_.value.slidePrev();
  count.value--;
  idSlide.value--;
};

onBeforeMount(async () => {
  await actGetProfileQuestions();
  slideLen.value = profileOnboardingSlides.value.length;
  if (slideLen.value > 0) {
    await actPostProfileOnboardingEmployee(slide.value[0].id);
  } else {
    if (profileQuestions.value.length > 0) {
      await router.push({ path: "questions" });
    } else {
      await router.push({ path: "home" });
    }
  }
});
</script>
<template>
  <div class="OnBoardView">
    <div class="w-100 text-center mb-3 d-none d-md-block">
      <h1
        class="textColorPrimary pt-3 tw-text-4xl md:tw-text-7xl tw-font-tolyerBold"
      >
        EMBAJADORES
      </h1>
    </div>
    <div>
      <span
        class="icon-flecha-der icon-lg icon-primary onboard-ctl onboard-ctl-rigth"
        v-if="idSlide >= 0 && idSlide + 1 < slideLen"
        @click="next"
      ></span>
      <span
        v-if="idSlide > 0"
        class="icon-flecha-izq icon-lg icon-primary onboard-ctl onboard-ctl-left"
        @click="previous"
      ></span>
    </div>
    <swiper
      :slides-per-view="swiperOptions.slidesPerView"
      :space-between="swiperOptions.spaceBetween"
      :loop="swiperOptions.loop"
      :pagination="swiperOptions.pagination"
      :navigation="swiperOptions.navigation"
      ref="mySwiper"
    >
      <swiper-slide
        v-for="(item, index) in profileOnboardingSlides"
        :key="item.id"
      >
        <div class="onboard-slide">
          <div>
            <div
              class="onboarTitle degradeGrayToTrans w-100 text-center mb-3 d-xl-none d-md-none"
            >
              <h1
                class="textColorWhite pt-3 tw-text-4xl md:tw-text-7xl tw-font-tolyerBold"
              >
                EMBAJADORES
              </h1>
            </div>
            <img
              class="img-slide-desk d-none d-md-block mx-auto tw-object-cover max-wi-500"
              :src="uriResources + '/' + item.image"
              alt=""
            />
            <img
              v-if="item.image_mobile !== '' && item.image_mobile !== null"
              class="img-slide-mobile d-block d-md-none tw-object-cover max-wi-500"
              :src="uriResources + '/' + item.image_mobile"
              alt=""
            />
          </div>
          <div class="pt-3 px-4">
            <h3
              class="textSubTitle tw-font-tolyerBold tw-text-xl md:tw-text-3xl"
            >
              {{ getFirstName }}, {{ item.title }}
            </h3>
          </div>
          <p class="px-3 px-md-4">{{ item.description }}</p>
          <div
            class="px-4 px-md-0"
            v-if="index + 1 === profileOnboardingSlides.length"
          >
            <router-link
              v-if="profileQuestions.length > 0"
              to="/questions"
              :class="'btn btn-primary btn-principal  m-0'"
              >Iniciar preguntas</router-link
            >
            <router-link
              v-else
              to="/home"
              :class="'btn btn-primary btn-principal  m-0'"
              >Iniciar</router-link
            >
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <span
      class="icon-flecha-der icon-lg icon-primary d-block d-md-none icon-flecha-der-mobile"
      v-if="idSlide + 1 < slideLen"
      @click="next"
    ></span>
    <img
      class="imgbl d-none d-md-block"
      src="../assets/img/icons/onboardingBL.png"
      alt
    />
    <img
      class="imgbr d-none d-md-block"
      src="../assets/img/icons/onboardingBR.png"
      alt
    />
  </div>
</template>

<style scoped>
/* Small devices*/
p {
  font-size: 16px !important;
}
h3 {
  font-size: 32px !important;
  line-height: 28px;
}

.onboarTitle {
  height: 125px;
}

.onboard-ctl {
  position: fixed;
  top: 45vh;
  z-index: 10;
  display: none;
}
.onboard-ctl-left {
  left: 20vw;
}
.onboard-ctl-rigth {
  right: 20vw;
}

.icon-flecha-der-mobile {
  position: fixed;
  bottom: 4px;
  right: 15px;
}

.onboard-slide {
  text-align: center;
}
.onboard-slide h3 {
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
.onboard-slide p {
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
.onboard-slide img {
  border-radius: 6%;
  height: auto;
}
.is-selected .onboard-slide img {
  margin-top: 0;
  width: 70%;
}
.is-selected .onboard-slide h3 {
  opacity: 1;
}
.is-selected .onboard-slide p {
  opacity: 1;
}

@media (max-width: 600px) {
  .onboard-ctl {
    position: fixed;
    top: 25%;
    width: 35px;
  }
  .onboard-ctl-left {
    left: 20px;
  }
  .onboard-ctl-rigth {
    right: 20px;
  }
  .onboard-slide {
    width: 100vw;
  }
  .onboard-slide img {
    width: 140%;
    opacity: 0.3;
    border-radius: 6%;
    margin-top: 0;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  .is-selected .onboard-slide img {
    width: 140%;
    margin-left: -20%;
  }
  .onboard-slide div {
    border-bottom-left-radius: 6%;
    border-bottom-right-radius: 6%;
    width: 100vw;
    overflow: hidden;
  }
}

.imgbl {
  position: absolute;
  bottom: 24px;
  left: 16px;
  width: 7%;
}

.imgbr {
  position: absolute;
  bottom: 24px;
  right: 16px;
  width: 10%;
}

/* Medium devices and up (tablets, 768px and up) */
@media (min-width: 768px) {
  p {
    font-size: 20px !important;
  }
  h3 {
    font-size: 50px;
    line-height: 38px;
  }
  .onboard-ctl {
    display: block;
  }
}
@media (min-width: 500px) {
  .img-slide-desk {
    display: none;
  }
}
</style>
