<template>
  <div class="px-0 px-md-3 tw-bg-gray-200">
    <div
      v-if="!isFilter"
      class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3"
    >
      <div
        role="button"
        class="
          tw-bg-white
          w-100
          px-3
          py-3
          tw-border tw-border-solid tw-border-gray-600
          tw-rounded-md
          text-center
          hover:tw-bg-gray-200
        "
        @click="changeKpi(), pushDataLayer(psiData.kpi.name)"
      >
        <p class="mb-0 tw-text-gray-600  tw-text-xxs  fs-md-14">
          {{ this.psiData.kpi.name }}
        </p>
      </div>
      <PsiCardData
        :color="Object.values(this.psiData.kpi.indicator_month_color).pop()"
        :value="Object.values(this.psiData.kpi.indicator_month).pop()"
        :end_value="this.psiData.kpi.end"
      />
      <PsiCardData
        :color="this.psiData.kpi.year_to_date_color"
        :value="this.psiData.kpi.year_to_date"
        :end_value="this.psiData.kpi.end"
      />
      <PsiCardData
        :color="null"
        :value="this.psiData.kpi.last_year"
        :end_value="this.psiData.kpi.end"
        :hide_mobile="true"
      />
      <PsiCardData
        :color="'gray-400'"
        :value="this.psiData.kpi.budget"
        :end_value="this.psiData.kpi.end"
        :hide_mobile="true"
      />
      <PsiCardData
        :color="this.psiData.kpi.le_color"
        :value="this.psiData.kpi.le"
        :end_value="this.psiData.kpi.end"
        :hide_mobile="true"
      />
      <div
        role="button"
        @click="gotoDetail(psiData.kpi)"
        class="
          d-flex
          justify-content-center
          align-items-center
          tw-bg-white
          tw-shadow-sm
          tw-rounded-md
          wi-60
        "
      >
        <i
          class="icon-visibilidad-sin-relleno fs-25 fs-md-30 tw-text-gray-600"
        ></i>
      </div>
    </div>
    <div v-else>
      <div class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3">
        <div
          v-b-toggle="'kpi-row-collapse-' + this.psiData.kpi.id"
          role="button"
          class="
            tw-bg-white
            w-100
            px-3
            py-3
            tw-border tw-border-solid tw-border-gray-600
            tw-rounded-md
            text-center
            hover:tw-bg-gray-200
            d-flex
            justify-content-between
          "
        >
          <p class="mb-0 tw-text-gray-600  tw-text-xxs  fs-md-14 text-center">
            {{ this.psiData.kpi.name }}
          </p>
          <i
            v-if="this.psiData.vp.length > 0 || this.psiData.psah.length > 0"
            class="icon-flecha-abajo tw-text-gray-600 ml-2"
          ></i>
        </div>
        <PsiCardData
          :color="Object.values(this.psiData.kpi.indicator_month_color).pop()"
          :value="Object.values(this.psiData.kpi.indicator_month).pop()"
          :end_value="this.psiData.kpi.end"
        />
        <PsiCardData
          :color="this.psiData.kpi.year_to_date_color"
          :value="this.psiData.kpi.year_to_date"
          :end_value="this.psiData.kpi.end"
        />
        <PsiCardData
          :color="null"
          :value="this.psiData.kpi.last_year"
          :end_value="this.psiData.kpi.end"
          :hide_mobile="true"
        />
        <PsiCardData
          :color="'gray-400'"
          :value="this.psiData.kpi.budget"
          :end_value="this.psiData.kpi.end"
          :hide_mobile="true"
        />
        <PsiCardData
          :color="this.psiData.kpi.le_color"
          :value="this.psiData.kpi.le"
          :end_value="this.psiData.kpi.end"
          :hide_mobile="true"
        />
        <div
          role="button"
          @click="gotoDetail(psiData.kpi)"
          class="
            d-flex
            justify-content-center
            align-items-center
            tw-bg-white
            tw-shadow-sm
            tw-rounded-md
            wi-60
          "
        >
          <i
            class="icon-visibilidad-sin-relleno fs-25 fs-md-30 tw-text-gray-600"
          ></i>
        </div>
      </div>
      <b-collapse :id="'kpi-row-collapse-' + this.psiData.kpi.id">
        <template v-if="this.psiData.vp">
          <div
            v-for="invp in this.psiData.vp"
            :key="'invp-' + invp.id"
            class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3"
          >
            <div
              v-b-toggle="'psah-row-collapse-' + invp.id"
              role="button"
              class="
                w-100
                px-3
                py-3
                text-center
                d-flex
                justify-content-between
                tw-border-b tw-border-solid tw-border-gray-300 
              "
            >
              <p
                class="
                  w-100
                  mb-0
                  tw-text-gray-600
                   tw-text-xxs  fs-md-14
                  font-weight-bold
                  text-center
                "
              >
                {{ invp.name }}
              </p>
              <i
                v-if="invp.psah"
                class="icon-flecha-abajo tw-text-gray-600 ml-2"
              ></i>
            </div>
            <PsiCardData
              :color="Object.values(invp.indicator_month_color).pop()"
              :value="Object.values(invp.indicator_month).pop()"
              :end_value="invp.end"
            />
            <PsiCardData
              :color="invp.year_to_date_color"
              :value="invp.year_to_date"
              :end_value="invp.end"
            />
            <PsiCardData
              :color="null"
              :value="invp.last_year"
              :end_value="invp.end"
              :hide_mobile="true"
            />
            <PsiCardData
              :color="'gray-400'"
              :value="invp.budget"
              :end_value="invp.end"
              :hide_mobile="true"
            />
            <PsiCardData
              :color="invp.le_color"
              :value="invp.le"
              :end_value="invp.end"
              :hide_mobile="true"
            />
            <div
              v-if="!isCompare"
              role="button"
              @click="gotoDetail(invp)"
              class="
                d-flex
                justify-content-center
                align-items-center
                tw-bg-white
                tw-shadow-sm
                tw-rounded-md
                wi-60
              "
            >
              <i
                class="
                  icon-visibilidad-sin-relleno
                  fs-25 fs-md-30
                  tw-text-gray-600
                "
              ></i>
            </div>
            <div
              v-else
              class="
                d-flex
                justify-content-center
                align-items-center
                tw-bg-white
                tw-shadow-sm
                tw-rounded-md
                wi-60
              "
            >
              <div
                @click="selectFilterOption($event, invp)"
                class="
                  wi-20
                  he-20
                  tw-rounded-full
                  tw-border-2 tw-border-solid tw-border-green-400 
                  tw-shadow
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <i class="icon-check-simple tw-text-base text-white"></i>
              </div>
            </div>
            <b-collapse :id="'psah-row-collapse-' + invp.id" v-if="invp.psah">
              <div
                v-for="psah in invp.psah"
                :key="'psah-' + psah.id"
                class="grid-1 d-grid gap-10 columns-7 py-3 px-0"
              >
                <div class="w-100 px-3 py-3 text-center tw-border-b tw-border-solid tw-border-gray-300 ">
                  <p class="mb-0 tw-text-gray-400  tw-text-xxs  fs-md-14">
                    {{ psah.name }}
                  </p>
                </div>
                <PsiCardData
                  :color="Object.values(psah.indicator_month_color).pop()"
                  :value="Object.values(psah.indicator_month).pop()"
                  :end_value="psah.end"
                />
                <PsiCardData
                  :color="psah.year_to_date_color"
                  :value="psah.year_to_date"
                  :end_value="psah.end"
                />
                <PsiCardData
                  :color="null"
                  :value="psah.last_year"
                  :end_value="psah.end"
                  :hide_mobile="true"
                />
                <PsiCardData
                  :color="'gray-400'"
                  :value="psah.budget"
                  :end_value="psah.end"
                  :hide_mobile="true"
                />
                <PsiCardData
                  :color="psah.le_color"
                  :value="psah.le"
                  :end_value="psah.end"
                  :hide_mobile="true"
                />
                <div
                  v-if="!isCompare"
                  @click="gotoDetail(psah)"
                  role="button"
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    tw-bg-white
                    tw-shadow-sm
                    tw-rounded-md
                    wi-60
                  "
                >
                  <i
                    class="
                      icon-visibilidad-sin-relleno
                      fs-25 fs-md-30
                      tw-text-gray-600
                    "
                  ></i>
                </div>
                <div
                  v-else
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    tw-bg-white
                    tw-shadow-sm
                    tw-rounded-md
                    wi-60
                  "
                >
                  <div
                    @click="selectFilterOption($event, psah)"
                    class="
                      wi-20
                      he-20
                      tw-rounded-full
                      tw-border-2 tw-border-solid tw-border-green-400 
                      tw-shadow
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="icon-check-simple tw-text-base text-white"></i>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </template>
        <template v-if="this.psiData.psah">
          <div
            v-for="scope in this.psiData.psah"
            :key="'scope-' + scope.id"
            class="grid-1 d-grid gap-10 columns-7 py-3 px-2 px-md-3"
          >
            <div
              role="button"
              class="w-100 px-3 py-3 text-center tw-border-b tw-border-solid tw-border-gray-300 "
            >
              <p class="mb-0 tw-text-gray-400  tw-text-xxs  fs-md-14 font-weight-bold">
                {{ scope.name }}
              </p>
            </div>
            <PsiCardData
              :color="Object.values(scope.indicator_month_color).pop()"
              :value="Object.values(scope.indicator_month).pop()"
              :end_value="scope.end"
            />
            <PsiCardData
              :color="scope.year_to_date_color"
              :value="scope.year_to_date"
              :end_value="scope.end"
            />
            <PsiCardData
              :color="null"
              :value="scope.last_year"
              :end_value="scope.end"
              :hide_mobile="true"
            />
            <PsiCardData
              :color="'gray-400'"
              :value="scope.budget"
              :end_value="scope.end"
              :hide_mobile="true"
            />
            <PsiCardData
              :color="scope.le_color"
              :value="scope.le"
              :end_value="scope.end"
              :hide_mobile="true"
            />
            <div
              v-if="!isCompare"
              @click="gotoDetail(scope)"
              role="button"
              class="
                d-flex
                justify-content-center
                align-items-center
                tw-bg-white
                tw-shadow-sm
                tw-rounded-md
                wi-60
              "
            >
              <i
                class="
                  icon-visibilidad-sin-relleno
                  fs-25 fs-md-30
                  tw-text-gray-600
                "
              ></i>
            </div>
            <div
              v-else
              class="
                d-flex
                justify-content-center
                align-items-center
                tw-bg-white
                tw-shadow-sm
                tw-rounded-md
                wi-60
              "
            >
              <div
                @click="selectFilterOption($event, scope)"
                class="
                  wi-20
                  he-20
                  tw-rounded-full
                  tw-border-2 tw-border-solid tw-border-green-400 
                  tw-shadow
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <i class="icon-check-simple tw-text-base text-white"></i>
              </div>
            </div>
          </div>
        </template>
      </b-collapse>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import PsiCardData from "@/components/psi/PsiCardData";
  export default {
    name: "PsiRowData",
    props: {
      psiData: {
        required: true,
      },
    },
    components: {
      PsiCardData,
    },
    computed: {
      ...mapState("modPsi", [
        "isFilter",
        "kpi",
        "vp",
        "psah",
        "month",
        "isCompare",
        "dataCompare",
      ]),
    },
    methods: {
      ...mapActions("modPsi", [
        "updateTableTitle",
        "updateDataDeatil",
        "removeDataCompare",
        "addDataCompare",
        "updateViewRowCompareData",
        "updateKpi",
        "updateVp",
        "updateScope",
        "getSummaryPsi",
      ]),
      gotoDetail(data) {
        this.updateTableTitle(data.name);
        this.updateDataDeatil(data);
        this.$router.push({ path: "/indicators/psi/detail" });
      },
      changeKpi() {
        this.updateViewRowCompareData(true);
        this.updateKpi([this.psiData.kpi.id]);
        this.updateVp([]);
        this.updateScope([]);
        this.updateTableTitle(this.psiData.kpi.name);
        this.getSummaryPsi(
          JSON.stringify({
            kpi: this.kpi,
            vp: this.vp,
            psah: this.psah,
            month: this.month,
          })
        );
      },
      selectFilterOption(event, data) {
        if (event.target.classList.contains("tw-bg-green-400")) {
          this.removeDataCompare(data);
          event.target.classList.remove("tw-bg-green-400");
        } else {
          if (this.dataCompare.length < 2) {
            this.addDataCompare(data);
            event.target.classList.add("tw-bg-green-400");
          }
        }
      },
      pushDataLayer(label) {
        window.dataLayer.push({
          event: "TrackEvent-TBO",
          category: "Somos Embajadores",
          SubCategory: "Indicadores PSI",
          Action: "Clics",
          Label: label,
        });
      },
    },
  };
</script>

<style scoped>
  .grid-1 {
    grid-template-columns: 100px 90px 80px 50px;
  }

  @media (min-width: 768px) {
    .grid-1 {
      grid-template-columns: auto auto auto auto auto auto 70px;
    }
  }

  @media (min-width: 992px) {
    .grid-1 {
      grid-template-columns: auto auto auto auto auto auto 70px;
    }
  }

  @media (min-width: 1200px) {
    .grid-1 {
      grid-template-columns: 210px 150px 150px 150px 150px 150px 70px;
    }
  }
</style>