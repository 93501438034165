<template>
  <div>
    <div class="row mt-md-n4">
      <div class="col-md-8"></div>
      <div class="col-md-4 col">
        <div class="container">
          <div class="row justify-content-center justify-content-md-end">
            <div
              v-for="button in psi_buttons"
              :key="button.id"
              class="col px-2"
            >
              <router-link
                :to="button.link"
                class="tw-text-xs fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center"
                @click.native="pushDataLayer(button.name)"
                :class="button.active ? 'tw-bg-gray-200' : ''"
              >
                {{ button.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 d-md-block d-none p-0 p-md">
        <div class="container">
          <div class="row">
            <!-- Title -->
            <div
              class="col-1"
              v-if="show === 2"
              @click="
                filterIndicatorbyId(1, 1);
                changeShow(1);
              "
              role="button"
            >
              <svg
                width="23"
                height="19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-3"
              >
                <path
                  d="M20.823 7.945a1.584 1.584 0 00-.274-.02H4.919l.341-.159a3.17 3.17 0 00.896-.634l4.383-4.383a1.64 1.64 0 00.23-2.1A1.585 1.585 0 008.39.443L.465 8.369a1.585 1.585 0 00-.001 2.241v.002l7.927 7.925a1.585 1.585 0 002.377-.158 1.64 1.64 0 00-.23-2.1l-4.374-4.391a3.167 3.167 0 00-.793-.579l-.476-.214h15.567a1.64 1.64 0 001.672-1.332 1.585 1.585 0 00-1.31-1.818z"
                  fill="#717171"
                />
              </svg>
            </div>
            <div class="col-8 pl-md-3">
              <p
                class="tw-font-robotoBold fs-24"
                :class="show === 2 ? '' : 'text-capitalize'"
              >
                {{ title }}
              </p>
            </div>
            <!-- Toggle Switch -->
            <div
              class="col-4 d-none d-md-flex justify-content-end"
              v-if="show === 1"
            >
              <label class="switch-check">
                <input type="checkbox" id="togBtn" @click="changeFilter" />
                <span class="slider-check round-check">
                  <!--ADDED HTML -->
                  <span
                    class="on w-50 tw-font-robotoBold tw-text-xs tw-top-2 md:tw-top-1"
                    >Por áreas</span
                  >
                  <span
                    class="off w-50 tw-font-robotoBold tw-text-xs tw-top-2 md:tw-top-1"
                    >Mes a Mes</span
                  >
                  <!--END-->
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import psi from "@/mixins/general/psi";

export default {
  name: "FilterSwitch",
  mixins: [psi],
  props: ["show", "title", "path", "categoriesListData", "description"],
  methods: {
    changeShow(number) {
      this.$parent.changeShow(number);
    },
    changeFilter() {
      this.$parent.changeFilter();
    },
    filterIndicatorbyId(id, show) {
      let filteredIndicator = this.categoriesListData.filter((e) => e.id === id);
      this.$parent.changeClassification(
        filteredIndicator[0].classification,
        filteredIndicator[0].id,
        filteredIndicator[0].name,
        filteredIndicator[0].description,
        show
      );
    },
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-TBO",
        category: "Somos Embajadores",
        SubCategory: "Indicadores",
        Action: "Clics",
        Label: label,
      });
    },
  },
  mounted() {
    for (let i = 0; i < this.psi_buttons.length; i++) {
      this.psi_buttons[i].active = this.psi_buttons[i].link === this.path;
    }
  },
};
</script>

<style scoped></style>
