<script setup>
import { getCurrentInstance } from "vue";
import CompButtonIconText from "@/components/buttons/CompButtonIconText.vue";

const { $isMobile } = getCurrentInstance().proxy;
const emit = defineEmits(["set-capacity"]);

const props = defineProps({
  option_1: {
    type: Object,
    required: true,
  },
  option_2: {
    type: Object,
  },
  justCapacity: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Capacidad",
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  optionSelected: {
    type: Object,
    default: null,
  },
  onlyShow: {
    type: Boolean,
    default: false,
  },
});

const setCapacity = (option) => {
  const newOption = props.optionSelected === option ? null : option;
  emit("set-capacity", newOption);
};

//Check empty object
const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
</script>
<template>
  <div class="tw-flex tw-flex-col tw-gap-y-3">
    <div>
      <p class="tw-m-0 tw-font-robotoBold tw-text-base md:tw-text-2xl">
        {{ props.title }}
      </p>
    </div>

    <div class="tw-flex tw-gap-3 lg:tw-gap-5 tw-w-full">
      <comp-button-icon-text
        class="tw-border-gray-300 tw-w-full"
        :class="[
          props.clickable
            ? 'tw-cursor-pointer hover:tw-opacity-100'
            : 'tw-opacity-100',
          props.clickable && props.optionSelected.id === props.option_1.id
            ? 'tw-opacity-100'
            : onlyShow
            ? 'tw-opacity-100'
            : 'tw-opacity-50',
          !props.option_2 ? 'tw-justify-center' : 'tw-justify-start',
          $isMobile() ? 'tw-max-h-16' : '',
        ]"
        icon="assets/img/reservations/icons/capacity1.svg"
        :text="
          props.justCapacity
            ? props.option_1.capacity + ' personas'
            : props.option_1.qty + ' ' + props.option_1.name
        "
        @click.native="props.clickable ? setCapacity(props.option_1) : null"
      />
      <comp-button-icon-text
        v-if="props.option_2"
        class="tw-border-gray-300 tw-w-full tw-justify-start"
        :class="[
          props.clickable
            ? 'tw-cursor-pointer hover:tw-opacity-100'
            : 'tw-opacity-100',
          props.clickable && props.optionSelected.id === props.option_2.id
            ? 'tw-opacity-100'
            : onlyShow
            ? 'tw-opacity-100'
            : 'tw-opacity-50',
          $isMobile() ? 'tw-max-h-16' : '',
        ]"
        icon="assets/img/reservations/icons/capacity1.svg"
        :text="
          props.justCapacity
            ? props.option_2.capacity + ' personas'
            : props.option_2.qty + ' ' + props.option_2.name
        "
        @click.native="props.clickable ? setCapacity(props.option_2) : null"
      />
    </div>
    <span
      v-if="!onlyShow && props.optionSelected && isEmpty(props.optionSelected)"
      class="tw-font-robotoLight tw-text-sm tw-text-red-350"
      >* Por favor selecciona la capacidad que deseas</span
    >
  </div>
</template>
