var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"tw-rounded-xl lg:tw-rounded-2xl tw-border tw-border-gray-350 tw-p-4 tw-flex tw-flex-col tw-gap-2"},[_vm._m(0),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.dependentsDisabledNumber,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', {
            ..._setup.props.data,
            dependents_disabled_number: Number(value),
          })}}),_vm._m(1)],1),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.dependentsSeniorNumber,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', {
            ..._setup.props.data,
            dependents_seniors_number: Number(value),
          })}}),_vm._m(2)],1),_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-2"},[_c(_setup.CompFormTextInput,{staticClass:"tw-col-span-1",attrs:{"model-value":_setup.dependentsJuniorNumber,"input-type":"number"},on:{"update":(value) =>
          _setup.emit('update', {
            ..._setup.props.data,
            dependents_juniors_number: Number(value),
          })}}),_vm._m(3)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xs lg:tw-text-sm"},[_vm._v(" ¿Cuántas personas tienes a tu cargo?* ")]),_c('p',{staticClass:"tw-m-0 tw-font-robotoLight tw-text-xxs lg:tw-text-xs"},[_vm._v(" *En caso de que no aplique, pon 0 en la respuesta ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v(" Personas con discapacidad ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v("Personas mayores")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-col-span-2 tw-flex tw-items-center"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-sm"},[_vm._v("Personas menores de edad")])])
}]

export { render, staticRenderFns }