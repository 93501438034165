<script setup>
import { ref, watch } from "vue";
import config from "@/services/config";
import { useContactsStore } from "@/stores/contactsStore";
import { storeToRefs } from "pinia";

const { actGetFindUsers, cleanContactUsers, actPostAddContact } =
  useContactsStore();
const { contactUsers } = storeToRefs(useContactsStore());
const uriResources = config.beerOfficeURL;
const searchText = ref("");
const imgAvatar = require("@/assets/img/contacts/avatar-empty.png");
const addingContact = ref(false);
const inputSearchError = ref({
  visible: false,
  msj: "",
});

const findUser = () => {
  inputSearchError.value.visible = false;
  if (searchText.value.length >= 3) {
    actGetFindUsers(searchText.value);
  }
  setTimeout(() => {
    if (contactUsers.value.length === 0) {
      inputSearchError.value.visible = true;
      inputSearchError.value.msj = "No se han encontrado usuarios.";
    }
  }, 2000);
};

const addUserToContacts = (contact) => {
  addingContact.value = true;
  setTimeout(() => {
    addingContact.value = false;
  }, 2000);
  actPostAddContact(contact);
  cleanSearchText();
};

const cleanSearchText = () => {
  searchText.value = "";
};

const closeModal = () => {
  cleanSearchText();
};

watch(searchText, () => {
  if (searchText.value.length >= 3) {
    inputSearchError.value.visible = false;
    inputSearchError.value.msj = "";
  } else {
    inputSearchError.value.visible = true;
    inputSearchError.value.msj =
      "Ingresa mínimo tres caracteres para hacer la búsqueda.";
    cleanContactUsers();
  }
});
</script>
<template>
  <b-modal
    id="bv-modal-favorite-contacts"
    size="md"
    hide-footer
    @hide="closeModal"
  >
    <template v-slot:modal-title>
      <span class="badge badge-success px-3 py-2" v-show="addingContact">
        <span class="icon-text icon-check mr-1"></span> Añadido a tus contactos
      </span>
    </template>
    <div class="d-block text-center">
      <div class="cont-search d-flex">
        <input
          type="text"
          class="form-control"
          placeholder="Buscar..."
          v-model="searchText"
        />
        <button class="btn btn-primary ml-3" @click="findUser()">Buscar</button>
      </div>
      <div class="w-100 text-left" v-show="inputSearchError.visible">
        <small class="txt-red">{{ inputSearchError.msj }}</small>
      </div>
      <div v-if="contactUsers.length <= 0" class="mt-3">
        <img src="@/assets/img/icons/search.png" alt="" class="py-4" />
      </div>
      <div v-else class="cont-users mt-4 p-2 p-md-3">
        <div
          class="cont-user"
          v-for="contact in contactUsers"
          :key="contact.id"
        >
          <div
            v-if="contact.favorite_core_employee_id"
            class="mb-3 d-flex justify-content-between align-items-center"
          >
            <div class="user d-flex align-items-center">
              <img
                :src="
                  contact.core_employee.prof_picture == null
                    ? imgAvatar
                    : uriResources + '/' + contact.core_employee.prof_picture
                "
                :alt="contact.core_employee.name"
                class="rounded-circle"
              />
              <p class="name ml-3 mb-0">{{ contact.core_employee.name }}</p>
            </div>
            <div class="actions ml-2">
              <div class="cont-opc d-flex align-items-center">
                <span class="icon-text icon-check mr-2"></span>
                <span>Agregado</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mb-3 d-flex justify-content-between align-items-center"
          >
            <div class="user d-flex align-items-center">
              <img
                :src="
                  contact.prof_picture == null
                    ? imgAvatar
                    : uriResources + '/' + contact.prof_picture
                "
                :alt="contact.name"
                class="rounded-circle"
              />
              <p class="name ml-3 mb-0">{{ contact.name }}</p>
            </div>
            <div class="actions ml-2">
              <div
                class="cont-opc d-flex align-items-center"
                @click="addUserToContacts(contact)"
              >
                <span class="icon-text icon-anadir mr-2"></span>
                <span class="textPlane">Agregar</span>
              </div>
            </div>
          </div>
        </div>
        <!-- .cont-user -->
      </div>
      <!-- .cont-users -->
    </div>
  </b-modal>
</template>

<style lang="css">
#bv-modal-favorite-contacts .txt-red {
  color: #d82b2b;
}

#bv-modal-favorite-contacts header {
  border-bottom: 0 none;
  padding-bottom: 0;
}

#bv-modal-favorite-contacts header button {
  opacity: 1;
}

#bv-modal-favorite-contacts .close {
  outline: none;
}

#bv-modal-favorite-contacts input[type="text"] {
  border-radius: 15px;
}

#bv-modal-favorite-contacts .btn-primary {
  border-radius: 8px;
}

#bv-modal-favorite-contacts .cont-users {
  background-color: #f2f2f2;
  border-radius: 8px;
  height: 340px;
  overflow-y: scroll;
}

#bv-modal-favorite-contacts .cont-users .rounded-circle {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

#bv-modal-favorite-contacts .cont-users .name {
  font-family: "RobotoMedium", sans-serif;
  color: #222;
}

#bv-modal-favorite-contacts .cont-users .cont-opc {
  font-size: 11px;
  width: 80px;
  cursor: pointer;
}

#bv-modal-favorite-contacts .cont-users .icon-text {
  color: #d82b2b;
  font-size: 16px;
}

#bv-modal-favorite-contacts .badge-success {
  background-color: #00bf79;
  font-size: 12px;
  font-weight: 500;
}

@media (min-width: 768px) {
  #bv-modal-favorite-contacts input[type="text"] {
    border-radius: 20px;
  }

  #bv-modal-favorite-contacts .cont-users .rounded-circle {
    width: 50px;
    height: 50px;
  }

  #bv-modal-favorite-contacts .cont-users .icon-text {
    font-size: 20px;
  }
}
</style>
