<template>
  <div class="cont-ranking">
    <div class="cont-title">
      <h4 class="text-center tw-font-robotoBold tw-text-xl md:tw-text-2xl">
        {{ title }}
      </h4>
    </div>
    <div class="d-flex justify-content-center cont-buttons">
      <b-form-group>
        <b-form-radio-group
          class="cont-radio-mes ml-5 my-3"
          id=""
          v-model="switchOptions.selected"
          :options="switchOptions.options"
          buttons
          name="radios-btn-default"
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <div
      v-if="switchOptions.selected === 'option1'"
      class="table-responsive cont-table"
    >
      <b-table
        class="table"
        hover
        sticky-header
        :items="tableData.positions"
        :tbody-tr-class="rowClass"
        :fields="
          tableData.level === 'Unidad Negocio' ? unitFields : generalFields
        "
      ></b-table>
    </div>
    <div v-else class="graphs-bg">
      <div class="cont-graphs py-4 px-3 mb-3">
        <div class="row mb-3">
          <b-carousel
            id="carousel-no-animation"
            no-animation
            controls
            indicators
            background="#ffffff"
            img-width="540"
            img-height="340"
          >
            <slot
              v-for="(item, index) in getUserResult.variables"
              @key="item.id"
            >
              <b-carousel-slide v-if="title === 'Mes'" img-blank>
                <slot>
                  <div class="cont-bar-graph position-relative mt-auto mx-auto">
                    <div
                      class="bar position-absolute d-flex justify-content-center align-items-center"
                      :class="index % 2 === 0 ? 'bg-red' : 'bg-yellow'"
                      :style="{
                        height:
                          (item.fulfilment * 100 > 100
                            ? '100'
                            : item.fulfilment * 100) + '%',
                      }"
                    >
                      <div class="cont-value position-absolute d-flex">
                        <div class="arrow-left"></div>
                        <span class="ml-1">{{
                          Number(
                            parseFloat(item.result).toFixed(0)
                          ).toLocaleString("en")
                        }}</span>
                      </div>
                      <span class="percent"
                        >{{ (item.fulfilment * 100).toFixed() }}%</span
                      >
                    </div>
                  </div>
                  <hr class="mt-0 pt-0" />
                  <p class="desc text-center">{{ item.variable_name }}</p>
                </slot>
              </b-carousel-slide>
              <slot v-else>
                <b-carousel-slide
                  v-if="item.variable_group.id !== 25"
                  img-blank
                >
                  <slot>
                    <div
                      class="cont-bar-graph position-relative mt-auto mx-auto"
                    >
                      <div
                        class="bar position-absolute d-flex justify-content-center align-items-center"
                        :class="index % 2 === 0 ? 'bg-red' : 'bg-yellow'"
                        :style="{
                          height:
                            (item.fulfilment * 100 > 100
                              ? '100'
                              : item.fulfilment * 100) + '%',
                        }"
                      >
                        <div class="cont-value position-absolute d-flex">
                          <div class="arrow-left"></div>
                          <span class="ml-1">{{
                            Number(
                              parseFloat(item.result).toFixed(0)
                            ).toLocaleString("en")
                          }}</span>
                        </div>
                        <span class="percent"
                          >{{ (item.fulfilment * 100).toFixed() }}%</span
                        >
                      </div>
                    </div>
                    <hr class="mt-0 pt-0" />
                    <p class="desc text-center">{{ item.variable_name }}</p>
                  </slot>
                </b-carousel-slide>
                <slot v-else>
                  <b-carousel-slide v-if="title === 'Trimestre'" img-blank>
                    {{ item.variable_name }}
                    <div class="table-responsive table-layer">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <th class="layer-col">Suma de posiciones</th>
                            <td>{{ getUserResult.sum_positions }}</td>
                          </tr>
                          <tr>
                            <th class="layer-col">Ranking KPI</th>
                            <td>{{ item.initial_ranking }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-carousel-slide>
                </slot>
              </slot>
            </slot>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompTableSwitch",
  props: {
    tableNames: {
      type: Array,
    },
    tableData: {},
    title: {
      type: String,
      required: true,
    },
    switchOptions: {},
    userId: Number,
    userPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getUserResult() {
      if (this.tableData) {
        return this.tableData.positions.find(
          (employee) => employee.employee_id === this.userId
        );
      }
      return [];
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      generalFields: [
        "position",
        { key: "regional.name", label: "Regional" },
        { key: "manager.name", label: "Gerencia" },
      ],
      unitFields: [
        "position",
        { key: "regional.name", label: "Regional" },
        { key: "unit.name", label: "Unidad de negocio" },
      ],
    };
  },
  methods: {
    rowClass(item) {
      if (item.employee_id !== this.userId) return;
      if (item.employee_id === this.userId) return "selected-row ";
    },
  },
};
</script>

<style scoped>
.carousel-caption {
  color: #000 !important;
}

.bg-red {
  background-color: #d82b2b;
}

.bg-yellow {
  background-color: #fab23a;
}

.cont-ranking .cont-title h4 {
  color: #343434;
  font-size: 24px;
  font-family: "RobotoBold", sans-serif;
}

.cont-ranking .btn-1 {
  border-color: #bababa;
  border-radius: 15px;
  width: 100%;
  color: #bababa;
  height: 46px;
  margin-bottom: 32px;
}

.graphs-bg {
}

.layer-col {
  background: #eeeeee 0 0 no-repeat padding-box;
  opacity: 1;
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0;
  color: #343434;
}

.table-layer {
  border-radius: 8px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #eeeeee !important;
  margin: 0 auto;
  float: none;
}

@media (min-width: 768px) {
  .cont-ranking .btn-1 {
    border-radius: 25px;
  }
}

@media (max-width: 768px) {
  .graphs-bg {
    background: #bababa29 0 0 no-repeat padding-box;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

.cont-graphs {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 12px;
}

.cont-graphs .graph {
  flex: 0.5;
}

.cont-graphs .cont-bar-graph {
  height: 120px;
  width: 48px;
  background: #eeeeee;
  border-radius: 15px;
}

.cont-graphs .cont-bar-graph .bar {
  width: 48px;
  border-radius: 15px 15px 0 0;
  bottom: 0;
}

.cont-graphs .cont-bar-graph .bar .percent {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-family: "RobotoBold", sans-serif;
}

.cont-graphs .cont-bar-graph .bar .cont-value {
  top: 0;
  right: -44px;
  font-size: 10px;
}

.cont-graphs .cont-bar-graph .bar .cont-value .arrow-left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #d82b2b;
}
</style>

<style lang="css">
#showMeRanking .cont-radio-mes .btn {
  border-color: #bababa;
  border-radius: 25px;
  padding: 10px 50px 10px 50px;
  background: #ffffff;
  color: #bababa;
  margin-left: -40px;
}

@media (min-width: 768px) {
  #showMeRanking .cont-radio-mes .btn {
    border-radius: 25px;
    margin-left: -35px;
  }
}

#showMeRanking .cont-radio-mes .active {
  background: #d82b2b;
  border-color: #d82b2b;
  border-radius: 25px;
  color: #ffffff;
  padding: 10px 30px;
  z-index: 10;
}
</style>
