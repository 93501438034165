var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('main',[_c(_setup.MobileBar,{attrs:{"title":"Beneficios A La Carta"}}),_c(_setup.TitleBar,{attrs:{"title":"Beneficios A La Carta"}}),_c('section',{staticClass:"tw-container-general"},[_c(_setup.CompGeneralTopCard,{attrs:{"description":"Descubre Beneficios a la Carta donde encuentras beneficios externos exclusivos para ti. ¡Mejora tu calidad de vida con ofertas especiales de empresas colaboradoras! ¡Disfruta de más opciones y cuida de ti!","image":"assets/img/cb_benefits.png","image-right-desk":"","title":"¡Disfruta más!"}})],1),_c('section',{staticClass:"tw-container-general tw-flex tw-flex-col tw-gap-5"},[_c('div',{staticClass:"tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-w-full tw-p-6 lg:tw-p-8 tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-gap-3 lg:tw-gap-6"},[_vm._m(0),_c('div',{staticClass:"lg:tw-col-span-3 tw-order-4 lg:tw-order-2 tw-flex lg:tw-justify-end"},[_c('button',{staticClass:"tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full lg:tw-w-max",on:{"click":function($event){return _setup.goToLink(
                'https://api.embajadores.com.co/storage/documents/1632327380.pdf',
                'Go Integro',
                $event
              )}}},[_vm._v(" Conoce como registrarte en Go Flex ")])]),_vm._m(1),_c('div',{staticClass:"lg:tw-col-span-6 tw-order-3 lg:tw-order-4 tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-3 lg:tw-gap-10"},[_c('div',{staticClass:"tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-p-5 tw-flex tw-flex-col tw-justify-between tw-gap-6"},[_vm._m(2),_c('button',{staticClass:"tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full",on:{"click":function($event){return _setup.goToLink(
                  'https://embajadoresbo.gointegro.com/gobenefits/',
                  'Go Benefits',
                  $event
                )}}},[_vm._v(" ¡Descúbrelo! ")])]),_c('div',{staticClass:"tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-p-5 tw-flex tw-flex-col tw-justify-between tw-gap-6"},[_vm._m(3),_c('button',{staticClass:"tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full",on:{"click":function($event){return _setup.goToLink(
                  'https://embajadoresbo.gointegro.com/gosocial/recognitions/stream',
                  'Go Recognition',
                  $event
                )}}},[_vm._v(" ¡Descúbrelo! ")])]),_c('div',{staticClass:"tw-w-full tw-rounded-2xl tw-border lg:tw-border-2 tw-border-turquoise-200 lg:tw-border-turquoise-200 tw-p-5 tw-flex tw-flex-col tw-justify-between tw-gap-6"},[_vm._m(4),_c('button',{staticClass:"tw-btn-general-shape tw-bg-turquoise-200 tw-border-none tw-text-white tw-w-full",on:{"click":function($event){return _setup.goToLink('/goflex', 'Go Flex', $event)}}},[_vm._v(" ¡Descúbrelo! ")])])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"lg:tw-col-span-3 tw-order-1 lg:tw-order-1 tw-flex tw-justify-center lg:tw-justify-start"},[_c('img',{staticClass:"tw-object-contain",attrs:{"alt":"cursos","src":require("@/assets/img/gointegro_logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"lg:tw-col-span-6 tw-order-2 lg:tw-order-3"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v(" Es una plataforma integral de beneficios y lealtad para empleados, donde se centralizan los beneficios, descuentos y programas de bienestar; ademas facilita el acceso, la comunicación y el seguimiento, mejorando así la experiencia del colaborador y simplificado la gestión de beneficios. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-gap-6"},[_c('div',{staticClass:"tw-bg-turquoise-200 tw-rounded-2xl tw-p-6"},[_c('div',{staticClass:"tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10"},[_c('img',{staticClass:"tw-object-cover tw-h-full tw-w-full tw-rounded-2xl",attrs:{"alt":"cursos","src":require("@/assets/img/cb_gobenefits.png")}})])]),_c('div',{staticClass:"tw-flex tw-gap-2 tw-justify-center tw-items-center"},[_c('i',{staticClass:"icon-tag tw-text-turquoise-200 tw-text-xl lg:tw-text-2xl"}),_c('p',{staticClass:"tw-text-center tw-m-0 tw-font-stark tw-text-base lg:tw-text-xl tw-text-gray-550"},[_vm._v(" GO benefits ")])]),_c('span',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v("Ofrece increíbles descuentos exclusivos en diferentes tiendas, plataformas y restaurantes")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-gap-6"},[_c('div',{staticClass:"tw-bg-turquoise-200 tw-rounded-2xl tw-p-6"},[_c('div',{staticClass:"tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10"},[_c('img',{staticClass:"tw-object-cover tw-h-full tw-w-full tw-rounded-2xl",attrs:{"alt":"cursos","src":require("@/assets/img/cb_gorecognition.png")}})])]),_c('div',{staticClass:"tw-flex tw-gap-2 tw-justify-center tw-items-center"},[_c('i',{staticClass:"icon-rating tw-text-turquoise-200 tw-text-xl lg:tw-text-2xl"}),_c('p',{staticClass:"tw-text-center tw-m-0 tw-font-stark tw-text-base lg:tw-text-xl tw-text-gray-550"},[_vm._v(" GO recognition ")])]),_c('span',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v("Reconoce a tu equipo por su excelente desempeño y acciones destacadas dentro de la compañía. esto asignara puntos que podrán ser redimidos en Go Benefits.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-gap-6"},[_c('div',{staticClass:"tw-bg-turquoise-200 tw-rounded-2xl tw-p-6"},[_c('div',{staticClass:"tw-aspect-w-16 tw-aspect-h-12 -tw-mb-10"},[_c('img',{staticClass:"tw-object-cover tw-h-full tw-w-full tw-rounded-2xl",attrs:{"alt":"cursos","src":require("@/assets/img/cb_goflex.png")}})])]),_c('div',{staticClass:"tw-flex tw-gap-2 tw-justify-center tw-items-center"},[_c('i',{staticClass:"icon-rating-full tw-text-turquoise-200 tw-text-xl lg:tw-text-2xl"}),_c('p',{staticClass:"tw-text-center tw-m-0 tw-font-stark tw-text-base lg:tw-text-xl tw-text-gray-550"},[_vm._v(" GO flex ")])]),_c('span',{staticClass:"tw-font-roboto tw-text-xs lg:tw-text-base"},[_vm._v("Encuentra el equilibrio perfecto entre tu vida laboral y personal, maximizando tu productividad y bienestar, solicitado días libres. "),_c('strong',[_vm._v("¡Disponible en Embajadores!")])])])
}]

export { render, staticRenderFns }