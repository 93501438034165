import { defineStore } from "pinia";
import { ref } from "vue";

import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  // General
  gamificationHelpUri: "/gamiHelps",
  gamificationAvatarElementsUri: "/gamiAvatarElements",
  gamificationMissionsUri: "/gamiMissions",
  gamificationRewardsUri: "/gamiRewards",
  gamificationRewardsHistoryUri: "/gamiRewards/history",
  gamificationFirstEntryUri: "/coreEmployees/firstEntryGamification",
  gamificationUserRanksUri: "/gamiRanks",
  gamificationPredefinedAvatarsUri: "/gamiPredefinedAvatars",
  // Form
  gamificationFormDepartmentsUri: "/departments",
  gamificationFormCitiesUri: "/cities",
  gamificationFormContactInfoUri: "/gamiRewards/contactInfo",
  //Malts - Tapit
  gamificationTapitMaltsExchangeUri: "/tapitIntegration/exchange",
  gamificationTapitTransactionsUri: "/tapitIntegration/transactions",
  gamificationTapitVerifyIdNumberUri: "/tapitIntegration/verifyIdNumber",
};

export const useGamificationStore = defineStore("gamification", () => {
  const userCanAccessGamification = ref(false);
  // const modalHowItWork = ref(true);
  const howItWorkSlides = ref([]);
  const avatarElements = ref([]);
  const predefinedAvatars = ref([]);
  const missions = ref([]);
  const rewards = ref([]);
  const reward = ref({});
  const rewardsStock = ref([]);
  const redeem = ref(null);
  const history = ref([]);
  const userRanks = ref([]);
  const departments = ref({});
  const cities = ref({});
  const contactInfo = ref([]);
  const success = ref(null);
  const tapitTransactions = ref([]);
  const tapitVerifiedState = ref(null);

  async function actChangeGamificationUserState() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationFirstEntryUri,
      true,
      {}
    );
    if (response.error) return response.error;
    userCanAccessGamification.value = true;
    return true;
  }

  async function actGetGamificationHelp() {
    let response = await AxiosBeerOffice.get(uri.gamificationHelpUri, true, {});
    if (response.error) return response.error;
    howItWorkSlides.value = response.data;
    return true;
  }

  async function actGetGamificationAvatarElements() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationAvatarElementsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    avatarElements.value = response.data;
    return true;
  }

  async function actGetGamificationPredefinedAvatars() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationPredefinedAvatarsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    predefinedAvatars.value = response.data;
    return true;
  }

  async function actGetGamificationMissions() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationMissionsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    missions.value = response.data;
    return true;
  }

  async function actGetGamificationRewards() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationRewardsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    rewards.value = response.data;
    return true;
  }

  async function actGetGamificationReward(id) {
    let response = await AxiosBeerOffice.get(
      `${uri.gamificationRewardsUri}/${id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    reward.value = response.data;
    return true;
  }

  async function actGetGamificationRewardsStock(data) {
    let selectedUri;
    if (Object.keys(data).length > 1) {
      selectedUri = `${uri.gamificationRewardsUri}/${data.id}/validateStock?combination=[${data.color},${data.talla}]`;
    } else {
      selectedUri = `${uri.gamificationRewardsUri}/${data.id}`;
    }

    let response = await AxiosBeerOffice.get(selectedUri, true, {});
    if (response.error) return response.error;
    rewardsStock.value = response.data;
    return true;
  }

  async function actPostRedeemGamificationRewards(data) {
    let response = await AxiosBeerOffice.post(
      uri.gamificationRewardsUri,
      true,
      data
    );
    if (response.error) return response.error;
    redeem.value = response;
    return true;
  }

  async function actGetGamificationRewardsHistory() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationRewardsHistoryUri,
      true,
      {}
    );
    if (response.error) return response.error;
    history.value = response.data;
    return true;
  }

  async function actGetGamificationUserRanks(id) {
    let response = await AxiosBeerOffice.get(
      `${uri.gamificationUserRanksUri}?profile_id=${id}`,
      true,
      id
    );
    if (response.error) return response.error;
    userRanks.value = response.data;
    return true;
  }

  async function actGetGamificationFormDepartments() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationFormDepartmentsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    departments.value = response.data;
    return true;
  }

  async function actGetGamificationFormCities() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationFormCitiesUri,
      true,
      {}
    );
    if (response.error) return response.error;
    cities.value = response.data;
    return true;
  }

  async function actGetGamificationFormContactInfo() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationFormContactInfoUri,
      true,
      {}
    );
    if (response.error) return response.error;
    contactInfo.value = response;
    return true;
  }

  async function actPostMaltsTapitExchange(malts) {
    let response = await AxiosBeerOffice.post(
      `${uri.gamificationTapitMaltsExchangeUri}?malts=${malts}`,
      true,
      {}
    );
    if (response.error) return response.error;
    success.value = response.status;
    return true;
  }

  async function actGetTapitTransactions() {
    let response = await AxiosBeerOffice.get(
      uri.gamificationTapitTransactionsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    tapitTransactions.value = response.data;
    return true;
  }

  async function actGetTapitVerifyIdNumber(accessToken) {
    let response = await AxiosBeerOffice.get(
      `${uri.gamificationTapitVerifyIdNumberUri}?accessToken=${accessToken}`,
      true,
      {}
    );
    if (response.error) return response.error;
    tapitVerifiedState.value = response.data;
    return true;
  }

  return {
    userCanAccessGamification,
    howItWorkSlides,
    avatarElements,
    predefinedAvatars,
    missions,
    rewards,
    reward,
    rewardsStock,
    redeem,
    history,
    userRanks,
    departments,
    cities,
    contactInfo,
    success,
    tapitTransactions,
    tapitVerifiedState,
    actChangeGamificationUserState,
    actGetGamificationHelp,
    actGetGamificationAvatarElements,
    actGetGamificationPredefinedAvatars,
    actGetGamificationMissions,
    actGetGamificationRewards,
    actGetGamificationReward,
    actGetGamificationRewardsStock,
    actPostRedeemGamificationRewards,
    actGetGamificationRewardsHistory,
    actGetGamificationUserRanks,
    actGetGamificationFormDepartments,
    actGetGamificationFormCities,
    actGetGamificationFormContactInfo,
    actPostMaltsTapitExchange,
    actGetTapitTransactions,
    actGetTapitVerifyIdNumber,
  };
});
