<script setup>
import { computed } from "vue";
import AuthLayout from "@/components/auth/AuthLayout.vue";
import AuthLogin from "@/components/auth/AuthLogin.vue";
import AuthLoginSso from "@/components/auth/AuthLoginSso.vue";

const loginSSO = computed(() => {
  return process.env.VUE_APP_LOGIN_SSO === "true";
});
</script>

<template>
  <auth-layout>
    <auth-login-sso v-if="loginSSO"></auth-login-sso>
    <auth-login v-else></auth-login>
  </auth-layout>
</template>
