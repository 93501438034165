<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  guestsNumber: {
    type: Number,
    required: true,
  },
  overCrowded: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["incrementGuests", "decrementGuests"]);

const incrementGuests = () => emit("incrementGuests");
const decrementGuests = () => emit("decrementGuests");
</script>
<template>
  <div :id="props.item.idName" class="tw-grid tw-grid-cols-2 tw-gap-3">
    <div>
      <p class="tw-font-robotoBold tw-text-xs md:tw-text-lg tw-m-0">
        {{ props.item.label }}
      </p>
      <p class="tw-font-roboto tw-text-xs md:tw-text-lg tw-m-0">
        {{ props.item.description }}
      </p>
    </div>
    <div>
      <div
        class="tw-w-full tw-h-full tw-border tw-border-red-350 tw-rounded-full tw-grid tw-grid-cols-col3_fit_first_and_last tw-overflow-hidden"
      >
        <div
          class="tw-flex tw-justify-center tw-items-center tw-bg-red-350 tw-cursor-pointer tw-px-2 md:tw-px-4"
          @click="decrementGuests"
        >
          <i
            class="icon-remover-signo tw-text-2xl md:tw-text-3xl tw-text-white"
          ></i>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
          <p
            class="tw-font-roboto tw-font-semibold tw-text-sm md:tw-text-xl tw-m-0"
            :class="props.overCrowded ? 'tw-text-red-500' : 'tw-text-black'"
          >
            {{ props.guestsNumber }}
          </p>
        </div>
        <div
          class="tw-flex tw-justify-center tw-items-center tw-bg-red-350 tw-cursor-pointer tw-px-2 md:tw-px-4"
          @click="incrementGuests"
        >
          <i
            class="icon-signo-mas tw-text-2xl md:tw-text-3xl tw-text-white"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
