import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    banners: [],
    slides: [],
    shortcutSections: [],
    shortcutPillars: [],
    comments: [],
    employeesForShare: [],
    reactions: [],
    showReactions: false,
    suggestionsCategories: [],
    questions: [],
    contacts: [],
  },
  getters: {
    getBannerById: (state) => (id) => {
      return state.banners.find((banner) => banner.id === id);
    },
  },
  mutations: {
    setBanners: (state, banners) => {
      state.banners = banners;
    },
    showStateReactions: (state) => {
      state.showReactions = true;
    },
    hideStateReactions: (state) => {
      state.showReactions = false;
    },
    setShortcutSections: (state, sections) => {
      state.shortcutSections = sections;
    },
    setShortcutPillars: (state, pillars) => {
      state.shortcutPillars = pillars;
    },
    setComments: (state, comments) => {
      state.comments = comments;
    },
    setEmployeesForShare: (state, employees) => {
      state.employeesForShare = employees;
    },
    setSuggestionsCategories: (state, categories) => {
      state.suggestionsCategories = categories;
    },
    setQuestions: (state, setQuestions) => {
      state.questions = setQuestions;
    },
    setContacts: (state, setContacts) => {
      state.contacts = setContacts;
    },
  },
  actions: {
    actGetBanners: async ({ commit }) => {
      let response = await ApiBeerOffice.getBanners();
      if (response.error) {
        return response.error;
      }
      commit("setBanners", response.data.banners);
      return true;
    },

    actGetBannerById: async (_, id) => {
      let response = await ApiBeerOffice.getBannerById(id);
      if (response.error) {
        return response.error;
      }
      return response;
    },

    actPostPqr: async (_, data) => {
      let response = await ApiBeerOffice.postPqr(data);
      if (response.error) {
        return response.error;
      }
      return true;
    },
    actGetShortcutSections: async ({ commit }) => {
      let response = await ApiBeerOffice.getShortcutSections();
      if (response.error) {
        return response.error;
      }
      commit("setShortcutSections", response.data);
      return true;
    },
    actGetShortcutPillars: async ({ commit }) => {
      let response = await ApiBeerOffice.getShortcutPillars();
      if (response.error) {
        return response.error;
      }
      commit("setShortcutPillars", response.data);
      return true;
    },
    actGetComments: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getComments(data);
      if (response.error) {
        return response.error;
      }

      commit("setComments", response.data.data);
      return true;
    },
    actPostComments: async (_, data) => {
      let response = await ApiBeerOffice.postComments(data);
      if (response.error) {
        return response.error;
      }
      return true;
    },
    actDeleteComments: async (_, id) => {
      let response = await ApiBeerOffice.deleteComments(id);
      if (response.error) {
        return response.error;
      }
      return true;
    },
    actGetEmployeesForShare: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getEmployeesForShare(data);
      if (response.error) {
        return response.error;
      }
      commit("setEmployeesForShare", response.data);
      return true;
    },
    actPostShareContent: async (_, data) => {
      let response = await ApiBeerOffice.shareContent(data);
      if (response.error) {
        return response.error;
      }
      return true;
    },
    actGetSuggestionsCategories: async ({ commit }) => {
      let response = await ApiBeerOffice.getSuggestionsCategories();
      if (response.error) {
        return response.error;
      }
      commit("setSuggestionsCategories", response.data.categories);
      return response.data.categories;
    },
    actPostSuggestions: async (_, data) => {
      let response = await ApiBeerOffice.postSuggestions(data);
      if (response.error) {
        return response.error;
      }
      return response;
    },
    actGetQuestions: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getQuestions(data);
      if (response.error) {
        return response.error;
      }
      commit("setQuestions", response.data);
      return true;
    },
    actGetContacts: async ({ commit }, data) => {
      let response = await ApiBeerOffice.getContactsNew(data);
      if (response.error) {
        return response.error;
      }
      commit("setContacts", response.data);
      return true;
    },
  },
};
