<script setup>
import { computed, onBeforeMount, ref, getCurrentInstance } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router/composables";
import { useReservationData } from "@/composables/reservations/useReservationData";
import { useReservationsStore } from "@/stores/reservationsStore";
const { $isMobile } = getCurrentInstance().proxy;

import MobileBar from "@/components/general/MobileBar";
import TitleBar from "@/components/general/TitleBar";
import GeneralModal from "@/components/modals/GeneralModal";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompBookStep3 from "@/components/reservations/bookingSteps/CompBookStep3.vue";
import CompBookStep4 from "@/components/reservations/bookingSteps/CompBookStep4.vue";
import CompBookStep5 from "@/components/reservations/bookingSteps/CompBookStep5.vue";
import CompBookSpaceInfo from "@/components/reservations/bookingSteps/CompBookSpaceInfo.vue";

const reservationsStore = useReservationsStore();
const route = useRoute();
const router = useRouter();
const isLoading = ref(false);
const isErrorModalOpen = ref(false);
const isNoAvailableModal = ref(false);
const loadingPostBooking = ref(false);

const {
  bookingStep,
  bookingDatesSelected,
  bookingPlaceSelected,
  bookingIsHighSeason,
} = storeToRefs(reservationsStore);

const {
  reservationData,
  overCrowded,
  guestsNumbers,
  checkEveryGuest,
  decrementAdultGuests,
  incrementAdultGuests,
  decrementChildrenGuests,
  incrementChildrenGuests,
  deleteCharacteristics,
  updateGuest,
} = useReservationData();

const { actPostBooking, actGetBookingPlaceDetail } = reservationsStore;

const validateSteps = computed(() => {
  if (bookingStep.value === 3 && !overCrowded.value) {
    return true;
  } else if (bookingStep.value === 4 && checkEveryGuest.value) {
    return true;
  } else return bookingStep.value === 5;
});

const cancel = () => {
  if (bookingStep.value === 3) {
    bookingPlaceSelected.value = null;
    bookingDatesSelected.value = null;
    bookingIsHighSeason.value = false;
    router.push(`/reservations`);
  } else {
    deleteCharacteristics();
    bookingStep.value -= 1;
  }
};

const nextStep = async () => {
  if (bookingStep.value < 4 && validateSteps.value) {
    bookingStep.value++;
  } else if (bookingStep.value === 4 && $isMobile() && validateSteps.value) {
    bookingStep.value++;
  } else if (validateSteps.value) {
    loadingPostBooking.value = true;
    await actPostBooking({
      booking_place_id: bookingPlaceSelected.value.booking_place_id,
      booking_place_option_id: bookingPlaceSelected.value.id,
      start_date: bookingDatesSelected.value.start_date,
      end_date: bookingDatesSelected.value.end_date,
      guests: reservationData.guests,
    })
      .then((response) => {
        if (response.message === "Booking created") {
          bookingIsHighSeason.value
            ? router.push({
                name: "CongratulationsLot",
                params: {
                  id: route.params.id,
                },
              })
            : router.push({
                name: "BookPending",
                params: {
                  id: route.params.id,
                  bookingId: response.data.id,
                },
              });
        } else {
          openErrorModal();
        }
      })
      .finally(() => {
        loadingPostBooking.value = false;
      });
    bookingStep.value = 3;
  } else {
    console.log("Step is not valid");
  }
};

const hideNoAvailableModal = () => {
  isNoAvailableModal.value = false;
};

const openErrorModal = () => {
  isErrorModalOpen.value = true;
};

const closeErrorModal = () => {
  isErrorModalOpen.value = false;
};

const changeStep = (step) => {
  bookingStep.value = step;
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetBookingPlaceDetail(route.params.id);
  isLoading.value = false;
});
</script>
<template>
  <div v-if="!isLoading" class="tw-relative">
    <general-modal
      v-if="isErrorModalOpen"
      :isShowModal="isErrorModalOpen"
      :maxHeight="{ base: 400, md: 400, lg: 400, xl: 400 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
      modal-name="uploadActivityFile"
      @close="closeErrorModal"
    >
      <div
        class="tw-py-5 tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2"
      >
        <img alt="" src="@/assets/img/modal/modal-error.svg" />
        <p
          class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl tw-text-red-350"
        >
          Lo sentimos
        </p>
        <p
          class="tw-mb-0 tw-mt-4 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
        >
          ¡Algo ha salido mal! Estas fechas no se encuentran disponibles para un
          solo apartamento, te invitamos a intentar reduciendo las fechas.
        </p>
      </div>
    </general-modal>
    <general-modal
      v-if="isNoAvailableModal"
      :hide-button-support="true"
      :isShowModal="isNoAvailableModal"
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 400, xl: 400 }"
      modalName="noAvailable"
      @close="hideNoAvailableModal"
    >
      <div class="tw-flex tw-flex-col tw-justify-between">
        <p
          class="tw-font-robotoBold tw-text-xl lg:tw-text-2xl tw-text-red-350 tw-mb-2"
        >
          ¡Lo sentimos!
        </p>

        <p class="tw-mt-2 tw-font-roboto tw-text-xs lg:tw-text-sm">
          No puedes seleccionar este día porque no tiene una noche disponible,
          selecciona una fecha diferente.
        </p>

        <div class="tw-w-full tw-flex tw-justify-end">
          <img
            alt=""
            class="tw-max-h-6"
            src="@/assets/img/icons/icon-corner.svg"
          />
        </div>
      </div>
    </general-modal>
    <mobile-bar title="Reserva" />
    <title-bar title="Reserva" />
    <div
      v-if="bookingIsHighSeason"
      id="seasonDisclaimer"
      class="md:tw-my-5 tw-bg-pink-100"
    >
      <div class="tw-container-general">
        <div class="tw-flex tw-items-center tw-gap-x-5 tw-py-3 md:tw-py-5">
          <i class="icon-lot tw-text-gray-500 tw-text-4xl md:tw-text-6xl"></i>
          <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
            Recuerda que las fechas de temporada alta entran en un sorteo y te
            avisaremos si eres uno de los ganadores
          </p>
        </div>
      </div>
    </div>

    <div class="tw-container-general tw-mt-5">
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-20">
        <div
          id="characteristics"
          class="tw-my-3 md:tw-my-4 tw-flex tw-flex-col tw-gap-5"
        >
          <!-- Booking Step 3 -->
          <comp-book-step3
            v-if="bookingStep === 3"
            :reservation-data="reservationData"
            :over-crowded="overCrowded"
            :increment-adult-guests="incrementAdultGuests"
            :decrement-adult-guests="decrementAdultGuests"
            :increment-children-guests="incrementChildrenGuests"
            :decrement-children-guests="decrementChildrenGuests"
          />

          <!-- Booking Step 4 -->
          <comp-book-step4
            v-if="bookingStep === 4"
            :reservation-data="reservationData"
            :update-guest="updateGuest"
          />

          <!-- Booking Step 5 (Only Mobile)-->
          <comp-book-step5
            v-if="bookingStep === 5"
            :guests-numbers="guestsNumbers"
            :change-step="changeStep"
          />

          <div id="buttons" class="tw-grid tw-grid-cols-2 tw-gap-3 tw-mt-2">
            <div
              class="tw-btn-secondary-white tw-flex tw-justify-center tw-items-center tw-py-2 tw-cursor-pointer"
              @click="cancel()"
            >
              <p class="tw-m-0 tw-font-roboto tw-text-xs md:tw-text-base">
                Cancelar
              </p>
            </div>
            <div
              v-if="loadingPostBooking"
              class="tw-text-center tw-mt-4 tw-mb-4"
            >
              <div class="text-center">
                <img
                  alt="loading"
                  class="tw-h-10"
                  src="@/assets/img/icons/loading.gif"
                />
              </div>
            </div>
            <comp-button-primary-red
              v-else
              :disabled="!validateSteps"
              title="Siguiente"
              class="tw-w-full tw-h-full tw-py-3"
              @click.native="nextStep()"
            />
          </div>
        </div>

        <comp-book-space-info
          :guests-numbers="guestsNumbers"
          :change-step="changeStep"
        />
      </div>
    </div>
  </div>
</template>
