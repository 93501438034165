var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"showMeRanking"}},[_c('div',{staticClass:"ml-0 md:tw-ml-20 ml-xl-0"},[_c('div',{staticClass:"container px-0 px-md-3 mt-md-4"},[_c('BreadCrumb',{attrs:{"crumbs":_vm.breadcrumb}})],1),_c('MobileBar',{attrs:{"title":"TU REMUNERACIÓN"}}),_c('div',{staticClass:"container d-flex flex-md-row flex-column px-0 px-md-3 mt-2 mt-md-4"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6 px-0 order-md-2 order-first"},[_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-center justify-content-md-end py-0"},[_c('div',{staticClass:"col col-md-auto my-md-0 my-1 px-md-0 px-3 py-0 order-first order-md-1"},[_c('router-link',{staticClass:"tw-text-sm line-height-20 tw-font-medium hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-md-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block text-center",attrs:{"to":"newRequestOptions/4"},nativeOn:{"click":function($event){return _vm.pushDataLayer('Tienes problemas con tu pedido')}}},[_vm._v(" ¿Tienes problemas con tu pedido? ")])],1)])])]),_c('div',{staticClass:"container px-3 px-md-3 mt-2 mt-md-5"},[_vm._l((_vm.variables),function(item,index){return _c('div',{key:index,staticClass:"row py-2"},[(
            item.metrics.some(function (e) {
              return e.variable_salary_weight > 0;
            }) === true
          )?_c('div',{staticClass:"col-md-3 d-md-none"},[_c('div',{staticClass:"tw-shadow-md tw-rounded-lg-lg tw-rounded-tr-lg tw-rounded-tl-lg he-70 he-md-130 wi-md-150 tw-bg-brown-500 d-flex flex-row align-items-center justify-content-center px-4 px-md-0",style:('background: ' + _vm.categories[index].background)},[_c('p',{staticClass:"tw-font-roboto text-white my-1"},[_vm._v("|")]),_c('p',{staticClass:"tw-font-robotoBold tw-text-xl text-white my-1 px-2"},[_vm._v(" "+_vm._s(_vm.user.remuneration.variables[index].variable_group)+" ")])])]):_vm._e(),(
            item.metrics.some(function (e) {
              return e.variable_salary_weight > 0;
            }) === true
          )?_c('div',{staticClass:"col-md d-flex"},[_c('div',{staticClass:"shadow-box-2 d-none d-md-inline-flex tw-rounded-tl-lg tw-rounded-bl-lg p-3 flex-column w-10p-md left he-100",style:('background: ' + _vm.categories[index].background)},[_c('p',{staticClass:"tw-font-robotoBold tw-text-base fs-md-18 my-1 text-white"},[_vm._v(" "+_vm._s(_vm.user.remuneration.variables[index].variable_group)+" ")]),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"md:tw-rounded-none tw-rounded-br-lg tw-rounded-bl-lg d-inline-flex flex-column flex-md-row px-4 py-2 p-md-4 w-90p-md w-100p right tw-overflow-y-scroll"},_vm._l((item.metrics),function(metrics,subIndex){return _c('div',{key:subIndex},[(metrics.variable_salary_weight > 0)?_c('CompItemCalc',{staticClass:"pb-3",attrs:{"kpiObject":metrics,"rates":item.rates,"salaryBase":_vm.variableRemuneration},on:{"subTotal":(val) => _vm.setSubTotal(metrics.id, val)}}):_vm._e()],1)}),0)]):_vm._e()])}),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',{staticClass:"col-12 col-md-5 my-3 my-md-5 d-flex justify-content-between border-bottom"},[_c('p',{staticClass:"tw-font-robotoBold tw-text-xl fs-md-24 tw-text-gray-300"},[_vm._v(" Total ")]),_c('p',{staticClass:"tw-font-robotoBold tw-text-xl fs-md-24 tw-text-green-400"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalSalary))+" ")])])])],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 px-0 order-md-1 order-last"},[_c('div',{staticClass:"d-none d-md-flex"},[_c('p',{staticClass:"tw-font-tolyerBold fs-md-40 text-uppercase"},[_vm._v(" Calculadora ")])])])
}]

export { render, staticRenderFns }