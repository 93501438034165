import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=1623eb9a&scoped=true"
import script from "./EventDetail.vue?vue&type=script&setup=true&lang=js"
export * from "./EventDetail.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1623eb9a",
  null
  
)

export default component.exports