import { computed, reactive } from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { storeToRefs } from "pinia";

const { bookingPlaceSelected, bookingStep } = storeToRefs(
  useReservationsStore()
);

export function useReservationData() {
  const reservationData = reactive({
    place: {},
    capacity: {},
    guestsNumber: {
      adults: 1,
      children: 0,
      pets: 0,
    },
    guests: [],
    adults: [],
    children: [],
    pets: [],
  });

  const overCrowded = computed(() => {
    return (
      reservationData.guestsNumber.adults +
        reservationData.guestsNumber.children >
      reservationData.capacity.capacity
    );
  });

  const incrementAdultGuests = () => {
    if (
      reservationData.guestsNumber.adults +
        reservationData.guestsNumber.children +
        1 <=
      bookingPlaceSelected.value.capacity
    ) {
      reservationData.guestsNumber.adults++;
      reservationData.adults.push({
        name: "",
        surname: "",
        document_type: "",
        document_id: "",
        eps: "",
      });
    }
  };

  const decrementAdultGuests = () => {
    if (reservationData.guestsNumber.adults > 1) {
      reservationData.guestsNumber.adults--;
      reservationData.adults.pop();
    }
  };

  const incrementChildrenGuests = () => {
    reservationData.guestsNumber.children++;
    reservationData.children.push({
      name: "",
      surname: "",
      document_type: "",
      document_id: "",
      eps: "",
    });
  };

  const decrementChildrenGuests = () => {
    if (reservationData.guestsNumber.children > 0) {
      reservationData.guestsNumber.children--;
      reservationData.children.pop();
    }
  };

  const deleteCharacteristics = () => {
    if (bookingStep.value === 4) {
      reservationData.guestsNumber = {
        adults: 1,
        children: 0,
        pets: 0,
      };
      reservationData.guests = [];
      reservationData.adults = [];
      reservationData.children = [];
      reservationData.pets = [];
    }
  };

  const updateGuest = (type, value, field, idx) => {
    if (!reservationData[type][idx]) {
      reservationData[type][idx] = {};
    }

    reservationData[type][idx][field] = value;
  };

  const guestsNumbers = computed(() => {
    let persons =
      reservationData.guestsNumber.adults +
      reservationData.guestsNumber.children;

    let pets = reservationData.guestsNumber.pets;

    const pluralize = (number, singular, plural) => {
      return number === 1 ? singular : plural;
    };

    return persons > 0
      ? `${persons} ${pluralize(persons, "persona", "personas")}${
          pets > 0 ? "," + "\n" + pets : ""
        } ${pets > 0 ? pluralize(pets, "mascota", "mascotas") : ""}`
      : "";
  });

  const gatherGuests = () => {
    console.log("Gather guests");
    const setGuestType = (guest, type) => {
      if (guest.type !== null) {
        guest.type = type;
      }
    };

    reservationData.adults.forEach((adult) => setGuestType(adult, "ADULT"));
    reservationData.children.forEach((child) => setGuestType(child, "CHILD"));

    reservationData.guests = [
      ...reservationData.adults,
      ...reservationData.children,
    ];
  };

  const checkEveryGuest = computed(() => {
    gatherGuests();
    let guests = reservationData.guests;
    let valid = true;
    guests.forEach((guest) => {
      if (
        guest.name === "" ||
        guest.surname === "" ||
        guest.document_type === "" ||
        guest.document_id === "" ||
        guest.eps === ""
      ) {
        valid = false;
      }
    });
    return valid;
  });

  return {
    reservationData,
    overCrowded,
    guestsNumbers,
    checkEveryGuest,
    incrementAdultGuests,
    decrementAdultGuests,
    incrementChildrenGuests,
    decrementChildrenGuests,
    deleteCharacteristics,
    updateGuest,
  };
}
