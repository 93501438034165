<script setup>
import config from "@/services/config";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompSocialNetworkInteractions from "@/components/social-network/CompSocialNetworkInteractions.vue";
import { ref } from "vue";

const uriResources = config.beerOfficeURL;
const isHovering = ref(false);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["compartir", "updateReaction", "updatedComment"]);

function changeHovering(boolean) {
  isHovering.value = boolean;
}
</script>

<template>
  <div
    class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-grid-rows-rows_3_fit_two_last tw-gap-4 lg:tw-gap-x-10 lg:tw-gap-y-4 tw-pb-4 lg:tw-pb-6"
    @mouseenter="changeHovering(true)"
    @mouseleave="changeHovering(false)"
  >
    <div class="lg:tw-row-span-3">
      <img
        :src="uriResources + '/' + props.item.resource"
        class="tw-w-full tw-h-72 lg:tw-h-auto lg:tw-max-h-[22rem] tw-rounded-2xl lg:tw-rounded-3xl tw-object-cover"
        alt=""
      />
    </div>
    <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-end">
      <p
        class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-2xl tw-line-clamp-2"
      >
        {{ props.item.title }}
      </p>
      <p class="tw-font-roboto tw-text-sm lg:tw-text-base tw-line-clamp-3">
        {{ props.item.description }}
      </p>
    </div>
    <div>
      <comp-social-network-interactions
        :item="props.item"
        :id="props.item.postable_id ? props.item.postable_id : props.item.id"
        :title="props.item.title"
        :description="props.item.description"
        :image="props.item.resource"
        :amount_reactions="Number(props.item.amount_reactions)"
        :amount_comments="Number(props.item.amount_comments)"
        :user_reaction="props.item.user_reaction"
        category="NEW_ARTICLES"
        :hover="isHovering"
        @updatedComment="emit('updatedComment', $event)"
        @updatedReaction="emit('updatedReaction', $event)"
      />
    </div>
    <div>
      <comp-button-primary-red title="Saber más" class="tw-w-full" />
    </div>
  </div>
</template>

<style scoped></style>
