import ApiBeerOffice from "@/services/apiBeerOffice";

export default {
  namespaced: true,
  state: {
    competitions: [],
    competition: {},
    participant: [],
    competitionUser: {},
    competitionInfo: {
      ranking: []
    }
  },
  getters: {},
  mutations: {
    setCompetitions: (state, competitions) => {
      state.competitions = competitions;
    },
    setCompetition: (state, competition) => {
      state.competition = competition;
    },
    setCompetitionInfo: (state, competitionInfo) => {
      state.competitionInfo = competitionInfo;
    },
    /*setSaveCompetition: (state, saveCompetition) => {
      state.saveComp = saveCompetition;
    },*/
    setcomPost: (state, participant) => {
      state.participant = participant;
    },
    setCompetitionUser: (state, competitionUser) => {
      state.competitionUser = competitionUser;
    }
  },
  actions: {
    actGetCompetitions: async ({ commit }) => {
      let response = await ApiBeerOffice.getCompetitions();
      if (response.error) {
        return response.error;
      }
      commit("setCompetitions", response.data);
      return true;
    },

    actGetCompetition: async ({ commit }, id) => {
      let response = await ApiBeerOffice.getCompetitionById(id);
      if (response.error) {
        return response.error;
      }
      commit("setCompetition", response.data);
      return true;
    },

    actGetCompetitionByIdUser: async ({ commit }, data) => {
      let id = data.id;
      let idUser = data.idUser;
      let response = await ApiBeerOffice.getCompetitionBy_Id_User(id, idUser);
      if (response.error) {
        return response.error;
      }
      commit("setCompetitionInfo", response.data);
      return true;
    },

    actQuestionsAnswer: async (_, data) => {
      return await ApiBeerOffice.competition_questions_answer(data);
    },
    actCompetition: (_, data) => {
      return ApiBeerOffice.postCompetition(data);
    },
    actCompPost: async ({ commit }, data) => {
      ApiBeerOffice.compPost(data).then(response => {
        if (response.error) {
          return response.error;
        } else {
          commit("setcomPost", response.data);
        }
      });
    }
  }
};
