<template>
  <div class="mx-auto">
    <SecondaryNavbar
      class="d-block d-sm-block d-md-none"
      title="Compensacion Fija"
    />
    <div class="showme container d-none d-sm-block mt-2">
      <div class="row pt-4">
        <router-link to="/showMeTheMoney">
          <div class="col-12">
            <img src="@/assets/img/icons/backArrow.png" alt="" />
            <span class="view-description-sti pl-1">Regresar</span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="showme container">
      <div class="showme pt-md-2">
        <div class="container-fluid">
          <div class="row d-flex justify-content-center">
            <div class="col-12">
              <div class="text-center pl-3">
                <h3
                  class="d-none d-sm-block view-title tw-font-tolyerBold tw-text-xl md:tw-text-3xl"
                >
                  Tus Beneficios
                </h3>
              </div>
            </div>
            <div class="text-left">
              <div class="p-3">
                Este valor corresponde al costo de algunos beneficios que
                recibes, con los valores promedio que la compañía asume por cada
                uno. Recuerda consultar la sección de beneficios
                <router-link
                  class="router-card final-path"
                  to="/care-benefits/benefits"
                  >aquí</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid pt-5 d-none d-sm-block">
          <div class="row">
            <div
              v-if="Number(user.total_anual_prepaid) !== 0"
              class="d-flex justify-content-center col-md-4 col-sm-12 pb-md-4 pb-sm-2"
            >
              <div
                class="card card-benefict border-0 shadow"
                @click="trackMedicina()"
              >
                <router-link to="/care-benefits/benefits/5">
                  <div class="card-body">
                    <div class="row align-items-md-center mb-4">
                      <div
                        class="col-2 col-sm-2 col-md-2 col-lg-2 pr-1 benefict-logo"
                      >
                        <div class="img-round-back">
                          <img src="@/assets/img/icons/Grupo 9637.png" alt="" />
                        </div>
                      </div>
                      <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                        <div class="card-benefict-title">
                          Medicina Prepagada
                        </div>
                        <div class="card-benefict-subtitle">
                          <span
                            >{{ user.month_prepaid | currency }} x
                            {{ user.total_benef_prepaid }} x 12 meses</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-link d-flex flex-row-reverse card-benefict-value"
                    >
                      <span class="pull-right">{{
                        user.total_anual_prepaid | currency
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div
              v-if="Number(user.total_anual_product) !== 0"
              class="d-flex justify-content-center col-md-4 col-sm-12 pb-md-4 pb-sm-2"
            >
              <div
                class="card card-benefict border-0 shadow"
                @click="trackProducto()"
              >
                <router-link to="/e-commerce">
                  <div class="card-body" @click="showMeBenefitsTrack(2)">
                    <div class="row align-items-md-center mb-4">
                      <div
                        class="col-2 col-sm-2 col-md-2 col-lg-2 pr-1 benefict-logo"
                      >
                        <div class="img-round-back">
                          <img alt="" src="@/assets/img/icons/EtZiER.tif.png" />
                        </div>
                      </div>
                      <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                        <div class="card-benefict-title">Producto</div>
                        <div class="card-benefict-subtitle">
                          <span
                            >{{ user.month_product | currency }} x 12
                            meses</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-link d-flex flex-row-reverse card-benefict-value"
                    >
                      <span class="pull-right">{{
                        user.total_anual_product | currency
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div
              v-if="Number(user.total_anual_route) !== 0"
              class="d-flex justify-content-center col-md-4 col-sm-12 pb-md-4 pb-sm-2"
            >
              <div class="card card-benefict border-0 shadow">
                <router-link to="/care-benefits/benefits/21">
                  <div class="card-body">
                    <div class="row align-items-md-center mb-4">
                      <div
                        class="col-2 col-sm-2 col-md-2 col-lg-2 pr-1 benefict-logo"
                      >
                        <div class="img-round-back">
                          <img alt="" src="@/assets/img/icons/Outline.png" />
                        </div>
                      </div>
                      <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                        <div class="card-benefict-title">Ruta</div>
                        <div class="card-benefict-subtitle">
                          <span
                            >{{ user.month_route | currency }} x 12 meses</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-link d-flex flex-row-reverse card-benefict-value"
                    >
                      <span class="pull-right">{{
                        user.total_anual_route | currency
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div
              v-if="Number(user.total_anual_casino) !== 0"
              class="d-flex justify-content-center col-md-4 col-sm-12 pb-md-4 pb-sm-2"
              @click="trackCasino()"
            >
              <div class="card card-benefict border-0 shadow">
                <router-link to="/care-benefits/benefits/22">
                  <div class="card-body" @click="showMeBenefitsTrack(3)">
                    <div class="row align-items-md-center mb-4">
                      <div
                        class="col-2 col-sm-2 col-md-2 col-lg-2 pr-1 benefict-logo"
                      >
                        <div class="img-round-back">
                          <img
                            src="@/assets/img/icons/Grupo 9638.png"
                            width="22"
                            height="22"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                        <div class="card-benefict-title">Casino</div>
                        <div class="card-benefict-subtitle">
                          <span
                            >{{ user.month_casino | currency }} x 12 meses</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-link d-flex flex-row-reverse card-benefict-value"
                    >
                      <span class="pull-right">{{
                        user.total_anual_casino | currency
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div
              class="d-flex justify-content-center col-md-4 col-sm-12 pb-md-4 pb-sm-2"
            >
              <div
                class="card card-benefict card-total justify-content-center d-flex align-items-center"
              >
                <div class="justify-content-center d-flex align-items-center">
                  <span class="text-uppercase">Total:</span>
                  <span class="ml-1 font-weight-bold" style="font-size: 2em">{{
                    calcTotalBenefits | currency
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <p class="disclaimer px-3 mx-auto">
              * Los beneficios que se muestran están cuantificados con valores
              de referencia, los efectivamente recibidos pueden variar según la
              particularidad y utilización de los mismos.
              <br />
              <br />** El auxilio de Casino aplica para personas que tengan un
              salario base menor de 10 SMLV
              <br />
              <br />*** E l auxilio de rutas aplica según la localización donde
              labore el trabajador
            </p>
          </div>
        </div>

        <div class="container-fluid d-sm-none pt-2 mb-5">
          <div class="row">
            <div class="col-12 panel-collapse collapse show">
              <div class="border rounded shadow">
                <div
                  v-if="Number(user.total_anual_prepaid) !== 0"
                  class="border-bottom container-fluid"
                  @click="trackMedicina()"
                >
                  <router-link to="/care-benefits/benefits/5">
                    <div class="row p-2 d-flex align-items-center">
                      <div class="col-2 col-sm-2 pr-1 benefict-logo">
                        <div class="img-round-back">
                          <img alt="" src="@/assets/img/icons/Grupo 9637.png" />
                        </div>
                      </div>
                      <div class="col-6 col-sm-5">
                        <div class="text-uppercase benfict-title-mobile">
                          Medicina Prepagada
                        </div>
                        <div class="benfict-subtitle-mobile">
                          <span
                            >{{ user.month_prepaid | currency }} x
                            {{ user.total_benef_prepaid }} x 12 meses</span
                          >
                        </div>
                      </div>
                      <div class="col-4 col-sm-5 text-right">
                        <span class="benefict-total-mobile">{{
                          user.total_anual_prepaid | currency
                        }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div
                  v-if="Number(user.total_anual_product) !== 0"
                  class="border-bottom container-fluid"
                  @click="trackProducto()"
                >
                  <router-link to="/e-commerce">
                    <div class="row p-2 d-flex align-items-center">
                      <div class="col-2 col-sm-2 pr-1 benefict-logo">
                        <div class="img-round-back">
                          <img alt="" src="@/assets/img/icons/EtZiER.tif.png" />
                        </div>
                      </div>
                      <div class="col-5 col-sm-5">
                        <div class>
                          <span class="text-uppercase benfict-title-mobile"
                            >Producto</span
                          >
                        </div>
                        <div class="benfict-subtitle-mobile">
                          <span
                            >{{ user.month_product | currency }} x 12
                            meses</span
                          >
                        </div>
                      </div>
                      <div class="col-5 col-sm-5 text-right">
                        <span class="benefict-total-mobile">{{
                          user.total_anual_product | currency
                        }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div
                  v-if="Number(user.total_anual_route) !== 0"
                  class="border-bottom container-fluid"
                >
                  <router-link to="/care-benefits/benefits/21">
                    <div class="row p-2 d-flex align-items-center">
                      <div class="col-2 col-sm-2 pr-1 benefict-logo">
                        <div class="img-round-back">
                          <img alt="" src="@/assets/img/icons/Outline.png" />
                        </div>
                      </div>
                      <div class="col-5 col-sm-5">
                        <div class>
                          <span class="text-uppercase benfict-title-mobile"
                            >Ruta</span
                          >
                        </div>
                        <div class="benfict-subtitle-mobile">
                          <span
                            >{{ user.month_route | currency }} x 12 meses</span
                          >
                        </div>
                      </div>
                      <div class="col-5 col-sm-5 text-right">
                        <span class="benefict-total-mobile">{{
                          user.total_anual_route | currency
                        }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div
                  v-if="Number(user.total_anual_casino) !== 0"
                  class="border-bottom container-fluid"
                  @click="trackCasino()"
                >
                  <router-link to="/care-benefits/benefits/22">
                    <div class="row p-2 d-flex align-items-center">
                      <div class="col-2 col-sm-2 pr-1 benefict-logo">
                        <div class="img-round-back">
                          <img alt="" src="@/assets/img/icons/Grupo 9638.png" />
                        </div>
                      </div>
                      <div class="col-5 col-sm-5">
                        <div class>
                          <span class="text-uppercase benfict-title-mobile"
                            >Casino</span
                          >
                        </div>
                        <div class="benfict-subtitle-mobile">
                          <span class="benefict-subtitle-mobile"
                            >{{ user.month_casino | currency }} x 12 meses</span
                          >
                        </div>
                      </div>
                      <div class="col-5 col-sm-5 text-right">
                        <span class="benefict-total-mobile">{{
                          user.total_anual_casino | currency
                        }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="row pt-3 pb-3">
                <div class="col-12">
                  <div
                    class="card card-body card-total d-flex align-items-center"
                  >
                    <div
                      class="justify-content-center d-flex align-items-center"
                    >
                      <span class="text-uppercase total-value-mobile"
                        >Total:</span
                      >
                      <span
                        class="ml-1"
                        style="font-size: 1.2em; font-weight: bold !important"
                        >{{ calcTotalBenefits | currency }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
export default {
  name: "UserBenefits",
  components: {
    SecondaryNavbar,
  },
  data() {
    return {};
  },
  props: {
    totalBenefits: Number,
  },
  mounted() {
    window.dataLayer.push({
      event: "Beneficios tú Remuneración",
      Area: this.user.core_vp.name,
      Banda: this.user.core_pa_sideline.name,
      Cargo: this.user.core_position.name,
      Regional: this.user.core_site.core_regional.name,
      Gerencia: this.user.core_organizational_unit.name,
    });
  },
  computed: {
    ...mapState("modCore", ["user"]),
    calcTotalBenefits() {
      return (
        Number(this.user.total_anual_prepaid) +
        Number(this.user.total_anual_casino) +
        Number(this.user.total_anual_route) +
        Number(this.user.total_anual_product)
      );
    },
  },
  methods: {
    trackMedicina() {
      window.dataLayer.push({
        event: "Medicina Prepagada",
        Area: this.user.core_vp.name,
        Banda: this.user.core_pa_sideline.name,
        Cargo: this.user.core_position.name,
        Regional: this.user.core_site.core_regional.name,
        Gerencia: this.user.core_organizational_unit.name,
      });
      this.pushDataLayer("Medicina Prepagada");
    },
    trackProducto() {
      window.dataLayer.push({
        event: "Producto",
        Area: this.user.core_vp.name,
        Banda: this.user.core_pa_sideline.name,
        Cargo: this.user.core_position.name,
        Regional: this.user.core_site.core_regional.name,
        Gerencia: this.user.core_organizational_unit.name,
      });
      this.pushDataLayer("Producto");
    },
    trackCasino() {
      window.dataLayer.push({
        event: "Casino",
        Area: this.user.core_vp.name,
        Banda: this.user.core_pa_sideline.name,
        Cargo: this.user.core_position.name,
        Regional: this.user.core_site.core_regional.name,
        Gerencia: this.user.core_organizational_unit.name,
      });
      this.pushDataLayer("Casino");
    },
    pushDataLayer(benefit) {
      window.dataLayer.push({
        event: "TrackEvent-compensacion",
        category: "Remuneracion",
        SubCategory: "Beneficios",
        Action: "Clic",
        label: benefit,
      });
    },
  },
};
</script>
<style src="@/assets/css/showme.css"></style>
<style scoped>
.border {
  border-radius: 8px !important;
}

.benefict-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.benfict-title-mobile {
  text-align: left;
  font: Bold 12px/14px Roboto;
  letter-spacing: 0;
  color: #0f0e0e;
  opacity: 0.8;
}
.benfict-subtitle-mobile {
  text-align: left;
  font: Bold 12px/14px Roboto;
  letter-spacing: 0;
  color: #000000;
  opacity: 0.5;
}
.benefict-total-mobile {
  text-align: left;
  font: 14px/18px Roboto;
  letter-spacing: 0;
  color: #0f0e0e;
  opacity: 1;
}
.card-total {
  background: #7f0000;
  border-color: #7f0000;
  color: #ffffff;
  border-radius: 8px;
}
.card-body {
  padding: 0.8rem !important;
}
.card-benefict {
  border-radius: 8px;
  height: 154px !important;
  width: calc(50vw);
}
.card-benefict-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #d82b2b;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}
.card-benefict-subtitle {
  opacity: 0.5;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
}
.card-benefict-value {
  font-style: normal;
  font-weight: lighter;
  font-size: 29px;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
}
.img-round-back {
  background: #ffc041;
  padding: 10px;
  border-radius: 50%;
}
.total-value-mobile {
  font: 12px/14px Roboto;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}
.final-path {
  font: bold 14px/24px Roboto;
  letter-spacing: 0;
  color: #0f0e0e;
  opacity: 1;
}
</style>
