<template>
  <div class="row questions-trivia-2">
    <div class="col-12 col-lg-4">
      <img
        :src="uriResources + '/' + imageQuestion"
        width="100%"
        height="auto"
        alt="image trivia"
        class="d-none d-lg-block bor-tr-rounded-100 tw-rounded-tl-lg tw-rounded-bl-lg tw-rounded-br-lg tw-object-cover he-600"
      />
    </div>
    <div class="col-12 col-lg-8">
      <form
        :id="'triviaForm-' + this.trivia.id"
        @submit.prevent="nextQuestion()"
      >
        <div class="d-flex mb-5 mt-4">
          <div
            class="w-100 mr-1"
            v-for="(category, index) in trivia.comp_question_by_category"
            :key="'trivia-category-' + index"
          >
            <p
              class="tw-text-xxs fs-md-12 mb-1 text-center"
              :class="
                category.completed === 'to_complete'
                  ? 'tw-text-gray-300'
                  : category.completed === 'in_progress'
                  ? 'tw-text-red-400'
                  : 'tw-text-green-400'
              "
            >
              {{ category.name }}
            </p>
            <div
              class="w-100 he-6 he-md-8"
              :class="
                category.completed === 'to_complete'
                  ? 'tw-bg-gray-300'
                  : category.completed === 'in_progress'
                  ? 'tw-bg-red-400'
                  : 'tw-bg-green-400'
              "
            ></div>
          </div>
        </div>
        <div>
          <p class="tw-font-tolyerBold tw-text-gray-600 fs-40 mb-5">
            CATEGORÍA: {{ categoryName }}
          </p>
          <p class="tw-font-tolyerBold tw-text-gray-600 tw-text-xl mb-4">
            Pregunta {{ questionNumber }}/{{ questionNumberByCategory }}
          </p>
          <div>
            <p class="tw-text-sm tw-text-gray-600 mb-5">
              {{ question.question }}
            </p>
            <div
              class="custom-control custom-radio mb-3"
              v-for="answer in answers"
              :key="'answer-' + answer.id"
            >
              <input
                type="radio"
                class="custom-control-input"
                :id="'radio-' + answer.id"
                name="radio-stacked"
                :value="answer.id"
                @change="radioChange($event)"
                required
              />
              <label class="custom-control-label" :for="'radio-' + answer.id">
                {{ answer.answer }}
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-6 col-lg-3 ml-auto">
            <button
              class="w-100 tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 tw-rounded-lg py-2 px-3 tw-bg-white hover:tw-bg-gray-200"
              @click="previousQuestion()"
            >
              Anterior
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button
              class="w-100 tw-text-white tw-border tw-border-solid tw-border-red-400 tw-rounded-lg py-2 px-3 tw-bg-red-400 hover:tw-bg-red-600"
              type="submit"
            >
              Siguiente
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import ApiBeerOffice from "../../../services/apiBeerOffice";

export default {
  name: "QuestionsTrivia2",
  props: {
    trivia: {
      type: Object,
    },
  },
  data() {
    return {
      uriResources: config.beerOfficeURL,
      initalValueState: 0,
      initalValueQuestionState: 0,
      totalCategories: 0,
      questionNumber: 1,
      user_answers: {
        answers: [],
      },
      actual_answer: [],
    };
  },
  created() {
    this.trivia.comp_question_by_category.forEach((question, index) => {
      if (question.completed === "in_progress") {
        this.initalValueState = index;
      }
    });
    this.totalCategories = this.trivia.comp_question_by_category.length;
  },
  computed: {
    categoryName() {
      return this.trivia.comp_question_by_category[this.initalValueState].name;
    },
    questionNumberByCategory() {
      return this.trivia.comp_question_by_category[this.initalValueState]
        .comp_question.length;
    },
    imageQuestion() {
      return this.trivia.comp_question_by_category[this.initalValueState]
        .comp_question[this.initalValueQuestionState].image;
    },
    question() {
      return this.trivia.comp_question_by_category[this.initalValueState]
        .comp_question[this.initalValueQuestionState];
    },
    answers() {
      return this.trivia.comp_question_by_category[this.initalValueState]
        .comp_question[this.initalValueQuestionState].comp_answer;
    },
  },
  methods: {
    radioChange(e) {
      this.actual_answer = {
        answerId: Number(e.target.value),
        questionId: this.question.id,
      };
    },
    nextQuestion() {
      if (
        document.getElementById("triviaForm-" + this.trivia.id).checkValidity()
      ) {
        this.addAnswer(
          this.actual_answer.questionId,
          this.actual_answer.answerId
        );
        if (
          this.trivia.comp_question_by_category[this.initalValueState]
            .comp_question.length > this.questionNumber
        ) {
          this.questionNumber++;
          this.initalValueQuestionState++;
        } else {
          if (this.initalValueState + 1 >= this.totalCategories) {
            this.movetrivia(3);
          }
          this.sendAnswer();
          this.trivia.comp_question_by_category[
            this.initalValueState
          ].completed = "complete";
          this.initalValueState++;
          this.initalValueQuestionState = 0;
          this.questionNumber = 1;
          this.trivia.comp_question_by_category[
            this.initalValueState
          ].completed = "in_progress";
        }
      }
    },
    previousQuestion() {
      if (this.initalValueState <= 0) {
        this.initalValueState = 0;
      } else {
        this.initalValueState--;
      }
      this.initalValueQuestionState = 0;
      this.questionNumber = 1;
    },
    sendAnswer() {
      ApiBeerOffice.answerTrivia(this.user_answers, this.trivia.id);
      this.user_answers = { answers: [] };
    },
    addAnswer(comp_question_id, comp_answer_id) {
      this.user_answers.answers.push({
        comp_question_id: comp_question_id,
        comp_answer_id: comp_answer_id,
        comp_competition_id: this.trivia.id,
      });
    },
    movetrivia(state) {
      this.$emit("changeOpcVisible", state);
    },
  },
};
</script>

<style scoped>
.questions-trivia-2 .custom-control-label::after {
  left: -2rem;
  width: 1.5rem;
  height: 1.5rem;
}
.questions-trivia-2 .custom-control-label::before {
  left: -2rem;
  width: 1.5rem;
  height: 1.5rem;
}
.questions-trivia-2 .custom-control-label {
  line-height: 2rem;
}
.questions-trivia-2
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #fff;
}
.questions-trivia-2
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none;
  width: 12px;
  height: 12px;
  background-color: #d82b2b;
  border-radius: 50%;
  top: 10px;
  left: -26px;
}
.bor-tr-rounded-100 {
  border-top-right-radius: 100px;
}
</style>
