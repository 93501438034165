<template>
  <div class="tw-shadow-md tw-rounded-lg tw-bg-white">
    <div class="tw-bg-gray-200 tw-rounded-lg py-3">
      <div class="container">
        <div class="row position-relative px-0 px-md-3">
          <div class="position-absolute tw-top-0 tw-right-5">
            <svg
              width="32"
              height="139"
              viewBox="0 0 32 139"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.3839 28.9609L30.3839 0.00238098C14.1597 0.00238169 0.975879 12.9206 0.695312 28.9609L30.3839 28.9609Z"
                fill="#D82B2B"
              />
              <path
                d="M31.6361 102.02L31.6361 85.3486C22.2933 85.3486 14.7037 92.7843 14.543 102.02L31.6361 102.02Z"
                fill="#D82B2B"
              />
              <path
                d="M31.6387 72.9986L1.88281 72.9986L1.88281 102.02C1.88281 85.9922 15.2056 72.9986 31.6387 72.9986Z"
                fill="#D82B2B"
              />
              <path
                d="M13.0026 54.3393C17.0269 58.4655 16.8058 65.3779 16.8058 65.3779C16.8058 65.3779 10.0613 65.6018 6.03977 61.4784C2.01546 57.3522 2.23654 50.4397 2.23654 50.4397C2.23654 50.4397 8.97824 50.213 13.0026 54.3393Z"
                fill="#FFBF35"
              />
              <path
                d="M20.5945 54.3393C16.5702 58.4655 16.7912 65.3779 16.7912 65.3779C16.7912 65.3779 23.5357 65.6018 27.5572 61.4784C31.5815 57.3522 31.3605 50.4397 31.3605 50.4397C31.3605 50.4397 24.6188 50.213 20.5945 54.3393Z"
                fill="#FFBF35"
              />
              <path
                d="M13.0026 39.4604C17.0269 43.5866 16.8058 50.499 16.8058 50.499C16.8058 50.499 10.0613 50.7228 6.03977 46.5995C2.01546 42.4733 2.23654 35.5608 2.23654 35.5608C2.23654 35.5608 8.97824 35.3341 13.0026 39.4604Z"
                fill="#FFBF35"
              />
              <path
                d="M20.5945 39.4604C16.5702 43.5866 16.7912 50.499 16.7912 50.499C16.7912 50.499 23.5357 50.7228 27.5572 46.5995C31.5815 42.4733 31.3605 35.5608 31.3605 35.5608C31.3605 35.5608 24.6188 35.3341 20.5945 39.4604Z"
                fill="#FFBF35"
              />
              <path
                d="M31.6465 139L31.6465 127.621L25.8101 133.311L19.9766 139L31.6465 139Z"
                fill="#00BF79"
              />
              <path
                d="M1.88281 109.978L13.5528 109.978L7.71921 115.667L1.88281 121.359L1.88281 109.978Z"
                fill="#00BF79"
              />
              <path
                d="M1.88281 139L13.5528 139L7.71921 133.311L1.88281 127.621L1.88281 139Z"
                fill="#00BF79"
              />
              <path
                d="M31.6465 109.978L31.6465 121.359L25.8101 115.667L19.9766 109.978L31.6465 109.978Z"
                fill="#00BF79"
              />
              <path
                d="M25.1742 124.496C25.1742 119.967 21.4101 116.296 16.7668 116.296C12.1235 116.296 8.35937 119.967 8.35937 124.496C8.35937 129.024 12.1235 132.695 16.7668 132.695C21.4101 132.695 25.1742 129.024 25.1742 124.496Z"
                fill="#00BF79"
              />
            </svg>
          </div>
          <div class="col-6 col-lg-4">
            <img
              :src="
                user.prof_picture !== null
                  ? uriResources + '/' + user.prof_picture
                  : imgAvatarEmpty
              "
              height="auto"
              class="
                wi-200
                he-200
                tw-rounded-full
                mb-4 mb-lg-0
                tw-object-cover
              "
              alt="user image"
            />
          </div>
          <div class="col-12 col-lg-7">
            <p class="tw-text-gray-600 tw-text-xl text-uppercase font-weight-bold mb-3">
              {{ user.name }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Banda: </span>
              {{ user.core_om_sideline.name }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Cargo: </span>
              {{ user.core_position.name }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Localización: </span>
              {{ user.core_class_employee.name }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Convenio: </span>
              {{ user.ben_agreement.name }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Tipo de contrato: </span>
              {{ user.ben_contract.acronym }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Sociedad: </span>
              {{ user.core_site.name }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Fecha de ingreso: </span>
              {{ user.date_entry }}
            </p>
            <p class="tw-text-gray-600 tw-text-sm mb-2">
              <span class="font-weight-bold">Jefe inmediato: </span>
              {{ user.immediate_boss }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-bg-white tw-rounded-lg pt-4 pt-lg-5 pb-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="tw-text-gray-600 text-uppercase tw-font-tolyerBold fs-24">
              Educación formal
            </p>
          </div>
        </div>
        <div
          v-for="(education, index) in formData.education.items"
          :key="'education-' + index"
          class="row mb-3"
        >
          <div class="col-12 col-xl-4">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Universidad</p>
            <p class="tw-text-sm tw-text-gray-300">{{ education.institution }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Carrera</p>
            <p class="tw-text-sm tw-text-gray-300">{{ education.career }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Lugar</p>
            <p class="tw-text-sm tw-text-gray-300">{{ education.place }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha inicio</p>
            <p class="tw-text-sm tw-text-gray-300">{{ education.start_date }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha fin</p>
            <p class="tw-text-sm tw-text-gray-300">
              {{
                !education.end_date
                  ? "Actualmente"
                  : education.end_date
              }}
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="w-100 he-1 tw-bg-gray-300"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="tw-text-gray-600 text-uppercase tw-font-tolyerBold fs-24">
              Experiencia laboral
            </p>
          </div>
        </div>
        <div
          v-for="(experience, index) in formData.work_experience.items"
          :key="'experience-' + index"
          class="row mb-3"
        >
          <div class="col-12 col-xl-4">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Empresa</p>
            <p class="tw-text-sm tw-text-gray-300">{{ experience.company }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Puesto</p>
            <p class="tw-text-sm tw-text-gray-300">{{ experience.position }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Lugar</p>
            <p class="tw-text-sm tw-text-gray-300">{{ experience.place }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha inicio</p>
            <p class="tw-text-sm tw-text-gray-300">{{ experience.start_date }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha fin</p>
            <p class="tw-text-sm tw-text-gray-300">
              {{
                !experience.end_date
                  ? "Actualmente"
                  : experience.end_date
              }}
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="w-100 he-1 tw-bg-gray-300"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="tw-text-gray-600 text-uppercase tw-font-tolyerBold fs-24">
              Cursos
            </p>
          </div>
        </div>
        <div
          v-for="(course, index) in formData.courses.items"
          :key="'courses-' + index"
          class="row mb-3"
        >
          <div class="col-12 col-xl-4">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Institución</p>
            <p class="tw-text-sm tw-text-gray-300">{{ course.institution }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Curso</p>
            <p class="tw-text-sm tw-text-gray-300">{{ course.course }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Lugar</p>
            <p class="tw-text-sm tw-text-gray-300">{{ course.place }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha inicio</p>
            <p class="tw-text-sm tw-text-gray-300">{{ course.start_date }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha fin</p>
            <p class="tw-text-sm tw-text-gray-300">
              {{
                !course.end_date ? "Actualmente" : course.end_date
              }}
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="w-100 he-1 tw-bg-gray-300"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="tw-text-gray-600 text-uppercase tw-font-tolyerBold fs-24">
              Idiomas
            </p>
          </div>
        </div>
        <div
          v-for="(language, index) in formData.languages.items"
          :key="'languages-' + index"
          class="row mb-3"
        >
          <div class="col-12 col-xl-3">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Idioma</p>
            <p class="tw-text-sm tw-text-gray-300">{{ language.language }}</p>
          </div>
          <div class="col-12 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Nivel</p>
            <p class="tw-text-sm tw-text-gray-300">{{ language.level }}</p>
          </div>
          <div class="col-12 col-xl-3">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Institución</p>
            <p class="tw-text-sm tw-text-gray-300">{{ language.institution }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha inicio</p>
            <p class="tw-text-sm tw-text-gray-300">{{ language.start_date }}</p>
          </div>
          <div class="col-6 col-xl-2">
            <p class="tw-text-sm tw-text-gray-600 font-weight-bold">Fecha fin</p>
            <p class="tw-text-sm tw-text-gray-300">
              {{
                !language.end_date
                  ? "Actualmente"
                  : language.end_date
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "@/services/config";

export default {
  name: "CvView",
  data() {
    return {
      uriResources: config.beerOfficeURL,
      imgAvatarEmpty: require("@/assets/img/demo/avatar-empty.png"),
    };
  },
  computed: {
    ...mapState("modCore", ["user"]),
    ...mapState("modCv", ["formData", "is_complete"]),
  },
};
</script>
