<template>
  <form @submit.prevent="sendForm" class="needs-validation" novalidate>
    <div class="row">
      <div class="col-12">
        <p class="fs-24 text-uppercase tetx tw-font-tolyerBold tw-text-black">
          Perfil
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <label for="description" class="tw-text-sm tw-text-gray-400 font-weight-bold">
          Agrega una breve descripción sobre ti
        </label>
        <textarea
          name="description"
          id="description"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 p-2 he-100 tw-text-gray-400 tw-placeholder-gray-300  tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="formData.profile.summary"
        >
        </textarea>
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Este campo es obligatorio.</div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <label for="personal-mail" class="tw-text-sm tw-text-gray-400 font-weight-bold">
          Correo personal
        </label>
        <input
          type="email"
          name="personal-email"
          id="personal-mail"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="formData.profile.email_personal"
        />
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Escribe un email válido.</div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <label for="phone" class="tw-text-sm tw-text-gray-400 font-weight-bold">
          Teléfono
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder="Escribe aquí"
          required="required"
          class="form-control w-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 he-40 p-2 tw-text-gray-400 tw-placeholder-gray-300 tw-text-sm  focus:tw-border tw-border-solid tw-border-gray-400"
          v-model="formData.profile.cellphone"
        />
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Este campo es obligatorio.</div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <p class="fs-24 text-uppercase tetx tw-font-tolyerBold tw-text-black">
          Áreas de interes
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <label for="short-term" class="tw-text-sm tw-text-gray-400 font-weight-bold">
          Shor term*
        </label>
        <select
          name="short-term"
          id="short-term"
          class="form-control he-40 tw-border tw-border-solid tw-border-gray-300 tw-text-gray-400 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400"
          required="required"
          v-model="formData.profile.short_term_interest"
        >
          <option value="">Seleccione</option>
          <option v-for="term in terms" :key="term.id" :value="term.id">
            {{ term.name }}
          </option>
        </select>
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Seleccione una opción.</div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <label for="long-term" class="tw-text-sm tw-text-gray-400 font-weight-bold">
          Long term*
        </label>
        <select
          id="long-term"
          name="long-term"
          class="form-control he-40 tw-border tw-border-solid tw-border-gray-300 tw-text-gray-400 tw-text-sm focus:tw-border tw-border-solid tw-border-gray-400"
          required="required"
          v-model="formData.profile.long_term_interest"
        >
          <option value="">Seleccione</option>
          <option v-for="term in terms" :key="term.id" :value="term.id">
            {{ term.name }}
          </option>
        </select>
        <div class="valid-feedback"></div>
        <div class="invalid-feedback">Seleccione una opción.</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-6 col-xl-3 ml-auto">
        <button
          class=" tw-text-xs  fs-md-14 hover:tw-text-gray-600 tw-border tw-border-solid tw-border-gray-600 py-2 px-3 tw-rounded-md tw-bg-white tw-text-gray-600 hover:tw-bg-gray-200 d-block w-100 text-center"
        >
          Siguiente paso
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import utilitiesComponents from "@/mixins/general/utilitiesComponents";

export default {
  name: "CvProfile",
  mixins: [utilitiesComponents],
  computed: {
    ...mapState("modCv", ["formData", "terms"]),
  },
  created() {
    this.getTerms();
  },
  methods: {
    ...mapActions("modCv", ["changeStep", "getTerms", "saveProfile"]),
    async sendForm() {
      let form = document.querySelector(".needs-validation");
      if (form.checkValidity() === true) {
        let response = await this.saveProfile(this.formData.profile);
        if (response.error) {
          this.showToast(
            "Error",
            "Los datos ingresados no son correctos.",
            "danger",
            "b-toaster-bottom-right"
          );
        } else {
          this.changeStep(1);
        }
      }
      form.classList.add("was-validated");
    },
  },
};
</script>
