<template>
  <div class="mb-3">
    <HomeTrivia2
      @changeOpcVisible="changeOpcVisible"
      v-if="opcVisible === 1"
      :trivia="trivia"
      :datalayer="datalayer"
    />
    <QuestionsTrivia2
      @changeOpcVisible="changeOpcVisible"
      v-if="opcVisible === 2"
      :trivia="trivia"
    />
    <FinishTrivia2
      @changeOpcVisible="changeOpcVisible"
      :trivia="trivia"
      v-if="opcVisible === 3"
    />
    <ResultsTrivia2
      @changeOpcVisible="changeOpcVisible"
      :trivia="resultTrivia"
      v-if="opcVisible === 4"
    />
  </div>
</template>

<script>
import ApiBeerOffice from "../../../services/apiBeerOffice";

const HomeTrivia2 = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/trivia-2/HomeTrivia2"
  );

const QuestionsTrivia2 = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/trivia-2/QuestionsTrivia2"
  );

const FinishTrivia2 = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/trivia-2/FinishTrivia2"
  );

const ResultsTrivia2 = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "trivia" */ "@/components/trivia/trivia-2/ResultsTrivia2"
  );

export default {
  name: "Trivia2Component",
  components: {
    HomeTrivia2,
    QuestionsTrivia2,
    FinishTrivia2,
    ResultsTrivia2,
  },
  props: {
    trivia: {
      type: Object,
    },
    datalayer: {
      type: Object,
    },
  },
  data() {
    return {
      opcVisible: 1,
      resultTrivia: [],
    };
  },
  created() {
    this.getTriviaResult();
  },
  methods: {
    changeOpcVisible(opc) {
      this.opcVisible = opc;
    },
    getTriviaResult() {
      ApiBeerOffice.getTriviaResult(this.trivia.id).then((data) => {
        let result = data.data;
        if (result.completed) {
          this.resultTrivia = result.result;
          this.opcVisible = 3;
        }
      });
    },
  },
};
</script>
