<template>
  <div id="extraHoursHome" class="ml-0 md:tw-ml-20 ml-xl-0">
    <div class="tw-container-general">
      <SecondaryNavbar title="Tus horas extras" :breadcrumb="breadcrumb" />
    </div>
    <div class="container py-3">
      <div class="row mb-0 mb-md-5">
        <div class="col-12">
          <div class="cont-1 text-left text-md-center">
            <p class="title mb-3">
              Trabajo suplementario, dominicales y festivos
            </p>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 mx-auto">
          <div class="cont-1 text-left text-md-center">
            <p class="desc">
              Conoce el valor de referencia de tus horas extras y trabajo
              suplementario que aplica según la ley y/o acuerdo colectivo/tipo
              de contrato. *
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 order-2 order-md-1">
          <div class="cont-2">
            <p class="title">
              Conoce el valor de referencia de tu trabajo suplementario,
              dominicales y festivos
            </p>
            <div class="cont-cards">
              <router-link to="/showMeTheMoney/extra-hours/calculator">
                <div
                  class="card-1 d-flex align-items-center justify-content-between p-3 mb-4"
                  @click="pushDataLayer('Calculadora')"
                >
                  <div class="d-flex">
                    <div
                      class="icon d-flex justify-content-center align-items-center"
                    >
                      <i class="icon-calculadora-2"></i>
                    </div>
                    <div class="text mx-3">
                      <p class="title mb-0">Calculadora</p>
                      <p class="desc mb-0">
                        Simula el cálculo del tiempo suplementario que
                        trabajaste.
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <i class="icon-flecha-der"></i>
                  </div>
                </div>
              </router-link>
              <router-link to="/showMeTheMoney/extra-hours/how-calculate">
                <div
                  class="card-1 d-flex align-items-center justify-content-between p-3 mb-4"
                  @click="pushDataLayer('¿Cómo se calcula?')"
                >
                  <div class="d-flex">
                    <div
                      class="icon d-flex justify-content-center align-items-center"
                    >
                      <i class="icon-agenda"></i>
                    </div>
                    <div class="text mx-3">
                      <p class="title mb-0">¿Cómo se calcula?</p>
                      <p class="desc mb-0">
                        Conoce los conceptos de tiempo suplementario y cómo se
                        calculan.
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <i class="icon-flecha-der"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="cont-3">
            <p class="desc text-center">
              <span>* </span>Esta herramienta arroja valores de referencia y el
              pago de tiempo suplementario se hace acorde al área de nomina.
              cualquier novedad consulta con tu líder.
            </p>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-md-2">
          <RemunerationVideo class="mb-5 mb-md-0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryNavbar from "@/components/general/SecondaryNavbar";
import RemunerationVideo from "@/components/show-me-the-money/CompRemunerationVideo.vue";

export default {
  name: "ExtraHoursHome",
  components: {
    SecondaryNavbar,
    RemunerationVideo,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          link: "/home",
        },
        {
          id: 2,
          name: "Cuidados y Beneficios",
          link: "/care-benefits",
        },
        {
          id: 3,
          name: "Tu compensación",
          link: "/showMeTheMoney",
        },
        {
          id: 4,
          name: "Tus horas extras",
          link: "#",
          active: true,
        },
      ],
    };
  },
  methods: {
    pushDataLayer(label) {
      window.dataLayer.push({
        event: "TrackEvent-compensacion",
        category: "Remuneracion",
        SubCategory: "Tus horas extras",
        Action: "Clic",
        label: label,
      });
    },
  },
};
</script>

<style scoped>
.cont-1 .title {
  color: #333333;
  font-size: 18px;
  font-family: "RobotoBold", sans-serif;
}

.cont-1 .desc {
  color: #343434;
  font-size: 14px;
}

.cont-2 .title {
  color: #333333;
  font-size: 18px;
  font-family: "RobotoBold", sans-serif;
}

.cont-cards {
  color: #343434;
}

.cont-cards a {
  text-decoration: none;
  color: #343434;
}

.cont-cards .card-1 {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
}

.cont-cards .card-1 .icon {
  width: 40px;
  flex: 0 0 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fab23a;
}

.cont-cards .card-1 .icon i {
  font-size: 24px;
  color: #d82b2b;
}

.cont-cards .card-1 .title {
  font-family: "RobotoBold", sans-serif;
  font-size: 12px;
}

.cont-cards .card-1 .desc {
  font-size: 12px;
  line-height: 14px;
}

.cont-cards .card-1 .arrow i {
  font-size: 28px;
  color: #d82b2b;
  font-weight: bold;
}

.cont-3 .desc {
  font-size: 12px;
  line-height: 14px;
  font-style: italic;
  color: #999999;
}

.cont-3 .desc span {
  color: #d82b2b;
}

@media (min-width: 768px) {
  .cont-1 .title {
    font-size: 28px;
  }

  .cont-1 .desc {
    font-size: 16px;
  }

  .cont-cards .card-1 .title {
    font-size: 18px;
  }

  .cont-cards .card-1 .desc {
    font-size: 14px;
    line-height: 16px;
  }

  .cont-cards .card-1 .icon {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }

  .cont-cards .card-1 .icon i {
    font-size: 32px;
  }

  .cont-3 .desc {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 1200px) {
  .cont-1 .title {
    font-size: 34px;
    line-height: 1;
  }
}
</style>
